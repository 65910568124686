import { FollowTypes } from '../model'
import { parseToValueJSON } from './editor.utils'
import { deserializeQuestion, deserializeQuestionnaire } from './questionnaire.utils'

export function deserializeDocumentInstance(
  documentInstance: FollowTypes.DocumentInstance.DocumentInstanceDTO,
): FollowTypes.DocumentInstance.DocumentInstance {
  if (documentInstance.type === 'farte') {
    return {
      ...documentInstance,
      variables: documentInstance.variables.map(deserializeQuestion),
      quoteLines: documentInstance.quoteLines,
      questionnaires: documentInstance.questionnaires.map(deserializeQuestionnaire),
      template: parseToValueJSON(documentInstance.template || null),
      category: documentInstance.documentCategory,
    }
  }
  return documentInstance
}
