import { useQuery } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { getDocumentInstance } from '../../../store/domain/documentInstances/api'
import { documentKeys } from './documents.keys'

interface GetDocumentParams {
  docId: number
}

export const useGetDocument = ({ docId }: GetDocumentParams) => {
  const queryKey = documentKeys.detail(docId)

  const queryResult = useQuery(queryKey, async () => {
    const { data, ok } = await getDocumentInstance(docId)

    if (!data || !ok) {
      throw new UserFriendlyError(
        'error',
        `Impossible de récupérer le document ${docId}`,
        `Une erreur technique s'est produite`,
      )
    }
    return data
  })

  return { query: queryResult }
}
