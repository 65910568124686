import React, { FunctionComponent, useMemo, useState } from 'react'
import {
  MultiSelectSearch,
  SearchInput,
  SelectSearch,
  useSelectSearchFiltering,
} from '../../../../shared'
import { PatientTimelineFiltersProps } from './PatientTimelineFilters.model'
import { computeTeamsDoctorsOptions } from '../../../../../misc/team.utilities'
import classnames from 'classnames'
import { useDocumentCategoryOptions, useTagSearch } from '../../../../../hooks/utils'
import { useDebounce } from 'react-use'

export const PatientTimelineFilters: FunctionComponent<PatientTimelineFiltersProps> = ({
  currentUser,
  teams,
  tags,
  filters,
  documentCategories,
  setFilters,
}) => {
  const { search, category, doctorId, tagIds } = filters
  const [deboucedSearch, setDeboucedSearch] = useState(search)
  const [tagOptions, tagValues] = useTagSearch(tagIds, tags)
  const doctorsOptions = useMemo(
    () => (currentUser ? computeTeamsDoctorsOptions(teams, currentUser, 'Mes événements') : []),
    [currentUser, teams],
  )
  const doctorValue = doctorsOptions.find(({ value }) => value === doctorId)
  const [doctorText, setDoctorText, filteredDoctorOptions] = useSelectSearchFiltering(
    doctorValue,
    doctorsOptions,
  )

  const hasTeamMates = doctorsOptions.length > 1
  const categoriesOptions = useDocumentCategoryOptions(documentCategories)
  const categoriesValue = categoriesOptions.find(({ value }) => value === category)
  const [categoriesText, setCategoriesText, filteredCategoriesOptions] = useSelectSearchFiltering(
    categoriesValue,
    categoriesOptions,
  )

  useDebounce(
    () => {
      setFilters({ ...filters, search: deboucedSearch })
    },
    500,
    [deboucedSearch],
  )

  return (
    <div
      className={classnames(
        'p-4 lg:p-10 lg:pt-0 grid grid-flow-row grid-cols-1 lg:grid-flow-col gap-4',
        {
          'lg:grid-cols-4': hasTeamMates,
          'lg:grid-cols-3': !hasTeamMates,
        },
      )}
    >
      <SearchInput
        placeholder="Nom de l'évènement"
        value={deboucedSearch}
        onChange={({ target: { value } }) => setDeboucedSearch(value)}
      />
      <MultiSelectSearch
        placeholder="Rechercher par label"
        icon="tag"
        value={tagValues}
        options={tagOptions}
        onSelect={(selected) => {
          const tagIds = selected.map(({ value }) => value)
          setFilters({ ...filters, tagIds })
        }}
      />
      {hasTeamMates && (
        <SelectSearch
          placeholder="Médecin"
          icon="profile"
          value={doctorValue}
          options={filteredDoctorOptions}
          onSelect={(selected) => {
            setFilters({ ...filters, doctorId: selected?.value ?? undefined })
          }}
          textValue={doctorText}
          onTextChange={setDoctorText}
          onFocus={() => setDoctorText('')}
        />
      )}
      <SelectSearch
        placeholder="Type de document"
        icon={categoriesValue?.icon ?? 'documentText'}
        value={categoriesValue}
        options={filteredCategoriesOptions}
        onSelect={(selected) => setFilters({ ...filters, category: selected?.value ?? undefined })}
        textValue={categoriesText}
        onTextChange={setCategoriesText}
        onFocus={() => setCategoriesText('')}
      />
    </div>
  )
}
