/**
 * Création d'une iframe servant de sandbox pour l'impression
 * @see https://developer.mozilla.org/fr/docs/Web/API/Window/print#Specification
 */
function createPrintFrame(src: string) {
  const iframe = document.createElement('iframe')
  iframe.setAttribute('allow-modal', 'true')
  iframe.setAttribute('src', src)
  iframe.style.display = 'none'
  return iframe
}

export async function requestPrintByBlobUrl(src: string) {
  const frame = createPrintFrame(src)
  return new Promise<void>((resolve, reject) => {
    // Handle close native print dialog
    function onFocus() {
      resolve()
      // Register micro task auto digest
      setTimeout(() => {
        document.body.removeChild(frame)
      }, 60 * 1000)
    }
    // Handle open native print dialog
    function onBlur() {
      window.addEventListener('focus', onFocus, { once: true })
    }
    window.addEventListener('blur', onBlur, { once: true })
    // Append frame to handle
    document.body.appendChild(frame)
    // Handle print
    if (frame.contentWindow) {
      // Délégation de l'impression au système depuis l'iframe sandbox
      // On ajoute un Timeout de 1s le temps que l'iframe puisse se setup correctement, malheursement c'est la seul solution qui semble fonctionner
      // Exemple avec la lib print-js qui fait de même https://github.com/crabbly/Print.js/pull/588/files
      setTimeout(() => frame.contentWindow?.print(), 1000)
    } else {
      document.body.removeChild(frame)
      reject()
    }
  })
}
