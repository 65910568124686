import { connect } from 'react-redux'

import { RootState } from '../../../../../store'
import { MedicalEventHeader } from './MedicalEventHeader.component'
import {
  MedicalEventHeaderStoreProps,
  MedicalEventHeaderDispatchProps,
} from './MedicalEventHeader.model'
import {
  inUseMedicalEventSelector,
  medicalEventUiActions,
} from '../../../../../store/ui/medicalEvents/index'
import { isOwnerOfSelectedMedicalEvent } from '../../../../../store/domain/medicalEvents'
import { enabledFeaturesPreferencesSelector } from '../../../../../store/domain/me'

const mapStateToProps = (state: RootState): MedicalEventHeaderStoreProps => ({
  medicalEvent: inUseMedicalEventSelector(state),
  isOwner: isOwnerOfSelectedMedicalEvent(state),
  userEnabledFeatures: enabledFeaturesPreferencesSelector(state),
})

const mapDispatchToProps: MedicalEventHeaderDispatchProps = {
  sendMedicalEventDocuments: medicalEventUiActions.sendMedicalEventDocuments,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)(MedicalEventHeader)
export { withConnect as MedicalEventHeader }
