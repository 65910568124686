import getApi from '../../../../api'
import { DocumentAlertsFilters, DocumentAlertsTypes } from '../../../../model/DocumentAlerts'
import { deserializeDocumentAlerts, serializeDocumentAlertFilters } from './documentAlerts.mapper'
import { DocumentAlertsResponse } from './documentAlerts.model'

const route = (documentId: number) => `/documents/${documentId}/alerts`

export const fetchDocumentAlerts = (documentId: number, filters?: DocumentAlertsFilters) =>
  getApi()
    .get<DocumentAlertsResponse>(
      route(documentId),
      filters ? serializeDocumentAlertFilters(filters) : undefined,
    )
    .then(deserializeDocumentAlerts)

export const fetchDocumentAlertTypes = () =>
  getApi().get<DocumentAlertsTypes>('/alerts/documents/types')
