import getApi from '../../../../api'
import {
  MailLoginFormFields,
  MailLoginOtpFormFields,
} from '../../../../pages/mail/MailLoginPage/MailLoginPage.model'
import { deserializeMailResponse } from './mail.mapper'
import { ApiMailList, MailFile, ApiMail } from './mail.model'
import { mapSelectedAttachmentsToApiMailClassifyAttachmentResources } from '../mail.mapper'
import { MailClassificationAttachment } from '../../../../model/Mail'

const baseUrl = '/mss'

export const initiateAuthenticationMail = (values: MailLoginFormFields) =>
  getApi().post(`${baseUrl}/auth/initiate`, {
    rpps: values.rpps,
    channel: values.otpMethod,
    password: values.password,
  })

export const finalizeAuthenticationMail = (
  values: MailLoginOtpFormFields,
  tmpAuthenticationId: string,
) =>
  getApi().post(
    `${baseUrl}/auth/finalize`,
    { otpPassword: values.otpPassword },
    {
      headers: {
        'X-MSS-TOKEN': tmpAuthenticationId,
      },
    },
  )

export const getMailAddressList = () => getApi().get(`${baseUrl}/email_addresses`)

export const getMailList = (offset: number, limit: number, search?: string) =>
  getApi().get<ApiMailList>(`${baseUrl}/messages`, { offset, limit, search })

export const getMail = (id: string) =>
  getApi().get<ApiMail>(`${baseUrl}/messages/${id}`).then(deserializeMailResponse)

export const getMailAttachementFile = (messageId: string, partId: number, cdaFileId?: string) =>
  getApi().get<MailFile>(
    `${baseUrl}/messages/${messageId}/attachment/${partId}${cdaFileId ? `/${cdaFileId}` : ''}`,
  )

export const postClassifyDocuments = (
  messageId: number,
  selectedAttachments: MailClassificationAttachment[],
  patientId: number,
  documentCategoryValue: string,
  date: Date,
) =>
  getApi().post(`${baseUrl}/messages/${messageId}/classify`, {
    attachments: mapSelectedAttachmentsToApiMailClassifyAttachmentResources(selectedAttachments),
    patientId,
    documentCategoryId: documentCategoryValue,
    medicalEventDate: date.toISOString(),
  })

export const sendMail = (
  recipientEmails: string[],
  subject: string,
  content: string,
  files: File[],
  replyToEmail: string | null,
  askReadAcknowledgement: boolean,
  documentIds?: (number | null)[],
  patientId?: number,
) => {
  const data = new FormData()
  files.forEach((file) => {
    data.append('files[]', file)
  })

  recipientEmails.forEach((recipient) => {
    data.append('recipientEmails[]', recipient)
  })

  if (patientId) {
    data.append('patientId', patientId.toString())
  }
  data.append('content', content)
  data.append('subject', subject)
  if (replyToEmail) {
    data.append('replyToEmail', replyToEmail)
  }
  data.append('askReadAcknowledgement', `${askReadAcknowledgement}`)

  if (documentIds) {
    const nbOfFollowDoc = documentIds.filter((id) => id !== null).length

    if (nbOfFollowDoc === 1) {
      documentIds.forEach((documentId) => {
        data.append(`documentIds[]`, `${documentId}`)
      })
    }
  }

  return getApi().post(`${baseUrl}/messages`, data, {
    headers: {},
  })
}

export const deleteMail = (id: string) => getApi().delete<void>(`${baseUrl}/messages/${id}`)
