import { restuxCacheFactory } from '../../restux/cache'
import { LightPatient, Patient } from '../../../model/Patient'
import { RestuxResources } from '../../resources'
import { restuxPatientSearchApiConfig } from './api'

const resourceName = RestuxResources.patientSearch

const { actions, reducers, sagas } = restuxCacheFactory<LightPatient, Patient>({
  apiConfig: restuxPatientSearchApiConfig,
  refetchList: true,
  resourceName,
})

export const patientSearchActions = actions
export const patientSearchReducers = reducers
export const patientSearchSagas = sagas
