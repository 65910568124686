import { BottomPanelActionTypes, BottomPanelOptions } from './bottomPanel.model'

export function setBottomPanelOptions(options: BottomPanelOptions) {
  return { type: BottomPanelActionTypes.SET_BOTTOM_PANEL_OPTIONS, options }
}

export function displayBottomPanel(options: BottomPanelOptions) {
  return { type: BottomPanelActionTypes.BOTTOM_PANEL_DISPLAY, options }
}

export function hideBottomPanel() {
  return { type: BottomPanelActionTypes.BOTTOM_PANEL_HIDE }
}

export function toggleExpandBottomPanel() {
  return { type: BottomPanelActionTypes.BOTTOM_PANEL_TOGGLE_EXPAND }
}
