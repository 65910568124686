import {
  requestPeriod,
  resetAnalytics,
  requestAnalytics,
  requestOverall,
  setOverall,
  addAnalytics,
} from './adminAnalytics.actions'
import { takeEvery, put, call, select } from 'redux-saga/effects'
import { AdminAnalyticsActionTypes, AdminAnalyticsState } from './adminAnalytics.model'
import { AnalyticsAccuracyKeys, DatedAnalytics, Analytics } from '../../../model/Analytics'
import { getAnalytics } from '../../../api/Admin'
import { addResponseError } from '../../message'
import { formatFr, DATE_FORMAT_FRONT_SHORT } from '../../../misc/date.utilities'
import { downloadBlob } from '../../../misc/blob.utilities'
import { adminAnalyticsSelector } from './adminAnalytics.selectors'
import { ApiResponse } from 'apisauce'
import { add } from 'date-fns'

function* requestPeriodWorker({
  request,
  accuracy,
  index,
  requestLastYear,
}: ReturnType<typeof requestPeriod>) {
  const { from, to } = request
  let currentFrom = new Date(from)
  let currentTo = new Date(from)
  currentTo.setHours(23, 59, 59)
  var dayNum = from.getDate()

  if (accuracy === AnalyticsAccuracyKeys.PONCTUAL) {
    // Sur une requête ponctuelle, on ne veut que l'overall
    if (index === 0) resetAnalytics() // Si c'est l'entrée principale qui fait la requête, on clear l'éventuelle requête itérative précédente
  } else {
    yield put(resetAnalytics())

    if (accuracy === AnalyticsAccuracyKeys.MONTHLY) currentFrom.setMonth(currentFrom.getMonth() - 1)
    if (accuracy === AnalyticsAccuracyKeys.WEEKLY) currentTo.setDate(currentTo.getDate() - 1)

    while (currentTo.getTime() < to.getTime()) {
      switch (accuracy) {
        case AnalyticsAccuracyKeys.MONTHLY:
          currentFrom = add(currentFrom, { months: 1 })
          currentTo = add(currentFrom, { months: 1, seconds: -1 })
          currentTo = currentTo > to ? to : currentTo
          break
        case AnalyticsAccuracyKeys.WEEKLY:
          currentFrom = new Date(from.getFullYear(), from.getMonth(), dayNum, 0, 0, 0)
          currentTo = add(currentTo, { days: 7 })
          currentTo = currentTo > to ? to : currentTo
          dayNum += 7
          break
        case AnalyticsAccuracyKeys.DAILY:
          currentTo = add(currentTo, { days: 1 })
          currentFrom = add(currentFrom, { days: 1 })
          break
      }
      yield put(requestAnalytics({ from: currentFrom, to: currentTo }, requestLastYear))
    }
  }
  yield put(requestOverall(request, index))
}

function* requestPeriodWatcher() {
  yield takeEvery(AdminAnalyticsActionTypes.REQUEST_PERIOD, requestPeriodWorker)
}

function* requestAnalyticsWorker({
  request,
  requestLastYear,
}: ReturnType<typeof requestAnalytics>) {
  const { from, to } = request
  const response: ApiResponse<Analytics> = yield call(getAnalytics, {
    from: new Date(from),
    to: new Date(to),
  })
  if (response.ok && response.data && response.config) {
    const currentYearResult = {
      from: new Date(response.config.params.from),
      to: new Date(response.config.params.to),
      name: formatFr(response.config.params.to, DATE_FORMAT_FRONT_SHORT),
      patients: response.data.totalPatients,
      medicalEvents: response.data.totalMedicalEvents,
    }
    if (requestLastYear) {
      const [lyFrom, lyTo] = [
        new Date(response.config.params.from),
        new Date(response.config.params.to),
      ]
      lyFrom.setFullYear(lyFrom.getFullYear() - 1)
      lyTo.setFullYear(lyTo.getFullYear() - 1)
      const lyResponse: ApiResponse<Analytics> = yield call(getAnalytics, {
        from: lyFrom,
        to: lyTo,
      })
      if (lyResponse.ok && lyResponse.data && lyResponse.config) {
        yield put(
          addAnalytics({
            ...currentYearResult,
            patients_past: lyResponse.data.totalPatients,
            medicalEvents_past: lyResponse.data.totalMedicalEvents,
          }),
        )
      } else {
        yield put(addResponseError(lyResponse))
      }
    } else {
      yield put(addAnalytics(currentYearResult))
    }
  } else {
    yield put(addResponseError(response))
  }
}

function* requestAnalyticsWatcher() {
  yield takeEvery(AdminAnalyticsActionTypes.REQUEST_ANALYTICS, requestAnalyticsWorker)
}

function* requestOverallWorker({ request, index }: ReturnType<typeof requestOverall>) {
  const { from, to } = request
  const response: ApiResponse<Analytics> = yield call(getAnalytics, {
    from: new Date(from),
    to: new Date(to),
  })
  if (response.ok && response.data && response.config) {
    yield put(
      setOverall(
        {
          name: `${formatFr(response.config.params.from, DATE_FORMAT_FRONT_SHORT)}-${formatFr(
            response.config.params.to,
            DATE_FORMAT_FRONT_SHORT,
          )}`,
          from: response.config.params.from,
          to: response.config.params.to,
          patients: response.data.totalPatients,
          medicalEvents: response.data.totalMedicalEvents,
        },
        index,
      ),
    )
  } else {
    yield put(addResponseError(response))
  }
}

function* requestOverallWatcher() {
  yield takeEvery(AdminAnalyticsActionTypes.REQUEST_OVERALL, requestOverallWorker)
}

function* exportToCSVWorker() {
  const { analytics, overall }: AdminAnalyticsState = yield select(adminAnalyticsSelector)
  const analyticsToCSV = (analytics: DatedAnalytics) =>
    `\n${formatFr(analytics.from, DATE_FORMAT_FRONT_SHORT)},${formatFr(
      analytics.to,
      DATE_FORMAT_FRONT_SHORT,
    )},${analytics.patients},${analytics.medicalEvents}`

  const exportToCSV = `Début, Fin, Patients, Medical Events${overall.map((item) =>
    item ? analyticsToCSV(item) : '',
  )}\n${analytics.map((item) => analyticsToCSV(item))}`

  const blob = new Blob([exportToCSV])
  const fileName = `analytics-${overall.length > 0 && overall[0] ? overall[0].name : ''}.csv`
  downloadBlob(blob, fileName)
}

function* exportToCSVWatcher() {
  yield takeEvery(AdminAnalyticsActionTypes.EXPORT_TO_CSV, exportToCSVWorker)
}

export const adminAnalyticsSagas = {
  requestPeriodWatcher,
  requestAnalyticsWatcher,
  requestOverallWatcher,
  exportToCSVWatcher,
}
