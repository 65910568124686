import { connect } from 'react-redux'
import { setBottomPanelOptions } from '../../../store/ui/bottomPanel'
import { bottomPanelManualPrescriptionActions } from '../../../store/ui/bottomPanelManualPrescription'
import { ManualPrescriptionBottomPanel } from './ManualPrescriptionBottomPanel.component'
import { ManualPrescriptionBottomPanelDispatchProps } from './ManualPrescriptionBottomPanel.model'

const mapDispatchToProps: ManualPrescriptionBottomPanelDispatchProps = {
  setBottomPanelOptions,
  submitManualPrescription: bottomPanelManualPrescriptionActions.submitManualPrescription,
}

const withConnect = connect(undefined, mapDispatchToProps)(ManualPrescriptionBottomPanel)
export { withConnect as ManualPrescriptionBottomPanel }
