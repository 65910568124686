import { RestuxApiConfig } from '../../../restux/cache/restuxCache.model'
import { ResourceHistoryDetails } from '../../../../model/ResourceHistory'
import getApi from '../../../../api'
import { PaginatedList } from '../../../../model/Pagination'
import { deserializeResourceHistoryResponse } from './resourceHistory.mapper'
import { ResourceHistoryApi } from './resourceHistory.model'

export const restuxResourceHistoryApiConfig: RestuxApiConfig<
  ResourceHistoryDetails,
  ResourceHistoryDetails
> = {
  url: '/resources/history',
  getPaginatedItems: (route) =>
    getApi().get<PaginatedList<ResourceHistoryApi>>(route).then(deserializeResourceHistoryResponse),
}
