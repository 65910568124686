import { MutationKey, useQueryClient } from '@tanstack/react-query'
import { useCallback, useEffect } from 'react'

const MUTATION_EVENT_TYPE = 'updated'
const MUTATION_ACTION_TYPE = 'success'

export const useSubscribeMutation = <T, U>(
  mutationKey: MutationKey,
  onMutationSuccess: (mutationData: T, variables: U) => void,
) => {
  const queryClient = useQueryClient()
  const mutationCallback = useCallback(
    (event) => {
      if (
        event.type === MUTATION_EVENT_TYPE &&
        event.mutation.options.mutationKey === mutationKey &&
        event.action.type === MUTATION_ACTION_TYPE
      ) {
        onMutationSuccess(event.action.data, event.mutation.state.variables)
      }
    },
    [mutationKey, onMutationSuccess],
  )

  useEffect(() => {
    const unsubscribe = queryClient.getMutationCache().subscribe(mutationCallback)
    return () => unsubscribe()
  }, [mutationCallback, queryClient])
}
