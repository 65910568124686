import React, { ReactElement, useRef, FunctionComponent, ReactNode } from 'react'
import classNames from 'classnames/bind'
import { RestuxFilters } from '../../store/restux/restux.model'
import { List, ListItemType } from '../../components/shared'
import { SearchPanelProps } from './SearchPanel.model'
import Filters, { FiltersType } from './Filters'
import styles from './SearchPanel.module.scss'
import { useGetTags } from '../../hooks/queries/tag'
import { isDefined } from '../../misc/functions.utilities'

const cx = classNames.bind(styles)

export const SearchPanelContainer: FunctionComponent<{
  filterable: boolean
  children?: ReactNode
}> = ({ filterable, children }) => (
  <div className={cx(styles.container, { filterable })}>{children}</div>
)

export const SearchPanelFilters: FunctionComponent<{ children?: ReactNode }> = ({ children }) => (
  <div className={styles.filters}>{children}</div>
)

export const SearchPanelList: FunctionComponent<{ children?: ReactNode }> = ({ children }) => (
  <div className={styles.list}>{children}</div>
)

function SearchPanel<T extends ListItemType>({
  actions: Actions,
  onClearItems,
  onClearFilters,
  getItems,
  filterable = true,
  filterCapabilities = [FiltersType.Title],
  renderActions,
  renderCustomFilters,
  renderHeaderActions,
  pageCount,
  itemCount,
  items,
  onPrimaryAction,
  onMultiSelectionChange,
  renderItem,
  loading,
  documentCategories,
  usedTags,
  hideActionItems,
  testId,
  currentPage,
  onFiltersChange,
}: SearchPanelProps<T>): ReactElement<SearchPanelProps<T>> {
  const listRef = useRef<List<T>>(null)
  const { tagList: tags } = useGetTags()
  const filtersRef = useRef<RestuxFilters>({})

  const handleFiltersChange = (newFilters: RestuxFilters) => {
    filtersRef.current = newFilters
    resetPagination()

    if (typeof onFiltersChange === 'function') {
      onFiltersChange(newFilters)
    }
  }

  const handleUpdateList = (page: number) => {
    if (onClearItems && page === 1) {
      onClearItems()
    }
    getItems(page, filtersRef.current)
  }

  const resetPagination = () => {
    if (listRef.current && !isDefined(currentPage)) {
      listRef.current.updateList(true)
    }
  }

  return (
    <SearchPanelContainer filterable={filterable}>
      {Actions && <Actions>{renderActions && renderActions()}</Actions>}
      <SearchPanelFilters>
        <Filters
          tags={usedTags ? usedTags : tags}
          capabilities={filterCapabilities}
          documentCategories={documentCategories}
          renderCustomFilters={renderCustomFilters}
          onFiltersChange={handleFiltersChange}
          onClearFilters={onClearFilters}
          hideActionItems={hideActionItems}
          resetPagination={resetPagination}
          testId={testId}
        />
      </SearchPanelFilters>
      <SearchPanelList>
        <List
          ref={listRef}
          currentPage={currentPage}
          pageCount={pageCount}
          itemCount={itemCount}
          loading={loading}
          items={items}
          testId={testId}
          onMultiSelectionChange={onMultiSelectionChange}
          onPrimaryAction={onPrimaryAction}
          renderItem={renderItem}
          onUpdateList={(page) => handleUpdateList(page)}
          renderHeaderActions={() => renderHeaderActions && renderHeaderActions(resetPagination)}
          fullPageMode={false}
        />
      </SearchPanelList>
    </SearchPanelContainer>
  )
}

export default SearchPanel
