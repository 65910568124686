import { ZENDESK_KEY } from '../environment/analytics'
import { createAsyncScript, preventAnalyticLoading } from './utils'

import './zendesk.scss'

// Commands API
// https://developer.zendesk.com/api-reference/widget/introduction/#commands

export function getzE() {
  return window['zE']
}

export function initZendesk() {
  if (preventAnalyticLoading(window.document)) {
    return
  }
  window['zESettings'] = {
    webWidget: {
      offset: { horizontal: '80px' },
      color: { theme: '#458fe6' },
    },
  }
  createAsyncScript(
    window.document,
    `https://static.zdassets.com/ekr/snippet.js?key=${ZENDESK_KEY}`,
    { id: 'ze-snippet' },
    () => {
      const zE = getzE()
      if (!zE) {
        // ZenDesk deactivated
        return
      }
      zE('webWidget', 'helpCenter:setSuggestions', { labels: ['épinglé'] })
    },
  )
}

export const openZenDesk = () => {
  const zE = getzE()
  if (zE) {
    zE('webWidget', 'open')
  }
}

export const isZendeskHelpCenterOpen = () => {
  const zE = getzE()
  if (zE) {
    const display = zE('webWidget:get', 'display')
    return display === 'helpCenter'
  } else {
    return false
  }
}

export const addZendeskEventListener = (event: 'open' | 'close', callback?: () => void) => {
  const zE = getzE()
  if (zE) {
    zE('webWidget:on', event, callback)
  }
}
