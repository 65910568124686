import { SelectedQuoteLinePayload } from '../../../model/Acte'
import { QuoteLineActionTypes } from './quoteLine.model'

export const quoteLineActions = {
  addQuoteLine: (quoteLine: SelectedQuoteLinePayload, onQuoteLineAdded: () => void) => ({
    type: QuoteLineActionTypes.ADD_QUOTE_LINE,
    quoteLine,
    onQuoteLineAdded,
  }),
}
