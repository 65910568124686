import { useMemo } from 'react'
import { isDefined } from '../../../../misc/functions.utilities'
import { DocumentAlertTypeEntry } from '../../../../model/DocumentAlerts'
import { MultiSelectOption } from '../../../../model/SelectOption'

const typeToOptions = ({ name, displayName }: DocumentAlertTypeEntry) => ({
  value: name,
  label: displayName ?? name,
})

export const useAlertTypeSelect = (
  selected: string[] | null,
  alertTypes: DocumentAlertTypeEntry[] | null,
) => {
  const options = useMemo(() => {
    if (!isDefined(alertTypes)) {
      return []
    } else {
      return alertTypes
        .map((item) => typeToOptions(item))
        .sort((type1, type2) => (type1.label > type2.label ? 1 : -1))
    }
  }, [alertTypes])

  const value = useMemo(() => {
    if (isDefined(selected)) {
      return selected.reduce((acc, alertType) => {
        const option = options.find(({ value }) => value === alertType)
        if (isDefined(option)) {
          return [...acc, option]
        } else {
          return acc
        }
      }, [] as MultiSelectOption<string>[])
    } else {
      return options
    }
  }, [selected, options])

  return [value, options]
}
