import { RenderedPdf, useRenderPdfFromDocuments } from './useRenderPdfFromDocuments.query'
import { useCallback } from 'react'
import { isPrescription } from '../../../misc/documents.utilities'
import { DocumentInstance } from '../../../model/DocumentInstance'
import { DocumentCategoryKeys } from '@follow/cdk'
import { requestPrintByBlobUrl } from '../../../misc/print.utilities'

type PrintableDocument = Pick<DocumentInstance, 'id' | 'type'> & { category: DocumentCategoryKeys }

export type PrintableDocumentsVariable = {
  documents: PrintableDocument[]
}

export const usePrintDocuments = () => {
  const print = useCallback(
    (renderedPdf: RenderedPdf) => requestPrintByBlobUrl(URL.createObjectURL(renderedPdf.blob)),
    [],
  )

  const { render } = useRenderPdfFromDocuments()

  return ({ documents }: PrintableDocumentsVariable) => {
    const documentsToLock = documents
      .filter(({ category, type }) => type === 'farte' && category && isPrescription(category))
      .map(({ id }) => id)

    render(
      {
        documents: documents.map((document) => ({
          documentId: document.id,
          type: document.type,
          documentCategoryId: document.category,
        })),
        documentsToLock,
      },
      {
        onSuccess: print,
      },
    )
  }
}
