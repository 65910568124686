import React, { FC, useContext, useMemo, useState } from 'react'
import { DropdownContainer, DropdownItem, SearchInput, TooltipWrapper } from '../../../../../shared'
import { UserAvatar, UserSelectItem } from '../../../../../user'
import { AvatarsTabProps } from './AvatarsTab.model'
import { isAssistant } from '../../../../../../misc/user.utilities'
import { DesktopNavigationContext } from '../DesktopNavigationMenu.context'

export const AvatarsTab: FC<AvatarsTabProps> = ({ currentUser, loggedUser, setUsurpedUser }) => {
  const [showDropdown, setShowDropdown] = useState(false)
  const { hideTooltips } = useContext(DesktopNavigationContext)
  const [doctorsSearch, setDoctorsSearch] = useState('')

  const doctors = useMemo(() => {
    if (isAssistant(loggedUser)) {
      if (doctorsSearch.length > 0) {
        const searchRegex = new RegExp(doctorsSearch, 'i')
        return (
          loggedUser.doctors.filter(
            (doctor) =>
              `${doctor.familyName} ${doctor.firstName}`.match(searchRegex) ||
              `${doctor.firstName} ${doctor.familyName}`.match(searchRegex),
          ) ?? []
        )
      }
      return loggedUser.doctors
    }
  }, [doctorsSearch, loggedUser])

  return (
    <div className="my-2 mx-auto" data-test-id="container-avatar_tabs">
      {loggedUser && isAssistant(loggedUser) && (
        <div className="mx-auto">
          <div className="relative">
            {loggedUser.doctors.length > 2 && (
              <UserAvatar
                icon="more"
                size="medium"
                testId="avatar-more"
                onClick={() => setShowDropdown(!showDropdown)}
              />
            )}
            <div className="absolute top-0 left-14">
              <DropdownContainer
                direction="left"
                opened={showDropdown}
                closeDropdown={() => setShowDropdown(false)}
                closeOnSelect={false}
              >
                {loggedUser.doctors.length >= 8 && (
                  <div className="min-w-60">
                    <SearchInput
                      value={doctorsSearch}
                      onChange={(e) => setDoctorsSearch(e.target.value)}
                      placeholder="Rechercher un nom ou un prénom"
                    />
                  </div>
                )}
                {doctors &&
                  doctors.map((doctor) => (
                    <DropdownItem
                      key={doctor.id}
                      onClick={() => {
                        setUsurpedUser(doctor)
                        setShowDropdown(false)
                      }}
                      testId={`button-usurpate-user-${doctor.internalId}`}
                    >
                      <UserSelectItem
                        user={doctor}
                        selected={!!currentUser && doctor.id === currentUser.id}
                      />
                    </DropdownItem>
                  ))}
              </DropdownContainer>
            </div>
          </div>
        </div>
      )}
      {currentUser && (
        <TooltipWrapper
          content={`${currentUser.firstName} ${currentUser.familyName}`}
          pointerDirection="left"
          display={!hideTooltips}
        >
          <UserAvatar
            firstName={currentUser.firstName}
            familyName={currentUser.familyName}
            size="medium"
            selected={true}
            testId="avatar-current"
          />
        </TooltipWrapper>
      )}
      {currentUser &&
        isAssistant(loggedUser) &&
        loggedUser.doctors
          .filter((doctor) => currentUser.id !== doctor.id)
          .splice(0, 1)
          .map((doctor) => (
            <TooltipWrapper
              key={doctor.id}
              display={!hideTooltips}
              content={`${doctor.familyName} ${doctor.firstName}`}
              pointerDirection="left"
            >
              <UserAvatar
                firstName={doctor.firstName}
                familyName={doctor.familyName}
                size="medium"
                onClick={() => setUsurpedUser(doctor)}
                testId={`avatar-usurpate-${doctor.id}`}
              />
            </TooltipWrapper>
          ))}
    </div>
  )
}
