import { FC } from 'react'
import { UserFormHeaderProps } from './UserFormHeader.model'
import { getFullName } from '../../../../misc/user.utilities'
import { IconButton } from '../../../shared'
import { hasAssistantRole, hasAdminRole } from '../../../../misc/roles.utilities'

export const UserFormHeader: FC<UserFormHeaderProps> = ({
  user,
  loading,
  onUsurpationClick,
  onContactSearchClick,
}) => {
  const disabled = user && (hasAssistantRole(user.roles) || hasAdminRole(user.roles))

  const title = disabled
    ? `Vous ne pouvez pas usurper un·e assistant·e ou un admin`
    : `Usurpation de ${user && getFullName(user)}`

  if (loading) {
    return <>Chargement...</>
  }

  if (!user) {
    return (
      <div className="w-full flex items-center justify-between">
        <span>Nouvel utilisateur</span>
        <IconButton
          theme="dark"
          icon="documentText"
          title="Pré-remplir le formulaire avec l'Annuaire Santé"
          onClick={onContactSearchClick}
        />
      </div>
    )
  }

  return (
    <div className="flex">
      <div className="flex items-center content-between flex-row flex-grow">
        {`${getFullName(user)} · ${user.internalId}`}
      </div>
      <IconButton
        disabled={disabled}
        theme="dark"
        icon="arrowCircle"
        title={title}
        onClick={onUsurpationClick}
      />
    </div>
  )
}
