import { MailPatient } from '../../../model/Mail'
import { AssigningAuthority, Sex } from '../../../model/Patient'

const RESOURCE_PREFIX = `@INSI_DOMAIN`

export interface InsiPatient {
  birthFirstName: string
  birthLastName: string
  usedFirstName: string | null
  otherFirstName?: string
  sex: Sex
  birthDate: string
  birthPlaceCode: string
  assigningAuthority: AssigningAuthority | null
  inseeNumber: string | null
}

export type InsiStatus = 'NOT_FOUND' | 'FOUND' | 'MULTIPLE_RESULTS'

export interface InsiDomainState {
  patient: InsiPatient | null
  status: InsiStatus | null
  request?: string
  mailPatient?: MailPatient
}

export const InsiDomainActionType = {
  CHECK_PATIENT_IDENTITY: `${RESOURCE_PREFIX}/CHECK_PATIENT_IDENTITY`,
  SET_PATIENT_RESPONSE: `${RESOURCE_PREFIX}/SET_PATIENT_RESPONSE`,
  CLEAR_PATIENT_RESPONSE: `${RESOURCE_PREFIX}/CLEAR_PATIENT_RESPONSE`,
  SET_REQUEST: `${RESOURCE_PREFIX}/SET_REQUEST`,
  LOG_INSI: `${RESOURCE_PREFIX}/LOG_INSI`,
}

export interface IdentityCheckPayload {
  birthFirstName: string
  birthLastName: string
  birthDate: string
  sex: Sex
  birthPlaceCode?: string
}

export interface IdentityCheckResponse extends InsiPatient {
  status: InsiStatus
}

export type InsiPatientErrorFields = 'birthDate' | 'birthLastName' | 'birthFirstName' | 'sex'
