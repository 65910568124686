import React, { FunctionComponent, useCallback } from 'react'
import { GridLayout, SearchInput } from '../../../components/shared'
import { LegalDocumentListActionsProps } from './LegalDocumentListActions.model'

export const LegalDocumentListActions: FunctionComponent<LegalDocumentListActionsProps> = ({
  filters,
  onFiltersChange,
}) => {
  const handleTextFilter = useCallback(
    ({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      onFiltersChange({ search: value })
    },
    [onFiltersChange],
  )

  return (
    <GridLayout columnsTemplate="1fr" rowsTemplate="45px">
      <SearchInput
        placeholder="Rechercher un document légal"
        value={filters.search}
        onChange={handleTextFilter}
      />
    </GridLayout>
  )
}
