import React, { FunctionComponent, useMemo } from 'react'
import styles from './TeamCardBadgeList.module.scss'
import {
  SharingConfigAccessLevel,
  SharingConfigIElement,
  SharingConfigKeys,
} from '../../../../model/Team'
import { BadgeListItem } from './BadgeListItem'
import { unionBy } from 'lodash'

type TeamCardBadgeListProps = {
  sharingConfig: Array<SharingConfigIElement>
}

const defaultSharingConfig = [
  {
    resourceName: SharingConfigKeys.documentTemplate,
    accessLevel: SharingConfigAccessLevel.nothing,
  },
  { resourceName: SharingConfigKeys.questionnaire, accessLevel: SharingConfigAccessLevel.nothing },
  { resourceName: SharingConfigKeys.tag, accessLevel: SharingConfigAccessLevel.nothing },
  { resourceName: SharingConfigKeys.patient, accessLevel: SharingConfigAccessLevel.nothing },
  { resourceName: SharingConfigKeys.medicalEvent, accessLevel: SharingConfigAccessLevel.nothing },
  { resourceName: SharingConfigKeys.contact, accessLevel: SharingConfigAccessLevel.nothing },
  { resourceName: SharingConfigKeys.documentLayout, accessLevel: SharingConfigAccessLevel.nothing },
]

export const TeamCardBadgeList: FunctionComponent<TeamCardBadgeListProps> = ({ sharingConfig }) => {
  const hydratedSharingConfig = useMemo(() => {
    const iteratee: keyof SharingConfigIElement = 'resourceName'
    return unionBy(sharingConfig, defaultSharingConfig, iteratee)
  }, [sharingConfig])

  return (
    <div className={styles.sharingConfigs}>
      <span className={styles.title}>Partages</span>
      <div className={styles.tagList}>
        {hydratedSharingConfig.map(({ resourceName, accessLevel }) => (
          <BadgeListItem key={resourceName} resourceName={resourceName} accessLevel={accessLevel} />
        ))}
      </div>
    </div>
  )
}
