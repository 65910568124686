import {
  DocumentCategoryKeys,
  DocumentCategoryIcon,
  DocumentCategory,
} from '../../model/DocumentCategory'
import { useMemo } from 'react'
import { SelectOption } from '../../model/SelectOption'

export const useDocumentCategoryOptions = (
  documentCategories: Array<DocumentCategory>,
): Array<SelectOption<DocumentCategoryKeys>> =>
  useMemo(
    () =>
      documentCategories
        .filter(({ id }) => id !== DocumentCategoryKeys.Survey)
        .map(({ id, label }) => ({
          value: id,
          label,
          icon: DocumentCategoryIcon[id],
        })),
    [documentCategories],
  )
