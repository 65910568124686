import { useMutation, useQueryClient } from '@tanstack/react-query'
import { TeamMutationPayload } from '../../../../data/admin/teams/teams.model'
import { addValid } from '../../../../store/message'
import { useActionDispatch } from '../../../utils'
import { Team } from '../../../../model/Team'
import { postTeam } from '../../../../data/admin'
import { UserFriendlyError } from '../../../../model/Error'
import { hideBottomPanel } from '../../../../store/ui/bottomPanel'
import { teamKeys } from './teams.keys'

interface TeamMutateVariables {
  variables: TeamMutationPayload
}

export const usePostTeam = () => {
  const valid = useActionDispatch(addValid)
  const queryClient = useQueryClient()
  const closeBottomPanel = useActionDispatch(hideBottomPanel)

  const queryReturn = useMutation<Team, Error, TeamMutateVariables>(
    async ({ variables }) => {
      const { data, ok } = await postTeam(variables)
      if (!data || !ok) {
        throw new UserFriendlyError('error', "La création de l'équipe a échoué")
      }
      return data
    },
    {
      onSuccess: () => {
        valid('Équipe créée')
        queryClient.refetchQueries(teamKeys.all)
        closeBottomPanel()
      },
    },
  )

  return queryReturn
}
