import { addYears, endOfDay, isWithinInterval, parseISO, startOfDay } from 'date-fns'

import { formatFr, getAgeWithUnit } from './date.utilities'
import {
  CHILD_CIVILITY,
  CHILD_LIMIT_AGE,
  Civility,
  IdentityState,
  IdentityStateLabel,
  IdentityStatus,
  Patient,
} from '../model/Patient'
import { User } from '../model/User'
import { isDoctor, isSuperAdmin, isAssistant } from './user.utilities'
import {
  CIVILITY_LABELS,
  CIVILITY_LABELS_ABBREVIATIONS,
  getDisplayedBirthnameAndUsedName,
  getDisplayedFirstname,
  getDisplayedLastname,
  getDisplayedName,
  getPriorityNames,
  Title,
} from '@follow/cdk'

export {
  getDisplayedBirthnameAndUsedName,
  getDisplayedFirstname,
  getDisplayedLastname,
  getDisplayedName,
  getPriorityNames,
}

export const computeAgeLabel = (birthDate: string) => {
  return `${getAgeWithUnit(birthDate)} (${formatFr(parseISO(birthDate), 'd MMMM yyyy')})`
}

//  Retourne le Label associé au status de l'identité
//   | etat_dispositif | etat_fictif | etat_insi    | statut_identite |
//   | inactif         | inactif     | non réalisée | provisoire      |
//   | actif           | inactif     | non réalisée | validée         |
//   | inactif         | inactif     | réalisée     | récupérée       |
//   | actif           | inactif     | réalisée     | qualifiée       |
//   | actif           | actif       | non réalisée | provisoire      |
//   | inactif         | actif       | réalisée     | provisoire      |
//   | actif           | actif       | réalisée     | provisoire      |
export const getPatientIdentityStatusLabel = (
  trustedSource: boolean,
  suspiciousIdentity: boolean,
  insiChecked: boolean,
) => {
  if (trustedSource && !suspiciousIdentity && !insiChecked)
    return IdentityStateLabel[IdentityState.VALIDATED]
  else if (!trustedSource && !suspiciousIdentity && insiChecked)
    return IdentityStateLabel[IdentityState.INSI_CHECKED]
  else if (trustedSource && !suspiciousIdentity && insiChecked)
    return IdentityStateLabel[IdentityState.QUALIFIED]
  else return IdentityStateLabel[IdentityState.TEMPORARY]
}

export const formatLastName = (value: string) => {
  let formated = value.normalize('NFD')
  formated = formated.replace(/[^a-zA-Z '-]|[\u0300-\u036f]/g, '')
  formated = formated.toUpperCase()
  return formated
}

export const isPatientFormReachable = (currentUser: User | null, loggedUser: User | null) =>
  isDoctor(loggedUser) ||
  isSuperAdmin(loggedUser) ||
  (isAssistant(loggedUser) &&
    loggedUser.impersonatePermissions.some(
      ({ doctorId, permissions }) =>
        doctorId === currentUser?.internalId && permissions.insOidAccess,
    ))

export const getPatientCivility = (
  patient: Patient,
  civility: Civility,
  date: Date = new Date(),
) => {
  const birthDate = new Date(patient.birthDate)
  const isPatientAChild = isWithinInterval(date, {
    start: startOfDay(birthDate),
    end: endOfDay(addYears(birthDate, CHILD_LIMIT_AGE)),
  })

  if (isPatientAChild) return CHILD_CIVILITY

  const requestedCivility =
    civility === Civility.LONG ? CIVILITY_LABELS : CIVILITY_LABELS_ABBREVIATIONS

  return requestedCivility[patient.title || Title.NONE]
}

export function isIdentityStateQualified(identityStatus?: IdentityStatus): boolean {
  return identityStatus?.state === IdentityState.QUALIFIED
}

export function isINSIValidated(identityStatus: IdentityStatus): boolean {
  return identityStatus.insiStatus.checked === 'VALIDATED'
}
