import { AuditFields } from './Resource'

export interface DocumentRevision extends AuditFields {
  id: string
  uuid: string
  hash: string
}

export type DocumentHistory = DocumentRevision & {
  documentTemplate: string
  template: string
}

export type DocumentRenderHistory = DocumentRevision & {
  document: number
  payload: string
}

export type DocumentWithRevision = DocumentHistory | DocumentRenderHistory

export function isDocumentHistory(
  withRevision: DocumentWithRevision,
): withRevision is DocumentHistory {
  return Object.hasOwnProperty.call(withRevision, 'template')
}
