import getApi from '../../../../api'
import { MailContact } from '../../../../model/Contact'
import { PaginatedList } from '../../../../model/Pagination'
import { RestuxApiConfig } from '../../../restux/cache/restuxCache.model'

export const restuxMailContactSearchApiConfig: RestuxApiConfig<MailContact, MailContact> = {
  url: '/contacts',
  getPaginatedItems: (route) =>
    getApi().get<PaginatedList<MailContact>>(route, { groupByMssEmail: true }),
}
