import styled from '@emotion/styled'
import React, { FunctionComponent, memo } from 'react'

import DropDown, { DropdownItem } from '../../../components/Dropdown'
import { FontFamily } from './Toolbar.model'

const DropDownContainer = styled.div`
  width: 115px; // Pour éviter les variations de largeur lors du changement d'item
`

const sanitize = (value?: string) => value && value.replace(/\+/g, ' ')

export const FontFamilyDropdown: FunctionComponent<{
  values: ReadonlyArray<FontFamily>
  value?: FontFamily
  onClick: (textSize: FontFamily) => void
}> = memo(({ values, value, onClick }) => {
  return (
    <DropDownContainer>
      <DropDown renderTitle={() => sanitize(value)} size="small">
        {values.map((item, index) => (
          <DropdownItem
            key={index}
            onClick={(event) => {
              event.preventDefault()
              onClick(item)
            }}
          >
            <span style={{ fontFamily: item }}>{sanitize(item)}</span>
          </DropdownItem>
        ))}
      </DropDown>
    </DropDownContainer>
  )
})
