import { FunctionComponent } from 'react'
import { ORDOCLIC_APP_URL } from '../../../environment/ordoclic'
import { Modal } from '../../shared'
import { OrdoclicIframeProps } from './OrdoclicIframe.model'

export const OrdoclicIframe: FunctionComponent<OrdoclicIframeProps> = ({
  isUnmounting,
  ordoclicPatientId,
  setOpenOrdoclic,
}) => {
  return (
    <Modal
      isUnmounting={isUnmounting}
      onClickOutside={() => {
        setOpenOrdoclic(false)
      }}
    >
      <div className="w-screen-3/4 h-screen-3/4 bg-shades-white shadow-2xl rounded-3xl p-5">
        <iframe
          className="w-full h-full"
          allow="camera *"
          title="ordoclic-iframe"
          src={`${ORDOCLIC_APP_URL}/creation-ordonnance/type?integrated=true${
            ordoclicPatientId ? `&patientId=${ordoclicPatientId}` : ''
          }`}
        />
      </div>
    </Modal>
  )
}
