import { combineReducers } from 'redux'
import { paginationReducerFactory } from '../../restux/ui/restuxUiReducer.factory'
import {
  PatientManualPrescriptionsState,
  patientManualPrescriptionsConfig,
} from './patientManualPrescriptions.model'

export const patientManualPrescriptionsReducer = combineReducers<PatientManualPrescriptionsState>({
  pagination: paginationReducerFactory(patientManualPrescriptionsConfig),
})
