import React, { FunctionComponent } from 'react'
import { DispensingBlock } from './DispensingBlock'
import { DurationBlock } from './DurationBlock'
import { DosageBlock } from './DosageBlock'
import { PosologyDetailProps } from './PosologyDetail.model'
import { PosologyFormResource } from '../../../../model/Posology'

export const PosologyDetail: FunctionComponent<PosologyDetailProps> = ({
  drugs,
  posologyForm,
  disabled,
  availableAdministrationRoutes,
  setPosologyForm,
}) => {
  const handleFormChange = (form: Partial<PosologyFormResource>) => {
    setPosologyForm({
      ...posologyForm,
      ...form,
    })
  }

  return (
    <div className="bg-white flex flex-col justify-between">
      <div className="w-full flex-col">
        <div className="flex flex-col lg:flex-row px-4">
          <div className="m-1 lg:w-1/2">
            <DispensingBlock
              drugs={drugs}
              posologyForm={posologyForm}
              setPosologyForm={handleFormChange}
              disabled={disabled}
              availableAdministrationRoutes={availableAdministrationRoutes}
            />
            <DurationBlock
              posologyForm={posologyForm}
              setPosologyForm={handleFormChange}
              disabled={disabled}
            />
          </div>
          <div className="m-1 lg:w-1/2">
            <DosageBlock
              drugs={drugs}
              posologyForm={posologyForm}
              setPosologyForm={handleFormChange}
              disabled={disabled}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
