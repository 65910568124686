import sanitizeHtml from 'sanitize-html'

export const sanitizeToBasicHtml = (toSanitize: string) =>
  sanitizeHtml(toSanitize || '-', {
    allowedTags: [
      'p',
      'span',
      'strong',
      'b',
      'a',
      'img',
      'i',
      'u',
      'td',
      'tr',
      'th',
      'table',
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
    ],
    allowedAttributes: {
      img: ['src'],
      a: ['href'],
    },
  })

export const removeAllHtmlTags = (toSanitize: string) =>
  sanitizeHtml(toSanitize || '-', { allowedTags: [] })
