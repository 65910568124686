import React, { FunctionComponent, MouseEventHandler } from 'react'
import classNames from 'classnames/bind'
import Ink from 'react-ink'

import { BaseAnswer } from '../../../../model/Questionnaire'

import styles from './SelectableAnswer.module.scss'

const cx = classNames.bind(styles)

interface SelectableAnswerProps {
  answer: BaseAnswer
  activated?: boolean
  warning?: boolean
  disabled?: boolean
  optimist?: boolean
  onClick: MouseEventHandler<HTMLDivElement>
  withImage?: boolean
}

export const SelectableAnswer: FunctionComponent<SelectableAnswerProps> = ({
  answer,
  activated,
  disabled,
  onClick,
  optimist,
  warning,
  withImage,
}) => {
  const onClickHandler = !disabled ? onClick : undefined
  return (
    <div
      className={cx(styles.container, { activated, optimist, disabled, withImage, warning })}
      onClick={onClickHandler}
      data-test-id={`button-answer-${answer.id}`}
    >
      {withImage && (
        <div className={styles.imageContainer}>
          {answer.imageFile ? (
            <img
              data-test-id={'image-answer'}
              className={styles.image}
              src={answer.imageFile.base64}
              alt={answer.imageFile.visibleName}
            />
          ) : null}
        </div>
      )}
      <div className={styles.label} data-test-id="label-answer">
        {!disabled && <Ink />}
        {answer.title}
      </div>
    </div>
  )
}
