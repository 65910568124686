import React, { FunctionComponent } from 'react'

import { LightQuestionnaire } from '../../../../model/Questionnaire'

import { Tag } from '../../../shared'

export const QuestionnaireListItem: FunctionComponent<{
  item: LightQuestionnaire
}> = ({ item }) => {
  const label = item.private ? 'Privé' : 'Public'
  return (
    <div className="flex space-x-2">
      <Tag>{label}</Tag>
      {item.scoreConfig && <Tag>Score Scientifique</Tag>}
      <span>{item.title}</span>
    </div>
  )
}
