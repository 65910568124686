import { RestuxResources } from '../../resources'
import { uiActionsFactory } from '../../restux/ui'
import { RestuxPagination, RestuxUiSagaConf } from '../../restux/ui/restuxUi.model'
import { resourceHistoryPaginationSelector } from './resourceHistory.selectors'

export const resourceHistoryConfig: RestuxUiSagaConf = {
  resourceName: RestuxResources.resourceHistory,
  paginationSelector: resourceHistoryPaginationSelector,
}

export interface ResourceHistoryState {
  pagination: RestuxPagination
}

export const uiActions = uiActionsFactory(resourceHistoryConfig)

export const UiResourceHistoryActionTypes = {
  ...uiActions.types,
}
