import getApi from '../../../../api'
import { largePageLimit } from '../../../../constants'
import { DocumentCategory } from '../../../../model/DocumentCategory'
import { PaginatedList } from '../../../../model/Pagination'
import { FwResourceType } from '../../../../model/Resource'
import { RestuxApiConfig } from '../../../restux/cache/restuxCache.model'

const baseUrl = '/document_categories'

export const restuxDocumentCategoriesApiConfig: RestuxApiConfig<
  DocumentCategory,
  DocumentCategory
> = {
  url: baseUrl,
  getPaginatedItems: (route) => getApi().get<PaginatedList<DocumentCategory>>(route),
  getItem: (route) => getApi().get<DocumentCategory>(route),
}

export const getUsedDocumentCategories = (usedInResource: FwResourceType) =>
  getApi().get<PaginatedList<DocumentCategory>>(baseUrl, {
    currentPage: 1,
    pageSize: largePageLimit,
    usedInResource,
  })
