import { useCallback, useEffect, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Waypoint from 'react-waypoint'
import classNames from 'classnames/bind'
import { useAtomValue, useSetAtom } from 'jotai'
import { isDefined } from '@follow/cdk'
import { useGetDocument } from '../../../../../hooks/queries/documents'
import { MedicalEventContentTypeIcon } from '../../../../../model/MedicalEvent'
import { Loader } from '../../../../shared'
import { EventFileProps } from '../PatientTransversalNavigationEventDetails.model'
import { PatientTransversalNavigationEventFarteDocument } from './PatientTransversalNavigationEventFarteDocument'
import {
  PatientTransversalNavigationEventDocHeader,
  EventDocumentType,
} from '../PatientTransversalNavigationEventDocHeader/'
import { FileViewer } from '../../../../file'
import { useScrollToEventDocOrFile } from '../useScrollToEventDocOrFile.hook'
import { BackgroundColors } from '../../../../file/FileViewer/FileViewer.model'
import {
  exitFullScreenAtom,
  scrollHighlightStatusAtom,
  scrolledItemIdAtom,
  ScrollHighlightStatus,
} from '../../../../../state/navTrans'
import styles from './PatientTransversalNavigationEventDocument.module.scss'
import { usePrintDocuments } from '../../../../../hooks/queries/document'
import { useGetDocumentVariableData } from '../../../../../hooks/queries/documents/useGetDocumentVariableData.query'
import { useHistorizedMemo } from '../../../../../hooks/utils/useHistorizedMemo'
import { FieldValue } from '../../../../../model/Document'
import { PdfDocumentInstance } from '../../../../../model/DocumentInstance'
import { mapFieldValues } from '../../../../file/FileViewer/PdfViewerLegacy/pdf.utils'

const cx = classNames.bind(styles)

export const PatientTransversalNavigationEventDocument = ({
  id,
  isSelected,
  isFirstDocOrFile,
  isEventEditable,
  allowWaypointDetection,
}: EventFileProps) => {
  const print = usePrintDocuments()
  const scrolledDocOrFileId = useAtomValue(scrolledItemIdAtom)
  const setScrolledDocOrFileId = useSetAtom(scrolledItemIdAtom)
  const setScrollHighlightStatus = useSetAtom(scrollHighlightStatusAtom)
  const setExitFullScreenState = useSetAtom(exitFullScreenAtom)

  const location = useLocation()
  const navigate = useNavigate()

  const {
    query: { data: doc, isLoading },
  } = useGetDocument({ docId: id })

  const {
    query: { data: variable_data, isLoading: isDocumentVariableDataLoading },
  } = useGetDocumentVariableData({ docId: id })

  useScrollToEventDocOrFile(id, EventDocumentType.Document, isLoading, isSelected, isFirstDocOrFile)

  const handlePrint = useCallback(
    () =>
      doc &&
      print({
        documents: [
          {
            id: doc.id,
            category: doc.documentCategory.id,
            type: doc.type,
          },
        ],
      }),
    [doc, print],
  )

  const fieldValues = useHistorizedMemo<Array<FieldValue> | null>(
    (previousValue) => {
      if (!isDefined(variable_data) || doc?.type !== 'pdf') {
        return null
      }

      if (isDocumentVariableDataLoading && isDefined(previousValue)) {
        return previousValue
      }

      const mapped = mapFieldValues(doc.fieldValues, variable_data)
      return mapped
    },
    [(doc as PdfDocumentInstance)?.fieldValues, isDocumentVariableDataLoading, variable_data],
  )

  const handleVisibleWaypoint = useCallback(
    () => allowWaypointDetection && setScrolledDocOrFileId(id),
    [allowWaypointDetection, setScrolledDocOrFileId, id],
  )

  const focused = useMemo(
    () => (isSelected && scrolledDocOrFileId === id) || scrolledDocOrFileId === id,
    [id, isSelected, scrolledDocOrFileId],
  )

  const handleEnterFullScreen = useCallback(() => {
    setScrollHighlightStatus(ScrollHighlightStatus.DISABLED)
    if (scrolledDocOrFileId !== id) {
      navigate(`${location.search}#doc=${id}`)
    }
  }, [id, location.search, navigate, scrolledDocOrFileId, setScrollHighlightStatus])

  const handleExitFullScreen = useCallback(() => {
    isSelected && setExitFullScreenState(true)
  }, [isSelected, setExitFullScreenState])

  useEffect(() => {
    return () => setScrolledDocOrFileId(null)
  }, [setScrolledDocOrFileId])

  if (isLoading) return <Loader />
  if (!doc) return <p>Impossible de récupérer le fichier</p>

  return (
    <div id={`doc-${id}`} className={cx({ form: doc.type === 'pdf' })}>
      <PatientTransversalNavigationEventDocHeader
        id={doc.id}
        title={doc.title}
        icon={MedicalEventContentTypeIcon[doc.documentCategory.id]}
        type={EventDocumentType.Document}
        isEventEditable={isEventEditable}
        onPrint={handlePrint}
      />
      <Waypoint key={id} bottomOffset="65%" topOffset="20%" onEnter={handleVisibleWaypoint} />
      {doc.type === 'pdf' ? (
        fieldValues && (
          <div className={cx('fileContainer', { focused })}>
            <FileViewer
              file={doc.file}
              backgroundColor={BackgroundColors.white}
              onEnterFullScreen={handleEnterFullScreen}
              onExitFullScreen={handleExitFullScreen}
              fieldValues={fieldValues}
              isInteractive
              readOnly
            />
          </div>
        )
      ) : (
        <PatientTransversalNavigationEventFarteDocument document={doc} focused={focused} />
      )}
    </div>
  )
}
