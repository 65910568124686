import { ReactComponent as EventWithoutDocsIcon } from '../../../../../../assets/images/transversalNavigation/working-people.svg'
import { PatientTransversalNavigationNextEventButton } from '../../PatientTransversalNavigationNextEventButton'
import { EventWithoutDocsProps } from './PatientTransversalNavigationMedicalEventWithoutDoc.model'
import styles from '../PatientTransversalNavigationMedicalEventNoResults.module.scss'

export const PatientTransversalNavigationMedicalEventWithoutDoc = ({
  hasNextEvent,
  onNextEventClick,
}: EventWithoutDocsProps) => (
  <div className={styles.noResultsContainer}>
    <div className={styles.noResultsContent}>
      <EventWithoutDocsIcon />
      <div className={styles.noResultsDescription}>
        <h1>Aucun document n'est rattaché à cet événement</h1>
        {hasNextEvent && (
          <>
            <h3>Souhaitez vous accéder à l'évènement suivant?</h3>
            <div>
              <PatientTransversalNavigationNextEventButton onNextEventClick={onNextEventClick} />
            </div>
          </>
        )}
      </div>
    </div>
  </div>
)
