const UNFORMATTED_PHONE_WITH_NUMBER_REGEX = /^(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})$/
const UNFORMATTED_PHONE_WITH_PLUS_REGEX = /^(\+\d{2})(\d{1})(\d{2})(\d{2})(\d{2})(\d{2})$/

const START_WITH_PLUS_REGEX = /^\+[\d ]*$/
const START_WITH_NUMBER_REGEX = /^[\d ]*$/

const WITH_NUMBER_SPACE_INDEXES = [2, 5, 8, 11]
const WITH_PLUS_SPACE_INDEXES = [3, 5, 8, 11, 14]

export function inputPhoneFormatter(input: string) {
  if (typeof input !== 'string') {
    return input
  }

  // Retrait de tous les espaces pour permettre un formattage from scratch
  const withoutSpace = input.replace(/\s/g, '')

  const matchStartWithNumber = withoutSpace.match(UNFORMATTED_PHONE_WITH_NUMBER_REGEX)
  if (matchStartWithNumber !== null) {
    return matchStartWithNumber
      .slice(1, 6)
      .filter((value) => value !== undefined)
      .join(' ')
  }
  const matchStartPlus = withoutSpace.match(UNFORMATTED_PHONE_WITH_PLUS_REGEX)
  if (matchStartPlus) {
    return matchStartPlus
      .slice(1, 7)
      .filter((value) => value !== undefined)
      .join(' ')
  }

  if (input.match(START_WITH_NUMBER_REGEX) !== null) {
    if (WITH_NUMBER_SPACE_INDEXES.includes(input.length)) {
      return `${input} `
    }
  } // Cas d'un numéro de téléphone Commencant avec un "+"
  if (input.match(START_WITH_PLUS_REGEX) !== null) {
    if (WITH_PLUS_SPACE_INDEXES.includes(input.length)) {
      return `${input} `
    }
  }
  return input
}
