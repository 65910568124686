import React, { FunctionComponent } from 'react'
import styles from './BadgeListItem.module.scss'
import { Icon, IconsType, Tag } from '../../../../shared'
import { TagBackgroundColors } from '../../../../../colors'
import { SharingConfigAccessLevel, SharingConfigKeys } from '../../../../../model/Team'

enum resourceNameTexts {
  documentTemplate = 'Modèles',
  questionnaire = 'Questionnaires',
  tag = 'Labels',
  patient = 'Patients',
  medicalEvent = 'Événements',
  contact = 'Correspondants',
  documentLayout = 'Mises en pages',
}

type BadgeListItemProps = {
  resourceName: SharingConfigKeys
  accessLevel: string
}

const getIconName = (name: string): IconsType => {
  switch (name) {
    case SharingConfigAccessLevel.nothing:
      return 'forbidden'
    case SharingConfigAccessLevel.write:
      return 'pencil'
    case SharingConfigAccessLevel.delete:
      return 'preferences'
    case SharingConfigAccessLevel.read:
    default:
      return 'eye'
  }
}

export const BadgeListItem: FunctionComponent<BadgeListItemProps> = ({
  resourceName,
  accessLevel,
}) => (
  <Tag color={TagBackgroundColors.dsTag00}>
    <div className={styles.tagContainer}>
      <Icon size="nano" icon={getIconName(accessLevel)} color="--fw-color-shades-shade4" />
      <span className={styles.tagText}>{resourceNameTexts[resourceName]}</span>
    </div>
  </Tag>
)
