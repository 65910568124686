import { createReducer } from 'redux-starter-kit'
import { MailSubjectState, MailSubjectActionTypes } from './mailSubject.model'
import { MailSubjectAction } from './mailSubject.actions'

const DEFAULT_MAIL_SUBJECT_STATE: MailSubjectState = {
  subject: undefined,
  documentIds: undefined,
}

const MAIL_SUBJECT_ACTION_HANDLERS = {
  [MailSubjectActionTypes.SET_MAIL_SUBJECT]: (
    state: MailSubjectState,
    {
      subject,
    }: ReturnType<
      typeof MailSubjectAction.setMailSubject | typeof MailSubjectAction.clearMailSubject
    >,
  ) => ({
    ...state,
    subject,
  }),
  [MailSubjectActionTypes.SET_DOCUMENTS_IDS]: (
    state: MailSubjectState,
    {
      documentIds,
    }: ReturnType<
      typeof MailSubjectAction.setDocumentsIds | typeof MailSubjectAction.clearDocumentsIds
    >,
  ) => ({
    ...state,
    documentIds,
  }),
}

export const MailSubjectreducer = createReducer(
  DEFAULT_MAIL_SUBJECT_STATE,
  MAIL_SUBJECT_ACTION_HANDLERS,
)
