import { RootState } from '../../reducers'
import { PageList } from '../../restux/ui'
import { ResourceHistoryDetails } from '../../../model/ResourceHistory'
import { restuxResolvePage } from '../../restux/ui/restuxUi.selectors'

export const resourceHistoryPaginationSelector = (state: RootState) =>
  state.ui.resourceHistory.pagination

export const resourceHistoryPageSelector = (state: RootState): PageList<ResourceHistoryDetails> => {
  const pagination = resourceHistoryPaginationSelector(state)
  const resourceHistoryResource = state.cache.resourceHistory.list
  return restuxResolvePage(resourceHistoryResource, pagination)
}
