import { createReducer } from 'redux-starter-kit'
import { documentAnswerValuesDomainActions } from './documentAnswerValues.actions'
import {
  DocumentAnswerValuesDomainActionsTypes,
  DocumentAnswerValuesState,
} from './documentAnswerValues.model'

const DEFAULT_STATE: DocumentAnswerValuesState = {}

const ACTION_HANDLERS = {
  [DocumentAnswerValuesDomainActionsTypes.CLEAR]: () => ({}),
  [DocumentAnswerValuesDomainActionsTypes.FETCH_SUCCESS]: (
    state: DocumentAnswerValuesState,
    {
      documentId,
      documentAnswerValues,
    }: ReturnType<typeof documentAnswerValuesDomainActions.onFetchSuccessDocumentAnswerValues>,
  ) => ({
    ...state,
    [documentId]: documentAnswerValues,
  }),
  [DocumentAnswerValuesDomainActionsTypes.SET_ERROR]: (
    state: DocumentAnswerValuesState,
    { isError }: ReturnType<typeof documentAnswerValuesDomainActions.setAnswerValueError>,
  ) => ({
    ...state,
    isError,
  }),
}
export const documentAnswerValuesReducer = createReducer<DocumentAnswerValuesState>(
  DEFAULT_STATE,
  ACTION_HANDLERS,
)
