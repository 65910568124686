import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../../model/Error'
import { useCallback, useMemo } from 'react'
import { Filters } from '../../../../model/Filters'
import { singleItemPageLimit } from '../../../../constants'
import { getNextPageParam } from '../../../../misc/query.utilities'
import { usePaginationState, useFlatPaginatedData } from '../../../utils/query'
import { actesKeys } from './actes.keys'
import { getActes } from '../../../../data/ccam/actes'

interface GetInfiniteActesParams {
  enabled?: boolean
  filters?: Filters
  limit?: number
}

export const useGetInfiniteActes = ({
  enabled = true,
  filters,
  limit = singleItemPageLimit,
}: GetInfiniteActesParams) => {
  const queryKey = useMemo(() => actesKeys.list(filters), [filters])

  const queryReturn = useInfiniteQuery(
    queryKey,
    async ({ pageParam = 1, signal }) => {
      const { data, ok } = await getActes(pageParam, filters, signal, limit)
      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          `Impossible de récupérer la liste des codes CCAM`,
          `Une erreur technique s'est produite`,
        )
      }
      return data
    },
    {
      getNextPageParam,
      enabled: enabled,
    },
  )

  const queryClient = useQueryClient()
  const acteList = useFlatPaginatedData(queryReturn.data)
  const paginationState = usePaginationState(queryReturn.data)

  const cancelPendingQuery = useCallback(() => {
    queryClient.cancelQueries({ queryKey })
  }, [queryClient, queryKey])

  return {
    query: queryReturn,
    acteList,
    paginationState,
    cancelPendingQuery,
  }
}
