import React, { FunctionComponent } from 'react'
import { ReactComponent as TaskEmptyIcon } from '../../../../assets/images/tasks/tasks.svg'
import { ReactComponent as TaskVectorArrowIcon } from '../../../../assets/images/tasks/rollingArrow.svg'
import { RoundedButton } from '../../../shared'
import { TaskEmptyStateProps } from './TaskEmptyState.model'

export const TaskEmptyState: FunctionComponent<TaskEmptyStateProps> = ({ newTask }) => {
  return (
    <div className="w-full h-96 bg-shades-7 rounded-xl">
      <div className="flex flex-row justify-center items-center">
        <div className="mt-10 text-center flex flex-col">
          <div className="self-center w-32 h-32">
            <TaskEmptyIcon />
          </div>
          <div className="mt-6">
            <h2 className="text-lg">Aucune tâche</h2>
          </div>
          <div className="mt-3 text-shades-4 font-medium">
            <p>Vous n’avez actuellement aucune tâche à réaliser.</p>
            <p>Commencez par ajouter une nouvelle tâche sur Follow.</p>
          </div>
          <div className="self-center mt-6">
            <RoundedButton
              label="Nouvelle tâche"
              icon="add"
              size="normal"
              theme="primary"
              onClick={newTask}
              disabled={!newTask}
            />
          </div>
          <div className="self-end mr-10 w-12 h-6">
            <TaskVectorArrowIcon />
          </div>
        </div>
      </div>
    </div>
  )
}
