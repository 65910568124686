import React, { FunctionComponent } from 'react'
import { FormBlock } from '../../../shared'
import { LinkedAccountsBlockProps } from './LinkedAccountsBlock.model'
import styles from './LinkedAccountsBlock.module.scss'
import { InformationAlert } from '../InformationAlert'
import { LinkedAccountItem } from './LinkedAccountItem'

export const LinkedAccountsBlock: FunctionComponent<LinkedAccountsBlockProps> = ({
  accounts,
  onChange,
}) => {
  const { stellair } = accounts
  const shouldDisplayBlock = stellair.enabled
  return (
    <FormBlock label="Comptes liés" icon="externalLink">
      {shouldDisplayBlock ? (
        <div className={styles.accounts}>
          {stellair.enabled && (
            <LinkedAccountItem
              serviceName="Stellair"
              authenticated={stellair.authenticated}
              useStellairAssistantAccount={stellair.useStellairAssistantAccount}
              onChange={onChange}
              logout={stellair.logout}
            />
          )}
        </div>
      ) : (
        <InformationAlert>Votre compte Follow n'est pas lié à un service externe</InformationAlert>
      )}
    </FormBlock>
  )
}
