import { connect } from 'react-redux'

import { RootState } from '../../store'
import { enabledFeaturesPreferencesSelector } from '../../store/domain/me'

import { AddContentToDocumentEditor } from './AddContentToDocumentEditor.component'

import { AddContentToDocumentEditorStateProps } from './AddContentToDocumentEditor.model'

const mapStateToProps = (state: RootState): AddContentToDocumentEditorStateProps => ({
  enabledFeatures: enabledFeaturesPreferencesSelector(state),
})

export default connect(mapStateToProps)(AddContentToDocumentEditor)
