import { useMutation, useQueryClient } from '@tanstack/react-query'
import { HealthData } from '../../../model/HealthData'
import { createPatientHealthData } from '../../../data/patientHealthData/createPatientHealthData'
import { UserFriendlyError } from '../../../model/Error'
import { patientHealthDataKeys } from './patientHealthData.keys'
import { addValid } from '../../../store/message'
import { useActionDispatch } from '../../utils'

interface CreatePatientHealthDataVariables {
  patientId: number
  healthData: Partial<HealthData>[]
}

export const useCreatePatientHealthData = ({
  triggerSubscription = true,
}: {
  triggerSubscription?: boolean
} = {}) => {
  const queryClient = useQueryClient()
  const valid = useActionDispatch(addValid)

  const mutate = useMutation<null, Error, CreatePatientHealthDataVariables>(
    triggerSubscription ? patientHealthDataKeys.mutation : [],
    async ({ patientId, healthData }: CreatePatientHealthDataVariables) => {
      const { data, ok } = await createPatientHealthData(patientId.toString(), healthData)
      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          'La création des informations médicales du patient a échoué',
        )
      }
      return null
    },
    {
      onSuccess: (_, { patientId }) => {
        valid(`Les informations médicales du patient ont bien été sauvegardées`)
        queryClient.invalidateQueries(patientHealthDataKeys.detail(patientId))
      },
    },
  )
  return mutate
}
