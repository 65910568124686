import { useMutation, useQueryClient } from '@tanstack/react-query'
import { putUser } from '../../../../data/admin'
import { LightUser, User } from '../../../../model/User'
import { UserFriendlyError } from '../../../../model/Error'
import { addValid } from '../../../../store/message'
import { useActionDispatch } from '../../../utils'
import { PaginatedList } from '../../../../model/Pagination'
import { userKeys } from './users.keys'

interface UserMutateVariables {
  userId: string
  variables: Partial<User>
}

export const usePutUser = () => {
  const valid = useActionDispatch(addValid)
  const queryClient = useQueryClient()

  return useMutation<User, Error, UserMutateVariables>(
    async ({ userId, variables }) => {
      const { data, ok } = await putUser(userId, variables)
      if (!data || !ok) {
        throw new UserFriendlyError('error', "La mise à jour de l'utilisateur a échoué")
      }
      return data
    },
    {
      onSuccess: (data) => {
        valid('Utilisateur mis à jour')
        queryClient.refetchQueries<PaginatedList<LightUser>>({
          queryKey: userKeys.all,
          refetchPage: (page) => page.items.some(({ id }) => id === data.id),
        })
        queryClient.setQueryData(userKeys.detail(data.id), data)
      },
    },
  )
}
