import React, { createElement, FunctionComponent, useMemo } from 'react'
import { RenderVariableComponentProps } from './Variable.model'
import { VariableWrapper } from './VariableWrapper.component'

const RenderVariable: FunctionComponent<RenderVariableComponentProps<any>> = ({
  customTheme,
  displayMode,
  handleClick,
  marks,
  mode,
  renderAttributes,
  valueProps,
  valueRenderer,
  variableTitle,
  variableContext,
  fixedValue,
  fallbackValue,
  fallbackTheme,
  isUpToDate,
}) => {
  const [content, isFallbackActive] = useMemo<[any | null, boolean]>(() => {
    if (!!fixedValue && !!fixedValue.content && fixedValue.renderer === valueRenderer.displayName) {
      // Fixed Value présente
      const content = JSON.parse(fixedValue.content)
      return [content, fixedValue.isFallback]
    } else {
      // Pas de fixed value
      const applyFallback = !valueProps
      return [applyFallback ? fallbackValue : valueProps, applyFallback]
    }
  }, [fixedValue, valueProps, valueRenderer, fallbackValue])

  return (
    <VariableWrapper
      onMouseUp={handleClick} // Utilisation de "onMouseUp" plutot que "onClick" pour ne pas perdre la selection
      mode={mode}
      displayMode={isFallbackActive && mode === 'print' ? 'none' : displayMode}
      title={variableTitle}
      customTheme={isFallbackActive ? fallbackTheme : customTheme}
      marks={marks}
      isFocused={renderAttributes.isFocused}
      isUpToDate={isUpToDate}
    >
      {createElement(valueRenderer, { mode, variableContext, ...content })}
    </VariableWrapper>
  )
}

export default RenderVariable
