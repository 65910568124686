import React, { FunctionComponent, useState } from 'react'
import { Spring } from 'react-spring/renderprops'
import classNames from 'classnames'
import { getCssVariable } from '../../../design-system/utils'
import { Icon } from '../Icon/Icon.component'
import { AccordionItemProps } from './AccordionItem.model'
import { addTestIdSuffix } from '../../../misc/testId.utilities'
import styles from './AccordionItem.module.scss'

const cx = classNames.bind(styles)

export const AccordionItem: FunctionComponent<AccordionItemProps> = ({
  testId,
  renderLabel,
  children,
  open = true,
  onOpenClose,
  overflow,
  theme = 'default',
  displayToggleButton = false,
}) => {
  const [preventMountAnimation, setpreventMountAnimation] = useState(open)

  return (
    <div className={styles[theme]} data-test-id={addTestIdSuffix('root', testId)}>
      <div
        className={`${styles.header} ${onOpenClose && styles.closable}`}
        onClick={(e) => onOpenClose && onOpenClose()}
        data-test-id={addTestIdSuffix('header', testId)}
      >
        <div className={styles.labelZone}>{renderLabel()}</div>
        {(onOpenClose || displayToggleButton) && (
          <div className={styles.iconZone}>
            <Icon
              icon="chevron"
              rotate={open ? 0 : 270}
              color={getCssVariable('shades', 'shade2')}
              size="nano"
            />
          </div>
        )}
      </div>
      <Spring
        from={{
          height: open ? 0 : 'auto',
          overflow: 'hidden',
        }}
        to={{
          height: open ? 'auto' : 0,
          overflow: 'hidden',
        }}
        after={{
          overflow: open && overflow ? 'visible' : 'hidden',
        }}
        immediate={preventMountAnimation}
        onRest={() => setpreventMountAnimation(false)}
      >
        {(springProps) => (
          <div
            className={cx(styles.content, { [styles.filled]: theme === 'filled' })}
            style={springProps}
            data-test-id={addTestIdSuffix('content', testId)}
          >
            {theme === 'filled' ? (
              <div className={styles.filledContentWrapper}>{children}</div>
            ) : (
              children
            )}
          </div>
        )}
      </Spring>
    </div>
  )
}
