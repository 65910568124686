import React, { FunctionComponent } from 'react'
import { Icon } from '../../../shared'
import styles from './informationAlert.module.scss'
import classNames from 'classnames'
import {
  InformationAlertIcon,
  InformationAlertIconColor,
  InformationAlertProps,
} from './informationAlert.model'

export type MessagesTypes = 'error' | 'warn' | 'info' | 'success'

export const InformationAlert: FunctionComponent<InformationAlertProps> = ({
  type = 'info',
  children,
}) => {
  const icon = InformationAlertIcon[type]
  const iconColor = InformationAlertIconColor[type]

  return (
    <div className={classNames(styles.container, styles[type])}>
      <div className={styles.icon}>
        <Icon size="micro" icon={icon} color={iconColor} />
      </div>
      <div className={styles.message}>{children}</div>
    </div>
  )
}
