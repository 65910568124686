import { connect } from 'react-redux'
import { RootState } from '../../store'
import { enabledFeaturesPreferencesSelector } from '../../store/domain/me'
import { domainMailActions } from '../../store/domain/mail'
import {
  inUseMailAddressSelector,
  mailAddressListSelector,
  requestMailsStatus,
  mailListSelector,
  fetchingNextMailsStatusSelector,
  mailSearchSelector,
} from '../../store/domain/mail/mail.selector'
import { bottomPanelMailEditorActions } from '../../store/ui/bottomPanelMailEditor'
import MailLayout from './MailLayout.component'
import { MailLayoutDispatchProps, MailLayoutStoreProps } from './MailLayout.model'

const mapStateToProps = (state: RootState): MailLayoutStoreProps => ({
  mailAddressList: mailAddressListSelector(state),
  inUseMailAddress: inUseMailAddressSelector(state),
  mailList: mailListSelector(state),
  requestMailsStatus: requestMailsStatus(state),
  fetchingNextMailsStatus: fetchingNextMailsStatusSelector(state),
  enabledFeatures: enabledFeaturesPreferencesSelector(state),
  search: mailSearchSelector(state),
})

const mapDispatchToProps: MailLayoutDispatchProps = {
  getMailAddressList: domainMailActions.getMailAddressList,
  setInUseMailAddress: domainMailActions.setInUseMailAddress,
  getMailList: domainMailActions.getMailList,
  clearMailList: domainMailActions.clearMailList,
  openMailEditor: bottomPanelMailEditorActions.openEditor,
  setSearch: domainMailActions.setMailSearch,
  reloadMailList: domainMailActions.reloadMailList,
  disconnect: domainMailActions.disconnect,
}

export default connect(mapStateToProps, mapDispatchToProps)(MailLayout)
