import { FC, useMemo, useState } from 'react'
import { RoundedButton, ValidationModal, AlertBannerItem } from '../../../../shared'
import {
  computeMissingPrescriptionVariables,
  isPrescription,
} from '../../../../../misc/documents.utilities'
import { isDefined } from '../../../../../misc/functions.utilities'
import {
  DocumentAlertSeverity,
  SeverityColor,
  SeverityIcon,
  SeverityNames,
} from '../../../../../model/DocumentAlerts'
import { DocumentInstanceBannersProps } from './DocumentInstanceBanners.model'
import LogoVidal from '../../../../../assets/images/logo-vidal-white.png'
import { formatFr } from '../../../../../misc/date.utilities'
import { mapSeverityToTagColor } from '../../../../document/DocumentAlertsPanel/DocumentAlertListItem/DocumentAlertItem.model'
import { SelectedContentType } from '../../../../../store/ui/medicalEvents/medicalEventContent'
import { useGetAllDocumentLayouts } from '../../../../../hooks/queries/documentLayouts'

export const DocumentInstanceBanners: FC<DocumentInstanceBannersProps> = ({
  document,
  isEventLocked,
  selectedContent,
  alerts,
  vidalInfos,
  alertSeverityLabels,
  onUnlockDocument,
  onOpenAlertsPanel,
}) => {
  const [openDocumentLockModal, setOpenDocumentLockModal] = useState(false)
  const { documentLayoutList } = useGetAllDocumentLayouts()

  const alertSummary = useMemo(() => {
    const countByType = alerts.reduce(
      (acc, alert) => {
        acc[alert.severityLevel]++
        return acc
      },
      [0, 0, 0, 0, 0],
    )

    const countsLabel = countByType
      .map((severityCount, index) =>
        severityCount === 0 ? undefined : `${severityCount} "${SeverityNames[index]}"`,
      )
      .filter(isDefined)

    return `Présence de ${countsLabel.reverse().join(', ')}. Cliquez ici pour les consulter.`
  }, [alerts])

  const alertsSeverity = useMemo(
    () =>
      alertSeverityLabels
        .filter((label) => label.topSeverityLevel)
        .sort((labelA, labelB) => labelB.topSeverityLevel - labelA.topSeverityLevel)
        .map((label) => ({
          label: label.code,
          color: mapSeverityToTagColor[label.topSeverityLevel],
          content: label.label,
        })),
    [alertSeverityLabels],
  )

  const missingPrescriptionVariables = useMemo(() => {
    const selectedLayout = documentLayoutList.find(({ id }) => document.documentLayoutId === id)
    return computeMissingPrescriptionVariables(document, selectedLayout)
  }, [document, documentLayoutList])

  const hasBeenPrinted = document.printedAt && isPrescription(document.category.id)
  const hasBeenRenewed = document.renewedAt && isPrescription(document.category.id)
  const topAlertSeverity = alerts[0]?.severityLevel ?? DocumentAlertSeverity.info

  return (
    <>
      {document.locked && hasBeenPrinted && (
        <AlertBannerItem
          code="hasBeenPrinted"
          title="Cette ordonnance a déjà été imprimée, vous ne pouvez pas la déverrouiller."
          icon="infoCircle"
          color="blue"
        />
      )}
      {document.locked && !hasBeenPrinted && (
        <AlertBannerItem
          code="documentLocked"
          title={hasBeenRenewed ? 'Cette ordonnance est verrouillée' : 'Ce document est verrouillé'}
          text={
            hasBeenRenewed
              ? 'Cette ordonnance ayant été renouvelée, son verrouillage est définitif'
              : "Son contenu, incluant les valeurs de ses variables, sont non modifiables et figées. Vous pouvez toujours déverrouiler le document si l'événement médical n'est pas verrouillé."
          }
          color="gray"
          icon="lock"
          actionButton={
            !hasBeenRenewed &&
            !isEventLocked && (
              <RoundedButton
                theme="transparent"
                label="Déverrouiller"
                onClick={() => setOpenDocumentLockModal(true)}
              />
            )
          }
        />
      )}
      <ValidationModal
        display={openDocumentLockModal}
        icon="lock"
        confirmLabel="Valider"
        title={
          document.locked
            ? 'Voulez vous vraiment déverrouiller ce document ? Son contenu ainsi que les valeurs de ses variables seront éditables et mises à jour avec les nouvelles données du patient'
            : 'Voulez vous vraiment verrouiller ce document ? Son contenu ainsi que les valeurs de ses variables seront figés'
        }
        onSubmit={onUnlockDocument}
        onClose={() => setOpenDocumentLockModal(false)}
      />
      {selectedContent === SelectedContentType.EDITOR && alerts.length > 0 && (
        <AlertBannerItem
          code="documentAlert"
          title="La prescription comporte des signaux et/ou alertes d'aide à la prescription"
          text={alertSummary}
          color={SeverityColor[topAlertSeverity]}
          icon={SeverityIcon[topAlertSeverity]}
          actionButton={
            <RoundedButton theme="transparent" label="Consulter" onClick={onOpenAlertsPanel} />
          }
          info={
            vidalInfos && (
              <div className="text-left text-xs">
                <img src={LogoVidal} alt="Logo vidal" width={100} className="mb-2" />
                <p>
                  Version Vidal: {vidalInfos.version}
                  <br />
                  Version de sécurisation: {vidalInfos.securisationVersion}
                  <br />
                  Dernière mise à jour: {formatFr(new Date(vidalInfos.updatedAt), 'dd/MM/yyyy')}
                  <br />
                  Fin de licence:{' '}
                  {vidalInfos.licenseExpirationDate
                    ? formatFr(new Date(vidalInfos.licenseExpirationDate), 'dd/MM/yyyy')
                    : "Pas d'expiration"}
                </p>
              </div>
            )
          }
          tags={alertsSeverity}
        />
      )}
      {missingPrescriptionVariables.length > 0 && (
        <AlertBannerItem
          code="missingVariables"
          icon="alert"
          color="red"
          title="Certaines variables sont obligatoires dans une ordonnance. Les variables suivantes sont manquantes :"
          text={missingPrescriptionVariables.join(', ')}
        />
      )}
    </>
  )
}
