import { takeEvery, call, put, select } from 'redux-saga/effects'
import { fetchDocumentAlerts, fetchDocumentAlertTypes } from './api'
import { documentAlertsDomainActions } from './documentAlerts.actions'
import { ApiResponse } from 'apisauce'
import {
  DocumentAlert,
  DocumentAlertsFilters,
  DocumentAlertsSeverityLabel,
  DocumentAlertsTypes,
} from '../../../model/DocumentAlerts'
import { addError } from '../../message'
import { inUseMedicalEventDocumentSelector } from '../../ui/medicalEvents/medicalEventDocumentInstances'
import { DocumentVidalInfos, DocumentInstance } from '../../../model/DocumentInstance'
import { DocumentAlertsDomainActionTypes } from './documentAlerts.model'
import { isDefined } from '../../../misc/functions.utilities'

function* fetchDocumentAlertsWorker({
  filters,
}: ReturnType<typeof documentAlertsDomainActions.getAlerts>) {
  const inUseDocument: DocumentInstance | null = yield select(inUseMedicalEventDocumentSelector)
  if (
    isDefined(inUseDocument) &&
    inUseDocument.type === 'farte' &&
    inUseDocument.prescriptions.length > 0
  ) {
    const response: ApiResponse<{
      items: DocumentAlert[]
      availableFilters: DocumentAlertsFilters
      vidalInfos: DocumentVidalInfos
      severityLabels: DocumentAlertsSeverityLabel[]
    }> = yield call(fetchDocumentAlerts, inUseDocument.id, filters)
    if (response.ok && response.data) {
      yield put(documentAlertsDomainActions.setAlerts(response.data.items))
      yield put(documentAlertsDomainActions.setAvailableFilters(response.data.availableFilters))
      yield put(documentAlertsDomainActions.setVidalInfos(response.data.vidalInfos))
      yield put(documentAlertsDomainActions.setSeverityLabels(response.data.severityLabels))
    } else {
      yield put(
        addError(
          "Impossible d'obtenir les aides à la prescription",
          "Une erreur s'est produite lors de la récupération des informations d'aide à la prescription",
        ),
      )
    }
  } else {
    yield put(documentAlertsDomainActions.clearAlerts())
    yield put(documentAlertsDomainActions.setAvailableFilters(null))
  }
}

function* fetchDocumentAlertsWatcher() {
  yield takeEvery([DocumentAlertsDomainActionTypes.GET], fetchDocumentAlertsWorker)
}

function* getDocumentAlertsTypesWorker() {
  const response: ApiResponse<DocumentAlertsTypes> = yield call(fetchDocumentAlertTypes)
  if (response.ok && isDefined(response.data)) {
    yield put(documentAlertsDomainActions.setTypes(response.data))
  }
}
function* getDocumentAlertsTypesWatcher() {
  yield takeEvery([DocumentAlertsDomainActionTypes.GET_TYPES], getDocumentAlertsTypesWorker)
}

export const domainDocumentAlertsSagas = {
  fetchDocumentAlertsWatcher,
  getDocumentAlertsTypesWatcher,
}
