import { FC, Suspense, lazy } from 'react'
import { Loader } from '../../shared'
import { DatamatrixReaderProps } from './DatamatrixReader.model'

const LazyDatamatrixReader = lazy(() =>
  import(/* webpackChunkName: "datamatrix" */ './DatamatrixReader.component').then(
    ({ DatamatrixReader }) => ({
      default: DatamatrixReader,
    }),
  ),
)

const LazyLoadedDatamatrixReader: FC<DatamatrixReaderProps> = (props) => (
  <Suspense fallback={<Loader />}>
    <LazyDatamatrixReader {...props} />
  </Suspense>
)

export { LazyLoadedDatamatrixReader as DatamatrixReader }
