import React, { FunctionComponent } from 'react'
import classNames from 'classnames/bind'

import { LabelProps } from './Label.model'

import styles from './Label.module.scss'
const cx = classNames.bind(styles)

export const Label: FunctionComponent<LabelProps> = ({ theme, title, testId, children }) => (
  <div title={title} className={cx(styles.labelContainer, { [theme]: true })}>
    <span className={styles.text} data-test-id={testId}>
      {children}
    </span>
  </div>
)
