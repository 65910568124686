import { ToolbarElementProps } from '@/model/Toolbar'
import React, { FC } from 'react'
import { BlockSpacingSelect } from './BlockSpacingSelect'
import { LineHeightSelect } from './LineHeightSelect'
import styles from './HeightGroup.module.scss'

export const HeightGroup: FC<ToolbarElementProps> = ({ editor }) => {
  return (
    <>
      <div className={styles['select']}>
        <LineHeightSelect editor={editor} />
      </div>
      <div className={styles['select']}>
        <BlockSpacingSelect editor={editor} />
      </div>
    </>
  )
}
