import { MedicalEventDocumentType } from '../../../model/MedicalEvent'
import { getFullName } from '../../../misc/user.utilities'
import { User } from '../../../model/User'

export const generateOwnerCreateNameTitle = (
  ownerName: string | undefined | null,
  creatorName: string | undefined | null,
) => {
  if (ownerName && creatorName) {
    return `Propriétaire: ${ownerName} - Créateur: ${creatorName}`
  } else if (ownerName && !creatorName) {
    return `Propriétaire: ${ownerName}`
  } else if (creatorName && !ownerName) {
    return `Créateur: ${creatorName}`
  }

  return undefined
}

export const extractOwnerOrCreatorNameFromFwDocumentFile = (
  selectedMedicalEventDocument,
  currentUser: User | null,
) => {
  if (!selectedMedicalEventDocument || !currentUser) {
    return { creatorName: null, ownerName: null }
  }

  const documentTypeMatch =
    selectedMedicalEventDocument.type === MedicalEventDocumentType.FW_DOCUMENT ||
    selectedMedicalEventDocument.type === MedicalEventDocumentType.FILE

  const creatorName = documentTypeMatch
    ? getFullName(selectedMedicalEventDocument.item.creator, true)
    : null
  const ownerName = documentTypeMatch
    ? getFullName(selectedMedicalEventDocument.item.owner, true)
    : null

  return { creatorName, ownerName }
}
