import { List, Map } from 'immutable'
import {
  Block,
  BlockJSON,
  Editor,
  Document,
  Inline,
  InlineJSON,
  Node,
  NodeJSON,
  Path,
  Text,
  Value,
  ValueJSON,
  Mark,
} from 'slate'
import { Editor as ReactEditor } from 'slate-react'
import { VariableType, VariablesData } from '../WithVariableEditor'
import { FormatingPluginMode } from './FormatingPlugin'
import { ToolbarConfig, FontSize, FontFamily } from './FormatingPlugin/Toolbar/Toolbar.model'
import {
  EditorValue,
  EditorValueJSON,
  InvalidEditorEmptyJsonValue,
  JSONValue,
  MarkType,
  NodeType,
  schema,
  VariableFixedValue,
  VariableInsertionPosition,
  VariableModel,
  VariableProperties,
} from './model/TextEditor.model'
import { isEqual } from 'lodash'

export function isEmptyEditor(content: ValueJSON | null | undefined) {
  if (content === undefined || content === null || content === InvalidEditorEmptyJsonValue) {
    return true
  } else {
    if (content.document && content.document.nodes && content.document.nodes.length > 0) {
      if (content.document.nodes.length === 1) {
        const [block] = content.document.nodes
        if (block.object === 'block') {
          return (block.nodes || []).every((node) => node.object === 'text' && node.text === '')
        } else {
          return false
        }
      } else {
        return false
      }
    } else {
      return true
    }
  }
}

export function valueToJSON(value: Value) {
  return value.toJSON()
}

export function JSONToValue(json: ValueJSON) {
  return Value.fromJSON(json)
}

function DOM_findDomNodeByKey(slateKey: string) {
  return window.document.querySelector(`[data-key='${slateKey}']`)
}

export function DOM_getTextByNodeKey(slateKey: string) {
  const element = DOM_findDomNodeByKey(slateKey)
  if (element) {
    return element.textContent || ''
  }
  return ''
}

export function DOM_setSelectionRangeBySlateKey(slateKey: string) {
  const element = DOM_findDomNodeByKey(slateKey)
  if (element) {
    const range = window.document.createRange()
    range.selectNode(element)
    const selection = window.getSelection()
    if (selection) {
      selection.removeAllRanges()
      selection.addRange(range)
    }
  }
}

/**
 * Return `true` if the node can have child or data
 */
function isParentableNode(node: NodeJSON): node is BlockJSON | InlineJSON {
  return node.object === 'block' || node.object === 'inline'
}

function listNodeOfTypeInNodes(nodes: NodeJSON[], objectType: string): List<NodeJSON> {
  let result = List<NodeJSON>([])

  for (let node of nodes) {
    if (node.object && isParentableNode(node) && node.type === objectType) {
      result = result.push(node)
    }
    if (
      (node.object === 'block' || node.object === 'document' || node.object === 'inline') &&
      node.nodes
    ) {
      let subResults = listNodeOfTypeInNodes(node.nodes, objectType)

      result = result.push(...subResults.toArray())
    }
  }
  return result
}

export function listNodeOfType(slateValue: ValueJSON, objectType: string): NodeJSON[] {
  if (!slateValue.document || !slateValue.document.nodes) {
    return []
  }
  const nodes = slateValue.document.nodes

  return listNodeOfTypeInNodes(nodes, objectType).toArray()
}

export function getNodeData(node: NodeJSON) {
  return isParentableNode(node) ? node.data : undefined
}

function isVariablePropertiesGuard(
  maybe: Record<string, any> | undefined,
): maybe is VariableProperties {
  return maybe !== undefined && typeof maybe.idvariable === 'string'
}

export function listVariables(
  slateValue: ValueJSON,
  variablesData?: VariablesData,
): VariableModel[] {
  const variablesNodes = listNodeOfType(slateValue, NodeType.VARIABLE)
  return variablesNodes
    .map(getNodeData)
    .filter(isVariablePropertiesGuard)
    .map(({ idvariable, variableContext = {} }) => ({
      id: idvariable,
      value: variablesData ? variablesData[idvariable] : undefined,
      context: variableContext,
    }))
}

export function insertVariable(
  value: EditorValue,
  {
    variableId,
    variableContext = {},
  }: { variableId: string; variableContext?: Record<string, JSONValue> },
  position?: VariableInsertionPosition,
  relativeNode?: Node,
) {
  const editor = new Editor({ value, plugins: [{ schema }] })

  if (hasInline(value, NodeType.VARIABLE) || hasInline(value, NodeType.VARIABLE)) {
    editor.moveForward()
  }

  if (position === VariableInsertionPosition.START) {
    if (relativeNode) {
      editor.moveToStartOfNode(relativeNode).insertBlock(NodeType.PARAGRAPH)
    } else {
      editor.moveToStartOfDocument().insertBlock(NodeType.PARAGRAPH)
    }
  }
  if (position === VariableInsertionPosition.END) {
    if (relativeNode) {
      editor.moveToEndOfNode(relativeNode).insertBlock(NodeType.PARAGRAPH)
    } else {
      editor.moveToEndOfDocument().insertBlock(NodeType.PARAGRAPH)
    }
  }

  editor
    .insertInline({
      type: NodeType.VARIABLE,
      data: {
        idvariable: variableId,
        variableContext,
      },
    })
    // On se positionne après la variable insérée pour permettre sa sélection
    .moveForward()
  return editor.value
}

function computeVariableFixedValue(variable: VariableType): VariableFixedValue | null {
  if (variable.value === null && variable.fallbackValue === null) return null

  switch (variable.type) {
    case 'render':
      return {
        content: JSON.stringify(variable.value.props ?? variable.fallbackValue),
        renderer: variable.value.renderer.displayName,
        isFallback: variable.value.props === null,
      }
    case 'slate':
      const slateValue = variable.value ?? variable.fallbackValue
      if (!slateValue) break
      return {
        content: JSON.stringify(valueToJSON(slateValue)),
        isFallback: variable.value === null,
      }
    case 'normal':
      const normalValue = variable.value ?? variable.fallbackValue
      if (!normalValue) break
      return {
        content: normalValue,
        isFallback: variable.value === null,
      }
  }
  return null
}

export function updateVariableFixedValue(
  value: EditorValue,
  slateKey: string,
  variableId: string,
  variableValue: VariableType | undefined,
) {
  if (!variableValue) return value

  const fixedValue = computeVariableFixedValue(variableValue)
  if (!fixedValue) return value

  const editor = new Editor({ value, plugins: [{ schema }] })
  const data = findVariableData(editor, slateKey)

  editor.setNodeByKey(slateKey, {
    type: NodeType.VARIABLE,
    data: {
      ...data,
      idvariable: variableId,
      fixedValue,
    },
  })

  return editor.value
}

export function findVariable(value: Value, variableId: string) {
  return value.document
    .getInlinesByType('variable')
    .find((node) => node?.data.get('idvariable') === variableId)
}

export function getFixedValue(node: Inline) {
  const variableFixedValue: VariableFixedValue | null = node.data.get('fixedValue', null)
  return variableFixedValue
}

export function isFixedValueUpToDate(
  variableData: VariableType,
  fixedValue: VariableFixedValue | null,
) {
  if (fixedValue === null || !variableData) {
    return true
  }

  const isFallbackActive = !!fixedValue?.isFallback
  if (isFallbackActive) {
    if (variableData.type === 'render') {
      return variableData.value.props === null
    }
    return variableData.value === null
  }

  switch (variableData.type) {
    case 'slate':
      if (variableData.value !== null) {
        const parsed = JSON.parse(fixedValue.content)
        return isEqual(parsed, valueToJSON(variableData.value))
      } else {
        return variableData.value === fixedValue.content
      }
    case 'render':
      const val = variableData.value.props
      const fixed = JSON.parse(fixedValue.content)
      return isEqual(val, fixed)
    case 'normal':
      return fixedValue.content === variableData.value
  }

  return true
}

export const removeFixedValuesFromVariables = (value: EditorValue) => {
  const patchedEditor = new Editor({
    value,
  })

  const variableNodes = value.document.getInlinesByType('variable').toArray()
  variableNodes.reduce((editorAcc, node) => {
    const { fixedValue, ...nodeData } = node.data.toObject()
    if (fixedValue) {
      editorAcc.setNodeByKey(node.key, {
        ...node,
        data: nodeData,
      })
    }

    return editorAcc
  }, patchedEditor)

  return patchedEditor.value
}

/**
 * Met à jour la fixed value de toutes les variables présentes dans le document.
 * @param force Si true, fixe une nouvelle valeur aux variables en ayant déjà une. Sinon met à jour uniquement les variables sans valeur fixée.
 * @return Un document dont les variables ont toute une fixed value
 */
export const updateAllFixedVariables = (
  template: Value,
  variablesData: VariablesData,
  force = false,
) => {
  const variableNodes = template.document.getInlinesByType('variable').toArray()

  const updatedTemplate = variableNodes.reduce((template, node) => {
    const nodeData = node.data.toObject()
    if (!isVariablePropertiesGuard(nodeData)) {
      return template
    }

    const fixedValue = getFixedValue(node)
    if (fixedValue && !force) {
      return template
    }

    const variableId = nodeData.idvariable
    const variableData = variablesData[variableId]
    if (!variableData.enableFixedValue) {
      return template
    }

    return updateVariableFixedValue(template, node.key, variableId, variableData)
  }, template)

  return updatedTemplate
}

export function removeVariable(value: Value, variableId: string, removeBlock = false) {
  const editor = new Editor({ value, plugins: [{ schema }] })
  const nodeKey = findVariable(value, variableId).key
  if (removeBlock) {
    const nodeParentKey = value.document.getParent(nodeKey)?.key
    editor.removeNodeByKey(nodeParentKey ?? nodeKey)
  } else {
    editor.removeNodeByKey(nodeKey)
  }
  return editor.value
}

export function insertText(value: EditorValue, text: string) {
  const editor = new Editor({ value })
  editor.insertText(text)
  return editor.value
}

export function insertImage(value: EditorValue, url: string) {
  const editor = new Editor({ value, plugins: [{ schema }] })
  editor.insertBlock({
    type: NodeType.IMAGE,
    data: {
      url,
    },
  })
  return editor.value
}

export function resizeImage(
  editor: ReactEditor,
  block: Block,
  size: { width: number; height: number },
) {
  const newBlock = mergeNodeDatas(block, { size })
  editor.setNodeByKey(block.key, newBlock)
}

function findVariableData(editor: Editor, slateKey: string) {
  // Trust me slate typins sucks
  // https://github.com/ianstormtaylor/slate/blob/master/packages/slate/src/commands/by-path.js#L802-L804
  const document = editor.value.document
  let data: Record<string, unknown> = {}
  if (document.has('assertPath') && document.has('assertNode')) {
    const assertPath: (key: string) => Path = document.get('assertPath')
    const assertNode: (path: Path) => Node = document.get('assertNode')
    const path = assertPath.call(document, slateKey)
    const node = assertNode.call(document, path)
    if (node.object === 'inline') {
      data = node.data.toJS()
    }
  }
  return data
}

export function editVariable(
  value: EditorValue,
  slateKey: string,
  {
    variableId,
    variableContext = {},
  }: { variableId: string; variableContext?: Record<string, string> },
) {
  const editor = new Editor({ value })
  const data = findVariableData(editor, slateKey)
  // Merge des propriétés du node
  editor.setNodeByKey(slateKey, {
    type: NodeType.VARIABLE,
    data: {
      ...data,
      idvariable: variableId,
      variableContext: {
        ...(typeof data.variableContext === 'object' ? data.variableContext : {}),
        ...variableContext,
      },
    },
  })
  return editor.value
}

export function replaceVariableByText(
  value: EditorValue,
  slateKey: string,
  mapper = (value: string) => value,
) {
  const editor = new Editor({ value })
  const text = DOM_getTextByNodeKey(slateKey)
  const textNode = Text.create({ text: mapper(text) })
  editor.replaceNodeByKey(slateKey, textNode)
  return editor.value
}

export function getNodeType(value: Value): string | undefined {
  const node = value.blocks.find((node) => !!node)
  return node ? node.type : undefined
}

export function hasBlock(value: Value, ...types: NodeType[]): boolean {
  return value.blocks.some((node) => {
    if (!node) {
      return false
    }

    return types.includes(node.type as NodeType)
  })
}

export function hasInline(value: Value, ...types: NodeType[]): boolean {
  return value.inlines.some((node) => {
    if (!node) {
      return false
    }

    return types.includes(node.type as NodeType)
  })
}

export function hasBlockIncludingParent(value: Value, ...types: NodeType[]): boolean {
  if (hasBlock(value, ...types)) {
    return true
  }

  const { document, blocks } = value

  if (blocks.size === 0) {
    return false
  }
  const parent = document.getParent(blocks.first().key)
  const isParentOfType =
    parent && parent.object === 'block' && types.includes(parent.type as NodeType)
  return isParentOfType || false
}

export function hasMark(value: Value, ...types: MarkType[]): boolean {
  return value.activeMarks.some((mark) => {
    if (!mark) {
      return false
    }
    return types.includes(mark.type as MarkType)
  })
}
export function hasNodeMark(node: Inline | Block, ...types: MarkType[]): boolean {
  return node.getMarks().some((mark) => {
    if (!mark) {
      return false
    }
    return types.includes(mark.type as MarkType)
  })
}

export function getNodeMark(node: Inline | Block, type: MarkType): Mark {
  return node.getMarks().find((mark) => !!mark && mark.type === type)
}

export function hasData(value: Value, key: string, data: string): boolean {
  return value.blocks.some((block) => block !== undefined && block.get('data').get(key) === data)
}

export function mergeNodeDatas(block: Block | Inline, data: any): Block {
  const originalData = block.data
  const newMapdata = Map(data)
  return block.set('data', originalData.merge(newMapdata)) as Block
}

export function addDataToBlocks(editor: ReactEditor, data: any): void {
  editor.value.blocks.forEach((block) => {
    if (block) {
      const newBlock = mergeNodeDatas(block, data)
      editor.setBlocks(newBlock)
    }
  })
}
export function getSelectionFontFamily(value: Value): FontFamily | undefined {
  const blockFontFamily: FontFamily = value.focusBlock && value.focusBlock.data.get('fontFamily')
  const selectionFontFamilyList: FontFamily[] = value.fragment
    .getMarks()
    .toArray()
    .filter((mark) => !!mark && mark.type === 'font-family')
    .map((mark) => mark.data.get('fontFamily'))
  const selectionTextList = value.fragment
    .getTexts()
    .toArray()
    .filter((node) => !!node && node.text !== '')
  if (selectionFontFamilyList.length === 0) {
    // Pas de marks on hérite de block fontFamily
    return blockFontFamily
  } else {
    const deduplicatedSelectionFontFamilyList = new Set(selectionFontFamilyList)
    // Est ce que tout les fontFamily de la séléction sont identique
    if (deduplicatedSelectionFontFamilyList.size === 1) {
      if (
        selectionFontFamilyList.length === selectionTextList.length ||
        selectionTextList.length === 0
      ) {
        // On retourne la fontFamily
        return selectionFontFamilyList[0]
      } else {
        if (selectionFontFamilyList[0] === blockFontFamily) {
          return blockFontFamily
        } else {
          // On ne peut pas déterminer la fontFamily
          return undefined
        }
      }
    } else {
      // On ne peut pas déterminer la fontFamily
      return undefined
    }
  }
}
export function getSelectionFontSize(value: Value): FontSize | undefined {
  const blockFontSize: FontSize = value.focusBlock && value.focusBlock.data.get('fontSize')
  const selectionFontSizeList: FontSize[] = value.fragment
    .getMarks()
    .toArray()
    .filter((mark) => !!mark && mark.type === 'font-size')
    .map((mark) => mark.data.get('fontSize'))
  const selectionTextList = value.fragment
    .getTexts()
    .toArray()
    .filter((node) => !!node && node.text !== '')
  if (selectionFontSizeList.length === 0) {
    // Pas de marks on hérite de block fontSize
    return blockFontSize
  } else {
    const deduplicatedSelectionFontSizeList = new Set(selectionFontSizeList)
    // Est ce que tout les fontSize de la séléction sont identique
    if (deduplicatedSelectionFontSizeList.size === 1) {
      if (
        selectionFontSizeList.length === selectionTextList.length ||
        selectionTextList.length === 0
      ) {
        // On retourne la fontSize$
        return selectionFontSizeList[0]
      } else {
        if (selectionFontSizeList[0] === blockFontSize) {
          return blockFontSize
        } else {
          // On ne peut pas déterminer la fontSize
          return undefined
        }
      }
    } else {
      // On ne peut pas déterminer la fontSize
      return undefined
    }
  }
}

export function checkToolbarConfig(config: ToolbarConfig, mode: FormatingPluginMode): void {
  // Les listes ne sont pas authorisées en mode "inline"
  if (mode === 'inline' && config.categories.list && config.categories.list.length > 0) {
    throw new Error(`Lists are note supported in "inline" mode.
    Invalid config ${JSON.stringify(config, null, 2)}`)
  }
}

export function editVariableAttribute(
  value: Value,
  variableId: string,
  attributeKey: string,
  attributeValue: string,
): Value {
  const variableNode = value.document.getInlines().find((inline) => {
    return (
      !!inline && inline.type === NodeType.VARIABLE && inline.data.get('idvariable') === variableId
    )
  })

  if (!variableNode) {
    console.warn(`No variable with id "${variableId}"`)
    return value
  }
  const blockPath = value.document.getPath(variableNode.key)
  if (!blockPath) {
    console.warn('Unable to find node with key', variableNode.key)
    return value
  }
  const newDocument = value.document.setNode(
    blockPath,
    mergeNodeDatas(variableNode, { [attributeKey]: attributeValue }),
  ) as Document
  return value.set('document', newDocument) as Value
}

function isBlockNode(node: NodeJSON): node is BlockJSON {
  return node.object === 'block'
}

function isDefined<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined
}

function filterInvalidChildNodeListItem(nodes: NodeJSON[]): NodeJSON[] {
  return nodes.filter((child) => child.object === 'block' && child.type === 'list_item')
}

function filterInvalidNodeList(nodes: NodeJSON[]): NodeJSON[] {
  return nodes
    .map((node) => {
      // Seul les nodes de type block sont analysé
      if (isBlockNode(node)) {
        // Si un node block n'a pas d'enfants
        if (node.nodes === undefined || node.nodes.length === 0) {
          return undefined
        }
        const filters = [filterInvalidNodeList]
        // On applique un filtre supplémentaire aux nodes de type list
        if (node.type === 'list' || node.type === 'numbered_list') {
          filters.push(filterInvalidChildNodeListItem)
        }
        // On applique les filtres sur les noeuds
        const filtered = filters.reduce((nodes, filter) => filter(nodes), node.nodes as NodeJSON[])
        // Si la liste des enfants filtrée devient vide on invalide le noeud courant
        if (filtered.length === 0) {
          return undefined
        }
      }
      return node
    })
    .filter(isDefined)
}

export function normalizeEditorValue(value: EditorValueJSON): EditorValueJSON {
  if (!value.document || !value.document.nodes) {
    return value
  }
  return {
    ...value,
    document: {
      ...value.document,
      nodes: filterInvalidNodeList(value.document.nodes),
    },
  }
}

export function changeVariablePosition(
  value: EditorValue,
  variableId: string,
  position?: VariableInsertionPosition,
  relativeNode?: Node,
  removeBlock?: boolean,
) {
  const removedValue = removeVariable(value, variableId, removeBlock)
  return insertVariable(removedValue, { variableId }, position, relativeNode)
}
