import { formatISO, parse } from 'date-fns'

import { customHistory } from '../../history'
import { DoctolibPatient } from './doctolib.model'
import { Patient, Sex, Title } from '../../model/Patient'
import { inputPhoneFormatter } from '../input.utilities'
import { formatLastName } from '../patient.utilities'
import { isDefined } from '../functions.utilities'
import { Address } from '../../model/Address'

const followDatetoDoctolib = (date: string): string =>
  formatISO(parse(date, 'yyyy-MM-dd', new Date()))
const doctolibDateToFollow = (date: string): string => date.substring(0, 10)

const followSexToDoctolibGenderMapper = (sex: Sex) => {
  switch (sex) {
    case Sex.FEMALE:
      return true
    case Sex.MALE:
      return false
    case Sex.UNKNOWN:
      return null
  }
}
export const followPatientFormValidation = (patient: DoctolibPatient) => {
  if (patient.last_name === null || patient.last_name === '') {
    throw new Error(`Le nom de famille n'est pas renseigné.`)
  }
  if (patient.first_name === null || patient.first_name === '') {
    throw new Error(`Le prénom n'est pas renseigné.`)
  }
  if (patient.birthdate === null) {
    throw new Error(`La date de naissance n'est pas renseignée.`)
  }
}

export const deserializeDoctolibAddress = (
  street: string | null | undefined,
  city: string | null | undefined,
  zipcode: string | null | undefined,
): Partial<Address> | undefined => {
  if (isDefined(street) || isDefined(city) || isDefined(zipcode)) {
    return {
      street: street || undefined,
      city: city || undefined,
      zipCode: zipcode || undefined,
    }
  } else {
    return undefined
  }
}

export const doctolibToFollowPatientMapper = (
  patient: DoctolibPatient,
  validateForm: boolean,
): Partial<Patient> => {
  // La validation de formulaire n'est faite qu'a l'ajout d'un patient.
  // En cas de mise à jour depuis doctolib, nom, prénom, sexe, sate de naissance ne sont jamais renvoyés.
  if (validateForm) {
    followPatientFormValidation(patient)
  }

  const {
    birthdate,
    first_name,
    last_name,
    maiden_name,
    gender,
    address,
    zipcode,
    city,
    email,
    phone_number,
    secondary_phone_number,
  } = patient

  const formatedMaidenName = maiden_name ? formatLastName(maiden_name) : null
  const formatedLastName = last_name ? formatLastName(last_name) : null
  const formatedFirstName = first_name ? formatLastName(first_name) : null

  return {
    birthDate: birthdate ? doctolibDateToFollow(birthdate) : undefined,
    birthFirstName: formatedFirstName ? formatedFirstName : undefined,
    birthLastName: formatedMaidenName || formatedLastName || undefined,
    usedLastName: formatedMaidenName ? formatedLastName : undefined,
    ...(gender !== undefined && {
      sex: gender !== null ? (gender ? Sex.FEMALE : Sex.MALE) : Sex.UNKNOWN,
      title: gender !== null ? (gender ? Title.MS : Title.MR) : null,
    }),
    email: email ? email : undefined,
    telephone1: phone_number ? inputPhoneFormatter(phone_number) : undefined,
    telephone2: secondary_phone_number ? inputPhoneFormatter(secondary_phone_number) : undefined,
    address: deserializeDoctolibAddress(address, city, zipcode) as Address,
  }
}

export const followToDoctolibPatientMapper = (patient: Patient): DoctolibPatient => {
  const {
    id,
    birthFirstName,
    birthLastName,
    usedFirstName,
    usedLastName,
    birthDate,
    sex,
    email,
    telephone1,
    telephone2,
    address,
  } = patient

  return {
    pms_id: `${id}`,
    first_name: usedFirstName || birthFirstName,
    last_name: usedLastName || birthLastName,
    birthdate: followDatetoDoctolib(birthDate),
    maiden_name: birthLastName,
    gender: followSexToDoctolibGenderMapper(sex),
    address: (address && address.street) || undefined,
    zipcode: (address && address.zipCode) || undefined,
    city: (address && address.city) || undefined,
    email: email || undefined,
    phone_number: telephone1 || undefined,
    secondary_phone_number: telephone2 || undefined,
  }
}

export const openFollowPatient = (id: number) => {
  if (
    window.confirm(`Voulez-vous être redirigé(e) vers la fiche patient demandée depuis Doctolib?`)
  ) {
    customHistory.navigate(`/patients/${id}`, { replace: true })
  }
}
