/* eslint-disable no-restricted-globals */
import { register } from 'register-service-worker'
import { NewVersionLabel } from './constants'

const serviceWorkerUrl = `${process.env.PUBLIC_URL}/service-worker.js`
export function registerServiceWorker() {
  register(serviceWorkerUrl, {
    registrationOptions: {
      scope: '/app/',
    },
    registered() {
      console.info('Service worker : Prêt')
    },
    updated(registration) {
      console.info('Service worker : Une nouvelle version est prête')
      document.dispatchEvent(new CustomEvent(NewVersionLabel, { detail: registration }))
    },
    updatefound() {
      console.info('Service worker: Mise à jour en cours')
    },
    cached() {
      console.info('Service worker : Version mise en cache')
    },
    error(error) {
      console.error(`Service worker :`, error)
    },
  })
}
