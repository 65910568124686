import { useField } from 'formik'
import { IdentityStatus } from '../../../model/Patient'
import { useContext } from 'react'
import { ConnectedUserContext } from '../../../misc/auth.utilities'
import { hasSuperAdminRole } from '../../../misc/roles.utilities'
import { isINSIValidated } from '../../../misc/patient.utilities'

export const useDisableInsiFields = () => {
  const { loggedUser } = useContext(ConnectedUserContext)
  const [identityStatusField] = useField<IdentityStatus>('identityStatus')

  if (loggedUser && !hasSuperAdminRole(loggedUser?.roles)) {
    return isINSIValidated(identityStatusField.value)
  } else {
    return false
  }
}
