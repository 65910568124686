import styled from '../../../../styles/theme/styled'
import { BorderColors, Colors, getColor } from '../../../../colors'
import { ChipProps, StyledChipProps } from './Chip.model'
import { getCssVariable } from '../../../../design-system/utils'

const fontColor = (colorPreset: ChipProps['colorPreset'], selected: boolean) => {
  if (colorPreset === 'danger')
    return selected ? getCssVariable('shades', 'white') : getCssVariable('status', 'danger')
  if (colorPreset === 'dark')
    return selected ? getCssVariable('shades', 'white') : getCssVariable('shades', 'shade2')

  return getCssVariable('shades', 'white')
}

const computeBorderColor = (
  selected: boolean,
  borderColor?: Colors,
  colorPreset?: ChipProps['colorPreset'],
) => {
  if (borderColor && !selected) {
    return borderColor
  }

  if (colorPreset === 'danger') {
    return Colors.error
  }

  return selected ? Colors.dsDark2 : Colors.backgroundBlue6
}

const computeHoverBorderColor = (colorPreset?: ChipProps['colorPreset']) => {
  if (colorPreset === 'danger') return getColor(Colors.error)
  if (colorPreset === 'light') return getColor(Colors.dsGreyBlue)
  return getColor(BorderColors.borderDark1)
}

const computeBackgroundColor = (
  colorPreset: ChipProps['colorPreset'],
  selected: boolean,
  borderColor?: Colors,
) => {
  if (colorPreset === 'light') {
    if (selected) {
      return borderColor ? getColor(borderColor) : getColor(Colors.dsDark2)
    }
    return 'transparent'
  } else if (colorPreset === 'dark') {
    if (selected) {
      return getColor(Colors.dsDark2)
    }
  } else {
    if (selected) {
      return getColor(Colors.error)
    }
  }
  return '#FFF'
}

export const StyledChip = styled.div<StyledChipProps>`
  font-family: Montserrat, sans-serif;
  letter-spacing: normal;
  font-stretch: normal;
  font-style: normal;
  font-weight: ${({ size }) => (size === 'normal' ? '500' : '600')};
  font-size: ${({ size }) => (size === 'normal' ? '1rem' : '0.75rem')};
  padding: auto;
  padding-left: 16px;
  padding-right: 16px;
  height: ${({ size }) => (size === 'normal' ? '40px' : '32px')};
  border: ${({ borderSize }) => `${borderSize}px`} ${({ dotted }) => (dotted ? 'dashed' : 'solid')};
  border-radius: 20px;
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out;
  opacity: ${({ selected, disabled }) => (disabled && !selected ? '0.5' : '1')};
  ${({ selectable, disabled }) =>
    selectable && !disabled ? 'cursor: pointer;' : 'cursor: not-allowed;'}
  color: var(${({ colorPreset, selected }) => fontColor(colorPreset, !!selected)});
  border-color: ${({ selected, borderColor, colorPreset }) =>
    getColor(computeBorderColor(!!selected, borderColor, colorPreset))};
  background-color: ${({ colorPreset, selected, borderColor }) =>
    computeBackgroundColor(colorPreset, !!selected, borderColor)};

  .chip-sublabel {
    color: var(
      ${({ selected }) =>
        selected ? getCssVariable('shades', 'shade5') : getCssVariable('shades', 'shade4')}
    );
  }

  &:hover {
    ${({ disabled, colorPreset }) =>
      !disabled && `border-color: ${computeHoverBorderColor(colorPreset)};`}
  }
`

export const StyledChipList = styled.div`
  padding: 4px;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
`
