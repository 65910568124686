import { RestuxResources } from '../../resources'
import { RestuxPagination, RestuxUiSagaConf } from '../../restux/ui/restuxUi.model'
import { uiActionsFactory } from '../../restux/ui/restuxUiActions.factory'
import { patientManualPrescriptionsPaginationSelector } from './patientManualPrescriptions.selectors'

export const patientManualPrescriptionsConfig: RestuxUiSagaConf = {
  resourceName: RestuxResources.patientManualPrescriptions,
  identifier: 'patientManualPrescriptions',
  paginationSelector: patientManualPrescriptionsPaginationSelector,
}

export interface PatientManualPrescriptionsState {
  pagination: RestuxPagination
}

export const uiActions = uiActionsFactory(patientManualPrescriptionsConfig)

export const UiPatientManualPrescriptionsActionTypes = {
  ...uiActions.types,
}
