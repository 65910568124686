import getApi from '../../api/apisauceConfig'
import { Filters } from '../../model/Filters'
import { PaginatedList } from '../../model/Pagination'
import { singleItemPageLimit } from '../../constants'
import { LightPatient } from '../../model/Patient'
import { deserializePatientPageResponse } from './mapper/patients.mapper'
import { serializeQueryFilters } from '../../misc/query.utilities'

export const getPatients = (
  page: number,
  filters?: Filters,
  signal?: AbortSignal,
  limit = singleItemPageLimit,
) =>
  getApi()
    .get<PaginatedList<LightPatient>>(
      '/patients',
      {
        page,
        limit,
        ...filters,
      },
      { signal, paramsSerializer: serializeQueryFilters },
    )
    .then(deserializePatientPageResponse)
