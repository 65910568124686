import { IconsType } from '../../../../shared'

export enum EventDocumentType {
  File = 'File',
  Document = 'Document',
}

export interface EventDocHeaderProps {
  id: number
  title: string
  icon: IconsType
  type: EventDocumentType
  isEventEditable?: boolean
  onPrint?: () => void
}
