import { EditorValue } from '@follow/farte'
import { DocumentTemplateSuggestionList } from '../../../model/DocumentTemplate'
import { DomainDocumentTemplateActionTypes } from './documentTemplates.model'

const clearDocumentTemplatesSuggestion = (medicalEventId: number) => ({
  type: DomainDocumentTemplateActionTypes.CLEAR_SUGGESTIONS,
  medicalEventId,
})

const getDocumentTemplatesSuggestion = (medicalEventId: number) => ({
  type: DomainDocumentTemplateActionTypes.GET_SUGGESTIONS,
  medicalEventId,
})

const setDocumentTemplatesSuggestion = (
  medicalEventId: number,
  suggestions: DocumentTemplateSuggestionList,
) => ({
  type: DomainDocumentTemplateActionTypes.SET_SUGGESTIONS,
  medicalEventId,
  suggestions,
})

const duplicateDocumentTemplate = (documentTemplateId: number, targetUserId?: string) => ({
  type: DomainDocumentTemplateActionTypes.DUPLICATE,
  documentTemplateId,
  targetUserId,
})

const updateDocumentTemplateValue = (
  documentTemplateId: number,
  documentTemplateValue: EditorValue,
) => ({
  type: DomainDocumentTemplateActionTypes.UPDATE_VALUE,
  documentTemplateId,
  documentTemplateValue,
})

export const domainDocumentTemplatesActions = {
  clearDocumentTemplatesSuggestion,
  getDocumentTemplatesSuggestion,
  setDocumentTemplatesSuggestion,
  duplicateDocumentTemplate,
  updateDocumentTemplateValue,
}
