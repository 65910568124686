import { call, put, takeEvery } from 'redux-saga/effects'
import { addError, addValid } from '../../message'
import { publishEphemeralNotification } from './api'
import { ephemeralNotificationsDomainActions } from './ephemeralNotification.actions'
import { domainEphemeralNotificationsActionTypes } from './ephemeralNotification.model'

function* publishEphemeralNotificationWorker({
  ephemeralNotification,
}: ReturnType<typeof ephemeralNotificationsDomainActions.publishEphemeralNotification>) {
  const response = yield call(publishEphemeralNotification, ephemeralNotification)
  if (response.ok) {
    yield put(addValid('Notification', 'La notification éphémère a bien été publiée'))
  } else {
    yield put(addError('Notification', `La notification éphémère n'a pas été publiée`))
  }
}

function* publishEphemeralNotificationWatcher() {
  yield takeEvery(
    domainEphemeralNotificationsActionTypes.PUBLISH_EPHEMERAL_NOTIFICATION,
    publishEphemeralNotificationWorker,
  )
}

export const ephemeralNotificationsSagas = {
  publishEphemeralNotificationWatcher,
}
