import getApi from '../../../../api/apisauceConfig'
import { PaginatedList } from '../../../../model/Pagination'
import { DocumentTemplate, DocumentTemplateListItem } from '../../../../model/DocumentTemplate'
import { RestuxApiConfig } from '../../../restux/cache/restuxCache.model'
import {
  deserializeDocumentTemplatePaginatedResponse,
  deserializeDocumentTemplateResponse,
  deserializeDocumentTemplateSuggestionListResponse,
  serializeDocumentTemplate,
} from './DocumentTemplates.mapper'
import {
  ApiDocumentTemplateResource,
  ApiDocumentTemplateListItemResource,
  ApiDocumentTemplateSuggestionListResource,
} from './DocumentTemplates.model'

export const cloneDocumentTemplate = (documentTemplateId: number, userId?: string) =>
  getApi()
    .post<ApiDocumentTemplateResource>(`/document_templates/clone`, {
      documentTemplateId,
      ...(userId && { userId }),
    })
    .then(deserializeDocumentTemplateResponse)

export const getDocumentTemplatesSuggestions = (medicalEventId: number) =>
  getApi()
    .get<ApiDocumentTemplateSuggestionListResource>('document_templates/suggestion', {
      medicalEventId,
    })
    .then(deserializeDocumentTemplateSuggestionListResponse)

export const restuxDocumentTemplateApiConfig: RestuxApiConfig<
  DocumentTemplateListItem,
  DocumentTemplate
> = {
  url: '/document_templates',
  getPaginatedItems: (route) =>
    getApi()
      .get<PaginatedList<ApiDocumentTemplateListItemResource>>(route)
      .then(deserializeDocumentTemplatePaginatedResponse),
  getItem: (route) =>
    getApi().get<ApiDocumentTemplateResource>(route).then(deserializeDocumentTemplateResponse),
  createItem: (route, item) =>
    getApi()
      .post<ApiDocumentTemplateResource>(route, serializeDocumentTemplate(item))
      .then(deserializeDocumentTemplateResponse),
  deleteItem: (route) => getApi().delete(route),
  updateItem: (route, item) =>
    getApi()
      .put<ApiDocumentTemplateResource>(route, serializeDocumentTemplate(item))
      .then(deserializeDocumentTemplateResponse),
}
