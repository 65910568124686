import { FC } from 'react'
import { HealthDataCode } from '@follow/cdk'
import { HealthDataModalHistory } from '../../HealthDataModalHistory'
import { HealthDataPregnancies } from '../HealthDataPregnancies'
import { HealthDataHistoryRightPanelProps } from './HealthDataHistoryRightPanel.model'
import styles from './HealthDataHistoryRightPanel.module.scss'

export const HealthDataHistoryRightPanel: FC<HealthDataHistoryRightPanelProps> = ({
  patientId,
  healthDataType,
  indexedTypes,
  indexedValues,
  onPregnancyChange,
}: HealthDataHistoryRightPanelProps) =>
  healthDataType.code === HealthDataCode.pregnancyEntity ? (
    <HealthDataPregnancies
      healthDataType={healthDataType}
      patientId={patientId}
      indexedTypes={indexedTypes}
      indexedValues={indexedValues}
      onPregnancyChange={onPregnancyChange}
    />
  ) : (
    <div className={styles.container}>
      <HealthDataModalHistory healthDataType={healthDataType} patientId={patientId} />
    </div>
  )
