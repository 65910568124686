import { createSelector } from 'reselect'

import { restuxResolvePage } from '../../restux/ui/restuxUi.selectors'
import { RootState } from '../../reducers'

/** Selector from cache branch */
function documentTemplatesDetailsResourcesSelector(state: RootState) {
  return state.cache.documentTemplates.details
}
function documentTemplatesListResourcesSelector(state: RootState) {
  return state.cache.documentTemplates.list
}

export const managerDocumentTemplatesPaginationSelector = (state: RootState) =>
  state.ui.managerDocumentTemplates.pagination

export const managerDocumentTemplateInUseId = (state: RootState) =>
  state.ui.managerDocumentTemplates.inUse.inUseId

export const managerDocumentTemplatesPageSelector = createSelector(
  [documentTemplatesListResourcesSelector, managerDocumentTemplatesPaginationSelector],
  (resources, pagination) => restuxResolvePage(resources, pagination),
)

export const managerInUseDocumentTemplateSelector = createSelector(
  [managerDocumentTemplateInUseId, documentTemplatesDetailsResourcesSelector],
  (inUseId, details) => (inUseId === null ? null : details[inUseId]),
)

export const managerLoadingInUseDocumentTemplate = (state: RootState) =>
  state.ui.managerDocumentTemplates.inUse.loadingState

export const documentTemplateListFiltersSelector = createSelector(
  [managerDocumentTemplatesPaginationSelector],
  (list) => list.filters,
)
