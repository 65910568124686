import { useDispatch } from 'react-redux'
import { useSubscribeMutation } from '../mutation'
import { patientHealthDataKeys } from '../../queries/patientHealthData/patientHealthData.keys'
import { getVariableData } from '../../../store/renderer'
import { documentAlertsDomainActions } from '../../../store/domain/documentAlerts'

export const useMutatePatientHealthData = () => {
  const dispatch = useDispatch()
  useSubscribeMutation(patientHealthDataKeys.mutation, () => {
    dispatch(getVariableData())
    dispatch(documentAlertsDomainActions.getAlerts())
  })
}
