import { patientHealthDataKeys } from './patientHealthData.keys'
import { Module } from '@follow/cdk'
import { useInfiniteQuery } from '@tanstack/react-query'
import { getPatientHealthDataHistory } from '../../../data/patientHealthData/patientHealthDataHistory/getPatientHealthDataHistory'
import { largePageLimit, startingPage } from '../../../constants'
import { UserFriendlyError } from '../../../model/Error'
import { PaginatedList } from '../../../model/Pagination'
import { HealthData } from '../../../model/HealthData'
import { useFlatPaginatedData } from '../../utils/query'

interface GetInifinitePatientHistoryHealthDataParams {
  patientId: number
  moduleDataTypeId: string
  moduleName: Module
  enabled?: boolean
  limit?: number
}

export const useGetInfinitePatientHistoryHealthData = ({
  patientId,
  moduleName,
  moduleDataTypeId,
  enabled = true,
  limit = largePageLimit,
}: GetInifinitePatientHistoryHealthDataParams) => {
  const queryKey = patientHealthDataKeys.getHistoryByPatientIdAndModuleDataTypeId(
    patientId,
    moduleDataTypeId,
  )

  const queryReturn = useInfiniteQuery<PaginatedList<HealthData> | undefined>(
    queryKey,
    async ({ pageParam = startingPage, signal }) => {
      const { data, ok } = await getPatientHealthDataHistory(
        patientId.toString(),
        moduleName,
        moduleDataTypeId,
        pageParam,
        limit,
        signal,
      )
      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          `Impossible de récupérer l'historique des informations médicales du patient`,
          `Une erreur technique s'est produite`,
        )
      }
      return data
    },
    { enabled },
  )

  const healthDataHistory = useFlatPaginatedData(queryReturn.data)

  return { query: queryReturn, healthDataHistory }
}
