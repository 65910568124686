import React, { FunctionComponent, useState } from 'react'

import { PatientTimeline } from './PatientTimeline'
import { PatientPageStatistics } from './PatientPageStatistics'

import { useCurrentPatient, useWidthObserver } from '../../../hooks/utils'
import { breakSmall } from '../../../misc/responsive'
import { IconButton } from '../../shared'
import { PatientTaskList } from './PatientTaskList'
import { isDefined } from '../../../misc/functions.utilities'

export const PatientPageContent: FunctionComponent<{}> = () => {
  const { currentPatient: patient } = useCurrentPatient()
  const isMobile = useWidthObserver(breakSmall)
  const [displayStatistic, setDisplayStatistic] = useState(false)

  if (!isDefined(patient)) {
    return null
  }

  return (
    <>
      {!isMobile && (
        <div className={`px-10 py-5 flex justify-${displayStatistic ? 'start' : 'end'}`}>
          <div
            className="grid gap-2 grid-flow-col w-max cursor-pointer"
            onClick={() => setDisplayStatistic(!displayStatistic)}
          >
            {displayStatistic && (
              <IconButton size="nano" rotate={-90} icon="arrow" theme="transparent-dark" />
            )}
            <span className="text-shades-2 text-base font-medium">
              {displayStatistic ? 'Retour à la timeline' : 'Statistiques'}
            </span>
            {!displayStatistic && (
              <IconButton size="nano" rotate={90} icon="arrow" theme="transparent-dark" />
            )}
          </div>
        </div>
      )}
      {displayStatistic ? (
        <PatientPageStatistics />
      ) : (
        <>
          <PatientTaskList patient={patient} />
          <PatientTimeline patient={patient} />
        </>
      )}
    </>
  )
}
