import { RootState } from '../../../reducers'
import { DocumentInstance } from '../../../../model/DocumentInstance'

export function inUseMedicalEventDocumentIdSelector(state: RootState) {
  return state.ui.medicalEvent.documentInstances.inUse.inUseId
}
export function inUseMedicalEventDocumentSelector(state: RootState): DocumentInstance | null {
  const inUseId = inUseMedicalEventDocumentIdSelector(state)
  const documentInstancesResources = state.cache.documentInstances.details

  return inUseId ? documentInstancesResources[inUseId] : null
}

export function isInUseMedicalEventDocumentInstanceLoadingSelector(state: RootState) {
  return state.ui.medicalEvent.documentInstances.inUse.loadingState
}
