import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { isDefined } from '../../misc/functions.utilities'

const scrollFuzzing = 1.2

type hookReturn = [
  React.RefObject<HTMLDivElement>,
  React.RefObject<HTMLDivElement>,
  number | undefined,
  (event: React.KeyboardEvent<HTMLInputElement>) => void,
]

export function useSelectArrowNavigation<T>(
  results?: T[],
  onSelect?: (result: T) => void,
): hookReturn {
  const [hoveredIndex, setHoveredIndex] = useState<number>()
  const scrollRef = useRef<HTMLDivElement>(null)
  const hoveredRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    if (scrollRef.current && hoveredRef.current && isDefined(hoveredIndex)) {
      const hoveredDiv = hoveredRef.current
      const scrollDiv = scrollRef.current

      const hoverBottomEdge = hoveredDiv.offsetTop + hoveredDiv.getBoundingClientRect().height
      const scrollBottomEdge = scrollDiv.scrollTop + scrollDiv.getBoundingClientRect().height

      if (hoveredDiv.offsetTop > scrollDiv.scrollTop && hoverBottomEdge > scrollBottomEdge) {
        // Le top border du hovered est visible, mais pas le bottom border
        // Donc je scroll vers le bas
        const scrollBottom = hoverBottomEdge - scrollBottomEdge

        scrollDiv.scrollBy({ top: scrollBottom * scrollFuzzing, behavior: 'smooth' })
      } else if (hoveredDiv.offsetTop < scrollDiv.scrollTop) {
        // Le top border n'est pas visible
        // Donc je scroll vers le haut
        const scrollTop = hoveredDiv.offsetTop - scrollDiv.scrollTop

        scrollDiv.scrollBy({ top: scrollTop * scrollFuzzing, behavior: 'smooth' })
      }
    }
  }, [hoveredIndex])

  useEffect(() => {
    if (results) {
      setHoveredIndex(undefined)
    }
  }, [results])

  const handleKeyDown = useCallback(
    ({ key }: React.KeyboardEvent<HTMLInputElement>) => {
      if (results && results.length > 0 && onSelect) {
        switch (key) {
          case 'ArrowDown':
            const upperValue = isDefined(hoveredIndex) ? hoveredIndex + 1 : 0
            setHoveredIndex(upperValue < results.length ? upperValue : hoveredIndex)
            break
          case 'ArrowUp':
            const lowerValue = isDefined(hoveredIndex) ? hoveredIndex - 1 : 0
            setHoveredIndex(lowerValue > -1 ? lowerValue : hoveredIndex)
            break
          case 'Enter':
            if (isDefined(hoveredIndex) && results[hoveredIndex]) {
              onSelect(results[hoveredIndex])
              setHoveredIndex(undefined)
              scrollRef.current?.scrollTo({ top: 0 })
            }
            break
        }
      }
    },
    [hoveredIndex, onSelect, results],
  )

  return [scrollRef, hoveredRef, hoveredIndex, handleKeyDown]
}
