import React, { FunctionComponent } from 'react'

import {
  MedicalEventContentType,
  MedicalEventContentTypeIcon,
} from '../../../../../../../model/MedicalEvent'
import { DocumentCategoryColor } from '../../../../../../../model/DocumentCategory'
import styles from './DocumentItem.module.scss'
import { Icon, IconChip } from '../../../../../../shared'
import { Colors } from '../../../../../../../colors'
import { ChipProps } from '../../../../../../shared/chip/Chip/Chip.model'

interface DocumentItemProps {
  documentId: number
  documentName: string
  documentInfos?: string
  category: MedicalEventContentType
  onClick: () => void
  colorPreset?: ChipProps['colorPreset']
}

export const DocumentItem: FunctionComponent<DocumentItemProps> = ({
  documentName,
  documentInfos,
  category,
  onClick,
  colorPreset,
}) => {
  const icon = MedicalEventContentTypeIcon[category]
  const color = DocumentCategoryColor[category]
    ? DocumentCategoryColor[category]
    : DocumentCategoryColor.other

  return (
    <div className={styles.container}>
      <IconChip
        size="normal"
        icon={icon}
        onClick={onClick}
        label={documentName}
        renderLabel={(label) => (
          <div className="inline-flex space-x-2 items-center">
            <span>{label}</span>
            {documentInfos && (
              <Icon size="micro" icon="infoCircle" color={Colors.dsDark4} title={documentInfos} />
            )}
          </div>
        )}
        borderColor={colorPreset !== 'danger' ? color : undefined}
        colorPreset={colorPreset}
      />
    </div>
  )
}
