import { useMutation } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import { UserFriendlyError } from '../../../model/Error'
import { renderPdfFromDocuments } from '../../../data/documents'
import { extractFileNameFromHeader } from '../../../misc/extractFilename.utilities'
import { domainDocumentInstancesActions } from '../../../store/domain/documentInstances'
import { RenderableDocument } from '../../../data/documents/mapper/documents.model'
import { FwTrackingEvent, TrackingService } from '../../../misc/Tracking'
import { DocumentFormat } from '../../../model/Document'

export type RenderableDocumentsVariable = {
  documents: RenderableDocument[]
  documentsToLock?: number[]
  format?: DocumentFormat
  fileName?: string | null
}
export type RenderedPdf = {
  blob: Blob
  fileName: string | null
}

export const useRenderPdfFromDocuments = ({
  onSuccess,
}: {
  // Trigger à chaque mutation en succès contrairement au callback dans le mutate qui n'est trigger que sur la dernière mutation
  // https://tanstack.com/query/v4/docs/framework/react/guides/mutations#consecutive-mutations
  onSuccess?: (renderedPdf: RenderedPdf) => void
} = {}) => {
  const dispatch = useDispatch()

  const { mutate: render, data: renderedPdf } = useMutation<
    RenderedPdf,
    Error,
    RenderableDocumentsVariable
  >(
    async ({
      documents,
      documentsToLock,
      format,
      fileName = null,
    }: RenderableDocumentsVariable): Promise<RenderedPdf> => {
      const { ok, data: blob, headers, duration } = await renderPdfFromDocuments(documents, format)
      TrackingService.sendEvent(FwTrackingEvent.RENDER_DOCUMENTS, {
        duration,
        success: ok,
        count: documents.length,
      })

      if (!ok || !blob) {
        throw new UserFriendlyError(
          'error',
          'Impossible de générer le(s) document(s) sélectionné(s)',
        )
      }

      if (documentsToLock?.length) {
        dispatch(domainDocumentInstancesActions.lockMultipleDocuments(documentsToLock, true))
      }

      return { blob, fileName: fileName || extractFileNameFromHeader(headers) }
    },
    {
      onSuccess,
    },
  )

  return { render, renderedPdf }
}
