import React, { FunctionComponent, useMemo } from 'react'

import { PatientHealthDataItem } from './PatientHealthDataItem'
import { PatientInfosContainer } from '../PatientInfosContainer'

import { PatientHealthDataProps } from './PatientHealthData.model'
import { getLatestHealthDatas } from '../../../../misc/healthData.utilities'

export const PatientHealthData: FunctionComponent<PatientHealthDataProps> = ({ list, types }) => {
  const filteredList = useMemo(
    () =>
      getLatestHealthDatas(list).sort((left, right) => {
        const leftWeight = types.find((type) => left.moduleDataType.id === type.id)?.weight ?? 0
        const rightWeight = types.find((type) => right.moduleDataType.id === type.id)?.weight ?? 0
        return leftWeight - rightWeight
      }),
    [list, types],
  )

  return (
    <PatientInfosContainer>
      {filteredList.map((item) => (
        <PatientHealthDataItem key={item.id} {...item} />
      ))}
    </PatientInfosContainer>
  )
}
