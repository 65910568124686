import styled from '@emotion/styled'
import { layoutColor } from '../Image.styled'

const square = (dim: string) => `width: ${dim}; height: ${dim}`

interface CornerProps {
  top?: boolean
  bottom?: boolean
  left?: boolean
  right?: boolean
  small?: boolean
}

export const Corner = styled.div<CornerProps>`
  ${({ top }) => top && 'top: -2px;'}
  ${({ right }) => right && 'right: -4px;'}
  ${({ bottom }) => bottom && 'bottom: -4px;'}
  ${({ left }) => left && 'left: -2px;'}
  ${({ small }) => (small ? square('10px') : square('15px'))};
  background-color: ${layoutColor};
  position: absolute;
`
