import { FunctionComponent, useCallback, useContext, useMemo } from 'react'
import { AlertBanner, RoundedButton } from '../../shared'
import { AlertSeverityColor, AlertSeverityIcon } from '../../../model/Alerts'
import {
  AlertsCode,
  AlertsTextMapping,
  AlertsTitleMapping,
  HealthDataType,
} from '../../../model/HealthData'
import { PatientLayoutContext } from '../../layout/PatientLayout/PatientLayout.context'
import { useCurrentPatient } from '../../../hooks/utils'
import {
  useGetPatientAlerts,
  useGetPatientHealthDataTypes,
  useCreatePatientHealthData,
} from '../../../hooks/queries/patientHealthData'
import { isDefined } from '../../../misc/functions.utilities'

export const PatientAlertsBanner: FunctionComponent = () => {
  const { currentPatient: patient } = useCurrentPatient()
  const { patientAlertList: alerts } = useGetPatientAlerts({
    patientId: patient?.id as number,
    enabled: isDefined(patient?.id),
  })
  const { healthDataTypes } = useGetPatientHealthDataTypes()
  const { mutate: createHealthData } = useCreatePatientHealthData()

  const { ignoredAlertCodes, setIgnoredAlertCodes } = useContext(PatientLayoutContext)

  const [breastfeeding, pregnancy] = useMemo(
    () => [
      healthDataTypes.find((type) => type.code === 'breastfeeding'),
      healthDataTypes.find((type) => type.code === 'pregnancy'),
    ],
    [healthDataTypes],
  )

  const handleFinishedIndication = useCallback(
    (type?: HealthDataType) => () => {
      if (type && patient) {
        createHealthData({
          patientId: patient.id,
          healthData: [{ moduleDataType: type, value: { value: false } }],
        })
      }
    },
    [createHealthData, patient],
  )

  const getActionButtons = useCallback(
    (code) => {
      switch (code) {
        case AlertsCode.breastfeeding_limit_exceeded:
          return (
            <>
              <div className="mr-1">
                <RoundedButton
                  onClick={handleFinishedIndication(breastfeeding)}
                  label="Indiquer comme terminé"
                  theme="invalid"
                />
              </div>
            </>
          )
        case AlertsCode.pregnancy_limit_exceeded:
          return (
            <>
              <div className="mr-1">
                <RoundedButton
                  onClick={handleFinishedIndication(pregnancy)}
                  label="Indiquer comme terminée"
                  theme="invalid"
                />
              </div>
            </>
          )
        default:
          break
      }
    },
    [handleFinishedIndication, breastfeeding, pregnancy],
  )

  const formatedAlerts = useMemo(() => {
    const visibleAlerts =
      alerts?.filter(
        ({ code }) => !ignoredAlertCodes.some((ignoredCode) => ignoredCode === code),
      ) ?? []

    return visibleAlerts.map((alert) => ({
      code: alert.code,
      title: AlertsTitleMapping[alert.code],
      text: AlertsTextMapping[alert.code],
      isActive: true,
      severity: alert.severity,
      actionButton: getActionButtons(alert.code),
      color: AlertSeverityColor[alert.severity],
      icon: AlertSeverityIcon[alert.severity],
    }))
  }, [alerts, ignoredAlertCodes, getActionButtons])

  const handleIgnoreAlert = useCallback(
    (code: string) => {
      if (ignoredAlertCodes.every((ignoredCode) => ignoredCode !== code)) {
        setIgnoredAlertCodes([...ignoredAlertCodes, code])
      }
    },
    [ignoredAlertCodes, setIgnoredAlertCodes],
  )

  return formatedAlerts && formatedAlerts.length > 0 ? (
    <AlertBanner
      alerts={formatedAlerts}
      onIgnore={handleIgnoreAlert}
      testId="banner-patient-alert"
    />
  ) : null
}
