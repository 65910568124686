import { useState } from 'react'
import createSearchPanel from '../../../../containers/SearchPanel/SearchPanel.container'
import { DocumentInstanceListItem } from '../../../../model/DocumentInstance'
import { BottomPanel } from '../BottomPanel'
import { SearchMedicalEventDocumentsBottomPanelProps } from './SearchMedicalEventDocumentsBottomPanel.model'
import { DocumentListItem } from '../../../document'
import { Button } from '../../buttons'

export const SearchMedicalEventDocumentsBottomPanel = ({
  isUnmounting,
  onRequestClose,
  onSelect,
  medicalEvent,
  title,
}: SearchMedicalEventDocumentsBottomPanelProps) => {
  const SearchPanel = createSearchPanel<DocumentInstanceListItem>()
  const [selected, setSelected] = useState<ReadonlyArray<DocumentInstanceListItem>>([])

  const onConfirm = (document?: DocumentInstanceListItem) => {
    document ? onSelect([document]) : onSelect(selected)
    onRequestClose()
  }

  const items: ReadonlyArray<DocumentInstanceListItem> = medicalEvent?.documents

  const topBarActions = selected.length > 0 && (
    <Button theme="primary" label="Sélectionner" onClick={() => onConfirm()} />
  )

  return (
    <BottomPanel
      title={title}
      isUnmounting={isUnmounting}
      onRequestClose={onRequestClose}
      actions={topBarActions}
    >
      <SearchPanel
        filterable={false}
        items={items}
        loading={false}
        itemCount={items.length}
        pageCount={1}
        onClearItems={() => setSelected([])}
        onMultiSelectionChange={(selection) => setSelected(selection)}
        onPrimaryAction={(selection) => onConfirm(selection)}
        getItems={() => items}
        renderItem={(item) => <DocumentListItem item={item} />}
      />
    </BottomPanel>
  )
}
