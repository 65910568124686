import { FunctionComponent, useEffect, useRef, useState } from 'react'
import { useLocalStorage } from '../../../../hooks/utils'
import { FwTrackingEvent, withTrackingEvent } from '../../../../misc/Tracking'
import { BottomPanelContactsContext } from '../../../../store/ui/bottomPanelContacts'
import styles from './PatientGeneralInfos.module.scss'
import { PatientInfosItem } from '../PatientInfosItem'
import { PatientInfosShowMore } from '../PatientInfosShowMore'
import { Sex } from '../../../../model/Patient'
import { SEX_FORM_LABELS, TITLE_FORM_LABELS } from '../../PatientForm/PatientForm.model'
import { Icon, IconButton, TooltipWrapper } from '../../../shared'
import { Colors } from '../../../../colors'
import { inputInseeNumberFormatter } from '../../../../misc/inseeNumber.utils'
import { PatientGeneralInfosProps } from './PatientGeneralInfos.model'
import { DATE_FORMAT_VERBOSE_ALTERNATIVE, formatStringFr } from '../../../../misc/date.utilities'

const withTrackingAccordionEvent = (section: string) =>
  withTrackingEvent(FwTrackingEvent.TOGGLE_PATIENT_INFO_ACCORDION, {
    section,
  })

export const PatientGeneralInfosComponent: FunctionComponent<PatientGeneralInfosProps> = ({
  patient,
  openEditContactForm,
  copyTextToClipboard,
}) => {
  const [showingMore, setShowingMore] = useLocalStorage<boolean>('ui.toggleShowMore.open', false)
  const [copyINSIcon, setCopyINSIcon] = useState<'copy' | 'check'>('copy')

  const setShowingMoreInfos = withTrackingAccordionEvent('Voir plus')(setShowingMore)

  const timerId = useRef<NodeJS.Timeout | null>(null)
  useEffect(() => {
    return () => {
      if (timerId.current) clearTimeout(timerId.current)
    }
  }, [timerId])
  const handleCopyINSWithoutSpace = () => {
    copyTextToClipboard(patient.inseeNumber || '')
    setCopyINSIcon('check')
    timerId.current = setTimeout(() => {
      setCopyINSIcon('copy')
    }, 2000)
  }

  return (
    <>
      {patient.title && (
        <PatientInfosItem label={'Civilité'} value={TITLE_FORM_LABELS[patient.title]} />
      )}
      {patient.usedLastName && (
        <PatientInfosItem label={'Nom utilisé'} value={patient.usedLastName} />
      )}
      {patient.usedFirstName && (
        <PatientInfosItem label={'Prénom utilisé'} value={patient.usedFirstName} />
      )}
      {patient.birthLastName && (
        <PatientInfosItem label={'Nom de naissance'} value={patient.birthLastName} />
      )}
      {patient.birthFirstName && (
        <PatientInfosItem label={'Premier prénom de naissance'} value={patient.birthFirstName} />
      )}
      {patient.birthDate && (
        <PatientInfosItem
          label={'Date de naissance'}
          value={formatStringFr(patient.birthDate, DATE_FORMAT_VERBOSE_ALTERNATIVE)}
        />
      )}
      {patient.sex && (
        <PatientInfosItem
          label={'Sexe'}
          value={
            <div className={styles.sex}>
              <span className={styles.gender}>{SEX_FORM_LABELS[patient.sex]}</span>
              {patient.sex !== Sex.UNKNOWN && (
                <Icon
                  icon={patient.sex === Sex.MALE ? 'male' : 'female'}
                  color={Colors.contentDark1}
                  size="nano"
                />
              )}
            </div>
          }
        />
      )}
      {patient.profession && <PatientInfosItem label={'Profession'} value={patient.profession} />}
      {patient.contacts.map((contact, key) => (
        <div className={styles.contact} key={key}>
          {contact.assignedDoctor ? (
            <span>Médecin Traitant</span>
          ) : contact.addressingDoctor ? (
            <span>Adressant</span>
          ) : (
            <span>{contact.profession?.description}</span>
          )}
          <span
            className={styles.infos}
            onClick={() =>
              openEditContactForm(contact.id, true, BottomPanelContactsContext.PATIENT_CONTACT_INFO)
            }
          >
            {contact.familyName} {contact.firstName}
          </span>
        </div>
      ))}
      {showingMore && (
        <>
          {patient.otherFirstNames && (
            <PatientInfosItem
              label={'Autres prénoms de naissance'}
              value={patient.otherFirstNames}
            />
          )}
          {patient.serial && <PatientInfosItem label={'Matricule'} value={patient.serial} />}
          {patient.birthPlaceCode && (
            <PatientInfosItem label={'Code lieu de naissance'} value={patient.birthPlaceCode} />
          )}
          {patient.address?.street2 && (
            <PatientInfosItem label={"Complément d'adresse"} value={patient.address.street2} />
          )}
          {patient.address?.street && (
            <PatientInfosItem label={'Rue'} value={patient.address.street} />
          )}
          {patient.address?.zipCode && (
            <PatientInfosItem label={'Code postal'} value={patient.address.zipCode} />
          )}
          {patient.address?.city && (
            <PatientInfosItem label={'Ville'} value={patient.address.city} />
          )}
          {patient.address?.country && (
            <PatientInfosItem label={'Pays'} value={patient.address.country} />
          )}
          {patient.telephone1 && (
            <PatientInfosItem
              label={'Téléphone 1'}
              value={
                <a className={styles.phoneNumber} href={`tel:${patient.telephone1}`}>
                  {patient.telephone1}
                </a>
              }
            />
          )}
          {patient.telephone2 && (
            <PatientInfosItem
              label={'Téléphone 2'}
              value={
                <a className={styles.phoneNumber} href={`tel:${patient.telephone2}`}>
                  {patient.telephone2}
                </a>
              }
            />
          )}
          {patient.email && <PatientInfosItem label={'Email'} value={patient.email} />}
          {patient.healthInsuranceNumber && (
            <PatientInfosItem
              label={'Assurance complémentaire'}
              value={patient.healthInsuranceNumber}
            />
          )}
          {patient.inseeNumber && (
            <PatientInfosItem
              label={'Matricule INS'}
              value={
                <div className={styles.ins}>
                  <span>{inputInseeNumberFormatter(patient.inseeNumber)}</span>
                  <TooltipWrapper content={'Copie du matricule sans espace'}>
                    <div className={styles.insIcon}>
                      <IconButton
                        onClick={handleCopyINSWithoutSpace}
                        icon={copyINSIcon}
                        size="nano"
                        theme="light"
                      />
                    </div>
                  </TooltipWrapper>
                </div>
              }
            />
          )}
          {patient.assigningAuthority?.label && (
            <PatientInfosItem
              label={"Identifiant d'objet (OID)"}
              value={patient.assigningAuthority.label}
            />
          )}
        </>
      )}
      {patient.customFields.map(({ label, value }, index) => (
        <PatientInfosItem key={index} label={label} value={value} />
      ))}
      <PatientInfosShowMore
        onClick={(showMore) => {
          setShowingMoreInfos(showMore)
        }}
        showingMore={showingMore}
      />
    </>
  )
}

export default PatientGeneralInfosComponent
