import React, { Children, FunctionComponent, ReactElement } from 'react'
import Waypoint from 'react-waypoint'

import { TimelineProps } from './Timeline.model'
import { TimelineItemProps } from './TimelineItem'
import { TimelineHeader } from './TimelineHeader'
import { TimelineIcon } from './TimelineIcon'

import styles from './Timeline.module.scss'
import { LineContainer } from './Timeline.styled'
import { GridLayout } from '../../shared'

export const Timeline: FunctionComponent<TimelineProps> = ({
  children,
  page,
  pageCount,
  loadedItemNumber,
  loading,
  isPatientEditable = true,
  loadNext,
}) => {
  const items = Children.map(children, (child: ReactElement<TimelineItemProps>, index) => {
    const { type, date, onIconClick } = child.props
    const notAllowedToCreateMedicalEvent = type === 'AddMedicalEventAction' && !isPatientEditable
    if (notAllowedToCreateMedicalEvent) return null

    const insertWaypoint = !loading && loadedItemNumber - 2 === index && page < (pageCount || 0)
    return (
      <>
        <GridLayout
          flow="column"
          columnsTemplate="auto 1fr"
          rowsTemplate="auto 1fr"
          alignColumns="center"
        >
          <TimelineIcon type={type} onClick={onIconClick} />
          <LineContainer invisible={child.props.type === 'CreatedPatientEvent'}>
            <div className={styles.line} />
          </LineContainer>
          {date && <TimelineHeader date={date} />}
          {child}
        </GridLayout>
        {insertWaypoint && <Waypoint onEnter={() => loadNext(page + 1)} />}
      </>
    )
  })
  return <div className={styles.timelineWrapper}>{items}</div>
}
