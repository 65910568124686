import { restuxFilesApiConfig } from './api'
import { restuxCacheFactory } from '../../restux/cache'
import { CustomFile } from '../../../model/File'
import { RestuxResources } from '../../resources'

const { actions, reducers, sagas } = restuxCacheFactory<CustomFile, CustomFile>({
  apiConfig: restuxFilesApiConfig,
  refetchList: false,
  resourceName: RestuxResources.file,
})

export const filesActions = actions
export const filesReducers = reducers
export const filesSagas = sagas
