import { RestuxUiSagaConf, RestuxInUse } from '../../../restux/ui/index'
import { RestuxResources } from '../../../resources'
import { RESTUX_IDENTIFIER } from '../../../restux.identifier'
import { toUpperSnakeCase } from '../../../restux/restux.utilities'

const RESOURCE_PREFIX = `@${toUpperSnakeCase(RestuxResources.documentInstance)}`

export const medicalEventDocumentInstancesConfig: RestuxUiSagaConf = {
  resourceName: RestuxResources.documentInstance,
  identifier: RESTUX_IDENTIFIER.medicalEventDocumentInstance,
}

export interface MedicalEventDocumentInstancesState {
  inUse: RestuxInUse
}

export const UiMedicalEventDocumentInstanceActionTypes = {
  ADD_EXISTING_QUESTION_TO_IN_USE_DOCUMENT_INSTANCE: `${RESOURCE_PREFIX}/ADD_EXISTING_QUESTION_TO_IN_USE_DOCUMENT_INSTANCE`,
  ADD_QUESTIONNAIRE_TO_IN_USE_DOCUMENT_INSTANCE: `${RESOURCE_PREFIX}/ADD_QUESTIONNAIRE_TO_IN_USE_DOCUMENT_INSTANCE`,
  ADD_QUESTION_TO_IN_USE_DOCUMENT_INSTANCE: `${RESOURCE_PREFIX}/ADD_QUESTION_TO_IN_USE_DOCUMENT_INSTANCE`,
}
