import { Patient } from '../model/Patient'

export const computePatientDifferenceToVitalCard = (
  patient: Patient,
  vitalCardInformations: Partial<Patient>,
): Partial<Patient> | null => {
  const replaceBirthPlaceCode =
    vitalCardInformations.birthPlaceCode &&
    vitalCardInformations.birthPlaceCode !== patient.birthPlaceCode
  const replaceInsee =
    vitalCardInformations.inseeNumber && vitalCardInformations.inseeNumber !== patient.inseeNumber

  const differences = {
    ...(replaceBirthPlaceCode && { birthPlaceCode: vitalCardInformations.birthPlaceCode }),
    ...(replaceInsee && { inseeNumber: vitalCardInformations.inseeNumber }),
  }

  return replaceInsee || replaceBirthPlaceCode ? differences : null
}
