import React, { FC } from 'react'
import { ToolbarButton } from '@/components/toolbar/atoms'
import { ToolbarElementProps } from '@/model/Toolbar'
import styles from './MarksGroup.module.scss'

export const MarksGroup: FC<ToolbarElementProps> = ({ editor }) => {
  const toggleBold = () => editor.chain().focus().toggleBold().run()
  const toggleItalic = () => editor.chain().focus().toggleItalic().run()
  const toggleUnderline = () => editor.chain().focus().toggleUnderline().run()
  const toggleStrike = () => editor.chain().focus().toggleStrike().run()

  return (
    <>
      <div className={styles['button']}>
        <ToolbarButton
          title="Gras (Ctrl + B)"
          icon="Bold"
          onClick={toggleBold}
          active={editor.isActive('bold')}
        />
      </div>
      <div className={styles['button']}>
        <ToolbarButton
          title="Italique (Ctrl + I)"
          icon="Italic"
          onClick={toggleItalic}
          active={editor.isActive('italic')}
        />
      </div>
      <div className={styles['button']}>
        <ToolbarButton
          title="Souligner (Ctrl + U)"
          icon="Underline"
          onClick={toggleUnderline}
          active={editor.isActive('underline')}
        />
      </div>
      <div className={styles['button']}>
        <ToolbarButton
          title="Barrer (Ctrl + Shift + S)"
          icon="Strikethrough"
          onClick={toggleStrike}
          active={editor.isActive('strike')}
        />
      </div>
    </>
  )
}
