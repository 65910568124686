import { FunctionComponent, useCallback, useMemo, useState } from 'react'

import { MedicalEvent, TimelineEventType } from '../../../../model/MedicalEvent'
import { IconButton, FullLoader, OwnerModal } from '../../../shared'

import { PatientTimelineProps, TIMELINE_PAGE_SIZE } from './PatientTimeline.model'

import styles from './PatientTimeline.module.scss'
import { Patient } from '../../../../model/Patient'
import { PatientTimelineItem } from './PatientTimelineItem'
import { PatientTimelineFilters } from './PatientTimelineFilters'
import { INITIAL_TIMELINE_FILTERS } from './PatientTimelineFilters/PatientTimelineFilters.model'
import { DuplicationModal } from '../../../medicalEvent'
import { Timeline, TimelineItemProps } from '../../Timeline'
import { SharingConfigKeys } from '../../../../model/Team'
import { useGetTags } from '../../../../hooks/queries/tag'
import {
  useGetInfiniteMedicalEvents,
  useDeleteMedicalEvent,
  useCreateMedicalEvent,
} from '../../../../hooks/queries/medicalEvents'
import { PaginationState } from '../../../../model/Pagination'
import { useSearchParamsFilters } from '../../../../hooks/utils'
import { useNavigate } from 'react-router-dom'

export const PatientTimeline: FunctionComponent<PatientTimelineProps> = ({
  currentUser,
  patient,
  teams,
  documentCategories,
  onPinMedicalEvent,
  duplicateMedicalEventDocuments,
  userTeams,
  changeOwner,
}) => {
  const [searchParamsFilters, setSearchParamsFilters] =
    useSearchParamsFilters(INITIAL_TIMELINE_FILTERS)

  const queryFilters = useMemo(
    () => ({
      patientId: `${patient.id}`,
      ...(searchParamsFilters.search.length > 2 ? { search: searchParamsFilters.search } : {}),
      ...(searchParamsFilters.tagIds.length ? { tagIds: searchParamsFilters.tagIds } : {}),
      ...(searchParamsFilters.doctorId ? { userIds: searchParamsFilters.doctorId } : {}),
      ...(searchParamsFilters.category ? { documentCategories: searchParamsFilters.category } : {}),
    }),
    [
      searchParamsFilters.category,
      searchParamsFilters.doctorId,
      searchParamsFilters.search,
      searchParamsFilters.tagIds,
      patient.id,
    ],
  )
  const {
    query: { isLoading: isLoadingMedicalEvents, fetchNextPage: fetchNextMedicalEventsPage },
    medicalEventList,
    paginationState: medicalEventPagination,
  } = useGetInfiniteMedicalEvents({
    limit: TIMELINE_PAGE_SIZE,
    filters: queryFilters,
  })
  const { mutate: createMedicalEvent } = useCreateMedicalEvent()
  const { mutate: deleteMedicalEvent } = useDeleteMedicalEvent()
  const navigate = useNavigate()
  const timelineItems = useMemo(
    () =>
      buildTimelineItems(medicalEventList, medicalEventPagination, isLoadingMedicalEvents, patient),
    [isLoadingMedicalEvents, medicalEventList, medicalEventPagination, patient],
  )
  const [currentMedicalEventDuplication, setCurrentMedicalEventDuplication] = useState<
    MedicalEvent | undefined
  >()
  const [selectedMedicalEvent, setSelectedMedicalEvent] = useState<MedicalEvent | null>(null)
  const isSwitchOwnerEnabled = useMemo(() => teams && teams.length > 0, [teams])
  const { tagList: tags } = useGetTags()

  const createMedicalEventAndOpenIt = useCallback(
    (patientId: number) =>
      createMedicalEvent(
        { patientId: patientId },
        {
          onSuccess: ({ id }) => navigate(`/patients/${patientId}/medicalEvent/${id}`),
        },
      ),
    [createMedicalEvent, navigate],
  )

  const handleIconClick = useCallback(
    (itemType: TimelineEventType, patientId: number) => () => {
      if (itemType === 'AddMedicalEventAction') {
        createMedicalEventAndOpenIt(patientId)
      }
    },
    [createMedicalEventAndOpenIt],
  )

  return (
    <>
      <div className={styles.mobileAddMedicalEvent}>
        <IconButton
          icon="add"
          theme="primary"
          size="macro"
          elevation={3}
          onClick={() => createMedicalEventAndOpenIt(patient.id)}
        />
      </div>
      <PatientTimelineFilters
        tags={tags}
        teams={teams}
        currentUser={currentUser}
        filters={searchParamsFilters}
        documentCategories={documentCategories}
        setFilters={setSearchParamsFilters}
      />
      <OwnerModal
        display={!!selectedMedicalEvent}
        label="Changement de propriétaire de l'évènement"
        sharingConfigKey={SharingConfigKeys.medicalEvent}
        teams={userTeams}
        currentUser={currentUser}
        currentOwner={selectedMedicalEvent?.owner ?? null}
        onClose={() => setSelectedMedicalEvent(null)}
        onValid={(userId) => selectedMedicalEvent && changeOwner(selectedMedicalEvent, userId)}
      />
      <Timeline
        page={medicalEventPagination?.currentPage || 1}
        pageCount={medicalEventPagination?.pageCount}
        loadedItemNumber={timelineItems.length}
        loading={isLoadingMedicalEvents}
        loadNext={() => fetchNextMedicalEventsPage()}
        isPatientEditable={patient.isEditable}
      >
        {timelineItems &&
          timelineItems.map((item, index) => {
            return (
              <PatientTimelineItem
                {...item}
                key={index}
                type={item.payload?.category ?? item.type}
                patient={patient}
                createMedicalEvent={createMedicalEventAndOpenIt}
                deleteMedicalEvent={deleteMedicalEvent}
                onPinMedicalEvent={onPinMedicalEvent}
                duplicateMedicalEventDocuments={() => {
                  if (item.payload) {
                    setCurrentMedicalEventDuplication(item.payload)
                  }
                }}
                onOwnerChange={
                  isSwitchOwnerEnabled && item.payload?.isEditable
                    ? setSelectedMedicalEvent
                    : undefined
                }
                onIconClick={handleIconClick(item.type, patient.id)}
              />
            )
          })}
      </Timeline>
      <DuplicationModal
        onCloseModal={() => setCurrentMedicalEventDuplication(undefined)}
        display={!!currentMedicalEventDuplication}
        medicalEvent={currentMedicalEventDuplication}
        onDuplicateMedicalEventDocuments={(medicalEventId, documentsIds) => {
          setSearchParamsFilters(INITIAL_TIMELINE_FILTERS)
          duplicateMedicalEventDocuments(medicalEventId, documentsIds)
        }}
      />
      {isLoadingMedicalEvents && (
        <div className="w-full relative h-20 mb-8">
          <FullLoader />
        </div>
      )}
    </>
  )
}

const buildTimelineItems = (
  medicalEvents: MedicalEvent[],
  medicalEventsPagination: PaginationState | null,
  isLoadingMedicalEvents: boolean,
  patient: Patient,
): TimelineItemProps[] => {
  const items: TimelineItemProps[] = [
    {
      type: 'AddMedicalEventAction',
      date: null,
      payload: null,
    },
    ...medicalEvents.map((med) => ({
      type: 'MedicalEvent' as const,
      date: med.date,
      payload: med,
    })),
  ]
  if (
    medicalEventsPagination &&
    medicalEventsPagination.currentPage >= medicalEventsPagination.pageCount &&
    !isLoadingMedicalEvents
  ) {
    items.push({
      type: 'CreatedPatientEvent',
      date: patient.createdAt,
      payload: null,
    })
  }
  return items
}
