import { connect } from 'react-redux'
import { branch, compose, renderNothing, lifecycle } from 'recompose'
import { initializeUsurpedUser } from '../../store/ui/adminUsers'
import { RootState } from '../../store/index'

interface WithUsurpationStateProps {
  usurpedUserInitializationWaitFlag: boolean
}

interface WithUsurpationDispatchProps {
  initializeUsurpedUser: typeof initializeUsurpedUser
}
type WithUsurpationProps = WithUsurpationStateProps & WithUsurpationDispatchProps

const mapStateToProps = (state: RootState): {} => ({
  usurpedUserInitializationWaitFlag: state.ui.adminUsers.usurpedUserInitializationWaitFlag,
})

const mapDispatchToProps: WithUsurpationDispatchProps = {
  initializeUsurpedUser,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const withLifecycle = lifecycle<WithUsurpationProps, {}, {}>({
  componentDidMount() {
    this.props.initializeUsurpedUser()
  },
})

const withBranch = branch<WithUsurpationProps>(
  ({ usurpedUserInitializationWaitFlag }) => usurpedUserInitializationWaitFlag,
  renderNothing,
)

export default compose(withConnect, withLifecycle, withBranch)
