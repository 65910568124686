import { useActionDispatch } from '../../utils'
import { addValid } from '../../../store/message'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { TagMutationPayload, TagType } from '../../../model/Tag'
import { createTag } from '../../../data/tag'
import { tagsKeys } from './tags.keys'
import { TagBackgroundColors } from '../../../colors'

export const useCreateTag = () => {
  const valid = useActionDispatch(addValid)
  const queryClient = useQueryClient()

  return useMutation<TagType, Error, TagMutationPayload>(
    async (tagPayload) => {
      const { data, ok } = await createTag({
        label: tagPayload.label,
        description: tagPayload.description,
        private: tagPayload.private ?? true,
        color: tagPayload.color ?? TagBackgroundColors.dsTag00,
      })
      if (!data || !ok) {
        throw new UserFriendlyError('error', 'La création du label a échoué')
      }
      return data
    },
    {
      onSuccess: () => {
        valid('Label créée avec succès.')
        queryClient.invalidateQueries({ queryKey: tagsKeys.all })
      },
    },
  )
}
