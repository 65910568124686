import getApi from '../../../api'
import { singleItemPageLimit } from '../../../constants'
import { Filters } from '../../../model/Filters'
import { LegalDocument } from '../../../model/LegalDocument'
import { PaginatedList } from '../../../model/Pagination'
import { sanitizeLegalDocumentResponse } from './legalDocument.mapper'

export const getLegalDocuments = (
  page: number,
  filters?: Filters,
  signal?: AbortSignal,
  limit = singleItemPageLimit,
) =>
  getApi()
    .get<PaginatedList<LegalDocument>>(
      '/admin/legal_documents/',
      { page, limit, ...filters },
      { signal },
    )
    .then(sanitizeLegalDocumentResponse)
