export function isDefined<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined
}

export function noop() {
  // Nothing to do
}

export function unity<T>(v: T) {
  return v
}
