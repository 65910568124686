import { Filters } from '../../../model/Filters'

const all = ['medicalEvents']
const lists = [...all, 'list']
const list = (filters?: Filters) => [...lists, filters]

export const medicalEventsKeys = {
  all,
  lists,
  list,
}
