import { ErrorInfo } from 'react'

export function reduxSagaOnErrorHandler(error: Error) {
  console.error(`[APP][REDUX] ${error.message}`, error)
}

export function manageError(error: Error) {
  console.error('[ERROR]', { error })
}

export function manageReactError(error: Error, errorInfo: ErrorInfo) {
  console.error('[REACT]', { error, errorInfo })
}
