import { RestuxResources } from '../../resources'
import { toUpperSnakeCase } from '../../restux/restux.utilities'

const RESOURCE_PREFIX = `@${toUpperSnakeCase(RestuxResources.prescription)}`

export const domainPrescriptionActionTypes = {
  SWITCH_TO_VMP: `${RESOURCE_PREFIX}/SWITCH_TO_VMP`,
  OPEN_POSOLOGY_BOTTOM_PANEL_FROM_PRESCRIPTION: `${RESOURCE_PREFIX}/OPEN_POSOLOGY_BOTTOM_PANEL_FROM_PRESCRIPTION`,
  UPDATE_PRESCRIPTION: `${RESOURCE_PREFIX}/UPDATE_PRESCRIPTION`,
}
