import { connect } from 'react-redux'

import { setBottomPanelOptions } from '../../../../store/ui/bottomPanel'
import {
  ChildQuestionsLinkDispatchProps,
  ChildQuestionsLinkStoreProps,
} from './ChildQuestionsLink.model'
import { ChildQuestionsLink } from './ChildQuestionsLink.component'
import { RootState } from '../../../../store'
import { questionEditionOriginSelector } from '../../../../store/ui/bottomPanelQuestions'
import { isAdminSelector } from '../../../../store/ui/adminUsers'

const mapStateToProps = (state: RootState): ChildQuestionsLinkStoreProps => ({
  isAdmin: isAdminSelector(state),
  origin: questionEditionOriginSelector(state),
})

const mapDispatchToProps: ChildQuestionsLinkDispatchProps = {
  setBottomPanelOptions,
}
export default connect(mapStateToProps, mapDispatchToProps)(ChildQuestionsLink)
