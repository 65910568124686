import { removeBase64Prefix } from '../../../../misc/files.utilities'
import { XmlViewerProps } from './XmlViewer.model'

const template = document.createElement('template')
template.innerHTML = `
<style>
#container {
  contain: inline-size;
}
</style>
<div id="container" />`

class XmlViewerElement extends HTMLElement {
  static get observedAttributes() {
    return ['base64']
  }

  constructor() {
    super()
    this.attachShadow({ mode: 'open' })
    this.shadowRoot?.appendChild(template.content.cloneNode(true))
  }

  attributeChangedCallback(name: string, oldValue: string, newValue: string) {
    if (name === 'base64') {
      const base64 = newValue || ''
      if (this.shadowRoot && base64) {
        const base64Data = removeBase64Prefix(base64)
        const decoded = window.atob(base64Data)
        const parsedXml = new TextDecoder('utf-8').decode(
          Uint8Array.from(decoded.split('').map((char) => char.charCodeAt(0))),
        )
        const container = this.shadowRoot.getElementById('container')
        if (container) container.innerHTML = parsedXml
      }
    }
  }
}

customElements.define('xml-viewer', XmlViewerElement)

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'xml-viewer': XmlViewerProps
    }
  }
}
