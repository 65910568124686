import { PastAnalytics, DatedAnalytics } from '../../../model/Analytics'

export interface AnalyticsState extends Array<PastAnalytics> {}

export interface OverallState extends Array<DatedAnalytics | undefined> {}

export interface AdminAnalyticsState {
  analytics: AnalyticsState
  overall: OverallState
}

export enum AdminAnalyticsActionTypes {
  REQUEST_ANALYTICS = '@ADMIN_ANALYTICS/REQUEST_ANALYTICS',
  REQUEST_OVERALL = '@ADMIN_ANALYTICS/REQUEST_OVERALL',
  REQUEST_PERIOD = '@ADMIN_ANALYTICS/REQUEST_PERIOD',
  ANALYTICS_ADD = '@ADMIN_ANALYITCS/ANALYTICS_ADD',
  ANALYTICS_RESET = '@ADMIN_ANALYITCS/ANALYTICS_RESET',
  SET_OVERALL = '@ADMIN_ANALYITCS/SET_OVERALL',
  OVERALL_ERASE = '@ADMIN_ANALYITCS/OVERALL_ERASE',
  OVERALL_ADD_FIELD = '@ADMIN_ANALYITCS/OVERALL_ADD_FIELD',
  EXPORT_TO_CSV = '@ADMIN_ANALYTICS/EXPORT_TO_CSV',
}
