import React, { FunctionComponent } from 'react'

import { User } from '../../../../model/User'
import { Icon } from '../../../shared'
import { Colors } from '../../../../colors'

import styles from './UserListItem.module.scss'
import { getFullName } from '../../../../misc/user.utilities'
import { ROLES_TYPES } from '../../../../model/Roles'

interface UserListItemProps {
  user: User
}

export const UserListItem: FunctionComponent<UserListItemProps> = ({ user }) => (
  <div className={styles.item}>
    <span title={user.locked ? 'Verrouillé' : 'Activé'}>
      <Icon
        icon="smallCircleFull"
        color={user.locked ? Colors.contentDark3 : Colors.brandPrimary}
        size="nano"
      />
    </span>
    <div className={styles.name}>{getFullName(user)}</div>
    <div className={styles.identifier}>{user.id}</div>
    <div className={styles.identifier}>{user.internalId}</div>
    <div className={styles.type}>{ROLES_TYPES[user.mainRole]?.title}</div>
  </div>
)
