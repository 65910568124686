import React, { FunctionComponent } from 'react'
import classNames from 'classnames/bind'
import { Loader } from '../../loading'
import styles from './EntityCard.module.scss'
import { EntityCardProps } from './EntityCard.model'

const cx = classNames.bind(styles)

export const EntityCard: FunctionComponent<EntityCardProps> = ({ loading, inactive, children }) => (
  <div className={cx(styles.container, { inactive })}>
    {loading && (
      <div className={styles.loadingOverlay}>
        <Loader isLoading={true} height={70} width={70} />
      </div>
    )}
    {children}
  </div>
)
