import { useCallback, useEffect } from 'react'
import {
  onInitPatient,
  onOpenPatient,
  onUpdatePatient,
} from '../../misc/Doctolib/doctolib.callbacks'
import { DoctolibPatient } from '../../misc/Doctolib/doctolib.model'
import { useCreateOrUpdateFollowPatient } from '../queries/doctolib'
import { zipperStatusAtom } from '../../state/doctolib'
import { useAtom } from 'jotai'
import { DoctolibZipperStatus } from '../../model/Doctolib'

export const useDoctolibStatus = () => {
  const [zipperStatus, setZipperStatus] = useAtom(zipperStatusAtom)
  const { mutate } = useCreateOrUpdateFollowPatient()

  const createOrUpdatePatient = useCallback(
    (patient: DoctolibPatient) => {
      mutate({ patient })
    },
    [mutate],
  )

  const initializeDoctolibZipper = useCallback(() => {
    if (window.zipper && zipperStatus === DoctolibZipperStatus.NOT_LOADED) {
      setZipperStatus(DoctolibZipperStatus.LOADED)
      window.zipper('onOpenPatient', onOpenPatient)
      window.zipper('onInitPatient', (patient: DoctolibPatient) =>
        onInitPatient(patient, createOrUpdatePatient),
      )
      window.zipper('onUpdatePatient', (patient: DoctolibPatient) =>
        onUpdatePatient(patient, createOrUpdatePatient),
      )
    }
  }, [createOrUpdatePatient, setZipperStatus, zipperStatus])

  useEffect(() => {
    initializeDoctolibZipper()
  }, [initializeDoctolibZipper])

  return { initializeDoctolibZipper }
}
