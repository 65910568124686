import React from 'react'
import { Global, css } from '@emotion/react'
import COLORS, { ColorNames } from '../../../design-system/colors'
import { getCssVariable } from '../../../design-system/utils'

export const GlobalStyles = () => (
  <Global
    styles={css`
      :root {
        ${Object.entries(COLORS).map(([type, colors]) =>
          Object.entries(colors).map(
            ([colorName, rgba]) =>
              `${getCssVariable(type as ColorNames, colorName as never)}: ${rgba}`,
          ),
        )}
      }
    `}
  />
)
