import React, { FC, Fragment } from 'react'
import { ToolbarColorPaletteProps } from './ToolbarColorPalette.model'
import {
  Combobox,
  ComboboxButton,
  ComboboxOption,
  ComboboxOptions,
  Transition,
} from '@headlessui/react'
import styles from './ToolbarColorPalette.module.scss'
import { isDefined } from '@/utils/functions.utils'
import { Icon } from '@/components/Icon'

export const ToolbarColorPalette: FC<ToolbarColorPaletteProps> = ({
  value,
  options,
  title,
  icon,
  onChange,
}) => {
  return (
    <div className={styles['listbox']}>
      <Combobox value={value} by="id" onChange={onChange}>
        <ComboboxButton
          title={title}
          className={styles['button']}
          style={{ color: value.value ?? undefined }}
        >
          <Icon icon={icon} />
        </ComboboxButton>
        <Transition
          as={Fragment}
          enter={styles['transition']}
          enterFrom={styles['to']}
          enterTo={styles['from']}
          leave={styles['transition']}
          leaveFrom={styles['from']}
          leaveTo={styles['to']}
        >
          <ComboboxOptions className={styles['options']}>
            {options.map((option) => (
              <ComboboxOption
                value={option}
                key={option.id}
                className={styles['option']}
                title={option.label}
              >
                <div
                  style={isDefined(option.value) ? { color: option.value } : undefined}
                  className={styles['caret']}
                />
              </ComboboxOption>
            ))}
          </ComboboxOptions>
        </Transition>
      </Combobox>
    </div>
  )
}
