import { createReducer } from 'redux-starter-kit'
import { bottomPanelDocumentHistoryActions } from './bottomPanelDocumentHistory.actions'

import {
  BottomPanelDocumentHistoryState,
  UiBottomPanelDocumentHistoryActionTypes,
} from './bottomPanelDocumentHistory.model'

const INITIAL_STATE: BottomPanelDocumentHistoryState = null

const ACTION_HANDLERS = {
  [UiBottomPanelDocumentHistoryActionTypes.CLOSE_DOCUMENT_HISTORY]: (
    state: BottomPanelDocumentHistoryState = INITIAL_STATE,
    _: ReturnType<typeof bottomPanelDocumentHistoryActions.closeDocumentHistory>,
  ): BottomPanelDocumentHistoryState => null,
  [UiBottomPanelDocumentHistoryActionTypes.SEARCH_DOCUMENT_HISTORY]: (
    state: BottomPanelDocumentHistoryState = INITIAL_STATE,
    {
      documentId,
      documentHash,
      documentType,
    }: ReturnType<typeof bottomPanelDocumentHistoryActions.searchDocumentHistory>,
  ): BottomPanelDocumentHistoryState => ({
    id: documentId,
    hash: documentHash,
    resource: documentType,
  }),
}

export const bottomPanelDocumentHistoryReducer = createReducer<BottomPanelDocumentHistoryState>(
  INITIAL_STATE,
  ACTION_HANDLERS,
)
