import { searchSideEffects } from './api'
import { call, put, takeEvery } from '@redux-saga/core/effects'
import { addError } from '../../message'
import { sideEffectsDomainActions } from './sideEffects.actions'
import { SideEffectsDomainActionsTypes } from './sideEffects.model'

function* searchSideEffectsWorker({ search }: ReturnType<typeof sideEffectsDomainActions.search>) {
  const response = yield call(searchSideEffects, search)
  const { ok, status, data } = response
  if (ok && 200 === status) {
    yield put(sideEffectsDomainActions.setSideEffectsList(data))
  } else {
    yield put(
      addError(
        `Une erreur s'est produite`,
        `Un problème est survenu en recherchant le principe actif, veuillez réessayer`,
      ),
    )
  }
}

function* searchSideEffectsWatcher() {
  yield takeEvery(SideEffectsDomainActionsTypes.SEARCH, searchSideEffectsWorker)
}

export const sideEffectsSagas = {
  searchSideEffectsWatcher,
}
