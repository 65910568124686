import { ApiResponse } from 'apisauce'
import { call, put, select, takeEvery } from 'redux-saga/effects'
import { getPdfFromDocuments } from '../../../api/Renderer'
import { blobToBase64 } from '../../../components/file/FileViewer/PdfViewerLegacy/pdf.utils'
import { formatFr } from '../../../misc/date.utilities'
import { Patient, Sex } from '../../../model/Patient'
import { Roles } from '../../../model/Roles'
import { RenderableDocument } from '../../renderer'
import { dmpDomainActions } from './dmp.actions'
import { DmpDomainActionsTypes, DmpRequestTypes } from './dmp.model'
import { EfficienceUploadConfig, HealthcareSetting } from '../../../model/Efficience'
import { getHealthcareSettings } from './api'
import { addError, addValid } from '../../message'
import { inUseMedicalEventSelector } from '../../ui/medicalEvents'
import { MedicalEvent } from '../../../model/MedicalEvent'
import { DocumentInstance } from '../../../model/DocumentInstance'
import { inUseMedicalEventDocumentSelector } from '../../ui/medicalEvents/medicalEventDocumentInstances'
import { extractFileNameFromHeader } from '../../../misc/extractFilename.utilities'
import { getCurrentPatient } from '../../../misc/currentPatient.utilities'

function* uploadDocumentWorker({
  documentId,
  documentType,
  config,
  dmpDocumentId,
}: ReturnType<typeof dmpDomainActions.uploadDocument>) {
  if (documentType === 'farte') {
    const renderable: RenderableDocument = { documentId, type: 'farte' }
    const patient = getCurrentPatient()
    const medicalEvent: MedicalEvent | undefined = yield select(inUseMedicalEventSelector)
    const document: DocumentInstance | null = yield inUseMedicalEventDocumentSelector

    if (!patient || !medicalEvent || !document) {
      yield addError(
        "Erreur lors de l'envoi au DMP",
        "Les informations nécessaires à l'envoi sont introuvables",
      )
      return
    }

    yield sendDocument([renderable], config, patient, medicalEvent, document, dmpDocumentId)
  }
}

function* uploadDocumentWatcher() {
  yield takeEvery(DmpDomainActionsTypes.UPLOAD_DOCUMENT, uploadDocumentWorker)
}

function* sendDocument(
  documents: Array<RenderableDocument>,
  efficienceConfig: EfficienceUploadConfig,
  patient: Patient,
  medicalEvent: MedicalEvent,
  documentInstance: DocumentInstance,
  dmpDocumentId?: string,
) {
  const response: ApiResponse<Blob> = yield call(getPdfFromDocuments, documents)
  if (response.ok && response.data) {
    const fileName = extractFileNameFromHeader(response.headers) ?? 'Document'
    yield put(addValid("Document en cours d'envoi au dossier médical partagé du patient"))
    const base64Pdf = yield call(blobToBase64, response.data)
    yield put(
      dmpDomainActions.setRequest(documentInstance.id, {
        request: {
          action: 'submitDocument',
          id: DmpRequestTypes.SEND_DOCUMENT,
          sendInBackground: 1,
          modal: 0,
          patient: {
            Identity: {
              Ins: {
                key: patient.inseeNumber?.slice(-2),
                oid: '1.2.250.1.213.1.4.10',
                value: patient.inseeNumber?.slice(0, -2),
              },
              root: 'T',
              extension: patient.inseeNumber,
              birthName: patient.birthLastName,
              birthGiven: patient.birthFirstName,
              sex: patient.sex === Sex.MALE ? '2' : '3',
              birthDate: formatFr(new Date(patient.birthDate), 'yyyyMMdd'),
              birthPlace: patient.birthPlaceCode,
            },
          },
          document: {
            content: base64Pdf.split(',')[1],
            format: 'pdf',
            title: fileName,
            typeCode: '57833-6',
            role: medicalEvent.owner.roles.includes(Roles.DOCTOR) ? 'PPRF' : 'SPRF',
            createDate: formatFr(new Date(documentInstance.createdAt), 'yyyyMMdd'),
            versionNumber: documentInstance.version ? documentInstance.version + 1 : '1',
            performer: {
              name: medicalEvent.owner.familyName,
              given: medicalEvent.owner.firstName,
              profession: '10',
              professionOid: '1.2.250.1.71.1.2.7',
              specialty: 'SM26',
              internalId: medicalEvent.owner.id,
            },
            invisiblePatient: efficienceConfig.hideToPatient ? 1 : 0,
            invisibleRepresentantLegal: efficienceConfig.hideToLegalRepresentative ? 1 : 0,
            masquePS: efficienceConfig.hideToHealthProfessional ? 1 : 0,
            practice: efficienceConfig.healthcareSettingsId,
            ...(dmpDocumentId && {
              replacedDocumentUniqueId: dmpDocumentId,
            }),
          },
        },
      }),
    )
  }
}

function* deleteDocumentWorker({
  documentId,
  dmpDocumentId,
  patientInseeNumber,
}: ReturnType<typeof dmpDomainActions.deleteDocument>) {
  yield put(
    dmpDomainActions.setRequest(documentId, {
      request: {
        action: 'deleteDocument',
        id: DmpRequestTypes.DELETE_DOCUMENT,
        patient: {
          ins: {
            root: 'T',
            extension: patientInseeNumber,
          },
        },
        document: {
          uniqueId: dmpDocumentId,
          practice: 'SA05',
        },
      },
    }),
  )
}

function* deleteDocumentWatcher() {
  yield takeEvery(DmpDomainActionsTypes.DELETE_DOCUMENT, deleteDocumentWorker)
}

function* fetchHealthcareSettingsWorker() {
  const response: ApiResponse<HealthcareSetting[]> = yield call(getHealthcareSettings)
  if (response.ok && response.data) {
    yield put(dmpDomainActions.storeHealthcareSettings(response.data))
  }
}

function* fetchHealthcareSettingsWatcher() {
  yield takeEvery(DmpDomainActionsTypes.FETCH_HEALTHCARE_SETTINGS, fetchHealthcareSettingsWorker)
}

export const dmpSagas = {
  deleteDocumentWatcher,
  uploadDocumentWatcher,
  fetchHealthcareSettingsWatcher,
}
