import { FunctionComponent } from 'react'
import { FormBlock, Switch } from '../../../shared'
import { SHOWN_DOCUMENT_OPTIONS } from '../../../patient/PatientForm/IdentityStatusBlock/IdentityStatusBlock.model'
import { InformationAlert } from '../InformationAlert'
import { TrustedSourcePreferenceBlockProps } from './TrustedSourcePreferenceBlock.model'

export const TrustedSourcePreferenceBlock: FunctionComponent<TrustedSourcePreferenceBlockProps> = ({
  trustedSourceAccepted,
  onChange,
}) => {
  return (
    <FormBlock label="Pièces justificatives de confiances" icon="emptyStar">
      <InformationAlert>
        Sélectionnez les pièces justificatives que vous considérez comme des preuves à haut degré de
        confiance pour la vérification de l'identité de vos patients. Vous devez choisir au moins
        une pièce.
      </InformationAlert>
      {SHOWN_DOCUMENT_OPTIONS.map(
        ({ label, value }) =>
          value && (
            <Switch
              name={label}
              key={value}
              borderBottom
              checked={!!trustedSourceAccepted.find((trustedSource) => trustedSource === value)}
              onChange={(checked) => {
                onChange(
                  checked
                    ? [...trustedSourceAccepted, value]
                    : trustedSourceAccepted.filter((trustedSource) => trustedSource !== value),
                )
              }}
            />
          ),
      )}
    </FormBlock>
  )
}
