import React, { FunctionComponent, ReactNode } from 'react'
import styles from './ListHeader.module.scss'
import { RoundedButton } from '../../buttons'
import { CheckboxState, Checkbox } from '../../inputs'

interface ListHeaderProps {
  itemCount?: number | null
  selected: number
  onSelectAll: () => void
  showMultiSelectCheckbox: boolean
  multiSelectChecked: CheckboxState
  onAddSelectedItems?: () => void
  renderHeaderActions?: () => ReactNode
  selectedCount?: number
}

export const ListHeader: FunctionComponent<ListHeaderProps> = ({
  itemCount,
  selected,
  onSelectAll,
  showMultiSelectCheckbox,
  multiSelectChecked,
  onAddSelectedItems,
  renderHeaderActions,
  selectedCount,
}) => {
  const itemCountLabel =
    itemCount && itemCount > 0 ? (
      <>
        <div className={styles.selectionLabel}>
          {showMultiSelectCheckbox && (
            <div className={styles.selectAllCheckbox}>
              <Checkbox checked={multiSelectChecked} onChange={onSelectAll} />
            </div>
          )}
          <div>
            {itemCount} résultat{itemCount > 1 && 's'}
          </div>
        </div>
      </>
    ) : null

  const itemSelectedLabel =
    selected > 0 ? (
      <>
        {selected > 0 &&
          !onAddSelectedItems &&
          `${selected} / ${itemCount} selectionné${selected > 1 ? 's' : ''}`}
        {selected > 0 && onAddSelectedItems && (
          <div className={styles.addButton}>
            <RoundedButton
              label={`Ajouter (${selectedCount})`}
              theme="primary"
              onClick={onAddSelectedItems}
            />
          </div>
        )}
      </>
    ) : null

  return (
    (itemCountLabel || itemSelectedLabel) && (
      <div className={styles.header}>
        <div className={styles.headerLeft}>{itemCountLabel}</div>
        <div className={styles.headerRight}>{itemSelectedLabel}</div>
        {renderHeaderActions && renderHeaderActions()}
      </div>
    )
  )
}
