import React, { FunctionComponent } from 'react'
import { Colors } from '../../../colors'
import { Icon } from '../Icon'
import { Centered } from '../layout'
import styles from './EmptyPlaceHolder.module.scss'
import { EmptyPlaceHolderProps } from './EmptyPlaceHolder.model'

export const EmptyPlaceHolder: FunctionComponent<EmptyPlaceHolderProps> = ({
  icon = 'bubble',
  message,
}) => (
  <Centered>
    <div className={styles.container} data-test-id="placeholder-empty">
      <div className={styles.icon}>
        {icon && <Icon icon={icon} color={Colors.info} size="normal" />}
      </div>
      <span>{message ?? 'Aucun élément à afficher'}</span>
    </div>
  </Centered>
)
