import React, { useEffect, FunctionComponent } from 'react'
import classNames from 'classnames/bind'

import styles from './ScorePercentage.module.scss'
import { ScorePercentageProps } from './ScorePercentage.model'
import { getColorByScoreConfigAndValue } from '../../../../../model/Questionnaire'
import { isDefined } from '../../../../../misc/functions.utilities'

const classNamesCx = classNames.bind(styles)

export const ScorePercentage: FunctionComponent<ScorePercentageProps> = ({
  fetchQuestionnaireComputedValue,
  documentId,
  questionaireId,
  scoreValue,
  scoreConfig,
}) => {
  useEffect(() => {
    fetchQuestionnaireComputedValue(documentId, questionaireId)
  }, [documentId, questionaireId, fetchQuestionnaireComputedValue])

  if (!isDefined(scoreValue)) {
    return null
  }
  const label = scoreConfig.percentage
    ? `${Math.round(scoreValue)}%`
    : scoreConfig.noRounding
      ? scoreValue
      : Math.round(scoreValue)
  const className = getColorByScoreConfigAndValue(scoreConfig, scoreValue)

  return <span className={classNamesCx(styles.scoreValue, className)}>{label}</span>
}
