import { isDefined } from '../utilities/functions.utils'

const VARIABLE_ID_PATTERN = /([a-z]+)(?:_(\d+))/
const VARIABLE_UUID_PATTERN =
  /([a-z]+)(?:_([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}))/

/* Retourne l'id numérique d'un variableId
    ex : drug_150 -> 150
*/
export function retrieveVariableId(variableId: string) {
  let id: number | null = null
  if (VARIABLE_ID_PATTERN.test(variableId)) {
    const parsed = VARIABLE_ID_PATTERN.exec(variableId)
    if (parsed !== null) {
      id = isDefined(parsed[2]) ? parseInt(parsed[2], 10) : null
    }
  }
  return id
}

/* Retourne l'uuid d'un variableId
    ex : prescription_f45cd77b-10a9-4f97-b843-5298ba473339 -> f45cd77b-10a9-4f97-b843-5298ba473339
  */
export function retrieveVariableUuid(variableId: string) {
  let id: string | null = null
  if (VARIABLE_UUID_PATTERN.test(variableId)) {
    const parsed = VARIABLE_UUID_PATTERN.exec(variableId)
    if (parsed !== null) {
      id = isDefined(parsed[2]) ? parsed[2] : null
    }
  }
  return id
}

export function retrieveVariablePart(variableId: string, part: number) {
  return variableId.split('_')[part - 1] ?? null
}
