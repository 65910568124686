import { DocumentAlert } from '../../../model/DocumentAlerts'

const RESOURCE_PREFIX = 'UI_DOCUMENT_ALERTS'

export const uiDocumentAlertsActionTypes = {
  STORE_DISPLAYABLE_SAM_ALERTS: `${RESOURCE_PREFIX}/STORE_DISPLAYABLE_SAM_ALERTS`,
  DISABLE_CURRENT_SAM_TYPES: `${RESOURCE_PREFIX}/DISABLE_CURRENT_SAM_TYPES`,
  ACKNOWLEDGE_CURRENT_SAM_ALERTS: `${RESOURCE_PREFIX}/ACKNOWLEDGE_CURRENT_SAM_ALERTS`,
  CLEAR_ALERTS: `${RESOURCE_PREFIX}/CLEAR_ALERTS`,
}

export interface UiDocumentAlertsState {
  displayableSamAlerts: DocumentAlert[]
  acknowledgedSamAlerts: DocumentAlert[]
}
