import { DependencyList, useMemo, useRef } from 'react'

export function useHistorizedMemo<T>(factory: (previousValue?: T) => T, deps: DependencyList): T {
  const previousValue = useRef<T>()

  return useMemo(() => {
    const computed = factory(previousValue.current)
    previousValue.current = computed
    return computed

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}
