import { VariableKindPrefix, computeDrugVariableData, computeManualPrescription } from '@follow/cdk'
import getApi from '../api'
import { Drug, VigilanceLevelPictograms } from '../model/Drug'
import { format, parse } from 'date-fns'

export { computeDrugVariableData, computeManualPrescription }

export const openMonograph = async (drug: Drug) => {
  const { data } = await getApi().get<{ url: string }>(`drugs/${drug.id}/monograph`)
  if (data) {
    window.open(data.url)
  }
}

export const fetchAndOpenHTMLContent = async (url: string) => {
  const { data } = await getApi().get<string>(url)
  if (data) {
    const tab = window.open('about:blank', '_blank')
    tab?.document.write(data)
    tab?.document.close()
  }
}

export const openVidalDetailedReport = async (documentId: number) =>
  fetchAndOpenHTMLContent(`documents/${documentId}/alerts?type=html`)

export const openSMR = async (drug: Drug) => fetchAndOpenHTMLContent(`drugs/${drug.id}/smr`)

export const computePrescriptionVariableId = (prescriptionVariableUuid: string) => {
  return `${VariableKindPrefix.PRESCRIPTION}_${prescriptionVariableUuid}`
}

export const computeManualPrescriptionVariableId = (manualPrescriptionUuid: string) => {
  return `${VariableKindPrefix.MANUAL_PRESCRIPTION}_${manualPrescriptionUuid}`
}

export const checkDrugDivisibility = (divisibility: number | undefined, input: number) => {
  if (isNaN(input)) {
    return false
  }
  if (divisibility) {
    const reference = 1 / divisibility
    return input % reference === 0
  }
  return true
}

export const getVigilancePictogram = (
  vigilanceLevel: number | null,
): (typeof VigilanceLevelPictograms)[number] | null => {
  const actualLevel = vigilanceLevel === -1 || vigilanceLevel === null ? 0 : vigilanceLevel
  return VigilanceLevelPictograms[actualLevel] ?? null
}

export const getTextFromDrugGs1Code = (gs1Code: string) => {
  const cip = gs1Code.substring(3, 17)
  return `Code CIP : ${cip} / ${
    gs1Code.substring(17, 19) === '10'
      ? `Numéro de lot : ${gs1Code.substring(19, 24)} / Date d'expiration : ${format(
          parse(gs1Code.substring(26, 32), 'yyMMdd', new Date()),
          'dd-MM-yyyy',
        )}`
      : `Numéro de lot : ${gs1Code.substring(27, 32)} / Date d'expiration : ${format(
          parse(gs1Code.substring(19, 25), 'yyMMdd', new Date()),
          'dd-MM-yyyy',
        )}`
  }`
}
