import { UnmountTransitionProps } from '../../../misc/withUnmountTransition.hoc'
import { PatientInformations } from '../../../model/PatientInformations'

export interface PatientInformationsModalProps extends UnmountTransitionProps {
  patientId: number
  patientInformationsUpdate: PatientInformations | null
  rootId?: string
  onClose: () => void
}

export interface NewPatientInformations extends Omit<PatientInformations, 'id'> {
  startAt: Date | null
  endAt: Date | null
}

export const DEFAULT_PATIENT_INFORMATIONS: NewPatientInformations = {
  startAt: null,
  endAt: null,
  name: '',
  codedInformationId: undefined,
}
