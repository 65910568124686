import { FC, useCallback } from 'react'
import { SearchExportColumnDocumentTemplatesProps } from './SearchExportColumnDocumentTemplates.model'
import { SearchDocumentTemplates } from '../../../medicalEvent/SearchDocumentTemplates/SearchDocumentTemplates.component'
import { useSetAtom } from 'jotai'
import { exportColumnsAddDocumentTemplate } from '../../../../state/configurableExport'
import { DocumentTemplateListItem } from '../../../../model/DocumentTemplate'

export const SearchExportColumnDocumentTemplates: FC<SearchExportColumnDocumentTemplatesProps> = (
  props,
) => {
  const selectItem = useSetAtom(exportColumnsAddDocumentTemplate)

  const handleSelect = useCallback(
    (templates: Array<DocumentTemplateListItem>) => {
      selectItem(templates)
      props.setBottomPanelOptions({ open: false })
    },
    [props, selectItem],
  )

  return <SearchDocumentTemplates {...props} setSelection={(items) => handleSelect(items)} />
}
