import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { ErrorPandaMessage, FullLoader } from '../../../components/shared'
import { MailClassification } from '../../../components/mail'
import { downloadBlob } from '../../../misc/blob.utilities'
import { requestPrintByBlobUrl } from '../../../misc/print.utilities'
import { MailPageProps } from './MailPage.model'
import { pdfDataToUrl } from '../../../components/file/FileViewer/PdfViewerLegacy/pdf.utils'
import { MailContent } from '../../../components/mail/MailContent/MailContent.component'
import { MailFile } from '../../../store/domain/mail/api/mail.model'
import styles from './MailPage.module.scss'
import { useParams } from 'react-router-dom'
import { isDefined } from '../../../misc/functions.utilities'

export const MailPage: FunctionComponent<MailPageProps> = ({
  inUseMail,
  selectedFile,
  getAttachmentFile,
  inUseMailStatus,
  setAttachment,
  getInUseMail,
}) => {
  const { mailId } = useParams<'mailId'>()

  const [selectedAttachmentId, setSelectedAttachmentId] = useState<number | string>(0)

  const handleGetAttachmentFile = useCallback(
    (fileId: number, messageId: string, cdaFileId?: string) => {
      getAttachmentFile(messageId, fileId, cdaFileId)
    },
    [getAttachmentFile],
  )

  const handleDownloadOrPrintFile = (file: MailFile, mode: 'download' | 'print') => {
    if (file.base64) {
      fetch(file.base64)
        .then((res) => res.blob())
        .then((blob) => {
          if (mode === 'download') downloadBlob(blob, `${file.visibleName}.${file.extension}`)
          else requestPrintByBlobUrl(pdfDataToUrl(blob))
        })
    }
  }

  const handleClearAttachment = useCallback(() => setAttachment(), [setAttachment])

  // Fetch du mail désigné par l'URL
  useEffect(() => {
    if (isDefined(mailId)) {
      if (mailId !== inUseMail?.id) {
        getInUseMail(mailId)
      }
    }
  }, [getInUseMail, inUseMail?.id, mailId])

  useEffect(() => {
    setSelectedAttachmentId(inUseMail?.attachments.at(0)?.id ?? 0)
  }, [inUseMail?.attachments])

  return (
    <div className="w-full flex bg-white h-full">
      {inUseMailStatus === 'idle' && (
        /* Fallback, ne devrait jamais s'afficher */
        <div className={styles.noMailFallback}>
          <span>Sélectionnez un email pour continuer</span>
        </div>
      )}

      {inUseMailStatus === 'pending' && (
        <div className={styles.loaderContainer}>
          <FullLoader message="Chargement de l'email" />
        </div>
      )}

      {inUseMailStatus === 'error' && (
        <div className="px-10">
          <ErrorPandaMessage
            title="Une erreur est survenue lors du chargement de l'email"
            subTitle="Veuillez réessayer ultérieurement"
            backToHomePage={false}
          />
        </div>
      )}

      {inUseMailStatus === 'success' && (
        <>
          {inUseMail && (
            <div className="w-3/4">
              <MailContent
                inUseMail={inUseMail}
                selectedFile={selectedFile}
                selectedAttachmentId={selectedAttachmentId}
                setSelectedAttachmentId={setSelectedAttachmentId}
                handleGetAttachmentFile={handleGetAttachmentFile}
                handleDownloadOrPrintFile={handleDownloadOrPrintFile}
                onClear={handleClearAttachment}
              />
            </div>
          )}
          {inUseMail?.attachments && inUseMail.attachments.length > 0 && (
            <div className="w-1/4">
              <MailClassification inUseMail={inUseMail} />
            </div>
          )}
        </>
      )}
    </div>
  )
}
