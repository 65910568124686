import { createSelector } from 'reselect'
import { RootState } from '../../reducers'

const usedDocumentCategoriesListResourcesSelector = (state: RootState) =>
  state.domain.documentCategories.usedDocumentCategories

export const usedDocumentCategoriesSelector = createSelector(
  [usedDocumentCategoriesListResourcesSelector],
  (documentCategories) => Object.values(documentCategories),
)

const documentCategoriesSelectorTest = (state: RootState) => state.cache.documentCategories.list

export const documentCategoriesSelector = createSelector(
  [documentCategoriesSelectorTest],
  (documentCategories) => Object.values(documentCategories),
)
