import { MailSubjectState, MailSubjectActionTypes } from './mailSubject.model'

export const MailSubjectAction = {
  setMailSubject: (subject: MailSubjectState['subject']) => ({
    type: MailSubjectActionTypes.SET_MAIL_SUBJECT,
    subject,
  }),

  clearMailSubject: () => ({
    type: MailSubjectActionTypes.SET_MAIL_SUBJECT,
    subject: undefined,
  }),

  setDocumentsIds: (documentIds: MailSubjectState['documentIds']) => ({
    type: MailSubjectActionTypes.SET_DOCUMENTS_IDS,
    documentIds,
  }),

  clearDocumentsIds: () => ({
    type: MailSubjectActionTypes.SET_DOCUMENTS_IDS,
    documentIds: undefined,
  }),
}
