import { SelectOption } from './SelectOption'
import VigilanceLevel1 from '../assets/images/pictogram/picto-drug-1.png'
import VigilanceLevel2 from '../assets/images/pictogram/picto-drug-2.png'
import VigilanceLevel3 from '../assets/images/pictogram/picto-drug-3.png'
import VigilanceLevel5 from '../assets/images/pictogram/picto-drug-5.png'
import {
  AldStatus,
  DrugGenericTypes,
  DrugTypes,
  FollowTypes,
  UnsubstituableStatement,
} from '@follow/cdk'
import { SORT_DIRECTION } from './Resource'

export type DrugIdentity = FollowTypes.Drug.DrugIdentity
export type DrugUnit = FollowTypes.Drug.DrugUnit
export type DrugAdministrationRoute = FollowTypes.Drug.DrugAdministrationRoute
export type SideEffect = FollowTypes.Drug.SideEffect
export type BioSimilarGroup = FollowTypes.Drug.BioSimilarGroup
export type DrugSorting = FollowTypes.Drug.DrugSorting
export type Drug = FollowTypes.Drug.Drug
export { UnsubstituableStatement, DrugTypes, DrugGenericTypes, AldStatus }

export interface AtcClass {
  id: number
  title: string
  code: string
  parentExternalId?: number
  totalChildren: number
}

export interface ActivePrinciple {
  externalId: number
  name: string
}

export interface Indications {
  externalId: number
  name: string
}

export const drugSortOptions: SelectOption<DrugSorting>[] = [
  { label: 'Alphabétique (croissant)', value: { sortBy: 'name', direction: SORT_DIRECTION.ASC } },
  {
    label: 'Alphabétique (décroissant)',
    value: { sortBy: 'name', direction: SORT_DIRECTION.DESC },
  },
  { label: 'Prix (croissant)', value: { sortBy: 'price', direction: SORT_DIRECTION.ASC } },
  { label: 'Prix (décroissant)', value: { sortBy: 'price', direction: SORT_DIRECTION.DESC } },
]

export const UnsubstituableStatementLabels: { [key in UnsubstituableStatement]: string } = {
  MTE: 'Médicament à marge thérapeutique étroite',
  EFG: 'Médicaments génériques dont la forme galénique est non adaptée à un enfant de moins de 6 ans',
  CIF: 'Contre-indication formelle à un excipient à effet notoire dans tous les médicaments génériques disponibles',
}

export const VigilanceLevelPictograms: {
  [key in number]: { pictogram: string; tooltip: string }
} = {
  1: {
    pictogram: VigilanceLevel1,
    tooltip: `Niveau 1 : "Soyez prudent. Ne pas conduire sans avoir lu la notice."`,
  },
  2: {
    pictogram: VigilanceLevel2,
    tooltip: `Niveau 2 : "Soyez très prudent. Ne pas conduire sans l'avis d'un professionnel de santé."`,
  },
  3: {
    pictogram: VigilanceLevel3,
    tooltip: `Niveau 3 : "Attention, danger : ne pas conduire. Pour la reprise de la conduite, demandez l'avis d'un médecin."`,
  },
  5: {
    pictogram: VigilanceLevel5,
    tooltip: `Vigilance provisoire`,
  },
}
