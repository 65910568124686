import { Indications } from '../../../model/Drug'
import { IndicationsDomainActionsTypes } from './indications.model'

export const indicationsDomainActions = {
  search: (search: string) => ({
    type: IndicationsDomainActionsTypes.SEARCH,
    search,
  }),
  setIndicationsList: (indicationsList: Array<Indications>) => ({
    type: IndicationsDomainActionsTypes.SET_LIST,
    indicationsList,
  }),
}
