import styled from '@emotion/styled'
import React, { FunctionComponent, memo } from 'react'

import DropDown, { DropdownItem } from '../../../components/Dropdown'
import { FontSize } from './Toolbar.model'

const DropDownContainer = styled.div`
  width: 40px; // Pour éviter les variations de largeur lors du changement d'item
`

export const FontSizeDropdown: FunctionComponent<{
  values: ReadonlyArray<FontSize>
  value?: FontSize
  onClick: (textSize: FontSize) => void
}> = memo(({ values: config, value: actualSize, onClick }) => {
  return (
    <DropDownContainer>
      <DropDown renderTitle={() => <> {actualSize} </>} size="small">
        {config.map((textSize, index) => (
          <DropdownItem
            key={index}
            onClick={(event) => {
              event.preventDefault()
              onClick(textSize)
            }}
          >
            {textSize}
          </DropdownItem>
        ))}
      </DropDown>
    </DropDownContainer>
  )
})
