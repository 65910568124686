import getApi from '../../../api/apisauceConfig'
import { Filters } from '../../../model/Filters'
import { PaginatedList } from '../../../model/Pagination'
import { singleItemPageLimit } from '../../../constants'
import { Acte } from '../../../model/Acte'
import { deserializeActePageResponse } from './mapper/actes.mapper'

export const getActes = (
  page: number,
  filters?: Filters,
  signal?: AbortSignal,
  limit = singleItemPageLimit,
) =>
  getApi()
    .get<PaginatedList<Acte>>(
      '/ccam/actes',
      {
        page,
        limit,
        ...filters,
      },
      { signal },
    )
    .then(deserializeActePageResponse)
