import Keycloak from 'keycloak-js'
import React from 'react'
import { getKeycloakApi } from '../api/apisauceConfig'
import { AUTH_CLIENT_ID, AUTH_REALM, AUTH_SCOPES, AUTH_URL } from '../environment/auth'
import { User } from '../model/User'

const keycloak = new Keycloak({
  url: AUTH_URL,
  realm: AUTH_REALM,
  clientId: AUTH_CLIENT_ID,
})

keycloak.onAuthRefreshSuccess = () => {
  getKeycloakApi().post(
    '/realms/master/protocol/openid-connect/token',
    new URLSearchParams({
      grant_type: 'urn:ietf:params:oauth:grant-type:token-exchange',
      requested_token_type: 'urn:ietf:params:oauth:token-type:access_token',
      client_id: 'follow-app',
      subject_token: `${getToken()}`,
      requested_issuer: 'pro-sante-connect',
    }),
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  )
}

const initKeycloak = async () => {
  await keycloak.init({
    onLoad: 'check-sso',
    pkceMethod: 'S256',
    checkLoginIframe: false,
  })
}

const requestLogin = () => keycloak.login({ scope: AUTH_SCOPES })

const requestLogout = () => keycloak.logout({ redirectUri: window.location.origin })

const getToken = () => keycloak.token

const isLogged = () => !!keycloak.token

const getTokenInfo = () => keycloak.tokenParsed

const updateToken = () => {
  keycloak.updateToken(5).catch(() => {
    requestLogin()
  })
}

export const ConnectedUserContext = React.createContext<{
  loggedUser: User | null
  currentUser: User | null
  usurpedUser: User | null
}>({
  loggedUser: null,
  currentUser: null,
  usurpedUser: null,
})

export const AuthService = {
  initKeycloak,
  requestLogin,
  requestLogout,
  getToken,
  getTokenInfo,
  isLogged,
  updateToken,
}
