import { restuxCacheFactory } from '../../restux/cache'
import { RestuxResources } from '../../resources'
import { ResourceHistoryDetails } from '../../../model/ResourceHistory'
import { restuxResourceHistoryApiConfig } from './api'

const resourceName = RestuxResources.resourceHistory

const { actions, reducers, sagas } = restuxCacheFactory<
  ResourceHistoryDetails,
  ResourceHistoryDetails
>({
  apiConfig: restuxResourceHistoryApiConfig,
  refetchList: true,
  resourceName,
})

export const resourceHistoryActions = actions
export const resourceHistoryReducers = reducers
export const resourceHistorySagas = sagas
