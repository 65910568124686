import React, { FunctionComponent } from 'react'
import { FormikTextInput, FormBlock } from '../../../shared'
import { ContactInformationsBlockProps } from './ContactInformationsBlock.model'
import { FormikInternationalPhoneInput } from '../../../shared/inputs/formik/Inputs/FormikInternationalPhoneInput'
import { useSearchParams } from 'react-router-dom'

export const ContactInformationsBlock: FunctionComponent<ContactInformationsBlockProps> = ({
  role,
}) => {
  const [searchParams] = useSearchParams()

  return (
    <FormBlock label="Coordonnées" icon="profile">
      <FormikInternationalPhoneInput fieldName="phone" label="Téléphone mobile" />
      <FormikTextInput
        fieldName="subPhone"
        colorPreset="light"
        label="Téléphone fixe"
        autocomplete={false}
      />
      <FormikTextInput
        fieldName="email"
        colorPreset="light"
        label="E-mail *"
        autocomplete={false}
      />
      <FormikTextInput
        fieldName="mailizEmailAddress"
        colorPreset="light"
        label="E-mail MSS Mailiz"
        autocomplete={false}
        autofocus={searchParams.get('focus') === 'mailizEmailAddress'}
      />
    </FormBlock>
  )
}
