import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Task } from '../../../model/Task'
import { UserFriendlyError } from '../../../model/Error'
import { PaginatedList } from '../../../model/Pagination'
import { deleteAllDoneTasks } from '../../../data/task/deleteAllDoneTasks'
import { taskKeys } from './tasks.keys'

export const useDeleteAllDoneTasks = () => {
  const queryClient = useQueryClient()

  return useMutation<null>(
    async () => {
      const { ok } = await deleteAllDoneTasks()
      if (!ok) {
        throw new UserFriendlyError('error', 'La suppression des tâches a échoué')
      }
      return null
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries<PaginatedList<Task>>({
          queryKey: taskKeys.all,
        })
      },
    },
  )
}
