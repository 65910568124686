import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import Logo from '../../../../../assets/images/logo.png'
import { NavigationMenuProps } from '../NavigationMenu.model'
import { AuthService } from '../../../../../misc/auth.utilities'
import { hasExtDsmRole, hasStaffRole } from '../../../../../misc/roles.utilities'
import { InfiniteLoader, UpdateModal } from '../../../../shared'
import { AvatarsTab } from './AvatarsTab'
import { NavigationFloatingMenuTab } from './NavigationFloatingMenuTab'
import { AUTHENTICATION_ID_MAIL } from '../../../../../model/Mail'
import { AgendaTab } from './AgendaTab'
import { addZendeskEventListener } from '../../../../../analytics'
import styles from './DesktopNavigationMenu.module.scss'
import { NotificationType } from '../../../../../model/Notification'
import { NotificationModal } from '../../NotificationModal'
import RoleRestriction from '../../../../../containers/RoleRestriction'
import { ADMIN_ROLES, Roles } from '../../../../../model/Roles'
import { DMP_URL } from '../../../../../misc/dmp.utilities'
import { DesktopNavigationContext } from './DesktopNavigationMenu.context'
import classNames from 'classnames'
import { ADEL_URL } from '../../../../../misc/adel.utilities'
import { NavTab } from './NavTab'
import { STELLAIR_APP_URL } from '../../../../../environment/stellair'
import { ActionTab } from './ActionTab'
import { useGetTaskCount } from '../../../../../hooks/queries/task'
import { useGetInfiniteUserNotifications } from '../../../../../hooks/queries/notifications'
import { SEPHIRA_APP_URL } from '../../../../../environment/sephira'
import { useGetSephiraToken } from '../../../../../hooks/queries/billing'
import { LegalDocumentModal } from '../../../../shared/modal/LegalDocumentModal'
import { useGetLegalDocument } from '../../../../../hooks/queries/legalDocument'

export const DesktopNavigationMenu: FunctionComponent<NavigationMenuProps> = ({
  loading,
  currentUser,
  userEnabledFeatures,
  loggedUser,
  setUsurpedUser,
}) => {
  const [isZendeskOpen, setIsZendeskOpen] = useState(false)
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false)
  const [openNotificationModal, setOpenNotificationModal] = useState(false)
  const [openLegalDocumentModal, setOpenLegalDocumentModal] = useState(
    !!loggedUser?.legalDocumentNeedRead?.length ?? false,
  )
  const isExtDSM = loggedUser && hasExtDsmRole(loggedUser.roles)
  const { data: unreadTaskNotificationCount } = useGetTaskCount(
    { read: false },
    { infinityStale: true },
  )
  const { paginationState: unreadOtherNotifications } = useGetInfiniteUserNotifications({
    filters: {
      types: [NotificationType.announcement, NotificationType.incident],
      unread: true,
    },
  })
  const { paginationState: unreadFeatureNotifications } = useGetInfiniteUserNotifications({
    filters: {
      types: [NotificationType.feature],
      unread: true,
    },
  })
  const { data: legalDocument } = useGetLegalDocument(loggedUser?.legalDocumentNeedRead?.at(0))

  const { data: sephiraToken } = useGetSephiraToken(
    userEnabledFeatures?.vitalCardService === 'sephira',
  )

  const openUpdateModal = () => {
    setIsUpdateModalOpen(true)
  }

  const handleCloseLegalModal = useCallback(() => {
    setOpenLegalDocumentModal(false)
  }, [])

  const handleShowNotificationSidebar = () => {
    setOpenNotificationModal(!openNotificationModal)
  }

  useEffect(() => {
    addZendeskEventListener('close', () => setIsZendeskOpen(false))
    addZendeskEventListener('open', () => setIsZendeskOpen(true))
    return () => {
      addZendeskEventListener('close', undefined)
      addZendeskEventListener('open', undefined)
    }
  }, [])

  const VITAL_SERVICE_URL = useMemo(() => {
    const vitalService = userEnabledFeatures?.vitalCardService

    switch (vitalService) {
      case 'sephira':
        return `${SEPHIRA_APP_URL}invoices?Token=${sephiraToken?.token}`
      case 'stellair':
        return STELLAIR_APP_URL
      default:
        return undefined
    }
  }, [userEnabledFeatures?.vitalCardService, sephiraToken])

  return (
    <DesktopNavigationContext.Provider value={{ hideTooltips: isZendeskOpen }}>
      <div
        className={`flex flex-col justify-between w-20 h-screen text-white ${
          currentUser &&
          loggedUser &&
          currentUser.id !== loggedUser?.id &&
          hasStaffRole(loggedUser.roles)
            ? 'bg-amber-500'
            : 'bg-primary-default'
        }`}
      >
        <div className="shrink-0 flex items-center justify-center w-full h-20 bg-no-repeat bg-center bg-contain overflow-hidden">
          {!loading ? (
            <img
              className="m-auto pl-[1px] w-1/2 h-full align-middle object-contain"
              src={Logo}
              alt="follow-logo"
            />
          ) : (
            <div className="mt-1.5">
              <InfiniteLoader loading />
            </div>
          )}
        </div>
        <div className={classNames(styles.links)} data-test-id="nav-links">
          <RoleRestriction roles={[Roles.DOCTOR]} useUsurpedUser>
            {!isExtDSM && (
              <NavTab label="Patients" icon="profile" link="/patients" testId="nav-link-patient" />
            )}
            {userEnabledFeatures?.agenda && !isExtDSM && (
              <AgendaTab label="Agenda" agendaType={userEnabledFeatures.agenda} />
            )}
            {!isExtDSM && (
              <NavTab
                label="Mes tâches"
                icon="documentCheck"
                link="/tasks"
                notification={unreadTaskNotificationCount}
                testId="nav-link-tasks"
              />
            )}
            {userEnabledFeatures?.vitalCardService && VITAL_SERVICE_URL && !isExtDSM && (
              <NavTab
                label="Facturation"
                icon="teletransmission"
                newTab
                link={VITAL_SERVICE_URL}
                testId="nav-link-billing"
              />
            )}
          </RoleRestriction>

          <NavTab label="Manager" icon="settings" link="/managers" testId="nav-link-manager" />

          <RoleRestriction roles={[Roles.DOCTOR]} useUsurpedUser={!isExtDSM}>
            {userEnabledFeatures?.mail && (
              <NavTab
                label="Messagerie"
                icon="mail"
                link="/mail/inbox"
                additionnalRouteMatch="/authenticate/mail"
                testId="nav-link-mail"
              />
            )}
            {userEnabledFeatures?.dmp && (
              <NavTab
                label="Dossier Médical Partagé (DMP)"
                icon="folderUser"
                newTab
                link={DMP_URL}
                testId="nav-link-dmp"
              />
            )}
            {userEnabledFeatures?.adel && (
              <NavTab
                label="Adel santé"
                icon="adel"
                iconSize="macro"
                newTab
                link={ADEL_URL}
                testId="nav-link-adel"
              />
            )}
          </RoleRestriction>

          <RoleRestriction roles={[...ADMIN_ROLES, Roles.EXT_DSM]} useUsurpedUser>
            <NavTab label="Admin" icon="smiles" link="/admin" testId="nav-link-admin" />
          </RoleRestriction>
        </div>
        <AvatarsTab
          loggedUser={loggedUser}
          currentUser={currentUser}
          setUsurpedUser={setUsurpedUser}
        />
        <div className="relative">
          {unreadOtherNotifications && unreadOtherNotifications.itemCount > 0 && (
            <div className="absolute rounded-full text-center bg-red-600 top-2 z-max right-3 w-3.5 h-3.5 font-bold text-xs">
              {unreadOtherNotifications.itemCount}
            </div>
          )}
          <ActionTab
            label={'Notifications'}
            icon={'bell'}
            onClick={() => handleShowNotificationSidebar()}
            testId="nav-notifications"
          />
        </div>
        {openNotificationModal && (
          <NotificationModal open={openNotificationModal} setOpen={setOpenNotificationModal} />
        )}
        <NavigationFloatingMenuTab
          currentUser={currentUser}
          loggedUser={loggedUser}
          setUsurpedUser={setUsurpedUser}
          userEnabledFeatures={userEnabledFeatures}
          onLogout={() => {
            setUsurpedUser()
            localStorage.removeItem(AUTHENTICATION_ID_MAIL)
            AuthService.requestLogout()
          }}
        />
        <div
          className="relative text-center py-2 font-bold text-shades-white hover:bg-white hover:bg-opacity-20 cursor-pointer"
          onClick={openUpdateModal}
          data-test-id="label-version"
        >
          {unreadFeatureNotifications && unreadFeatureNotifications.itemCount > 0 && (
            <div className="absolute rounded-full bg-red-600 top-1 right-1.5 w-3.5 h-3.5 font-bold text-xs">
              {unreadFeatureNotifications.itemCount}
            </div>
          )}
          v{process.env.REACT_APP_VERSION}
        </div>
        <UpdateModal display={isUpdateModalOpen} onClose={() => setIsUpdateModalOpen(false)} />
        <LegalDocumentModal
          display={openLegalDocumentModal}
          onClose={handleCloseLegalModal}
          legalDocument={legalDocument}
        />
      </div>
    </DesktopNavigationContext.Provider>
  )
}
