import { RestuxResources } from '../../resources'
import { RestuxInUse, RestuxPagination, RestuxUiSagaConf, uiActionsFactory } from '../../restux/ui'
import { countriesPaginationSelector } from './countries.selectors'

export const countriesConfig: RestuxUiSagaConf = {
  resourceName: RestuxResources.countries,
  paginationSelector: countriesPaginationSelector,
}

export interface CountriesState {
  pagination: RestuxPagination
  inUse: RestuxInUse
}

export const uiActions = uiActionsFactory(countriesConfig)

export const uiCountriesActionTypes = {
  ...uiActions.types,
}
