import React, { FunctionComponent } from 'react'
import styles from './RadioGroup.module.scss'
import { RadioGroupContext } from './RadioGroup.context'
import { RadioGroupProps } from './RadioGroup.model'

export const RadioGroup: FunctionComponent<RadioGroupProps> = ({
  name,
  label,
  value,
  onChange,
  disabled = false,
  children,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event, event.target.value)
    }
  }
  return (
    <div className={styles.radioGroup}>
      {label && <label className={styles.label}>{label}</label>}
      <div className={styles.wrapper}>
        <RadioGroupContext.Provider value={{ name, value, onChange: handleChange, disabled }}>
          {children}
        </RadioGroupContext.Provider>
      </div>
    </div>
  )
}
