import React, { FunctionComponent } from 'react'
import { Icon, Switch } from '../../../shared'
import { OtpInformationsBlockProps } from './OtpInformationsBlock.model'

export const OtpInformationsBlock: FunctionComponent<OtpInformationsBlockProps> = ({
  value,
  onSwitchOtp,
}) => {
  return (
    <div className="flex flex-col items-center w-full mt-4 bg-shades-8 rounded-md p-4 shadow-md">
      <div className="flex flex-row w-full items-center space-x-2">
        <Icon icon="lock" size="nano" />
        <p className="text-lg font-medium text-shades-2">Double Authentification</p>
      </div>
      <div className="text-base text-shades-4 mt-4">
        Vous avez la possibilité de renforcer la sécurité de votre compte en activant la connexion
        en deux étapes.
      </div>
      <div className="border-t border-shades-6 w-full pt-4 mt-4 font-medium text-shades-2">
        <Switch
          theme="primary"
          name="Activer la double authentification"
          checked={value}
          onChange={onSwitchOtp}
        />
      </div>
    </div>
  )
}
