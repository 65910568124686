import { Reducer, createReducer } from 'redux-starter-kit'
import { metricsActions } from './metrics.actions'
import { MetricsState, metricsActionsType } from './metrics.model'

const METRICS_DEFAULT_STATE: MetricsState = {
  messageSendingInfos: {
    messageSent: undefined,
    messageSentToDoctor: undefined,
    messageSentToPatient: undefined,
    dmpDocumentSentToPatient: undefined,
  },
  requestStatus: 'idle',
}

const ACTION_HANDLERS = {
  [metricsActionsType.GET_LIST_OF_METRICS_PENDING]: (
    state: MetricsState = METRICS_DEFAULT_STATE,
  ): MetricsState => ({
    ...state,
    requestStatus: 'pending',
  }),

  [metricsActionsType.GET_LIST_OF_METRICS_FAILED]: (
    state: MetricsState = METRICS_DEFAULT_STATE,
  ): MetricsState => ({
    ...state,
    requestStatus: 'error',
  }),

  [metricsActionsType.GET_LIST_OF_METRICS_SUCCESS]: (
    state: MetricsState = METRICS_DEFAULT_STATE,
    { metrics }: ReturnType<typeof metricsActions.getMetricsSuccess>,
  ): MetricsState => ({
    ...state,
    messageSendingInfos: metrics,
    requestStatus: 'success',
  }),

  [metricsActionsType.CLEAR_METRICS]: (): MetricsState => METRICS_DEFAULT_STATE,
}

export const metricsReducer: Reducer = createReducer<MetricsState, typeof ACTION_HANDLERS>(
  METRICS_DEFAULT_STATE,
  ACTION_HANDLERS,
)
