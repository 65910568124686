import { RESET, atomWithReset } from 'jotai/utils'
import {
  ConfigurableExportColumns,
  EXPORT_PATIENT_COLUMNS_PARTIAL,
} from '../../model/ConfigurableExport'
import { atom } from 'jotai'
import { LightQuestionnaire } from '../../model/Questionnaire'
import { addItemIfMissing } from './exportAtoms.utils'
import { DocumentTemplateListItem } from '../../model/DocumentTemplate'
import { unionBy } from 'lodash'

export const EXPORTS_DEFAULT_COLUMNS: ConfigurableExportColumns = {
  documentTemplates: [],
  questionnaires: [],
  assignatedDoctor: false,
  addressingDoctor: false,
  patient: EXPORT_PATIENT_COLUMNS_PARTIAL,
}

const exportColumnsAtom = atomWithReset(EXPORTS_DEFAULT_COLUMNS)
export const exportColumnsStateAtom = atom(
  (get) => get(exportColumnsAtom),
  (_, set) => set(exportColumnsAtom, RESET),
)

export const exportColumnsSetOptions = atom(
  null,
  (
    get,
    set,
    options: Partial<SafeOmit<ConfigurableExportColumns, 'documentTemplates' | 'questionnaires'>>,
  ) => {
    const currentValue = get(exportColumnsAtom)
    set(exportColumnsAtom, {
      ...currentValue,
      ...options,
    })
  },
)

// Questionnaires
export const exportColumnsAddQuestionnaire = atom(null, (get, set, item: LightQuestionnaire) => {
  const currentValue = get(exportColumnsAtom)
  const questionnaires = addItemIfMissing(currentValue.questionnaires, item)
  set(exportColumnsAtom, {
    ...currentValue,
    questionnaires,
  })
})

export const exportColumnsRemoveQuestionnaire = atom(null, (get, set, itemId: number) => {
  const currentValue = get(exportColumnsAtom)
  const questionnaires = currentValue.questionnaires.filter(({ id }) => itemId !== id)
  set(exportColumnsAtom, {
    ...currentValue,
    questionnaires,
  })
})

// Document Templates
export const exportColumnsAddDocumentTemplate = atom(
  null,
  (get, set, items: Array<DocumentTemplateListItem>) => {
    const currentValue = get(exportColumnsAtom)
    const documentTemplates = unionBy(currentValue.documentTemplates, items, 'id')
    set(exportColumnsAtom, {
      ...currentValue,
      documentTemplates,
    })
  },
)

export const exportColumnsRemoveDocumentTemplate = atom(null, (get, set, itemId: number) => {
  const currentValue = get(exportColumnsAtom)
  const documentTemplates = currentValue.documentTemplates.filter(({ id }) => itemId !== id)
  set(exportColumnsAtom, {
    ...currentValue,
    documentTemplates,
  })
})
