import styled from '@emotion/styled'
import React, { FunctionComponent, memo } from 'react'
import { BlockProperties } from 'slate'

import DropDown, { DropdownItem } from '../../../components/Dropdown'
import { NodeType } from '../../model/TextEditor.model'
import { BlockType } from './Toolbar.model'

const BLOCK_TYPE_TO_LABEL: { [textSize in BlockType]: string } = {
  title1: 'Titre 1',
  title2: 'Titre 2',
  title3: 'Titre 3',
  paragraph: 'Normal',
}

type ListItemType = FunctionComponent<{ onClick: (type: BlockProperties) => void }>

const DropDownContainer = styled.div`
  width: 75px; // Pour éviter les variations de largeur lors du changement d'item
`
const ITEMS_COMPONENTS: { [key in BlockType]: ListItemType } = {
  title1: ({ onClick }) => (
    <DropdownItem
      key="title1"
      onClick={(event) => {
        event.preventDefault()
        onClick({ type: NodeType.TITLE1, data: { fontSize: 30 } })
      }}
    >
      {BLOCK_TYPE_TO_LABEL.title1}
    </DropdownItem>
  ),
  title2: ({ onClick }) => (
    <DropdownItem
      key="title2"
      onClick={(event) => {
        event.preventDefault()
        onClick({ type: NodeType.TITLE2, data: { fontSize: 18 } })
      }}
    >
      {BLOCK_TYPE_TO_LABEL.title2}
    </DropdownItem>
  ),
  title3: ({ onClick }) => (
    <DropdownItem
      key="title3"
      onClick={(event) => {
        event.preventDefault()
        onClick({ type: NodeType.TITLE3, data: { fontSize: 16 } })
      }}
    >
      {BLOCK_TYPE_TO_LABEL.title3}
    </DropdownItem>
  ),
  paragraph: ({ onClick }) => (
    <DropdownItem
      key="paragraph"
      onClick={(event) => {
        event.preventDefault()
        onClick({ type: NodeType.PARAGRAPH, data: { fontSize: 14 } })
      }}
    >
      {BLOCK_TYPE_TO_LABEL.paragraph}
    </DropdownItem>
  ),
}

function isBlockType(value: string | undefined, values: BlockType[]): value is BlockType {
  return typeof value === 'string' && values.map((type) => type.toString()).includes(value)
}

export const BlockTypeDropdown: FunctionComponent<{
  values: BlockType[]
  value?: string | BlockType
  onClick: (block: BlockProperties) => void
}> = memo(({ values, value, onClick }) => {
  const dropdownLabel = isBlockType(value, values)
    ? BLOCK_TYPE_TO_LABEL[value]
    : BLOCK_TYPE_TO_LABEL.paragraph

  return (
    <DropDownContainer>
      <DropDown renderTitle={() => <> {dropdownLabel} </>} size="small">
        {values.map((item, index) => {
          const Component = ITEMS_COMPONENTS[item]
          return <Component key={index} onClick={onClick} />
        })}
      </DropDown>
    </DropDownContainer>
  )
})
