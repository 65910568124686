import { FC, useCallback } from 'react'
import { useEffectWithRef } from '../../../hooks/utils'
import { ResourceHistoryDetails } from '../../../model/ResourceHistory'
import { List, AbstractList } from '../../shared'
import { ResourceHistoryPanelProps } from './ResourceHistoryPanel.model'
import { ResourceHistoryPanelItem } from './ResourceHistoryPanelItem'
import { RestuxLoadingState } from '../../../store/restux/ui'
import { singleItemPageLimit } from '../../../constants'
import { isDefined } from '../../../misc/functions.utilities'
import styles from './ResourceHistoryPanel.module.scss'

export const ResourceHistoryPanel: FC<ResourceHistoryPanelProps> = ({
  historyPages: { items, totalItems, totalPages, loadingState },
  historyResourceEntity,
  getResourceHistory,
}) => {
  const [listRef, setListRef] = useEffectWithRef<AbstractList<ResourceHistoryDetails>>(() => {
    if (listRef) {
      listRef.updateList(true)
    }
  }, [])

  const fetchHistory = useCallback(
    (page = 1) => {
      if (isDefined(historyResourceEntity)) {
        getResourceHistory({
          page: { currentPage: page, pageSize: singleItemPageLimit },
          filters: { ...historyResourceEntity },
        })
      }
    },
    [getResourceHistory, historyResourceEntity],
  )

  return (
    <div className={styles['container']}>
      <List
        fullPageMode={false}
        ref={setListRef}
        items={items}
        loading={loadingState !== RestuxLoadingState.IDLE}
        itemCount={totalItems}
        pageCount={totalPages}
        onUpdateList={fetchHistory}
        renderItem={(item) =>
          historyResourceEntity && (
            <ResourceHistoryPanelItem historyDetail={item} type={historyResourceEntity.type} />
          )
        }
      />
    </div>
  )
}
