import { FC } from 'react'
import { getCssVariable } from '../../../design-system/utils'
import { Icon } from '../../shared'
import { AddEprescriptionTileProps } from './AddEprescriptionTile.model'

export const AddEprescriptionTile: FC<AddEprescriptionTileProps> = ({ onClick }) => {
  return (
    <div
      className="flex items-center justify-center flex-col w-full h-full p-3 bg-dimmer bg-opacity-60 hover:bg-opacity-40 cursor-pointer"
      onClick={onClick}
    >
      <Icon icon="pills" color={getCssVariable('shades', 'white')} size="macro" />
      <h1 className="text-shades-white text-xl text-center mt-2">
        Créer une nouvelle ePrescription
      </h1>
    </div>
  )
}
