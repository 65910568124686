import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import { useAtomValue } from 'jotai'
import {
  PatientTransversalNavigationDocumentFilters,
  PatientTransversalNavigationMedicalEventList,
  PatientTransversalNavigationPageNavBar,
  PatientTransversalNavigationEventDetails,
  PatientTransversalNavigationMedicalEventNoFilterResults,
} from '../../../components/patient'
import { useCurrentPatient } from '../../../hooks/utils'
import { UrlSearchParams } from '../../../model/Navigation'
import { MedicalEvent } from '../../../model/MedicalEvent'
import { isDefined } from '../../../misc/functions.utilities'
import { useGetInfiniteMedicalEvents } from '../../../hooks/queries/medicalEvents'
import { Loader } from '../../../components/shared'
import { scrolledItemIdAtom } from '../../../state/navTrans'
import styles from './PatientTransversalNavigationPage.module.scss'

export interface MedicalEventsDocumentsFilters {
  fileAndDocumentType: string[]
}

export const TRANSVERSAL_NAVIGATION_MEDICAL_EVENTS_PAGE_SIZE = 5

const cx = classNames.bind(styles)

const getEventUrl = (event: MedicalEvent) => {
  const eventFirstDocId = event.documents[0]?.id.toString()
  const eventFirstFileId = event.files[0]?.id.toString()
  let hash = ''
  if (eventFirstDocId) hash = `#document=${eventFirstDocId}`
  if (!eventFirstDocId && eventFirstFileId) hash = `#file=${eventFirstFileId}`
  return `?eventId=${event.id}${hash}`
}

const getIdFromHash = (hash: string) => {
  const hashId = hash.match(/\d+/)
  return hashId && Number.parseInt(hashId[0])
}

export const hasDocuments = (event: MedicalEvent) =>
  !!event.documents.length || !!event.files.length

export const PatientTransversalNavigationPage: FunctionComponent = () => {
  const navigate = useNavigate()
  const { hash } = useLocation()
  const { currentPatient: patient } = useCurrentPatient()
  const scrolledDocOrFileId = useAtomValue(scrolledItemIdAtom)
  const [navigateToNextPageEvent, setNavigateToNextPageEvent] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const eventId = searchParams.get(UrlSearchParams.eventId)
  const fileAndDocumentType = searchParams.get(UrlSearchParams.fileAndDocumentType)

  const queryFilters = useMemo(
    () => ({
      patientId: `${patient?.id}`,
      ...(fileAndDocumentType ? { fileAndDocumentType } : {}),
    }),
    [fileAndDocumentType, patient?.id],
  )

  const filters = useMemo(
    () => ({
      fileAndDocumentType: fileAndDocumentType ? fileAndDocumentType.split(',') : [],
    }),
    [fileAndDocumentType],
  )

  const {
    query: { isLoading, fetchNextPage: fetchNextMedicalEvents, hasNextPage, isFetchingNextPage },
    medicalEventList,
    paginationState: medicalEventPagination,
  } = useGetInfiniteMedicalEvents({
    filters: queryFilters,
    limit: TRANSVERSAL_NAVIGATION_MEDICAL_EVENTS_PAGE_SIZE,
    enabled: isDefined(patient),
  })

  const handleFiltersChange = useCallback(
    (filters: MedicalEventsDocumentsFilters) => {
      const docTypesFilters = filters.fileAndDocumentType
      const docTypesParams = `${UrlSearchParams.fileAndDocumentType}=${docTypesFilters.toString()}`
      const urlRest = docTypesFilters.length ? `&${docTypesParams}${hash}` : `${hash}`
      navigate(`?eventId=${eventId}${urlRest}`, { replace: true })
    },
    [eventId, hash, navigate],
  )

  const { currentEvent, currentEventIndex } = useMemo(() => {
    const index = medicalEventList.findIndex((event) => event.id.toString() === eventId)
    if (index === -1 && hasNextPage) fetchNextMedicalEvents()
    const event = medicalEventList[index]
    return { currentEvent: event, currentEventIndex: index }
  }, [eventId, fetchNextMedicalEvents, hasNextPage, medicalEventList])

  const isLastEventFromPage = useMemo(
    () => medicalEventList.length === currentEventIndex + 1,
    [currentEventIndex, medicalEventList.length],
  )

  const hasNextEventOnNextPage = useMemo(
    () => (isLastEventFromPage && hasNextPage) ?? false,
    [hasNextPage, isLastEventFromPage],
  )

  const hasNextEvent = useMemo(
    () => !isLastEventFromPage || hasNextEventOnNextPage,
    [hasNextEventOnNextPage, isLastEventFromPage],
  )

  const navigateToNextEvent = useCallback(() => {
    const nextEvent = medicalEventList[currentEventIndex + 1]
    if (nextEvent) navigate(getEventUrl(nextEvent), { replace: true })
  }, [currentEventIndex, medicalEventList, navigate])

  const handleNextEvent = useCallback(() => {
    if (hasNextEventOnNextPage) {
      fetchNextMedicalEvents()
      setNavigateToNextPageEvent(true)
    } else {
      navigateToNextEvent()
    }
  }, [fetchNextMedicalEvents, hasNextEventOnNextPage, navigateToNextEvent])

  const docOrFileIdFromUrlHash = useMemo(() => getIdFromHash(hash), [hash])

  const firstDocOrFileId = useMemo(() => {
    if (currentEvent) {
      const eventFirstDocId = currentEvent.documents[0]?.id
      const eventFirstFileId = currentEvent.files[0]?.id
      return eventFirstDocId ?? eventFirstFileId
    }
  }, [currentEvent])

  useEffect(() => {
    if (!eventId && medicalEventList[0]) {
      setSearchParams((prevParams) => {
        const updatedParams = new URLSearchParams(prevParams)
        updatedParams.set('eventId', medicalEventList[0].id.toString())
        return updatedParams
      })
    }
  }, [eventId, medicalEventList, setSearchParams])

  useEffect(() => {
    if (currentEvent && !hash && hasDocuments(currentEvent)) {
      navigate(getEventUrl(currentEvent))
    }
  }, [currentEvent, hash, navigate])

  useEffect(() => {
    if (!isFetchingNextPage && navigateToNextPageEvent) {
      navigateToNextEvent()
      setNavigateToNextPageEvent(false)
    }
  }, [isFetchingNextPage, navigateToNextEvent, navigateToNextPageEvent])

  return (
    <>
      <PatientTransversalNavigationPageNavBar patient={patient} />
      <div className={styles.pageContent}>
        <div className={styles.listAndFiltersContainer}>
          <div>
            <div className={styles.filters}>
              <PatientTransversalNavigationDocumentFilters
                filters={filters}
                onFiltersChange={handleFiltersChange}
              />
            </div>
            <div className={styles.listTitle}>
              <h3>Liste des évènements</h3>
            </div>
          </div>
          <PatientTransversalNavigationMedicalEventList
            filters={filters}
            medicalEventList={medicalEventList}
            isLoading={isLoading}
            hasNextPage={hasNextPage}
            currentPage={medicalEventPagination?.currentPage}
            fetchNextMedicalEvents={fetchNextMedicalEvents}
            eventId={eventId!}
            selectedDocOrFileId={docOrFileIdFromUrlHash}
            focusedDocOrFileId={scrolledDocOrFileId}
            isFetchingNextPage={isFetchingNextPage}
          />
        </div>
        <div
          className={cx(styles.eventDetails, {
            [styles.eventWithoutDocs]: currentEvent && !hasDocuments(currentEvent),
          })}
        >
          {isLoading ? (
            <Loader />
          ) : currentEvent ? (
            <PatientTransversalNavigationEventDetails
              event={currentEvent}
              hasNextEvent={hasNextEvent}
              onNextEventClick={handleNextEvent}
              key={currentEvent.id}
              selectedDocOrFileId={docOrFileIdFromUrlHash}
              firstDocOrFileId={firstDocOrFileId}
            />
          ) : filters.fileAndDocumentType.length ? (
            <PatientTransversalNavigationMedicalEventNoFilterResults />
          ) : null}
        </div>
      </div>
    </>
  )
}
