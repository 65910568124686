export const RESTUX_IDENTIFIER = {
  questionEdition: 'questionEdition',
  createDocumentLayout: 'createDocumentLayout',
  createFileTemplate: 'createFileTemplate',
  createQuestion: 'createQuestion',
  createQuestionnaireFromManager: 'createQuestionnaireFromManager',
  contextualMenuQuestionnaire: 'contextualMenuQuestionnaire',
  me: 'me',
  addAnswerToQuestionSaga: 'addAnswerToQuestionSaga',
  addExistingQuestionToDocumentInstance: 'addExistingQuestionToDocumentInstance',
  addExistingQuestionToDocumentTemplate: 'addExistingQuestionToDocumentTemplate',
  addQuestionToDocumentInstanceSaga: 'addQuestionToDocumentInstanceSaga',
  addQuestionToDocumentTemplateSaga: 'addQuestionToDocumentTemplateSaga',
  createDocumentTemplateSaga: 'createDocumentTemplateSaga',
  createPatient: 'createPatient',
  updatePatient: 'updatePatient',
  fromDoctolib: 'fromDoctolib',
  updateDocumentTemplateSaga: 'updateDocumentTemplateSaga',
  manageDocumentTemplate: 'manageDocumentTemplate',
  medicalEventQuestionnaire: 'medicalEventQuestionnaire',
  medicalEventDocumentInstance: 'medicalEventDocumentInstance',
  medicalEventContent: 'medicalEventContent',
  medicalEventFiles: 'medicalEventFiles',
  updateMedicalEvent: 'updateMedicalEvent',
  pinMedicalEvent: 'pinMedicalEvent',
  documentInstanceEdition: 'documentInstanceEdition',
  deleteQuestionFromDocumentTemplate: 'deleteQuestionFromDocumentTemplate',
  deleteDocumentFromMedicalEvent: 'deleteDocumentFromMedicalEvent',
  updateDocumentTitleFromMedicalEvent: 'updateDocumentTitleFromMedicalEvent',
  deleteTeam: 'deleteTeam',
  updateTask: 'updateTask',
  fetchDocumentTemplate: 'fetchDocumentTemplate',
}
