import { PluginNames } from '@/model/Plugins'
import { Extension } from '@tiptap/react'
import { BlockSpacingValueOptions, DEFAULT_BLOCKSPACING_VALUE } from './blockSpacing.model'

export interface BlockSpacingOptions {
  types: string[]
  spacings: BlockSpacingValueOptions
}

export interface BlockSpacingStorage {
  /**
   * Retourne les options du plugin BlockSpacing
   */
  getOptions: () => BlockSpacingOptions
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    [PluginNames.BlockSpacing]: {
      /**
       * Définie la marge du bloc courant
       */
      setBlockSpacing: (space: string | null) => ReturnType
    }
  }

  interface CustomStorage {
    [PluginNames.BlockSpacing]: BlockSpacingStorage
  }
}

export const BlockSpacing = Extension.create<BlockSpacingOptions, BlockSpacingStorage>({
  name: PluginNames.BlockSpacing,

  addOptions() {
    return {
      types: ['heading', 'paragraph'],
      spacings: DEFAULT_BLOCKSPACING_VALUE,
    }
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          blockSpacing: {
            parseHTML: (element) => element.style.marginBottom,
            renderHTML: (attributes) => {
              const spacing = attributes['blockSpacing']

              if (!spacing) return {}
              const isAmongOptions = this.options.spacings.some(({ value }) => value === spacing)
              if (!spacing || !isAmongOptions) return {}

              return { style: `margin-bottom: ${spacing}` }
            },
          },
        },
      },
    ]
  },

  addStorage() {
    return {
      getOptions: () => this.options,
    }
  },

  addCommands() {
    return {
      setBlockSpacing:
        (spacing) =>
        ({ commands }) => {
          if (!this.options.spacings.some(({ value }) => value === spacing)) {
            return false
          }

          return this.options.types.every((type) =>
            commands.updateAttributes(type, { blockSpacing: spacing }),
          )
        },
    }
  },
})
