import isHotkey from 'is-hotkey'
import React from 'react'
import { CSSProperties } from 'react'
import { Editor, RenderBlockProps, RenderMarkProps, Plugin } from 'slate-react'

import { BlockAlignment, MarkType, NodeType } from '../model/TextEditor.model'
import { addDataToBlocks, hasBlock } from '../TextEditor.utilities'
import PageBreak from './components/PageBreak'
import { Paragraph } from './components/Paragraph'
import { Title } from './components/Title/Title.component'

const isboldHotKey = isHotkey('mod+b')
const isItalicHotKey = isHotkey('mod+i')
const isUnderlineHotKey = isHotkey('mod+u')
const isSoftNewLine = isHotkey('shift+enter')
const isTab = isHotkey('tab')

export type FormatingPluginMode = 'normal' | 'inline' | 'variables'

interface FormatingPluginOpts {
  mode: FormatingPluginMode
}
const DEFAULT_FORMATING_PLUGIN_OPTS = {
  mode: 'normal' as FormatingPluginMode,
}

export const FormatingPlugin = ({
  mode,
}: FormatingPluginOpts = DEFAULT_FORMATING_PLUGIN_OPTS): Plugin => {
  const isInline = mode === 'inline'

  return {
    renderBlock(props: RenderBlockProps, _editor: Editor, next: () => any) {
      const {
        attributes: { ref, ...attributes },
        children,
        node,
      } = props
      const align = node.data.get('align')
      const fontFamily = node.data.get('fontFamily')
      const fontSize = node.data.get('fontSize')
      const baseStyle: CSSProperties = {
        fontFamily,
        fontSize: `${fontSize}px`,
      }
      switch (node.type) {
        case NodeType.TITLE1:
          return (
            <Title
              type={1}
              align={align}
              fontSize={fontSize}
              fontFamily={fontFamily}
              {...attributes}
            >
              {children}
            </Title>
          )
        case NodeType.TITLE2:
          return (
            <Title
              type={2}
              align={align}
              fontSize={fontSize}
              fontFamily={fontFamily}
              {...attributes}
            >
              {children}
            </Title>
          )
        case NodeType.TITLE3:
          return (
            <Title
              type={3}
              align={align}
              fontSize={fontSize}
              fontFamily={fontFamily}
              {...attributes}
            >
              {children}
            </Title>
          )

        case NodeType.PARAGRAPH:
          return (
            <Paragraph
              mode={mode}
              style={{
                textAlign: align,
                display: isInline ? 'inline' : 'block',
                ...baseStyle,
              }}
              {...attributes}
            >
              {children}
            </Paragraph>
          )
        case NodeType.LIST:
          return !isInline ? (
            <ul style={baseStyle} {...attributes}>
              {children}
            </ul>
          ) : (
            children
          )
        case NodeType.NUMBERED_LIST:
          return !isInline ? (
            <ol style={baseStyle} {...attributes}>
              {children}
            </ol>
          ) : (
            children
          )
        case NodeType.LIST_ITEM:
          return !isInline ? (
            <li style={baseStyle} {...attributes}>
              {children}
            </li>
          ) : (
            children
          )
        case NodeType.PAGE_BREAK:
          return <PageBreak {...attributes} />
        default:
          return next()
      }
    },
    renderMark(props: RenderMarkProps, _editor: Editor, next: () => any) {
      const { children, mark, attributes } = props
      switch (mark.type) {
        case MarkType.BOLD:
          return <b {...attributes}>{children}</b>
        case MarkType.CODE:
          return <code {...attributes}>{children}</code>
        case MarkType.ITALIC:
          return <em {...attributes}>{children}</em>
        case MarkType.UNDERLINED:
          return <u {...attributes}>{children}</u>
        case MarkType.FONT_FAMILY:
          return (
            <span style={{ fontFamily: mark.data.get('fontFamily') }} {...attributes}>
              {children}
            </span>
          )
        case MarkType.FONT_SIZE:
          return (
            <span style={{ fontSize: `${mark.data.get('fontSize')}px` }} {...attributes}>
              {children}
            </span>
          )
        default:
          return next()
      }
    },

    onKeyDown(event, editor: Editor, next: () => any) {
      if (isboldHotKey(event.nativeEvent)) {
        return editor.toggleMark(MarkType.BOLD).focus()
      }
      if (isItalicHotKey(event.nativeEvent)) {
        return editor.toggleMark(MarkType.ITALIC).focus()
      }
      if (isUnderlineHotKey(event.nativeEvent)) {
        return editor.toggleMark(MarkType.UNDERLINED).focus()
      }

      if (isSoftNewLine(event.nativeEvent)) {
        return editor.insertText('\n')
      }

      if (isTab(event.nativeEvent)) {
        event.preventDefault()
        return editor.insertText('      ')
      }

      if (event.key === 'Enter') {
        const isList = hasBlock(editor.value, NodeType.LIST_ITEM)
        const isTitle = hasBlock(editor.value, NodeType.TITLE1, NodeType.TITLE2, NodeType.TITLE3)
        // Si ce n'est pas une entrée de liste vide alors on ne rajoute pas un nouveau paragraphe
        // Si l'entrée de liste est vide on arrete de rajouter des puces
        if (isList) {
          return editor.value.anchorBlock.text !== ''
            ? next()
            : editor
                .unwrapBlock(NodeType.LIST)
                .unwrapBlock(NodeType.NUMBERED_LIST)
                .setBlocks(NodeType.PARAGRAPH)
        }
        if (isTitle) {
          return editor.insertBlock(NodeType.PARAGRAPH)
        }
        return mode === 'inline' ? editor.insertText('\n') : next()
      }

      return next()
    },

    commands: {
      setAlign(editor: Editor, alignment: BlockAlignment) {
        addDataToBlocks(editor, alignment)
        return editor
      },
    },
  }
}
