import { FunctionComponent, useState } from 'react'
import { GridLayout, MultiSelectSearch, SearchInput, SelectSearch } from '../../../shared'
import { SearchSchema, deserializeFromUrlSearchParams } from '../../../../misc/url.utilities'
import { useTimeoutEffect, useTagSearch } from '../../../../hooks/utils'
import { TagType } from '../../../../model/Tag'
import {
  QuestionnaireListPageFilters,
  QuestionnaireListTypeFilterSelectValues,
} from '../../../../pages/ManagerPage/questionnaires/QuestionnaireListPage/QuestionnaireListPage.model'
import { timeBeforeStartingResearch } from '../../../../constants'

interface QuestionnairesListActionsProps {
  onFiltersChange: (filters: QuestionnaireListPageFilters) => void
  tags: ReadonlyArray<TagType>
}

function getFiltersSchema(): SearchSchema {
  return {
    search: {
      type: 'string',
      defaultValue: '',
    },
    private: {
      type: 'custom',
      customName: 'type',
      defaultValue: QuestionnaireListTypeFilterSelectValues[0],
      customDeserializer: (filterParam) => {
        switch (filterParam) {
          case 'true':
            return QuestionnaireListTypeFilterSelectValues[2]
          case 'false':
            return QuestionnaireListTypeFilterSelectValues[1]
          default:
            return QuestionnaireListTypeFilterSelectValues[0]
        }
      },
    },
    tagIds: {
      type: 'arrayOfStrings',
      customName: 'tagIds',
    },
  }
}

export const QuestionnairesListActions: FunctionComponent<QuestionnairesListActionsProps> = ({
  onFiltersChange,
  tags,
}) => {
  const urlFilters: QuestionnaireListPageFilters = deserializeFromUrlSearchParams(
    document.location.search,
    getFiltersSchema(),
  )
  const [searchFilter, setSearchFilter] = useState(urlFilters.search)
  const [questionnaireTypeFilter, setQuestionnaireTypeFilter] = useState(urlFilters.type)
  const [tagIds, setTagIds] = useState<ReadonlyArray<string>>(urlFilters.tagIds || [])

  const [tagOptions, tagValues] = useTagSearch(tagIds, tags)

  useTimeoutEffect(
    () => {
      onFiltersChange({
        search: searchFilter,
        type: questionnaireTypeFilter,
        tagIds,
      })
    },
    timeBeforeStartingResearch,
    [searchFilter, questionnaireTypeFilter, tagIds],
  )

  return (
    <GridLayout columnsTemplate="2fr 1fr 1fr" gap="medium" rowsOnMobile={2}>
      <SearchInput
        autofocus
        placeholder="Rechercher un questionnaire"
        testId="search-questionnaire-name"
        value={searchFilter}
        onChange={({ target: { value } }) => setSearchFilter(value)}
      />
      <MultiSelectSearch
        placeholder="Rechercher par label"
        icon="tag"
        testId="search-questionnaire-tags"
        value={tagValues}
        options={tagOptions}
        onSelect={(selected) => {
          setTagIds((selected || []).map(({ value }) => value))
        }}
      />
      <SelectSearch
        placeholder="Type"
        icon="eye"
        testId="search-questionnaire-visibility"
        value={questionnaireTypeFilter}
        options={QuestionnaireListTypeFilterSelectValues}
        onSelect={(selected) => {
          if (selected !== undefined) {
            setQuestionnaireTypeFilter(selected)
          }
        }}
        clearable={false}
      />
    </GridLayout>
  )
}
