import { RestuxResources } from '../../resources'
import { RESTUX_IDENTIFIER } from '../../restux.identifier'
import { RestuxUiSagaConf, RestuxInUse, RestuxPagination } from '../../restux/ui'

export const documentContextualMenuQuestionnaireConfig: RestuxUiSagaConf = {
  resourceName: RestuxResources.questionnaire,
  identifier: RESTUX_IDENTIFIER.contextualMenuQuestionnaire,
}

export interface DocumentContextualMenuUiState {
  inUseQuestionnaire: RestuxInUse
  paginationQuestionnaire: RestuxPagination
}
