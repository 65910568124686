import { connect } from 'react-redux'
import { AddFileToMedicalEventDispatchProps } from './AddFileToMedicalEvent.model'
import { AddFileToMedicalEvent } from './AddFileToMedicalEvent.component'
import { medicalEventDomainActions } from '../../../store/domain/medicalEvents/index'

const mapDispatchToProps: AddFileToMedicalEventDispatchProps = {
  addFilesToMedicalEvent: medicalEventDomainActions.addFilesToMedicalEvent,
}
const withConnect = connect(null, mapDispatchToProps)(AddFileToMedicalEvent)
export { withConnect as AddFileToMedicalEvent }
