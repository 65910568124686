import { IconsType } from '../components/shared'
import { LabelsName } from '../design-system/colors'

export enum AlertSeverity {
  info,
  warning,
  danger,
}

export const SEVERITIES = [AlertSeverity.info, AlertSeverity.warning, AlertSeverity.danger]

export const AlertSeverityColor: { [key in AlertSeverity]: LabelsName } = {
  [AlertSeverity.info]: 'blue',
  [AlertSeverity.warning]: 'yellow',
  [AlertSeverity.danger]: 'red',
}

export const AlertSeverityIcon: { [key in AlertSeverity]: IconsType } = {
  [AlertSeverity.info]: 'infoCircle',
  [AlertSeverity.warning]: 'warningCircle',
  [AlertSeverity.danger]: 'crossCircle',
}
