import React, {
  Dispatch,
  SetStateAction,
  FunctionComponent,
  useState,
  useEffect,
  useContext,
  useRef,
  ReactNode,
} from 'react'
import { useDebounce } from 'react-use'
import {
  EditorValue,
  JSONToValue,
  DEFAULT_TOOLBAR_CONFIG,
  WithVariableEditor,
  insertVariable,
  insertImage,
} from '@follow/farte'

import { BASE_VARIABLES_LIST } from '../../../model/DocumentVariable'
import { DocumentLayout, MarginConfig } from '../../../model/DocumentLayout'

import { GridLayout, Heading, Input } from '../../shared'
import { DocumentEditorWrapper, DocumentSection } from '../../document'
import { AddVariableButton } from '../../../containers/DocumentEditor/ToolbarButton'

import { DocumentLayoutContentProps } from './DocumentLayoutContent.model'
import styles from './DocumentLayoutContent.module.scss'
import { ReactComponent as LayoutHelper } from '../../../assets/images/layout-helper.svg'
import { GridLayoutDocument } from './DocumentLayoutContent.styled'
import { FileDropzone } from '../../file'
import { AuthService, ConnectedUserContext } from '../../../misc/auth.utilities'
import { LabeledCheckbox } from '../../legacy/LabeledCheckbox'

const MM_IN_HORIZONTAL_A4_FORMAT = 210
const MM_IN_VERTICAL_A4_FORMAT = 297

const DocumentLayoutMarginSection: FunctionComponent<{
  title: string
  marginConfig: MarginConfig
  documentLayout: DocumentLayout
  onChange: (updates: MarginConfig) => void
}> = ({ title, marginConfig, documentLayout, onChange }) => {
  const [top, setTop] = useState<number>(marginConfig.top)
  const [bottom, setBottom] = useState<number>(marginConfig.bottom)
  const [left, setLeft] = useState<number>(marginConfig.left)
  const [right, setRight] = useState<number>(marginConfig.right)
  const [innerLeft] = useState<number>(marginConfig.innerLeft)
  const [innerRight] = useState<number>(marginConfig.innerRight)

  useEffect(() => {
    onChange({
      top,
      bottom,
      left,
      right,
      innerLeft,
      innerRight,
    })
  }, [top, bottom, left, right, innerLeft, innerRight, onChange])

  function maxVerticalValue(constraint: number) {
    return MM_IN_VERTICAL_A4_FORMAT - constraint
  }

  function maxHorizontalValue(constraint: number) {
    return MM_IN_HORIZONTAL_A4_FORMAT - constraint
  }

  function handleChange(value: string, setValue: Dispatch<SetStateAction<number>>) {
    if (value !== '') {
      const parsed = parseInt(value, 10)
      if (typeof parsed === 'number') {
        setValue(parsed)
      }
    } else {
      setValue(0)
    }
  }

  return (
    <DocumentSection title={title}>
      <GridLayout rowsTemplate="repeat(3, auto)" gap="small">
        <Heading size={3}>{title}</Heading>
        <span className={styles.message}>
          Si vous utilisez du papier pré-imprimé, vous pouvez personnaliser les marges à réserver
          pour l’impression de vos documents.
        </span>
        <GridLayout columns={4} gap={['small', 'extraLarge']}>
          <Input
            type="number"
            value={top.toString()}
            name="top"
            label="Haut (mm)"
            onChange={(e) => handleChange(e.target.value, setTop)}
            valid={top >= 0 && top <= maxVerticalValue(bottom)}
            disabled={!documentLayout.isEditable}
            colorPreset="light"
          />
          <Input
            type="number"
            value={bottom.toString()}
            name="bottom"
            label="Bas (mm)"
            onChange={(e) => handleChange(e.target.value, setBottom)}
            valid={bottom >= 0 && bottom <= maxVerticalValue(top)}
            disabled={!documentLayout.isEditable}
            colorPreset="light"
          />
          <Input
            type="number"
            value={left.toString()}
            name="left"
            label="Gauche (mm)"
            onChange={(e) => handleChange(e.target.value, setLeft)}
            valid={left >= 0 && left <= maxHorizontalValue(right)}
            disabled={!documentLayout.isEditable}
            colorPreset="light"
          />
          <Input
            type="number"
            value={right.toString()}
            name="right"
            label="Droite (mm)"
            onChange={(e) => handleChange(e.target.value, setRight)}
            valid={right >= 0 && right <= maxHorizontalValue(left)}
            disabled={!documentLayout.isEditable}
            colorPreset="light"
          />
        </GridLayout>
      </GridLayout>
    </DocumentSection>
  )
}

const DocumentLayoutEditorSection: FunctionComponent<{
  enabled: boolean
  title: string
  value: EditorValue
  documentLayout: DocumentLayout
  children?: ReactNode
  onAddVariable: () => void
  onChange: (value: EditorValue) => void
  onCheck: (value: boolean) => void
  onAddImage: (file: File) => void
}> = ({
  title,
  value,
  onAddVariable,
  onChange,
  onCheck,
  onAddImage,
  enabled,
  children,
  documentLayout,
}) => {
  const [displayDropzone, setDisplayDropzone] = useState(false)
  const [authToken, setAuthToken] = useState(AuthService.getToken())
  const { usurpedUser } = useContext(ConnectedUserContext)

  const handleAddImage = (files: File[]) => {
    setDisplayDropzone(false)
    if (files.length > 0) {
      setAuthToken(AuthService.getToken())
      onAddImage(files[0])
    }
  }
  return (
    <DocumentSection title={title} subtitle={enabled ? 'Activé' : 'Désactivé'} visible={enabled}>
      <GridLayout rowsTemplate="auto auto" gap="small">
        <LabeledCheckbox
          label="Activé"
          checked={enabled}
          onChange={(e) => onCheck(e.target.checked)}
          disabled={!documentLayout.isEditable}
        />
        {children}
        <DocumentEditorWrapper>
          <WithVariableEditor
            mode="edit"
            token={authToken}
            usurpedUser={usurpedUser}
            variableData={BASE_VARIABLES_LIST}
            toolbarConfig={DEFAULT_TOOLBAR_CONFIG}
            onValueChange={onChange}
            value={value}
            onAddImage={() => setDisplayDropzone(true)}
            readOnly={!enabled || !documentLayout.isEditable}
            toolbarAdditionalContent={<AddVariableButton onClick={onAddVariable} />}
          />
        </DocumentEditorWrapper>
      </GridLayout>
      {displayDropzone && (
        <div className={styles.dropZone}>
          <FileDropzone
            type="image"
            multiple={false}
            onDrop={handleAddImage}
            onClose={() => setDisplayDropzone(false)}
          />
        </div>
      )}
    </DocumentSection>
  )
}

export const DocumentLayoutContent: FunctionComponent<DocumentLayoutContentProps> = ({
  documentLayout,
  onChange,
  searchBaseVariable,
  addImageToDocument,
}) => {
  const [header, setHeader] = useState(JSONToValue(documentLayout.header))
  const [introduction, setIntroduction] = useState(JSONToValue(documentLayout.introduction))
  const [conclusion, setConclusion] = useState(JSONToValue(documentLayout.conclusion))
  const [footer, setFooter] = useState(JSONToValue(documentLayout.footer))
  const [left, setLeft] = useState(JSONToValue(documentLayout.left))
  const [right, setRight] = useState(JSONToValue(documentLayout.right))
  const [marginConfig, setMarginConfig] = useState(documentLayout.marginConfig)
  const [renderConfig, setRenderConfig] = useState(documentLayout.renderConfig)
  const firstUpdate = useRef(true)

  const handleAddVariable =
    (value: EditorValue, setValue: Dispatch<SetStateAction<EditorValue>>) => () => {
      searchBaseVariable((variableId) => {
        setValue(
          insertVariable(value, {
            variableId,
            variableContext: {},
          }),
        )
      }, 'document')
    }

  const handleAddImage = (
    value: EditorValue,
    setValue: Dispatch<SetStateAction<EditorValue>>,
    file: File,
  ) => {
    addImageToDocument(file, (imageUrl) => {
      setValue(insertImage(value, imageUrl))
    })
  }

  useDebounce(
    () => {
      if (!firstUpdate.current) {
        onChange({
          header: header.toJSON(),
          introduction: introduction.toJSON(),
          conclusion: conclusion.toJSON(),
          footer: footer.toJSON(),
          left: left.toJSON(),
          right: right.toJSON(),
          marginConfig,
          renderConfig,
        })
      } else {
        firstUpdate.current = false
      }
    },
    500,
    [header, introduction, conclusion, footer, marginConfig, left, right, renderConfig],
  )

  function handleChange(value: string, setValue: Dispatch<number>) {
    if (value !== '') {
      const parsed = parseInt(value, 10)
      if (typeof parsed === 'number') {
        setValue(parsed)
      }
    } else {
      setValue(0)
    }
  }

  return (
    <GridLayoutDocument
      rowsTemplate="repeat(7, auto)"
      width="100%"
      padding={['medium', 'none']}
      gap="medium"
    >
      <DocumentSection title="Aide">
        <LayoutHelper className={styles.layoutHelper} />
      </DocumentSection>
      <DocumentLayoutMarginSection
        title="Marges"
        marginConfig={marginConfig}
        onChange={setMarginConfig}
        documentLayout={documentLayout}
      />
      <DocumentLayoutEditorSection
        title="En-tête"
        onAddVariable={handleAddVariable(header, setHeader)}
        onChange={(value) => setHeader(value)}
        value={header}
        enabled={renderConfig.headerEnabled}
        documentLayout={documentLayout}
        onCheck={(value) => setRenderConfig({ ...renderConfig, headerEnabled: value })}
        onAddImage={(file) => handleAddImage(header, setHeader, file)}
      />
      <GridLayout columns={2} gap="medium">
        <DocumentLayoutEditorSection
          title="Entête latérale gauche"
          onAddVariable={handleAddVariable(left, setLeft)}
          onChange={(value) => setLeft(value)}
          value={left}
          enabled={renderConfig.leftEnabled}
          documentLayout={documentLayout}
          onCheck={(value) => setRenderConfig({ ...renderConfig, leftEnabled: value })}
          onAddImage={(file) => handleAddImage(left, setLeft, file)}
        >
          <GridLayout columns={2} gap="small">
            <Input
              type="number"
              value={renderConfig.leftWidth.toString()}
              name="leftWidth"
              label="Largeur (mm)"
              onChange={(e) =>
                handleChange(e.target.value, (parsed) => {
                  setRenderConfig({ ...renderConfig, leftWidth: parsed })
                })
              }
              disabled={!documentLayout.isEditable}
              colorPreset="light"
            />
            <Input
              type="number"
              value={marginConfig.innerLeft.toString()}
              name="innerLeft"
              label="Marge à droite (mm)"
              onChange={(e) =>
                handleChange(e.target.value, (parsed) => {
                  setMarginConfig({ ...marginConfig, innerLeft: parsed })
                })
              }
              disabled={!documentLayout.isEditable}
              colorPreset="light"
            />
          </GridLayout>
        </DocumentLayoutEditorSection>
        <DocumentLayoutEditorSection
          title="Entête latérale droite"
          onAddVariable={handleAddVariable(right, setRight)}
          onChange={(value) => setRight(value)}
          value={right}
          enabled={renderConfig.rightEnabled}
          documentLayout={documentLayout}
          onCheck={(value) => setRenderConfig({ ...renderConfig, rightEnabled: value })}
          onAddImage={(file) => handleAddImage(right, setRight, file)}
        >
          <GridLayout columns={2} gap="small">
            <Input
              type="number"
              value={renderConfig.rightWidth.toString()}
              name="rightWidth"
              label="Largeur (mm)"
              onChange={(e) =>
                handleChange(e.target.value, (parsedValue) => {
                  setRenderConfig({ ...renderConfig, rightWidth: parsedValue })
                })
              }
              disabled={!documentLayout.isEditable}
              colorPreset="light"
            />
            <Input
              type="number"
              value={marginConfig.innerRight.toString()}
              name="innerRight"
              label="Marge à gauche (mm)"
              onChange={(e) =>
                handleChange(e.target.value, (parsed) => {
                  setMarginConfig({ ...marginConfig, innerRight: parsed })
                })
              }
              disabled={!documentLayout.isEditable}
              colorPreset="light"
            />
          </GridLayout>
        </DocumentLayoutEditorSection>
      </GridLayout>
      <DocumentLayoutEditorSection
        title="Introduction"
        onAddVariable={handleAddVariable(introduction, setIntroduction)}
        onChange={(value) => setIntroduction(value)}
        value={introduction}
        enabled={renderConfig.introductionEnabled}
        documentLayout={documentLayout}
        onCheck={(value) => setRenderConfig({ ...renderConfig, introductionEnabled: value })}
        onAddImage={(file) => handleAddImage(introduction, setIntroduction, file)}
      />
      <DocumentLayoutEditorSection
        title="Conclusion"
        onAddVariable={handleAddVariable(conclusion, setConclusion)}
        onChange={(value) => setConclusion(value)}
        value={conclusion}
        enabled={renderConfig.conclusionEnabled}
        documentLayout={documentLayout}
        onCheck={(value) => setRenderConfig({ ...renderConfig, conclusionEnabled: value })}
        onAddImage={(file) => handleAddImage(conclusion, setConclusion, file)}
      />
      <DocumentLayoutEditorSection
        title="Pied de page"
        onAddVariable={handleAddVariable(footer, setFooter)}
        onChange={(value) => setFooter(value)}
        value={footer}
        documentLayout={documentLayout}
        enabled={renderConfig.footerEnabled}
        onCheck={(value) => setRenderConfig({ ...renderConfig, footerEnabled: value })}
        onAddImage={(file) => handleAddImage(footer, setFooter, file)}
      />
    </GridLayoutDocument>
  )
}
