import React, { FunctionComponent } from 'react'

import { ReactComponent as Placeholder } from '../../../../../assets/images/nobilan.svg'
import { Heading, RoundedButton } from '../../../../shared'

import { QuestionnairePlaceholderProps } from './QuestionnairePlaceholder.model'

import styles from './QuestionnairePlaceholder.module.scss'

export const QuestionnairePlaceholder: FunctionComponent<QuestionnairePlaceholderProps> = ({
  title = `Aucune variable à remplir dans ce document`,
  advice = `Rendez-vous dans le manager pour créer des variables, ou cliquez ci-dessous pour consulter et modifier le texte du document.`,
  onPreview,
}) => (
  <div className={styles.container} data-test-id="placeholder-questionnaire">
    <div className={styles.placeholder}>
      <div className={styles.image}>
        <Placeholder className={styles.svg} />
      </div>
      <Heading size={3}>{title}</Heading>
      <p>{advice}</p>
      {onPreview && (
        <div className={styles.button}>
          <RoundedButton label="Aperçu du document" theme="transparent" onClick={onPreview} />
        </div>
      )}
    </div>
  </div>
)
