import { createReducer } from 'redux-starter-kit'
import { domainDrugsActions } from './drugs.actions'
import { domainDrugsActionTypes, DrugsState } from './drugs.model'

const DRUGS_INITIAL_STATE: DrugsState = {
  details: null,
}

const ACTION_HANDLERS = {
  [domainDrugsActionTypes.STORE_DETAILS]: (
    state: DrugsState = DRUGS_INITIAL_STATE,
    { drug }: ReturnType<typeof domainDrugsActions.storeDetails>,
  ): DrugsState => ({
    ...state,
    details: drug,
  }),
  [domainDrugsActionTypes.CLEAR_DETAILS]: (
    state: DrugsState = DRUGS_INITIAL_STATE,
  ): DrugsState => ({
    ...state,
    details: null,
  }),
}

export const drugsReducer = createReducer<DrugsState>(DRUGS_INITIAL_STATE, ACTION_HANDLERS)
