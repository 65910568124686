import getApi from '../../../../api'
import { AtcClass } from '../../../../model/Drug'
import { RestuxApiConfig } from '../../../restux/cache/restuxCache.model'
import { PaginatedList } from '../../../../model/Pagination'

const baseUrl = '/atc_classifications'

export const restuxAtcClassificationApiConfig: RestuxApiConfig<AtcClass, AtcClass> = {
  url: baseUrl,
  getPaginatedItems: (route) => getApi().get<PaginatedList<AtcClass>>(route),
  getItem: (route) => getApi().get<AtcClass>(route),
}

export const fetchClassChildren = (id: number) =>
  getApi().get<PaginatedList<AtcClass>>(`${baseUrl}/${id}/children`)
