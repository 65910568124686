import { useMutation, useQueryClient } from '@tanstack/react-query'
import { deletePatient } from '../../../data/patients'
import { UserFriendlyError } from '../../../model/Error'
import { patientsKeys } from './patients.keys'

export interface DeletePatientMutateVariable {
  patientId: number
}

export const useDeletePatient = ({
  triggerSubscription = true,
}: { triggerSubscription?: boolean } = {}) => {
  const queryClient = useQueryClient()

  return useMutation<null, Error, DeletePatientMutateVariable>(
    triggerSubscription ? patientsKeys.deleteItem : [],
    async ({ patientId }) => {
      const { ok } = await deletePatient(patientId)
      if (!ok) {
        throw new UserFriendlyError('error', 'La suppression du patient a échoué')
      }
      return null
    },
    {
      onSuccess: (_data, { patientId }) => {
        queryClient.invalidateQueries(patientsKeys.lists)
        queryClient.invalidateQueries(patientsKeys.detail(patientId))
      },
    },
  )
}
