import { FC, useEffect, useState } from 'react'
import {
  MedicalEventNavBarProps,
  READONLY_MEDICAL_EVENT_ALERTS,
  lockedMedicalEventAlerts,
} from './MedicalEventNavBar.model'
import { NavBar } from '../../MainLayout'
import { getDisplayedName } from '../../../../misc/patient.utilities'
import { DocumentCategoryIndicator } from '../../../document'
import { DocumentCategoryKeys } from '../../../../model/DocumentCategory'
import { PatientAlertsBanner } from '../../../patient'
import { MedicalEventForm } from '../../../medicalEvent'
import { AlertBanner, ValidationModal } from '../../../shared'
import { RestuxLoadingState } from '../../../../store/restux/ui'
import { MedicalEventHeader } from './MedicalEventHeader'
import { useCurrentPatient } from '../../../../hooks/utils'
import { useLocation } from 'react-router-dom'

export const MedicalEventNavBar: FC<MedicalEventNavBarProps> = ({
  medicalEvent,
  medicalEventLoadingState,
  isOwner,
  lockMedicalEvent,
}) => {
  const { currentPatient: patient } = useCurrentPatient()
  const location = useLocation()
  const [isEditing, setIsEditing] = useState(false)
  const [openLockModalValidation, setOpenLockModalValidation] = useState(false)
  const [backButtonTitle, setBackButtonTitle] = useState<string>()

  const displayedName = patient ? getDisplayedName(patient) : null

  const handleLock = () => {
    if (medicalEvent) {
      lockMedicalEvent(medicalEvent)
    }
    setOpenLockModalValidation(false)
  }

  useEffect(() => {
    if (!backButtonTitle) {
      if (location.state?.from?.includes('browse_event')) {
        setBackButtonTitle('Retour vers la navigation transverse')
      } else {
        displayedName &&
          setBackButtonTitle(
            `Retour vers la page patient "${displayedName.firstname} ${displayedName.lastname}"`,
          )
      }
    }
  }, [backButtonTitle, displayedName, location.state])

  return (
    <>
      <NavBar
        noDivider={true}
        backButtonPath={patient ? null : undefined}
        backButtonTitle={backButtonTitle}
        stickyHeaderContent={
          <>
            <DocumentCategoryIndicator
              documentCategory={medicalEvent?.category ?? DocumentCategoryKeys.Other}
            />
            {!medicalEvent?.isEditable &&
              medicalEventLoadingState === RestuxLoadingState.IDLE &&
              (!medicalEvent?.locked ? (
                <AlertBanner alerts={READONLY_MEDICAL_EVENT_ALERTS} />
              ) : (
                <AlertBanner alerts={lockedMedicalEventAlerts(isOwner)} />
              ))}
            {isEditing && <MedicalEventForm />}
            <PatientAlertsBanner />
          </>
        }
      >
        <MedicalEventHeader
          editMedicalEvent={() => setIsEditing((value) => !value)}
          patient={patient ?? null}
          onLock={() => setOpenLockModalValidation(true)}
        />
      </NavBar>
      <ValidationModal
        display={openLockModalValidation}
        icon="lock"
        confirmLabel="Valider"
        title={
          medicalEvent?.locked
            ? 'Voulez vous vraiment déverrouiller cet évènement médical ? Ses documents ainsi que les valeurs de leurs variables seront éditables et mises à jour avec les nouvelles données du patient'
            : 'Voulez vous vraiment verrouiller cet évènement médical ? Ses documents ainsi que les valeurs de leurs variables seront figées'
        }
        onSubmit={handleLock}
        onClose={() => setOpenLockModalValidation(false)}
      />
    </>
  )
}
