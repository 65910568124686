import { HealthcareSetting } from '../../../model/Efficience'

export interface DmpRequest {
  uuid: string
  documentId?: number
  payload: string
}

export interface DmpState {
  request?: DmpRequest
  healthcareSettings: HealthcareSetting[]
}

export const DmpDomainActionsTypes = {
  SET_REQUEST: '@DMP/SET_REQUEST',
  SEND_DOCUMENT: '@DMP/SEND_DOCUMENT',
  DELETE_DOCUMENT: '@DMP/DELETE_DOCUMENT',
  UPLOAD_DOCUMENT: '@DMP/UPLOAD_DOCUMENT',
  FETCH_HEALTHCARE_SETTINGS: '@DMP/FETCH_HEALTHCARE_SETTINGS',
  STORE_HEALTHCARE_SETTINGS: '@DMP/STORE_HEALTHCARE_SETTINGS',
}

export enum DmpRequestTypes {
  SEND_DOCUMENT = 'SEND_DOCUMENT',
  DELETE_DOCUMENT = 'DELETE_DOCUMENT',
}
