import { restuxPatientManualPrescriptionsApiConfig } from './api'
import { restuxCacheFactory } from '../../restux/cache'
import { RestuxResources } from '../../resources'
import { ManualPrescriptionItem } from '../../../model/ManualPrescription'

const resourceName = RestuxResources.patientManualPrescriptions

const { actions, reducers, sagas } = restuxCacheFactory<
  ManualPrescriptionItem,
  ManualPrescriptionItem
>({
  apiConfig: restuxPatientManualPrescriptionsApiConfig,
  refetchList: true,
  resourceName,
})

export const patientManualPrescriptionsActions = actions
export const patientManualPrescriptionsReducers = reducers
export const patientManualPrescriptionsSagas = sagas
