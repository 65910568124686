import { put, take, takeEvery } from 'redux-saga/effects'
import { uiBottomPanelMailEditorActionTypes } from './bottomPanelMailEditor.model'
import { setBottomPanelOptions } from '../bottomPanel'
import { BottomPanelActionTypes, BottomPanelComponentType } from '../bottomPanel/bottomPanel.model'

function* openMailEditorWorker() {
  yield put(
    setBottomPanelOptions({
      title: 'Nouveau message',
      open: true,
      componentType: BottomPanelComponentType.MailEditor,
      displayCloseButton: false,
    }),
  )

  // Clear du displayCloseButton à la fermeture du MailEditor
  yield take(BottomPanelActionTypes.SET_BOTTOM_PANEL_OPTIONS)
  yield put(
    setBottomPanelOptions({
      displayCloseButton: true,
    }),
  )
}

function* openMailEditorWatcher() {
  yield takeEvery(uiBottomPanelMailEditorActionTypes.OPEN_EDITOR, openMailEditorWorker)
}

export const bottomPanelMailEditor = {
  openMailEditorWatcher,
}
