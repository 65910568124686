import { restuxContactApiConfig } from './api'
import { restuxCacheFactory } from '../../restux/cache/index'
import { Contact } from '../../../model/Contact'
import { RestuxResources } from '../../resources'

const { actions, reducers, sagas } = restuxCacheFactory<Contact, Contact>({
  apiConfig: restuxContactApiConfig,
  refetchList: false,
  resourceName: RestuxResources.contact,
})

export const contactsActions = actions
export const contactsReducers = reducers
export const contactsSagas = sagas
