import { FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Icon } from '../../shared'
import { TaskTableColumn, TaskTableProps } from './TaskTable.model'
import { TaskTableRow } from './TaskTableRow'
import { TaskSorting } from '../../../model/Task'
import { TaskPageContentFiltersContext } from '../TaskPageContent/TaskPageContent.context'
import { addTestIdSuffix } from '../../../misc/testId.utilities'
import { SORT_DIRECTION } from '../../../model/Resource'
import styles from './TaskTable.module.scss'
import classNames from 'classnames/bind'

const cx = classNames.bind(styles)

export const TaskTable: FunctionComponent<TaskTableProps> = ({
  tasks,
  onCreateOrUpdate,
  onDeleteTask,
  removeNewTask,
  openTaskInformation,
  openCdaInformation,
  displayColumns = Object.values(TaskTableColumn),
  size = 'normal',
  testId,
}) => {
  const { ASC, DESC } = SORT_DIRECTION
  const [rows, setRows] = useState(tasks)
  const { filters, setFilters } = useContext(TaskPageContentFiltersContext)
  const { orderBy, order } = filters

  const canSwitchToEditMode = useMemo(
    () => rows.filter((row) => row.editMode === true).length === 0,
    [rows],
  )

  const iconRotate = order === ASC ? 180 : 0

  const handleOrderChange = useCallback(
    (newOrder: TaskSorting) => {
      setFilters({
        orderBy: newOrder,
        order: order === ASC ? DESC : ASC,
      })
    },
    [setFilters, order, ASC, DESC],
  )

  const handleSwitchEditModeRequest = useCallback(
    (taskId?: number) => {
      if (!canSwitchToEditMode) {
        return
      }

      const updatedRow = rows.map((row) => (row.id === taskId ? { ...row, editMode: true } : row))
      setRows([...updatedRow])
    },
    [canSwitchToEditMode, rows],
  )

  useEffect(() => setRows(tasks), [tasks])

  return (
    <table className={cx(styles.table, size)} data-test-id={addTestIdSuffix('root', testId)}>
      <colgroup>
        <col className={styles.colCheckbox} />
        {displayColumns.includes(TaskTableColumn.Title) && <col className={styles.colTitle} />}
        {displayColumns.includes(TaskTableColumn.CdaContext) && (
          <col className={styles.colCdaContext} />
        )}
        {displayColumns.includes(TaskTableColumn.Category) && (
          <col className={styles.colCategory} />
        )}
        {displayColumns.includes(TaskTableColumn.Patient) && <col className={styles.colPatient} />}
        {displayColumns.includes(TaskTableColumn.CreatedAt) && (
          <col className={styles.colCreatedAt} />
        )}
        {displayColumns.includes(TaskTableColumn.Actions) && <col className={styles.colActions} />}
      </colgroup>

      <thead>
        <tr>
          <th />
          {displayColumns.includes(TaskTableColumn.Title) && (
            <th>
              <div
                className={cx(styles.headerWrapper, styles.clickable)}
                onClick={() => handleOrderChange('title')}
              >
                <span>Nom</span>
                {orderBy === 'title' && <Icon icon="chevron" rotate={iconRotate} size="femto" />}
              </div>
            </th>
          )}
          {displayColumns.includes(TaskTableColumn.CdaContext) && <th />}
          {displayColumns.includes(TaskTableColumn.Category) && (
            <th>
              <div
                className={cx(styles.headerWrapper, styles.clickable)}
                onClick={() => handleOrderChange('type')}
              >
                <span>Catégorie</span>
                {orderBy === 'type' && <Icon icon="chevron" rotate={iconRotate} size="femto" />}
              </div>
            </th>
          )}
          {displayColumns.includes(TaskTableColumn.Patient) && (
            <th>
              <div
                className={cx(styles.headerWrapper, styles.clickable)}
                onClick={() => handleOrderChange('patient')}
              >
                <span>Patient</span>
                {orderBy === 'patient' && <Icon icon="chevron" rotate={iconRotate} size="femto" />}
              </div>
            </th>
          )}
          {displayColumns.includes(TaskTableColumn.CreatedAt) && (
            <th>
              <div
                className={cx(styles.headerWrapper, styles.clickable)}
                onClick={() => handleOrderChange('date')}
              >
                <span>Date d'ajout</span>
                {orderBy === 'date' && <Icon icon="chevron" rotate={iconRotate} size="femto" />}
              </div>
            </th>
          )}
          {displayColumns.includes(TaskTableColumn.Actions) && (
            <th>
              <div className={styles.headerWrapper}>
                <span>Actions</span>
              </div>
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {rows.map(({ editMode, ...task }, index) => (
          <TaskTableRow
            key={task.id ?? index}
            task={task}
            editMode={editMode}
            onCreateOrUpdate={onCreateOrUpdate}
            onDeleteTask={onDeleteTask}
            removeNewTask={removeNewTask}
            handleSwitchEditModeRequest={handleSwitchEditModeRequest}
            openTaskInformation={openTaskInformation}
            openCdaInformation={openCdaInformation}
            displayColumns={displayColumns}
            size={size}
            testId={addTestIdSuffix(`row-${task.id ?? index}`, testId)}
          />
        ))}
      </tbody>
    </table>
  )
}
