import { Filters } from '../../../../model/Filters'

const all = ['admin/users']
const list = (filters?: Filters) => [...all, filters]
const detail = (id?: string) => [...all, id]

export const userKeys = {
  all,
  list,
  detail,
}
