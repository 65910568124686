import { Drug } from '../../../model/Drug'
import { PosologyFormResource, PosologyPreview } from '../../../model/Posology'
import { PrescriptionPosologyUpdate } from '../../../model/Prescription'
import { domainPosologyActionTypes } from './posology.model'

export const domainPosologyActions = {
  batchPosology: (prescription: PrescriptionPosologyUpdate, prescriptionUuid: string) => ({
    type: domainPosologyActionTypes.BATCH,
    prescription,
    prescriptionUuid,
  }),
  batchSucceed: () => ({
    type: domainPosologyActionTypes.BATCH_SUCCEED,
  }),
  batchFailed: () => ({
    type: domainPosologyActionTypes.BATCH_FAILED,
  }),
  getPreview: (posologyIntervals: PosologyFormResource[], drugs: Drug[]) => ({
    type: domainPosologyActionTypes.GET_PREVIEW,
    posologyIntervals,
    drugs,
  }),
  setPreview: (preview: PosologyPreview | null) => ({
    type: domainPosologyActionTypes.SET_PREVIEW,
    preview,
  }),
  clearPreview: () => ({
    type: domainPosologyActionTypes.CLEAR_PREVIEW,
  }),
}
