import { AdminAnalyticsActionTypes } from './adminAnalytics.model'
import {
  AnalyticsRequest,
  PastAnalytics,
  DatedAnalytics,
  AnalyticsAccuracyKeys,
} from '../../../model/Analytics'

export const addAnalytics = (analytics: PastAnalytics) => ({
  type: AdminAnalyticsActionTypes.ANALYTICS_ADD,
  analytics,
})

export const requestPeriod = (
  request: AnalyticsRequest,
  accuracy: AnalyticsAccuracyKeys,
  index: number,
  requestLastYear: boolean,
) => ({
  type: AdminAnalyticsActionTypes.REQUEST_PERIOD,
  request,
  accuracy,
  index,
  requestLastYear,
})

export const requestAnalytics = (request: AnalyticsRequest, requestLastYear: boolean) => ({
  type: AdminAnalyticsActionTypes.REQUEST_ANALYTICS,
  request,
  requestLastYear,
})

export const requestOverall = (request: AnalyticsRequest, index: number) => ({
  type: AdminAnalyticsActionTypes.REQUEST_OVERALL,
  request,
  index,
})

export const resetAnalytics = () => ({
  type: AdminAnalyticsActionTypes.ANALYTICS_RESET,
})

export const setOverall = (analytics: DatedAnalytics, index: number) => ({
  type: AdminAnalyticsActionTypes.SET_OVERALL,
  analytics,
  index,
})

export const eraseOverall = (index: number) => ({
  type: AdminAnalyticsActionTypes.OVERALL_ERASE,
  index,
})

export const addOverallField = () => ({
  type: AdminAnalyticsActionTypes.OVERALL_ADD_FIELD,
})

export const exportToCSV = () => ({
  type: AdminAnalyticsActionTypes.EXPORT_TO_CSV,
})
