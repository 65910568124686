import React, { FunctionComponent } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import { WithVariableValueToHtml, JSONToValue } from '@follow/farte'

import { PrintDocumentBodyProps } from './component-store.model'

export const PrintDocumentBody: FunctionComponent<PrintDocumentBodyProps> = ({
  template,
  introduction,
  conclusion,
  variablesData,
  token,
}) => {
  return (
    <div>
      {introduction && (
        <WithVariableValueToHtml
          value={JSONToValue(introduction)}
          data={variablesData}
          token={token}
        />
      )}
      <WithVariableValueToHtml value={JSONToValue(template)} data={variablesData} token={token} />
      {conclusion && (
        <WithVariableValueToHtml
          value={JSONToValue(conclusion)}
          data={variablesData}
          token={token}
        />
      )}
    </div>
  )
}

export function PrintDocumentBodyHtml(
  { template, variablesData, introduction, conclusion }: PrintDocumentBodyProps,
  token?: string,
) {
  return renderToStaticMarkup(
    <PrintDocumentBody
      template={template}
      variablesData={variablesData}
      introduction={introduction}
      conclusion={conclusion}
      token={token}
    />,
  )
}
