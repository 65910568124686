import { Prescription, PrescriptionUpdate } from '../../../model/Prescription'
import { domainPrescriptionActionTypes } from './prescription.model'

const switchToVMP = (prescription: Prescription) => ({
  type: domainPrescriptionActionTypes.SWITCH_TO_VMP,
  prescription,
})

const openPosologyBottomPanelFromPrescription = (prescriptionId: string) => ({
  type: domainPrescriptionActionTypes.OPEN_POSOLOGY_BOTTOM_PANEL_FROM_PRESCRIPTION,
  prescriptionId,
})

const updatePrescription = (
  prescriptionId: string,
  prescriptionUpdate: Partial<PrescriptionUpdate>,
) => ({
  type: domainPrescriptionActionTypes.UPDATE_PRESCRIPTION,
  prescriptionId,
  prescriptionUpdate,
})

export const domainPrescriptionActions = {
  switchToVMP,
  openPosologyBottomPanelFromPrescription,
  updatePrescription,
}
