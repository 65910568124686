import { createReducer } from 'redux-starter-kit'
import { domainEditorActions } from './editor.actions'
import { DomainEditorActionTypes, DomainEditorState } from './editor.model'

const INITIAL_STATE: DomainEditorState = {
  pendingVariables: {
    prescriptions: [],
    manualPrescriptions: [],
    quoteLines: [],
  },
}

const ACTION_HANDLERS = {
  [DomainEditorActionTypes.SET_PENDING_VARIABLES]: (
    state: DomainEditorState = INITIAL_STATE,
    {
      pendingVariables: { prescriptions = [], manualPrescriptions = [], quoteLines = [] },
    }: ReturnType<typeof domainEditorActions.setPendingVariables>,
  ) => ({
    ...state,
    pendingVariables: {
      prescriptions: [...state.pendingVariables.prescriptions, ...prescriptions],
      manualPrescriptions: [...state.pendingVariables.manualPrescriptions, ...manualPrescriptions],
      quoteLines: [...state.pendingVariables.quoteLines, ...quoteLines],
    },
  }),
  [DomainEditorActionTypes.CLEAR_PENDING_VARIABLES]: (
    state: DomainEditorState = INITIAL_STATE,
  ) => ({
    ...state,
    pendingVariables: INITIAL_STATE.pendingVariables,
  }),
}

export const domainEditorReducer = createReducer(INITIAL_STATE, ACTION_HANDLERS)
