import isHotkey from 'is-hotkey'
import { useCallback, useEffect, DependencyList } from 'react'

export enum HotKeys {
  ModP = 'mod+p',
  ModS = 'mod+s',
  ModShiftS = 'mod+shift+s',
  ModShitV = 'mod+shift+v',
  ArrowUp = 'ArrowUp',
  ArrowDown = 'ArrowDown',
  Enter = 'Enter',
}

export const useHotKeyEffect = (
  hotkey: HotKeys,
  callback: (event: KeyboardEvent) => void,
  deps: DependencyList,
) => {
  const handleDocumentKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (isHotkey(hotkey, event)) {
        callback(event)
      }
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    [...deps, callback, hotkey],
  )
  useEffect(() => {
    window.document.addEventListener('keydown', handleDocumentKeyDown, true)
    return () => {
      window.document.removeEventListener('keydown', handleDocumentKeyDown, true)
    }
  }, [handleDocumentKeyDown])
}
