import { CodeCategory } from '../../../model/Code'

export interface CodesState {
  [categoryName: string]: CodeCategory
}

export const CodesDomainActionsTypes = {
  FETCH: '@CODES/FETCH',
  FETCH_SUCCESS: '@CODES/FETCH_SUCCESS',
}
