import { put, takeEvery } from 'redux-saga/effects'
import { domainEditorActions } from '../../domain/editor/editor.actions'
import { quoteLineActions } from './quoteLine.actions'
import { QuoteLineActionTypes } from './quoteLine.model'

function* addQuoteLineSagaWorker({
  quoteLine,
  onQuoteLineAdded,
}: ReturnType<typeof quoteLineActions.addQuoteLine>) {
  yield put(
    domainEditorActions.setPendingVariables({
      quoteLines: [quoteLine],
    }),
  )
  onQuoteLineAdded()
}

function* addQuoteLineSagaWatcher() {
  yield takeEvery(QuoteLineActionTypes.ADD_QUOTE_LINE, addQuoteLineSagaWorker)
}

export const quoteLineSagas = {
  addQuoteLineSagaWatcher,
}
