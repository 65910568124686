import React, { FunctionComponent } from 'react'

import { LightQuestionnaire } from '../../../../model/Questionnaire'

import styles from './QuestionnaireItem.module.scss'
import { Icon, TagCollection } from '../../../shared'
import { Colors } from '../../../../colors'
import { User } from '../../../../model/User'
import { getFullName } from '../../../../misc/user.utilities'
import RoleRestriction from '../../../../containers/RoleRestriction'
import { ADMIN_ROLES } from '../../../../model/Roles'
import classnames from 'classnames'

interface DocumentTemplateProps {
  questionnaire: LightQuestionnaire
  currentUser: User | null
}

export const QuestionnaireItem: FunctionComponent<DocumentTemplateProps> = ({
  questionnaire,
  currentUser,
}) => (
  <div className={styles.item}>
    <div
      className={styles.visibility}
      title={`Ce questionnaire est ${questionnaire.private ? 'privé' : 'public'}`}
    >
      <Icon size="micro" icon={questionnaire.private ? 'lock' : 'global'} color={Colors.dsDark4} />
    </div>
    <div className={styles.titleContainer}>
      <div className={styles.title} title="Titre du Questionnaire">
        {questionnaire.title}
      </div>
      {questionnaire.private && questionnaire.owner.id !== currentUser?.id && (
        <div className={styles.ownerName}>{getFullName(questionnaire.owner, true)}</div>
      )}
    </div>
    <div className={classnames(styles.tags, 'space-x-2')}>
      <TagCollection ids={questionnaire.tagIds} />
    </div>
    <RoleRestriction roles={ADMIN_ROLES}>
      <div className={styles.identifier} title="Identifiant technique du questionnaire">
        {questionnaire.id}
      </div>
    </RoleRestriction>
  </div>
)
