import { FunctionComponent } from 'react'
import { Card } from '../../components/shared'
import { PartnerLink } from '../../components/legal'
import { PartnerLinkProps } from '../../components/legal/PartnerLink/PartnerLink.model'
import AmeliLogo from '../../assets/images/logo_ameli_pro.png'
import AnsmLogo from '../../assets/images/logo_ansm.png'
import LegalDocumentsLogo from '../../assets/images/logo_documents_legaux.png'
import { NavBar } from '../../components/layout'

const AmeliLinks: Array<PartnerLinkProps> = [
  {
    title: 'Ameli Pro (Accueil)',
    link: 'https://espacepro.ameli.fr/',
    description:
      "Accedez à l'ensemble des services dédiés aux professionnels de santé. Vous devez être connecté avec votre carte CPS.",
  },
  {
    title: 'Modèle d’ordonnance de non-prescription d’antibiotiques',
    link:
      'https://www.ameli.fr/sites/default/files/Documents/4074/document/information-antibiotiques-non-prescrits_assurance-maladie.pdf',
    description: 'Informez vos patients sur la non-nécessité de leur prescrire des antibiotiques.',
  },
  {
    title:
      "Téléservice de l'assurance maladie relatif aux demandes d'accord préalable pour la prise en charge de médicaments",
    link:
      'https://authps-espacepro.ameli.fr/oauth2/authorize?response_type=code&scope=openid profile infosps email&client_id=csm-cen-prod_ameliprotransverse-connexionadmin_1_amtrx_i1_csm-cen-prod%2Fameliprotransverse-connexionadmin_1%2Famtrx_i1&state=5GL7Rxrgk6PBjMhHCGqZw2fjkFo&redirect_uri=https%3A%2F%2Fespacepro.ameli.fr%2Fredirect_uri&nonce=MFr3nfhQ5h3Qw72EcH6t6JuCnvKZTXsLvsn8-ieZrjc',
    description:
      "Accédez au formulaire de prise en charge de certains actes ou traitements médicaux nécessitant l'accord préalable de l'Assurance Maladie.",
  },
  {
    title: 'Documents pour demande d’accord préalable',
    link:
      'https://www.ameli.fr/medecin/exercice-liberal/presciption-prise-charge/accord-prealable/accord-prealable',
    description: 'Accéder au mode d’emploi d’accord préalable sur le site de l’assurance maladie.',
  },
  {
    title: 'Formulaires CERFA Ameli',
    link: 'https://www.ameli.fr/assure/recherche-formulaire',
    description: 'Accéder à la liste des formulaires CERFA mis à disposition sur le site Ameli.',
  },
  {
    title: 'Téléservice de l’assurance maladie',
    link:
      'https://www.ameli.fr/paris/medecin/exercice-liberal/entre-vous-et-nous/teleservices/teleservices',
    description: 'Accéder à la page explicative des téléservices de l’assurance maladie.',
  },
]

const AnsmLinks: Array<PartnerLinkProps> = [
  {
    title: "Portail des signalements des événements sanitaires indésirables de l'ANSM",
    link: 'https://ansm.sante.fr/documents/reference/declarer-un-effet-indesirable',
    description:
      "Déclarez les événements sanitaires indésirables que vous suspectez d'être liés aux produits de santé.",
  },
]

const LegalDocuments: Array<PartnerLinkProps> = [
  {
    title: 'Conditions générales d’utilisation (CGU)',
    link:
      'https://storage.googleapis.com/fw-website-public-files/follow_conditions_generales_utilisation.pdf',
    description: 'Télécharger le document',
  },
  {
    title: 'Informations de la base de données de médicaments (bdm)',
    link: 'https://storage.googleapis.com/fw-website-public-files/follow_informations_bdm.pdf',
    description: 'Télécharger le document',
  },
  {
    title: 'Liens d’intérêts',
    link: 'https://storage.googleapis.com/fw-website-public-files/follow_liens_interets.pdf',
    description: 'Télécharger le document',
  },
  {
    title: 'Critères d’engagements à la certification HAS LAP',
    link:
      'https://storage.googleapis.com/fw-website-public-files/follow_criteres_engagement_has_lap.pdf',
    description: 'Télécharger le document',
  },
]

export const LegalPage: FunctionComponent = () => {
  return (
    <>
      <NavBar>Liens et Informations</NavBar>
      <div className="flex flex-col w-1/2 mx-auto my-4 gap-y-4">
        <Card>
          <div className="flex flex-row">
            <img className="w-16 h-12 object-contain mr-5" src={AmeliLogo} alt="Ameli logo" />
            <div className="flex flex-col space-y-3">
              {AmeliLinks.map(({ title, link, description }, index) => (
                <PartnerLink key={index} title={title} link={link} description={description} />
              ))}
            </div>
          </div>
        </Card>
        <Card>
          <div className="flex flex-row">
            <img className="w-16 h-12 object-contain mr-5" src={AnsmLogo} alt="Ansm logo" />
            <div className="flex flex-col space-y-3">
              {AnsmLinks.map(({ title, link, description }, index) => (
                <PartnerLink key={index} title={title} link={link} description={description} />
              ))}
            </div>
          </div>
        </Card>
        <Card>
          <div className="flex flex-row">
            <img
              className="w-16 h-12 object-contain mr-5"
              src={LegalDocumentsLogo}
              alt="Ansm logo"
            />
            <div className="flex flex-col space-y-3">
              {LegalDocuments.map(({ title, link, description }, index) => (
                <PartnerLink key={index} title={title} link={link} description={description} />
              ))}
            </div>
          </div>
        </Card>
      </div>
    </>
  )
}
