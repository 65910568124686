import React, { FunctionComponent } from 'react'
import { ThingModalLayoutProps } from './ThinModalLayout.model'
import classNames from 'classnames'

export const ThinModalLayout: FunctionComponent<ThingModalLayoutProps> = ({
  children,
  size = 'small',
}) => (
  <div
    className={classNames(
      'bg-shades-white shadow-2xl rounded-3xl max-h-screen overflow-y-auto md:overflow-y-visible md:max-h-[80vh]',
      {
        'max-w-sm': size === 'small',
        'max-w-lg': size === 'medium',
      },
    )}
  >
    {children}
  </div>
)
