import { Formik } from 'formik'
import React, { FunctionComponent, useState } from 'react'
import { PasswordUpdate } from '../../../../model/User'
import { RoundedButton, FormikTextInput } from '../../../shared'
import { PasswordInformationsBlockProps } from './PasswordInformationsBlock.model'
import styles from './PasswordInformationsBlock.module.scss'
import { PasswordValidationSchema } from './PasswordInformationsBlock.validator'

export const PasswordInformationsBlock: FunctionComponent<PasswordInformationsBlockProps> = ({
  onSubmitPassword,
}) => {
  const [showEditPassword, setShowEditPassword] = useState(false)

  return (
    <div>
      {showEditPassword && (
        <>
          <Formik<PasswordUpdate>
            initialValues={{ actualPassword: '', newPassword: '', newPasswordConfirmation: '' }}
            validationSchema={PasswordValidationSchema}
            onSubmit={onSubmitPassword}
            enableReinitialize
            validateOnMount
          >
            {(props) => (
              <div className={styles.form}>
                <FormikTextInput
                  type="password"
                  fieldName="actualPassword"
                  colorPreset="light"
                  label="Mot de passe actuel"
                />
                <FormikTextInput
                  type="password"
                  fieldName="newPassword"
                  colorPreset="light"
                  label="Nouveau mot de passe"
                />
                <FormikTextInput
                  type="password"
                  fieldName="newPasswordConfirmation"
                  colorPreset="light"
                  label="Confirmez votre nouveau mot de passe"
                />
                <div className={styles.passwordActions}>
                  <RoundedButton
                    theme="transparent-dark"
                    size="micro"
                    label="Annuler"
                    onClick={() => setShowEditPassword(false)}
                  />
                  <RoundedButton
                    theme="invalid"
                    size="micro"
                    label="Modifier le mot de passe"
                    onClick={() => props.handleSubmit()}
                    disabled={!props.isValid}
                  />
                </div>
              </div>
            )}
          </Formik>
        </>
      )}
      {!showEditPassword && (
        <RoundedButton
          theme="primary"
          size="micro"
          label="Modifier le mot de passe"
          onClick={() => setShowEditPassword(true)}
        />
      )}
    </div>
  )
}
