import { FollowTypes } from '@follow/cdk'

export type Address = FollowTypes.Address.Address
export type PracticeLocation = FollowTypes.Address.PracticeLocation

export const DEFAULT_ADDRESS: Address = {
  street2: '',
  street: '',
  zipCode: '',
  city: '',
  country: 'France',
}

export const DEFAULT_PRACTICE_LOCATION: PracticeLocation = {
  name: '',
  address: DEFAULT_ADDRESS,
}
