export interface BrowsersList {
  [browserName: string]: string
}

export interface DevicesRules {
  desktop?: BrowsersList
  tablet?: BrowsersList
  mobile?: BrowsersList
}

export interface MatchDeviceProps {
  matchDevice?: boolean
}

export const defaultBrowsersConfig: BrowsersList = {
  safari: '>=13',
  chrome: '>=85',
  chromium: '>=85',
}

export const onlyDesktopConfig: DevicesRules = {
  desktop: defaultBrowsersConfig,
}
