import React, { FunctionComponent, useEffect, useState } from 'react'
import {
  Chip,
  DatePicker,
  EntityCard,
  HorizontalScroller,
  IconChip,
  FormHeading,
} from '../../../../shared'

import { durationTimeUnits, timeUnitLabels } from '../PosologyDetail.model'
import {
  DurationBlockProps,
  PREDEFINED_MAX_TIME_VALUE,
  PREDEFINED_MIN_TIME_VALUE,
} from './DurationBlock.model'
import { integerRegex } from '../../../../../misc/regex'

export const DurationBlock: FunctionComponent<DurationBlockProps> = ({
  posologyForm,
  disabled,
  setPosologyForm,
}) => {
  const [durationSelected, setDurationSelected] = useState(true)
  const [customTimeValue, setCustomTimeValue] = useState<string>()

  useEffect(() => {
    if (posologyForm.startDate && posologyForm.endDate && !posologyForm.intakeDurationValue) {
      setDurationSelected(false)
    }
  }, [posologyForm.startDate, posologyForm.endDate, posologyForm.intakeDurationValue])

  useEffect(() => {
    if (
      posologyForm.intakeDurationValue &&
      posologyForm.intakeDurationValue > PREDEFINED_MAX_TIME_VALUE
    ) {
      setCustomTimeValue(`${posologyForm.intakeDurationValue}`)
    }
  }, [posologyForm.intakeDurationValue])

  const setDurationType = (type: string) => {
    setPosologyForm({
      ...posologyForm,
      intakeDurationType: type,
      startDate: null,
      endDate: null,
    })
  }

  const setDurationValue = (value: number, isCustomValue: boolean) => {
    setPosologyForm({
      ...posologyForm,
      intakeDurationValue: value,
      startDate: null,
      endDate: null,
    })
    if (!isCustomValue) {
      setCustomTimeValue(undefined)
    }
  }

  return (
    <EntityCard loading={false} inactive={false}>
      <div className="p-5 w-full">
        <FormHeading
          disabled={disabled}
          headings={[
            {
              icon: 'history',
              label: 'Durée',
              selected: durationSelected,
              onSelect: () => setDurationSelected(true),
            },
            {
              icon: 'calendar',
              label: 'Début/Fin',
              selected: !durationSelected,
              onSelect: () => setDurationSelected(false),
            },
          ]}
        />
        {durationSelected && (
          <>
            <div className="mt-4">
              <HorizontalScroller
                renderTitle={() => <span className="text-xs text-shades-4 font-medium">Temps</span>}
              >
                <div className="flex flex-row py-2 space-x-2">
                  {Array.from(Array(PREDEFINED_MAX_TIME_VALUE - PREDEFINED_MIN_TIME_VALUE + 1)).map(
                    (_, index) => {
                      const timeItem = index + PREDEFINED_MIN_TIME_VALUE
                      return (
                        <Chip
                          colorPreset="dark"
                          label={`${timeItem}`}
                          key={timeItem}
                          onClick={() => setDurationValue(timeItem, false)}
                          selected={
                            timeItem === posologyForm.intakeDurationValue &&
                            customTimeValue === undefined
                          }
                          renderLabel={(label) => <span>{label}</span>}
                          disabled={disabled}
                          testId={`posology-duration-time-${timeItem}`}
                        />
                      )
                    },
                  )}
                  <IconChip
                    selected={customTimeValue !== undefined}
                    editMode={customTimeValue !== undefined}
                    onClick={() => setCustomTimeValue('')}
                    label={customTimeValue !== undefined ? customTimeValue : 'Autre'}
                    onTitleEdit={(title) => {
                      setCustomTimeValue(title)
                      if (integerRegex.test(title)) {
                        setDurationValue(parseInt(title), true)
                      }
                    }}
                    icon="text"
                    disabled={disabled}
                  />
                </div>
              </HorizontalScroller>
            </div>
            <div className="mt-4">
              <HorizontalScroller
                renderTitle={() => (
                  <span className="text-xs text-shades-4 font-medium">Unité de temps</span>
                )}
              >
                <div className="flex flex-row py-2 space-x-2">
                  {durationTimeUnits.map((unit) => (
                    <Chip
                      colorPreset="dark"
                      label={timeUnitLabels[unit] ?? unit}
                      key={unit}
                      selected={unit === posologyForm.intakeDurationType}
                      onClick={() => setDurationType(unit)}
                      renderLabel={(label) => <span>{label}</span>}
                      disabled={disabled}
                      testId={`posology-duration-unit-${unit}`}
                    />
                  ))}
                </div>
              </HorizontalScroller>
            </div>
          </>
        )}
        {!durationSelected && (
          <>
            <div className="mt-4">
              <DatePicker
                label="Date de début"
                value={posologyForm.startDate}
                colorPreset="light"
                onChange={(value) => {
                  setPosologyForm({
                    ...posologyForm,
                    startDate: value,
                    intakeDurationType: undefined,
                    intakeDurationValue: undefined,
                  })
                }}
                disabled={disabled}
              />
            </div>
            <div className="mt-4">
              <DatePicker
                label="Date de fin"
                value={posologyForm.endDate}
                colorPreset="light"
                onChange={(value) => {
                  setPosologyForm({
                    ...posologyForm,
                    endDate: value,
                    intakeDurationType: undefined,
                    intakeDurationValue: undefined,
                  })
                }}
                disabled={disabled}
              />
            </div>
          </>
        )}
      </div>
    </EntityCard>
  )
}
