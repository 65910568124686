import { createContext } from 'react'

export interface PatientLayoutContextType {
  ignoredAlertCodes: string[]
  setIgnoredAlertCodes: React.Dispatch<React.SetStateAction<string[]>>
}

export const PatientLayoutContext = createContext<PatientLayoutContextType>({
  ignoredAlertCodes: [],
  setIgnoredAlertCodes: () => {},
})
