import React, { FunctionComponent } from 'react'
import { BadgeProps } from './Badge.model'
import classNames from 'classnames/bind'
import styles from './Badge.module.scss'
import { addTestIdSuffix } from '../../../misc/testId.utilities'

const cx = classNames.bind(styles)

export const Badge: FunctionComponent<BadgeProps> = ({
  value,
  size = 'small',
  theme = 'light',
  testId,
}) => {
  const themeOrZero = value === 0 ? `zero-${theme}` : theme
  return (
    <div
      className={cx(styles.container, { [size]: true }, { [themeOrZero]: true })}
      data-test-id={addTestIdSuffix('root', testId)}
    >
      <span>{value}</span>
    </div>
  )
}
