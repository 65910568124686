import { format } from 'date-fns'
import { SephiraPayload } from './sephira.model'

export const serializeSephiraPayload = (
  date: Date,
  pinCode?: string,
  readerResourceName?: string,
  professionnalResourceName?: string,
): SephiraPayload => ({
  args: {
    CodePorteurPS: pinCode,
    DateConsultation: format(date, 'yyyyMMdd'),
    NomRessourceLecteur: readerResourceName ?? '',
    NomRessourcePS: professionnalResourceName ?? '',
  },
})
