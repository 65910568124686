import { PluginNames } from '@/model/Plugins'
import { Extension } from '@tiptap/react'
import { ColorOptions, DEFAULT_COLORS_OPTIONS } from './textColor.model'

export interface TextColorOptions {
  types: Array<string>
  colorOptions: ColorOptions
}

export interface TextColorStorage {
  /**
   * Retourne les options du plugin TextColor
   */
  getOptions: () => TextColorOptions
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    [PluginNames.TextColor]: {
      setTextColor: (color: string | null) => ReturnType
      setHighlightColor: (color: string | null) => ReturnType
    }
  }

  interface CustomStorage {
    [PluginNames.TextColor]: TextColorStorage
  }
}

export const TextColor = Extension.create<TextColorOptions, TextColorStorage>({
  name: PluginNames.TextColor,

  addOptions() {
    return {
      types: ['textStyle'],
      colorOptions: DEFAULT_COLORS_OPTIONS,
    }
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          color: {
            parseHTML: (element) => element.style.color,
            renderHTML: (attributes) => {
              const color = attributes['color']
              if (!color) return {}

              return {
                style: `color: ${color}`,
              }
            },
          },
          backgroundColor: {
            parseHTML: (element) => element.style.backgroundColor,
            renderHTML: (attributes) => {
              const backgroundColor = attributes['backgroundColor']
              if (!backgroundColor) return {}

              return {
                style: `background-color: ${backgroundColor}`,
              }
            },
          },
        },
      },
    ]
  },

  addStorage() {
    return {
      getOptions: () => this.options,
    }
  },

  addCommands() {
    const isColorAllowed = (color: string | null) => {
      return this.options.colorOptions.some(({ value }) => value === color)
    }

    return {
      setTextColor:
        (color) =>
        ({ chain }) => {
          const isAllowed = isColorAllowed(color)
          if (!isAllowed) return false

          return chain().setMark('textStyle', { color: color }).run()
        },

      setHighlightColor:
        (color) =>
        ({ chain }) => {
          const isAllowed = isColorAllowed(color)
          if (!isAllowed) return false

          return chain().setMark('textStyle', { backgroundColor: color }).run()
        },
    }
  },
})
