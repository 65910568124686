import { useMutation } from '@tanstack/react-query'
import { DoctolibPatient } from '../../../misc/Doctolib/doctolib.model'
import { useCreatePatient, useUpdatePatient } from '../patients'
import {
  doctolibToFollowPatientMapper,
  openFollowPatient,
  followToDoctolibPatientMapper,
} from '../../../misc/Doctolib/doctolib.utils'
import { UserFriendlyError } from '../../../model/Error'
import { doctolibZipperUpdateOrCreatePatient } from '../../../misc/Doctolib/doctolib.actions'

interface PatientMutateVariable {
  patient: DoctolibPatient
}

export const useCreateOrUpdateFollowPatient = () => {
  const { mutate: createPatient } = useCreatePatient({ triggerSubscription: false })
  const { mutate: updatePatient } = useUpdatePatient({ triggerSubscription: false })

  return useMutation<void, Error, PatientMutateVariable>(async ({ patient }) => {
    try {
      if (patient.pms_id) {
        const fwPatientId = parseInt(patient.pms_id, 10)
        if (Number.isInteger(fwPatientId)) {
          const fwPatient = doctolibToFollowPatientMapper(patient, false)
          updatePatient({ id: fwPatientId, payload: fwPatient })
        } else {
          throw new UserFriendlyError(
            'error',
            `L'identifiant du patient indiqué par doctolib n'a pas le bon format: (${JSON.stringify(
              patient.pms_id,
            )})`,
          )
        }
      } else {
        const fwPatient = doctolibToFollowPatientMapper(patient, true)
        createPatient(fwPatient, {
          onSuccess: (createPatientData) => {
            openFollowPatient(createPatientData.id)
            doctolibZipperUpdateOrCreatePatient({
              ...followToDoctolibPatientMapper(createPatientData),
              doctolib_id: patient.doctolib_id, // On repasse le doctolib id pour éviter la création d'un doublon au retour dans Doctolib
            })
          },
        })
      }
    } catch (e: any) {
      throw new UserFriendlyError(
        'error',
        `L'import des données patient depuis Doctolib a échoué : ${e.message}`,
      )
    }
  })
}
