import { connect } from 'react-redux'
import { compose } from 'recompose'
import { medicalEventDomainActions } from '../../../../store/domain/medicalEvents'

import { bottomPanelDocumentHistoryActions } from '../../../../store/ui/bottomPanelDocumentHistory'

import {
  SearchDocumentHistoryDispatchProps,
  SearchDocumentHistoryProps,
} from './SearchDocumentHistory.model'

const mapDisptachToProps: SearchDocumentHistoryDispatchProps = {
  download: medicalEventDomainActions.downloadDocumentRenderHistory,
  restore: bottomPanelDocumentHistoryActions.restoreDocumentHistory,
}

const withConnect = connect(null, mapDisptachToProps)

export const withCompose = compose<SearchDocumentHistoryProps, {}>(withConnect)
