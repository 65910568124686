import { RestuxResources } from '../../resources'
import { uiActionsFactory } from '../../restux/ui'
import { RestuxPagination, RestuxUiSagaConf } from '../../restux/ui/restuxUi.model'
import { mailContactSearchPaginationSelector } from './mailContactSearch.selectors'

export const mailContactSearchConfig: RestuxUiSagaConf = {
  resourceName: RestuxResources.mailContactSearch,
  identifier: 'mailContactSearch',
  paginationSelector: mailContactSearchPaginationSelector,
}

export interface MailContactSearchState {
  pagination: RestuxPagination
}

export const uiActions = uiActionsFactory(mailContactSearchConfig)

export const UiMailContactSearchActionTypes = {
  ...uiActions.types,
}
