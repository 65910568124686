import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import { HealthDataPregnanciesProps } from './HealthDataPregnancies.model'
import styles from './HealthDataPregnancies.module.scss'
import {
  AccordionItem,
  DatePicker,
  FullLoader,
  LabeledButton,
  SelectInput,
  TextArea,
} from '../../../shared'
import {
  computeTypeLabel,
  findPregnancyTypeOption,
  findTwinPregnancyTypeOption,
} from '../../../../misc/healthData.utilities'
import { DATE_FORMAT_FRONT_SHORT, formatFr } from '../../../../misc/date.utilities'
import {
  PregnancyType,
  PREGNANCY_TYPE_OPTIONS,
  Pregnancy,
  TWIN_PREGNANCY_TYPE_OPTIONS,
  HealthDataCode,
  PregnancyData,
} from '../../../../model/HealthData'
import { addDays, addMonths, addWeeks, isPast, subDays, subWeeks } from 'date-fns'
import classNames from 'classnames'
import { useGetInfinitePatientHistoryHealthData } from '../../../../hooks/queries/patientHealthData'

export const HealthDataPregnancies: FunctionComponent<HealthDataPregnanciesProps> = ({
  patientId,
  healthDataType,
  indexedValues,
  indexedTypes,
  onPregnancyChange,
}) => {
  const {
    query: { isLoading },
    healthDataHistory,
  } = useGetInfinitePatientHistoryHealthData({
    patientId: patientId,
    moduleName: healthDataType.module,
    moduleDataTypeId: healthDataType.id,
  })
  const [open, setOpen] = useState(-1)

  const [pregnancyEntities, setPregnancyEntities] = useState<Partial<Pregnancy>[]>([])

  const pregnancyHistory: Array<Pregnancy> = useMemo(
    () =>
      healthDataHistory
        .filter(
          (entry): entry is PregnancyData =>
            entry.moduleDataType.code === HealthDataCode.pregnancyEntity,
        )
        .map((entry) => entry.value.value),
    [healthDataHistory],
  )
  const allowNewPregnancy = useMemo(() => {
    return pregnancyEntities.every(
      (pregnancy) => pregnancy.pregnancyEnd && isPast(pregnancy.pregnancyEnd),
    )
  }, [pregnancyEntities])

  const addNewPregnancy = useCallback(() => {
    setPregnancyEntities((currentPregnancies) => [
      ...currentPregnancies,
      {
        pregnancyType: PregnancyType.UNIQUE,
      },
    ])
    setOpen(pregnancyEntities.length)
  }, [setPregnancyEntities, setOpen, pregnancyEntities.length])

  const calculPregnancyDate = useCallback(
    (pregnancyType, index, value) => {
      if (!value) {
        return
      }
      setPregnancyEntities((currentValue) => {
        const pregnancyStart = addDays(value, 14)
        const dueDate = addMonths(pregnancyStart, 9)
        const maternityLeaveStart = subWeeks(
          dueDate,
          pregnancyType === PregnancyType.UNIQUE
            ? indexedValues.number_of_children?.value?.value &&
              parseInt(indexedValues.number_of_children?.value?.value) >= 2
              ? 8
              : 6
            : 12,
        )
        const maternityLeaveEnd = addWeeks(
          dueDate,
          pregnancyType === PregnancyType.UNIQUE
            ? indexedValues.number_of_children?.value?.value &&
              parseInt(indexedValues.number_of_children?.value?.value) >= 2
              ? 18
              : 10
            : 22,
        )
        const pathologicalLeaveStart = subDays(maternityLeaveStart, 14)
        return currentValue.map((currentPregnancy, currentIndex) => {
          if (currentIndex === index)
            return {
              ...currentPregnancy,
              lastMenstrualPeriod: value,
              pregnancyStart,
              dueDate,
              pathologicalLeaveStart,
              maternityLeaveStart,
              maternityLeaveEnd,
            }
          return currentPregnancy
        })
      })
    },
    [setPregnancyEntities, indexedValues],
  )

  useEffect(() => {
    setPregnancyEntities(pregnancyHistory)
  }, [pregnancyHistory])

  useEffect(() => {
    // Si state courant différent de state original, comparaison par référence volontaire
    if (pregnancyEntities !== pregnancyHistory) {
      onPregnancyChange(
        pregnancyEntities.map((pregnancy) => ({
          value: { ...pregnancy, id: pregnancy.id },
          type: indexedTypes.pregnancy_entity,
        })),
      )
    }
  }, [indexedTypes, onPregnancyChange, pregnancyEntities, pregnancyHistory])

  return (
    <div className={classNames(styles.container)}>
      {isLoading ? (
        <FullLoader />
      ) : (
        <>
          {pregnancyEntities.map((pregnancy, index) => (
            <AccordionItem
              open={open === index}
              onOpenClose={() => setOpen((currentValue) => (currentValue === index ? -1 : index))}
              overflow={true}
              renderLabel={() => (
                <div
                  className={classNames(styles.accordion_title, {
                    [styles.pregnancy_ended]:
                      pregnancy.pregnancyEnd && isPast(pregnancy.pregnancyEnd),
                  })}
                >
                  <span>Grossesse</span>
                  <span>{`${
                    pregnancy.pregnancyStart
                      ? `Début ${formatFr(pregnancy.pregnancyStart, DATE_FORMAT_FRONT_SHORT)}`
                      : ''
                  } ${
                    pregnancy.dueDate
                      ? `- Terme ${formatFr(pregnancy.dueDate, DATE_FORMAT_FRONT_SHORT)}`
                      : ''
                  }`}</span>
                  <span>
                    {pregnancy.pregnancyEnd && isPast(pregnancy.pregnancyEnd)
                      ? 'Terminée'
                      : 'En cours'}
                  </span>
                </div>
              )}
              key={index}
            >
              <div className={styles.content}>
                <div className={styles.column}>
                  {indexedTypes.pregnancy_type && (
                    <SelectInput
                      title={computeTypeLabel(indexedTypes.pregnancy_type)}
                      placeholder={pregnancy?.pregnancyType}
                      options={PREGNANCY_TYPE_OPTIONS}
                      value={
                        pregnancy?.pregnancyType
                          ? findPregnancyTypeOption(pregnancy.pregnancyType)
                          : undefined
                      }
                      onSelect={(selected) => {
                        setPregnancyEntities((currentValue) => {
                          return currentValue.map((currentPregnancy, currentIndex) => {
                            if (currentIndex === index)
                              return { ...currentPregnancy, pregnancyType: selected.value }
                            return currentPregnancy
                          })
                        })
                        calculPregnancyDate(selected.value, index, pregnancy?.lastMenstrualPeriod)
                      }}
                    />
                  )}
                  {indexedTypes.twin_pregnancy &&
                    pregnancy?.pregnancyType === PregnancyType.GEMELLAIRE && (
                      <SelectInput
                        title={computeTypeLabel(indexedTypes.twin_pregnancy)}
                        placeholder={pregnancy?.twinPregnancy}
                        options={TWIN_PREGNANCY_TYPE_OPTIONS}
                        value={
                          pregnancy?.twinPregnancy
                            ? findTwinPregnancyTypeOption(pregnancy.twinPregnancy)
                            : undefined
                        }
                        onSelect={(selected) =>
                          setPregnancyEntities((currentValue) => {
                            return currentValue.map((currentPregnancy, currentIndex) => {
                              if (currentIndex === index)
                                return { ...currentPregnancy, twinPregnancy: selected.value }
                              return currentPregnancy
                            })
                          })
                        }
                      />
                    )}
                  {indexedTypes.pregnancy_comment && (
                    <div className={styles.number_input_layout}>
                      <TextArea
                        name="pregnancy_comment"
                        label={computeTypeLabel(indexedTypes.pregnancy_comment)}
                        value={pregnancy?.pregnancyComment ?? ''}
                        placeholder={pregnancy?.pregnancyComment}
                        onChange={(event) => {
                          const value = event.target.value
                          setPregnancyEntities((currentValue) => {
                            return currentValue.map((currentPregnancy, currentIndex) => {
                              if (currentIndex === index)
                                return { ...currentPregnancy, pregnancyComment: value }
                              return currentPregnancy
                            })
                          })
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className={styles.column}>
                  {indexedTypes.last_menstrual_period && (
                    <DatePicker
                      label={computeTypeLabel(indexedTypes.last_menstrual_period)}
                      placeholder={
                        pregnancy?.lastMenstrualPeriod
                          ? formatFr(pregnancy?.lastMenstrualPeriod, DATE_FORMAT_FRONT_SHORT)
                          : 'jj/mm/aaaa'
                      }
                      value={pregnancy?.lastMenstrualPeriod ? pregnancy?.lastMenstrualPeriod : null}
                      onChange={(value) =>
                        value && calculPregnancyDate(pregnancy?.pregnancyType, index, value)
                      }
                      showCalendarButton={false}
                    />
                  )}
                  {indexedTypes.pregnancy_start && (
                    <DatePicker
                      label={computeTypeLabel(indexedTypes.pregnancy_start)}
                      placeholder={
                        pregnancy?.pregnancyStart
                          ? formatFr(pregnancy?.pregnancyStart, DATE_FORMAT_FRONT_SHORT)
                          : 'jj/mm/aaaa'
                      }
                      value={pregnancy?.pregnancyStart ? pregnancy?.pregnancyStart : null}
                      onChange={(value) =>
                        value &&
                        setPregnancyEntities((currentValue) => {
                          return currentValue.map((currentPregnancy, currentIndex) => {
                            if (currentIndex === index)
                              return { ...currentPregnancy, pregnancyStart: value }
                            return currentPregnancy
                          })
                        })
                      }
                      showCalendarButton={false}
                    />
                  )}
                  {indexedTypes.due_date && (
                    <DatePicker
                      label={computeTypeLabel(indexedTypes.due_date)}
                      placeholder={
                        pregnancy?.dueDate
                          ? formatFr(pregnancy?.dueDate, DATE_FORMAT_FRONT_SHORT)
                          : 'jj/mm/aaaa'
                      }
                      value={pregnancy?.dueDate ? pregnancy?.dueDate : null}
                      onChange={(value) =>
                        value &&
                        setPregnancyEntities((currentValue) => {
                          return currentValue.map((currentPregnancy, currentIndex) => {
                            if (currentIndex === index)
                              return { ...currentPregnancy, dueDate: value }
                            return currentPregnancy
                          })
                        })
                      }
                      showCalendarButton={false}
                    />
                  )}
                  {indexedTypes.pathological_leave_start && (
                    <DatePicker
                      direction="top"
                      label={computeTypeLabel(indexedTypes.pathological_leave_start)}
                      placeholder={
                        pregnancy?.pathologicalLeaveStart
                          ? formatFr(pregnancy?.pathologicalLeaveStart, DATE_FORMAT_FRONT_SHORT)
                          : 'jj/mm/aaaa'
                      }
                      value={
                        pregnancy?.pathologicalLeaveStart ? pregnancy?.pathologicalLeaveStart : null
                      }
                      onChange={(value) =>
                        value &&
                        setPregnancyEntities((currentValue) => {
                          return currentValue.map((currentPregnancy, currentIndex) => {
                            if (currentIndex === index)
                              return { ...currentPregnancy, pathologicalLeaveStart: value }
                            return currentPregnancy
                          })
                        })
                      }
                      showCalendarButton={false}
                    />
                  )}
                  {indexedTypes.maternity_leave_start && (
                    <DatePicker
                      direction="top"
                      label={computeTypeLabel(indexedTypes.maternity_leave_start)}
                      placeholder={
                        pregnancy.maternityLeaveStart
                          ? formatFr(pregnancy.maternityLeaveStart, DATE_FORMAT_FRONT_SHORT)
                          : 'jj/mm/aaaa'
                      }
                      value={pregnancy?.maternityLeaveStart ? pregnancy?.maternityLeaveStart : null}
                      onChange={(value) =>
                        value &&
                        setPregnancyEntities((currentValue) => {
                          return currentValue.map((currentPregnancy, currentIndex) => {
                            if (currentIndex === index)
                              return { ...currentPregnancy, maternityLeaveStart: value }
                            return currentPregnancy
                          })
                        })
                      }
                      showCalendarButton={false}
                    />
                  )}
                  {indexedTypes.maternity_leave_end && (
                    <DatePicker
                      direction="top"
                      label={computeTypeLabel(indexedTypes.maternity_leave_end)}
                      placeholder={
                        pregnancy.maternityLeaveEnd
                          ? formatFr(pregnancy.maternityLeaveEnd, DATE_FORMAT_FRONT_SHORT)
                          : 'jj/mm/aaaa'
                      }
                      value={pregnancy?.maternityLeaveEnd ? pregnancy?.maternityLeaveEnd : null}
                      onChange={(value) =>
                        value &&
                        setPregnancyEntities((currentValue) => {
                          return currentValue.map((currentPregnancy, currentIndex) => {
                            if (currentIndex === index)
                              return { ...currentPregnancy, maternityLeaveEnd: value }
                            return currentPregnancy
                          })
                        })
                      }
                      showCalendarButton={false}
                    />
                  )}
                  {indexedTypes.pregnancy_end && (
                    <DatePicker
                      direction="top"
                      label={computeTypeLabel(indexedTypes.pregnancy_end)}
                      placeholder={
                        pregnancy.pregnancyEnd
                          ? formatFr(pregnancy.pregnancyEnd, DATE_FORMAT_FRONT_SHORT)
                          : 'jj/mm/aaaa'
                      }
                      value={pregnancy?.pregnancyEnd ? new Date(pregnancy?.pregnancyEnd) : null}
                      onChange={(value) =>
                        value &&
                        setPregnancyEntities((currentValue) => {
                          return currentValue.map((currentPregnancy, currentIndex) => {
                            if (currentIndex === index)
                              return { ...currentPregnancy, pregnancyEnd: value }
                            return currentPregnancy
                          })
                        })
                      }
                      showCalendarButton={false}
                    />
                  )}
                </div>
              </div>
            </AccordionItem>
          ))}
          {allowNewPregnancy && (
            <div className={styles.pregnancy_button}>
              <LabeledButton icon="add" label="Ajouter une Grossesse" onClick={addNewPregnancy} />
            </div>
          )}
        </>
      )}
    </div>
  )
}
