import { ChangeEvent } from 'react'
import { hasSuperAdminRole } from '../../../misc/roles.utilities'
import { IdentityStatus, Patient, Sex, Title } from '../../../model/Patient'
import { User } from '../../../model/User'
import { getEditorEmptyJsonValue } from '@follow/farte'
import { inputDateFormatter } from '../../../misc/date.utilities'
import { inputInseeNumberFormatter } from '../../../misc/inseeNumber.utils'
import { EMPTY_DEFAULT_VALUES, PatientFormFields } from './PatientForm.model'
import { isINSIValidated } from '../../../misc/patient.utilities'

export function toLocaleUpperCase(event: ChangeEvent<HTMLInputElement>) {
  const element = event.target
  const start = element.selectionStart
  const end = element.selectionEnd
  element.value = element.value.toUpperCase()
  if (start && end) element.setSelectionRange(start, end)
  return element.value
}

export const mapSexToDefaultTitle = {
  [Sex.FEMALE]: Title.MS,
  [Sex.MALE]: Title.MR,
  [Sex.UNKNOWN]: Title.NONE,
}

export const shouldDisableINSIFields = (
  identityStatus: IdentityStatus,
  loggedUser: User | null,
) => {
  if (loggedUser && !hasSuperAdminRole(loggedUser?.roles)) {
    return isINSIValidated(identityStatus)
  } else {
    return false
  }
}

export function getInitialValues(patient: Partial<Patient>): PatientFormFields {
  return {
    // Bloc patient
    birthFirstName: patient.birthFirstName || '',
    birthLastName: patient.birthLastName || '',
    usedFirstName: patient.usedFirstName || '',
    usedLastName: patient.usedLastName || '',
    otherFirstNames: patient.otherFirstNames || '',
    birthDate: inputDateFormatter(patient.birthDate || ''),
    sex: patient.sex,
    title: patient.title || Title.NONE,
    profession: patient.profession || '',
    birthPlaceCode: patient.birthPlaceCode?.toString() || undefined,
    // Bloc coordonnées
    address: patient.address
      ? {
          street2: patient.address.street2 || '',
          street: patient.address.street || '',
          zipCode: patient.address.zipCode || '',
          city: patient.address.city || '',
          country: patient.address.country || '',
        }
      : {
          street2: '',
          street: '',
          zipCode: '',
          city: '',
          country: '',
        },
    telephone1: patient.telephone1 || '',
    telephone2: patient.telephone2 || '',
    email: patient.email || '',
    identityStatus: patient.identityStatus || EMPTY_DEFAULT_VALUES.identityStatus,
    disallowMSSMessaging: patient.disallowMSSMessaging ?? false,

    // Bloc santé
    inseeNumber: patient.inseeNumber ? inputInseeNumberFormatter(patient.inseeNumber) : '',
    assigningAuthority: patient.assigningAuthority,
    healthInsuranceNumber: patient.healthInsuranceNumber || '',
    doctor: patient.doctor || '',
    note: patient.note || getEditorEmptyJsonValue(),
    contacts: patient.contacts ? [...patient.contacts] : [],
    customFields: patient.customFields || [],
    isDeceased: patient.isDeceased ?? false,
  }
}
