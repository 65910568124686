import { createReducer } from 'redux-starter-kit'
import {
  domainPrescriptionTemplateActionTypes,
  PrescriptionTemplatesState,
} from './prescriptionTemplate.model'
import { domainPrescriptionTemplateActions } from './prescriptionTemplate.actions'

const INITIAL_STATE: PrescriptionTemplatesState = {
  prescriptionTemplates: null,
}

const ACTION_HANDLERS = {
  [domainPrescriptionTemplateActionTypes.STORE_COLLECTION]: (
    state: PrescriptionTemplatesState = INITIAL_STATE,
    { prescriptionTemplates }: ReturnType<typeof domainPrescriptionTemplateActions.storeCollection>,
  ) => ({
    ...state,
    prescriptionTemplates,
  }),
}

export const prescriptionTemplateReducer = createReducer(INITIAL_STATE, ACTION_HANDLERS)
