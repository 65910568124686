import { CdaContext } from './CdaContext'
import { LightPatient } from './Patient'
import { Filters } from './Filters'
import { SORT_DIRECTION } from './Resource'

export enum TaskStatus {
  DONE = 'DONE',
  TODO = 'TODO',
}

export enum TaskCategory {
  OTHER = 'OTHER',
  EDIT = 'EDIT',
  BILLING = 'BILLING',
  INFORMATION = 'INFORMATION',
  PRESCRIPTION = 'PRESCRIPTION',
  BIOLOGICAL_REPORT = 'BIOLOGICAL_REPORT',
}

export interface TaskInformation {
  title: string
  value: string
}

export interface TaskMailInformation {
  id: string
  recipientAddress: string
}

export interface Task {
  id: number
  title: string
  createdAt: Date
  status: TaskStatus
  category: TaskCategory
  read: boolean
  cdaContext: CdaContext
  patient?: LightPatient
  editMode?: boolean
  information?: TaskInformation[]
  autoCreated?: boolean
  isNotify?: boolean
  email: TaskMailInformation | null
}

export type TaskSorting = 'title' | 'patient' | 'date' | 'type'

export type TaskMutationPayload = Partial<SafeOmit<Task, 'patient'>> & {
  patientId?: number | null
}

export interface TaskFilters extends Filters {
  status?: string
  read?: boolean
  title?: string
  type?: string
  startDate?: string
  endDate?: string
  order?: SORT_DIRECTION
  orderBy?: TaskSorting
  patientId?: number
  autoCreated?: boolean
  includeCompletedSince?: string
}
