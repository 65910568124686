import { RestuxApiConfig } from '../../../restux/cache/restuxCache.model'
import getApi from '../../../../api'
import { MedicalEvent } from '../../../../model/MedicalEvent'
import { PaginatedList } from '../../../../model/Pagination'
import {
  deserializeMedicalEventPaginatedResponse,
  deserializeMedicalEventResponse,
  toMedicalEventUpdateResource,
} from './MedicalEvents.mapper'
import { ApiMedicalEventListItemResource, ApiMedicalEventResource } from './MedicalEvents.model'
import { ApiResponse } from 'apisauce'

const medicaEventsBaseUrl = '/medical_events'

export const restuxMedicalEventApiConfig: RestuxApiConfig<MedicalEvent, MedicalEvent> = {
  url: medicaEventsBaseUrl,
  getPaginatedItems: (route) =>
    getApi()
      .get<PaginatedList<ApiMedicalEventListItemResource>>(route)
      .then(deserializeMedicalEventPaginatedResponse),
  getItem: (route) =>
    getApi().get<ApiMedicalEventResource>(route).then(deserializeMedicalEventResponse),
  createItem: (route, form) =>
    getApi().post<ApiMedicalEventResource>(route, form).then(deserializeMedicalEventResponse),
  updateItem: (route, item) =>
    getApi()
      .put<ApiMedicalEventResource>(route, toMedicalEventUpdateResource(item))
      .then(deserializeMedicalEventResponse),
  deleteItem: (route) => getApi().delete(route),
}

export const duplicateMedicalEventDocuments = (
  medicalEventId: number,
  ids: number[],
): Promise<ApiResponse<void>> =>
  getApi().post(`${medicaEventsBaseUrl}/${medicalEventId}/duplicate`, {
    type: 'documents',
    ids,
  })
