import { useMutation } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { LegalDocument } from '../../../model/LegalDocument'
import { readLegalDocument } from '../../../data/legalDocument'

export const useReadLegalDocument = () => {
  return useMutation<LegalDocument, Error, string>(async (id) => {
    const { ok, data } = await readLegalDocument(id)
    if (!ok || !data) {
      throw new UserFriendlyError('error', 'Impossible de valider la lecture du document légal')
    }
    return data
  })
}
