import { take, takeEvery, put, race } from 'redux-saga/effects'
import {
  setBottomPanelOptions,
  BottomPanelComponentType,
  BottomPanelActionTypes,
} from '../bottomPanel'

import { bottomPanelDrugsActions } from './bottomPanelDrugs.actions'
import { UiBottomPanelDrugsActionTypes } from './bottomPanelDrugs.model'
import { domainEditorActions } from '../../domain/editor/editor.actions'

function* searchDrugSagaWorker({
  onDrugSelected,
  state: { multiSelect },
}: ReturnType<typeof bottomPanelDrugsActions.searchDrug>) {
  yield put(
    setBottomPanelOptions({
      title: multiSelect ? `Ajouter une préparation de médicaments` : `Ajouter un médicament`,
      open: true,
      componentType: BottomPanelComponentType.SearchDrug,
    }),
  )
  // Catch de la primary Action et de la fermeture du bottom panel
  const {
    selected,
  }: {
    selected?: ReturnType<typeof bottomPanelDrugsActions.selectDrug>
  } = yield race({
    canceled: take(BottomPanelActionTypes.SET_BOTTOM_PANEL_OPTIONS),
    selected: take(UiBottomPanelDrugsActionTypes.SELECT_DRUG),
  })
  if (selected) {
    yield put(domainEditorActions.setPendingVariables({ prescriptions: [selected.prescription] }))
    onDrugSelected(selected.prescription)

    yield put(
      setBottomPanelOptions({
        open: false,
        componentType: BottomPanelComponentType.SearchDrug,
      }),
    )
  }
  // Clear du state à la fermeture du bottom panel
  yield put(bottomPanelDrugsActions.clearSearchState())
}

function* searchDrugSagaWatcher() {
  yield takeEvery(UiBottomPanelDrugsActionTypes.SEARCH_DRUG, searchDrugSagaWorker)
}

export const bottomPanelDrugsSagas = {
  searchDrugSagaWatcher,
}
