import { call, put, takeEvery } from 'redux-saga/effects'
import { ApiResponse } from 'apisauce'
import { domainPrescriptionTemplateActions } from './prescriptionTemplate.actions'
import { createPrescriptionTemplate, deleteItem, getCollection } from './api'
import { domainPrescriptionTemplateActionTypes } from './prescriptionTemplate.model'
import { Drug } from '../../../model/Drug'
import { isSuccessfulApiResponse } from '../../restux/cache/restuxCacheSagas.factory'
import { addResponseError } from '../../message'
import { ApiPrescriptionTemplateList } from './api/prescriptionTemplate.model'

function* getPrescriptionTemplatesWorker({
  drugs,
}: ReturnType<typeof domainPrescriptionTemplateActions.getCollection>) {
  const drugIds = drugs.map((drug: Drug) => drug.id).join()
  const response: ApiResponse<ApiPrescriptionTemplateList> = yield call(getCollection, drugIds)

  if (isSuccessfulApiResponse(response) && response.data) {
    yield put(domainPrescriptionTemplateActions.storeCollection(response.data.items))
  } else {
    yield put(addResponseError(response))
  }
}

function* getPrescriptionTemplatesWatcher() {
  yield takeEvery(
    domainPrescriptionTemplateActionTypes.GET_COLLECTION,
    getPrescriptionTemplatesWorker,
  )
}

function* deletePrescriptionTemplateWorker({
  prescriptionTemplate,
}: ReturnType<typeof domainPrescriptionTemplateActions.deleteItem>) {
  const response: ApiResponse<void> = yield call(deleteItem, prescriptionTemplate.uuid)

  if (response.ok) {
    yield put(domainPrescriptionTemplateActions.getCollection(prescriptionTemplate.drugs))
  } else {
    yield put(addResponseError(response))
  }
}

function* deletePrescriptionTemplateWatcher() {
  yield takeEvery(
    domainPrescriptionTemplateActionTypes.DELETE_ITEM,
    deletePrescriptionTemplateWorker,
  )
}

function* createPrescriptionTemplateWorker({
  prescriptionUuid,
  name,
}: ReturnType<typeof domainPrescriptionTemplateActions.createPrescriptionTemplate>) {
  const response: ApiResponse<void> = yield call(createPrescriptionTemplate, prescriptionUuid, name)

  if (response && response.ok) {
    return
  }

  yield put(addResponseError(response))
}

function* createPrescriptionTemplateWatcher() {
  yield takeEvery(
    domainPrescriptionTemplateActionTypes.CREATE_PRESCRIPTION_TEMPLATE,
    createPrescriptionTemplateWorker,
  )
}

export const prescriptionTemplateSagas = {
  getPrescriptionTemplatesWatcher,
  deletePrescriptionTemplateWatcher,
  createPrescriptionTemplateWatcher,
}
