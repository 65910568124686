import React, { FunctionComponent, useEffect, useState } from 'react'
import { DocumentTemplateListItem } from '../../../model/DocumentTemplate'
import { Button, ListItemType } from '../../shared'
import { SearchDocumentTemplatesProps } from './SearchDocumentTemplates.model'
import createSearchPanel, { FiltersType } from '../../../containers/SearchPanel'
import { RestuxLoadingState } from '../../../store/restux/ui'
import { FwResourceType } from '../../../model/Resource'
import { DocumentListItem } from '../../document'
import { useGetUsedTags } from '../../../hooks/queries/tag'

const SearchPanel = createSearchPanel<DocumentTemplateListItem>()

export const SearchDocumentTemplates: FunctionComponent<SearchDocumentTemplatesProps> = ({
  documentTemplatesPage,
  getPaginatedDocumentTemplate,
  getUsedDocumentCategories,
  usedDocumentCategories,
  clearDocumentTemplateList,
  actions,
  setSelection,
}) => {
  const [selected, setSelected] = useState<ReadonlyArray<DocumentTemplateListItem>>([])
  const { usedTagList: usedTags } = useGetUsedTags({
    usedInResource: FwResourceType.DocumentTemplates,
  })
  const applySelection = (selectedItem?: ListItemType) => {
    const selection = selectedItem
      ? documentTemplatesPage.items.filter((item) => item.id === selectedItem.id)
      : documentTemplatesPage.items.filter((item) => selected.some(({ id }) => id === item.id))

    setSelection(selection, {
      origin: 'bottomPanel',
    })
  }

  useEffect(() => {
    getUsedDocumentCategories(FwResourceType.DocumentTemplates)
  }, [getUsedDocumentCategories])

  return (
    <SearchPanel
      items={documentTemplatesPage.items}
      loading={documentTemplatesPage.loadingState !== RestuxLoadingState.IDLE}
      itemCount={documentTemplatesPage.totalItems}
      pageCount={documentTemplatesPage.totalPages}
      onClearItems={() => clearDocumentTemplateList()}
      onMultiSelectionChange={(selection) => setSelected(selection)}
      onPrimaryAction={applySelection}
      getItems={(currentPage, { search, ...filters }) =>
        getPaginatedDocumentTemplate({
          page: { currentPage, pageSize: 40 },
          filters: {
            title: search,
            categories: filters.documentCategories,
            tagIds: filters.tagIds,
          },
        })
      }
      actions={actions}
      renderItem={(item) => <DocumentListItem item={item} />}
      renderActions={() =>
        (selected.length > 0 && (
          <Button theme="primary" label="Valider" onClick={() => applySelection()} />
        )) ||
        null
      }
      filterCapabilities={[FiltersType.Title, FiltersType.DocumentCategory, FiltersType.Tags]}
      documentCategories={usedDocumentCategories}
      usedTags={usedTags}
    />
  )
}
