import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react'
import { EFFICIENCE_APP_URL } from '../../../environment/efficience'
import { DmpRequestTypes } from '../../../store/domain/dmp'
import { Modal } from '../../shared'
import { EffecienceIframeProps } from './EfficienceIframe.model'
import { isDefined } from '../../../misc/functions.utilities'
import { useCurrentPatient } from '../../../hooks/utils'

const EFFICIENCE_LOGIN_REQUIRED_ERROR_CODE = 'NoHPConnected'

export const EfficienceIframe: FunctionComponent<EffecienceIframeProps> = ({
  request,
  addValid,
  updateDocument,
  setRequest,
  sendDmpMetrics,
}) => {
  const { currentPatient } = useCurrentPatient()
  const efficienceRef = useRef<HTMLIFrameElement>(null)
  const [openEfficience, setOpenEfficience] = useState(false)

  const sendMessageToEfficience = useCallback(() => {
    if (request?.uuid && request.payload) {
      efficienceRef.current?.contentWindow?.postMessage(request.payload, '*')
    }
  }, [request])

  const efficienceMessageHandler = useCallback(
    (event: MessageEvent) => {
      if (event.origin === EFFICIENCE_APP_URL) {
        const response = event.data
        if (response.data) {
          const { answer } = JSON.parse(window.atob(response.data))
          if (answer.errors) {
            setOpenEfficience(true)
          } else if (answer.RelatesTo === DmpRequestTypes.SEND_DOCUMENT && request?.documentId) {
            addValid('Le document a bien été envoyé au dossier médical partagé')
            updateDocument(request.documentId, {
              type: 'farte',
              dmpDocumentId: answer.document.uniqueId,
            })
            setRequest(undefined)
          } else if (answer.RelatesTo === DmpRequestTypes.DELETE_DOCUMENT && request?.documentId) {
            updateDocument(request?.documentId, { type: 'farte', dmpDocumentId: null })
            addValid('Le document a été supprimé du DMP')
          }

          // Metrics
          if (
            answer.RelatesTo === DmpRequestTypes.SEND_DOCUMENT &&
            isDefined(currentPatient) &&
            (!answer.errors ||
              answer?.errors.every(({ code }) => code !== EFFICIENCE_LOGIN_REQUIRED_ERROR_CODE)) // On n'ajoute pas aux metrics les erreurs d'auth
          ) {
            sendDmpMetrics({
              success: !answer.errors,
              patientId: currentPatient.id,
            })
          }
        } else {
          if (response.type === 'login') {
            setOpenEfficience(false)
            if (request) {
              sendMessageToEfficience()
            }
          }
        }
      }
    },
    [
      request,
      currentPatient,
      addValid,
      updateDocument,
      setRequest,
      sendDmpMetrics,
      sendMessageToEfficience,
    ],
  )

  useEffect(() => {
    window.addEventListener('message', efficienceMessageHandler)
    return () => {
      window.removeEventListener('message', efficienceMessageHandler)
    }
  }, [efficienceMessageHandler])

  useEffect(() => {
    sendMessageToEfficience()
  }, [sendMessageToEfficience])

  return (
    <Modal
      onClickOutside={() => {
        setOpenEfficience(false)
      }}
      isUnmounting={false}
      hidden={!openEfficience}
    >
      <div className="w-screen-3/4 h-screen-3/4 bg-shades-white shadow-2xl rounded-3xl p-5">
        <iframe
          className="w-full h-full"
          ref={efficienceRef}
          title="efficience-iframe"
          src={EFFICIENCE_APP_URL}
        />
      </div>
    </Modal>
  )
}
