import { useMutation, useQueryClient } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { updatePatient } from '../../../data/patients'
import { patientsKeys } from './patients.keys'
import { PatientMutationPayload } from '../../../data/patients/mapper/patients.model'
import { Patient } from '../../../model/Patient'

export interface UpdatePatientMutateVariable {
  id: number
  payload: PatientMutationPayload
}

export const useUpdatePatient = ({
  optimistic = false,
  triggerSubscription = true,
}: { optimistic?: boolean; triggerSubscription?: boolean } = {}) => {
  const queryClient = useQueryClient()

  return useMutation<Patient, Error, UpdatePatientMutateVariable>(
    triggerSubscription ? patientsKeys.update : [],
    async ({ id, payload }) => {
      if (optimistic) {
        queryClient.setQueryData(patientsKeys.detail(id), payload)
      }

      const { data, ok } = await updatePatient(id, payload)
      if (!data || !ok) {
        throw new UserFriendlyError('error', 'La modification du patient a échoué')
      }
      return data
    },
    {
      onSuccess: (patient) => {
        queryClient.invalidateQueries(patientsKeys.lists)
        queryClient.setQueryData(patientsKeys.detail(patient.id), patient)
      },
    },
  )
}
