import { ApisauceConfig } from 'apisauce'
import { MetricsState, metricsActionsType } from './metrics.model'
import { MetricsParams } from '../../cache/metrics/api/metrics.model'
import { DmpMetricsPayload } from '../../../model/Metrics'

export const metricsActions = {
  getMetrics: (params: MetricsParams, config?: Partial<ApisauceConfig>) => ({
    type: metricsActionsType.GET_LIST_OF_METRICS,
    params,
    config,
  }),

  getMetricsPending: () => ({
    type: metricsActionsType.GET_LIST_OF_METRICS_PENDING,
  }),

  getMetricsFailed: () => ({
    type: metricsActionsType.GET_LIST_OF_METRICS_FAILED,
  }),

  getMetricsSuccess: (metrics: MetricsState['messageSendingInfos']) => ({
    type: metricsActionsType.GET_LIST_OF_METRICS_SUCCESS,
    metrics,
  }),

  clearMetrics: () => ({
    type: metricsActionsType.CLEAR_METRICS,
  }),

  downloadMetrics: (dateInterval: { start: Date; end: Date }) => ({
    type: metricsActionsType.DOWNLOAD_METRICS,
    dateInterval,
  }),

  sendDmpMetrics: (payload: DmpMetricsPayload) => ({
    type: metricsActionsType.SEND_DMP_METRICS,
    payload,
  }),
}
