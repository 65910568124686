import React, { FunctionComponent, useState } from 'react'
import { VariableType } from '@follow/farte'
import { isBaseVariable, NormalTypeBaseVariableId } from '@follow/cdk'

import {
  BASE_VARIABLES_LIST,
  DEPRECATED_BASE_VARIABLES_LIST_IDS,
} from '../../../model/DocumentVariable'

import createSearchPanel from '../../SearchPanel'

import { SearchBaseVariableProps } from './SearchBaseVariable.model'
import { RestuxFilters } from '../../../store/restux/restux.model'
import { search } from '../../../misc/search.utilities'
import { isDefined } from '../../../misc/functions.utilities'

const SearchPanel = createSearchPanel<VariableType>()

const withValueComparator = (current: { value: string | null }, next: { value: string | null }) => {
  if (current.value === null || next.value === null) {
    return 0
  }
  return current.value < next.value ? -1 : current.value > next.value ? 1 : 0
}

const BASE_VARIABLES_DOCUMENT = Object.values(BASE_VARIABLES_LIST)
  .filter(({ id }) => !DEPRECATED_BASE_VARIABLES_LIST_IDS.includes(id))
  .sort(withValueComparator)

const BASE_VARIABLES_FILE_TEMPLATE = Object.values(BASE_VARIABLES_LIST)
  .filter(
    ({ id }) =>
      !DEPRECATED_BASE_VARIABLES_LIST_IDS.includes(id) &&
      Object.values(NormalTypeBaseVariableId).find((value) => id === value),
  )
  .sort(withValueComparator)

const SearchBaseVariable: FunctionComponent<SearchBaseVariableProps> = ({
  actions,
  selectBaseVariable,
  baseVariableListType,
}) => {
  const BASE_VARIABLES =
    baseVariableListType === 'document' ? BASE_VARIABLES_DOCUMENT : BASE_VARIABLES_FILE_TEMPLATE
  const [items, setItems] = useState(BASE_VARIABLES)

  const onPrimaryAction = (item?: VariableType) => {
    if (item && isBaseVariable(item.id)) {
      selectBaseVariable(item.id)
    }
  }

  const getItems = (page: number, filters: RestuxFilters) => {
    const { search: criteria } = filters
    if (typeof criteria === 'string') {
      const filtered =
        criteria.length > 0
          ? BASE_VARIABLES.filter((item) => isDefined(item.value) && search(item.value, criteria))
          : BASE_VARIABLES
      setItems(filtered)
    }
  }

  const onClearItems = () => setItems(BASE_VARIABLES)

  return (
    <SearchPanel
      items={items}
      loading={false}
      pageCount={1}
      itemCount={items.length}
      testId="base_variable"
      onClearItems={onClearItems}
      onPrimaryAction={onPrimaryAction}
      getItems={getItems}
      actions={actions}
      renderItem={(item) => <div>{item.value as string}</div>}
      renderActions={() => null}
    />
  )
}

export default SearchBaseVariable
