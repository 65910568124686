import { connect } from 'react-redux'
import { RootState } from '../../../../store'
import { bottomPanelDocumentTemplatesPageSelector } from '../../../../store/ui/bottomPanelDocumentTemplates'
import { SearchDocumentTemplatesStoreProps } from '../../../medicalEvent/SearchDocumentTemplates/SearchDocumentTemplates.model'
import { bottomPanelDocumentTemplatesActions } from '../../../../store/ui/bottomPanelDocumentTemplates/bottomPanelDocumentTemplates.actions'
import { setBottomPanelOptions } from '../../../../store/ui/bottomPanel/bottomPanel.actions'
import { usedDocumentCategoriesSelector } from '../../../../store/domain/documentCategories/documentCategories.selectors'
import { documentCategoriesDomainAction } from '../../../../store/domain/documentCategories'
import { SearchExportFilterDocumentTemplatesDispatchProps } from './SearchExportFilterDocumentTemplates.model'
import { SearchExportFilterDocumentTemplates } from './SearchExportFilterDocumentTemplates.component'

const mapStateToProps = (state: RootState): SearchDocumentTemplatesStoreProps => ({
  documentTemplatesPage: bottomPanelDocumentTemplatesPageSelector(state),
  usedDocumentCategories: usedDocumentCategoriesSelector(state),
})

const mapDispatchToProps: SearchExportFilterDocumentTemplatesDispatchProps = {
  getPaginatedDocumentTemplate: bottomPanelDocumentTemplatesActions.requestPage,
  clearDocumentTemplateList: bottomPanelDocumentTemplatesActions.clearPagination,
  getUsedDocumentCategories: documentCategoriesDomainAction.fetchUsedDocumentCategories,
  setBottomPanelOptions,
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchExportFilterDocumentTemplates)
export { withConnect as SearchExportFilterDocumentTemplates }
