import { HEADERS } from 'apisauce'

export function extractFileNameFromHeader(headers?: HEADERS): string | null {
  const contentDisposition = headers?.['content-disposition']
  if (!contentDisposition) {
    return null
  }
  return extractFilename(contentDisposition)
}

export function extractFilename(headerString: string): string | null {
  const regex = /filename="(.+)"/
  const match = headerString.match(regex)
  return match && match[1] ? decodeURI(match[1]) : null
}
