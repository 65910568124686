import { ApiResponse } from 'apisauce'
import { call, put, takeEvery } from 'redux-saga/effects'
import { FileType, CustomFile } from '../../../model/File'
import { uploadFile } from '../../cache/files/api'
import { addResponseError } from '../../message'
import { DocumentActions } from './document.actions'
import { DocumentActionTypes } from './document.model'

function* addImageToDocumentWorker({
  file,
  onImageAdded,
}: ReturnType<typeof DocumentActions.addImageToDocument>) {
  const response: ApiResponse<CustomFile> = yield call(
    uploadFile,
    file,
    FileType.EDITOR_DOCUMENT_FILE,
  )
  if (response.ok && response.data) {
    const { id } = response.data
    const imageUrl = `/api/files/${id}/content`
    onImageAdded(imageUrl)
  } else {
    yield put(addResponseError(response))
  }
}

function* addImageToDocumentWatcher() {
  yield takeEvery(DocumentActionTypes.ADD_IMAGE_TO_DOCUMENT, addImageToDocumentWorker)
}

export const DocumentSagas = {
  addImageToDocumentWatcher,
}
