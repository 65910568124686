import { RootState } from '../../../store'
import { connect } from 'react-redux'
import { ResourceHistoryPanel } from './ResourceHistoryPanel.component'
import {
  ResourceHistoryPanelDispatchProps,
  ResourceHistoryPanelStoreProps,
} from './ResourceHistoryPanel.model'
import {
  resourceHistoryPageSelector,
  uiResourceHistoryActions,
} from '../../../store/ui/resourceHistory'
import { inUseResourceHistoryEntity } from '../../../store/ui/bottomPanelResourceHistory'

const mapStateToProps = (state: RootState): ResourceHistoryPanelStoreProps => ({
  historyPages: resourceHistoryPageSelector(state),
  historyResourceEntity: inUseResourceHistoryEntity(state),
})

const mapDispatchToProps: ResourceHistoryPanelDispatchProps = {
  getResourceHistory: uiResourceHistoryActions.requestPage,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)(ResourceHistoryPanel)
export { withConnect as ResourceHistoryPanel }
