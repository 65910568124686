import { RootState } from '../../../reducers/index'
import { medicalEventAnswerValuesLoadingSelector } from '../../../domain/documentAnswerValues'
import { inUseMedicalEventDocumentIdSelector } from '../medicalEventDocumentInstances'

export function selectedContentFilterSelector(state: RootState) {
  return state.ui.medicalEvent.medicalEventContent.selected.filter
}

export function inUseMedicalEventContentIdSelector(state: RootState) {
  return state.ui.medicalEvent.medicalEventContent.inUse.inUseId
}

export function isInUseMedicalEventContentLoadingSelector(state: RootState) {
  return state.ui.medicalEvent.medicalEventContent.inUse.loadingState
}

export function isInUseMedicalEventFileLoadingSelector(state: RootState) {
  return state.ui.medicalEvent.files.inUse.loadingState
}

export const isMedicalEventQuestionnaireLoadingSelector = (state: RootState) => {
  const isInUseMedicalEventContentLoading = isInUseMedicalEventContentLoadingSelector(state)
  const inUseMedicalEventDocumentId = inUseMedicalEventDocumentIdSelector(state)
  if (inUseMedicalEventDocumentId === null) {
    return false
  }
  const medicalEventAnswerValuesLoading = medicalEventAnswerValuesLoadingSelector(
    state,
    inUseMedicalEventDocumentId,
  )
  return isInUseMedicalEventContentLoading || medicalEventAnswerValuesLoading
}
