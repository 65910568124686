import { ApiResponse } from 'apisauce'
import { PaginatedList } from '../../../model/Pagination'
import { IdentityIdentifier } from '../restux.model'
import { toUpperSnakeCase } from '../restux.utilities'
import {
  DEFAULT_RESTUX_PAGINATED_REQUEST,
  Identity,
  RestuxCacheActionParams,
  RestuxCacheFilterableActionParams,
  RestuxCachePaginableActionParams,
  RestuxCacheRefetchableActionParams,
  RestuxIdentifiableAction,
  RestuxCreateItemAction,
  RestuxDeleteItemAction,
  RestuxDeleteItemsAction,
  RestuxGenericAction,
  RestuxGetItemAction,
  RestuxGetPaginatedItemsAction,
  RestuxSetItemAction,
  RestuxSetItemsAction,
  RestuxUpdateItemAction,
} from './restuxCache.model'

export function restuxCacheActionsFactory<TL extends Identity, TD extends Identity>(
  resourceName: string,
) {
  const RESOURCE_PREFIX = `@${toUpperSnakeCase(resourceName)}`
  const types = {
    API_GET_PAGINATED_ITEMS: `${RESOURCE_PREFIX}/API_GET_PAGINATED_ITEMS`,
    API_GET_ITEM_DETAILS: `${RESOURCE_PREFIX}/API_GET_ITEM_DETAILS`,
    API_CREATE_ITEM: `${RESOURCE_PREFIX}/API_CREATE_ITEM`,
    API_UPDATE_ITEM: `${RESOURCE_PREFIX}/API_UPDATE_ITEM`,
    API_DELETE_ITEM: `${RESOURCE_PREFIX}/API_DELETE_ITEM`,
    DISPATCH_ERROR: `${RESOURCE_PREFIX}/DISPATCH_ERROR`,
    STORE_SET_LIST_ITEMS: `${RESOURCE_PREFIX}/STORE_SET_LIST_ITEMS`,
    STORE_SET_ITEM_DETAILS: `${RESOURCE_PREFIX}/STORE_SET_ITEM_DETAILS`,
    STORE_DELETE_LIST_ITEMS: `${RESOURCE_PREFIX}/STORE_DELETE_LIST_ITEMS`,
    STORE_DELETE_ITEM: `${RESOURCE_PREFIX}/STORE_DELETE_ITEM`,
    STORE_CLEAR_CACHE: `${RESOURCE_PREFIX}/STORE_CLEAR_CACHE`,
  }

  const actions = {
    getPaginatedItems: ({
      page = DEFAULT_RESTUX_PAGINATED_REQUEST,
      filters = {},
      params = {},
      identifier,
    }: RestuxCachePaginableActionParams = {}): RestuxGetPaginatedItemsAction => ({
      type: types.API_GET_PAGINATED_ITEMS,
      filters,
      page,
      params,
      identifier,
    }),
    apiGetItemDetails: (
      id: IdentityIdentifier,
      { filters = {}, params = {}, identifier }: RestuxCacheFilterableActionParams = {},
    ): RestuxGetItemAction => ({
      type: types.API_GET_ITEM_DETAILS,
      id,
      filters,
      params,
      identifier,
    }),
    apiCreateItem: (
      item: Omit<Partial<TD>, 'id'>,
      { params = {}, refetchList, identifier }: RestuxCacheRefetchableActionParams = {},
    ): RestuxCreateItemAction<TD> => ({
      type: types.API_CREATE_ITEM,
      item,
      params,
      refetchList,
      identifier,
    }),
    apiUpdateItem: (
      id: IdentityIdentifier,
      item: Partial<TD>,
      { refetchList, params = {}, identifier }: RestuxCacheRefetchableActionParams = {},
    ): RestuxUpdateItemAction<TD> => ({
      type: types.API_UPDATE_ITEM,
      id,
      item,
      params,
      refetchList,
      identifier,
    }),
    apiDeleteItem: (
      id: IdentityIdentifier,
      { refetchList, params = {}, identifier }: RestuxCacheRefetchableActionParams = {},
    ): RestuxDeleteItemAction => ({
      type: types.API_DELETE_ITEM,
      id,
      params,
      refetchList,
      identifier,
    }),
    dispatchError: <R>(response: ApiResponse<R>, context: RestuxGenericAction) => ({
      type: types.DISPATCH_ERROR,
      context,
      response,
    }),
    storeSetListItems: (
      paginatedList: PaginatedList<TL>,
      { params = {}, identifier }: RestuxCacheActionParams = {},
    ): RestuxSetItemsAction<TL> => ({
      type: types.STORE_SET_LIST_ITEMS,
      paginatedList,
      params,
      identifier,
    }),
    storeSetItemDetails: (
      item: TD,
      { refetchList, params = {}, identifier }: RestuxCacheRefetchableActionParams = {},
    ): RestuxSetItemAction<TD> => ({
      type: types.STORE_SET_ITEM_DETAILS,
      item,
      refetchList,
      params,
      identifier,
    }),
    storeDeleteListItems: (identifier?: string): RestuxDeleteItemsAction => ({
      type: types.STORE_DELETE_LIST_ITEMS,
      identifier,
    }),
    storeDeleteDetailsItem: (
      id: IdentityIdentifier,
      { refetchList, params = {}, identifier }: RestuxCacheRefetchableActionParams = {},
    ): RestuxDeleteItemAction => ({
      type: types.STORE_DELETE_ITEM,
      id,
      params,
      refetchList,
      identifier,
    }),
    storeClearCache: (identifier?: string): RestuxIdentifiableAction => ({
      type: types.STORE_CLEAR_CACHE,
      identifier,
    }),
  }
  return { types, actions }
}
