import React, { FunctionComponent, useRef, useEffect, useMemo } from 'react'
import { useDrag, useDrop, useDragLayer } from 'react-dnd'

import {
  DragQuestion,
  CollectedDraggingQuestion,
  CollectedDroppedQuestion,
  CollapsableQuestionProps,
} from './QuestionList.model'
import { QuestionCard } from '../Questions/QuestionCard.component'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { QuestionnaireMode } from '../Questionnaire.model'

import styles from './QuestionList.module.scss'

export const ITEM_TYPE = 'question'

export const SortableQuestion: FunctionComponent<
  CollapsableQuestionProps & {
    order: number
    onMoveQuestion: (questionId: number, newIndex: number) => void
    setIsDragging?: (dragging: boolean) => void
  }
> = ({ order, onMoveQuestion, setIsDragging, ...restProps }) => {
  const questionRef = useRef<HTMLDivElement>(null)

  const [{ isDragging }, drag, preview] = useDrag<
    DragQuestion,
    DragQuestion,
    CollectedDraggingQuestion
  >({
    type: ITEM_TYPE,
    item: () => {
      if (setIsDragging) {
        setIsDragging(true)
      }
      return { type: ITEM_TYPE, order, question: restProps.question }
    },
    end: () => {
      if (setIsDragging) {
        setIsDragging(false)
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })
  const [{ isOver }, drop] = useDrop<DragQuestion, DragQuestion, CollectedDroppedQuestion>({
    accept: ITEM_TYPE,
    drop: (item, _monitor) => {
      onMoveQuestion(item.question.id, order)
      return item
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })

  drop(drag(questionRef))
  useEffect(() => {
    // On désactive la preview pour utiliser le "dragLayer" spécifique
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [preview])
  return <QuestionCard {...restProps} dndRef={questionRef} attenuated={isOver || isDragging} />
}

/**
 * Utilisation d'un "dragLayer" spécifique afin de pouvoir afficher un version épuré de "Question" pour éviter les bugs de preview
 */
export const QuestionDragLayer: FunctionComponent<{}> = () => {
  const { item, itemType, isDragging, initialOffset, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    isDragging: monitor.isDragging(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
  }))

  const itemStyles = useMemo(() => {
    if (!initialOffset || !currentOffset) {
      return { display: 'none' }
    }
    const { x, y } = currentOffset

    const transform = `translate(${x}px, ${y}px)`
    return { transform }
  }, [initialOffset, currentOffset])

  if (!isDragging || itemType !== ITEM_TYPE) {
    return null
  }
  return (
    <div className={styles.dragLayer}>
      <div style={itemStyles}>
        <QuestionCard
          questionType={item.question.type}
          question={item.question}
          mode={QuestionnaireMode.managerEdit}
          disabled={true}
          hideSubQuestions={false}
        />
      </div>
    </div>
  )
}
