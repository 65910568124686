import { Formik, FormikProps } from 'formik'
import { capitalize } from 'lodash'
import React, { FunctionComponent, useEffect } from 'react'
import { Button, FormikTextInput } from '../../../shared'
import { isDefined } from '../../../../misc/functions.utilities'
import { TMP_AUTHENTICATION_ID_MAIL } from '../../../../model/Mail'
import { MailLoginOtpFormFields } from '../../../../pages/mail/MailLoginPage/MailLoginPage.model'
import { OTP_METHOD_MAIL } from '../MailLoginForm/MailLoginForm.model'
import { MailLoginOtpFormProps } from './MailLoginOtpForm.model'
import { MailLoginOtpValidationSchema } from './MailLoginOtpForm.validator'

export const MailLoginOtpForm: FunctionComponent<MailLoginOtpFormProps> = ({
  onSubmit,
  onRetry,
  setLoginInitiate,
  otpMethod,
}) => {
  const handleSubmit = (values: MailLoginOtpFormFields) => {
    onSubmit({ otpPassword: values.otpPassword })
  }

  useEffect(() => {
    const token = localStorage.getItem(TMP_AUTHENTICATION_ID_MAIL)
    if (token === null) {
      localStorage.removeItem(TMP_AUTHENTICATION_ID_MAIL)
      setLoginInitiate(false)
    } else {
      const { exp } = JSON.parse(atob(token.split('.')[1]))
      if (isDefined(exp) && typeof exp === 'number') {
        const timestamp = Date.now() / 1000
        const leftTime = exp - timestamp
        if (leftTime < 60) {
          localStorage.removeItem(TMP_AUTHENTICATION_ID_MAIL)
          setLoginInitiate(false)
        }
      }
    }
  }, [setLoginInitiate])

  return (
    <div>
      <Formik
        initialValues={{ otpPassword: '' }}
        validationSchema={MailLoginOtpValidationSchema}
        onSubmit={handleSubmit}
        validateOnBlur
        validateOnChange
        validateOnMount={true}
      >
        {(props: FormikProps<MailLoginOtpFormFields>) => (
          <div
            onKeyPress={(e) => {
              if (e.key === 'Enter' && props.isValid) {
                props.handleSubmit()
              }
            }}
          >
            <span className="text-xs text-shades-4 mb-3">
              {`Veuillez entrer le code reçu par ${
                otpMethod === OTP_METHOD_MAIL ? capitalize(otpMethod) : otpMethod
              }`}
            </span>
            <FormikTextInput
              fieldName="otpPassword"
              label="Code"
              autocomplete={false}
              colorPreset="light"
              required
            />
            <div className="flex w-full mt-5">
              <Button
                theme="primary"
                type="submit"
                onClick={() => {
                  props.handleSubmit()
                }}
                label="Valider le code reçu"
                disabled={!props.isValid}
              />
            </div>
          </div>
        )}
      </Formik>
      <div className="w-full mt-5 text-center">
        <span
          onClick={onRetry}
          className="text-xs text-shades-4 mb-3 hover:text-shades-3 hover:cursor-pointer"
        >
          Code non reçu ou expiré, recommencer la connexion
        </span>
      </div>
    </div>
  )
}
