import React, { FunctionComponent } from 'react'
import { Badge } from '../../Badge'
import { GridLayout } from '../../layout'
import { ListNavBarProps } from './ListNavBar.model'
import { isDefined } from '../../../../misc/functions.utilities'

export const ListNavBar: FunctionComponent<ListNavBarProps> = ({ itemCount, title }) => (
  <GridLayout
    alignColumns="center"
    columnsTemplate="auto auto 1fr"
    shouldShrinkOnMobile={false}
    gap="small"
  >
    {title}
    {isDefined(itemCount) && <Badge theme="dark" value={itemCount} size="large" />}
  </GridLayout>
)
