import { MailContact } from '../../../model/Contact'
import { RestuxResources } from '../../resources'
import { restuxCacheFactory } from '../../restux/cache/index'
import { restuxMailContactsApiConfig } from './api'

const resourceName = RestuxResources.mailContacts

const { actions, reducers, sagas } = restuxCacheFactory<MailContact, MailContact>({
  apiConfig: restuxMailContactsApiConfig,
  refetchList: true,
  resourceName,
})

export const mailContactsActions = actions
export const mailContactsReducers = reducers
export const mailContactsSagas = sagas
