import { call, put, takeEvery } from 'redux-saga/effects'
import { addResponseError } from '../../message'
import { getAssigningAuthorithies } from './api'
import { domainAssigningAuthorithiesActions } from './assigningAuthorities.actions'
import { DomainAssigningAuthoritiesTypes } from './assigningAuthorities.model'

function* getAssigningAuthoritiesWorker() {
  const response = yield call(getAssigningAuthorithies)
  if (response.ok) {
    yield put(domainAssigningAuthorithiesActions.setAssigningAuthorithies(response.data))
  } else {
    yield put(addResponseError(response))
  }
}

function* getAssigningAuthoritiesWatcher() {
  yield takeEvery(
    DomainAssigningAuthoritiesTypes.GET_ASSIGNING_AUTHORITIES,
    getAssigningAuthoritiesWorker,
  )
}

export const assigningAuthoritiesSagas = {
  getAssigningAuthoritiesWatcher,
}
