import React, { FunctionComponent, useEffect } from 'react'
import { useIdle } from 'react-use'
import { useDocumentVisibility } from '../../../hooks/utils'
import { FwTrackingEvent, TrackingService } from '../../../misc/Tracking'

let timer: number

function getUsageAppParams() {
  const extra = navigator.connection
    ? {
        downlink: navigator.connection.downlink,
        effectiveType: navigator.connection.effectiveType,
        rtt: navigator.connection.rtt,
        saveData: navigator.connection.saveData,
      }
    : {}
  return {
    viewHeight: window.innerHeight,
    viewWidth: window.innerWidth,
    ...extra,
  }
}

export const AnalyticsHeartbeatService: FunctionComponent<{}> = () => {
  const isIdle = useIdle(7 * 60 * 1000) // 7 minutes d'inactivité
  const isDocumentVisibile = useDocumentVisibility()

  useEffect(() => {
    if (!isIdle && isDocumentVisibile === 'visible') {
      timer = window.setInterval(() => {
        TrackingService.sendEvent(FwTrackingEvent.USAGE_APP, getUsageAppParams())
      }, 60 * 1000) // Toutes les minutes
    } else {
      window.clearInterval(timer)
    }
  }, [isIdle, isDocumentVisibile])

  return (
    <div id="AnalyticsHeartbeatService" style={{ display: 'none' }}>
      {isIdle ? 'Idle :(' : 'Using it :D'}
    </div>
  )
}
