import { connect } from 'react-redux'
import { SnackbarWrapper } from '../../components/shared'
import { clearError } from '../../store/message/index'
import { RootState } from '../../store/index'

const mapStateToProps = (state: RootState) => ({
  messages: state.messages.messages,
})

const mapDispatchToProps = {
  clearError,
}

export default connect(mapStateToProps, mapDispatchToProps)(SnackbarWrapper)
