import { FunctionComponent, createElement } from 'react'
import { HeadingProps } from './Heading.model'
import styles from './Heading.module.scss'

// Pas fier de ca
const upperCaseFirstLetter = (value: string) => value.charAt(0).toUpperCase() + value.slice(1)

export const Heading: FunctionComponent<HeadingProps> = ({
  size = 1,
  theme = 'dark',
  weight = 'regular',
  children,
}) => {
  const sizedHeader = `h${size}`
  const titleProps = {
    className:
      styles[`heading${size}${upperCaseFirstLetter(weight)}${upperCaseFirstLetter(theme)}`],
  }
  return createElement(sizedHeader, titleProps, children)
}
