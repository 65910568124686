import { ApiResponse } from 'apisauce'
import { DEFAULT_PRACTICE_LOCATION } from '../../../../model/Address'
import {
  DEFAULT_ADMIN_PREFERENCES,
  DEFAULT_ASSISTANT_PREFERENCES,
  DEFAULT_DOCTOR_PREFERENCES,
  PreferenceNames,
  Preferences,
  PreferencesAdmin,
  PreferencesAssistant,
  PreferencesDoctor,
} from '../../../../model/Preference'
import { Roles } from '../../../../model/Roles'
import { User } from '../../../../model/User'
import {
  SerializedAdminPreferences,
  SerializedAssistantPreferences,
  SerializedDoctorPreferences,
  SerializedUser,
  UserMutationPayload,
} from './users.model'

export function serializeUserMutation({
  userType,
  preferences,
  ...props
}: Partial<User>): UserMutationPayload {
  const serializedPreferences = !preferences
    ? undefined
    : Object.entries(preferences).reduce((acc, [preferenceKey, preferenceValue]) => {
        const translatedKey: PreferenceNames | undefined = PreferenceNames[preferenceKey]
        if (translatedKey) {
          return {
            ...acc,
            [translatedKey]:
              translatedKey === PreferenceNames.enabledFeatures
                ? preferenceValue
                : { value: preferenceValue },
          }
        } else {
          return acc
        }
      }, {} as Preferences)

  const assistants = props.mainRole === Roles.DOCTOR ? props.assistants : undefined

  return {
    ...props,
    userTypeId: userType ? userType.id : undefined,
    assistantIds: assistants ? assistants.map(({ id }) => id) : undefined,
    preferences: serializedPreferences,
  }
}

export const deserializeUserResponse = ({
  data,
  ...response
}: ApiResponse<SerializedUser>): ApiResponse<User> => {
  if (response.ok && data) {
    return {
      ...response,
      data: deserializeUser(data),
    }
  } else {
    return response
  }
}

export const deserializeUser = (user: SerializedUser): User => {
  const { quality, subPhone, phone, preferences } = user
  const deserializedNullableFields = {
    quality: quality ?? '',
    subPhone: subPhone ?? '',
    phone: phone ?? '',
  }

  if (user.mainRole === Roles.DOCTOR) {
    const { rppsNumber, finessNumber, practiceLocation, ...restUser } = user
    return {
      ...restUser,
      ...deserializedNullableFields,
      rppsNumber: rppsNumber ?? '',
      finessNumber: finessNumber ?? '',
      preferences: deserializeDoctorPreferences(preferences),
      practiceLocation: practiceLocation ?? DEFAULT_PRACTICE_LOCATION,
    }
  }

  if (user.mainRole === Roles.ASSISTANT) {
    return {
      ...user,
      ...deserializedNullableFields,
      preferences: deserializeAssistantPreferences(preferences),
    }
  }

  return {
    ...user,
    ...deserializedNullableFields,
    preferences: deserializeAdminPreferences(user.preferences),
  }
}

const deserializeAssistantPreferences = (
  preferences: SerializedAssistantPreferences,
): PreferencesAssistant => ({
  otpEnabled: preferences['otp-enabled']?.value ?? DEFAULT_ASSISTANT_PREFERENCES.otpEnabled,
  useStellairAssistantAccount:
    preferences['use-stellair-assistant-account']?.value ??
    DEFAULT_ASSISTANT_PREFERENCES.useStellairAssistantAccount,
})

const deserializeAdminPreferences = (
  preferences: SerializedAdminPreferences,
): PreferencesAdmin => ({
  otpEnabled: preferences['otp-enabled']?.value ?? DEFAULT_ADMIN_PREFERENCES.otpEnabled,
})

const deserializeDoctorPreferences = (
  preferences: SerializedDoctorPreferences,
): PreferencesDoctor => {
  const {
    enabledFeatures,
    consentText,
    editorFontFamily,
    defaultLayout,
    drugSorting,
    documentAlertsFilters,
    otpEnabled,
    trustedSourceAccepted,
    timeZone,
    contextePS,
  } = DEFAULT_DOCTOR_PREFERENCES

  return {
    consentText: preferences['tablet-consenttext']?.value ?? consentText,
    editorFontFamily: preferences['editor-font-family']?.value ?? editorFontFamily,
    defaultLayout: preferences['default-layout']?.value ?? defaultLayout,
    enabledFeatures: preferences.features
      ? { ...enabledFeatures, ...preferences.features }
      : enabledFeatures,
    drugSorting: preferences['drug-sorting']?.value ?? drugSorting,
    documentAlertsFilters: preferences['document-alerts-filters']?.value ?? documentAlertsFilters,
    otpEnabled: preferences['otp-enabled']?.value ?? otpEnabled,
    trustedSourceAccepted: preferences['trusted-source-accepted']?.value ?? trustedSourceAccepted,
    timeZone: preferences['time-zone']?.value ?? timeZone,
    contextePS: preferences['contexte-ps']?.value ?? contextePS,
  }
}
