const shades = {
  black: `rgba(0, 0, 0, 1)`,
  shade1: `rgba(0, 21, 64, 1)`,
  shade2: `rgba(18, 42, 89, 1)`,
  shade3: `rgba(40, 65, 115, 1)`,
  shade4: `rgba(77, 101, 153, 1)`,
  shade5: `rgba(168, 187, 224, 1)`,
  shade6: `rgba(208, 221, 245, 1)`,
  shade7: `rgba(235, 241, 255, 1)`,
  shade8: `rgba(246, 248, 254, 1)`,
  white: `rgba(255, 255, 255, 1)`,
} as const

export type Shades = typeof shades
export type ShadesName = keyof Shades
export type ShadesValues = ValueOf<Shades>

const primary = {
  dark: `rgba(36, 102, 178, 1)`,
  default: `rgba(69, 143, 230, 1)`,
  light: `rgba(97, 164, 242, 1)`,
  washed: `rgba(217, 235, 255, 1)`,
  transparent: `rgba(69, 143, 230, 0.1)`,
} as const

export type Primary = typeof primary
export type PrimaryName = keyof Primary
export type PrimaryValues = ValueOf<Primary>

const secondary = {
  dark: `rgba(0, 154, 131, 1)`,
  default: `rgba(12, 193, 165, 1)`,
  light: `rgba(85, 211, 192, 1)`,
  washed: `rgba(206, 243, 237, 1)`,
  transparent: `rgba(12, 193, 165, 0.1)`,
} as const

export type Secondary = typeof secondary
export type SecondaryName = keyof Secondary
export type SecondaryValues = ValueOf<Secondary>

const status = {
  valid: `rgba(12, 193, 165, 1)`,
  warning: `rgba(242, 186, 73, 1)`,
  invalid: `rgba(230, 69, 82, 1)`,
  info: `rgba(230, 230, 230, 1)`,
  danger: `rgba(212, 88, 88, 1)`,
  normal: `rgba(69, 143, 229, 1)`,
} as const

export type Status = typeof status
export type StatusName = keyof Status
export type StatusValues = ValueOf<Status>

const transparentStatus = {
  valid: `rgba(12, 193, 165, 0.1)`,
  warning: `rgba(242, 186, 73, 0.1)`,
  invalid: `rgba(230, 69, 82, 0.1)`,
} as const

export type TransparentStatus = typeof transparentStatus
export type TransparentStatusName = keyof TransparentStatus
export type TransparentStatusValues = ValueOf<TransparentStatus>

const labels = {
  gray: `rgba(107, 122, 153, 1)`,
  darkGray: `rgba(70, 84, 112, 1)`,
  brown: `rgba(139, 87, 42, 1)`,
  garnetRed: `rgba(154, 9, 9, 1)`,
  red: `rgba(212, 88, 88, 1)`,
  orange: `rgba(228, 163, 73, 1)`,
  yellow: `rgba(191, 178, 25, 1)`,
  green: `rgba(9, 154, 132, 1)`,
  blue: `rgba(74, 144, 226, 1)`,
  navyBlue: `rgba(59, 115, 180, 1)`,
  purple: `rgba(136, 108, 230, 1)`,
  magenta: `rgba(193, 53, 193, 1)`,
} as const

export type Labels = typeof labels
export type LabelsName = keyof Labels
export type LabelsValues = ValueOf<Labels>

const transparentLabels = {
  gray: `rgba(107, 122, 153, 0.1)`,
  darkGray: `rgba(70, 84, 112, 0.1)`,
  brown: `rgba(139, 87, 42, 0.1)`,
  garnetRed: `rgba(154, 9, 9, 0.1)`,
  red: `rgba(212, 88, 88, 0.1)`,
  orange: `rgba(228, 163, 73, 0.1)`,
  yellow: `rgba(191, 178, 25, 0.1)`,
  green: `rgba(9, 154, 132, 0.1)`,
  blue: `rgba(74, 144, 226, 0.1)`,
  navyBlue: `rgba(59, 115, 180, 0.1)`,
  purple: `rgba(136, 108, 230, 0.1)`,
  magenta: `rgba(193, 53, 193, 0.1)`,
} as const

export type TransparentLabels = typeof transparentLabels
export type TransparentLabelsName = keyof TransparentLabels
export type TransparentLabelsValues = ValueOf<TransparentLabels>

const dimmer = {
  light: `rgba(34, 45, 66, 0.1)`,
  medium: `rgba(34, 45, 66, 0.25)`,
  dark: `rgba(34, 45, 66, 0.6)`,
} as const

export type Dimmer = typeof dimmer
export type DimmerName = keyof Dimmer
export type DimmerValues = ValueOf<Dimmer>

const COLORS = {
  shades,
  primary,
  secondary,
  status,
  transparentStatus,
  labels,
  transparentLabels,
  dimmer,
} as const

export type Colors = typeof COLORS
export type ColorNames = keyof Colors
export default COLORS
