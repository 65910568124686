import { capitalize } from 'lodash'
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { isDefined } from '../../../misc/functions.utilities'
import { AtcClass } from '../../../model/Drug'
import { AtcClassSelectProps } from './AtcClassSelect.model'
import { useDebounce } from 'react-use'
import { RestuxLoadingState } from '../../../store/restux/ui'
import { DropdownItem, Icon, SearchInput } from '../../shared'
import { getCssVariable } from '../../../design-system/utils'
import { SelectOption } from '../../../model/SelectOption'

const GOBACK_TO_ROOT = -1
const LOADING_VALUE = -2

const convertAtcClassIntoOption = (item: AtcClass) => ({
  label: `${item.code} - ${capitalize(item.title)}`,
  value: item.id,
})

const computeParentClassValue = (classItem: AtcClass | null) =>
  classItem?.parentExternalId ?? GOBACK_TO_ROOT

export const AtcClassSelect: FC<AtcClassSelectProps> = ({
  atcClasses,
  inUseClass,
  label,
  colorPreset = 'dark',
  classId,
  setInUseClass,
  onClassSelected,
  getAtcClasses,
  getClassChildren,
}) => {
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    classId && setInUseClass(classId)
  }, [setInUseClass, classId])

  useEffect(() => {
    if (atcClasses.loadingState === RestuxLoadingState.IDLE) {
      setLoading(false)
    }
  }, [atcClasses.loadingState])

  const atcClassesOptions = useMemo(() => {
    const parentClass = computeParentClassValue(inUseClass)
    const options = atcClasses.items.map(convertAtcClassIntoOption)
    const gobackOption = { value: parentClass, label: 'Retour' }

    if (loading) {
      return [{ value: LOADING_VALUE, label: 'Chargement...' }]
    }

    if (isDefined(inUseClass) && inUseClass.totalChildren === 0) {
      return [gobackOption]
    }
    if (!isDefined(inUseClass)) {
      return options
    }

    return [gobackOption, ...options]
  }, [loading, inUseClass, atcClasses])

  useDebounce(
    () => {
      if (inUseClass) {
        if (inUseClass.totalChildren > 0) {
          getClassChildren(inUseClass.id)
          onClassSelected(inUseClass)
        } else {
          onClassSelected(inUseClass)
          setLoading(false)
        }
      } else {
        getAtcClasses({ filters: { search } })
        onClassSelected(null)
      }
    },
    500,
    [inUseClass, search, getAtcClasses, getClassChildren],
  )

  useEffect(() => {
    getAtcClasses()
    return () => {
      setInUseClass(null)
    }
  }, [getAtcClasses, setInUseClass])

  const handleSelect = useCallback(
    (selected: SelectOption<number>) => {
      if (selected.value === LOADING_VALUE) return
      setLoading(true)
      if (selected.value === GOBACK_TO_ROOT) {
        setInUseClass(null)
        setSearch('')
      } else {
        setInUseClass(selected.value)
      }
      inputRef.current?.focus()
    },
    [setInUseClass],
  )

  useEffect(() => {
    if (inUseClass) {
      setSearch(inUseClass.code)
    }
  }, [inUseClass])

  return (
    <SearchInput
      placeholder="Rechercher une classe ATC"
      colorPreset={colorPreset}
      inputRef={inputRef}
      value={search}
      results={atcClassesOptions}
      onSelect={handleSelect}
      label={label}
      renderResult={(option, isHovered) => (
        <DropdownItem selected={isHovered}>
          <div className="max-w-full flex items-center whitespace-normal">
            {option.value === computeParentClassValue(inUseClass) && (
              <div className="mr-4">
                <Icon
                  icon="chevron"
                  rotate={90}
                  size="femto"
                  color={getCssVariable('shades', 'shade2')}
                />
              </div>
            )}
            <span>{option.label}</span>
          </div>
        </DropdownItem>
      )}
      onChange={({ target: { value } }) => {
        setSearch(value)
        setInUseClass(null)
      }}
    />
  )
}
