import { connect } from 'react-redux'
import { CreatePatientThroughMailModal } from './CreatePatientThroughMailModal.component'
import {
  CreatePatientThroughMailModalDispatchProps,
  CreatePatientThroughMailModalStoreProps,
} from './CreatePatientThroughMailModal.model'
import { RootState } from '../../../store/reducers/reducers.model'
import { isCreatePatientThroughMailModalOpenSelector } from '../../../store/domain/mail/mail.selector'
import { domainMailActions } from '../../../store/domain/mail'
import { withUnmountTransition } from '../../../misc/withUnmountTransition.hoc'

const mapStateToProps = (state: RootState): CreatePatientThroughMailModalStoreProps => ({
  display: isCreatePatientThroughMailModalOpenSelector(state),
})

const mapDispatchToProps: CreatePatientThroughMailModalDispatchProps = {
  closeModal: domainMailActions.closeCreatePatientThroughMailModal,
}

const withUnmountHoc = withUnmountTransition(CreatePatientThroughMailModal)
const withConnect = connect(mapStateToProps, mapDispatchToProps)(withUnmountHoc)
export { withConnect as CreatePatientThroughMailModal }
