import { createContext, FunctionComponent } from 'react'
import {
  EditorMode,
  EditorValue,
  RenderVariableDisplayMode,
} from '../../core/model/TextEditor.model'
import { DisplayContextualMenuCallback, ClickVariableCallback } from '../WithVariableEditor.model'

export interface RenderValue<T> {
  props: T | null
  renderer: FunctionComponent<T>
  displayMode?: RenderVariableDisplayMode
}

export interface VariableTheme {
  txtColor: string
  backgroundColor: string
}
export interface BaseVariableData<T> {
  id: string
  variableTitle?: string
  type: 'normal' | 'render' | 'slate'
  value: string | RenderValue<T> | EditorValue | null
  fallbackValue: string | T | EditorValue | null
  customTheme?: VariableTheme
  enableFixedValue?: boolean
}

export interface NormalVariableData extends BaseVariableData<any> {
  type: 'normal'
  value: string | null
  fallbackValue: string | null
}

export interface RenderVariableData<T> extends BaseVariableData<T> {
  type: 'render'
  value: RenderValue<T>
  fallbackValue: T | null
}

export interface SlateVariableData extends BaseVariableData<{ content: EditorValue }> {
  type: 'slate'
  value: EditorValue | null
  fallbackValue: EditorValue | null
}

export type VariableType = NormalVariableData | SlateVariableData | RenderVariableData<any>

export type VariablesData = Record<string, VariableType>

interface WithVariableEditorContextValueType {
  mode: EditorMode
  variableData: VariablesData
  enableVariableFixedValues: boolean
  variableFallbackTheme?: VariableTheme
  clickVariable: ClickVariableCallback
  variableSecondaryAction: ClickVariableCallback
  displayContextualMenu: DisplayContextualMenuCallback
  updateVariableFixedValue: (
    slateKey: string,
    variableId: string,
    variableValue: VariableType,
  ) => void
}

export const DEFAULT_WITH_VARIABLE_EDITOR_CONTEXT: WithVariableEditorContextValueType = {
  mode: 'preview',
  variableData: {},
  enableVariableFixedValues: false,
  clickVariable: () => {
    console.warn('No "clickVariable" implementation provided to context')
  },
  variableSecondaryAction: () => {
    console.warn('No "variableSecondaryAction" implementation provided to context')
  },
  displayContextualMenu: () => {
    console.warn('No "setContextualMenuPosition" implementation provided to context')
  },
  updateVariableFixedValue: () => {
    console.warn('No "updateVariableFixedValue" implementation provided to context')
  },
}
export const WithVariableEditorContext = createContext<WithVariableEditorContextValueType>(
  DEFAULT_WITH_VARIABLE_EDITOR_CONTEXT,
)
