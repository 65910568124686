import { MedicalEvent } from '../../../../model/MedicalEvent'
import { MedicalEventsDocumentsFilters } from '../../../../pages/patient'

export const WAYPOINT_DETECTION_ZONE = -300

export interface MedicalEventListProps {
  filters: MedicalEventsDocumentsFilters
  medicalEventList: MedicalEvent[]
  isLoading: Boolean
  eventId: string
  focusedDocOrFileId: number | null
  selectedDocOrFileId: number | null
  isFetchingNextPage: boolean
  fetchNextMedicalEvents: () => void
  currentPage?: number
  hasNextPage?: Boolean
}
