import { put, race, take, takeEvery } from 'redux-saga/effects'
import { domainPosologyActions, domainPosologyActionTypes } from '../../domain/posology'
import { domainPrescriptionTemplateActions } from '../../domain/prescriptionTemplate'
import { uiDocumentPosologyActionTypes } from './documentPosology.model'
import { uiDocumentPosologyActions } from './documentPosology.actions'

function* updatePrescriptionAndCreateTemplateWorker({
  prescriptionUuid,
  prescription,
  name,
}: ReturnType<typeof uiDocumentPosologyActions.updatePrescriptionAndCreateTemplate>) {
  yield put(domainPosologyActions.batchPosology(prescription, prescriptionUuid))

  const { succeed } = yield race({
    succeed: take(domainPosologyActionTypes.BATCH_SUCCEED),
    failed: take(domainPosologyActionTypes.BATCH_FAILED),
  })

  if (succeed) {
    yield put(domainPrescriptionTemplateActions.createPrescriptionTemplate(prescriptionUuid, name))
  }
}

function* updatePrescriptionAndCreateTemplateWatcher() {
  yield takeEvery(
    uiDocumentPosologyActionTypes.UPDATE_PRESCRIPTION_AND_CREATE_TEMPLATE,
    updatePrescriptionAndCreateTemplateWorker,
  )
}

export const documentPosologySagas = {
  updatePrescriptionAndCreateTemplateWatcher,
}
