import React, { FC } from 'react'
import { ChipLabelProps } from './ChipLabel.model'

export const ChipLabel: FC<ChipLabelProps> = ({ label, sublabel, title = label }) => (
  <>
    <span className="truncate max-w-72" title={title}>
      {label}
    </span>
    {sublabel && (
      <span className="chip-sublabel" title={sublabel}>
        {sublabel}
      </span>
    )}
  </>
)
