import React, { FC } from 'react'
import { useField } from 'formik'
import { FormikSwitchProps } from './Switch.model'
import { Switch as SwitchComponent } from '../../Switch'

export const Switch: FC<FormikSwitchProps> = React.forwardRef<HTMLDivElement, FormikSwitchProps>(
  ({ fieldName, onChange = () => {}, ...props }, ref) => {
    const [field, , helpers] = useField<boolean>({ name: fieldName })

    const handleChange = (value: boolean) => {
      helpers.setValue(value)
      onChange(value)
    }

    return (
      <SwitchComponent
        {...props}
        ref={ref}
        checked={field.value}
        onChange={handleChange}
      ></SwitchComponent>
    )
  },
)
