import { combineReducers } from 'redux'
import { paginationReducerFactory } from '../../restux/ui/restuxUiReducer.factory'
import {
  PatientCurrentTreatmentsState,
  patientCurrentTreatmentsConfig,
} from './patientCurrentTreatments.model'

export const patientCurrentTreatmentsReducer = combineReducers<PatientCurrentTreatmentsState>({
  pagination: paginationReducerFactory(patientCurrentTreatmentsConfig),
})
