import { useCallback } from 'react'
import { Checkbox, CheckboxState, EntityCard, FormHeading } from '../../../shared'
import { ModifierCodesProps } from './ModifierCodes.model'
import styles from './ModifierCodes.module.scss'

export const ModifierCodes = ({ options, selectedCodes, onCheckChange }: ModifierCodesProps) => {
  const handleChange = useCallback(
    (code: string) => () => {
      const updatedModifiers = selectedCodes.includes(code)
        ? selectedCodes.filter((modifierCode) => code !== modifierCode)
        : [...selectedCodes, code]
      onCheckChange(updatedModifiers)
    },
    [onCheckChange, selectedCodes],
  )

  return (
    <EntityCard loading={false} inactive={false}>
      <div className={styles.cardContent}>
        <FormHeading headings={[{ label: 'Modificateurs', size: 5 }]} />
        <div className={styles.checkboxesContainer}>
          {options.length ? (
            options.map((modifier) => (
              <div className={styles.checkbox} key={modifier.code}>
                <Checkbox
                  checked={
                    selectedCodes.includes(modifier.code)
                      ? CheckboxState.CHECKED
                      : CheckboxState.UNCHECKED
                  }
                  onChange={handleChange(modifier.code)}
                  testId={`modifier-checkbox_${modifier.code}`}
                />
                <p>
                  {modifier.code} : {modifier.libelle}
                </p>
              </div>
            ))
          ) : (
            <p>Aucun code modificateur n'est associé à la sélection actuelle.</p>
          )}
        </div>
      </div>
    </EntityCard>
  )
}
