import getApi from '../../../api'
import { singleItemPageLimit } from '../../../constants'
import { Filters } from '../../../model/Filters'
import { ServerSentNotification } from '../../../model/Notification'
import { PaginatedList } from '../../../model/Pagination'

export const getNotifications = (
  page: number,
  filters?: Filters,
  signal?: AbortSignal,
  limit = singleItemPageLimit,
) =>
  getApi().get<PaginatedList<ServerSentNotification>>(
    '/admin/notifications',
    { page, limit, ...filters },
    { signal },
  )
