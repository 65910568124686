export const breakSmall = 767 // In pixels
export const breakMedium = 1023 // In pixels
export const breakLarge = 1440 // In pixels

export type ScreenWidthObserverCallback = (isGreater: boolean) => any
export type UnsubscribeWidthWatcher = () => any

export function screenWidthGreaterThan(
  widthBreakpointPx: number,
  callback: ScreenWidthObserverCallback,
) {
  return screenWidthObserver('min', widthBreakpointPx, callback)
}

export function screenWidthLowerThan(
  widthBreakpointPx: number,
  callback: ScreenWidthObserverCallback,
) {
  return screenWidthObserver('max', widthBreakpointPx, callback)
}

function screenWidthObserver(
  type: 'min' | 'max',
  widthBreakpointPx: number,
  callback: ScreenWidthObserverCallback,
): UnsubscribeWidthWatcher {
  function handler() {
    if (window.matchMedia(`(${type}-width: ${widthBreakpointPx}px)`).matches) {
      callback(true)
    } else {
      callback(false)
    }
  }
  /**
   * Initialize value
   */
  handler()
  window.addEventListener('resize', handler)
  return function unsubscribe() {
    window.removeEventListener('resize', handler)
  }
}
