import { RestuxApiConfig } from '../../../restux/cache/restuxCache.model'
import { Questionnaire, LightQuestionnaire } from '../../../../model/Questionnaire'
import getApi from '../../../../api/index'
import { PaginatedList } from '../../../../model/Pagination'
import { QuestionnaireResource } from './Questionnaire.model'
import { deserializeQuestionnaireResponse, serializeQuestionnaire } from './Questionnaire.mapper'

export const restuxQuestionnairesApiConfig: RestuxApiConfig<LightQuestionnaire, Questionnaire> = {
  url: '/questionnaires',
  getPaginatedItems: (route) => getApi().get<PaginatedList<LightQuestionnaire>>(route),
  getItem: (route) =>
    getApi().get<QuestionnaireResource>(route).then(deserializeQuestionnaireResponse),
  createItem: (route, item) =>
    getApi()
      .post<QuestionnaireResource>(route, serializeQuestionnaire(item))
      .then(deserializeQuestionnaireResponse),
  updateItem: (route, item) =>
    getApi()
      .put<QuestionnaireResource>(route, serializeQuestionnaire(item))
      .then(deserializeQuestionnaireResponse),
  deleteItem: (route) => getApi().delete<void>(route),
}
