import { connect } from 'react-redux'
import { PrescriptionTemplateList } from './PrescriptionTemplateList.component'
import { RootState } from '../../../../store'
import { domainPrescriptionTemplateActions } from '../../../../store/domain/prescriptionTemplate'
import { PrescriptionTemplateListStoreProps } from './PrescriptionTemplateList.model'
import { prescriptionTemplatesSelector } from '../../../../store/domain/prescriptionTemplate/prescriptionTemplate.selectors'

const mapStateToProps = (state: RootState): PrescriptionTemplateListStoreProps => ({
  prescriptionTemplates: prescriptionTemplatesSelector(state),
})

const mapDispatchToProps = {
  getPrescriptionTemplates: domainPrescriptionTemplateActions.getCollection,
  deletePrescriptionTemplate: domainPrescriptionTemplateActions.deleteItem,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)(PrescriptionTemplateList)
export { withConnect as PrescriptionTemplateList }
