import { ActivePrinciple } from '../../../model/Drug'
import { ActivePrinciplesDomainActionsTypes } from './activePrinciples.model'

export const activePrinciplesDomainActions = {
  search: (search: string) => ({
    type: ActivePrinciplesDomainActionsTypes.SEARCH,
    search,
  }),
  setActivePrinciplesList: (activePrinciplesList: Array<ActivePrinciple>) => ({
    type: ActivePrinciplesDomainActionsTypes.SET_LIST,
    activePrinciplesList,
  }),
}
