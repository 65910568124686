import { User } from '../../../../model/User'
import { MailLoginFormFields } from '../../../../pages/mail/MailLoginPage/MailLoginPage.model'

export const OTP_METHOD_SMS = 'SMS'
export const OTP_METHOD_MAIL = 'EMAIL'

export interface MailLoginFormProps {
  onSubmit: (formValues: MailLoginFormFields) => void
  currentUser: User | null
}
