import { RootState } from '../../reducers'

export const documentAlertsSelector = (state: RootState) => state.domain.documentAlerts.alerts

export const documentAlertsFiltersSelector = (state: RootState) =>
  state.domain.documentAlerts.availableFilters

export const documentAlertsTypesSelector = (state: RootState) => state.domain.documentAlerts.types

export const documentVidalInfosSelector = (state: RootState) =>
  state.domain.documentAlerts.vidalInfos

export const documentSeverityLabelsSelector = (state: RootState) =>
  state.domain.documentAlerts.severityLabels
