import { ExternalServicesStatus } from '../../../model/ExternalServices'

export enum ExternalServicesActionTypes {
  GET_STATUS = '@EXTERNAL_SERVICES/GET_STATUS',
  SET_STATUS = '@EXTERNAL_SERVICES/SET_STATUS',
}

export interface ExternalServicesState {
  status: ExternalServicesStatus[]
}
