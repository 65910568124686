import { useCallback, useLayoutEffect, useRef, useState } from 'react'

const DEFAULT_HEIGHT = 'auto'

export const useAutoresizeTextArea = (inputValue: string, enabled: boolean) => {
  const ref = useRef<HTMLTextAreaElement>(null)
  const [height, setHeight] = useState(DEFAULT_HEIGHT)

  useLayoutEffect(() => {
    if (enabled && ref.current && inputValue !== '') {
      setHeight(`${ref.current.scrollHeight}px`)
    }
  }, [enabled, inputValue])

  const resetHeight = useCallback(() => {
    setHeight(DEFAULT_HEIGHT)
  }, [])

  return { height, ref, resetHeight }
}
