import getApi from '../../api/apisauceConfig'
import { PaginatedList } from '../../model/Pagination'
import { largePageLimit } from '../../constants'
import { HealthData } from '../../model/HealthData'
import { deserializePatientHealthDataPaginatedResponse } from './mapper/patientHealthData.mapper'

export const getPatientHealthData = (patientId: string, page: number, limit = largePageLimit) =>
  getApi()
    .get<PaginatedList<HealthData>>(`/patients/${patientId}/module_data/latest`, { page, limit })
    .then(deserializePatientHealthDataPaginatedResponse)
