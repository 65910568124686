import { useQuery } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { tagsKeys } from '../tag/tags.keys'
import { getTags } from '../../../data/tag'
import { TagType } from '../../../model/Tag'
import { fetchAllTags } from './tags.utils'

interface GetTagsParams {
  enabled?: boolean
}

export const useGetTags = ({ enabled = true }: GetTagsParams = {}) => {
  const fetchTags = async (page: number, signal?: AbortSignal) => {
    const { data, ok } = await getTags(page, undefined, signal)
    if (!data || !ok) {
      throw new UserFriendlyError(
        'error',
        'Impossible de récupérer la liste des labels',
        "Une erreur technique s'est produite",
      )
    }
    return data
  }

  const queryReturn = useQuery<TagType[]>(
    tagsKeys.all,
    async ({ signal }) => fetchAllTags(fetchTags, signal),
    {
      staleTime: Infinity,
      enabled,
    },
  )

  return { query: queryReturn, tagList: queryReturn.data || [] }
}
