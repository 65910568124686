import React, { FunctionComponent } from 'react'
import { useFormik } from 'formik'

import { RoundedButton, GridLayout, GridElement, SelectInput } from '../../../../shared'
import { ScoreConfig } from '../../../../../model/Questionnaire'

import styles from './ScoreConfigForm.module.scss'
import { CodeValue } from '../../../../../model/Code'
import { SelectOption } from '../../../../../model/SelectOption'
import { LabeledCheckbox } from '../../../../legacy/LabeledCheckbox'

function scoreCodesToSelectValues(scoreCodes: ReadonlyArray<CodeValue>): SelectOption<number>[] {
  return scoreCodes.map(({ label }, index) => ({ label, value: index }))
}

interface ScoreConfigFormValue {
  title?: SelectOption<number>
  percentage?: boolean
  inverted?: boolean
  noRounding?: boolean
  min?: string
  max?: string
}

interface ScoreConfigFormProps {
  config?: ScoreConfig
  scoreCodes: ReadonlyArray<CodeValue>
  disabled: boolean
  saveScoreConfig: (scoreConfig: ScoreConfig) => void
}

const validate = (values: ScoreConfigFormValue) => {
  const errors: any = {}
  if (!values.title) {
    errors.title = 'Obligatoire'
  }

  return errors
}
export const ScoreConfigForm: FunctionComponent<ScoreConfigFormProps> = ({
  config = {},
  scoreCodes,
  disabled,
  saveScoreConfig,
}) => {
  const scoreSelectValues = scoreCodesToSelectValues(scoreCodes)
  const matchingTitleValue = scoreSelectValues.find(({ label }) => label === config.title)
  const initialValues = {
    title: matchingTitleValue,
    percentage: config.percentage,
    inverted: config.inverted,
    noRounding: config.noRounding,
  }

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: (values) => {
      if (!values.title) {
        return
      }
      const isPercentage = !!values.percentage
      const scoreConfig: ScoreConfig = isPercentage
        ? {
            title: values.title.label,
            percentage: isPercentage,
            inverted: !!values.inverted,
            noRounding: values.noRounding,
          }
        : ({
            title: values.title.label,
            percentage: isPercentage,
            inverted: !!values.inverted,
            noRounding: values.noRounding,
          } as ScoreConfig)
      saveScoreConfig(scoreConfig)
    },
  })

  return (
    <form className={styles.scoreConfig} onSubmit={formik.handleSubmit}>
      <GridLayout columnsTemplate="1fr auto auto" gap="medium">
        <div>
          <SelectInput
            title="Titre"
            options={scoreSelectValues}
            value={formik.values.title}
            onSelect={(option) => {
              formik.setFieldValue('title', option)
            }}
            colorPreset="dark"
            disabled={disabled}
          />
          {formik.errors.title ? <div className={styles.error}>{formik.errors.title}</div> : null}
        </div>
        <LabeledCheckbox
          colorPreset="light"
          checked={formik.values.percentage}
          label="Pourcentage"
          disabled={disabled}
          onChange={(e) => formik.setFieldValue('percentage', e.target.checked)}
        />
        {!formik.values.percentage && <></>}
        <LabeledCheckbox
          colorPreset="light"
          checked={formik.values.inverted}
          label="Couleur inversée"
          disabled={disabled}
          onChange={(e) => formik.setFieldValue('inverted', e.target.checked)}
        />
        <LabeledCheckbox
          colorPreset="light"
          checked={formik.values.noRounding}
          label="Afficher décimale"
          disabled={disabled}
          onChange={(e) => formik.setFieldValue('noRounding', e.target.checked)}
        />
        {!disabled && (
          <GridElement justifySelf="end">
            <RoundedButton
              label="Enregistrer"
              theme="primary"
              disabled={!formik.isValid}
              onClick={() => formik.handleSubmit()}
            ></RoundedButton>
          </GridElement>
        )}
      </GridLayout>
    </form>
  )
}
