import { RootState } from '../../../../store'
import {
  insiDomainActions,
  insiIdentityCheckPatientSelector,
  insiIdentityCheckStatusSelector,
} from '../../../../store/domain/insi'
import {
  IdentityStatusBlockDispatchProps,
  IdentityStatusBlockStoreProps,
} from './IdentityStatusBlock.model'
import { IdentityStatusBlock } from './IdentityStatusBlock.component'
import { connect } from 'react-redux'
import { enabledFeaturesPreferencesSelector } from '../../../../store/domain/me'

const mapStateToProps = (state: RootState): IdentityStatusBlockStoreProps => ({
  insiStatus: insiIdentityCheckStatusSelector(state),
  insiPatient: insiIdentityCheckPatientSelector(state),
  enabledFeatures: enabledFeaturesPreferencesSelector(state),
})

const mapDispatchToProps: IdentityStatusBlockDispatchProps = {
  checkPatientIdentity: insiDomainActions.checkPatientIdentity,
  clearPatientResponse: insiDomainActions.clearPatientResponse,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)(IdentityStatusBlock)
export { withConnect as IdentityStatusBlock }
