import React, { FC, useMemo } from 'react'
import { ToolbarSelect } from '@/components/toolbar/atoms'
import { PluginNames } from '@/model/Plugins'
import { SelectOption } from '@/model/SelectOption'
import { findAttributesAmongTypes } from '@/utils/plugins.utils'
import { ToolbarElementProps } from '@/model/Toolbar'
import { CustomStorage } from '@tiptap/react'

export const LineHeightSelect: FC<ToolbarElementProps> = ({ editor }) => {
  const lineHeightOptions = (editor.storage as CustomStorage).lineHeight.getOptions()
  const selectOptions = lineHeightOptions.heights

  const activeLineHeight: string | null = findAttributesAmongTypes(
    editor,
    lineHeightOptions.types,
    PluginNames.LineHeight,
  )

  const activeLineHeightOption = useMemo(
    () => selectOptions.find(({ value }) => value === activeLineHeight),
    [activeLineHeight, selectOptions],
  )

  const handleLineHeightSelect = (option: SelectOption<string | null>) => {
    editor.chain().focus().setLineHeight(option.value).run()
  }

  return (
    <ToolbarSelect
      title="Interligne"
      icon="LineSpacing"
      value={activeLineHeightOption}
      options={selectOptions}
      onChange={handleLineHeightSelect}
    />
  )
}
