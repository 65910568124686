import React, { FunctionComponent } from 'react'
import {
  createMatchDevice,
  MatchDeviceProps,
  onlyDesktopConfig,
} from '../../../../misc/MatchDevice'
import { BaseButton } from '../BaseButton'
import BaseButtonThemes from '../../../../design-system/themes/button.theme'
import Ink from 'react-ink'
import { Icon } from '../../Icon'
import { RoundedButtonProps } from './RoundedButton.model'
import { BASE_BUTTON_SIZES } from '../BaseButton/BaseButton.model'

const fontSizes = {
  atom: 12,
  nano: 12,
  micro: 12,
  normal: 16,
  macro: 20,
}

const paddingSizes = {
  atom: `2px 4px`,
  nano: `4px 8px`,
  micro: `6px 12px`,
  normal: `8px 16px`,
  macro: `8px 16px`,
}

const RoundedButton: FunctionComponent<RoundedButtonProps & MatchDeviceProps> = (props) => {
  const {
    type = 'button',
    disabled = false,
    theme = 'primary',
    size = 'normal',
    onClick,
    testId,
    icon,
    iconPosition = 'left',
    iconRotate,
    label,
    matchDevice,
    appearance,
  } = props
  const selectedTheme = disabled ? BaseButtonThemes[theme].disabled : BaseButtonThemes[theme].active

  const renderIcon = icon && (
    <Icon icon={icon} rotate={iconRotate ?? 0} color={selectedTheme.colors.default} size="nano" />
  )

  return (
    <BaseButton
      type={type}
      onClick={disabled ? undefined : onClick}
      disabled={disabled}
      data-test-id={testId}
      colors={selectedTheme.colors}
      sizes={{
        padding: paddingSizes[size],
        font: `${fontSizes[size]}px`,
        height: `${BASE_BUTTON_SIZES[size]}px`,
      }}
      borderColors={selectedTheme.borderColors}
      borderSize={1}
      borderRadius="20px"
      appearance={appearance}
    >
      {!disabled && matchDevice && <Ink />}
      <div className="flex items-center gap-3">
        {iconPosition === 'left' && renderIcon}
        <div className="font-semibold text-base">{label}</div>
        {iconPosition === 'right' && renderIcon}
      </div>
    </BaseButton>
  )
}

const withHoc = createMatchDevice(onlyDesktopConfig)(RoundedButton)
export { withHoc as RoundedButton }
