/* eslint-disable no-redeclare */
import { PageList, RestuxPagination, RestuxInUse } from './restuxUi.model'
import { RestuxEntityDict, Identity } from '../cache/restuxCache.model'

export type Mapper<I, O> = (input: I) => O

export const undefinedItemInCacheGuard = <I>(item: I | undefined): item is I => {
  if (!item) {
    console.warn('No item in cache')
    return false
  }
  return true
}

export function restuxResolvePage<I extends Identity>(
  resource: RestuxEntityDict<I>,
  pagination: RestuxPagination,
): PageList<I>

export function restuxResolvePage<I extends Identity, O>(
  resource: RestuxEntityDict<I>,
  pagination: RestuxPagination,
  mapper: Mapper<I, O>,
): PageList<O>

export function restuxResolvePage<I extends Identity, O>(
  resource: RestuxEntityDict<I>,
  pagination: RestuxPagination,
  mapper?: Mapper<I, O>,
): PageList<I> | PageList<O> {
  const { itemIds, ...toKeep } = pagination
  const totalPages = Math.ceil(pagination.totalItems / pagination.pageSize)
  const resourceItems = itemIds.map((id) => resource[id]).filter(undefinedItemInCacheGuard)
  if (mapper) {
    return {
      ...toKeep,
      totalPages,
      items: resourceItems.map((item) => mapper(item)),
    } as PageList<O>
  }
  return { ...toKeep, totalPages, items: resourceItems } as PageList<I>
}

export function restuxResolveList<I extends Identity>(
  resource: RestuxEntityDict<I>,
  itemIds: number[],
): Readonly<I>[] {
  return itemIds.map((id) => resource[id]).filter(undefinedItemInCacheGuard)
}

export function restuxResolveInUse<I extends Identity>(
  resources: RestuxEntityDict<I>,
  { inUseId }: RestuxInUse,
): Readonly<I> | null {
  return inUseId === null || resources[inUseId] === undefined ? null : resources[inUseId]
}
