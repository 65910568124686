import { parseISO } from 'date-fns'
import { FunctionComponent } from 'react'
import { formatFr } from '../../../../misc/date.utilities'
import { inputInseeNumberFormatter } from '../../../../misc/inseeNumber.utils'
import { SEX_FORM_LABELS } from '../../../patient/PatientForm/PatientForm.model'
import { Icon } from '../../../shared'
import { MailPatientInfoProps } from './MailPatientInfo.model'

const MailPatientInfo: FunctionComponent<MailPatientInfoProps> = ({ patient }) => (
  <div className="rounded-md border border-shades-6 py-5 px-3 max-w-xs">
    <div className="inline-flex font-semibold text-shades-2 items-center leading-none mb-5">
      <Icon size="micro" icon="infoCircle" color="--fw-color-shades-shade2" />
      <span className="ml-3">Information du patient</span>
    </div>
    <div className="flex flex-col text-xs space-y-2 font-medium">
      <div className="inline-flex items-center justify-between">
        <span className="text-shades-4">Nom</span>
        <span className="text-shades-1">{patient.birthLastName}</span>
      </div>
      {patient.usedLastName && (
        <div className="inline-flex items-center justify-between">
          <span className="text-shades-4">Nom utilisé</span>
          <span className="text-shades-1">{patient.usedLastName}</span>
        </div>
      )}
      <div className="inline-flex items-center justify-between">
        <span className="text-shades-4">Prénom</span>
        <span className="text-shades-1">{patient.birthFirstName}</span>
      </div>
      {patient.usedFirstName && (
        <div className="inline-flex items-center justify-between">
          <span className="text-shades-4">Prénom utilisé</span>
          <span className="text-shades-1">{patient.usedFirstName}</span>
        </div>
      )}
      <div className="inline-flex items-center justify-between">
        <span className="text-shades-4">Date de naissance</span>
        <span className="text-shades-1">{formatFr(parseISO(patient.birthDate), 'dd/MM/yyyy')}</span>
      </div>
      <div className="inline-flex items-center justify-between">
        <span className="text-shades-4">Sexe</span>
        <span className="text-shades-1">{SEX_FORM_LABELS[patient.sex]}</span>
      </div>
      {patient.inseeNumber && (
        <div className="inline-flex items-center justify-between">
          <span className="text-shades-4">Matricule INS</span>
          <span className="text-shades-1">{inputInseeNumberFormatter(patient.inseeNumber)}</span>
        </div>
      )}
    </div>
  </div>
)

export default MailPatientInfo
