import { RestuxResources } from '../../resources'
import { RestuxPagination, RestuxUiSagaConf, uiActionsFactory } from '../../restux/ui'
import { citiesPaginationSelector } from './cities.selectors'

export const citiesConfig: RestuxUiSagaConf = {
  resourceName: RestuxResources.cities,
  paginationSelector: citiesPaginationSelector,
}

export interface CitiesState {
  pagination: RestuxPagination
}

export const uiActions = uiActionsFactory(citiesConfig)

export const uiCitiesActionTypes = {
  ...uiActions.types,
}

export enum CitySourceFilter {
  INSEE = 'insee',
}
