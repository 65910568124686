import { FC, Reducer, useCallback, useMemo, useReducer, useState } from 'react'
import classNames from 'classnames'
import { GynecologyModalFormState, GynecologyModalProps } from './GynecologyModal.model'
import { ActionModalLayout, Modal, RoundedButton } from '../../shared'
import { HealthDataGynecology } from './HealthDataGynecology'
import { HealthDataCode, HealthDataField, HealthDataType } from '../../../model/HealthData'
import { serializeModuleDataForm } from '../../../misc/healthData.utilities'
import { isDefined } from '../../../misc/functions.utilities'
import { HealthDataHistoryRightPanel } from './HealthDataHistoryRightPanel'
import {
  useGetPatientHealthDataTypes,
  useCreatePatientHealthData,
  useGetInfinitePatientHealthData,
} from '../../../hooks/queries/patientHealthData'
import styles from './GynecologyModal.module.scss'

const regexValidate = (type: HealthDataType | undefined, value: string | null | undefined) => {
  if (!value) {
    return undefined
  }
  if (!type?.validationPattern) {
    return true
  }
  return type.validationPattern.test(value)
}

const DEFAULT_STATE: GynecologyModalFormState = {
  changesCount: 0,
  lastSmear: null,
  lastHivTest: null,
  lastHpvTest: null,
  lastMammography: null,
  breastfeedingStart: null,
  pregnancy: [],
}

export const GynecologyModal: FC<GynecologyModalProps> = ({
  isUnmounting,
  patientId,
  rootId,
  onClose,
}) => {
  const [selectedModuleType, setSelectedModuleType] = useState<HealthDataType>()
  const [editedValues, dispatchEditedValues] = useReducer<
    Reducer<GynecologyModalFormState, Partial<GynecologyModalFormState>>
  >((state, action) => {
    const { changesCount, ...formFields } = state
    const newState = {
      ...formFields,
      ...action,
    }

    const newChangesCount = Object.values(newState).reduce<number>((count, item) => {
      if (!isDefined(item) || item === '' || (Array.isArray(item) && item.length === 0)) {
        return count
      }
      return count + 1
    }, 0)

    return {
      ...newState,
      changesCount: newChangesCount,
    }
  }, DEFAULT_STATE)

  const pregnancyModuleSelected = selectedModuleType?.code === HealthDataCode.pregnancyEntity
  const { indexedTypes } = useGetPatientHealthDataTypes()
  const { indexedValues } = useGetInfinitePatientHealthData({
    patientId,
  })
  const { mutate: createHealthData } = useCreatePatientHealthData()

  const validation = useMemo(
    () => ({
      gravidity: regexValidate(indexedTypes.gravidity, editedValues.gravidity?.toString()),
      parity: regexValidate(indexedTypes.parity, editedValues.parity?.toString()),
      numberOfChildren: regexValidate(
        indexedTypes.number_of_children,
        editedValues.numberOfChildren?.toString(),
      ),
      comment: regexValidate(indexedTypes.comment, editedValues.comment),
    }),
    [editedValues, indexedTypes],
  )

  const togglePregnancy = useCallback(() => {
    const pregnancyType = indexedTypes[HealthDataCode.pregnancyEntity]
    if (!pregnancyType) return

    setSelectedModuleType(
      selectedModuleType?.code === pregnancyType.code ? undefined : pregnancyType,
    )
  }, [indexedTypes, selectedModuleType?.code])

  const handleClose = useCallback(() => {
    pregnancyModuleSelected ? togglePregnancy() : onClose()
  }, [onClose, pregnancyModuleSelected, togglePregnancy])

  const handlePregnancyChange = useCallback((fields: Array<HealthDataField>) => {
    dispatchEditedValues({
      pregnancy: fields,
    })
  }, [])

  const handleSave = useCallback(() => {
    const fields: Array<HealthDataField> = [
      { value: editedValues.gravidity, type: indexedTypes.gravidity },
      { value: editedValues.parity, type: indexedTypes.parity },
      { value: editedValues.numberOfChildren, type: indexedTypes.number_of_children },
      { value: editedValues.comment, type: indexedTypes.comment },
      { value: editedValues.lastSmear, type: indexedTypes.last_smear_at },
      { value: editedValues.lastHivTest, type: indexedTypes.last_hiv_test_at },
      { value: editedValues.lastHpvTest, type: indexedTypes.last_hpv_test_at },
      { value: editedValues.lastMammography, type: indexedTypes.last_mammography_at },
      { value: editedValues.contraceptionMethod, type: indexedTypes.contraception_method },
      { value: editedValues.isBreastfeeding, type: indexedTypes.is_breastfeeding },
      { value: editedValues.breastfeedingStart, type: indexedTypes.breastfeeding_start },
      ...editedValues.pregnancy,
    ]
    const serializedFields = serializeModuleDataForm(fields)
    createHealthData({ patientId, healthData: serializedFields })
    handleClose()
  }, [handleClose, editedValues, indexedTypes, patientId, createHealthData])

  const handleContinue = useCallback(() => {
    pregnancyModuleSelected ? togglePregnancy() : handleSave()
  }, [handleSave, pregnancyModuleSelected, togglePregnancy])

  return (
    <Modal isUnmounting={isUnmounting} rootId={rootId} onClickOutside={handleClose}>
      <ActionModalLayout
        title="Édition des données gynécologiques"
        onClose={handleClose}
        actions={
          <div className={styles.actions}>
            <RoundedButton
              label={pregnancyModuleSelected ? 'Retour' : 'Annuler'}
              size="micro"
              theme="transparent-dark"
              onClick={handleClose}
              testId="gynecology-form-cancel"
            />
            <RoundedButton
              label={pregnancyModuleSelected ? 'Continuer' : 'Enregistrer'}
              size="micro"
              onClick={handleContinue}
              disabled={!editedValues.changesCount}
              testId="gynecology-form-save"
            />
          </div>
        }
      >
        <div className={styles.content}>
          <div className={classNames(styles.column, styles.left)}>
            <HealthDataGynecology
              editedValues={editedValues}
              indexedTypes={indexedTypes}
              indexedValues={indexedValues}
              validation={validation}
              openPregnanciesForm={pregnancyModuleSelected}
              setOpenPregnanciesForm={togglePregnancy}
              setSelectedHealthDataType={setSelectedModuleType}
              setValues={dispatchEditedValues}
            />
          </div>
          {selectedModuleType && (
            <HealthDataHistoryRightPanel
              patientId={patientId}
              healthDataType={selectedModuleType}
              indexedTypes={indexedTypes}
              indexedValues={indexedValues}
              onPregnancyChange={handlePregnancyChange}
            />
          )}
        </div>
      </ActionModalLayout>
    </Modal>
  )
}
