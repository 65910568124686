import { createReducer } from 'redux-starter-kit'
import { sideEffectsDomainActions } from './sideEffects.actions'
import { SideEffectsState, SideEffectsDomainActionsTypes } from './sideEffects.model'

const SIDE_EFFECTS_INITIAL_STATE: SideEffectsState = {
  sideEffects: [],
}

const ACTION_HANDLERS = {
  [SideEffectsDomainActionsTypes.SET_LIST]: (
    state: SideEffectsState = SIDE_EFFECTS_INITIAL_STATE,
    { sideEffectsList }: ReturnType<typeof sideEffectsDomainActions.setSideEffectsList>,
  ): SideEffectsState => ({
    ...state,
    sideEffects: sideEffectsList,
  }),
}

export const sideEffectsDomainReducer = createReducer<SideEffectsState, any>(
  SIDE_EFFECTS_INITIAL_STATE,
  ACTION_HANDLERS,
)
