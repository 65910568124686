import { EventDetailsTooltipProps } from './EventDetailsTooltip.model'

export const EventDetailsTooltip = ({ ownerName, creatorName }: EventDetailsTooltipProps) => (
  <div>
    {ownerName && (
      <p>
        Propriétaire: <span>{ownerName}</span>
      </p>
    )}
    {creatorName && (
      <p>
        Créateur: <span>{creatorName}</span>
      </p>
    )}
  </div>
)
