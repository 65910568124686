import styled from '../../../../styles/theme/styled'
import { GridLayoutProps } from './GridLayout.model'
import { SpaceType } from '../../../../styles/theme/spaces'
import { Theme } from '@emotion/react'

/* Convertit les props gap/padding en valeur css valide, par exemple :
    spacing = "medium" -> "24px"
    spacing = ["small", "large"] -> "16px 32px"
    spacing = [["small", "medium"], ["large", "extraLarge"]] = 16px 24px 32px 40px"
*/
const convertSpaces = (theme: Theme, spacing?: SpaceType) =>
  spacing
    ? Array.isArray(spacing)
      ? `${convertSpaces(theme, spacing[0])} ${convertSpaces(theme, spacing[1])}`
      : theme.spaces[spacing]
    : '0'

export const GridLayout = styled.div<GridLayoutProps>`
  display: grid;
  grid-template-columns: ${({ columnsTemplate, columns = '1' }) =>
    columnsTemplate ? columnsTemplate : `repeat(${columns}, 1fr)`};
  grid-template-rows: ${({ rowsTemplate, rows = '1' }) =>
    rowsTemplate ? rowsTemplate : `repeat(${rows}, 1fr)`};

  gap: ${({ theme, gap }) => convertSpaces(theme, gap)};
  padding: ${({ theme, padding }) => convertSpaces(theme, padding)};
  height: ${({ height = 'auto' }) => height};
  width: ${({ width = 'auto' }) => width};

  align-items: ${({ alignColumns = 'stretch' }) => alignColumns};
  justify-items: ${({ alignRows = 'stretch' }) => alignRows};
  grid-auto-flow: ${({ flow = 'initial' }) => flow};

  transition: all ${({ transitionTime = '1s' }) => transitionTime};

  @media ${({ theme }) => theme.mediaQueries.smallScreen} {
    ${({ shouldShrinkOnMobile = true, columnsOnMobile = 1, rowsOnMobile = 1 }) =>
      shouldShrinkOnMobile
        ? `grid-auto-flow: row; 
      grid-template-columns: repeat(${columnsOnMobile}, 1fr);
      grid-template-rows: repeat(${rowsOnMobile}, 1fr);`
        : ''}
  }
`
