import getApi from '../../api/apisauceConfig'
import { Patient } from '../../model/Patient'

export const changePatientOwner = (
  patientId: number,
  newOwnerId: string,
  includeSubResources: boolean,
) =>
  getApi().post<Patient>(
    `patients/${patientId}/switch_owner?includeSubResources=${includeSubResources}`,
    { newOwnerUuid: newOwnerId },
  )
