import { JSONToValue } from '@follow/farte'
import { take, takeEvery, put, select } from 'redux-saga/effects'

import { domainDocumentHistoryActions } from '../../domain/documentHistory'
import { domainDocumentInstancesActions } from '../../domain/documentInstances'
import { domainDocumentTemplatesActions } from '../../domain/documentTemplates'
import {
  setBottomPanelOptions,
  BottomPanelComponentType,
  BottomPanelActionTypes,
} from '../bottomPanel'

import { bottomPanelDocumentHistoryActions } from './bottomPanelDocumentHistory.actions'
import { UiBottomPanelDocumentHistoryActionTypes } from './bottomPanelDocumentHistory.model'
import { documentHistorySelector } from './bottomPanelDocumentHistory.selectors'

import { parseToValueJSON } from '../../../misc/editor.utilities'

function* searchDocumentHistorySagaWorker(
  _: ReturnType<typeof bottomPanelDocumentHistoryActions.searchDocumentHistory>,
) {
  yield put(
    setBottomPanelOptions({
      title: `Historique des versions`,
      open: true,
      componentType: BottomPanelComponentType.SearchDocumentHistory,
    }),
  )
  let opened = true
  while (opened) {
    const { options }: ReturnType<typeof setBottomPanelOptions> = yield take(
      BottomPanelActionTypes.SET_BOTTOM_PANEL_OPTIONS,
    )
    opened = options.open || false
  }
  // Clear document history
  yield put(bottomPanelDocumentHistoryActions.closeDocumentHistory())
}

function* searchDocumentHistorySagaWatcher() {
  yield takeEvery(
    UiBottomPanelDocumentHistoryActionTypes.SEARCH_DOCUMENT_HISTORY,
    searchDocumentHistorySagaWorker,
  )
}

function* restoreDocumentHistorySagaWorker({
  documentRevision,
}: ReturnType<typeof bottomPanelDocumentHistoryActions.restoreDocumentHistory>) {
  const documentHistory: ReturnType<typeof documentHistorySelector> =
    yield select(documentHistorySelector)
  if (documentHistory === null) {
    return
  }
  // Création d'un point de sauvegarde
  yield put(
    domainDocumentHistoryActions.createDocumentHistoryRevision(
      documentHistory.id,
      documentHistory.resource,
    ),
  )
  // Restauration de la révision précedente
  if (documentHistory.resource === 'document_templates') {
    yield put(
      domainDocumentTemplatesActions.updateDocumentTemplateValue(
        documentHistory.id,
        JSONToValue(parseToValueJSON(documentRevision.template)),
      ),
    )
  } else if (documentHistory.resource === 'documents') {
    yield put(
      domainDocumentInstancesActions.updateDocumentInstanceValue(
        documentHistory.id,
        JSONToValue(parseToValueJSON(documentRevision.template)),
        true,
      ),
    )
  }
  // Update bottom panel state
  yield put(
    bottomPanelDocumentHistoryActions.searchDocumentHistory(
      documentHistory.id,
      documentRevision.hash,
      documentHistory.resource,
    ),
  )
}

function* restoreDocumentHistorySagaWatcher() {
  yield takeEvery(
    UiBottomPanelDocumentHistoryActionTypes.RESTORE_DOCUMENT_HISTORY,
    restoreDocumentHistorySagaWorker,
  )
}

export const bottomPanelDocumentHistorysSagas = {
  restoreDocumentHistorySagaWatcher,
  searchDocumentHistorySagaWatcher,
}
