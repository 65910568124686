import { UnmountTransitionProps } from '../../../../misc/withUnmountTransition.hoc'
import {
  ContactConfiguration,
  ContactEndGreeting,
  ContactStartGreeting,
} from '../../../../model/Contact'
import { EditComponentUsingBottomPanelProps } from '../BottomPanel'
import { SelectOption } from '../../../../model/SelectOption'
import * as yup from 'yup'

export const CONTACT_GREETING_START_OPTIONS: SelectOption<ContactStartGreeting>[] = [
  { value: 'salutation_male_colleague', label: 'Cher Confrère,' },
  { value: 'salutation_female_colleague', label: 'Chère Consœur,' },
  { value: 'salutation_male_friend', label: 'Cher Ami,' },
  { value: 'salutation_female_friend', label: 'Chère Amie,' },
  { value: 'salutation_dear_colleague', label: 'Cher Collègue,' },
]

export const CONTACT_GREETING_END_OPTIONS: SelectOption<ContactEndGreeting>[] = [
  { value: 'closing_colleague', label: 'Bien confraternellement,' },
  { value: 'closing_friendly', label: 'Bien amicalement,' },
  { value: 'closing_friendship', label: 'Amitiés,' },
  { value: 'closing_best_regards', label: 'Bien cordialement,' },
]

export const ContactConfigureSchema = yup.object().shape({
  startGreeting: yup.string().nullable().required('La formule de politesse du début est requise'),
  endGreeting: yup.string().nullable().required('La formule de politesse de fin est requise'),
})

interface ContactConfigureBottomPanelOwnProps {
  patientId?: number
}

export interface ContactConfigureBottomPanelProps
  extends ContactConfigureBottomPanelOwnProps,
    EditComponentUsingBottomPanelProps<ContactConfiguration, string>,
    UnmountTransitionProps {}
