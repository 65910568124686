import { FunctionComponent, useMemo, useState } from 'react'
import {
  AutocompleteInput,
  DatePicker,
  DropdownOverlay,
  FormHeading,
  Input,
  SuggestionItem,
  Switch,
  TextArea,
} from '../../../shared'
import { HealthDataModalInputWrapper } from '../../HealthDataModalInputWrapper'
import { computeTypeLabel } from '../../../../misc/healthData.utilities'
import { HealthDataGynecologyProps } from './HealthDataGynecology.model'
import { DATE_FORMAT_FRONT_SHORT, formatFr } from '../../../../misc/date.utilities'
import { CONTRACEPTION_OPTIONS } from '../../../../model/HealthData'
import { useSelectArrowNavigation } from '../../../../hooks/utils'
import styles from './HealthDataGynecology.module.scss'

export const HealthDataGynecology: FunctionComponent<HealthDataGynecologyProps> = ({
  indexedTypes,
  indexedValues,
  editedValues,
  validation,
  openPregnanciesForm,
  setOpenPregnanciesForm,
  setSelectedHealthDataType,
  setValues,
}) => {
  const [showContraceptionsSuggestion, setShowContraceptionsSuggestion] = useState(false)
  const contraceptionMethodSearchResult = useMemo(() => {
    const filteredOptions = CONTRACEPTION_OPTIONS.filter((option) =>
      option.label
        .toLocaleLowerCase()
        .includes(editedValues.contraceptionMethod?.toLocaleLowerCase() ?? ''),
    )

    if (
      filteredOptions.length === 1 &&
      filteredOptions.at(0)?.label === editedValues.contraceptionMethod
    ) {
      return CONTRACEPTION_OPTIONS
    }
    return filteredOptions
  }, [editedValues.contraceptionMethod])

  const [selectedContraceptionMethodIndex, setSelectedContraceptionMethodIndex] = useState<number>()
  const [scrollRef] = useSelectArrowNavigation(CONTRACEPTION_OPTIONS, (option) =>
    setValues({ contraceptionMethod: option.value }),
  )

  return (
    <div className={styles.column}>
      <FormHeading
        headings={[
          { icon: 'warningCircle', label: 'Antécédents gynécologiques et obstétricaux', size: 6 },
        ]}
      />
      {indexedTypes.gravidity && (
        <HealthDataModalInputWrapper
          value={indexedValues.gravidity}
          type={indexedTypes.gravidity}
          onClickHistory={setSelectedHealthDataType}
        >
          <div className={styles.number_input_layout}>
            <Input
              name="gravidity"
              label={computeTypeLabel(indexedTypes.gravidity)}
              colorPreset="light"
              valid={validation.gravidity}
              value={editedValues.gravidity ?? ''}
              autocomplete="off"
              placeholder={indexedValues.gravidity?.value.value}
              onChange={(event) => {
                const value = event.target.value
                const parsed = parseInt(value)
                setValues({ gravidity: !Number.isNaN(parsed) ? parsed : undefined })
              }}
            />
          </div>
        </HealthDataModalInputWrapper>
      )}
      {indexedTypes.parity && (
        <HealthDataModalInputWrapper
          value={indexedValues.parity}
          type={indexedTypes.parity}
          onClickHistory={setSelectedHealthDataType}
        >
          <div className={styles.number_input_layout}>
            <Input
              name="parity"
              label={computeTypeLabel(indexedTypes.parity)}
              colorPreset="light"
              valid={validation.parity}
              value={editedValues.parity ?? ''}
              autocomplete="off"
              placeholder={indexedValues.parity?.value.value}
              onChange={(event) => {
                const value = event.target.value
                const parsed = parseInt(value)
                setValues({ parity: !Number.isNaN(parsed) ? parsed : undefined })
              }}
            />
          </div>
        </HealthDataModalInputWrapper>
      )}
      {indexedTypes.number_of_children && (
        <HealthDataModalInputWrapper
          value={indexedValues.number_of_children}
          type={indexedTypes.number_of_children}
          onClickHistory={setSelectedHealthDataType}
        >
          <div className={styles.number_input_layout}>
            <Input
              name="number_of_children"
              label={computeTypeLabel(indexedTypes.number_of_children)}
              colorPreset="light"
              valid={validation.number_of_children}
              value={editedValues.numberOfChildren ?? ''}
              autocomplete="off"
              placeholder={
                indexedValues.number_of_children
                  ? indexedValues.number_of_children.value.value
                  : undefined
              }
              onChange={(event) => {
                const value = event.target.value
                const parsed = parseInt(value)
                setValues({ numberOfChildren: !Number.isNaN(parsed) ? parsed : undefined })
              }}
            />
          </div>
        </HealthDataModalInputWrapper>
      )}
      {indexedTypes.comment && (
        <HealthDataModalInputWrapper
          value={indexedValues.comment}
          type={indexedTypes.comment}
          onClickHistory={setSelectedHealthDataType}
        >
          <div className={styles.number_input_layout}>
            <TextArea
              name="comment"
              label={computeTypeLabel(indexedTypes.comment)}
              valid={validation.comment}
              value={editedValues.comment ?? ''}
              placeholder={indexedValues.comment ? indexedValues.comment.value.value : undefined}
              onChange={(event) => {
                const value = event.target.value
                setValues({ comment: value ?? undefined })
              }}
            />
          </div>
        </HealthDataModalInputWrapper>
      )}
      <FormHeading
        headings={[{ icon: 'warningCircle', label: 'Informations gynécologiques', size: 6 }]}
      />
      {indexedTypes.last_smear_at && (
        <HealthDataModalInputWrapper
          value={indexedValues.last_smear_at}
          type={indexedTypes.last_smear_at}
          onClickHistory={setSelectedHealthDataType}
        >
          <DatePicker
            label={computeTypeLabel(indexedTypes.last_smear_at)}
            placeholder={
              indexedValues.last_smear_at?.value
                ? formatFr(indexedValues.last_smear_at.value.value, DATE_FORMAT_FRONT_SHORT)
                : 'jj/mm/aaaa'
            }
            value={editedValues.lastSmear}
            onChange={(value) => setValues({ lastSmear: value })}
            showCalendarButton={false}
          />
        </HealthDataModalInputWrapper>
      )}
      {indexedTypes.last_hiv_test_at && (
        <HealthDataModalInputWrapper
          value={indexedValues.last_hiv_test_at}
          type={indexedTypes.last_hiv_test_at}
          onClickHistory={setSelectedHealthDataType}
        >
          <DatePicker
            label={computeTypeLabel(indexedTypes.last_hiv_test_at)}
            placeholder={
              indexedValues.last_hiv_test_at?.value
                ? formatFr(indexedValues.last_hiv_test_at.value.value, DATE_FORMAT_FRONT_SHORT)
                : 'jj/mm/aaaa'
            }
            value={editedValues.lastHivTest}
            onChange={(value) => setValues({ lastHivTest: value })}
            showCalendarButton={false}
          />
        </HealthDataModalInputWrapper>
      )}
      {indexedTypes.last_hpv_test_at && (
        <HealthDataModalInputWrapper
          value={indexedValues.last_hpv_test_at}
          type={indexedTypes.last_hpv_test_at}
          onClickHistory={setSelectedHealthDataType}
        >
          <DatePicker
            label={computeTypeLabel(indexedTypes.last_hpv_test_at)}
            placeholder={
              indexedValues.last_hpv_test_at?.value
                ? formatFr(indexedValues.last_hpv_test_at.value.value, DATE_FORMAT_FRONT_SHORT)
                : 'jj/mm/aaaa'
            }
            value={editedValues.lastHpvTest}
            onChange={(value) => setValues({ lastHpvTest: value })}
            showCalendarButton={false}
          />
        </HealthDataModalInputWrapper>
      )}
      {indexedTypes.last_mammography_at && (
        <HealthDataModalInputWrapper
          value={indexedValues.last_mammography_at}
          type={indexedTypes.last_mammography_at}
          onClickHistory={setSelectedHealthDataType}
        >
          <DatePicker
            label={computeTypeLabel(indexedTypes.last_mammography_at)}
            placeholder={
              indexedValues.last_mammography_at?.value
                ? formatFr(indexedValues.last_mammography_at.value.value, DATE_FORMAT_FRONT_SHORT)
                : 'jj/mm/aaaa'
            }
            value={editedValues.lastMammography}
            onChange={(value) => setValues({ lastMammography: value })}
            showCalendarButton={false}
          />
        </HealthDataModalInputWrapper>
      )}
      {indexedTypes.contraception_method && (
        <HealthDataModalInputWrapper
          value={indexedValues.contraception_method}
          type={indexedTypes.contraception_method}
          onClickHistory={setSelectedHealthDataType}
        >
          <AutocompleteInput
            selectedIndex={selectedContraceptionMethodIndex}
            predictionsLength={contraceptionMethodSearchResult.length}
            handlePressEnter={() => {
              if (selectedContraceptionMethodIndex) {
                setValues({
                  contraceptionMethod:
                    contraceptionMethodSearchResult[selectedContraceptionMethodIndex].value,
                })
              }
            }}
            renderInput={() =>
              indexedTypes.contraception_method && (
                <>
                  <Input
                    label={computeTypeLabel(indexedTypes.contraception_method)}
                    value={editedValues.contraceptionMethod ?? ''}
                    placeholder={indexedValues.contraception_method?.value.value}
                    name="contraception_method"
                    onFocus={() => setShowContraceptionsSuggestion(true)}
                    onBlur={() => setShowContraceptionsSuggestion(false)}
                    onChange={(value) => setValues({ contraceptionMethod: value.target.value })}
                    colorPreset="light"
                  />
                  {contraceptionMethodSearchResult.length > 0 && (
                    <DropdownOverlay
                      display={showContraceptionsSuggestion}
                      scrollRef={scrollRef}
                      position="top"
                    >
                      {contraceptionMethodSearchResult.map((option, index) => (
                        <SuggestionItem
                          onClick={() => setValues({ contraceptionMethod: option.value })}
                          hover={index === selectedContraceptionMethodIndex}
                          key={option.value}
                        >
                          <div className="p-4">{option.label}</div>
                        </SuggestionItem>
                      ))}
                    </DropdownOverlay>
                  )}
                </>
              )
            }
            suggestionsShown={showContraceptionsSuggestion}
            setSelectedIndex={setSelectedContraceptionMethodIndex}
          />
        </HealthDataModalInputWrapper>
      )}
      {indexedTypes.is_breastfeeding && (
        <HealthDataModalInputWrapper
          value={indexedValues.is_breastfeeding}
          type={indexedTypes.is_breastfeeding}
          onClickHistory={setSelectedHealthDataType}
        >
          <div className={styles.input_layout}>
            <Switch
              name={computeTypeLabel(indexedTypes.is_breastfeeding)}
              checked={editedValues.isBreastfeeding ?? indexedValues?.is_breastfeeding?.value.value}
              onChange={(value) => setValues({ isBreastfeeding: value })}
            />
          </div>
        </HealthDataModalInputWrapper>
      )}
      {indexedTypes.is_breastfeeding &&
        (editedValues.isBreastfeeding ?? indexedValues.is_breastfeeding) &&
        indexedTypes.breastfeeding_start && (
          <HealthDataModalInputWrapper
            value={indexedValues.breastfeeding_start}
            type={indexedTypes.breastfeeding_start}
            onClickHistory={setSelectedHealthDataType}
          >
            <DatePicker
              label={computeTypeLabel(indexedTypes.breastfeeding_start)}
              placeholder={
                indexedValues.breastfeeding_start?.value
                  ? formatFr(indexedValues.breastfeeding_start.value.value, DATE_FORMAT_FRONT_SHORT)
                  : 'jj/mm/aaaa'
              }
              value={editedValues.breastfeedingStart}
              onChange={(value) => setValues({ breastfeedingStart: value })}
              showCalendarButton={false}
            />
          </HealthDataModalInputWrapper>
        )}
      <span
        className={styles.pregnancy_label}
        onClick={() => setOpenPregnanciesForm((currentValue) => !currentValue)}
      >
        {`${openPregnanciesForm ? 'Masquer' : 'Voir'} les grossesses`}
      </span>
    </div>
  )
}
