import { Task, TaskCategory, TaskInformation, TaskMutationPayload } from '../../../../model/Task'
import { SelectOption } from '../../../../model/SelectOption'
import { IconsType } from '../../../shared'
import { CdaContext } from '../../../../model/CdaContext'
import { TestProps } from '../../../../misc/types.utilities'
import { TaskTableColumn, TaskTableSize } from '../TaskTable.model'

export interface TaskTableRowProps extends TestProps {
  task: Partial<Task>
  editMode?: boolean
  onCreateOrUpdate: (task: TaskMutationPayload) => void
  onDeleteTask: (taskId?: number) => void
  removeNewTask?: () => void
  handleSwitchEditModeRequest: (taskId?: number) => void
  openTaskInformation?: (taskInformation: TaskInformation[]) => void
  openCdaInformation?: (cdaContext: CdaContext) => void
  displayColumns?: TaskTableColumn[]
  size?: TaskTableSize
}

export const TASK_CATEGORY_OPTIONS: SelectOption<TaskCategory>[] = [
  { label: 'Autre', value: TaskCategory.OTHER },
  { label: 'Edition', value: TaskCategory.EDIT },
  { label: 'Facturation', value: TaskCategory.BILLING },
  { label: 'Information', value: TaskCategory.INFORMATION },
  { label: 'Ordonnance', value: TaskCategory.PRESCRIPTION },
  { label: 'Compte rendu de biologie', value: TaskCategory.BIOLOGICAL_REPORT },
]

export const TASK_CATEGORY_LABEL: { [key in TaskCategory]: string } = {
  [TaskCategory.OTHER]: 'Autre',
  [TaskCategory.EDIT]: 'Edition',
  [TaskCategory.BILLING]: 'Facturation',
  [TaskCategory.INFORMATION]: 'Information',
  [TaskCategory.PRESCRIPTION]: 'Ordonnance',
  [TaskCategory.BIOLOGICAL_REPORT]: 'Compte rendu de biologie',
}

export const TASK_CATEGORY_ICON: { [key in TaskCategory]: IconsType } = {
  [TaskCategory.OTHER]: 'label',
  [TaskCategory.EDIT]: 'pencil',
  [TaskCategory.BILLING]: 'creditCard',
  [TaskCategory.INFORMATION]: 'infoCircle',
  [TaskCategory.PRESCRIPTION]: 'documentText',
  [TaskCategory.BIOLOGICAL_REPORT]: 'microscope',
}

export type ICON_COLOR = 'success' | 'backgroundDark1'
