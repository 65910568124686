import { parseISO } from 'date-fns'
import {
  CHILD_CIVILITY,
  CHILD_LIMIT_AGE,
  Civility,
  CIVILITY_LABELS,
  CIVILITY_LABELS_ABBREVIATIONS,
  FollowTypes,
  Title,
} from '../model'
import { parseToValueJSON } from './editor.utils'
import { deserializePatientHealthData } from './moduleData.utils'
import { isWithinInterval, startOfDay, endOfDay, addYears } from 'date-fns'

interface FirstNames {
  birthFirstName: string
  usedFirstName?: string | null
}
interface LastNames {
  birthLastName: string
  usedLastName?: string | null
}
type Names = FirstNames & LastNames

export const getDisplayedFirstname = ({ birthFirstName, usedFirstName }: FirstNames) => {
  return usedFirstName ?? birthFirstName
}

export const getDisplayedLastname = ({ birthLastName, usedLastName }: LastNames) => {
  return usedLastName ?? birthLastName
}

export const getPriorityNames = ({
  birthLastName,
  birthFirstName,
  usedLastName,
  usedFirstName,
}: Names) => {
  return !usedLastName && !usedFirstName
    ? {
        primaryFirstName: birthFirstName,
        primaryLastName: birthLastName,
      }
    : {
        primaryFirstName: usedFirstName ?? birthFirstName,
        primaryLastName: usedLastName ?? birthLastName,
        secondaryFirstName: birthFirstName,
        secondaryLastName: birthLastName,
      }
}

export const getDisplayedBirthnameAndUsedName = (patient: Names) => {
  const { primaryFirstName, primaryLastName, secondaryFirstName, secondaryLastName } =
    getPriorityNames(patient)
  return `${primaryFirstName} ${primaryLastName.toUpperCase()}${
    secondaryFirstName && secondaryLastName
      ? ` (né.e ${secondaryFirstName} ${secondaryLastName.toUpperCase()})`
      : ''
  }`
}

export const getDisplayedName = (patient: Names) => {
  const firstname = getDisplayedFirstname(patient)
  const lastname = getDisplayedLastname(patient)
  return { firstname, lastname }
}

const UNFORMATTED_SOCIAL_NUMBER_REGEX = /^\d{15}$/
const SPACE_INDEXES = [1, 4, 7, 10, 14, 18]
export function inputInseeNumberFormatter(input: string) {
  if (typeof input !== 'string') {
    return input
  }
  // Retrait de tous les espaces pour permettre un formattage from scratch
  let withoutSpace = input.replace(/\s/g, '')

  if (withoutSpace.match(UNFORMATTED_SOCIAL_NUMBER_REGEX) !== null) {
    for (const spaceIndex of SPACE_INDEXES) {
      const start = withoutSpace.slice(0, spaceIndex)
      const end = withoutSpace.slice(spaceIndex)
      if (spaceIndex === start.length) {
        withoutSpace = `${start} ${end}`
      }
    }
    return withoutSpace
  }
  if (SPACE_INDEXES.includes(input.length)) {
    return `${input} `
  }
  if (input.length > 21) {
    return input.slice(0, 21)
  }
  return input
}

export function deserializePatient(
  patient: FollowTypes.Patient.PatientDto,
): FollowTypes.Patient.Patient {
  return {
    ...patient,
    note: parseToValueJSON(patient.note || null),
    birthLastName: patient.birthLastName.toLocaleUpperCase(),
    usedLastName: patient.usedLastName ? patient.usedLastName.toLocaleUpperCase() : null,
    identityStatus: deserializeIdentityStatus(patient.identityStatus),
    moduleData: patient.moduleData.map(deserializePatientHealthData),
  }
}

export const deserializeIdentityStatus = (
  identityStatus: FollowTypes.Patient.IdentityStatusDto,
): FollowTypes.Patient.IdentityStatus => {
  const { insiStatus } = identityStatus
  return {
    ...identityStatus,
    insiStatus: {
      ...insiStatus,
      checkedAt: insiStatus.checkedAt ? parseISO(insiStatus.checkedAt) : null,
    },
  }
}

export const getBirthPlaceLabel = ({
  birthPlaceCity,
  birthPlaceCountry,
}: FollowTypes.Patient.Patient) => {
  let label = birthPlaceCity && birthPlaceCountry ? `${birthPlaceCity}, ${birthPlaceCountry}` : null
  label ??= birthPlaceCountry
  label ||= null

  return label
}

export const getPatientCivility = (
  patient: FollowTypes.Patient.Patient,
  civility: Civility,
  date: Date = new Date(),
) => {
  const birthDate = new Date(patient.birthDate)
  const isPatientAChild = isWithinInterval(date, {
    start: startOfDay(birthDate),
    end: endOfDay(addYears(birthDate, CHILD_LIMIT_AGE)),
  })

  if (isPatientAChild) return CHILD_CIVILITY

  const requestedCivility =
    civility === Civility.LONG ? CIVILITY_LABELS : CIVILITY_LABELS_ABBREVIATIONS

  return requestedCivility[patient.title || Title.NONE]
}
