import { useQuery } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { tagsKeys } from './tags.keys'
import { TagType } from '../../../model/Tag'
import { useMemo } from 'react'
import { FwResourceType } from '../../../model/Resource'
import { getUsedTags } from '../../../data/tag'
import { fetchAllTags } from './tags.utils'

interface GetInfiniteUsedTagsParams {
  usedInResource: FwResourceType
  enabled?: boolean
}

export const useGetUsedTags = ({ usedInResource, enabled = true }: GetInfiniteUsedTagsParams) => {
  const queryKey = useMemo(() => tagsKeys.listByResource(usedInResource), [usedInResource])

  const fetchTags = async (page: number, signal?: AbortSignal) => {
    const { data, ok } = await getUsedTags(page, usedInResource, signal)
    if (!data || !ok) {
      throw new UserFriendlyError(
        'error',
        'Impossible de récupérer la liste des labels',
        "Une erreur technique s'est produite",
      )
    }
    return data
  }

  const queryReturn = useQuery<TagType[]>(
    queryKey,
    async ({ signal }) => fetchAllTags(fetchTags, signal),
    {
      enabled,
    },
  )

  return { query: queryReturn, usedTagList: queryReturn.data || [] }
}
