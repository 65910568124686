import React, { FunctionComponent } from 'react'

import {
  PatientImportantInformationsItemProps,
  formatImportantInformationsLabel,
} from './PatientImportantInformationsItem.model'

import styles from './PatientImportantInformationsItem.module.scss'

export const PatientImportantInformationsItem: FunctionComponent<PatientImportantInformationsItemProps> = (
  props,
) => (
  <div className={styles.container}>
    <div className={styles.label}>{props.title}</div>
    <div className={styles.value}>{formatImportantInformationsLabel(props)}</div>
  </div>
)
