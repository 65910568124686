import { restuxUserMeTeamsApiConfig } from './api'
import { restuxCacheFactory } from '../../restux/cache'
import { RestuxResources } from '../../resources'
import { Team } from '../../../model/Team'

const {
  actions: userMeTeamsActions,
  reducers: userMeTeamsReducers,
  sagas: userMeTeamsSagas,
} = restuxCacheFactory<Team, Team>({
  apiConfig: restuxUserMeTeamsApiConfig,
  refetchList: true,
  resourceName: RestuxResources.userTeams,
})

export { userMeTeamsActions, userMeTeamsReducers, userMeTeamsSagas }
