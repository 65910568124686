import { connect } from 'react-redux'

import { RootState } from '../../store/index'
import { setBottomPanelOptions, toggleExpandBottomPanel } from '../../store/ui/bottomPanel'

import BottomPanelWrapper from './BottomPanelWrapper.component'

import {
  BottomPanelWrapperDispatchProps,
  BottomPanelWrapperStateProps,
} from './BottomPanelWrapper.model'

const mapStateToProps = (state: RootState): BottomPanelWrapperStateProps => ({
  title: state.ui.bottomPanel.title,
  open: state.ui.bottomPanel.open,
  withOverlay: state.ui.bottomPanel.withOverlay,
  maximized: state.ui.bottomPanel.maximized,
  componentType: state.ui.bottomPanel.componentType,
  displayCloseButton: state.ui.bottomPanel.displayCloseButton,
})

const mapDispatchToProps: BottomPanelWrapperDispatchProps = {
  setBottomPanelOptions,
  toggleExpandBottomPanel,
}

export default connect(mapStateToProps, mapDispatchToProps)(BottomPanelWrapper)
