/// <reference lib="dom" /> # l'usage de lib.dom est forcé afin d'éviter que TSs utilise le typing de react

import Html, { Rule } from 'slate-html-serializer'
import { NodeType, MarkType } from '../model/TextEditor.model'
import {
  CUSTOM_HTML_ATTRIBUTES,
  deserializeFixedValue,
  serializeFixedValue,
  serializeStyle,
  toObjectStyling,
  toStylesObject,
} from './HtmlSerializer.utils'
import { FormatingPluginMode } from '../FormatingPlugin'
import React from 'react'
import { Node } from 'slate'
import { Mark } from 'slate'
import { removeUnwantedCharacters, sanitizeSlateTemplate } from './TemplateCleaner/TemplateCleaner'
import { JSONToValue } from '../TextEditor.utilities'
import { isEqual } from 'lodash'

const BLOCK_TAGS: { [tagname: string]: NodeType } = {
  p: NodeType.PARAGRAPH,
  h1: NodeType.TITLE1,
  h2: NodeType.TITLE2,
  h3: NodeType.TITLE3,
  h4: NodeType.TITLE3,
}

const LIST_TAGS: { [tagname: string]: NodeType } = {
  li: NodeType.LIST_ITEM,
  ul: NodeType.LIST,
  ol: NodeType.NUMBERED_LIST,
}

const MARK_TAGS: { [tagname: string]: MarkType } = {
  strong: MarkType.BOLD,
  b: MarkType.BOLD,
  i: MarkType.ITALIC,
  em: MarkType.ITALIC,
  u: MarkType.UNDERLINED,
  code: MarkType.CODE,
}

const VARIABLE_TAGS = ['placeholder', 'placeholderbase']

function getIndexOfElementInHtmlCollection(el: Element): number {
  const childLists = el.parentElement ? el.parentElement.children : []
  for (let index = 0; index < childLists.length; index++) {
    if (childLists[index] === el) {
      return index + 1
    }
  }
  return -1
}

const STYLE_PROPERTIES = {
  fontSize: 'font-size',
  textAlign: 'text-align',
  fontFamily: 'font-family',
} as const

const deserializeStyle = (style: { [key: string]: string }) => {
  return Object.entries(style).reduce<
    Partial<Record<'fontFamily' | 'align' | 'fontSize', string | number>>
  >((acc, [key, value]) => {
    switch (key) {
      case STYLE_PROPERTIES.fontFamily:
        return { ...acc, fontFamily: value }
      case STYLE_PROPERTIES.textAlign:
        return { ...acc, align: value }
      case STYLE_PROPERTIES.fontSize:
        const fontSize = Number.parseInt(value)
        return { ...acc, fontSize }
      default:
        return { ...acc, [key]: value }
    }
  }, {})
}

function getRules(
  mode: FormatingPluginMode = 'normal',
  parseHtml?: (html: string) => HTMLElement,
): Rule[] {
  return [
    // Cas général pour les types block
    {
      deserialize(el: Element, next) {
        const blockTags = mode === 'inline' ? BLOCK_TAGS : { ...BLOCK_TAGS, ...LIST_TAGS }

        const block = blockTags[el.tagName.toLowerCase()]

        if (block) {
          let styleObj: any = {}
          let marker: string | null = null
          if (el.getAttribute) {
            const style = el.getAttribute('style')
            styleObj = style ? toStylesObject(style) : {}

            marker = el.getAttribute('data-marker')
          }

          return {
            object: 'block',
            type: block,
            nodes: next(el.childNodes),
            data: {
              ...deserializeStyle(styleObj),
              ...(marker && { marker }),
            },
          }
        }
        return undefined
      },
    },
    // Special case for list block in "inline mode"
    {
      deserialize(el: Element) {
        if (mode !== 'inline') {
          return undefined
        }

        const tagName = el.tagName.toLowerCase()
        if (LIST_TAGS[tagName] && el.parentElement) {
          const parentTagName = el.parentElement.tagName.toLowerCase()
          const index = getIndexOfElementInHtmlCollection(el)
          switch (parentTagName) {
            case 'ol':
              return {
                object: 'text',
                leaves: [
                  {
                    object: 'leaf',
                    text: `${index === 1 ? '\n' : ''}${index}. ${el.textContent}\n`,
                  },
                ],
              }
            case 'ul':
              return {
                object: 'text',
                leaves: [
                  {
                    object: 'leaf',
                    text: `${index === 1 ? '\n' : ''}- ${el.textContent}\n`,
                  },
                ],
              }
            default:
              return undefined
          }
        }
        return undefined
      },
    },
    // Page break
    {
      deserialize(el: Element) {
        if (el.tagName.toLowerCase() !== 'div') return undefined

        const role = el.getAttribute('role')

        if (role !== 'pagebreak') return undefined

        return {
          object: 'block',
          type: NodeType.PAGE_BREAK,
          isVoid: true,
        }
      },
    },
    // Marks: b, i, u, etc
    {
      deserialize(el: Element, next) {
        const mark = MARK_TAGS[el.tagName.toLowerCase()]

        // Gestion du cas particulier de la balise "<b>" racine dans Google Doc.
        //   La balise est un "<b>" et elle contient une directive de style "font-weight: normal"
        //   Alors on ommet cette balise "<b>"
        if (el.tagName.toLowerCase() === 'b') {
          let styleObj: any = {}
          if (el.getAttribute) {
            const style = el.getAttribute('style')
            styleObj = style ? toStylesObject(style) : {}
          }
          const fontWeight = styleObj['font-weight']
          if (fontWeight === 'normal' || fontWeight === 400) {
            return next(el.childNodes)
          }
        }
        if (mark) {
          return {
            object: 'mark',
            type: mark,
            nodes: next(el.childNodes),
          }
        }
        return undefined
      },
    },
    // Variables
    {
      deserialize(el: Element) {
        if (el.tagName.toLowerCase() !== 'span') return undefined
        const variableId = el.getAttribute(CUSTOM_HTML_ATTRIBUTES.variableId)
        if (!variableId) return undefined

        const displayConfig = el.getAttribute(CUSTOM_HTML_ATTRIBUTES.displayConfig)
        const simpleSerializer = createSerializer(parseHtml)
        const fixedValue = deserializeFixedValue(el, simpleSerializer)
        const style = el.getAttribute('style')
        const styleObj = deserializeStyle(style ? toStylesObject(style) : {})

        return {
          object: 'inline',
          type: NodeType.VARIABLE,
          data: {
            ...styleObj,
            idvariable: variableId,
            ...(displayConfig && { variableContext: { displayConfig } }),
            ...(fixedValue && { fixedValue }),
          },
        }
      },
    },
    // Style marks
    {
      deserialize(el: Element, next) {
        if (el.tagName.toLowerCase() === 'span') {
          const style = el.getAttribute('style')
          const styleObj = style ? toStylesObject(style) : {}
          const fontWeight = styleObj['font-weight']

          // Special case <span> with bold in css
          if (fontWeight === 'bold' || fontWeight === '700') {
            return {
              object: 'mark',
              type: MarkType.BOLD,
              nodes: next(el.childNodes),
            }
          }

          const styling = toObjectStyling(styleObj)
          const styleRule = Object.entries(styleObj)[0]
          if (!styleRule) return undefined

          return {
            object: 'mark',
            type: styleRule[0],
            data: styling,
            nodes: next(el.childNodes),
          }
        }
        return undefined
      },
    },
    {
      // Special case for code blocks, which need to grab the nested childNodes.
      deserialize(el: Element, next) {
        if (el.tagName.toLowerCase() === 'pre') {
          const code = el.childNodes[0]
          const childNodes =
            code && (code as Element).tagName.toLowerCase() === 'code'
              ? code.childNodes
              : el.childNodes

          return {
            object: 'block',
            type: 'code',
            nodes: next(childNodes),
          }
        }
        return undefined
      },
    },
    {
      // Special case for variable placeholder
      deserialize(el: Element, next) {
        const baseVariables: { [id: number]: string } = {
          1: 'patientName',
          2: 'patientSex',
          3: 'birthDate',
          4: 'doctorName',
          5: 'attachedDoctor',
          6: 'interventionDate',
          7: 'today',
          10: 'documentName',
          11: 'patientCivility',
          12: 'observations',
          13: 'patientAge',
        }
        const tagname = el.tagName.toLowerCase()
        if (VARIABLE_TAGS.includes(tagname)) {
          const strIdVariable = el.getAttribute('idvariable')
          const type: 'base' | 'user' = tagname === 'placeholderbase' ? 'base' : 'user'
          if (!strIdVariable) {
            // Lorsque nous n'avons pas d'idvariable (=> template incorrect). On remplace les variables par du texte
            return {
              object: 'text',
              text: 'not_founded', //el.textContent,
              marks: [],
            }
          }

          const idvariable =
            type === 'base'
              ? baseVariables[parseInt(strIdVariable, 10)] || `unknown_${strIdVariable}`
              : `question_${parseInt(strIdVariable, 10)}`

          return {
            object: 'inline',
            type: NodeType.VARIABLE,
            nodes: next(el.childNodes),
            data: {
              idvariable,
            },
          }
        }
        return undefined
      },
    },
    {
      // Special case for images, to grab their src.
      deserialize(el: Element, _next) {
        if (el.tagName.toLowerCase() === 'img') {
          const width = parseInt(el.getAttribute('width') ?? '')
          const height = parseInt(el.getAttribute('height') ?? '')
          const style = deserializeStyle(toStylesObject(el.getAttribute('style') ?? ''))
          const isSizeDefined = !Number.isNaN(width) && !Number.isNaN(height)
          return {
            object: 'block',
            type: 'image',
            isVoid: true,
            data: {
              url: el.getAttribute('src'),
              ...(isSizeDefined && { size: { width, height } }),
              ...(style.align && { align: style.align }),
            },
          }
        }
        return undefined
      },
    },
    {
      deserialize(el: Element) {
        if (el.tagName.toLowerCase() === 'pagebreak') {
          return {
            object: 'block',
            type: NodeType.PAGE_BREAK,
            isVoid: true,
          }
        }
        return undefined
      },
    },
    {
      // Special case for links, to grab their text content
      deserialize(el: Element) {
        if (el.tagName.toLowerCase() === 'a') {
          return {
            object: 'text',
            leaves: [
              {
                object: 'leaf',
                text: el.textContent,
              },
            ],
          }
        }
        return undefined
      },
    },
    {
      serialize(obj: Node | Mark, children) {
        if (obj.object === 'text') {
          return obj.text
        }

        const style = serializeStyle(obj.data)
        if (obj.object === 'document') {
          return <div style={style}>{children}</div>
        }

        if (obj.object === 'block') {
          switch (obj.type) {
            case NodeType.IMAGE:
              const size = obj.data.get('size')
              const align: 'center' | 'left' | 'justify' | 'right' = obj.data.get('align')

              const imgStyle = align ? { textAlign: align } : undefined

              return (
                <img
                  src={obj.data.get('url')}
                  width={size ? size.width : ''}
                  height={size ? size.height : ''}
                  style={imgStyle}
                />
              )
            case NodeType.TITLE1:
              return <h1 style={style}>{children}</h1>
            case NodeType.TITLE2:
              return <h2 style={style}>{children}</h2>
            case NodeType.TITLE3:
              return <h3 style={style}>{children}</h3>
            case NodeType.LIST_ITEM:
              return <li style={style}>{children}</li>
            case NodeType.LIST:
              return <ul style={style}>{children}</ul>
            case NodeType.NUMBERED_LIST:
              return <ol style={style}>{children}</ol>
            case NodeType.PARAGRAPH:
              const marker = obj.data.get('marker') || undefined
              return (
                <p style={style} data-marker={marker}>
                  {children}
                </p>
              )
            case NodeType.PAGE_BREAK:
              return <div style={style} role="pagebreak"></div>
            case 'text':
              return <span style={style}>{children}</span>
          }

          return <div style={style}>{children}</div>
        }
        if (obj.object === 'mark') {
          switch (obj.type) {
            case MarkType.UNDERLINED:
              return <u>{children}</u>
            case MarkType.BOLD:
              return <strong>{children}</strong>
            case MarkType.ITALIC:
              return <em>{children}</em>
            case 'font-size':
              return <span style={style}>{children}</span>
          }
          return <span style={style}>{children}</span>
        }

        if (obj.object === 'inline') {
          switch (obj.type) {
            case NodeType.VARIABLE:
              const variableId = obj.data.get('idvariable')
              if (!variableId) return null

              const variableContext = obj.data.get('variableContext', {})
              const displayConfig = variableContext['displayConfig']
              const fixedValue = obj.data.get('fixedValue')

              const simpleSerializer = createSerializer(parseHtml)
              const serializedFixed = serializeFixedValue(fixedValue, simpleSerializer)
              const nodeProps = {
                style,
                [CUSTOM_HTML_ATTRIBUTES.variableId]: variableId,
                [CUSTOM_HTML_ATTRIBUTES.displayConfig]: displayConfig,
                ...(serializedFixed && serializedFixed?.props),
              }

              if (!serializedFixed) {
                return <span {...nodeProps}></span>
              }

              if (serializedFixed.valueType === 'normal') {
                return <span {...nodeProps}>{serializedFixed.value}</span>
              }

              return (
                <div
                  {...nodeProps}
                  dangerouslySetInnerHTML={{ __html: serializedFixed.value }}
                ></div>
              )
          }
        }

        return obj.text
      },
    },
  ]
}

export function createSerializer(
  parseHtml?: (html: string) => HTMLElement,
  writingMode: FormatingPluginMode = 'normal',
) {
  return new Html({ rules: getRules(writingMode, parseHtml), parseHtml })
}

export function serializeWithSanityCheck(stringifiedDocument: string, htmlSerializer: Html) {
  const clearedString = removeUnwantedCharacters(stringifiedDocument)
  const parsedValue = JSONToValue(JSON.parse(clearedString))
  const jsonValue = sanitizeSlateTemplate(parsedValue.toJSON())
  const slateValue = JSONToValue(jsonValue)
  const htmlValue = htmlSerializer.serialize(slateValue)

  // Re-conversion en Slate pour comparaison
  const slateValueJson = slateValue.toJSON()
  const reslateValueJson = htmlSerializer.deserialize(htmlValue).toJSON()

  const reconversionCheck = isEqual(slateValueJson, reslateValueJson)

  return {
    reconversionCheck,
    htmlValue,
    originalValue: slateValueJson,
    reconvertedValue: reslateValueJson,
  }
}
