import { TagBackgroundColors } from '../../../../colors'
import { DocumentAlertSeverity, DocumentAlert } from '../../../../model/DocumentAlerts'

export interface DocumentAlertItemProps {
  alert: DocumentAlert
  open: boolean
}

export const mapSeverityToTagColor: { [key in DocumentAlertSeverity]: TagBackgroundColors } = {
  [DocumentAlertSeverity.info]: TagBackgroundColors.dsTag03,
  [DocumentAlertSeverity.level1]: TagBackgroundColors.dsTag05,
  [DocumentAlertSeverity.level2]: TagBackgroundColors.dsTag06,
  [DocumentAlertSeverity.level3]: TagBackgroundColors.dsTag07,
  [DocumentAlertSeverity.level4]: TagBackgroundColors.dsTag08,
}
