import { Formik, FormikProps } from 'formik'
import React, { FunctionComponent, useState } from 'react'
import { Button, FormikTextInput, Radio, RadioGroup } from '../../../shared'
import { MailLoginFormFields } from '../../../../pages/mail/MailLoginPage/MailLoginPage.model'
import { MailLoginFormProps, OTP_METHOD_SMS, OTP_METHOD_MAIL } from './MailLoginForm.model'
import { MailLoginValidationSchema } from './MailLoginForm.validator'
import { isDoctor } from '../../../../misc/user.utilities'

export const MailLoginForm: FunctionComponent<MailLoginFormProps> = ({ onSubmit, currentUser }) => {
  const [otpMethod, setOtpMethod] = useState(OTP_METHOD_SMS)

  const handleSubmit = (values: MailLoginFormFields) => {
    onSubmit({ rpps: values.rpps, password: values.password, otpMethod })
  }

  return (
    <Formik
      initialValues={{
        rpps: isDoctor(currentUser) && currentUser.rppsNumber ? currentUser.rppsNumber : '',
        password: '',
        otpMethod,
      }}
      validationSchema={MailLoginValidationSchema}
      onSubmit={handleSubmit}
      validateOnBlur
      validateOnChange
    >
      {(props: FormikProps<MailLoginFormFields>) => (
        <div
          onKeyPress={(e) => {
            if (e.key === 'Enter' && props.isValid) {
              props.handleSubmit()
            }
          }}
        >
          <FormikTextInput
            fieldName="rpps"
            label="Identifiant RPPS"
            autocomplete={false}
            colorPreset="light"
            required
          />
          <FormikTextInput
            type="password"
            fieldName="password"
            label="Mot de passe"
            autocomplete={false}
            colorPreset="light"
            required
          />
          <div className="my-5">
            <RadioGroup
              name="otpMethod"
              label="Recevoir un code vérification par :"
              value={otpMethod}
              onChange={(e) => setOtpMethod(e.currentTarget.value)}
            >
              <Radio label="SMS" value={OTP_METHOD_SMS} />
              <Radio label="Email" value={OTP_METHOD_MAIL} />
            </RadioGroup>
          </div>
          <div className="flex w-full">
            <Button
              theme="primary"
              type="submit"
              label="Se connecter"
              disabled={!props.isValid}
              onClick={() => {
                props.handleSubmit()
              }}
            />
          </div>
          <div className="w-full text-center mt-3">
            <a
              href="https://mailiz.mssante.fr/web/mss/connexion-otp"
              target="_blank"
              rel="noreferrer"
              className="text-xs text-shades-4 mb-3 hover:text-shades-3"
            >
              Gérer mon compte Mailiz
            </a>
          </div>
        </div>
      )}
    </Formik>
  )
}
