import { ApiIdentityStatusResource } from '../data/patients/mapper/patients.model'
import { CustomFile } from './File'
import { IdentityStatus, Patient, Sex } from './Patient'
import { FollowTypes } from '@follow/cdk'

export type MailContact = FollowTypes.Mail.MailContact

export const TMP_AUTHENTICATION_ID_MAIL = 'tmp_authentication_id_mail'
export const AUTHENTICATION_ID_MAIL = 'authentication_id_mail'

export enum MailFlag {
  UNREAD = 'UNREAD',
  FLAGGED = 'FLAGGED',
  ATTACHMENT = 'ATTACHMENT',
  REPLIED = 'REPLIED',
  SENT_BY_ME = 'SENT_BY_ME',
  DELETED = 'DELETED',
  DRAFT = 'DRAFT',
  FORWARDED = 'FORWARDED',
  URGENT = 'URGENT',
  LOW_PRIORITY = 'LOW_PRIORITY',
  PRIORITY = 'PRIORITY',
}

export interface MailListPage {
  items: Array<LightMail>
  itemsCount: number
}

export const DEFAULT_OFFSET_MAIL_LIST = 0
export const DEFAULT_LIMIT_MAIL_LIST = 20
export const DEFAULT_INCREMENT_OFFSET_MAIL_LIST = 20

export const SEARCH_MIN_CHAR = 4

export const ATTACHMENT_MAX_SIZE_IN_BYTES = 10485760 // 10 Mo
export const ATTACHMENT_MIME_TYPES =
  'application/pdf,image/jpeg,image/tiff,text/plain,application/rtf'

export interface MailAttachement extends CustomFile {
  part: number // Fait office d'id
  contentType: string
  size: number
}

export interface LightMail {
  id: string
  attachmentCount: number
  date: {
    date: string
    timezone_type: number
    timezone: string
  }
  emailFrom: string
  flags: MailFlag[]
  nameFrom: string
  subject: string
}

export interface Mail extends LightMail {
  attachments: MailAttachement[]
  emailTo: string
  nameTo: string
  body: string
  folderId: string
  fragment: string
  messageId: string
  size: string
  patient?: MailPatient
}

export interface MailPatient extends Partial<Patient> {
  birthFirstName: string
  birthLastName: string
  usedLastName?: string
  usedFirstName: string | null
  sex: Sex
  birthDate: string
  birthPlaceCode: string
  inseeNumber: string | null
  needUpdate?: boolean
  identityStatus: IdentityStatus
}

export interface MailPatientApi extends SafeOmit<MailPatient, 'identityStatus'> {
  identityStatus: ApiIdentityStatusResource
}

export interface MailList {
  itemsCount: number
  items: Mail[]
}

export interface MssEmailAddress {
  emailAddress: string
}

export interface MailClassificationBaseAttachment {
  id: string | number
  visibleName: string
  classifyVisibleName: string
  extension: string
}

export interface MailClassificationAttachment extends MailClassificationBaseAttachment {
  id: number
  cdaContent?: MailClassificationCdaAttachment[]
}

export interface MailClassificationCdaAttachment extends MailClassificationBaseAttachment {
  id: string
}
