import getApi from '../../../api'
import { singleItemPageLimit } from '../../../constants'
import { PaginatedList } from '../../../model/Pagination'
import { Team } from '../../../model/Team'

export const getUserTeams = (page: number, userId: string, signal?: AbortSignal) =>
  getApi().get<PaginatedList<Team>>(
    `/admin/users/${userId}/teams`,
    {
      page,
      limit: singleItemPageLimit,
    },
    { signal },
  )
