export const parseFontSizeAttribute = (value: any) => {
  if (!value || !(typeof value === 'string')) return null

  const parsed = value.replace('px', '')
  return Number(parsed)
}

// Mapping des niveaux de heading aux tailles de polices correspondantes (utilisé pour l'affichage uniquement)
// ex: <h1> -> font-size: 32px
export const headingFontSizeDisplay: Record<number, number> = {
  1: 32,
  2: 24,
  3: 19,
}
