import React, { FunctionComponent, useContext, forwardRef, MouseEvent, useEffect } from 'react'
import { WithVariableEditorContext } from '../../WithVariableEditorContext'
import { VariableContainerProps, BaseVariableComponentProps } from './Variable.model'
import NormalVariable from './NormalVariable.component'
import RenderVariable from './RenderVariable.component'
import SlateVariable from './SlateVariable.component'
import { isFixedValueUpToDate } from '../../../core/TextEditor.utilities'

const VariableContainer: FunctionComponent<VariableContainerProps> = ({
  marks,
  slateNodeKey,
  variableId,
  variableTitle,
  variableContext,
  fixedValue,
  ...renderAttributes
}) => {
  const {
    mode,
    enableVariableFixedValues,
    clickVariable,
    variableSecondaryAction,
    variableData,
    variableFallbackTheme,
    displayContextualMenu,
    updateVariableFixedValue,
  } = useContext(WithVariableEditorContext)

  const data = variableData[variableId]
  const isFixedValueActive = enableVariableFixedValues && !!data?.enableFixedValue

  useEffect(() => {
    if (fixedValue === null && isFixedValueActive) {
      updateVariableFixedValue(slateNodeKey, variableId, data)
    }
  }, [
    fixedValue,
    enableVariableFixedValues,
    updateVariableFixedValue,
    slateNodeKey,
    variableId,
    data,
  ])

  const handleClick = (event: MouseEvent<HTMLSpanElement>) => {
    event.preventDefault()
    if (event.ctrlKey && variableSecondaryAction) {
      variableSecondaryAction({ variableId })
      return
    }
    const position = { top: event.clientY, left: event.clientX }
    displayContextualMenu({
      variableSlateNodeKey: slateNodeKey,
      variableContext,
      variableId,
      position,
    })
    if (clickVariable) {
      clickVariable({ variableId })
    }
  }
  const commonProps: BaseVariableComponentProps = {
    variableId,
    variableContext,
    variableTitle: data && data.variableTitle,
    mode,
    slateNodeKey,
    renderAttributes,
    marks,
    handleClick: handleClick,
    customTheme: data && data.customTheme,
    fixedValue,
    fallbackTheme: variableFallbackTheme,
    isUpToDate: enableVariableFixedValues ? isFixedValueUpToDate(data, fixedValue) : true,
  }

  if (!data) {
    return <NormalVariable {...commonProps} value={null} fallbackValue={null} />
  }

  // Pas de fallback à l'impression
  if (!!fixedValue?.isFallback && ['print', 'preview'].includes(mode)) {
    return null
  }

  switch (data.type) {
    case 'slate':
      return (
        <SlateVariable value={data.value} fallbackValue={data.fallbackValue} {...commonProps} />
      )
    case 'render':
      return (
        <RenderVariable
          valueProps={data.value.props}
          valueRenderer={data.value.renderer}
          displayMode={data.value.displayMode}
          fallbackValue={data.fallbackValue}
          {...commonProps}
        />
      )
    case 'normal':
    default:
      return (
        <NormalVariable value={data.value} fallbackValue={data.fallbackValue} {...commonProps} />
      )
  }
}

export default forwardRef<unknown, VariableContainerProps>((props, ref) => (
  <VariableContainer forwardedRef={ref} {...props} />
))
