import { FollowTypes } from '../../model'
import { formatFr } from '../../utilities/date.utilities'
import { VariableKindPrefix } from '../variables.model'

export function isManualPrescriptionVariable(variableId: string) {
  return variableId.startsWith(`${VariableKindPrefix.MANUAL_PRESCRIPTION}_`)
}

export const computeManualPrescription = ({
  content,
  startDate,
  endDate,
}: FollowTypes.ManualPrescription.ManualPrescription) => {
  const start = formatFr(new Date(startDate), 'dd/MM/yyyy')

  if (endDate) {
    const end = formatFr(new Date(endDate), 'dd/MM/yyyy')
    return `${content} du ${start} au ${end}`
  } else {
    return `${content} à partir du ${start}`
  }
}
