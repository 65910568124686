import classNames from 'classnames'
import { FunctionComponent } from 'react'
import { InputWrapperProps } from './InputWrapper.model'

export const InputWrapper: FunctionComponent<InputWrapperProps> = ({
  children,
  disabled,
  title,
  disabledLabel,
}) => (
  <div>
    <div className={classNames('bg-shades-white', 'p-2 border-b border-shades-5')}>
      <div className="text-shades-4 font-medium text-xs">{title}</div>
      {children}
    </div>
    {disabled && <span className="text-shades-2 font-light text-xs">{disabledLabel}</span>}
  </div>
)
