import { Extension } from '@tiptap/react'
import { DEFAULT_FONT_SIZE, fontSizeRegex } from '@/model/Font'
import { PluginNames } from '@/model/Plugins'

type FontSizeOptions = {
  types: Array<string>
  defaultFontSize: number
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    [PluginNames.FontSize]: {
      /**
       * Set the text size
       * @param size Target font size in px
       */
      setFontSize: (size: number) => ReturnType
    }
  }
}

export const FontSize = Extension.create<FontSizeOptions>({
  name: PluginNames.FontSize,

  addOptions(): FontSizeOptions {
    return {
      ...this.parent?.(),
      types: ['textStyle'],
      defaultFontSize: DEFAULT_FONT_SIZE,
    }
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          fontSize: {
            default: this.options.defaultFontSize,
            parseHTML: (element) => element.style.fontSize,
            renderHTML: (attributes) => {
              const fontSize = attributes['fontSize']
              if (!fontSize || typeof fontSize !== 'string') return {}

              const match = fontSize.match(fontSizeRegex)
              const parsedSize = Number(match?.at(1))
              if (Number.isNaN(parsedSize) || parsedSize < 1) return {}

              return {
                style: `font-size: ${fontSize}`,
              }
            },
          },
        },
      },
    ]
  },

  addCommands() {
    return {
      setFontSize:
        (fontSize) =>
        ({ chain }) => {
          return chain()
            .setMark('textStyle', { fontSize: `${fontSize}px` })
            .run()
        },
    }
  },
})
