export const uiBottomPanelMailEditorActionTypes = {
  OPEN_EDITOR: `@BOTTOM_PANEL_MAIL_EDITOR/OPEN_EDITOR`,
}

export type MailOpeningSource = 'new' | 'medicalEvent' | 'reply'

export interface BottomPanelMailEditorConfig {
  includeObservation: boolean
  openedFrom: MailOpeningSource
  preselectedMssEmails: string[]
}

export type BottomPanelMailEditorState = BottomPanelMailEditorConfig
