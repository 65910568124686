import { useMutation, useQueryClient } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { TagMutationPayload, TagType } from '../../../model/Tag'
import { updateTag } from '../../../data/tag'
import { tagsKeys } from './tags.keys'

interface TagMutateVariable {
  tagId: string
  tagPayload: TagMutationPayload
}

export const useUpdateTag = () => {
  const queryClient = useQueryClient()

  return useMutation<TagType, Error, TagMutateVariable>(
    async ({ tagId, tagPayload }) => {
      const { data, ok } = await updateTag(tagId, tagPayload)
      if (!data || !ok) {
        throw new UserFriendlyError('error', 'La mise à jour du label a échoué')
      }
      return data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: tagsKeys.all })
      },
    },
  )
}
