import React, { FunctionComponent } from 'react'
import classnames from 'classnames/bind'
import styles from './TextArea.module.scss'
import { TextAreaProps } from './TextArea.model'
import { useAutoresizeTextArea } from '../../../../hooks/utils'

const classnamesCx = classnames.bind(styles)

export const TextArea: FunctionComponent<TextAreaProps> = ({
  name,
  colorPreset = 'dark',
  size = 'normal',
  label,
  placeholder,
  disabled = false,
  required = false,
  resize = 'none',
  value,
  initialRows = 3,
  autoResize = false,
  maxLength,
  minLength,
  testId,
  onChange,
  onBlur,
  onKeyPress,
}) => {
  const { height, ref: inputRef, resetHeight } = useAutoresizeTextArea(value ?? '', autoResize)

  function focusOnClick() {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }

  const containerClasses = classnamesCx({
    container: true,
    dark: colorPreset === 'dark',
    light: colorPreset === 'light',
    [`${size}Size`]: true,
  })

  const labelText = label ? `${label} ${required ? '*' : ''}` : label

  return (
    <div className={containerClasses} onClick={focusOnClick}>
      <div className={styles.wrapper}>
        {labelText && <label className={styles.label}>{labelText}</label>}
        <textarea
          ref={inputRef}
          data-test-id={testId}
          name={name}
          value={value}
          className={styles.input}
          placeholder={placeholder}
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
          onKeyPress={onKeyPress}
          onInput={() => autoResize && resetHeight()}
          rows={initialRows}
          style={{ resize, height }}
          maxLength={maxLength}
          minLength={minLength}
        />
      </div>
    </div>
  )
}
