import { createReducer } from 'redux-starter-kit'
import { bottomPanelBaseVariablesActions } from './bottomPanelBaseVariables.actions'
import {
  BottomPanelBaseVariableState,
  UiBottomPanelBaseVariablesActionTypes,
} from './bottomPanelBaseVariables.model'

const BASE_VARIABLE_PANEL_INITIAL_STATE: BottomPanelBaseVariableState = {
  baseVariableList: 'document',
}

const ACTION_HANDLERS = {
  [UiBottomPanelBaseVariablesActionTypes.SET_BASE_VARIABLE_LIST]: (
    state = BASE_VARIABLE_PANEL_INITIAL_STATE,
    { baseVariableList }: ReturnType<typeof bottomPanelBaseVariablesActions.setBaseVariableList>,
  ) => ({
    ...state,
    baseVariableList,
  }),
}

export const bottomPanelBaseVariableReducer = createReducer<BottomPanelBaseVariableState>(
  BASE_VARIABLE_PANEL_INITIAL_STATE,
  ACTION_HANDLERS,
)
