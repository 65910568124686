import React, { FunctionComponent, useState } from 'react'
import { MailLoginCard, MailLoginForm, MailLoginOtpForm } from '../../../components/mail'
import { OTP_METHOD_SMS } from '../../../components/mail/login/MailLoginForm/MailLoginForm.model'
import {
  MailLoginPageProps,
  MailLoginFormFields,
  MailLoginOtpFormFields,
} from './MailLoginPage.model'
import { TMP_AUTHENTICATION_ID_MAIL } from '../../../model/Mail'
import { isDoctor } from '../../../misc/user.utilities'
import { MailNoAddresses } from '../../../components/mail/login/MailNoAddresses'

export const MailLoginPage: FunctionComponent<MailLoginPageProps> = ({
  initializeAuthentication,
  finalizeAuthentication,
  setLoginInitiate,
  setOtpStatus,
  loginInitiate,
  currentUser,
  enabledFeatures,
}) => {
  const [otpMethod, setOtpMethod] = useState(OTP_METHOD_SMS)

  function handleLogin(values: MailLoginFormFields) {
    initializeAuthentication(values)
    setOtpMethod(values.otpMethod)
  }

  function handleLoginOtp(values: MailLoginOtpFormFields) {
    finalizeAuthentication(values)
  }

  function handleRetry() {
    setLoginInitiate(false)
    setOtpStatus(false)
    localStorage.removeItem(TMP_AUTHENTICATION_ID_MAIL)
  }

  return (
    <div className="flex flex-row h-screen w-full overflow-hidden">
      <MailLoginCard>
        {enabledFeatures?.mssSmtp && isDoctor(currentUser) && !currentUser.mailizEmailAddress ? (
          <MailNoAddresses />
        ) : (
          <>
            {!loginInitiate ? (
              <MailLoginForm onSubmit={handleLogin} currentUser={currentUser} />
            ) : (
              <MailLoginOtpForm
                onSubmit={handleLoginOtp}
                onRetry={handleRetry}
                otpMethod={otpMethod}
                setLoginInitiate={setLoginInitiate}
              />
            )}
          </>
        )}
      </MailLoginCard>
    </div>
  )
}
