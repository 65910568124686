import { connect } from 'react-redux'
import { RootState } from '../../../store'
import { enabledFeaturesPreferencesSelector } from '../../../store/domain/me'
import { domainMailActions } from '../../../store/domain/mail/mail.actions'
import {
  blackListedPatientMails,
  inUseMailSelector,
  selectFilesToSend,
  unqualifiedPatientEmails,
} from '../../../store/domain/mail/mail.selector'
import { addError } from '../../../store/message'
import { setBottomPanelOptions } from '../../../store/ui/bottomPanel'
import {
  mailEditorOpenedFromConfigSelector,
  mailEditorObservationConfigSelector,
  mailEditorPreselectedMssEmailsConfigSelector,
} from '../../../store/ui/bottomPanelMailEditor'
import { mailContactsPageSelector, uiMailContactsActions } from '../../../store/ui/mailContacts'
import { inUseMedicalEventSelector } from '../../../store/ui/medicalEvents'
import { MailEditor } from './MailEditor.component'
import { MailEditorDispatchProps, MailEditorStoreProps } from './MailEditor.model'
import {
  MailSubjectAction,
  mailDocumentsIdsSelector,
  mailSubjectSelector,
} from '../../../store/ui/mailSubject'

const mapStateToProps = (state: RootState): MailEditorStoreProps => ({
  mailContacts: mailContactsPageSelector(state),
  enabledFeatures: enabledFeaturesPreferencesSelector(state),
  openedFrom: mailEditorOpenedFromConfigSelector(state),
  includeObservation: mailEditorObservationConfigSelector(state),
  preselectedMssEmails: mailEditorPreselectedMssEmailsConfigSelector(state),
  currentMedicalEvent: inUseMedicalEventSelector(state),
  fileToSend: selectFilesToSend(state),
  blackListedPatientMails: blackListedPatientMails(state),
  unqualifiedPatientMails: unqualifiedPatientEmails(state),
  documentsIds: mailDocumentsIdsSelector(state),
  mailSubject: mailSubjectSelector(state),
  currentMail: inUseMailSelector(state),
})

const mapDispatchToProps: MailEditorDispatchProps = {
  setBottomPanelOptions,
  sendMail: domainMailActions.sendMail,
  addError,
  getMailContacts: uiMailContactsActions.requestPage,
  clearMailContacts: uiMailContactsActions.clearPagination,
  clearFilesToSend: domainMailActions.clearFilesToSend,
  checkPatientEmailValidation: domainMailActions.checkPatientEmailValidation,
  clearMailSubject: MailSubjectAction.clearMailSubject,
  clearDocumentsIds: MailSubjectAction.clearDocumentsIds,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)(MailEditor)
export { withConnect as MailEditor }
