import { useActionDispatch } from '../../utils'
import { addValid } from '../../../store/message'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Task, TaskMutationPayload } from '../../../model/Task'
import { createTask } from '../../../data/task/createTask'
import { UserFriendlyError } from '../../../model/Error'
import { taskKeys } from './tasks.keys'

export const useCreateTask = () => {
  const valid = useActionDispatch(addValid)
  const queryClient = useQueryClient()

  return useMutation<Task, Error, TaskMutationPayload>(
    async (variables) => {
      const { data, ok } = await createTask(variables)
      if (!data || !ok) {
        throw new UserFriendlyError('error', 'La création de la tâche a échoué')
      }
      return data
    },
    {
      onSuccess: (data) => {
        valid('Tâche créée avec succès.')
        queryClient.refetchQueries(taskKeys.all)
        queryClient.setQueryData(taskKeys.detail(data.id), data)
      },
    },
  )
}
