import { createReducer } from 'redux-starter-kit'
import { documentAlertsDomainActions } from './documentAlerts.actions'
import { DocumentAlertsDomainActionTypes, DocumentAlertsState } from './documentAlerts.model'

const DEFAULT_STATE: DocumentAlertsState = {
  alerts: [],
  availableFilters: null,
  types: null,
  vidalInfos: null,
  severityLabels: [],
}

const ACTION_HANDLERS = {
  [DocumentAlertsDomainActionTypes.SET]: (
    state = DEFAULT_STATE,
    { alerts }: ReturnType<typeof documentAlertsDomainActions.setAlerts>,
  ) => ({
    ...state,
    alerts,
  }),
  [DocumentAlertsDomainActionTypes.CLEAR]: (state = DEFAULT_STATE) => ({
    ...state,
    alerts: [],
  }),
  [DocumentAlertsDomainActionTypes.SET_FILTERS]: (
    state = DEFAULT_STATE,
    { availableFilters }: ReturnType<typeof documentAlertsDomainActions.setAvailableFilters>,
  ) => ({
    ...state,
    availableFilters,
  }),
  [DocumentAlertsDomainActionTypes.SET_TYPES]: (
    state = DEFAULT_STATE,
    { types }: ReturnType<typeof documentAlertsDomainActions.setTypes>,
  ) => ({
    ...state,
    types,
  }),
  [DocumentAlertsDomainActionTypes.SET_VIDAL_INFOS]: (
    state = DEFAULT_STATE,
    { vidalInfos }: ReturnType<typeof documentAlertsDomainActions.setVidalInfos>,
  ) => ({
    ...state,
    vidalInfos,
  }),
  [DocumentAlertsDomainActionTypes.SET_SEVERITY_LABELS]: (
    state = DEFAULT_STATE,
    { severityLabels }: ReturnType<typeof documentAlertsDomainActions.setSeverityLabels>,
  ) => ({
    ...state,
    severityLabels,
  }),
}

export const documentAlertsReducer = createReducer(DEFAULT_STATE, ACTION_HANDLERS)
