import { connect } from 'react-redux'
import { MedicalEventNavBar } from './MedicalEventNavBar.component'
import {
  MedicalEventNavBarDispatchProps,
  MedicalEventNavBarStoreProps,
} from './MedicalEventNavBar.model'
import { RootState } from '../../../../store'
import {
  inUseMedicalEventSelector,
  isMedicalEventInUseLoadingSelector,
} from '../../../../store/ui/medicalEvents'
import {
  isOwnerOfSelectedMedicalEvent,
  medicalEventDomainActions,
} from '../../../../store/domain/medicalEvents'

const mapStateToProps = (state: RootState): MedicalEventNavBarStoreProps => ({
  medicalEvent: inUseMedicalEventSelector(state),
  medicalEventLoadingState: isMedicalEventInUseLoadingSelector(state),
  isOwner: isOwnerOfSelectedMedicalEvent(state),
})

const mapDispatchToProps: MedicalEventNavBarDispatchProps = {
  lockMedicalEvent: medicalEventDomainActions.lockMedicalEvent,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)(MedicalEventNavBar)
export { withConnect as MedicalEventNavBar }
