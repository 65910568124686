import React, { FunctionComponent } from 'react'
import { UserAvatar } from '../../UserAvatar'
import styles from './MemberUsersListItem.module.scss'

type MemberUserListItemProps = {
  firstName: string
  familyName: string
  professionTitle?: string
  showStar?: boolean
}

export const MemberUserListItem: FunctionComponent<MemberUserListItemProps> = ({
  firstName,
  familyName,
  professionTitle,
  showStar = false,
}) => (
  <div className={styles.userItem}>
    <UserAvatar theme="light" familyName={familyName} firstName={firstName} showStar={showStar} />
    <span className={styles.itemText}>
      {professionTitle ? `${professionTitle} ` : ''}
      {`${firstName} ${familyName}`}
    </span>
  </div>
)
