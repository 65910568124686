import { Action, AnyAction } from 'redux'
import { ApiResponse } from 'apisauce'

import { PaginatedList } from '../../../model/Pagination'
import {
  RestuxPaginatedRequest,
  RestuxParams,
  RestuxFilters,
  IdentityIdentifier,
} from '../restux.model'

export interface RestuxCacheActionParams {
  params?: RestuxParams
  identifier?: string
}
export interface RestuxCacheRefetchableActionParams extends RestuxCacheActionParams {
  refetchList?: boolean
}
export interface RestuxCacheFilterableActionParams extends RestuxCacheActionParams {
  filters?: RestuxFilters
}

export interface RestuxCachePaginableActionParams extends RestuxCacheFilterableActionParams {
  page?: RestuxPaginatedRequest
}

export interface Identity {
  id: IdentityIdentifier
}
export type RestuxIdentifier = string | undefined

export type RestuxEntityDict<T extends Identity> = Record<IdentityIdentifier, Readonly<T>>

export interface RestuxResourceState<TL extends Identity, TD extends Identity> {
  list: RestuxEntityDict<TL>
  details: RestuxEntityDict<TD>
}

export interface RestuxIdentifiableAction extends Action {
  identifier: RestuxIdentifier
}

export interface RestuxAction extends RestuxIdentifiableAction {
  params: RestuxParams
}

export interface RestuxGetPaginatedItemsAction extends RestuxAction {
  filters: RestuxFilters
  page: RestuxPaginatedRequest
}

export interface RestuxGetItemAction extends RestuxAction {
  id: IdentityIdentifier
  filters: RestuxFilters
}

export interface WithRefetchListAction extends AnyAction {
  refetchList?: boolean
}

export type RestuxGenericAction = Partial<
  WithRefetchListAction | RestuxGetItemAction | RestuxGetPaginatedItemsAction
>

export interface RestuxCreateItemAction<T> extends RestuxAction, WithRefetchListAction {
  item: Omit<Partial<T>, 'id'>
}
export interface RestuxUpdateItemAction<T> extends RestuxAction, WithRefetchListAction {
  id: IdentityIdentifier
  item: Partial<T>
}

export interface RestuxSetItemsAction<T extends Identity> extends RestuxAction {
  paginatedList: PaginatedList<T>
}

export interface RestuxSetItemAction<T extends Identity>
  extends RestuxAction,
    WithRefetchListAction {
  item: T
}

export type RestuxDeleteItemsAction = RestuxIdentifiableAction
export interface RestuxDeleteItemAction extends RestuxAction, WithRefetchListAction {
  id: IdentityIdentifier
}

export type GetPaginatedItemsRestuxApiMethod<R extends Identity> = (
  route: string,
) => Promise<ApiResponse<PaginatedList<R>>>
export type GetItemRestuxApiMethod<R extends Identity> = (route: string) => Promise<ApiResponse<R>>
export type CreateItemRestuxApiMethod<R extends Identity> = (
  route: string,
  item: Partial<Omit<R, 'id'>>,
) => Promise<ApiResponse<R>>
export type UpdateItemRestuxApiMethod<R extends Identity> = (
  route: string,
  item: Partial<R>,
) => Promise<ApiResponse<R>>
export type DeleteItemRestuxApiMethod = (route: string) => Promise<ApiResponse<void>>

export interface RestuxApiConfig<TL extends Identity, TD extends Identity> {
  url: string // l'url peut avoir des variables. Exemple: '/posts/:postId/author'
  getPaginatedItems?: GetPaginatedItemsRestuxApiMethod<TL>
  getItem?: GetItemRestuxApiMethod<TD>
  createItem?: CreateItemRestuxApiMethod<TD>
  updateItem?: UpdateItemRestuxApiMethod<TD>
  deleteItem?: DeleteItemRestuxApiMethod
}

export interface RestuxCacheConf<TL extends Identity, TD extends Identity> {
  apiConfig: RestuxApiConfig<TL, TD>
  refetchList: boolean
  resourceName: string
}

export const DEFAULT_RESTUX_PAGINATED_REQUEST = {
  currentPage: 1,
  pageSize: 40,
}
