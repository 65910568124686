import { useMutation, useQueryClient } from '@tanstack/react-query'
import { readNotifications } from '../../../data/notifications'
import { PaginatedList } from '../../../model/Pagination'
import { ServerSentNotification } from '../../../model/Notification'
import { notificationsKeys } from './notifications.keys'

interface ReadUserNotificationsMutateVariables {
  notificationIds: string[]
}

export const useReadUserNotifications = () => {
  const queryClient = useQueryClient()

  const queryReturn = useMutation<null, Error, ReadUserNotificationsMutateVariables>(
    async ({ notificationIds }) => {
      const { data, ok } = await readNotifications(notificationIds)
      if (!data || !ok) {
        throw new Error('Impossible de marquer les notifcations comme lues')
      }
      return null
    },
    {
      onSuccess: (_data, { notificationIds }) => {
        queryClient.invalidateQueries<PaginatedList<ServerSentNotification>>({
          queryKey: notificationsKeys.all,
          refetchPage: (page) => page.items.some(({ id }) => notificationIds.includes(id)),
        })
      },
    },
  )

  return queryReturn
}
