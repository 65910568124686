import { LogoutPageDispatchProps } from './LogoutPage.model'
import { setUsurpedUser } from '../../../store/ui/adminUsers/adminUsers.actions'
import { connect } from 'react-redux'
import { LogoutPage } from './LogoutPage.component'

const mapDispatchToProps: LogoutPageDispatchProps = {
  setUsurpedUser,
}

const withConnect = connect(null, mapDispatchToProps)(LogoutPage)
export { withConnect as LogoutPage }
