import classNames from 'classnames/bind'
import React, { FC } from 'react'
import { Icon } from '@/components/Icon'
import { ToolbarButtonProps } from './ToolbarButton.model'
import styles from './ToolbarButton.module.scss'

const cx = classNames.bind(styles)

export const ToolbarButton: FC<ToolbarButtonProps> = ({
  label,
  title,
  active,
  disabled,
  icon,
  onClick,
}) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={cx(styles['button'], {
        active,
      })}
      title={title}
    >
      {icon && <Icon icon={icon} />}
      {label && label}
    </button>
  )
}
