import { useMutation, useQueryClient } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { DocumentLayout, DocumentLayoutMutationPayload } from '../../../model/DocumentLayout'
import { updateDocumentLayout } from '../../../data/documentLayouts'
import { documentLayoutsKeys } from './documentLayouts.keys'

interface DocumentLayoutMutateVariable {
  id: number
  payload: DocumentLayoutMutationPayload
}

export const useUpdateDocumentLayout = () => {
  const queryClient = useQueryClient()

  return useMutation<DocumentLayout, Error, DocumentLayoutMutateVariable>(
    async ({ id, payload }) => {
      const { data, ok } = await updateDocumentLayout(id, payload)
      if (!data || !ok) {
        throw new UserFriendlyError('error', 'La mise à jour de la mise en page a échoué')
      }
      return data
    },
    {
      onSuccess: (documentLayout) => {
        queryClient.invalidateQueries(documentLayoutsKeys.lists)
        queryClient.setQueryData(documentLayoutsKeys.detail(documentLayout.id), documentLayout)
      },
    },
  )
}
