import React from 'react'
import * as Bowser from 'bowser'
import { DevicesRules, MatchDeviceProps, BrowsersList } from './MatchDevice.model'

interface MatchDeviceState {
  matchDevice?: boolean
}

export function createMatchDevice(devicesRules: DevicesRules | BrowsersList) {
  return <OriginalProps extends {}>(
    WrappedComponent: React.ComponentType<OriginalProps & MatchDeviceProps>,
  ) =>
    class extends React.Component<OriginalProps & MatchDeviceProps, MatchDeviceState> {
      public state: MatchDeviceState = {}

      public componentDidMount() {
        const bowser = Bowser.getParser(window.navigator.userAgent)
        const isValidBrowser = bowser.satisfies(devicesRules)
        this.setState({
          matchDevice: isValidBrowser === true,
        })
      }

      public render() {
        const { matchDevice } = this.state
        return <WrappedComponent {...this.props} matchDevice={matchDevice} />
      }
    }
}
