import React, { FunctionComponent, useState } from 'react'
import { Icon, FormBlock } from '../../../shared'
import { InformationAlert } from '../InformationAlert'

export const DistantFormBlock: FunctionComponent<{ token: string }> = ({ token }) => {
  const url = `${window.location.origin}/survey/startConsultation?token=${token}`

  const [copied, setCopied] = useState(false)

  const copyToClipBoard = async () => {
    await navigator.clipboard.writeText(url)
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 2000)
  }

  return (
    <FormBlock label="Questionnaire à distance" icon="clipboard">
      <InformationAlert>
        <span>Lien public de votre questionnaire à distance :</span>
        <div className="flex flex-col relative">
          <div className="flex items-baseline text-xs cursor-pointer mt-2">
            <span className="hover:underline" onClick={() => window.open(url)}>
              {url}
            </span>
            <div
              className="self-end cursor-pointer"
              title="Copier le lien"
              onClick={copyToClipBoard}
            >
              <Icon icon="clipboard" size="nano" />
            </div>
          </div>
          {copied && (
            <div className="flex absolute items-center left-0 right-0 top-0 bottom-0 w-full h-full bg-white opacity-100">
              <span className="text-xs m-2 italic">Copié dans le presse papier</span>
            </div>
          )}
        </div>
      </InformationAlert>
    </FormBlock>
  )
}
