import { ReactElement, ReactNode } from 'react'
import { TooltipPointerDirection, TooltipSize, TooltipTextAlign } from '../Tooltip/Tooltip.model'

export interface TooltipWrapperProps {
  content?: ReactNode
  children?: ReactElement
  display?: boolean
  pointerDirection?: TooltipPointerDirection
  size?: TooltipSize
  interactive?: boolean
  textAlign?: TooltipTextAlign
  debounceHiding?: boolean
}

export const oppositeArrowDirection: {
  [key in TooltipPointerDirection]: TooltipPointerDirection
} = {
  top: 'bottom',
  bottom: 'top',
  left: 'right',
  right: 'left',
}
