import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { metricsActionsType } from './metrics.model'
import { ApiResponse } from 'apisauce'
import { addResponseError } from '../../message'
import { metricsActions } from './metrics.actions'
import { downloadMetrics, fetchMetrics, sendDmpMetrics } from '../../cache/metrics/api'
import { MetricsSendingInfos } from '../../../model/Metrics'
import { downloadBlob } from '../../../misc/blob.utilities'
import { extractFilename } from '../../../misc/extractFilename.utilities'

function* fetchMetricsWorker({ params, config }: ReturnType<typeof metricsActions.getMetrics>) {
  yield put(metricsActions.getMetricsPending())
  try {
    const { data, ok, status }: ApiResponse<MetricsSendingInfos> = yield call(
      fetchMetrics,
      params,
      config,
    )

    if (!ok || !data)
      throw new Error('Response is not ok or payload no contains data - FetchMetrics failed')

    if (status !== 200) throw new Error('Response status is not 200 - FetchMetrics failed')

    yield put(metricsActions.getMetricsSuccess(data))
  } catch (error) {
    yield put(metricsActions.getMetricsFailed())
    yield put(addResponseError('Erreur lors du chargement du Dashboard'))
  }
}

export function* fetchMetricsWatcher() {
  yield takeEvery(metricsActionsType.GET_LIST_OF_METRICS, fetchMetricsWorker)
}

function* downloadMetricsWorker({
  dateInterval: { start, end },
}: ReturnType<typeof metricsActions.downloadMetrics>) {
  try {
    const { ok, data, status, headers }: ApiResponse<string> = yield call(downloadMetrics, {
      start,
      end,
    })

    if (!ok || !data) {
      throw new Error('Response is not ok or payload no contains data - DownloadMetrics failed')
    }

    if (status !== 200) {
      throw new Error('Response status is not 200 - DownloadMetrics failed')
    }

    const blob = new Blob([data], {
      type: 'text/csv;charset=utf-8',
    })

    const contentDisposition = headers?.['content-disposition']
    const filename = contentDisposition ? extractFilename(contentDisposition) : null

    downloadBlob(blob, filename || 'metrics.csv')
  } catch (error) {
    yield put(addResponseError('Erreur lors du téléchargement du fichier CSV'))
  }
}

export function* downloadMetricsWatcher() {
  yield takeLatest(metricsActionsType.DOWNLOAD_METRICS, downloadMetricsWorker)
}

function* sendDmpMetricsWorker({ payload }: ReturnType<typeof metricsActions.sendDmpMetrics>) {
  const response: ApiResponse<{}> = yield call(sendDmpMetrics, payload)
  if (response.status !== 204) {
    console.warn('sendDmpMetrics error', response)
  }
}

function* sendDmpMetricsWatcher() {
  yield takeEvery(metricsActionsType.SEND_DMP_METRICS, sendDmpMetricsWorker)
}

export const metricsSagas = { fetchMetricsWatcher, downloadMetricsWatcher, sendDmpMetricsWatcher }
