import { RootState } from '../../reducers'
import { restuxResolveInUse } from '../../restux/ui/restuxUi.selectors'

export const inUseContextualMenuQuestionnaireIdSelector = (state: RootState) =>
  state.ui.documentContextualMenu.inUseQuestionnaire

export const contextualMenuQuestionnairePaginationSelector = (state: RootState) =>
  state.ui.documentContextualMenu.paginationQuestionnaire

export const inUseContextualMenuQuestionnaireSelector = (state: RootState) => {
  const inUse = state.ui.documentContextualMenu.inUseQuestionnaire
  const resources = state.cache.questionnaires.details
  return restuxResolveInUse(resources, inUse)
}

export const loadingInUseContextualMenuQuestionnaireSelector = (state: RootState) =>
  state.ui.documentContextualMenu.inUseQuestionnaire.loadingState
