import { put, take, select, takeEvery } from 'redux-saga/effects'
import isEqual from 'lodash.isequal'
import { restuxCacheActionsFactory } from '../cache/restuxCacheActions.factory'
import {
  PaginationPatch,
  RestuxUiSagaConf,
  RestuxPagination,
  RestuxLoadingState,
  RESTUX_UI_IDENTIFIER,
} from './restuxUi.model'
import { uiActionsFactory } from './restuxUiActions.factory'
import { notMatchingIdentifier, generateIdentifier } from '../restux.utilities'
import { WithRefetchListAction } from '../cache/restuxCache.model'
import { RestuxPaginatedRequest, RestuxFilters, RestuxParams } from '../restux.model'
import { AnyAction } from 'redux-starter-kit'

export const uiSagasFactory = (conf: RestuxUiSagaConf) => {
  const { types: CacheActionTypes, actions: CacheActions } = restuxCacheActionsFactory<any, any>(
    conf.resourceName,
  )
  const { actions: UiActions, types: UiActionTypes } = uiActionsFactory(conf)

  function isReloadedPaginationRequest(
    request: { page?: RestuxPaginatedRequest; filters?: RestuxFilters; params?: RestuxParams },
    pagination: RestuxPagination,
  ): boolean {
    const returnValue = isEqual(
      {
        page: {
          currentPage: pagination.currentPage,
          pageSize: pagination.pageSize,
        },
        filters: pagination.filters,
        params: pagination.params,
      },
      request,
    )
    return returnValue
  }

  function* requestPageSagaWorker({
    page,
    filters,
    params,
    identifier: actionIdentifier,
  }: ReturnType<typeof UiActions.requestPage>) {
    // if "not not" matching, "oui oui", c'est ca ...
    if (!notMatchingIdentifier(conf.identifier, actionIdentifier)) {
      let loadingState: RestuxLoadingState = RestuxLoadingState.LOADING
      if (conf.paginationSelector) {
        const pagination: RestuxPagination = yield select(conf.paginationSelector)
        if (isReloadedPaginationRequest({ page, filters, params }, pagination)) {
          loadingState = RestuxLoadingState.RELOADING
        }
      } else {
        throw new Error(
          `Missing paginationSelector for resource (${conf.resourceName}) with identifier (${conf.identifier})`,
        )
      }
      yield put(UiActions.setPaginationLoading(loadingState))
      yield put(CacheActions.getPaginatedItems({ page, filters, params }))
    }
  }
  function* requestPageSagaWatcher() {
    yield takeEvery(UiActionTypes.UI_REQUEST_PAGE, requestPageSagaWorker)
  }

  function* setPaginationSagaWorker({
    paginatedList,
  }: ReturnType<typeof CacheActions.storeSetListItems>) {
    let itemIds: number[] = []
    const { paginationSelector } = conf
    if (paginationSelector) {
      const pagination: ReturnType<typeof paginationSelector> = yield select(paginationSelector)
      if (paginatedList.currentPage > pagination.currentPage) {
        itemIds = [...pagination.itemIds]
      }
    } else {
      throw new Error(
        `Missing paginationSelector for resource (${conf.resourceName}) with identifier (${conf.identifier})`,
      )
    }
    // Concaténation des items de list pour conserver les éléments précédents
    itemIds = [...itemIds, ...paginatedList.items.map(({ id }) => id)]
    const paginationPatch: PaginationPatch = {
      itemIds,
      currentPage: paginatedList.currentPage,
      totalItems: paginatedList.itemCount,
      pageSize: paginatedList.limit,
    }
    yield put(UiActions.updatePagination(paginationPatch))
    yield put(UiActions.setPaginationLoading(RestuxLoadingState.IDLE))
  }
  function* setPaginationSagaWatcher() {
    yield takeEvery(CacheActionTypes.STORE_SET_LIST_ITEMS, setPaginationSagaWorker)
  }

  function* useIdSagaWorker({
    inUseId,
    config: { withFetch, params },
    identifier: actionIdentifier,
  }: ReturnType<typeof UiActions.useId>) {
    const taskIdentifier = generateIdentifier(RESTUX_UI_IDENTIFIER.useId)
    if (!notMatchingIdentifier(conf.identifier, actionIdentifier)) {
      if (inUseId && withFetch) {
        yield put(UiActions.setInUseLoading(RestuxLoadingState.LOADING))
        yield put(
          CacheActions.apiGetItemDetails(inUseId, {
            identifier: taskIdentifier,
            params,
          }),
        )
        yield take(
          (action: AnyAction) =>
            action.type === CacheActionTypes.STORE_SET_ITEM_DETAILS &&
            action.identifier === taskIdentifier,
        )
        yield put(UiActions.setInUseLoading(RestuxLoadingState.IDLE))
      }
    }
  }
  function* useIdSagaWatcher() {
    yield takeEvery(UiActionTypes.UI_USE_ID, useIdSagaWorker)
  }

  function* refreshListOnItemActionsSagaWorker({
    refetchList,
    params: actionParams,
  }: WithRefetchListAction) {
    if (refetchList) {
      let filters
      let params
      if (conf.paginationSelector) {
        const pagination: RestuxPagination = yield select(conf.paginationSelector)
        filters = pagination.filters
        params = { ...pagination.params, ...actionParams }
      } else {
        throw new Error(
          `Missing paginationSelector for resource (${conf.resourceName}) with identifier (${conf.identifier})`,
        )
      }
      yield put(UiActions.requestPage({ filters, params }))
    }
  }
  function* refreshListOnItemActionsSagaWatcher() {
    // Attention! beaucoup de refresh inutiles sont potentiellement déclenchés ici.
    // Il faudrait plutôt faire un équivalent avec un takeLatest, mais avoir en plus un discriminant par liste UI ...
    // cf issue : https://gitlab.com/follow-health/fw_front_react/issues/1341
    yield takeEvery(
      [
        CacheActionTypes.STORE_SET_LIST_ITEMS,
        CacheActionTypes.STORE_SET_ITEM_DETAILS,
        CacheActionTypes.STORE_DELETE_ITEM,
      ],
      refreshListOnItemActionsSagaWorker,
    )
  }

  function* clearCacheSagaWorker({ clearCache }: ReturnType<typeof UiActions.clearPagination>) {
    if (clearCache) {
      yield put(CacheActions.storeClearCache())
    }
  }
  function* clearCacheSagaWatcher() {
    yield takeEvery(UiActionTypes.UI_PAGINATION_CLEAR, clearCacheSagaWorker)
  }

  return {
    clearCacheSagaWatcher,
    refreshListOnItemActionsSagaWatcher,
    requestPageSagaWatcher,
    setPaginationSagaWatcher,
    useIdSagaWatcher,
  }
}
