import { formatISO } from 'date-fns'
import {
  MetricsParams,
  MetricsParamsApi,
  MetricsSendingInfosApi,
  MetricsTypeNameApi,
} from './metrics.model'
import { MetricsSendingInfos, MetricsTypeName } from '../../../../model/Metrics'
import { ApiResponse } from 'apisauce'

export const formatTypesOfMetrics = (types: MetricsTypeName): MetricsTypeNameApi => {
  switch (types) {
    case MetricsTypeName.messageSent:
      return MetricsTypeNameApi.message_sent

    case MetricsTypeName.messageSentToDoctor:
      return MetricsTypeNameApi.message_sent_to_doctor

    case MetricsTypeName.messageSentToPatient:
      return MetricsTypeNameApi.message_sent_to_patient

    case MetricsTypeName.dmpDocumentSentToPatient:
      return MetricsTypeNameApi.dmp_document_sent_to_patient
  }
}

export const formatGetMetricsParams = (params: MetricsParams): MetricsParamsApi => ({
  start: formatISO(params.start),
  end: formatISO(params.end),
  types: params.types && formatTypesOfMetrics(params.types),
})

export const MetricsResponseMapper = (data: MetricsSendingInfosApi): MetricsSendingInfos => ({
  messageSent: data.message_sent,
  messageSentToDoctor: data.message_sent_to_doctor,
  messageSentToPatient: data.message_sent_to_patient,
  dmpDocumentSentToPatient: data.dmp_document_sent_to_patient,
})

export const deserializeMetricsResponse = ({
  data,
  ...res
}: ApiResponse<MetricsSendingInfosApi>): ApiResponse<MetricsSendingInfos> =>
  !res.ok || !data
    ? { ...res }
    : {
        ...res,
        data: MetricsResponseMapper(data),
      }
