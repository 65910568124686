export const mailErrorsTitle = {
  genericTitle: `Erreur`,
  errorWhileSending: `Erreur lors de l'envoi du message`,
  errorWhileDeleting: `Erreur lors de la suppression du message`,
  connexionError: `Erreur de connexion`,
  errorMail: 'Mail',
  errorPj: 'Pièce jointe',
  checkPatientId: "Vérifiez l'identité du patient",
}

export const mailErrorsMessage = {
  genericMessage: `Une erreur est survenue lors de l'envoi du message. Veuillez réessayer ultérieurement.`,
  sessionExpired: 'Désolé, votre session a expiré. Veuillez vous reconnecter pour continuer.',
  checkCredentials: 'Veuillez vérifier vos identifiants.',
  errorWhileFetchingAllMail: `Une erreur est survenue lors de la récupération des messages. Veuillez réessayer ultérieurement.`,
  errorWhileFetchingMail: 'Une erreur est survenue lors de la récupération du mail',
  errorWhileSendingMail: "Une erreur est survenue lors de l'envoi du mail",
  errorWhileFetchingAttachment: 'Erreur de récupération de la pièce jointe',
  classificationError: 'Erreur lors de la classification du message',
  patientRefusedMss: `Ce patient refuse d'être contacté ou que ses informations soient échangées via la messagerie de santé sécurisée.`,
  invalidInsi: `Vous ne pouvez pas envoyer un message à propos d'un patient qui n'a pas été vérifié par l'INSI`,
  invalidAttachment: 'Pièce jointe invalide',
  forbidden: `Vous n'avez pas les droits nécessaires pour cette action.`,
}
