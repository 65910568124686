import { DAY_PARITIES, FollowTypes } from '@follow/cdk'

export type PosologySummary = FollowTypes.Posology.PosologySummary
export type PosologyQuantity = FollowTypes.Posology.PosologyQuantity
export type PosologyUnit = FollowTypes.Posology.PosologyUnit
export type DayParity = FollowTypes.Posology.DayParity
export type Posology = FollowTypes.Posology.Posology
export type PosologyDosages = FollowTypes.Posology.PosologyDosages

export { DAY_PARITIES }

export interface PosologyFormResource {
  id?: string
  units: PosologyUnit[]
  administrationRoute?: string
  intakeDurationType?: string
  intakeDurationValue?: number
  startDate: Date | null
  endDate: Date | null
  dosages: PosologyDosages[]
  previewId: string
}

export interface PosologyPreviewEntry {
  id: string
  summary: string
}

export interface PosologyPreview {
  summaries: PosologySummary[]
  posologyIntervals: PosologyPreviewEntry[]
}

export const dayParitiesLabel: { [key in DayParity]: string } = {
  odd: 'Jours impairs',
  even: 'Jours pairs',
}
