import { MedicalEvent } from '../../../../model/MedicalEvent'
import { medicalEventDomainActions } from '../../../../store/domain/medicalEvents'
import { RestuxLoadingState } from '../../../../store/restux/ui'
import { AlertBannerItemProps } from '../../../shared'

export interface MedicalEventNavBarOwnProps {}

export interface MedicalEventNavBarStoreProps {
  medicalEvent: MedicalEvent | undefined
  medicalEventLoadingState: RestuxLoadingState
  isOwner: boolean
}

export interface MedicalEventNavBarDispatchProps {
  lockMedicalEvent: typeof medicalEventDomainActions.lockMedicalEvent
}

export type MedicalEventNavBarProps = MedicalEventNavBarOwnProps &
  MedicalEventNavBarStoreProps &
  MedicalEventNavBarDispatchProps

export const READONLY_MEDICAL_EVENT_ALERTS: AlertBannerItemProps[] = [
  {
    code: 'alert_medical_event_only_read',
    title: 'Évènement en consultation seule',
    text: "Merci de contacter le médecin propriétaire de l'information pour le modifier.",
    color: 'blue',
    icon: 'infoCircle',
  },
]

export const lockedMedicalEventAlerts = (isOwner: boolean): AlertBannerItemProps[] => [
  {
    code: 'alert_medical_event_lock',
    title: 'Évènement verrouillé',
    text: isOwner
      ? 'Vous avez verrouillé cet évènement médical'
      : 'Le médecin propriétaire a verrouillé cet évènement médical',
    color: 'gray',
    icon: 'lock',
  },
]
