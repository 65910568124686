import { toUpperSnakeCase } from '../restux.utilities'
import {
  RestuxUpdatePaginationAction,
  PaginationPatch,
  RestuxUiSagaConf,
  RestuxRequestPageAction,
  RestuxSetLoadingStateAction,
  RestuxInUseAction,
  RestuxClearPaginationAction,
  RestuxLoadingState,
} from './restuxUi.model'
import {
  RestuxPaginatedRequest,
  RestuxFilters,
  RestuxParams,
  IdentityIdentifier,
} from '../restux.model'
import { DEFAULT_RESTUX_PAGINATED_REQUEST } from '../cache/restuxCache.model'

export function uiActionsFactory({ resourceName, identifier }: RestuxUiSagaConf) {
  const RESOURCE_PREFIX = `@${toUpperSnakeCase(resourceName)}`
  const types = {
    UI_SET_PAGINATION_LOADING: `${RESOURCE_PREFIX}/UI_SET_PAGINATION_LOADING`,
    UI_SET_IN_USE_LOADING: `${RESOURCE_PREFIX}/UI_SET_IN_USE_LOADING`,
    UI_REQUEST_PAGE: `${RESOURCE_PREFIX}/UI_REQUEST_PAGE`,
    UI_PAGINATION_UPDATE: `${RESOURCE_PREFIX}/UI_UPDATE_PAGINATION`,
    UI_USE_ID: `${RESOURCE_PREFIX}/UI_USE_ID`,
    UI_PAGINATION_CLEAR: `${RESOURCE_PREFIX}/UI_PAGINATION_CLEAR`,
  }

  const actions = {
    setInUseLoading: (loadingState: RestuxLoadingState): RestuxSetLoadingStateAction => ({
      type: types.UI_SET_IN_USE_LOADING,
      loadingState,
      identifier,
    }),
    setPaginationLoading: (loadingState: RestuxLoadingState): RestuxSetLoadingStateAction => ({
      type: types.UI_SET_PAGINATION_LOADING,
      loadingState,
      identifier,
    }),
    requestPage: ({
      page = DEFAULT_RESTUX_PAGINATED_REQUEST,
      filters = {},
      params = {},
    }: {
      page?: RestuxPaginatedRequest
      filters?: RestuxFilters
      params?: RestuxParams
    } = {}): RestuxRequestPageAction => ({
      type: types.UI_REQUEST_PAGE,
      identifier,
      filters,
      page,
      params,
    }),
    updatePagination: (paginationPatch: PaginationPatch): RestuxUpdatePaginationAction => ({
      type: types.UI_PAGINATION_UPDATE,
      identifier,
      paginationPatch,
    }),
    useId: (
      id: IdentityIdentifier | null,
      config: { withFetch: boolean; params?: RestuxParams } = {
        withFetch: true,
      },
    ): RestuxInUseAction => ({
      type: types.UI_USE_ID,
      inUseId: id,
      config,
      identifier,
    }),
    clearPagination: (clearCache = false): RestuxClearPaginationAction => ({
      type: types.UI_PAGINATION_CLEAR,
      identifier,
      clearCache,
    }),
  }

  return { types, actions }
}
