import React, { FC, useMemo, useRef } from 'react'
import { isDefined } from '../../../../misc/functions.utilities'
import { ActionModalLayout, Modal } from '../Modal'
import { LegalDocumentModalProps } from './LegalDocumentModal.model'
import { RoundedButton, IconButton } from '../../../shared'
import { useReadLegalDocument } from '../../../../hooks/queries/legalDocument'
import styles from './LegalDocumentModal.module.scss'
import { useOnScreen } from '../../../../hooks/utils'

const parser = new DOMParser()

export const LegalDocumentModal: FC<LegalDocumentModalProps> = ({
  isUnmounting,
  onClose,
  legalDocument,
}) => {
  const { mutate: readLegalDocument } = useReadLegalDocument()

  const handleAccept = () => {
    onClose()
    if (legalDocument) {
      readLegalDocument(legalDocument?.id)
    }
  }

  const htmlParsed = useMemo(
    () =>
      parser
        .parseFromString(
          legalDocument ? legalDocument.content : 'problème de récupération',
          'text/html',
        )
        .body.innerHTML.replace(/(?:\r\n|\r|\n)/g, '<br />'),
    [legalDocument],
  )

  const bottomRef = useRef<HTMLDivElement>(null)

  const bottomOutOfScreen = !useOnScreen(bottomRef?.current ? bottomRef : null)

  const scrollToBottom = () => {
    bottomRef?.current?.scrollIntoView({ behavior: 'smooth' })
  }

  return isDefined(legalDocument) ? (
    <Modal isUnmounting={isUnmounting}>
      <ActionModalLayout title={legalDocument?.title ?? 'Document à lire'}>
        <div className={styles.container}>
          {bottomOutOfScreen && (
            <div className={styles.scrollTopButton}>
              <IconButton
                onClick={scrollToBottom}
                icon="arrow"
                theme="primary"
                title="Remonter"
                testId="button-scroll-top"
                rotate={180}
              />
            </div>
          )}
          <div
            className={styles.containerHtml}
            dangerouslySetInnerHTML={{
              __html: htmlParsed,
            }}
          />
          <div className={styles.validationButton} ref={bottomRef}>
            <RoundedButton onClick={handleAccept} label="Accepter" theme="primary" />
          </div>
        </div>
      </ActionModalLayout>
    </Modal>
  ) : null
}
