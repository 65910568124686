import { QuestionType } from './model/Questionnaire'

export const ItemTypes = {
  FIELD: 'field',
  SURVEY_ITEM: 'surveyitem',
}

export const defaultAnswerTitle = 'Réponse sans titre'

export const defaultQuestionLabels = {
  [QuestionType.Text]: 'Nouvelle question (Texte libre)',
  [QuestionType.Slider]: 'Nouvelle question (Slider)',
  [QuestionType.YesNo]: 'Nouvelle question (Oui / Non)',
  [QuestionType.QCU]: 'Nouvelle question (QCU)',
  [QuestionType.QCM]: 'Nouvelle question (QCM)',
  [QuestionType.Date]: 'Nouvelle question (Date)',
}

export const defaultOptionLabel = 'Nouvelle Option'
export const defaultYesLabel = 'Oui'
export const defaultNoLabel = 'Non'

export const searchLimit = 10
export const autocompletionPageLimit = 50
export const singleItemPageLimit = 40
export const largePageLimit = 100
export const timeBeforeStartingResearch = 200
export const startingPage = 1

export const LEAVING_WARNING_MESSAGE =
  'En poursuivant pour perdrez toute les modifications non sauvegardées. Êtes vous sûr(e) de vouloir quitter la page?'

export enum VisibilityType {
  ALL = 'Tous',
  PUBLIC = 'Publics',
  PRIVATE = 'Privés',
}

export const NewVersionLabel = 'NEW_VERSION_FOUND'

export const mediumTransitionDuration = 500
