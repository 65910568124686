import { createReducer } from 'redux-starter-kit'

import { BottomPanelState, BottomPanelActionTypes } from './bottomPanel.model'
import { setBottomPanelOptions, displayBottomPanel, hideBottomPanel } from './bottomPanel.actions'

const INITIAL_STATE: BottomPanelState = {
  title: '',
  open: false,
  componentType: undefined,
  maximized: false,
  withOverlay: true,
  displayCloseButton: true,
}

const ACTION_HANDLERS = {
  [BottomPanelActionTypes.SET_BOTTOM_PANEL_OPTIONS]: (
    state: BottomPanelState = INITIAL_STATE,
    { options }: ReturnType<typeof setBottomPanelOptions>,
  ) => ({
    ...state,
    ...options,
  }),
  [BottomPanelActionTypes.BOTTOM_PANEL_DISPLAY]: (
    state: BottomPanelState = INITIAL_STATE,
    { options }: ReturnType<typeof displayBottomPanel>,
  ) => ({
    ...state,
    open: true,
    ...options,
  }),
  [BottomPanelActionTypes.BOTTOM_PANEL_HIDE]: (
    state: BottomPanelState = INITIAL_STATE,
    action: ReturnType<typeof hideBottomPanel>,
  ) => ({
    ...state,
    displayCloseButton: true,
    open: false,
    componentType: undefined,
  }),
  [BottomPanelActionTypes.BOTTOM_PANEL_TOGGLE_EXPAND]: (
    state: BottomPanelState = INITIAL_STATE,
  ) => ({ ...state, maximized: !state.maximized }),
}

export const bottomPanelReducer = createReducer(INITIAL_STATE, ACTION_HANDLERS)
