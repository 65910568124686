import { createReducer } from 'redux-starter-kit'
import { domainAssigningAuthorithiesActions } from './assigningAuthorities.actions'
import {
  DomainAssigningAuthoritiesTypes,
  AssigningAuthoritiesState,
} from './assigningAuthorities.model'

const ASSIGNING_AUTHORITIES_INITIAL_STATE: AssigningAuthoritiesState = {
  assigningAuthorities: [],
}

const ACTION_HANDLERS = {
  [DomainAssigningAuthoritiesTypes.SET_ASSIGNING_AUTHORITIES]: (
    state: AssigningAuthoritiesState = ASSIGNING_AUTHORITIES_INITIAL_STATE,
    {
      assigningAuthorities,
    }: ReturnType<typeof domainAssigningAuthorithiesActions.setAssigningAuthorithies>,
  ) => ({
    ...state,
    assigningAuthorities,
  }),
}

export const assigningAuthoritiesReducer = createReducer<AssigningAuthoritiesState, any>(
  ASSIGNING_AUTHORITIES_INITIAL_STATE,
  ACTION_HANDLERS,
)
