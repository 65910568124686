import getApi from '../../api'
import { PaginatedList } from '../../model/Pagination'
import { deserializePatientInformationsPaginatedResponse } from './mapper/patientInformations.mapper'
import { SerializedPatientInformations } from './mapper/patientInformations.model'

export const getPatientInformations = (patientId: number) =>
  getApi()
    .get<PaginatedList<SerializedPatientInformations>>(
      `/patients/${patientId}/patient_informations`,
    )
    .then(deserializePatientInformationsPaginatedResponse)
