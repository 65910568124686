import { FC, Suspense, lazy } from 'react'
import { FullLoader } from '../../components/shared'

const LazyAdminRouter = lazy(() =>
  import(/* webpackChunkName: "admin" */ './AdminRouter.component').then(({ AdminRouter }) => ({
    default: AdminRouter,
  })),
)

const LazyLoadedAdminRouter: FC = (props) => (
  <Suspense fallback={<FullLoader />}>
    <LazyAdminRouter {...props} />
  </Suspense>
)
export { LazyLoadedAdminRouter as AdminRouter }
