import { RootState } from '../../reducers/index'
import { ImpersonatePermissions } from '../../../model/User'
import { hasAdminRole, hasExtDsmRole } from '../../../misc/roles.utilities'
import { loggedUserSelector } from '../../domain/me'
import { Roles } from '../../../model/Roles'

export const usurpedSelector = (state: RootState) => state.ui.adminUsers.usurpedUser || null

export const isAdminSelector = (state: RootState): boolean => {
  const currentUser = state.domain.me.user
  if (!currentUser) {
    return false
  }
  return hasAdminRole(currentUser.roles)
}

export const isExtDsmSelector = (state: RootState): boolean => {
  const currentUser = state.domain.me.user
  if (!currentUser) {
    return false
  }
  return hasExtDsmRole(currentUser.roles)
}

export const loggedUserImpersonatePermissions = (
  state: RootState,
): ImpersonatePermissions | null => {
  const loggedUser = loggedUserSelector(state)
  const usurpedUser = usurpedSelector(state)
  if (loggedUser?.mainRole === Roles.ASSISTANT && usurpedUser?.mainRole === Roles.DOCTOR) {
    const currentPermissions = loggedUser.impersonatePermissions.find(
      ({ doctorId }) => doctorId === usurpedUser.internalId,
    )
    return currentPermissions ?? null
  }
  return null
}
