import { FC } from 'react'
import { LoadingPageProps } from './LoadingPage.model'
import { NavBar } from '../../../layout'
import { FullLoader } from '../FullLoader'

export const LoadingPage: FC<LoadingPageProps> = ({ title = 'Chargement...', backButtonPath }) => {
  return (
    <>
      <NavBar backButtonPath={backButtonPath}>{title}</NavBar>
      <FullLoader />
    </>
  )
}
