import { parseISO } from 'date-fns'
import { defaultYesLabel, defaultNoLabel } from '../../../../../constants'
import { formatFr } from '../../../../../misc/date.utilities'
import { ImportantInformation } from '../../../../../model/ImportantInformations'
import { QuestionType } from '../../../../../model/Questionnaire'

export type PatientImportantInformationsItemProps = ImportantInformation

export function formatImportantInformationsLabel({
  questionType: fieldType,
  value,
}: PatientImportantInformationsItemProps) {
  if (fieldType === QuestionType.YesNo) {
    const valueStr = (value + '').toLowerCase()
    if (valueStr === '0' || valueStr === 'no' || valueStr === 'non') {
      // On n'est pas trop confiant sur les données legacy présentes en BDD
      return defaultNoLabel
    }
    if (valueStr === '1' || valueStr === 'yes' || valueStr === 'oui') {
      // On n'est pas trop confiant sur les données legacy présentes en BDD
      return defaultYesLabel
    }
  } else if (fieldType === QuestionType.Date) {
    return formatFr(parseISO(value), 'dd/MM/yyyy')
  }
  return value
}
