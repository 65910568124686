import React, { FunctionComponent, useContext, useEffect, useMemo } from 'react'
import { MailNavbar } from '../../components/layout'
import { MailList } from '../../components/mail'
import { FullLoader, ErrorPandaMessage } from '../../components/shared'
import { MailLayoutProps } from './MailLayout.model'
import { Outlet, useNavigate } from 'react-router-dom'
import { ConnectedUserContext } from '../../misc/auth.utilities'
import { isDoctor } from '../../misc/user.utilities'
import styles from './MailLayout.module.scss'

const MailLayout: FunctionComponent<MailLayoutProps> = ({
  mailAddressList,
  getMailAddressList,
  inUseMailAddress,
  setInUseMailAddress,
  getMailList,
  mailList,
  clearMailList,
  requestMailsStatus,
  fetchingNextMailsStatus,
  enabledFeatures,
  openMailEditor,
  setSearch,
  search,
  reloadMailList,
  disconnect,
}) => {
  const navigate = useNavigate()
  const { currentUser } = useContext(ConnectedUserContext)

  useEffect(() => {
    if (!inUseMailAddress && !enabledFeatures?.mssSmtp) {
      getMailAddressList()
    }
  }, [getMailAddressList, inUseMailAddress, enabledFeatures])

  const mailAddressOptions = useMemo(() => {
    if (enabledFeatures?.mssSmtp && isDoctor(currentUser) && currentUser.mailizEmailAddress) {
      return [{ value: currentUser.mailizEmailAddress, label: currentUser.mailizEmailAddress }]
    }

    return mailAddressList.map((value) => ({ value, label: value }))
  }, [currentUser, enabledFeatures?.mssSmtp, mailAddressList])

  useEffect(() => {
    if (inUseMailAddress || enabledFeatures?.mssSmtp) {
      clearMailList()
      getMailList()
    }
  }, [inUseMailAddress, clearMailList, getMailList, enabledFeatures])

  useEffect(() => {
    if (!enabledFeatures?.mail) {
      navigate('/patients')
    }
  }, [enabledFeatures?.mail, navigate])

  return (
    <div className={styles.mailLayout}>
      <div className={styles.navbarContainer}>
        <MailNavbar
          mailAddressOptions={mailAddressOptions}
          inUseMailAddress={
            enabledFeatures?.mssSmtp && isDoctor(currentUser) && currentUser?.mailizEmailAddress
              ? currentUser.mailizEmailAddress
              : inUseMailAddress
          }
          setInUseMailAddress={setInUseMailAddress}
          disconnect={!enabledFeatures?.mssSmtp ? disconnect : undefined}
        />
      </div>

      <div className={styles.mailContainer}>
        <div className={styles.mailList}>
          <MailList
            mailList={mailList}
            requestMailsStatus={requestMailsStatus}
            fetchNextItemsStatus={fetchingNextMailsStatus}
            getMailList={getMailList}
            enabledFeatures={enabledFeatures}
            openMailEditor={openMailEditor}
            setSearch={setSearch}
            search={search}
            reloadMailList={reloadMailList}
          />
        </div>
        <div className={styles.mailDetail}>
          {(requestMailsStatus === 'pending' || requestMailsStatus === 'idle') && (
            <div className={styles.loading}>
              <FullLoader message="Chargement de votre messagerie sécurisée..." />
            </div>
          )}
          {requestMailsStatus === 'error' && (
            <div className={styles.mailError}>
              <ErrorPandaMessage
                title="Une erreur est survenue"
                subTitle="Nous n'avons pas pu charger votre messagerie sécurisée. Veuillez réessayer ultérieurement."
                contactSupport
                backToHomePage
                className={styles.errorContainer}
              />
            </div>
          )}

          {requestMailsStatus === 'success' && <Outlet />}
        </div>
      </div>
    </div>
  )
}

export default MailLayout
