import { Mail } from '../model/Mail'
import { formatFr } from './date.utilities'

export const formatReply = (mail: Mail) =>
  `<p>
<div>___________________________________________</div>
</br>
</br>
<div>De: ${mail.nameFrom} &lt;${mail.emailFrom}&gt;</div>
</br>
<div>À: "&lt;${mail.emailTo}&gt;"</div>
</br>
<div>Envoyé: ${formatFr(new Date(mail.date.date), 'dd/MM/yyyy')} </div>
</br>
<div>Objet: ${mail.subject}</div>
</br>
<div>${mail.body}</div>
</p>`
    .replace(/\n+/g, '')
    .trim()
