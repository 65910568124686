import { ApiResponse } from 'apisauce'
import { ResourceHistoryApi } from './resourceHistory.model'
import { ResourceHistoryDetails } from '../../../../model/ResourceHistory'
import { parseISO } from 'date-fns'
import { PaginatedList } from '../../../../model/Pagination'

export const deserializeResourceHistory = ({
  date,
  ...resource
}: ResourceHistoryApi): ResourceHistoryDetails => ({
  ...resource,
  date: parseISO(date),
})

export const deserializeResourceHistoryResponse = (
  response: ApiResponse<PaginatedList<ResourceHistoryApi>>,
): ApiResponse<PaginatedList<ResourceHistoryDetails>> => {
  const { data, ...responseRest } = response
  if (!response.ok || !data) {
    return { ...responseRest }
  }
  return {
    ...responseRest,
    data: {
      ...data,
      items: data.items.map(deserializeResourceHistory),
    },
  }
}
