export function invokeAfter<Input extends any[], Output>(
  task: (...input: Input) => Output,
  after: () => void,
) {
  return (...parameters: Parameters<typeof task>): ReturnType<typeof task> => {
    const returnValue = task(...parameters)
    after()
    return returnValue
  }
}
