import { useFormikContext } from 'formik'
import { useCallback, useContext } from 'react'
import { PatientFormFields } from './PatientForm.model'
import { hasSuperAdminRole } from '../../../misc/roles.utilities'
import { ConnectedUserContext } from '../../../misc/auth.utilities'

export const useResetINSIStatusHook = () => {
  const formikContext = useFormikContext<PatientFormFields>()
  const { loggedUser } = useContext(ConnectedUserContext)
  return useCallback(() => {
    if (loggedUser && hasSuperAdminRole(loggedUser?.roles)) {
      formikContext.setFieldValue('identityStatus.insiStatus.checked', 'UNCHECKED')
      formikContext.setFieldValue('identityStatus.insiStatus.checkedAt', null)
      formikContext.setFieldValue('inseeNumber', '')
    }
  }, [formikContext, loggedUser])
}
