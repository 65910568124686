import TablePlugin from '@tiptap/extension-table'
import './Table.scss'

export const Table = TablePlugin.extend({
  addKeyboardShortcuts() {
    return {
      Tab: () => {
        return this.editor.commands.goToNextCell()
      },
      'Shift-Tab': () => {
        return this.editor.commands.goToPreviousCell()
      },
      'Mod-Shift-F': () => {
        // Log pour le debug
        console.log('Fixing tables')
        return this.editor.commands.fixTables()
      },
    }
  },
})
