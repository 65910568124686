import { Contact, ContactOrganization } from '../../../model/Contact'
import { BottomPanelContactsContext } from '../../../store/ui/bottomPanelContacts'
import { Profession, Speciality } from '../../../model/Professions'
import { BottomPanelActionsProps } from '../../../model/BottomPanel'

export interface ContactFormFields {
  title: string
  firstName: string
  familyName: string
  profession: Profession | null
  speciality: Speciality | null
  organizations: ContactOrganization[]
  ppIdentifier: string
  notes: string
}

export interface ContactFormProps extends BottomPanelActionsProps {
  readOnly: boolean
  contact?: Contact
  onCancel: () => void
  onConfirm: (updates: Partial<Contact>) => void
  onCreatePrivateCopy: (contact: Partial<Contact>) => void
  context: BottomPanelContactsContext
}

export const getDefaultValues = (): ContactFormFields => ({
  title: '',
  firstName: '',
  familyName: '',
  profession: null,
  speciality: null,
  notes: '',
  organizations: [
    {
      id: '',
      technicalId: '',
      name: '',
      emailAddress: '',
      phoneNumber: '',
      private: true,
      address: {
        street2: null,
        street: '',
        zipCode: '',
        city: '',
        country: 'France',
      },
    },
  ],
  ppIdentifier: '',
})

export function getInitialValues(contact: Contact): ContactFormFields {
  return {
    title: contact.title || '',
    firstName: contact.firstName || '',
    familyName: contact.familyName || '',
    profession: contact.profession || null,
    speciality: contact.speciality || null,
    ppIdentifier: contact.ppIdentifier || '',
    notes: contact.notes || '',
    organizations: contact.organizations.length
      ? contact.organizations.map(sanitizeOrganizations)
      : [
          {
            id: '',
            technicalId: '',
            name: '',
            private: true,
            emailAddress: '',
            phoneNumber: '',
            address: {
              street2: null,
              street: '',
              zipCode: '',
              city: '',
              country: '',
            },
          },
        ],
  }
}

const sanitizeOrganizations = ({
  name,
  emailAddress,
  phoneNumber,
  ...organization
}: ContactOrganization) => ({
  ...organization,
  name: name ?? '',
  emailAddress: emailAddress ?? '',
  phoneNumber: phoneNumber ?? '',
})
