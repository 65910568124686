import { FunctionComponent, useMemo, Fragment } from 'react'
import { getLatestHealthDatas } from '../../../../misc/healthData.utilities'
import { PatientGynecologyDataProps } from './PatientGynecologyData.model'
import { PatientInfosContainer } from '../PatientInfosContainer'
import { PatientHealthDataItem } from '../PatientHealthData/PatientHealthDataItem'
import styles from './PatientGynecologyData.module.scss'
import { isDefined } from '../../../../misc/functions.utilities'
import { HealthDataCode, PregnancyType } from '../../../../model/HealthData'

const conditionalDisplayMapping = {
  [HealthDataCode.twinPregnancy]: [
    { type: HealthDataCode.pregnancyType, value: { value: PregnancyType.GEMELLAIRE } },
  ],
  [HealthDataCode.breastfeedingStart]: [
    { type: HealthDataCode.isBreastfeeding, value: { value: true } },
  ],
}

export const PatientGynecologyData: FunctionComponent<PatientGynecologyDataProps> = ({
  list,
  types,
}) => {
  const filteredList = useMemo(() => {
    const sortedHealthDatas = getLatestHealthDatas(list).sort((left, right) => {
      const leftWeight = types.find((type) => left.moduleDataType.id === type.id)?.weight ?? 0
      const rightWeight = types.find((type) => right.moduleDataType.id === type.id)?.weight ?? 0
      return leftWeight - rightWeight
    })

    const healthDataValues = {}
    sortedHealthDatas.forEach(({ moduleDataType, value }) => {
      healthDataValues[moduleDataType.code] = value
    })

    // Remove data if it has conditions for display which are not true
    return sortedHealthDatas.filter((currentItem) => {
      return (
        !conditionalDisplayMapping[currentItem.moduleDataType.code] ||
        conditionalDisplayMapping[currentItem.moduleDataType.code].every(
          ({ type, value }) => healthDataValues[type]?.value === value.value,
        )
      )
    })
  }, [list, types])

  return (
    <PatientInfosContainer>
      {filteredList.map((currentItem, index) => {
        const previousItem = filteredList[index - 1]
        const displayDivider = isDefined(previousItem) && previousItem.module !== currentItem.module

        return currentItem?.value?.value ? (
          <Fragment key={currentItem.moduleDataType.code}>
            {displayDivider && <div className={styles.separator} />}
            <PatientHealthDataItem {...currentItem} />
          </Fragment>
        ) : null
      })}
    </PatientInfosContainer>
  )
}
