import { createReducer } from 'redux-starter-kit'
import { insiDomainActions } from './insi.actions'
import { InsiDomainState, InsiDomainActionType } from './insi.model'
import { insiPatientSanityCheck } from '../../../misc/insi.utilities'

const DEFAULT_STATE: InsiDomainState = {
  patient: null,
  status: null,
}

const ACTION_HANDLERS = {
  [InsiDomainActionType.SET_REQUEST]: (
    state = DEFAULT_STATE,
    { request, patient }: ReturnType<typeof insiDomainActions.setRequest>,
  ): InsiDomainState => ({
    ...state,
    request: request ? btoa(JSON.stringify(request)) : undefined,
    mailPatient: patient,
  }),
  [InsiDomainActionType.SET_PATIENT_RESPONSE]: (
    state = DEFAULT_STATE,
    { patient }: ReturnType<typeof insiDomainActions.setPatientResponse>,
  ): InsiDomainState => {
    const isPatientValid = patient && insiPatientSanityCheck(patient).length === 0
    return {
      ...state,
      patient: patient && isPatientValid ? patient : null,
      status: patient && isPatientValid ? 'FOUND' : 'NOT_FOUND',
    }
  },
  [InsiDomainActionType.CLEAR_PATIENT_RESPONSE]: (state = DEFAULT_STATE) => ({
    ...state,
    status: null,
    patient: null,
  }),
}

export const insiDomainReducer = createReducer<InsiDomainState>(DEFAULT_STATE, ACTION_HANDLERS)
