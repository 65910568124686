import { useCallback } from 'react'
import { EntityCard, FormHeading, SelectSearch } from '../../../shared'
import { ApiOption, OrderedProps, SelectorProperty, SelectorsProps } from './Selectors.model'
import styles from './Selectors.module.scss'
import { SelectOption } from '../../../../model/SelectOption'
import {
  getActivityPhases,
  getPhaseRateGrids,
  getRateGridAssociationsAndModifiers,
} from '../QuoteLineForm.component'

export const emptyOption: SelectOption<string> = { label: '', value: '' }

const optionToReset = (optionToUpdate: SelectorProperty, currentOption: SelectorProperty) =>
  OrderedProps[currentOption] > OrderedProps[optionToUpdate]

const mapToOption = (item?: ApiOption): SelectOption<string> =>
  item
    ? {
        label: item.libelle,
        value: item.code,
      }
    : emptyOption

export const Selectors = ({ uiQuoteLine, activities, options, onSelect }: SelectorsProps) => {
  const getOptionToDisplay = useCallback(
    (
      selectedProperty: SelectorProperty,
      selectorProperty: SelectorProperty,
      option: SelectOption<string>,
      options: ApiOption[],
    ) => {
      if (selectedProperty === selectorProperty) {
        return option
      } else {
        return optionToReset(selectedProperty, selectorProperty)
          ? mapToOption(options[0])
          : uiQuoteLine[selectorProperty]
      }
    },
    [uiQuoteLine],
  )

  const handleChange = useCallback(
    (selectedProperty: SelectorProperty) => (selectedOption: SelectOption<string> | undefined) => {
      if (!selectedOption) return
      const activity =
        selectedProperty === SelectorProperty.Activity ? selectedOption : uiQuoteLine.activity

      const activityPhases = getActivityPhases(activities, activity)
      const phase = getOptionToDisplay(
        selectedProperty,
        SelectorProperty.Phase,
        selectedOption,
        activityPhases,
      )

      const rateGrids = getPhaseRateGrids(activityPhases, phase)
      const rateGrid = getOptionToDisplay(
        selectedProperty,
        SelectorProperty.RateGrid,
        selectedOption,
        rateGrids,
      )

      const { associations } = getRateGridAssociationsAndModifiers(rateGrids, rateGrid)
      const association = getOptionToDisplay(
        selectedProperty,
        SelectorProperty.Association,
        selectedOption,
        associations,
      )

      const modifiers =
        selectedProperty === SelectorProperty.Association ? uiQuoteLine.modifiers : []

      onSelect({
        activity,
        phase,
        rateGrid,
        association,
        modifiers,
        additionalFee: uiQuoteLine.additionalFee,
      })
    },
    [
      uiQuoteLine.activity,
      uiQuoteLine.modifiers,
      uiQuoteLine.additionalFee,
      activities,
      getOptionToDisplay,
      onSelect,
    ],
  )

  return (
    <EntityCard loading={false} inactive={false}>
      <div className={styles.cardContent}>
        <div className={styles.selectContainer}>
          <FormHeading headings={[{ label: 'Activité', size: 6 }]} />
          <SelectSearch
            value={uiQuoteLine.activity}
            options={options.activities}
            onSelect={handleChange(SelectorProperty.Activity)}
            clearable={false}
            testId="ccam-form-select_activity"
          />
        </div>
        <div className={styles.selectContainer}>
          <FormHeading headings={[{ label: 'Phase', size: 6 }]} />
          <SelectSearch
            value={uiQuoteLine.phase}
            options={options.phases}
            onSelect={handleChange(SelectorProperty.Phase)}
            clearable={false}
            testId="ccam-form-select_phase"
          />
        </div>
        <div className={styles.selectContainer}>
          <FormHeading headings={[{ label: 'Grille Tarifaire', size: 6 }]} />
          <SelectSearch
            value={uiQuoteLine.rateGrid}
            options={options.rateGrids}
            onSelect={handleChange(SelectorProperty.RateGrid)}
            clearable={false}
            testId="ccam-form-select_grid"
          />
        </div>
        <div className={styles.selectContainer}>
          <FormHeading headings={[{ label: 'Association', size: 6 }]} />
          <SelectSearch
            value={uiQuoteLine.association}
            placeholder="Code association"
            options={options.associations}
            onSelect={handleChange(SelectorProperty.Association)}
            clearable={false}
            testId="ccam-form-select_association"
          />
        </div>
      </div>
    </EntityCard>
  )
}
