import { capitalize } from 'lodash'
import React, { FunctionComponent } from 'react'
import { PosologyUnit } from '../../../../../model/Posology'
import { HorizontalScroller, EntityCard, Chip, FormHeading } from '../../../../shared'
import { DipensingBlockProps } from './DispensingBlock.model'

export const DispensingBlock: FunctionComponent<DipensingBlockProps> = ({
  drugs,
  posologyForm,
  disabled,
  availableAdministrationRoutes,
  setPosologyForm,
}) => {
  const setUnit = (unit: PosologyUnit) => {
    setPosologyForm({
      ...posologyForm,
      units: [...posologyForm.units.filter((_unit) => _unit.id !== unit.id), unit],
    })
  }

  const setAdministrationRoute = (value: string) => {
    setPosologyForm({ ...posologyForm, administrationRoute: value })
  }

  return (
    <EntityCard loading={false} inactive={false}>
      <div className="p-5 w-full">
        <FormHeading headings={[{ icon: 'pills', label: 'Administration' }]} />
        {drugs.map((drug) => (
          <div className="mt-4" key={drug.id}>
            <HorizontalScroller
              renderTitle={() => (
                <span className="text-xs text-shades-4 font-medium">
                  Unité {drugs.length > 1 && drug.name}
                </span>
              )}
            >
              <div className="flex flex-row py-2 space-x-2">
                {drug.units.map(({ title, id }) => (
                  <Chip
                    label={capitalize(title)}
                    key={id}
                    selected={
                      posologyForm.units.find((unit) => unit.id === drug.id)?.value === title
                    }
                    onClick={() => setUnit({ id: drug.id, type: drug.type, value: title })}
                    renderLabel={(label) => <span className="whitespace-nowrap">{label}</span>}
                    disabled={disabled}
                    testId={`posology-dispensing-unit-${id}`}
                  />
                ))}
              </div>
            </HorizontalScroller>
          </div>
        ))}
        <div className="mt-4">
          <HorizontalScroller
            renderTitle={() => <span className="text-xs text-shades-4 font-medium">Voie</span>}
          >
            <div className="flex flex-row py-2 space-x-2">
              {availableAdministrationRoutes.map(({ title, id }) => (
                <Chip
                  colorPreset="dark"
                  label={capitalize(title)}
                  key={id}
                  onClick={() => setAdministrationRoute(title)}
                  selected={posologyForm.administrationRoute === title}
                  renderLabel={(label) => <span className="whitespace-nowrap">{label}</span>}
                  disabled={disabled}
                  testId={`posology-dispensing-administration-${id}`}
                />
              ))}
            </div>
          </HorizontalScroller>
        </div>
      </div>
    </EntityCard>
  )
}
