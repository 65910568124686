import React, { FunctionComponent, useLayoutEffect, useRef } from 'react'

import { ChipProps } from './Chip.model'

import { StyledChip, StyledChipList } from './Chip.styled'
import { ChipLabel } from '../ChipLabel'
import { GridLayout } from '../../layout'
import { Icon } from '../../Icon'
import { TooltipWrapper } from '../../tooltip'

export const ChipList: FunctionComponent = StyledChipList

export const Chip: FunctionComponent<ChipProps> = ({
  label,
  title,
  colorPreset = 'dark',
  selected,
  disabled = false,
  onClick,
  renderLabel,
  size = 'normal',
  dotted = false,
  scrollIntoViewOnSelect = false,
  borderColor,
  borderSize = 2,
  sublabel,
  testId,
  additionalInfos,
}) => {
  const componentRef = useRef<HTMLDivElement>(null)
  useLayoutEffect(() => {
    if (scrollIntoViewOnSelect && selected && componentRef.current) {
      componentRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [scrollIntoViewOnSelect, selected])

  return (
    <StyledChip
      ref={componentRef}
      onClick={disabled ? undefined : onClick}
      selected={selected}
      borderColor={borderColor}
      borderSize={borderSize}
      dotted={dotted}
      size={size}
      colorPreset={colorPreset}
      selectable={!!onClick}
      disabled={disabled}
      data-test-id={testId}
    >
      <GridLayout
        columnsTemplate="auto"
        flow="column"
        alignColumns="center"
        gap="extraSmall"
        height="100%"
        shouldShrinkOnMobile={false}
      >
        {renderLabel && renderLabel(label || '')}
        {!renderLabel && <ChipLabel sublabel={sublabel} label={label} title={title} />}
        {additionalInfos && (
          <TooltipWrapper content={additionalInfos}>
            <Icon icon="infoCircle" size="micro" />
          </TooltipWrapper>
        )}
      </GridLayout>
    </StyledChip>
  )
}
