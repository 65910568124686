export enum DocumentCategoryKeys {
  ConsultationNote = 'consultation_note',
  DischargeSummaryNote = 'discharge_summary_note',
  PrivacyPolicyAcknowledgment = 'privacy_policy_acknowledgment',
  SurgicalOperationNote = 'surgical_operation_note',
  ReferralNote = 'referral_note',
  Certificate = 'certificate',
  Quotation = 'quotation',
  Checkup = 'checkup',
  Other = 'other',
  PdfForm = 'pdf_form',
  Survey = 'survey',
  ReceivedDocument = 'received_document',
  FacilityDischargeSummaryNote = 'facility_discharge_summary_note',
  MedicalSummary = 'medical_summary',
  PrescriptionForFootCare = 'prescription_for_foot_care',
  BizonePrescriptionForMedication = 'bizone_prescription_for_medication',
  PrescriptionForMedication = 'prescription_for_medication',
  PrescriptionForInfirmaryAct = 'prescription_for_infirmary_act',
  PrescriptionForMedicalBiology = 'prescription_for_medical_biology',
  PrescriptionForOrthoptics = 'prescription_for_orthoptics',
  PrescriptionForPhysicalTherapy = 'prescription_for_physical_therapy',
  PrescriptionForSpeechTherapy = 'prescription_for_speech_therapy',
  RequestForTelehealth = 'request_for_telehealth',
}
