import { Action } from 'redux'
import {
  RestuxPaginatedRequest,
  RestuxFilters,
  RestuxParams,
  IdentityIdentifier,
} from '../restux.model'
import { RootState } from '../../reducers/index'

export enum RestuxLoadingState {
  LOADING = 'LOADING',
  RELOADING = 'RELOADING',
  IDLE = 'IDLE',
}

export enum RESTUX_UI_IDENTIFIER {
  useId = 'useId',
}

export interface RestuxPagination extends RestuxPaginatedRequest {
  itemIds: number[]
  totalItems: number
  selectedItemIds?: number[]
  loadingState: RestuxLoadingState
  params?: RestuxParams
  filters?: RestuxFilters
}

export interface RestuxInUse {
  inUseId: IdentityIdentifier | null
  loadingState: RestuxLoadingState
}

export interface PageList<T> extends Omit<RestuxPagination, 'itemIds'> {
  items: T[]
  totalPages: number
}

interface RestuxIdentifiedAction extends Action {
  identifier?: string
}

export interface RestuxInUseAction extends RestuxIdentifiedAction {
  inUseId: IdentityIdentifier | null
  config: { withFetch: boolean; params?: RestuxParams }
}

export interface RestuxRequestPageAction extends RestuxIdentifiedAction {
  page: RestuxPaginatedRequest
  filters?: RestuxFilters
  params?: RestuxParams
}

export interface RestuxSetLoadingStateAction extends RestuxIdentifiedAction {
  loadingState: RestuxLoadingState
}
export interface RestuxUpdatePaginationAction extends RestuxIdentifiedAction {
  paginationPatch: PaginationPatch
}

export interface RestuxClearPaginationAction extends RestuxIdentifiedAction {
  clearCache: boolean
}
export type PaginationPatch = Partial<RestuxPagination>

export interface RestuxUiSagaConf {
  resourceName: string
  identifier?: string
  paginationSelector?: (state: RootState) => RestuxPagination
  inUseSelector?: (state: RootState) => RestuxInUse
}
