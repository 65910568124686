import {
  ApiMailClassifyAttachmentResource,
  ApiMailClassifyCdaAttachmentResource,
} from './mail.model'
import { MailClassificationAttachment, MailClassificationCdaAttachment } from '../../../model/Mail'

export const mapSelectedAttachmentsToApiMailClassifyAttachmentResources = (
  attachments: MailClassificationAttachment[],
): ApiMailClassifyAttachmentResource[] =>
  attachments.map(
    (attachment: MailClassificationAttachment): ApiMailClassifyAttachmentResource =>
      mapSelectedAttachmentToApiMailClassifyAttachmentResource(attachment),
  )

export const mapSelectedAttachmentToApiMailClassifyAttachmentResource = (
  selectedAttachment: MailClassificationAttachment,
): ApiMailClassifyAttachmentResource => ({
  id: selectedAttachment.id,
  visibleName: `${selectedAttachment.classifyVisibleName}.${selectedAttachment.extension}`,
  cdaContent: selectedAttachment.cdaContent?.map(
    (
      selectedCdaAttachment: MailClassificationCdaAttachment,
    ): ApiMailClassifyCdaAttachmentResource => ({
      id: selectedCdaAttachment.id,
      visibleName: `${selectedCdaAttachment.classifyVisibleName}.${selectedCdaAttachment.extension}`,
    }),
  ),
})
