import { RootState } from '../../reducers'
import { restuxResolvePage } from '../../restux/ui/restuxUi.selectors'
import { createSelector } from 'reselect'

const paginationSelector = (state: RootState) => state.ui.mailContacts

export const mailContactsPaginationSelector = createSelector(
  [paginationSelector],
  (service) => service.pagination,
)

const pageSelector = (state: RootState) => state.cache.mailContacts.list

export const mailContactsPageSelector = createSelector(
  [mailContactsPaginationSelector, pageSelector],
  (pagination, resource) => restuxResolvePage(resource, pagination),
)
