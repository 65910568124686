import { restuxDocumentInstanceApiConfig } from './api'
import { restuxCacheFactory } from '../../restux/cache/index'
import { DocumentInstance, DocumentInstanceListItem } from '../../../model/DocumentInstance'
import { RestuxResources } from '../../resources'

const { actions, reducers, sagas } = restuxCacheFactory<DocumentInstanceListItem, DocumentInstance>(
  {
    apiConfig: restuxDocumentInstanceApiConfig,
    refetchList: false,
    resourceName: RestuxResources.documentInstance,
  },
)

export const documentInstancesActions = actions
export const documentInstancesReducers = reducers
export const documentInstancesSagas = sagas
