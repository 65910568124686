import {
  DocumentAlert,
  DocumentAlertsFilters,
  DocumentAlertsSeverityLabel,
  DocumentAlertsTypes,
} from '../../../model/DocumentAlerts'
import { DocumentVidalInfos } from '../../../model/DocumentInstance'
import { DocumentAlertsDomainActionTypes } from './documentAlerts.model'

export const documentAlertsDomainActions = {
  getAlerts: (filters?: DocumentAlertsFilters) => ({
    type: DocumentAlertsDomainActionTypes.GET,
    filters,
  }),
  setAlerts: (alerts: DocumentAlert[]) => ({
    type: DocumentAlertsDomainActionTypes.SET,
    alerts,
  }),
  clearAlerts: () => ({
    type: DocumentAlertsDomainActionTypes.CLEAR,
  }),
  setAvailableFilters: (availableFilters: DocumentAlertsFilters | null) => ({
    type: DocumentAlertsDomainActionTypes.SET_FILTERS,
    availableFilters,
  }),
  setVidalInfos: (vidalInfos: DocumentVidalInfos) => ({
    type: DocumentAlertsDomainActionTypes.SET_VIDAL_INFOS,
    vidalInfos,
  }),
  setSeverityLabels: (severityLabels: DocumentAlertsSeverityLabel[]) => ({
    type: DocumentAlertsDomainActionTypes.SET_SEVERITY_LABELS,
    severityLabels,
  }),
  getTypes: () => ({
    type: DocumentAlertsDomainActionTypes.GET_TYPES,
  }),
  setTypes: (types: DocumentAlertsTypes) => ({
    type: DocumentAlertsDomainActionTypes.SET_TYPES,
    types,
  }),
}
