import { connect } from 'react-redux'

import { RootState } from '../../../../store'
import { QuestionnaireInstance } from './QuestionnaireInstance.component'
import {
  QuestionnaireInstanceStoreProps,
  QuestionnaireInstanceDispatchProps,
} from './QuestionnaireInstance.model'

import { nextQuestionnaireIdSelector } from '../../../../store/ui/medicalEvents/index'
import { medicalEventContentUiActions } from '../../../../store/ui/medicalEvents/medicalEventContent/index'
import { medicalEventDomainActions } from '../../../../store/domain/medicalEvents/index'
import { bottomPanelQuestionsActions } from '../../../../store/ui/bottomPanelQuestions'

const mapStateToProps = (state: RootState): QuestionnaireInstanceStoreProps => ({
  nextQuestionnaireId: nextQuestionnaireIdSelector(state),
})

const mapDispatchToProps: QuestionnaireInstanceDispatchProps = {
  selectMedicalEventContent: medicalEventContentUiActions.selectMedicalEventContent,
  saveAnswerValue: medicalEventDomainActions.setAnswerValue,
  onEditQuestion: bottomPanelQuestionsActions.editQuestionFromTemplate,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)(QuestionnaireInstance)
export { withConnect as QuestionnaireInstance }
