import { PasswordUpdate, User } from '../../../model/User'
import { MeUpdate, MeActionTypes } from './me.model'
import { Preferences } from '../../../model/Preference'
import { MailContact } from '../../../model/Contact'

export const getMe = () => ({ type: MeActionTypes.GET_ME })

export const setMe = (me: User | null) => ({ type: MeActionTypes.SET_ME, me })

export const updateMe = (me: MeUpdate) => ({
  type: MeActionTypes.UPDATE_ME,
  me,
})

export const updatePassword = (updatedPassword: PasswordUpdate) => ({
  type: MeActionTypes.UPDATE_PASSWORD,
  updatedPassword,
})

export const updatePreferences = (updatedPreferences: Partial<Preferences>) => ({
  type: MeActionTypes.UPDATE_PREFERENCES,
  updatedPreferences,
})

export const updatedTrustedContact = (updatedTrustedContact: MailContact[]) => ({
  type: MeActionTypes.UPDATE_TRUSTED_CONTACT,
  updatedTrustedContact,
})

export const fetchAllTeams = () => ({
  type: MeActionTypes.FETCH_ALL_TEAMS,
})
