import { Contact } from '../../../model/Contact'
import { RootState } from '../../reducers'
import { PageList } from '../../restux/ui'
import { undefinedItemInCacheGuard } from '../../restux/ui/restuxUi.selectors'

export function contactsPaginationSelector(state: RootState) {
  return state.ui.contacts.pagination
}

export function contactsPageSelector(state: RootState): PageList<Contact> {
  const contactsResources = state.cache.contacts.list
  const pagination = state.ui.contacts.pagination

  // Pas possible d'utiliser la fonction "restuxResolvePage" en raison de l'union type de "IContact"
  const { itemIds, ...toKeep } = pagination
  const totalPages = Math.ceil(pagination.totalItems / pagination.pageSize)
  const resourceItems = itemIds.map((id) => contactsResources[id]).filter(undefinedItemInCacheGuard)
  return { ...toKeep, totalPages, items: resourceItems }
}
