import React, { FC } from 'react'
import { PARENT_NODE_ID, IMAGE_NODE_ID } from '../ImageResize.model'
import { ResizeHandleProps } from './ResizeHandle.model'
import classNames from 'classnames/bind'
import styles from './ResizeHandle.module.scss'

const cx = classNames.bind(styles)

export const ResizeHandle: FC<ResizeHandleProps> = ({ orientation, onDragEnd }) => {
  const handleMouseDown = (mouseDownEvent: React.MouseEvent<HTMLImageElement>) => {
    const parent = (mouseDownEvent.target as HTMLElement).closest(`#${PARENT_NODE_ID}`)
    const image = parent?.querySelector(`img#${IMAGE_NODE_ID}`) ?? null
    if (image === null) return
    const startWidth = image.clientWidth
    const startPosition = mouseDownEvent.pageX

    function onMouseMove(mouseMoveEvent: MouseEvent) {
      if (orientation === 'right') {
        const finalWidth = startWidth - startPosition + mouseMoveEvent.pageX
        onDragEnd(finalWidth)
      } else {
        const finalWidth = startWidth + startPosition - mouseMoveEvent.pageX
        onDragEnd(finalWidth)
      }
    }
    function onMouseUp() {
      document.body.removeEventListener('mousemove', onMouseMove)
    }

    document.body.addEventListener('mousemove', onMouseMove)
    document.body.addEventListener('mouseup', onMouseUp, { once: true })
  }

  return <div onMouseDown={handleMouseDown} className={cx('resize-handle', styles[orientation])} />
}
