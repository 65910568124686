import { connect } from 'react-redux'
import { RootState } from '../../../store'
import { drugSortingPreferencesSelector } from '../../../store/domain/me'
import { bottomPanelDrugsActions } from '../../../store/ui/bottomPanelDrugs'
import {
  bottomPanelDrugsDisabledSelector,
  bottomPanelDrugsInitialSearchSelector,
  bottomPanelDrugsMultiSelectSelector,
} from '../../../store/ui/bottomPanelDrugs/bottomPanelDrugs.selectors'

import { SearchDrug } from './SearchDrug.component'
import { SearchDrugDispatchProps, SearchDrugStateProps } from './SearchDrug.model'

export const mapStateToProps = (state: RootState): SearchDrugStateProps => ({
  drugSortingPreference: drugSortingPreferencesSelector(state),
  disabled: bottomPanelDrugsDisabledSelector(state),
  initialSearch: bottomPanelDrugsInitialSearchSelector(state),
  isMultiSelect: bottomPanelDrugsMultiSelectSelector(state),
})

export const mapDisptachToProps: SearchDrugDispatchProps = {
  selectDrug: bottomPanelDrugsActions.selectDrug,
}

export default connect(mapStateToProps, mapDisptachToProps)(SearchDrug)
