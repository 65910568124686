import getApi from '../../../../api'
import { RestuxApiConfig } from '../../../restux/cache/restuxCache.model'
import { CustomFile, FileType } from '../../../../model/File'
import { PaginatedList } from '../../../../model/Pagination'
import { ApiResponse } from 'apisauce'

export const restuxFilesApiConfig: RestuxApiConfig<CustomFile, CustomFile> = {
  url: '/files',
  getPaginatedItems: (route) => getApi().get<PaginatedList<CustomFile>>(route),
  getItem: (route) => getApi().get<CustomFile>(route),
  createItem: (route, item) => getApi().post<CustomFile>(route, item),
  updateItem: (route, item) => getApi().put<CustomFile>(route, item),
  deleteItem: (route) => getApi().delete<void>(route),
}

const resourceIdNames = {
  [FileType.MEDICAL_EVENT]: 'medicalEventId',
  [FileType.SIGNATURE]: 'userId',
}

export const fetchFile = (fileId: number) => getApi().get<CustomFile>(`/files/${fileId}`)

export const fileToBlob = (file: CustomFile) => {
  if (file.base64) {
    return fetch(file.base64)
      .then((res) => res.blob())
      .then((blob) => new File([blob], file.visibleName))
  }
}

export const uploadFile = (
  file: File,
  type: FileType,
  resourceId?: number,
): Promise<ApiResponse<CustomFile>> => {
  const data = new FormData()

  data.append('file', file)
  data.append('type', `${type}`)

  // https://gitlab.com/follow-health/fw_api_back/issues/151
  const resourceIdName = resourceIdNames[type]
  if (resourceIdName && resourceId) {
    data.append(resourceIdName, `${resourceId}`)
  } else {
    console.warn(`No ressource id specified for file upload type ${type}`)
  }

  return getApi().post<CustomFile>(restuxFilesApiConfig.url, data)
}
