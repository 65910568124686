import React, { FunctionComponent, useCallback, useState } from 'react'
import classNames from 'classnames/bind'
import Ink from 'react-ink'
import { Link } from 'react-router-dom'

import { Colors } from '../../../../colors'
import { BaseIcon } from '../../Icon/BaseIcon'
import { IconsType } from '../../Icon/Icon.library'
import {
  createMatchDevice,
  MatchDeviceProps,
  onlyDesktopConfig,
} from '../../../../misc/MatchDevice'
import styles from './IconButton.module.scss'
import { IconButtonSize, IconButtonProps } from './IconButton.model'
import { BaseButton } from '../BaseButton'
import BaseButtonThemes from '../../../../design-system/themes/button.theme'
import { Theme as ButtonThemes } from '../../../../design-system/themes/button.model'
import { isDefined } from '../../../../misc/functions.utilities'
import { DropdownContainer, DropdownItem } from '../../dropdown'
import { BASE_BUTTON_SIZES } from '../BaseButton/BaseButton.model'

const cx = classNames.bind(styles)

const IconButton: FunctionComponent<IconButtonProps & MatchDeviceProps> = ({
  icon,
  onClick,
  onMouseDown,
  onMouseEnter,
  onMouseOver,
  onMouseLeave,
  link,
  size = 'normal' as IconButtonSize,
  theme = 'dark' as ButtonThemes,
  type = 'button',
  rotate = 0,
  title = '',
  testId = `icon-button-${icon}`,
  matchDevice = false,
  noBorder = false,
  borderSize = 1,
  elevation,
  disabled = false,
  options,
  showOptionsCaret = false,
  appearance,
}) => {
  const [showDropdown, setShowDropdown] = useState(false)
  const selectedTheme = disabled ? BaseButtonThemes[theme].disabled : BaseButtonThemes[theme].active
  const buttonClasses = cx(styles.button, {
    [`elevation${elevation}`]: true,
    noBorder,
  })

  const displayOptions = isDefined(options) && options.length > 0
  const dropdownIconSize = showOptionsCaret ? 10 : 0
  const toggleDropdown = useCallback(() => {
    setShowDropdown((value) => !value)
  }, [])
  const handleClick = displayOptions ? toggleDropdown : onClick

  return link ? (
    <Link className={buttonClasses} to={link} title={title} data-test-id={testId}>
      {buttonContent({ icon, size, theme, rotate, ink: matchDevice, disabled })}
    </Link>
  ) : (
    <div className="relative">
      <BaseButton
        onClick={!disabled ? handleClick : undefined}
        onMouseDown={!disabled ? onMouseDown : undefined}
        onMouseEnter={onMouseEnter}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        type={type}
        title={title}
        data-test-id={testId}
        colors={selectedTheme.colors}
        borderColors={selectedTheme.borderColors}
        sizes={{
          height: `${BASE_BUTTON_SIZES[size]}px`,
          width: `${BASE_BUTTON_SIZES[size] + dropdownIconSize * 2}px`,
          padding: 0,
        }}
        borderSize={borderSize}
        borderRadius={`${BASE_BUTTON_SIZES[size] / 2}px`}
        noBorder={noBorder}
        disabled={disabled}
        appearance={appearance}
      >
        {buttonContent({ icon, size, theme, rotate, ink: matchDevice, disabled })}
        {showOptionsCaret && (
          <div className="ml-2">
            <BaseIcon
              sizeInPx={dropdownIconSize}
              icon="chevron"
              color={disabled ? Colors.dsPrimaryLight : selectedTheme.colors.default}
            />
          </div>
        )}
      </BaseButton>
      <div className={`absolute top-[125%] right-0`}>
        <DropdownContainer opened={showDropdown} closeDropdown={toggleDropdown}>
          {options?.map(({ label, onClick }, index) => (
            <DropdownItem key={index} onClick={onClick} layout="thin">
              {label}
            </DropdownItem>
          ))}
        </DropdownContainer>
      </div>
    </div>
  )
}

interface ButtonContentProps {
  icon: IconsType
  theme: ButtonThemes
  size: IconButtonSize
  ink: boolean
  rotate?: number
  disabled: boolean
}

const buttonContent = ({ icon, size, rotate, ink, theme, disabled }: ButtonContentProps) => {
  const selectedTheme = disabled ? BaseButtonThemes[theme].disabled : BaseButtonThemes[theme].active

  return (
    <>
      {ink && !disabled && <Ink />}
      <BaseIcon
        sizeInPx={BASE_BUTTON_SIZES[size] / 2}
        color={disabled ? Colors.dsPrimaryLight : selectedTheme.colors.default}
        icon={icon}
        rotate={rotate}
      />
    </>
  )
}

const withHoc = createMatchDevice(onlyDesktopConfig)(IconButton)
export { withHoc as IconButton }
