import { EditorImageFetcher } from '@/model/Config'
import { Buffer } from 'buffer'

export const defaultImageFetch: EditorImageFetcher = async (url: string): Promise<string> => {
  const response = await fetch(url)

  if (!response.ok) {
    const body = await response.text()
    throw new Error(
      `[ELFE] Unable to fetch image. Status: ${response.status}, URL: ${url}. ${body}`,
    )
  }

  const contentType = response.headers.get('content-type')
  const arrayBuffer = await response.arrayBuffer()
  const base64 = Buffer.from(arrayBuffer).toString('base64')

  return `data:${contentType};base64,${base64}`
}
