import { useCurrentEditor } from '@tiptap/react'
import React, { FC, useContext } from 'react'
import {
  FontGroup,
  MarksGroup,
  AlignmentGroup,
  ListGroup,
  UndoGroup,
  SpecialContentGroup,
  ColorGroup,
  HeightGroup,
  FormatGroup,
} from '@/components/toolbar/groups'
import { ToolbarContainer, ToolbarSeparator } from '@/components/toolbar/atoms'
import { ToolbarProps } from './Toolbar.model'
import styles from './Toolbar.module.scss'
import { EditorConfigContext } from '@/context'
import classNames from 'classnames/bind'

const cx = classNames.bind(styles)

export const Toolbar: FC<ToolbarProps> = ({ toolbarStyle, extraTools: ExtraTools }) => {
  const { editor } = useCurrentEditor()
  const { themes, tools } = useContext(EditorConfigContext)

  return editor ? (
    <div className={cx('toolbar', themes)} style={toolbarStyle}>
      <ToolbarContainer>
        <FontGroup editor={editor} />
        <ToolbarSeparator />
        {tools.includes('marks') && <MarksGroup editor={editor} />}
        <ToolbarSeparator />
        {tools.includes('colors') && <ColorGroup editor={editor} />}
        <ToolbarSeparator />
        {tools.includes('align') && <AlignmentGroup editor={editor} />}
        <ToolbarSeparator />
        {tools.includes('list') && <ListGroup editor={editor} />}
        <ToolbarSeparator />
        {tools.includes('lineHeight') && <HeightGroup editor={editor} />}
        <ToolbarSeparator />
        <FormatGroup editor={editor} />
        <ToolbarSeparator />
        <UndoGroup editor={editor} />
        <ToolbarSeparator />
        <SpecialContentGroup editor={editor} />
        {ExtraTools && <ExtraTools editor={editor} />}
      </ToolbarContainer>
    </div>
  ) : null
}
