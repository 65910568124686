import React, { FunctionComponent, useState } from 'react'
import { AuthService } from '../../../misc/auth.utilities'
import { LogoutPageProps } from './LogoutPage.model'
import { Card } from '../../../components/shared'
import { AUTHENTICATION_ID_MAIL } from '../../../model/Mail'

export const LogoutPage: FunctionComponent<LogoutPageProps> = ({ setUsurpedUser }) => {
  useState(() => {
    AuthService.requestLogout()
    localStorage.removeItem(AUTHENTICATION_ID_MAIL)
    setUsurpedUser()
  })

  return (
    <Card>
      <div className="text-center p-4">Déconnexion en cours</div>
    </Card>
  )
}
