import React, { FunctionComponent } from 'react'
import { parseISO } from 'date-fns'

import { formatFr } from '../../../../misc/date.utilities'

import { PatientImportantInformationsItem } from './PatientImportantInformationsItem'
import { PatientInfosContainer } from '../PatientInfosContainer'

import { PatientImportantInformationsProps } from './PatientImportantInformations.model'

import styles from './PatientImportantInformations.module.scss'

export const PatientImportantInformations: FunctionComponent<PatientImportantInformationsProps> = ({
  list,
}) => (
  <PatientInfosContainer>
    <>
      {list.map((item, index) => (
        <div key={`${item.eventId}--${index}`}>
          <div className={styles.date}>{formatFr(parseISO(item.date), 'dd.MM.yyyy')}</div>
          <>
            {item.importantInformations.map((information) => (
              <PatientImportantInformationsItem key={information.questionId} {...information} />
            ))}
          </>
        </div>
      ))}
    </>
    {list.length === 0 && (
      <p className={styles.text}>
        Ajoutez <strong>une question</strong> en favoris et suivez{' '}
        <strong>les informations importantes</strong> de vos patients
      </p>
    )}
  </PatientInfosContainer>
)
