import { put, takeEvery } from 'redux-saga/effects'
import { BottomPanelComponentType, setBottomPanelOptions } from '../bottomPanel'
import { uiBottomPanelPosologyActionTypes } from './bottomPanelPosology.model'

function* openWorker() {
  yield put(
    setBottomPanelOptions({
      title: 'Saisir manuellement un traitement en cours',
      open: true,
      componentType: BottomPanelComponentType.Posology,
    }),
  )
}

function* openWatcher() {
  yield takeEvery(uiBottomPanelPosologyActionTypes.OPEN, openWorker)
}

export const bottomPanelPosologySagas = {
  openWatcher,
}
