import type { PlaceAutocompleteResult, PlaceDetailsResult } from '@google/maps'
import { useState } from 'react'
import uuidV4 from 'uuid/v4'
import { useDebounce } from 'react-use'
import { searchAddresses, getAddressDetails } from '../../api/Address'
import { Address } from '../../model/Address'

type SelectAddressHandler = (address: Address) => void

interface AutocompleteProps {
  onSelectAddress: (placeId: string) => void
}

function placeAddressDetailsToAddress(addressDetails: PlaceDetailsResult): Address {
  const addressComponents = addressDetails.address_components
  const streetNumber = addressComponents.find(({ types }) => types.includes('street_number'))
  const route = addressComponents.find(({ types }) => types.includes('route'))
  const city = addressComponents.find(({ types }) => types.includes('locality'))
  const postalCode = addressComponents.find(({ types }) => types.includes('postal_code'))
  const country = addressComponents.find(({ types }) => types.includes('country'))

  const street = `${streetNumber ? streetNumber.short_name + ' ' : ''}${
    route ? route.short_name : ''
  }`

  const address = {
    street,
    city: city ? city.short_name : '',
    zipCode: postalCode ? postalCode.short_name : '',
    country: country ? country.long_name : '',
  }

  // Selon la précision de l'input, addressDetails.name peut être le nom du pays, de la ville ou le code postal
  // On s'assure donc que la valeur est différente de tous les champs récupérés
  const street2 = Object.values(address).find((v) => v === addressDetails.name)
    ? ''
    : addressDetails.name

  return { ...address, street2 }
}

// Format de date ISO 3166-1 Alpha-2 (France, Belgique, Suisse, Luxembourg)
const COUNTRY_FILTERS = 'fr,be,ch,lu'

export function useAddressAutocomplete(
  search: string,
  handleSelect: SelectAddressHandler,
  searchDebounceDuration = 300,
): [PlaceAutocompleteResult[], boolean, AutocompleteProps] {
  const [sessionToken, setSessionToken] = useState(uuidV4())
  const [loading, setLoading] = useState(false)
  const [predictions, setPredictions] = useState<PlaceAutocompleteResult[]>([])

  useDebounce(
    async () => {
      if (search) {
        setLoading(true)
        const response = await searchAddresses({ search, sessionToken, countries: COUNTRY_FILTERS })
        setLoading(false)

        if (response.ok && response.data && response.data.status === 'OK') {
          setPredictions(response.data.predictions)
        }
      } else {
        setPredictions([])
      }
    },
    searchDebounceDuration,
    [search],
  )

  async function onSelectAddress(placeId: string) {
    const response = await getAddressDetails(placeId, sessionToken)

    if (response.ok && response.data && response.data.status === 'OK') {
      handleSelect(placeAddressDetailsToAddress(response.data.result))
      setSessionToken(uuidV4())
      setPredictions([])
    }
  }
  return [predictions, loading, { onSelectAddress }]
}
