import { useMutation } from '@tanstack/react-query'
import { EphemeralNotification } from '../../../../model/EphemeralNotification'
import { postEphemeralNotification } from '../../../../data/admin'
import { UserFriendlyError } from '../../../../model/Error'
import { addValid } from '../../../../store/message'
import { useActionDispatch } from '../../../utils'
import { hideBottomPanel } from '../../../../store/ui/bottomPanel'

export const usePostEphemeralNotification = () => {
  const valid = useActionDispatch(addValid)
  const closeBottomPanel = useActionDispatch(hideBottomPanel)

  const queryReturn = useMutation<EphemeralNotification, Error, EphemeralNotification>(
    async (notification) => {
      const { ok, data } = await postEphemeralNotification(notification)
      if (!ok || !data) {
        throw new UserFriendlyError('error', 'La création de notification éphémère a échoué')
      }
      return data
    },
    {
      onSuccess: () => {
        valid('Notification éphémère créée')
        closeBottomPanel()
      },
    },
  )

  return queryReturn
}
