import { JSONToValue, SimpleValueToHtml } from '@follow/farte'
import { AnswerParts } from '../model/AnswerParts'
import React, { FunctionComponent } from 'react'
import { DangerousHtml } from './DangerousHtml.component'

interface ListRendererProps {
  title: string
  items: AnswerParts
}

export const ListRenderer: FunctionComponent<ListRendererProps> = ({ items, title }) => {
  return (
    <>
      <div>{title}</div>
      <ul>
        {items.map((item, index) => {
          return (
            <li key={index}>
              {item.title}
              {' : '}
              {item.type === 'normal' && <DangerousHtml html={item.value} />}
              {item.type === 'farte' && (
                <SimpleValueToHtml value={JSONToValue(item.value)} mode="inline" />
              )}
              {/* Sous questions */}
              {item.subItems && (
                <ul>
                  {item.subItems.map((item, index) => {
                    return (
                      <li key={index}>
                        {item.title}
                        {' : '}
                        {item.type === 'normal' && <DangerousHtml html={item.value} />}
                        {item.type === 'farte' && (
                          <SimpleValueToHtml value={JSONToValue(item.value)} mode="inline" />
                        )}
                      </li>
                    )
                  })}
                </ul>
              )}
            </li>
          )
        })}
      </ul>
    </>
  )
}

ListRenderer.displayName = 'ListRenderer'
