import { DrugTypes } from '../../../model/Drug'
import { PrescriptionVariable } from '../../../model/Prescription'

export interface BottomPanelDrugsState {
  disabled?: boolean
  initialSearch?: {
    type: DrugTypes
    searchId: number
  }
  multiSelect?: boolean
}

const RESOURCE_NAME = '@BOTTOM_PANEL_PRESCRIPTIONS'

export const UiBottomPanelDrugsActionTypes = {
  SEARCH_DRUG: `${RESOURCE_NAME}/SEARCH_DRUG`,
  SELECT_DRUG: `${RESOURCE_NAME}/SELECT_DRUG`,
  CLEAR_SEARCH_STATE: `${RESOURCE_NAME}/CLEAR_SEARCH_STATE`,
}

export type SearchDrugParameters = {
  onDrugSelected: (drug: PrescriptionVariable) => void
  state?: BottomPanelDrugsState
}
