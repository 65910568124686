import { combineReducers } from 'redux'
import { AtcClassificationState, atcClassificationConfig } from './atcClassification.model'
import {
  inUseReducerFactory,
  paginationReducerFactory,
} from '../../restux/ui/restuxUiReducer.factory'

export const atcClassificationReducer = combineReducers<AtcClassificationState>({
  pagination: paginationReducerFactory(atcClassificationConfig),
  inUse: inUseReducerFactory(atcClassificationConfig),
})
