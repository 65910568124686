import { DocumentCategory } from '../../../model/DocumentCategory'
import { RestuxResources } from '../../resources'
import { restuxCacheFactory } from '../../restux/cache'
import { restuxDocumentCategoriesApiConfig } from './api'

const { actions, reducers, sagas } = restuxCacheFactory<
  DocumentCategory,
  DocumentCategory
>({
  apiConfig: restuxDocumentCategoriesApiConfig,
  refetchList: false,
  resourceName: RestuxResources.documentCategories,
})

export const documentCategoriesActions = actions
export const documentCategoriesReducers = reducers
export const documentCategoriesSagas = sagas
