import {
  DocumentTemplateEditorVariant,
  DocumentTemplateEditorVariantType,
} from '../../../../../model/DocumentTemplate'

export function searchParamToDocumentTemplateEditorVariant(
  locationSearch: string,
): DocumentTemplateEditorVariant {
  const searchParams = new URLSearchParams(locationSearch)
  const type = searchParams.get('type')
  if (!type) {
    return { type: DocumentTemplateEditorVariantType.EDITOR }
  }
  const questionnaireIdStr = searchParams.get('questionnaireId')
  if (type === DocumentTemplateEditorVariantType.QUESTIONNAIRE && questionnaireIdStr !== null) {
    return {
      type: DocumentTemplateEditorVariantType.QUESTIONNAIRE,
      questionnaireId: parseInt(questionnaireIdStr, 10),
    }
  }
  const prescriptionIdStr = searchParams.get('prescriptionId')
  const intervalId = searchParams.get('intervalId')
  if (type === DocumentTemplateEditorVariantType.PRESCRIPTION && prescriptionIdStr !== null) {
    return {
      type: DocumentTemplateEditorVariantType.PRESCRIPTION,
      prescriptionId: prescriptionIdStr,
      ...(intervalId && { intervalId }),
    }
  }
  const acteId = searchParams.get('acteId')
  if (type === DocumentTemplateEditorVariantType.ACTE && acteId !== null) {
    return {
      type: DocumentTemplateEditorVariantType.ACTE,
      acteId: acteId,
    }
  }
  return {
    type:
      DocumentTemplateEditorVariantType[type.toUpperCase()] ||
      DocumentTemplateEditorVariantType.EDITOR,
  }
}

export function getDocumentTemplateEditorVariantSearchParams(
  editorVariant: DocumentTemplateEditorVariant,
) {
  const searchParams = new URLSearchParams({ type: editorVariant.type })
  if (editorVariant.type === DocumentTemplateEditorVariantType.QUESTIONNAIRE) {
    searchParams.set('questionnaireId', editorVariant.questionnaireId.toString())
  }
  if (editorVariant.type === DocumentTemplateEditorVariantType.PRESCRIPTION) {
    searchParams.set('prescriptionId', editorVariant.prescriptionId)
    if (editorVariant.intervalId !== undefined) {
      searchParams.set('intervalId', editorVariant.intervalId)
    }
  }
  if (editorVariant.type === DocumentTemplateEditorVariantType.ACTE) {
    searchParams.set('acteId', editorVariant.acteId)
  }
  return { search: searchParams.toString() }
}
