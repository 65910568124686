import React, { FunctionComponent } from 'react'
import { Icon } from '../../shared'
import { UserAvatar } from '../UserAvatar'
import { UserSelectItemProps } from './UserSelectItem.model'
import styles from './UserSelectItem.module.scss'
import { getFullName } from '../../../misc/user.utilities'

export const UserSelectItem: FunctionComponent<UserSelectItemProps> = ({
  user,
  selected = false,
}) => (
  <div className={styles.container}>
    <div className={styles.avatar}>
      <UserAvatar familyName={user.familyName} firstName={user.firstName} size="small" />
    </div>
    <span>{getFullName(user)}</span>
    {selected && (
      <div className={styles.selected}>
        <Icon icon="checkCircle" size="micro" />
      </div>
    )}
  </div>
)
