import { toUpperSnakeCase } from '../../restux/restux.utilities'
import { RestuxResources } from '../../resources'
import { DocumentTemplateSuggestionList } from '../../../model/DocumentTemplate'
import { IdentityIdentifier } from '../../restux/restux.model'

const RESOURCE_PREFIX = `@${toUpperSnakeCase(RestuxResources.documentTemplate)}`

export interface DocumentTemplatesState {
  suggestions: Record<IdentityIdentifier, DocumentTemplateSuggestionList>
}

export const DomainDocumentTemplateActionTypes = {
  DUPLICATE: `${RESOURCE_PREFIX}/DUPLICATE`,
  CLEAR_SUGGESTIONS: `${RESOURCE_PREFIX}/CLEAR_SUGGESTIONS`,
  GET_SUGGESTIONS: `${RESOURCE_PREFIX}/GET_SUGGESTIONS`,
  SET_SUGGESTIONS: `${RESOURCE_PREFIX}/SET_SUGGESTIONS`,
  UPDATE_VALUE: `${RESOURCE_PREFIX}/UPDATE_VALUE`,
}
