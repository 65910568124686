import React, { FunctionComponent } from 'react'
import { library } from './Icon.library'
import styled from '@emotion/styled'

interface Props {
  icon: string
  sizeInPx: number
  active?: boolean
}

const Icon: FunctionComponent<Props> = ({ icon, sizeInPx = 24, active }) => {
  const svgProps = getSvgProps(icon)
  const Svg = styled.svg`
    &:hover {
      & > * {
        fill: #1890ff;
      }
    }
  `
  return (
    <Svg viewBox={svgProps.view} height={`${sizeInPx}px`} width={`${sizeInPx}px`}>
      <g fill={active ? '#1890ff' : '#000'} dangerouslySetInnerHTML={{ __html: svgProps.path }} />
    </Svg>
  )
}

const getSvgProps = (icon: string) => {
  const svg = library[icon]
  return svg ? svg : library.default
}

export default Icon
