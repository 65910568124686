import React, { FunctionComponent, useMemo } from 'react'
import { DEFAULT_FONT_FAMILIES, FontFamily } from '@follow/farte'
import { PreferencesBlockProps } from './PreferencesBlock.model'
import { FormBlock, FormikSelectInput } from '../../../shared'
import { drugSortOptions } from '../../../../model/Drug'
import { isEqual } from 'lodash'
import { computeTimeZoneOptions } from '../../../../misc/date.utilities'

const fontFamiliesOptions = DEFAULT_FONT_FAMILIES.map((fontFamily) => ({
  key: fontFamily,
  value: fontFamily,
  label: fontFamily.replace(/\+/g, ' '),
}))

const fontFamilyToOption = (font: FontFamily) => ({
  value: font,
  label: font,
})

const timezoneOptions = computeTimeZoneOptions()

export const PreferencesBlock: FunctionComponent<PreferencesBlockProps> = ({
  fontFamily,
  drugSorting,
  timeZone,
}) => {
  const currentDrugSorting =
    drugSortOptions.find((option) => isEqual(option.value, drugSorting)) ?? drugSortOptions[0]

  const currentTimezone = useMemo(
    () => timezoneOptions.find(({ value }) => value === timeZone),
    [timeZone],
  )

  return (
    <FormBlock label="Préférences" icon="preferences">
      <FormikSelectInput
        label="Police de caractères"
        value={fontFamilyToOption(fontFamily)}
        options={fontFamiliesOptions}
        fieldName="preferences.editorFontFamily"
      />
      <FormikSelectInput
        label="Tri par défaut de la recherche de médicament"
        value={currentDrugSorting}
        options={drugSortOptions}
        fieldName="preferences.drugSorting"
      />
      <FormikSelectInput
        fieldName="preferences.timeZone"
        label="Fuseau horaire"
        value={currentTimezone}
        options={timezoneOptions}
      />
    </FormBlock>
  )
}
