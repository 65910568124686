import { RestuxResources } from '../../resources'
import { toUpperSnakeCase } from '../../restux/restux.utilities'
import { RestuxPagination, RestuxUiSagaConf, RestuxInUse } from '../../restux/ui/restuxUi.model'
import { uiActionsFactory } from '../../restux/ui/restuxUiActions.factory'
import { atcClassificationPaginationSelector } from './atcClassification.selectors'

export const atcClassificationConfig: RestuxUiSagaConf = {
  resourceName: RestuxResources.atcClassification,
  identifier: 'atcClassification',
  paginationSelector: atcClassificationPaginationSelector,
}

export interface AtcClassificationState {
  pagination: RestuxPagination
  inUse: RestuxInUse
}

const RESOURCE_PREFIX = `@${toUpperSnakeCase(RestuxResources.codedInformations)}`

export const uiActions = uiActionsFactory(atcClassificationConfig)

export const uiAtcClassificationActionTypes = {
  ...uiActions.types,
  GET_CLASS_CHILDREN: `${RESOURCE_PREFIX}/GET_CLASS_CHILDREN`,
}
