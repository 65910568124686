import { ContextualMenuPosition } from 'WithVariableEditor/WithVariableEditor.model'

const OVERFLOW_MARGIN = 10
const SMALL_DEVICE_BREAKPOINT = 500

interface Offsets {
  top: number
  left: number
}

export function calculatePosition(
  element: HTMLDivElement,
  { top, left }: ContextualMenuPosition,
  { top: offsetTop, left: offsetLeft }: Offsets,
) {
  const { height } = element.getBoundingClientRect()
  const windowWidth = window.document.documentElement.clientWidth
  const calculatedTop = top - offsetTop - height + window.pageYOffset

  // Si taille du device trop petit. On affiche le menu au milieu de l'écran
  const calculatedLeft =
    windowWidth > SMALL_DEVICE_BREAKPOINT
      ? left + window.pageXOffset - element.offsetWidth / 2 + offsetLeft / 2
      : windowWidth / 2
  return {
    top: Math.round(calculatedTop),
    left: Math.round(calculatedLeft),
  }
}

export function detectOverflow(element: HTMLDivElement) {
  const windowWidth = window.document.documentElement.clientWidth
  // Si taille du device trop petit. On ne tente pas de detecter l'overflow en raison d'un risque de boucle infini
  if (windowWidth < SMALL_DEVICE_BREAKPOINT) {
    return {
      left: 0,
      right: 0,
    }
  }
  const { x: positionLeft, right: positionRight } = element.getBoundingClientRect()
  const overflowLeft = positionLeft
  const overflowRight = positionRight - windowWidth

  return {
    left: overflowLeft - OVERFLOW_MARGIN < 0 ? Math.abs(overflowLeft) : 0,
    right: overflowRight + OVERFLOW_MARGIN > 0 ? Math.abs(overflowRight) : 0,
  }
}
