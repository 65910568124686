import { useEffect, useState } from 'react'
import { screenWidthLowerThan } from '../../misc/responsive'

export const useWidthObserver = (breakPoint: number) => {
  const [isLowerThanBreakpoint, setIsLowerThanBreakpoint] = useState<boolean>(false)

  useEffect(() => {
    const unsubscribeWidthWatcher = screenWidthLowerThan(breakPoint, (isLower) =>
      setIsLowerThanBreakpoint(isLower),
    )
    return () => {
      unsubscribeWidthWatcher()
    }
  }, [breakPoint])

  return isLowerThanBreakpoint
}
