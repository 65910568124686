import { RootState } from '../../../store/reducers/reducers.model'
import { IndicationSelectDispatchProps, IndicationSelectStoreProps } from './IndicationSelect.model'
import { connect } from 'react-redux'
import { IndicationsSelect } from './IndicationSelect.component'
import { indicationsListSelector } from '../../../store/domain/indications/indications.selectors'
import { indicationsDomainActions } from '../../../store/domain/indications'

const mapStoreToProps = (state: RootState): IndicationSelectStoreProps => ({
  indicationList: indicationsListSelector(state),
})

const mapDispatchToProps: IndicationSelectDispatchProps = {
  searchIndications: indicationsDomainActions.search,
  setIndicationList: indicationsDomainActions.setIndicationsList,
}

const withConnect = connect(mapStoreToProps, mapDispatchToProps)(IndicationsSelect)
export { withConnect as IndicationsSelect }
