import { useMutation, useQueryClient } from '@tanstack/react-query'
import { PatientInformationsPayload, PatientInformations } from '../../../model/PatientInformations'
import { UserFriendlyError } from '../../../model/Error'
import { patientInformationsKeys } from './patientInformations.keys'
import { createPatientInformations } from '../../../data/patientInformations/createPatientInformations'

interface CreatePatientInformationsVariables {
  patientId: number
  patientInformationsPayload: PatientInformationsPayload
}

export const useCreatePatientInformations = (triggerSubscription: boolean = true) => {
  const queryClient = useQueryClient()

  const mutate = useMutation<PatientInformations, Error, CreatePatientInformationsVariables>(
    triggerSubscription ? patientInformationsKeys.mutation : [],
    async ({ patientId, patientInformationsPayload }: CreatePatientInformationsVariables) => {
      const { data, ok } = await createPatientInformations(
        patientId.toString(),
        patientInformationsPayload,
      )

      if (!data || !ok) {
        throw new UserFriendlyError('error', "La création de l'allergie a échoué")
      }

      return data
    },
    {
      onSuccess: (_data, { patientId }) =>
        queryClient.invalidateQueries(patientInformationsKeys.list(patientId)),
    },
  )
  return mutate
}
