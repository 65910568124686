import { FollowTypes } from '@follow/cdk'

export type CodedInformationsType = FollowTypes.CodedInformations.CodedInformationsType
export type CodedInformations = FollowTypes.CodedInformations.CodedInformations

export interface SearchCodedInformationsResponse {
  allergies: CodedInformations[]
  cim10: CodedInformations[]
  indicationGroups: CodedInformations[]
}

export type CodedInformationsGroupResponse = CodedInformations[]

export enum CodedInformationsTypeLabel {
  ALLERGY = 'Allergie',
  MOLECULE = 'Allergie molécule',
  ANTECEDENT = 'Antécédent',
  INDICATION = 'Pathologie',
  CIM10 = 'Antécédent',
}
