import React, { FunctionComponent } from 'react'
import styled from '@emotion/styled'

import { VariableCreatedCallback } from './WithVariableEditor.model'

import DropDown from '../components/Dropdown'

const ToolbarCategory = styled.div`
  display: flex;
`

export const VariableToolbar: FunctionComponent<{
  onCreateVariable: VariableCreatedCallback
  renderContent: (onVariableCreate: VariableCreatedCallback) => React.ReactNode
}> = ({ onCreateVariable, renderContent }) => (
  <ToolbarCategory>
    <DropDown renderTitle={() => <> {`Insérer variable`} </>} scrollable={false}>
      {renderContent(onCreateVariable)}
    </DropDown>
  </ToolbarCategory>
)
