import { ComponentType } from 'react'

export interface BottomPanelOwnProps {
  content: ComponentType<any> | null
  open: boolean
  maximized: boolean
  title: string
  displayCloseButton: boolean
  onRequestClose: () => void
  onRequestExpand: () => void
}

export const BOTTOM_PANEL_CONTENT_ROOT = 'bottomPanelContentRoot'
