import { combineReducers } from 'redux'
import { createReducer } from 'redux-starter-kit'

import { inUseReducerFactory } from '../../../restux/ui/index'
import {
  medicalEventQuestionnairesConfig,
  MedicalEventContentState,
  SelectedContentState,
  MedicalEventContentUiActionTypes,
  SelectedContentType,
} from './medicalEventContent.model'
import { MedicalEventsUiActionTypes } from '../medicalEvents.model'
import { medicalEventContentUiActions } from './medicalEventContent.actions'

const inUse = inUseReducerFactory(medicalEventQuestionnairesConfig)

const SELECTED_CONTENT_INITIAL_STATE: SelectedContentState = {
  filter: { type: SelectedContentType.EDITOR },
}
const SELECTED_CONTENT_ACTION_HANDLERS = {
  [MedicalEventContentUiActionTypes.SELECT_CONTENT]: (
    state: SelectedContentState = SELECTED_CONTENT_INITIAL_STATE,
    { filter }: ReturnType<typeof medicalEventContentUiActions.selectMedicalEventContent>,
  ): SelectedContentState => ({ ...state, filter }),

  [MedicalEventsUiActionTypes.CLEAR_FILTERS]: (
    state: SelectedContentState = SELECTED_CONTENT_INITIAL_STATE,
  ) => {
    return SELECTED_CONTENT_INITIAL_STATE
  },
}

export const medicalEventContentReducer = combineReducers<MedicalEventContentState>({
  inUse,
  selected: createReducer<SelectedContentState, any>(
    SELECTED_CONTENT_INITIAL_STATE,
    SELECTED_CONTENT_ACTION_HANDLERS,
  ),
})
