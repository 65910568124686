import { Contact, PP_IDENTIFIER_TYPE } from '../../../../model/Contact'
import { ApiResponse } from 'apisauce'
import { PaginatedList } from '../../../../model/Pagination'

export function deserializeContactResponse(response: ApiResponse<Contact>): ApiResponse<Contact> {
  const { data, ...responseRest } = response
  // Error case => no deserialization
  if (!response.ok || !data) {
    return { ...responseRest }
  }
  return {
    ...responseRest,
    data: deserializeContact(data),
  }
}

export function deserializeContactPageResponse(response: ApiResponse<PaginatedList<Contact>>) {
  const { data, ...responseRest } = response
  // Error case => no deserialization
  if (!response.ok || !data) {
    return { ...responseRest }
  }

  return {
    ...responseRest,
    data: { ...data, items: deserializeContacts(data.items) },
  }
}

export function deserializeContact(contact: Contact): Contact {
  return {
    ...contact,
  }
}

export function deserializeContacts(contacts: Contact[]): Contact[] {
  return contacts.map(deserializeContact)
}

export function serializeContact(contact: Partial<Contact>) {
  return {
    ...contact,
    ppIdentifier:
      contact.ppIdentifier === '' || contact.ppIdentifier === null ? null : contact.ppIdentifier,
    ppIdentifierType: contact.ppIdentifierType ? contact.ppIdentifierType : PP_IDENTIFIER_TYPE.RPPS,
    organizations: contact.organizations
      ? contact.organizations.map((organization) => ({
          ...organization,
          id: organization.id === '' || !contact.id ? undefined : organization.id,
          technicalId:
            organization.technicalId === '' || !contact.id ? undefined : organization.technicalId,
          private: true,
        }))
      : undefined,
  }
}
