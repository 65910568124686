import { SharingConfigAccessLevel } from '../../../../model/Team'

export interface AccessLevelSelectProps {
  value: SharingConfigAccessLevel
  label: string
  disabled: boolean
  setValue: (value: SharingConfigAccessLevel) => void
}

export const AccessLevelOptionValues = {
  [SharingConfigAccessLevel.nothing]: {
    label: 'Aucun partage',
    value: SharingConfigAccessLevel.nothing,
  },
  [SharingConfigAccessLevel.read]: { label: 'Lecture seule', value: SharingConfigAccessLevel.read },
  [SharingConfigAccessLevel.write]: {
    label: 'Lecture & écriture',
    value: SharingConfigAccessLevel.write,
  },
  [SharingConfigAccessLevel.delete]: {
    label: 'Tous les droits',
    value: SharingConfigAccessLevel.delete,
  },
}

export const defaultSharingConfig = {
  documentTemplate: AccessLevelOptionValues[SharingConfigAccessLevel.nothing],
  questionnaire: AccessLevelOptionValues[SharingConfigAccessLevel.nothing],
  tag: AccessLevelOptionValues[SharingConfigAccessLevel.nothing],
  patient: AccessLevelOptionValues[SharingConfigAccessLevel.nothing],
  medicalEvent: AccessLevelOptionValues[SharingConfigAccessLevel.nothing],
  contact: AccessLevelOptionValues[SharingConfigAccessLevel.nothing],
  documentLayout: AccessLevelOptionValues[SharingConfigAccessLevel.nothing],
}
