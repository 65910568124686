import { uiSagasFactory } from '../../restux/ui/restuxUiSagas.factory'
import { documentContextualMenuQuestionnaireConfig } from './documentContextualMenu.model'
import {
  inUseContextualMenuQuestionnaireIdSelector,
  contextualMenuQuestionnairePaginationSelector,
} from './documentContextualMenu.selectors'

const uiSagas = uiSagasFactory({
  ...documentContextualMenuQuestionnaireConfig,
  inUseSelector: inUseContextualMenuQuestionnaireIdSelector,
  paginationSelector: contextualMenuQuestionnairePaginationSelector,
})

export const documentContextualMenuUiSagas = {
  ...uiSagas,
}
