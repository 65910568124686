import { ApiResponse } from 'apisauce'
import { formatISO } from 'date-fns'
import { deserializePatientHealthData, isDefined } from '@follow/cdk'
import {
  HealthData,
  HealthDataType,
  HealthDataTypeSerialized,
  Module,
} from '../../../model/HealthData'
import { PaginatedList } from '../../../model/Pagination'
import { PatientHealthDataCreateResource } from './patientHealthData.model'

export const serializePatientHealthData = (
  item: Partial<HealthData>,
): PatientHealthDataCreateResource | undefined => {
  if (!isDefined(item.value)) return
  if (item.module === Module.PREGNANCY) {
    const {
      lastMenstrualPeriod,
      pregnancyStart,
      dueDate,
      pathologicalLeaveStart,
      maternityLeaveStart,
      maternityLeaveEnd,
      pregnancyEnd,
      amenorrhea,
      ...restValues
    } = item.value.value
    return {
      value: {
        value: {
          ...restValues,
          lastMenstrualPeriod: lastMenstrualPeriod ? formatISO(lastMenstrualPeriod) : undefined,
          pregnancyStart: pregnancyStart ? formatISO(pregnancyStart) : undefined,
          dueDate: dueDate ? formatISO(dueDate) : undefined,
          pathologicalLeaveStart: pathologicalLeaveStart
            ? formatISO(pathologicalLeaveStart)
            : undefined,
          maternityLeaveStart: maternityLeaveStart ? formatISO(maternityLeaveStart) : undefined,
          maternityLeaveEnd: maternityLeaveEnd ? formatISO(maternityLeaveEnd) : undefined,
          pregnancyEnd: pregnancyEnd ? formatISO(pregnancyEnd) : undefined,
        },
      },
      module: item.module,
      moduleDataTypeId: item.moduleDataType?.id,
    }
  } else if (
    item.module === Module.GYNECOLOGICAL ||
    item.module === Module.GYNECO_OBSTETRIC ||
    item.module === Module.HEALTH_DATA
  ) {
    if (item.value?.value instanceof Date) {
      return {
        value: { value: formatISO(item.value.value) },
        module: item.module,
        moduleDataTypeId: item.moduleDataType?.id,
      }
    }
    return {
      value: item.value,
      module: item.module,
      moduleDataTypeId: item.moduleDataType?.id,
    }
  }
}

export const deserializePatientHealthDataPaginatedResponse = (
  response: ApiResponse<PaginatedList<HealthData>>,
): ApiResponse<PaginatedList<HealthData>> => {
  const { data, ...responseRest } = response
  if (!response.ok || !data) {
    return { ...responseRest }
  }
  return {
    ...responseRest,
    data: { ...data, items: data.items.map(deserializePatientHealthData) },
  }
}

export const deserializeHealthDataType = (
  response: ApiResponse<PaginatedList<HealthDataTypeSerialized>>,
): ApiResponse<PaginatedList<HealthDataType>> => ({
  ...response,
  data: response.data && {
    ...response.data,
    items: response.data?.items.map((healthDataType) => ({
      ...healthDataType,
      validationPattern: healthDataType.validationPattern
        ? new RegExp(healthDataType.validationPattern)
        : null,
    })),
  },
})
