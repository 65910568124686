import { Filters } from '../../../../model/Filters'

const all = ['admin/teams']
const list = (filters?: Filters) => [...all, filters]
const userTeamsList = () => [...all, 'users']
const userTeams = (userId?: string) => [...userTeamsList(), userId]

export const teamKeys = {
  all,
  list,
  userTeamsList,
  userTeams,
}
