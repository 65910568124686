import { FC, useMemo } from 'react'
import {
  DocumentAlertSeverity,
  SEVERITIES,
  SeverityShortNames,
} from '../../../../model/DocumentAlerts'
import { mapSeverityToTagColor } from '../../../document/DocumentAlertsPanel/DocumentAlertListItem/DocumentAlertItem.model'
import { FormikMultiSelectInput, FormBlock } from '../../../shared'
import { InformationAlert } from '../InformationAlert'
import { PrescriptionBlockProps } from './PrescriptionBlock.model'
import { useAlertTypeSelect } from './useAlertTypeSelect.hook'

const severityToOption = (severityItem: DocumentAlertSeverity) => ({
  value: severityItem,
  label: SeverityShortNames[severityItem] ?? '',
  color: mapSeverityToTagColor[severityItem],
})

const severityOptions = SEVERITIES.map(severityToOption)

export const PrescriptionBlock: FC<PrescriptionBlockProps> = ({
  documentAlertsFilters,
  documentAlertTypes,
}) => {
  const severityValue = useMemo(
    () => documentAlertsFilters.severityLevels.map(severityToOption),
    [documentAlertsFilters.severityLevels],
  )

  const [typeValue, typeOptions] = useAlertTypeSelect(
    documentAlertsFilters.types,
    documentAlertTypes?.types ?? null,
  )

  const [samTypeValue, samTypeOptions] = useAlertTypeSelect(
    documentAlertsFilters.samTypes,
    documentAlertTypes?.samTypes ?? null,
  )

  return (
    <FormBlock label="Aide à la prescription" icon="pills">
      <InformationAlert>
        Configurez les filtres par défaut de l'affichage des aides à la prescription. Vous pourrez
        toujours modifier ces valeurs lors de la consultation des résultats.
      </InformationAlert>
      <FormikMultiSelectInput
        label="Sévérités"
        value={severityValue}
        options={severityOptions}
        fieldName="preferences.documentAlertsFilters.severityLevels"
      />
      {typeOptions.length > 0 && (
        <FormikMultiSelectInput
          label="Types"
          value={typeValue}
          options={typeOptions}
          fieldName="preferences.documentAlertsFilters.types"
        />
      )}
      {samTypeOptions.length > 0 && (
        <FormikMultiSelectInput
          label="Systèmes d'aide à la décision par médicaments (SAM)"
          value={samTypeValue}
          options={samTypeOptions}
          fieldName="preferences.documentAlertsFilters.samTypes"
        />
      )}
    </FormBlock>
  )
}
