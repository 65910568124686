import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { getNextPageParam } from '../../../misc/query.utilities'
import { getDocumentLayouts } from '../../../data/documentLayouts'
import { documentLayoutsKeys } from './documentLayouts.keys'
import { Filters } from '../../../model/Filters'
import { useFlatPaginatedData } from '../../utils/query/useFlatPaginatedData.hook'
import { usePaginationState } from '../../utils/query'
import { useCallback, useMemo } from 'react'
import { singleItemPageLimit } from '../../../constants'

interface GetInfiniteDocumentLayoutsParams {
  enabled?: boolean
  filters?: Filters
  limit?: number
}

export const useGetInfiniteDocumentLayouts = ({
  enabled = true,
  filters,
  limit = singleItemPageLimit,
}: GetInfiniteDocumentLayoutsParams = {}) => {
  const queryKey = useMemo(() => documentLayoutsKeys.list(filters), [filters])

  const queryReturn = useInfiniteQuery(
    queryKey,
    async ({ pageParam = 1, signal }) => {
      const { data, ok } = await getDocumentLayouts(pageParam, filters, signal, limit)
      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          `Impossible de récupérer la liste des mises en page`,
          `Une erreur technique s'est produite`,
        )
      }
      return data
    },
    {
      getNextPageParam,
      enabled: enabled,
    },
  )

  const queryClient = useQueryClient()

  const DocumentLayoutList = useFlatPaginatedData(queryReturn.data)
  const paginationState = usePaginationState(queryReturn.data)

  const cancelPendingQuery = useCallback(() => {
    queryClient.cancelQueries({ queryKey })
  }, [queryClient, queryKey])

  return { query: queryReturn, DocumentLayoutList, paginationState, cancelPendingQuery }
}
