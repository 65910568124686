import { RootState } from '../../reducers'

export const bottomPanelPosologySelectedDrugSelector = (state: RootState) =>
  state.ui.bottomPanelPosology.selectedDrug

export const bottomPanelPosologyModeSelector = (state: RootState) =>
  state.ui.bottomPanelPosology.mode

export const bottomPanelPosologyPrescriptionSelector = (state: RootState) =>
  state.ui.bottomPanelPosology.prescription
