import { QuestionnaireComputedValuesUiActionsTypes } from './questionnaireComputedValues.model'
import { IdentityIdentifier } from '../../restux/restux.model'

export const questionnaireComputedValuesUiActions = {
  clearQuestionnaireComputedValues: () => ({
    type: QuestionnaireComputedValuesUiActionsTypes.CLEAR,
  }),
  fetchQuestionnaireComputedValue: (documentId: IdentityIdentifier, questionnaireId: number) => ({
    type: QuestionnaireComputedValuesUiActionsTypes.FETCH,
    documentId,
    questionnaireId,
  }),
  setQuestionnaireComputedValue: (
    documentId: IdentityIdentifier,
    questionnaireId: number,
    computedValue?: number,
  ) => ({
    type: QuestionnaireComputedValuesUiActionsTypes.SET,
    documentId,
    questionnaireId,
    computedValue,
  }),
}
