import { createSelector } from 'reselect'
import { Country } from '../../../model/Contact'
import { RootState } from '../../reducers'
import { PageList } from '../../restux/ui'
import { restuxResolvePage } from '../../restux/ui/restuxUi.selectors'

export const countriesPaginationSelector = (state: RootState) => state.ui.countries.pagination

export const countriesPageSelector = (state: RootState): PageList<Country> => {
  const pagination = countriesPaginationSelector(state)
  const countriesResource = state.cache.countries.list
  return restuxResolvePage(countriesResource, pagination)
}

const inUseId = (state: RootState) => state.ui.countries.inUse.inUseId

export const selectedCountrySelector = createSelector(
  [inUseId, (state) => state],
  (inUseId, state) => {
    if (!inUseId) {
      return undefined
    }
    return state.cache.countries.list[inUseId]
  },
)
