import React, { FunctionComponent, useMemo } from 'react'
import { City } from '../../../model/Contact'
import { SelectOption } from '../../../model/SelectOption'
import { DropdownItem, SelectSearch } from '../../shared'
import { CitiesSearchProps } from './CitiesSearch.model'

export const CitiesSearch: FunctionComponent<CitiesSearchProps> = ({
  search,
  colorPreset = 'light',
  disabled = false,
  cities,
  onSearch,
  onSelect,
}) => {
  const renderCityOption = (
    { value }: SelectOption<City>,
    currentValue: SelectOption<City> | undefined,
  ) => {
    return (
      <DropdownItem selected={value.id === currentValue?.value.id}>
        <div key={value.id} className="flex flex-col">
          <span>{value.name}</span>
          <span className="font-normal text-sm">
            {value.inseeCode} - {value.department}
          </span>
        </div>
      </DropdownItem>
    )
  }

  const citiesSearchOptions = useMemo(
    () =>
      cities.map((city) => {
        return {
          label: city.name,
          value: city,
        }
      }),
    [cities],
  )

  return (
    <SelectSearch
      placeholder="Recherche par ville"
      colorPreset={colorPreset}
      icon="search"
      value={citiesSearchOptions.find((option) => option.label === search)}
      options={citiesSearchOptions}
      onSelect={(selected) => {
        onSelect(selected ? selected?.value : undefined)
      }}
      disabled={disabled}
      textValue={search}
      onTextChange={onSearch}
      renderOption={renderCityOption}
    />
  )
}
