import { Activity } from '../../../../model/Acte'
import { Options, UiQuoteline } from '../QuoteLineForm.model'

export enum SelectorProperty {
  Activity = 'activity',
  Phase = 'phase',
  RateGrid = 'rateGrid',
  Association = 'association',
}

export enum OrderedProps {
  activity,
  phase,
  rateGrid,
  association,
  modifier,
}

export interface ApiOption {
  libelle: string
  code: string
}

export interface SelectorsProps {
  uiQuoteLine: UiQuoteline
  activities: Activity[]
  options: Options
  onSelect: (uiQuoteLine: UiQuoteline | null) => void
}
