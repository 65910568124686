import { FollowTypes } from '../../model'
import { VariableKindPrefix } from '../variables.model'

export function isPrescriptionVariable(variableId: string) {
  return variableId.startsWith(`${VariableKindPrefix.PRESCRIPTION}_`)
}

export const computeDrugVariableData = ({
  posologySummaries,
  note,
  unsubstituableStatement,
  drugs,
}: FollowTypes.Prescription.Prescription) => {
  const dataEntries: string[] = []

  const renderDrugsInfo = drugs
    .map((drug, index) => {
      const posology = posologySummaries.find((summary) => summary.id === drug.id)

      return `${index > 0 ? '\net ' : ''}<b>${drug.summary}</b>${
        unsubstituableStatement ? ` (Non substituable ${unsubstituableStatement})` : ''
      }${posology ? ':\n- ' + posology.value : ''}`
    })
    .join('')

  dataEntries.push(renderDrugsInfo)

  if (note) {
    dataEntries.push(`\n${note}`)
  }

  return dataEntries.join('')
}
