import { FunctionComponent } from 'react'
import { RoundedButton, ListNavBar } from '../../../shared'
import { TaskPageHeaderProps } from './TaskPageHeader.model'
import { addTestIdSuffix } from '../../../../misc/testId.utilities'

export const TaskPageHeader: FunctionComponent<TaskPageHeaderProps> = ({
  itemsCount,
  newTask,
  testId,
}) => {
  return (
    <div className="flex justify-between">
      <ListNavBar itemCount={itemsCount} title="Mes tâches" />
      <RoundedButton
        label="Nouvelle tâche"
        icon="add"
        size="normal"
        theme="primary"
        onClick={newTask}
        disabled={!newTask}
        testId={addTestIdSuffix('new-btn', testId)}
      />
    </div>
  )
}
