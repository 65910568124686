import React, { FC, useEffect, useState } from 'react'
import { ToolbarInputProps } from './ToolbarInput.model'
import { Input } from '@headlessui/react'
import { isDefined } from '@/utils/functions.utils'
import classNames from 'classnames/bind'
import styles from './ToolbarInput.module.scss'
import { ToolbarButton } from '../ToolbarButton'

const cx = classNames.bind(styles)

const handleMinMax = (value: number, min?: number, max?: number) => {
  if (isDefined(min) && value < min) {
    return min
  }
  if (isDefined(max) && value > max) {
    return max
  }

  return value
}

export const ToolbarInput: FC<ToolbarInputProps> = ({ value, name, title, min, max, onChange }) => {
  const [localValue, setLocalValue] = useState(value)

  useEffect(() => {
    setLocalValue(value)
  }, [value])

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const eventValue = event.currentTarget.value

    const parsed = Number.parseInt(eventValue)
    if (Number.isNaN(parsed) && eventValue.length !== 0) return
    setLocalValue(parsed)

    /* Pour permettre la saisie de taille à deux chiffres, dans le cas où on entre zéro ou un digit, on ne commit pas la modification 
        pour éviter un conflit entre la nouvelle valeur passée par le parent et la valeur en cours saisie.
      Dans le cas d'une valeur à un digit, ce sera le handleGenericEvent qui commitera la modification */
    if (eventValue.length < 2) return

    handleChange(parsed)
  }

  const handleChange = (value: number) => {
    const bounded = handleMinMax(value, min, max)
    onChange(bounded)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleGenericEvent(event)
    }
  }

  const handleGenericEvent = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const parsed = Number.parseInt(event.currentTarget.value)
    if (Number.isNaN(parsed)) return
    handleChange(parsed)
  }

  return (
    <div className={cx('container')}>
      <ToolbarButton
        title="Réduire la taille de la police"
        icon="Minus"
        onClick={() => handleChange(value - 1)}
      />
      <Input
        value={localValue}
        name={name}
        title={title}
        onChange={handleInput}
        onKeyDown={handleKeyDown}
        onBlur={handleGenericEvent}
        className={cx('input')}
        type="number"
      />
      <ToolbarButton
        title="Augmenter la taille de la police"
        icon="Plus"
        onClick={() => handleChange(value + 1)}
      />
    </div>
  )
}
