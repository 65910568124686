import { NavBar } from '../../../layout'
import { PatientPageHeader } from '../../../layout/PatientLayout/PatientPageNavBar/PatientPageHeader'
import { getDisplayedName } from '../../../../misc/patient.utilities'
import { PatientNavBarProps } from './PatientTransversalNavigationNavBar.model'

export const PatientTransversalNavigationPageNavBar = ({ patient }: PatientNavBarProps) => {
  const patientFullName = patient ? getDisplayedName(patient) : null

  if (!patient) {
    return <NavBar backButtonPath={null}>Chargement...</NavBar>
  }

  return (
    <NavBar
      backButtonPath={null}
      backButtonTitle={
        patientFullName
          ? `Retour vers la page patient "${patientFullName.firstname} ${patientFullName.lastname}"`
          : undefined
      }
    >
      <PatientPageHeader patient={patient} />
    </NavBar>
  )
}
