import { indicationsDomainActions } from './indications.actions'
import { searchIndications } from './api'
import { call, debounce, put } from '@redux-saga/core/effects'
import { addError } from '../../message'
import { IndicationsDomainActionsTypes } from './indications.model'

function* searchIndicationsWorker({ search }: ReturnType<typeof indicationsDomainActions.search>) {
  const response = yield call(searchIndications, search)
  const { ok, status, data } = response
  if (ok && 200 === status) {
    yield put(indicationsDomainActions.setIndicationsList(data))
  } else {
    yield put(
      addError(
        `Une erreur s'est produite`,
        `Un problème est survenu en recherchant l'indication', veuillez réessayer`,
      ),
    )
  }
}

function* searchIndicationsWatcher() {
  yield debounce(500, IndicationsDomainActionsTypes.SEARCH, searchIndicationsWorker)
}

export const indicationsSagas = {
  searchIndicationsWatcher,
}
