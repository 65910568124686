import { combineReducers } from 'redux'
import { httpReducer as http } from '../http/http.reducer'
import { messageReducer as messages } from '../message/message.reducer'
import { surveyReducer as survey } from '../survey_legacy/survey.reducer'
import { rendererReducer as renderer } from '../renderer/renderer.reducer'

import { RootState } from './reducers.model'
import cache from '../cache/cache.reducer'
import ui from '../ui/ui.reducer'
import domain from '../domain/domain.reducer'

const rootReducer = combineReducers<RootState>({
  cache,
  ui,
  domain,
  messages,
  survey,
  http,
  renderer,
})

export default rootReducer
export * from './reducers.model'
