import { Contact, RecipientContact } from '../model/Contact'

export const mapContactToRecipientContact = (contact: Contact): RecipientContact | never => {
  if (contact.organizations.length === 0) {
    throw new Error(`Impossible d'ajouter un contact n'ayant aucune organization`)
  }

  return {
    ...contact,
    mainRecipient: false,
    copyRecipient: false,
    assignedDoctor: false,
    addressingDoctor: false,
    organization: contact.organizations[0],
    mssEmail: contact.mssEmails[0] || null,
  }
}
