import { ChangeEvent, FunctionComponent, useCallback, useMemo, useState } from 'react'
import {
  FormikInternationalPhoneInputProps,
  countryCodeOptions,
} from './FormikInternationalPhoneInput.model'
import classNames from 'classnames/bind'
import styles from './FormikInternationalPhoneInput.module.scss'
import { SelectInput } from '../../../SelectInput'
import { SelectOption } from '../../../../../../model/SelectOption'
import { PhoneCountryCode } from '../../../../../../model/Phone'
import { Input } from '../../../Input'
import { useField } from 'formik'
import parsePhoneNumber, {
  CountryCode,
  getExampleNumber,
  isValidPhoneNumber,
} from 'libphonenumber-js'
import examples from 'libphonenumber-js/mobile/examples'
import { CountryCodeOption } from './CountryCodeOption'
import { parseInitialValue, filterOptions } from './FormikInternationalPhoneInput.utils'

const cx = classNames.bind(styles)

const ERROR_MESSAGE = "Numéro invalide, vérifiez l'indicatif et le numéro."
const DEFAULT_PLACEHOLDER = `213 373 4253`

export const FormikInternationalPhoneInput: FunctionComponent<
  FormikInternationalPhoneInputProps
> = ({ fieldName, label }) => {
  const [field, meta, helpers] = useField<string>({ name: fieldName })
  const [initialParsing] = useState(() => parseInitialValue(field.value))

  const [textValue, setTextValue] = useState(initialParsing.number)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [selectedCountryCode, setSelectedCountryCode] = useState<SelectOption<PhoneCountryCode>>(
    initialParsing.countryCode,
  )

  const parseAndFormatNumber = useCallback(
    (currentPhoneCode?: string) => {
      if (textValue === '') {
        helpers.setValue(textValue, false)
        helpers.setTouched(false, false)
        helpers.setError(undefined)
        return
      }

      const defaultCallingCode = currentPhoneCode ?? selectedCountryCode.value.phoneCode
      try {
        helpers.setTouched(true, false)
        if (!isValidPhoneNumber(textValue, { defaultCallingCode })) {
          helpers.setError(ERROR_MESSAGE)
          throw new Error('Parse failed')
        }

        const parsed = parsePhoneNumber(textValue, {
          defaultCallingCode,
        })
        if (!parsed) throw new Error('Parse failed')

        if (parsed.country && parsed.country !== selectedCountryCode.value.countryCode) {
          const foundCountryOption = countryCodeOptions.find(
            ({ value }) => value.countryCode === parsed.country,
          )
          if (foundCountryOption) {
            setSelectedCountryCode(foundCountryOption)
          }
        }

        setTextValue(parsed.formatNational())
        helpers.setError(undefined)
        helpers.setValue(parsed.formatInternational(), false)
      } catch (error) {
        console.error(error)
        helpers.setError(ERROR_MESSAGE)
      }
    },
    [helpers, selectedCountryCode, textValue],
  )

  const handleSelectCountryCode = useCallback(
    (option: SelectOption<PhoneCountryCode>) => {
      setSelectedCountryCode(option)
      parseAndFormatNumber(option.value.phoneCode)
    },
    [parseAndFormatNumber],
  )

  const handleTextInput = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setTextValue(event.currentTarget.value)
  }, [])

  const placeholder = useMemo(() => {
    const { countryCode } = selectedCountryCode.value
    const example = getExampleNumber(countryCode as CountryCode, examples)
    if (example) {
      return example.formatNational()
    }
    return DEFAULT_PLACEHOLDER
  }, [selectedCountryCode.value])

  const valid = meta.touched ? !meta.error : undefined

  return (
    <div
      className={cx(styles.grid, {
        focusCodeInput: isDropdownOpen,
      })}
    >
      <span className={styles.label}>{label}</span>
      <SelectInput
        value={selectedCountryCode}
        options={countryCodeOptions}
        onSelect={handleSelectCountryCode}
        height="small"
        renderOption={(option, currentValue, isHovered) => (
          <CountryCodeOption option={option} currentValue={currentValue} isHovered={isHovered} />
        )}
        onToggle={setIsDropdownOpen}
        filterOptions={filterOptions}
        valid={valid}
      />
      <Input
        name={`${fieldName}Raw`}
        value={textValue}
        height="small"
        colorPreset="light"
        onChange={handleTextInput}
        onBlur={() => parseAndFormatNumber()}
        placeholder={placeholder}
        valid={valid}
      />
      {meta.error && <span className={styles.error}>{meta.error}</span>}
    </div>
  )
}
