import { VisibilityType } from '../../../constants'
import { User } from '../../../model/User'
import { SelectOption } from '../../../model/SelectOption'
import { setBottomPanelOptions } from '../../../store/ui/bottomPanel'

export interface TagListPageFilters {
  title: string
  type: SelectOption<VisibilityType>
}

export interface TagListPageDispatchProps {
  setBottomPanelOptions: typeof setBottomPanelOptions
}

export interface TagListPageStoreProps {
  currentUser: User | null
}

export type TagListProps = TagListPageDispatchProps & TagListPageStoreProps

export const TagListPageTypeFilterSelectValues: SelectOption<VisibilityType>[] = [
  { value: VisibilityType.ALL, label: 'Tous les labels' },
  { value: VisibilityType.PUBLIC, label: 'Labels publics' },
  { value: VisibilityType.PRIVATE, label: 'Labels privés' },
]
