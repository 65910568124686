import { preventAnalyticLoading } from './utils'
import loadSatismeterScript from './uglySatismeterScript'
import { SATISMETER_TOKEN } from '../environment/analytics'

export function initSatismeter() {
  if (preventAnalyticLoading(document)) {
    return
  }

  loadSatismeterScript(SATISMETER_TOKEN)
}
