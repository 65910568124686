import { useField } from 'formik'
import React, { FunctionComponent, ChangeEvent } from 'react'
import { inputPhoneFormatter } from '../../../../../misc/input.utilities'
import { Input } from '../../Input'
import { FormikAucompleteInput } from './Input.model'

export const FormikPhoneInput: FunctionComponent<FormikAucompleteInput> = ({
  label,
  fieldName,
  autocomplete = true,
  disabled,
  colorPreset,
}) => {
  const [field, meta, helpers] = useField({ name: fieldName })

  const handleBlur = (event: React.FocusEvent<any>) => {
    const formated = inputPhoneFormatter(field.value)
    helpers.setValue(formated)
    field.onBlur(event)
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    helpers.setValue(event.target.value)
  }

  return (
    <Input
      type="tel"
      value={field.value}
      label={label}
      name={fieldName}
      valid={!meta.touched || !meta.value ? undefined : !meta.error}
      onChange={handleChange}
      onBlur={handleBlur}
      autocomplete="off"
      disabled={disabled}
      colorPreset={colorPreset}
      error={meta.error}
    />
  )
}
