import { combineReducers } from 'redux'
import {
  paginationReducerFactory,
  inUseReducerFactory,
} from '../../restux/ui/restuxUiReducer.factory'
import { RestuxUiSagaConf } from '../../restux/ui/restuxUi.model'

const conf: RestuxUiSagaConf = {
  resourceName: 'documentTemplate',
  identifier: 'bottomPanel',
}

const pagination = paginationReducerFactory(conf)

const inUse = inUseReducerFactory(conf)
export const bottomPanelDocumentTemplatesReducer = combineReducers({
  pagination,
  inUse,
})
