import { combineReducers } from 'redux'
import { createReducer } from 'redux-starter-kit'

import { inUseReducerFactory } from '../../restux/ui/restuxUiReducer.factory'
import { bottomPanelContactsActions } from './bottomPanelContacts.actions'
import {
  bottomPanelContactsConfig,
  BottomPanelContactsContext,
  BottomPanelContactsState,
  UiBottomPanelContactsActionTypes,
} from './bottomPanelContacts.model'

const CONTACT_PANEL_INITIAL_STATE = {
  readOnly: false,
  context: BottomPanelContactsContext.PATIENT_CONTACT,
  isInCreatePrivateCopyMode: false,
}
const CONTACT_PANEL_ACTION_HANDLERS = {
  [UiBottomPanelContactsActionTypes.SET_READ_ONLY]: (
    state = CONTACT_PANEL_INITIAL_STATE,
    { readOnly }: ReturnType<typeof bottomPanelContactsActions.setReadOnly>,
  ) => ({
    ...state,
    readOnly,
  }),
  [UiBottomPanelContactsActionTypes.SET_CONTEXT]: (
    state = CONTACT_PANEL_INITIAL_STATE,
    { context }: ReturnType<typeof bottomPanelContactsActions.setContext>,
  ) => ({
    ...state,
    context,
  }),
  [UiBottomPanelContactsActionTypes.OPEN_CREATE_CONTACT_FORM]: (
    state = CONTACT_PANEL_INITIAL_STATE,
  ) => ({
    ...state,
    readOnly: false,
  }),
  [UiBottomPanelContactsActionTypes.SWITCH_PRIVATE_COPY_MODE]: (
    state = CONTACT_PANEL_INITIAL_STATE,
  ) => ({
    ...state,
    readOnly: false,
    isInCreatePrivateCopyMode: true,
  }),
  [UiBottomPanelContactsActionTypes.CREATE_PRIVATE_COPY]: (
    state = CONTACT_PANEL_INITIAL_STATE,
  ) => ({
    ...state,
    isInCreatePrivateCopyMode: false,
  }),
}

export const contactPanelReducer = createReducer(
  CONTACT_PANEL_INITIAL_STATE,
  CONTACT_PANEL_ACTION_HANDLERS,
)

const inUse = inUseReducerFactory(bottomPanelContactsConfig)

export const bottomPanelContactsReducer = combineReducers<BottomPanelContactsState>({
  inUse,
  contactPanel: contactPanelReducer,
})
