import { RootState } from '../../reducers'
import { createSelector } from 'reselect'

const drugpanelDisabledSelector = (state: RootState) => state.ui.bottomPanelDrugs.disabled
export const bottomPanelDrugsDisabledSelector = createSelector(
  [drugpanelDisabledSelector],
  (disabled) => disabled,
)

const drugpanelInitialSearchSelector = (state: RootState) => state.ui.bottomPanelDrugs.initialSearch
export const bottomPanelDrugsInitialSearchSelector = createSelector(
  [drugpanelInitialSearchSelector],
  (search) => search,
)

const drugpanelMultiSelectSelector = (state: RootState) => state.ui.bottomPanelDrugs.multiSelect
export const bottomPanelDrugsMultiSelectSelector = createSelector(
  [drugpanelMultiSelectSelector],
  (multiSelect) => multiSelect,
)
