import { CancelToken } from 'apisauce'
import React, { useCallback, useEffect, useState } from 'react'
import { RoundedButton } from '../../../../../components/shared'
import { sortByCreatedDate } from '../../../../../misc/sort.utilities'
import { DocumentWithRevision, isDocumentHistory } from '../../../../../model/DocumentHistory'
import {
  GetDocumentHistoryRequest,
  getDocumentInstanceHistory,
  getDocumentInstanceRenderHistory,
} from '../../../../../store/cache/documentHistory'

import { SearchDocumentHistoryItem, SearchDocumentHistoryPanel } from '../../atoms'
import {
  SearchDocumentHistoryFilters,
  SearchDocumentHistoryFilterType,
} from '../../atoms/SearchDocumentHistoryFilters'

import { SearchDocumentHistoryProps } from '../SearchDocumentHistory.model'

export function SearchDocumentInstanceHistory({
  id,
  hash,
  download,
  restore,
}: SearchDocumentHistoryProps) {
  const [filter, setFilter] = useState(SearchDocumentHistoryFilterType.ALL)
  const [items, setItems] = useState<DocumentWithRevision[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const token = CancelToken.source()
    // Loading
    setLoading(true)
    const requests: GetDocumentHistoryRequest[] = Array.from(
      new Set([
        ...(filter === SearchDocumentHistoryFilterType.ALL
          ? [getDocumentInstanceHistory, getDocumentInstanceRenderHistory]
          : []),
        ...(filter === SearchDocumentHistoryFilterType.HISTORY ? [getDocumentInstanceHistory] : []),
        ...(filter === SearchDocumentHistoryFilterType.RENDER_HISTORY
          ? [getDocumentInstanceRenderHistory]
          : []),
      ]),
    )
    // Récupéraion de la liste
    Promise.all(requests.map((request) => request(id, token)))
      .then((elements) => elements.flat())
      .then(sortByCreatedDate)
      .then(setItems)
      .finally(() => setLoading(false))
    // Annulation de la requête lors du clean de l'effet
    return () => {
      token.cancel()
    }
  }, [id, filter])

  const renderItemAction = useCallback(
    (item: DocumentWithRevision) => {
      if (isDocumentHistory(item)) {
        if (item.hash === hash) {
          return <span>Version actuelle</span>
        } else {
          return <RoundedButton onClick={() => restore(item)} label="Restaurer" theme="light" />
        }
      } else {
        return <RoundedButton onClick={() => download(item)} label="Télécharger" theme="light" />
      }
    },
    [hash, download, restore],
  )

  return (
    <SearchDocumentHistoryPanel<DocumentWithRevision>
      loading={loading}
      items={items}
      renderFilters={() => <SearchDocumentHistoryFilters filter={filter} onChange={setFilter} />}
      renderItem={(item) => (
        <SearchDocumentHistoryItem key={item.hash} item={item} renderAction={renderItemAction} />
      )}
    />
  )
}
