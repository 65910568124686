import { connect } from 'react-redux'
import ManualPrescriptionConfiguaration from './ManualPrescriptionConfiguration.component'
import { domainManualPrescriptionActions } from '../../../store/domain/manualPrescription'
import { ManualPrescriptionConfigurationDispatchProps } from './ManualPrescriptionConfiguration.model'

const mapDispatchToProps: ManualPrescriptionConfigurationDispatchProps = {
  updateManualPrescription: domainManualPrescriptionActions.updateManualPrescription,
}

export default connect(undefined, mapDispatchToProps)(ManualPrescriptionConfiguaration)
