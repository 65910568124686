import React, { FunctionComponent, useRef, useState, useLayoutEffect, ReactNode } from 'react'
import classnames from 'classnames/bind'

import styles from './FiltersWrapper.module.scss'

const classnamesCx = classnames.bind(styles)

interface FiltersWrapperProps {
  variance: 'variant1' | 'variant2'
  children?: ReactNode
}

export const FiltersWrapper: FunctionComponent<FiltersWrapperProps> = ({ variance, children }) => {
  const [displayHalo, setDisplayHalo] = useState(false)
  const scrollableRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    if (scrollableRef.current) {
      const current = scrollableRef.current
      setDisplayHalo(current.scrollWidth > current.clientWidth)
    }
  }, [])
  return (
    <div className={classnamesCx(styles.container, { [variance]: true, withHalo: displayHalo })}>
      <div ref={scrollableRef} className={styles.filters}>
        {children}
      </div>
    </div>
  )
}
