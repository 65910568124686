import { createReducer } from 'redux-starter-kit'
import { UsurpationState, AdminUsersActionTypes } from './adminUsers.model'
import { storeUsurpedUser } from './adminUsers.actions'

const DEFAULT_USURPATION_STATE: UsurpationState = { usurpedUserInitializationWaitFlag: true }

const USURPATION_ACTION_HANDLERS = {
  [AdminUsersActionTypes.STORE_USURPED_USER]: (
    state: UsurpationState = DEFAULT_USURPATION_STATE,
    { user }: ReturnType<typeof storeUsurpedUser>,
  ) => ({
    ...state,
    usurpedUser: user,
  }),
  [AdminUsersActionTypes.INITIALIZE_USURPED_USER]: (
    state: UsurpationState = DEFAULT_USURPATION_STATE,
  ) => ({ ...state, usurpedUserInitializationWaitFlag: true }),
  [AdminUsersActionTypes.USURPED_USER_INITIALIZATION_DONE]: (
    state: UsurpationState = DEFAULT_USURPATION_STATE,
  ) => ({
    ...state,
    usurpedUserInitializationWaitFlag: false,
  }),
}

export const adminUsersReducer = createReducer(DEFAULT_USURPATION_STATE, USURPATION_ACTION_HANDLERS)
