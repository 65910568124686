import React, { FunctionComponent } from 'react'
import { ErrorPandaMessage } from '../../components/shared'
import { NoDoctorsAssignedErrorProps } from './NoDoctorsAssignedError.model'
import styles from './NoDoctorsAssignedError.module.scss'
import { isAssistant } from '../../misc/user.utilities'

const NoDoctorsAssignedError: FunctionComponent<NoDoctorsAssignedErrorProps> = ({
  currentUser,
  children,
}) => {
  if (isAssistant(currentUser) && currentUser.doctors.length === 0) {
    return (
      <div className={styles.errorMessage}>
        <ErrorPandaMessage
          title="Vous n'êtes pas rattaché à un médecin"
          subTitle="Veuillez contacter le support."
          contactSupport={true}
          backToHomePage={false}
        />
      </div>
    )
  }
  return <>{children}</>
}

export default NoDoctorsAssignedError
