import classNames from 'classnames'
import { useCallback, useRef, useState } from 'react'
import { useSelectArrowNavigation } from '../../../../hooks/utils'
import { DropdownOverlay } from '../../dropdown'
import { Icon } from '../../Icon'
import { SearchInputProps } from './SearchInput.model'
import { addTestIdSuffix } from '../../../../misc/testId.utilities'

export const searchInputThemes = {
  light: {
    background: 'bg-shades-white',
    border: 'border-shades-5',
    placeholder: 'placeholder:text-shades-5',
    text: 'text-shades-2',
    fill: 'fill-shades-5',
    label: 'text-shades-4',
  },
  dark: {
    background: 'bg-shades-3',
    border: 'border-shades-3',
    placeholder: 'placeholder:text-shades-5',
    text: 'text-shades-white',
    fill: 'fill-shades-5',
    label: 'text-shades-5',
  },
}

export function SearchInput<T>({
  label,
  value,
  placeholder,
  results,
  inputRef,
  testId,
  onChange,
  onSelect,
  onFocus,
  onBlur,
  renderResult,
  dropdownPosition,
  icon = 'search',
  autofocus = false,
  colorPreset = 'light',
  disabled = false,
}: SearchInputProps<T>) {
  const [searchFocused, setSearchFocused] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)

  const enableSearchFocus = useCallback(() => setSearchFocused(true), [setSearchFocused])
  const disableSearchFocus = useCallback(() => setSearchFocused(false), [setSearchFocused])

  const handleBlurInput = () => {
    if (
      containerRef.current?.contains(document.activeElement) &&
      document.activeElement instanceof HTMLElement
    ) {
      document.activeElement.blur()
    }
  }

  const [scrollRef, hoveredRef, hoveredIndex, handleKeyDown] = useSelectArrowNavigation(
    results,
    (item) => {
      onSelect && onSelect(item, handleBlurInput)
    },
  )

  const theme = searchInputThemes[colorPreset]

  return (
    <div className="flex flex-col" data-test-id={testId}>
      {label && (
        <span className={classNames('text-xs font-medium mb-1', theme.label)}>{label}</span>
      )}
      <div
        className={classNames(
          'relative flex items-center border rounded-3xl px-4 h-12',
          theme.background,
          theme.border,
        )}
        ref={containerRef}
        tabIndex={0}
        onFocus={enableSearchFocus}
        onBlur={disableSearchFocus}
      >
        <div className={classNames('mr-1', theme.fill)}>
          <Icon icon={icon} size="nano" />
        </div>
        <input
          className={classNames(
            'w-full ml-1 bg-transparent border-none outline-none font-semibold text-sm',
            theme.placeholder,
            theme.text,
          )}
          value={value}
          type="text"
          data-test-id={addTestIdSuffix('input', testId)}
          placeholder={placeholder}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={handleKeyDown}
          disabled={disabled}
          ref={inputRef}
          autoFocus={autofocus}
        />
        {results && renderResult && (
          <DropdownOverlay
            display={searchFocused && results.length > 0}
            scrollRef={scrollRef}
            position={dropdownPosition}
          >
            {results.map((result, index) => (
              <div
                key={index}
                ref={hoveredIndex === index ? hoveredRef : undefined}
                onMouseDown={(event) => {
                  onSelect && onSelect(result, handleBlurInput)
                  event.preventDefault()
                }}
              >
                {renderResult(result, hoveredIndex === index)}
              </div>
            ))}
          </DropdownOverlay>
        )}
      </div>
    </div>
  )
}
