import React, { FunctionComponent } from 'react'

import { DesktopNavigationMenu } from './DesktopNavigationMenu'
import { MobileNavigationMenu } from './MobileNavigationMenu'
import { NavigationMenuProps } from './NavigationMenu.model'

import styles from './NavigationMenu.module.scss'

export const NavigationMenu: FunctionComponent<NavigationMenuProps> = (props) => (
  <>
    <div className={styles.desktop}>
      <DesktopNavigationMenu {...props} />
    </div>
    <div className={styles.mobile}>
      <MobileNavigationMenu {...props} />
    </div>
  </>
)
