import React, { FunctionComponent, useMemo } from 'react'
import ReactDOM from 'react-dom'
import { ModalProps } from './Modal.model'
import { useScrollLock } from '../../../../hooks/utils/useScrollLock.hook'
import styles from './Modal.module.scss'
import classNames from 'classnames'
import { useTimeout } from 'react-use'

export const Modal: FunctionComponent<ModalProps> = ({
  children,
  rootId = 'modal-root',
  testId,
  isUnmounting,
  hidden = false,
  onClickOutside,
}) => {
  const root = useMemo(() => document.getElementById(rootId), [rootId])

  useScrollLock(!hidden)

  // Pas idéal, voir https://fwhealth.atlassian.net/browse/DEV-3824
  const [hasRenderedTwice] = useTimeout(25)

  const closed = isUnmounting || !hasRenderedTwice()

  if (!root) return null
  return ReactDOM.createPortal(
    <div
      className={classNames(styles.backgroundVeil, {
        [styles.hidden]: hidden,
        [styles.closed]: closed,
      })}
      data-test-id={testId}
      onMouseDown={onClickOutside}
    >
      <div
        className={classNames(styles.content, {
          [styles.closed]: closed,
        })}
        onMouseDown={(e) => {
          e.stopPropagation()
        }}
      >
        {children}
      </div>
    </div>,
    root,
  )
}
