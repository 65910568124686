import { uiDocumentPosologyActionTypes } from './documentPosology.model'
import { PrescriptionPosologyUpdate } from '../../../model/Prescription'

export const uiDocumentPosologyActions = {
  updatePrescriptionAndCreateTemplate: (
    prescriptionUuid: string,
    prescription: PrescriptionPosologyUpdate,
    name: string,
  ) => ({
    type: uiDocumentPosologyActionTypes.UPDATE_PRESCRIPTION_AND_CREATE_TEMPLATE,
    prescriptionUuid,
    prescription,
    name,
  }),
}
