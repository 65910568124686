import { RootState } from '../../reducers/index'
import { usurpedSelector } from '../../ui/adminUsers/index'
import { User } from '../../../model/User'
import { createSelector } from 'reselect'
import { DEFAULT_DOCTOR_PREFERENCES } from '../../../model/Preference'
import { isDoctor } from '../../../misc/user.utilities'

export const currentUserSelector = (state: RootState): User | null => {
  return usurpedSelector(state) || state.domain.me.user
}

export const loggedUserSelector = (state: RootState): User | null => {
  return state.domain.me.user
}

export const currentUserMeTeamsSelector = (state: RootState) => {
  return Object.values(state.cache.userMeTeams.list)
}

const userMeTeamsListSelector = (state: RootState) => state.cache.userMeTeams.list

export const userMeTeamsSelector = createSelector([userMeTeamsListSelector], (teams) =>
  Object.values(teams),
)

export const editorFontFamilyPreferencesSelector = createSelector([currentUserSelector], (user) =>
  isDoctor(user) ? user.preferences.editorFontFamily : DEFAULT_DOCTOR_PREFERENCES.editorFontFamily,
)

export const defaultLayoutPreferencesSelector = createSelector([currentUserSelector], (user) =>
  isDoctor(user) ? user.preferences.defaultLayout : null,
)

export const enabledFeaturesPreferencesSelector = createSelector([currentUserSelector], (user) =>
  isDoctor(user) ? user.preferences.enabledFeatures : null,
)

export const consentTextPreferencesSelector = createSelector([currentUserSelector], (user) =>
  isDoctor(user) ? user.preferences.consentText : null,
)

export const drugSortingPreferencesSelector = createSelector([currentUserSelector], (user) =>
  isDoctor(user) ? user.preferences.drugSorting : DEFAULT_DOCTOR_PREFERENCES.drugSorting,
)

export const trustedSourceAcceptedSelector = createSelector([currentUserSelector], (user) =>
  isDoctor(user)
    ? user.preferences.trustedSourceAccepted
    : DEFAULT_DOCTOR_PREFERENCES.trustedSourceAccepted,
)

export const documentAlertsFiltersPreferencesSelector = createSelector(
  [currentUserSelector],
  (user) =>
    isDoctor(user)
      ? user.preferences.documentAlertsFilters
      : DEFAULT_DOCTOR_PREFERENCES.documentAlertsFilters,
)

export const timeZonePreferencesSelector = createSelector([currentUserSelector], (user) =>
  isDoctor(user) ? user.preferences.timeZone : DEFAULT_DOCTOR_PREFERENCES.timeZone,
)
