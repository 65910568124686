import {
  Mail,
  MailPatient,
  MailListPage,
  DEFAULT_OFFSET_MAIL_LIST,
  DEFAULT_LIMIT_MAIL_LIST,
  MailClassificationAttachment,
} from '../../../model/Mail'
import { Patient } from '../../../model/Patient'
import { RequestStatus } from '../../../model/RequestStatus'
import {
  MailLoginFormFields,
  MailLoginOtpFormFields,
} from '../../../pages/mail/MailLoginPage/MailLoginPage.model'
import { MailFile } from './api/mail.model'
import { MailActionTypes } from './mail.model'

/********************** LOGIN ACTION ********************************/
export const domainMailActions = {
  initiateAuthMail: (values: MailLoginFormFields) => ({
    type: MailActionTypes.INITIATE_AUTH,
    values,
  }),

  finalizeAuthMail: (values: MailLoginOtpFormFields) => ({
    type: MailActionTypes.FINALIZE_AUTH,
    values,
  }),

  setLoginInitiate: (status: boolean) => ({
    type: MailActionTypes.SET_LOGIN_INITIATE,
    status,
  }),

  setOtpStatus: (status: boolean) => ({
    type: MailActionTypes.SET_OTP_STATUS,
    status,
  }),

  disconnect: () => ({
    type: MailActionTypes.DISCONNECT,
  }),
  disconnectWithError: (redirectUri?: string) => ({
    type: MailActionTypes.DISCONNECT_WITH_ERROR,
    redirectUri,
  }),
  /********************** LOGIN ACTION ********************************/

  setMailAddressList: (emailAddressList: Array<string>) => ({
    type: MailActionTypes.SET_MAIL_ADDRESS_LIST,
    emailAddressList,
  }),

  getMailAddressList: () => ({
    type: MailActionTypes.GET_MAIL_ADDRESS_LIST,
  }),

  setInUseMailAddress: (inUseMailAddress: string) => ({
    type: MailActionTypes.SET_IN_USE_MAIL_ADDRESS,
    inUseMailAddress,
  }),

  getMailList: (
    offset: number = DEFAULT_OFFSET_MAIL_LIST,
    limit: number = DEFAULT_LIMIT_MAIL_LIST,
    search?: string,
  ) => ({
    type: MailActionTypes.GET_MAIL_LIST,
    offset,
    limit,
    search,
  }),

  setFetchingNextMailsStatus: (status: RequestStatus) => ({
    type: MailActionTypes.SET_FETCHING_NEXT_MAILS_STATUS,
    status,
  }),

  setMailList: (mailList: MailListPage) => ({
    type: MailActionTypes.SET_MAIL_LIST,
    mailList,
  }),

  clearMailList: () => ({
    type: MailActionTypes.CLEAR_MAIL_LIST,
  }),

  reloadMailList: (navigateToInbox: boolean) => ({
    type: MailActionTypes.RELOAD_MAIL_LIST,
    navigateToInbox,
  }),

  setInUseMail: (inUseMail: Mail) => ({
    type: MailActionTypes.SET_IN_USE_MAIL,
    inUseMail,
  }),

  getInUseMail: (inUseMailId: string) => ({
    type: MailActionTypes.GET_IN_USE_MAIL,
    inUseMailId,
  }),

  setRequestMailsStatus: (requestStatus: RequestStatus) => ({
    type: MailActionTypes.SET_REQUEST_MAIL_STATUS,
    requestStatus,
  }),

  setRequestInUseMailsStatus: (inUseMailStatus: RequestStatus) => ({
    type: MailActionTypes.SET_REQUEST_IN_USE_MAIL_STATUS,
    inUseMailStatus,
  }),

  getAttachmentFile: (messageId: string, partId: number, cdaFileId?: string) => ({
    type: MailActionTypes.GET_ATTACHMENT_FILE,
    messageId,
    partId,
    cdaFileId,
  }),

  classifyDocuments: (
    selectedAttachments: MailClassificationAttachment[],
    patientId: number,
    documentCategoryValue: string,
    date: Date,
  ) => ({
    type: MailActionTypes.CLASSIFY_DOCUMENTS,
    selectedAttachments,
    patientId,
    documentCategoryValue,
    date,
  }),

  setPatientMailsBlackList: (blackListedPatientMails: Array<string>) => ({
    type: MailActionTypes.SET_PATIENT_MAIL_BLACKLIST,
    blackListedPatientMails,
  }),

  setPatientUnqualified: (unqualifiedPatientEmailS: Array<string>) => ({
    type: MailActionTypes.SET_PATIENT_UNQUALIFIED,
    unqualifiedPatientEmailS,
  }),

  checkPatientEmailValidation: (patientsEmail: Array<string>) => ({
    type: MailActionTypes.CHECK_PATIENT_EMAIL_VALIDATION,
    patientsEmail,
  }),

  sendMail: (
    recipientEmails: string[],
    subject: string,
    content: string,
    files: File[],
    askReadAcknowledgement: boolean,
    onError: () => any,
    replyToEmail: string | null,
    documentIds?: Array<number | null>,
    patiendId?: number,
  ) => ({
    type: MailActionTypes.SEND_MAIL,
    recipientEmails,
    subject,
    content,
    askReadAcknowledgement,
    files,
    patiendId,
    replyToEmail,
    documentIds,
    onError,
  }),

  setFilesToSend: ({ files }: { files: File[] }) => ({
    type: MailActionTypes.SET_FILES_TO_SEND,
    files,
  }),

  clearFilesToSend: () => ({
    type: MailActionTypes.CLEAR_FILES_TO_SEND,
  }),

  createPatientFromMail: (patient: MailPatient, external = true, createInsiTask = false) => ({
    type: MailActionTypes.CREATE_PATIENT_FROM_MAIL,
    patient,
  }),

  updatePatientFromMail: (
    patientId: number,
    patient: MailPatient,
    forceInsiCheck = false,
    external = false,
  ) => ({
    type: MailActionTypes.UPDATE_PATIENT_FROM_MAIL,
    patientId,
    patient,
    forceInsiCheck,
    external,
  }),

  setAttachementPatient: (patient: Patient) => ({
    type: MailActionTypes.SET_ATTACHMENT_PATIENT,
    patient,
  }),

  setInUseAttachment: (file?: MailFile) => ({
    type: MailActionTypes.SET_IN_USE_ATTACHMENT,
    file,
  }),

  openCreatePatientThroughMailModal: () => ({
    type: MailActionTypes.SET_CREATE_PATIENT_THROUGH_MAIL_MODAL_OPEN,
    open: true,
  }),

  closeCreatePatientThroughMailModal: (createPatient: boolean) => ({
    type: MailActionTypes.SET_CREATE_PATIENT_THROUGH_MAIL_MODAL_OPEN,
    open: false,
    createPatient,
  }),

  setMailSearch: (search?: string) => ({
    type: MailActionTypes.SET_MAIL_SEARCH,
    search,
  }),

  deleteMail: (mailId: string, navigateToInbox: boolean) => ({
    type: MailActionTypes.DELETE_MAIL,
    mailId,
    navigateToInbox,
  }),
}
