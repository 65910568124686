import { connect } from 'react-redux'
import { RootState } from '../../../store'
import { DocumentAlertsPanel } from './DocumentAlertsPanel.component'
import {
  DocumentAlertsPanelDispatchProps,
  DocumentAlertsPanelStateProps,
} from './DocumentAlertsPanel.model'
import { documentAlertsDomainActions } from '../../../store/domain/documentAlerts/documentAlerts.actions'
import {
  documentAlertsFiltersSelector,
  documentAlertsSelector,
} from '../../../store/domain/documentAlerts/documentAlerts.selectors'
import { documentAlertsFiltersPreferencesSelector } from '../../../store/domain/me'
import { inUseMedicalEventDocumentSelector } from '../../../store/ui/medicalEvents/medicalEventDocumentInstances'

const mapStateToProps = (state: RootState): DocumentAlertsPanelStateProps => ({
  documentAlerts: documentAlertsSelector(state),
  availableFilters: documentAlertsFiltersSelector(state),
  filtersPreferences: documentAlertsFiltersPreferencesSelector(state),
  document: inUseMedicalEventDocumentSelector(state),
})

const mapDispatchToProps: DocumentAlertsPanelDispatchProps = {
  getAlerts: documentAlertsDomainActions.getAlerts,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)(DocumentAlertsPanel)
export { withConnect as DocumentAlertsPanel }
