import { EditorValueJSON, getEditorEmptyJsonValue } from '@follow/farte'
import { Address, DEFAULT_ADDRESS } from '../../../model/Address'
import { RecipientContact } from '../../../model/Contact'
import {
  AssigningAuthority,
  IdentityState,
  IdentityStatus,
  Patient,
  PatientCustomField,
  Sex,
  Title,
} from '../../../model/Patient'
import { ImpersonatePermissions } from '../../../model/User'
import { domainAssigningAuthorithiesActions } from '../../../store/domain/assigningAuthorities'
import { Features } from '../../../model/Preference'

export interface PatientFormOwnProps {
  patient: Partial<Patient> | Patient | null
}

export interface PatientFormStoreProps {
  assigningAuthorities: AssigningAuthority[]
  impersonatePermissions: ImpersonatePermissions | null
  enabledFeatures: Features | null
}

export interface PatientFormDispatchProps {
  getAssigningAuthorithies: typeof domainAssigningAuthorithiesActions.getAssigningAuthorithies
}

export type PatientFormProps = PatientFormOwnProps &
  PatientFormStoreProps &
  PatientFormDispatchProps

export const SEX_FORM_LABELS: { [key in Sex]: string } = {
  [Sex.MALE]: 'Masculin',
  [Sex.FEMALE]: 'Féminin',
  [Sex.UNKNOWN]: 'Indéterminé',
}

export const TITLE_FORM_LABELS: { [key in Title]: string } = {
  [Title.MR]: 'Monsieur',
  [Title.MS]: 'Madame',
  [Title.NONE]: 'Aucune',
}

export const EMPTY_DEFAULT_ADDRESS: Address = {
  street2: null,
  street: null,
  zipCode: null,
  city: null,
  country: null,
}

export interface PatientFormFields {
  birthFirstName: string
  birthLastName: string
  usedFirstName?: string
  usedLastName?: string
  otherFirstNames?: string
  birthDate: string
  birthPlaceCode?: string
  assigningAuthority?: AssigningAuthority | null
  sex?: Sex
  title?: Title
  profession?: string
  email?: string
  telephone1?: string
  telephone2?: string
  doctor?: string
  inseeNumber?: string
  healthInsuranceNumber?: string
  note: EditorValueJSON
  address: Address
  contacts: ReadonlyArray<RecipientContact>
  customFields: PatientCustomField[]
  identityStatus: IdentityStatus
  disallowMSSMessaging: boolean
  isDeceased: boolean
}

export const EMPTY_DEFAULT_VALUES: PatientFormFields = {
  birthFirstName: '',
  birthLastName: '',
  usedFirstName: '',
  usedLastName: '',
  otherFirstNames: '',
  birthDate: '',
  profession: '',
  address: DEFAULT_ADDRESS,
  telephone1: '',
  telephone2: '',
  email: '',
  inseeNumber: '',
  healthInsuranceNumber: '',
  doctor: '',
  note: getEditorEmptyJsonValue(),
  contacts: [],
  customFields: [],
  identityStatus: {
    suspicious: false,
    fictitious: false,
    trustedSource: null,
    showTrustedSource: false,
    state: IdentityState.TEMPORARY,
    insiStatus: {
      checkedAt: null,
      checked: 'UNCHECKED',
    },
    id: null,
  },
  disallowMSSMessaging: false,
  isDeceased: false,
}
