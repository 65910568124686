import { Filters } from '../../model/Filters'
import getApi from '../../api'
import { PaginatedList } from '../../model/Pagination'
import { DocumentLayoutLight } from '../../model/DocumentLayout'
import { singleItemPageLimit } from '../../constants'

export const getDocumentLayouts = (
  page: number,
  filters?: Filters,
  signal?: AbortSignal,
  limit = singleItemPageLimit,
) =>
  getApi().get<PaginatedList<DocumentLayoutLight>>(
    '/document_layouts',
    {
      page,
      limit,
      ...filters,
    },
    { signal },
  )
