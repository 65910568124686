import { FC } from 'react'
import { AcademicBackgroundBlockProps } from './AcademicBackgroundBlock.model'
import { FormBlock, TextArea } from '../../../shared'

export const AcademicBackgroundBlock: FC<AcademicBackgroundBlockProps> = ({
  academicBackground,
  onChangeAcademicBackground,
}) => {
  return (
    <FormBlock label="Parcours académique" icon="school">
      <div className="overflow-auto h-103">
        <TextArea
          autoResize
          name="academicBackground"
          value={academicBackground}
          onChange={(e) => onChangeAcademicBackground(e.target.value)}
        />
      </div>
    </FormBlock>
  )
}
