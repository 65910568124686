import React, { FunctionComponent } from 'react'
import { DropdownItem } from '../../shared'
import { getFullName } from '../../../misc/user.utilities'
import { DropdownUserItemProps } from './DropdownUserItem.model'
import styles from './DropdownUserItem.module.scss'
import { UserAvatar } from '../UserAvatar'

export const DropdownUserItem: FunctionComponent<DropdownUserItemProps> = ({
  user,
  selectedValues,
  selected = false,
}) => {
  const isSelected = !!selectedValues.find((selItem) => selItem.id === user.id)
  return (
    <DropdownItem
      selected={isSelected || selected}
      icon={isSelected ? 'checkCircle' : undefined}
      iconPosition="right"
    >
      <div className={styles.menuItem}>
        <UserAvatar
          firstName={user.firstName}
          familyName={user.familyName}
          size="small"
          theme={isSelected ? 'primary' : 'light'}
        />
        {getFullName(user)}
      </div>
    </DropdownItem>
  )
}
