import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  BOTTOM_PANEL_CONTENT_ROOT,
  Button,
  ClickableLink,
  DropdownItem,
  Icon,
  IconsType,
  Input,
  MenuChip,
  MultiSelectInput,
  Switch,
  TooltipWrapper,
  ValidationModal,
} from '../../shared'
import LogoMsSante from '../../../assets/images/logo_mssante.png'
import { MailEditorProps } from './MailEditor.model'
import { emailRegex } from '../../../misc/regex'
import { LightPatient } from '../../../model/Patient'
import { PatientDropDownItemSelector } from '../../patient'
import { FileAddButton, FileAddButtonErrorType } from '../../file'
import { ATTACHMENT_MAX_SIZE_IN_BYTES, ATTACHMENT_MIME_TYPES } from '../../../model/Mail'
import { useDebounce } from 'react-use'
import { ContactOption } from '../../contact'
import { FwTrackingEvent, TrackingService } from '../../../misc/Tracking'
import { MultiSelectOption, SelectOption } from '../../../model/SelectOption'
import { SECURITE_SOCIAL_NUMBER_REGEX_WITHOUT_SPACE } from '../../../misc/inseeNumber.utils'
import { formatReply } from '../../../misc/mail.utilities'
import { MailContact } from '../../../model/Contact'
import { useSearchParams } from 'react-router-dom'
import { getCurrentPatient } from '../../../misc/currentPatient.utilities'
import { Editor, EditorTool, SimpleEditor } from '@follow/elfe'

const editorTools: Array<EditorTool> = ['heading', 'fontSize', 'marks', 'align', 'list', 'undo']

const EMPTY_CONTACT_ID = '0'
const emptyMailContact: MailContact = {
  id: EMPTY_CONTACT_ID,
  firstName: '',
  familyName: '',
  mssEmail: {
    emailAddress: '',
  },
  organizations: [
    {
      address: {
        zipCode: '',
        city: '',
        street: '',
        street2: '',
        country: '',
      },
      id: '',
      technicalId: '',
      emailAddress: '',
      name: '',
      private: true,
      phoneNumber: '',
    },
  ],
  profession: {
    description: '',
    oid: '',
    id: '',
  },
  speciality: {
    description: '',
    id: '',
  },
  trusted: false,
}

const getFileIcon = (contentType: string): IconsType => {
  if (contentType.includes('image')) return 'image'
  else if (contentType.includes('pdf')) return 'fileInstance'
  return 'documentDefault'
}

const enableReturnTo = false

export const MailEditor: FC<MailEditorProps> = ({
  actions: Actions,
  mailContacts,
  documentsIds,
  mailSubject,
  enabledFeatures,
  openedFrom,
  includeObservation,
  preselectedMssEmails,
  currentMedicalEvent,
  fileToSend,
  clearFilesToSend,
  setBottomPanelOptions,
  getMailContacts,
  clearMailContacts,
  sendMail,
  addError,
  clearMailSubject,
  clearDocumentsIds,
  unqualifiedPatientMails,
  blackListedPatientMails,
  checkPatientEmailValidation,
  currentMail,
}) => {
  const currentPatient = getCurrentPatient()
  const [documentIds, setDocumentIds] = useState(documentsIds ?? undefined)
  const [localMailSubject, setLocalMailSubject] = useState(mailSubject ?? '')
  const [addressInput, setAddressInput] = useState('')
  const [emailAddresses, setEmailAddresses] = useState<MultiSelectOption<MailContact>[]>([])
  const [displayCloseModal, setDisplayCloseModal] = useState(false)
  const [selectedPatient, setSelectedPatient] = useState<LightPatient>()
  const [selectedFiles, setSelectedFiles] = useState<File[]>(fileToSend)
  const [selectedFileName, setSelectedFileName] = useState<string>('')
  const [formDisabled, setFormDisabled] = useState<boolean>(false)
  const [lockSendButton, setLockSendButton] = useState<boolean>(true)
  const [lockedMessage, setLockedMessage] = useState('')
  const [replyToEmail, setReplyToEmail] = useState<string>()
  const [askReadAcknowledgement, setAskReadAcknowledgement] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()

  const editorRef = useRef<Editor>(null)

  const contactOptions = useMemo(() => {
    if (addressInput.length > 0) {
      const options: Array<SelectOption<MailContact>> = mailContacts.items.map((contact) => ({
        label: contact.mssEmail.emailAddress,
        value: contact,
      }))

      const manualEntry: SelectOption<MailContact> = {
        label: addressInput,
        value: {
          ...emptyMailContact,
          mssEmail: {
            emailAddress: addressInput,
          },
        },
      }

      return [manualEntry, ...options]
    } else {
      return []
    }
  }, [addressInput, mailContacts.items])

  useEffect(() => {
    return () => {
      clearFilesToSend()
      if (searchParams.get('sending') === 'true') {
        setSearchParams((prevParams) => {
          const updatedParams = new URLSearchParams(prevParams)
          updatedParams.delete('sending')
          return updatedParams
        })
      }
    }
  }, [clearFilesToSend, searchParams, setSearchParams])

  useEffect(() => {
    if (mailSubject) setLocalMailSubject(mailSubject)
    return () => {
      clearMailSubject()
    }
  }, [mailSubject, clearMailSubject])

  useEffect(() => {
    if (documentsIds) setDocumentIds(documentsIds)
    return () => {
      clearDocumentsIds()
    }
  }, [documentsIds, clearDocumentsIds, documentIds])

  useEffect(() => {
    setLockSendButton(true)
    if (emailAddresses.length === 0) {
      setLockedMessage('Veuillez saisir une adresse mail valide')
    } else if (replyToEmail && !emailRegex.test(replyToEmail)) {
      setLockedMessage('Veuillez saisir une adresse mail de retour valide')
    } else if (blackListedPatientMails.length > 0) {
      setLockedMessage(
        `${
          blackListedPatientMails.length < 2
            ? "Ce patient refuse d'être contacté"
            : "Plusieurs patients saisis refusent d'être contactés"
        } via la messagerie sécurisée`,
      )
    } else if (unqualifiedPatientMails.length > 0) {
      setLockedMessage(
        `L'identité ${
          unqualifiedPatientMails.length < 2 ? 'du patient' : 'des patients'
        } doit être qualifiée`,
      )
    } else if (selectedPatient && selectedFiles.length === 0) {
      setLockedMessage(
        `Vous ne pouvez pas envoyer de mail à propos d'un patient sans ajouter de pièce jointe`,
      )
    } else {
      setLockSendButton(false)
      setLockedMessage('')
    }
  }, [
    emailAddresses,
    replyToEmail,
    blackListedPatientMails.length,
    unqualifiedPatientMails.length,
    selectedPatient,
    selectedFiles.length,
  ])

  useEffect(() => {
    const patientEmailAddresses = emailAddresses.reduce(
      (
        patientEmails,
        {
          value: {
            mssEmail: { emailAddress },
          },
        },
      ) => {
        const emailParts = emailRegex.exec(emailAddress)
        if (emailParts) {
          const emailUsername = emailParts[1]
          if (SECURITE_SOCIAL_NUMBER_REGEX_WITHOUT_SPACE.test(emailUsername)) {
            return [...patientEmails, emailAddress]
          }
        }
        return patientEmails
      },
      [] as Array<string>,
    )
    checkPatientEmailValidation(patientEmailAddresses)
  }, [emailAddresses, checkPatientEmailValidation])

  useEffect(() => {
    setSelectedFiles(fileToSend)
  }, [fileToSend])

  useEffect(() => {
    if (openedFrom === 'medicalEvent') {
      if (currentPatient) {
        setSelectedPatient(currentPatient)
      }

      if (includeObservation && currentMedicalEvent) {
        editorRef.current?.commands.setContent(currentMedicalEvent.observation)
      }

      if (preselectedMssEmails) {
        setEmailAddresses((currentEmailAddresses) => [
          ...currentEmailAddresses,
          ...preselectedMssEmails.map((emailAddress) => ({
            label: emailAddress,
            value: {
              ...emptyMailContact,
              mssEmail: {
                emailAddress: emailAddress,
              },
            },
          })),
        ])
      }
    } else if (openedFrom === 'reply') {
      if (currentMail?.body) {
        setLocalMailSubject('À propos de notre conversation')
        setEmailAddresses((currentEmailAddresses) => [
          ...currentEmailAddresses,
          {
            label: currentMail.emailFrom,
            value: {
              ...emptyMailContact,
              mssEmail: {
                emailAddress: currentMail.emailFrom,
              },
            },
          },
        ])
        editorRef.current?.setOptions({ content: formatReply(currentMail) })
      }
    }
  }, [
    openedFrom,
    includeObservation,
    currentMedicalEvent,
    currentPatient,
    currentMail,
    setEmailAddresses,
    preselectedMssEmails,
  ])

  useDebounce(
    () => {
      if (addressInput.length > 2) {
        getMailContacts({
          page: { currentPage: 1, pageSize: 15 },
          filters: { search: addressInput },
        })
      } else {
        clearMailContacts()
      }
    },
    500,
    [addressInput],
  )

  const handleSelectEmailAddress = useCallback((value: MultiSelectOption<MailContact>[]) => {
    const filtered = value.filter(
      (item) => item.value.mssEmail && emailRegex.test(item.value.mssEmail.emailAddress),
    )

    setEmailAddresses(filtered)

    // Si l'email en cours de saisie est valide, on vide l'input text
    if (value.length === filtered.length) {
      setAddressInput('')
    }
  }, [])

  const closeMailEditorModal = useCallback(() => {
    setDisplayCloseModal(false)
  }, [])

  const handleBackToEditorModal = useCallback(() => {
    setDisplayCloseModal(false)
  }, [])

  const lockForm = useCallback(() => {
    setFormDisabled(true)
    setLockSendButton(true)
    setSelectedFileName('')
  }, [])

  const onError = () => {
    setFormDisabled(false)
    setLockSendButton(false)
  }

  const handleSend = useCallback(() => {
    if (!editorRef.current) return

    const recipients = emailAddresses.map(({ value }) => value.mssEmail.emailAddress)
    const htmlContent = editorRef.current.getHTML()
    const html = `<div>${htmlContent}</div>`
    lockForm()

    sendMail(
      recipients,
      localMailSubject,
      html,
      selectedFiles,
      askReadAcknowledgement,
      onError,
      replyToEmail && emailRegex.test(replyToEmail) ? replyToEmail : null,
      documentIds,
      selectedPatient ? selectedPatient.id : undefined,
    )
    if (openedFrom === 'medicalEvent') {
      TrackingService.sendEvent(FwTrackingEvent.MSS_MEDICAL_EVENT, {
        medicalEventId: currentMedicalEvent?.id,
      })
    }
  }, [
    emailAddresses,
    lockForm,
    sendMail,
    localMailSubject,
    selectedFiles,
    askReadAcknowledgement,
    selectedPatient,
    replyToEmail,
    openedFrom,
    currentMedicalEvent?.id,
    documentIds,
  ])

  const handleConfirmClose = useCallback(() => {
    closeMailEditorModal()
    setBottomPanelOptions({
      open: false,
      displayCloseButton: true,
    })
  }, [closeMailEditorModal, setBottomPanelOptions])

  const handleClose = useCallback(() => {
    if (!editorRef.current) return

    const isEditorEmpty = editorRef.current.getText() === ''
    const isSubjectEmpty = localMailSubject.length === 0
    const isEmailEmpty = emailAddresses.length === 0
    const isPatientEmpty = selectedPatient === undefined

    const isFormEmpty = isEditorEmpty && isSubjectEmpty && isEmailEmpty && isPatientEmpty

    if (isFormEmpty) {
      handleConfirmClose()
    } else {
      setDisplayCloseModal(true)
    }
  }, [emailAddresses.length, handleConfirmClose, localMailSubject.length, selectedPatient])

  const isAlreadySelected = (file: File) =>
    selectedFiles.some((item) => item.name === file.name && item.size === file.size)

  const handleAddFile = (filesToUpload: File[]) => {
    const files = filesToUpload.reduce(
      (previousValue, file) => (isAlreadySelected(file) ? previousValue : [...previousValue, file]),
      selectedFiles,
    )

    setDocumentIds((ids) => (ids ? [...ids, null] : [null]))
    setSelectedFiles(files)
  }

  const handleRemoveFile = (file: File) => {
    setSelectedFiles([
      ...selectedFiles.filter((item) => item.name !== file.name && item.size !== file.size),
    ])
  }

  const handleSelectPatient = useCallback(
    (patient?: LightPatient) => {
      if (patient && patient.disallowMSSMessaging) {
        return addError(
          `Impossible d’envoyer le message`,
          `Le patient ${patient.identity} refuse d'être contacté ou que ses informations soient échangées via la messagerie de santé sécurisée`,
        )
      }

      if (!patient) {
        setSelectedFiles([])
      }
      setSelectedPatient(patient)
    },
    [addError],
  )

  const handleFileButtonError = useCallback(
    (errorType: FileAddButtonErrorType) => {
      switch (errorType) {
        case 'OVERSIZED_FILE':
          addError(
            `Fichier(s) trop volumineux`,
            'La taille des pièces jointes est limitée à 10 Mo.',
          )
          break
        case 'INCORRECT_FILE_TYPE':
          addError(
            `Type de fichier incorrect`,
            'Le type des pièces jointes est restreint à PDF, JPG, Texte, TIFF et RTF.',
          )
          break
      }
    },
    [addError],
  )

  return (
    <div className="bg-white px-2 overflow-y-scroll overflow-x-hidden flex flex-col justify-between h-full">
      <div className="px-4 pt-2 pb-4 space-y-2">
        <div className="flex items-center">
          <MultiSelectInput
            title="Destinataire(s)"
            mode="multiline"
            placeholder="Rechercher un destinataire"
            value={emailAddresses}
            options={contactOptions}
            disabled={formDisabled}
            valid={addressInput.length > 0 ? emailRegex.test(addressInput) : undefined}
            emitChange={setAddressInput}
            onSelect={handleSelectEmailAddress}
            renderOption={({ value }, _, isHovered) =>
              value.id === EMPTY_CONTACT_ID ? (
                <DropdownItem selected={isHovered}>
                  <span className="mx-2">{value.mssEmail.emailAddress}</span>
                </DropdownItem>
              ) : (
                <ContactOption
                  contact={value}
                  selected={isHovered}
                  trusted={value.trusted ?? undefined}
                  mssEmailAddress={value.mssEmail.emailAddress}
                />
              )
            }
          />
          <div className="ml-4 w-80 font-medium text-shades-2">
            <Switch
              checked={askReadAcknowledgement}
              onChange={setAskReadAcknowledgement}
              name="Accusé de réception"
            />
          </div>
          <span className="ml-8 mr-2 text-shades-2 font-bold text-base whitespace-nowrap">
            Envoyé via
          </span>
          <img src={LogoMsSante} width="52px" height="auto" alt="logo-mss" />
        </div>
        {enableReturnTo && (
          <>
            {replyToEmail === undefined ? (
              <div className="ml-2">
                <ClickableLink
                  label="Ajouter une adresse de retour différente"
                  icon="add"
                  onClick={() => setReplyToEmail('')}
                />
              </div>
            ) : (
              <Input
                label="Adresse de retour"
                name="mailToAddress"
                placeholder="Entrer une adresse de retour"
                colorPreset="light"
                valid={replyToEmail.length > 0 ? emailRegex.test(replyToEmail) : undefined}
                error={
                  replyToEmail.length > 0 && !emailRegex.test(replyToEmail)
                    ? "L'adresse saisie n'est pas valide"
                    : undefined
                }
                disabled={formDisabled}
                value={replyToEmail}
                onChange={(event) => setReplyToEmail(event.target.value)}
              />
            )}
          </>
        )}
        <Input
          label="Objet"
          name="object"
          colorPreset="light"
          value={localMailSubject}
          disabled={formDisabled}
          onChange={(event) => setLocalMailSubject(event.target.value)}
        />
      </div>
      {enabledFeatures?.mailAttachments && (
        <div className="flex mb-4 w-full justify-between relative px-4">
          <div className="w-1/4">
            <PatientDropDownItemSelector
              selectedPatient={selectedPatient}
              setSelectedPatient={handleSelectPatient}
              hasInseeNumber={true}
              disabled={formDisabled || openedFrom === 'medicalEvent'}
            />
          </div>
          <div className="text-shades-3 text-base font-medium w-3/4 flex items-start ml-4 pl-4 border-l-shades-5 border-l min-h-full">
            {selectedPatient ? (
              <div className="flex flex-wrap">
                {selectedFiles.length > 0 && (
                  <>
                    {selectedFiles.map((file, index) => (
                      <div className="mr-2 mb-2" key={file.name}>
                        <MenuChip
                          key={index}
                          label={file.name}
                          icon={file.type ? getFileIcon(file.type) : undefined}
                          selected={file.name === selectedFileName}
                          collapsed={file.name !== selectedFileName}
                          onClick={() => {
                            setSelectedFileName(file.name)
                          }}
                          onDelete={() => handleRemoveFile(file)}
                          disabled={formDisabled}
                        />
                      </div>
                    ))}
                  </>
                )}
                <FileAddButton
                  type={ATTACHMENT_MIME_TYPES}
                  buttonLabel="Pièce(s) jointe(s)"
                  maxSizeInBytes={ATTACHMENT_MAX_SIZE_IN_BYTES}
                  disabled={formDisabled}
                  onAdd={handleAddFile}
                  onError={handleFileButtonError}
                />
              </div>
            ) : (
              <div className="flex items-center h-full">
                <Icon icon="infoCircle" size="nano" />
                <span className="ml-2 break-words">
                  La sélection d'un patient avec un matricule INS est nécessaire pour ajouter des
                  pièces jointes
                </span>
              </div>
            )}
          </div>
        </div>
      )}
      <SimpleEditor initialContent={''} tools={editorTools} editorRef={editorRef} />
      <Actions>
        <Button label="Annuler" theme="dark" onClick={handleClose} />
        <TooltipWrapper
          display={lockSendButton && !formDisabled}
          content={lockedMessage}
          size="small"
        >
          <div>
            <Button
              type="submit"
              label="Envoyer le message"
              theme="primary"
              disabled={lockSendButton}
              onClick={handleSend}
            />
          </div>
        </TooltipWrapper>
      </Actions>
      <ValidationModal
        display={displayCloseModal}
        title="Votre saisie ne sera pas sauvegardée, voulez-vous fermer l'éditeur de message ?"
        onSubmit={handleConfirmClose}
        onClose={handleBackToEditorModal}
        rootId={BOTTOM_PANEL_CONTENT_ROOT}
      />
    </div>
  )
}
