import { useMemo } from 'react'
import { MultiSelectOption } from '../../model/SelectOption'
import { TagType } from '../../model/Tag'

export const useTagSearch = (
  tagIds: ReadonlyArray<string>,
  tags: ReadonlyArray<TagType>,
): [MultiSelectOption<string>[], MultiSelectOption<string>[]] => {
  return useMemo(() => {
    const options = tags.map(({ id, label }) => ({ value: id, label }))
    const value = tags
      .filter((tag) => tagIds.includes(tag.id))
      .map(({ id, label, color }) => ({ value: id, label, color }))

    return [options, value]
  }, [tagIds, tags])
}
