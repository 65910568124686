import classNames from 'classnames'
import { getCssVariable } from '../../../../design-system/utils'
import { useMultiSelect, useSelectArrowNavigation } from '../../../../hooks/utils'
import { MultiSelectOption } from '../../../../model/SelectOption'
import { DropdownItem, DropdownOverlay } from '../../dropdown'
import { Icon } from '../../Icon'
import { Tag } from '../../tag'
import { searchSelectThemes } from '../SelectSearch'
import { MultiSelectSearchProps } from './MultiSelectSearch.model'

export const defaultRenderMultiSelectOption = (
  option: MultiSelectOption<any>,
  currentValue: Array<MultiSelectOption<any>>,
  isHovered: boolean,
) => (
  <DropdownItem
    icon={option.icon}
    selected={isHovered || !!currentValue.find(({ value }) => value === option.value)}
  >
    <span className="whitespace-normal">{option.label}</span>
  </DropdownItem>
)

export function MultiSelectSearch<T>({
  label,
  icon,
  value,
  colorPreset = 'light',
  options,
  placeholder,
  mode = 'wrap',
  testId,
  emitChange,
  areElementsEquals,
  onSelect,
  renderOption = defaultRenderMultiSelectOption,
}: MultiSelectSearchProps<T>) {
  const {
    open,
    showTags,
    textValue,
    computedOptions,
    inputRef,
    containerRef,
    handleOpen,
    handleSelect,
    handleTextInput,
  } = useMultiSelect(value, options, mode, onSelect, areElementsEquals, emitChange)

  const [scrollRef, hoveredRef, hoveredIndex, handleKeyDown] = useSelectArrowNavigation(
    computedOptions,
    handleSelect,
  )

  const theme = searchSelectThemes[colorPreset]

  return (
    <div className="relative min-w-0 flex flex-col" data-test-id={testId}>
      {label && (
        <span className={classNames('text-xs font-medium mb-1', theme.label)}>{label}</span>
      )}
      <div
        className={classNames(
          'h-12 px-4 flex items-center rounded-full border cursor-pointer',
          theme.background,
          theme.border,
        )}
        onClick={() => handleOpen(!open)}
        onMouseDown={(e) => {
          // Empêche le blur de l'input de se déclencher pour permettre la fermeture du dropdown
          if (open) {
            e.preventDefault()
          }
        }}
      >
        {icon && (
          <div className="flex-shrink-0 mr-1">
            <Icon size="nano" icon={icon} color={getCssVariable('shades', 'shade5')} />
          </div>
        )}
        <div className="w-full max-w-full min-w-0">
          <div
            ref={containerRef}
            className="flex min-w-0 max-w-full w-fit h-full overflow-x-hidden space-x-2"
          >
            {open ? (
              <input
                ref={inputRef}
                value={textValue}
                data-test-id={`${testId}-input`}
                readOnly={!open}
                onChange={(event) => handleTextInput(event.currentTarget.value)}
                className={classNames(
                  'my-auto appearance-none bg-transparent w-full ml-1 text-sm font-semibold cursor-inherit',
                  theme.placeholderClass,
                  theme.text,
                )}
                placeholder={placeholder}
                onBlur={() => {
                  handleOpen(false)
                }}
                onKeyDown={handleKeyDown}
              />
            ) : showTags ? (
              <>
                {value.map((opt, index) => (
                  <Tag
                    key={index}
                    color={opt.color}
                    ellipsis={false}
                    onClose={(event) => {
                      event.stopPropagation()
                      handleSelect(opt)
                    }}
                  >
                    {opt.label}
                  </Tag>
                ))}
              </>
            ) : (
              <div
                className={classNames(
                  'text-sm font-semibold ml-1 whitespace-nowrap overflow-x-hidden',
                  {
                    [theme.text]: value.length !== 0,
                    [theme.placeholder]: value.length === 0,
                  },
                )}
              >
                {value.length > 0 ? `${value.length} éléments sélectionnés` : placeholder}
              </div>
            )}
          </div>
        </div>
        {value.length > 0 && (
          <div
            className="ml-1 mr-2"
            onClick={(event) => {
              onSelect([])
              event.stopPropagation()
            }}
          >
            <Icon
              size="nano"
              icon="cross"
              rotate={open ? 180 : 0}
              color={getCssVariable('shades', 'shade5')}
            />
          </div>
        )}
        <div className="flex-shrink-0">
          <Icon
            size="pico"
            icon="chevron"
            rotate={open ? 180 : 0}
            color={getCssVariable('shades', 'shade5')}
          />
        </div>
      </div>
      <DropdownOverlay display={open} scrollRef={scrollRef}>
        {computedOptions.map((option, index) => (
          <div
            key={index}
            onMouseDown={(event) => {
              handleSelect(option)
              event.preventDefault()
            }}
            ref={index === hoveredIndex ? hoveredRef : undefined}
          >
            {renderOption(option, value, index === hoveredIndex)}
          </div>
        ))}
      </DropdownOverlay>
    </div>
  )
}
