import { ApiResponse } from 'apisauce'
import { sanitizeToBasicHtml } from '../../../misc/sanitizer.utilities'
import { LegalDocument } from '../../../model/LegalDocument'
import { PaginatedList } from '../../../model/Pagination'

export const sanitizeLegalDocumentResponse = ({
  data,
  ...response
}: ApiResponse<PaginatedList<LegalDocument>>): ApiResponse<PaginatedList<LegalDocument>> => {
  if (response.ok && data) {
    return {
      ...response,
      data: {
        ...data,
        items: data.items.map((legalDocument) => {
          return { ...legalDocument, content: sanitizeToBasicHtml(legalDocument.content) }
        }),
      },
    }
  } else {
    return response
  }
}
