import getApi from '../../../../api'
import { ApiPatientResource } from '../../../../data/patients/mapper/patients.model'
import { PaginatedList } from '../../../../model/Pagination'
import { LightPatient, Patient } from '../../../../model/Patient'
import { RestuxApiConfig } from '../../../restux/cache/restuxCache.model'
import { deserializeLightPatients } from './PatientSearch.mapper'

export const restuxPatientSearchApiConfig: RestuxApiConfig<LightPatient, Patient> = {
  url: '/patients',
  getPaginatedItems: (route) =>
    getApi().get<PaginatedList<ApiPatientResource>>(route).then(deserializeLightPatients),
}
