import { IconsType } from '../components/shared'
import { DocumentCategoryKeys, DocumentCategoryIcon } from './DocumentCategory'
import { MedicalEventFileType, FileKind } from './File'
import { Question } from './Questionnaire'
import { FollowTypes } from '@follow/cdk'

export type MedicalEvent = FollowTypes.MedicalEvent.MedicalEvent
export type MedicalEventContentType = DocumentCategoryKeys | FileKind

export interface DocumentQuestionList {
  documentId: number
  title: string
  questions: ReadonlyArray<Question>
}

export interface WithFilledIndicatorDocumentQuestionList extends DocumentQuestionList {
  isFilled: boolean
}
export interface DocumentQuestionListWithAnswerValues
  extends Omit<DocumentQuestionList, 'questions'> {
  questions: ReadonlyArray<Question>
}
export const MedicalEventContentTypeIcon: { [type in MedicalEventContentType]: IconsType } = {
  ...DocumentCategoryIcon,
  [MedicalEventFileType.IMAGE]: 'image',
  [MedicalEventFileType.FILE]: 'documentDefault',
}

export enum MedicalEventDocumentType {
  FW_DOCUMENT = 'fwDocument',
  OBSERVATIONS = 'observations',
  FILE = 'files',
  ADDING = 'adding',
}

export type TimelineEventType =
  | DocumentCategoryKeys
  | 'AddMedicalEventAction'
  | 'MedicalEvent'
  | 'CreatedPatientEvent'
