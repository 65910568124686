import { connect } from 'react-redux'
import { RootState } from '../../../store'
import { domainMailActions } from '../../../store/domain/mail'
import {
  inUseAttachmentSelector,
  inUseMailSelector,
  inUseMailStatusSelector,
} from '../../../store/domain/mail/mail.selector'
import { MailPage } from './MailPage.component'
import { MailDispatchProps, MailStoreProps } from './MailPage.model'

const mapStateToProps = (state: RootState): MailStoreProps => ({
  inUseMail: inUseMailSelector(state),
  inUseMailStatus: inUseMailStatusSelector(state),
  selectedFile: inUseAttachmentSelector(state),
})

const mapDispatchToProps: MailDispatchProps = {
  getAttachmentFile: domainMailActions.getAttachmentFile,
  setAttachment: domainMailActions.setInUseAttachment,
  getInUseMail: domainMailActions.getInUseMail,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)(MailPage)
export { withConnect as MailPage }
