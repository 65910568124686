import { useMutation, useQueryClient } from '@tanstack/react-query'
import { MedicalEvent } from '../../../model/MedicalEvent'
import { createMedicalEvent } from '../../../data/medicalEvents'
import { UserFriendlyError } from '../../../model/Error'
import { medicalEventsKeys } from './medicalEvents.keys'
import { MedicalEventMutationPayload } from '../../../data/medicalEvents/mapper/medicalEvents.model'

export const useCreateMedicalEvent = () => {
  const queryClient = useQueryClient()

  return useMutation<MedicalEvent, Error, MedicalEventMutationPayload>(
    async (payload) => {
      const { data, ok } = await createMedicalEvent(payload)
      if (!data || !ok) {
        throw new UserFriendlyError('error', `La création de l'évènement médical a échoué`)
      }
      return data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(medicalEventsKeys.lists)
      },
    },
  )
}
