import React, { ChangeEvent, FunctionComponent, useCallback, useMemo, useState } from 'react'

import { SearchInput, GridLayout, MultiSelectSearch } from '../../../shared'
import { ROLES_TYPES, Roles } from '../../../../model/Roles'
import { MultiSelectOption, SelectOption } from '../../../../model/SelectOption'
import { useTimeoutEffect } from '../../../../hooks/utils'
import { timeBeforeStartingResearch } from '../../../../constants'

interface UserListActionsProps {
  filters: UsersFilters
  onFiltersChange: (filters: UsersFilters) => void
}

export type UsersFilters = { search: string; roles: Roles[] }

const roleOptions: SelectOption<Roles>[] = Object.entries(ROLES_TYPES)
  .map(([key, value]) => ({
    label: value.title,
    value: key as Roles,
  }))
  .sort((a, b) => a.label.localeCompare(b.label))

const mapRoleOptionToRoleValue = (roles: MultiSelectOption<Roles>[]): Roles[] =>
  roles.map((roleOption) => roleOption.value)

export const UserListActions: FunctionComponent<UserListActionsProps> = ({
  filters,
  onFiltersChange,
}) => {
  const [search, setSearch] = useState(filters.search)

  const rolesFilterValue: MultiSelectOption<Roles>[] = useMemo(
    () =>
      filters.roles.map((roleFilter) => ({
        label: ROLES_TYPES[roleFilter].title,
        value: ROLES_TYPES[roleFilter].id,
      })),
    [filters.roles],
  )

  const handleSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }, [])

  const handleRolesSelection = useCallback(
    (roles: MultiSelectOption<Roles>[]) =>
      onFiltersChange({ ...filters, roles: mapRoleOptionToRoleValue(roles) }),
    [filters, onFiltersChange],
  )

  useTimeoutEffect(
    () => {
      if (search !== filters.search) {
        onFiltersChange({ ...filters, search: search })
      }
    },
    timeBeforeStartingResearch,
    [search],
  )

  return (
    <GridLayout columnsTemplate="1fr 1fr auto" gap="medium" rowsOnMobile={2}>
      <SearchInput
        placeholder="Rechercher un utilisateur (par nom ou ID)"
        value={search}
        onChange={handleSearchChange}
        testId="search-user-name-or-id"
        autofocus
      />
      <MultiSelectSearch
        placeholder="Rechercher par rôle"
        icon="profile"
        options={roleOptions}
        value={rolesFilterValue}
        testId="select-user-role"
        onSelect={handleRolesSelection}
      />
    </GridLayout>
  )
}
