import { LightPatient } from '../../../../model/Patient'
import { ApiResponse } from 'apisauce'
import { PaginatedList } from '../../../../model/Pagination'
import { deserializeIdentityStatus } from '@follow/cdk'
import { ApiPatientResource } from '../../../../data/patients/mapper/patients.model'

export function deserializeLightPatients(
  pageResponse: ApiResponse<PaginatedList<ApiPatientResource>>,
): ApiResponse<PaginatedList<LightPatient>> {
  const { data, ...responseRest } = pageResponse
  // Error case => no deserialization
  if (!pageResponse.ok || !data) {
    return { ...responseRest }
  }
  return {
    ...responseRest,
    data: { ...data, items: data.items.map(deserializeLightPatient) },
  }
}
export function deserializeLightPatient(patient: ApiPatientResource): LightPatient {
  return {
    ...patient,
    identityStatus: deserializeIdentityStatus(patient.identityStatus),
  }
}
