import { CancelToken } from 'apisauce'
import getApi from '../../../../api'

import {
  DocumentHistory,
  DocumentRenderHistory,
  DocumentWithRevision,
} from '../../../../model/DocumentHistory'
import { PaginatedList } from '../../../../model/Pagination'

import { restuxDocumentInstanceApiConfig } from '../../documentInstances/api'
import { restuxDocumentTemplateApiConfig } from '../../documentTemplates/api'
import { deserializeDocumentRevisionResponse } from './DocumentHistory.mapper'

type CancelTokenSource = ReturnType<typeof CancelToken.source>

export type GetDocumentHistoryRequest = (
  documentId: number,
  token: CancelTokenSource,
) => Promise<DocumentWithRevision[]>

export const createDocumentInstanceHistoryRevision = (documentId: number) =>
  getApi().post(`${restuxDocumentInstanceApiConfig.url}/${documentId}/history`)

export const createDocumentTemplateHistoryRevision = (documentId: number) =>
  getApi().post(`${restuxDocumentTemplateApiConfig.url}/${documentId}/history`)

export const getDocumentInstanceHistory = (documentId: number, token: CancelTokenSource) =>
  getApi()
    .get<PaginatedList<DocumentHistory>>(
      `${restuxDocumentInstanceApiConfig.url}/${documentId}/history`,
      {},
      {
        cancelToken: token.token,
      },
    )
    .then(deserializeDocumentRevisionResponse)

export const getDocumentInstanceRenderHistory = (documentId: number, token: CancelTokenSource) =>
  getApi()
    .get<PaginatedList<DocumentRenderHistory>>(
      `${restuxDocumentInstanceApiConfig.url}/${documentId}/rendering_history`,
      {},
      {
        cancelToken: token.token,
      },
    )
    .then(deserializeDocumentRevisionResponse)

export const getDocumentTemplateHistory = (documentId: number, token: CancelTokenSource) =>
  getApi()
    .get<PaginatedList<DocumentHistory>>(
      `${restuxDocumentTemplateApiConfig.url}/${documentId}/history`,
      {},
      {
        cancelToken: token.token,
      },
    )
    .then(deserializeDocumentRevisionResponse)
