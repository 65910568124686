import React, { FunctionComponent, useContext } from 'react'
import { ConnectedUserContext } from '../../misc/auth.utilities'
import { RoleRestrictionProps } from './RoleRestriction.model'

const RoleRestriction: FunctionComponent<RoleRestrictionProps> = ({
  roles,
  children,
  useUsurpedUser = false,
  user = null,
}) => {
  const { loggedUser, usurpedUser } = useContext(ConnectedUserContext)
  let activeUser = usurpedUser && useUsurpedUser ? usurpedUser : loggedUser

  if (user) {
    activeUser = user
  }

  return (
    <>
      {(roles.some((role) => activeUser?.roles.includes(role)) || roles.length === 0) && children}
    </>
  )
}

export default RoleRestriction
