import React, { FunctionComponent, ReactNode } from 'react'
import styled from '@emotion/styled'

import { blueColor } from '../../../core/TextEditor.style'

interface DropdownItemProps {
  children?: ReactNode
  onClick?: (evt: React.MouseEvent) => void
}

const Item = styled.div`
  padding: 1rem;
  text-align: left;
  white-space: nowrap;
  background-color: transparent;
  color: #555878;

  &:hover {
    color: ${blueColor};
    background-color: $color-brand-primary;
  }
`

export const DropdownItem: FunctionComponent<DropdownItemProps> = ({ onClick, children }) => (
  // Utilisation de onMouseDown pour ne pas perdre le focus dans l'éditeur
  <Item onMouseDown={onClick} role="button">
    {children}
  </Item>
)
