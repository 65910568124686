import { useMutation, useQueryClient } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { deleteDocumentLayout } from '../../../data/documentLayouts'
import { documentLayoutsKeys } from './documentLayouts.keys'

interface DocumentLayoutMutateVariable {
  documentLayoutId: number
}

export const useDeleteDocumentLayout = () => {
  const queryClient = useQueryClient()

  return useMutation<null, Error, DocumentLayoutMutateVariable>(
    async ({ documentLayoutId }) => {
      const { ok } = await deleteDocumentLayout(documentLayoutId)
      if (!ok) {
        throw new UserFriendlyError('error', 'La suppression de la mise en page a échoué')
      }
      return null
    },
    {
      onSuccess: (_, { documentLayoutId }) => {
        queryClient.invalidateQueries(documentLayoutsKeys.lists)
        queryClient.removeQueries(documentLayoutsKeys.detail(documentLayoutId))
      },
    },
  )
}
