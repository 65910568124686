import getApi from '../../api/apisauceConfig'
import { singleItemPageLimit } from '../../constants'
import { Filters } from '../../model/Filters'
import { PaginatedList } from '../../model/Pagination'
import { deserializeMedicalEventPaginatedResponse } from './mapper/medicalEvents.mapper'
import { ApiMedicalEventResource } from './mapper/medicalEvents.model'

export const getMedicalEvents = (
  page: number,
  filters?: Filters,
  signal?: AbortSignal,
  limit = singleItemPageLimit,
) =>
  getApi()
    .get<PaginatedList<ApiMedicalEventResource>>(
      '/medical_events',
      {
        page,
        limit,
        ...filters,
      },
      { signal },
    )
    .then(deserializeMedicalEventPaginatedResponse)
