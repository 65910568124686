import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { billingSephiraKey } from './sephira.keys'
import { generateSephiraToken } from '../../../../data/billing'

export const useGetSephiraToken = (enabled: boolean) => {
  const queryKey = useMemo(() => billingSephiraKey.token, [])
  const expirationTime = 1000 * 60 * 50

  return useQuery(
    queryKey,
    async () => {
      const { data, ok } = await generateSephiraToken()

      if (!data || !ok) {
        throw new Error('Impossible de récupérer le jeton de connexion Follow Vitale.')
      }

      return data
    },
    {
      staleTime: expirationTime,
      cacheTime: expirationTime,
      refetchInterval: expirationTime,
      refetchIntervalInBackground: true,
      enabled: enabled,
    },
  )
}
