import { PluginNames } from '@/model/Plugins'
import { VariableAttrbs, VariableDataAttrbs } from '@/model/Variable'
import { Node } from '@tiptap/core'
import { mergeAttributes } from '@tiptap/react'

export const variableFlattenBlock = Node.create({
  name: PluginNames.VariableFlattenBlock,
  group: 'block',
  content: 'block*',

  addAttributes() {
    return {
      [VariableAttrbs.flatId]: {
        default: null,
        parseHTML: (element) => element.getAttribute(VariableDataAttrbs.flatId),
      },
      [VariableAttrbs.displayConfig]: {
        default: null,
        parseHTML: (element) => element.getAttribute(VariableDataAttrbs.displayConfig),
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'div',
        getAttrs(node) {
          const id = node.getAttribute(VariableDataAttrbs.flatId)
          return !!id && null
        },
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    const flatId = HTMLAttributes[VariableAttrbs.flatId]
    const displayconfig = HTMLAttributes[VariableAttrbs.displayConfig]

    return [
      'div',
      mergeAttributes(this.options.HTMLAttributes, {
        [VariableDataAttrbs.flatId]: flatId,
        [VariableDataAttrbs.displayConfig]: displayconfig,
      }),
      0,
    ]
  },
})
