import { DEFAULT_FONT_FAMILIES, DEFAULT_FONT_SIZES, ToolbarConfig } from '@follow/farte'

export const NOTES_TOOLBAR_CONFIG: ToolbarConfig = {
  categories: {
    history: ['undo', 'redo'],
    mark: ['bold', 'italic', 'underline'],
    align: ['alignLeft', 'alignCenter', 'alignRight', 'justify'],
    list: ['bulletList', 'numberedList'],
  },
  blockTypes: ['title1', 'title2', 'title3', 'paragraph'],
  fontFamilies: DEFAULT_FONT_FAMILIES,
  fontSizes: DEFAULT_FONT_SIZES,
}
