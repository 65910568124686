import * as Yup from 'yup'

export const ContactValidationSchema = Yup.object().shape({
  title: Yup.string().notRequired(),
  firstName: Yup.string().notRequired(),
  familyName: Yup.string().required('Obligatoire'),
  profession: Yup.object().nullable().notRequired(),
  speciality: Yup.object().nullable().notRequired(),
  mssanteAddress: Yup.string().notRequired(),
  notes: Yup.string().notRequired(),
  organizations: Yup.array().of(
    Yup.object().shape({
      emailAddress: Yup.string().email('Format invalide'),
      phoneNumber: Yup.string()
        .min(9, 'Le numéro doit contenir au moins 9 chiffres')
        .max(15, 'Le numéro doit contenir maximum 15 chiffres')
        .notRequired(),
      address: Yup.object().shape({
        street: Yup.string().nullable(),
        zipCode: Yup.string().nullable(),
        city: Yup.string().nullable(),
        country: Yup.string().nullable(),
      }),
    }),
  ),
})
