import getApi from '../../../../api/apisauceConfig'
import { PaginatedList } from '../../../../model/Pagination'
import { RestuxApiConfig } from '../../../restux/cache/restuxCache.model'
import { deserializePatientManualPrescriptionsPaginatedResponse } from './PatientManualPrescriptions.mapper'
import { SerializedManualPrescription } from './PatientManualPrescriptions.model'
import { ManualPrescriptionItem } from '../../../../model/ManualPrescription'

export const manualPrescriptionsBaseURL = 'patients/:patientId/manual_prescriptions'

export const restuxPatientManualPrescriptionsApiConfig: RestuxApiConfig<
  ManualPrescriptionItem,
  ManualPrescriptionItem
> = {
  url: manualPrescriptionsBaseURL,
  getPaginatedItems: (route) =>
    getApi()
      .get<PaginatedList<SerializedManualPrescription>>(route)
      .then(deserializePatientManualPrescriptionsPaginatedResponse),
}
