import Image from '@tiptap/extension-image'
import { mergeAttributes } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'
import { ImageResizeComponent } from './ImageResize.component'
import { PluginNames } from '@/model/Plugins'

export const IMAGE_DEFAULT_WIDTH = 500

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    [PluginNames.ImageResize]: {
      setImage: (options: { src: string; width?: string | number }) => ReturnType
    }
  }
}

export const ImageResize = Image.extend({
  name: PluginNames.ImageResize,

  draggable: true,

  addAttributes() {
    return {
      ...this.parent?.(),
      width: {
        default: IMAGE_DEFAULT_WIDTH,
      },
      float: {},
      textAlign: {
        default: 'center',
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'img',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['img', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)]
  },

  addNodeView() {
    return ReactNodeViewRenderer(ImageResizeComponent)
  },
})
