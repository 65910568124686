import React, { FunctionComponent, useContext, useMemo, useState } from 'react'
import { withWidthLowerObserver } from '../../../../misc/WidthObserver.hoc'
import { withTrackingEvent, FwTrackingEvent } from '../../../../misc/Tracking'
import { breakMedium } from '../../../../misc/responsive'

import { PatientsListActionsProps } from './PatientsListActions.model'
import {
  GridLayout,
  MultiSelectSearch,
  SearchInput,
  SelectSearch,
  useSelectSearchFiltering,
} from '../../../shared'
import { ConnectedUserContext } from '../../../../misc/auth.utilities'
import { computeTeamsDoctorsOptions } from '../../../../misc/team.utilities'
import { useTagSearch } from '../../../../hooks/utils'
import { Sex } from '../../../../model/Patient'
import { SelectOption } from '../../../../model/SelectOption'
import { isDefined } from '../../../../misc/functions.utilities'
import { useDebounce } from 'react-use'

const followedPatient = 'followedPatient'

const patientSexOptions: SelectOption<Sex>[] = [
  { value: Sex.MALE, label: 'Masculin', icon: 'male' },
  { value: Sex.FEMALE, label: 'Féminin', icon: 'female' },
  { value: Sex.UNKNOWN, label: 'Indéterminé' },
]

const PatientsListActions: FunctionComponent<PatientsListActionsProps> = ({
  filters: { search, tagIds, userIds, myFollowedPatient, sex },
  onSearchFiltersChange,
  tags,
  userTeams,
  isLowerThanBreakpoint,
}) => {
  const [debouncedFilters, setDebouncedFilters] = useState({ search: search })
  const { currentUser } = useContext(ConnectedUserContext)
  const trackFocusOnSearchField = withTrackingEvent(FwTrackingEvent.SEARCH_PATIENT)(() => {
    // Nothing to do here
  })

  useDebounce(
    () => {
      onSearchFiltersChange(debouncedFilters)
    },
    500,
    [debouncedFilters],
  )

  // Wording conditionel sur desktop
  const placeholder = isLowerThanBreakpoint
    ? 'Rechercher un patient'
    : 'Rechercher un nom ou une date de naissance complète (ex: 17/03/1989)'

  // Options pour le filtrage par médecins
  const doctorSearchOptions = useMemo(
    () =>
      currentUser
        ? [
            {
              value: followedPatient,
              label: 'Mes patients suivis',
            },
            ...computeTeamsDoctorsOptions(userTeams, currentUser, 'Mes patients créés'),
          ]
        : [],
    [userTeams, currentUser],
  )

  const doctorValue = doctorSearchOptions.find(({ value }) =>
    myFollowedPatient ? value === followedPatient : value === userIds[0],
  )
  const [doctorText, setDoctorText, filteredDoctorOptions] = useSelectSearchFiltering(
    doctorValue,
    doctorSearchOptions,
  )

  const [tagOptions, tagValues] = useTagSearch(tagIds, tags)

  const sexValue = useMemo(
    () =>
      sex
        .map((sexItem) => patientSexOptions.find(({ value }) => value === sexItem))
        .filter(isDefined),
    [sex],
  )

  return (
    <GridLayout columnsTemplate="2fr 2fr 2fr 2fr" gap="medium" rowsOnMobile={2}>
      <SearchInput
        testId="search-patient-name"
        autofocus
        value={debouncedFilters.search}
        placeholder={placeholder}
        onChange={({ target: { value } }) =>
          setDebouncedFilters((prev) => ({ ...prev, search: value }))
        }
        onFocus={trackFocusOnSearchField}
      />
      <MultiSelectSearch
        placeholder="Rechercher par sexe"
        icon="sex"
        testId="search-patient-sex"
        value={sexValue}
        options={patientSexOptions}
        onSelect={(selected) => onSearchFiltersChange({ sex: selected.map(({ value }) => value) })}
      />
      <MultiSelectSearch
        placeholder="Rechercher par label"
        icon="tag"
        testId="search-patient-tags"
        value={tagValues}
        options={tagOptions}
        onSelect={(selected) =>
          onSearchFiltersChange({ tagIds: selected.map(({ value }) => value) })
        }
      />
      {doctorSearchOptions.length > 1 && (
        <SelectSearch
          placeholder="Rechercher par médecin"
          testId="search-patient-doctor"
          icon="profile"
          value={doctorSearchOptions.find(
            (option) => option.value === (myFollowedPatient ? followedPatient : userIds[0]),
          )}
          options={filteredDoctorOptions}
          onSelect={(selected) => {
            onSearchFiltersChange({
              userIds: selected && selected.value !== followedPatient ? [selected.value] : [],
              myFollowedPatient: selected?.value === followedPatient,
            })
          }}
          textValue={doctorText}
          onTextChange={setDoctorText}
          onFocus={() => setDoctorText('')}
        />
      )}
    </GridLayout>
  )
}

const withHoc = withWidthLowerObserver(breakMedium)(PatientsListActions)
export { withHoc as PatientsListActions }
