import React, { FunctionComponent, useMemo, useState } from 'react'
import { Spring } from 'react-spring/renderprops'
import { getCssVariable } from '../../../../design-system/utils'
import { AlertSeverity, AlertSeverityColor, AlertSeverityIcon } from '../../../../model/Alerts'
import { Icon } from '../../Icon'
import { AlertBannerProps } from './AlertBanner.model'
import { AlertBannerItem } from './AlertBannerItem'

export const AlertBanner: FunctionComponent<AlertBannerProps> = ({
  alerts,
  onIgnore,
  defaultOpen = false,
  testId,
}) => {
  const [open, setOpen] = useState(defaultOpen)

  const topSeverity = useMemo(
    () =>
      alerts?.reduce(
        (currentTopSeverity, alert) =>
          alert.severity && currentTopSeverity < alert.severity
            ? alert.severity
            : currentTopSeverity,
        AlertSeverity.info,
      ),
    [alerts],
  )

  return (
    <>
      {alerts.length > 0 &&
        (alerts.length > 1 ? (
          <>
            <div className="relative text-shades-8 bg-shades-1 py-5" data-test-id={testId}>
              <div className="flex items-center w-full">
                <div className="flex items-center basis-4/5 ml-6">
                  <div className="mr-4">
                    <Icon
                      icon={AlertSeverityIcon[topSeverity]}
                      size="normal"
                      color={getCssVariable('labels', AlertSeverityColor[topSeverity])}
                    />
                  </div>
                  <div className="text-base font-bold text-shades-white">
                    {`Plusieurs alertes liées au patient (${alerts
                      .map((alert) => alert.title)
                      .join(', ')})`}
                  </div>
                </div>
                <div
                  onClick={() => setOpen(!open)}
                  className="basis-1/5 flex justify-end mr-5 cursor-pointer"
                >
                  <Icon
                    icon="chevron"
                    rotate={open ? 0 : 270}
                    color={getCssVariable('shades', 'shade8')}
                    size="nano"
                  />
                </div>
              </div>
              <Spring
                from={{
                  height: !defaultOpen && open ? 0 : 'auto',
                  overflow: 'hidden',
                }}
                to={{
                  height: open ? 'auto' : 0,
                  overflow: 'hidden',
                }}
                after={{
                  overflow: open ? 'visible' : 'hidden',
                }}
              >
                {(springProps) => (
                  <div className="w-full absolute" style={springProps}>
                    {alerts.map((alert) => (
                      <AlertBannerItem
                        key={alert.code}
                        title={alert.title}
                        text={alert.text}
                        isActive={alert.isActive}
                        code={alert.code}
                        onIgnore={onIgnore}
                        actionButton={alert.actionButton}
                        parentIsList={true}
                        color={alert.color}
                        icon={alert.icon}
                        testId={`${testId}-item-${alert.code}`}
                      />
                    ))}
                  </div>
                )}
              </Spring>
            </div>
          </>
        ) : (
          <AlertBannerItem
            title={alerts[0].title}
            text={alerts[0].text}
            isActive={alerts[0].isActive}
            code={alerts[0].code}
            onIgnore={onIgnore}
            actionButton={alerts[0].actionButton}
            color={alerts[0].color}
            icon={alerts[0].icon}
            testId={`${testId}-item-${alerts[0].code}`}
          />
        ))}
    </>
  )
}
