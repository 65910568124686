import React from 'react'
import { UnsubscribeWidthWatcher, screenWidthLowerThan } from './responsive'

export interface WidthLowerObserverProps {
  isLowerThanBreakpoint?: boolean
}
export interface WidthObserverState {
  isLowerThanBreakpoint: boolean
}

export function withWidthLowerObserver(breakPoint: number) {
  return <OriginalProps extends {}>(
    WrappedComponent: React.ComponentType<OriginalProps & WidthLowerObserverProps>,
  ) =>
    class extends React.Component<OriginalProps & WidthLowerObserverProps, WidthObserverState> {
      private unsubscribeWidthWatcher: UnsubscribeWidthWatcher | undefined

      public state: WidthObserverState = {
        isLowerThanBreakpoint: false,
      }

      public componentDidMount() {
        this.unsubscribeWidthWatcher = screenWidthLowerThan(breakPoint, (isLower) =>
          this.setState({ isLowerThanBreakpoint: isLower }),
        )
      }

      public render() {
        const { isLowerThanBreakpoint } = this.state
        return <WrappedComponent {...this.props} isLowerThanBreakpoint={isLowerThanBreakpoint} />
      }

      public componentWillUnmount() {
        if (this.unsubscribeWidthWatcher) {
          this.unsubscribeWidthWatcher()
        }
      }
    }
}
