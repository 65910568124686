import { parseISO, format, formatDistanceToNow, parse, isValid } from 'date-fns'
import frLocal from 'date-fns/locale/fr'
import { ChangeEvent } from 'react'
import { isEvent, isInputEvent } from './events.utilities'
import { manageError } from '../errors/errors.handler'
import { isDate } from 'lodash'
import { SelectOption } from '../model/SelectOption'

export { getDistanceToNowInDays, getAge, getAgeWithUnit } from '@follow/cdk'

// 15/04/2000
export const FR_SHORT_DATE_REGEX = /^(0{1}[1-9]|[12]\d|3[01])[/](0{1}[1-9]|1[0-2])[/]\d{4}$/

// 2000-04-15
export const API_DATE_REGEX = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/

export function listFrenchMonths(): string[] {
  return [
    'janvier',
    'février',
    'mars',
    'avril',
    'mai',
    'juin',
    'juillet',
    'août',
    'septembre',
    'octobre',
    'novembre',
    'décembre',
  ]
}
export function getFrLocale() {
  return frLocal
}

export function formatFr(date: Date | number, formatStr: string) {
  return format(date, formatStr, {
    locale: frLocal,
  })
}

export function formatStringFr(date: string, formatStr: string) {
  return format(parseISO(date), formatStr, {
    locale: frLocal,
  })
}

export function formatNullableFr(date: Date | null, formatStr = DATE_FORMAT_FRONT_SHORT) {
  if (isDate(date) && isValid(date)) {
    return formatFr(date, formatStr)
  }

  return null
}

// 15/04/200 à 13:37
export function formatDateWithDaytime(date: Date) {
  return format(date, DATE_FORMAT_WITH_DAYTIME, {
    locale: frLocal,
  })
}

// 15/04/2000 -> 2000-04-15
export function formatShortFrStringToApiFormat(shortFrStr: string) {
  if (shortFrStr.match(FR_SHORT_DATE_REGEX) === null) {
    throw new Error('Invalid date format')
  }
  const [day, month, year] = shortFrStr.split('/')
  return `${year}-${month}-${day}`
}

// 2000-04-15T17:29:54+02:00 -> 2000-04-15
export function formatCompleteDateToApiFormat(completeDate: string) {
  return format(parseISO(completeDate), DATE_FORMAT_API)
}

export function parseApiFormat(date: string) {
  return parse(date, DATE_FORMAT_API, new Date())
}

export function getDistanceInWordsToNow(
  date: Date,
  options?: {
    includeSeconds?: boolean
    addSuffix?: boolean
    locale?: object
  },
): string {
  options = options || {}
  options.locale = getFrLocale()
  return formatDistanceToNow(date, options)
}

export const DATE_FORMAT_API = 'yyyy-MM-dd'
export const DATE_FORMAT_FRONT_SHORT = 'dd/MM/yyyy'
export const DATE_FORMAT_VERBOSE_ALTERNATIVE = 'dd MMMM yyyy'
export const DATE_FORMAT_WITH_DAYTIME = 'dd/MM/yyyy à HH:mm'
export const DATE_FORMAT_VERBOSE = 'd MMMM yyyy'
export const DATE_FORMAT_SHORT_WITH_HYPHENS = 'dd-MM-yyyy'

const ISO8601_DATE_REGEX =
  /^(\d{4})(-(\d{2}))??(-(\d{2}))??(T(\d{2}):(\d{2})(:(\d{2}))??(\.(\d+))??(([+-]{1}\d{2}:\d{2})|Z)??)??$/
/**
 *  Match "dd"
 */
const DAY_REGEX = /^(0{1}[1-9]|[12]\d|3[0-9])/

/**
 *  Match "dd/mm"
 */
const DAY_AND_MONTH_REGEX = /^(0{1}[1-9]|[12]\d|3[0-9])[/](0{1}[1-9]|1[0-2])/

/**
 *  Match date format "dd/mm/yyyy" *
 */

export function inputDateFormatter(input: string) {
  try {
    const isIsoDate = input.match(ISO8601_DATE_REGEX) !== null
    if (isIsoDate) {
      return formatStringFr(input, 'dd/MM/yyyy')
    }
    if (input.length === 2 && input.match(DAY_REGEX) !== null) {
      return `${input}/`
    }
    if (input.length === 5 && input.match(DAY_AND_MONTH_REGEX) !== null) {
      return `${input}/`
    }
    if (input.length > 10) {
      return input.slice(0, 10)
    }
    return input
  } catch (error) {
    manageError(error as Error)
    return input
  }
}

export function applyInputDateMask(
  input: ChangeEvent<HTMLInputElement> | string,
): [string, boolean] {
  if (isEvent(input)) {
    const formatted =
      isInputEvent(input.nativeEvent) && input.nativeEvent.inputType !== 'deleteContentBackward'
        ? inputDateFormatter(input.target.value)
        : input.target.value

    const isValid = formatted.match(FR_SHORT_DATE_REGEX) !== null
    return [formatted, isValid]
  } else {
    const formatted = inputDateFormatter(input)
    const isValid = formatted.match(FR_SHORT_DATE_REGEX) !== null
    return [formatted, isValid]
  }
}

export const computeTimeZoneOptions = (): Array<SelectOption<string>> => {
  const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const supportedZones = Intl.supportedValuesOf('timeZone') ?? []

  const options = supportedZones.reduce<Array<SelectOption<string>>>((acc, zone) => {
    const option = {
      label: zone.replaceAll('/', ', '),
      value: zone,
    }
    // On positionne la time zone active au début de la liste des options
    if (zone === currentTimeZone) {
      return [option, ...acc]
    } else {
      return [...acc, option]
    }
  }, [])

  return options
}
