import React, { FunctionComponent } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'

import { WithVariableValueToHtml, JSONToValue } from '@follow/farte'
import { HeaderFooterProps } from './component-store.model'

export const PrintFooter: FunctionComponent<HeaderFooterProps> = ({
  template,
  variablesData,
  token,
}) => <WithVariableValueToHtml value={JSONToValue(template)} data={variablesData} token={token} />
PrintFooter.displayName = 'PrintFooter'

export function PrintFooterToHtml({ template, variablesData }: HeaderFooterProps, token?: string) {
  return renderToStaticMarkup(
    <PrintFooter template={template} variablesData={variablesData} token={token} />,
  )
}

export default PrintFooter
