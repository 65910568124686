import { NormalVariableData, VariableTheme } from '@follow/farte'
import {
  BaseVariableId,
  BaseVariableIdType,
  DocumentBaseVariableId,
  HTMLTypeBaseVariableId,
  MedicalEventBaseVariableId,
  ModuleDataBaseVariableId,
  OtherBaseVariableId,
  PatientBaseVariableId,
  SlateTypeBaseVariableId,
  TreatmentBaseVariableId,
} from '@follow/cdk'

export const EMPTY_VARIABLE_THEME: VariableTheme = {
  txtColor: '#ff2525',
  backgroundColor: 'rgb(220, 236, 255)',
}

export const DEFAULT_VARIABLE_THEME: VariableTheme = {
  txtColor: '#3273bf',
  backgroundColor: 'rgb(220, 236, 255)',
}

export const DEPRECATED_BASE_VARIABLES_LIST_IDS: ReadonlyArray<string> = [
  BaseVariableId.RECIPIENT_ASSIGNED_DOCTOR,
]

const PATIENT_BASE_VARIABLES_NAMES: Record<PatientBaseVariableId, string> = {
  [PatientBaseVariableId.PATIENT_ADDRESS]: 'Patient > Adresse complète',
  [PatientBaseVariableId.PATIENT_ADDRESS_LINEBREAKLESS]:
    'Patient > Adresse complète (sans retour à la ligne)',
  [PatientBaseVariableId.PATIENT_ADDRESS_SHORT]: 'Patient > Adresse courte',
  [PatientBaseVariableId.PATIENT_ADDRESS_ZIPCODE]: 'Patient > Adresse > Code Postal',
  [PatientBaseVariableId.PATIENT_ADDRESS_CITY]: 'Patient > Adresse > Ville',
  [PatientBaseVariableId.PATIENT_AGE]: 'Patient > Age',
  [PatientBaseVariableId.PATIENT_AGE_WITH_UNIT]: 'Patient > Age avec unité',
  [PatientBaseVariableId.HEALTH_INSURANCE]: 'Patient > Assurance complémentaire',
  [PatientBaseVariableId.BIRTH_DATE]: 'Patient > Date de naissance (jj/mm/aaaa)',
  [PatientBaseVariableId.BIRTH_DATE_NO_HASH]:
    'Patient > Date de naissance (sans séparateurs : jjmmaaaa)',
  [PatientBaseVariableId.EMAIL_ADDRESS]: 'Patient > Email',
  [PatientBaseVariableId.SERIAL]: 'Patient > Matricule',
  [PatientBaseVariableId.ATTACHED_DOCTOR]: 'Patient > Médecin traitant',
  [PatientBaseVariableId.ADDRESSING_DOCTOR]: 'Patient > Adressant',
  [PatientBaseVariableId.PATIENT_NAME]: `Patient > Nom complet`,
  [PatientBaseVariableId.PATIENT_USED_NAME]: `Patient > Nom complet d'usage`,
  [PatientBaseVariableId.PATIENT_BIRTH_NAME]: `Patient > Nom complet de naissance`,
  [PatientBaseVariableId.PATIENT_BIRTH_NAME_AND_USED_NAME]: `Patient > Nom complet avec nom de naissance et d'usage`,
  [PatientBaseVariableId.PATIENT_FAMILYNAME]: `Patient > Nom`,
  [PatientBaseVariableId.PATIENT_FIRSTNAME]: 'Patient > Prénom',
  [PatientBaseVariableId.PATIENT_BIRTH_FIRSTNAME]: 'Patient > Prénom de naissance',
  [PatientBaseVariableId.PATIENT_BIRTH_LASTNAME]: 'Patient > Nom de naissance',
  [PatientBaseVariableId.PATIENT_USED_FIRSTNAME]: `Patient > Prénom utilisé`,
  [PatientBaseVariableId.PATIENT_USED_LASTNAME]: `Patient > Nom utilisé`,
  [PatientBaseVariableId.PATIENT_OTHER_FIRST_NAMES]: `Patient > Autres prénoms`,
  [PatientBaseVariableId.SECU_NUMBER]: 'Patient > Matricule INS',
  [PatientBaseVariableId.SECU_NUMBER_REDUCED]: 'Patient > Matricule INS (sans clé)',
  [PatientBaseVariableId.SECU_KEY]: 'Patient > Clé du Matricule INS',
  [PatientBaseVariableId.PHONE_NUMBER1]: 'Patient > Numéro de téléphone',
  [PatientBaseVariableId.PHONE_NUMBER1_TRIMMED]: 'Patient > Numéro de téléphone (sans espaces)',
  [PatientBaseVariableId.PROFESSION]: 'Patient > Profession',
  [PatientBaseVariableId.PHONE_NUMBER2]: 'Patient > Second numéro de téléphone',
  [PatientBaseVariableId.PHONE_NUMBER2_TRIMMED]:
    'Patient > Second numéro de téléphone (sans espaces)',
  [PatientBaseVariableId.PATIENT_CIVILITY]: 'Patient > Civilité',
  [PatientBaseVariableId.PATIENT_CIVILITY_ABBREVIATION]: 'Patient > Civilité (courte)',
  [PatientBaseVariableId.PATIENT_BIRTH_PLACE_CODE]: 'Patient > Code lieu de naissance',
  [PatientBaseVariableId.PATIENT_BIRTH_PLACE_CODE_CITY]:
    'Patient > Code lieu de naissance (Pays et ville)',
  [PatientBaseVariableId.PATIENT_ASSIGNED_DOCTOR_ADDRESS]: 'Patient > Adresse du médecin traitant',
  [PatientBaseVariableId.PATIENT_SEX]: 'Patient > Sexe',
  [PatientBaseVariableId.PATIENT_SEX_M_F_I]: 'Patient > Sexe (masculin / féminin / indéterminé)',
  [PatientBaseVariableId.E_IF_FEMALE]: 'Patient > Sexe > e si féminin',
  [PatientBaseVariableId.THIRD_PRONOUN]: 'Patient > Sexe > il ou elle',
  [PatientBaseVariableId.PERSONAL_PRONOUN]: 'Patient > Sexe > le ou la',
  [PatientBaseVariableId.POSSESIVE_ADJECTIVE]: 'Patient > Sexe > son ou sa',
  [PatientBaseVariableId.OBJECT_PRONOUN]: 'Patient > Sexe > lui ou elle',
  [PatientBaseVariableId.RECIPIENT_ASSIGNED_DOCTOR]: 'Médecin traitant (Ne plus utiliser)',
  [PatientBaseVariableId.PATIENT_OID]: "Identifiant d'objet (OID)",
}

const DOCUMENT_BASE_VARIABLE_NAMES: Record<DocumentBaseVariableId, string> = {
  [DocumentBaseVariableId.DOCUMENT_CREATION_DATE]: 'Document > Date de création (jj/mm/aaaa)',
  [DocumentBaseVariableId.DOCUMENT_CREATION_DATE_NO_HASH]:
    'Document > Date de création (sans séparateurs : jjmmaaaa)',
  [DocumentBaseVariableId.DOCUMENT_REPLACE_NOTE]: 'Document > Mention annule et remplace',
  [DocumentBaseVariableId.DOCUMENT_NAME]: 'Document > Nom du document',
  [DocumentBaseVariableId.DOCUMENT_START_GREETING]: 'Document > Formule de politesse de début',
  [DocumentBaseVariableId.DOCUMENT_END_GREETING]: 'Document > Formule de politesse de fin',
}

const TREATMENT_BASE_VARIABLE_NAMES: Record<TreatmentBaseVariableId, string> = {
  [TreatmentBaseVariableId.CURRENT_TREATMENTS]: 'Patient > Traitements en cours (tous)',
  [TreatmentBaseVariableId.CURRENT_DOCTOR_TREATMENTS]:
    'Patient > Traitements en cours (mes prescriptions)',
  [TreatmentBaseVariableId.CURRENT_EXTERNAL_TREATMENTS]:
    'Patient > Traitements en cours (autres praticiens)',
  [TreatmentBaseVariableId.PATIENT_ALLERGIES_AND_MEDICAL_HISTORY]:
    'Patient > Allergies et antécédents',
  [TreatmentBaseVariableId.PATIENT_ALLERGIES]: 'Patient > Allergies',
  [TreatmentBaseVariableId.DRUG_COUNT]: 'Médicaments > Nombre de médicaments prescrits',
}

const MEDICAL_EVENT_BASE_VARIABLE_NAMES: Record<MedicalEventBaseVariableId, string> = {
  [MedicalEventBaseVariableId.MEDICAL_EVENT_DATE]: `Évènement > Date du jour`,
  [MedicalEventBaseVariableId.MEDICAL_EVENT_DATE_NO_HASH]: `Évènement > Date du jour (sans séparateurs : jjmmaaaa)`,
  [MedicalEventBaseVariableId.MEDICAL_EVENT_DATE_SHORT]: `Évènement > Date du jour (avec séparateurs : jj/mm/aaaa)`,
  [MedicalEventBaseVariableId.MEDICAL_EVENT_DATE_DAY_NEXT]: `Évènement > Date du lendemain`,
  [MedicalEventBaseVariableId.MEDICAL_EVENT_DATE_DAY_BEFORE]: `Événement > Date de la veille`,
  [MedicalEventBaseVariableId.MEDICAL_EVENT_DATE_DELAY]: `Évènement > Délais opération`,
  [MedicalEventBaseVariableId.MEDICAL_EVENT_TITLE]: `Évènement > Nom de l'Évènement`,
  [MedicalEventBaseVariableId.DOCTOR_NAME]: 'Évènement > Nom du médecin',
  [MedicalEventBaseVariableId.DOCTOR_TITLE]: 'Évènement > Profession du médecin',
  [MedicalEventBaseVariableId.DOCTOR_QUALITY]: 'Évènement > Qualité du médecin',
  [MedicalEventBaseVariableId.DOCTOR_SPECIALITY]: 'Évènement > Spécialité du médecin',
  [MedicalEventBaseVariableId.DOCTOR_ACADEMIC_BACKGROUND_NO_LINE_BREAK]:
    'Évènement > Parcours académique du médecin (sans retour à la ligne)',
  [MedicalEventBaseVariableId.DOCTOR_ACADEMIC_BACKGROUND_LINE_BREAK]:
    'Évènement > Parcours académique du médecin (avec retour à la ligne)',
  [MedicalEventBaseVariableId.DOCTOR_ADDRESS]: 'Évènement > Adresse du médecin',
  [MedicalEventBaseVariableId.DOCTOR_ADDRESS_LINEBREAKLESS]:
    'Évènement > Adresse du médecin (sans retour à la ligne)',
  [MedicalEventBaseVariableId.DOCTOR_EMAIL]: 'Évènement > Email du médecin',
  [MedicalEventBaseVariableId.DOCTOR_EMAIL_MSS_MAILIZ]: 'Évènement > Email MSS Mailiz',
  [MedicalEventBaseVariableId.RPPS_NUMBER]: 'Évènement > numéro RPPS',
  [MedicalEventBaseVariableId.FINESS_NUMBER]: 'Évènement > numéro FINESS',
}

const MODULE_DATA_BASE_VARIABLE_NAMES: Record<ModuleDataBaseVariableId, string> = {
  [ModuleDataBaseVariableId.PATIENT_BLOOD_GROUP]: 'Patient > Groupe sanguin',
  [ModuleDataBaseVariableId.PATIENT_WEIGHT]: 'Patient > Poids',
  [ModuleDataBaseVariableId.PATIENT_SIZE]: 'Patient > Taille',
  [ModuleDataBaseVariableId.PATIENT_BMI]: 'Patient > IMC',
  [ModuleDataBaseVariableId.PATIENT_GYNECOLOGICAL_GRAVIDITY]: 'Patient > Nombre de gestités',
  [ModuleDataBaseVariableId.PATIENT_GYNECOLOGICAL_PARITY]: 'Patient > Nombre de parités',
  [ModuleDataBaseVariableId.PATIENT_GYNECOLOGICAL_NUMBER_OF_CHILDREN]: "Patient > Nombre d'enfants",
  [ModuleDataBaseVariableId.PATIENT_GYNECOLOGICAL_LAST_SMEAR]: 'Patient > Date du dernier frottis',
  [ModuleDataBaseVariableId.PATIENT_GYNECOLOGICAL_LAST_HIV_TEST]:
    'Patient > Date du dernier dépistage VIH',
  [ModuleDataBaseVariableId.PATIENT_GYNECOLOGICAL_LAST_HPV_TEST]:
    'Patient > Date du dernier dépistage VPH',
  [ModuleDataBaseVariableId.PATIENT_GYNECOLOGICAL_LAST_MAMMOGRAPHY]:
    'Patient > Date de la dernière mammographie',
  [ModuleDataBaseVariableId.PATIENT_GYNECOLOGICAL_CONTRACEPTION_METHOD]:
    'Patient > Mode de contraception',
  [ModuleDataBaseVariableId.PATIENT_GYNECOLOGICAL_PREGNANCY_TYPE]: 'Patient > Type de grossesse',
  [ModuleDataBaseVariableId.PATIENT_GYNECOLOGICAL_TWIN_PREGNANCY]:
    'Patient > Type de grossesse gémellaire',
  [ModuleDataBaseVariableId.PATIENT_GYNECOLOGICAL_LAST_MENSTRUAL_PERIOD]:
    'Patient > Date des dernières règles',
  [ModuleDataBaseVariableId.PATIENT_GYNECOLOGICAL_PREGNANCY_START]:
    'Patient > Date du début de grossesse',
  [ModuleDataBaseVariableId.PATIENT_GYNECOLOGICAL_DUE_DATE]: 'Patient > Date du terme',
  [ModuleDataBaseVariableId.PATIENT_GYNECOLOGICAL_PATHOLOGICAL_LEAVE_START]:
    'Patient > Début du congé pathologique',
  [ModuleDataBaseVariableId.PATIENT_GYNECOLOGICAL_MATERNITY_LEAVE_START]:
    'Patient > Début du congé maternité',
  [ModuleDataBaseVariableId.PATIENT_GYNECOLOGICAL_MATERNITY_LEAVE_END]:
    'Patient > Fin du congé maternité',
  [ModuleDataBaseVariableId.PATIENT_GYNECOLOGICAL_AMENORRHEA]: `Patient > Terme`,
  [ModuleDataBaseVariableId.PATIENT_SYSTOLIC_PRESSURE]: 'Patient > Tension systolique',
  [ModuleDataBaseVariableId.PATIENT_DIASTOLIC_PRESSURE]: 'Patient > Tension diastolique',
  [ModuleDataBaseVariableId.PATIENT_BLOOD_PRESSURE]: 'Patient > Tension artérielle',
}

const OTHER_BASE_VARIABLE_NAMES: Record<OtherBaseVariableId, string> = {
  [OtherBaseVariableId.TODAY]: `Aujourd'hui`,
  [OtherBaseVariableId.TODAY_COMPACT]: `Aujourd'hui (jj/mm/aaaa)`,
  [OtherBaseVariableId.MENTIONS_LEGALES]: 'Document > Mention RGPD',
}

const HTML_BASE_VARIABLES_NAMES: Record<HTMLTypeBaseVariableId, string> = {
  [HTMLTypeBaseVariableId.PATIENT_INS_QRCODE]: 'Patient > QR Code matricule INS',
  [HTMLTypeBaseVariableId.OBSERVATIONS]: 'Évènement > Observations',
  [HTMLTypeBaseVariableId.RPPS_BARCODE]: 'Évènement > code-barre RPPS',
  [HTMLTypeBaseVariableId.FINESS_BARCODE]: 'Évènement > code-barre FINESS',
  [HTMLTypeBaseVariableId.RECIPIENTS]: 'Document > Destinataires',
  [HTMLTypeBaseVariableId.SIGNATURE]: 'Évènement > Signature du médecin',
}

const FARTE_BASE_VARIABLES_NAMES: Record<SlateTypeBaseVariableId, string> = {
  [SlateTypeBaseVariableId.NOTES]: 'Patient > Notes',
}

const BASE_VARIABLES_NAMES: Record<BaseVariableIdType, string> = {
  ...PATIENT_BASE_VARIABLES_NAMES,
  ...DOCUMENT_BASE_VARIABLE_NAMES,
  ...TREATMENT_BASE_VARIABLE_NAMES,
  ...MEDICAL_EVENT_BASE_VARIABLE_NAMES,
  ...MODULE_DATA_BASE_VARIABLE_NAMES,
  ...OTHER_BASE_VARIABLE_NAMES,
  ...HTML_BASE_VARIABLES_NAMES,
  ...FARTE_BASE_VARIABLES_NAMES,
}

export const BASE_VARIABLES_LIST = Object.entries(BASE_VARIABLES_NAMES).reduce(
  (acc, [key, value]) => {
    return {
      ...acc,
      [key]: {
        type: 'normal',
        id: key,
        value,
        customTheme: DEFAULT_VARIABLE_THEME,
        fallbackValue: null,
      },
    }
  },
  {} as Record<BaseVariableIdType, NormalVariableData>,
)
