import { SharingConfigAccessLevel, SharingConfigKeys, Team } from '../model/Team'
import { LightUser, User } from '../model/User'
import { getFullName } from './user.utilities'

/*
 * Convertit une liste de teams en couples label-value
 * Le résultat est filtré pour ne contenir qu'une option par user
 * currentUser est toujours placé en première position de la liste
 */
export const computeTeamsDoctorsOptions = (
  teams: Team[],
  currentUser: User,
  replaceMyNameBy?: string,
  additionnalUsers: LightUser[] = [],
) => {
  if (teams.length === 0) return []

  const users = [...teams.map((team) => team.memberUsers).flat(), ...additionnalUsers]
  const options = [
    { value: currentUser.id, label: replaceMyNameBy ?? getFullName(currentUser, true) },
    ...users.map((member) => ({
      value: member.id,
      label: getFullName(member, true),
    })),
  ]
  return options.filter(
    (user, index, array) => array.findIndex((item) => item.value === user.value) === index,
  )
}

export const filterTeamBySharingConfig = (
  teams: Team[],
  key: SharingConfigKeys,
  accesses: SharingConfigAccessLevel[],
) => {
  return teams.filter(({ sharingConfig }) =>
    sharingConfig.some(
      ({ accessLevel, resourceName }) => resourceName === key && accesses.includes(accessLevel),
    ),
  )
}
