import { Filters } from '../../../model/Filters'

const all = ['documentLayouts']
const lists = [...all, 'list']
const listAll = [...lists, 'all']
const list = (filters?: Filters) => [...lists, filters]
const detail = (id: number) => [...all, id]

export const documentLayoutsKeys = {
  all,
  lists,
  listAll,
  list,
  detail,
}
