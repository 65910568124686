import { ApiResponse } from 'apisauce'
import { DATE_FORMAT_SHORT_WITH_HYPHENS, formatStringFr } from '../../../misc/date.utilities'
import { Statistics, StatisticsValue } from '../../../model/PatientStatistics'

const formatToFrenchDate = (statValue: StatisticsValue) => ({
  ...statValue,
  date: formatStringFr(statValue.date, DATE_FORMAT_SHORT_WITH_HYPHENS),
})

export const formatPatientStatisticsDates = (
  patientStatisticsApiResponse: ApiResponse<Statistics[]>,
) => ({
  ...patientStatisticsApiResponse,
  data: patientStatisticsApiResponse.data?.map((statistics) => ({
    ...statistics,
    values: statistics.values.map(formatToFrenchDate),
  })),
})
