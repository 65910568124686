import { useNavigate } from 'react-router-dom'
import { RoundedButton } from '../../../shared'
import styles from './MailNoAddresses.module.scss'

export const MailNoAddresses = () => {
  const navigate = useNavigate()
  return (
    <div className={styles.container}>
      <h3 className={styles.title}>Aucune adresse Mailiz renseignée</h3>
      <span className={styles.description}>
        Vous devez saisir votre adresse MSS Mailiz dans vos informations de compte afin d'accéder à
        votre boîte de réception dans Follow
      </span>
      <div className={styles.button}>
        <RoundedButton
          label="Saisir l'adresse Mailiz"
          onClick={() => navigate('/account/settings?focus=mailizEmailAddress')}
        />
      </div>
    </div>
  )
}
