import { call, put, takeEvery } from 'redux-saga/effects'
import { getServicesStatus } from './api'
import { ExternalServicesActionTypes } from './externalServices.model'
import { externalServicesActions } from './externalServices.actions'
import { addError } from '../../message/message.actions'

function* getServicesStatusWorker() {
  const response = yield call(getServicesStatus)
  if (response.ok) {
    yield put(externalServicesActions.setStatus(response.data))
  } else {
    addError(response)
  }
}

function* getServicesStatusWatcher() {
  yield takeEvery(ExternalServicesActionTypes.GET_STATUS, getServicesStatusWorker)
}

export const externalServicesSagas = {
  getServicesStatusWatcher,
}
