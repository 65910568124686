import { MessagesTypes } from './Notification'
import { SelectOption } from './SelectOption'

export interface EphemeralNotification {
  title: string
  shortMessage: string
  level: MessagesTypes
  type: string
  recipients?: Array<string>
  disableDuration?: boolean
}

export const EPHEMERAL_NOTIFICATION_LEVEL_OPTIONS: SelectOption<MessagesTypes>[] = [
  { label: 'Erreur', value: MessagesTypes.Error },
  { label: 'Warning', value: MessagesTypes.Warn },
  { label: 'Validation', value: MessagesTypes.Valid },
  { label: 'Info', value: MessagesTypes.Info },
]
