import { ContactGreetingMap } from '../model/types/Contact.types'

export const contactStartGreetingMap: ContactGreetingMap = {
  salutation_male_colleague: 'Cher Confrère,',
  salutation_female_colleague: 'Chère Consœur,',
  salutation_male_friend: 'Cher Ami,',
  salutation_female_friend: 'Chère Amie,',
  salutation_dear_colleague: 'Cher Collègue,',
  closing_colleague: 'Bien confraternellement,',
  closing_friendly: 'Bien amicalement,',
  closing_friendship: 'Amitiés,',
  closing_best_regards: 'Bien cordialement,',
}
