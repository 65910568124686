import { Prescription, PrescriptionVariable } from '../../../model/Prescription'

const RESOURCE_NAME = '@BOTTOM_PANEL_POSOLOGY'

export const uiBottomPanelPosologyActionTypes = {
  OPEN: `${RESOURCE_NAME}/OPEN`,
}

export type PosologyBottomPanelMode = 'create' | 'edit'

export type PosologyPanelOpenState =
  | {
      mode: 'create'
      selectedDrug: PrescriptionVariable
    }
  | {
      mode: 'edit'
      prescription: Prescription
    }

export interface BottomPanelPosologyState {
  mode: PosologyBottomPanelMode
  selectedDrug: PrescriptionVariable | null
  prescription: Prescription | null
}
