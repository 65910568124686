import React, { FunctionComponent } from 'react'
import { SelectInput } from '../../../../shared'
import { Roles } from '../../../../../model/Roles'
import { RoleSelectProps } from './RoleSelect.model'

export const RoleSelect: FunctionComponent<RoleSelectProps> = ({ value, options, onSelect }) => {
  return (
    <SelectInput<Roles>
      title="Rôle *"
      options={options.map((item) => ({ key: item.id, value: item.id, label: item.title }))}
      colorPreset="light"
      value={value}
      onSelect={onSelect}
    />
  )
}
