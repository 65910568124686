import { useField } from 'formik'
import React, { FunctionComponent } from 'react'
import { Input } from '../../Input'
import { FormikAucompleteInput } from './Input.model'

type FormikTextInputProps = FormikAucompleteInput & {
  type?: 'search' | 'text' | 'number' | 'password'
}

export const FormikTextInput: FunctionComponent<FormikTextInputProps> = React.forwardRef<
  HTMLDivElement,
  FormikTextInputProps
>(
  (
    {
      label,
      fieldName,
      placeholder,
      type = 'text',
      autocomplete = true,
      disabled,
      required = false,
      colorPreset,
      infos,
      transform,
      autofocus = false,
      onChange,
    },
    ref,
  ) => {
    const [field, meta, helpers] = useField<string>({ name: fieldName })

    return (
      <Input
        ref={ref}
        value={field.value ?? ''}
        label={label}
        name={fieldName}
        placeholder={placeholder}
        valid={!meta.touched ? undefined : !meta.error}
        onChange={(event) => {
          if (transform) {
            const value = transform(event)
            helpers.setValue(value)
          } else {
            helpers.setValue(event.target.value)
          }

          if (onChange) {
            onChange(event)
          }
        }}
        onBlur={field.onBlur}
        type={type}
        autocomplete="off"
        disabled={disabled}
        required={required}
        colorPreset={colorPreset}
        error={meta.error}
        infos={infos}
        autofocus={autofocus}
      />
    )
  },
)
