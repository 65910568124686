import {
  BottomPanelMailEditorState,
  uiBottomPanelMailEditorActionTypes,
} from './bottomPanelMailEditor.model'

export const bottomPanelMailEditorActions = {
  openEditor: (config?: Partial<BottomPanelMailEditorState>) => ({
    type: uiBottomPanelMailEditorActionTypes.OPEN_EDITOR,
    config,
  }),
}
