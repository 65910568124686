import * as Yup from 'yup'
import { OwnerModalForm } from './OwnerModal.model'

export const OwnerModalValidatorSchema = (initialValues: OwnerModalForm) =>
  Yup.object().shape({
    selectedUser: Yup.string().required(),
    includeSubResource: Yup.boolean().when(['selectedUser'], {
      is: (selectedUser) => selectedUser === initialValues.selectedUser,
      then: Yup.boolean().required().oneOf([true]),
    }),
  })
