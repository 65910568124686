import {
  PosologyPanelOpenState,
  uiBottomPanelPosologyActionTypes,
} from './bottomPanelPosology.model'

export const bottomPanelPosologyActions = {
  open: (parameters: PosologyPanelOpenState) => ({
    type: uiBottomPanelPosologyActionTypes.OPEN,
    mode: parameters.mode,
    prescription: parameters.mode === 'edit' ? parameters.prescription : null,
    selectedDrug: parameters.mode === 'create' ? parameters.selectedDrug : null,
  }),
}
