import { Task } from '../../../model/Task'
import { ApiResponse } from 'apisauce'
import { PaginatedList } from '../../../model/Pagination'
import { TaskApi } from './Task.model'

export const deserializeTask = (taskApi: TaskApi) => {
  return { ...taskApi, createdAt: new Date(taskApi.createdAt) }
}

export function deserializeTasks(
  pageResponse: ApiResponse<PaginatedList<TaskApi>, PaginatedList<TaskApi>>,
): ApiResponse<PaginatedList<Task>> {
  const { data, ...responseRest } = pageResponse
  // Error case => no deserialization
  if (!pageResponse.ok || !data) {
    return { ...responseRest }
  }
  return {
    ...responseRest,
    data: { ...data, items: data.items.map(deserializeTask) },
  }
}
