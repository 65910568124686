import { css } from '@emotion/react'
import { FormatingPluginMode } from './FormatingPlugin'

export const mainTxtColor = '#555878'
export const blueColor = '#3273bf'

export const getEditorStyles = (
  mode: string,
  disabled: boolean,
  writingMode?: FormatingPluginMode,
) => {
  return css`
    color: black;
    background-color: white;
    border-radius: 0 0 2px 2px;
    font-family: Montserrat, sans-serif;
    font-size: 0.9rem;
    line-height: 1.25;
    a[href] {
      cursor: pointer;
    }
    ${mode !== 'print' &&
    css`
      color: ${mainTxtColor};
      flex-grow: 1;
      padding: 2.5rem;
      @media (max-width: 1023px) {
        padding: 8px;
      }

      // ! Dirty !
      // Nécessaire pour les vielles version de slate qui s'exécutent sur Chrome
      ${!disabled ? '-webkit-user-modify: read-write !important;' : ''}
    `}
    ${writingMode === 'inline' &&
    css`
      & * {
        display: inline !important;
      }
    `}
  `
}
