import { VariablesData, VariableType, JSONToValue } from '@follow/farte'
import ComponentsStore from '../components'
import {
  VariableTypeJson,
  SlateVariableDataJson,
  RenderVariableDataJson,
  VariablesDataJson,
} from '../variables'

function serializeVariableData(variableData: VariableType): VariableTypeJson {
  switch (variableData.type) {
    case 'slate':
      const slateVariableDataJson: SlateVariableDataJson = {
        ...variableData,
        value: variableData.value?.toJSON() ?? null,
        fallbackValue: variableData.fallbackValue?.toJSON() ?? null,
      }
      return slateVariableDataJson
    case 'render':
      const componentName = variableData.value.renderer.displayName
      if (!componentName) {
        throw new Error(
          ` "VariableData" is incorrect. In Farte "VariablesData". All renderer component must have a "displayName".  `,
        )
      }
      const renderVariableDataJSon: RenderVariableDataJson = {
        ...variableData,
        value: {
          ...variableData.value,
          renderer: componentName,
        },
        fallbackValue: variableData.fallbackValue,
      }
      return renderVariableDataJSon
    case 'normal':
    default:
      return {
        ...variableData,
      }
  }
}

function deserializeVariableDataJson(variableDataJson: VariableTypeJson): VariableType {
  switch (variableDataJson.type) {
    case 'slate':
      const slateVariableData: VariableType = {
        ...variableDataJson,
        value: variableDataJson.value ? JSONToValue(variableDataJson.value) : null,
        fallbackValue: variableDataJson.fallbackValue
          ? JSONToValue(variableDataJson.fallbackValue)
          : null,
      }
      return slateVariableData
    case 'render':
      const renderVariableData: VariableType = {
        ...variableDataJson,
        value: {
          ...variableDataJson.value,
          renderer: ComponentsStore[variableDataJson.value.renderer],
        },
        fallbackValue: variableDataJson.fallbackValue,
      }
      return renderVariableData
    case 'normal':
    default:
      return { ...variableDataJson }
  }
}

function serialize(variablesData: VariablesData): VariablesDataJson {
  return Object.entries(variablesData)
    .map(([variableId, variableData]) => {
      const variableJson = serializeVariableData(variableData)
      return { variableId, variableJson }
    })
    .reduce((acc, { variableId, variableJson }) => {
      acc[variableId] = variableJson
      return acc
    }, {} as VariablesDataJson)
}

function deserialize(variablesDataJson: VariablesDataJson): VariablesData {
  return Object.entries(variablesDataJson)
    .map(([variableId, variableData]) => {
      const variable = deserializeVariableDataJson(variableData)
      return { variableId, variable }
    })
    .reduce((acc, { variableId, variable }) => {
      acc[variableId] = variable
      return acc
    }, {} as VariablesData)
}

export const VariableDataSerializer = {
  serialize,
  deserialize,
}
