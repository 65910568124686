import { parseToValueJSON } from '@follow/cdk'
import { EditorValueJSON, normalizeEditorValue } from '@follow/farte'

export { parseToValueJSON }

export const variableIdToFieldId = (variableId: string) => parseInt(variableId.substring(9), 10)

export const stringifyFromValueJSON = (value: EditorValueJSON | undefined) => {
  if (value === undefined) {
    return undefined
  }
  try {
    value = normalizeEditorValue(value)
  } catch (error) {
    console.error('FixInvalidEditorValueJSON', error)
  }
  return JSON.stringify(value)
}
