import { NotificationType } from '../../../model/Notification'

const all = ['notifications']
const types = (types?: NotificationType[]) => [...all, types]
const unread = (notifTypes?: NotificationType[], unread?: boolean) => [...types(notifTypes), unread]

export const notificationsKeys = {
  all,
  types,
  unread,
}
