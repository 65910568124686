import { ApiResponse } from 'apisauce'
import { takeEvery, call, put } from 'redux-saga/effects'
import { codesDomainActions } from './codes.actions'

import { getCodes } from './api'
import { CodeCategory } from '../../../model/Code'
import { PaginatedList } from '../../../model/Pagination'
import { isSuccessfulApiResponse } from '../../restux/cache/restuxCacheSagas.factory'
import { addResponseError } from '../../message'
import { CodesDomainActionsTypes } from './codes.model'

function* fetchCodesWorker() {
  const response: ApiResponse<PaginatedList<CodeCategory>> = yield call(getCodes)
  if (!isSuccessfulApiResponse(response)) {
    yield put(addResponseError(response))
  } else if (response.data) {
    yield put(codesDomainActions.fetchSuccess(response.data.items))
  }
}

function* fetchDocumentAnswerValuesWatcher() {
  yield takeEvery(CodesDomainActionsTypes.FETCH, fetchCodesWorker)
}

export const codesSagas = { fetchDocumentAnswerValuesWatcher }
