import { useDispatch } from 'react-redux'
import { patientInformationsKeys } from '../../queries/patientInformations/patientInformations.keys'
import { useSubscribeMutation } from '../mutation'
import { documentAlertsDomainActions } from '../../../store/domain/documentAlerts'
import { getVariableData } from '../../../store/renderer'

export const useMutatePatientInformations = () => {
  const dispatch = useDispatch()
  useSubscribeMutation(patientInformationsKeys.mutation, () => {
    dispatch(documentAlertsDomainActions.getAlerts())
    dispatch(getVariableData())
  })
}
