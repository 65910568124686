import { patientsKeys } from '../../queries/patients/patients.keys'
import { doctolibZipperUpdateOrCreatePatient } from '../../../misc/Doctolib/doctolib.actions'
import { followToDoctolibPatientMapper } from '../../../misc/Doctolib/doctolib.utils'
import { useSubscribeMutation } from '../mutation'
import { Patient } from '../../../model/Patient'
import { UpdatePatientMutateVariable } from '../../queries/patients'
import { zipperStatusAtom } from '../../../state/doctolib'
import { useAtomValue } from 'jotai'
import { DoctolibZipperStatus } from '../../../model/Doctolib'

export const useUpdateDoctolibPatient = () => {
  const zipperStatus = useAtomValue(zipperStatusAtom)

  useSubscribeMutation<Patient, UpdatePatientMutateVariable>(patientsKeys.update, (patient) => {
    if (zipperStatus === DoctolibZipperStatus.LOADED) {
      console.log(`Doctolib: Update patient with id ${patient.id}`)
      doctolibZipperUpdateOrCreatePatient(followToDoctolibPatientMapper(patient))
    }
  })
}
