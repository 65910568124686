import { Editor } from '@tiptap/react'

export interface ToolbarElementProps {
  editor: Editor
}
export const EDITOR_ALL_TOOLS = [
  'heading',
  'fontFamily',
  'fontSize',
  'marks',
  'colors',
  'align',
  'list',
  'lineHeight',
  'unformat',
  'undo',
  'pagebreak',
  'table',
  'image',
] as const

export type EditorTool = (typeof EDITOR_ALL_TOOLS)[number]
