import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { ErrorBoundaryContentProps } from './ErrorBoundaryContent.model'
import classNames from 'classnames/bind'
import styles from './ErrorBoundaryContent.module.scss'

const cx = classNames.bind(styles)

export const ErrorBoundaryContent: FC<ErrorBoundaryContentProps> = ({
  message = 'Une erreur est survenue',
  error,
  resetErrorBoundary,
}) => {
  const [errorHasBeenCopied, setErrorHasBeenCopied] = useState(false)

  const stringifiedError = useMemo(() => error?.toString() ?? JSON.stringify(error), [error])
  const isErrorWritable = stringifiedError && typeof stringifiedError === 'string'

  const timerId = useRef<NodeJS.Timeout | null>(null)
  useEffect(() => {
    return () => {
      if (timerId.current) clearTimeout(timerId.current)
    }
  }, [])
  const handleCopyError = () => {
    if (isErrorWritable) {
      if (timerId.current) clearTimeout(timerId.current)
      navigator.clipboard.writeText(stringifiedError).then(() => {
        setErrorHasBeenCopied(true)
        timerId.current = setTimeout(() => {
          setErrorHasBeenCopied(false)
        }, 2000)
      })
    }
  }

  return (
    <div className={styles['card']}>
      <p className={styles['message']}>{message}</p>
      <button className={cx('button', 'orange')} onClick={resetErrorBoundary}>
        Réessayer
      </button>
      {isErrorWritable && (
        <button className={cx('button', 'red')} onClick={handleCopyError}>
          {errorHasBeenCopied ? '🗸 Erreur copiée' : "Copier le détail de l'erreur"}
        </button>
      )}
    </div>
  )
}
