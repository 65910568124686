const all = ['patientHealthData']
const lists = [...all, 'list']
const types = [...all, 'types']
const mutation = [...all, 'mutation']
const detail = (patientId: number) => [...all, patientId]
const listAlerts = (patientId: number) => [...detail(patientId), 'alerts']
const getHistoryByPatientId = (patientId: number) => [...detail(patientId), 'patientHistory']
const getHistoryByPatientIdAndModuleDataTypeId = (patientId: number, moduleId: string) => [
  ...getHistoryByPatientId(patientId),
  'moduleId',
  moduleId,
]

export const patientHealthDataKeys = {
  all,
  lists,
  types,
  mutation,
  detail,
  listAlerts,
  getHistoryByPatientId,
  getHistoryByPatientIdAndModuleDataTypeId,
}
