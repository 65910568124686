import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { isDefined } from '../../../misc/functions.utilities'
import { PosologyFormResource } from '../../../model/Posology'
import { Button, Checkbox, CheckboxState, FullLoader, TextArea } from '../../shared'
import { PosologyForm, convertFormIntoPosology, isPosologyFormComplete } from '../PosologyForm'
import { PosologyBottomPanelProps } from './PosologyBottomPanel.model'
import { ContactCard, ContactSearchAutocomplete } from '../../contact'
import { autocompletionPageLimit } from '../../../constants'
import { RestuxLoadingState } from '../../../store/restux/ui'
import { useDebounce } from 'react-use'
import { BottomPanelContactsContext } from '../../../store/ui/bottomPanelContacts'
import { Contact, RecipientContact } from '../../../model/Contact'
import { mapContactToRecipientContact } from '../../../misc/contact.utilities'

export const PosologyBottomPanel: FC<PosologyBottomPanelProps> = ({
  actions: Actions,
  selectedDrug,
  mode,
  prescription,
  drugDetails,
  predictions,
  getDrugDetails,
  clearDrugDetails,
  createTreatment,
  updatePrescription,
  setBottomPanelOptions,
  searchContact,
  clearPredictions,
}) => {
  // Default intervals if current treatment already exist
  const intervals = useMemo(() => prescription?.posologyIntervals ?? [], [prescription])

  const [selectedIntervalId, setSelectedIntervalId] = useState<string | undefined>(
    intervals[0]?.id ?? 'new',
  )
  const [intervalForms, setIntervalForms] = useState<PosologyFormResource[]>([])
  const [prescriptionReason, setPrescriptionReason] = useState(
    prescription?.prescriptionReason ?? '',
  )
  const [isChronic, setIsChronic] = useState(prescription?.isChronic ?? false)
  const [searchValue, setSearchValue] = useState('')
  const [prescriberContact, setPrescriberContact] = useState<RecipientContact | null>(
    (prescription?.prescriber && mapContactToRecipientContact(prescription?.prescriber)) ?? null,
  )
  const [manualPrescriber, setManualPrescriber] = useState<string | null>(
    prescription?.manualPrescriber ?? null,
  )
  const drugs = useMemo(() => (drugDetails ? [drugDetails] : []), [drugDetails])

  useEffect(() => {
    if (mode === 'edit' && isDefined(prescription)) {
      const drug = prescription.drugs.at(0)
      if (drug) {
        getDrugDetails(drug)
      }
    }

    if (mode === 'create' && isDefined(selectedDrug)) {
      const drug = selectedDrug.drugs.at(0)
      if (drug) {
        getDrugDetails(drug)
      }
    }

    return () => {
      clearDrugDetails()
    }
  }, [clearDrugDetails, prescription, getDrugDetails, mode, selectedDrug])

  const areIntervalFormsValid = useMemo(
    () =>
      isDefined(drugDetails)
        ? intervalForms.every((interval) => isPosologyFormComplete(interval, [drugDetails]))
        : false,
    [drugDetails, intervalForms],
  )

  const handleClose = useCallback(() => {
    setBottomPanelOptions({
      open: false,
    })
  }, [setBottomPanelOptions])

  const handleSubmit = useCallback(() => {
    const posologyIntervals = convertFormIntoPosology(intervalForms)

    if (mode === 'create' && isDefined(selectedDrug)) {
      const drug = selectedDrug.drugs.at(0)
      if (drug) {
        createTreatment({
          drug,
          isAld: !!selectedDrug?.isAld,
          posologyIntervals,
          prescriptionReason,
          isChronic,
          prescriberId: prescriberContact?.id ?? null,
          manualPrescriber,
        })
      }
    }
    if (mode === 'edit' && isDefined(prescription)) {
      updatePrescription(prescription.uuid, {
        posologyIntervals,
        prescriptionReason,
        isChronic,
        prescriberId: prescriberContact?.id ?? null,
        manualPrescriber,
      })
    }
  }, [
    selectedDrug,
    intervalForms,
    prescriptionReason,
    isChronic,
    prescriberContact,
    manualPrescriber,
    mode,
    prescription,
    updatePrescription,
    createTreatment,
  ])

  useDebounce(
    () => {
      handleSearchContact()
    },
    400,
    [searchValue],
  )

  const handleSearchContact = () => {
    if (searchValue.length > 1) {
      searchContact({
        page: { currentPage: 1, pageSize: autocompletionPageLimit },
        filters: {
          search: searchValue,
        },
      })
    } else {
      clearPredictions()
    }
  }

  const handleHideSuggestions = () => {
    clearPredictions()
  }

  const handleAddContact = (contact: Contact) => {
    clearPredictions()
    setPrescriberContact({
      ...mapContactToRecipientContact(contact),
    })
    setSearchValue('')
  }

  function handleRemoveContact() {
    setPrescriberContact(null)
  }

  return isDefined(drugDetails) ? (
    <>
      <div className="grid grid-cols-30/70 h-full bg-shades-white">
        <div className="flex flex-col p-6 border-r border-shades-5 overflow-y-auto">
          <span className="text-shades-4 text-xs font-medium">MÉDICAMENT</span>
          <span className="text-shades-2 text-base font-semibold">{drugDetails.name}</span>
          <span className="text-shades-4 text-xs font-medium capitalize mt-1">
            {drugDetails.activePrinciples}
          </span>
          <div className="mt-5 space-y-4">
            <TextArea
              name="prescriptionReason"
              label="Motif de prescription"
              value={prescriptionReason ?? ''}
              onChange={({ target: { value } }) => setPrescriptionReason(value)}
              initialRows={2}
              autoResize
              maxLength={255}
            />
            <div>
              <span className="text-shades-4 text-xs font-medium">PRESCRIPTEUR</span>
              {!prescriberContact && (
                <ContactSearchAutocomplete
                  label="Rechercher le prescripteur (2 caractères min)"
                  searchValue={searchValue}
                  predictions={predictions.items}
                  loading={predictions.loadingState === RestuxLoadingState.LOADING}
                  onSearchValueChange={(event) => setSearchValue(event.target.value)}
                  onHideSuggestion={handleHideSuggestions}
                  onSelectContact={handleAddContact}
                  onFocus={handleSearchContact}
                  colorPreset="light"
                />
              )}
              {prescriberContact && (
                <ContactCard
                  showAddresses={false}
                  contact={prescriberContact}
                  onClose={handleRemoveContact}
                  loading={false}
                  context={BottomPanelContactsContext.POSOLOGY_CONTACT}
                />
              )}
            </div>
            <div>
              <span className="inline-block mb-1 text-shades-4 font-medium">ou</span>
              <TextArea
                name="manualPrescriber"
                label="Prescripteur (saisie libre)"
                value={manualPrescriber ?? undefined}
                onChange={({ target: { value } }) => setManualPrescriber(value)}
                initialRows={2}
                autoResize
                maxLength={255}
              />
            </div>
            <Checkbox
              checked={isChronic ? CheckboxState.CHECKED : CheckboxState.UNCHECKED}
              onChange={() => setIsChronic(!isChronic)}
              label="Traitement chronique"
            />
          </div>
        </div>
        <div className="p-4 pl-2 overflow-y-auto">
          <PosologyForm
            prescriptionId={prescription?.uuid}
            allowEmptyIntervals={false}
            intervalForms={intervalForms}
            setIntervalForms={setIntervalForms}
            drugs={drugs}
            intervals={intervals}
            disabled={false}
            activeIntervalId={selectedIntervalId}
            setActiveIntervalId={setSelectedIntervalId}
            availableAdministrationRoutes={drugDetails.administrationRoutes}
          />
        </div>
      </div>
      <Actions>
        <Button label="Annuler" theme="dark" onClick={handleClose} />
        <Button
          type="submit"
          label="Enregistrer"
          theme="primary"
          disabled={!areIntervalFormsValid}
          onClick={handleSubmit}
        />
      </Actions>
    </>
  ) : (
    <div className="bg-shades-white h-full">
      <FullLoader />
    </div>
  )
}
