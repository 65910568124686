import { FwDocumentType } from '../../../model/Document'
import { RenderableDocument } from '../../renderer'
import { DmpDomainActionsTypes } from './dmp.model'
import { EfficienceUploadConfig, HealthcareSetting } from '../../../model/Efficience'
import { DocumentCategoryKeys } from '../../../model/DocumentCategory'

export const dmpDomainActions = {
  setRequest: (documentId?: number, request?: Record<string, any>) => ({
    type: DmpDomainActionsTypes.SET_REQUEST,
    documentId,
    request,
  }),
  uploadDocument: (
    documentId: number,
    documentType: FwDocumentType,
    config: EfficienceUploadConfig,
    documentCategoryId: DocumentCategoryKeys,
    dmpDocumentId?: string,
  ) => ({
    type: DmpDomainActionsTypes.UPLOAD_DOCUMENT,
    documentId,
    documentType,
    config,
    documentCategoryId,
    dmpDocumentId,
  }),
  sendDocument: (documents: Array<RenderableDocument>) => ({
    type: DmpDomainActionsTypes.SEND_DOCUMENT,
    documents,
  }),
  deleteDocument: (documentId: number, dmpDocumentId: string, patientInseeNumber: string) => ({
    type: DmpDomainActionsTypes.DELETE_DOCUMENT,
    documentId,
    dmpDocumentId,
    patientInseeNumber,
  }),
  fetchHealthcareSettings: () => ({
    type: DmpDomainActionsTypes.FETCH_HEALTHCARE_SETTINGS,
  }),
  storeHealthcareSettings: (healthcareSettings: HealthcareSetting[]) => ({
    type: DmpDomainActionsTypes.STORE_HEALTHCARE_SETTINGS,
    healthcareSettings,
  }),
}
