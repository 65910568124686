import { VariableInsertionPosition, VariablesData } from '@follow/farte'
import { Block, Value } from 'slate'
import { EditorVariableDisplayConfig } from '@follow/cdk'
import { DocumentTemplate } from '../../model/DocumentTemplate'
import { SelectedMedicalEventDocumentWrapper } from '../../store/ui/medicalEvents'

export const UNDISPLAYABLE: unique symbol = Symbol()

export type PendingVariableValue = string | null | typeof UNDISPLAYABLE

export interface VariableConfigurationOwnProps {
  variableId: string
  variableTitle?: string
  variableContext: Record<string, string>
  editorValue: Value
  variablesData: VariablesData
  onSelectVariableDisplayConfig: (
    config?: EditorVariableDisplayConfig,
    newVariableId?: string,
  ) => void
  onChangeVariablePosition: (
    variableId: string,
    position?: VariableInsertionPosition,
    relativeBlock?: Block,
    removeBlock?: boolean,
  ) => void
  onConfigureQuestion?: (variableId: string) => void
  onCopyVariable: () => void
  onCopyVariableText: () => void
  onChangeVariableToText: () => void
  onRemoveVariable: (variableId: string) => void
  onUpdateVariableFixedValue?: (variableId: string) => void
  hide: () => void
}

export interface VariableConfigurationStateProps {
  isAdmin: boolean
  inUseDocumentTemplate: DocumentTemplate | null
  selectedMedicalEventDocument: SelectedMedicalEventDocumentWrapper | null
}

export type VariableConfigurationProps = VariableConfigurationOwnProps &
  VariableConfigurationStateProps
