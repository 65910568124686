import React, { FunctionComponent } from 'react'

import styles from './Chevron.module.scss'
import { Colors, getColor } from '../../../colors'

interface ChevronProps {
  color: Colors
}

export const Chevron: FunctionComponent<ChevronProps> = ({ color }) => (
  <div className={styles.chevron} style={{ borderTopColor: getColor(color) }} />
)
