import { FC, useCallback, useMemo, useState } from 'react'
import { useTagSearch } from '../../../../hooks/utils'
import { MultiSelectOption, SelectOption } from '../../../../model/SelectOption'
import { DropdownItem } from '../../dropdown'
import { Tag } from '../Tag'
import { SearchInput } from '../../search'
import { TagSearchAndDisplayProps } from './TagSearchAndDisplay.model'

const ADD_TAG_VALUE = '-1'

export const TagSearchAndDisplay: FC<TagSearchAndDisplayProps> = ({
  valueIds,
  tags,
  dropdownPosition,
  placeholder = 'Rechercher un label',
  colorPreset = 'light',
  disabled = false,
  testId,
  onChange,
  onCreate,
}) => {
  const [search, setSearch] = useState('')

  const [tagOptions, tagValues] = useTagSearch(valueIds, tags)
  const optionsWithAdd = useMemo(() => {
    const addOption = { label: `Créer le label "${search}"`, value: ADD_TAG_VALUE }
    if (tagOptions.length > 0) {
      if (search.length > 0) {
        /* filtered = Les options qui correspondent à la recherche
          exactMatch = Y a-t-il une option qui correspond exactement à la recherche */
        const { filtered, exactMatch } = tagOptions.reduce(
          ({ filtered, exactMatch }, option) => {
            if (option.label.toLowerCase().includes(search.toLowerCase())) {
              return {
                filtered: [...filtered, option],
                exactMatch: exactMatch || option.label.toLowerCase() === search.toLowerCase(),
              }
            } else {
              return { filtered, exactMatch }
            }
          },
          { filtered: [] as MultiSelectOption<string>[], exactMatch: false },
        )

        // Si on a un tag qui correspond exactement à la recherche, on ne propose pas la création
        return filtered.concat(exactMatch || !onCreate ? [] : addOption)
      } else {
        return tagOptions
      }
    } else {
      return [addOption, ...tagOptions]
    }
  }, [onCreate, search, tagOptions])

  const toggleTag = useCallback(
    (option: SelectOption<string>) => {
      if (option.value === ADD_TAG_VALUE && onCreate) {
        onCreate(search)
      } else {
        if (valueIds.includes(option.value)) {
          const filtered = valueIds.filter((value) => value !== option.value)
          onChange(filtered)
        } else {
          onChange([...valueIds, option.value])
        }
      }
      setSearch('')
    },
    [onChange, onCreate, search, valueIds],
  )

  const renderResult = useCallback(
    ({ label, value }: SelectOption<string>, isHovered) => {
      const selected = valueIds.includes(value)
      return (
        <DropdownItem selected={selected || isHovered}>
          <div className="max-w-full flex items-center whitespace-normal">{label}</div>
        </DropdownItem>
      )
    },
    [valueIds],
  )

  return (
    <div className="w-full space-y-3" data-test-id={`search_display-${testId}`}>
      {!disabled && (
        <SearchInput
          dropdownPosition={dropdownPosition}
          value={search}
          results={optionsWithAdd}
          colorPreset={colorPreset}
          placeholder={placeholder}
          onSelect={toggleTag}
          renderResult={renderResult}
          onChange={({ currentTarget: { value } }) => setSearch(value)}
          testId={`search-${testId}`}
        />
      )}
      <div className="flex flex-wrap gap-2">
        {tagValues.map(({ value, color, label }) => (
          <Tag
            key={value}
            color={color}
            title={label}
            testId={value}
            onClose={disabled ? undefined : () => toggleTag({ value, label })}
          >
            {label}
          </Tag>
        ))}
      </div>
    </div>
  )
}
