import { MailPatient } from '../../../model/Mail'
import { IdentityCheckPayload, InsiPatient, InsiDomainActionType } from './insi.model'

export const insiDomainActions = {
  setRequest: (request?: Record<string, any>, patient?: MailPatient) => ({
    type: InsiDomainActionType.SET_REQUEST,
    request,
    patient,
  }),
  checkPatientIdentity: (patient?: IdentityCheckPayload) => ({
    type: InsiDomainActionType.CHECK_PATIENT_IDENTITY,
    patient,
  }),
  setPatientResponse: (patient?: InsiPatient) => ({
    type: InsiDomainActionType.SET_PATIENT_RESPONSE,
    patient,
  }),
  clearPatientResponse: () => ({
    type: InsiDomainActionType.CLEAR_PATIENT_RESPONSE,
  }),
  logInsi: (payload: Record<string, string>) => ({
    type: InsiDomainActionType.LOG_INSI,
    payload,
  }),
}
