import { Node } from '@tiptap/core'
import { PluginNames } from '@/model/Plugins'
import { TextSelection } from '@tiptap/pm/state'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    [PluginNames.CursorControl]: {
      /** Move cursor after the node previously added */
      moveCursorAfter: () => ReturnType
    }
  }
}

export const CursorControl = Node.create({
  name: PluginNames.CursorControl,

  addCommands() {
    return {
      moveCursorAfter:
        () =>
        ({ tr, dispatch }) => {
          if (dispatch) {
            const to = tr.selection.$to
            const posAfter = to.end()

            if (to.nodeAfter) {
              // Si il y a un noeud après, on déplace le curseur
              tr.setSelection(TextSelection.create(tr.doc, to.pos))
            } else {
              // Si pas de noeud, on en crée un
              const node = to.parent.type.contentMatch.defaultType?.create()
              if (node) {
                tr.insert(posAfter, node)
                // Après avoir inséré le noeud, on y déplace le curseur
                tr.setSelection(TextSelection.create(tr.doc, posAfter))
              }
            }

            tr.scrollIntoView()
          }

          return true
        },
    }
  },
})
