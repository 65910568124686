import { RootState } from '../../../store/reducers/reducers.model'
import { connect } from 'react-redux'
import { sideEffectsListSelector } from '../../../store/domain/sideEffects/sideEffects.selectors'
import {
  SideEffectsSelectDispatchProps,
  SideEffectsSelectStoreProps,
} from './SideEffectsSelect.model'
import { sideEffectsDomainActions } from '../../../store/domain/sideEffects'
import { SideEffectsSelect } from './SideEffectsSelect.component'

const mapStoreToProps = (state: RootState): SideEffectsSelectStoreProps => ({
  sideEffectsList: sideEffectsListSelector(state),
})

const mapDispatchToProps: SideEffectsSelectDispatchProps = {
  searchSideEffects: sideEffectsDomainActions.search,
  setSideEffectsList: sideEffectsDomainActions.setSideEffectsList,
}

const withConnect = connect(mapStoreToProps, mapDispatchToProps)(SideEffectsSelect)
export { withConnect as SideEffectsSelect }
