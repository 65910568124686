import { useEffect } from 'react'
import { TrackingService } from './tracking.service'
import { preventAnalyticLoading } from '../../analytics/utils'
import loadSegmentScript from '../../analytics/uglySegmentScript'
import { SEGMENT_TOKEN } from '../../environment/analytics'
import { useLocation } from 'react-router-dom'

export const useTrackingService = () => {
  const location = useLocation()

  useEffect(() => {
    if (!preventAnalyticLoading(document)) {
      loadSegmentScript(SEGMENT_TOKEN)
      window.analytics.debug(false)
    }
  }, [])

  useEffect(() => {
    if (!preventAnalyticLoading(document)) {
      TrackingService.page(location.pathname)
    }
  }, [location])
}
