import { useState, useMemo } from 'react'
import classNames from 'classnames/bind'
import { JSONToValue, WithVariableEditor } from '@follow/farte'
import { useGetDocumentVariableData } from '../../../../../../hooks/queries/documents/useGetDocumentVariableData.query'
import { AuthService } from '../../../../../../misc/auth.utilities'
import { Loader } from '../../../../../shared'
import { EventFarteDocumentProps } from './model'
import styles from './PatientTransversalNavigationEventFarteDocument.module.scss'

const cx = classNames.bind(styles)

export const PatientTransversalNavigationEventFarteDocument = ({
  document,
  focused,
}: EventFarteDocumentProps) => {
  const [authToken] = useState(AuthService.getToken())
  const {
    query: { data: variable_data, isLoading },
  } = useGetDocumentVariableData({ docId: document.id })
  const value = useMemo(() => JSONToValue(document.template), [document.template])

  if (isLoading) return <Loader />
  if (!value || !variable_data) return <p>Impossible de récupérer le fichier</p>

  return (
    <div className={cx('fileContainer', { focused })}>
      <WithVariableEditor
        value={value}
        variableData={variable_data}
        token={authToken}
        readOnly
        mode="print"
      />
    </div>
  )
}
