import { PrescriptionVariable } from '../../../model/Prescription'

import { BottomPanelDrugsState, UiBottomPanelDrugsActionTypes } from './bottomPanelDrugs.model'

export const bottomPanelDrugsActions = {
  searchDrug: (
    onDrugSelected: (prescription: PrescriptionVariable) => void,
    state: BottomPanelDrugsState = {},
  ) => ({
    type: UiBottomPanelDrugsActionTypes.SEARCH_DRUG,
    onDrugSelected,
    state,
  }),
  selectDrug: (prescription: PrescriptionVariable) => ({
    type: UiBottomPanelDrugsActionTypes.SELECT_DRUG,
    prescription,
  }),
  clearSearchState: () => ({
    type: UiBottomPanelDrugsActionTypes.CLEAR_SEARCH_STATE,
  }),
}
