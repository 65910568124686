import React, { FunctionComponent } from 'react'
import { AlertBanner, RoundedButton } from '../../shared'

interface QuestionnaireDuplicationBannerProps {
  canDuplicate: boolean
  onClickDuplicate: () => void
}

export const QuestionnaireDuplicationBanner: FunctionComponent<QuestionnaireDuplicationBannerProps> = ({
  canDuplicate,
  onClickDuplicate,
}) => {
  const title = `Vous n'êtes pas le propriétaire de ce questionnaire`
  const text = canDuplicate
    ? `Pour le personnaliser, il est nécessaire d'en faire une copie dans votre espace privé.
  (Vous ne bénéficierez plus des nouveautés de ce questionnaire).`
    : `Il s'agit d'un questionnaire scientifique. Pour demander une modification, veuillez contacter le service client.`

  return (
    <AlertBanner
      alerts={[
        {
          code: 'alert_questionnaire',
          title: title,
          text: text,
          actionButton: canDuplicate && (
            <RoundedButton
              label="Créer une copie privée"
              theme="warning"
              onClick={onClickDuplicate}
            />
          ),
          color: 'blue',
          icon: 'infoCircle',
        },
      ]}
    />
  )
}
