import { FunctionComponent, useCallback, useMemo } from 'react'
import { MultiSelectOption } from '../../../../model/SelectOption'
import { MedicalEventsDocumentsFilters } from '../../../../pages/patient'
import { MultiSelectSearch } from '../../../shared'
import { MedicalEventDocumentFiltersProps } from './PatientTransversalNavigationDocumentFilters.model'

enum MedicalEventDocumentTypeLabel {
  FOLLOW = 'Document Follow',
  FORM = 'Formulaire',
  IMAGE = 'Image',
  PDF = 'Pdf',
}

const documentTypeOptions: MultiSelectOption<string>[] = [
  { label: MedicalEventDocumentTypeLabel.FOLLOW, value: 'farte', icon: 'documentText' },
  { label: MedicalEventDocumentTypeLabel.IMAGE, value: 'image', icon: 'image' },
  { label: MedicalEventDocumentTypeLabel.PDF, value: 'pdf_file', icon: 'documentDefault' },
  { label: MedicalEventDocumentTypeLabel.FORM, value: 'pdf', icon: 'fileInstance' },
]

export const PatientTransversalNavigationDocumentFilters: FunctionComponent<
  MedicalEventDocumentFiltersProps
> = ({ filters, onFiltersChange }: MedicalEventDocumentFiltersProps) => {
  const mapSelectedFilters = useCallback(
    (selectedFilters: MultiSelectOption<string>[]): MedicalEventsDocumentsFilters => {
      return { ...filters, fileAndDocumentType: selectedFilters.map((filter) => filter.value) }
    },
    [filters],
  )

  const mappedReceivedFiltersToMultiSelectOption = useMemo(
    () =>
      documentTypeOptions.filter((option) => filters.fileAndDocumentType.includes(option.value)),
    [filters.fileAndDocumentType],
  )

  const handleFilterChange = useCallback(
    (selectedFilters: MultiSelectOption<string>[]) => {
      const mappedFilters = mapSelectedFilters(selectedFilters)
      onFiltersChange(mappedFilters)
    },
    [mapSelectedFilters, onFiltersChange],
  )

  return (
    <MultiSelectSearch
      placeholder="Filtrer par types de fichiers"
      icon="search"
      testId="search-transversal_navigation-medical_event_document"
      value={mappedReceivedFiltersToMultiSelectOption}
      options={documentTypeOptions}
      onSelect={handleFilterChange}
    />
  )
}
