import * as Yup from 'yup'

export const MailClassificationModalValidationSchema = Yup.array().of(
  Yup.object().shape({
    classifyVisibleName: Yup.string().required('Obligatoire'),
    cdaContent: Yup.array().of(
      Yup.object().shape({
        classifyVisibleName: Yup.string().required('Obligatoire'),
      }),
    ),
  }),
)
