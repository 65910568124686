import React, { FunctionComponent, MouseEventHandler, ReactNode } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import Icon from '../Icon'
import { blueColor } from '../../core/TextEditor.style'

interface ButtonProps {
  children?: ReactNode
  title: string
  label?: string
  iconType?: string
  active?: boolean
  onClick: MouseEventHandler<HTMLButtonElement>
}
// L'interface externe expose une prop onClick, mais le listener est posé sur onMouseDown
// afin de pouvoir concerver le focus sur l'editeur lors d'un clic sur le bouton
type StyleButtonProps = Pick<ButtonProps, Exclude<keyof ButtonProps, 'onClick'>> & {
  onMouseDown: MouseEventHandler<HTMLButtonElement>
}

const StyledButton = styled.button<StyleButtonProps>`
  background: transparent;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-grow: 1;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 500;
  outline: none;
  padding: 6px;
  text-align: center;
  transition: background 0.2s ease 0s;

  :hover {
    color: ${blueColor};
  }
  ${({ active }) =>
    active &&
    css`
      color: ${blueColor};
    `}
`

export const Button: FunctionComponent<ButtonProps> = ({ onClick, ...props }) => (
  <StyledButton onMouseDown={onClick} {...props}>
    <>
      {props.iconType && <Icon icon={props.iconType} active={props.active} sizeInPx={16} />}
      {props.label || props.children}
    </>
  </StyledButton>
)
