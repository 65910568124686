import { DocumentHistory } from '../../../model/DocumentHistory'
import { DocumentHistoryResourceType } from '../../domain/documentHistory'

import { UiBottomPanelDocumentHistoryActionTypes } from './bottomPanelDocumentHistory.model'

const closeDocumentHistory = () => ({
  type: UiBottomPanelDocumentHistoryActionTypes.CLOSE_DOCUMENT_HISTORY,
})

const downloadDocumentHistory = (documentHistory: DocumentHistory) => ({
  type: UiBottomPanelDocumentHistoryActionTypes.DOWNLOAD_DOCUMENT_HISTORY,
  documentHistory,
})

const restoreDocumentHistory = (documentRevision: DocumentHistory) => ({
  type: UiBottomPanelDocumentHistoryActionTypes.RESTORE_DOCUMENT_HISTORY,
  documentRevision,
})

const searchDocumentHistory = (
  documentId: number,
  documentHash: string | null,
  documentType: DocumentHistoryResourceType,
) => ({
  type: UiBottomPanelDocumentHistoryActionTypes.SEARCH_DOCUMENT_HISTORY,
  documentId,
  documentHash,
  documentType,
})

export const bottomPanelDocumentHistoryActions = {
  closeDocumentHistory,
  downloadDocumentHistory,
  restoreDocumentHistory,
  searchDocumentHistory,
}
