import { EditorValueJSON } from '@follow/farte'
import { stringifyFromValueJSON } from '../../../misc/editor.utilities'
import { ApiResponse } from 'apisauce'
import { ApiDocumentLayoutResource, DocumentLayout } from '../../../model/DocumentLayout'
import { deserializeDocumentLayout } from '@follow/cdk'

export function deserializeDocumentLayoutResponse(
  response: ApiResponse<ApiDocumentLayoutResource>,
): ApiResponse<DocumentLayout> {
  const { data, ...responseRest } = response
  // Error case => no deserialization
  if (!response.ok || !data) {
    return { ...responseRest }
  }
  return {
    ...responseRest,
    data: deserializeDocumentLayout(data),
  }
}

export function serializeLayout(documentLayout: {
  header?: EditorValueJSON
  introduction?: EditorValueJSON
  conclusion?: EditorValueJSON
  left?: EditorValueJSON
  right?: EditorValueJSON
  footer?: EditorValueJSON
}): any {
  return {
    ...documentLayout,
    header: stringifyFromValueJSON(documentLayout.header),
    introduction: stringifyFromValueJSON(documentLayout.introduction),
    conclusion: stringifyFromValueJSON(documentLayout.conclusion),
    footer: stringifyFromValueJSON(documentLayout.footer),
    left: stringifyFromValueJSON(documentLayout.left),
    right: stringifyFromValueJSON(documentLayout.right),
  }
}
