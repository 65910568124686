import React, { FC, useContext } from 'react'
import styles from './VariableMenu.module.scss'
import { BubbleMenu, useCurrentEditor } from '@tiptap/react'
import { PluginNames } from '@/model/Plugins'
import { VariableEditorContext } from '@/context'
import { VariableAttrbs } from '@/model/Variable'

export const VariableMenu: FC = () => {
  const { renderVariableMenu: MenuContent } = useContext(VariableEditorContext)
  const { editor } = useCurrentEditor()
  if (!editor) return null

  const variableId = editor.getAttributes(PluginNames.Variable)[VariableAttrbs.variableId]

  return (
    <BubbleMenu
      editor={editor}
      pluginKey={PluginNames.Variable}
      shouldShow={({ editor }) => editor.isActive(PluginNames.Variable)}
      className={styles['menu']}
    >
      {variableId && <MenuContent variableId={variableId} editor={editor} />}
    </BubbleMenu>
  )
}
