import { put, call, takeEvery } from 'redux-saga/effects'
import { ApiResponse, ApiOkResponse } from 'apisauce'
import { compile } from 'path-to-regexp'

import { isDefined } from '../../../misc/functions.utilities'
import { normalizeSearchParams } from '../../../misc/url.utilities'
import { restuxCacheActionsFactory } from './restuxCacheActions.factory'
import {
  Identity,
  RestuxCacheConf,
  GetPaginatedItemsRestuxApiMethod,
  GetItemRestuxApiMethod,
  CreateItemRestuxApiMethod,
  UpdateItemRestuxApiMethod,
  DeleteItemRestuxApiMethod,
} from './restuxCache.model'
import { PaginatedList } from '../../../model/Pagination'
import { addResponseError, addError } from '../../message/index'
import { RestuxFilters } from '../restux.model'

function generateSearchQuery(filters?: RestuxFilters): string {
  let stringified = ''
  if (filters && Object.values(filters).length) {
    const normalized = normalizeSearchParams(filters)
    const params = new URLSearchParams(normalized)
    stringified = `?${params.toString()}`
  }
  return stringified
}

export const isSuccessfulApiResponse = <R>(
  response: ApiResponse<R>,
  validate = true,
): response is ApiOkResponse<R> =>
  validate ? response.ok && isDefined(response.data) : response.ok

export const restuxCacheSagasFactory = <TL extends Identity, TD extends Identity>(
  conf: RestuxCacheConf<TL, TD>,
) => {
  const { types: ActionTypes, actions: ResourceActions } = restuxCacheActionsFactory<TL, TD>(
    conf.resourceName,
  )

  const { url, getPaginatedItems, getItem, createItem, updateItem, deleteItem } = conf.apiConfig

  const urlRegex = compile(url)

  /**
   * Get a paginated list
   */
  function* getPaginatedItemsWorker(
    apiMethod: GetPaginatedItemsRestuxApiMethod<TL>,
    {
      page,
      filters = {},
      params = {},
      identifier,
    }: ReturnType<typeof ResourceActions.getPaginatedItems>,
  ) {
    // Fetch api
    const templatedUrl = urlRegex(params)

    filters.page = `${page.currentPage}`
    filters.limit = `${page.pageSize}`

    const response: ApiResponse<PaginatedList<TL>> = yield call(
      apiMethod,
      `${templatedUrl}${generateSearchQuery(filters)}`,
    )
    if (!isSuccessfulApiResponse(response)) {
      yield put(
        ResourceActions.dispatchError(response, {
          page,
          filters,
          params,
          identifier,
        }),
      )
    } else if (response.data) {
      const { items, currentPage, itemCount, limit, pageCount } = response.data
      yield put(
        ResourceActions.storeSetListItems(
          {
            items,
            currentPage,
            itemCount,
            limit,
            pageCount,
          },
          { params, identifier },
        ),
      )
    }
  }
  function* getPaginatedItemsWatcher() {
    if (getPaginatedItems) {
      yield takeEvery(
        ActionTypes.API_GET_PAGINATED_ITEMS,
        getPaginatedItemsWorker,
        getPaginatedItems,
      )
    }
  }

  /**
   * Get an item by it's ID
   */
  function* getItemDetailsWorker(
    apiMethod: GetItemRestuxApiMethod<TD>,
    {
      id,
      filters = {},
      params = {},
      identifier,
    }: ReturnType<typeof ResourceActions.apiGetItemDetails>,
  ) {
    if (!getItem) {
      return // On ne fait rien car pas activé
    }
    // Fetch api
    const templatedUrl = `${urlRegex(params)}/${id}`

    const response: ApiResponse<TD> = yield call(
      apiMethod,
      `${templatedUrl}${generateSearchQuery(filters)}`,
    )
    if (!isSuccessfulApiResponse(response)) {
      yield put(ResourceActions.dispatchError(response, { id, filters, params, identifier }))
    } else if (response.data) {
      yield put(
        ResourceActions.storeSetItemDetails(response.data, {
          refetchList: false,
          params,
          identifier,
        }),
      )
    }
  }
  function* getItemDetailsWatcher() {
    if (getItem) {
      yield takeEvery(ActionTypes.API_GET_ITEM_DETAILS, getItemDetailsWorker, getItem)
    }
  }

  /**
   * Create a new item
   */
  function* createItemWorker(
    apiMethod: CreateItemRestuxApiMethod<TD>,
    {
      item,
      params = {},
      refetchList = conf.refetchList,
      identifier,
    }: ReturnType<typeof ResourceActions.apiCreateItem>,
  ) {
    // Fetch api
    const templatedUrl = urlRegex(params)
    const response: ApiResponse<TD> = yield call(apiMethod, `${templatedUrl}`, item)
    if (!isSuccessfulApiResponse(response)) {
      yield put(ResourceActions.dispatchError(response, { params, identifier, refetchList }))
    } else if (response.data) {
      yield put(
        ResourceActions.storeSetItemDetails(response.data, {
          refetchList,
          params,
          identifier,
        }),
      )
    }
  }
  function* createItemWatcher() {
    if (createItem) {
      yield takeEvery(ActionTypes.API_CREATE_ITEM, createItemWorker, createItem)
    }
  }
  /**
   * Update an item by it's ID
   */
  function* updateItemWorker(
    apiMethod: UpdateItemRestuxApiMethod<TD>,
    {
      id,
      item,
      params = {},
      refetchList = conf.refetchList,
      identifier,
    }: ReturnType<typeof ResourceActions.apiUpdateItem>,
  ) {
    // Fetch api
    const templatedUrl = `${urlRegex(params)}/${id}`

    const response: ApiResponse<TD> = yield call(apiMethod, templatedUrl, item)
    if (!isSuccessfulApiResponse(response)) {
      yield put(
        ResourceActions.dispatchError(response, {
          id,
          params,
          identifier,
          refetchList,
        }),
      )
    } else if (response.data) {
      yield put(
        ResourceActions.storeSetItemDetails(response.data, {
          refetchList,
          params,
          identifier,
        }),
      )
    }
  }
  function* updateItemWatcher() {
    if (updateItem) {
      yield takeEvery(ActionTypes.API_UPDATE_ITEM, updateItemWorker, updateItem)
    }
  }

  /**
   * Delete an item by it's ID
   */
  function* deleteItemWorker(
    apiMethod: DeleteItemRestuxApiMethod,
    {
      id,
      params = {},
      refetchList = conf.refetchList,
      identifier,
    }: ReturnType<typeof ResourceActions.apiDeleteItem>,
  ) {
    // Fetch api
    const templatedUrl = `${urlRegex(params)}/${id}`

    const response: ApiResponse<void> = yield call(apiMethod, templatedUrl)
    if (!isSuccessfulApiResponse(response, false)) {
      yield put(ResourceActions.dispatchError(response, { id, params, identifier, refetchList }))
    } else {
      yield put(ResourceActions.storeDeleteDetailsItem(id, { refetchList, params, identifier }))
    }
  }
  function* deleteItemWatcher() {
    if (deleteItem) {
      yield takeEvery(ActionTypes.API_DELETE_ITEM, deleteItemWorker, deleteItem)
    }
  }

  function* handleErrorWorker({ response }: ReturnType<typeof ResourceActions.dispatchError>) {
    if (!response.ok) {
      console.error('an error occurred', response.problem)
      yield put(addResponseError(response))
    }
    if (!response.data) {
      console.error('Empty response body. It should never happen')
      yield put(addError('Une erreur est survenue'))
    }
  }

  function* handleErrorWatcher() {
    yield takeEvery(ActionTypes.DISPATCH_ERROR, handleErrorWorker)
  }

  return {
    getPaginatedItemsWatcher,
    getItemDetailsWatcher,
    createItemWatcher,
    updateItemWatcher,
    deleteItemWatcher,
    handleErrorWatcher,
  }
}
