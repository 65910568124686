import { useSearchParams } from 'react-router-dom'
import { isDefined } from '../../misc/functions.utilities'
import { Filters } from '../../model/Filters'
import { useCallback, useMemo } from 'react'
import { isEqual, reduce } from 'lodash'

export const useSearchParamsFilters = <T extends Filters>(
  initialFilters: T,
): [T, (filters: T) => void] => {
  const [searchParams, setSearchParams] = useSearchParams()

  const formattedSearchParams = useMemo((): Filters => {
    const formattedSearchParams = {}

    for (let [key, value] of searchParams.entries()) {
      if (isDefined(formattedSearchParams[key])) {
        const prevValue: Array<any> = Array.isArray(formattedSearchParams[key])
          ? [...formattedSearchParams[key]]
          : [formattedSearchParams[key]]
        formattedSearchParams[key] = [...prevValue, value]
      } else {
        formattedSearchParams[key] = value
      }
    }

    return formattedSearchParams
  }, [searchParams])

  const isManagedFilter = useCallback((key: string) => key in initialFilters, [initialFilters])

  const initialValueHasChange = useCallback(
    (key: string, value: any) => !isEqual(value, initialFilters[key]),
    [initialFilters],
  )

  const searchParamsFilters = useMemo((): T => {
    const searchParamsFilters = Object.keys(formattedSearchParams)
      .filter(isManagedFilter)
      .reduce((acc, key) => {
        acc[key] = formattedSearchParams[key]
        return acc
      }, {})

    return { ...initialFilters, ...searchParamsFilters }
  }, [formattedSearchParams, isManagedFilter, initialFilters])

  const setSearchParamsFilters = useCallback(
    (filters: T): void =>
      setSearchParams(
        () => {
          return reduce(
            {
              ...formattedSearchParams,
              ...filters,
            },
            function (acc, value, key) {
              return !isManagedFilter(key) ||
                (isManagedFilter(key) && initialValueHasChange(key, value))
                ? { ...acc, [key]: value }
                : acc
            },
            [],
          )
        },
        { replace: true },
      ),
    [setSearchParams, formattedSearchParams, isManagedFilter, initialValueHasChange],
  )

  return [searchParamsFilters, setSearchParamsFilters]
}
