import { CustomFile } from '../../../model/File'
import { FilesDomainActionTypes } from './files.model'

export const filesDomainActions = {
  download: (fileId: number) => ({
    type: FilesDomainActionTypes.DOWNLOAD,
    fileId,
  }),
  selectAndPrint: (fileId: number) => ({
    type: FilesDomainActionTypes.SELECT_AND_PRINT,
    fileId,
  }),
  print: (file: CustomFile) => ({
    type: FilesDomainActionTypes.PRINT,
    file,
  }),
  send: (fileIds: number[]) => ({
    type: FilesDomainActionTypes.SEND,
    fileIds,
  }),
}
