import { Component, ErrorInfo } from 'react'
import { manageReactError } from '../errors.handler'
import { LayoutErrorBoundaryProps, LayoutErrorBoundaryState } from './LayoutErrorBoundary.model'
import styles from './LayoutErrorBoundary.module.scss'
import { NavBar, NavigationMenu } from '../../components/layout'
import { ErrorPandaMessage } from '../../components/shared'

export class LayoutErrorBoundary extends Component<
  LayoutErrorBoundaryProps,
  LayoutErrorBoundaryState
> {
  state: LayoutErrorBoundaryState = { hasError: false, errorDetails: null, mobileMenuOpen: false }

  public static getDerivedStateFromError(_error: Error) {
    return { hasError: true }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    manageReactError(error, errorInfo)
    this.setState({ errorDetails: JSON.stringify({ stack: error.stack, info: errorInfo }) })
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div className={styles.layoutErrorBoundary}>
          <NavigationMenu />
          <div className={styles.layoutErrorBoundaryPage}>
            <NavBar>Erreur</NavBar>
            <ErrorPandaMessage
              title="Une erreur est survenue"
              subTitle="Si l'erreur persiste, nous vous invitons à contacter le support en fournissant les informations détaillées disponibles ci-dessous."
              errorDetails={this.state.errorDetails ?? undefined}
            />
          </div>
        </div>
      )
    }
    return this.props.children
  }
}
