export enum ModuleDataBaseVariableId {
  PATIENT_WEIGHT = 'patientWeight',
  PATIENT_BLOOD_GROUP = 'patientBloodGroup',
  PATIENT_SIZE = 'patientSize',
  PATIENT_BMI = 'patientBmi',
  PATIENT_GYNECOLOGICAL_GRAVIDITY = 'patientGynecologicalGravidity',
  PATIENT_GYNECOLOGICAL_PARITY = 'patientGynecologicalParity',
  PATIENT_GYNECOLOGICAL_NUMBER_OF_CHILDREN = 'patientGynecologicalNumberOfChildren',
  PATIENT_GYNECOLOGICAL_LAST_SMEAR = 'patientGynecologicalLastSmear',
  PATIENT_GYNECOLOGICAL_LAST_HIV_TEST = 'patientGynecologicalLastHivTest',
  PATIENT_GYNECOLOGICAL_LAST_HPV_TEST = 'patientGynecologicalLastHpvTest',
  PATIENT_GYNECOLOGICAL_LAST_MAMMOGRAPHY = 'patientGynecologicalLastMammography',
  PATIENT_GYNECOLOGICAL_CONTRACEPTION_METHOD = 'patientGynecologicalContraceptionMethod',
  PATIENT_GYNECOLOGICAL_PREGNANCY_TYPE = 'patientGynecologicalPregnancyType',
  PATIENT_GYNECOLOGICAL_TWIN_PREGNANCY = 'patientGynecologicalTwinPregnancy',
  PATIENT_GYNECOLOGICAL_LAST_MENSTRUAL_PERIOD = 'patientGynecologicalLastMenstrualPeriod',
  PATIENT_GYNECOLOGICAL_PREGNANCY_START = 'patientGynecologicalPregnancyStart',
  PATIENT_GYNECOLOGICAL_DUE_DATE = 'patientGynecologicalDueDate',
  PATIENT_GYNECOLOGICAL_PATHOLOGICAL_LEAVE_START = 'patientGynecologicalPathologicalLeaveStart',
  PATIENT_GYNECOLOGICAL_MATERNITY_LEAVE_START = 'patientGynecologicalMaternityLeaveStart',
  PATIENT_GYNECOLOGICAL_MATERNITY_LEAVE_END = 'patientGynecologicalMaternityLeaveEnd',
  PATIENT_GYNECOLOGICAL_AMENORRHEA = 'patientAmenorrhea',
  PATIENT_SYSTOLIC_PRESSURE = 'patientSystolicPressure',
  PATIENT_DIASTOLIC_PRESSURE = 'patientDiastolicPressure',
  PATIENT_BLOOD_PRESSURE = 'patientBloodPressure',
}
