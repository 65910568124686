import { FC, Suspense, lazy } from 'react'
import { FullLoader } from '../../components/shared'

const LazyAccountRouter = lazy(() =>
  import(/* webpackPrefetch: true, webpackChunkName: "manager" */ './AccountRouter.component').then(
    ({ AccountRouter }) => ({ default: AccountRouter }),
  ),
)

const LazyLoadedAccountRouter: FC = (props) => (
  <Suspense fallback={<FullLoader />}>
    <LazyAccountRouter {...props} />
  </Suspense>
)
export { LazyLoadedAccountRouter as AccountRouter }
