import { useMutation, useQueryClient } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { createPatient } from '../../../data/patients'
import { patientsKeys } from './patients.keys'
import { PatientMutationPayload } from '../../../data/patients/mapper/patients.model'
import { Patient } from '../../../model/Patient'

export const useCreatePatient = ({
  triggerSubscription = true,
}: { triggerSubscription?: boolean } = {}) => {
  const queryClient = useQueryClient()

  return useMutation<Patient, Error, PatientMutationPayload>(
    triggerSubscription ? patientsKeys.create : [],
    async (payload) => {
      const { data, ok } = await createPatient(payload)
      if (!data || !ok) {
        throw new UserFriendlyError('error', 'La création du patient a échoué')
      }
      return data
    },
    {
      onSuccess: (patient) => {
        queryClient.invalidateQueries(patientsKeys.lists)
        queryClient.setQueryData(patientsKeys.detail(patient.id), patient)
      },
    },
  )
}
