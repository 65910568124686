import { RestuxFilters } from '../../../store/restux/restux.model'
import { DocumentCategoryKeys, DocumentCategory } from '../../../model/DocumentCategory'
import { TagType } from '../../../model/Tag'
import { ReactNode } from 'react'
import { TestProps } from '../../../misc/types.utilities'

export enum FiltersType {
  DocumentCategory = 'DocumentCategory',
  Title = 'Title',
  Visibility = 'Visibility',
  Tags = 'Tags',
}

export interface SearchPanelFilters {
  search: string
  private?: boolean
  documentCategories: ReadonlyArray<DocumentCategoryKeys>
  tagIds: ReadonlyArray<string>
}

export interface FiltersProps extends TestProps {
  tags: ReadonlyArray<TagType>
  usedTags?: ReadonlyArray<TagType>
  documentCategories?: ReadonlyArray<DocumentCategory>
  capabilities: ReadonlyArray<FiltersType>
  onFiltersChange: (filters: RestuxFilters) => void
  onClearFilters?: () => void
  renderCustomFilters?: (resetPagination: () => void) => ReactNode
  renderActionItem?: () => ReactNode
  hideActionItems?: boolean
  resetPagination: () => void
}
