import { restuxQuestionnairesApiConfig } from './api'
import { restuxCacheFactory } from '../../restux/cache'
import { Questionnaire, LightQuestionnaire } from '../../../model/Questionnaire'
import { RestuxResources } from '../../resources'

const { actions, reducers, sagas } = restuxCacheFactory<LightQuestionnaire, Questionnaire>({
  apiConfig: restuxQuestionnairesApiConfig,
  refetchList: true,
  resourceName: RestuxResources.questionnaire,
})

export const questionnairesActions = actions
export const questionnairesReducers = reducers
export const questionnairesSagas = sagas
