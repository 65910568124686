import React, { FunctionComponent } from 'react'

import styles from './ChildQuestionsLink.module.scss'
import { ChildQuestionsLinkProps } from './ChildQuestionsLink.model'
import { QuestionEditionOrigin } from '../../../../store/ui/bottomPanelQuestions'
import { DocumentTemplateEditorVariantType } from '../../../../model/DocumentTemplate'
import { useNavigate } from 'react-router-dom'

export const ChildQuestionsLink: FunctionComponent<ChildQuestionsLinkProps> = ({
  answer,
  isAdmin,
  isChild,
  origin,
  setBottomPanelOptions,
}) => {
  const navigate = useNavigate()

  // https://gitlab.com/follow-health/fw_requests_tracker/-/issues/797
  if (!isAdmin || isChild || origin === QuestionEditionOrigin.MEDICAL_EVENT) {
    return null
  }

  const navigateToAnswer = () => {
    const searchParams = new URLSearchParams({
      scrollToAnswerId: `${answer.id}`,
    })
    if (origin === QuestionEditionOrigin.TEMPLATE) {
      searchParams.set('type', `${DocumentTemplateEditorVariantType.VARIABLES}`)
    }
    navigate({
      search: searchParams.toString(),
    })
    setBottomPanelOptions({ open: false })
  }
  const label =
    answer.childQuestionIds && answer.childQuestionIds.length > 0
      ? `> ${answer.childQuestionIds.length} sous questions`
      : '> Ajouter des sous questions'
  return (
    <span className={styles.link} onClick={navigateToAnswer}>
      {label}
    </span>
  )
}
