import { ApiResponse } from 'apisauce'
import { call, put, takeEvery } from 'redux-saga/effects'
import { ManualPrescription } from '../../../model/ManualPrescription'
import { documentInstancesActions } from '../../cache/documentInstances'
import { documentTemplatesActions } from '../../cache/documentTemplates'
import { patientManualPrescriptionsActions } from '../../cache/patientManualPrescriptions'
import { addResponseError } from '../../message/message.actions'
import { getVariableData } from '../../renderer'
import { updateManualPrescription } from './api'
import { domainManualPrescriptionActions } from './manualPrescription.actions'
import { domainManualPrescriptionActionTypes } from './manualPrescription.model'
import { getCurrentPatientId } from '../../../misc/currentPatient.utilities'

function* updateManualPrescriptionWorker({
  manualPrescriptionUuid,
  manualPrescriptionUpdate,
}: ReturnType<typeof domainManualPrescriptionActions.updateManualPrescription>) {
  const response: ApiResponse<ManualPrescription> = yield call(
    updateManualPrescription,
    manualPrescriptionUuid,
    manualPrescriptionUpdate,
  )
  if (response.ok) {
    yield put(getVariableData())
    const patientId = getCurrentPatientId()
    if (patientId) {
      yield put(
        patientManualPrescriptionsActions.actions.getPaginatedItems({
          page: { currentPage: 1, pageSize: 100 },
          params: { patientId: `${patientId}` },
        }),
      )
    }
    if (response.data?.documentId) {
      yield put(documentInstancesActions.actions.apiGetItemDetails(response.data.documentId))
    } else if (response.data?.documentTemplateId) {
      yield put(
        documentTemplatesActions.actions.apiGetItemDetails(response.data.documentTemplateId),
      )
    }
  } else {
    yield put(addResponseError(response))
  }
}

function* updatePrescriptionWatcher() {
  yield takeEvery(
    domainManualPrescriptionActionTypes.UPDATE_MANUAL_PRESCRIPTION,
    updateManualPrescriptionWorker,
  )
}

export const manualPrescriptionSagas = {
  updatePrescriptionWatcher,
}
