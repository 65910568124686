import { connect } from 'react-redux'

import { RootState } from '../../store'
import {
  isMedicalEventInUseLoadingSelector,
  medicalEventUiActions,
  selectedMedicalEventDocumentSelector,
} from '../../store/ui/medicalEvents'
import {
  isOwnerOfSelectedMedicalEvent,
  medicalEventDomainActions,
} from '../../store/domain/medicalEvents'

import { MedicalEventPage } from './MedicalEventPage.component'
import { MedicalEventPageDispatchProps, MedicalEventPageStoreProps } from './MedicalEventPage.model'

const mapStateToProps = (state: RootState): MedicalEventPageStoreProps => ({
  medicalEventLoadingState: isMedicalEventInUseLoadingSelector(state),
  isOwner: isOwnerOfSelectedMedicalEvent(state),
  selectedMedicalEventDocument: selectedMedicalEventDocumentSelector(state),
})

const mapDispatchToProps: MedicalEventPageDispatchProps = {
  selectMedicalEvent: medicalEventUiActions.useId,
  clearMedicalEventContext: medicalEventDomainActions.clearMedicalEventContext,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)(MedicalEventPage)
export { withConnect as MedicalEventPage }
