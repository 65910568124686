import { createContext } from 'react'
import { UsurpedUser } from './auth.model'

export type AuthContext = {
  token: string | undefined
  usurpedUser: UsurpedUser | undefined
}

const auth: AuthContext = {
  token: '',
  usurpedUser: {
    username: '',
    id: '',
  },
}

export const AuthContext = createContext(auth)
