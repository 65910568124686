import { FunctionComponent, useCallback } from 'react'
import { PatientInformationsContainer } from '../PatientInformationsContainer'
import { PatientInformationsGroupContainer } from '../PatientInformationsGroupContainer'
import { CodedInformations } from '../../../../model/CodedInformations'
import { GroupProps, PatientInformationsDataProps } from './PatientInformationsData.model'
import styles from './PatientInformationsData.module.scss'

const GroupPart: FunctionComponent<GroupProps> = ({
  group,
  groupName,
  onIndicationGroupClick,
  onShowAddPatientInformationsFormClick,
}) => {
  const handleClick = useCallback(
    (item: CodedInformations) => () => {
      item.type === 'INDICATION_GROUP'
        ? onIndicationGroupClick?.(item)
        : onShowAddPatientInformationsFormClick?.(item.id)
    },
    [onIndicationGroupClick, onShowAddPatientInformationsFormClick],
  )

  return (
    <>
      <PatientInformationsGroupContainer>{groupName}</PatientInformationsGroupContainer>
      {group.map((item) => (
        <PatientInformationsContainer
          key={item.id}
          isIndicationGroup={item.type === 'INDICATION_GROUP'}
          cim10={item.cim10}
          onClick={handleClick(item)}
          testId="allergy-modal-allergy-container"
        >
          {item.name}
        </PatientInformationsContainer>
      ))}
    </>
  )
}

export const PatientInformationsData: FunctionComponent<PatientInformationsDataProps> = ({
  data,
  onShowAddPatientInformationsFormClick,
  onIndicationGroupClick,
  groupName = 'Pathologies',
}) => {
  const { allergies, indicationGroups, molecules } = data

  return (
    <div className={styles.patientInformationsResultsContainer}>
      {indicationGroups.length > 0 && (
        <GroupPart
          group={indicationGroups}
          groupName={groupName}
          onIndicationGroupClick={onIndicationGroupClick}
          onShowAddPatientInformationsFormClick={onShowAddPatientInformationsFormClick}
        />
      )}
      {allergies.length > 0 && (
        <GroupPart
          group={allergies}
          groupName="Allergie générale"
          onShowAddPatientInformationsFormClick={onShowAddPatientInformationsFormClick}
        />
      )}
      {molecules.length > 0 && (
        <GroupPart
          group={molecules}
          groupName="Allergie molécule"
          onShowAddPatientInformationsFormClick={onShowAddPatientInformationsFormClick}
        />
      )}
    </div>
  )
}
