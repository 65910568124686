import { DocumentAlert } from '../../../model/DocumentAlerts'
import { uiDocumentAlertsActionTypes } from './documentAlerts.model'

export const uiDocumentAlertsActions = {
  storeDisplayableSamAlerts: (alerts: DocumentAlert[]) => ({
    type: uiDocumentAlertsActionTypes.STORE_DISPLAYABLE_SAM_ALERTS,
    alerts,
  }),
  disableCurrentSamTypes: () => ({
    type: uiDocumentAlertsActionTypes.DISABLE_CURRENT_SAM_TYPES,
  }),
  acknowledgeCurrentSamAlerts: () => ({
    type: uiDocumentAlertsActionTypes.ACKNOWLEDGE_CURRENT_SAM_ALERTS,
  }),
  clearAlerts: () => ({
    type: uiDocumentAlertsActionTypes.CLEAR_ALERTS,
  }),
}
