import { FC, Fragment, useMemo } from 'react'
import { AssistantPermissionsForm, AssistantPermissionsProps } from './AssistantPermissions.model'
import { getFullName } from '../../../misc/user.utilities'
import { Button, TooltipWrapper, FormikSwitch, GridLayout } from '../../shared'
import { Formik } from 'formik'
import { assistantPermissionsForm } from '../../../state/admin'
import { useAtom } from 'jotai'
import { useActionDispatch } from '../../../hooks/utils'
import { hideBottomPanel } from '../../../store/ui/bottomPanel'

export const AssistantPermissions: FC<AssistantPermissionsProps> = ({ actions: Actions }) => {
  const [assistantsField, setAssistantsField] = useAtom(assistantPermissionsForm)

  const closeBottomPanel = useActionDispatch(hideBottomPanel)

  const initialValues = useMemo(() => {
    return assistantsField.reduce<AssistantPermissionsForm>(
      (acc, item) => ({
        ...acc,
        [item.id]: {
          assistant: item,
          permissions: item.permissions,
          label: getFullName(item),
        },
      }),
      {},
    )
  }, [assistantsField])

  const handleSubmit = (values: AssistantPermissionsForm) => {
    const assistants = Object.values(values).map(({ assistant, permissions }) => ({
      ...assistant,
      permissions,
    }))

    setAssistantsField(assistants)
    closeBottomPanel()
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ values, dirty, handleSubmit }) => (
        <>
          <div className="w-full max-h-full p-8 text-shades-white text-sm overflow-y-auto">
            <GridLayout columnsTemplate="1fr auto auto">
              <span />
              <span className="text-center">INS</span>
              <span className="px-4">Téléservice INSI</span>
              {Object.values(values).map(({ label, assistant, permissions }) => (
                <Fragment key={assistant.id}>
                  <div className="border-b border-shades-white flex items-center">{label}</div>
                  <div className="border-b border-shades-white flex justify-center py-4">
                    <TooltipWrapper
                      pointerDirection="right"
                      content={
                        permissions.insOidAccess
                          ? "Peut voir et éditer les traits d'identité INS des patients"
                          : "Ne peut pas consulter les traits d'identité INS des patients"
                      }
                    >
                      <FormikSwitch fieldName={`${assistant.id}.permissions.insOidAccess`} />
                    </TooltipWrapper>
                  </div>
                  <div className="border-b border-shades-white flex justify-center py-4">
                    <TooltipWrapper
                      pointerDirection="right"
                      content={
                        permissions.insiServiceAccess
                          ? "Peut vérifier l'identité d'un patient via le téléservice INSI"
                          : "Ne peut pas vérifier l'identité d'un patient via le téléservice INSI"
                      }
                    >
                      <FormikSwitch fieldName={`${assistant.id}.permissions.insiServiceAccess`} />
                    </TooltipWrapper>
                  </div>
                </Fragment>
              ))}
            </GridLayout>
          </div>
          <Actions>
            <Button
              theme="primary"
              label="Valider"
              disabled={!dirty}
              onClick={() => handleSubmit()}
            />
          </Actions>
        </>
      )}
    </Formik>
  )
}
