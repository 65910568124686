import { AssigningAuthority } from '../../../model/Patient'
import { RestuxResources } from '../../resources'
import { toUpperSnakeCase } from '../../restux/restux.utilities'

const RESOURCE_PREFIX = `@${toUpperSnakeCase(RestuxResources.codedInformations)}`

export const DomainAssigningAuthoritiesTypes = {
  GET_ASSIGNING_AUTHORITIES: `${RESOURCE_PREFIX}/GET_ASSIGNING_AUTHORITIES`,
  SET_ASSIGNING_AUTHORITIES: `${RESOURCE_PREFIX}/SET_ASSIGNING_AUTHORITIES`,
}

export interface AssigningAuthoritiesState {
  assigningAuthorities: AssigningAuthority[]
}
