import { ApiResponse } from 'apisauce'
import { DocumentAlert, DocumentAlertsFilters } from '../../../../model/DocumentAlerts'
import { DocumentAlertsResponse } from './documentAlerts.model'

export const deserializeDocumentAlerts = ({
  data,
  ok,
  ...response
}: ApiResponse<DocumentAlertsResponse>) => {
  if (ok && data) {
    // Ajout d'un id artificiel pour rendre les alerts compatibles avec le commposant List
    const items: DocumentAlert[] = data.items.map((alert, index) => ({
      ...alert,
      id: index,
    }))

    const { types, ...filters } = data.availableFilters
    const availableFilters: DocumentAlertsFilters = {
      ...filters,
      types: types.map(([value, label]) => ({ value, label })),
    }

    return {
      ...response,
      ok,
      data: {
        ...data,
        availableFilters,
        items,
      },
    }
  } else {
    return { data, ok, ...response }
  }
}

export const serializeDocumentAlertFilters = ({
  drugNames,
  severityLevels,
  types,
  categories,
  samTypes,
}: DocumentAlertsFilters) => ({
  categories: categories.join(','),
  severityLevels: severityLevels.join(','),
  types: types.map(({ value }) => value).join(','),
  drugIds: drugNames.map(({ id }) => id).join(','),
  samTypes: samTypes.map(({ name }) => name).join(','),
})
