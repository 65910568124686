import { PageList } from '../../restux/ui/restuxUi.model'
import { restuxResolvePage } from '../../restux/ui/restuxUi.selectors'
import { RootState } from '../../reducers/index'
import {} from '../../../model/Drug'
import { ManualPrescriptionItem } from '../../../model/ManualPrescription'

export const patientManualPrescriptionsPaginationSelector = (state: RootState) =>
  state.ui.patientManualPrescriptions.pagination

export const patientManualPrescriptionsPageSelector = (
  state: RootState,
): PageList<ManualPrescriptionItem> => {
  const pagination = patientManualPrescriptionsPaginationSelector(state)
  const patientManualPrescriptionsResource = state.cache.patientManualPrescriptions.list
  return restuxResolvePage(patientManualPrescriptionsResource, pagination)
}
