import { connect } from 'react-redux'
import { bottomPanelMailEditorActions } from '../../../../store/ui/bottomPanelMailEditor'
import { domainMailActions } from '../../../../store/domain/mail'
import { enabledFeaturesPreferencesSelector } from '../../../../store/domain/me'
import { RootState } from '../../../../store'
import { MailHeaderDispatchProps, MailHeaderStoreProps } from './MailHeader.model'
import { MailHeader } from './MailHeader.component'

const mapStateToProps = (state: RootState): MailHeaderStoreProps => ({
  enabledFeatures: enabledFeaturesPreferencesSelector(state),
})

const mapDispatchToProps: MailHeaderDispatchProps = {
  openMailEditor: bottomPanelMailEditorActions.openEditor,
  deleteMail: domainMailActions.deleteMail,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)(MailHeader)
export { withConnect as MailHeader }
