import { FC, useCallback } from 'react'
import { useSetAtom } from 'jotai'
import { exportColumnsAddQuestionnaire } from '../../../../state/configurableExport'
import { LightQuestionnaire } from '../../../../model/Questionnaire'
import { SearchQuestionnaireBottomPanel } from '../../../shared/bottomPanel/SearchQuestionnaireBottomPanel'
import { SearchExportColumnQuestionnaireProps } from './SearchExportColumnQuestionnaire.model'

export const SearchExportColumnQuestionnaire: FC<SearchExportColumnQuestionnaireProps> = ({
  open,
  onRequestClose,
}) => {
  const selectItem = useSetAtom(exportColumnsAddQuestionnaire)

  const handleSelectQuestionnaire = useCallback(
    (questionnaire: LightQuestionnaire) => {
      selectItem(questionnaire)
      onRequestClose()
    },
    [selectItem, onRequestClose],
  )

  return (
    <SearchQuestionnaireBottomPanel
      display={open}
      onRequestClose={onRequestClose}
      onSelect={handleSelectQuestionnaire}
    />
  )
}
