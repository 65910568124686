import { FwResourceType } from '../../../model/Resource'
import { domainDocumentCategoriesActionTypes } from './documentCategories.model'
import { DocumentCategory } from '../../../model/DocumentCategory'

export const documentCategoriesDomainAction = {
  fetchUsedDocumentCategories: (usedInResource: FwResourceType) => ({
    type: domainDocumentCategoriesActionTypes.FETCH_USED_DOCUMENT_CATEGORIES,
    usedInResource,
  }),
  storeUsedDocumentCategories: (usedDocumentCategories: Array<DocumentCategory>) => ({
    type: domainDocumentCategoriesActionTypes.STORE_USED_DOCUMENT_CATEGORIES,
    usedDocumentCategories,
  }),
}
