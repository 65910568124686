import { matchPath } from 'react-router'
import { queryClient } from '../App'
import { patientsKeys } from '../hooks/queries/patients/patients.keys'
import { Patient } from '../model/Patient'

/**
 * @deprecated Used by Redux / old bottom panel
 */
export const getCurrentPatientId = () => {
  const pathname = window.location.pathname.replace(/^\/app/, '')
  const match = matchPath(
    {
      path: '/patients/:patientId/*',
    },
    pathname,
  )
  if (!match?.params?.patientId) {
    return null
  }
  const patientId = parseInt(match.params.patientId, 10)
  return !isNaN(patientId) ? patientId : null
}

/**
 * @deprecated Used by Redux / old bottom panel
 */
export const getCurrentPatient = () => {
  const patientId = getCurrentPatientId()
  if (patientId === null) {
    return null
  }
  return (queryClient.getQueryData(patientsKeys.detail(patientId)) as Patient) ?? null
}
