import getApi from '../../../api'
import { HealthData } from '../../../model/HealthData'
import { PaginatedList } from '../../../model/Pagination'
import { deserializePatientHealthDataPaginatedResponse } from './mapper/healthDataHistory.mapper'

export const getPatientHealthDataHistory = (
  patientId: string,
  moduleName: string,
  moduleDataTypeId: string,
  page: number,
  limit: number,
  signal?: AbortSignal,
) =>
  getApi()
    .get<PaginatedList<HealthData>>(
      `/patients/${patientId}/modules/${moduleName}/module_data_types/${moduleDataTypeId}/module_data`,
      { page, limit },
      { signal },
    )
    .then(deserializePatientHealthDataPaginatedResponse)
