import React, { FC, ReactNode } from 'react'
import styles from './EntityCardContent.module.scss'

export interface EntityCardContentProps {
  children?: ReactNode
}

export const EntityCardContent: FC<EntityCardContentProps> = ({ children }) => (
  <div className={styles.container}>{children}</div>
)
