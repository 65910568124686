import { EditorVariableDisplayConfig, VariableKindPrefix } from '../variables.model'

export function isQuestionVariable(variableId: string) {
  return variableId.startsWith(`${VariableKindPrefix.QUESTION}_`)
}

export function getDisplayConfigLabel(displayConfig?: EditorVariableDisplayConfig) {
  switch (displayConfig) {
    case EditorVariableDisplayConfig.HIDDEN:
      return 'masqué'
    case EditorVariableDisplayConfig.SCORE_VALUE:
      return 'score uniquement'
    case EditorVariableDisplayConfig.TITLE:
      return 'titre seulement'
    case EditorVariableDisplayConfig.TITLE_AND_SCORE_VALUE:
      return 'titre+score'
    case EditorVariableDisplayConfig.VARIABLE_TITLE_ONLY:
      return 'titre seulement'
    case EditorVariableDisplayConfig.FULL:
      return 'complet'
    default:
      return 'par défaut'
  }
}
