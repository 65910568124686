import { FC, useContext } from 'react'
import { NavTabProps } from './NavTab.model'
import { TooltipWrapper } from '../../../../../shared'
import { DesktopNavigationContext } from '../DesktopNavigationMenu.context'
import { NavLink, useMatch } from 'react-router-dom'
import { Tab } from '../Tab'
import { isDefined } from '../../../../../../misc/functions.utilities'

export const NavTab: FC<NavTabProps> = ({
  link,
  label,
  newTab,
  interactiveLabel,
  additionnalRouteMatch,
  ...props
}) => {
  const { hideTooltips } = useContext(DesktopNavigationContext)

  const additionnalMatch = useMatch(additionnalRouteMatch ?? '')

  return (
    <TooltipWrapper
      content={label}
      interactive={interactiveLabel}
      pointerDirection="left"
      display={!!label && !hideTooltips}
    >
      <NavLink to={link} target={newTab ? '_blank' : undefined}>
        {({ isActive }) => <Tab active={isActive || isDefined(additionnalMatch)} {...props} />}
      </NavLink>
    </TooltipWrapper>
  )
}
