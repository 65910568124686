import { FC } from 'react'
import { AnalysisResultModalProps } from './AnalysisResultModal.model'
import { BiologyReportResult } from '..'
import { Modal } from '../../shared'

export const AnalysisResultModal: FC<AnalysisResultModalProps> = ({
  isUnmounting,
  onClose,
  result,
}) => {
  return (
    <Modal isUnmounting={isUnmounting} onClickOutside={onClose}>
      <BiologyReportResult analysisResults={result} />
    </Modal>
  )
}
