import { ApiResponse } from 'apisauce'
import { Mail } from '../../../../model/Mail'
import { ApiMail, ApiMailCdaAttachement } from './mail.model'
import { CdaFile } from '../../../../model/CdaContext'
import { deserializeIdentityStatus } from '@follow/cdk'

export function deserializeMailResponse(response: ApiResponse<ApiMail>): ApiResponse<Mail> {
  const { data, ...responseRest } = response
  // Error case => no deserialization
  if (!response.ok || !data) {
    return { ...responseRest }
  }

  return {
    ...responseRest,
    data: {
      ...data,
      patient:
        data?.patient !== null
          ? {
              ...data?.patient,
              identityStatus: deserializeIdentityStatus(data?.patient?.identityStatus),
            }
          : undefined,
      attachments: data.attachments.map(({ cdaContent, part, ...attachment }) => ({
        ...attachment,
        id: part,
        part,
        cdaContent: cdaContent ? deserializeCdaAttachements(cdaContent) : undefined,
      })),
    },
  }
}

const deserializeCdaAttachements = (cdaAttachements: ApiMailCdaAttachement[]): CdaFile[] =>
  cdaAttachements.map((attachment) => ({
    ...attachment,
    visibleName: attachment.title,
    base64: attachment.base64 || '',
  }))
