import getApi from '../../api'
import { PatientInformationsPayload } from '../../model/PatientInformations'
import { SerializedPatientInformations } from './mapper/patientInformations.model'
import {
  deserializePatientResponse,
  serializePatientInformations,
} from './mapper/patientInformations.mapper'

export const updatePatientInformations = (
  patientId: string,
  patientInformationsId: string,
  patientInformationsPayload: PatientInformationsPayload,
) =>
  getApi()
    .put<SerializedPatientInformations>(
      `patients/${patientId}/patient_informations/${patientInformationsId}`,
      serializePatientInformations(patientInformationsPayload),
    )
    .then(deserializePatientResponse)
