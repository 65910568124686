import React, { FunctionComponent, useMemo } from 'react'
import { TagBackgroundColors } from '../../../../colors'
import { Tag } from '../Tag'
import { TagCollectionProps } from './TagCollection.model'
import { useGetTags } from '../../../../hooks/queries/tag'

export const TagCollection: FunctionComponent<TagCollectionProps> = ({ ids, limit = 4 }) => {
  const { tagList: resources } = useGetTags()

  const tags = useMemo(
    () =>
      ids
        .map((id) => resources.find((resource) => resource.id === id))
        .filter(Boolean)
        .filter((_, index) => index < limit),
    [ids, resources, limit],
  )
  const more = useMemo(
    () =>
      ids.length > limit ? (
        <Tag color={TagBackgroundColors.dsTag00}>
          +{ids.length - limit} label{ids.length - limit > 1 ? 's' : ''}
        </Tag>
      ) : null,
    [ids.length, limit],
  )
  return (
    <>
      {tags.map(
        (value, key) =>
          value && (
            <Tag key={key} color={value.color} title={value.label}>
              {value.label}
            </Tag>
          ),
      )}
      {more}
    </>
  )
}
