import { Drug, DrugIdentity } from '../../../model/Drug'
import { domainDrugsActionTypes } from './drugs.model'

export const domainDrugsActions = {
  getDetails: (drug: DrugIdentity) => ({
    type: domainDrugsActionTypes.GET_DETAILS,
    drug,
  }),
  storeDetails: (drug: Drug) => ({
    type: domainDrugsActionTypes.STORE_DETAILS,
    drug,
  }),
  clearDetails: () => ({
    type: domainDrugsActionTypes.CLEAR_DETAILS,
  }),
}
