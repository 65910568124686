import {
  FollowTypes,
  Module,
  HealthDataCode,
  PregnancyType,
  LIVER_FAILURE_VALUES,
  BLOOD_TYPE_OPTIONS as READONLY_BLOOD_TYPE_OPTIONS,
  CONTRACEPTION_VALUES,
  TWIN_PREGNANCY_TYPE_VALUES,
} from '@follow/cdk'
import { AlertSeverity } from './Alerts'
import { SelectOption } from './SelectOption'
import { PatientHealthDataCreateResource } from '../data/patientHealthData/mapper/patientHealthData.model'

export { Module, HealthDataCode, PregnancyType }
export type HealthDataFormat = FollowTypes.ModuleData.HealthDataFormat
export type HealthDataTypeLight = FollowTypes.ModuleData.HealthDataTypeLight
export type Pregnancy = FollowTypes.ModuleData.Pregnancy
export type PregnancyData = FollowTypes.ModuleData.PregnancyData
export type HealthData = FollowTypes.ModuleData.HealthData
export type GenericHealthData<T = FollowTypes.ModuleData.GenericHealthDataDefaultGeneric> =
  FollowTypes.ModuleData.GenericHealthData<T>

export const LIVER_FAILURE_OPTIONS: SelectOption<string>[] = LIVER_FAILURE_VALUES.map((value) => ({
  value,
  label: value,
}))

export const CONTRACEPTION_OPTIONS: Array<SelectOption<string>> = CONTRACEPTION_VALUES.map(
  (value) => ({
    value,
    label: value,
  }),
)

export const TWIN_PREGNANCY_TYPE_OPTIONS: SelectOption<string>[] = TWIN_PREGNANCY_TYPE_VALUES.map(
  (value) => ({
    value,
    label: value,
  }),
)

export const PREGNANCY_TYPE_OPTIONS: SelectOption<PregnancyType>[] = [
  { label: PregnancyType.UNIQUE, value: PregnancyType.UNIQUE },
  { label: PregnancyType.GEMELLAIRE, value: PregnancyType.GEMELLAIRE },
]

export const BLOOD_TYPE_OPTIONS = [...READONLY_BLOOD_TYPE_OPTIONS]

export interface HealthDataTypeBase extends HealthDataTypeLight {
  parentId?: string
  canBeHistorized: boolean
  weight: number
}

export interface HealthDataTypeSerialized extends HealthDataTypeBase {
  validationPattern: string | null
}

export interface HealthDataType extends HealthDataTypeBase {
  validationPattern: RegExp | null
}

export interface HealthDataPayload {
  moduleData: (PatientHealthDataCreateResource | undefined)[]
}

export interface HealthDataHistoryEntry<T = number | boolean | string | Date | Pregnancy> {
  value: T
  date: string
  author: string
  stringValue: string
}

export enum AlertsCode {
  breastfeeding_limit_exceeded = 'breastfeeding_limit_exceeded',
  pregnancy_limit_exceeded = 'pregnancy_limit_exceeded',
  weight_no_data = 'weight_no_data',
  weight_too_low = 'weight_too_low',
  size_no_data = 'size_no_data',
}

export const AlertsTitleMapping = {
  breastfeeding_limit_exceeded: 'Durée d’allaitement anormale',
  pregnancy_limit_exceeded: 'Durée de grossesse anormale',
  weight_no_data: 'Poids manquant',
  weight_too_low: 'Poids inférieur à 30 kg',
  size_no_data: 'Taille manquante',
}

export const AlertsTextMapping = {
  breastfeeding_limit_exceeded:
    'La durée d’allaitement est supérieure à 3 ans. Veuillez contrôler cette information.',
  pregnancy_limit_exceeded:
    'La durée de grossesse est supérieure à 46 semaines. Veuillez contrôler cette information.',
  weight_no_data:
    "Le poids du patient n'est pas renseigné. Ce dernier est nécessaire aux contrôles de sécurité liés à l'aide à la prescription.",
  weight_too_low:
    "Le poids du patient est inférieur à 30 kg. La mise à jour de ce dernier est nécessaire aux contrôles de sécurité liées à l'aide à la prescription.",
  size_no_data:
    "La taille du patient n'est pas renseignée. Cette dernière est nécessaire aux contrôles de sécurité liés à l'aide à la prescription.",
}

export interface HealthDataDictionnary {
  [HealthDataCode.size]?: GenericHealthData<number>
  [HealthDataCode.weight]?: GenericHealthData<number>
  [HealthDataCode.dfg]?: GenericHealthData<number>
  [HealthDataCode.liverFailure]?: GenericHealthData<string>
  [HealthDataCode.breastfeeding]?: GenericHealthData<boolean>
  [HealthDataCode.breastfeeding_date]?: GenericHealthData<Date>
  [HealthDataCode.pregnancy]?: GenericHealthData<boolean>
  [HealthDataCode.pregnancy_date]?: GenericHealthData<Date>
  [HealthDataCode.lastPeriod]?: GenericHealthData<Date>
  [HealthDataCode.bmi]?: GenericHealthData<number>
  [HealthDataCode.bloodType]?: GenericHealthData<string>
  [HealthDataCode.systolicPressure]?: GenericHealthData<string>
  [HealthDataCode.diastolicPressure]?: GenericHealthData<string>
  [HealthDataCode.bloodPressure]?: GenericHealthData<string>
  [HealthDataCode.gravidity]?: GenericHealthData<string>
  [HealthDataCode.parity]?: GenericHealthData<string>
  [HealthDataCode.number_of_children]?: GenericHealthData<string>
  [HealthDataCode.comment]?: GenericHealthData<string>
  [HealthDataCode.lastSmear]?: GenericHealthData<Date>
  [HealthDataCode.lastHivTest]?: GenericHealthData<Date>
  [HealthDataCode.lastHpvTest]?: GenericHealthData<Date>
  [HealthDataCode.lastMammography]?: GenericHealthData<Date>
  [HealthDataCode.contraceptionMethod]?: GenericHealthData<string>
  [HealthDataCode.isBreastfeeding]?: GenericHealthData<boolean>
  [HealthDataCode.breastfeedingStart]?: GenericHealthData<Date>
  [HealthDataCode.pregnancyEntity]?: PregnancyData
}

export interface HealthDataField {
  value: any
  type?: HealthDataType | undefined
  parentId?: string | undefined
}

export interface PatientAlert {
  code: string
  severity: AlertSeverity
}
