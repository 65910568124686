import { PhoneCountryCode, PHONE_COUNTRY_CODES } from '../../../../../../model/Phone'
import { SelectOption } from '../../../../../../model/SelectOption'
import { FormikAucompleteInput } from '../Input.model'

export interface FormikInternationalPhoneInputProps
  extends SafeOmit<FormikAucompleteInput, 'colorPreset'> {}

export const CUSTOM_COUNTRY_CODE = 'CUSTOM'

export const countryCodeOptions: SelectOption<PhoneCountryCode>[] = PHONE_COUNTRY_CODES.map(
  (countryCode) => ({
    value: countryCode,
    label: `+${countryCode.phoneCode}`,
  }),
)

export const defaultCountryCodeOption =
  countryCodeOptions.find(({ value }) => value.countryCode === 'FR') ?? countryCodeOptions[0]
