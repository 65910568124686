import { Filters } from '../../../model/Filters'

const all = ['tasks']
const detail = (id: number) => [...all, id]
const list = (filters?: Filters) => [...all, filters]
const count = (filters?: Filters) => [...all, 'count', filters]

export const taskKeys = {
  all,
  detail,
  list,
  count,
}
