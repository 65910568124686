import getApi from '../../api/apisauceConfig'
import { HealthDataTypeSerialized } from '../../model/HealthData'
import { PaginatedList } from '../../model/Pagination'
import { largePageLimit } from '../../constants'
import { deserializeHealthDataType } from './mapper/patientHealthData.mapper'

export const getPatientHealthDataTypes = (page: number, limit = largePageLimit) =>
  getApi()
    .get<PaginatedList<HealthDataTypeSerialized>>('/module_data_types', { page, limit })
    .then(deserializeHealthDataType)
