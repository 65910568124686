import { useMutation, useQueryClient } from '@tanstack/react-query'
import { lockUser, unlockUser } from '../../../../data/admin'
import { LightUser } from '../../../../model/User'
import { UserFriendlyError } from '../../../../model/Error'
import { addValid } from '../../../../store/message'
import { useActionDispatch } from '../../../utils'
import { PaginatedList } from '../../../../model/Pagination'
import { userKeys } from './users.keys'

interface UserLockVariables {
  userId: string
  lock: boolean
}

export const useLockUser = () => {
  const valid = useActionDispatch(addValid)
  const queryClient = useQueryClient()

  const queryReturn = useMutation<null, Error, UserLockVariables>(
    async ({ userId, lock }) => {
      const endpoint = lock ? lockUser : unlockUser
      const { ok } = await endpoint(userId)
      if (!ok) {
        throw new UserFriendlyError(
          'error',
          `Le ${lock ? '' : 'dé'}verrouillage de l'utilisage a échoué`,
        )
      }
      return null
    },
    {
      onSuccess: (_data, { userId, lock }) => {
        valid(`Utilisateur ${lock ? '' : 'dé'}verrouillé`)
        queryClient.refetchQueries<PaginatedList<LightUser>>({
          queryKey: userKeys.all,
          refetchPage: (page) => page.items.some(({ id }) => id === userId),
        })
      },
    },
  )

  return queryReturn
}
