import React, { FunctionComponent } from 'react'

import { withWidthLowerObserver } from '../../../../misc/WidthObserver.hoc'
import { breakSmall } from '../../../../misc/responsive'
import { ListActionsProps } from './ListActions.model'

import styles from './ListActions.module.scss'
import { LabeledButton, IconButton } from '../../buttons'
import { GridLayout } from '../../layout'

const ListActions: FunctionComponent<ListActionsProps> = ({
  onAddItem,
  onToggleExtendedActions,
  renderCustomActionButtons,
  isLowerThanBreakpoint,
  children,
  addItemLabel = 'Nouveau',
  extendedActionsOpen,
  testId,
}) => (
  <div>
    <GridLayout
      columnsTemplate="1fr auto auto"
      alignColumns="center"
      gap="small"
      padding={['small', 'medium']}
      width="100%"
    >
      {children}
      <div className="flex flex-row h-full">
        {onToggleExtendedActions && !isLowerThanBreakpoint && (
          <button
            className="font-sm text-sm text-primary-default hover:underline cursor-pointer mr-4"
            onClick={() => onToggleExtendedActions(!extendedActionsOpen)}
            data-test-id={testId ? `button-list-${testId}-extended-actions` : undefined}
          >
            {extendedActionsOpen ? 'Moins de filtres' : 'Plus de filtres'}
          </button>
        )}
        {(onAddItem || renderCustomActionButtons) && !isLowerThanBreakpoint && (
          <div className="flex space-x-4">
            <div className={styles.separator} />
            {renderCustomActionButtons && renderCustomActionButtons()}
            {onAddItem && (
              <LabeledButton
                icon="add"
                label={addItemLabel}
                active={true}
                onClick={onAddItem}
                testId={testId ? `button-list-${testId}-add` : undefined}
              />
            )}
          </div>
        )}
      </div>
    </GridLayout>
    {onAddItem && isLowerThanBreakpoint && (
      <div className={styles.mobileAdd}>
        <IconButton elevation={3} icon="add" theme="primary" size="macro" onClick={onAddItem} />
      </div>
    )}
  </div>
)

const withHoc = withWidthLowerObserver(breakSmall)(ListActions)
export { withHoc as ListActions }
