import { FunctionComponent } from 'react'
import { SearchInput, GridLayout } from '../../shared'

interface DocumentLayoutListActionsProps {
  searchValue: string
  onChangeSearch: (value: string) => void
}

export const DocumentLayoutListActions: FunctionComponent<DocumentLayoutListActionsProps> = ({
  searchValue,
  onChangeSearch,
}) => (
  <GridLayout columnsTemplate="1fr">
    <SearchInput
      placeholder="Rechercher une mise en page"
      value={searchValue}
      onChange={({ target: { value } }) => onChangeSearch(value)}
      autofocus
    />
  </GridLayout>
)
