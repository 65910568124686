import { Editor } from 'slate-react'

import { EditorValue } from '../../model/TextEditor.model'
import { ReactNode } from 'react'

export type ButtonType =
  | 'blockType'
  | 'fontFamily'
  | 'fontSize'
  | 'bold'
  | 'italic'
  | 'underline'
  | 'alignLeft'
  | 'alignCenter'
  | 'alignRight'
  | 'justify'
  | 'bulletList'
  | 'numberedList'
  | 'addImage'
  | 'breakPage'
  | 'undo'
  | 'redo'
  | 'saveText'

export type BlockType = 'title1' | 'title2' | 'title3' | 'paragraph'

export type FontFamily =
  | 'Amiri'
  | 'Crimson Pro'
  | 'Lato'
  | 'Lora'
  | 'Merriweather'
  | 'Montserrat'
  | 'Raleway'
  | 'Roboto'
  | 'Source Sans Pro'

export type FontSize = 8 | 9 | 10 | 11 | 12 | 14 | 18 | 24 | 30 | 36 | 48 | 60

type Categories = 'heading' | 'mark' | 'list' | 'align' | 'image' | 'page' | 'history' | 'save'

export const DEFAULT_FONT_FAMILIES: ReadonlyArray<FontFamily> = [
  'Amiri',
  'Crimson Pro',
  'Lato',
  'Lora',
  'Merriweather',
  'Montserrat',
  'Raleway',
  'Roboto',
  'Source Sans Pro',
]

export const DEFAULT_FONT_SIZES: ReadonlyArray<FontSize> = [
  8, 9, 10, 11, 12, 14, 18, 24, 30, 36, 48, 60,
]

export type ToolbarConfig = {
  categories: { [key in Categories]?: ButtonType[] }
  blockTypes: BlockType[]
  fontFamilies: ReadonlyArray<FontFamily>
  fontSizes: ReadonlyArray<FontSize>
}

export interface ToolbarProps {
  children?: ReactNode
  editor: Editor
  value: EditorValue
  readOnly?: boolean
  config?: ToolbarConfig
  editorBanner?: React.ReactNode
  style?: React.CSSProperties
  saveAsText?: () => void
  onAddImage?: () => void
}

export const DEFAULT_TOOLBAR_CONFIG: ToolbarConfig = {
  categories: {
    heading: ['blockType', 'fontFamily', 'fontSize'],
    mark: ['bold', 'italic', 'underline'],
    align: ['alignLeft', 'alignCenter', 'alignRight', 'justify'],
    list: ['bulletList', 'numberedList'],
    image: ['addImage'],
    page: ['breakPage'],
    history: ['undo', 'redo'],
  },
  blockTypes: ['title1', 'title2', 'title3', 'paragraph'],
  fontFamilies: DEFAULT_FONT_FAMILIES,
  fontSizes: DEFAULT_FONT_SIZES,
}

export const DEFAULT_INLINE_TOOLBAR_CONFIG: ToolbarConfig = {
  categories: {
    heading: ['blockType', 'fontFamily', 'fontSize'],
    mark: ['bold', 'italic', 'underline'],
    history: ['undo', 'redo'],
  },
  blockTypes: ['title1', 'title2', 'title3', 'paragraph'],
  fontFamilies: DEFAULT_FONT_FAMILIES,
  fontSizes: DEFAULT_FONT_SIZES,
}
