import { IconsType } from '../components/shared'

export interface LegalDocument {
  id: string
  title: string
  content: string
  needRead: boolean
  type: LegalDocumentType
}

export interface LegalDocumentCreatePayload extends SafeOmit<LegalDocument, 'id'> {}

export interface LegalDocumentUpdatePayload extends SafeOmit<LegalDocument, 'id'> {
  resetReaders: boolean
}

export interface LegalDocumentTypeOptions {
  label: string
  value: LegalDocumentType
  icon: IconsType
  color: string
}

export enum LegalDocumentType {
  gcu = 'gcu',
}

export const LegalDocumentTypeLabel: { [property in LegalDocumentType]: string } = {
  [LegalDocumentType.gcu]: `CGU`,
} as const

export const LEGAL_DOCUMENT_TYPE_OPTIONS = Object.entries(LegalDocumentTypeLabel).map(
  ([label, value]) => {
    return {
      label: value,
      value: label,
    } as LegalDocumentTypeOptions
  },
)
