import { IdentityIdentifier } from '../../restux/restux.model'

export type QuestionnaireComputedValuesState = Record<string, number | undefined>

export const buildQuestionnaireComputedValueKey = (
  documentId: IdentityIdentifier,
  questionnaireId: number,
) => {
  return `${documentId}:${questionnaireId}`
}

export const QuestionnaireComputedValuesUiActionsTypes = {
  CLEAR: '@QUESTIONNAIRE_COMPUTED_VALUES/CLEAR',
  FETCH: '@QUESTIONNAIRE_COMPUTED_VALUES/FETCH',
  SET: '@QUESTIONNAIRE_COMPUTED_VALUES/SET',
}
