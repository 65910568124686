import React, { FunctionComponent, useMemo } from 'react'
import { HealthDataModalHistoryProps } from './HealthDataModalHistory.model'
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, Tooltip, Legend } from 'recharts'
import { FullLoader, Table, TooltipWrapper } from '../../shared'
import {
  computeFormatedHealthDateHistory,
  computeHealthDataHistoryDomain,
} from '../../../misc/healthData.utilities'
import { useGetInfinitePatientHistoryHealthData } from '../../../hooks/queries/patientHealthData'
import styles from './HealthDataModalHistory.module.scss'

export const HealthDataModalHistory: FunctionComponent<HealthDataModalHistoryProps> = ({
  healthDataType,
  patientId,
}) => {
  const { healthDataHistory } = useGetInfinitePatientHistoryHealthData({
    patientId,
    moduleName: healthDataType.module,
    moduleDataTypeId: healthDataType.id,
  })

  const formatedHistory = useMemo(
    () => computeFormatedHealthDateHistory(healthDataHistory, healthDataType.code).slice(-6),
    [healthDataType, healthDataHistory],
  )

  const domain = useMemo(
    () => computeHealthDataHistoryDomain(formatedHistory, healthDataType.type),
    [formatedHistory, healthDataType?.type],
  )

  return (
    <div className={styles.container}>
      {formatedHistory.length > 0 ? (
        <>
          <span className="text-sm text-gray-500 font-normal">
            Historique des données de <b>{healthDataType?.name}</b>
          </span>
          {healthDataType && formatedHistory && (
            <>
              {formatedHistory.length > 1 && ['FLOAT', 'INTEGER'].includes(healthDataType.type) && (
                <div className="bg-white p-2 rounded-lg ">
                  <ResponsiveContainer width="100%" height={180}>
                    <LineChart data={formatedHistory}>
                      <XAxis axisLine={false} tickLine={false} dataKey="date" height={16} />
                      <YAxis
                        axisLine={false}
                        tickLine={false}
                        dataKey={healthDataType.name}
                        width={30}
                        domain={domain}
                      />
                      <Legend />
                      <Tooltip />
                      <Line
                        strokeWidth={2}
                        type="monotone"
                        dataKey="value"
                        name={healthDataType.name}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              )}
              <Table
                columns={[
                  { title: healthDataType.name, dataIndex: 'moduleDataType' },
                  { title: 'Date', dataIndex: 'date' },
                  {
                    title: 'Auteur',
                    dataIndex: 'author',
                    render: (data) => (
                      <TooltipWrapper
                        content={<span className="max-w-[120px] block break-words">{data}</span>}
                        pointerDirection="right"
                      >
                        <span className="max-w-[90px] text-ellipsis block overflow-hidden">
                          {data}
                        </span>
                      </TooltipWrapper>
                    ),
                  },
                ]}
                rows={formatedHistory.map(({ stringValue, date, author }) => ({
                  data: {
                    moduleDataType: `${stringValue}${
                      healthDataType.unit ? ` ${healthDataType.unit}` : ''
                    }`,
                    date,
                    author,
                  },
                }))}
              />
            </>
          )}
        </>
      ) : (
        <FullLoader />
      )}
    </div>
  )
}
