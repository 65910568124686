export type IconButtonSize = 'atom' | 'nano' | 'micro' | 'normal' | 'macro'

export interface BorderColors {
  default?: string
  hover?: string
}

export interface Sizes {
  height?: number | string
  width?: number | string
  padding?: number | string
  margin?: number | string
  font?: number | string
}

export interface Colors {
  default?: string
  backgroundColor?: string
  defaultHover?: string
  backgroundColorHover?: string
}

export const BASE_BUTTON_SIZES = {
  atom: 16,
  nano: 24,
  micro: 32,
  normal: 40,
  macro: 48,
}

export type BaseButtonAppearance = 'standalone' | 'left' | 'right'

export interface BaseButtonProps {
  type: 'submit' | 'reset' | 'button'
  sizes?: Sizes
  colors?: Colors
  borderSize?: 1 | 2
  borderRadius?: string
  borderColors?: BorderColors
  noBorder?: boolean
  disabled?: boolean
  appearance?: BaseButtonAppearance
}

export interface StyledButtonProps
  extends Pick<BaseButtonProps, 'type' | 'noBorder' | 'borderSize' | 'borderRadius' | 'disabled'> {
  appearance: BaseButtonAppearance
  fontColor?: string
  backgroundColor?: string
  borderColor?: string
  borderColorHover?: string
  widthSize?: number | string
  heightSize?: number | string
  paddingSize?: number | string
  fontSize?: number | string
}
