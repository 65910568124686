import { connect } from 'react-redux'
import { DocumentActions } from '../../../store/ui/document/document.actions'
import { DocumentLayoutContent } from './DocumentLayoutContent.component'
import { DocumentLayourContentDispatchProps } from './DocumentLayoutContent.model'

const mapDisptachToProps: DocumentLayourContentDispatchProps = {
  addImageToDocument: DocumentActions.addImageToDocument,
}

const withConnect = connect(null, mapDisptachToProps)(DocumentLayoutContent)
export { withConnect as DocumentLayoutContent }
