import { useQuery } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../../model/Error'
import { actesKeys } from './actes.keys'
import { DetailedActe, QuoteLine } from '../../../../model/Acte'
import { getActe } from '../../../../data/ccam/actes/getActe'

interface GetActeParams {
  quoteLine: QuoteLine
  enabled?: boolean
}

export const useGetActe = ({ quoteLine, enabled = true }: GetActeParams) => {
  const queryKey = actesKeys.detail(quoteLine.codeActe)

  const queryResult = useQuery<DetailedActe>(
    queryKey,
    async () => {
      const { data, ok } = await getActe(quoteLine.codeActe)

      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          `Impossible de récupérer le code CCAM ${quoteLine.codeActe}`,
          `Une erreur technique s'est produite`,
        )
      }
      return data
    },
    {
      enabled,
    },
  )

  return {
    query: queryResult,
  }
}
