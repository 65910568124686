import { createReducer } from 'redux-starter-kit'
import { questionnaireComputedValuesUiActions } from './questionnaireComputedValues.actions'
import {
  QuestionnaireComputedValuesState,
  QuestionnaireComputedValuesUiActionsTypes,
  buildQuestionnaireComputedValueKey,
} from './questionnaireComputedValues.model'

const DEFAULT_STATE: QuestionnaireComputedValuesState = {}

const ACTION_HANDLERS = {
  [QuestionnaireComputedValuesUiActionsTypes.CLEAR]: () => ({}),
  [QuestionnaireComputedValuesUiActionsTypes.SET]: (
    computedValues: QuestionnaireComputedValuesState,
    {
      documentId,
      questionnaireId,
      computedValue,
    }: ReturnType<typeof questionnaireComputedValuesUiActions.setQuestionnaireComputedValue>,
  ) => ({
    ...computedValues,
    [buildQuestionnaireComputedValueKey(documentId, questionnaireId)]: computedValue,
  }),
}
export const questionnaireComputedValuesReducer = createReducer<QuestionnaireComputedValuesState>(
  DEFAULT_STATE,
  ACTION_HANDLERS,
)
