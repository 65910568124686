import classNames from 'classnames'
import { getCssVariable } from '../../../../design-system/utils'
import { DropdownOverlay } from '../../dropdown'
import { Icon } from '../../Icon'
import { SelectSearchProps } from './SelectSearch.model'
import { useRef, useState } from 'react'
import { defaultRenderOption } from '../../inputs/SelectInput'
import { SelectOption } from '../../../../model/SelectOption'
import { useSelectArrowNavigation } from '../../../../hooks/utils'

export const searchSelectThemes = {
  light: {
    background: 'bg-shades-white',
    border: 'border-shades-5',
    placeholder: 'text-shades-5',
    placeholderClass: 'placeholder:text-shades-5',
    text: 'text-shades-2',
    label: 'text-shades-4',
  },
  dark: {
    background: 'bg-shades-3',
    border: 'border-shades-3',
    placeholder: 'text-shades-5',
    placeholderClass: 'placeholder:text-shades-5',
    text: 'text-shades-white',
    label: 'text-shades-5',
  },
}

export function SelectSearch<T>({
  icon,
  value,
  textValue,
  label,
  colorPreset = 'light',
  options,
  placeholder,
  clearable = true,
  disabled = false,
  testId,
  onSelect,
  onTextChange,
  onFocus,
  onBlur,
  renderOption = defaultRenderOption,
}: SelectSearchProps<T>) {
  const [open, setOpen] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const theme = searchSelectThemes[colorPreset]

  const handleSelect = (option: SelectOption<T>) => {
    setOpen(false)
    onSelect(option)
  }

  const [scrollRef, hoveredRef, hoveredIndex, handleKeyDown] = useSelectArrowNavigation(
    options,
    onSelect,
  )

  const displayDropdown = open && options.length > 0

  return (
    <div className="relative flex flex-col" onBlur={() => setOpen(false)} data-test-id={testId}>
      {label && (
        <span className={classNames('text-xs font-medium mb-1', theme.label)}>{label}</span>
      )}
      <div
        className={classNames(
          'h-12 px-4 flex items-center rounded-full border',
          disabled ? 'cursor-not-allowed' : 'cursor-pointer',
          theme.background,
          theme.border,
        )}
        onClick={() => {
          inputRef.current?.focus()
        }}
      >
        {icon && (
          <div className="flex-shrink-0 mr-1">
            <Icon size="nano" icon={icon} color={getCssVariable('shades', 'shade5')} />
          </div>
        )}
        <input
          ref={inputRef}
          value={open ? textValue : value?.label ?? ''}
          readOnly={!onTextChange}
          onChange={(event) => onTextChange && onTextChange(event.currentTarget.value)}
          disabled={disabled}
          data-test-id={`${testId}-input`}
          className={classNames(
            'appearance-none bg-transparent w-full ml-1 text-sm font-semibold cursor-inherit',
            theme.placeholderClass,
            theme.text,
          )}
          placeholder={placeholder}
          onFocus={(event) => {
            setOpen((value) => !value)
            onFocus && onFocus(event)
          }}
          onBlur={onBlur}
          onKeyDown={handleKeyDown}
        />
        {clearable && value && (
          <div
            className="ml-1 mr-2"
            onClick={(event) => {
              onSelect(undefined)
              event.stopPropagation()
            }}
          >
            <Icon
              size="nano"
              icon="cross"
              rotate={open ? 180 : 0}
              color={getCssVariable('shades', 'shade5')}
            />
          </div>
        )}
        {!disabled && (
          <div className="flex-shrink-0">
            <Icon
              size="pico"
              icon="chevron"
              rotate={open ? 180 : 0}
              color={getCssVariable('shades', 'shade5')}
            />
          </div>
        )}
      </div>
      <DropdownOverlay display={displayDropdown} scrollRef={scrollRef}>
        {options.map((item, index) => (
          <div
            key={index}
            ref={hoveredIndex === index ? hoveredRef : undefined}
            onMouseDown={() => handleSelect(item)}
          >
            {renderOption(item, value, hoveredIndex === index)}
          </div>
        ))}
      </DropdownOverlay>
    </div>
  )
}
