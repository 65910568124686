import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { ActivePrinciplesSelectProps } from './ActivePrinciplesSelect.model'
import { DropdownItem, SearchInput } from '../../shared'
import { SelectOption } from '../../../model/SelectOption'
import { ActivePrinciple } from '../../../model/Drug'
import { useDebounce } from 'react-use'

const convertActivePrinciplesIntoOption = (item: ActivePrinciple) => ({
  label: item.name,
  value: item.externalId,
})

export const ActivePrinciplesSelect: FC<ActivePrinciplesSelectProps> = ({
  activePrinciplesList,
  searchActivePrinciples,
  setActivePrinciplesList,
  setActivePrincipleSelected,
  resetDrugsList,
  initialSearch = '',
  colorPreset = 'dark',
  placeHolder = '3 caractères min',
}) => {
  const clearList = useCallback(() => {
    setActivePrinciplesList([])
  }, [setActivePrinciplesList])

  const [search, setSearch] = useState(initialSearch)

  useEffect(() => setSearch(initialSearch), [initialSearch])

  const activePrinciplesOptions = useMemo(() => {
    if (activePrinciplesList) {
      const options = activePrinciplesList.map(convertActivePrinciplesIntoOption)
      return options
    }
  }, [activePrinciplesList])

  const handleSelect = useCallback(
    (selected: SelectOption<number>) => {
      setActivePrincipleSelected({ externalId: selected.value, name: selected.label })
      setSearch(selected.label)
      clearList()
    },
    [setActivePrincipleSelected, clearList],
  )

  useDebounce(
    () => {
      if (search.length > 2) {
        searchActivePrinciples(search)
      } else {
        clearList()
        setActivePrincipleSelected(undefined)
        if (resetDrugsList) {
          resetDrugsList()
        }
      }
    },
    500,
    [search],
  )

  return (
    <SearchInput
      placeholder={placeHolder}
      colorPreset={colorPreset}
      value={search}
      results={activePrinciplesOptions}
      onSelect={handleSelect}
      renderResult={(option, isHovered) => (
        <DropdownItem selected={isHovered}>
          <div className="max-w-full flex items-center whitespace-normal">
            <span className="capitalize">{option.label}</span>
          </div>
        </DropdownItem>
      )}
      onChange={({ target: { value } }) => {
        setSearch(value)
      }}
    />
  )
}
