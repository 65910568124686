import { useMutation, useQueryClient } from '@tanstack/react-query'
import { publishNotification } from '../../../../data/admin'
import { UserFriendlyError } from '../../../../model/Error'
import { ServerSentNotification } from '../../../../model/Notification'
import { addValid } from '../../../../store/message'
import { useActionDispatch } from '../../../utils'
import { PaginatedList } from '../../../../model/Pagination'
import { adminNotifKeys } from './adminNotifications.keys'

export const usePublishNotification = () => {
  const valid = useActionDispatch(addValid)
  const queryClient = useQueryClient()

  const queryReturn = useMutation<ServerSentNotification, Error, string>(
    async (id) => {
      const { ok, data } = await publishNotification(id)
      if (!ok || !data) {
        throw new UserFriendlyError('error', 'Impossible de publier la notification')
      }
      return data
    },
    {
      onSuccess: (_data, notificationId) => {
        valid('La notification a été publiée')
        queryClient.refetchQueries<PaginatedList<ServerSentNotification>>({
          queryKey: adminNotifKeys.all,
          refetchPage: (page) => page.items.some(({ id }) => id === notificationId),
        })
      },
    },
  )

  return queryReturn
}
