import React, { FunctionComponent, useState } from 'react'
import { DropdownContainer, DropdownItem, IconButton } from '../../../shared'
import { QuestionnaireCustomActionsProps } from './QuestionnaireCustomActions.model'
import styles from './QuestionnaireCustumActions.module.scss'
import { ADMIN_ROLES } from '../../../../model/Roles'
import RoleRestriction from '../../../../containers/RoleRestriction'

export const QuestionnaireCustomActions: FunctionComponent<QuestionnaireCustomActionsProps> = ({
  onDuplicateToAnotherUser,
  onEdit,
  onDelete,
  onDuplicate,
}) => {
  const [opened, setOpened] = useState(false)

  return (
    <div className={styles.container}>
      <IconButton
        icon="more"
        theme="light"
        size="micro"
        title="Plus d'actions "
        onClick={() => setOpened(!opened)}
      />
      <div className={styles.dropdown}>
        <DropdownContainer direction="right" opened={opened} closeDropdown={() => setOpened(false)}>
          {onEdit && (
            <DropdownItem onClick={onEdit} icon="pencil" testId={`button-edit-questionnaire`}>
              <span className="text-shades-2">Éditer</span>
            </DropdownItem>
          )}
          <DropdownItem onClick={onDuplicate} icon="copy" testId={`button-duplicate-questionnaire`}>
            <span>Dupliquer</span>
          </DropdownItem>
          <RoleRestriction roles={ADMIN_ROLES}>
            <DropdownItem
              onClick={onDuplicateToAnotherUser}
              icon="duplicate"
              testId={`button-transfer-questionnaire`}
            >
              <span>Dupliquer vers un autre utilisateur</span>
            </DropdownItem>
          </RoleRestriction>
          {onDelete && (
            <DropdownItem onClick={onDelete} icon="trash" testId={`button-delete-questionnaire`}>
              <span className="text-shades-2">Supprimer</span>
            </DropdownItem>
          )}
        </DropdownContainer>
      </div>
    </div>
  )
}
