import React, { FunctionComponent } from 'react'
import classNames from 'classnames/bind'

import { getColor, TagTextColors } from '../../../../colors'

import { ITagColorProps } from './TagColor.model'
import styles from './TagColor.module.scss'

const cx = classNames.bind(styles)

export const TagColor: FunctionComponent<ITagColorProps> = ({
  active = false,
  color,
  onClick,
  disabled = false,
}) => (
  <div
    className={cx(styles.color, { active, disabled })}
    style={{ backgroundColor: getColor(TagTextColors[color]) }}
    onClick={!disabled ? () => onClick && onClick(color) : undefined}
  />
)
