import React, { FunctionComponent } from 'react'
import { TimelineItemProps } from './TimelineItem.model'
import { StyledTimeLineItem } from './TimelineItem.styled'

export const TimelineItem: FunctionComponent<TimelineItemProps> = ({
  children,
  hiddenOnMobile,
  headerLess,
}) => (
  <StyledTimeLineItem hiddenOnMobile={hiddenOnMobile} headerLess={headerLess}>
    {children}
  </StyledTimeLineItem>
)
