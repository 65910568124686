import React from 'react'
import styles from './CompatibilityContainer.module.scss'

const CompatibilityContainer: React.FC = () => (
  <div className={styles.background}>
    <div className={styles.container}>
      <div className={styles.logo} />
      <div>
        <h2 className={styles.title}>Votre périphérique n'est pas supporté</h2>
        <p>
          Afin d'apporter le meilleur service possible à vos patients, nous vous recommandons de
          vérifier que vous utilisez bien le navigateur{' '}
          <a href="https://www.google.com/chrome/">Chrome</a> sur ordinateur et que votre tablette
          bénéficie bien de ses dernières mises à jour.
        </p>
        <p>En cas de doute, nous serons ravis de vous conseiller à l'adresse suivante :</p>
        <p>
          <a href="mailto:support@follow.fr">support@follow.fr</a>
        </p>
        <p>Agent Utilisateur: {navigator.userAgent}</p>
        <p>Système d'exploitation: {navigator.platform}</p>
      </div>
    </div>
  </div>
)

export default CompatibilityContainer
