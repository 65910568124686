import React, { FunctionComponent, useContext, useEffect, useState } from 'react'
import { useMeasure } from 'react-use'

import { MedicalEventDocumentType } from '../../../model/MedicalEvent'

import { FullLoader } from '../../shared'

import WithQuestionContext from '../../../containers/Question/WithQuestionContext'

import { AddMedicalEventContent } from './AddMedicalEventContent'
import { Observations } from './Observations'
import { DocumentInstanceContent } from './DocumentInstanceContent'

import { MedicalEventContentProps } from './MedicalEventContent.model'
import styles from './MedicalEventContent.module.scss'
import { SelectedContentType } from '../../../store/ui/medicalEvents/medicalEventContent'
import { FileInstanceContent } from './FileInstanceContent'
import { FileDropzone, FileViewer } from '../../file'
import classNames from 'classnames'
import { AddEprescriptionTile, OrdoclicIframe } from '../..//eprescription'
import { FileInstanceContentBanner } from './FileInstanceContent/FileInstanceContentBanner'
import {
  extractOwnerOrCreatorNameFromFwDocumentFile,
  generateOwnerCreateNameTitle,
} from '../../../store/domain/medicalEvents/medicalEvents.utils'
import { ConnectedUserContext } from '../../../misc/auth.utilities'
import { useCurrentPatient } from '../../../hooks/utils'

export const MedicalEventContent: FunctionComponent<MedicalEventContentProps> = ({
  medicalEvent,
  documentQuestionList,
  loading,
  questionnaire,
  prescription,
  quoteLine,
  selectedMedicalEventDocument,
  selectedContentFilter,
  selectMedicalEventContent,
  selectMedicalEventDocument,
  addFilesToMedicalEvent,
  lockDocument,
  updateMedicalEvent,
  userEnabledFeatures,
}) => {
  const { currentPatient } = useCurrentPatient()
  const [ref, measure] = useMeasure()
  const [openOrdoclic, setOpenOrdoclic] = useState(false)
  const { currentUser } = useContext(ConnectedUserContext)

  useEffect(() => {
    if (medicalEvent && !medicalEvent.isEditable) {
      selectMedicalEventDocument({
        medicalEventDocumentType: MedicalEventDocumentType.OBSERVATIONS,
      })
    }
  }, [medicalEvent, selectMedicalEventDocument])

  if (loading) {
    return <FullLoader />
  }

  const creatorAndOwnerNameExtracted = extractOwnerOrCreatorNameFromFwDocumentFile(
    selectedMedicalEventDocument,
    currentUser,
  )

  const ownerAndCreatorName = generateOwnerCreateNameTitle(
    creatorAndOwnerNameExtracted.ownerName,
    creatorAndOwnerNameExtracted.creatorName,
  )

  if (selectedMedicalEventDocument && medicalEvent) {
    if (selectedMedicalEventDocument.type === MedicalEventDocumentType.FW_DOCUMENT) {
      if (selectedMedicalEventDocument.item.type === 'farte') {
        return (
          <div ref={ref} className={styles.container}>
            <WithQuestionContext
              singleColumnDisplay={measure.width < 1024}
              readOnly={!medicalEvent?.isEditable}
            >
              <DocumentInstanceContent
                key={selectedMedicalEventDocument.item.id}
                medicalEvent={medicalEvent}
                documentInstance={selectedMedicalEventDocument.item}
                documentQuestionList={documentQuestionList}
                questionnaire={questionnaire}
                prescription={prescription}
                quoteLine={quoteLine}
                selectedContent={selectedContentFilter}
                onSelectionChange={() => {
                  selectMedicalEventContent({
                    type: SelectedContentType.ADD_VARIABLE_PANEL,
                  })
                }}
                lockDocument={lockDocument}
              />
            </WithQuestionContext>
          </div>
        )
      } else {
        return (
          <>
            {ownerAndCreatorName && (
              <div className={styles.fileViewerHeaderOwnerCreatorName}>{ownerAndCreatorName}</div>
            )}
            <FileInstanceContent documentInstance={selectedMedicalEventDocument.item} />
          </>
        )
      }
    }

    if (selectedMedicalEventDocument.type === MedicalEventDocumentType.OBSERVATIONS) {
      return (
        <Observations
          content={medicalEvent.observation ?? ''}
          onChange={(value) => updateMedicalEvent(medicalEvent.id, { observation: value })}
          readonly={!medicalEvent.isEditable}
        />
      )
    }

    if (selectedMedicalEventDocument.type === MedicalEventDocumentType.FILE) {
      const file = selectedMedicalEventDocument.item
      return (
        <>
          <FileInstanceContentBanner
            file={file}
            selectMedicalEventDocument={selectMedicalEventDocument}
          />
          {ownerAndCreatorName && (
            <div className={styles.fileViewerHeaderOwnerCreatorName}>{ownerAndCreatorName}</div>
          )}
          <div className={styles.fileViewer}>
            <FileViewer file={file} enableScrollButton />
          </div>
        </>
      )
    }
  }
  return medicalEvent ? (
    <div className={styles.addContentContainer}>
      <AddMedicalEventContent medicalEventId={medicalEvent.id} />
      <div className={classNames(styles.floatingTile, styles.dropZone)}>
        <FileDropzone type="file" onDrop={addFilesToMedicalEvent} />
      </div>
      {userEnabledFeatures?.ePrescription && (
        <>
          <div className={classNames(styles.floatingTile, styles.eprescription)}>
            <AddEprescriptionTile
              onClick={() => {
                setOpenOrdoclic(true)
              }}
            />
          </div>
          <OrdoclicIframe
            setOpenOrdoclic={() => {
              setOpenOrdoclic(false)
            }}
            ordoclicPatientId={currentPatient?.ordoclicPatientId}
            display={openOrdoclic}
          />
        </>
      )}
    </div>
  ) : null
}
