import { object, ref, string } from 'yup'

export const PasswordValidationSchema = object().shape({
  actualPassword: string().required('Mot de passe actuel requis'),
  newPassword: string()
    .required('Nouveau mot de passe requis')
    .min(8, 'Le mot de passe doit faire au moins 8 caractères'),
  newPasswordConfirmation: string()
    .oneOf([ref('newPassword'), null], 'Les mots de passe ne correspondent pas')
    .required('Confirmation requise'),
})
