import { Team } from '../../../model/Team'
import { atomWithReset } from 'jotai/utils'
import { User } from '../../../model/User'

interface EditTeamPanelAtomState {
  selectedTeam: Team | null
  selectedUser: User | null
}

export const editTeamPanelAtom = atomWithReset<EditTeamPanelAtomState>({
  selectedTeam: null,
  selectedUser: null,
})
