export enum Roles {
  DOCTOR = 'ROLE_DOCTOR',
  ASSISTANT = 'ROLE_ASSISTANT',
  ADMIN_TECH = 'ROLE_ADMIN_TECH',
  ADMIN_CSM = 'ROLE_ADMIN_CSM',
  ADMIN_SALE = 'ROLE_ADMIN_SALE',
  ADMIN_OFFICE_MANAGER = 'ROLE_ADMIN_OFFICE_MANAGER',
  EXT_DSM = 'ROLE_EXT_DSM',
  SUPER_ADMIN = 'ROLE_SUPER_ADMIN',
}

export const ADMIN_ROLES = [
  Roles.SUPER_ADMIN,
  Roles.ADMIN_TECH,
  Roles.ADMIN_CSM,
  Roles.ADMIN_SALE,
  Roles.ADMIN_OFFICE_MANAGER,
] as const
