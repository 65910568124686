import { BlockAlignment } from 'core/model/TextEditor.model'
import { RenderAttributes } from 'slate-react'

export const DEFAULT_MAX_WIDTH = 920
export interface ImageProps {
  url: string
  size?: Size
  isFocused: boolean
  readOnly: boolean
  editing: boolean
  align?: BlockAlignment
  renderAttributes: RenderAttributes
  setSize: (size: Size) => void
}

export interface Size {
  width: number
  height: number
}

export const MINIMAL_SIZE: Size = { width: 50, height: 50 }
