import { DocumentCategoryKeys, FollowTypes } from '@follow/cdk'
import { Colors } from '../colors'
import { IconsType } from '../components/shared'

export type DocumentCategory = FollowTypes.DocumentCategory.DocumentCategory

export { DocumentCategoryKeys }

export enum BizoneMarker {
  ALD = 'aldEnd',
  NON_ALD = 'nonAldEnd',
}

export const DocumentCategoryIcon: { [property in DocumentCategoryKeys]: IconsType } = {
  [DocumentCategoryKeys.ConsultationNote]: `stethoscope`,
  [DocumentCategoryKeys.DischargeSummaryNote]: `out`,
  [DocumentCategoryKeys.PrivacyPolicyAcknowledgment]: `fingerPrint`,
  [DocumentCategoryKeys.SurgicalOperationNote]: `scalpel`,
  [DocumentCategoryKeys.ReferralNote]: `stethoscope`,
  [DocumentCategoryKeys.PrescriptionForMedication]: `pills`,
  [DocumentCategoryKeys.BizonePrescriptionForMedication]: `pills`,
  [DocumentCategoryKeys.Certificate]: `fileChecked`,
  [DocumentCategoryKeys.Quotation]: `calculator`,
  [DocumentCategoryKeys.Checkup]: `stethoscope`,
  [DocumentCategoryKeys.Other]: `documentText`,
  [DocumentCategoryKeys.PdfForm]: 'fileInstance',
  [DocumentCategoryKeys.Survey]: 'chat',
  [DocumentCategoryKeys.ReceivedDocument]: 'receivedDocument',
  [DocumentCategoryKeys.MedicalSummary]: 'mailbox',
  [DocumentCategoryKeys.RequestForTelehealth]: 'camera',
  [DocumentCategoryKeys.FacilityDischargeSummaryNote]: 'mail',

  [DocumentCategoryKeys.PrescriptionForFootCare]: 'pills',
  [DocumentCategoryKeys.PrescriptionForInfirmaryAct]: 'pills',
  [DocumentCategoryKeys.PrescriptionForMedicalBiology]: 'pills',
  [DocumentCategoryKeys.PrescriptionForOrthoptics]: 'pills',
  [DocumentCategoryKeys.PrescriptionForPhysicalTherapy]: 'pills',
  [DocumentCategoryKeys.PrescriptionForSpeechTherapy]: 'pills',
}

export const DocumentCategoryColor: { [property in DocumentCategoryKeys]: Colors } = {
  [DocumentCategoryKeys.ConsultationNote]: Colors.dsPrimaryDark,
  [DocumentCategoryKeys.DischargeSummaryNote]: Colors.dsSecondaryDark,
  [DocumentCategoryKeys.PrivacyPolicyAcknowledgment]: Colors.backgroundBlue6,
  [DocumentCategoryKeys.SurgicalOperationNote]: Colors.dsSecondaryDark,
  [DocumentCategoryKeys.ReferralNote]: Colors.backgroundBlue6,
  [DocumentCategoryKeys.PrescriptionForMedication]: Colors.backgroundBlue6,
  [DocumentCategoryKeys.BizonePrescriptionForMedication]: Colors.backgroundBlue6,
  [DocumentCategoryKeys.Certificate]: Colors.backgroundBlue6,
  [DocumentCategoryKeys.Quotation]: Colors.backgroundBlue6,
  [DocumentCategoryKeys.Checkup]: Colors.dsDark1,
  [DocumentCategoryKeys.Other]: Colors.backgroundBlue6,
  [DocumentCategoryKeys.PdfForm]: Colors.backgroundBlue6,
  [DocumentCategoryKeys.Survey]: Colors.backgroundBlue6,
  [DocumentCategoryKeys.ReceivedDocument]: Colors.backgroundBlue6,

  [DocumentCategoryKeys.FacilityDischargeSummaryNote]: Colors.backgroundBlue6,
  [DocumentCategoryKeys.MedicalSummary]: Colors.backgroundBlue6,
  [DocumentCategoryKeys.PrescriptionForFootCare]: Colors.backgroundBlue6,
  [DocumentCategoryKeys.PrescriptionForInfirmaryAct]: Colors.backgroundBlue6,
  [DocumentCategoryKeys.PrescriptionForMedicalBiology]: Colors.backgroundBlue6,
  [DocumentCategoryKeys.PrescriptionForOrthoptics]: Colors.backgroundBlue6,
  [DocumentCategoryKeys.PrescriptionForPhysicalTherapy]: Colors.backgroundBlue6,
  [DocumentCategoryKeys.PrescriptionForSpeechTherapy]: Colors.backgroundBlue6,
  [DocumentCategoryKeys.RequestForTelehealth]: Colors.backgroundBlue6,
}
