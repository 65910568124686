import { connect } from 'react-redux'

import { RootState } from '../../../../store'
import { clipboardActions } from '../../../../store/clipboard'
import { domainDocumentHistoryActions } from '../../../../store/domain/documentHistory'
import { domainDocumentInstancesActions } from '../../../../store/domain/documentInstances'
import { bottomPanelBaseVariablesActions } from '../../../../store/ui/bottomPanelBaseVariables'
import {
  bottomPanelDocumentHistoryActions,
  isDocumentHistoryPanelOpenSelector,
} from '../../../../store/ui/bottomPanelDocumentHistory'
import { bottomPanelQuestionsActions } from '../../../../store/ui/bottomPanelQuestions'
import { medicalEventContentUiActions } from '../../../../store/ui/medicalEvents/medicalEventContent'
import { medicalEventDocumentInstancesActions } from '../../../../store/ui/medicalEvents/medicalEventDocumentInstances'

import {
  DocumentInstanceContentDispatchProps,
  DocumentInstanceContentStateProps,
} from './DocumentInstanceContent.model'
import { DocumentInstanceContent } from './DocumentInstanceContent.component'
import { bottomPanelDrugsActions } from '../../../../store/ui/bottomPanelDrugs'
import { medicalEventsActions } from '../../../../store/cache/medicalEvents'
import {
  documentAlertsSelector,
  documentSeverityLabelsSelector,
  documentVidalInfosSelector,
} from '../../../../store/domain/documentAlerts/documentAlerts.selectors'
import { setBottomPanelOptions } from '../../../../store/ui/bottomPanel'
import { getVariableData, setVariableData, variableDataSelector } from '../../../../store/renderer'
import { documentAlertsDomainActions } from '../../../../store/domain/documentAlerts'
import { documentAlertsDisplayableSamAlertsSelector } from '../../../../store/ui/documentAlerts/documentAlerts.selector'
import { uiDocumentAlertsActions } from '../../../../store/ui/documentAlerts'
import { bottomPanelManualPrescriptionActions } from '../../../../store/ui/bottomPanelManualPrescription'
import { quoteLineActions } from '../../../../store/ui/quoteLine'

const mapStateToProps = (state: RootState): DocumentInstanceContentStateProps => ({
  isDocumentHistoryOpen: isDocumentHistoryPanelOpenSelector(state),
  documentAlerts: documentAlertsSelector(state),
  vidalInfos: documentVidalInfosSelector(state),
  severityLabels: documentSeverityLabelsSelector(state),
  displayableSamAlerts: documentAlertsDisplayableSamAlertsSelector(state),
  variablesData: variableDataSelector(state),
})

const mapDispatchToProps: DocumentInstanceContentDispatchProps = {
  addExistingQuestionToInUseDocumentInstance:
    medicalEventDocumentInstancesActions.addExistingQuestionToInUseDocumentInstance,
  addQuestionnaireToInUseDocumentInstance:
    medicalEventDocumentInstancesActions.addQuestionnaireToInUseDocumentInstance,
  addQuestionToInUseDocumentInstance:
    medicalEventDocumentInstancesActions.addQuestionToInUseDocumentInstance,
  createDocumentHistoryRevision: domainDocumentHistoryActions.createDocumentHistoryRevision,
  editQuestion: bottomPanelQuestionsActions.editQuestionFromMedicalEvent,
  searchBaseVariable: bottomPanelBaseVariablesActions.searchBaseVariable,
  searchDrug: bottomPanelDrugsActions.searchDrug,
  addManualPrescription: bottomPanelManualPrescriptionActions.addManualPrescription,
  addQuoteLine: quoteLineActions.addQuoteLine,
  searchDocumentHistory: bottomPanelDocumentHistoryActions.searchDocumentHistory,
  setSelectedContent: medicalEventContentUiActions.selectMedicalEventContent,
  updateDocumentInstanceValue: domainDocumentInstancesActions.updateDocumentInstanceValue,
  updateDocumentInstanceThenRefetchVariableData:
    domainDocumentInstancesActions.updateDocumentInstanceWithVariableDataRefetch,
  copyHtmlToClipboard: clipboardActions.copyHtmlToClipboard,
  setInUseMedicalEvent: medicalEventsActions.actions.storeSetItemDetails,
  setBottomPanelOptions,
  getDocumentAlerts: documentAlertsDomainActions.getAlerts,
  disableCurrentSamAlerts: uiDocumentAlertsActions.disableCurrentSamTypes,
  acknowledgeCurrentSamAlerts: uiDocumentAlertsActions.acknowledgeCurrentSamAlerts,
  getVariableData,
  setVariableData,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)(DocumentInstanceContent)
export { withConnect as DocumentInstanceContent }
