import React, { FunctionComponent } from 'react'

import { ReactComponent as NoStatistics } from '../../../assets/images/noscore.svg'
import { Heading } from '../../shared'

import { PatientStatisticsPlaceholderProps } from './PatientStatisticsPlaceholder.model'

import styles from './PatientStatisticsPlaceholder.module.scss'

export const PatientStatisticsPlaceholder: FunctionComponent<PatientStatisticsPlaceholderProps> = ({
  title = `Aucune évolution n'est modélisable`,
  advice = `Remplissez au moins 2 fois le même questionnaire à points pour ce patient, et retrouvez l'évolution ici.`,
}) => (
  <div className={styles.container}>
    <div className={styles.placeholder}>
      <div className={styles.image}>
        <NoStatistics className={styles.svg} />
      </div>
      <Heading size={3}>{title}</Heading>
      <p>{advice}</p>
    </div>
  </div>
)
