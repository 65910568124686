import { connect } from 'react-redux'
import { RootState } from '../../../store'
import { medicalEventDomainActions } from '../../../store/domain/medicalEvents'
import { dmpDomainActions, dmpRequestSelector } from '../../../store/domain/dmp'
import { addValid } from '../../../store/message'
import { EfficienceIframe } from './EfficienceIframe.component'
import { EfficienceIframeDispatchProps, EfficienceIframeStateProps } from './EfficienceIframe.model'
import { metricsActions } from '../../../store/domain/metrics'

const mapStateToProps = (state: RootState): EfficienceIframeStateProps => ({
  request: dmpRequestSelector(state),
})

const mapDispatchToProps: EfficienceIframeDispatchProps = {
  updateDocument: medicalEventDomainActions.updateDocumentThenFetchEvent,
  setRequest: dmpDomainActions.setRequest,
  addValid: addValid,
  sendDmpMetrics: metricsActions.sendDmpMetrics,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)(EfficienceIframe)
export { withConnect as EfficienceIframe }
