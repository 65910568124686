// https://flagpedia.net/download/api
export const FLAG_CDN_BASEURL = 'https://flagcdn.com'

export interface PhoneCountryCode {
  phoneCode: string
  countryCode: string
  label: string
}

export const PHONE_COUNTRY_CODES: PhoneCountryCode[] = [
  { phoneCode: '33', countryCode: 'FR', label: 'France' },
  { phoneCode: '376', countryCode: 'AD', label: 'Andorre' },
  { phoneCode: '32', countryCode: 'BE', label: 'Belgique' },
  { phoneCode: '590', countryCode: 'GP', label: 'Guadeloupe' },
  { phoneCode: '594', countryCode: 'GF', label: 'Guyane' },
  { phoneCode: '39', countryCode: 'IT', label: 'Italie' },
  { phoneCode: '262', countryCode: 'RE', label: 'La Réunion' },
  { phoneCode: '352', countryCode: 'LU', label: 'Luxembourg' },
  { phoneCode: '596', countryCode: 'MQ', label: 'Martinique' },
  { phoneCode: '262', countryCode: 'YT', label: 'Mayotte' },
  { phoneCode: '377', countryCode: 'MC', label: 'Monaco' },
  { phoneCode: '687', countryCode: 'NC', label: 'Nouvelle-Calédonie' },
  { phoneCode: '689', countryCode: 'PF', label: 'Polynésie française' },
  { phoneCode: '590', countryCode: 'BL', label: 'Saint-Barthélémy' },
  { phoneCode: '590', countryCode: 'MF', label: 'Saint-Martin' },
  { phoneCode: '508', countryCode: 'PM', label: 'Saint-Pierre-et-Miquelon' },
  { phoneCode: '41', countryCode: 'CH', label: 'Suisse' },
  { phoneCode: '262', countryCode: 'TF', label: 'Terres australes et antarctiques françaises' },
  { phoneCode: '681', countryCode: 'WF', label: 'Wallis-et-Futuna' },
]
