import { ApiResponse } from 'apisauce'
import { call, put, takeEvery } from 'redux-saga/effects'
import { AtcClass } from '../../../model/Drug'
import { PaginatedList } from '../../../model/Pagination'
import { atcClassificationActions } from '../../cache/atcClassification'
import { fetchClassChildren } from '../../cache/atcClassification/api'
import { addResponseError } from '../../message'
import { RestuxLoadingState } from '../../restux/ui'
import { uiSagasFactory } from '../../restux/ui/restuxUiSagas.factory'
import { uiAtcClassificationActions } from './atcClassification.actions'
import { atcClassificationConfig, uiAtcClassificationActionTypes } from './atcClassification.model'

const paginationSagas = uiSagasFactory(atcClassificationConfig)

function* getClassChildrenWorker({
  id,
}: ReturnType<typeof uiAtcClassificationActions.getClassChildren>) {
  yield put(uiAtcClassificationActions.setPaginationLoading(RestuxLoadingState.LOADING))
  const response: ApiResponse<PaginatedList<AtcClass>> = yield call(fetchClassChildren, id)

  if (response.ok && response.data) {
    const { items, currentPage, itemCount, limit, pageCount } = response.data
    yield put(
      atcClassificationActions.actions.storeSetListItems({
        items,
        currentPage,
        itemCount,
        limit,
        pageCount,
      }),
    )
  } else {
    yield put(addResponseError(response))
  }

  yield put(uiAtcClassificationActions.setPaginationLoading(RestuxLoadingState.IDLE))
}

function* getClassChildrenWatcher() {
  yield takeEvery(uiAtcClassificationActionTypes.GET_CLASS_CHILDREN, getClassChildrenWorker)
}

export const atcClassificationSagas = {
  ...paginationSagas,
  getClassChildrenWatcher,
}
