import React, { FunctionComponent } from 'react'
import { Checkbox } from '../../../components/legacy/Checkbox'
import { EditorVariableDisplayConfig } from '@follow/cdk'
import { CommonVariableActions } from '../CommonVariableActions'
import { BaseVariableConfigurationProps } from './BaseVariableConfiguration.model'
import styles from './BaseVariableConfiguration.module.scss'
import { UNDISPLAYABLE } from '../VariableConfiguration.model'
import { TooltipWrapper } from '../../../components/shared'

const BaseVariableConfiguration: FunctionComponent<BaseVariableConfigurationProps> = ({
  renderTitle,
  displayConfig,
  pendingValue,
  onDisplayConfigChange,
  ...restProps
}) => {
  const handleCapitalize = (checked: boolean) => {
    if (checked) {
      onDisplayConfigChange(EditorVariableDisplayConfig.CAPITALIZE)
    } else {
      onDisplayConfigChange(undefined)
    }
  }

  return (
    <CommonVariableActions {...restProps}>
      {renderTitle}
      {pendingValue && (
        <div className={styles.pendingValueLabel}>
          <span>
            Cette variable peut être mise à jour
            {pendingValue !== UNDISPLAYABLE ? ' par sa  dernière valeur :' : ''}
          </span>
          {pendingValue !== null && typeof pendingValue === 'string' && (
            <TooltipWrapper content={pendingValue}>
              <span className={styles.pendingValue}>{pendingValue}</span>
            </TooltipWrapper>
          )}
        </div>
      )}
      {
        <div className={styles.form}>
          <label>Majuscule au début</label>
          <Checkbox
            checked={displayConfig === EditorVariableDisplayConfig.CAPITALIZE}
            onChange={(event) => handleCapitalize(event.target.checked)}
          />
        </div>
      }
    </CommonVariableActions>
  )
}

export default BaseVariableConfiguration
