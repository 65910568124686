import { put, takeEvery } from 'redux-saga/effects'
import { BottomPanelComponentType, setBottomPanelOptions } from '../bottomPanel'
import { UiBottomPanelResourceHistoryActionTypes } from './bottomPanelResourceHistory.model'

function* openResourceHistoryPanelWorker() {
  yield put(
    setBottomPanelOptions({
      componentType: BottomPanelComponentType.ResourceHistory,
      open: true,
      title: 'Historique informations patient',
    }),
  )
}

function* openResourceHistoryPanelWatcher() {
  yield takeEvery(UiBottomPanelResourceHistoryActionTypes.OPEN, openResourceHistoryPanelWorker)
}

export const bottomPanelResourceHistorySagas = {
  openResourceHistoryPanelWatcher,
}
