import { ApiResponse } from 'apisauce'
import { deserializePatientHealthData } from '@follow/cdk'
import { HealthData } from '../../../../model/HealthData'
import { PaginatedList } from '../../../../model/Pagination'

export const deserializePatientHealthDataPaginatedResponse = (
  response: ApiResponse<PaginatedList<HealthData>>,
): ApiResponse<PaginatedList<HealthData>> => {
  const { data, ...responseRest } = response
  if (!response.ok || !data) {
    return { ...responseRest }
  }
  return {
    ...responseRest,
    data: { ...data, items: data.items.map(deserializePatientHealthData) },
  }
}
