import { domainMailActions } from './mail.actions'
import {
  deleteMail,
  finalizeAuthenticationMail,
  getMail,
  getMailAddressList,
  getMailAttachementFile,
  getMailList,
  initiateAuthenticationMail,
  postClassifyDocuments,
  sendMail,
} from './api'
import { call, delay, put, race, select, take, takeEvery } from '@redux-saga/core/effects'
import { addError, addValid } from '../../message'
import { customHistory } from '../../../history'
import {
  AUTHENTICATION_ID_MAIL,
  DEFAULT_LIMIT_MAIL_LIST,
  DEFAULT_OFFSET_MAIL_LIST,
  Mail,
  MailListPage,
  SEARCH_MIN_CHAR,
  TMP_AUTHENTICATION_ID_MAIL,
} from '../../../model/Mail'
import { MAIL_CONNECTION_LOOP_DELAY, MailActionTypes } from './mail.model'
import { ApiMailList, MailFile } from './api/mail.model'
import { ApiResponse } from 'apisauce'
import {
  fetchingNextMailsStatusSelector,
  inUseMailSelector,
  mailListSelector,
  mailSearchSelector,
  otpStatusSelector,
} from './mail.selector'
import { setBottomPanelOptions } from '../../ui/bottomPanel'
import { medicalEventUiActions } from '../../ui/medicalEvents'
import { patientSearchActions } from '../../cache/patientSearch'
import { emailRegex } from '../../../misc/regex'
import { enabledFeaturesPreferencesSelector } from '../me'
import { Patient } from '../../../model/Patient'
import { mailErrorsMessage, mailErrorsTitle } from './mail.error'
import { isValidBase64 } from '../../../misc/files.utilities'
import { RequestStatus } from '../../../model/RequestStatus'
import { TaskCategory } from '../../../model/Task'
import { checkPatientIdentity } from '../insi/api'
import { patientToInsiRestPatientPayload } from '../../../misc/insi.utilities'
import { IdentityCheckResponse } from '../insi'
import { Features } from '../../../model/Preference'
import { createTask } from '../../../data/task/createTask'
import { isIdentityStateQualified } from '../../../misc/patient.utilities'
import { createPatient, updatePatient } from '../../../data/patients'
import { queryClient } from '../../../App'
import { patientsKeys } from '../../../hooks/queries/patients/patients.keys'

export const subCodeTokenExpired = ({ status, data }) => {
  try {
    return status === 400 && JSON.parse(data.detail).statusCode === 401
  } catch (e) {
    return false
  }
}

export function* disconnectWorker() {
  localStorage.removeItem(TMP_AUTHENTICATION_ID_MAIL)
  localStorage.removeItem(AUTHENTICATION_ID_MAIL)
  yield put(domainMailActions.setLoginInitiate(false))
  yield put(domainMailActions.setOtpStatus(false))
  customHistory.navigate('/authenticate/mail')
}

function* disconnectWatcher() {
  yield takeEvery(MailActionTypes.DISCONNECT, disconnectWorker)
}

function* disconnectWithErrorWorker({
  redirectUri,
}: ReturnType<typeof domainMailActions.disconnectWithError>) {
  const enabledFeatures: Features = yield select(enabledFeaturesPreferencesSelector)
  if (enabledFeatures.mssSmtp && redirectUri) {
    window.location.replace(redirectUri)
  } else {
    localStorage.removeItem(TMP_AUTHENTICATION_ID_MAIL)
    localStorage.removeItem(AUTHENTICATION_ID_MAIL)
    yield put(addError(mailErrorsTitle.connexionError, mailErrorsMessage.sessionExpired))
    yield put(domainMailActions.setLoginInitiate(false))
    yield put(domainMailActions.setOtpStatus(false))
    yield put(domainMailActions.setRequestMailsStatus('error'))
    yield put(domainMailActions.setRequestInUseMailsStatus('error'))
    customHistory.navigate('/authenticate/mail')
  }
}

function* disconnectWithErrorWatcher() {
  yield takeEvery(MailActionTypes.DISCONNECT_WITH_ERROR, disconnectWithErrorWorker)
}

function* initiateAuthenticateMailWorker({
  values,
}: ReturnType<typeof domainMailActions.initiateAuthMail>) {
  const { ok, status, data } = yield call(initiateAuthenticationMail, values)

  if (!ok || status !== 201) {
    yield put(addError(mailErrorsTitle.connexionError, mailErrorsMessage.checkCredentials))
    yield put(domainMailActions.setLoginInitiate(false))
  } else {
    localStorage.setItem(TMP_AUTHENTICATION_ID_MAIL, data.authenticationId)
    yield put(domainMailActions.setLoginInitiate(true))
  }
}

function* initiateAuthenticateMailWatcher() {
  yield takeEvery(MailActionTypes.INITIATE_AUTH, initiateAuthenticateMailWorker)
}

function* finalizeAuthenticateMailWorker({
  values,
}: ReturnType<typeof domainMailActions.finalizeAuthMail>) {
  const tmpAuthenticationId = localStorage.getItem(TMP_AUTHENTICATION_ID_MAIL)
  if (!tmpAuthenticationId) throw new Error(`TMP_AUTHENTICATION_ID_MAIL not found`)

  const { ok, status, data } = yield call(finalizeAuthenticationMail, values, tmpAuthenticationId)

  if (subCodeTokenExpired({ status, data })) {
    yield put(domainMailActions.disconnectWithError())
    return
  }

  if (status === 401) {
    yield put(domainMailActions.disconnectWithError())
    return
  }

  if (!ok || status !== 201) {
    yield put(domainMailActions.setRequestMailsStatus('error'))
    return
  }

  localStorage.setItem(AUTHENTICATION_ID_MAIL, data.authenticationId)
  localStorage.removeItem(TMP_AUTHENTICATION_ID_MAIL)
  yield put(domainMailActions.setOtpStatus(true))

  const redirect = new URLSearchParams(document.location.search).get('redirect')
  if (!redirect) customHistory.navigate('/mail/inbox')
  else {
    yield put(domainMailActions.getMailAddressList())
    yield put(medicalEventUiActions.displaySendFileModal(true))
    customHistory.navigate(redirect)
  }
}

function* finalizeAuthenticateMailWatcher() {
  yield takeEvery(MailActionTypes.FINALIZE_AUTH, finalizeAuthenticateMailWorker)
}

function* getMailAddressListWorker() {
  try {
    const { ok, status, data } = yield call(getMailAddressList)

    if (subCodeTokenExpired({ status, data }))
      throw new Error('CODE_HTTP_400 : Invalid OR expired MSS token')

    if (401 === status) throw new Error('CODE_HTTP_401 : Invalid OR expired token follow')

    if (!ok || 200 !== status || data.emailAddresses.length === 0) {
      yield put(domainMailActions.setRequestMailsStatus('error'))
      return
    }

    yield put(domainMailActions.setMailAddressList(data.emailAddresses))

    if (data.emailAddresses.length > 0)
      yield put(domainMailActions.setInUseMailAddress(data.emailAddresses[0]))
  } catch (e) {
    yield put(domainMailActions.disconnectWithError())
  }
}

function* getMailAddressListWatcher() {
  yield takeEvery(MailActionTypes.GET_MAIL_ADDRESS_LIST, getMailAddressListWorker)
}

function* getMailListWorker({
  search,
  offset,
  limit,
}: ReturnType<typeof domainMailActions.getMailList>) {
  // Si tous les mails ont déjà été chargé, on ne refait pas de requête
  const status: RequestStatus = yield select(fetchingNextMailsStatusSelector)
  if (status === 'success') return

  const searchFilter = search && search.length >= SEARCH_MIN_CHAR ? search : undefined

  /* La comparaison avec les offsets permet de savoir si on est dans le cas du premier fetch */
  if (offset === DEFAULT_OFFSET_MAIL_LIST) {
    yield put(domainMailActions.setRequestMailsStatus('pending'))
  } else {
    yield put(domainMailActions.setFetchingNextMailsStatus('pending'))
  }

  try {
    const { ok, status, data, headers }: ApiResponse<ApiMailList> = yield call(
      getMailList,
      offset,
      limit,
      searchFilter,
    )

    if (subCodeTokenExpired({ status, data })) {
      yield put(domainMailActions.disconnectWithError(headers?.redirect_uri))
      return
    }

    if (401 === status) throw new Error('CODE_HTTP_401 : Invalid OR expired token follow')

    if (!ok || 200 !== status || !data) {
      yield put(addError(mailErrorsTitle.errorMail, mailErrorsMessage.errorWhileFetchingAllMail))
      yield put(domainMailActions.setRequestMailsStatus('error'))
      yield put(domainMailActions.setFetchingNextMailsStatus('error'))
      return
    }

    const { items, itemsCount }: MailListPage = yield select(mailListSelector)

    yield put(
      domainMailActions.setMailList({
        items: [...items, ...data.items],
        itemsCount: itemsCount + data.itemsCount,
      }),
    )

    if (data.items.length > 0 && DEFAULT_OFFSET_MAIL_LIST === offset) {
      if (data.itemsCount < limit) {
        // Permet de vérifier si on a récupéré tous les items
        yield put(domainMailActions.setFetchingNextMailsStatus('success'))
      } else {
        yield put(domainMailActions.setFetchingNextMailsStatus('idle'))
      }
    }
  } catch (e) {
    yield put(domainMailActions.disconnectWithError())
  }
}

function* getMailListWatcher() {
  yield takeEvery(MailActionTypes.GET_MAIL_LIST, getMailListWorker)
}

function* reloadMailListWorker({
  navigateToInbox,
}: ReturnType<typeof domainMailActions.reloadMailList>) {
  const search = yield select(mailSearchSelector)

  yield put(domainMailActions.clearMailList())
  yield put(
    domainMailActions.getMailList(DEFAULT_OFFSET_MAIL_LIST, DEFAULT_LIMIT_MAIL_LIST, search),
  )

  if (navigateToInbox) {
    customHistory.navigate('/mail/inbox')
  }
}

function* reloadMailListWatcher() {
  yield takeEvery(MailActionTypes.RELOAD_MAIL_LIST, reloadMailListWorker)
}

function* getAttachmentFileWorker({
  messageId,
  partId,
  cdaFileId,
}: ReturnType<typeof domainMailActions.getAttachmentFile>) {
  try {
    const { ok, data, status, headers }: ApiResponse<MailFile> = yield call(
      getMailAttachementFile,
      messageId,
      partId,
      cdaFileId,
    )

    if (subCodeTokenExpired({ status, data })) {
      yield put(domainMailActions.disconnectWithError(headers?.redirect_uri))
      return
    }

    if (401 === status) throw new Error('CODE_HTTP_401 : Invalid OR expired token follow')

    if (!ok || !data) {
      yield put(addError(mailErrorsTitle.errorPj, mailErrorsMessage.errorWhileFetchingAttachment))
      return
    }

    if (!isValidBase64(data.base64)) {
      yield put(addError(mailErrorsTitle.errorPj, mailErrorsMessage.invalidAttachment))
      return
    }

    const formatedBase64 = `data:${data.extension};base64,${data.base64}`
    yield put(
      domainMailActions.setInUseAttachment({
        ...data,
        base64: formatedBase64,
      }),
    )
  } catch (e) {
    yield put(domainMailActions.disconnectWithError())
  }
}

function* getAttachmentFileWatcher() {
  yield takeEvery(MailActionTypes.GET_ATTACHMENT_FILE, getAttachmentFileWorker)
}

function* getInUseMailWorker({ inUseMailId }: ReturnType<typeof domainMailActions.getInUseMail>) {
  yield put(domainMailActions.setRequestInUseMailsStatus('pending'))
  try {
    const { ok, data, status, headers }: ApiResponse<Mail> = yield call(getMail, inUseMailId)

    if (subCodeTokenExpired({ status, data })) {
      yield put(domainMailActions.disconnectWithError(headers?.redirect_uri))
      return
    }

    if (401 === status) throw new Error('CODE_HTTP_401 : Invalid OR expired token follow')

    if (!ok || !data) {
      yield put(domainMailActions.setRequestInUseMailsStatus('error'))
      yield put(addError(mailErrorsTitle.errorMail, mailErrorsMessage.errorWhileFetchingMail))
      return
    }

    yield put(domainMailActions.setInUseMail(data))
    const currentMailList: MailListPage = yield select(mailListSelector)
    yield put(
      domainMailActions.setMailList({
        ...currentMailList,
        items: currentMailList.items.map((mail) => (data && mail.id === data.id ? data : mail)),
      }),
    )
    if (data.attachments[0]?.id)
      yield put(domainMailActions.getAttachmentFile(data.id, data.attachments[0].id))
  } catch (e) {
    yield put(domainMailActions.disconnectWithError())
  }
}

function* getInUseMailWatcher() {
  yield takeEvery(MailActionTypes.GET_IN_USE_MAIL, getInUseMailWorker)
}

function* classifyDocumentsWorker({
  selectedAttachments,
  patientId,
  documentCategoryValue,
  date,
}: ReturnType<typeof domainMailActions.classifyDocuments>) {
  const messageId = (yield select(inUseMailSelector)).id
  const { ok, status, data, headers } = yield call(
    postClassifyDocuments,
    messageId,
    selectedAttachments,
    patientId,
    documentCategoryValue,
    date,
  )
  try {
    if (subCodeTokenExpired({ status, data })) {
      yield put(domainMailActions.disconnectWithError(headers?.redirect_uri))
      return
    }

    if (401 === status) throw new Error('CODE_HTTP_401 : Invalid OR expired token follow')

    if (ok)
      yield put(
        addValid(
          'Classification des documents',
          'Les documents ont été ajoutés dans un nouvel événement médical du patient',
        ),
      )
    else yield put(addError(mailErrorsTitle.errorMail, mailErrorsMessage.classificationError))
  } catch (error) {
    yield put(domainMailActions.disconnectWithError())
  }
}

function* sendMailWorker({
  subject,
  content,
  recipientEmails,
  files,
  askReadAcknowledgement,
  onError,
  patiendId,
  replyToEmail,
  documentIds,
}: ReturnType<typeof domainMailActions.sendMail>) {
  try {
    const { ok, status, data, headers } = yield call(
      sendMail,
      recipientEmails,
      subject,
      content,
      files,
      replyToEmail,
      askReadAcknowledgement,
      documentIds,
      patiendId,
    )
    if (subCodeTokenExpired({ status, data })) {
      yield put(domainMailActions.disconnectWithError(headers?.redirect_uri))
      return
    }

    if (401 === status) throw new Error('CODE_HTTP_401 : Invalid OR expired token follow')

    if (403 === status) {
      onError()
      yield put(addError(mailErrorsTitle.errorWhileSending, mailErrorsMessage.invalidInsi))
      return
    }

    if (!ok) {
      onError()
      yield put(addError(mailErrorsTitle.errorMail, mailErrorsMessage.errorWhileSendingMail))
      return
    }
    yield put(addValid('Message envoyé avec succès'))
    yield put(setBottomPanelOptions({ open: false }))
  } catch (e) {
    yield put(domainMailActions.disconnectWithError())
  }
}

function* sendMailWatcher() {
  yield takeEvery(MailActionTypes.SEND_MAIL, sendMailWorker)
}

function* deleteMailWorker({
  mailId,
  navigateToInbox,
}: ReturnType<typeof domainMailActions.deleteMail>) {
  try {
    const { ok, status, data, headers }: ApiResponse<void> = yield call(deleteMail, mailId)

    if (subCodeTokenExpired({ status, data })) {
      yield put(domainMailActions.disconnectWithError(headers?.redirect_uri))
      return
    }

    if (401 === status) throw new Error('CODE_HTTP_401 : Invalid OR expired token follow')

    if (403 === status) {
      yield put(addError(mailErrorsTitle.errorWhileDeleting, mailErrorsMessage.forbidden))
      return
    }

    if (!ok) {
      yield put(addError(mailErrorsTitle.errorMail, mailErrorsMessage.errorWhileSendingMail))
      return
    }

    yield put(domainMailActions.reloadMailList(navigateToInbox))
  } catch (e) {
    yield put(domainMailActions.disconnectWithError())
  }
}

function* deleteMailWatcher() {
  yield takeEvery(MailActionTypes.DELETE_MAIL, deleteMailWorker)
}

function* classifyDocumentsWatcher() {
  yield takeEvery(MailActionTypes.CLASSIFY_DOCUMENTS, classifyDocumentsWorker)
}

function* checkPatientEmailValidation({
  patientsEmail,
}: ReturnType<typeof domainMailActions.checkPatientEmailValidation>) {
  const unqualifiedPatientEmails: Array<string> = []
  const blackListedPatientMails: Array<string> = []

  for (const patientEmail of patientsEmail) {
    const emailParts = emailRegex.exec(patientEmail)
    if (emailParts) {
      const inseeNumber = emailParts[1]
      yield put(
        patientSearchActions.actions.getPaginatedItems({ filters: { search: inseeNumber } }),
      )
      const {
        succeed,
      }: {
        succeed: ReturnType<typeof patientSearchActions.actions.storeSetListItems>
      } = yield race({
        succeed: take(patientSearchActions.types.STORE_SET_LIST_ITEMS),
        failed: take(patientSearchActions.types.DISPATCH_ERROR),
      })
      if (succeed) {
        const patient = succeed.paginatedList.items[0]
        const enabledFeatures = yield select(enabledFeaturesPreferencesSelector)

        if (patient?.disallowMSSMessaging) {
          yield put(
            addError(
              "Impossible d'envoyer le message",
              `Le patient ${patient.birthFirstName} ${patient.birthLastName} (${patientEmail}) refuse d'être contacté via la messagerie de santé sécurisée`,
            ),
          )
          blackListedPatientMails.push(patientEmail)
        }

        if (
          enabledFeatures?.insiValidation &&
          patient &&
          !isIdentityStateQualified(patient?.identityStatus)
        ) {
          yield put(addError(mailErrorsTitle.checkPatientId, mailErrorsMessage.invalidInsi))
          unqualifiedPatientEmails.push(patientEmail)
        }
      }
    }
  }
  yield put(domainMailActions.setPatientUnqualified(unqualifiedPatientEmails))
  yield put(domainMailActions.setPatientMailsBlackList(blackListedPatientMails))
}

function* checkPatientEmailValidationWatcher() {
  yield takeEvery(MailActionTypes.CHECK_PATIENT_EMAIL_VALIDATION, checkPatientEmailValidation)
}

function* maintainMailboxConnectionLoop() {
  const otpStatus = yield select(otpStatusSelector)
  if (otpStatus) {
    const { ok } = yield call(getMailAddressList)
    if (ok) {
      yield delay(MAIL_CONNECTION_LOOP_DELAY)
      yield call(maintainMailboxConnectionLoop)
    }
  }
}

function* maintainMailboxConnectionWatcher() {
  yield race({
    otp: take(MailActionTypes.SET_OTP_STATUS),
    mailFetch: take(MailActionTypes.SET_MAIL_LIST),
  })
  yield call(maintainMailboxConnectionLoop)
}

function* insiConsultCreatePatientFromMail({
  patient,
}: ReturnType<typeof domainMailActions.createPatientFromMail>) {
  const insiCheckFailed = patient.identityStatus.insiStatus.checked === 'FAILED'

  // Si la validation échoue, on demande à l'utilisateur s'il souhaite créer le patient via une modale
  const toBeCreatedPatient = { ...patient }
  if (insiCheckFailed) {
    yield put(domainMailActions.openCreatePatientThroughMailModal())

    // Attente de l'input sur la modale
    const {
      createPatient,
    }: ReturnType<typeof domainMailActions.closeCreatePatientThroughMailModal> = yield take(
      MailActionTypes.SET_CREATE_PATIENT_THROUGH_MAIL_MODAL_OPEN,
    )

    // Si l'utilisateur souhaite créer le patient, on retire l'INS et l'OID (exigence SEGUR)
    if (createPatient) {
      toBeCreatedPatient.inseeNumber = null
      toBeCreatedPatient.assigningAuthority = null
    } else {
      return
    }
  }

  // Création du patient
  const { data: createdPatient, ok }: ApiResponse<Patient> = yield call(
    createPatient,
    toBeCreatedPatient,
    {
      external: true,
      forceInsiCheck: false,
    },
  )
  if (ok && createdPatient) {
    // Invalidation du cache de liste patient RQ
    queryClient.invalidateQueries(patientsKeys.lists)

    yield put(domainMailActions.setAttachementPatient(createdPatient))
    if (insiCheckFailed) {
      yield call(createTask, {
        title: "Échec de la validation de l'identité via l'INSi",
        autoCreated: true,
        category: TaskCategory.INFORMATION,
        patientId: createdPatient.id,
        isNotify: true,
      })
    }
  } else {
    yield put(addError("Le patient n'a pas pu être créé"))
  }
}

function* simplyCreatePatientFromMail({
  patient,
}: ReturnType<typeof domainMailActions.createPatientFromMail>) {
  const toBeCreatedPatient = {
    ...patient,
    inseeNumber: null,
    assigningAuthority: null,
  }

  const patientResponse: ApiResponse<Patient> = yield call(createPatient, toBeCreatedPatient)

  if (patientResponse.ok && patientResponse.data) {
    // Invalidation du cache de liste patient RQ
    queryClient.invalidateQueries(patientsKeys.lists)

    yield put(addValid('Patient créé avec succès'))
    yield put(domainMailActions.setAttachementPatient(patientResponse.data))
  } else {
    yield put(addError('Échec de la création du patient'))
  }
}

function* insiRestCreatePatientFromMail({
  patient,
}: ReturnType<typeof domainMailActions.createPatientFromMail>) {
  const serializedPatient = patientToInsiRestPatientPayload(patient)
  if (!serializedPatient) return

  // Vérification INSi
  const insiResponse: ApiResponse<IdentityCheckResponse> = yield call(
    checkPatientIdentity,
    serializedPatient,
  )

  // Si la validation échoue, on demande à l'utilisateur s'il souhaite créer le patient via une modale
  const toBeCreatedPatient = { ...patient }
  if (!(insiResponse.ok && insiResponse.data && insiResponse.data.status === 'FOUND')) {
    yield put(domainMailActions.openCreatePatientThroughMailModal())

    // Attente de l'input sur la modale
    const {
      createPatient,
    }: ReturnType<typeof domainMailActions.closeCreatePatientThroughMailModal> = yield take(
      MailActionTypes.SET_CREATE_PATIENT_THROUGH_MAIL_MODAL_OPEN,
    )

    // Si l'utilisateur souhaite créer le patient, on retire l'INS et l'OID (exigence SEGUR)
    if (createPatient) {
      toBeCreatedPatient.inseeNumber = null
      toBeCreatedPatient.assigningAuthority = null
    } else {
      return
    }
  }

  // Création du patient
  const patientResponse: ApiResponse<Patient> = yield call(createPatient, toBeCreatedPatient, {
    external: true,
    forceInsiCheck: true,
  })

  if (patientResponse.ok && patientResponse.data) {
    // Invalidation du cache de liste patient RQ
    queryClient.invalidateQueries(patientsKeys.lists)

    yield put(addValid('Patient créé avec succès'))
    yield put(domainMailActions.setAttachementPatient(patientResponse.data))
  } else {
    yield put(addError('Échec de la création du patient'))
  }
}

function* createPatientFromMailWorker(
  action: ReturnType<typeof domainMailActions.createPatientFromMail>,
) {
  const features: Features | null = yield select(enabledFeaturesPreferencesSelector)
  if (features?.insiValidation === 'insiValidationInApp') {
    yield insiConsultCreatePatientFromMail(action)
    return
  }
  if (features?.insiValidation === 'insiValidationRest') {
    yield insiRestCreatePatientFromMail(action)
    return
  }
  // Bypass validation pour tester l'extraction de patient en prod.
  // TODO: reactiver le check INSI quand on aura testé l'INSI en prod.
  if (features?.mssExtractPatient) {
    yield simplyCreatePatientFromMail(action)
    return
  }
}

function* createPatientFromMailWatcher() {
  yield takeEvery(MailActionTypes.CREATE_PATIENT_FROM_MAIL, createPatientFromMailWorker)
}

function* updatePatientFromMailWorker({
  patientId,
  patient,
  forceInsiCheck,
  external,
}: ReturnType<typeof domainMailActions.updatePatientFromMail>) {
  if (!forceInsiCheck && patient.identityStatus.insiStatus.checked === 'FAILED') {
    yield put(
      addError("Échec de la vérification de l'identité", "Le patient n'a pas été mis à jour"),
    )
  } else {
    const { data: createdPatient, ok }: ApiResponse<Patient> = yield call(
      updatePatient,
      patientId,
      patient,
      { forceInsiCheck, external },
    )

    if (ok && createdPatient) {
      // Invalidation du cache de liste et détail patient RQ
      queryClient.invalidateQueries(patientsKeys.lists)
      queryClient.invalidateQueries(patientsKeys.detail(createdPatient.id))

      yield put(domainMailActions.setAttachementPatient(createdPatient))
    } else {
      yield put(addError("Le patient n'a pas pu être mis à jour"))
    }
  }
}

function* updatePatientFromMailWatcher() {
  yield takeEvery(MailActionTypes.UPDATE_PATIENT_FROM_MAIL, updatePatientFromMailWorker)
}

export const mailSagas = {
  initiateAuthenticateMailWatcher,
  finalizeAuthenticateMailWatcher,
  getMailAddressListWatcher,
  getMailListWatcher,
  reloadMailListWatcher,
  getAttachmentFileWatcher,
  getInUseMailWatcher,
  classifyDocumentsWatcher,
  sendMailWatcher,
  deleteMailWatcher,
  maintainMailboxConnectionWatcher,
  checkPatientEmailValidationWatcher,
  createPatientFromMailWatcher,
  updatePatientFromMailWatcher,
  disconnectWatcher,
  disconnectWithErrorWatcher,
}
