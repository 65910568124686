import { CommonPluginsParams, getCommonPlugins } from './commonPlugins'
import { Variable } from './variable'
import { VariableClass } from './variableClass'
import { variableFlatten } from './variableFlatten'
import { variableFlattenBlock } from './variableFlattenBlock'

export const getVariablePlugins = (commonArgs: CommonPluginsParams) => [
  ...getCommonPlugins(commonArgs),
  Variable,
  VariableClass,
  variableFlatten,
  variableFlattenBlock,
]
