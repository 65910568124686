import React, { useState, FunctionComponent } from 'react'
import { FiltersWrapper, LabeledButton, MenuChip } from '../../shared'
import { FormCard } from './FormCard.component'
import { DocumentCategoryIcon } from '../../../model/DocumentCategory'
import { DocumentTemplateListItem } from '../../../model/DocumentTemplate'

interface DocumentFormCardProps {
  addDocument: () => void
  removeDocument: (id: number) => void
  description: string
  documentTemplates: DocumentTemplateListItem[]
}

export const DocumentFormCard: FunctionComponent<DocumentFormCardProps> = ({
  documentTemplates,
  description,
  addDocument,
  removeDocument,
}) => {
  const [selectedDocumentId, setSelectedDocumentId] = useState<null | number>(null)

  const handleDocumentClick = (document: DocumentTemplateListItem) => () => {
    if (selectedDocumentId === document.id) {
      setSelectedDocumentId(null)
    } else {
      setSelectedDocumentId(document.id)
    }
  }

  const handleDocumentDelete = (id: number) => () => {
    setSelectedDocumentId(null)
    removeDocument(id)
  }

  return (
    <FormCard title="Document" label={description}>
      <FiltersWrapper variance="variant1">
        {documentTemplates.map((document, index) => (
          <MenuChip
            key={index}
            onClick={handleDocumentClick(document)}
            label={document.title}
            collapsed={selectedDocumentId !== document.id}
            selected={selectedDocumentId === document.id}
            onDelete={handleDocumentDelete(document.id)}
            icon={DocumentCategoryIcon[document.category.id]}
          />
        ))}
        <LabeledButton icon="add" label="Document" size="small" onClick={addDocument} />
      </FiltersWrapper>
    </FormCard>
  )
}
