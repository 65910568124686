export enum UserTypeId {
  Chir = 1,
  Kine = 2,
  Labo = 3,
  Medecin = 4,
  Other = 5,
}

export enum ExternalServices {
  STELLAIR = 'stellair',
  SEPHIRA = 'sephira',
}
