import { FC } from 'react'
import { TooltipWrapperProps, oppositeArrowDirection } from './TooltipWrapper.model'
import Tippy from '@tippyjs/react/headless'
import { Tooltip } from '../Tooltip'

export const TooltipWrapper: FC<TooltipWrapperProps> = ({
  children,
  content,
  pointerDirection = 'bottom',
  display = true,
  size,
  interactive = false,
  textAlign = 'center',
  debounceHiding,
}) => {
  return display ? (
    <Tippy
      zIndex={1000000001} // Juste un cran au dessus du bottom panel, https://fwhealth.atlassian.net/browse/DEV-1042
      placement={oppositeArrowDirection[pointerDirection]}
      render={() => (
        <Tooltip
          size={size}
          content={content}
          pointerDirection={pointerDirection}
          textAlign={textAlign}
        />
      )}
      interactive={interactive}
      delay={debounceHiding ? [null, 500] : undefined}
    >
      {children}
    </Tippy>
  ) : (
    <>{children}</>
  )
}
