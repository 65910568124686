import { ReactNode } from 'react'

export const InlineTagColors = {
  lightGray: {
    color: 'text-shades-4',
    background: 'bg-shades-7',
  },
  red: {
    color: 'text-white',
    background: 'bg-red-600',
  },
  yellow: {
    color: 'text-white',
    background: 'bg-amber-500',
  },
  orange: {
    color: 'text-white',
    background: 'bg-orange-600',
  },
  primaryDefault: {
    color: 'text-white',
    background: 'bg-primary-default',
  },
} as const

export type InlineTagColorsType = typeof InlineTagColors
export type InlineTagColorsName = keyof InlineTagColorsType

export interface InlineTagProps {
  children?: ReactNode
  name?: string
  color?: InlineTagColorsName
  onClick?: (event: React.MouseEvent) => void
}
