import {
  LightUser,
  LightUserAdmin,
  LightUserAssistant,
  LightUserBase,
  LightUserDoctor,
  User,
  UserBase,
} from '../model/User'
import { SelectOption } from '../model/SelectOption'
import { Roles } from '../model/Roles'
import { isAdminRole, isExtDsmRole } from './roles.utilities'

export const getFullName = (user: LightUserBase, includeTitle?: boolean) => {
  const fullName = `${user.firstName} ${user.familyName}`
  if (includeTitle && user.userType.professionTitle) {
    return `${user.userType.professionTitle} ${fullName}`
  } else {
    return fullName
  }
}

export const userToSelectOptions = (user: LightUser): SelectOption<LightUser> => ({
  value: user,
  label: getFullName(user),
})

export const isAssistant = (user: LightUser | undefined | null): user is LightUserAssistant =>
  user?.mainRole === Roles.ASSISTANT

export const isDoctor = (user: LightUser | undefined | null): user is LightUserDoctor =>
  user?.mainRole === Roles.DOCTOR

export const isAdmin = (user: LightUser | undefined | null): user is LightUserAdmin =>
  !!user && isAdminRole(user.mainRole)

export const isExtDsm = (user: LightUser | undefined | null): user is LightUserAdmin =>
  !!user && isExtDsmRole(user.mainRole)

export const isSuperAdmin = (user: LightUser | undefined | null): user is LightUserAdmin =>
  !!user && user.mainRole === Roles.SUPER_ADMIN

/* prettier-ignore */
export const retrieveBaseUser = ({
                                   email, firstName, familyName, id, internalId, userType, roles, mainRole, trackingId,
                                   locked, username, phone, subPhone, sex, enabled,
                                   queryToken, createdAt, updatedAt, deletedAt, subscriberToken
                                 }: User): UserBase & LightUserBase => ({
  email, firstName, familyName, id, internalId, userType, roles, mainRole, trackingId,
  locked, username, phone, subPhone, sex, enabled,
  queryToken, createdAt, updatedAt, deletedAt, subscriberToken
})
