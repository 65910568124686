import { externalServicesActions } from './externalServices.actions'
import { ExternalServicesActionTypes, ExternalServicesState } from './externalServices.model'
import { createReducer } from 'redux-starter-kit'

const EXTERNAL_SERVICES_INITIAL_STATE: ExternalServicesState = {
  status: [],
}

const ACTION_HANDLERS = {
  [ExternalServicesActionTypes.SET_STATUS]: (
    state: ExternalServicesState = EXTERNAL_SERVICES_INITIAL_STATE,
    { status }: ReturnType<typeof externalServicesActions.setStatus>,
  ): ExternalServicesState => ({
    ...state,
    status,
  }),
}

export const externalServicesReducer = createReducer<ExternalServicesState, any>(
  EXTERNAL_SERVICES_INITIAL_STATE,
  ACTION_HANDLERS,
)
