import React, { FunctionComponent } from 'react'
import styles from './CardMenuItem.module.scss'
import { CardMenuItemProps } from './CardMenuItem.model'
import { Icon } from '../../../Icon'
import { Colors } from '../../../../../colors'
import { ColoredIcon } from '../../../Icon/ColoredIcon.component'
import RoleRestriction from '../../../../../containers/RoleRestriction'
import { Link } from 'react-router-dom'

export const CardMenuItem: FunctionComponent<CardMenuItemProps> = ({
  label,
  icon,
  coloredIcon,
  onClick,
  link,
  roles = [],
}) => {
  const content = (
    <div className={styles.container} onClick={onClick}>
      <div className={styles.triangle}>
        <Icon icon="arrow" rotate={90} size="nano" color={Colors.dsWhite} />
      </div>
      <div className={styles.icon}>
        {icon && <Icon icon={icon} size="large" />}
        {coloredIcon && <ColoredIcon icon={coloredIcon} />}
      </div>
      <div className={styles.labelContainer}>
        <div className={styles.label}>{label}</div>
      </div>
    </div>
  )

  return (
    <RoleRestriction roles={roles} useUsurpedUser>
      {link ? <Link to={link}>{content}</Link> : <>{content}</>}
    </RoleRestriction>
  )
}
