import { RootState } from '../../../store/reducers/reducers.model'
import {
  ActivePrinciplesSelectDispatchProps,
  ActivePrinciplesSelectStoreProps,
} from './ActivePrinciplesSelect.model'
import { connect } from 'react-redux'
import { ActivePrinciplesSelect } from './ActivePrinciplesSelect.component'
import { activePrinciplesListSelector } from '../../../store/domain/activePrinciples/activePrinciples.selectors'
import { activePrinciplesDomainActions } from '../../../store/domain/activePrinciples'

const mapStoreToProps = (state: RootState): ActivePrinciplesSelectStoreProps => ({
  activePrinciplesList: activePrinciplesListSelector(state),
})

const mapDispatchToProps: ActivePrinciplesSelectDispatchProps = {
  searchActivePrinciples: activePrinciplesDomainActions.search,
  setActivePrinciplesList: activePrinciplesDomainActions.setActivePrinciplesList,
}

const withConnect = connect(mapStoreToProps, mapDispatchToProps)(ActivePrinciplesSelect)
export { withConnect as ActivePrinciplesSelect }
