import { FC, Suspense, lazy } from 'react'
import { FullLoader } from '../../components/shared'

const LazyManagerRouter = lazy(() =>
  import(/* webpackPrefetch: true, webpackChunkName: "manager" */ './ManagerRouter.component').then(
    ({ ManagerRouter }) => ({ default: ManagerRouter }),
  ),
)

const LazyLoadedManagerRouter: FC = (props) => (
  <Suspense fallback={<FullLoader />}>
    <LazyManagerRouter {...props} />
  </Suspense>
)
export { LazyLoadedManagerRouter as ManagerRouter }
