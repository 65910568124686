import { AnyAction, Reducer } from 'redux'
import { createReducer } from 'redux-starter-kit'
import { singleItemPageLimit } from '../../../constants'
import { uiActionsFactory } from './restuxUiActions.factory'
import {
  RestuxUiSagaConf,
  RestuxPagination,
  RestuxInUse,
  RestuxLoadingState,
} from './restuxUi.model'
import { notMatchingIdentifier } from '../restux.utilities'

export const paginationReducerFactory = (conf: RestuxUiSagaConf): Reducer<RestuxPagination> => {
  const DEFAULT_PAGINATION: RestuxPagination = {
    itemIds: [],
    currentPage: 1,
    totalItems: 0,
    pageSize: singleItemPageLimit,
    loadingState: RestuxLoadingState.IDLE,
  }
  const { identifier: id } = conf
  const { types: UiActionTypes, actions: uiResourceActions } = uiActionsFactory(conf)

  const PAGINATION_ACTION_HANDLERS = {
    [UiActionTypes.UI_PAGINATION_CLEAR]: (state: RestuxPagination = DEFAULT_PAGINATION) =>
      DEFAULT_PAGINATION,

    [UiActionTypes.UI_REQUEST_PAGE]: (
      state: RestuxPagination = DEFAULT_PAGINATION,
      { filters, params }: ReturnType<typeof uiResourceActions.requestPage>,
    ) => ({ ...state, filters, params }),

    [UiActionTypes.UI_SET_PAGINATION_LOADING]: (
      state: RestuxPagination = DEFAULT_PAGINATION,
      {
        loadingState,
        identifier: actionId,
      }: ReturnType<typeof uiResourceActions.setPaginationLoading>,
    ) => (notMatchingIdentifier(id, actionId) ? state : { ...state, loadingState }),

    [UiActionTypes.UI_PAGINATION_UPDATE]: (
      state: RestuxPagination = DEFAULT_PAGINATION,
      {
        paginationPatch,
        identifier: actionId,
      }: ReturnType<typeof uiResourceActions.updatePagination>,
    ) => (notMatchingIdentifier(id, actionId) ? state : { ...state, ...paginationPatch }),
  }

  return createReducer<RestuxPagination>(DEFAULT_PAGINATION, PAGINATION_ACTION_HANDLERS)
}

export const inUseReducerFactory = (conf: RestuxUiSagaConf): Reducer<RestuxInUse> => {
  const DEFAULT_IN_USE: RestuxInUse = {
    inUseId: null,
    loadingState: RestuxLoadingState.IDLE,
  }
  const { types: UiActionTypes, actions: uiResourceActions } = uiActionsFactory(conf)

  return (state: RestuxInUse = DEFAULT_IN_USE, action: AnyAction): RestuxInUse => {
    switch (action.type) {
      case UiActionTypes.UI_SET_IN_USE_LOADING:
        const { loadingState } = action as ReturnType<typeof uiResourceActions.setInUseLoading>
        if (notMatchingIdentifier(conf.identifier, action.identifier)) {
          return state
        }
        return { ...state, loadingState }
      case UiActionTypes.UI_USE_ID:
        const { inUseId } = action as ReturnType<typeof uiResourceActions.useId>
        if (notMatchingIdentifier(conf.identifier, action.identifier)) {
          return state
        }
        return { ...state, inUseId }

      default:
        return state
    }
  }
}
