import { PaginatedList } from '../../../../model/Pagination'
import { RestuxApiConfig } from '../../../restux/cache/restuxCache.model'
import getApi from '../../../../api/apisauceConfig'

import { Contact } from '../../../../model/Contact'
import {
  deserializeContactResponse,
  deserializeContactPageResponse,
  serializeContact,
} from './Contacts.mapper'

export const restuxContactApiConfig: RestuxApiConfig<Contact, Contact> = {
  url: '/contacts',
  getPaginatedItems: (route) =>
    getApi().get<PaginatedList<Contact>>(route).then(deserializeContactPageResponse),
  getItem: (route) => getApi().get<Contact>(route).then(deserializeContactResponse),
  createItem: (route, item) => getApi().post<Contact>(route, serializeContact(item)),
  deleteItem: (route) => getApi().delete(route),
  updateItem: (route, item) => getApi().put<Contact>(route, serializeContact(item)),
}
