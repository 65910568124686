import { TrackingService, FwTrackingEvent } from './tracking.service'

/**
 *
 * Decorator
 * ```
 * class A {
 * @withTrackingEvent('EVENT:NAME', {optionX : 'x'})
 *    methodA() {
 *    }
 * }
 * ```
 */
export const trackingEvent = (event: FwTrackingEvent, option: object = {}) => (
  target: any,
  propertyKey: string,
  descriptor: PropertyDescriptor,
) => {
  const method = descriptor.value
  return {
    ...descriptor,
    value: (...args) => {
      /* *** ANALYTICS *** */
      TrackingService.sendEvent(event, option)

      return method.apply(target, args)
    },
  }
}
