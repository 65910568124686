import { getAgeWithUnit, getDisplayedBirthnameAndUsedName } from '@follow/cdk'
import { useEffect, useContext, useMemo } from 'react'
import { useTitle } from 'react-use'
import { ConnectedUserContext } from '../../misc/auth.utilities'
import { isDefined } from '../../misc/functions.utilities'
import { getFullName } from '../../misc/user.utilities'
import { useCurrentPatient } from './useCurrentPatient.hooks'

export function useTitleBrowserTabs() {
  const { currentPatient: patient } = useCurrentPatient()
  const { currentUser } = useContext(ConnectedUserContext)

  const title = useMemo(() => {
    if (isDefined(patient)) {
      return `${getDisplayedBirthnameAndUsedName(patient)} - ${getAgeWithUnit(patient.birthDate)}`
    } else if (currentUser) {
      return `${getFullName(currentUser)} - Follow`
    }

    return 'Follow - App'
  }, [patient, currentUser])

  useEffect(
    () => () => {
      if (currentUser) {
        document.title = `${getFullName(currentUser)} - Follow`
      } else {
        document.title = 'Follow - App'
      }
    },
    [currentUser],
  )

  useTitle(title)
}
