import { NavigateFunction } from 'react-router-dom'

export const basename = document.baseURI.substring(window.location.origin.length)

// https://fwhealth.atlassian.net/browse/DEV-2172
interface CustomHistory {
  navigate: NavigateFunction
}

export let customHistory: CustomHistory = {
  navigate: () => null,
}
