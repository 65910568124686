import { FunctionComponent, useCallback } from 'react'
import { RoundedButton } from '../../../shared'
import { PatientInformationsActionButtonsProps } from './PatientInformationsActionsButtons.model'
import styles from './PatientInformationsActionButtons.module.scss'

export const PatientInformationsActionButtons: FunctionComponent<
  PatientInformationsActionButtonsProps
> = ({
  groupId,
  search,
  showNewPatientInformationsForm,
  hasResults,
  isEditingPatientInformations,
  hasPendingData,
  onBackButtonClick,
  onValidPatientInformations,
  onShowAddPatientInformationsForm,
}) => {
  const handleShowAddPatientInformationsForm = useCallback(
    () => onShowAddPatientInformationsForm(undefined, search),
    [onShowAddPatientInformationsForm, search],
  )

  return (hasResults && groupId) || showNewPatientInformationsForm ? (
    <div className={styles.patientInformationsButtonsContainer}>
      <RoundedButton
        theme="light"
        label={isEditingPatientInformations ? 'Annuler' : 'Retour'}
        size="micro"
        onClick={onBackButtonClick}
      />
      {showNewPatientInformationsForm && (
        <RoundedButton
          theme="primary"
          label="Enregistrer"
          size="micro"
          onClick={onValidPatientInformations}
          testId="allergy-modal-save-button"
          disabled={hasPendingData}
        />
      )}
    </div>
  ) : (
    <RoundedButton
      label="Ajouter manuellement"
      size="micro"
      onClick={handleShowAddPatientInformationsForm}
    />
  )
}
