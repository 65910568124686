import { ApiResponse } from 'apisauce'
import { sanitizeToBasicHtml } from '../../misc/sanitizer.utilities'
import { LegalDocument } from '../../model/LegalDocument'

export const sanitizeLegalDocumentResponse = ({
  data,
  ...response
}: ApiResponse<LegalDocument>): ApiResponse<LegalDocument> => {
  if (response.ok && data) {
    return {
      ...response,
      data: { ...data, content: sanitizeToBasicHtml(data.content) },
    }
  } else {
    return response
  }
}
