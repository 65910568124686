import { FC } from 'react'
import { NavBar } from '../../components/layout'
import { Card, Heading } from '../../components/shared'
import {
  InaccessiblePage,
  LargeScreenAccessiblePageOnlyProps,
} from './LargeScreenAccessiblePageOnly.model'
import styles from './LargeScreenAccessiblePageOnly.module.scss'

const getTitle = (pageType: InaccessiblePage) => {
  switch (pageType) {
    case 'Manager':
      return 'Le manager est dédié à une utilisation sur ordinateur'
    case 'Navigation Transverse':
      return 'La navigation transverse est dédiée à une utilisation sur ordinateur'
    default:
      return 'Cette page est dédiée à une utilisation sur ordinateur'
  }
}

export const LargeScreenAccessiblePageOnly: FC<LargeScreenAccessiblePageOnlyProps> = ({ page }) => {
  const title = getTitle(page)
  return (
    <>
      <NavBar>{page}</NavBar>
      <Card>
        <div className={styles.inaccessibleSectionContainer}>
          <Heading size={3} theme="dark">
            {title}
          </Heading>
        </div>
      </Card>
    </>
  )
}
