import { NavBar } from '../../MainLayout/NavBar'
import { PatientAlertsBanner, PatientMergeBanner } from '../../../patient'
import { PatientPageHeader } from './PatientPageHeader'
import { useCurrentPatient } from '../../../../hooks/utils'

export const PatientPageNavBar = () => {
  const { currentPatient: patient } = useCurrentPatient()

  if (!patient) {
    return <NavBar backButtonPath={null}>Chargement...</NavBar>
  }

  return (
    <NavBar
      backButtonPath={null}
      backButtonTitle="Retour vers la liste des patients"
      stickyHeaderContent={
        <>
          <PatientMergeBanner patient={patient} />
          <PatientAlertsBanner />
        </>
      }
    >
      <PatientPageHeader patient={patient} />
    </NavBar>
  )
}
