import uuidv4 from 'uuid/v4'
import { MessagesTypes } from '../../model/Notification'
import { Message, MessageActionTypes } from './message.model'
import { AnyAction } from 'redux'

const EMPTY_ACTION: AnyAction = { type: '' }

export type MessageOptions = SafeOmit<Message, 'message' | 'title' | 'type'>

export const clearError = (id) => ({ type: MessageActionTypes.CLEAR_MESSAGE, id })

export const addError = (title: string, message?: string, options?: MessageOptions) => ({
  type: MessageActionTypes.ADD_MESSAGE,
  message: buildMessage(MessagesTypes.Error, title, message, options),
})

export const addWarning = (title: string, message?: string, options?: MessageOptions) => ({
  type: MessageActionTypes.ADD_MESSAGE,
  message: buildMessage(MessagesTypes.Warn, title, message, options),
})

export const addInfo = (title: string, message?: string, options?: MessageOptions) => ({
  type: MessageActionTypes.ADD_MESSAGE,
  message: buildMessage(MessagesTypes.Info, title, message, options),
})

export const addValid = (title: string, message?: string, options?: MessageOptions) => ({
  type: MessageActionTypes.ADD_MESSAGE,
  message: buildMessage(MessagesTypes.Valid, title, message, options),
})

export const addMessage = (message: Message) => ({
  type: MessageActionTypes.ADD_MESSAGE,
  message,
})

export const addResponseError = (response: any): AnyAction => {
  // gestion précise des erreurs
  if (response.data && response.data.errors && response.data.errors.code) {
    const key = parseInt(Object.keys(response.data.errors.code)[0], 10)

    switch (key) {
      case 6304:
        return addError(
          `Le token est invalide`,
          `Veuillez faire une nouvelle demande de changement de mot de passe.`,
        )
    }
  }

  // à défaut, gestion plus générique des erreurs classiques (400 et co.)
  const code = response.status
  switch (code) {
    case 401:
    case 403:
      return addError(
        `Accès non autorisé`,
        `L'accès à une information demandée n'a pas été autorisé.`,
        { id: 'unauthorized-access' },
      )
    case 400:
      if (response.config.method !== 'get') {
        return addError(
          `Contenu incorrect`,
          `Le contenu à créer est incorrect. Echec de la création.`,
        )
      } else {
        return EMPTY_ACTION
      }
    case 404:
      return addError(`Contenu introuvable`, `Impossible de trouver le contenu demandé.`)
    case 413:
      return addError(
        `Impossible d'uploader le contenu'`,
        `Le fichier que vous souhaitez uploader dépasse la limite autorisée`,
      )
    case 500:
      return addError(
        `Une erreur inattendue s'est produite`,
        `Elle a été remontée à l'équipe technique.`,
        { id: 'internal-server-error' },
      )
    case null:
      return addError(
        `Impossible de contacter le serveur`,
        `Merci de vérifier votre connexion internet, ou réessayer ultérieurement`,
        { id: 'unable-to-contact-server' },
      )
  }

  // si vraiment on a rien :
  return addError(
    `Une erreur inconnue s'est produite`,
    `Merci de réessayer ultérieurement, et contacter notre support si le problème survient à nouveau.`,
    { id: 'unknown-error' },
  )
}

const buildMessage = (
  type: string,
  title: string,
  message?: string,
  options: MessageOptions = {
    id: uuidv4(),
  },
): Message => {
  return { type, title, message, ...options }
}
