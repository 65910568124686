import { JSONToValue, SimpleValueToHtml } from '@follow/farte'
import { AnswerParts } from '../model/AnswerParts'
import React, { FunctionComponent } from 'react'
import { DangerousHtml } from './DangerousHtml.component'

interface AnswerRendererProps {
  answerParts: AnswerParts
}

export const AnswerRenderer: FunctionComponent<AnswerRendererProps> = ({ answerParts }) => {
  return (
    <>
      {answerParts.map((answerPart, index) => {
        const isLastPart = index === answerParts.length - 1
        return (
          <span key={index}>
            {answerPart.type === 'normal' && <DangerousHtml html={answerPart.value} />}
            {answerPart.type === 'farte' && (
              <SimpleValueToHtml value={JSONToValue(answerPart.value)} mode="inline" />
            )}
            {!isLastPart && ' '}
          </span>
        )
      })}
    </>
  )
}
AnswerRenderer.displayName = 'AnswerRenderer'
