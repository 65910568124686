import { CancelToken } from 'apisauce'
import React, { useCallback, useEffect, useState } from 'react'
import { RoundedButton } from '../../../../../components/shared'
import { DocumentHistory } from '../../../../../model/DocumentHistory'
import { getDocumentTemplateHistory } from '../../../../../store/cache/documentHistory'

import { SearchDocumentHistoryItem, SearchDocumentHistoryPanel } from '../../atoms'

import { SearchDocumentHistoryProps } from '../SearchDocumentHistory.model'

export function SearchDocumentTemplateHistory({ id, hash, restore }: SearchDocumentHistoryProps) {
  const [items, setItems] = useState<DocumentHistory[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const token = CancelToken.source()
    // Loading
    setLoading(true)
    // Récupéraion de la liste
    getDocumentTemplateHistory(id, token)
      .then(setItems)
      .finally(() => setLoading(false))
    // Annulation de la requête lors du clean de l'effet
    return () => {
      token.cancel()
    }
  }, [id])

  const renderItemAction = useCallback(
    (item: DocumentHistory) => {
      if (item.hash === hash) {
        return <span>Version actuelle</span>
      } else {
        return <RoundedButton onClick={() => restore(item)} label="Restaurer" theme="light" />
      }
    },
    [hash, restore],
  )

  return (
    <SearchDocumentHistoryPanel<DocumentHistory>
      loading={loading}
      items={items}
      renderItem={(item) => (
        <SearchDocumentHistoryItem key={item.uuid} item={item} renderAction={renderItemAction} />
      )}
    />
  )
}
