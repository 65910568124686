import { AtcClass } from '../../../model/Drug'
import { RestuxResources } from '../../resources'
import { restuxCacheFactory } from '../../restux/cache/index'
import { restuxAtcClassificationApiConfig } from './api'

const resourceName = RestuxResources.atcClassification

const { actions, reducers, sagas } = restuxCacheFactory<AtcClass, AtcClass>({
  apiConfig: restuxAtcClassificationApiConfig,
  refetchList: false,
  resourceName,
})

export const atcClassificationActions = actions
export const atcClassificationReducers = reducers
export const atcClassificationSagas = sagas
