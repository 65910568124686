import { ResourceHistoryEntity } from '../../../model/ResourceHistory'

const RESOURCE_PREFIX = `@BOTTOM_PANEL_RESOURCE_HISTORY`

export interface BottomPanelResourceHistoryState {
  inUseResource: ResourceHistoryEntity | null
}

export const UiBottomPanelResourceHistoryActionTypes = {
  OPEN: `${RESOURCE_PREFIX}/OPEN`,
}
