import { ComponentClass } from 'react'
import { compose } from 'recompose'

import { ListItemType } from '../../components/shared'
import SearchPanel from './SearchPanel.component'

import { SearchPanelOwnProps, SearchPanelProps } from './SearchPanel.model'

/**
 * Cette fonction "factory" et ce cast "hardcore" sert à faire comprendre à React que je lui passe un type paramétré "T"
 */
export default function createSearchPanel<T extends ListItemType>(): ComponentClass<
  SearchPanelOwnProps<T>
> {
  return (compose<SearchPanelProps<T>, SearchPanelOwnProps<T>>()(
    SearchPanel,
  ) as unknown) as ComponentClass<SearchPanelOwnProps<T>>
}
