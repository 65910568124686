import { CodesDomainActionsTypes } from './codes.model'
import { CodeCategory } from '../../../model/Code'

export const codesDomainActions = {
  fetch: () => ({
    type: CodesDomainActionsTypes.FETCH,
  }),
  fetchSuccess: (codeCategories: CodeCategory[]) => ({
    type: CodesDomainActionsTypes.FETCH_SUCCESS,
    codeCategories,
  }),
}
