import { FC, useCallback } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useCurrentPatient } from '../../../../../hooks/utils'
import { UrlSearchParams } from '../../../../../model/Navigation'
import { Icon, IconButton } from '../../../../shared'
import {
  EventDocHeaderProps,
  EventDocumentType,
} from './PatientTransversalNavigationEventDocHeader.model'
import styles from './PatientTransversalNavigationEventDocHeader.module.scss'

export const PatientTransversalNavigationEventDocHeader: FC<EventDocHeaderProps> = ({
  id,
  title,
  icon,
  type,
  isEventEditable,
  onPrint,
}: EventDocHeaderProps) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [params] = useSearchParams()
  const { currentPatient } = useCurrentPatient()
  const eventId = params.get(UrlSearchParams.eventId)

  const handleFileClick = useCallback(() => {
    const params = type === EventDocumentType.Document ? `docId=${id}` : `fileId=${id}`
    currentPatient &&
      navigate(`/patients/${currentPatient.id}/medicalEvent/${eventId}?${params}`, {
        state: { from: pathname },
      })
  }, [currentPatient, eventId, id, navigate, pathname, type])

  return (
    <div>
      <div className={styles.fileHeader}>
        <div className={styles.fileNameWithTypeIcon}>
          <div className={styles.fileIcon}>
            <Icon icon={icon} size="nano" />
          </div>
          <h5 className={styles.fileName}>{title}</h5>
        </div>
        <div className={styles.iconsContainer}>
          <IconButton
            icon={isEventEditable ? 'editFile' : 'eye'}
            theme="transparent-dark"
            onClick={handleFileClick}
            title={isEventEditable ? 'Éditer le fichier' : 'Consulter le fichier'}
            size="micro"
          />
          {onPrint && (
            <IconButton
              icon="print"
              theme="primary"
              onClick={onPrint}
              title="Imprimer le fichier"
              size="micro"
            />
          )}
        </div>
      </div>
    </div>
  )
}
