enum CopyContentType {
  TEXT_PLAIN = 'textarea',
  TEXT_HTML = 'div',
}

type CopyContainerElement = HTMLDivElement | HTMLTextAreaElement

function createCopyElement(type: CopyContentType): CopyContainerElement {
  const element = document.createElement(type)
  // Prevent zooming on iOS
  element.style.fontSize = '12pt'
  // Reset box model
  element.style.border = '0'
  element.style.padding = '0'
  element.style.margin = '0'
  // Move element out of screen horizontally
  element.style.position = 'absolute'
  element.style.left = '-9999px'
  // Move element to the same position vertically
  const yPosition = window.pageYOffset || document.documentElement.scrollTop
  element.style.top = `${yPosition}px`
  // Append copy element to document body
  document.body.appendChild(element)
  return element
}

function copyElementContent(container: CopyContainerElement) {
  try {
    document.execCommand('copy')
  } catch (error) {
    throw error
  } finally {
    const selection = window.getSelection()
    if (selection) {
      selection.removeAllRanges()
    }
    document.body.removeChild(container)
  }
}

export function copyTextPlain(text: string) {
  const element = createCopyElement(CopyContentType.TEXT_PLAIN) as HTMLTextAreaElement

  element.setAttribute('readonly', '')
  element.value = text

  element.select()
  element.setSelectionRange(0, element.value.length)

  copyElementContent(element)
}

export function copyTextHtml(text: string) {
  const element = createCopyElement(CopyContentType.TEXT_HTML)
  element.innerHTML = text
  const range = document.createRange()
  range.selectNodeContents(element)
  const selection = window.getSelection()
  if (selection) {
    selection.removeAllRanges()
    selection.addRange(range)
  }
  copyElementContent(element)
}
