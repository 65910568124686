import { ReactNode, RefObject, Ref, Component } from 'react'
import { PermissionFields } from '../../../model/Resource'
import { TestProps } from '../../../misc/types.utilities'

export abstract class AbstractList<T extends ListItemType> extends Component<
  ListProps<T>,
  ListState<T>
> {
  public updateList(reload: boolean) {
    throw new Error('Method updateList() must be implemented')
  }
}

export interface ListItemType extends PermissionFields {
  id: number | string
}
export interface ListProps<T extends ListItemType> extends TestProps {
  items: ReadonlyArray<T>
  loading?: boolean
  renderEmptyPlaceHolder?: ReactNode
  renderActions?: () => ReactNode
  renderCustomActionButtons?: () => ReactNode
  renderExtendedActions?: () => ReactNode
  renderItem: (item: T) => ReactNode
  renderCustomItemActions?: (item: T) => ReactNode
  renderHeaderActions?: () => ReactNode
  renderListFooter?: ReactNode
  onMultiSelectionChange?: (selection: ReadonlyArray<T>) => void
  onPrimaryAction?: (selection: T) => void
  onEditItem?: (selection: T) => void
  onDeleteItem?: (selection: T) => void
  onUpdateList: (page: number) => void
  onAddItem?: () => void
  onAddSelectedItems?: () => void
  toggleExtendedActions?: (value: boolean) => void
  extendedActionsOpen?: boolean
  highlightedId?: number | string
  pageCount: number | null
  itemCount?: number | null
  addItemLabel?: string
  ref?: RefObject<AbstractList<T>> | Ref<AbstractList<T>>
  selectedCount?: number
  intel?: string
  noRef?: boolean // Permet d'utiliser la liste sans l'usage de ref
  currentPage?: number
  fullPageMode?: boolean
}

export interface ListState<T extends ListItemType> {
  selected: ReadonlyArray<T>
  currentPage: number
}
