import { connect } from 'react-redux'
import { RootState } from '../../../store'
import {
  insiDomainActions,
  insiMailPatientSelector,
  insiRequestSelector,
} from '../../../store/domain/insi'
import { domainMailActions } from '../../../store/domain/mail'
import { InsiConsultIframe } from './InsiConsultIframe.component'
import {
  InsiConsultIframeDispatchProps,
  InsiConsultIframeStateProps,
} from './InsiConsultIframe.model'
import { addError, addInfo } from '../../../store/message'

const mapStateToProps = (state: RootState): InsiConsultIframeStateProps => ({
  request: insiRequestSelector(state),
  mailPatient: insiMailPatientSelector(state),
})

const mapDispatchToProps: InsiConsultIframeDispatchProps = {
  setRequest: insiDomainActions.setRequest,
  setPatientResponse: insiDomainActions.setPatientResponse,
  createPatient: domainMailActions.createPatientFromMail,
  updatePatient: domainMailActions.updatePatientFromMail,
  logInsi: insiDomainActions.logInsi,
  addInfo,
  addError,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)(InsiConsultIframe)
export { withConnect as InsiConsultIframe }
