import { bottomPanelResourceHistoryActions } from './bottomPanelResourceHistory.actions'
import {
  BottomPanelResourceHistoryState,
  UiBottomPanelResourceHistoryActionTypes,
} from './bottomPanelResourceHistory.model'
import { createReducer } from 'redux-starter-kit'

const INITIAL_STATE: BottomPanelResourceHistoryState = {
  inUseResource: null,
}

const ACTION_HANDLERS = {
  [UiBottomPanelResourceHistoryActionTypes.OPEN]: (
    state = INITIAL_STATE,
    { inUseResource }: ReturnType<typeof bottomPanelResourceHistoryActions.open>,
  ) => ({
    ...state,
    inUseResource,
  }),
}

export const bottomPanelResourceHistoryReducer = createReducer(INITIAL_STATE, ACTION_HANDLERS)
