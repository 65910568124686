import { DocumentInstance } from '../../../model/DocumentInstance'
import { CustomFile } from '../../../model/File'
import { MedicalEventDocumentType } from '../../../model/MedicalEvent'
import { RootState } from '../../reducers/index'
import { RestuxResources } from '../../resources'
import { toUpperSnakeCase } from '../../restux/restux.utilities'
import { RestuxInUse, RestuxPagination, RestuxUiSagaConf } from '../../restux/ui/index'
import { MedicalEventContentState } from './medicalEventContent/index'
import { MedicalEventDocumentInstancesState } from './medicalEventDocumentInstances/index'
import { MedicalEventFilesState } from './medicalEventFiles/index'

export const medicalEventConfig: RestuxUiSagaConf = {
  resourceName: RestuxResources.medicalEvent,
  paginationSelector: (state: RootState) => state.ui.medicalEvent.pagination,
}

const RESOURCE_PREFIX = `@${toUpperSnakeCase(RestuxResources.medicalEvent)}_UI`

export const MedicalEventsUiActionTypes = {
  FILTER_DOCUMENT: `${RESOURCE_PREFIX}/FILTER_DOCUMENT`,
  SELECT_DOCUMENT: `${RESOURCE_PREFIX}/SELECT_DOCUMENT`,
  ASK_TO_CLEAR_FILTERS: `${RESOURCE_PREFIX}/ASK_TO_CLEAR_FILTERS`,
  CLEAR_FILTERS: `${RESOURCE_PREFIX}/CLEAR_FILTERS`,
  DISPLAY_ADD_FILE_DROPZONE: `${RESOURCE_PREFIX}/DISPLAY_ADD_FILE_DROPZONE`,
  HIDE_ADD_FILE_DROPZONE: `${RESOURCE_PREFIX}/HIDE_ADD_FILE_DROPZONE`,
  CLEAR: `${RESOURCE_PREFIX}/CLEAR`,
  SEND_DOCUMENTS: `${RESOURCE_PREFIX}/SEND_DOCUMENTS`,
  SELECT_DOCUMENTS: `${RESOURCE_PREFIX}/SELECT_DOCUMENTS`,
  DISPLAY_SEND_FILE_MODAL: `${RESOURCE_PREFIX}/DISPLAY_SEND_FILE_MODAL`,
}

export interface FwDocumentFilter {
  medicalEventDocumentType: MedicalEventDocumentType.FW_DOCUMENT
  id: number
  // Indique s'il faut reset le questionnaire selectionné lors du choix du document.
  //  A la fois dans le Store et dans l'url
  resetSelectedQuestionnaire?: boolean
}

export interface FwDocumentFileFilter {
  medicalEventDocumentType: MedicalEventDocumentType.FILE
  id: number
}

export interface FwDocumentNotesFilter {
  medicalEventDocumentType: MedicalEventDocumentType.OBSERVATIONS
}

export interface FwDocumentAddingFilter {
  medicalEventDocumentType: MedicalEventDocumentType.ADDING
}

export type MedicalEventFilter =
  | FwDocumentFilter
  | FwDocumentFileFilter
  | FwDocumentNotesFilter
  | FwDocumentAddingFilter

export interface FwDocumentInstanceWrapper {
  type: MedicalEventDocumentType.FW_DOCUMENT
  item: DocumentInstance
}

export interface FwFileWrapper {
  type: MedicalEventDocumentType.FILE
  item: CustomFile
}

export interface FwNotesWrapper {
  type: MedicalEventDocumentType.OBSERVATIONS
}

export type SelectedMedicalEventDocumentWrapper =
  | FwDocumentInstanceWrapper
  | FwFileWrapper
  | FwNotesWrapper

export interface QuestionnaireFilter {
  id: number
}
export interface MedicalEventFilterState {
  documentInstance: MedicalEventFilter | null
  questionnaire: QuestionnaireFilter | null
}

export interface MedicalEventAddFileState {
  displayDropzone: boolean
}
export interface MedicalEventSendFileState {
  displaySendFileModal: boolean
}

export interface MedicalEventState {
  inUse: RestuxInUse
  pagination: RestuxPagination
  filters: MedicalEventFilterState
  addFile: MedicalEventAddFileState
  documentInstances: MedicalEventDocumentInstancesState
  files: MedicalEventFilesState
  medicalEventContent: MedicalEventContentState
  sendFile: MedicalEventSendFileState
}
