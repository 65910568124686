import { isPast } from 'date-fns'
import { FollowTypes, Module } from '../model'
import { Amenorrhea } from '../model/types/ModuleData.types'
import { isDefined } from './functions.utils'

export const getLatestHealthDatas = (list: Array<FollowTypes.ModuleData.HealthData>) => {
  return list.reduce<Array<FollowTypes.ModuleData.HealthData>>((acc, item) => {
    const foundIndex = acc.findIndex(
      ({ moduleDataType }) => moduleDataType.id === item.moduleDataType.id,
    )
    const found = acc[foundIndex]
    if (found) {
      if (new Date(item.createdAt).getTime() > new Date(found.createdAt).getTime()) {
        acc.splice(foundIndex, 1)
        acc.push(item)
      }
      return acc
    } else {
      return [...acc, item]
    }
  }, [])
}

export const findOngoingPregnancy = (healthData: FollowTypes.ModuleData.HealthData[]) => {
  const pregnancy = healthData.find(
    (listItem): listItem is FollowTypes.ModuleData.PregnancyData =>
      listItem.module === Module.PREGNANCY &&
      (!listItem?.value?.value?.pregnancyEnd || !isPast(listItem.value.value.pregnancyEnd)),
  )
  return pregnancy
}

export const deserializePatientHealthData = (
  item: FollowTypes.ModuleData.HealthData,
): FollowTypes.ModuleData.HealthData => {
  if (item.module === Module.PREGNANCY) {
    const {
      lastMenstrualPeriod,
      pregnancyStart,
      dueDate,
      pathologicalLeaveStart,
      maternityLeaveStart,
      maternityLeaveEnd,
      pregnancyEnd,
      amenorrhea,
      ...restValues
    } = item.value.value
    return {
      ...item,
      value: {
        value: {
          ...restValues,
          lastMenstrualPeriod: lastMenstrualPeriod ? new Date(lastMenstrualPeriod) : undefined,
          pregnancyStart: pregnancyStart ? new Date(pregnancyStart) : undefined,
          dueDate: dueDate ? new Date(dueDate) : undefined,
          pathologicalLeaveStart: pathologicalLeaveStart
            ? new Date(pathologicalLeaveStart)
            : undefined,
          maternityLeaveStart: maternityLeaveStart ? new Date(maternityLeaveStart) : undefined,
          maternityLeaveEnd: maternityLeaveEnd ? new Date(maternityLeaveEnd) : undefined,
          pregnancyEnd: pregnancyEnd ? new Date(pregnancyEnd) : undefined,
          amenorrhea: {
            weeks: amenorrhea?.weeks?.toString(),
            additionalDays: amenorrhea?.additionalDays?.toString(),
          },
        },
      },
    }
  } else {
    if (item.moduleDataType.type === 'DATETIME' && typeof item.value.value === 'string') {
      return {
        ...item,
        value: {
          value: new Date(item.value.value),
        },
      }
    }
  }
  return item
}

export const getAmenorrheaText = (amenorrhea: Amenorrhea) => {
  if (!isDefined(amenorrhea.additionalDays) && !isDefined(amenorrhea.weeks)) return null

  const hasAdditionalDays =
    isDefined(amenorrhea.additionalDays) && amenorrhea.additionalDays !== '0'
  const amenorrheaWeeks = `${amenorrhea.weeks ?? '0'} SA`
  const dayWord = `${amenorrhea.additionalDays === '1' ? 'jour' : 'jours'}`
  const amenorrheaDaysOnly = `+ ${amenorrhea.additionalDays} ${dayWord}`
  const amenorrheaWeeksAndDays = `${amenorrheaWeeks} ${amenorrheaDaysOnly}`
  return hasAdditionalDays ? amenorrheaWeeksAndDays : amenorrheaWeeks
}
