import { ComponentType, useEffect, useState } from 'react'
import { mediumTransitionDuration } from '../constants'

export interface UnmountTransitionProps {
  isUnmounting: boolean
}

export function withUnmountTransition<Props extends UnmountTransitionProps>(
  BaseComponent: ComponentType<Props>,
  transitionDuration = mediumTransitionDuration,
) {
  type DisplayProps = Props & { display: boolean }
  type FinalProps = SafeOmit<DisplayProps, 'isUnmounting'>

  return function (props: FinalProps) {
    const [propsMemory, setPropsMemory] = useState<DisplayProps>({
      ...props,
      isUnmounting: false,
    } as DisplayProps)

    useEffect(() => {
      // Passage de l'état ouvert à état fermé
      if (propsMemory.display && !props.display) {
        setPropsMemory((current) => ({ ...current, isUnmounting: true }))
        setTimeout(() => {
          setPropsMemory({ ...props, isUnmounting: false } as DisplayProps)
        }, transitionDuration)
      } else {
        setPropsMemory({ ...props, isUnmounting: false } as DisplayProps)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    return propsMemory.display ? <BaseComponent {...propsMemory} /> : null
  }
}
