import React from 'react'
import {
  CartesianGrid,
  LineChart,
  Line,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Legend,
  Tooltip,
} from 'recharts'
import { PatientQuestionnaireEvolutionChartProps } from './PatientQuestionnaireEvolutionChart.model'

import styles from './PatientQuestionnaireEvolutionChart.module.scss'

export const PatientQuestionnaireEvolutionChart = ({
  color,
  name,
  values,
  width = '100%',
  height = '100%',
}: PatientQuestionnaireEvolutionChartProps) => {
  const activeDotStyle = { stroke: color, strokeWidth: 2, r: 5, fill: '#FFFFFF' }
  return (
    <div className={styles.container}>
      <ResponsiveContainer width={width} height={height}>
        <LineChart data={values}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis dataKey="value" />
          <Tooltip />
          <Line
            type="monotone"
            dataKey="value"
            stroke={color}
            strokeWidth={2}
            activeDot={activeDotStyle}
            isAnimationActive={false}
          />
          <Legend
            align="center"
            payload={[
              {
                dataKey: 'value',
                type: 'line',
                color,
                value: name,
              },
            ]}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}
