import {
  DocumentAlert,
  DocumentAlertsFilters,
  DocumentAlertsSeverityLabel,
  DocumentAlertsTypes,
} from '../../../model/DocumentAlerts'
import { DocumentVidalInfos } from '../../../model/DocumentInstance'

export interface DocumentAlertsState {
  alerts: DocumentAlert[]
  availableFilters: DocumentAlertsFilters | null
  types: DocumentAlertsTypes | null
  vidalInfos: DocumentVidalInfos | null
  severityLabels: DocumentAlertsSeverityLabel[]
}

const RESOURCE_NAME = '@DOCUMENT_ALERTS'

export const DocumentAlertsDomainActionTypes = {
  GET: `${RESOURCE_NAME}/GET`,
  SET: `${RESOURCE_NAME}/SET`,
  CLEAR: `${RESOURCE_NAME}/CLEAR`,
  SET_FILTERS: `${RESOURCE_NAME}/SET_FILTERS`,
  SET_VIDAL_INFOS: `${RESOURCE_NAME}/SET_VIDAL_INFOS`,
  SET_SEVERITY_LABELS: `${RESOURCE_NAME}/SET_SEVERITY_LABELS`,
  GET_TYPES: `${RESOURCE_NAME}/GET_TYPES`,
  SET_TYPES: `${RESOURCE_NAME}/SET_TYPES`,
}
