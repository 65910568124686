import { FC, useCallback, useState } from 'react'
import { Posology, PosologyFormResource } from '../../../model/Posology'
import { PosologyForm } from '../../drug'
import { DocumentPosologyProps } from './DocumentPosology.model'

export const DocumentPosology: FC<DocumentPosologyProps> = ({
  prescription,
  activeIntervalId,
  disabled = false,
  setActiveIntervalId,
  batchPosology,
  updatePrescriptionAndCreateTemplate,
}) => {
  const [intervalForms, setIntervalForms] = useState<PosologyFormResource[]>([])

  const handlePosologyChange = useCallback(
    (posologies: Partial<Posology>[]) => {
      if (prescription) {
        batchPosology(
          {
            posologyIntervals: posologies,
          },
          prescription.uuid,
        )
      }
    },
    [batchPosology, prescription],
  )

  const handleUpdatePrescriptionAndCreateTemplate = useCallback(
    (posologies: Partial<Posology>[], name: string) => {
      if (prescription) {
        updatePrescriptionAndCreateTemplate(
          prescription.uuid,
          {
            posologyIntervals: posologies,
          },
          name,
        )
      }
    },
    [updatePrescriptionAndCreateTemplate, prescription],
  )

  return (
    <PosologyForm
      prescriptionId={prescription.uuid}
      drugs={prescription.drugs}
      intervals={prescription.posologyIntervals}
      activeIntervalId={activeIntervalId}
      intervalForms={intervalForms}
      setIntervalForms={setIntervalForms}
      setActiveIntervalId={setActiveIntervalId}
      disabled={disabled}
      onSubmit={handlePosologyChange}
      onSubmitAndCreateTemplate={handleUpdatePrescriptionAndCreateTemplate}
      availableAdministrationRoutes={prescription.availableAdministrationRoutes}
    />
  )
}
