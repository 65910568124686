import React, { FunctionComponent, useEffect } from 'react'

import { PatientQuestionnaireEvolutionChart } from './PatientQuestionnaireEvolutionChart'
import { Loader } from '../../../shared'

import styles from './PatientPageStatistics.module.scss'
import { TrackingService, FwTrackingEvent } from '../../../../misc/Tracking'
import { useCurrentPatient } from '../../../../hooks/utils'
import { useGetPatientStatistics } from '../../../../hooks/queries/patientStatistics'
import { PatientStatisticsPlaceholder } from '../../PatientStatisticsPlaceholder'

export const PatientPageStatistics: FunctionComponent = () => {
  const { currentPatient: patient, isLoadingCurrentPatient } = useCurrentPatient()

  const {
    query: { data: patientStatistics, isLoading },
  } = useGetPatientStatistics({
    patientId: patient!.id,
    enabled: !!patient?.id,
  })

  useEffect(() => {
    if (patientStatistics) TrackingService.sendEvent(FwTrackingEvent.STATISTICS_DISPLAY)
  }, [patientStatistics])

  if (isLoadingCurrentPatient || isLoading) {
    return <Loader />
  }

  return (
    <div className={styles.statistics}>
      {patientStatistics?.length ? (
        patientStatistics.map(({ id, name, values }) => (
          <PatientQuestionnaireEvolutionChart
            key={id}
            color="#3b73b4"
            name={name}
            values={values}
          />
        ))
      ) : (
        <PatientStatisticsPlaceholder />
      )}
    </div>
  )
}
