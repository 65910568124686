import { atom } from 'jotai'

export enum ScrollHighlightStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export const scrollHighlightStatusAtom = atom<ScrollHighlightStatus>(ScrollHighlightStatus.DISABLED)

export const scrolledItemIdAtom = atom<number | null>(null)

export const forceItemFocusAtom = atom<boolean>(false)
