import React, { FunctionComponent } from 'react'
import { MessagesTypes } from '../../../model/Notification'

import { Snackbar } from './Snackbar/Snackbar.component'

import { SnackbarWrapperProps } from './SnackbarWrapper.model'
import styles from './SnackbarWrapper.module.scss'

export const SnackbarWrapper: FunctionComponent<SnackbarWrapperProps> = (props) => (
  <div className={styles.container}>
    <>
      {props.messages.map((error) => {
        return (
          <Snackbar
            key={error.id}
            id={error.id}
            title={error.title}
            message={error.message}
            type={error.type as MessagesTypes}
            disableDuration={error.disableDuration}
            onClose={props.clearError}
          />
        )
      })}
    </>
  </div>
)
