import {
  paginationReducerFactory,
  inUseReducerFactory,
} from '../../restux/ui/restuxUiReducer.factory'
import { combineReducers } from 'redux'
import { contactsConfig } from './contacts.model'

const pagination = paginationReducerFactory(contactsConfig)

const inUse = inUseReducerFactory(contactsConfig)

export const contactsReducer = combineReducers({
  pagination,
  inUse,
})
