export interface Analytics {
  totalMedicalEvents: number
  totalPatients: number
}

export interface AnalyticsRequest {
  from: Date
  to: Date
}

export interface DatedAnalytics {
  from: Date
  to: Date
  name: string
  patients: number
  medicalEvents: number
}

export interface PastAnalytics extends DatedAnalytics {
  patients_past?: number
  medicalEvents_past?: number
}

export enum AnalyticsAccuracyKeys {
  PONCTUAL,
  DAILY,
  WEEKLY,
  MONTHLY,
}
