import { useEffect, DependencyList } from 'react'

// TODO #MAIN-1247
export function useTimeoutEffect(effect: () => void, delay: number, deps: DependencyList) {
  useEffect(() => {
    const handler = setTimeout(effect, delay)
    return () => clearTimeout(handler)
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [...deps, delay])
}
