import { useMemo } from 'react'
import { HealthData, HealthDataDictionnary } from '../../../model/HealthData'

export const useIndexedModuleValues = (healthData: HealthData[]) => {
  const indexedValues = useMemo(() => {
    const values = healthData.reduce((acc, value) => {
      const found = acc[value.moduleDataType.code]
      if (found) {
        if (new Date(found.createdAt).getTime() < new Date(value.createdAt).getTime()) {
          return {
            ...acc,
            [value.moduleDataType.code]: value,
          }
        }
        return acc
      } else {
        return {
          ...acc,
          [value.moduleDataType.code]: value,
        }
      }
    }, {} as HealthDataDictionnary)

    return values
  }, [healthData])

  return indexedValues
}
