import getApi from '../../api'
import { searchLimit } from '../../constants'
import { ServerSentNotification } from '../../model/Notification'
import { PaginatedList } from '../../model/Pagination'
import { serializeNotificationListFilters } from './mapper/notifications.mapper'
import { NotificationListFilters } from './mapper/notifications.model'

export const getUserNotifications = (
  page: number,
  filters: NotificationListFilters,
  signal?: AbortSignal,
) =>
  getApi().get<PaginatedList<ServerSentNotification>>(
    '/notifications',
    { ...serializeNotificationListFilters(filters), page, limit: searchLimit },
    { signal },
  )
