import { FC } from 'react'
import { ClickableLinkProps } from './ClickableLink.model'
import classNames from 'classnames'
import { Icon } from '../Icon'
import { getCssVariable } from '../../../design-system/utils'

export const ClickableLink: FC<ClickableLinkProps> = ({ label, icon, onClick }) => {
  return (
    <div
      className={classNames('flex items-center text-primary-default text-sm', {
        'hover:underline cursor-pointer': !!onClick,
      })}
      onClick={onClick}
    >
      {icon && (
        <div className="mr-1">
          <Icon icon={icon} color={getCssVariable('primary', 'default')} size="pico" />
        </div>
      )}
      <span>{label}</span>
    </div>
  )
}
