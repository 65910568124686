import { ComponentType, LazyExoticComponent, lazy } from 'react'

const DEFAULT_DELAY = 500
const DEFAULT_RETRY = 3

export function lazyWithRetry<T extends ComponentType<any>>(
  loader: () => Promise<T>,
  delay = DEFAULT_DELAY,
): LazyExoticComponent<T> {
  return lazy<T>(() => {
    function retry(retries = DEFAULT_RETRY): Promise<{ default: T }> {
      return new Promise((resolve, reject) => {
        const promise = loader()
        promise
          .then((module) => {
            resolve({ default: module })
          })
          .catch((error) => {
            setTimeout(() => {
              if (retries === 1) {
                return reject(error)
              }
              return retry(retries - 1).then(resolve, reject)
            }, delay)
          })
      })
    }
    return retry()
  })
}
