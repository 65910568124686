import React, { FunctionComponent, useCallback, useEffect } from 'react'
import classNames from 'classnames/bind'

import { Colors } from '../../../../colors'
import { Icon } from '../../Icon'
import { IconsType } from '../../Icon/Icon.library'

import styles from './Snackbar.module.scss'
import { SnackbarProps } from './Snackbar.model'
import { MessagesTypes } from '../../../../model/Notification'
import { IconButton } from '../../buttons'

const cx = classNames.bind(styles)

export const Snackbar: FunctionComponent<SnackbarProps> = ({
  id,
  title,
  message,
  type = 'error',
  onClose,
  duration = 5000,
  disableDuration = false,
}) => {
  const handleClose = useCallback(() => onClose(id), [id, onClose])
  const icon = getIcon(type)

  useEffect(() => {
    if (!disableDuration) {
      const handler = setTimeout(handleClose, duration)
      return () => {
        clearTimeout(handler)
      }
    }
  }, [id, duration, handleClose, disableDuration])

  return (
    <div className={styles.snackbar}>
      <div
        className={cx(styles.icon, {
          error: type === 'error',
          warn: type === 'warn',
          info: type === 'info',
          valid: type === 'valid',
        })}
      >
        {icon && <Icon icon={icon} color={Colors.contentLight1} size="nano" />}
      </div>
      <div className={styles.textContainer}>
        <div>{title}</div>
        {message && <div className={styles.message}>{message}</div>}
      </div>
      <IconButton icon="done" onClick={handleClose} size="micro" theme="transparent" />
    </div>
  )
}

const getIcon = (type): IconsType | undefined => {
  switch (type) {
    case MessagesTypes.Error:
      return 'cross'
    case MessagesTypes.Warn:
      return 'flash'
    case MessagesTypes.Info:
      return 'info'
    case MessagesTypes.Valid:
      return 'done'
    default:
      return undefined
  }
}
