import { Filters } from '../../../../model/Filters'

const all = ['actes']
const detail = (id: string) => [...all, id]
const lists = [...all, 'list']
const list = (filters?: Filters) => [...lists, filters]

export const actesKeys = {
  all,
  detail,
  lists,
  list,
}
