import { FC, useCallback, useMemo, useState } from 'react'
import {
  Heading,
  Icon,
  Modal,
  ModalActions,
  ModalContent,
  RoundedButton,
  Switch,
  ThinModalLayout,
} from '../../shared'
import { SamAlertModalProps } from './SamAlertModal.model'
import { removeAllHtmlTags, sanitizeToBasicHtml } from '../../../misc/sanitizer.utilities'
import styles from './SamAlertModal.module.scss'
import RoleRestriction from '../../../containers/RoleRestriction'
import { Roles } from '../../../model/Roles'

export const SamAlertModal: FC<SamAlertModalProps> = ({ alerts, isUnmounting, onClose }) => {
  const [disableAlerts, setDisableAlerts] = useState(false)

  const sanitizedAlerts = useMemo(
    () =>
      alerts.map(({ content, title }) => ({
        content: sanitizeToBasicHtml(content),
        title: removeAllHtmlTags(title),
      })),
    [alerts],
  )

  const handleValid = useCallback(() => {
    onClose(disableAlerts)
    setDisableAlerts(false)
  }, [disableAlerts, onClose])

  const handleClose = useCallback(() => {
    onClose(false)
    setDisableAlerts(false)
  }, [onClose])

  return (
    <Modal isUnmounting={isUnmounting} onClickOutside={handleClose}>
      <ThinModalLayout size="medium">
        <div className="max-h-full flex flex-col">
          <ModalContent>
            <Icon size="mega" icon="alert" />
            <Heading size={3}>Systèmes d'aide à la décision indexée par médicaments (SAM)</Heading>
          </ModalContent>
          <div className="max-h-full overflow-y-scroll px-4">
            {sanitizedAlerts.map(({ content, title }, index) => (
              <div key={index} className="flex flex-col border-t border-shades-6 py-4 px-2">
                <span
                  className="font-semibold text-base"
                  dangerouslySetInnerHTML={{ __html: title }}
                />
                <span
                  className={styles.underlineAchildren}
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              </div>
            ))}
          </div>
          <ModalActions>
            <div className="w-full mt-4 mb-2 font-semibold">
              <RoleRestriction roles={[Roles.DOCTOR]}>
                <Switch
                  name={`Ne plus afficher les alertes SAM de ce type (${alerts
                    .map(({ samTypeDisplayName }) => samTypeDisplayName)
                    .join(', ')})`}
                  checked={disableAlerts}
                  onChange={setDisableAlerts}
                />
              </RoleRestriction>
            </div>
            <RoundedButton theme="invalid" label="Fermer" onClick={handleValid} />
          </ModalActions>
        </div>
      </ThinModalLayout>
    </Modal>
  )
}
