import { LightUser } from './User'

export type Team = {
  id: string
  name: string
  memberUsers: Array<LightUser>
  contactUsers: Array<LightUser>
  sharingConfig: SharingConfig
}

export type SharingConfig = Array<SharingConfigIElement>

export type SharingConfigIElement = {
  resourceName: SharingConfigKeys
  accessLevel: SharingConfigAccessLevel
}

export enum SharingConfigKeys {
  patient = 'patient',
  medicalEvent = 'medicalEvent',
  contact = 'contact',
  documentTemplate = 'documentTemplate',
  questionnaire = 'questionnaire',
  tag = 'tag',
  documentLayout = 'documentLayout',
}

export enum SharingConfigAccessLevel {
  nothing = 'none',
  read = 'read',
  write = 'write',
  delete = 'delete',
}
