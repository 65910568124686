import { PosologyFormResource } from '../../../../model/Posology'
import { Drug, DrugAdministrationRoute } from '../../../../model/Drug'

export interface PosologyDetailProps {
  posologyForm: PosologyFormResource
  drugs: Drug[]
  disabled: boolean
  availableAdministrationRoutes: DrugAdministrationRoute[]
  setPosologyForm: (form: PosologyFormResource) => void
}

export interface PosologyBlockCommonProps {
  posologyForm: PosologyFormResource
  disabled: boolean
  setPosologyForm: (form: PosologyFormResource) => void
}

// Correspondances valeur - label

export const daytimeLabels = {
  morning: 'Matin',
  noon: 'Midi',
  snack: 'Goûter',
  evening: 'Soir',
  night: 'Nuit',
}

export const timeUnitLabels = {
  min: 'Minutes',
  hour: 'Heures',
  day: 'Jours',
  week: 'Semaines',
  month: 'Mois',
  year: 'Années',
}

export const timeUnitConfiguration = {
  min: {
    min: 5,
    max: 180,
    delta: 5,
  },
  hour: {
    min: 1,
    max: 72,
    delta: 1,
  },
  day: {
    min: 1,
    max: 90,
    delta: 1,
  },
  week: {
    min: 1,
    max: 12,
    delta: 1,
  },
  month: {
    min: 1,
    max: 12,
    delta: 1,
  },
}

const dayTimes = ['morning', 'noon', 'snack', 'evening', 'night']

const durationTimeUnits = ['day', 'week', 'month', 'year']
const frequencyTimeUnits = ['min', 'hour', 'day', 'week', 'month']

export { dayTimes, durationTimeUnits, frequencyTimeUnits }
