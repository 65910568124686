import { IconsType } from '../components/shared'
import { LabelsName } from '../design-system/colors'
import { DocumentAlertSeverity, FollowTypes } from '@follow/cdk'

export { DocumentAlertSeverity }

export type DocumentAlertsFiltersPreference =
  FollowTypes.DocumentAlerts.DocumentAlertsFiltersPreference

export const SEVERITIES = [
  DocumentAlertSeverity.info,
  DocumentAlertSeverity.level1,
  DocumentAlertSeverity.level2,
  DocumentAlertSeverity.level3,
  DocumentAlertSeverity.level4,
]

export const SeverityNames: { [key in DocumentAlertSeverity]: string } = {
  [DocumentAlertSeverity.info]: 'Info',
  [DocumentAlertSeverity.level1]: 'Sévérité 1',
  [DocumentAlertSeverity.level2]: 'Sévérité 2',
  [DocumentAlertSeverity.level3]: 'Sévérité 3',
  [DocumentAlertSeverity.level4]: 'Sévérité 4',
}

export const SeverityShortNames: { [key in DocumentAlertSeverity]: string } = {
  [DocumentAlertSeverity.info]: 'Info',
  [DocumentAlertSeverity.level1]: 'Sév.1',
  [DocumentAlertSeverity.level2]: 'Sév.2',
  [DocumentAlertSeverity.level3]: 'Sév.3',
  [DocumentAlertSeverity.level4]: 'Sév.4',
}

export const SeverityColor: { [key in DocumentAlertSeverity]: LabelsName } = {
  [DocumentAlertSeverity.info]: 'blue',
  [DocumentAlertSeverity.level1]: 'green',
  [DocumentAlertSeverity.level2]: 'yellow',
  [DocumentAlertSeverity.level3]: 'orange',
  [DocumentAlertSeverity.level4]: 'red',
}

export const SeverityIcon: { [key in DocumentAlertSeverity]: IconsType } = {
  [DocumentAlertSeverity.info]: 'infoCircle',
  [DocumentAlertSeverity.level1]: 'warningCircle',
  [DocumentAlertSeverity.level2]: 'warningCircle',
  [DocumentAlertSeverity.level3]: 'crossCircle',
  [DocumentAlertSeverity.level4]: 'crossCircle',
}

export interface DocumentAlertType {
  value: string
  label: string
}

export type DocumentAlertCategories = 'alert' | 'signal'

export interface DocumentAlert {
  id: number
  category: DocumentAlertCategories
  type: string
  typeDisplayName: string
  subType: string | null
  subTypeDisplayName: string | null
  samType: string
  samTypeDisplayName: string
  severityLevel: DocumentAlertSeverity
  title: string
  content: string
  detail: string
  source: string | null
  contributor: string | null
  drugConfigUuids: string[]
  drugIds: number[]
}

export interface DocumentAlertsFilters {
  severityLevels: DocumentAlertSeverity[]
  types: DocumentAlertType[]
  categories: DocumentAlertCategories[]
  drugNames: Array<{ id: number; name: string }>
  samTypes: Array<{ name: string; displayName: string }>
}

export const DocumentAlertsCategoryNames: { [key in DocumentAlertCategories]: string } = {
  alert: 'Alerte',
  signal: 'Signal',
}

export const DEFAULT_DOCUMENT_ALERTS_FILTERS: DocumentAlertsFiltersPreference = {
  severityLevels: SEVERITIES,
  types: null,
  samTypes: null,
}

export interface DocumentAlertTypeEntry {
  name: string
  displayName: string | null
}

export interface DocumentAlertsTypes {
  types: DocumentAlertTypeEntry[]
  samTypes: DocumentAlertTypeEntry[]
}

export interface DocumentAlertsSeverityLabel {
  code: string
  label: string
  topSeverityLevel: DocumentAlertSeverity
}
export const NON_SAM_FILTER = 'Hors SAM'
