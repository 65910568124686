import { ManualPrescription } from '../../../model/ManualPrescription'
import { domainManualPrescriptionActionTypes } from './manualPrescription.model'

const updateManualPrescription = (
  manualPrescriptionUuid: string,
  manualPrescriptionUpdate: Partial<ManualPrescription>,
) => ({
  type: domainManualPrescriptionActionTypes.UPDATE_MANUAL_PRESCRIPTION,
  manualPrescriptionUuid,
  manualPrescriptionUpdate,
})

export const domainManualPrescriptionActions = {
  updateManualPrescription,
}
