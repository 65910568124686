import { RestuxResources } from '../../resources'
import { RestuxInUse, RestuxPagination, RestuxUiSagaConf } from '../../restux/ui'
import { contactsPaginationSelector } from './contacts.selectors'

export const contactsConfig: RestuxUiSagaConf = {
  resourceName: RestuxResources.contact,
  paginationSelector: contactsPaginationSelector,
}

export interface ContactsState {
  pagination: RestuxPagination
  inUse: RestuxInUse
}
