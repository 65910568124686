import React, { FC } from 'react'
import { ToolbarButton } from '@/components/toolbar/atoms'
import { ToolbarElementProps } from '@/model/Toolbar'

export const ListGroup: FC<ToolbarElementProps> = ({ editor }) => {
  const toggleBulletList = () => editor.chain().focus().toggleBulletList().run()
  const toggleOrderedList = () => editor.chain().focus().toggleOrderedList().run()

  return (
    <>
      <ToolbarButton
        title="Liste à puces (Ctrl + Shift + _)"
        icon="UnorderedList"
        active={editor.isActive('bulletList')}
        onClick={toggleBulletList}
      />
      <ToolbarButton
        title="Liste numérotée (Ctrl + Shift + è)"
        icon="OrderedList"
        active={editor.isActive('orderedList')}
        onClick={toggleOrderedList}
      />
    </>
  )
}
