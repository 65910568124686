import { useInfiniteQuery } from '@tanstack/react-query'
import { patientHealthDataKeys } from './patientHealthData.keys'

import { getPatientHealthDataTypes } from '../../../data/patientHealthData/getPatientHealthDataTypes'
import { UserFriendlyError } from '../../../model/Error'
import { useFlatPaginatedData } from '../../utils/query'
import { useIndexedModuleTypes } from '../../utils'

export const useGetPatientHealthDataTypes = () => {
  const queryReturn = useInfiniteQuery(
    patientHealthDataKeys.types,
    async ({ pageParam = 1 }) => {
      const { data, ok } = await getPatientHealthDataTypes(pageParam)
      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          `Impossible de récupérer les types des informations médicales`,
        )
      }
      return data
    },
    { staleTime: Infinity },
  )

  const healthDataTypes = useFlatPaginatedData(queryReturn.data)
  const indexedTypes = useIndexedModuleTypes(healthDataTypes)

  return { query: queryReturn, healthDataTypes, indexedTypes }
}
