import { PageList } from '../../restux/ui/restuxUi.model'
import { restuxResolvePage } from '../../restux/ui/restuxUi.selectors'
import { RootState } from '../../reducers/index'
import { Treatment } from '../../../model/Treatment'

export const patientCurrentTreatmentsPaginationSelector = (state: RootState) =>
  state.ui.patientCurrentTreatments.pagination

export const patientCurrentTreatmentsPageSelector = (state: RootState): PageList<Treatment> => {
  const pagination = patientCurrentTreatmentsPaginationSelector(state)
  const patientCurrentTreatmentsResource = state.cache.patientCurrentTreatments.list
  return restuxResolvePage(patientCurrentTreatmentsResource, pagination)
}
