import { useAtomValue } from 'jotai'
import { isDoctolibLoaded } from '../../../state/doctolib'
import { useGetPatient } from '../../queries/patients'
import { useCallback, useEffect, useState } from 'react'
import { isDefined } from '../../../misc/functions.utilities'
import { doctolibZipperCreateEvent } from '../../../misc/Doctolib/doctolib.actions'

export const useCreateEventInPlanning = () => {
  const isZipperLoaded = useAtomValue(isDoctolibLoaded)
  const [patientId, setPatientId] = useState<number | null>(null)
  const {
    query: { data: patient },
  } = useGetPatient({
    id: patientId!,
    enabled: isDefined(patientId) && isZipperLoaded,
  })

  useEffect(() => {
    if (patient) {
      console.log(`Doctolib: Create event for patient with id ${patient.id}`)
      doctolibZipperCreateEvent(patient)
      setPatientId(null)
    }
  }, [patient])

  const triggerCreateEventInPlanning = useCallback(
    ({ patientId }: { patientId: number }) => {
      setPatientId(isZipperLoaded ? patientId : null)
    },
    [isZipperLoaded],
  )

  return { triggerCreateEventInPlanning }
}
