import { FC, useCallback, useEffect, useState } from 'react'
import { Button } from '../../shared'
import { ManualPrescriptionBottomPanelProps } from './ManualPrescriptionBottomPanel.model'
import { v4 as uuid } from 'uuid'
import { ManualPrescriptionForm } from '../ManualPrescriptionForm'
import { ManualPrescriptionFormValue } from '../ManualPrescriptionForm/ManualPrescriptionForm.model'
import { formatISO } from 'date-fns'

export const ManualPrescriptionBottomPanel: FC<ManualPrescriptionBottomPanelProps> = ({
  actions: Actions,
  setBottomPanelOptions,
  submitManualPrescription,
}) => {
  const [formValid, setFormValid] = useState(false)
  const [
    manualPrescriptionForm,
    setCurrentManualPrescription,
  ] = useState<ManualPrescriptionFormValue>({
    content: '',
    startDate: new Date(),
    endDate: null,
  })

  const handleClose = useCallback(() => {
    setBottomPanelOptions({
      open: false,
    })
  }, [setBottomPanelOptions])
  const handleSubmit = useCallback(() => {
    if (manualPrescriptionForm.startDate) {
      submitManualPrescription({
        variableUuid: uuid(),
        content: manualPrescriptionForm.content,
        startDate: formatISO(manualPrescriptionForm.startDate),
        endDate: manualPrescriptionForm.endDate ? formatISO(manualPrescriptionForm.endDate) : null,
      })
    }
  }, [manualPrescriptionForm, submitManualPrescription])

  useEffect(() => {
    if (
      manualPrescriptionForm.content &&
      manualPrescriptionForm.startDate &&
      (manualPrescriptionForm.endDate
        ? manualPrescriptionForm.endDate.getTime() > manualPrescriptionForm.startDate.getTime()
        : true)
    )
      setFormValid(true)
    else setFormValid(false)
  }, [manualPrescriptionForm])

  return (
    <>
      <div className="flex h-full bg-shades-white">
        <div className="w-full mt-5 ml-10">
          <ManualPrescriptionForm
            currentManualPrescription={manualPrescriptionForm}
            onChange={(manualPrescription) =>
              setCurrentManualPrescription((currentManualPrescription) => ({
                ...currentManualPrescription,
                ...manualPrescription,
              }))
            }
          />
        </div>
      </div>
      <Actions>
        <Button label="Annuler" theme="dark" onClick={handleClose} />
        <Button
          type="submit"
          label="Enregistrer"
          theme="primary"
          disabled={!formValid}
          onClick={handleSubmit}
        />
      </Actions>
    </>
  )
}
