import {
  ApiMedicalEventListItemResource,
  ApiMedicalEventResource,
  MedicalEventUpdateResource,
} from './MedicalEvents.model'
import { MedicalEvent } from '../../../../model/MedicalEvent'
import { ApiResponse } from 'apisauce'
import { PaginatedList } from '../../../../model/Pagination'
import { deserializeDocumentInstanceListItem } from '../../documentInstances/api/DocumentInstances.mapper'

export function deserializeMedicalEvent(item: ApiMedicalEventResource): MedicalEvent {
  return {
    ...item,
    category: item.medicalEventCategory.id,
    documents: item.documents.map(deserializeDocumentInstanceListItem),
    owner: {
      ...item.owner,
      practiceLocation: item.owner?.practiceLocation || null,
    },
  }
}

export function deserializeMedicalEventResponse(response: ApiResponse<ApiMedicalEventResource>) {
  const { data, ...responseRest } = response
  // Error case => no deserialization
  if (!response.ok || !data) {
    return { ...responseRest }
  }
  return {
    ...responseRest,
    data: deserializeMedicalEvent(data),
  }
}

export function deserializeMedicalEventListItem(
  item: ApiMedicalEventListItemResource,
): MedicalEvent {
  return {
    ...item,
    category: item.medicalEventCategory.id,
    documents: item.documents.map(deserializeDocumentInstanceListItem),
  }
}

export function deserializeMedicalEventPaginatedResponse(
  response: ApiResponse<PaginatedList<ApiMedicalEventListItemResource>>,
): ApiResponse<PaginatedList<MedicalEvent>> {
  const { data, ...responseRest } = response
  // Error case => no deserialization
  if (!response.ok || !data) {
    return { ...responseRest }
  }
  return {
    ...responseRest,
    data: { ...data, items: data.items.map(deserializeMedicalEventListItem) },
  }
}

export function toMedicalEventUpdateResource({
  documents,
  title,
  ...restProps
}: Partial<MedicalEvent>): MedicalEventUpdateResource {
  return {
    ...restProps,
    documentIds: documents ? documents.map(({ id }) => id) : undefined,
    title,
  }
}
