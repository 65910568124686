import { FC, useContext } from 'react'
import { ActionTabProps } from './ActionTab.model'
import { TooltipWrapper } from '../../../../../shared'
import { DesktopNavigationContext } from '../DesktopNavigationMenu.context'
import { Tab } from '../Tab'

export const ActionTab: FC<ActionTabProps> = ({ onClick, label, interactiveLabel, ...props }) => {
  const { hideTooltips } = useContext(DesktopNavigationContext)

  return (
    <TooltipWrapper
      content={label}
      interactive={interactiveLabel}
      pointerDirection="left"
      display={!!label && !hideTooltips}
    >
      <div onClick={onClick}>
        <Tab active={false} {...props} />
      </div>
    </TooltipWrapper>
  )
}
