import { Action } from 'redux'
import { toUpperSnakeCase } from '../../restux/restux.utilities'
import { RestuxResources } from '../../resources'

export enum QuestionEditionOrigin {
  TEMPLATE = 'template',
  QUESTIONNAIRE = 'questionnaire',
  MEDICAL_EVENT = 'medicalEvent',
  NONE = 'none',
}
export type QuestionEditionOriginState = QuestionEditionOrigin

export interface BottomPanelQuestionState {
  origin: QuestionEditionOriginState
}

export interface EditQuestionAction extends Action {
  questionId: number
  questionContext: {
    documentInstanceId?: number
    documentTemplateId?: number
  }
  origin: QuestionEditionOrigin
  onQuestionEdited?: () => void
}

const RESOURCE_PREFIX = `@${toUpperSnakeCase(RestuxResources.question)}`

export const UiBottomPanelQuestionsActionTypes = {
  EDIT_QUESTION: `${RESOURCE_PREFIX}/EDIT_QUESTION`,
}
