import { connect } from 'react-redux'
import { RootState } from '../../../store'
import {
  bottomPanelDocumentTemplatesActions,
  bottomPanelDocumentTemplatesPageSelector,
} from '../../../store/ui/bottomPanelDocumentTemplates'
import { setBottomPanelOptions } from '../../../store/ui/bottomPanel'

import {
  SearchDocumentTemplatesStoreProps,
  SearchDocumentTemplatesDispatchProps,
} from './SearchDocumentTemplates.model'
import { SearchDocumentTemplates } from './SearchDocumentTemplates.component'

import { medicalEventDomainActions } from '../../../store/domain/medicalEvents/index'
import { usedDocumentCategoriesSelector } from '../../../store/domain/documentCategories/documentCategories.selectors'
import { documentCategoriesDomainAction } from '../../../store/domain/documentCategories'

const mapStateToProps = (state: RootState): SearchDocumentTemplatesStoreProps => ({
  documentTemplatesPage: bottomPanelDocumentTemplatesPageSelector(state),
  usedDocumentCategories: usedDocumentCategoriesSelector(state),
})

const mapDispatchToProps: SearchDocumentTemplatesDispatchProps = {
  setSelection: medicalEventDomainActions.addDocumentInstances,
  getPaginatedDocumentTemplate: bottomPanelDocumentTemplatesActions.requestPage,
  clearDocumentTemplateList: bottomPanelDocumentTemplatesActions.clearPagination,
  getUsedDocumentCategories: documentCategoriesDomainAction.fetchUsedDocumentCategories,
  setBottomPanelOptions,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)(SearchDocumentTemplates)
export { withConnect as SearchDocumentTemplates }
