import { ApiResponse } from 'apisauce'
import { PaginatedList } from '../../../model/Pagination'
import { IdentityStatus, LightPatient, Patient } from '../../../model/Patient'
import { stringifyFromValueJSON } from '../../../misc/editor.utilities'
import { formatISO } from 'date-fns'
import { ApiPatientResource, ApiPatientUpdateResource } from './patients.model'
import { deserializePatient, deserializeIdentityStatus } from '@follow/cdk'

export { deserializePatient, deserializeIdentityStatus }

export function serializePatient({
  contacts,
  assigningAuthority,
  identityStatus,
  ...patient
}: Partial<Patient>): ApiPatientUpdateResource {
  return {
    ...patient,
    birthLastName: patient.birthLastName ? patient.birthLastName.toLocaleUpperCase() : undefined,
    usedLastName: patient.usedLastName
      ? patient.usedLastName.toLocaleUpperCase()
      : patient.usedLastName,
    note: patient.note && stringifyFromValueJSON(patient.note),
    contacts: contacts
      ? contacts.map(({ organization, ...contact }) => ({
          ...contact,
          organizationId: organization.id,
        }))
      : undefined,
    assigningAuthorityOid: assigningAuthority?.oid,
    identityStatus: serializeIdentityStatus(identityStatus),
  }
}

export const serializeIdentityStatus = (identityStatus: IdentityStatus | undefined) => {
  if (identityStatus !== undefined) {
    const { insiStatus } = identityStatus
    return {
      ...identityStatus,
      insiStatus: {
        ...insiStatus,
        checkedAt: insiStatus.checkedAt ? formatISO(insiStatus.checkedAt) : null,
      },
    }
  }

  return undefined
}

export function deserializeLightPatient(patient: LightPatient): LightPatient {
  return {
    ...patient,
    birthLastName: patient.birthLastName.toLocaleUpperCase(),
    usedLastName: patient.usedLastName ? patient.usedLastName.toLocaleUpperCase() : null,
  }
}

export function deserializePatientResponse(
  response: ApiResponse<ApiPatientResource>,
): ApiResponse<Patient> {
  const { data, ...responseRest } = response
  // Error case => no deserialization
  if (!response.ok || !data) {
    return { ...responseRest }
  }
  return {
    ...responseRest,
    data: deserializePatient(data),
  }
}

export function deserializePatientPageResponse(
  pageResponse: ApiResponse<PaginatedList<LightPatient>>,
): ApiResponse<PaginatedList<LightPatient>> {
  const { data, ...responseRest } = pageResponse
  // Error case => no deserialization
  if (!pageResponse.ok || !data) {
    return { ...responseRest }
  }
  return {
    ...responseRest,
    data: { ...data, items: data.items.map(deserializeLightPatient) },
  }
}
