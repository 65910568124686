import { DocumentHistoryResourceType } from '../../domain/documentHistory'

export const UiBottomPanelDocumentHistoryActionTypes = {
  CLOSE_DOCUMENT_HISTORY: `@BOTTOM_PANEL_DOCUMENT_HISTORY/CLOSE_DOCUMENT_HISTORY`,
  DOWNLOAD_DOCUMENT_HISTORY: `@BOTTOM_PANEL_DOCUMENT_HISTORY/DOWNLOAD_DOCUMENT_HISTORY`,
  RESTORE_DOCUMENT_HISTORY: `@BOTTOM_PANEL_DOCUMENT_HISTORY/RESTAORE_DOCUMENT_HISTORY`,
  SEARCH_DOCUMENT_HISTORY: `@BOTTOM_PANEL_DOCUMENT_HISTORY/SEARCH_DOCUMENT_HISTORY`,
}

export type BottomPanelDocumentHistoryState = null | {
  id: number
  hash: string | null
  resource: DocumentHistoryResourceType
}
