import {
  BottomPanelPosologyState,
  uiBottomPanelPosologyActionTypes,
} from './bottomPanelPosology.model'
import { bottomPanelPosologyActions } from './bottomPanelPosology.actions'
import { createReducer } from 'redux-starter-kit'

const INITIAL_STATE: BottomPanelPosologyState = {
  mode: 'create',
  selectedDrug: null,
  prescription: null,
}

const ACTION_HANDLERS = {
  [uiBottomPanelPosologyActionTypes.OPEN]: (
    state = INITIAL_STATE,
    { mode, selectedDrug, prescription }: ReturnType<typeof bottomPanelPosologyActions.open>,
  ) => ({
    ...state,
    mode,
    selectedDrug,
    prescription,
  }),
}

export const bottomPanelPosologyReducer = createReducer(INITIAL_STATE, ACTION_HANDLERS)
