import { RestuxResources } from '../../resources'
import { toUpperSnakeCase } from '../../restux/restux.utilities'
import { RestuxPagination, RestuxUiSagaConf } from '../../restux/ui/restuxUi.model'
import { uiActionsFactory } from '../../restux/ui/restuxUiActions.factory'
import { patientCurrentTreatmentsPaginationSelector } from './patientCurrentTreatments.selectors'

export const patientCurrentTreatmentsConfig: RestuxUiSagaConf = {
  resourceName: RestuxResources.patientCurrentTreatments,
  identifier: 'patientCurrentTreatments',
  paginationSelector: patientCurrentTreatmentsPaginationSelector,
}

export interface PatientCurrentTreatmentsState {
  pagination: RestuxPagination
}

export const uiActions = uiActionsFactory(patientCurrentTreatmentsConfig)

const RESOURCE_PREFIX = `@${toUpperSnakeCase(RestuxResources.patientCurrentTreatments)}`

export const UiPatientCurrentTreatmentsActionTypes = {
  ...uiActions.types,
  GET_CURRENTS_TREATMENTS: `${RESOURCE_PREFIX}/GET_CURRENTS_TREATMENTS`,
  CREATE_CURRENTS_TREATMENTS: `${RESOURCE_PREFIX}/CREATE_CURRENTS_TREATMENTS`,
  UPDATE_CURRENTS_TREATMENTS: `${RESOURCE_PREFIX}/UPDATE_CURRENTS_TREATMENTS`,
  TERMINATE_TREATMENT: `${RESOURCE_PREFIX}/TERMINATE_TREATMENT`,
}
