import { atomWithReset } from 'jotai/utils'
import { TagType } from '../../model/Tag'

interface EditTagPanelAtomState {
  selectedTag: TagType | null
}

export const editTagPanelAtom = atomWithReset<EditTagPanelAtomState>({
  selectedTag: null,
})
