import { SideEffect } from '../../../model/Drug'

export interface SideEffectsState {
  sideEffects: Array<SideEffect>
  sideEffectSelected?: SideEffect
}

export const SideEffectsDomainActionsTypes = {
  SEARCH: '@SIDE_EFFECTS/SEARCH',
  SET_LIST: '@SIDE_EFFECTS/SET_LIST',
}
