import React, { FunctionComponent } from 'react'

import { Input } from '../../../../shared'
import styles from './MobileInputValue.module.scss'

interface MobileInputValueProps {
  value?: number
  minValue: number
  maxValue: number
  disabled: boolean
  onValueChange: (value: string) => void
}
export const MobileInputValue: FunctionComponent<MobileInputValueProps> = ({
  value,
  minValue,
  maxValue,
  disabled,
  onValueChange,
}) => (
  <div className={styles.inputNumber}>
    <div className={styles.minMax}>Min: {minValue}</div>
    <div className={styles.inputField}>
      <Input
        height="small"
        type="number"
        name="field"
        placeholder="?"
        value={`${value}`}
        disabled={disabled}
        onChange={(e) => onValueChange(e.target.value)}
        valid={!!value && value >= minValue && value <= maxValue}
        colorPreset="light"
        centered
      />
    </div>
    <div className={styles.minMax}>Max: {maxValue}</div>
  </div>
)
