import React, { FunctionComponent } from 'react'
import styles from './PatientCreationEvent.module.scss'
import { PatientCreationEventProps } from './PatientCreationEvent.model'
import { generateOwnerCreateNameTitle } from '../../../../../store/domain/medicalEvents/medicalEvents.utils'

export const PatientCreationEvent: FunctionComponent<PatientCreationEventProps> = ({
  creatorName,
  ownerName,
}) => {
  const ownerAndCreatorName = generateOwnerCreateNameTitle(ownerName, creatorName)

  return (
    <div className={styles.container}>
      <div className={styles.title}>Création du patient</div>

      {ownerAndCreatorName && <div className={styles.ownerCreatorName}>{ownerAndCreatorName}</div>}
    </div>
  )
}
