import React, { FunctionComponent, ReactNode } from 'react'
import classNames from 'classnames/bind'

import styles from './Tick.module.scss'

const classnamesCx = classNames.bind(styles)

interface TickProps {
  children?: ReactNode
  type: 'checkbox' | 'radio'
  title?: string
  id?: string
  name?: string
  checked?: boolean
  rounded?: boolean
  reverse?: boolean
  value?: string | number | string[]
  disabled?: boolean

  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}
export const Tick: FunctionComponent<TickProps> = ({
  type,
  title,
  id,
  name,
  checked = false,
  rounded = false,
  reverse = false,
  value,
  disabled = false,
  onChange,
  children,
}) => (
  <label title={title} className={styles.label} htmlFor={id}>
    {reverse && children}
    <div className={styles.checkbox}>
      <input
        className={classnamesCx(styles.input, { checked, rounded })}
        type={type}
        id={id}
        name={name}
        onChange={onChange}
        checked={checked}
        value={value}
        onClick={(e) => e.stopPropagation()}
        disabled={disabled}
      />

      <svg
        className={classnamesCx(styles.tick, { checked, disabled })}
        xmlns="http://www.w3.org/2000/svg"
        width="18px"
        height="18px"
        viewBox="0 0 24 24"
      >
        {!rounded && (
          <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
        )}
        {rounded && <circle cx="12" cy="12" r="8" />}
      </svg>
    </div>
    {!reverse && children}
  </label>
)
