import { FunctionComponent } from 'react'
import { RoundedButton } from '../../../shared'
import { MailPatientDetectionProps } from './MailPatientDetection.model'

const MailPatientDetection: FunctionComponent<MailPatientDetectionProps> = ({
  patient,
  onUpdate,
  onCreate,
}) => (
  <div className="rounded-lg bg-shades-8 max-w-xs p-3">
    {patient.id ? (
      <>
        <span className="text-shades-2 font-semibold mb-2">Patient déjà sur Follow !</span>
        {patient.needUpdate ? (
          <>
            <p className="text-shades-4 text-xs mb-3">
              Ce patient figure déjà dans votre base de patient sur Follow. Voulez-vous vérifier et
              mettre à jour les informations du patient ?
            </p>
            <RoundedButton
              icon="reload"
              label="Mettre à jour le patient"
              size="micro"
              onClick={onUpdate}
            />
          </>
        ) : (
          <p className="text-shades-4 text-xs">
            Ce patient figure dans votre base patient. Ses informations personnelles sont à jour.
          </p>
        )}
      </>
    ) : (
      <>
        <span className="text-shades-2 font-semibold mb-2">Nouveau patient trouvé !</span>
        <p className="text-shades-4 text-xs mb-3">
          Ce patient ne figure pas dans votre base de patient. Voulez-vous créer un patient avec ses
          informations ?
        </p>
        <RoundedButton icon="add" label="Ajouter le patient" size="micro" onClick={onCreate} />
      </>
    )}
  </div>
)

export default MailPatientDetection
