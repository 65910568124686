import { useAtomValue, useSetAtom } from 'jotai'
import { useLayoutEffect } from 'react'
import {
  exitFullScreenAtom,
  scrolledItemIdAtom,
  ScrollHighlightStatus,
  scrollHighlightStatusAtom,
  forceItemFocusAtom,
} from '../../../../state/navTrans'
import { getNavBarWithEventHeaderHeight } from '../utils'
import { EventDocumentType } from './PatientTransversalNavigationEventDocHeader'

export const useScrollToEventDocOrFile = (
  id: number,
  type: EventDocumentType,
  isLoading: boolean,
  isSelected: boolean,
  isFirstDocOrFile: boolean,
) => {
  const setScrollHighlightStatus = useSetAtom(scrollHighlightStatusAtom)
  const setScrolledDocOrFileId = useSetAtom(scrolledItemIdAtom)
  const exitFullScreenState = useAtomValue(exitFullScreenAtom)
  const setExitFullScreenState = useSetAtom(exitFullScreenAtom)
  const forceItemFocus = useAtomValue(forceItemFocusAtom)
  const setForceItemFocus = useSetAtom(forceItemFocusAtom)

  useLayoutEffect(() => {
    if ((!isLoading && isSelected) || exitFullScreenState || forceItemFocus) {
      if (isFirstDocOrFile) {
        window.scrollTo({ top: 0, behavior: 'auto' })
      } else {
        const idType = type === EventDocumentType.File ? `file-${id}` : `doc-${id}`
        const selected = document.getElementById(idType)
        const fileTopPosition = selected?.getBoundingClientRect().top
        if (fileTopPosition) {
          const fileOffsetPosition =
            fileTopPosition + window.pageYOffset - getNavBarWithEventHeaderHeight()

          window.scrollTo({
            top: fileOffsetPosition,
            behavior: 'auto',
          })
        }
      }
      setTimeout(() => {
        exitFullScreenState && setExitFullScreenState(false)
        forceItemFocus && setForceItemFocus(false)
        setScrolledDocOrFileId(id)
        setScrollHighlightStatus(ScrollHighlightStatus.ENABLED)
      }, 0)
    }
  }, [
    isSelected,
    isLoading,
    isFirstDocOrFile,
    id,
    setScrollHighlightStatus,
    setScrolledDocOrFileId,
    type,
    exitFullScreenState,
    setExitFullScreenState,
    forceItemFocus,
    setForceItemFocus,
  ])
}
