import { useContext, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { getTaskCount } from '../../../data/task'
import { isDefined } from '../../../misc/functions.utilities'
import { ConnectedUserContext } from '../../../misc/auth.utilities'
import { isDoctor } from '../../../misc/user.utilities'
import { taskKeys } from './tasks.keys'
import { TaskFilters } from '../../../model/Task'
import { HookQueryOptions } from '../Query.model'
import { queryOptionsMapper } from '../Query.mapper'

export const useGetTaskCount = (filters?: TaskFilters, options?: HookQueryOptions) => {
  const queryKey = useMemo(() => taskKeys.count(filters), [filters])
  const { currentUser } = useContext(ConnectedUserContext)

  return useQuery<number>(
    queryKey,
    async ({ signal }) => {
      const { data, ok } = await getTaskCount(filters, signal)
      const taskCount = data ?? 0
      if (!isDefined(taskCount) || !ok) {
        throw new Error(`Impossible de récupérer le nombre de tâches`)
      }
      return taskCount
    },
    queryOptionsMapper({
      enabled: isDoctor(currentUser),
      ...options,
    }),
  )
}
