import { RestuxApiConfig } from '../../../restux/cache/restuxCache.model'
import { PasswordUpdate } from '../../../../model/User'
import getApi from '../../../../api'
import { PaginatedList } from '../../../../model/Pagination'
import {
  deserializeUserResponse,
  serializeTrustedContact,
  serializeUserCreation,
} from './users.mapper'
import { Team } from '../../../../model/Team'
import { UserSerialized, UserUpdateResource } from './users.model'
import { MailContact } from '../../../../model/Contact'

const Config = {
  baseUrl: '/users',
}

export const restuxUserMeTeamsApiConfig: RestuxApiConfig<Team, Team> = {
  url: `${Config.baseUrl}/me/teams`,
  getPaginatedItems: (route) => getApi().get<PaginatedList<Team>>(route),
  getItem: (route) => getApi().get<Team>(route),
}

export const getMe = () =>
  getApi().get<UserSerialized>(`${Config.baseUrl}/me`).then(deserializeUserResponse)

export const updateMe = (user: UserUpdateResource) =>
  getApi()
    .put<UserSerialized>(`${Config.baseUrl}/me`, serializeUserCreation(user))
    .then(deserializeUserResponse)

export const requestPasswordUpdate = ({ actualPassword, newPassword }: PasswordUpdate) =>
  getApi().put(`${Config.baseUrl}/me/password`, {
    oldPassword: actualPassword,
    password: newPassword,
  })

export const getMeUsurpation = (usurpedUserId: string, usurpedUserEmail: string) =>
  getApi()
    .get<UserSerialized>(`${Config.baseUrl}/me`, {
      _switch_user: usurpedUserEmail,
      _switch_user_id: usurpedUserId,
    })
    .then(deserializeUserResponse)

export const updateTrustedContact = (trustedContacts: MailContact[]) =>
  getApi().put<UserSerialized>(
    `${Config.baseUrl}/me/trusted_mss_emails`,
    serializeTrustedContact(trustedContacts),
  )
