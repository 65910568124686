import type { PlaceAutocompleteResponse, PlaceDetailsResponse } from '@google/maps'
import { getMapApi } from '../api'

export async function searchAddresses({
  search,
  sessionToken,
  countries,
}: {
  search: string
  sessionToken: string
  countries?: string
}) {
  const searchParams = new URLSearchParams({ input: search, sessionToken })
  if (countries) {
    searchParams.append('countries', countries)
  }
  return getMapApi().get<PlaceAutocompleteResponse>(`places/search?${searchParams}`)
}

export async function getAddressDetails(placeId: string, sessionToken: string) {
  return getMapApi().get<PlaceDetailsResponse>('places/details', {
    placeId,
    sessionToken,
  })
}
