import { combineReducers } from 'redux'
import {
  paginationReducerFactory,
  inUseReducerFactory,
} from '../../restux/ui/restuxUiReducer.factory'
import {
  managerDocumentTemplatesConfig,
  ManagerDocumentTemplateState,
} from './managerDocumentTemplates.model'

const pagination = paginationReducerFactory(managerDocumentTemplatesConfig)

const inUse = inUseReducerFactory(managerDocumentTemplatesConfig)

export const managerDocumentTemplatesReducer = combineReducers<ManagerDocumentTemplateState>({
  pagination,
  inUse,
})
