import React, { FunctionComponent } from 'react'
import { DocumentLayoutHeaderProps } from './DocumentLayoutHeader.model'
import { IconButton } from '../../shared'
import styles from './DocumentLayoutHeader.module.scss'

export const DocumentLayoutHeader: FunctionComponent<DocumentLayoutHeaderProps> = ({
  title,
  documentLayout,
  previewDocumentLayout,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.label}>{title}</div>
      <div className={styles.actions}>
        <IconButton
          icon="print"
          theme="primary"
          onClick={() => previewDocumentLayout('print', documentLayout)}
          title="Imprimer un apercu de la mise en page"
        ></IconButton>
        <IconButton
          icon="save"
          theme="primary"
          onClick={() => previewDocumentLayout('download', documentLayout)}
          title="Télécharger un aperçu de la mise en page"
        ></IconButton>
      </div>
    </div>
  )
}
