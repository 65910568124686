import { SideEffect } from '../../../model/Drug'
import { SideEffectsDomainActionsTypes } from './sideEffects.model'

export const sideEffectsDomainActions = {
  search: (search: string) => ({
    type: SideEffectsDomainActionsTypes.SEARCH,
    search,
  }),
  setSideEffectsList: (sideEffectsList: Array<SideEffect>) => ({
    type: SideEffectsDomainActionsTypes.SET_LIST,
    sideEffectsList,
  }),
}
