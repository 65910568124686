import getApi from '../../../../api/apisauceConfig'
import { ApiPrescriptionTemplateList } from './prescriptionTemplate.model'
import { largePageLimit } from '../../../../constants'

export const getCollection = (drugIds: string) =>
  getApi().get<ApiPrescriptionTemplateList>(`prescription_templates`, {
    currentPage: 1,
    pageSize: largePageLimit,
    drugIds,
  })

export const deleteItem = (id: string) => getApi().delete<void>(`prescription_templates/${id}`)

export const createPrescriptionTemplate = (prescriptionUuid: string, name: string) =>
  getApi().post<void>('/prescription_templates/create_from_prescription', {
    prescriptionUuid,
    name,
  })
