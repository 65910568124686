import { RenderedPdf, useRenderPdfFromDocuments } from './useRenderPdfFromDocuments.query'
import { useCallback } from 'react'
import { downloadBlob } from '../../../misc/blob.utilities'
import { isPrescription } from '../../../misc/documents.utilities'
import { DocumentInstance } from '../../../model/DocumentInstance'
import { DocumentCategoryKeys } from '@follow/cdk'
import { DocumentFormat } from '../../../model/Document'

type DownloadableDocument = Pick<DocumentInstance, 'id' | 'type'> & {
  category: DocumentCategoryKeys
}

export type DownloadableDocumentsVariable = {
  documents: DownloadableDocument[]
  fileName?: string
  format?: DocumentFormat
}

export const useDownloadDocuments = () => {
  const download = useCallback((renderedPdf: RenderedPdf) => {
    downloadBlob(renderedPdf.blob, renderedPdf.fileName || 'Document')
  }, [])

  const { render } = useRenderPdfFromDocuments()

  return ({ documents, fileName, format }: DownloadableDocumentsVariable) => {
    const documentsToLock = documents
      .filter(({ category, type }) => type === 'farte' && category && isPrescription(category))
      .map(({ id }) => id)

    render(
      {
        documents: documents.map((document) => ({
          documentId: document.id,
          type: document.type,
          documentCategoryId: document.category,
        })),
        documentsToLock,
        format,
        fileName,
      },
      { onSuccess: download },
    )
  }
}
