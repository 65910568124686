import { getCssVariable } from '../utils'

const BaseButtonThemes = {
  light: {
    active: {
      colors: {
        default: getCssVariable('shades', 'shade2'),
        backgroundColor: getCssVariable('shades', 'white'),
      },
      borderColors: {
        default: getCssVariable('shades', 'shade5'),
        hover: getCssVariable('shades', 'shade6'),
      },
    },
    disabled: {
      colors: {
        default: getCssVariable('shades', 'shade5'),
        backgroundColor: getCssVariable('shades', 'white'),
      },
      borderColors: {
        default: getCssVariable('shades', 'shade5'),
        hover: getCssVariable('shades', 'shade5'),
      },
    },
  },
  dark: {
    active: {
      colors: {
        default: getCssVariable('shades', 'white'),
        backgroundColor: getCssVariable('shades', 'shade2'),
      },
      borderColors: {
        default: getCssVariable('shades', 'shade4'),
        hover: getCssVariable('shades', 'shade6'),
      },
    },
    disabled: {
      colors: {
        default: getCssVariable('shades', 'shade4'),
        backgroundColor: getCssVariable('shades', 'shade2'),
      },
      borderColors: {
        default: getCssVariable('shades', 'shade4'),
        hover: getCssVariable('shades', 'shade4'),
      },
    },
  },
  transparent: {
    active: {
      colors: {
        default: getCssVariable('shades', 'white'),
        backgroundColor: 'transparent',
      },
      borderColors: {
        default: getCssVariable('shades', 'shade4'),
        hover: getCssVariable('shades', 'shade5'),
      },
    },
    disabled: {
      colors: {
        default: getCssVariable('shades', 'shade5'),
        backgroundColor: 'transparent',
      },
      borderColors: {
        default: getCssVariable('shades', 'shade5'),
        hover: getCssVariable('shades', 'shade5'),
      },
    },
  },
  'transparent-light': {
    active: {
      colors: {
        default: getCssVariable('shades', 'white'),
        backgroundColor: 'transparent',
      },
      borderColors: {
        default: getCssVariable('shades', 'white'),
        hover: getCssVariable('shades', 'shade5'),
      },
    },
    disabled: {
      colors: {
        default: getCssVariable('shades', 'shade5'),
        backgroundColor: 'transparent',
      },
      borderColors: {
        default: getCssVariable('shades', 'shade5'),
        hover: getCssVariable('shades', 'shade5'),
      },
    },
  },
  'transparent-dark': {
    active: {
      colors: {
        default: getCssVariable('shades', 'shade2'),
        backgroundColor: 'transparent',
      },
      borderColors: {
        default: getCssVariable('shades', 'shade5'),
        hover: getCssVariable('shades', 'shade6'),
      },
    },
    disabled: {
      colors: {
        default: getCssVariable('shades', 'shade5'),
        backgroundColor: 'transparent',
      },
      borderColors: {
        default: getCssVariable('shades', 'shade5'),
        hover: getCssVariable('shades', 'shade5'),
      },
    },
  },
  primary: {
    active: {
      colors: {
        default: getCssVariable('shades', 'white'),
        backgroundColor: getCssVariable('primary', 'default'),
      },
      borderColors: {
        default: getCssVariable('primary', 'light'),
        hover: getCssVariable('shades', 'shade8'),
      },
    },
    disabled: {
      colors: {
        default: getCssVariable('shades', 'white'),
        backgroundColor: getCssVariable('primary', 'washed'),
      },
      borderColors: {
        default: getCssVariable('primary', 'washed'),
        hover: getCssVariable('primary', 'washed'),
      },
    },
  },
  secondary: {
    active: {
      colors: {
        default: getCssVariable('shades', 'white'),
        backgroundColor: getCssVariable('secondary', 'default'),
      },
      borderColors: {
        default: getCssVariable('secondary', 'light'),
        hover: getCssVariable('secondary', 'light'),
      },
    },
    disabled: {
      colors: {
        default: getCssVariable('shades', 'white'),
        backgroundColor: getCssVariable('secondary', 'washed'),
      },
      borderColors: {
        default: getCssVariable('secondary', 'washed'),
        hover: getCssVariable('secondary', 'washed'),
      },
    },
  },
  warning: {
    active: {
      colors: {
        default: getCssVariable('shades', 'white'),
        backgroundColor: getCssVariable('status', 'warning'),
      },
      borderColors: {
        default: 'transparent',
        hover: 'transparent',
      },
    },
    disabled: {
      colors: {
        default: getCssVariable('shades', 'white'),
        backgroundColor: getCssVariable('transparentStatus', 'warning'),
      },
      borderColors: {
        default: 'transparent',
        hover: 'transparent',
      },
    },
  },
  invalid: {
    active: {
      colors: {
        default: getCssVariable('shades', 'white'),
        backgroundColor: getCssVariable('status', 'invalid'),
      },
      borderColors: {
        default: 'transparent',
        hover: 'transparent',
      },
    },
    disabled: {
      colors: {
        default: getCssVariable('shades', 'white'),
        backgroundColor: getCssVariable('transparentStatus', 'invalid'),
      },
      borderColors: {
        default: 'transparent',
        hover: 'transparent',
      },
    },
  },
}

export default BaseButtonThemes
