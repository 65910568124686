import React, { FunctionComponent, useMemo } from 'react'
import { getDistanceInWordsToNow } from '../../../../../misc/date.utilities'
import { HealthData } from '../../../../../model/HealthData'
import { stringifyHealthDataValueWithUnit } from '../../../../../misc/healthData.utilities'
import { TooltipWrapper } from '../../../../shared'

export const PatientHealthDataItem: FunctionComponent<HealthData> = (props) => {
  const { value, updatedAt, moduleDataType } = props

  const info = useMemo(() => {
    if (value.info) {
      return value.info
    }

    return `il y a ${getDistanceInWordsToNow(new Date(updatedAt))}`
  }, [value, updatedAt])

  return (
    <div className="flex justify-between items-center my-2 text-xs">
      <div className="flex-grow text-left text-shades-2">{moduleDataType.name}</div>
      <TooltipWrapper content={info}>
        <div className="text-right text-xs text-shades-2 font-semibold">
          {stringifyHealthDataValueWithUnit(props)}
        </div>
      </TooltipWrapper>
    </div>
  )
}
