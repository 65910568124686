import {
  FontFamily,
  InlineSimpleEditor,
  valueToJSON,
  JSONToValue,
  getEditorEmptyJsonValue,
  EditorValueJSON,
} from '@follow/farte'

import React, { FunctionComponent, useState } from 'react'
import { useDebounce } from 'react-use'

interface EditTextOutputProps {
  editorFontFamily: FontFamily
  textOutput: EditorValueJSON | undefined
  onTextChange: (value: EditorValueJSON) => void
}

export const EditTextOutput: FunctionComponent<EditTextOutputProps> = ({
  editorFontFamily,
  textOutput,
  onTextChange,
}) => {
  const [value, setValue] = useState(
    JSONToValue(textOutput || getEditorEmptyJsonValue(editorFontFamily)),
  )

  useDebounce(
    () => {
      onTextChange(valueToJSON(value))
    },
    300,
    [value],
  )

  return <InlineSimpleEditor value={value} onValueChange={setValue} />
}
