import { connect } from 'react-redux'
import { branch, renderNothing, compose } from 'recompose'

import { RootState } from '../../../store'
import { inUseMedicalEventSelector } from '../../../store/ui/medicalEvents'

import { MedicalEventForm } from './MedicalEventForm.component'
import {
  MedicalEventFormStoreProps,
  MedicalEventFormProps,
  MedicalEventFormDispatchProps,
} from './MedicalEventForm.model'
import { medicalEventDomainActions } from '../../../store/domain/medicalEvents'

const mapStateToProps = (state: RootState): MedicalEventFormStoreProps => ({
  inUseMedicalEvent: inUseMedicalEventSelector(state),
})

const mapDispatchToProps: MedicalEventFormDispatchProps = {
  updateMedicalEvent: medicalEventDomainActions.updateMedicalEvent,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const withBranch = branch<MedicalEventFormStoreProps>(
  ({ inUseMedicalEvent }) => inUseMedicalEvent === undefined,
  renderNothing,
)

const withCompose = compose<MedicalEventFormProps, {}>(withConnect, withBranch)(MedicalEventForm)

export { withCompose as MedicalEventForm }
