import React, { FunctionComponent, useCallback, useMemo } from 'react'
import { FwTrackingEvent, withTrackingEvent } from '../../../../../misc/Tracking'
import { MedicalEventItem } from '../MedicalEventItem'
import { PatientTimelineItemProps } from './PatientTimelineItem.model'
import { PatientCreationEvent } from '../PatientCreationEvent'
import { getFullName } from '../../../../../misc/user.utilities'
import { MedicalEvent } from '../../../../../model/MedicalEvent'
import { TimelineItem } from '../../../Timeline'
import { useNavigate } from 'react-router-dom'
import styles from './PatientTimelineItem.module.scss'

export const PatientTimelineItem: FunctionComponent<PatientTimelineItemProps> = ({
  patient,
  createMedicalEvent,
  deleteMedicalEvent,
  onPinMedicalEvent,
  duplicateMedicalEventDocuments,
  payload,
  date,
  type,
  onOwnerChange,
}) => {
  const navigate = useNavigate()

  const handleAddDocumentClick = (medicalEvent: MedicalEvent) => {
    navigate({
      pathname: `medicalEvent/${medicalEvent.id}`,
      search: '?adding=true',
    })
  }

  const handleDeleteMedicalEvent = useCallback(
    (payload: MedicalEvent) => () =>
      deleteMedicalEvent({ medicalEventId: payload.id, patientId: payload.patientId }),
    [deleteMedicalEvent],
  )

  const navigateToMedicalEvent = useMemo(
    () =>
      withTrackingEvent(FwTrackingEvent.DISPLAY_MEDICAL_EVENT)(
        (patientId: number, medicalEventId: number) => {
          navigate(`/patients/${patientId}/medicalEvent/${medicalEventId}`)
        },
      ),
    [navigate],
  )

  switch (type) {
    case 'AddMedicalEventAction':
      return patient.isEditable ? (
        <TimelineItem type={type} date={date} payload={payload} hiddenOnMobile headerLess>
          <button
            className={styles.createMedEvent}
            onClick={() => createMedicalEvent(patient.id)}
            data-test-id="button-add-medical_event"
          >
            Nouvel événement
          </button>
        </TimelineItem>
      ) : (
        <div />
      )
    case 'CreatedPatientEvent':
      return (
        <TimelineItem type={type} date={date} payload={payload}>
          <PatientCreationEvent
            creatorName={getFullName(patient.creator)}
            ownerName={getFullName(patient.owner)}
          />
        </TimelineItem>
      )
    default:
      return (
        (payload && (
          <TimelineItem date={date} type={payload.category} payload={payload}>
            <MedicalEventItem
              type={payload.category}
              medicalEvent={payload}
              onDeleteMedicalEvent={handleDeleteMedicalEvent(payload)}
              onPinMedicalEvent={onPinMedicalEvent}
              onNavigateToMedicalEvent={() => navigateToMedicalEvent(patient.id, payload.id)}
              onAddDocumentClick={() => handleAddDocumentClick(payload)}
              onDuplicateMedicalEventDocuments={duplicateMedicalEventDocuments}
              onOwnerChange={onOwnerChange}
            />
          </TimelineItem>
        )) || <div />
      )
  }
}
