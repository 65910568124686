import apisauce, { ApisauceConfig, ApisauceInstance } from 'apisauce'
import { Store } from 'redux-starter-kit'
import { AuthService } from '../misc/auth.utilities'
import { redirectToErrorPage } from '../misc/url.utilities'
import { User } from '../model/User'
import { RootState } from '../store'
import { decrementRequestCount, incrementRequestCount } from '../store/http/http.actions'
import { usurpedSelector } from '../store/ui/adminUsers/index'
import { AUTHENTICATION_ID_MAIL } from '../model/Mail'
import { inUseMailAddressSelector } from '../store/domain/mail/mail.selector'
import { checkLocalStorageValueExist } from '../misc/localstorage.utilities'
import { loggedUserSelector } from '../store/domain/me'
import { isAssistant } from '../misc/user.utilities'
import { AUTH_URL } from '../environment/auth'
import { SEPHIRA_ICONNECT_URL } from '../environment/sephira'

let api: ApisauceInstance
let mapApi: ApisauceInstance
let keycloakApi: ApisauceInstance
let sephiraIconnectApi: ApisauceInstance

interface ApiClientOpts {
  addTransforms?: boolean
  addMonitor?: boolean
  addBearer?: boolean
}
function createApiClient(
  store: Store<RootState>,
  baseURL: string,
  { addBearer, addTransforms }: ApiClientOpts = {},
) {
  const apiInstance = apisauce.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
    },
  })

  if (addBearer) {
    apiInstance.addAsyncRequestTransform(async (request) => {
      if (AuthService.isLogged() && request.headers) {
        await AuthService.updateToken()
        request.headers.Authorization = `Bearer ${AuthService.getToken()}`
      }
    })
  }

  if (addTransforms) {
    apiInstance.addRequestTransform((request) => {
      store.dispatch(incrementRequestCount())
    })

    apiInstance.addResponseTransform((response) => {
      store.dispatch(decrementRequestCount())
      if (response.status === 503) {
        redirectToErrorPage('maintenance')
      }
    })

    apiInstance.addRequestTransform((request) => {
      const state = store.getState()
      const usurpedUser: User | null = usurpedSelector(state)
      const loggedUser: User | null = loggedUserSelector(state)
      const inUseMailAddress: string | undefined = inUseMailAddressSelector(state)
      // On ne doit pas appliquer l'usurpation sur ces URL

      const useStellairAssistantAccount =
        isAssistant(loggedUser) && loggedUser.preferences.useStellairAssistantAccount
      const adminUrls = [
        /^users$/,
        /^users\/me$/,
        /^admin/,
        /^score\/base\/duplicate-from-score-user/,
        /^survey\/clone/,
        /^documents\/clone/,
        /^file_templates\/clone/,
        ...(useStellairAssistantAccount ? [/^stellair/, /^external-services/] : []),
        /^notifications/,
        /^legal_documents/,
      ]
      // TODO, je vais être obligé de gérer des patterns

      let urlWithoutParameters = request.url || ''
      // on retire le slash au debut
      if (urlWithoutParameters.startsWith('/')) {
        urlWithoutParameters = urlWithoutParameters.substr(1)
      }
      // On récupère l'URL sans les paramètres get s'il y en a
      if (request.url) {
        const search = request.url.match(/\/(.*?)\?/)
        if (search && search.length >= 2) {
          urlWithoutParameters = search[1]
        }
      }

      const mssUrlsPatternForXMssToken = [/^mss.*/]
      const oneUrlIsMatchingWithMssPattern = mssUrlsPatternForXMssToken.some((pattern) =>
        urlWithoutParameters.match(pattern),
      )

      if (
        oneUrlIsMatchingWithMssPattern &&
        urlWithoutParameters &&
        checkLocalStorageValueExist(AUTHENTICATION_ID_MAIL)
      ) {
        request.headers = {
          'X-MSS-TOKEN': localStorage.getItem(AUTHENTICATION_ID_MAIL) ?? '',
        }
      }

      //Il faut exclure ici les paths qui n'ont pas besoin du paramètre _mss_email_address
      const mssUrlsPatternWithExcludes = [/^mss\/(?!email_addresses).*/]

      if (inUseMailAddress) {
        const oneUrlIsMatching = mssUrlsPatternWithExcludes.some((pattern) =>
          urlWithoutParameters.match(pattern),
        )
        if (urlWithoutParameters && oneUrlIsMatching) {
          request.params = {
            ...request.params,
            _mss_email_address: inUseMailAddress,
          }
        }
      }

      // En cas d'usurpation active, et pour une requête autre que sur une URL réservée aux admins
      // Alors on passe le paramètre d'usurpation
      if (usurpedUser) {
        const oneUrlIsMatching = adminUrls.some((pattern) => !!urlWithoutParameters.match(pattern))
        if (urlWithoutParameters && !oneUrlIsMatching) {
          request.params = {
            ...request.params,
            _switch_user: usurpedUser.email,
            _switch_user_id: usurpedUser.id,
          }
        }
      }
    })

    apiInstance.axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        const {
          response: { status, data },
        } = error
        if (status === 401) {
          localStorage.removeItem(AUTHENTICATION_ID_MAIL)
          AuthService.requestLogin()
        }
        console.error(data?.detail)
        return error
      },
    )
  }

  return apiInstance
}

function createApiClientWithLoadingWatcher(store: Store<RootState>, config: ApisauceConfig) {
  const apiInstance = apisauce.create(config)
  apiInstance.addRequestTransform(() => {
    store.dispatch(incrementRequestCount())
  })

  apiInstance.addResponseTransform(() => {
    store.dispatch(decrementRequestCount())
  })

  return apiInstance
}

export function initClient(appStore: Store<RootState>) {
  api = createApiClient(appStore, `/api`, {
    addBearer: true,
    addMonitor: true,
    addTransforms: true,
  })

  mapApi = createApiClient(appStore, `/map-api/v1`, {
    addBearer: true,
  })

  keycloakApi = apisauce.create({
    baseURL: AUTH_URL,
    headers: {
      'Content-Type': 'application/json',
    },
  })

  sephiraIconnectApi = createApiClientWithLoadingWatcher(appStore, {
    baseURL: SEPHIRA_ICONNECT_URL,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      Accept: 'application/json',
    },
  })
}

export function getMapApi() {
  return mapApi
}

export function getKeycloakApi() {
  return keycloakApi
}

export default function getApi() {
  return api
}

export function getSephiraIconnectApi() {
  return sephiraIconnectApi
}
