import React, { FunctionComponent } from 'react'

import { Colors } from '../../../colors'
import { Icon } from '../../../components/shared'

import styles from './ToolbarButton.module.scss'

export const AddDatamatrixButton: FunctionComponent<{ onClick: () => void }> = ({ onClick }) => (
  <div role="button" className={styles.button} onClick={onClick}>
    <Icon icon="barcode" size="pico" color={Colors.backgroundDark3} />
  </div>
)
