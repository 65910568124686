import { IDrugVariableConfigurationDispatchProps } from './DrugVariableConfiguration.model'
import { DrugVariableConfiguration } from './DrugVariableConfiguration.component'
import { connect } from 'react-redux'
import { medicalEventContentUiActions } from '../../../store/ui/medicalEvents/medicalEventContent'
import { domainPrescriptionActions } from '../../../store/domain/prescription'
import { bottomPanelDrugsActions } from '../../../store/ui/bottomPanelDrugs'

const mapDispatchToProps: IDrugVariableConfigurationDispatchProps = {
  selectMedicalEventContent: medicalEventContentUiActions.selectMedicalEventContent,
  switchPrescriptionToVMP: domainPrescriptionActions.switchToVMP,
  searchDrug: bottomPanelDrugsActions.searchDrug,
  updatePrescription: domainPrescriptionActions.updatePrescription,
}

export default connect(undefined, mapDispatchToProps)(DrugVariableConfiguration)
