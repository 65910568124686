import { createReducer } from 'redux-starter-kit'
import { indicationsDomainActions } from './indications.actions'
import { IndicationsDomainActionsTypes, IndicationsState } from './indications.model'

const INDICATIONS_INITIAL_STATE: IndicationsState = {
  indications: [],
}

const ACTION_HANDLERS = {
  [IndicationsDomainActionsTypes.SET_LIST]: (
    state: IndicationsState = INDICATIONS_INITIAL_STATE,
    { indicationsList }: ReturnType<typeof indicationsDomainActions.setIndicationsList>,
  ): IndicationsState => ({
    ...state,
    indications: indicationsList,
  }),
}

export const indicationsDomainReducer = createReducer<IndicationsState, any>(
  INDICATIONS_INITIAL_STATE,
  ACTION_HANDLERS,
)
