import { createReducer } from 'redux-starter-kit'
import { MeState, MeActionTypes } from './me.model'
import { setMe } from './me.actions'

const USER_INITIAL_STATE: MeState = {
  user: null,
}

const ACTION_HANDLERS = {
  [MeActionTypes.SET_ME]: (
    state: MeState = USER_INITIAL_STATE,
    { me }: ReturnType<typeof setMe>,
  ): MeState => ({
    ...state,
    user: me,
  }),
}

export const meReducer = createReducer<MeState, any>(USER_INITIAL_STATE, ACTION_HANDLERS)
