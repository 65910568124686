import React, { FunctionComponent } from 'react'
import { Card } from '../../shared'

interface FormCardProps {
  title: string
  label: string
  children?: React.ReactNode
}

export const FormCard: FunctionComponent<FormCardProps> = ({ title, label, children }) => {
  return (
    <Card>
      <div className="flex flex-col gap-4">
        <h2>{title}</h2>
        <p>{label}</p>
        {children}
      </div>
    </Card>
  )
}
