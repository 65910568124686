import React, { FC, useContext } from 'react'
import { ToolbarElementProps } from '@/model/Toolbar'
import { ToolbarButton } from '@/components/toolbar/atoms'
import { EditorConfigContext } from '@/context'

export const FormatGroup: FC<ToolbarElementProps> = ({ editor }) => {
  const { tools } = useContext(EditorConfigContext)

  const handleClear = () => editor.chain().focus().clearNodes().unsetAllMarks().run()

  const display = tools.includes('unformat')

  return display ? (
    <ToolbarButton title="Retirer la mise en forme" icon="Unformat" onClick={handleClear} />
  ) : null
}
