import { createReducer } from 'redux-starter-kit'
import { uiDocumentAlertsActions } from './documentAlerts.actions'
import { UiDocumentAlertsState, uiDocumentAlertsActionTypes } from './documentAlerts.model'

const DEFAULT_STATE: UiDocumentAlertsState = {
  displayableSamAlerts: [],
  acknowledgedSamAlerts: [],
}

const ACTION_HANDLERS = {
  [uiDocumentAlertsActionTypes.STORE_DISPLAYABLE_SAM_ALERTS]: (
    state = DEFAULT_STATE,
    { alerts }: ReturnType<typeof uiDocumentAlertsActions.storeDisplayableSamAlerts>,
  ) => ({
    ...state,
    displayableSamAlerts: alerts,
  }),
  [uiDocumentAlertsActionTypes.ACKNOWLEDGE_CURRENT_SAM_ALERTS]: ({
    displayableSamAlerts,
    acknowledgedSamAlerts,
    ...state
  } = DEFAULT_STATE) => ({
    ...state,
    acknowledgedSamAlerts: [...acknowledgedSamAlerts, ...displayableSamAlerts],
    displayableSamAlerts: [],
  }),
  [uiDocumentAlertsActionTypes.CLEAR_ALERTS]: (state = DEFAULT_STATE) => ({
    ...state,
    displayableSamAlerts: [],
    acknowledgedSamAlerts: [],
  }),
}

export const documentAlertsReducer = createReducer(DEFAULT_STATE, ACTION_HANDLERS)
