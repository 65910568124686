export type VariableTheme = 'stale'

export type VariableState = 'fallback' | 'value'

export interface VariableData {
  type: 'inline' | 'inline-block' | 'block'
  id: string
  value: string
  state: VariableState
  themes?: Array<VariableTheme>
}

export type VariableMap = Record<string, VariableData>

export interface VariableProperties {
  id: string
  displayConfig: string | null
}

export enum VariableAttrbs {
  variableId = 'variableid',
  displayConfig = 'displayconfig',
  flatId = 'flat-variableid',
}

export enum VariableDataAttrbs {
  variableId = `data-variableid`,
  displayConfig = `data-displayconfig`,
  flatId = 'data-flat-variableid',
}
