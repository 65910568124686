import { patientsKeys } from '../../queries/patients/patients.keys'
import { doctolibZipperUpdateOrCreatePatient } from '../../../misc/Doctolib/doctolib.actions'
import { followToDoctolibPatientMapper } from '../../../misc/Doctolib/doctolib.utils'
import { useSubscribeMutation } from '../mutation'
import { Patient } from '../../../model/Patient'
import { PatientMutationPayload } from '../../../data/patients/mapper/patients.model'
import { useAtomValue } from 'jotai'
import { zipperStatusAtom } from '../../../state/doctolib'
import { DoctolibZipperStatus } from '../../../model/Doctolib'

export const useCreateDoctolibPatient = () => {
  const zipperStatus = useAtomValue(zipperStatusAtom)

  useSubscribeMutation<Patient, PatientMutationPayload>(patientsKeys.create, (patient) => {
    if (zipperStatus === DoctolibZipperStatus.LOADED) {
      console.log(`Doctolib: Create patient with id ${patient.id}`)
      doctolibZipperUpdateOrCreatePatient(followToDoctolibPatientMapper(patient))
    }
  })
}
