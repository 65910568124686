import { RootState } from '../../reducers'
import { buildQuestionnaireComputedValueKey } from './questionnaireComputedValues.model'

export function questionnaireComputedValue(
  state: RootState,
  documentId: number,
  questionnaireId: number,
) {
  const computedValues = state.ui.questionnaireComputedValues
  const computedValueKey = buildQuestionnaireComputedValueKey(documentId, questionnaireId)
  const computedValue = computedValues[computedValueKey]
  return computedValue
}
