export enum PatientBaseVariableId {
  PATIENT_AGE = 'patientAge',
  PATIENT_AGE_WITH_UNIT = 'patientAgeWithUnit',
  PATIENT_FIRSTNAME = 'patientFirstname',
  PATIENT_FAMILYNAME = 'patientFamilyname',
  PATIENT_BIRTH_FIRSTNAME = 'patientBirthFirstname',
  PATIENT_BIRTH_LASTNAME = 'patientBirthLastname',
  PATIENT_USED_FIRSTNAME = 'patientUsedFirstname',
  PATIENT_USED_LASTNAME = 'patientUsedLastname',
  PATIENT_OTHER_FIRST_NAMES = 'patientOtherFirstnames',
  PATIENT_NAME = 'patientName',
  PATIENT_USED_NAME = 'patientUsedName',
  PATIENT_BIRTH_NAME = 'patientBirthName',
  PATIENT_BIRTH_NAME_AND_USED_NAME = 'patientBirthNameAndUsedName',
  PATIENT_SEX = 'patientSex',
  PATIENT_SEX_M_F_I = 'patientSexMaleFemaleIndeterminate',
  PATIENT_BIRTH_PLACE_CODE = 'patientBirthPlaceCode',
  PATIENT_BIRTH_PLACE_CODE_CITY = 'patientBirthPlaceCodeCity',
  PATIENT_ASSIGNED_DOCTOR_ADDRESS = 'patientAssignedDoctorAddress',
  PATIENT_CIVILITY = 'patientCivility',
  PATIENT_CIVILITY_ABBREVIATION = 'patientCivilityAbbreviation',
  PATIENT_ADDRESS = 'postalAddress',
  PATIENT_ADDRESS_LINEBREAKLESS = 'postalAddressLinebreakLess',
  PATIENT_ADDRESS_SHORT = 'postalAddressShort',
  PATIENT_ADDRESS_CITY = 'patientAddressCity',
  PATIENT_ADDRESS_ZIPCODE = 'patientAddressZipCode',
  BIRTH_DATE = 'birthDate',
  BIRTH_DATE_NO_HASH = 'birthDateNoHash',
  PROFESSION = 'profession',
  HEALTH_INSURANCE = 'healthInsuranceNumber',
  SECU_NUMBER = 'secuNumber',
  SECU_NUMBER_REDUCED = 'secuNumberReduced',
  SECU_KEY = 'secuKey',
  PHONE_NUMBER1 = 'phoneNumber1',
  PHONE_NUMBER2 = 'phoneNumber2',
  PHONE_NUMBER1_TRIMMED = 'phoneNumber1Trimmed',
  PHONE_NUMBER2_TRIMMED = 'phoneNumber2Trimmed',
  EMAIL_ADDRESS = 'emailAddress',
  SERIAL = 'serial',
  E_IF_FEMALE = 'eIfFemale',
  THIRD_PRONOUN = 'thirdPronoun',
  POSSESIVE_ADJECTIVE = 'possesiveAdjective',
  PERSONAL_PRONOUN = 'personalPronoun',
  OBJECT_PRONOUN = 'objectPronoun',
  PATIENT_OID = 'patientOid',
  ATTACHED_DOCTOR = 'attachedDoctor',
  ADDRESSING_DOCTOR = 'addressingDoctor',
  RECIPIENT_ASSIGNED_DOCTOR = 'recipientAssignedDoctor', // Dépréciée
}
