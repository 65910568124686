import { restuxCacheFactory } from '../../restux/cache'
import { RestuxResources } from '../../resources'
import { Treatment } from '../../../model/Treatment'
import { restuxPatientCurrentTreatmentsApiConfig } from './api'

const resourceName = RestuxResources.patientCurrentTreatments

const { actions, reducers, sagas } = restuxCacheFactory<Treatment, Treatment>({
  apiConfig: restuxPatientCurrentTreatmentsApiConfig,
  refetchList: true,
  resourceName,
})

export const patientCurrentTreatmentsActions = actions
export const patientCurrentTreatmentsReducers = reducers
export const patientCurrentTreatmentsSagas = sagas
