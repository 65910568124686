import { RootState } from '../../reducers'

export const mailEditorOpenedFromConfigSelector = (state: RootState) =>
  state.ui.bottomPanelMailEditor.openedFrom

export const mailEditorObservationConfigSelector = (state: RootState) =>
  state.ui.bottomPanelMailEditor.includeObservation

export const mailEditorPreselectedMssEmailsConfigSelector = (state: RootState) =>
  state.ui.bottomPanelMailEditor.preselectedMssEmails
