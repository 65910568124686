import classNames from 'classnames'
import { FieldArray, Formik, FormikProps } from 'formik'
import React, { FunctionComponent, useMemo } from 'react'
import { ColorPreset } from '../../../colors'
import { Button, AddressFields, FormikTextInput } from '../../../components/shared'
import {
  getDefaultValues,
  getInitialValues,
  ContactFormFields,
  ContactFormProps,
} from './ContactForm.model'
import styles from './ContactForm.module.scss'
import { ContactValidationSchema } from './ContactForm.validator'
import { BottomPanelContactsContext } from '../../../store/ui/bottomPanelContacts'
import { ProfessionSelect } from '../../../components/user/userForm/GeneralInformationsBlock/ProfessionSelect'
import { ProfessionSpecialitiesSelect } from '../../../components/user/userForm/GeneralInformationsBlock/ProfessionSpecialitiesSelect'

export const ContactForm: FunctionComponent<ContactFormProps> = ({
  actions: Actions,
  readOnly,
  contact,
  onCancel,
  onConfirm,
  onCreatePrivateCopy,
  context,
}) => {
  // Les champs du formulaire sont désactivés pour les contacts public
  const disabled = useMemo(() => readOnly, [readOnly])
  const initialValues = contact ? getInitialValues(contact) : getDefaultValues()
  const handleSubmit = (updates: ContactFormFields) => {
    onConfirm(updates)
  }
  const colorPreset: ColorPreset = 'dark'

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ContactValidationSchema}
      validateOnBlur
      validateOnChange
      validateOnMount
      onSubmit={handleSubmit}
    >
      {(props: FormikProps<ContactFormFields>) => (
        <>
          <form className={styles.form} onSubmit={props.handleSubmit}>
            <div className={styles.fields}>
              <div className={styles.row}>
                <div className="flex flex-wrap gap-y-4 justify-between">
                  <div className={classNames(styles.field, styles.fieldTitle)}>
                    <FormikTextInput
                      fieldName="title"
                      label="Titre"
                      autocomplete={false}
                      disabled={disabled}
                      colorPreset={colorPreset}
                    />
                  </div>
                  <div className={classNames(styles.field, styles.fieldFamilyName)}>
                    <FormikTextInput
                      fieldName="familyName"
                      label="Nom ou raison sociale *"
                      autocomplete={false}
                      disabled={disabled}
                      colorPreset={colorPreset}
                    />
                  </div>
                  <div className={classNames(styles.field, styles.fieldFirstName)}>
                    <FormikTextInput
                      fieldName="firstName"
                      label="Prénom"
                      autocomplete={false}
                      disabled={disabled}
                      colorPreset={colorPreset}
                    />
                  </div>
                  <div className={classNames(styles.field, styles.fieldProfession)}>
                    <ProfessionSelect theme="dark" disabled={disabled} />
                  </div>
                  <div className="invisible">
                    <ProfessionSpecialitiesSelect theme="dark" disabled={disabled} />
                  </div>
                  <div className={classNames(styles.field, styles.fieldIdentifier)}>
                    <FormikTextInput
                      fieldName="ppIdentifier"
                      label="Numéro RPPS"
                      autocomplete={false}
                      disabled={disabled}
                      colorPreset={colorPreset}
                    />
                  </div>
                  <div className={classNames(styles.field, styles.fieldIdentifier)}>
                    <FormikTextInput
                      fieldName="notes"
                      label="Information complémentaires"
                      autocomplete={false}
                      disabled={disabled}
                      colorPreset={colorPreset}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.row}>
                <FieldArray
                  name="organizations"
                  render={(helpers) =>
                    props.values.organizations.map((organization, index) => (
                      <div className={styles.organization} key={organization.id}>
                        <div className={styles.inputSpacing}>
                          <FormikTextInput
                            fieldName={`organizations.${index}.name`}
                            label="Intitulé de l'adresse"
                            autocomplete={false}
                            disabled={disabled}
                            colorPreset={colorPreset}
                          />
                        </div>
                        <div className={styles.inputSpacing}>
                          <FormikTextInput
                            fieldName={`organizations.${index}.emailAddress`}
                            label="Email"
                            autocomplete={false}
                            disabled={disabled}
                            colorPreset={colorPreset}
                          />
                        </div>
                        <div className={styles.inputSpacing}>
                          <FormikTextInput
                            fieldName={`organizations.${index}.phoneNumber`}
                            label="Téléphone"
                            autocomplete={false}
                            disabled={disabled}
                            colorPreset={colorPreset}
                          />
                        </div>
                        <AddressFields
                          type="contact"
                          key={index}
                          fieldNamePrefix={`organizations.${index}`}
                          colorPreset={colorPreset}
                          disabled={disabled}
                        />
                      </div>
                    ))
                  }
                />
              </div>
            </div>
          </form>
          <Actions>
            <div className={styles.actions}>
              <div className={styles.button}>
                <Button
                  type="reset"
                  label={disabled ? 'Fermer' : 'Annuler'}
                  theme="dark"
                  onClick={onCancel}
                />
              </div>
              {!disabled && (
                <div className={styles.button}>
                  <Button
                    type="submit"
                    label="Valider"
                    theme="primary"
                    disabled={!props.isValid}
                    onClick={() => props.handleSubmit()}
                  />
                </div>
              )}
              {contact &&
                !contact?.private &&
                context === BottomPanelContactsContext.PATIENT_CONTACT &&
                readOnly && (
                  <div className={styles.button}>
                    <Button
                      type="submit"
                      label="Créer une copie privée"
                      theme="primary"
                      disabled={!props.isValid}
                      onClick={() => onCreatePrivateCopy(props.values)}
                    />
                  </div>
                )}
            </div>
          </Actions>
        </>
      )}
    </Formik>
  )
}
