import { Patient } from '../../model/Patient'
import { IdentityIdentifier } from '../../store/restux/restux.model'
import { followToDoctolibPatientMapper } from './doctolib.utils'
import { DoctolibPatient } from './doctolib.model'
import { DOCTOLIB_APP_URL } from '../../environment/doctolib'

export const doctolibZipperOpenCalendar = () => {
  if (window.zipper) {
    window.zipper('openCalendar')
  } else {
    window.open(DOCTOLIB_APP_URL, '_blank')
  }
}

export const doctolibZipperOpenPatientHistoric = (patient: Patient) => {
  window.zipper('openPatientHistoric', followToDoctolibPatientMapper(patient))
}

export const doctolibZipperCreateEvent = (patient: Patient) => {
  window.zipper('book', followToDoctolibPatientMapper(patient))
}

export const doctolibZipperUpdateOrCreatePatient = (patient: DoctolibPatient) => {
  window.zipper('updateOrCreatePatient', patient)
}

export const doctolibZipperDeletePatient = (id: IdentityIdentifier) => {
  window.zipper('destroyPatient', `${id}`)
}
