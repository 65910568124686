import { RestuxApiConfig } from '../../../restux/cache/restuxCache.model'
import getApi from '../../../../api/apisauceConfig'
import { PaginatedList } from '../../../../model/Pagination'
import { MailContact } from '../../../../model/Contact'

export const restuxMailContactsApiConfig: RestuxApiConfig<MailContact, MailContact> = {
  url: '/contacts',
  getPaginatedItems: (route) =>
    getApi().get<PaginatedList<MailContact>>(route, { groupByMssEmail: true }),
  updateItem: (route, item) => getApi().put<MailContact>(route, item),
}
