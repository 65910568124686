import {
  DocumentBaseVariableId,
  MedicalEventBaseVariableId,
  TreatmentBaseVariableId,
  OtherBaseVariableId,
  PatientBaseVariableId,
  ModuleDataBaseVariableId,
} from './normalType'
import { HTMLTypeBaseVariableId } from './htmlType'
import { SlateTypeBaseVariableId } from './farteType/index'

export const NormalTypeBaseVariableId = {
  ...DocumentBaseVariableId,
  ...TreatmentBaseVariableId,
  ...MedicalEventBaseVariableId,
  ...OtherBaseVariableId,
  ...PatientBaseVariableId,
  ...ModuleDataBaseVariableId,
}

export type NormalTypeBaseVariableIdType = ValueOf<typeof NormalTypeBaseVariableId>

export const BaseVariableId = {
  ...NormalTypeBaseVariableId,
  ...HTMLTypeBaseVariableId,
  ...SlateTypeBaseVariableId,
} as const

export type BaseVariableIdType = ValueOf<typeof BaseVariableId>
