import getApi from '../../api/apisauceConfig'
import { deserializePatientResponse, serializePatient } from './mapper/patients.mapper'
import { ApiPatientResource, PatientMutationPayload } from './mapper/patients.model'

export const updatePatient = (
  id: number,
  payload: PatientMutationPayload,
  params?: Record<string, any>,
) =>
  getApi()
    .put<ApiPatientResource>(`/patients/${id}`, serializePatient(payload), {
      params,
    })
    .then(deserializePatientResponse)
