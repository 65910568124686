import { FC } from 'react'
import { PatientIdentityBlockProps } from './PatientIdentityBlock.model'
import { Sex, Title } from '../../../../model/Patient'
import {
  FormBlock,
  FieldWrapper,
  RadioGroup,
  Radio,
  FormikTextInput,
  FormikDateInput,
} from '../../../shared'
import { DisabledINSITooltipWrapper } from '../DisabledINSITootipWrapper'
import { PatientBirthPlaceCodeBlock } from '../PatientBirthPlaceCodeBlock'
import { mapSexToDefaultTitle, toLocaleUpperCase } from '../PatientForm.utils'
import { PatientFormFields, SEX_FORM_LABELS, TITLE_FORM_LABELS } from '../PatientForm.model'
import { useFormikContext } from 'formik'
import { useDisableInsiFields } from '../useDisableInsiFields.hook'
import { useResetINSIStatusHook } from '../useResetINSIStatus.hook'
import { isINSIValidated } from '../../../../misc/patient.utilities'

export const PatientIdentityBlock: FC<PatientIdentityBlockProps> = ({ patient }) => {
  const formikProps = useFormikContext<PatientFormFields>()
  const disableInsiFields = useDisableInsiFields()
  const resetINSIStatus = useResetINSIStatusHook()

  return (
    <FormBlock icon="profile" label="Patient">
      <FieldWrapper>
        <DisabledINSITooltipWrapper display={isINSIValidated(formikProps.values.identityStatus)}>
          <FieldWrapper errorMessage={formikProps.submitCount === 0 ? '' : formikProps.errors.sex}>
            <RadioGroup
              name="sex"
              label="Sexe *"
              disabled={disableInsiFields}
              onChange={(event) => {
                formikProps.setValues(
                  {
                    ...formikProps.values,
                    sex: Sex[event.target.value],
                    title: formikProps.getFieldMeta('title').touched
                      ? formikProps.values.title
                      : mapSexToDefaultTitle[event.target.value],
                  },
                  true,
                )
                resetINSIStatus()
              }}
              value={formikProps.values.sex}
            >
              <Radio label={SEX_FORM_LABELS[Sex.MALE]} value={Sex.MALE} />
              <Radio label={SEX_FORM_LABELS[Sex.FEMALE]} value={Sex.FEMALE} />
              <Radio label={SEX_FORM_LABELS[Sex.UNKNOWN]} value={Sex.UNKNOWN} />
            </RadioGroup>
          </FieldWrapper>
        </DisabledINSITooltipWrapper>
        <RadioGroup
          name="title"
          label="Civilité"
          onChange={(event) => {
            formikProps.handleChange(event)
            formikProps.setFieldTouched('title', true, false)
          }}
          value={formikProps.values.title}
        >
          <Radio label={TITLE_FORM_LABELS[Title.MR]} value={Title.MR} />
          <Radio label={TITLE_FORM_LABELS[Title.MS]} value={Title.MS} />
          <Radio label={TITLE_FORM_LABELS[Title.NONE]} value={Title.NONE} />
        </RadioGroup>
      </FieldWrapper>
      <div className="flex flex-row justify-between gap-4">
        <DisabledINSITooltipWrapper display={isINSIValidated(formikProps.values.identityStatus)}>
          <FormikTextInput
            fieldName="birthLastName"
            label="Nom de naissance"
            autocomplete={false}
            colorPreset="light"
            transform={toLocaleUpperCase}
            disabled={disableInsiFields}
            onChange={resetINSIStatus}
            required
          />
        </DisabledINSITooltipWrapper>
        <FormikTextInput
          fieldName="birthFirstName"
          label="Premier prénom de naissance"
          autocomplete={false}
          colorPreset="light"
          required
          onChange={resetINSIStatus}
        />
      </div>
      <div className="flex flex-row justify-between gap-4">
        <DisabledINSITooltipWrapper display={isINSIValidated(formikProps.values.identityStatus)}>
          <FormikTextInput
            fieldName="otherFirstNames"
            label="Prénoms de naissance"
            autocomplete={false}
            colorPreset="light"
            disabled={disableInsiFields}
            onChange={resetINSIStatus}
          />
        </DisabledINSITooltipWrapper>
        <DisabledINSITooltipWrapper display={isINSIValidated(formikProps.values.identityStatus)}>
          <FormikDateInput
            fieldName="birthDate"
            label="Date de naissance"
            placeholder="jj/mm/aaaa"
            autocomplete={false}
            disabled={disableInsiFields}
            colorPreset="light"
            required
            onChange={resetINSIStatus}
          />
        </DisabledINSITooltipWrapper>
      </div>
      <div className="flex flex-row justify-between gap-4">
        <FormikTextInput
          fieldName="usedLastName"
          label="Nom utilisé"
          autocomplete={false}
          colorPreset="light"
        />
        <FormikTextInput
          fieldName="usedFirstName"
          label="Prénom utilisé"
          autocomplete={false}
          colorPreset="light"
        />
      </div>
      <FormikTextInput fieldName="profession" label="Profession" colorPreset="light" />
      <PatientBirthPlaceCodeBlock patient={patient} disabled={disableInsiFields} />
    </FormBlock>
  )
}
