import { connect } from 'react-redux'
import {
  currentUserSelector,
  enabledFeaturesPreferencesSelector,
  loggedUserSelector,
} from '../../../../store/domain/me'
import { isLoading } from '../../../../store/http/index'
import { RootState } from '../../../../store/index'
import { setUsurpedUser } from '../../../../store/ui/adminUsers/index'
import { NavigationMenuDispatchProps, NavigationMenuStoreProps } from './NavigationMenu.model'
import { NavigationMenu } from './NavigationMenu.component'

const mapStateToProps = (state: RootState): NavigationMenuStoreProps => ({
  loading: isLoading(state),
  currentUser: currentUserSelector(state),
  loggedUser: loggedUserSelector(state),
  userEnabledFeatures: enabledFeaturesPreferencesSelector(state),
})

const dispatchProps: NavigationMenuDispatchProps = {
  setUsurpedUser,
}

const withConnect = connect(mapStateToProps, dispatchProps)(NavigationMenu)

export { withConnect as NavigationMenu }
