import { ReactComponent as EventWithoutDocsIcon } from '../../../../../../assets/images/transversalNavigation/working-people.svg'
import styles from '../PatientTransversalNavigationMedicalEventNoResults.module.scss'

export const PatientTransversalNavigationMedicalEventNoFilterResults = () => (
  <div className={styles.noResultsContainer}>
    <div className={styles.noResultsContent}>
      <EventWithoutDocsIcon />
      <div className={styles.noResultsDescription}>
        <h1>Aucun document ne correspond aux filtres pour cet événement</h1>
      </div>
    </div>
  </div>
)
