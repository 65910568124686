import React, { FunctionComponent, useMemo } from 'react'
import { parseISO } from 'date-fns'
import { formatFr, getDistanceToNowInDays } from '../../../../misc/date.utilities'
import styles from './TimelineHeader.module.scss'

interface TimelineHeaderProps {
  date: string
}

export const TimelineHeader: FunctionComponent<TimelineHeaderProps> = ({ date }) => {
  const eventDate = useMemo(() => parseISO(date), [date])
  return (
    <div className={styles.TimelineHeader}>
      <div className={styles.label} title={formatFr(eventDate, 'dd/MM/yyyy HH:mm:ss')}>
        {`${getDistanceToNowInDays(eventDate)} - ${formatFr(eventDate, 'd MMM yyyy')}`}
      </div>
    </div>
  )
}
