import { FollowTypes, SORT_DIRECTION } from '@follow/cdk'

export type AuditFields = FollowTypes.Resource.AuditFields
export type PermissionFields = FollowTypes.Resource.PermissionFields
export { SORT_DIRECTION }

export enum FwResourceType {
  MedicalEvent = 'medical_event',
  DocumentTemplates = 'document_template',
  Patient = 'patient',
}
