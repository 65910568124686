import React, { FunctionComponent, ReactNode, useState } from 'react'
import classNames from 'classnames/bind'

import { IconButton } from '../../buttons'

import styles from './BannerWrapper.module.scss'
const classNamesCx = classNames.bind(styles)

interface BannerWrapperProps {
  children?: ReactNode
  criticity: 'error' | 'warning'
  closable?: boolean
  onClose?: () => void
}

export const BannerWrapper: FunctionComponent<BannerWrapperProps> = ({
  criticity,
  closable,
  onClose,
  children,
}) => {
  const [closed, setClosed] = useState(false)
  return !closed ? (
    <div className={classNamesCx({ banner: true, [criticity]: true })}>
      <>
        {children}
        {closable && (
          <div className={styles.button}>
            <IconButton
              onClick={() => {
                setClosed(true)
                if (onClose) {
                  onClose()
                }
              }}
              icon="cross"
              size="nano"
              theme="transparent"
            />
          </div>
        )}
      </>
    </div>
  ) : null
}
