import getApi from '../../api/apisauceConfig'
import { DocumentFormat } from '../../model/Document'
import { RenderableDocument } from './mapper/documents.model'

export const renderPdfFromDocuments = (
  documents: RenderableDocument[],
  format: DocumentFormat = 'pdf',
) =>
  getApi().post<Blob>(
    '/documents/render',
    { documents },
    { responseType: 'blob', params: { format } },
  )
