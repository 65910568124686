import getApi from '../../../../api'

import { RestuxApiConfig } from '../../../restux/cache/restuxCache.model'

import { PaginatedList } from '../../../../model/Pagination'
import { TagType } from '../../../../model/Tag'
import { FwResourceType } from '../../../../model/Resource'

export const restuxTagsApiConfig: RestuxApiConfig<TagType, TagType> = {
  url: '/tags',
  getPaginatedItems: (route) => getApi().get<PaginatedList<TagType>>(route),
  getItem: (route) => getApi().get<TagType>(route),
  createItem: (route, item) => getApi().post<TagType>(route, item),
  updateItem: (route, item) => getApi().put<TagType>(route, item),
  deleteItem: (route) => getApi().delete<void>(route),
}

export const getUsedTags = ({
  page,
  limit,
  usedInResource,
}: {
  page: number
  limit: number
  usedInResource: FwResourceType
}) => getApi().get<PaginatedList<TagType>>('/tags', { page, limit, usedInResource })
