import { takeEvery, put, race, take } from 'redux-saga/effects'
import {
  setBottomPanelOptions,
  BottomPanelComponentType,
  BottomPanelActionTypes,
} from '../bottomPanel'

import { UiBottomPanelManualPrescriptionActionTypes } from './bottomPanelManualPrescription.model'
import { bottomPanelManualPrescriptionActions } from './bottomPanelManualPrescription.actions'
import { domainEditorActions } from '../../domain/editor/editor.actions'

function* addManualPrescriptionSagaWorker({
  onAddManualPrescription,
}: ReturnType<typeof bottomPanelManualPrescriptionActions.addManualPrescription>) {
  yield put(
    setBottomPanelOptions({
      title: `Ajouter une prescription libre`,
      open: true,
      componentType: BottomPanelComponentType.AddManualPrescription,
    }),
  )
  const {
    selected,
  }: {
    selected?: ReturnType<typeof bottomPanelManualPrescriptionActions.submitManualPrescription>
  } = yield race({
    canceled: take(BottomPanelActionTypes.SET_BOTTOM_PANEL_OPTIONS),
    selected: take(UiBottomPanelManualPrescriptionActionTypes.SUBMIT_MANUAL_PRESCRIPTION),
  })
  if (selected) {
    yield put(
      domainEditorActions.setPendingVariables({
        manualPrescriptions: [selected.manualPrescription],
      }),
    )
    onAddManualPrescription(selected.manualPrescription)
    yield put(
      setBottomPanelOptions({
        open: false,
        componentType: BottomPanelComponentType.AddManualPrescription,
      }),
    )
  }
}

function* addManualPrescriptionSagaWatcher() {
  yield takeEvery(
    UiBottomPanelManualPrescriptionActionTypes.ADD_MANUAL_PRESCRIPTION,
    addManualPrescriptionSagaWorker,
  )
}

export const bottomPanelManualPrescriptionSagas = {
  addManualPrescriptionSagaWatcher,
}
