import { MedicalEvent } from '../../../../model/MedicalEvent'
import { User } from '../../../../model/User'
import { PageList } from '../../../../store/restux/ui/restuxUi.model'
import { medicalEventDomainActions } from '../../../../store/domain/medicalEvents/index'
import { Team } from '../../../../model/Team'
import { DocumentCategory } from '../../../../model/DocumentCategory'
import { Patient } from '../../../../model/Patient'

export const TIMELINE_PAGE_SIZE = 5

export interface PatientTimelineStoreProps {
  currentUser: User | null
  userTeams: Array<Team>
  timeline: PageList<MedicalEvent>
  teams: Team[]
  documentCategories: Array<DocumentCategory>
}

export interface PatientTimelineWithProps {
  currentUser: User
  patient: Patient
  timeline: PageList<MedicalEvent>
}

export interface PatientTimelineDispatchProps {
  onPinMedicalEvent: typeof medicalEventDomainActions.pinMedicalEvent
  duplicateMedicalEventDocuments: typeof medicalEventDomainActions.duplicateMedicalEventDocuments
  changeOwner: typeof medicalEventDomainActions.changeOwner
}

export type PatientTimelineProps = PatientTimelineStoreProps &
  PatientTimelineWithProps &
  PatientTimelineDispatchProps
