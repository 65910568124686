import { useGetPatient } from '../../hooks/queries/patients'
import { useParams } from 'react-router-dom'
import { isDefined } from '@follow/cdk'

export const useCurrentPatient = () => {
  const currentPatientId = useCurrentPatientId()
  const {
    query: { data, isLoading },
  } = useGetPatient({
    enabled: currentPatientId !== null,
    id: currentPatientId!,
  })
  return { currentPatient: data, isLoadingCurrentPatient: currentPatientId !== null && isLoading }
}

export const useCurrentPatientId = () => {
  let { patientId } = useParams()
  return isDefined(patientId) ? Number.parseInt(patientId) : null
}
