import { UiQuoteline } from '../../../../components/ccam/QuoteLineForm/QuoteLineForm.model'

export interface ApiQuoteLinePayload {
  codeActivite: number | null
  codePhase: number | null
  codeGrille: number | null
  codeModificateurs: string[]
  codeAssociationNonPrevue: number | null
  additionalFee: number | null
}

export const serializeQuoteLine = (quoteLinePayload: UiQuoteline): ApiQuoteLinePayload => {
  const { activity, phase, rateGrid, modifiers, association, additionalFee } = quoteLinePayload

  return {
    codeActivite: Number(activity.value),
    codePhase: Number(phase.value),
    codeGrille: Number(rateGrid.value),
    codeModificateurs: modifiers,
    codeAssociationNonPrevue: association.value.length ? Number(association.value) : null,
    additionalFee: additionalFee,
  }
}
