import { Drug } from '../../../model/Drug'
import { RestuxResources } from '../../resources'
import { toUpperSnakeCase } from '../../restux/restux.utilities'

const RESOURCE_PREFIX = `@${toUpperSnakeCase(RestuxResources.drugs)}`

export const domainDrugsActionTypes = {
  GET_DETAILS: `${RESOURCE_PREFIX}/GET_DETAILS`,
  STORE_DETAILS: `${RESOURCE_PREFIX}/STORE_DETAILS`,
  CLEAR_DETAILS: `${RESOURCE_PREFIX}/CLEAR_DETAILS`,
}

export interface DrugsState {
  details: Drug | null
}
