import { uiSagasFactory } from '../../restux/ui/restuxUiSagas.factory'
import { bottomPanelPaginationSelector as paginationSelector } from './bottomPanelDocumentTemplates.selectors'

const uiSagas = uiSagasFactory({
  resourceName: 'documentTemplate',
  identifier: 'bottomPanel',
  paginationSelector,
})

export const bottomPanelDocumentTemplatesSagas = { ...uiSagas }
