import { DomainEditorActionTypes, PendingVariables } from './editor.model'

export const domainEditorActions = {
  setPendingVariables: (pendingVariables: Partial<PendingVariables>) => ({
    type: DomainEditorActionTypes.SET_PENDING_VARIABLES,
    pendingVariables,
  }),
  clearPendingVariables: () => ({
    type: DomainEditorActionTypes.CLEAR_PENDING_VARIABLES,
  }),
}
