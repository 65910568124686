import { TrackingService, FwTrackingEvent } from './tracking.service'

export const withTrackingEvent = (event: FwTrackingEvent, option: object = {}) =>
  function <T extends Function>(wrapped: T): T {
    return (function (this: any) {
      /* *** ANALYTICS *** */
      TrackingService.sendEvent(event, option)

      return wrapped.apply(this as any, arguments)
    } as unknown) as T
  }
