import React, { useState } from 'react'
import { Colors } from '../../../../colors'
import { Icon } from '../../../shared'
import { ItemSelectProps, SelectItem } from './ItemSelect.model'
import styles from './ItemSelect.module.scss'

const DEFAULT_SHOW_NUMBER = 5

export function ItemSelect<T extends SelectItem>({ title, items, renderItem }: ItemSelectProps<T>) {
  const [showCount, setShowCount] = useState(DEFAULT_SHOW_NUMBER)
  const [showMore, setShowMore] = useState(false)

  const handleShowMore = (show: boolean) => {
    setShowMore(show)
    if (show) {
      setShowCount(items.length)
    } else {
      setShowCount(DEFAULT_SHOW_NUMBER)
    }
  }

  return (
    <div className={styles.itemSelect}>
      <label>{title}</label>
      <div className={styles.itemsContainer}>
        {items.slice(0, showCount).map((item) => (
          <div className={styles.item} key={item.id}>
            {renderItem(item)}
          </div>
        ))}
      </div>
      {items.length > DEFAULT_SHOW_NUMBER && (
        <div className={styles.showMore} onClick={() => handleShowMore(!showMore)}>
          <span className={styles.label}>{showMore ? 'Voir moins' : 'Voir tous'}</span>
          <Icon
            icon="chevron"
            color={Colors.brandPrimary}
            size="femto"
            rotate={showMore ? 180 : 0}
          />
        </div>
      )}
    </div>
  )
}
