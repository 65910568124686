import { FunctionComponent } from 'react'
import { ValidationModal } from '../../components/shared/modal/ValidationModal/ValidationModal.component'
import { AddDatamatrixModalProps } from './AddDatamatrix.model'
import { DatamatrixReader } from './DatamatrixReader'

export const AddDatamatrixModal: FunctionComponent<AddDatamatrixModalProps> = ({
  isUnmounting,
  addDatamatrix,
  onCancel,
}) => {
  return (
    <ValidationModal
      isUnmounting={isUnmounting}
      icon={undefined}
      title="Présentez le datamatrix de la boîte de vaccin à votre webcam"
      onSubmit={() => {
        onCancel()
      }}
      confirmLabel="Fermer"
      confirmTheme="invalid"
    >
      <DatamatrixReader addDatamatrix={addDatamatrix} />
    </ValidationModal>
  )
}
