import getApi from '../../../../api/apisauceConfig'
import { DocumentInstance } from '../../../../model/DocumentInstance'
import { MedicalEvent } from '../../../../model/MedicalEvent'

export const lockMedicalEvent = (id: number, locked: boolean, documentIds: Array<number>) =>
  getApi().post<DocumentInstance>(`medical_events/${id}/lock`, {
    locked,
    documentIds,
  })

export const changeMedicalEventOwner = (medicalEventId: number, newOwnerId: string) =>
  getApi().post<MedicalEvent>(`medical_events/${medicalEventId}/switch_owner`, {
    newOwnerUuid: newOwnerId,
  })

export const downloadRenderHistoryFile = (id: string) =>
  getApi().get<Blob>(`/document_rendering_histories/${id}/download`, {}, { responseType: 'blob' })
