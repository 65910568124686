import React, { FunctionComponent, useState } from 'react'
import { useDebounce } from 'react-use'
import { Input, GridLayout } from '../../shared'
import { DocumentLayoutFormProps } from './DocumentLayoutForm.model'
import styles from './DocumentLayoutForm.module.scss'

export const DocumentLayoutForm: FunctionComponent<DocumentLayoutFormProps> = ({
  documentLayout,
  onChange,
}) => {
  const [title, setTitle] = useState<string>(documentLayout.title)

  useDebounce(
    () => {
      onChange({ title })
    },
    500,
    [title],
  )

  return (
    <form className={styles.form}>
      <GridLayout columns={2} gap="medium" padding="medium">
        <Input
          type="text"
          colorPreset="dark"
          name="title"
          label="Titre"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
          disabled={!documentLayout.isEditable}
        />
      </GridLayout>
    </form>
  )
}
