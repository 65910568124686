import React from 'react'
import { UserAvatarProps } from './UserAvatar.model'
import classNames from 'classnames'
import styles from './UserAvatar.module.scss'
import { Icon } from '../../shared'
import { getCssVariable } from '../../../design-system/utils'

export const UserAvatar = React.forwardRef<HTMLDivElement, UserAvatarProps>(
  (
    {
      familyName,
      firstName,
      icon,
      theme = 'primary',
      size = 'large',
      selected = false,
      showStar = false,
      testId,
      onClick,
    },
    ref,
  ) => (
    <div
      className={classNames(styles.userAvatar, styles[theme], styles[size], {
        [styles.selectable]: onClick ? true : false,
        [styles.selected]: selected,
      })}
      onClick={onClick}
      ref={ref}
      data-test-id={testId}
    >
      {!icon && familyName && firstName ? (
        <span className={styles.initials}>
          {firstName.charAt(0).toUpperCase()}
          {familyName.charAt(0).toUpperCase()}
        </span>
      ) : (
        icon && (
          <Icon
            icon={icon}
            size="micro"
            color={
              theme === 'primary'
                ? getCssVariable('shades', 'white')
                : getCssVariable('shades', 'shade4')
            }
          />
        )
      )}
      {showStar && (
        <div className={styles.star}>
          <Icon icon="starFull" size="nano" color={getCssVariable('primary', 'default')} />
        </div>
      )}
    </div>
  ),
)
