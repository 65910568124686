import { RootState } from '../../reducers'

export const inUseContactSelector = (state: RootState) => {
  const inUseId = state.ui.bottomPanelContacts.inUse.inUseId
  if (!inUseId) {
    return undefined
  }
  return state.cache.contacts.details[inUseId] || undefined
}

export const readOnlyContactSelector = (state: RootState) =>
  state.ui.bottomPanelContacts.contactPanel.readOnly

export const contextContactSelector = (state: RootState) =>
  state.ui.bottomPanelContacts.contactPanel.context

export const inUseLoadingContactSelector = (state: RootState) =>
  state.ui.bottomPanelContacts.inUse.loadingState

export const isInCreatePrivateCopyModeSelector = (state: RootState) =>
  state.ui.bottomPanelContacts.contactPanel.isInCreatePrivateCopyMode
