const all = ['patientCodedInformations']
const detail = (id: string) => [...all, id]
const searchByName = (name: string) => [...all, 'search', name]
const searchByGroup = (groupId: string) => [all, 'groupdId', groupId]

export const codedInformationsKeys = {
  all,
  detail,
  searchByName,
  searchByGroup,
}
