import React, { FunctionComponent } from 'react'
import { Colors } from '../../../../colors'
import { Heading } from '../../fonts'
import { Icon } from '../../Icon'
import { FormHeadingProps } from './FormHeading.model'

export const FormHeading: FunctionComponent<FormHeadingProps> = ({ headings, disabled }) => (
  <div>
    {headings.map(({ icon, label, selected, size = 5, onSelect }, index) => (
      <div
        className={`inline-flex items-center first:rounded-l-md last:rounded-r-md h-11 px-2 py-3 ${
          onSelect ? 'cursor-pointer' : 'cursor-default'
        } ${selected ? 'bg-primary-default' : 'bg-shades-7'}`}
        key={index}
        onClick={disabled ? undefined : onSelect}
      >
        {icon && (
          <div className="mr-2">
            <Icon icon={icon} color={selected ? Colors.dsWhite : Colors.dsDark1} size="nano" />
          </div>
        )}
        <Heading size={size} theme={selected ? 'light' : 'dark'}>
          {label}
        </Heading>
      </div>
    ))}
  </div>
)
