import { RenderMode, VariableDataSerializer } from '@follow/cdk'
import { useQuery } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { fetchDocumentVariableData } from '../../../store/renderer/api'
import { documentKeys } from './documents.keys'

interface DocumentVariableDataProps {
  docId: number
}

export const useGetDocumentVariableData = ({ docId }: DocumentVariableDataProps) => {
  const queryKey = documentKeys.variable_data(docId)

  const queryResult = useQuery(queryKey, async () => {
    const { data, ok } = await fetchDocumentVariableData(docId, RenderMode.PREVIEW)

    if (!data || !ok) {
      throw new UserFriendlyError(
        'error',
        `Impossible de récupérer les variables du document ${docId}`,
        `Une erreur technique s'est produite`,
      )
    }
    const variableData = VariableDataSerializer.deserialize(data.variables)

    return variableData
  })

  return { query: queryResult }
}
