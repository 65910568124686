import { MultiSelectInputProps } from './MultiSelectInput.model'
import { useMultiSelect, useSelectArrowNavigation } from '../../../../hooks/utils'
import classNames from 'classnames'
import { getSelectInputTheme } from '../SelectInput/SelectInput.component'
import { getCssVariable } from '../../../../design-system/utils'
import { Icon } from '../../Icon'
import { DropdownOverlay } from '../../dropdown'
import { defaultRenderMultiSelectOption } from '../../search/MultiSelectSearch/MultiSelectSearch.component'
import { Tag } from '../../tag'
import { useCallback, useMemo } from 'react'
import { addTestIdSuffix } from '../../../../misc/testId.utilities'

export function MultiSelectInput<T>({
  value,
  options,
  icon,
  title,
  placeholder,
  valid,
  colorPreset = 'light',
  disabled = false,
  readonly = false,
  mode = 'wrap',
  testId,
  onSelect,
  emitChange,
  areElementsEquals,
  renderOption = defaultRenderMultiSelectOption,
}: MultiSelectInputProps<T>) {
  const {
    open,
    showTags,
    textValue,
    computedOptions,
    inputRef,
    containerRef,
    handleOpen,
    handleSelect,
    handleTextInput,
  } = useMultiSelect(value, options, mode, onSelect, areElementsEquals, emitChange)

  const [scrollRef, hoveredRef, hoveredIndex, handleKeyDown] = useSelectArrowNavigation(
    computedOptions,
    handleSelect,
  )

  const isFakePlaceholder = useMemo(() => value.length > 0 && !showTags, [value, showTags])
  const showFakePlaceholder = useMemo(() => isFakePlaceholder && !open, [isFakePlaceholder, open])

  const colorTheme = getSelectInputTheme(disabled)[colorPreset]
  const disableInteraction = disabled || readonly

  const toggleOpen = useCallback(() => {
    handleOpen(!open)
    inputRef.current?.focus()
  }, [handleOpen, inputRef, open])
  return (
    <div
      data-test-id={testId}
      className={classNames(
        colorTheme.background,
        'relative min-h-14 h-fit border-b w-full rounded-t group',
        {
          'cursor-not-allowed': disabled,
          'cursor-pointer': !disableInteraction,
          'max-h-14': mode === 'wrap',
        },
        {
          [`${colorTheme.border} focus-within:border-primary-default`]: valid === undefined,
          'border-status-valid': valid === true,
          'border-status-invalid': valid === false,
        },
      )}
      onBlur={() => handleOpen(false)}
    >
      <div
        className={classNames('pl-2 pr-3 w-full h-full flex items-center', {
          'pb-2': mode === 'multiline',
        })}
        onClick={disableInteraction ? undefined : toggleOpen}
      >
        {icon && (
          <div className="mr-2">
            <Icon icon={icon} size="nano" color={getCssVariable('shades', 'shade4')} />
          </div>
        )}
        <div className="w-full max-w-full min-w-0 h-full">
          {title && (
            <span className={classNames('text-xs font-medium', colorTheme.title)}>{title}</span>
          )}
          <div
            ref={containerRef}
            data-test-id={addTestIdSuffix('container', testId)}
            className={classNames('flex min-w-0 max-w-full w-full h-full overflow-x-hidden', {
              'flex-wrap gap-2': mode === 'multiline',
              'space-x-2': mode === 'wrap',
            })}
          >
            {showTags && (
              <>
                {value.map((opt, index) => (
                  <Tag
                    key={index}
                    color={opt.color}
                    ellipsis={false}
                    onClose={
                      disableInteraction
                        ? undefined
                        : (event) => {
                            event.stopPropagation()
                            handleSelect(opt)
                          }
                    }
                  >
                    {opt.label}
                  </Tag>
                ))}
              </>
            )}
            <input
              ref={inputRef}
              value={textValue}
              readOnly={!open}
              data-test-id={addTestIdSuffix('input', testId)}
              onChange={(event) => handleTextInput(event.currentTarget.value)}
              className={classNames(
                `my-auto appearance-none bg-transparent ml-0.5 mt-0.5 grow min-w-1/5 bg-color-pink text-sm font-semibold cursor-inherit`,
                isFakePlaceholder
                  ? `placeholder:text-sm placeholder:font-bold ${colorTheme.wrappedLabel}`
                  : colorTheme.placeholderClass,
                colorTheme.color,
              )}
              placeholder={
                showFakePlaceholder ? `${value.length} éléments sélectionnés` : placeholder
              }
              onBlur={() => {
                handleOpen(false)
              }}
              onKeyDown={handleKeyDown}
            />
          </div>
        </div>
        <Icon icon="chevron" size="femto" color={colorTheme.arrow} rotate={open ? 180 : 0} />
      </div>
      {computedOptions.length > 0 && (
        <DropdownOverlay display={open} scrollRef={scrollRef}>
          {computedOptions.map((item, index) => (
            <div
              key={index}
              onMouseDown={(event) => {
                handleSelect(item)
                event.preventDefault()
              }}
              ref={hoveredIndex === index ? hoveredRef : undefined}
            >
              {renderOption(item, value, hoveredIndex === index)}
            </div>
          ))}
        </DropdownOverlay>
      )}
    </div>
  )
}
