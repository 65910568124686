import { ApiResponse } from 'apisauce'
import { PaginatedList } from '../../../model/Pagination'
import { ApiMedicalEventResource } from './medicalEvents.model'
import { MedicalEvent } from '../../../model/MedicalEvent'
import { deserializeDocumentInstanceListItem } from '../../documentInstances/mapper/documentInstances.mapper'

export function deserializeMedicalEventPaginatedResponse(
  response: ApiResponse<PaginatedList<ApiMedicalEventResource>>,
): ApiResponse<PaginatedList<MedicalEvent>> {
  const { data, ...responseRest } = response
  // Error case => no deserialization
  if (!response.ok || !data) {
    return { ...responseRest }
  }
  return {
    ...responseRest,
    data: { ...data, items: data.items.map(deserializeMedicalEventListItem) },
  }
}

export function deserializeMedicalEventListItem(item: ApiMedicalEventResource): MedicalEvent {
  return {
    ...item,
    category: item.medicalEventCategory.id,
    documents: item.documents.map(deserializeDocumentInstanceListItem),
  }
}

export function deserializeMedicalEventResponse(response: ApiResponse<ApiMedicalEventResource>) {
  const { data, ...responseRest } = response
  // Error case => no deserialization
  if (!response.ok || !data) {
    return { ...responseRest, data: null }
  }
  return {
    ...responseRest,
    data: deserializeMedicalEvent(data),
  }
}

export function deserializeMedicalEvent(item: ApiMedicalEventResource): MedicalEvent {
  return {
    ...item,
    category: item.medicalEventCategory.id,
    documents: item.documents.map(deserializeDocumentInstanceListItem),
    owner: {
      ...item.owner,
      practiceLocation: item.owner?.practiceLocation || null,
    },
  }
}
