import { activePrinciplesDomainActions } from './activePrinciples.actions'
import { searchActivePrinciples } from './api'
import { call, put } from '@redux-saga/core/effects'
import { addError } from '../../message'
import { ActivePrinciplesDomainActionsTypes } from './activePrinciples.model'
import { debounce } from 'redux-saga/effects'

function* searchActivePrinciplesWorker({
  search,
}: ReturnType<typeof activePrinciplesDomainActions.search>) {
  const response = yield call(searchActivePrinciples, search)
  const { ok, status, data } = response
  if (ok && 200 === status) {
    yield put(activePrinciplesDomainActions.setActivePrinciplesList(data))
  } else {
    yield put(
      addError(
        `Une erreur s'est produite`,
        `Un problème est survenu en recherchant le principe actif, veuillez réessayer`,
      ),
    )
  }
}

function* searchActivePrinciplesWatcher() {
  yield debounce(500, ActivePrinciplesDomainActionsTypes.SEARCH, searchActivePrinciplesWorker)
}

export const activePrinciplesSagas = {
  searchActivePrinciplesWatcher,
}
