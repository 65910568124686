import { connect } from 'react-redux'
import { RootState } from '../../../store'
import { healthcareSettingsSelector } from '../../../store/domain/dmp'
import { EfficienceDmpUploadModal } from './EfficienceDmpUploadModal.component'
import { EfficienceDmpUploadModalStoreProps } from './EfficienceDmpUploadModal.model'
import { withUnmountTransition } from '../../../misc/withUnmountTransition.hoc'

export const mapStateToProps = (state: RootState): EfficienceDmpUploadModalStoreProps => ({
  healthcareSettings: healthcareSettingsSelector(state),
})

const withUnmountHoc = withUnmountTransition(EfficienceDmpUploadModal)
const withConnect = connect(mapStateToProps)(withUnmountHoc)
export { withConnect as EfficienceDmpUploadModal }
