import { createReducer } from 'redux-starter-kit'
import { domainPosologyActions } from './posology.actions'
import { domainPosologyActionTypes, PosologyState } from './posology.model'

const INITIAL_STATE: PosologyState = {
  preview: null,
}

const ACTION_HANDLERS = {
  [domainPosologyActionTypes.SET_PREVIEW]: (
    state: PosologyState = INITIAL_STATE,
    { preview }: ReturnType<typeof domainPosologyActions.setPreview>,
  ) => ({
    ...state,
    preview,
  }),
  [domainPosologyActionTypes.CLEAR_PREVIEW]: (state: PosologyState = INITIAL_STATE) => ({
    ...state,
    preview: null,
  }),
}

export const posologyReducer = createReducer(INITIAL_STATE, ACTION_HANDLERS)
