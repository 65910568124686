import { AnyAction } from 'redux'
import createReducer from '../reducers/createReducer'
import { ReduxMessages, MessageActionTypes } from './message.model'

const INITIAL_STATE: ReduxMessages = {
  messages: [],
}

const ACTION_HANDLERS = {
  [MessageActionTypes.ADD_MESSAGE]: (
    { messages, ...state }: ReduxMessages = INITIAL_STATE,
    action: AnyAction,
  ) => {
    // Garantie de l'unicité des messages par id
    if (!messages.find(({ id }) => id === action.message.id)) {
      messages = [...messages, action.message]
    }
    return {
      ...state,
      messages,
    }
  },
  [MessageActionTypes.CLEAR_MESSAGE]: (
    state: ReduxMessages = INITIAL_STATE,
    action: AnyAction,
  ) => ({
    ...state,
    messages: state.messages.filter((error) => error.id !== action.id),
  }),
}

export const messageReducer = createReducer(INITIAL_STATE, ACTION_HANDLERS)
