import { FunctionComponent } from 'react'
import {
  DisabledINSITooltipWrapperProps,
  TOOLTIP_CONTENT_DISABLED_INSI_FIELDS,
} from './DisabledINSITooltipWrapper.model'
import { TooltipWrapper } from '../../../shared'

export const DisabledINSITooltipWrapper: FunctionComponent<DisabledINSITooltipWrapperProps> = ({
  display,
  children,
}) => {
  return (
    <TooltipWrapper
      pointerDirection="top"
      content={TOOLTIP_CONTENT_DISABLED_INSI_FIELDS}
      display={display}
      size="small"
    >
      {children}
    </TooltipWrapper>
  )
}
