import React, { FunctionComponent, useState } from 'react'
import { useDebounce } from 'react-use'
import { Input } from '../../../components/shared'
import { QuestionConfig } from '../../../model/Questionnaire'
import styles from './EditSliderConfig.module.scss'
import { EDIT_QUESTION_DEBOUNCE_LATENCY } from '../EditQuestionPanel/EditQuestionPanel.model'
import { LabeledCheckbox } from '../../../components/legacy/LabeledCheckbox'

interface EditSliderQuestionProps {
  isAdmin: boolean
  canEditPoints: boolean
  questionConfig: QuestionConfig
  onConfigValueChange: (change: QuestionConfig) => void
}

const computeWarningState = (left: number, right: number, step: number) =>
  (right - left) % step !== 0

export const EditSliderConfig: FunctionComponent<EditSliderQuestionProps> = ({
  questionConfig: { leftValue, rightValue, minPoints, maxPoints, stepValue, inverted },
  isAdmin,
  canEditPoints,
  onConfigValueChange,
}) => {
  const [leftValueStr, setLeftValueStr] = useState<string>(`${leftValue}`)
  const [rightValueStr, setRightValueStr] = useState<string>(`${rightValue}`)
  const [minPointsStr, setMinPointsStr] = useState<string>(`${minPoints}`)
  const [maxPointsStr, setMaxPointsStr] = useState<string>(`${maxPoints}`)
  const [stepValueStr, setStepValueStr] = useState<string>(`${stepValue}`)
  const [isInverted, setIsInverted] = useState<boolean>(inverted)
  const [divisibleWarning, setDivisibleWarning] = useState(
    computeWarningState(rightValue, leftValue, stepValue),
  )

  useDebounce(
    () => {
      const newLeftValue = parseInt(leftValueStr, 10)
      const newRightValue = parseInt(rightValueStr, 10)
      const newMinPoints = parseInt(minPointsStr, 10)
      const newMaxPoints = parseInt(maxPointsStr, 10)
      const newStepValue = parseInt(stepValueStr, 10)
      if (
        !isNaN(newLeftValue) &&
        !isNaN(newRightValue) &&
        !isNaN(newMinPoints) &&
        !isNaN(newMaxPoints) &&
        !isNaN(newStepValue)
      ) {
        setDivisibleWarning(computeWarningState(newLeftValue, newRightValue, newStepValue))
        onConfigValueChange({
          leftValue: newLeftValue,
          rightValue: newRightValue,
          minPoints: newMinPoints,
          maxPoints: newMaxPoints,
          stepValue: newStepValue,
          inverted: isInverted,
        })
      }
    },
    EDIT_QUESTION_DEBOUNCE_LATENCY,
    [leftValueStr, rightValueStr, stepValueStr, minPointsStr, maxPointsStr, isInverted],
  )
  return (
    <div className={styles.configValues}>
      <div className={styles.inputContainer}>
        <Input
          type="number"
          name="leftValue"
          label="Valeur minimum"
          colorPreset="dark"
          value={leftValueStr}
          onChange={(e) => setLeftValueStr(e.target.value)}
          required
        />
      </div>
      <div className={styles.inputContainer}>
        <Input
          type="number"
          name="rightValue"
          label="Valeur maximum"
          colorPreset="dark"
          value={rightValueStr}
          onChange={(e) => setRightValueStr(e.target.value)}
          required
        />
      </div>
      {canEditPoints && (
        <>
          <div className={styles.inputContainer}>
            <Input
              type="number"
              name="minPoints"
              label="Points minimum"
              colorPreset="dark"
              value={minPointsStr}
              onChange={(e) => setMinPointsStr(e.target.value)}
              required
            />
          </div>
          <div className={styles.inputContainer}>
            <Input
              type="number"
              name="maxPoints"
              label="Points maximum"
              colorPreset="dark"
              value={maxPointsStr}
              onChange={(e) => setMaxPointsStr(e.target.value)}
              required
            />
          </div>
        </>
      )}
      {isAdmin && (
        <>
          <div className={styles.inputContainer}>
            <Input
              type="number"
              name="stepValue"
              label="Intervalle"
              colorPreset="dark"
              value={stepValueStr}
              onChange={(e) => setStepValueStr(e.target.value)}
              valid={parseInt(stepValueStr, 10) >= 1}
            />
          </div>
          <div className={styles.inputContainer}>
            <LabeledCheckbox
              colorPreset="dark"
              checked={isInverted}
              label="Couleur inversée"
              onChange={(e) => setIsInverted(e.target.checked)}
              testId="checkbox-invert_color"
            />
          </div>
          {divisibleWarning && (
            <span className={styles.warning} data-test-id="label-divisible_warning">
              Attention: La différence des valeurs minimale et maximale n'est pas divisible par
              l'intervalle. Une entrée clavier sera nécessaire pour atteindre la valeur maximale.
            </span>
          )}
        </>
      )}
    </div>
  )
}
