import React, { FunctionComponent, useContext } from 'react'
import { insertVariable, VariableInsertionPosition } from '@follow/farte'
import { VariableKind, VariableKindPrefix } from '@follow/cdk'

import { TrackingService } from '../../misc/Tracking'
import { QuestionType } from '../../model/Questionnaire'

import { CardMenuItem, Heading } from '../../components/shared'
import { AddContentToDocumentEditorProps } from './AddContentToDocumentEditor.model'

import styles from './AddContentToDocumentEditor.module.scss'
import { defaultQuestionLabels } from '../../constants'

import { Block } from 'slate'
import { ConnectedUserContext } from '../../misc/auth.utilities'
import { hasAdminRole } from '../../misc/roles.utilities'

export const AddContentToDocumentEditor: FunctionComponent<AddContentToDocumentEditorProps> = ({
  enabledFeatures,
  tracker,
  value,
  contentType,
  setValue,
  onInsertVariable,
  onCreateQuestion,
}) => {
  const { loggedUser } = useContext(ConnectedUserContext)

  const isAdmin = loggedUser && hasAdminRole(loggedUser.roles)

  const createdQuestionCallback = (question) => {
    setValue(
      insertVariable(value, {
        variableId: `${VariableKindPrefix.QUESTION}_${question.id}`,
      }),
    )
  }

  const insertedQuestionCallback = (
    variableId: string,
    variableContext?: Record<string, string>,
    position?: VariableInsertionPosition,
    relativeBlock?: Block,
  ) => {
    setValue(
      insertVariable(
        value,
        {
          variableId,
          ...(variableContext && { variableContext }),
        },
        position,
        relativeBlock,
      ),
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.label}>
        <Heading size={3} theme="dark" weight="bold">
          Ajouter une variable
        </Heading>
      </div>
      <div className={styles.actions}>
        {(contentType.BASE_VARIABLE_ADD || isAdmin) && (
          <CardMenuItem
            label="Variable de base"
            icon="text"
            onClick={() => {
              onInsertVariable(VariableKind.Base, (id: string) => insertedQuestionCallback(id))
              TrackingService.sendEvent(tracker, {
                type: `base`,
              })
            }}
          />
        )}
        {enabledFeatures?.drug && (contentType.PRESCRIPTION_ADD || isAdmin) && (
          <CardMenuItem
            label="Médicament"
            icon="pills"
            onClick={() => {
              onInsertVariable(VariableKind.Drug, (id, variableContext, position, relativeBlock) =>
                insertedQuestionCallback(id, variableContext, position, relativeBlock),
              )
              TrackingService.sendEvent(tracker, {
                type: `drugs`,
              })
            }}
          />
        )}
        {enabledFeatures?.drug && (contentType.PRESCRIPTION_ADD || isAdmin) && (
          <CardMenuItem
            label="Préparation de médicament"
            icon="multipills"
            onClick={() => {
              onInsertVariable(
                VariableKind.MultiDrugs,
                (id, variableContext, position, relativeBlock) =>
                  insertedQuestionCallback(id, variableContext, position, relativeBlock),
              )
              TrackingService.sendEvent(tracker, {
                type: `drugs`,
              })
            }}
          />
        )}
        {enabledFeatures?.drug && (contentType.PRESCRIPTION_ADD || isAdmin) && (
          <CardMenuItem
            label="Prescription libre"
            icon="pencil"
            onClick={() => {
              onInsertVariable(
                VariableKind.ManualPrescription,
                (id, variableContext, position, relativeBlock) =>
                  insertedQuestionCallback(id, variableContext, position, relativeBlock),
              )
            }}
          />
        )}
        {(contentType.QUESTION_ADD || isAdmin) && (
          <CardMenuItem
            label="Question Existante"
            icon="question"
            onClick={() => {
              onInsertVariable(VariableKind.Question, (id: string) => insertedQuestionCallback(id))
              TrackingService.sendEvent(tracker, {
                type: `question`,
              })
            }}
          />
        )}
        {(contentType.QUESTIONNAIRE_ADD || isAdmin) && (
          <CardMenuItem
            label="Questionnaire existant"
            icon="questionnaire"
            onClick={() => {
              onInsertVariable(
                VariableKind.Questionnaire,
                (
                  id: string,
                  variableContext?: Record<string, string>,
                  position?: VariableInsertionPosition,
                ) => insertedQuestionCallback(id, variableContext, position),
              )
              TrackingService.sendEvent(tracker, {
                type: `questionnaire`,
              })
            }}
          />
        )}
        {(contentType.QUESTION_ADD || isAdmin) && (
          <>
            <CardMenuItem
              label={defaultQuestionLabels[QuestionType.QCU]}
              coloredIcon="qcuInactive"
              onClick={() => {
                onCreateQuestion(QuestionType.QCU, (question) => createdQuestionCallback(question))
                TrackingService.sendEvent(tracker, {
                  type: `newquestion.${QuestionType.QCU}`,
                })
              }}
            />
            <CardMenuItem
              label={defaultQuestionLabels[QuestionType.QCM]}
              coloredIcon="qcmInactive"
              onClick={() => {
                onCreateQuestion(QuestionType.QCM, (question) => createdQuestionCallback(question))
                TrackingService.sendEvent(tracker, {
                  type: `newquestion.${QuestionType.QCM}`,
                })
              }}
            />
            <CardMenuItem
              label={defaultQuestionLabels[QuestionType.Slider]}
              coloredIcon="sliderInactive"
              onClick={() => {
                onCreateQuestion(QuestionType.Slider, (question) =>
                  createdQuestionCallback(question),
                )
                TrackingService.sendEvent(tracker, {
                  type: `newquestion.${QuestionType.Slider}`,
                })
              }}
            />
            <CardMenuItem
              label={defaultQuestionLabels[QuestionType.Text]}
              coloredIcon="textInactive"
              onClick={() => {
                onCreateQuestion(QuestionType.Text, (question) => createdQuestionCallback(question))
                TrackingService.sendEvent(tracker, {
                  type: `newquestion.${QuestionType.Text}`,
                })
              }}
            />
            <CardMenuItem
              label={defaultQuestionLabels[QuestionType.Date]}
              icon="calendar"
              onClick={() => {
                onCreateQuestion(QuestionType.Date, (question) => createdQuestionCallback(question))
                TrackingService.sendEvent(tracker, {
                  type: `newquestion.${QuestionType.Date}`,
                })
              }}
            />
          </>
        )}
        {enabledFeatures?.ccam && (
          <CardMenuItem
            label="Codage CCAM"
            icon="calculator"
            onClick={() => {
              onInsertVariable(
                VariableKind.Acte,
                (
                  id: string,
                  variableContext?: Record<string, string>,
                  position?: VariableInsertionPosition,
                ) => insertedQuestionCallback(id, variableContext, position),
              )
            }}
          />
        )}
      </div>
    </div>
  )
}
