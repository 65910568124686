import { FC } from 'react'
import { LoadingPage } from '../../../components/shared/loading/LoadingPage/LoadingPage.component'
import { useLoginStellair } from '../../../hooks/queries/vitalCard'

export const StellairOAuthPage: FC = () => {
  const code = new URLSearchParams(document.location.search).get('code')

  useLoginStellair({ code })

  return <LoadingPage title="Authentification Stellair en cours..." />
}
