import { connect } from 'react-redux'
import { branch, compose, renderNothing } from 'recompose'

import { RootState } from '../../../store'
import { BottomPanelDocumentHistoryState } from '../../../store/ui/bottomPanelDocumentHistory'

import { SearchDocumentHistory } from './SearchDocumentHistory.component'
import { SearchDocumentHistoryProps } from './SearchDocumentHistory.model'

const mapStateToProps = (state: RootState) => state.ui.bottomPanelDocumentHistory

const withConnect = connect(mapStateToProps, null)

const withBranch = branch<BottomPanelDocumentHistoryState>(
  (documentHistory) => documentHistory === null,
  renderNothing,
)

export default compose<SearchDocumentHistoryProps, {}>(
  withConnect,
  withBranch,
)(SearchDocumentHistory)
