import { connect } from 'react-redux'

import { RootState } from '../../../../../store'
import {
  questionnaireComputedValue,
  questionnaireComputedValuesUiActions,
} from '../../../../../store/ui/questionnaireComputedValues'

import { ScorePercentage } from './ScorePercentage.component'

import { ScorePercentageStateProps, ScorePercentageOwnProps } from './ScorePercentage.model'

const mapStateToProps = (
  state: RootState,
  { documentId, questionaireId }: ScorePercentageOwnProps,
): ScorePercentageStateProps => ({
  scoreValue: questionnaireComputedValue(state, documentId, questionaireId),
})

const mapDispatchToProps = {
  fetchQuestionnaireComputedValue:
    questionnaireComputedValuesUiActions.fetchQuestionnaireComputedValue,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)(ScorePercentage)
export { withConnect as ScorePercentage }
