import React, { FunctionComponent, useEffect } from 'react'
import { useLocation } from 'react-router'

import { BottomPanel } from '../../components/shared'

import { BottomPanelWrapperProps, BOTTOM_PANEL_COMPONENTS } from './BottomPanelWrapper.model'
import { useScrollLock } from '../../hooks/utils/useScrollLock.hook'

const BottomPanelWrapper: FunctionComponent<BottomPanelWrapperProps> = ({
  setBottomPanelOptions,
  componentType,
  toggleExpandBottomPanel,
  title,
  open,
  maximized,
  displayCloseButton,
}) => {
  const location = useLocation()
  useScrollLock(open)

  // Fermeture du bottom panel au démontage du composant
  useEffect(() => {
    setBottomPanelOptions({
      open: false,
    })
    return () => {
      setBottomPanelOptions({
        open: false,
      })
    }
  }, [location, setBottomPanelOptions])

  const onRequestClose = () =>
    setBottomPanelOptions({
      open: false,
      componentType: componentType,
    })
  const onRequestExpand = () => toggleExpandBottomPanel()
  const Component = componentType ? BOTTOM_PANEL_COMPONENTS[componentType] : null
  return (
    <BottomPanel
      title={title}
      open={open}
      maximized={maximized}
      onRequestClose={onRequestClose}
      onRequestExpand={onRequestExpand}
      content={Component}
      displayCloseButton={displayCloseButton}
    />
  )
}

export default BottomPanelWrapper
