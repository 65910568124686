import React, { FunctionComponent, HTMLAttributes } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { blueColor } from '../../../core/TextEditor.style'
import { RenderVariableDisplayMode, EditorMode } from '../../../core/model/TextEditor.model'
import { VariableTheme } from '../../WithVariableEditorContext'
import { VariableMarks } from './Variable.model'

interface VariableWrapperProps {
  isFocused: boolean
  isUpToDate: boolean
  mode: EditorMode
  displayMode?: RenderVariableDisplayMode
  customTheme?: VariableTheme
  marks: VariableMarks
}

const VariableWrapperStyled = styled.span<VariableWrapperProps>`
  border-radius: 6px;

  ${({ marks: { fontFamily, fontSize } }) => css`
    font-family: ${fontFamily && fontFamily.data.get('fontFamily')};
    font-size: ${fontSize && fontSize.data.get('fontSize')}px;
  `}

  ${({ displayMode }) => {
    switch (displayMode) {
      case 'block':
        return css`
          display: inline-block;
          width: 100%;
        `
      case 'none':
        return css`
          display: none;
        `
      case 'inline':
      default:
        return css`
          display: inline;
        `
    }
  }}

  color: ${({ customTheme }) => (customTheme ? customTheme.txtColor : blueColor)};
  background-color: ${({ customTheme, isUpToDate }) => {
    if (!isUpToDate) return 'rgba(228, 163, 73, 0.3)'
    if (customTheme) return customTheme.backgroundColor
    return 'rgb(220, 236, 255)'
  }};
  border: ${({ isFocused }) => {
    let borderColor = isFocused ? '#7ab7ff' : 'transparent'
    return isFocused ? `2px dotted ${borderColor}` : `2px solid ${borderColor}`
  }};

  // Si pas de contenu dans la variable on n'affiche ni le fond ni les bords
  :empty {
    display: none;
  }

  ${({ mode }) =>
    mode === 'print' &&
    css`
      color: inherit;
      background: transparent;
      border: none;
    `}

  cursor: ${({ mode }) => (['edit', 'preview'].includes(mode) ? 'pointer' : 'auto')};
`

export const UPDATE_LABEL = 'Cette variable peut être mise à jour'

export const VariableWrapper: FunctionComponent<
  VariableWrapperProps & HTMLAttributes<HTMLSpanElement>
> = (props) => {
  const updateTitle = props.title ? `${props.title} - ${UPDATE_LABEL}` : UPDATE_LABEL

  let styledComponent = (
    <VariableWrapperStyled {...props} title={props.isUpToDate ? props.title : updateTitle} />
  )
  if (props.marks.isItalic) {
    styledComponent = <i>{styledComponent}</i>
  }
  if (props.marks.isUnderlined) {
    styledComponent = <u>{styledComponent}</u>
  }
  if (props.marks.isBold) {
    styledComponent = <b>{styledComponent}</b>
  }
  return styledComponent
}
