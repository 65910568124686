import React, { FunctionComponent, useRef, memo, CSSProperties } from 'react'
import { Value } from 'slate'
import { Editor, Plugin } from 'slate-react'
import { renderToStaticMarkup } from 'react-dom/server'

import { FormatingPlugin, FormatingPluginMode, Toolbar } from '../core/FormatingPlugin'
import {
  ToolbarConfig,
  DEFAULT_INLINE_TOOLBAR_CONFIG,
  DEFAULT_TOOLBAR_CONFIG,
} from '../core/FormatingPlugin/Toolbar/Toolbar.model'
import { schema, EditorMode } from '../core/model/TextEditor.model'
import { ImagePlugin } from '../core/ImagePlugin'
import { createOnPaste } from '../core/HtmlSerializer'
import { WithErrorBoundary } from '../components/ErrorBoundary/ErrorBoundary.component'
import { checkToolbarConfig } from '../core/TextEditor.utilities'
import { EditorValue } from '../index'
import EditorContainer from '../components/EditorContainer'
import { getEditorStyles } from '../core/TextEditor.style'
import { ImagePluginMode } from 'core/ImagePlugin/ImagePlugin'
import { AuthContext } from '../core/AuthContext'
import { UsurpedUser } from 'core/AuthContext/auth.model'
import { ClassNames } from '@emotion/react'

const EditorWithTagNameProps = Editor as unknown as FunctionComponent<
  Editor['props'] & { tagName: string }
>

interface SimpleEditorProps extends React.JSX.IntrinsicAttributes {
  value: EditorValue
  deactivateSchemaValidation?: boolean
  editorMode?: EditorMode
  onValueChange?: (value: Value) => void
  readOnly?: boolean
  disabled?: boolean
  toolbarConfig?: ToolbarConfig
  token?: string
  usurpedUser?: UsurpedUser
  toolbarStyle?: CSSProperties
  onAddImage?: () => void
}
function createEditorWithPlugin(
  plugins: Plugin[],
  writingMode: FormatingPluginMode = 'normal',
): FunctionComponent<SimpleEditorProps> {
  const onPaste = createOnPaste(writingMode)
  return WithErrorBoundary(
    ({
      deactivateSchemaValidation = false,
      editorMode = 'edit',
      onValueChange,
      readOnly = false,
      disabled = false,
      toolbarConfig,
      value,
      token,
      usurpedUser,
      toolbarStyle,
      onAddImage,
    }: SimpleEditorProps) => {
      const editorRef = useRef<Editor>(null)

      const handleChange = ({ value }: { value: Value }) => {
        if (onValueChange) {
          onValueChange(value)
        }
      }

      let toolbarConfigToUse: ToolbarConfig
      if (toolbarConfig) {
        toolbarConfigToUse = toolbarConfig
      } else {
        if (writingMode == 'normal') {
          toolbarConfigToUse = DEFAULT_TOOLBAR_CONFIG
        } else {
          toolbarConfigToUse = DEFAULT_INLINE_TOOLBAR_CONFIG
        }
      }

      checkToolbarConfig(toolbarConfigToUse, writingMode)

      return (
        <AuthContext.Provider value={{ token, usurpedUser }}>
          <EditorContainer mode={editorMode} disabled={disabled}>
            {editorRef.current && editorMode !== 'print' && !readOnly && (
              <Toolbar
                editor={editorRef.current}
                value={value}
                config={toolbarConfigToUse}
                style={toolbarStyle}
                onAddImage={onAddImage}
              />
            )}
            <ClassNames>
              {({ css }) => (
                <Editor
                  ref={editorRef}
                  value={value}
                  onChange={handleChange}
                  schema={!deactivateSchemaValidation ? schema : undefined}
                  plugins={plugins}
                  onPaste={onPaste}
                  autoFocus={false}
                  className={css(getEditorStyles(editorMode, readOnly || disabled, writingMode))}
                  readOnly={readOnly || editorMode === 'print'}
                />
              )}
            </ClassNames>
          </EditorContainer>
        </AuthContext.Provider>
      )
    },
  )
}

export const SimpleEditor = createEditorWithPlugin([FormatingPlugin(), ImagePlugin()])

export const InlineSimpleEditor = createEditorWithPlugin(
  [FormatingPlugin({ mode: 'inline' }), ImagePlugin()],
  'inline',
)

export function getSimpleEditorHtml(
  value: Value,
  mode: FormatingPluginMode = 'normal',
  imageMode: ImagePluginMode = 'edit',
): string {
  const isInline = mode === 'inline'
  const editor = isInline ? (
    <EditorWithTagNameProps
      value={value}
      schema={schema}
      plugins={[
        FormatingPlugin({ mode: 'inline' }),
        ImagePlugin({ readOnly: true, mode: imageMode }),
      ]}
      readOnly={true}
      tagName="span"
      style={{ display: 'inline' }}
    />
  ) : (
    <Editor
      value={value}
      schema={schema}
      plugins={[FormatingPlugin(), ImagePlugin({ readOnly: true, mode: imageMode })]}
      readOnly={true}
    />
  )

  return renderToStaticMarkup(editor)
}

export const SimpleValueToHtml = memo<{ value: Value; mode?: FormatingPluginMode }>(
  ({ value, mode = 'normal' }) => {
    const isInline = mode === 'inline'
    const Node = isInline ? 'span' : 'div'
    return (
      <Node
        style={{ display: isInline ? 'inline' : 'block' }}
        dangerouslySetInnerHTML={{
          __html: getSimpleEditorHtml(value, mode),
        }}
      />
    )
  },
)
