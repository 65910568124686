import { restuxDocumentTemplateApiConfig } from './api'
import { restuxCacheFactory } from '../../restux/cache/index'
import { DocumentTemplate, DocumentTemplateListItem } from '../../../model/DocumentTemplate'
import { RestuxResources } from '../../resources'

const resourceName = RestuxResources.documentTemplate

const { actions, reducers, sagas } = restuxCacheFactory<DocumentTemplateListItem, DocumentTemplate>(
  {
    apiConfig: restuxDocumentTemplateApiConfig,
    refetchList: false,
    resourceName,
  },
)

export const documentTemplatesActions = actions
export const documentTemplatesReducers = reducers
export const documentTemplatesSagas = sagas
