import React, { FC, useContext } from 'react'
import { ToolbarContainerProps } from './ToolbarContainer.model'
import { EditorConfigContext } from '@/context'
import classNames from 'classnames/bind'
import styles from './ToolbarContainer.module.scss'

const cx = classNames.bind(styles)

export const ToolbarContainer: FC<ToolbarContainerProps> = ({ children }) => {
  const { themes } = useContext(EditorConfigContext)

  return <div className={cx('toolbar', themes)}>{children}</div>
}
