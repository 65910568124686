import { combineReducers } from 'redux'
import { inUseReducerFactory, paginationReducerFactory } from '../../restux/ui'
import {
  documentContextualMenuQuestionnaireConfig,
  DocumentContextualMenuUiState,
} from './documentContextualMenu.model'

const inUseQuestionnaire = inUseReducerFactory(documentContextualMenuQuestionnaireConfig)
const paginationQuestionnaire = paginationReducerFactory(documentContextualMenuQuestionnaireConfig)

export const documentContextualMenuReducer = combineReducers<DocumentContextualMenuUiState>({
  inUseQuestionnaire,
  paginationQuestionnaire,
})
