import React, { FunctionComponent, createElement, useMemo } from 'react'
import { EditorMode, EditorValue } from '../../../core/model/TextEditor.model'
import { SimpleValueToHtml } from '../../../SimpleEditor'
import { SlateVariableComponentProps } from './Variable.model'
import { VariableWrapper } from './VariableWrapper.component'
import { JSONToValue } from '../../../core/TextEditor.utilities'
import { ValueJSON } from 'slate'

const SlateValueRenderer: FunctionComponent<{ value: EditorValue; mode: EditorMode }> = ({
  value,
}) => <SimpleValueToHtml value={value} mode="inline" />

const SlateVariable: FunctionComponent<SlateVariableComponentProps> = ({
  value,
  mode,
  marks,
  handleClick,
  variableTitle,
  customTheme,
  renderAttributes,
  fixedValue,
  fallbackValue,
  fallbackTheme,
  isUpToDate,
}) => {
  const [content, isFallbackActive] = useMemo<[EditorValue | null, boolean]>(() => {
    const parsedFixedValue: ValueJSON | null = fixedValue ? JSON.parse(fixedValue.content) : null
    if (fixedValue && !!parsedFixedValue) {
      // Fixed Value présente
      const content = JSONToValue(parsedFixedValue)
      return [content, fixedValue.isFallback]
    } else {
      // Pas de fixed value
      const applyFallback = !value
      return [applyFallback ? fallbackValue : value, applyFallback]
    }
  }, [fixedValue, value, fallbackValue])

  return (
    <VariableWrapper
      onMouseUp={handleClick}
      mode={mode}
      title={variableTitle}
      customTheme={isFallbackActive ? fallbackTheme : customTheme}
      marks={marks}
      isFocused={renderAttributes.isFocused}
      isUpToDate={isUpToDate}
      displayMode={isFallbackActive && mode === 'print' ? 'none' : undefined}
    >
      {content && createElement(SlateValueRenderer, { mode, ...{ value: content } })}
    </VariableWrapper>
  )
}
export default SlateVariable
