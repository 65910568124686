import { combineReducers } from 'redux'
import { createReducer } from 'redux-starter-kit'

import {
  UiBottomPanelQuestionsActionTypes,
  EditQuestionAction,
  QuestionEditionOriginState,
  QuestionEditionOrigin,
  BottomPanelQuestionState,
} from './bottomPanelQuestions.model'

const ORIGIN_DEFAULT_STATE: QuestionEditionOriginState = QuestionEditionOrigin.NONE

const ORIGIN_ACTION_HANDLERS = {
  [UiBottomPanelQuestionsActionTypes.EDIT_QUESTION]: (
    _state: QuestionEditionOriginState = ORIGIN_DEFAULT_STATE,
    { origin }: EditQuestionAction,
  ): QuestionEditionOriginState => origin,
}

export const questionOriginReducer = createReducer<QuestionEditionOriginState>(
  ORIGIN_DEFAULT_STATE,
  ORIGIN_ACTION_HANDLERS,
)

export const bottomPanelQuestionsReducer = combineReducers<BottomPanelQuestionState>({
  origin: questionOriginReducer,
})
