import { FunctionComponent, useState } from 'react'
import { FileInstanceContentBannerProps } from './FileInstanceContentBanner.model'
import { AlertBannerItem, IconButton, RoundedButton } from '../../../../shared'
import { MedicalEventDocumentType } from '../../../../../model/MedicalEvent'
import { AnalysisResultModal } from '../../../../cda'

export const FileInstanceContentBanner: FunctionComponent<FileInstanceContentBannerProps> = ({
  file,
  selectMedicalEventDocument,
}) => {
  const [openResults, setOpenResults] = useState<boolean>(false)

  return (
    <div>
      {file.cdaContext?.analysisResult && (
        <>
          <AlertBannerItem
            code="seeResults"
            icon="infoCircle"
            color="blue"
            title="Des résultats importants ont été détectés"
            actionButton={
              <RoundedButton
                theme="transparent"
                label="Consulter"
                onClick={() => setOpenResults(true)}
              />
            }
          />
          <AnalysisResultModal
            display={!!openResults}
            onClose={() => setOpenResults(false)}
            result={file.cdaContext.analysisResult}
          />
        </>
      )}
      {file.fileVersions && file.fileVersions.length > 0 && (
        <>
          <AlertBannerItem
            code="seeResults"
            icon="infoCircle"
            color="blue"
            title={`Vous consultez la version ${file.version} du document`}
            text={`${file.fileVersions?.length} versions antérieurs sont disponibles`}
            actionButton={
              <IconButton
                icon="documentText"
                theme="transparent"
                title="Consulter"
                options={file.fileVersions.map((linkedFile) => ({
                  label: `Version ${linkedFile.version}`,
                  onClick: () => {
                    selectMedicalEventDocument({
                      medicalEventDocumentType: MedicalEventDocumentType.FILE,
                      id: linkedFile.id,
                    })
                  },
                }))}
              />
            }
          />
        </>
      )}
    </div>
  )
}
