import { InfiniteData } from '@tanstack/react-query'
import { PaginatedList, PaginationState } from '../../../model/Pagination'
import { useMemo } from 'react'

export const usePaginationState = <T>(
  data: InfiniteData<PaginatedList<T> | undefined> | undefined,
): PaginationState | null => {
  const state = useMemo(() => {
    const lastPage = data?.pages.at(-1)
    if (lastPage) {
      const { items, ...pagination } = lastPage
      return pagination
    } else {
      return null
    }
  }, [data?.pages])

  return state
}
