import React, {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  DEFAULT_PATIENT_INFORMATIONS,
  PatientInformationsModalProps,
} from './PatientInformationsModal.model'
import { Modal, ActionModalLayout, SearchInput } from '../../shared'
import { useDebounce } from 'react-use'
import { CodedInformations } from '../../../model/CodedInformations'
import { PatientInformationsForm } from './PatientInformationsForm'
import { PatientInformationsActionButtons } from './PatientInformationsActionsButtons/PatientInformationsActionButtons'
import { PatientInformationsSearchResults } from './PatientInformationsSearchResults/PatientInformationsSearchResults.component'
import {
  useGetCodedInformations,
  useSearchCodedInformations,
  useSearchCodedInformationsGroup,
} from '../../../hooks/queries/patientCodedInformations'
import {
  useUpdatePatientInformations,
  useCreatePatientInformations,
} from '../../../hooks/queries/patientInformations'
import styles from './PatientInformationsModal.module.scss'

export interface PatientInformationsModalData {
  indicationGroups: CodedInformations[]
  molecules: CodedInformations[]
  allergies: CodedInformations[]
}

const getModalData = (codedInformations: CodedInformations[]): PatientInformationsModalData =>
  codedInformations.reduce(
    (accumulator, item) => {
      switch (item.type) {
        case 'INDICATION_GROUP':
        case 'INDICATION':
        case 'CIM10':
          return { ...accumulator, indicationGroups: [...accumulator.indicationGroups, item] }
        case 'MOLECULE':
          return { ...accumulator, molecules: [...accumulator.molecules, item] }
        case 'ALLERGY':
          return { ...accumulator, allergies: [...accumulator.allergies, item] }
        default:
          return accumulator
      }
    },
    { indicationGroups: [], molecules: [], allergies: [] } as PatientInformationsModalData,
  ) || { indicationGroups: [], molecules: [], allergies: [] }

export const PatientInformationsModal: FunctionComponent<PatientInformationsModalProps> = ({
  patientId,
  patientInformationsUpdate,
  onClose,
  ...restProps
}) => {
  const [search, setSearch] = useState('')
  const [debouncedSearch, setDebouncedSearch] = useState('')
  const [groupId, setGroupId] = useState('')
  const [showPatientInformationsForm, setShowPatientInformationsForm] = useState(false)
  const [selectedGroupName, setSelectedGroupName] = useState<string | undefined>(undefined)
  const [patientInformationId, setPatientInformationId] = useState<string>('')
  const [newItem, setNewItem] = useState(DEFAULT_PATIENT_INFORMATIONS)
  const {
    query: { data: searchCodedInformationsResult, isInitialLoading: isSearchLoading },
    cancelPendingQuery,
  } = useSearchCodedInformations({
    search: debouncedSearch,
    enabled: !groupId && !!debouncedSearch,
  })

  const {
    query: { data: searchCodedInformationsGroupResult, isInitialLoading: isSearchGroupLoading },
  } = useSearchCodedInformationsGroup({
    groupId: groupId,
    enabled: !!groupId,
  })

  const {
    query: { data: codedInformations, isPlaceholderData },
  } = useGetCodedInformations({
    codedInfoId: patientInformationId,
    enabled: !!patientInformationId,
    search: debouncedSearch,
    groupId,
  })

  const { mutate: updatePatientInformations } = useUpdatePatientInformations()
  const { mutate: createPatientInformations } = useCreatePatientInformations()

  const formattedData = useMemo(() => {
    if (groupId) {
      return searchCodedInformationsGroupResult && getModalData(searchCodedInformationsGroupResult)
    } else {
      return (
        searchCodedInformationsResult &&
        getModalData([
          ...searchCodedInformationsResult.allergies,
          ...searchCodedInformationsResult.cim10,
          ...searchCodedInformationsResult.indicationGroups,
        ])
      )
    }
  }, [groupId, searchCodedInformationsGroupResult, searchCodedInformationsResult])

  useDebounce(() => setDebouncedSearch(search.length > 2 ? search : ''), 500, [search])

  useEffect(() => {
    if (patientInformationsUpdate) {
      const { startAt, endAt, codedInformation } = patientInformationsUpdate
      setNewItem({
        ...patientInformationsUpdate,
        startAt: startAt ? new Date(startAt) : null,
        endAt: endAt ? new Date(endAt) : null,
        codedInformationId: codedInformation?.id,
      })
      setShowPatientInformationsForm(true)
    }
    if (patientInformationId) {
      setNewItem({
        ...DEFAULT_PATIENT_INFORMATIONS,
        name: codedInformations?.name,
        codedInformationId: codedInformations?.id,
        codedInformation: codedInformations,
      })
    }
  }, [patientInformationsUpdate, codedInformations, patientInformationId])

  const showIndicationGroupItems = (id: string, name: string) => {
    setGroupId(id)
    setSelectedGroupName(name)
  }

  const goBackToPreviousResults = () => {
    setGroupId('')
    setPatientInformationId('')
    setSelectedGroupName(undefined)
  }

  const showAddPatientInformationsForm = useCallback(
    (codedInformationId?: string, name?: string) => {
      setShowPatientInformationsForm(true)
      if (codedInformationId) {
        setPatientInformationId(codedInformationId)
      } else {
        setNewItem({
          ...DEFAULT_PATIENT_INFORMATIONS,
          name,
        })
      }
    },
    [],
  )

  const handleIndicationGroup = useCallback(
    (item: CodedInformations) => {
      item.type === 'INDICATION_GROUP'
        ? showIndicationGroupItems(item.id, item.name)
        : showAddPatientInformationsForm(item.id)
    },
    [showAddPatientInformationsForm],
  )
  const handleValidPatientInformations = () => {
    const { name, isAld, startAt, endAt, codedInformationId } = newItem
    const patientInformationsPayload = {
      name,
      isAld,
      startAt,
      endAt,
      codedInformationId,
    }
    if (patientInformationsUpdate) {
      updatePatientInformations({
        patientId,
        patientInformationId: patientInformationsUpdate.id,
        patientInformationsPayload,
      })
    } else {
      createPatientInformations({
        patientId,
        patientInformationsPayload,
      })
    }
    onClose()
    setSearch('')
  }

  const handleBackButtonClick = () => {
    if (patientInformationsUpdate) {
      onClose()
    } else {
      showPatientInformationsForm
        ? setShowPatientInformationsForm(false)
        : goBackToPreviousResults()
    }
  }

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value)
      cancelPendingQuery()
    },
    [cancelPendingQuery],
  )

  const hasResults =
    !!formattedData &&
    Object.values(formattedData).some((codedInfoProp) => codedInfoProp.length > 0)

  return (
    <Modal onClickOutside={onClose} {...restProps}>
      <ActionModalLayout
        title="Ajouter un antécédent, une allergie ou une pathologie"
        onClose={onClose}
        actions={
          <PatientInformationsActionButtons
            search={search}
            hasResults={hasResults}
            groupId={groupId}
            showNewPatientInformationsForm={showPatientInformationsForm}
            onBackButtonClick={handleBackButtonClick}
            onValidPatientInformations={handleValidPatientInformations}
            onShowAddPatientInformationsForm={showAddPatientInformationsForm}
            isEditingPatientInformations={!!patientInformationsUpdate}
            hasPendingData={isPlaceholderData}
          />
        }
      >
        {showPatientInformationsForm ? (
          <PatientInformationsForm
            patientInformations={newItem}
            setPatientInformations={setNewItem}
            testId="allergy-modal-allergy-form"
          />
        ) : (
          <div className={styles.searchAndResultsContainer}>
            {!groupId && (
              <div className={styles.searchContainer}>
                <SearchInput
                  placeholder="Rechercher"
                  value={search}
                  onChange={handleChange}
                  testId="allergy-modal-search-input"
                />
              </div>
            )}
            <PatientInformationsSearchResults
              search={search}
              loading={isSearchLoading || isSearchGroupLoading}
              groupName={selectedGroupName}
              onIndicationGroupClick={handleIndicationGroup}
              onShowAddPatientInformationsFormClick={showAddPatientInformationsForm}
              data={formattedData}
              hasResults={hasResults}
            />
          </div>
        )}
      </ActionModalLayout>
    </Modal>
  )
}
