import { ApiResponse } from 'apisauce'
import { call, put, takeEvery } from 'redux-saga/effects'
import { Prescription } from '../../../model/Prescription'
import { documentTemplatesActions } from '../../cache/documentTemplates'
import { getPrescription, switchToVMP, updatePrescription } from './api'
import { bottomPanelPosologyActions } from '../../ui/bottomPanelPosology'
import { domainPrescriptionActions } from './prescription.actions'
import { addError, addResponseError } from '../../message/message.actions'
import { domainPrescriptionActionTypes } from './prescription.model'
import { setBottomPanelOptions } from '../../ui/bottomPanel'
import { uiPatientCurrentTreatmentsActions } from '../../ui/patientCurrentTreatments'
import { getVariableData } from '../../renderer'
import { documentInstancesActions } from '../../cache/documentInstances'

function* switchPrescriptionToVMPSagaWorker({
  prescription,
}: ReturnType<typeof domainPrescriptionActions.switchToVMP>) {
  const response: ApiResponse<Prescription> = yield call(switchToVMP, prescription.uuid)
  if (response.ok && response.data) {
    const baseLength = prescription.posologyIntervals.length
    const newLength = response.data.posologyIntervals.length

    if (baseLength > 0 && baseLength !== newLength) {
      yield put(
        addError(
          newLength === 0
            ? "La posologie n'a pas pu être conservée"
            : "Une partie de la posologie n'a pas pu être conservée",
          'Le médicament de base et la dénomination commune utilisent des unités incompatibles',
        ),
      )
    }

    if (prescription.documentId) {
      yield put(getVariableData())
      yield put(documentInstancesActions.actions.apiGetItemDetails(prescription.documentId))
    } else if (prescription.documentTemplateId) {
      yield put(documentTemplatesActions.actions.apiGetItemDetails(prescription.documentTemplateId))
    }
  } else {
    yield put(addError('La conversion a échoué.'))
  }
}

function* switchPrescriptionToVMPSagaWatcher() {
  yield takeEvery(domainPrescriptionActionTypes.SWITCH_TO_VMP, switchPrescriptionToVMPSagaWorker)
}

function* openPosologyBottomPanelFromPrescriptionsWorker({
  prescriptionId,
}: ReturnType<typeof domainPrescriptionActions.openPosologyBottomPanelFromPrescription>) {
  const response: ApiResponse<Prescription> = yield call(getPrescription, prescriptionId)
  if (response.ok && response.data) {
    yield put(bottomPanelPosologyActions.open({ mode: 'edit', prescription: response.data }))
  }
}

function* openPosologyBottomPanelFromPrescriptionWatcher() {
  yield takeEvery(
    domainPrescriptionActionTypes.OPEN_POSOLOGY_BOTTOM_PANEL_FROM_PRESCRIPTION,
    openPosologyBottomPanelFromPrescriptionsWorker,
  )
}

function* updatePrescriptionWorker({
  prescriptionId,
  prescriptionUpdate,
}: ReturnType<typeof domainPrescriptionActions.updatePrescription>) {
  const response: ApiResponse<Prescription> = yield call(
    updatePrescription,
    prescriptionId,
    prescriptionUpdate,
  )
  if (response.ok) {
    yield put(
      setBottomPanelOptions({
        open: false,
      }),
    )
    yield put(uiPatientCurrentTreatmentsActions.getCurrentTreatments())
    if (response.data?.documentId) {
      yield put(documentInstancesActions.actions.apiGetItemDetails(response.data.documentId))
    } else if (response.data?.documentTemplateId) {
      yield put(
        documentTemplatesActions.actions.apiGetItemDetails(response.data.documentTemplateId),
      )
    }
  } else {
    yield put(addResponseError(response))
  }
}

function* updatePrescriptionWatcher() {
  yield takeEvery(domainPrescriptionActionTypes.UPDATE_PRESCRIPTION, updatePrescriptionWorker)
}

export const prescriptionConfigsSagas = {
  switchPrescriptionToVMPSagaWatcher,
  openPosologyBottomPanelFromPrescriptionWatcher,
  updatePrescriptionWatcher,
}
