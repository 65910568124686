import { restuxMailContactSearchApiConfig } from './api'
import { restuxCacheFactory } from '../../restux/cache'
import { RestuxResources } from '../../resources'
import { MailContact } from '../../../model/Contact'

const resourceName = RestuxResources.mailContactSearch

const { actions, reducers, sagas } = restuxCacheFactory<MailContact, MailContact>({
  apiConfig: restuxMailContactSearchApiConfig,
  refetchList: true,
  resourceName,
})

export const mailContactSearchActions = actions
export const mailContactSearchReducers = reducers
export const mailContactSearchSagas = sagas
