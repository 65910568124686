import { useState, useCallback, useMemo, useEffect, useRef } from 'react'
import { Loader, RoundedButton } from '../../shared'
import { Options, QuoteLineFormProps, UiQuoteline } from './QuoteLineForm.model'
import { ExtraFees } from './ExtraFees'
import { ModifierCodes } from './ModifierCodes'
import { Selectors, emptyOption } from './Selectors'
import { useGetActe } from '../../../hooks/queries/ccam/actes'
import { useUpdateQuoteLine } from '../../../hooks/queries/ccam/quoteLines'
import { Activity, Phase, QuoteLine, RateGrid } from '../../../model/Acte'
import { SelectOption } from '../../../model/SelectOption'
import { isDefined } from '@follow/cdk'
import { useGetQuoteLinePreview } from '../../../hooks/queries/ccam/quoteLines/useGetQuoteLinePreview.query'
import styles from './QuoteLineForm.module.scss'

const getInitialUiQuoteline = (
  selectedQuoteline: QuoteLine,
  quoteLineActivities: Activity[],
): UiQuoteline | null => {
  const configuredActivity = quoteLineActivities.find(
    (activity) => activity.code === selectedQuoteline.codeActivite?.toString(),
  )

  const configuredPhase = configuredActivity?.phases.find(
    (phase) => phase.code === selectedQuoteline.codePhase?.toString(),
  )

  const configuredRateGrid = configuredPhase?.grilles.find(
    (grille) => grille.code === selectedQuoteline.codeGrille?.toString(),
  )

  const configuredAssociation = configuredRateGrid?.associationNonPrevues.find(
    (association) => association.code === selectedQuoteline.codeAssociationNonPrevue?.toString(),
  )

  const activity = configuredActivity ?? quoteLineActivities[0]
  const phase = configuredPhase ?? activity.phases[0]
  const rateGrid = configuredRateGrid ?? phase.grilles[0]

  return {
    activity: createSelectOption(activity),
    phase: createSelectOption(phase),
    rateGrid: createSelectOption(rateGrid),
    association: configuredAssociation ? createSelectOption(configuredAssociation) : emptyOption,
    modifiers: selectedQuoteline.codeModificateurs ?? [],
    additionalFee: selectedQuoteline.additionalFee,
  }
}

export const createSelectOption = (item: { libelle: string; code: string }) => ({
  label: item.libelle,
  value: item.code,
})

export const getActivityPhases = (activities: Activity[], activity: SelectOption<string>) =>
  activities.find((act) => act.code === activity.value)?.phases ?? []

export const getPhaseRateGrids = (phases: Phase[], phase: SelectOption<string>) =>
  phases.find((pha) => pha.code === phase.value)?.grilles ?? []

export const getRateGridAssociationsAndModifiers = (
  rateGrids: RateGrid[],
  rateGrid: SelectOption<string>,
) => {
  const foundRateGrid = rateGrids.find((grid) => grid.code === rateGrid.value)

  return {
    associations: foundRateGrid?.associationNonPrevues || [],
    modifiers: foundRateGrid?.modificateurs || [],
  }
}

export const QuoteLineForm = ({
  quoteLine,
  documentInstanceId,
  documentTemplateId,
}: QuoteLineFormProps) => {
  const [uiQuoteLine, setUiQuoteline] = useState<UiQuoteline | null>(null)
  const isFirstRender = useRef<boolean>(true)

  const {
    query: { data, isLoading, isSuccess },
  } = useGetActe({
    quoteLine,
  })

  const { mutate: updateQuoteLine } = useUpdateQuoteLine()
  const { data: quoteLinePreview, cancelPendingQuery } = useGetQuoteLinePreview({
    quoteLineId: quoteLine.id,
    quoteLine: uiQuoteLine!,
    enabled: isDefined(uiQuoteLine),
  })

  const hasData = useMemo(
    () => isSuccess && isDefined(data?.activites),
    [data?.activites, isSuccess],
  )

  const options: Options | undefined = useMemo(() => {
    if (!data || !uiQuoteLine) return
    const activityPhases = getActivityPhases(data.activites, uiQuoteLine.activity)
    const phaseRateGrids = getPhaseRateGrids(activityPhases, uiQuoteLine.phase)
    const { associations, modifiers } = getRateGridAssociationsAndModifiers(
      phaseRateGrids,
      uiQuoteLine.rateGrid,
    )

    return {
      activities: data.activites.map(createSelectOption),
      phases: activityPhases.map(createSelectOption),
      rateGrids: phaseRateGrids.map(createSelectOption),
      associations: associations.map(createSelectOption),
      modifiers,
    }
  }, [data, uiQuoteLine])

  useEffect(() => {
    if (isFirstRender && hasData) {
      const quoteLineForDisplay = getInitialUiQuoteline(quoteLine, data!.activites)
      setUiQuoteline(quoteLineForDisplay)
      isFirstRender.current = false
    }
  }, [data, hasData, isFirstRender, isSuccess, quoteLine])

  const handleSelectChange = useCallback(
    (uiQuoteLine: UiQuoteline | null) => {
      setUiQuoteline(uiQuoteLine)
      cancelPendingQuery()
    },
    [cancelPendingQuery],
  )
  const handleCheckChange = useCallback(
    (modifiers: string[]) => {
      setUiQuoteline((prev) => {
        if (!prev) return null
        return { ...prev, modifiers }
      })
      cancelPendingQuery()
    },
    [cancelPendingQuery],
  )

  const handleExtraFeesChange = useCallback(
    (amount: string) => {
      setUiQuoteline((prev) => {
        if (!prev) return null
        return { ...prev, additionalFee: Number(amount) }
      })
      cancelPendingQuery()
    },
    [cancelPendingQuery],
  )

  const handleSave = () =>
    uiQuoteLine &&
    updateQuoteLine({
      quoteLineId: quoteLine.id,
      quoteLinePayload: uiQuoteLine,
      documentInstanceId,
      documentTemplateId,
    })

  if (isLoading) {
    return <Loader />
  }

  return uiQuoteLine && data && options ? (
    <div className={styles.quoteLineFormContainer}>
      <div className={styles.header}>
        <div className={styles.summary}>
          <span className={styles.title}>code ccam</span>
          {quoteLinePreview && (
            <span className={styles.preview}>{quoteLinePreview.data?.summary}</span>
          )}
        </div>
        <RoundedButton label="Enregistrer" size="micro" onClick={handleSave} />
      </div>
      <div className={styles.quoteLineFormContent}>
        <Selectors
          uiQuoteLine={uiQuoteLine}
          activities={data.activites}
          onSelect={handleSelectChange}
          options={options}
        />
        <ModifierCodes
          selectedCodes={uiQuoteLine.modifiers}
          options={options.modifiers}
          onCheckChange={handleCheckChange}
        />
        <ExtraFees amount={uiQuoteLine.additionalFee} onExtraFeesChange={handleExtraFeesChange} />
      </div>
    </div>
  ) : (
    <p>Impossible de récupérer les informations du code CCAM</p>
  )
}
