import React from 'react'
import { Editor as ReactEditor, RenderInlineProps, Plugin } from 'slate-react'
import {
  EditorCommands,
  NodeType,
  VariableModel,
  MarkType,
  EditorValue,
} from '../../core/model/TextEditor.model'
import {
  getFixedValue,
  getNodeMark,
  hasNodeMark,
  removeFixedValuesFromVariables,
} from '../../core/TextEditor.utilities'
import Variable from './components'
import { VariableMarks } from './components/Variable.model'

export const VariablePlugin = (): Plugin => {
  return {
    commands: {
      [EditorCommands.INSERT_VARIABLE]: (editor: ReactEditor, { id }: VariableModel) => {
        editor
          .insertInline({
            type: NodeType.VARIABLE,
            data: {
              idvariable: id,
            },
          })
          .moveForward()
        return editor
      },
    },
    renderInline(props: RenderInlineProps, _editor: ReactEditor, next: () => any) {
      const { node, attributes, isFocused } = props
      switch (node.type) {
        case NodeType.VARIABLE:
          const slateNodeKey = node.key
          const variableId = node.data.get('idvariable')
          const variableContext = node.data.get('variableContext', {})
          const variableFixedValue = getFixedValue(node)
          if (!variableId) {
            throw new Error('Creating a variable without idVariable!')
          }

          const marks: VariableMarks = {
            isBold: hasNodeMark(node, MarkType.BOLD),
            isItalic: hasNodeMark(node, MarkType.ITALIC),
            isUnderlined: hasNodeMark(node, MarkType.UNDERLINED),
            fontFamily: getNodeMark(node, MarkType.FONT_FAMILY),
            fontSize: getNodeMark(node, MarkType.FONT_SIZE),
          }

          return (
            <Variable
              {...attributes}
              marks={marks}
              isFocused={isFocused}
              slateNodeKey={slateNodeKey}
              variableId={variableId}
              variableContext={variableContext}
              fixedValue={variableFixedValue}
            />
          )

        default:
          return next()
      }
    },
    onCopy: (event, _editor, next) => {
      next()
      const fragment = event.clipboardData.getData('application/x-slate-fragment')
      if (!fragment) return

      // Désérialisation du document contenu dans l'event
      const base64 = atob(fragment)
      const document = JSON.parse(decodeURIComponent(base64))

      // Patch du document
      const patchedValue = removeFixedValuesFromVariables(EditorValue.fromJSON({ document }))

      // Sérialisation du document patché
      const patchedDocument = patchedValue.document.toJSON()
      const stringDocument = JSON.stringify(patchedDocument)
      const recoded = btoa(encodeURIComponent(stringDocument))

      event.clipboardData.setData('application/x-slate-fragment', recoded)
    },
  }
}
