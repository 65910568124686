import { parseISO } from 'date-fns'

interface WithCreatedAt {
  createdAt: string
}

export function createdDateComparator<Item extends WithCreatedAt>(current: Item, next: Item) {
  const left = parseISO(current.createdAt).getTime()
  const right = parseISO(next.createdAt).getTime()
  if (left === right) {
    return 0
  } else if (left > right) {
    return -1
  } else {
    return 1
  }
}

export function sortByCreatedDate<Item extends WithCreatedAt>(items: Item[]) {
  return items.sort(createdDateComparator)
}
