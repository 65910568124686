import React, { FunctionComponent } from 'react'
import { PatientInfosItemProps } from './PatientInfosItem.model'
import styles from './PatientInfosItem.module.scss'

export const PatientInfosItem: FunctionComponent<PatientInfosItemProps> = ({ label, value }) => (
  <div className={styles.container}>
    <div className={styles.label}>{label}</div>
    <div className={styles.value}>{value}</div>
  </div>
)
