import { FunctionComponent, useMemo } from 'react'
import { TaskCategorySelectProps } from './TaskCategorySelect.model'
import { Icon, SelectInput, Tag, TooltipWrapper } from '../../../../../shared'
import {
  TASK_CATEGORY_ICON,
  TASK_CATEGORY_LABEL,
  TASK_CATEGORY_OPTIONS,
} from '../../TaskTableRow.model'

export const TaskCategorySelect: FunctionComponent<TaskCategorySelectProps> = ({
  category,
  isEditModeEnabled,
  setCategory,
}) => {
  const categoryValue = useMemo(
    () => TASK_CATEGORY_OPTIONS.find((localCategory) => category === localCategory.value),
    [category],
  )

  return (
    <div className="w-60">
      {isEditModeEnabled ? (
        <SelectInput
          placeholder="Catégorie"
          options={TASK_CATEGORY_OPTIONS}
          value={categoryValue}
          onSelect={(category) => setCategory(category.value)}
        />
      ) : (
        <Tag key={category} ellipsis={false}>
          <div className="flex">
            <div className="mt-1 mr-2">
              {category && <Icon size="nano" icon={TASK_CATEGORY_ICON[category]} />}
            </div>
            <div>
              <TooltipWrapper
                content={
                  <div className="break-normal">
                    {category ? TASK_CATEGORY_LABEL[category] : ''}
                  </div>
                }
                pointerDirection="bottom"
              >
                <div className="max-w-44 text-ellipsis overflow-hidden whitespace-nowrap">
                  {category ? TASK_CATEGORY_LABEL[category] : ''}
                </div>
              </TooltipWrapper>
            </div>
            <div className="mt-2 ml-3">
              <Icon size="femto" icon="chevron" />
            </div>
          </div>
        </Tag>
      )}
    </div>
  )
}
