import React, { FunctionComponent, useState } from 'react'
import { DropdownContainer, DropdownItem, Icon, IconButton, IconsType } from '../../..//shared'
import { User } from '../../../../model/User'
import styles from './UserListCustomActions.module.scss'
import { getFullName } from '../../../../misc/user.utilities'
import { hasAdminRole, hasAssistantRole } from '../../../../misc/roles.utilities'
import RoleRestriction from '../../../../containers/RoleRestriction'
import { ADMIN_ROLES } from '../../../../model/Roles'

const DropdownItemAction: FunctionComponent<{
  iconName: IconsType
  label: string
}> = ({ iconName, label }) => (
  <div className={styles.listItem} title={label}>
    <Icon icon={iconName} size="micro" />
    <div className={styles.label}>{label}</div>
  </div>
)

export const UserListCustomActions: FunctionComponent<{
  user: User
  onLock: () => void
  onDuplicate: () => void
  onUsurpedUser: () => void
}> = ({ user, onLock, onDuplicate, onUsurpedUser }) => {
  const [opened, setOpened] = useState<boolean>(false)
  const disabled = hasAssistantRole(user.roles) || hasAdminRole(user.roles)
  const title = disabled
    ? `Vous ne pouvez pas usurper un·e assistant·e ou un admin`
    : `Usurpation de ${getFullName(user)}`

  return (
    <>
      <IconButton
        disabled={disabled}
        size="micro"
        theme="light"
        icon="arrowCircle"
        title={title}
        onClick={onUsurpedUser}
      />
      <RoleRestriction roles={ADMIN_ROLES}>
        <div className={styles.container}>
          <IconButton
            size="micro"
            theme="light"
            icon="more"
            title="Action supplémentaire"
            onClick={() => setOpened(!opened)}
          />
          <div className={styles.dropdown}>
            <DropdownContainer
              direction="right"
              opened={opened}
              closeDropdown={() => setOpened(false)}
            >
              <DropdownItem onClick={onDuplicate}>
                <DropdownItemAction iconName="duplicate" label="Dupliquer le contenu utilisateur" />
              </DropdownItem>
              <DropdownItem onClick={onLock}>
                <DropdownItemAction
                  iconName="default"
                  label={user.locked ? 'Déverrouiller' : 'Verrouiller'}
                />
              </DropdownItem>
            </DropdownContainer>
          </div>
        </div>
      </RoleRestriction>
    </>
  )
}
