import { takeEvery, call, put } from 'redux-saga/effects'
import { ApiResponse } from 'apisauce'
import { isSuccessfulApiResponse } from '../../restux/cache/restuxCacheSagas.factory'
import { addResponseError } from '../../message'
import { PaginatedList } from '../../../model/Pagination'
import { AnswerValue } from '../../../model/AnswerValue'
import { getDocumentAnswerValues } from './api'
import { documentAnswerValuesDomainActions } from './documentAnswerValues.actions'
import { DocumentAnswerValuesDomainActionsTypes } from './documentAnswerValues.model'

function* fetchDocumentAnswerValuesWorker({
  documentId,
}: ReturnType<typeof documentAnswerValuesDomainActions.fetchDocumentAnswerValues>) {
  const response: ApiResponse<PaginatedList<AnswerValue>> = yield call(
    getDocumentAnswerValues,
    documentId,
  )
  if (!isSuccessfulApiResponse(response)) {
    yield put(addResponseError(response))
  } else if (response.data) {
    yield put(
      documentAnswerValuesDomainActions.onFetchSuccessDocumentAnswerValues(
        documentId,
        response.data.items,
      ),
    )
  }
}

function* fetchDocumentAnswerValuesWatcher() {
  yield takeEvery(DocumentAnswerValuesDomainActionsTypes.FETCH, fetchDocumentAnswerValuesWorker)
}

export const domainAnswerValuesSagas = {
  fetchDocumentAnswerValuesWatcher,
}
