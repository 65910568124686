import React, { FunctionComponent } from 'react'
import styles from './FullLoader.module.scss'
import { InfiniteLoader } from '../InfiniteLoader'
import { FullLoaderProps } from './FullLoader.model'

export const FullLoader: FunctionComponent<FullLoaderProps> = ({ message }) => {
  return (
    <div className={styles.container}>
      <InfiniteLoader loading={true} />
      {message && <span className="text-base text-shades-3 mt-3">{message}</span>}
    </div>
  )
}
