import React, { FC, useMemo } from 'react'
import { formatFr } from '../../../../../misc/date.utilities'
import { NotificationTypeLabel } from '../../../../../model/Notification'
import { UpdateModalItemProps } from './UpdateModalItem.model'

const parser = new DOMParser()

export const UpdateModalItem: FC<UpdateModalItemProps> = ({ notification }) => {
  const htmlParsed = useMemo(
    () =>
      parser
        .parseFromString(notification.content, 'text/html')
        .body.innerHTML.replaceAll(/(?:\r\n|\r|\n)/g, '<br />')
        .replaceAll('<p></p>', '<br />'),
    [notification],
  )

  return (
    <>
      <div className="sticky  top-0 bg-shades-7 font-medium space-x-3 px-14 py-6 flex items-center border-b border-t border-shades-6">
        <span className="bg-status-valid py-0.5 px-2 text-shades-white text-base rounded-full">
          {NotificationTypeLabel[notification.type]}
        </span>
        {!notification.read && (
          <span className="bg-status-warning py-0.5 px-2 text-shades-white text-base rounded-full">
            Non lue
          </span>
        )}
        <span className="text-xl text-shades-4">
          {formatFr(new Date(notification.publishedAt), 'dd MMMMMM yyyy')}
        </span>
      </div>
      <h1 className="text-primary-dark font-medium mx-12 mt-6">{notification.title}</h1>
      <div
        className="mx-12 my-6 text-shades-3"
        dangerouslySetInnerHTML={{
          __html: htmlParsed,
        }}
      />
    </>
  )
}
