import { VariablesData } from '@follow/farte'
import { RendererActionTypes, RenderableDocument } from './renderer.model'

export const sendDocuments = (documents: Array<RenderableDocument>, fileName?: string) => ({
  type: RendererActionTypes.SEND_DOCUMENT,
  documents,
  fileName,
})

export const getVariableData = () => ({
  type: RendererActionTypes.GET_VARIABLE_DATA,
})

export const setVariableData = (variableData: VariablesData | null) => ({
  type: RendererActionTypes.SET_VARIABLE_DATA,
  variableData,
})

export const setVariableDataLoading = (loading: boolean) => ({
  type: RendererActionTypes.SET_VARIABLE_DATA_LOADING,
  loading,
})
