import React, { FunctionComponent, useState } from 'react'
import { useEffectWithRef } from '../../../hooks/utils'
import { DocumentInstanceListItem } from '../../../model/DocumentInstance'
import { DocumentListItem } from '../../document'
import { RoundedButton, Modal, ActionModalLayout, List, AbstractList } from '../../shared'
import { DuplicationModalProps } from './DuplicationModal.model'

export const DuplicationModal: FunctionComponent<DuplicationModalProps> = ({
  isUnmounting,
  medicalEvent,
  onCloseModal,
  onDuplicateMedicalEventDocuments,
}) => {
  const [listRef, setListRef] = useEffectWithRef<AbstractList<DocumentInstanceListItem>>(() => {
    if (listRef) {
      listRef.updateList(true)
    }
  }, [])

  const [toDuplicateDocuments, setToDuplicateDocuments] = useState<Array<number>>([])

  const handleDuplicateDocuments = (documentId?: number) => {
    if (medicalEvent) {
      if (documentId) {
        onDuplicateMedicalEventDocuments(medicalEvent.id, [documentId])
      } else {
        onDuplicateMedicalEventDocuments(medicalEvent.id, toDuplicateDocuments)
      }
      onCloseModal()
    }
  }

  return (
    <Modal isUnmounting={isUnmounting} onClickOutside={onCloseModal}>
      <ActionModalLayout
        title="Duplication de document(s)"
        onClose={onCloseModal}
        actions={
          <div className="grid grid-flow-col gap-4 w-full justify-between">
            <RoundedButton
              label="Annuler"
              size="micro"
              theme="transparent-dark"
              onClick={onCloseModal}
            />
            <RoundedButton
              label="Dupliquer"
              size="micro"
              onClick={() => handleDuplicateDocuments()}
              disabled={toDuplicateDocuments.length === 0}
            />
          </div>
        }
      >
        <div className="relative w-screen-1/2 h-screen-1/2 overflow-y-scroll bg-white">
          <List
            loading={false}
            items={medicalEvent ? medicalEvent.documents : []}
            itemCount={medicalEvent?.documents.length}
            renderItem={(document) => <DocumentListItem item={document} />}
            onUpdateList={() => {}}
            pageCount={0}
            ref={setListRef}
            onPrimaryAction={(document) => handleDuplicateDocuments(document.id)}
            onMultiSelectionChange={(documents) => {
              setToDuplicateDocuments(documents.map((document) => document.id))
            }}
            fullPageMode={false}
          />
        </div>
      </ActionModalLayout>
    </Modal>
  )
}
