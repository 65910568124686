import { Filters } from '../../../../model/Filters'

const all = ['admin/notif']
const list = (filters?: Filters) => [...all, filters]
const detail = (id: string) => [...all, id]

export const adminNotifKeys = {
  all,
  list,
  detail,
}
