import { BaseVariableIdType } from '@follow/cdk'
import {
  BaseVariableListType,
  UiBottomPanelBaseVariablesActionTypes,
} from './bottomPanelBaseVariables.model'

const searchBaseVariable = (
  onBaseVariableSelected: (id: BaseVariableIdType) => void,
  baseVariableList: BaseVariableListType,
) => ({
  type: UiBottomPanelBaseVariablesActionTypes.SEARCH_BASE_VARIABLE,
  onBaseVariableSelected,
  baseVariableList,
})

const setBaseVariableList = (baseVariableList: BaseVariableListType) => ({
  type: UiBottomPanelBaseVariablesActionTypes.SET_BASE_VARIABLE_LIST,
  baseVariableList,
})

const selectBaseVariable = (variableId: BaseVariableIdType) => ({
  type: UiBottomPanelBaseVariablesActionTypes.SELECT_BASE_VARIABLE,
  variableId,
})

export const bottomPanelBaseVariablesActions = {
  searchBaseVariable,
  selectBaseVariable,
  setBaseVariableList,
}
