import {
  STELLAIR_APP_URL,
  STELLAIR_AUTHORIZE_URL,
  STELLAIR_CLIENT_ID,
  STELLAIR_REDIRECT_URI,
} from '../../environment/stellair'

export const stellairOpenApp = () => {
  return window.open(STELLAIR_APP_URL)
}

export const stellairLoginPrompt = () => {
  return window.open(
    `${STELLAIR_AUTHORIZE_URL}?client_id=${STELLAIR_CLIENT_ID}&response_type=code&redirect_uri=${STELLAIR_REDIRECT_URI}&scope=openid&state=HNdzk`,
  )
}
