import createReducer from '../reducers/createReducer'
import * as Actions from './http.actions'
import { ReduxHttp } from './http.model'

const INITIAL_STATE: ReduxHttp = { concurrentRequestsCount: 0 }

const ACTION_HANDLERS = {
  [Actions.Types.INCREMENT_REQUESTS_COUNT]: (state: ReduxHttp = INITIAL_STATE): ReduxHttp => ({
    ...state,
    concurrentRequestsCount: state.concurrentRequestsCount + 1,
  }),

  [Actions.Types.DECREMENT_REQUESTS_COUNT]: (state: ReduxHttp = INITIAL_STATE): ReduxHttp => ({
    ...state,
    concurrentRequestsCount: state.concurrentRequestsCount - 1,
  }),
}

export const httpReducer = createReducer(INITIAL_STATE, ACTION_HANDLERS)
