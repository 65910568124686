import React, { FunctionComponent } from 'react'
import { DocumentLayoutListItemProps } from './DocumentLayoutListItem.model'
import styles from './DocumentLayoutListItem.module.scss'

export const DocumentLayoutListItem: FunctionComponent<DocumentLayoutListItemProps> = ({
  documentLayout,
  isAdmin,
}) => {
  return (
    <div className={styles.item}>
      <div className={styles.title} title="Nom de la mise en page">
        {documentLayout.title}
      </div>
      {isAdmin && (
        <div className={styles.identifier} title="Identifiant technique de la mise en page">
          {documentLayout.id}
        </div>
      )}
    </div>
  )
}
