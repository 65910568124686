import React, { FunctionComponent, useEffect, useRef } from 'react'
import classNames from 'classnames/bind'
import { RestuxLoadingState } from '../../store/restux/ui'
import { FullLoader } from '../../components/shared'
import { MedicalEventPageProps } from './MedicalEventPage.model'
import styles from './MedicalEventPage.module.scss'
import {
  SendFileModal,
  MedicalEventFilters,
  MedicalEventContent,
} from '../../components/medicalEvent'
import { useParams } from 'react-router'

const cx = classNames.bind(styles)

export const MedicalEventPage: FunctionComponent<MedicalEventPageProps> = ({
  medicalEventLoadingState,
  selectedMedicalEventDocument,
  selectMedicalEvent,
  clearMedicalEventContext,
}) => {
  const { medicalEventId } = useParams<'patientId' | 'medicalEventId'>()

  useEffect(() => {
    selectMedicalEvent(parseInt(medicalEventId ?? '', 10), {
      withFetch: true,
    })

    return () => {
      clearMedicalEventContext()
    }
  }, [clearMedicalEventContext, medicalEventId, selectMedicalEvent])

  const contentRef = useRef<HTMLDivElement>(null)

  return medicalEventLoadingState !== RestuxLoadingState.IDLE ? (
    <div className={styles.loadingContainer}>
      <FullLoader />
    </div>
  ) : (
    <div className={cx(styles.container)}>
      <div
        ref={contentRef}
        className={cx(styles.content, { fixedHeightContainer: !selectedMedicalEventDocument })}
      >
        <MedicalEventFilters />
        {medicalEventLoadingState === RestuxLoadingState.IDLE && <MedicalEventContent />}
        <SendFileModal />
      </div>
    </div>
  )
}
