import React, { FunctionComponent } from 'react'
import { MailClassificationModalProps } from './MailClassificationModal.model'
import { ActionModalLayout, FormikTextInput, Modal, RoundedButton } from '../../../shared'
import { addTestIdSuffix } from '../../../../misc/testId.utilities'
import styles from './MailClassificationModal.module.scss'
import { Formik, FormikProps } from 'formik'
import { MailClassificationModalValidationSchema } from './MailClassificationModal.validator'
import {
  MailClassificationAttachment,
  MailClassificationCdaAttachment,
} from '../../../../model/Mail'

export const MailClassificationModal: FunctionComponent<MailClassificationModalProps> = ({
  isUnmounting,
  attachments,
  onClose,
  onSubmit,
  testId,
}) => {
  return (
    <Modal
      isUnmounting={isUnmounting}
      onClickOutside={onClose}
      testId={addTestIdSuffix('root', testId)}
    >
      <Formik
        initialValues={attachments}
        validationSchema={MailClassificationModalValidationSchema}
        onSubmit={onSubmit}
        validateOnBlur={true}
        validateOnChange={true}
        validateOnMount={true}
      >
        {(props: FormikProps<MailClassificationAttachment[]>) => (
          <ActionModalLayout
            title="Renommer et classer ce(s) document(s)"
            onClose={onClose}
            actions={
              <div className={styles.actionsContainer}>
                <RoundedButton
                  label="Annuler"
                  onClick={onClose}
                  theme="transparent-dark"
                  testId={addTestIdSuffix('cancel-btn', testId)}
                />
                <RoundedButton
                  label="Classer"
                  onClick={props.submitForm}
                  theme="primary"
                  disabled={!props.isValid}
                  testId={addTestIdSuffix('submit-btn', testId)}
                />
              </div>
            }
          >
            <div className={styles.body} data-test-id={addTestIdSuffix('body', testId)}>
              <div className={styles.fieldsContainer}>
                {attachments.map((attachment: MailClassificationAttachment, index) => {
                  if (attachment.cdaContent) {
                    return (
                      <div key={attachment.id}>
                        {attachment.cdaContent.map(
                          (cdaAttachment: MailClassificationCdaAttachment, cdaIndex) => (
                            <FormikTextInput
                              key={cdaAttachment.id}
                              colorPreset="light"
                              label={`${attachment.visibleName} - ${cdaAttachment.visibleName}`}
                              fieldName={`${index}.cdaContent.${cdaIndex}.classifyVisibleName`}
                            />
                          ),
                        )}
                      </div>
                    )
                  } else {
                    return (
                      <FormikTextInput
                        key={attachment.id}
                        colorPreset="light"
                        label={attachment.visibleName}
                        fieldName={`${index}.classifyVisibleName`}
                      />
                    )
                  }
                })}
              </div>
            </div>
          </ActionModalLayout>
        )}
      </Formik>
    </Modal>
  )
}
