import { FC } from 'react'
import { InsiPatientFirstnameChangeValidationModalProps } from './InsiPatientFirstnameChangeValidationModal.model'
import { ValidationModal } from '../../../../shared/modal/ValidationModal/ValidationModal.component'

export const InsiPatientFirstnameChangeValidationModal: FC<
  InsiPatientFirstnameChangeValidationModalProps
> = ({ insiResponseFirstname, userInputFirstname, isUnmounting, onSubmit, onClose }) => {
  return (
    <ValidationModal
      isUnmounting={isUnmounting}
      onClose={onClose}
      cancelLabel="Ignorer"
      onSubmit={onSubmit}
      confirmLabel="Modifier"
      size="medium"
      title="Différence sur le premier prénom de naissance"
      icon="infoCircle"
      description="Le téléservice INSi a fourni un premier prénom de naissance différent de celui présent dans le formulaire. Souhaitez-vous utiliser le prénom fourni par le téléservice ?"
    >
      <div className="grid grid-cols-[auto_1fr] gap-1 mx-auto text-shades-2 text-base text-start">
        <span className="justify-self-end">Formulaire :</span>
        <span>
          <b>{userInputFirstname}</b>
        </span>
        <span className="justify-self-end">Téléservice :</span>
        <span className="capitalize">
          <b>{insiResponseFirstname.toLowerCase()}</b>
        </span>
      </div>

      <span className="text-shades-2 text-sm font-medium">
        Le reste du formulaire ne sera pas impacté.
      </span>
    </ValidationModal>
  )
}
