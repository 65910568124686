import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react'
import { INSI_CONSULT_APP_URL } from '../../../environment/insiConsult'
import { useCreateTask } from '../../../hooks/queries/task'
import { insiConsultPatientMapper } from '../../../misc/insi.utilities'
import { InsiConsultActions } from '../../../model/Insi'
import { IdentityState } from '../../../model/Patient'
import { TaskCategory } from '../../../model/Task'
import { Modal } from '../../shared'
import { InsiConsultIframeProps } from './InsiConsultIframe.model'

export const InsiConsultIframe: FunctionComponent<InsiConsultIframeProps> = ({
  request,
  setRequest,
  setPatientResponse,
  createPatient,
  updatePatient,
  mailPatient,
  logInsi,
  addInfo,
  addError,
}) => {
  const insiConsultRef = useRef<HTMLIFrameElement>(null)
  const [openInsiConsult, setOpenInsiConsult] = useState(false)
  const { mutate: createTask } = useCreateTask()

  const insiConsultMessageHandler = useCallback(
    (event: MessageEvent) => {
      if (event.origin === INSI_CONSULT_APP_URL.origin) {
        const response = event.data
        logInsi(response)
        setOpenInsiConsult(false)
        switch (response.exportId) {
          case InsiConsultActions.PATIENT_FORM: {
            if (response.CR?.codeCR === '00') {
              const patient = insiConsultPatientMapper(response)
              if (patient) {
                setPatientResponse(patient)
              }
            } else {
              setPatientResponse()
            }
            break
          }
          case InsiConsultActions.MSS_CREATION: {
            if (mailPatient) {
              createPatient({
                ...mailPatient,
                identityStatus: {
                  id: null,
                  suspicious: false,
                  fictitious: false,
                  showTrustedSource: mailPatient.identityStatus?.showTrustedSource,
                  trustedSource: mailPatient.identityStatus?.trustedSource,
                  state:
                    response.CR?.codeCR === '00'
                      ? IdentityState.VALIDATED
                      : IdentityState.TEMPORARY,
                  insiStatus: {
                    checkedAt: new Date(),
                    checked: response.CR?.codeCR === '00' ? 'VALIDATED' : 'FAILED',
                  },
                },
              })
            }
            break
          }
          case InsiConsultActions.MSS_UPDATE: {
            if (mailPatient?.id) {
              updatePatient(mailPatient.id, {
                ...mailPatient,
                identityStatus: {
                  ...mailPatient.identityStatus,
                  state:
                    response.CR?.codeCR === '00'
                      ? IdentityState.VALIDATED
                      : IdentityState.TEMPORARY,
                  insiStatus: {
                    checkedAt: new Date(),
                    checked: response.CR?.codeCR === '00' ? 'VALIDATED' : 'FAILED',
                  },
                },
              })
            }
            break
          }
          case InsiConsultActions.MSS_CHECK: {
            if (response.CR?.codeCR === '00') {
              addInfo(`Code retour INSI: ${response.CR?.codeCR}`)
              const patient = insiConsultPatientMapper(response)
              if (patient) {
                setPatientResponse(patient)
              }
            } else {
              if (mailPatient) {
                createTask({
                  title: mailPatient.id
                    ? "Échec de la validation de l'identité via l'INSi"
                    : `Échec de la validation de l'identité via l'INSi pour le patient ${mailPatient.birthFirstName} ${mailPatient.birthLastName}`,
                  autoCreated: true,
                  category: TaskCategory.INFORMATION,
                  patientId: mailPatient.id ?? undefined,
                  isNotify: true,
                })
              }
              addError('Code retour INSI: 01')
              setPatientResponse()
            }
            break
          }
        }
        setRequest()
      }
    },
    [
      logInsi,
      setRequest,
      setPatientResponse,
      mailPatient,
      createPatient,
      updatePatient,
      addInfo,
      addError,
      createTask,
    ],
  )

  useEffect(() => {
    if (request) {
      setOpenInsiConsult(true)
    } else {
      setOpenInsiConsult(false)
    }
  }, [request])

  useEffect(() => {
    window.addEventListener('message', insiConsultMessageHandler)
    return () => {
      window.removeEventListener('message', insiConsultMessageHandler)
    }
  }, [insiConsultMessageHandler, setRequest])

  return (
    <Modal
      hidden={!openInsiConsult}
      isUnmounting={false}
      onClickOutside={() => {
        setOpenInsiConsult(false)
        setRequest()
      }}
    >
      <div className="w-screen-3/4 h-screen-3/4 bg-shades-white shadow-2xl rounded-3xl p-5">
        <iframe
          className="w-full h-full"
          ref={insiConsultRef}
          title="insi-consult-iframe"
          src={`${INSI_CONSULT_APP_URL.toString()}${request ? `#params=${request}` : ''}`}
        />
      </div>
    </Modal>
  )
}
