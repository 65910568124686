import { developmentMode } from '../misc/environment.utilities'
import { noop } from '../misc/functions.utilities'

/**
 * Insertion dynamique de script. Utiliser pour intégrer les solution d'analytics
 * @param globalDocument Instance de document à utiliser
 * @param src La source du script à insérer
 * @param props Propriété custom. Par exemple "id", "class", ...
 * @param onLoad Callback de chargement du script
 */
export function createAsyncScript(
  globalDocument: Document,
  src: string,
  props: { [key: string]: string } = {},
  onLoad: () => void = noop,
) {
  const script: any = globalDocument.createElement('script')
  script.async = true
  script.src = src
  script.onload = onLoad
  // Ajout des props custom
  Object.entries(props).map(([key, value]) => (script[key] = value))
  globalDocument.head.appendChild(script)
}

export function preventAnalyticLoading(globalDocument: Document) {
  if (developmentMode) {
    return true
  }
  if (!globalDocument.location) {
    return false
  }
  const search = new URLSearchParams(globalDocument.location.search)
  return search.get('analytic')
}
