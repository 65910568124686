import React, { Component, createRef } from 'react'
import classNames from 'classnames/bind'
import { findDOMNode, createPortal } from 'react-dom'
import { BOTTOM_PANEL_CONTENT_ROOT, BottomPanelOwnProps } from './BottomPanel.model'
import { TopBar } from './TopBar'
import styles from './BottomPanel.module.scss'

const cx = classNames.bind(styles)

export class BottomPanel extends Component<BottomPanelOwnProps> {
  private actionsRef = createRef<HTMLDivElement>()

  public render() {
    const {
      content: Content,
      open,
      maximized,
      onRequestClose,
      onRequestExpand,
      title,
      displayCloseButton,
    } = this.props
    return (
      <>
        <div className={cx(styles.overlay, { open })} />
        <div className={cx(styles.container, { hidden: !open, maximized })}>
          <TopBar
            hidden={!open}
            onRequestClose={onRequestClose}
            onRequestExpand={onRequestExpand}
            maximized={maximized}
            actionsRef={this.actionsRef}
            title={title}
            displayCloseButton={displayCloseButton}
          />
          <div
            id={BOTTOM_PANEL_CONTENT_ROOT}
            className={cx(styles.content, { hidden: !open, maximized })}
          >
            {open && Content && this.actionsRef.current && (
              <Content actions={this.getActionsPortal} title={title} />
            )}
          </div>
          <div className={cx(styles.mobileActions, { hidden: !open })}></div>
        </div>
      </>
    )
  }

  private getActionsPortal = ({ children }: { children?: React.ReactNode }) => {
    return createPortal(children, findDOMNode(this.actionsRef.current) as HTMLDivElement)
  }
}
