import { RestuxResources } from '../../resources'
import { toUpperSnakeCase } from '../../restux/restux.utilities'
import { RestuxInUse, RestuxUiSagaConf } from '../../restux/ui'

const RESOURCE_PREFIX = `@${toUpperSnakeCase(RestuxResources.contact)}`

export enum BottomPanelContactsContext {
  PATIENT_CONTACT,
  DOCUMENT_CONTACT,
  POSOLOGY_CONTACT,
  PATIENT_CONTACT_INFO,
}

export const bottomPanelContactsConfig: RestuxUiSagaConf = {
  resourceName: RestuxResources.contact,
}

export interface BottomPanelContactsState {
  inUse: RestuxInUse
  contactPanel: {
    readOnly: boolean
    context: BottomPanelContactsContext
    isInCreatePrivateCopyMode: boolean
  }
}

export const UiBottomPanelContactsActionTypes = {
  OPEN_CREATE_CONTACT_FORM: `${RESOURCE_PREFIX}/OPEN_CREATE_CONTACT_FORM`,
  OPEN_EDIT_CONTACT_FORM: `${RESOURCE_PREFIX}/OPEN_EDIT_CONTACT_FORM`,
  SET_READ_ONLY: `${RESOURCE_PREFIX}/SET_READ_ONLY`,
  SET_CONTEXT: `${RESOURCE_PREFIX}/SET_CONTEXT`,
  SWITCH_PRIVATE_COPY_MODE: `${RESOURCE_PREFIX}/SWITCH_PRIVATE_COPY_MODE`,
  CREATE_PRIVATE_COPY: `${RESOURCE_PREFIX}/CREATE_PRIVATE_COPY`,
  CONTACT_SUCCESFULLY_UPDATED: `${RESOURCE_PREFIX}/CONTACT_SUCCESFULLY_UPDATED`,
}
