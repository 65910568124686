import { put, race, select, take, takeEvery } from 'redux-saga/effects'
import { mapContactToRecipientContact } from '../../../misc/contact.utilities'
import { RecipientContact } from '../../../model/Contact'
import { contactsActions } from '../../cache/contacts'
import { documentInstancesActions } from '../../cache/documentInstances'
import { BottomPanelActionTypes, hideBottomPanel } from '../../ui/bottomPanel'
import {
  BottomPanelContactsContext,
  bottomPanelContactsActions,
} from '../../ui/bottomPanelContacts'
import { inUseMedicalEventDocumentSelector } from '../../ui/medicalEvents/medicalEventDocumentInstances'
import { domainContactsActions } from './contacts.actions'
import { DomainContactsActionTypes } from './contacts.model'

function* editContactsWorker({
  contactId,
  updatedContact,
  context,
}: ReturnType<typeof domainContactsActions.editContact>) {
  yield put(contactsActions.actions.apiUpdateItem(contactId, updatedContact))
  const {
    succeed,
  }: {
    succeed: ReturnType<typeof contactsActions.actions.storeSetItemDetails>
  } = yield race({
    succeed: take(contactsActions.types.STORE_SET_ITEM_DETAILS),
    failed: take(contactsActions.types.DISPATCH_ERROR),
    cancel: take(BottomPanelActionTypes.BOTTOM_PANEL_HIDE),
  })
  if (succeed) {
    const contact = succeed.item
    if (context !== undefined) {
      switch (context) {
        case BottomPanelContactsContext.PATIENT_CONTACT:
          // Ajout du patient créé dans la liste des patients
          yield put(bottomPanelContactsActions.contactSuccessfullyUpdated(contact))
          break
        case BottomPanelContactsContext.DOCUMENT_CONTACT:
          // Ajout du contact au document en cours d'édiion
          const inUseDocumentInstance: ReturnType<
            typeof inUseMedicalEventDocumentSelector
          > = yield select(inUseMedicalEventDocumentSelector)
          // Gestion de l'unicité dans les contacts
          if (inUseDocumentInstance && inUseDocumentInstance.type === 'farte') {
            const oldContact = inUseDocumentInstance.contacts.find(({ id }) => contact.id === id)
            if (!oldContact) {
              const contacts = [
                ...inUseDocumentInstance.contacts,
                mapContactToRecipientContact(contact),
              ]
              yield put(
                documentInstancesActions.actions.apiUpdateItem(inUseDocumentInstance.id, {
                  contacts,
                  type: inUseDocumentInstance.type,
                }),
              )
            } else if (oldContact) {
              const newContact: RecipientContact = {
                ...mapContactToRecipientContact(contact),
                assignedDoctor: oldContact.assignedDoctor,
                addressingDoctor: oldContact.addressingDoctor,
                mainRecipient: oldContact.mainRecipient,
              }
              const newContactsArray = inUseDocumentInstance.contacts.map((item) => {
                if (item.id === oldContact.id) {
                  return { ...oldContact, ...newContact }
                }
                return item
              })
              yield put(
                documentInstancesActions.actions.apiUpdateItem(inUseDocumentInstance.id, {
                  contacts: newContactsArray,
                  type: inUseDocumentInstance.type,
                }),
              )
            }
          }
          break
      }
    }
  }
  // Close bottom panel
  yield put(hideBottomPanel())
}

function* editContactsWatcher() {
  yield takeEvery(DomainContactsActionTypes.EDIT_CONTACT, editContactsWorker)
}

export const domainContactsSagas = {
  editContactsWatcher,
}
