export enum PatientSex {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  UNKNOWN = 'UNKNOWN',
}

export enum Title {
  MR = 'MR',
  MS = 'MS',
  NONE = 'NONE',
}

export enum Civility {
  SHORT = 'short',
  LONG = 'long',
}

export const CHILD_LIMIT_AGE = 16
export const CHILD_CIVILITY = 'Enfant'

export const SEX_LABELS: { [key in PatientSex]: string } = {
  [PatientSex.MALE]: 'Homme',
  [PatientSex.FEMALE]: 'Femme',
  [PatientSex.UNKNOWN]: 'Indéterminé',
}

export const SEX_LABELS_M_F_I: { [key in PatientSex]: string } = {
  [PatientSex.MALE]: 'Masculin',
  [PatientSex.FEMALE]: 'Féminin',
  [PatientSex.UNKNOWN]: 'Indéterminé',
}

export const CIVILITY_LABELS: { [key in Title]: string } = {
  [Title.MR]: 'Monsieur',
  [Title.MS]: 'Madame',
  [Title.NONE]: '',
}

export const CIVILITY_LABELS_ABBREVIATIONS: { [key in Title]: string } = {
  [Title.MR]: 'M.',
  [Title.MS]: 'Mme',
  [Title.NONE]: '',
}

export const OBJECT_PRONOUN: { [key in PatientSex]: string } = {
  [PatientSex.MALE]: 'lui',
  [PatientSex.FEMALE]: 'elle',
  [PatientSex.UNKNOWN]: 'Indéterminé',
}

export enum IdentityState {
  TEMPORARY = 'TEMPORARY',
  VALIDATED = 'VALIDATED',
  INSI_CHECKED = 'INSI_CHECKED',
  QUALIFIED = 'QUALIFIED',
}

export enum TrustedSource {
  SOCIAL_SECURITY_CARD = 'socialSecurityCard',
  ID_CARD = 'idCard',
  E_DOCUMENT = 'eDocument',
  DRIVER_LICENSE = 'driverLicense',
  RESIDENCE_PERMIT = 'residencePermit',
  PASSPORT = 'passport',
  TRUSTED_CONTACT = 'trustedContact',
  MANUAL_VALIDATION = 'manualValidation',
  OTHER = 'other',
}
