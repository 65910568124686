import { connect } from 'react-redux'
import { RootState } from '../../../store'
import { domainPosologyActions } from '../../../store/domain/posology'
import { posologyPreviewSelector } from '../../../store/domain/posology/posology.selectors'
import { PosologyForm } from './PosologyForm.component'
import { PosologyFormStoreProps } from './PosologyForm.model'
import { domainPrescriptionTemplateActions } from '../../../store/domain/prescriptionTemplate'

const mapStateToProps = (state: RootState): PosologyFormStoreProps => ({
  posologyPreview: posologyPreviewSelector(state),
})

const mapDispatchToProps = {
  getPosologyPreview: domainPosologyActions.getPreview,
  clearPosologyPreview: domainPosologyActions.clearPreview,
  createPrescriptionTemplate: domainPrescriptionTemplateActions.createPrescriptionTemplate,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)(PosologyForm)
export { withConnect as PosologyForm }
