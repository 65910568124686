export enum Module {
  HEALTH_DATA = 'health_data',
  GYNECOLOGICAL = 'gynecological',
  GYNECO_OBSTETRIC = 'gyneco_obstetric',
  PREGNANCY = 'pregnancy',
}

export enum HealthDataCode {
  size = 'size',
  weight = 'weight',
  liverFailure = 'liver_failure',
  dfg = 'dfg',
  breastfeeding = 'breastfeeding',
  breastfeeding_date = 'breastfeeding_date',
  pregnancy = 'pregnancy',
  pregnancy_date = 'pregnancy_date',
  lastPeriod = 'last_period_at',
  bmi = 'bmi',
  bloodType = 'blood_type',
  systolicPressure = 'systolic_pressure',
  diastolicPressure = 'diastolic_pressure',
  bloodPressure = 'blood_pressure',
  gravidity = 'gravidity',
  parity = 'parity',
  number_of_children = 'number_of_children',
  comment = 'comment',
  lastSmear = 'last_smear_at',
  lastHivTest = 'last_hiv_test_at',
  lastHpvTest = 'last_hpv_test_at',
  lastMammography = 'last_mammography_at',
  contraceptionMethod = 'contraception_method',
  pregnancyType = 'pregnancy_type',
  twinPregnancy = 'twin_pregnancy',
  lastMenstrualPeriod = 'last_menstrual_period',
  pregnancyStart = 'pregnancy_start',
  dueDate = 'due_date',
  pathologicalLeaveStart = 'pathological_leave_start',
  maternityLeaveStart = 'maternity_leave_start',
  maternityLeaveEnd = 'maternity_leave_end',
  pregnancyEnd = 'pregnancy_end',
  pregnancyComment = 'pregnancy_comment',
  pregnancyEntity = 'pregnancy_entity',
  isBreastfeeding = 'is_breastfeeding',
  breastfeedingStart = 'breastfeeding_start',
  amenorrhea = 'amenorrhea',
}

export enum PregnancyType {
  UNIQUE = 'Unique',
  GEMELLAIRE = 'Gémellaire',
}

export const LIVER_FAILURE_VALUES = ['Aucune', 'Modérée', 'Sévère'] as const

export const CONTRACEPTION_VALUES = [
  'Pilule estroprogestative',
  'Pilule progestative',
  'Dispositif intra-utérin au lévonorgestrel',
  'Dispositif intra-utérin au cuivre',
  'Préservatifs masculins',
  'Spermicides',
  'Diaphragme et spermicides',
  'Cape cervicale',
  'Méthodes naturelles',
  'Implants',
  'Vasectomie',
  'Ligature des trompes',
] as const

export const TWIN_PREGNANCY_TYPE_VALUES = [
  'Bichoriale biamniotique',
  'Monochoriale biamniotique',
  'Monochoriale monoamiotique',
] as const

export const BLOOD_TYPE_OPTIONS = [
  { label: 'A positif (A+)', value: 'A+' },
  { label: 'A négatif (A-)', value: 'A-' },
  { label: 'B positif (B+)', value: 'B+' },
  { label: 'B négatif (B-)', value: 'B-' },
  { label: 'AB positif (AB+)', value: 'AB+' },
  { label: 'AB négatif (AB-)', value: 'AB-' },
  { label: 'O positif (O+)', value: 'O+' },
  { label: 'O négatif (O-)', value: 'O-' },
] as const
