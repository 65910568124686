import { BaseVariableId, BaseVariableIdType, FollowTypes } from '@follow/cdk'
import { DrugIdentity } from './Drug'
import { Posology } from './Posology'

export type PrescriptionLight = FollowTypes.Prescription.PrescriptionLight
export type Prescription = FollowTypes.Prescription.Prescription

// Informations nécessaires à l'insertion de Drugs en tant que variable Farte
export interface PrescriptionVariable {
  uuid?: string
  drugs: DrugIdentity[]
  isAld: boolean | undefined
  prescriptionVariableUuid: string
}

export interface PrescriptionDocumentUpdate extends Partial<Omit<Prescription, 'drugs'>> {
  drugs: DrugIdentity[]
}

export type PrescriptionUpdate = SafeOmit<Prescription, 'posologyIntervals' | 'prescriber'> & {
  posologyIntervals: Partial<Posology>[]
  prescriberId: string | null
}

export type PrescriptionPosologyUpdate = {
  posologyIntervals: Partial<Posology>[]
}

export const PRESCRIPTION_MANDATORY_VARIABLES: Array<{
  label: string
  variables: Array<BaseVariableIdType>
}> = [
  {
    label: 'Nom de naissance',
    variables: [
      BaseVariableId.PATIENT_NAME,
      BaseVariableId.PATIENT_BIRTH_NAME_AND_USED_NAME,
      BaseVariableId.PATIENT_BIRTH_NAME,
      BaseVariableId.PATIENT_BIRTH_LASTNAME,
    ],
  },
  {
    label: 'Prénom de naissance',
    variables: [
      BaseVariableId.PATIENT_NAME,
      BaseVariableId.PATIENT_BIRTH_NAME_AND_USED_NAME,
      BaseVariableId.PATIENT_BIRTH_NAME,
      BaseVariableId.PATIENT_BIRTH_FIRSTNAME,
    ],
  },
  {
    label: 'Date de naissance',
    variables: [BaseVariableId.BIRTH_DATE, BaseVariableId.BIRTH_DATE_NO_HASH],
  },
  {
    label: 'Matricule INS',
    variables: [BaseVariableId.SECU_NUMBER, BaseVariableId.SECU_NUMBER_REDUCED],
  },
  { label: 'Sexe', variables: [BaseVariableId.PATIENT_SEX] },
]
