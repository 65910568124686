import React, { FC, useCallback, useMemo, useState } from 'react'
import { IndicationSelectProps } from './IndicationSelect.model'
import { DropdownItem, SearchInput } from '../../shared'
import { SelectOption } from '../../../model/SelectOption'
import { Indications } from '../../../model/Drug'
import { useDebounce } from 'react-use'

const convertActivePrinciplesIntoOption = (item: Indications) => ({
  label: item.name,
  value: item.externalId,
})

export const IndicationsSelect: FC<IndicationSelectProps> = ({
  indicationList,
  searchIndications,
  setIndicationList,
  setIndicationSelected,
  resetDrugsList,
}) => {
  const clearList = useCallback(() => {
    setIndicationList([])
  }, [setIndicationList])

  const [search, setSearch] = useState('')

  const indicationOptions = useMemo(() => {
    if (indicationList) {
      const options = indicationList.map(convertActivePrinciplesIntoOption)
      return options
    }
  }, [indicationList])

  const handleSelect = useCallback(
    (selected: SelectOption<number>) => {
      setIndicationSelected({ externalId: selected.value, name: selected.label })
      setSearch(selected.label)
      clearList()
    },
    [setIndicationSelected, clearList],
  )

  useDebounce(
    () => {
      if (search.length > 2) {
        searchIndications(search)
      } else {
        clearList()
        setIndicationSelected(undefined)
        resetDrugsList()
      }
    },
    500,
    [search],
  )

  return (
    <SearchInput
      placeholder="3 caractères min"
      colorPreset="dark"
      value={search}
      results={indicationOptions}
      onSelect={handleSelect}
      renderResult={(option, isHovered) => (
        <DropdownItem selected={isHovered}>
          <div className="max-w-full flex items-center whitespace-normal">
            <span className="capitalize">{option.label}</span>
          </div>
        </DropdownItem>
      )}
      onChange={({ target: { value } }) => {
        setSearch(value)
      }}
    />
  )
}
