import { ClipboardActionTypes, ClipboardContentType } from './clipboard.model'

const copyHtmlToClipboard = (html: string) =>
  copyContentToClipboard(html, ClipboardContentType.TEXT_HTML)

const copyTextToClipboard = (text: string) =>
  copyContentToClipboard(text, ClipboardContentType.TEXT_PLAIN)

const copyContentToClipboard = (
  content: string,
  contentType: ClipboardContentType = ClipboardContentType.TEXT_PLAIN,
) => ({
  type: ClipboardActionTypes.COPY_CONTENT,
  content,
  contentType,
})

export const clipboardActions = { copyHtmlToClipboard, copyTextToClipboard, copyContentToClipboard }
