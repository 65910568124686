import { DEFAULT_FONT_FAMILY, FontFamily } from '@/model/Font'
import React, { useMemo } from 'react'

export const useDefaultFontFamily = (fontFamily: FontFamily = DEFAULT_FONT_FAMILY) => {
  const style = useMemo(
    () =>
      ({
        '--elfe-default-font-family': fontFamily,
      }) as React.CSSProperties,
    [fontFamily],
  )

  return { style }
}
