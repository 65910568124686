import { connect } from 'react-redux'
import { domainPosologyActions } from '../../../store/domain/posology'
import { DocumentPosology } from './DocumentPosology.component'
import { DocumentPosologyDispatchProps } from './DocumentPosology.model'
import { uiDocumentPosologyActions } from '../../../store/ui/documentPosology'

const mapDispatchToProps: DocumentPosologyDispatchProps = {
  batchPosology: domainPosologyActions.batchPosology,
  updatePrescriptionAndCreateTemplate:
    uiDocumentPosologyActions.updatePrescriptionAndCreateTemplate,
}

const withConnect = connect(null, mapDispatchToProps)(DocumentPosology)
export { withConnect as DocumentPosology }
