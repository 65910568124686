import {
  bottomPanelContactsConfig,
  BottomPanelContactsContext,
  UiBottomPanelContactsActionTypes,
} from './bottomPanelContacts.model'
import { uiActionsFactory } from '../../restux/ui'
import { Contact } from '../../../model/Contact'

const uiActions = uiActionsFactory({
  ...bottomPanelContactsConfig,
}).actions

const setReadOnly = (readOnly: boolean) => ({
  type: UiBottomPanelContactsActionTypes.SET_READ_ONLY,
  readOnly,
})

const setContext = (context: BottomPanelContactsContext) => ({
  type: UiBottomPanelContactsActionTypes.SET_CONTEXT,
  context,
})

const openCreateContactForm = (
  context: BottomPanelContactsContext,
  onCreate?: (contact: Contact) => void,
) => ({
  type: UiBottomPanelContactsActionTypes.OPEN_CREATE_CONTACT_FORM,
  context,
  onCreate,
})

const switchToPrivateCopyMode = () => ({
  type: UiBottomPanelContactsActionTypes.SWITCH_PRIVATE_COPY_MODE,
})

const createPrivateContact = (
  contact: Partial<SafeOmit<Contact, 'id'>>,
  context: BottomPanelContactsContext,
  privateCopyofPublicContact: boolean,
) => ({
  type: UiBottomPanelContactsActionTypes.CREATE_PRIVATE_COPY,
  contact,
  context,
  privateCopyofPublicContact,
})

const openEditContactForm = (
  contactId: string,
  readOnly = false,
  context?: BottomPanelContactsContext,
  onEdit?: (contact: Contact, publicContactId?: string) => void,
) => ({
  type: UiBottomPanelContactsActionTypes.OPEN_EDIT_CONTACT_FORM,
  contactId,
  readOnly,
  context,
  onEdit,
})

const contactSuccessfullyUpdated = (contact: Contact) => ({
  type: UiBottomPanelContactsActionTypes.CONTACT_SUCCESFULLY_UPDATED,
  contact,
})

export const bottomPanelContactsActions = {
  ...uiActions,
  openCreateContactForm,
  openEditContactForm,
  setReadOnly,
  setContext,
  switchToPrivateCopyMode,
  createPrivateCopy: createPrivateContact,
  contactSuccessfullyUpdated,
}
