import React, { FunctionComponent } from 'react'
import styles from './FormBlock.module.scss'
import { FormBlockProps } from './FormBlock.model'
import { FormHeading } from '../FormHeading'
import { ClickableLink } from '../../ClickableLink'

export const FormBlock: FunctionComponent<FormBlockProps> = ({
  label,
  icon,
  children,
  linkLabel,
  onLinkClick,
}) => {
  return (
    <div className={styles.block}>
      <div className={styles.titleContainer}>
        <FormHeading headings={[{ icon, label }]} />
        <div className={styles.spacer}>
          {linkLabel && <ClickableLink label={linkLabel} onClick={onLinkClick} />}
        </div>
      </div>
      <div className={styles.blockContent}>{children}</div>
    </div>
  )
}
