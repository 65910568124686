import { capitalize } from 'lodash'
import { InsiConnectInsResponse } from '../model/Insi'
import { Sex } from '../model/Patient'
import { IdentityCheckPayload, InsiPatient, InsiPatientErrorFields } from '../store/domain/insi'
import { parseApiFormat, formatFr, DATE_FORMAT_FRONT_SHORT } from './date.utilities'
import { isDefined } from './functions.utilities'
import { isValid } from 'date-fns'
import { PatientFormFields } from '../components/patient/PatientForm/PatientForm.model'
import { MailPatient } from '../model/Mail'

export const insiConsultSerializePatient = (patient: IdentityCheckPayload) => {
  const date = parseApiFormat(patient.birthDate)
  const formatedDate = formatFr(date, DATE_FORMAT_FRONT_SHORT)

  return {
    name: patient.birthLastName,
    given: patient.birthFirstName,
    birthday: formatedDate,
    sex: patient.sex === Sex.MALE ? '2' : '3',
    birthplace: patient.birthPlaceCode,
  }
}

export const insiSexMapper = (insiSex: 'F' | 'M') => {
  switch (insiSex) {
    case 'M':
      return Sex.MALE
    case 'F':
      return Sex.FEMALE
    default:
      return Sex.UNKNOWN
  }
}

export const insiConsultPatientMapper = (response: InsiConnectInsResponse): InsiPatient | null => {
  if (!response.error && response.Ins) {
    const { sex, birthDate, birthPlace } = response
    const { nir, oid, key } = response.Ins
    const lastname: string = response.name
    const firstnameList: string = response.birthGiven
    const [firstname, ...otherFirstnames] = firstnameList.split(' ').map(capitalize)

    return {
      birthFirstName: firstname,
      birthLastName: lastname.toUpperCase(),
      usedFirstName: null,
      otherFirstName: [firstname, ...otherFirstnames].join(' '),
      sex: insiSexMapper(sex),
      birthDate: birthDate,
      birthPlaceCode: birthPlace,
      assigningAuthority: { label: '', oid: oid },
      inseeNumber: nir + key,
    }
  } else {
    return null
  }
}

export const insiPatientSanityCheck = ({
  birthDate,
  birthFirstName,
  birthLastName,
  sex,
}: InsiPatient): InsiPatientErrorFields[] => {
  let errors: InsiPatientErrorFields[] = []
  if (!isDefined(birthDate) || !isValid(new Date(birthDate))) {
    errors.push('birthDate')
  }
  if (!isDefined(birthFirstName) || birthFirstName === '') {
    errors.push('birthFirstName')
  }
  if (!isDefined(birthLastName) || birthLastName === '') {
    errors.push('birthLastName')
  }
  if (!isDefined(sex) || sex === Sex.UNKNOWN) {
    errors.push('sex')
  }

  return errors
}

export const patientToInsiRestPatientPayload = (
  patient: MailPatient | PatientFormFields,
): IdentityCheckPayload | null => {
  const { birthFirstName, birthLastName, sex, birthPlaceCode, birthDate } = patient

  if (isDefined(sex))
    return {
      birthFirstName,
      birthLastName,
      birthDate,
      sex,
      birthPlaceCode: birthPlaceCode ?? undefined,
    }
  return null
}
