import { MailClassificationAttachment, MailClassificationCdaAttachment } from '../../../model/Mail'
import { CustomFile } from '../../../model/File'
import { getExtension, removeExtension } from '../../../misc/files.utilities'
import { CdaFile } from '../../../model/CdaContext'

export const mapMailAttachmentsToSelectedAttachments = (
  attachments: CustomFile[],
): MailClassificationAttachment[] =>
  attachments.map(
    (file: CustomFile): MailClassificationAttachment => mapMailAttachmentToSelectedAttachment(file),
  )

export const mapMailAttachmentToSelectedAttachment = (
  file: CustomFile,
): MailClassificationAttachment => ({
  id: file.id,
  visibleName: file.visibleName,
  classifyVisibleName: removeExtension(file.visibleName),
  extension: file.extension,
  cdaContent: file.cdaContent?.map(
    (cdaFile: CdaFile): MailClassificationCdaAttachment => ({
      id: cdaFile.id,
      visibleName: cdaFile.visibleName,
      classifyVisibleName: removeExtension(cdaFile.visibleName),
      extension: getExtension(cdaFile.visibleName),
    }),
  ),
})
