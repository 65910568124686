import { RootState } from '../../../store/reducers/reducers.model'
import { AtcClassSelectDispatchProps, AtcClassSelectStoreProps } from './AtcClassSelect.model'
import { connect } from 'react-redux'
import { AtcClassSelect } from './AtcClassSelect.component'
import {
  atcClassificationPageSelector,
  inUseAtcClassSelector,
} from '../../../store/ui/atcClassification/atcClassification.selectors'
import { uiAtcClassificationActions } from '../../../store/ui/atcClassification'

const mapStoreToProps = (state: RootState): AtcClassSelectStoreProps => ({
  atcClasses: atcClassificationPageSelector(state),
  inUseClass: inUseAtcClassSelector(state),
})

const mapDispatchToProps: AtcClassSelectDispatchProps = {
  getAtcClasses: uiAtcClassificationActions.requestPage,
  getClassChildren: uiAtcClassificationActions.getClassChildren,
  setInUseClass: uiAtcClassificationActions.useId,
}

const withConnect = connect(mapStoreToProps, mapDispatchToProps)(AtcClassSelect)
export { withConnect as AtcClassSelect }
