import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'
import { frequencyTimeUnits, timeUnitConfiguration, timeUnitLabels } from '../PosologyDetail.model'
import { DosageBlockProps } from './DosageBlock.model'
import {
  HorizontalScroller,
  LabeledButton,
  SliderInput,
  EntityCard,
  Chip,
  FormHeading,
} from '../../../../shared'
import { PosologyDosages } from '../../../../../model/Posology'
import { MomentDosageField } from './MomentDosageField'
import { isDefined } from '../../../../../misc/functions.utilities'
import { DosageQuantityInput } from './DosageQuantityInput'

export const DosageBlock: FunctionComponent<DosageBlockProps> = ({
  posologyForm,
  disabled,
  setPosologyForm,
  drugs,
}) => {
  const [dayTimesSelected, setDayTimesSelected] = useState(
    !isDefined(posologyForm.dosages[0].recurrenceType),
  )

  const timeConfiguration = useMemo(
    () => timeUnitConfiguration[posologyForm.dosages[0]?.recurrenceType ?? 'day'],
    [posologyForm.dosages],
  )

  const onMomentChange = useCallback(
    (dosages: PosologyDosages[]) => {
      if (dayTimesSelected) {
        const sanitizedDosages = dosages.map((dosageItem) => ({
          ...dosageItem,
          recurrenceType: null,
          recurrenceValue: null,
        }))
        setPosologyForm({
          ...posologyForm,
          dosages: sanitizedDosages,
        })
      }
    },
    [dayTimesSelected, posologyForm, setPosologyForm],
  )

  const onAddMoment = useCallback(
    () =>
      onMomentChange([
        ...posologyForm.dosages,
        {
          quantities: posologyForm.dosages[0].quantities.map((quantity) => ({
            ...quantity,
            value: 0,
          })),
          dayParity: null,
          recurrenceType: null,
          recurrenceValue: null,
          moment: null,
        },
      ]),
    [onMomentChange, posologyForm.dosages],
  )

  const onReccurencyChange = useCallback(
    (partialDosage: Partial<PosologyDosages>) => {
      if (!dayTimesSelected) {
        if (partialDosage.recurrenceType) {
          // Si changement unité de temps, on set la valeur au minimum par défaut
          partialDosage.recurrenceValue = timeUnitConfiguration[partialDosage.recurrenceType].min
        }
        const dosage: PosologyDosages = {
          ...posologyForm.dosages[0],
          ...partialDosage,
          moment: null,
          dayParity: null,
        }
        setPosologyForm({
          ...posologyForm,
          dosages: [dosage],
        })
      }
    },
    [dayTimesSelected, posologyForm, setPosologyForm],
  )

  return (
    <EntityCard loading={false} inactive={false}>
      <div className="p-5 w-full">
        <div className="pb-5">
          <FormHeading
            disabled={disabled}
            headings={[
              {
                icon: 'sun',
                label: 'Moment',
                selected: dayTimesSelected,
                onSelect: () => setDayTimesSelected(true),
              },
              {
                icon: 'sun',
                label: 'Espacement entre les prises',
                selected: !dayTimesSelected,
                onSelect: () => setDayTimesSelected(false),
              },
            ]}
          />
          {dayTimesSelected && (
            <div className="mt-4">
              {posologyForm.dosages.map((dosage, index) => (
                <MomentDosageField
                  key={index}
                  index={index}
                  drugs={drugs}
                  momentDosage={posologyForm.dosages}
                  dosage={dosage}
                  setMomentDosage={onMomentChange}
                  disabled={disabled}
                  divisibility={drugs[0].divisibility}
                />
              ))}
              <div className="mt-8">
                <LabeledButton
                  icon="add"
                  label="Ajouter un moment"
                  onClick={onAddMoment}
                ></LabeledButton>
              </div>
            </div>
          )}
          {!dayTimesSelected && (
            <>
              <div className="mt-4 w-1/2">
                {posologyForm.dosages[0].quantities.map((quantity) => (
                  <div className="mb-4">
                    <DosageQuantityInput
                      label={`Quantité ${
                        drugs.length > 1 ? drugs.find((drug) => drug.id === quantity.id)?.name : ''
                      }`}
                      quantityValue={quantity.value ?? 0}
                      divisibility={drugs[0].divisibility}
                      disabled={disabled}
                      onChange={(value) =>
                        onReccurencyChange({
                          quantities: posologyForm.dosages[0].quantities.map((_quantity) =>
                            _quantity.id === quantity.id ? { ...quantity, value } : _quantity,
                          ),
                        })
                      }
                    />
                  </div>
                ))}
              </div>
              <div className="mt-4">
                <HorizontalScroller
                  renderTitle={() => (
                    <span className="text-xs text-shades-4 font-medium">Unité de temps</span>
                  )}
                >
                  <div className="flex flex-row py-2 space-x-2">
                    {frequencyTimeUnits.map((unit) => (
                      <Chip
                        colorPreset="dark"
                        label={timeUnitLabels[unit] ?? unit}
                        key={unit}
                        onClick={() =>
                          onReccurencyChange({
                            recurrenceType: unit,
                          })
                        }
                        selected={unit === posologyForm.dosages[0]?.recurrenceType}
                        renderLabel={(label) => <span>{label}</span>}
                        disabled={disabled}
                        testId={`posology-dosage-unit-${unit}`}
                      />
                    ))}
                  </div>
                </HorizontalScroller>
              </div>
              <div className=" flex flex-col w-full">
                <SliderInput
                  appearance="complete"
                  value={posologyForm.dosages[0]?.recurrenceValue ?? undefined}
                  min={timeConfiguration.min}
                  max={timeConfiguration.max}
                  step={timeConfiguration.delta}
                  onChange={(value) =>
                    onReccurencyChange({
                      recurrenceValue: value,
                    })
                  }
                />
              </div>
            </>
          )}
        </div>
      </div>
    </EntityCard>
  )
}
