import { call, put, select, takeEvery } from 'redux-saga/effects'
import { IdentityCheckResponse, InsiDomainActionType } from './insi.model'
import { insiDomainActions } from './insi.actions'
import { ApiResponse } from 'apisauce'
import { checkPatientIdentity, logInsi } from './api'
import { addError, addValid } from '../../message'
import { enabledFeaturesPreferencesSelector } from '../me'
import { Features } from '../../../model/Preference'
import { insiConsultSerializePatient, insiPatientSanityCheck } from '../../../misc/insi.utilities'
import { InsiConsultActions } from '../../../model/Insi'
import { isDefined } from '../../../misc/functions.utilities'

function* checkPatientIdentityWorker({
  patient,
}: ReturnType<typeof insiDomainActions.checkPatientIdentity>) {
  const enabledFeatures: Features | null = yield select(enabledFeaturesPreferencesSelector)

  if (enabledFeatures?.insiValidation === 'insiValidationInApp') {
    if (isDefined(patient)) {
      yield put(
        insiDomainActions.setRequest({
          exportId: InsiConsultActions.PATIENT_FORM,
          search: insiConsultSerializePatient(patient),
        }),
      )
    } else {
      yield put(
        insiDomainActions.setRequest({
          exportId: InsiConsultActions.PATIENT_FORM,
        }),
      )
    }
  } else if (enabledFeatures?.insiValidation === 'insiValidationRest' && isDefined(patient)) {
    const response: ApiResponse<IdentityCheckResponse> = yield call(checkPatientIdentity, patient)

    if (response.ok && response.data) {
      const { status, ...patient } = response.data
      yield put(insiDomainActions.setPatientResponse(status === 'FOUND' ? patient : undefined))
    } else {
      yield put(
        addError(
          'Erreur',
          "Une erreur est survenue lors de la vérification de l'identité du patient",
        ),
      )
    }
  }
}

function* checkPatientIdentityWatcher() {
  yield takeEvery(InsiDomainActionType.CHECK_PATIENT_IDENTITY, checkPatientIdentityWorker)
}

function* setPatientResponseWorker({
  patient,
}: ReturnType<typeof insiDomainActions.setPatientResponse>) {
  if (patient) {
    const sanityCheckErrors = insiPatientSanityCheck(patient)

    if (sanityCheckErrors.length === 0) {
      yield put(
        addValid(
          'Succès',
          "Les informations d'identité du patient ont été validées et récupérées depuis le service INSi. (Code retour INSI: 00)",
        ),
      )
    } else {
      const err = sanityCheckErrors.at(0)
      const fieldName =
        (err === 'birthDate' && 'La date de naissance') ||
        (err === 'birthFirstName' && 'Le prénom de naissance') ||
        (err === 'birthLastName' && 'Le nom de naissance') ||
        (err === 'sex' && 'Le sexe') ||
        'Un champ'

      yield put(
        addError(
          sanityCheckErrors.length === 1
            ? `${fieldName} est invalide`
            : 'Plusieurs informations sont invalides',
          "Le service INSi a retourné une identité contenant des erreurs. Elle n'a pas été rapatriée dans le dossier patient. (Code retour INSI: 01)",
        ),
      )
    }
  } else {
    yield put(
      addError(
        'Erreur',
        "Les informations d'identité du patient n'ont pas été validées par le service INSi. (Code retour INSI: 01)",
      ),
    )
  }
}

function* setPatientResponseWatcher() {
  yield takeEvery(InsiDomainActionType.SET_PATIENT_RESPONSE, setPatientResponseWorker)
}

function* logInsiWorker({ payload }: ReturnType<typeof insiDomainActions.logInsi>) {
  yield call(logInsi, payload)
}

function* logInsiWatcher() {
  yield takeEvery(InsiDomainActionType.LOG_INSI, logInsiWorker)
}

export const insiDomainSagas = {
  checkPatientIdentityWatcher,
  setPatientResponseWatcher,
  logInsiWatcher,
}
