import { QueryClient, useQuery, useQueryClient } from '@tanstack/react-query'
import { codedInformationsKeys } from './codedInformations.keys'
import { getCodedInformationsDetail } from '../../../data/patientCodedInformations'
import { UserFriendlyError } from '../../../model/Error'
import {
  CodedInformations,
  SearchCodedInformationsResponse,
} from '../../../model/CodedInformations'

interface GetCodedInformationsParams {
  codedInfoId: string
  enabled?: boolean
  groupId?: string
  search?: string
}

const getPlaceholderData = (
  queryClient: QueryClient,
  codedInfoId: string,
  groupId?: string,
  search?: string,
) => {
  const data = queryClient.getQueryData<SearchCodedInformationsResponse>(
    groupId
      ? codedInformationsKeys.searchByGroup(groupId)
      : codedInformationsKeys.searchByName(search!),
  )

  return (
    data &&
    Object.values(data)
      .flat()
      .find((item) => item.id === codedInfoId)
  )
}

export const useGetCodedInformations = ({
  codedInfoId,
  enabled = true,
  search,
  groupId,
}: GetCodedInformationsParams) => {
  const queryClient = useQueryClient()

  const queryKey = codedInformationsKeys.detail(codedInfoId)

  const queryResult = useQuery<CodedInformations>(
    queryKey,
    async () => {
      const { data, ok } = await getCodedInformationsDetail(codedInfoId)
      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          `Impossible de récupérer les données sur l'allergie`,
          `Une erreur technique s'est produite`,
        )
      }
      return data
    },
    {
      enabled,
      placeholderData: () => getPlaceholderData(queryClient, codedInfoId, groupId, search),
    },
  )

  return { query: queryResult }
}
