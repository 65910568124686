import React, { FunctionComponent } from 'react'
import { Checkbox } from '../../../shared'
import { MailClassificationAttachmentItemProps } from './MailClassificationAttachmentItem.model'

export const MailClassificationAttachmentItem: FunctionComponent<MailClassificationAttachmentItemProps> = ({
  item,
  checked,
  selectItem,
}) => (
  <div className="flex items-center max-w-80">
    <Checkbox checked={checked} onChange={() => selectItem(item)} />
    <span className="text-sm font-medium text-shades-2 ml-2 truncate" title={item.visibleName}>
      {item.visibleName}
    </span>
  </div>
)
