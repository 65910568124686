import getApi from '../../../api/apisauceConfig'
import { singleItemPageLimit } from '../../../constants'
import { Filters } from '../../../model/Filters'
import { PaginatedList } from '../../../model/Pagination'
import { User } from '../../../model/User'

export const getUsers = (
  page: number,
  filters?: Filters,
  signal?: AbortSignal,
  limit = singleItemPageLimit,
) =>
  getApi().get<PaginatedList<User>>(
    '/admin/users',
    {
      page,
      limit,
      ...filters,
    },
    { signal },
  )
