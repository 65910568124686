export enum PluginNames {
  ImageResize = 'imageResize',
  FontFamily = 'fontFamily',
  FontSize = 'fontSize',
  PageBreak = 'pageBreak',
  CursorControl = 'cursorControl',
  TextColor = 'textColor',
  TextStyle = 'textStyle',
  LineHeight = 'lineHeight',
  BlockSpacing = 'blockSpacing',
  Indent = 'indent',
  Table = 'table',
  TableHeader = 'tableHeader',
  ClassName = 'className',
  Variable = 'variable',
  VariableFlatten = 'variableFlatten',
  VariableFlattenBlock = 'variableFlattenBlock',
  VariableClass = 'variableClass',
}
