import { Metric, MetricsTypeName } from '../../../../model/Metrics'

export enum MetricsTypeNameApi {
  message_sent = 'message_sent',
  message_sent_to_doctor = 'message_sent_to_doctor',
  message_sent_to_patient = 'message_sent_to_patient',
  dmp_document_sent_to_patient = 'dmp_document_sent_to_patient',
}
export interface MetricsParams {
  start: Date
  end: Date
  types?: MetricsTypeName
}

export interface MetricsParamsApi {
  start: string
  end: string
  types?: MetricsTypeNameApi
}

export type MetricsSendingInfosApi = Record<MetricsTypeNameApi, Metric>
