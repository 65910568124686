import { Node, mergeAttributes, nodeInputRule } from '@tiptap/core'
import { PluginNames } from '@/model/Plugins'
import './PageBreak.scss'

export interface PageBreakOptions {
  HTMLAttributes: Record<string, any>
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    [PluginNames.PageBreak]: {
      /**
       * Adds a page break
       */
      setPageBreak: () => ReturnType
    }
  }
}

export const PageBreak = Node.create<PageBreakOptions>({
  name: PluginNames.PageBreak,

  addOptions() {
    return {
      HTMLAttributes: {
        role: 'pagebreak',
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'div',
        getAttrs: (node: HTMLElement) => {
          return node.role === 'pagebreak' && null
        },
      },
    ]
  },

  group: 'block',

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)]
  },

  addCommands() {
    return {
      setPageBreak:
        () =>
        ({ chain }) => {
          return chain().insertContent({ type: this.name }).run()
        },
    }
  },

  addInputRules() {
    return [
      nodeInputRule({
        find: /^(?:---|—-|___\s|\*\*\*\s)$/,
        type: this.type,
      }),
    ]
  },
})
