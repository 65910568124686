import { Patient, TrustedSource } from '../../../../model/Patient'
import { SelectOption } from '../../../../model/SelectOption'
import { ImpersonatePermissions } from '../../../../model/User'
import { insiDomainActions, InsiStatus } from '../../../../store/domain/insi'
import { InsiPatient } from '../../../../store/domain/insi/insi.model'
import { Features } from '../../../../model/Preference'

export interface IdentityStatusBlockOwnProps {
  impersonatePermissions: ImpersonatePermissions | null
  patient: Partial<Patient> | Patient | null
}

export interface IdentityStatusBlockStoreProps {
  insiStatus: InsiStatus | null
  insiPatient: InsiPatient | null
  enabledFeatures: Features | null
}

export interface IdentityStatusBlockDispatchProps {
  checkPatientIdentity: typeof insiDomainActions.checkPatientIdentity
  clearPatientResponse: typeof insiDomainActions.clearPatientResponse
}

export type IdentityStatusBlockProps = IdentityStatusBlockStoreProps &
  IdentityStatusBlockDispatchProps &
  IdentityStatusBlockOwnProps

export const SHOWN_DOCUMENT_OPTIONS: SelectOption<TrustedSource | null>[] = [
  { value: null, label: 'Aucun' },
  { value: TrustedSource.SOCIAL_SECURITY_CARD, label: 'Carte vitale' },
  { value: TrustedSource.ID_CARD, label: "Carte nationale d'identité" },
  { value: TrustedSource.DRIVER_LICENSE, label: 'Permis de conduire' },
  { value: TrustedSource.RESIDENCE_PERMIT, label: 'Titre de séjour' },
  { value: TrustedSource.E_DOCUMENT, label: "Dispositif d'identification electronique" },
  { value: TrustedSource.PASSPORT, label: 'Passeport' },
  { value: TrustedSource.MANUAL_VALIDATION, label: 'Avis du praticien' },
  { value: TrustedSource.OTHER, label: 'Autre' },
]
