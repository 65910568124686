import getApi from '../../api'
import {
  ApiDocumentLayoutResource,
  DocumentLayoutMutationPayload,
} from '../../model/DocumentLayout'
import { deserializeDocumentLayoutResponse, serializeLayout } from './mapper/documentLayouts.mapper'

export const createDocumentLayout = (payload: DocumentLayoutMutationPayload) =>
  getApi()
    .post<ApiDocumentLayoutResource>(`/document_layouts`, serializeLayout(payload))
    .then(deserializeDocumentLayoutResponse)
