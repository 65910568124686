import React, { FunctionComponent } from 'react'

import { Chip, Heading } from '../../shared'
import { FiltersWrapper } from '../../shared/filters/FiltersWrapper'

import styles from './MedicalEventFilters.module.scss'

export const MedicalEventFiltersPlaceholder: FunctionComponent<{}> = () => (
  <div className={styles.container}>
    <Heading size={3} theme="light">
      {`Chargement`}
    </Heading>
    <FiltersWrapper variance="variant1">
      {Array.from(new Array(3), (_, index) => (
        <Chip
          key={index}
          colorPreset="light"
          label=""
          renderLabel={() => <span className={styles.placeholderLabel} />}
        />
      ))}
    </FiltersWrapper>
  </div>
)
