import { useMutation, useQueryClient } from '@tanstack/react-query'
import { addValid } from '../../../../store/message'
import { useActionDispatch } from '../../../utils'
import { UserFriendlyError } from '../../../../model/Error'
import { postLegalDocument } from '../../../../data/admin/legalDocument'
import { LegalDocument, LegalDocumentCreatePayload } from '../../../../model/LegalDocument'
import { legalDocumentKeys } from '.'

export const usePostLegalDocument = () => {
  const valid = useActionDispatch(addValid)
  const queryClient = useQueryClient()

  const queryReturn = useMutation<LegalDocument, Error, LegalDocumentCreatePayload>(
    async (variables) => {
      const { data, ok, status } = await postLegalDocument(variables)
      if (status === 409) {
        throw new UserFriendlyError('error', 'Un document légal existe déjà avec ce type')
      }

      if (!data || !ok) {
        throw new UserFriendlyError('error', 'La création du document légal a échoué')
      }

      return data
    },
    {
      onSuccess: () => {
        valid('Document légal créé')
        queryClient.refetchQueries(legalDocumentKeys.all)
      },
    },
  )
  return queryReturn
}
