import { call, put, select, takeEvery } from 'redux-saga/effects'
import { domainPosologyActions } from './posology.actions'
import { domainPosologyActionTypes } from './posology.model'
import {
  FwDocumentInstanceWrapper,
  selectedMedicalEventDocumentSelector,
} from '../../ui/medicalEvents'
import { batchPosology, getPreview } from './api'
import { managerInUseDocumentTemplateSelector } from '../../ui/managerDocumentTemplates'
import {
  DocumentTemplateEditorVariantType,
  DocumentTemplate,
} from '../../../model/DocumentTemplate'
import { addError } from '../../message'
import { ApiResponse } from 'apisauce'
import { PosologyPreview } from '../../../model/Posology'
import { documentTemplatesActions } from '../../cache/documentTemplates/index'
import { documentAlertsDomainActions } from '../documentAlerts'
import { uiPatientCurrentTreatmentsActions } from '../../ui/patientCurrentTreatments'
import { documentInstancesActions } from '../../cache/documentInstances'
import {
  medicalEventContentUiActions,
  SelectedContentType,
} from '../../ui/medicalEvents/medicalEventContent'
import { customHistory } from '../../../history'
import { getDocumentTemplateEditorVariantSearchParams } from '../../../pages/ManagerPage/documentTemplate/DocumentTemplateDetailPage/FarteDocumentTemplatePage/FarteDocumentTemplatePage.utilities'

function* batchPosologyWorker({
  prescriptionUuid,
  prescription,
}: ReturnType<typeof domainPosologyActions.batchPosology>) {
  const response = yield call(batchPosology, prescription, prescriptionUuid)
  if (response.ok) {
    const inUseDocumentInstance: FwDocumentInstanceWrapper = yield select(
      selectedMedicalEventDocumentSelector,
    )
    const inUseDocumentTemplate: DocumentTemplate | null = yield select(
      managerInUseDocumentTemplateSelector,
    )
    if (inUseDocumentInstance) {
      yield put(
        medicalEventContentUiActions.selectMedicalEventContent({
          type: SelectedContentType.EDITOR,
        }),
      )
      yield put(documentInstancesActions.actions.apiGetItemDetails(inUseDocumentInstance.item.id))
    } else if (inUseDocumentTemplate) {
      yield put(documentTemplatesActions.actions.apiGetItemDetails(inUseDocumentTemplate.id))
      customHistory.navigate(
        getDocumentTemplateEditorVariantSearchParams({
          type: DocumentTemplateEditorVariantType.EDITOR,
        }),
        { replace: true },
      )
    }
    yield put(uiPatientCurrentTreatmentsActions.getCurrentTreatments())
    yield put(documentAlertsDomainActions.getAlerts())
    yield put(domainPosologyActions.batchSucceed())
  } else {
    yield put(domainPosologyActions.batchFailed())
    yield put(addError('Erreur pendant la sauvegarde', "L'enregistrement de la posologie a échoué"))
  }
}
function* batchPosologyWatcher() {
  yield takeEvery(domainPosologyActionTypes.BATCH, batchPosologyWorker)
}

function* getPosologyPreviewWorker({
  posologyIntervals,
  drugs,
}: ReturnType<typeof domainPosologyActions.getPreview>) {
  const resources = posologyIntervals.map((interval) => ({
    ...interval,
    id: interval.previewId,
  }))
  const response: ApiResponse<PosologyPreview> = yield call(getPreview, resources, drugs)
  if (response.ok && response.data) {
    yield put(domainPosologyActions.setPreview(response.data))
  }
}

function* getPosologyPreviewWatcher() {
  yield takeEvery(domainPosologyActionTypes.GET_PREVIEW, getPosologyPreviewWorker)
}

export const posologySagas = {
  batchPosologyWatcher,
  getPosologyPreviewWatcher,
}
