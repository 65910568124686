import { FC, useCallback, useEffect, useState } from 'react'
import { useEffectWithRef } from '../../../hooks/utils'
import {
  DEFAULT_INCREMENT_OFFSET_MAIL_LIST,
  DEFAULT_LIMIT_MAIL_LIST,
  DEFAULT_OFFSET_MAIL_LIST,
  LightMail,
  SEARCH_MIN_CHAR,
} from '../../../model/Mail'
import {
  List,
  RoundedButton,
  AbstractList,
  Icon,
  IconButton,
  TooltipWrapper,
  SearchInput,
} from '../../shared'
import { MailListProps } from './MailList.model'
import { MailListItem } from './MailListItem'
import Waypoint from 'react-waypoint'
import { ListItem } from '../../shared/List/ListItem'
import styles from './MailList.module.scss'
import { useNavigate, useParams } from 'react-router-dom'
import { isDefined } from '../../../misc/functions.utilities'
import { useDebounce } from 'react-use'
import { EmptyPlaceHolder } from '../../shared/EmptyPlaceHolder'
import classNames from 'classnames/bind'

const cx = classNames.bind(styles)

export const MailList: FC<MailListProps> = ({
  mailList,
  requestMailsStatus,
  fetchNextItemsStatus,
  getMailList,
  enabledFeatures,
  openMailEditor,
  setSearch,
  search,
  reloadMailList,
}) => {
  const { mailId } = useParams<'mailId'>()
  const navigate = useNavigate()
  const [hasSearch, setHasSearch] = useState(false)

  const [listRef, setListRef] = useEffectWithRef<AbstractList<LightMail>>(() => {
    if (listRef) {
      listRef.updateList(true)
    }
  }, [])

  const [currentOffset, setCurrentOffset] = useState(DEFAULT_OFFSET_MAIL_LIST)

  const fetchMailReceivedList = useCallback(
    (offset, limit) => {
      if (mailList.itemsCount > 0) {
        getMailList(offset, limit, search)
      }
    },
    [getMailList, mailList, search],
  )

  // Redirection automatique vers le premier mail si aucun mail sélectionné
  useEffect(() => {
    const firstMail = mailList.items.at(0)
    if (!isDefined(mailId) && isDefined(firstMail)) {
      navigate(firstMail.id)
    }
  }, [mailId, mailList.items, navigate])

  useDebounce(
    () => {
      setHasSearch(!!search)

      if (search && search.length < SEARCH_MIN_CHAR) {
        return
      }

      reloadMailList(true)
    },
    500,
    [search],
  )

  const requestIsCompleted = requestMailsStatus !== 'pending' && requestMailsStatus !== 'idle'

  return (
    <div className={styles.mailListContainer}>
      <div className={styles.mailActionsWithSearch}>
        <div className={styles.mailActions}>
          <RoundedButton label="Nouveau message" icon="add" onClick={openMailEditor} />
          <TooltipWrapper pointerDirection="left" content="Charger les nouveaux messages">
            <div className={styles.reloadButtonWrapper}>
              <IconButton
                icon="reload"
                theme="transparent-dark"
                size="micro"
                onClick={() => reloadMailList(false)}
              />
            </div>
          </TooltipWrapper>
        </div>
        {enabledFeatures?.mssSmtp && (
          <div className={styles.searchWrapper}>
            <SearchInput
              value={search ?? ''}
              onChange={({ target: { value } }) => {
                setSearch(value)
              }}
              placeholder="Rechercher dans les messages (4 caractères minimum)"
            />
          </div>
        )}
      </div>
      <div
        className={cx(styles.listWrapper, {
          'border-right': !requestIsCompleted,
        })}
      >
        {requestIsCompleted && (
          <List
            items={mailList.items}
            renderItem={(item) => <MailListItem mail={item} />}
            highlightedId={mailId}
            onUpdateList={() => {}}
            pageCount={null}
            loading={false}
            fullPageMode={false}
            renderEmptyPlaceHolder={
              <div className={styles.emptyPlaceHolderWrapper}>
                <EmptyPlaceHolder
                  icon={null}
                  message={
                    (hasSearch && 'Aucun élément ne correspond à votre recherche') || undefined
                  }
                />
              </div>
            }
            ref={setListRef}
            renderListFooter={
              <>
                {fetchNextItemsStatus === 'pending' && (
                  <ListItem>
                    <div className={styles.loaderNextItem}>
                      <div className={styles.iconLoader}>
                        <Icon icon="arrowCircle" size="normal" />
                      </div>
                      Chargement en cours
                    </div>
                  </ListItem>
                )}
                {fetchNextItemsStatus === 'success' && (
                  <ListItem>
                    <div className={styles.loaderNextItem}>
                      Fin des messages dans votre boîte de réception
                    </div>
                  </ListItem>
                )}
                {fetchNextItemsStatus === 'error' && (
                  <ListItem>
                    <div className={styles.loaderNextItem}>
                      Une erreur est survenue lors du chargement des données
                    </div>
                  </ListItem>
                )}
              </>
            }
          />
        )}
      </div>
      {mailList.itemsCount > 0 && (
        <Waypoint
          topOffset={1700}
          onEnter={() => {
            fetchMailReceivedList(
              currentOffset + DEFAULT_INCREMENT_OFFSET_MAIL_LIST,
              DEFAULT_LIMIT_MAIL_LIST,
            )
            setCurrentOffset((previous) => previous + DEFAULT_INCREMENT_OFFSET_MAIL_LIST)
          }}
        />
      )}
    </div>
  )
}
