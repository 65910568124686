import React, { FunctionComponent, useState } from 'react'
import { IconButton } from '../../../components/shared'
import { CommonVariableActionsProps } from './CommonVariableActions.model'
import styles from './CommonVariableActions.module.scss'

export const CommonVariableActions: FunctionComponent<CommonVariableActionsProps> = ({
  children,
  customActions,
  onCopyVariable,
  onCopyVariableText,
  onChangeVariableToText,
  onUpdateVariableFixedValue,
}) => {
  const [hintLabel, setHintLabel] = useState('')
  return (
    <div>
      {children}
      <div className={styles.container}>
        <div className="flex space-x-4">
          <IconButton
            onMouseDown={onCopyVariable}
            onMouseOver={() => setHintLabel('Copier la variable')}
            onMouseLeave={() => setHintLabel('')}
            icon="copy"
            size="micro"
            theme="primary"
          />
          <IconButton
            onMouseDown={onCopyVariableText}
            onMouseOver={() => setHintLabel('Copier le texte de la variable')}
            onMouseLeave={() => setHintLabel('')}
            icon="copyText"
            size="micro"
            theme="primary"
          />
          <IconButton
            onMouseDown={onChangeVariableToText}
            onMouseEnter={() => setHintLabel('Remplacer la variable par son texte')}
            onMouseLeave={() => setHintLabel('')}
            icon="text"
            size="micro"
            theme="primary"
          />
          {onUpdateVariableFixedValue && (
            <IconButton
              onMouseDown={onUpdateVariableFixedValue}
              onMouseEnter={() => setHintLabel('Mettre à jour la variable')}
              onMouseLeave={() => setHintLabel('')}
              icon="arrowCircle"
              size="micro"
              theme="primary"
            />
          )}
          {customActions &&
            customActions.map(({ icon, hint, onClick }, index) => (
              <IconButton
                key={index}
                onMouseDown={onClick}
                onMouseEnter={() => setHintLabel(hint)}
                onMouseLeave={() => setHintLabel('')}
                icon={icon}
                size="micro"
                theme="primary"
              />
            ))}
        </div>
        <div className={styles.hint}>{hintLabel}</div>
      </div>
    </div>
  )
}
