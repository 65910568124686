import { createContext } from 'react'

interface RadioGroupContextType {
  name: string
  value?: string | number | string[]
  disabled: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const RadioGroupContext = createContext<RadioGroupContextType>({
  name: 'radio',
  disabled: false,
  onChange: (event) => {
    console.error('Not implemented', event)
  },
})
