import { FunctionComponent, useCallback } from 'react'
import { IconButton, SelectSearch, TooltipWrapper } from '../../../shared'
import { SelectOption } from '../../../../model/SelectOption'
import { MailNavbarProps } from './MailNavBar.model'

export const MailNavbar: FunctionComponent<MailNavbarProps> = ({
  mailAddressOptions,
  inUseMailAddress,
  setInUseMailAddress,
  disconnect,
}) => {
  const disabled = 1 === mailAddressOptions.length

  const handleSelect = useCallback(
    (selected: SelectOption<string> | undefined) => {
      if (selected) {
        setInUseMailAddress(selected.value)
      }
    },
    [setInUseMailAddress],
  )
  return (
    <div className="flex w-full bg-shades-2 h-16 px-5 items-center">
      <div className="flex w-full items-end h-full">
        <div className="flex flex-col h-3/4 bg-shades-white px-5 rounded-t-md">
          <span className="my-auto font-semibold text-xl">Boîte de réception</span>
        </div>
      </div>
      <div className="my-auto w-150 max-w-full mr-3">
        <SelectSearch
          options={mailAddressOptions}
          value={mailAddressOptions.find(({ value }) => value === inUseMailAddress)}
          placeholder="Sélectionner une adresse mail"
          disabled={disabled}
          onSelect={handleSelect}
          colorPreset="dark"
          clearable={false}
        />
      </div>
      {disconnect && (
        <TooltipWrapper
          pointerDirection="right"
          content="Se déconnecter de la messagerie de santé sécurisée"
        >
          <div>
            <IconButton icon="power" onClick={disconnect} />
          </div>
        </TooltipWrapper>
      )}
    </div>
  )
}
