import styled from '@emotion/styled'
import { FormatingPluginMode } from '../../FormatingPlugin'
import React, { CSSProperties, FunctionComponent, ReactNode } from 'react'

type ParagraphProps = {
  mode: FormatingPluginMode
  style: CSSProperties
  children?: ReactNode
}

export const Paragraph: FunctionComponent<ParagraphProps> = (props) => {
  const Tag = props.mode === 'inline' ? styled.span : styled.div
  // On utilise "overflow: hidden;" afin d'éviter des barres de scroll lors de l'utilisation de "text-align: center"
  const Node = Tag`
    margin: 0.88rem 0; // 14px 0
    overflow: hidden;
  `
  return <Node {...props} />
}
