import { useInfiniteQuery } from '@tanstack/react-query'
import { patientHealthDataKeys } from './patientHealthData.keys'
import { largePageLimit, startingPage } from '../../../constants'
import { UserFriendlyError } from '../../../model/Error'
import { PaginatedList } from '../../../model/Pagination'
import { HealthData } from '../../../model/HealthData'
import { useFlatPaginatedData } from '../../utils/query'
import { getPatientHealthData } from '../../../data/patientHealthData/getPatientHealthData'
import { useIndexedModuleValues } from '../../utils'

interface GetInifinitePatientHealthDataParams {
  patientId: number
  enabled?: boolean
  limit?: number
}

export const useGetInfinitePatientHealthData = ({
  patientId,
  enabled = true,
  limit = largePageLimit,
}: GetInifinitePatientHealthDataParams) => {
  const queryReturn = useInfiniteQuery<PaginatedList<HealthData> | undefined>(
    patientHealthDataKeys.detail(patientId),
    async ({ pageParam = startingPage }) => {
      const { data, ok } = await getPatientHealthData(patientId.toString(), pageParam, limit)
      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          `Impossible de récupérer les informations médicales du patient`,
          `Une erreur technique s'est produite`,
        )
      }
      return data
    },
    { enabled },
  )

  const healthData = useFlatPaginatedData(queryReturn.data)
  const indexedValues = useIndexedModuleValues(healthData)

  return { query: queryReturn, healthData, indexedValues }
}
