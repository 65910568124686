import { ClipboardEvent } from 'react'
import { Editor, getEventTransfer } from 'slate-react'
import { FormatingPluginMode } from '../FormatingPlugin'
import { createSerializer } from './HtmlSerializer'

export const createOnPaste = (writingMode: FormatingPluginMode = 'normal') => (
  event: ClipboardEvent,
  editor: Editor,
  next: () => any,
) => {
  const transfer = getEventTransfer(event)

  if (transfer.type !== 'html') {
    return next()
  }

  // Evite la double insertion de contenu
  event.preventDefault()

  // en best effort pour le moment (enlève les LF insérés par word dans le flux html copié)
  let sanitized = ((transfer as any).html as string)
    .replace(/>(\r\n|\r|\n)/g, '>') // Suppression des "\r \n \r\n" en début de flux
    .replace(/(\r\n|\r|\n)</g, '<') // Suppression des "\r \n \r\n" en fin de flux
    .replace(/<br\/>/g, '') // Suppression des <br>
    .replace(/( ?)(\r\n|\r|\n)( ?)/g, ' ') //  Suppression des "\r \n \r\n"

  const { document } = createSerializer(undefined, writingMode).deserialize(sanitized)
  editor.insertFragment(document)
}
