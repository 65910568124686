import React, { FunctionComponent, useState } from 'react'
import { formatFr } from '../../../../misc/date.utilities'
import { DeleteValidationModal, Icon, IconButton, TooltipWrapper } from '../../../shared'
import { MailHeaderProps } from './MailHeader.model'

export const MailHeader: FunctionComponent<MailHeaderProps> = ({
  inUseMail,
  openMailEditor,
  deleteMail,
  enabledFeatures,
}) => {
  const [showDeleteMailModal, setShowDeleteMailModal] = useState<boolean>(false)

  const name = inUseMail.nameFrom
    ? `${inUseMail.nameFrom} <${inUseMail.emailFrom}>`
    : inUseMail.emailFrom

  const handleDeleteMail = () => {
    setShowDeleteMailModal(true)
  }

  const handleDeleteMailValidation = (): void => {
    deleteMail(inUseMail.id, true)
    setShowDeleteMailModal(false)
  }

  return (
    <div>
      <div className="flex justify-between max-w-full">
        <div className="flex flex-grow-2 flex-col mr-5 justify-between min-w-0 shrink">
          <h1 className="text-shades-2 font-semibold text-3xl">
            {'' !== inUseMail.subject && inUseMail ? inUseMail.subject : 'aucun objet'}
          </h1>
          <span className="text-base font-semibold leading-none break-words" title={name}>
            {name}
          </span>
        </div>
        <div className="flex-col items-end self-end justify-between shrink-0">
          <div className="flex justify-end mb-4">
            <IconButton
              icon="upLeft"
              theme="light"
              onClick={() => openMailEditor({ openedFrom: 'reply' })}
            />
            {enabledFeatures?.mssSmtp && (
              <TooltipWrapper pointerDirection="left" content="Supprimer le message">
                <div>
                  <IconButton icon="trash" theme="light" onClick={handleDeleteMail} />
                </div>
              </TooltipWrapper>
            )}
          </div>
          <div className="flex flex-grow text-shades-4 fill-shades-4 justify-end">
            <Icon icon="calendar" size="nano" />
            <span className="text-sm ml-2 whitespace-nowrap leading-none">
              {formatFr(new Date(inUseMail.date.date), 'dd/MM/yyyy HH:mm')}
            </span>
          </div>
        </div>
      </div>
      <DeleteValidationModal
        display={showDeleteMailModal}
        title={`Voulez vous vraiment supprimer ce message ?`}
        testId="modal-delete-mail"
        onClose={() => setShowDeleteMailModal(false)}
        onSubmit={handleDeleteMailValidation}
      />
    </div>
  )
}
