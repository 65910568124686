import { BottomPanelComponentType } from '../../../store/ui/bottomPanel'
import { setBottomPanelOptions } from '../../../store/ui/bottomPanel/bottomPanel.actions'
import { connect } from 'react-redux'
import { ExportColumnsDispatchProps } from './ExportColumns.model'
import { ExportColumns } from './ExportColumns.component'

const mapDispatchToProps: ExportColumnsDispatchProps = {
  openSearchColumnDocument: () =>
    setBottomPanelOptions({
      open: true,
      componentType: BottomPanelComponentType.SearchDocumentsColumnExport,
      title: 'Ajouter un Document',
    }),
}

const withConnect = connect(null, mapDispatchToProps)(ExportColumns)
export { withConnect as ExportColumns }
