import { connect } from 'react-redux'

import { SearchQuestionnairesSurvey } from './SearchQuestionnairesSurvey.component'
import { SearchQuestionnaireSurveyDispatchProps } from './SearchQuestionnairesSurvey.model'
import { setBottomPanelOptions } from '../../../store/ui/bottomPanel/index'

import { addQuestionnairesToSurvey } from '../../../store/survey_legacy/index'

const mapDispatchToProps: SearchQuestionnaireSurveyDispatchProps = {
  setBottomPanelOptions,
  addQuestionnaires: addQuestionnairesToSurvey,
}

const withConnect = connect(null, mapDispatchToProps)(SearchQuestionnairesSurvey)
export { withConnect as SearchQuestionnairesSurvey }
