import { PosologyPreview } from '../../../model/Posology'
import { RestuxResources } from '../../resources'
import { toUpperSnakeCase } from '../../restux/restux.utilities'

const RESOURCE_PREFIX = `@${toUpperSnakeCase(RestuxResources.posology)}`

export const domainPosologyActionTypes = {
  BATCH: `${RESOURCE_PREFIX}/BATCH`,
  BATCH_SUCCEED: `${RESOURCE_PREFIX}/BATCH_SUCCEED`,
  BATCH_FAILED: `${RESOURCE_PREFIX}/BATCH_FAILED`,
  GET_PREVIEW: `${RESOURCE_PREFIX}/GET_PREVIEW`,
  SET_PREVIEW: `${RESOURCE_PREFIX}/SET_PREVIEW`,
  CLEAR_PREVIEW: `${RESOURCE_PREFIX}/CLEAR_PREVIEW`,
}

export interface PosologyState {
  preview: PosologyPreview | null
}
