import { PluginNames } from '@/model/Plugins'
import { Extension } from '@tiptap/core'
import { DEFAULT_LINEHEIGHT_OPTIONS, LineHeightValueOptions } from './lineHeight.model'

export interface LineHeightOptions {
  types: string[]
  heights: LineHeightValueOptions
}

export interface LineHeightStorage {
  /**
   * Retourne les options du plugin LineHeight
   */
  getOptions: () => LineHeightOptions
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    [PluginNames.LineHeight]: {
      /**
       * Définie la propriété lineHeight
       */
      setLineHeight: (height: string | null) => ReturnType
      /**
       * Retire la propriété lineHeight
       */
      unsetLineHeight: () => ReturnType
    }
  }

  interface CustomStorage {
    [PluginNames.LineHeight]: LineHeightStorage
  }
}

export const LineHeight = Extension.create<LineHeightOptions, LineHeightStorage>({
  name: PluginNames.LineHeight,

  addOptions() {
    return {
      types: ['heading', 'paragraph'],
      heights: DEFAULT_LINEHEIGHT_OPTIONS,
    }
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          lineHeight: {
            parseHTML: (element) => element.style.lineHeight,
            renderHTML: (attributes) => {
              const lineHeight = attributes['lineHeight']

              const isAmongOptions = this.options.heights.some(({ value }) => value === lineHeight)
              if (!lineHeight || !isAmongOptions) return {}

              return { style: `line-height: ${lineHeight}` }
            },
          },
        },
      },
    ]
  },

  addStorage() {
    return {
      getOptions: () => this.options,
    }
  },

  addCommands() {
    return {
      setLineHeight:
        (height) =>
        ({ commands }) => {
          if (!this.options.heights.some(({ value }) => value === height)) {
            return false
          }

          return this.options.types.every((type) =>
            commands.updateAttributes(type, { lineHeight: height }),
          )
        },

      unsetLineHeight:
        () =>
        ({ commands }) => {
          return this.options.types.every((type) => commands.resetAttributes(type, 'lineHeight'))
        },
    }
  },
})
