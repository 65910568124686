import { useMutation, useQueryClient } from '@tanstack/react-query'
import { addValid } from '../../../../store/message'
import { useActionDispatch } from '../../../utils'
import { ServerSentNotification } from '../../../../model/Notification'
import { putNotification } from '../../../../data/admin'
import { UserFriendlyError } from '../../../../model/Error'
import { PaginatedList } from '../../../../model/Pagination'
import { ServerSentNotificationMutationPayload } from '../../../../data/admin/notifications/notification.model'
import { hideBottomPanel } from '../../../../store/ui/bottomPanel'
import { adminNotifKeys } from './adminNotifications.keys'

interface NotificationMutateVariables {
  notificationId: string
  variables: ServerSentNotificationMutationPayload
}

export const usePutNotification = () => {
  const valid = useActionDispatch(addValid)
  const queryClient = useQueryClient()
  const closeBottomPanel = useActionDispatch(hideBottomPanel)

  const queryReturn = useMutation<ServerSentNotification, Error, NotificationMutateVariables>(
    async ({ notificationId, variables }) => {
      const { ok, data } = await putNotification(notificationId, variables)
      if (!ok || !data) {
        throw new UserFriendlyError('error', 'La mise à jour de la notification a échoué')
      }
      return data
    },
    {
      onSuccess: (data) => {
        valid('Notification mise à jour')
        closeBottomPanel()
        queryClient.refetchQueries<PaginatedList<ServerSentNotification>>({
          queryKey: adminNotifKeys.all,
          refetchPage: (page) => page.items.some(({ id }) => id === data.id),
        })
      },
    },
  )

  return queryReturn
}
