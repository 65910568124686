import { formatISO } from 'date-fns'
import { checkDrugDivisibility } from '../../../misc/drug.utilities'
import { isDefined } from '../../../misc/functions.utilities'
import { Drug, DrugAdministrationRoute } from '../../../model/Drug'
import { Posology, PosologyFormResource } from '../../../model/Posology'
import { v4 as uuidV4 } from 'uuid'

export const isPosologyFormComplete = (form: PosologyFormResource, drugs: Drug[]) => {
  // Administration
  const isAdministrationBlockValid = form.units.length && isDefined(form.administrationRoute)

  // Moment - Fréquence
  const areQuantitiesValid = form.dosages.every(({ quantities, moment }) =>
    quantities
      .map(
        (quantity) =>
          quantity.value > 0 &&
          checkDrugDivisibility(
            drugs.find((drug) => drug.id === quantity.id)?.divisibility,
            quantity.value,
          ) &&
          moment !== null,
      )
      .reduce(
        (prevQuantityChecked, currentQuantityChecked) =>
          prevQuantityChecked && currentQuantityChecked,
        true,
      ),
  )
  const isFrequencyValid =
    isDefined(form.dosages[0]) &&
    !!form.dosages[0].recurrenceType &&
    !!form.dosages[0].recurrenceValue &&
    form.dosages[0].quantities.every((quantity) => quantity.value)
  const isDosageBlockValid = !!form.dosages?.length && (isFrequencyValid || areQuantitiesValid)

  // Durée - Début/fin
  const isDurationBlockValid =
    (!!form.intakeDurationType && isDefined(form.intakeDurationValue)) ||
    !!(form.startDate && form.endDate)

  return isAdministrationBlockValid && isDosageBlockValid && isDurationBlockValid
}

export const convertFormIntoPosology = (forms: PosologyFormResource[]): Partial<Posology>[] =>
  forms.map((form) => ({
    ...form,
    startDate: form.startDate ? formatISO(form.startDate) : null,
    endDate: form.endDate ? formatISO(form.endDate) : null,
    summary: undefined,
  }))

export const computePosologyFormDefaultValue = (
  baseValue: Posology | null | undefined,
  drugs: Drug[],
  availableAdministrationRoutes: DrugAdministrationRoute[],
): PosologyFormResource => {
  if (baseValue) {
    const { startDate, endDate, intakeDurationValue, summary, ...restBaseValue } = baseValue
    return {
      ...restBaseValue,
      intakeDurationValue,
      startDate: !intakeDurationValue && startDate ? new Date(startDate) : null,
      endDate: !intakeDurationValue && endDate ? new Date(endDate) : null,
      previewId: baseValue.id,
    }
  }

  return {
    startDate: null,
    endDate: null,
    dosages: [
      {
        dayParity: null,
        moment: null,
        recurrenceType: null,
        recurrenceValue: null,
        quantities: drugs.map((drug) => ({ id: drug.id, type: drug.type, value: 0 })),
      },
    ],
    units: drugs
      .filter((drug) => drug.units.length === 1)
      .map(({ id, type, units }) => ({ id, type, value: units[0].name })),
    ...(availableAdministrationRoutes.length === 1 && {
      administrationRoute: drugs[0].administrationRoutes[0].title,
    }),
    previewId: uuidV4(),
  }
}

export const computeIntervalForms = (
  baseValues: Posology[],
  drugs: Drug[],
  availableAdministrationRoutes: DrugAdministrationRoute[],
): PosologyFormResource[] => {
  return baseValues.map((i) =>
    computePosologyFormDefaultValue(i, drugs, availableAdministrationRoutes),
  )
}
