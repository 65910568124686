import React, { FunctionComponent, ReactNode } from 'react'

import styles from './ToolbarButton.module.scss'

interface ToolbarAdditionnalCategoryProps {
  children?: ReactNode
}

export const ToolbarAdditionalCategory: FunctionComponent<ToolbarAdditionnalCategoryProps> = ({
  children,
}) => <div className={styles.category}>{children}</div>
