import { uiAtcClassificationActionTypes, uiActions } from './atcClassification.model'

const getClassChildren = (id: number) => ({
  type: uiAtcClassificationActionTypes.GET_CLASS_CHILDREN,
  id,
})

export const uiAtcClassificationActions = {
  ...uiActions.actions,
  getClassChildren,
}
