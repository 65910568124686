import React, { FC, useContext } from 'react'
import { ToolbarElementProps } from '@/model/Toolbar'
import { ToolbarButton } from '@/components/toolbar/atoms'
import styles from './SpecialContentGroup.module.scss'
import { EditorConfigContext } from '@/context'

export const SpecialContentGroup: FC<ToolbarElementProps> = ({ editor }) => {
  const { tools } = useContext(EditorConfigContext)

  const handleAddPagebreak = () => {
    editor.chain().focus().setPageBreak().moveCursorAfter().run()
  }

  const handleAddTable = () => {
    editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: false }).run()
  }

  const displayPageBreak = tools.includes('pagebreak')
  const displayTable = tools.includes('table')

  return (
    <>
      {displayPageBreak && (
        <div className={styles['button']}>
          <ToolbarButton title="Saut de page" icon="PageBreak" onClick={handleAddPagebreak} />
        </div>
      )}
      {displayTable && (
        <div className={styles['button']}>
          <ToolbarButton title="Tableau" icon="AddTable" onClick={handleAddTable} />
        </div>
      )}
    </>
  )
}
