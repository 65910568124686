export enum DrugTypes {
  ATC = 'atc',
  SPECIALITY = 'speciality',
  NON_PROPRIETARY = 'non-proprietary-name',
  ACTIVE_PRINCIPLES = 'active-principle',
  BIOSIMILAR_GROUP = 'bioSimilarGroup',
  INDICATION = 'indication',
}

export enum UnsubstituableStatement {
  MTE = 'MTE',
  EFG = 'EFG',
  CIF = 'CIF',
}

export enum DrugGenericTypes {
  GENERIC = 'GENERIC',
  REFERENT = 'REFERENT',
}

export enum AldStatus {
  OK = 'OK',
  PATIENT_HAS_NO_ALD = 'PatientHasNoAld',
  NO_MATCHING_ALD_FOUND = 'NoMatchingAldFound',
}
