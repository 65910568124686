import { InfiniteData } from '@tanstack/react-query'
import { PaginatedList } from '../../../model/Pagination'
import { useMemo } from 'react'
import { isDefined } from '../../../misc/functions.utilities'

export const useFlatPaginatedData = <T>(
  data: InfiniteData<PaginatedList<T> | undefined> | undefined,
): T[] => {
  const items = useMemo(() => {
    if (data) {
      return data.pages.filter(isDefined).flatMap(({ items }) => items)
    }
    return []
  }, [data])

  return items
}
