import { FunctionComponent, useMemo, useState } from 'react'
import { PatientMenuActionsProps } from './PatientMenuActions.model'
import { DropdownContainer, DropdownItem, Icon, IconButton } from '../../shared'
import { getCssVariable } from '../../../design-system/utils'
import { DoctolibZipperStatus } from '../../../model/Doctolib'

const DoctolibMenuEntry: FunctionComponent<{ label: string }> = ({ label }) => (
  <div className="flex items-center">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <g fill="none" fill-rule="evenodd">
        <circle cx="8" cy="8" r="8" fill="#4097E1" />
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M10.033 3.377c.316.04.655.103 1.003.23a3.936 3.936 0 0 1 .521.229c.174.086.348.197.506.323.166.127.316.277.466.435a3.1 3.1 0 0 1 .37.537c.104.197.198.395.254.608.04.103.055.213.086.316.024.102.04.213.056.316.055.426.047.845 0 1.255-.048.411-.134.814-.237 1.201-.032.095-.055.197-.087.292l-.095.285c-.031.094-.07.19-.11.284l-.056.142c-.015.047-.04.087-.055.134l-.118.277-.143.284a3.47 3.47 0 0 1-.347.537 4.605 4.605 0 0 1-1.959 1.493c-.095.04-.19.071-.276.103-.095.031-.19.063-.285.086-.19.056-.379.103-.568.135-.38.079-.766.126-1.145.15-.759.047-1.517.008-2.212-.166a4.557 4.557 0 0 1-1.832-.916 3.532 3.532 0 0 1-.672-.703 2.36 2.36 0 0 1-.395-.885 2.298 2.298 0 0 1-.031-.245.704.704 0 0 1 .008-.229c.008-.063.016-.126.031-.19.008-.062.024-.118.04-.18.063-.238.15-.435.253-.609a2.02 2.02 0 0 1 .71-.719c.032-.023.064-.031.08-.047a.62.62 0 0 1 .07-.032l.064-.031.063.055-.055.095s-.008.023-.032.07c-.024.048-.047.111-.079.19-.063.166-.15.411-.19.703-.015.15-.031.308-.023.474 0 .087.008.174.023.26.008.048.008.088.016.103a.734.734 0 0 0 .032.103c.095.277.387.608.79.9.402.293.908.57 1.46.838.554.26 1.185.513 1.873.616.347.047.703.055 1.058.008.174-.024.355-.063.53-.11a3.94 3.94 0 0 0 .505-.198c.323-.158.616-.38.86-.64a3.3 3.3 0 0 0 .57-.893c.07-.158.126-.323.18-.49.048-.165.088-.331.135-.528l.032-.143.031-.134c.016-.087.04-.174.056-.26.015-.087.039-.182.055-.269l.047-.26c.119-.704.142-1.39.032-1.975a2.707 2.707 0 0 0-.269-.774c-.063-.11-.134-.213-.205-.316-.08-.095-.158-.19-.253-.269-.371-.331-.869-.56-1.366-.703a8.76 8.76 0 0 0-1.453-.268c-.45-.047-.853-.063-1.193-.071-.265-.007-.49-.008-.663-.008H6.03l-.024-.095.063-.031s.087-.048.26-.119a6.61 6.61 0 0 1 .767-.268c.331-.103.75-.198 1.24-.269a6.663 6.663 0 0 1 1.698-.024zM8.46 5.755c.063-.332 1.066-.893.98-.403-.293 1.477-.577 2.946-.87 4.423-.07.324-1.073.884-.979.403.293-1.477.577-2.946.87-4.423z"
        />
      </g>
    </svg>
    <div className="ml-2">{label}</div>
  </div>
)

export const PatientMenuActions: FunctionComponent<PatientMenuActionsProps> = ({
  doctolibStatus,
  theme = 'light',
  onChangeOwner,
  onEdit,
  onDelete,
  onCreateEvent,
  onOpenPatientHistoric,
}) => {
  const [opened, setOpened] = useState(false)
  const hasSomethingToShow = useMemo(
    () =>
      doctolibStatus === DoctolibZipperStatus.LOADED || !!onEdit || !!onDelete || !!onChangeOwner,
    [doctolibStatus, onChangeOwner, onDelete, onEdit],
  )

  return hasSomethingToShow ? (
    <div className="relative">
      <IconButton
        size="micro"
        theme={theme}
        icon="more"
        onClick={() => setOpened(!opened)}
        testId="button-patient-actions"
      />
      <div className="absolute top-0 right-10 z-10">
        <DropdownContainer direction="right" opened={opened} closeDropdown={() => setOpened(false)}>
          {onEdit && (
            <DropdownItem onClick={onEdit} testId="button-edit-patient">
              <Icon icon="pencil" size="nano" color={getCssVariable('shades', 'shade2')} />
              <span className="text-shades-2 ml-2">Éditer</span>
            </DropdownItem>
          )}
          {onChangeOwner && (
            <DropdownItem
              onClick={() => {
                onChangeOwner()
                setOpened(false)
              }}
              testId="button-change-patient-owner"
            >
              <Icon icon="profile" size="nano" color={getCssVariable('shades', 'shade2')} />
              <span className="text-shades-2 ml-2">Changer le référent</span>
            </DropdownItem>
          )}
          {onDelete && (
            <DropdownItem onClick={onDelete} testId="button-delete-patient">
              <Icon icon="trash" size="nano" color={getCssVariable('shades', 'shade2')} />
              <span className="text-shades-2 ml-2">Supprimer</span>
            </DropdownItem>
          )}
          {doctolibStatus === DoctolibZipperStatus.LOADED && (
            <>
              <DropdownItem onClick={onCreateEvent}>
                <DoctolibMenuEntry label="Créer un rendez-vous" />
              </DropdownItem>
              <DropdownItem onClick={onOpenPatientHistoric}>
                <DoctolibMenuEntry label="Historique des rendez-vous" />
              </DropdownItem>
            </>
          )}
        </DropdownContainer>
      </div>
    </div>
  ) : null
}
