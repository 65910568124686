import { Editor } from '@tiptap/react'
import React, { FC } from 'react'
import { ToolbarElementProps } from '@/model/Toolbar'
import { ToolbarInput } from '@/components/toolbar/atoms'
import { parseFontSizeAttribute, headingFontSizeDisplay } from './FontSizeSelect.utils'
import { PluginNames } from '@/model/Plugins'

const getCurrentFontSize = (editor: Editor) => {
  const activeFontSize = parseFontSizeAttribute(editor.getAttributes('textStyle')['fontSize'])

  if (activeFontSize) {
    return activeFontSize
  }
  // Pas d'attribut font size sur l'élément
  // On infère alors la taille affichée en fonction de l'élément actif (text ou heading)
  const parent = editor.state.selection.$head.parent
  const nodeName = parent.type.name

  if (nodeName === 'heading') {
    const level: number = parent.attrs['level']
    const fontSize = headingFontSizeDisplay[level]
    if (fontSize) return fontSize
  }

  // Affichage de la taille par défaut pour les paragraphes
  const fontSizePlugin = editor.extensionManager.extensions.find(
    ({ name }) => name === PluginNames.FontSize,
  )
  const defaultFontSize = fontSizePlugin?.options['defaultFontSize']
  if (!defaultFontSize) return undefined

  return defaultFontSize
}

export const FontSizeSelect: FC<ToolbarElementProps> = ({ editor }) => {
  const activeFontSize = getCurrentFontSize(editor)

  const handleFontSizeChange = (value: number) => {
    if (value === activeFontSize) return

    editor.chain().focus().setFontSize(value).run()
  }

  return (
    <ToolbarInput
      title="Taille de police"
      name="fontSize"
      value={activeFontSize}
      onChange={handleFontSizeChange}
      min={6}
      max={99}
    />
  )
}
