import { configureStore } from 'redux-starter-kit'
import reducers from './reducers'
import { initClient } from '../api/apisauceConfig'
import { initHotjar, initZendesk } from '../analytics'
import { initSatismeter } from '../analytics/satismeter'
import createSagaMiddleware from 'redux-saga'
import sagas from './sagas'
import { reduxSagaOnErrorHandler } from '../errors/errors.handler'

const sagaMiddleware = createSagaMiddleware({
  onError: reduxSagaOnErrorHandler,
})

const appStore = configureStore({
  reducer: reducers,
  middleware: [sagaMiddleware],
  devTools: process.env.NODE_ENV !== 'production',
})
sagaMiddleware.run(sagas)
initClient(appStore)
initHotjar()
initSatismeter()
initZendesk()

export * from './reducers/reducers.model'
export default appStore
