import React from 'react'
import { FieldWrapper } from '../Fields'
import { useField } from 'formik'
import { FormikInput } from './Input.model'
import { SelectInput } from '../../SelectInput'
import { SelectOption } from '../../../../../model/SelectOption'
import { DropdownItem } from '../../../dropdown'

export function FormikSelectInput<T>({
  label,
  fieldName,
  options,
  placeholder,
  disabled,
  required = false,
  colorPreset,
  value,
  onChange,
}: FormikInput<T> & {
  options: SelectOption<T>[]
  value: SelectOption<T> | undefined
}) {
  const [, meta, utils] = useField({ name: fieldName })

  return (
    <FieldWrapper errorMessage={meta.error}>
      <SelectInput<T>
        value={value}
        options={options}
        placeholder={placeholder}
        disabled={disabled}
        required={required}
        valid={!meta.touched ? undefined : !meta.error}
        title={label}
        colorPreset={colorPreset}
        onSelect={(selected) => {
          utils.setTouched(true)
          utils.setValue(selected.value)
          onChange && onChange(selected.value)
        }}
        renderOption={(option, currentValue, isHovered) => (
          <DropdownItem
            icon={option.icon}
            selected={isHovered || currentValue?.value === option.value}
          >
            <div className="max-w-full flex items-center whitespace-normal">{option.label}</div>
          </DropdownItem>
        )}
      />
    </FieldWrapper>
  )
}
