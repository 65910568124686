import { useQuery } from '@tanstack/react-query'
import { patientInformationsKeys } from './patientInformations.keys'
import { UserFriendlyError } from '../../../model/Error'
import { PatientInformations } from '../../../model/PatientInformations'
import { getPatientInformations } from '../../../data/patientInformations/getPatientInformations'

export const useGetPatientInformations = (patientId: number) => {
  const queryKey = patientInformationsKeys.list(patientId)

  const queryResult = useQuery<PatientInformations[]>(queryKey, async () => {
    const { data, ok } = await getPatientInformations(patientId)
    if (!data || !ok) {
      throw new UserFriendlyError(
        'error',
        `Impossible de récupérer les données de la liste des allergies`,
        `Une erreur technique s'est produite`,
      )
    }

    return data.items
  })

  return { query: queryResult }
}
