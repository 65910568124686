import React from 'react'
import classNames from 'classnames/bind'

import { LabeledCheckboxProps } from './LabeledCheckbox.model'
import styles from './LabeledCheckbox.module.scss'
import { Checkbox } from '../Checkbox'

const classnamesCx = classNames.bind(styles)

export const LabeledCheckbox: React.FunctionComponent<LabeledCheckboxProps> = (props) => {
  const {
    label,
    colorPreset = 'dark',
    checked,
    onChange,
    testId,
    disabled = false,
    reverse = false,
  } = props

  return (
    <div className={styles.labeledCheckbox}>
      <Checkbox
        checked={checked}
        onChange={onChange}
        testId={testId}
        disabled={disabled}
        reverse={reverse}
      >
        <span
          className={`${classnamesCx(styles[colorPreset], styles.label)} ${
            reverse ? 'mr-[8px]' : 'ml-[8px]'
          }`}
        >
          {label}
        </span>
      </Checkbox>
    </div>
  )
}
