import getApi from '../../api'
import { deserializePatientResponse, serializePatient } from './mapper/patients.mapper'
import { ApiPatientResource, PatientMutationPayload } from './mapper/patients.model'

export const createPatient = (patient: PatientMutationPayload, params?: Record<string, any>) =>
  getApi()
    .post<ApiPatientResource>('/patients', serializePatient(patient), {
      params,
    })
    .then(deserializePatientResponse)
