import React, { FunctionComponent } from 'react'
import { ValidationModal } from '../ValidationModal/ValidationModal.component'
import { DeleteValidationModalProps } from './DeleteValidationModal.model'
import { Heading } from '../../fonts'

export const DeleteValidationModal: FunctionComponent<DeleteValidationModalProps> = (props) => (
  <ValidationModal {...props}>
    <Heading size={3}>{`Toute suppression est définitive et irréversible.`}</Heading>
  </ValidationModal>
)
