import React from 'react'
import { TableProps, TableRowsProps, TableHeaderProps } from './Table.model'

function TableHeader<T>({ columns }: TableHeaderProps<T>): JSX.Element {
  const headers = columns.map((column, index) => {
    return (
      <th className="text-left font-medium p-3 pr-6 pl-6" key={`th-${index}`}>
        {column.title}
      </th>
    )
  })

  return (
    <thead>
      <tr className="uppercase text-gray-500 text-xs leading-6 tracking-wider bg-gray-50 border-b border-gray-200">
        {headers}
      </tr>
    </thead>
  )
}

function TableRows<T>({ rows, columns }: TableRowsProps<T>): JSX.Element {
  const table = rows.map((row, rowIndex) => {
    return (
      <tr key={`tr-${rowIndex}`} className="odd:bg-white even:bg-gray-50 h-13">
        {columns.map((column, columnIndex) => {
          if (row.renderTd) {
            return row.renderTd(
              <>
                {column.render
                  ? column.render(row.data[column.dataIndex])
                  : row.data[column.dataIndex]}
              </>,
            )
          } else {
            return (
              <td
                key={`td-${columnIndex}`}
                className="font-normal text-gray-500 first:font-semibold first:text-gray-900 w-full pr-6 pl-6"
              >
                {column.render
                  ? column.render(row.data[column.dataIndex])
                  : row.data[column.dataIndex]}
              </td>
            )
          }
        })}
      </tr>
    )
  })

  return <tbody>{table}</tbody>
}

export function Table<T>({ columns, rows }: TableProps<T>): JSX.Element {
  return (
    <table className="border-collapse shadow overflow-hidden rounded-lg">
      <TableHeader columns={columns} />
      <TableRows rows={rows} columns={columns} />
    </table>
  )
}
