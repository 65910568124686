import React, { useState, FunctionComponent } from 'react'
import { FiltersWrapper, LabeledButton, MenuChip } from '../../shared'
import { LightQuestionnaire } from '../../../model/Questionnaire'
import { FormCard } from './FormCard.component'

interface QuestionnaireFormCardProps {
  questionnaires: LightQuestionnaire[]
  description: string
  addQuestionnaire: () => void
  removeQuestionnaire: (id: number) => void
}

export const QuestionnaireFormCard: FunctionComponent<QuestionnaireFormCardProps> = ({
  questionnaires,
  description,
  addQuestionnaire,
  removeQuestionnaire,
}) => {
  const [selectedQuestionnaireId, setSelectedQuestionnaireId] = useState<null | number>(null)

  const handleQuestionnaireClick = (questionnaire) => () => {
    if (selectedQuestionnaireId === questionnaire.id) {
      setSelectedQuestionnaireId(null)
    } else {
      setSelectedQuestionnaireId(questionnaire.id)
    }
  }

  const handleQuestionnaireDelete = (id) => () => {
    setSelectedQuestionnaireId(null)
    removeQuestionnaire(id)
  }

  return (
    <FormCard title="Questionnaire" label={description}>
      <FiltersWrapper variance="variant1">
        {questionnaires.map((questionnaire, index) => (
          <MenuChip
            key={index}
            onClick={handleQuestionnaireClick(questionnaire)}
            label={questionnaire.title}
            collapsed={selectedQuestionnaireId !== questionnaire.id}
            selected={selectedQuestionnaireId === questionnaire.id}
            onDelete={handleQuestionnaireDelete(questionnaire.id)}
          />
        ))}
        <LabeledButton icon="add" label="Questionnaire" size="small" onClick={addQuestionnaire} />
      </FiltersWrapper>
    </FormCard>
  )
}
