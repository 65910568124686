import {
  DomainDocumentTemplateActionTypes,
  DocumentTemplatesState,
} from './documentTemplates.model'
import { domainDocumentTemplatesActions } from './documentTemplates.actions'
import { createReducer } from 'redux-starter-kit'

const DOCUMENT_TEMPLATES_INITIAL_STATE: DocumentTemplatesState = {
  suggestions: {},
}

const ACTION_HANDLERS = {
  [DomainDocumentTemplateActionTypes.CLEAR_SUGGESTIONS]: (
    state: DocumentTemplatesState = DOCUMENT_TEMPLATES_INITIAL_STATE,
    {
      medicalEventId,
    }: ReturnType<typeof domainDocumentTemplatesActions.clearDocumentTemplatesSuggestion>,
  ): DocumentTemplatesState => {
    const { [medicalEventId]: _remove, ...suggestions } = state.suggestions
    return { ...state, suggestions }
  },
  [DomainDocumentTemplateActionTypes.SET_SUGGESTIONS]: (
    state: DocumentTemplatesState = DOCUMENT_TEMPLATES_INITIAL_STATE,
    {
      medicalEventId,
      suggestions,
    }: ReturnType<typeof domainDocumentTemplatesActions.setDocumentTemplatesSuggestion>,
  ): DocumentTemplatesState => ({
    ...state,
    suggestions: {
      ...state.suggestions,
      [medicalEventId]: suggestions,
    },
  }),
}

export const documentTemplatesReducer = createReducer<DocumentTemplatesState, any>(
  DOCUMENT_TEMPLATES_INITIAL_STATE,
  ACTION_HANDLERS,
)
