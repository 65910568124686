import React, { useState, FunctionComponent, useCallback, useMemo } from 'react'
import { useDebounce } from 'react-use'
import { formatISO, isEqual, isValid, parseISO } from 'date-fns'

import { Input, GridLayout, GridElement, DatePicker, TagSearchAndDisplay } from '../../shared'

import styles from './MedicalEventForm.module.scss'

import { MedicalEventFormProps } from './MedicalEventForm.model'
import { MedicalEvent } from '../../../model/MedicalEvent'
import { useCreateTag, useGetTags } from '../../../hooks/queries/tag'

export const MedicalEventForm: FunctionComponent<MedicalEventFormProps> = ({
  inUseMedicalEvent,
  updateMedicalEvent,
}) => {
  const [title, setTitle] = useState(inUseMedicalEvent.title)
  const [date, setDate] = useState<Date | null>(parseISO(inUseMedicalEvent.date))
  const { tagList: tags } = useGetTags()
  const { mutate: createTag } = useCreateTag()

  const isDateValid = useMemo(() => {
    return isValid(date)
  }, [date])

  const handleTagsUpdate = useCallback(
    (valueIds: string[]) => {
      updateMedicalEvent(inUseMedicalEvent.id, { tagIds: valueIds })
    },
    [inUseMedicalEvent.id, updateMedicalEvent],
  )

  const handleTagCreate = useCallback(
    (label: string) => {
      createTag(
        { label },
        { onSuccess: (tag) => handleTagsUpdate([...inUseMedicalEvent.tagIds, tag.id]) },
      )
    },
    [createTag, handleTagsUpdate, inUseMedicalEvent.tagIds],
  )

  useDebounce(
    () => {
      const updates: Partial<MedicalEvent> = {}
      if (title !== inUseMedicalEvent.title) {
        updates.title = title
      }
      if (date !== null && !isEqual(date, parseISO(inUseMedicalEvent.date)) && isDateValid) {
        updates.date = formatISO(new Date(date))
      }
      if (Object.values(updates).length > 0) {
        updateMedicalEvent(inUseMedicalEvent.id, updates)
      }
    },
    500,
    [title, date],
  )

  return (
    <form className={styles.form}>
      <GridLayout columns={2} rowsTemplate="auto auto" gap="medium" padding="medium">
        <Input
          name="title"
          colorPreset="dark"
          label="Titre"
          placeholder="Titre de l'événement médical"
          value={title}
          onChange={(e) => setTitle(e.currentTarget.value)}
          required={true}
        />
        <DatePicker
          label="Date"
          placeholder="jj/mm/aaaa"
          value={date}
          onChange={setDate}
          inputType="input"
          colorPreset="dark"
        />
        <GridElement>
          <TagSearchAndDisplay
            disabled={!inUseMedicalEvent.isEditable}
            placeholder="Rechercher un label"
            tags={tags}
            valueIds={inUseMedicalEvent.tagIds}
            onChange={handleTagsUpdate}
            onCreate={handleTagCreate}
            colorPreset="dark"
          />
        </GridElement>
      </GridLayout>
    </form>
  )
}
