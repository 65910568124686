import { RootState } from '../../reducers'
import { IdentityIdentifier } from '../../restux/restux.model'

export function documentInstanceQuestionnaireSelector(
  state: RootState,
  documentId: IdentityIdentifier,
  questionnaireId: number,
) {
  const resources = state.cache.documentInstances.details
  const documentInstance = resources[documentId]
  return documentInstance && documentInstance.type === 'farte'
    ? documentInstance.questionnaires.find(({ id }) => id === questionnaireId)
    : undefined
}
