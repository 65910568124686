import { Country } from '../../../model/Contact'
import { RestuxResources } from '../../resources'
import { restuxCacheFactory } from '../../restux/cache/index'
import { restuxCountriesApiConfig } from './api/Countries.api'

const resourceName = RestuxResources.countries

const { actions, reducers, sagas } = restuxCacheFactory<Country, Country>({
  apiConfig: restuxCountriesApiConfig,
  refetchList: false,
  resourceName,
})

export const countriesActions = actions
export const countriesReducers = reducers
export const countriesSagas = sagas
