import { isBefore, isToday, startOfYesterday } from 'date-fns'
import { FC, useCallback, useState } from 'react'
import { ActionModalLayout, DatePicker, Modal, RoundedButton } from '../../shared'
import { TerminateTreatmentModalProps } from './TerminateTreatmentModal.model'

export const TerminateTreatmentModal: FC<TerminateTreatmentModalProps> = ({
  treatment,
  isUnmounting,
  onClose,
  onValid,
}) => {
  const [endDate, setEndDate] = useState<Date | null>(startOfYesterday())

  const handleClose = useCallback(() => {
    setEndDate(startOfYesterday())
    onClose()
  }, [onClose])

  const handleValid = useCallback(() => {
    if (endDate !== null) {
      onValid(endDate)
    }
    handleClose()
  }, [endDate, handleClose, onValid])

  // Cas exceptionnel : Si le traitement commence aujourd'hui, je peux mettre une date de fin à hier
  const isEndDateValid =
    endDate !== null &&
    treatment?.startDate &&
    (isToday(treatment.startDate)
      ? !isBefore(endDate, startOfYesterday())
      : !isBefore(endDate, treatment.startDate))

  return (
    <Modal isUnmounting={isUnmounting} onClickOutside={handleClose}>
      <ActionModalLayout
        key={treatment?.id}
        title="Arrêt de traitement"
        onClose={onClose}
        actions={
          <div className="flex space-x-2">
            <RoundedButton label="Annuler" onClick={handleClose} theme="transparent-dark" />
            <RoundedButton
              label="Valider"
              disabled={!isEndDateValid}
              onClick={handleValid}
              theme="primary"
            />
          </div>
        }
      >
        <div className="bg-gray-100 py-4 px-6 w-103">
          <div className="bg-white pt-1">
            <DatePicker
              label="Date de dernière prise du traitement"
              clearable={false}
              colorPreset="light"
              value={endDate}
              onChange={(date) => {
                setEndDate(date)
              }}
              inputType="input"
            />
          </div>
        </div>
      </ActionModalLayout>
    </Modal>
  )
}
