import { createReducer } from 'redux-starter-kit'
import { activePrinciplesDomainActions } from './activePrinciples.actions'
import { ActivePrinciplesDomainActionsTypes, ActivePrinciplesState } from './activePrinciples.model'

const ACTIVE_PRINCIPLES_INITIAL_STATE: ActivePrinciplesState = {
  activePrinciples: [],
}

const ACTION_HANDLERS = {
  [ActivePrinciplesDomainActionsTypes.SET_LIST]: (
    state: ActivePrinciplesState = ACTIVE_PRINCIPLES_INITIAL_STATE,
    {
      activePrinciplesList,
    }: ReturnType<typeof activePrinciplesDomainActions.setActivePrinciplesList>,
  ): ActivePrinciplesState => ({
    ...state,
    activePrinciples: activePrinciplesList,
  }),
}

export const activePrinciplesDomainReducer = createReducer<ActivePrinciplesState, any>(
  ACTIVE_PRINCIPLES_INITIAL_STATE,
  ACTION_HANDLERS,
)
