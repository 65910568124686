import React, { ErrorInfo } from 'react'
import { ErrorPandaMessage } from '../../components/shared'
import styles from './GlobalErrorBoundary.module.scss'
import { manageReactError } from '../errors.handler'
import { GlobalErrorBoundaryProps, GlobalErrorBoundaryState } from './GlobalErrorBoundary.model'

export default class GlobalErrorBoundary extends React.Component<
  GlobalErrorBoundaryProps,
  GlobalErrorBoundaryState
> {
  public state = { hasError: false, errorDetails: null }

  public static getDerivedStateFromError(_error: Error) {
    return { hasError: true }
  }
  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    manageReactError(error, errorInfo)
    this.setState({ errorDetails: JSON.stringify({ stack: error.stack, info: errorInfo }) })
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div className={styles.container}>
          <ErrorPandaMessage
            title="Une erreur est survenue"
            subTitle="Si l'erreur persiste, nous vous invitons à contacter le support en fournissant les informations détaillées disponibles ci-dessous."
            errorDetails={this.state.errorDetails ?? undefined}
          />
        </div>
      )
    }
    return this.props.children
  }
}
