import { FC } from 'react'
import { Roles } from '../../../../model/Roles'
import { FormBlock, AddressFields } from '../../../shared'
import { PracticeLocationBlockProps } from './PracticeLocationBlock.model'

export const PracticeLocationBlock: FC<PracticeLocationBlockProps> = ({ mainRole }) => {
  return mainRole === Roles.DOCTOR ? (
    <div className="mt-8">
      <FormBlock label="Lieu d'exercice" icon="infoCircle">
        <AddressFields fieldNamePrefix="practiceLocation" type="doctor" colorPreset="light" />
      </FormBlock>
    </div>
  ) : null
}
