import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { SelectOption } from '../../../../model/SelectOption'

export function useSelectSearchFiltering<T>(
  value: SelectOption<T> | undefined,
  options: SelectOption<T>[],
): [string, Dispatch<SetStateAction<string>>, SelectOption<T>[]] {
  const [textValue, setTextValue] = useState('')
  const filteredOptions = useMemo(
    () => options.filter((option) => option.label.toLowerCase().includes(textValue.toLowerCase())),
    [options, textValue],
  )

  useEffect(() => {
    setTextValue(value?.label ?? '')
  }, [value])

  return [textValue, setTextValue, filteredOptions]
}
