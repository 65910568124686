import { call, put, takeEvery } from 'redux-saga/effects'
import { User } from '../../../model/User'
import { getMeUsurpation } from '../../cache/users/api'
import { addResponseError } from '../../message'
import {
  initializeUsurpedUser,
  setUsurpedUser,
  storeUsurpedUser,
  usurpedUserInitializationDone,
} from './adminUsers.actions'
import { AdminUsersActionTypes } from './adminUsers.model'
import { customHistory } from '../../../history'
import { codesDomainActions } from '../../domain/codes'
import { documentCategoriesActions } from '../../cache/documentCategories'
import { ApiResponse } from 'apisauce'
import { fetchAllTeams } from '../../domain/me'
import { queryClient } from '../../../App'

const USURPED_USER_ID_STORAGE_KEY = '_switch_user_id'
const USURPED_USER_EMAIL_STORAGE_KEY = '_switch_user_email'

function* setUsurpedUserInStorageWorker({ user }: ReturnType<typeof setUsurpedUser>) {
  if (user) {
    const response = yield call(getMeUsurpation, user.id, user.email)
    if (response.ok) {
      yield put(storeUsurpedUser(response.data))
    }
    localStorage.setItem(USURPED_USER_ID_STORAGE_KEY, user.id)
    localStorage.setItem(USURPED_USER_EMAIL_STORAGE_KEY, user.email)
    // Refetch des données de l'utilisateur usurpé
    queryClient.resetQueries()
    yield put(codesDomainActions.fetch())
    yield put(fetchAllTeams())
    yield put(documentCategoriesActions.actions.getPaginatedItems())
    customHistory.navigate('/')
  } else if (
    localStorage.getItem(USURPED_USER_ID_STORAGE_KEY) &&
    localStorage.getItem(USURPED_USER_ID_STORAGE_KEY)
  ) {
    localStorage.removeItem(USURPED_USER_ID_STORAGE_KEY)
    localStorage.removeItem(USURPED_USER_EMAIL_STORAGE_KEY)
    // Refetch des données de l'utilisateur
    queryClient.resetQueries()
    yield put(codesDomainActions.fetch())
  }
}

function* setUsurpedUserInStorageWatcher() {
  yield takeEvery(AdminUsersActionTypes.SET_USURPED_USER, setUsurpedUserInStorageWorker)
}

function* initializeUsurpedUserWorker() {
  const userIdInStorage = localStorage.getItem(USURPED_USER_ID_STORAGE_KEY)
  const userEmailInStorage = localStorage.getItem(USURPED_USER_EMAIL_STORAGE_KEY)

  if (userIdInStorage && userEmailInStorage) {
    const response: ApiResponse<User> = yield call(
      getMeUsurpation,
      userIdInStorage,
      userEmailInStorage,
    )
    if (!(response.ok && response.data)) {
      if (response.status === 403) {
        localStorage.removeItem(USURPED_USER_ID_STORAGE_KEY)
        localStorage.removeItem(USURPED_USER_EMAIL_STORAGE_KEY)
        yield put(initializeUsurpedUser())
      } else {
        yield put(addResponseError(response))
      }
      return
    }
    const user: User = response.data

    yield put(storeUsurpedUser(user))
  }
  yield put(usurpedUserInitializationDone())
}

function* initializeUsurpedUserWatcher() {
  yield takeEvery(AdminUsersActionTypes.INITIALIZE_USURPED_USER, initializeUsurpedUserWorker)
}

export const adminUsersSagas = {
  setUsurpedUserInStorageWatcher,
  initializeUsurpedUserWatcher,
}
