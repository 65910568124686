import { ExportFiltersDispatchProps } from './ExportFilters.model'
import { BottomPanelComponentType } from '../../../store/ui/bottomPanel'
import { setBottomPanelOptions } from '../../../store/ui/bottomPanel/bottomPanel.actions'
import { connect } from 'react-redux'
import { ExportFilters } from './ExportFilters.component'

const mapDispatchToProps: ExportFiltersDispatchProps = {
  openSearchFilterDocument: () =>
    setBottomPanelOptions({
      open: true,
      componentType: BottomPanelComponentType.SearchDocumentsFilterExport,
      title: 'Ajouter un Document',
    }),
}

const withConnect = connect(null, mapDispatchToProps)(ExportFilters)
export { withConnect as ExportFilters }
