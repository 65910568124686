import React, { FunctionComponent } from 'react'
import RoleRestriction from '../../../../../containers/RoleRestriction'
import { Roles } from '../../../../../model/Roles'
import { RoundedButton, Switch } from '../../../../shared'
import { LinkedAccountItemProps } from './LinkedAccountItem.model'
import styles from './LinkedAccountItem.module.scss'

export const LinkedAccountItem: FunctionComponent<LinkedAccountItemProps> = ({
  serviceName,
  authenticated,
  useStellairAssistantAccount,
  onChange,
  logout,
}) => (
  <div className="flex flex-col">
    <div className={styles.accountItem}>
      {serviceName}
      <div
        title={
          authenticated
            ? `Déconnecte votre compte Follow de votre compte ${serviceName}`
            : `Votre compte Follow n'est lié à aucun compte ${serviceName}`
        }
      >
        <RoundedButton label="Déconnecter" onClick={logout} disabled={!authenticated} />
      </div>
    </div>
    <RoleRestriction roles={[Roles.ASSISTANT]}>
      <div className="flex flex-row items-center w-full mt-4">
        <Switch
          theme="primary"
          name="Utiliser mon compte secrétaire Stellair"
          checked={useStellairAssistantAccount}
          onChange={() => onChange(!useStellairAssistantAccount)}
        />
      </div>
    </RoleRestriction>
  </div>
)
