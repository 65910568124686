import React, { FunctionComponent, useCallback, useMemo } from 'react'
import { Modal, ModalActions, ModalContent, ThinModalLayout } from '../Modal'
import { OwnerModalForm, OwnerModalProps } from './OwnerModal.model'
import {
  computeTeamsDoctorsOptions,
  filterTeamBySharingConfig,
} from '../../../../misc/team.utilities'
import { Heading } from '../../fonts'
import { getFullName } from '../../../../misc/user.utilities'
import { FormikSelectInput, FormikSwitch } from '../../inputs'
import { RoundedButton } from '../../buttons'
import { SharingConfigAccessLevel, SharingConfigKeys } from '../../../../model/Team'
import { InformationAlert } from '../../../user'
import { TooltipWrapper } from '../../tooltip'
import { Icon } from '../../Icon'
import { getCssVariable } from '../../../../design-system/utils'
import styles from './OwnerModal.module.scss'
import { Formik, FormikProps } from 'formik'
import { OwnerModalValidatorSchema } from './OwnerModal.validator'
import { isDefined } from '../../../../misc/functions.utilities'

const ressourceNameToLabel = (key: SharingConfigKeys) => {
  switch (key) {
    case SharingConfigKeys.contact:
      return 'le contact'
    case SharingConfigKeys.documentLayout:
      return 'la mise en page'
    case SharingConfigKeys.documentTemplate:
      return 'le modèle de document'
    case SharingConfigKeys.medicalEvent:
      return "l'événement médical"
    case SharingConfigKeys.patient:
      return 'le patient'
    case SharingConfigKeys.questionnaire:
      return 'le questionnaire'
    case SharingConfigKeys.tag:
      return 'le label'
  }
}

export const OwnerModal: FunctionComponent<OwnerModalProps> = ({
  isUnmounting,
  teams,
  currentUser,
  currentOwner,
  label,
  rootId,
  sharingConfigKey,
  onClose,
  onValid,
  showSubResourcesSwitch = false,
}) => {
  const selectedUser = currentOwner
    ? {
        value: currentOwner.id,
        label: getFullName(currentOwner),
      }
    : undefined

  const doctorsOptions = useMemo(() => {
    if (currentUser) {
      const allowedTeams = filterTeamBySharingConfig(teams, sharingConfigKey, [
        SharingConfigAccessLevel.write,
        SharingConfigAccessLevel.delete,
      ])
      return computeTeamsDoctorsOptions(allowedTeams, currentUser, undefined, [])
    }
    return []
  }, [currentUser, teams, sharingConfigKey])

  const initialValues: OwnerModalForm = {
    includeSubResource: false,
    selectedUser: selectedUser?.value,
  }

  const selectedUserValue = useCallback(
    (value?: string) =>
      (isDefined(value) && doctorsOptions.find((doctor) => doctor.value === value)) || undefined,
    [doctorsOptions],
  )

  const ownerInputInfo = `Seuls les médecins ayant les droits de lecture et d’édition sur ${ressourceNameToLabel(
    sharingConfigKey,
  )} peuvent être sélectionnés.`

  const handleSubmit = useCallback(
    (values: OwnerModalForm) => {
      if (values.selectedUser) {
        onValid(values.selectedUser, values.includeSubResource)
        onClose()
      }
    },
    [onClose, onValid],
  )

  return (
    <Modal isUnmounting={isUnmounting} onClickOutside={onClose} rootId={rootId}>
      <ThinModalLayout>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={OwnerModalValidatorSchema(initialValues)}
          validateOnMount={true}
          enableReinitialize={true}
        >
          {(props: FormikProps<OwnerModalForm>) => (
            <form
              onSubmit={props.handleSubmit}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault()
                }
              }}
            >
              <ModalContent>
                <Heading size={3}>{label}</Heading>
                {showSubResourcesSwitch && (
                  <>
                    <FormikSwitch
                      fieldName="includeSubResource"
                      label="Récupérer la totalité du dossier patient"
                    />
                    {props.values.includeSubResource && (
                      <>
                        <InformationAlert>
                          Le nom du nouveau propriétaire apparaîtra sur tous les événements médicaux
                          et documents de ce patient. Les variables liées à l'identité du médecin
                          seront mises à jour avec les informations du nouveau propriétaire.
                        </InformationAlert>
                      </>
                    )}
                  </>
                )}
                <div className={styles.container}>
                  <TooltipWrapper pointerDirection="left" content={ownerInputInfo}>
                    <Icon
                      icon="infoCircle"
                      color={getCssVariable('primary', 'default')}
                      size="normal"
                    />
                  </TooltipWrapper>
                  <FormikSelectInput<string>
                    fieldName="selectedUser"
                    label="Nouveau propriétaire"
                    options={doctorsOptions}
                    value={selectedUserValue(props.values.selectedUser)}
                  />
                </div>
              </ModalContent>
              <ModalActions>
                <div className="mr-4">
                  <RoundedButton
                    onClick={onClose}
                    icon="arrow"
                    iconRotate={-90}
                    theme="transparent-dark"
                    label="Annuler"
                  />
                </div>
                <RoundedButton
                  theme="invalid"
                  type="submit"
                  label="Valider"
                  disabled={!props.isValid}
                />
              </ModalActions>
            </form>
          )}
        </Formik>
      </ThinModalLayout>
    </Modal>
  )
}
