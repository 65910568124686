import React, { FunctionComponent } from 'react'
import { MedicalFormProps } from './MedicalForm.model'
import styles from './MedicalForm.module.scss'

export const MedicalForm: FunctionComponent<MedicalFormProps> = ({ children, onSubmit }) => {
  return (
    <form
      className={styles.form}
      onSubmit={onSubmit}
      onKeyPress={(event) => {
        if (event.key === 'Enter') {
          event.preventDefault()
        }
      }}
    >
      {children}
    </form>
  )
}
