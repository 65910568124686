import React, { FunctionComponent, ReactNode, useContext } from 'react'
import classNames from 'classnames/bind'

import { DropDownColorPresetContext } from '../DropdownContainer'

import styles from './DropdownItem.module.scss'
import { Icon, IconsType } from '../../Icon'
import { TestProps } from '../../../../misc/types.utilities'

const classNamesCx = classNames.bind(styles)

interface DropdownItemProps extends TestProps {
  children?: ReactNode
  onClick?: (evt: React.MouseEvent) => void
  icon?: IconsType
  iconPosition?: 'left' | 'right'
  iconRotate?: number
  selected?: boolean
  layout?: 'thin' | 'normal'
}

export const DropdownItem: FunctionComponent<DropdownItemProps> = ({
  onClick,
  icon,
  iconPosition = 'left',
  iconRotate,
  children,
  selected,
  layout = 'normal',
  testId,
}) => {
  const colorPreset = useContext(DropDownColorPresetContext)

  const renderIcon = icon && (
    <div className={styles.icon}>
      <Icon icon={icon} rotate={iconRotate} size="nano" />
    </div>
  )

  return (
    <div
      onClick={onClick}
      className={classNamesCx({
        item: true,
        [layout]: true,
        [colorPreset]: true,
        right: iconPosition === 'right',
        selected,
      })}
      role="button"
      data-test-id={testId}
    >
      {iconPosition === 'left' && renderIcon}
      {children}
      {iconPosition === 'right' && renderIcon}
    </div>
  )
}
