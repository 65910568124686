export const UiBottomPanelBaseVariablesActionTypes = {
  SEARCH_BASE_VARIABLE: `@BOTTOM_PANEL_BASE_VARIABLES/SEARCH_BASE_VARIABLE`,
  SELECT_BASE_VARIABLE: `@BOTTOM_PANEL_BASE_VARIABLES/SELECT_BASE_VARIABLE`,
  SET_BASE_VARIABLE_LIST: `@BOTTOM_PANEL_BASE_VARIABLES/SET_BASE_VARIABLE_LIST`,
}

export type BaseVariableListType = 'document' | 'fileTemplate'

export interface BottomPanelBaseVariableState {
  baseVariableList: BaseVariableListType
}
