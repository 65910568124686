import { ActivePrinciple } from '../../../model/Drug'

export interface ActivePrinciplesState {
  activePrinciples: Array<ActivePrinciple>
  activePrincipleSelected?: ActivePrinciple
}

export const ActivePrinciplesDomainActionsTypes = {
  SEARCH: '@ACTIVE_PRINCIPLES/SEARCH',
  SET_LIST: '@ACTIVE_PRINCIPLES/SET_LIST',
}
