import { FunctionComponent } from 'react'
import { Tick } from '../../shared'
import { CheckboxProps } from './Checkbox.model'

export const Checkbox: FunctionComponent<CheckboxProps> = ({
  checked,
  onChange,
  title,
  children,
  disabled = false,
  reverse = false,
}) => {
  return (
    <Tick
      type="checkbox"
      checked={checked}
      title={title}
      onChange={onChange}
      disabled={disabled}
      reverse={reverse}
    >
      {children}
    </Tick>
  )
}
