import { FC, useCallback } from 'react'
import { SearchExportFilterDocumentTemplatesProps } from './SearchExportFilterDocumentTemplates.model'
import { useSetAtom } from 'jotai'
import { exportFilterAddDocumentTemplate } from '../../../../state/configurableExport'
import { SearchDocumentTemplates } from '../../../medicalEvent/SearchDocumentTemplates/SearchDocumentTemplates.component'
import { DocumentTemplateListItem } from '../../../../model/DocumentTemplate'

export const SearchExportFilterDocumentTemplates: FC<SearchExportFilterDocumentTemplatesProps> = (
  props,
) => {
  const selectItems = useSetAtom(exportFilterAddDocumentTemplate)

  const handleSelectTemplates = useCallback(
    (items: Array<DocumentTemplateListItem>) => {
      selectItems(items)
      props.setBottomPanelOptions({ open: false })
    },
    [props, selectItems],
  )

  return <SearchDocumentTemplates setSelection={handleSelectTemplates} {...props} />
}
