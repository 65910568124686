import { connect } from 'react-redux'
import { RootState } from '../../../store'
import {
  assigningAuthoritiesSelector,
  domainAssigningAuthorithiesActions,
} from '../../../store/domain/assigningAuthorities'
import { loggedUserImpersonatePermissions } from '../../../store/ui/adminUsers'
import { PatientForm } from './PatientForm.component'
import { PatientFormDispatchProps, PatientFormStoreProps } from './PatientForm.model'
import { enabledFeaturesPreferencesSelector } from '../../../store/domain/me'

const mapStateToProps = (state: RootState): PatientFormStoreProps => ({
  assigningAuthorities: assigningAuthoritiesSelector(state),
  impersonatePermissions: loggedUserImpersonatePermissions(state),
  enabledFeatures: enabledFeaturesPreferencesSelector(state),
})

const mapDispatchToProps: PatientFormDispatchProps = {
  getAssigningAuthorithies: domainAssigningAuthorithiesActions.getAssigningAuthorithies,
}
const withConnect = connect(mapStateToProps, mapDispatchToProps)(PatientForm)
export { withConnect as PatientForm }
