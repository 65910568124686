import { isEmpty, isEqual, xorWith } from 'lodash'

/**
 * Suppression des doublons d'un tableau
 */
export function uniq<T>(list: T[]): T[] {
  return Array.from(new Set(list))
}

export const isArrayEqual = (x: ReadonlyArray<any>, y: ReadonlyArray<any>) =>
  isEmpty(xorWith(x, y, isEqual))
