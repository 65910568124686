import getApi from '../../../../api'
import { DmpMetricsPayload, Metric } from '../../../../model/Metrics'
import { RestuxApiConfig } from '../../../restux/cache/restuxCache.model'
import { ApisauceConfig } from 'apisauce'
import { MetricsParams, MetricsSendingInfosApi } from './metrics.model'
import { deserializeMetricsResponse, formatGetMetricsParams } from './metrics.mapper'

const baseUrl = '/metrics'

export const restuxMetricsApiConfig: RestuxApiConfig<Metric, Metric> = {
  url: baseUrl,
  getItem: (route) => getApi().get<Metric>(route),
}

export const fetchMetrics = (params: MetricsParams, config?: Partial<ApisauceConfig>) =>
  getApi()
    .get<MetricsSendingInfosApi>(baseUrl, formatGetMetricsParams(params), config)
    .then(deserializeMetricsResponse)

export const downloadMetrics = (params: MetricsParams) =>
  getApi().get<string>(`${baseUrl}/export`, formatGetMetricsParams(params))

export const sendDmpMetrics = (payload: DmpMetricsPayload) =>
  getApi().post(`${baseUrl}/dmp_document_sent_to_patient`, payload)
