import { useMutation, useQueryClient } from '@tanstack/react-query'
import { addValid } from '../../../../store/message'
import { useActionDispatch } from '../../../utils'
import { UserFriendlyError } from '../../../../model/Error'
import { PaginatedList } from '../../../../model/Pagination'
import { LegalDocument, LegalDocumentUpdatePayload } from '../../../../model/LegalDocument'
import { putLegalDocument } from '../../../../data/admin/legalDocument'
import { legalDocumentKeys } from '.'

interface LegalDocumentMutateVariables {
  id: string
  variables: LegalDocumentUpdatePayload
}

export const usePutLegalDocument = () => {
  const valid = useActionDispatch(addValid)
  const queryClient = useQueryClient()

  const queryReturn = useMutation<LegalDocument, Error, LegalDocumentMutateVariables>(
    async ({ id, variables }) => {
      const { ok, data } = await putLegalDocument(id, variables)
      if (!ok || !data) {
        throw new UserFriendlyError('error', 'La mise à jour du document légal a échoué')
      }
      return data
    },
    {
      onSuccess: (data) => {
        valid('Document légal mis à jour')
        queryClient.refetchQueries<PaginatedList<LegalDocument>>({
          queryKey: legalDocumentKeys.all,
          refetchPage: (page) => page.items.some(({ id }) => id === data.id),
        })
      },
    },
  )

  return queryReturn
}
