import { useField } from 'formik'
import React, { ChangeEvent } from 'react'
import { Input } from '../../Input'
import { onlyNumberInputPreventer } from '../../../../../misc/form.utilities'
import { FormikAucompleteInput } from './Input.model'
import { applyInputDateMask } from '../../../../../misc/date.utilities'

export const FormikDateInput = React.forwardRef<HTMLDivElement, FormikAucompleteInput>(
  (
    {
      label,
      fieldName,
      placeholder,
      autocomplete = true,
      disabled,
      colorPreset,
      required,
      onChange,
    },
    ref,
  ) => {
    const [formatted] = applyInputDateMask(fieldName)
    const [field, meta, helpers] = useField({ name: fieldName, value: formatted })

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const [formatted] = applyInputDateMask(event)
      helpers.setValue(formatted)
      if (onChange) {
        onChange(event)
      }
    }
    return (
      <Input
        ref={ref}
        value={field.value}
        label={label}
        name={fieldName}
        placeholder={placeholder}
        valid={!meta.touched || !meta.value ? undefined : !meta.error}
        error={meta.error}
        onChange={handleChange}
        onBlur={field.onBlur}
        onKeyPress={onlyNumberInputPreventer}
        autocomplete="off"
        disabled={disabled}
        required={required}
        colorPreset={colorPreset}
      />
    )
  },
)
