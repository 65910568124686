import { FunctionComponent, useEffect } from 'react'
import { PatientPageContent } from '../../../components/patient'
import { PatientPageProps } from './PatientPage.model'
import { FullLoader } from '../../../components/shared'
import styles from './PatientPage.module.scss'
import { useCurrentPatient } from '../../../hooks/utils'

export const PatientPage: FunctionComponent<PatientPageProps> = ({ clearPatientTimeline }) => {
  const { isLoadingCurrentPatient } = useCurrentPatient()

  useEffect(() => {
    return () => {
      clearPatientTimeline()
    }
  }, [clearPatientTimeline])

  return isLoadingCurrentPatient ? (
    <div className={styles.loadingContainer}>
      <FullLoader />
    </div>
  ) : (
    <PatientPageContent />
  )
}
