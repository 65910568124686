import { createReducer } from 'redux-starter-kit'
import { bottomPanelMailEditorActions } from './bottomPanelMailEditor.actions'
import {
  BottomPanelMailEditorConfig,
  BottomPanelMailEditorState,
  uiBottomPanelMailEditorActionTypes,
} from './bottomPanelMailEditor.model'

const DEFAULT_CONFIG: BottomPanelMailEditorConfig = {
  includeObservation: false,
  preselectedMssEmails: [],
  openedFrom: 'new',
}

const INITIAL_STATE: BottomPanelMailEditorState = {
  ...DEFAULT_CONFIG,
}

const ACTION_HANDLERS = {
  [uiBottomPanelMailEditorActionTypes.OPEN_EDITOR]: (
    state = INITIAL_STATE,
    { config }: ReturnType<typeof bottomPanelMailEditorActions.openEditor>,
  ) => ({
    ...state,
    ...DEFAULT_CONFIG,
    ...config,
  }),
}

export const bottomPanelMailEditorReducer = createReducer(INITIAL_STATE, ACTION_HANDLERS)
