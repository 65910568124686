import { FunctionComponent } from 'react'
import { Loader } from '../../../shared'
import { PatientInformationsData } from '../PatientInformationsData/PatientInformationsData'
import { PatientInformationsSearchResultsProps } from './PatientInformationsSearchResults.model'
import styles from './PatientInformationsSearchResults.module.scss'

export const PatientInformationsSearchResults: FunctionComponent<
  PatientInformationsSearchResultsProps
> = ({
  loading,
  groupName,
  search,
  data,
  hasResults,
  onIndicationGroupClick,
  onShowAddPatientInformationsFormClick,
}) => {
  return loading ? (
    <Loader isLoading className={styles.loader} width={70} height={70} />
  ) : (
    <>
      {hasResults ? (
        <PatientInformationsData
          groupName={groupName}
          data={data!}
          onIndicationGroupClick={onIndicationGroupClick}
          onShowAddPatientInformationsFormClick={onShowAddPatientInformationsFormClick}
        />
      ) : (
        search && <div className={styles.noResult}>Aucun résultat</div>
      )}
    </>
  )
}
