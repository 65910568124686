import { FC } from 'react'
import { ResourceHistoryPanelItemProps } from './ResourceHistoryPanelItem.model'
import { getFullName } from '../../../../misc/user.utilities'
import { formatFr } from '../../../../misc/date.utilities'
import { Icon } from '../../../shared'
import { getCssVariable } from '../../../../design-system/utils'

export const ResourceHistoryPanelItem: FC<ResourceHistoryPanelItemProps> = ({ historyDetail }) => {
  const { changes, date, user } = historyDetail
  const formatedDate = formatFr(date, 'dd/MM/yyyy HH:mm')
  const fullName = (user ? `Auteur·e : ${getFullName(user)}` : null) ?? 'Importé par Follow'

  return (
    <div className="w-full text-xs font-medium text-shades-4">
      <span className="text-base text-shades-2">
        {historyDetail.eventType === 'create' ? 'Création' : 'Modification'}
      </span>
      <div className="flex items-start w-full mr-2">
        <div className="flex flex-col space-y-1 flex-grow mr-2">
          {changes.length > 0 && <span>{changes.join(', ')}</span>}
          <span>{fullName}</span>
        </div>
        <div className="flex items-center min-w-32 space-x-1">
          <Icon icon="calendar" size="nano" color={getCssVariable('shades', 'shade4')} />
          <span>{formatedDate}</span>
        </div>
      </div>
    </div>
  )
}
