import React, { FunctionComponent } from 'react'
import classnames from 'classnames/bind'

import { DocumentCategoryIndicatorProps } from './DocumentCategoryIndicator.model'

import styles from './DocumentCategoryIndicator.module.scss'

const classnamesCx = classnames.bind(styles)

export const DocumentCategoryIndicator: FunctionComponent<DocumentCategoryIndicatorProps> = ({
  documentCategory,
}) => <div className={classnamesCx(styles.indicator, { [documentCategory]: true })} />
