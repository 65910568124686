import { defaultEditorConfig } from '@/context'
import { EditorConfig } from '@/model/Config'
import { useMemo } from 'react'

export const useEditorConfig = (config: Partial<EditorConfig>) => {
  return useMemo<EditorConfig>(() => {
    return Object.entries(config).reduce<EditorConfig>((acc, [key, value]) => {
      if (value) {
        return { ...acc, [key]: value }
      }
      return acc
    }, defaultEditorConfig)
  }, [config])
}
