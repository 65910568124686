import { ExternalServicesStatus } from '../../../model/ExternalServices'
import { ExternalServicesActionTypes } from './externalServices.model'

const getStatus = () => ({
  type: ExternalServicesActionTypes.GET_STATUS,
})

const setStatus = (status: ExternalServicesStatus[]) => ({
  type: ExternalServicesActionTypes.SET_STATUS,
  status,
})

export const externalServicesActions = {
  getStatus,
  setStatus,
}
