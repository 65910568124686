import React, { FunctionComponent } from 'react'
import sanitizeHtml from 'sanitize-html'

export const DangerousHtml: FunctionComponent<{ html: string }> = ({ html }) => {
  const sanitizedHtml = sanitizeHtml(html, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img']),
    allowedSchemesByTag: {
      img: ['data', 'http', 'https'],
    },
    allowedAttributes: {
      img: sanitizeHtml.defaults.allowedAttributes.img.concat(['style']),
      div: ['style'],
    },
    allowedClasses: {
      div: ['imageContainer'],
    },
    allowedStyles: {
      '*': {
        'text-align': [/^left$/, /^right$/, /^center$/],
        display: [/^inline-grid$/, /^inline$/],
      },
    },
  })
  return <span dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
}
DangerousHtml.displayName = 'DangerousHtml'
