import React, { FunctionComponent, useState, useMemo, useCallback } from 'react'
import { useDebounce } from 'react-use'

import { autocompletionPageLimit } from '../../../../../constants'
import { Contact, RecipientContact, ContactConfiguration } from '../../../../../model/Contact'
import { RestuxLoadingState } from '../../../../../store/restux/ui'
import { ContactCard, ContactSearchAutocomplete, PatientCard } from '../../../../contact'
import { DocumentSection } from '../../../../document'

import { DocumentContactsFormProps } from './DocumentContactsForm.model'
import styles from './DocumentContactsForm.module.scss'
import { BottomPanelContactsContext } from '../../../../../store/ui/bottomPanelContacts'
import { getDisplayedName } from '../../../../../misc/patient.utilities'
import { mapContactToRecipientContact } from '../../../../../misc/contact.utilities'
import { useCurrentPatient } from '../../../../../hooks/utils'
import { ContactConfigureBottomPanel } from '../../../../shared/bottomPanel/ContactConfigureBottomPanel'
import { isDefined } from '@follow/cdk'

function mapContactToString(contact: RecipientContact) {
  return `${contact.title} ${contact.firstName} ${contact.familyName}`
}

export const DocumentContactsForm: FunctionComponent<DocumentContactsFormProps> = ({
  contacts,
  onChangeContacts,
  onChangePatientRecipient,
  isEditable = true,
  patientAsCopyRecipient,
  patientAsMainRecipient,
  predictions,
  searchContact,
  clearPredictions,
  openCreateContactForm,
  openEditContactForm,
}) => {
  const { currentPatient: patient } = useCurrentPatient()
  const [searchValue, setSearchValue] = useState('')
  const [inUseContactId, setInUseContactId] = useState<string | null>(null)
  const subtitle = useMemo(() => {
    const copyContacts = contacts.filter(({ copyRecipient }) => copyRecipient)
    const mainContacts = contacts.filter(({ mainRecipient }) => mainRecipient)
    if (
      copyContacts.length === 0 &&
      mainContacts.length === 0 &&
      !patientAsCopyRecipient &&
      !patientAsMainRecipient
    ) {
      return `Aucun destinaire`
    }
    let main = mainContacts.map(mapContactToString).join(', ')
    let copy = copyContacts.map(mapContactToString).join(', ')
    if (patient) {
      const { firstname, lastname } = getDisplayedName(patient)
      const patientFullname = `${firstname} ${lastname}`
      if (patientAsCopyRecipient) {
        copy = `${copy ? `${copy}, ` : ``}${patientFullname}`
      }
      if (patientAsMainRecipient) {
        main = `${main ? `${main}, ` : ``}${patientFullname}`
      }
    }
    return `${main ? `${main}${copy ? ` ` : ``}` : ``}${copy ? `(en copie : ${copy})` : ``}`
  }, [contacts, patient, patientAsCopyRecipient, patientAsMainRecipient])

  useDebounce(
    () => {
      handleSearchContact()
    },
    400,
    [searchValue],
  )

  const handleSearchContact = () => {
    if (searchValue.length > 1) {
      searchContact({
        page: { currentPage: 1, pageSize: autocompletionPageLimit },
        filters: { search: searchValue, withOrganizationOnly: 'true' },
      })
    } else {
      clearPredictions()
    }
  }

  const handleToggleConfigurationContact = useCallback((id: string) => {
    setInUseContactId(id)
  }, [])

  const handleEditedContact = useCallback(
    (item: ContactConfiguration) => {
      onChangeContacts(
        contacts.map((contact) => ({
          ...contact,
          contactConfiguration: inUseContactId === contact.id ? item : contact.contactConfiguration,
        })),
      )
      setInUseContactId(null)
    },
    [onChangeContacts, contacts, inUseContactId],
  )

  function onSelectContact(contact: Contact) {
    // Gestion des doublons
    if (!contacts.find(({ id }) => contact.id === id)) {
      clearPredictions()
      onChangeContacts([
        ...contacts,
        {
          ...contact,
          ...mapContactToRecipientContact(contact),
        },
      ])
      setSearchValue('')
    }
  }

  function handleChangeCopyRecipient(contactId: string, value: boolean) {
    onChangeContacts(
      contacts.map((contact) => ({
        ...contact,
        copyRecipient: contact.id === contactId ? value : contact.copyRecipient,
        mainRecipient: contact.id === contactId ? false : contact.mainRecipient,
      })),
    )
  }

  function handleChangeMainRecipient(contactId: string, value: boolean) {
    onChangeContacts(
      contacts.map((contact) => ({
        ...contact,
        copyRecipient: contact.id === contactId ? false : contact.copyRecipient,
        mainRecipient: contact.id === contactId ? value : contact.mainRecipient,
      })),
    )
  }

  function handleChangeOrganization(contactId: string, organizationId: string) {
    onChangeContacts(
      contacts.map((contact) => {
        const organization =
          contact.id === contactId
            ? contact.organizations.find(({ id }) => id === organizationId) || contact.organization
            : contact.organization
        return {
          ...contact,
          organization,
        }
      }),
    )
  }

  function handleChangeMssEmail(contactId: string, mssEmailId: string) {
    onChangeContacts(
      contacts.map((contact) => {
        const mssEmail =
          contact.id === contactId
            ? contact.mssEmails.find(({ id }) => id === mssEmailId) || contact.mssEmail
            : contact.mssEmail
        return {
          ...contact,
          mssEmail,
        }
      }),
    )
  }

  function handleChangePatientCopyRecipient(copyRecipient: boolean) {
    onChangePatientRecipient({
      patientAsCopyRecipient: copyRecipient,
      patientAsMainRecipient: copyRecipient ? false : patientAsMainRecipient,
    })
  }

  function handleChangePatientMainRecipient(mainRecipient: boolean) {
    onChangePatientRecipient({
      patientAsCopyRecipient: mainRecipient ? false : patientAsCopyRecipient,
      patientAsMainRecipient: mainRecipient,
    })
  }

  return (
    <DocumentSection title="Destinataires" subtitle={subtitle}>
      <div className={styles.content}>
        <ContactSearchAutocomplete
          searchValue={searchValue}
          predictions={predictions.items}
          loading={predictions.loadingState !== RestuxLoadingState.IDLE}
          onSearchValueChange={(event) => setSearchValue(event.target.value)}
          onHideSuggestion={() => clearPredictions()}
          onSelectContact={onSelectContact}
          createContact={() => openCreateContactForm(BottomPanelContactsContext.DOCUMENT_CONTACT)}
          onFocus={handleSearchContact}
          colorPreset="light"
          disabled={!isEditable}
        />
        <div className={styles.contacts}>
          {contacts.map((contact, index) => (
            <div key={index} className={styles.contact}>
              <ContactCard
                context={BottomPanelContactsContext.DOCUMENT_CONTACT}
                showAddresses
                contact={contact}
                loading={false}
                disabled={!isEditable}
                recipientDisabled={!isEditable}
                onClickDisplayDetails={openEditContactForm}
                onClickDisplayConfiguration={handleToggleConfigurationContact}
                onChangeCopyRecipient={(event) =>
                  handleChangeCopyRecipient(contact.id, event.target.checked)
                }
                onChangeMainRecipient={(event) =>
                  handleChangeMainRecipient(contact.id, event.target.checked)
                }
                onChangeOrganization={(organizationId) =>
                  handleChangeOrganization(contact.id, organizationId)
                }
                onChangeMssEmail={(mssEmailId) => handleChangeMssEmail(contact.id, mssEmailId)}
              />
            </div>
          ))}
          {patient && (
            <div className={styles.contact}>
              <PatientCard
                patient={patient}
                disabled={!isEditable}
                copyRecipient={patientAsCopyRecipient}
                mainRecipient={patientAsMainRecipient}
                onChangeCopyRecipient={(e) => handleChangePatientCopyRecipient(e.target.checked)}
                onChangeMainRecipient={(e) => handleChangePatientMainRecipient(e.target.checked)}
              />
            </div>
          )}
        </div>
      </div>
      <ContactConfigureBottomPanel
        itemId={inUseContactId}
        onItemEdited={(item) => handleEditedContact(item)}
        onRequestClose={() => setInUseContactId(null)}
        display={isDefined(inUseContactId)}
      />
    </DocumentSection>
  )
}
