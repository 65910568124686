import getApi from '../../../../api/index'
import { CodeCategory } from '../../../../model/Code'
import { PaginatedList } from '../../../../model/Pagination'

export const getCodes = () => {
  return getApi().get<PaginatedList<CodeCategory>>('list_value_categories', {
    page: 1,
    limit: 1000,
  })
}
