import { RestuxPagination, RestuxInUse, RestuxUiSagaConf } from '../../restux/ui/restuxUi.model'
import { toUpperSnakeCase } from '../../restux/restux.utilities'
import { RestuxResources } from '../../resources'
import { RootState } from '../../reducers/reducers.model'
import { RESTUX_IDENTIFIER } from '../../restux.identifier'
import { DocumentCategoryKeys } from '../../../model/DocumentCategory'

export const managerDocumentTemplatesConfig: RestuxUiSagaConf = {
  resourceName: RestuxResources.documentTemplate,
  identifier: RESTUX_IDENTIFIER.manageDocumentTemplate,
  paginationSelector: (state: RootState) => state.ui.managerDocumentTemplates.pagination,
}

export interface ManagerDocumentTemplateState {
  pagination: RestuxPagination
  inUse: RestuxInUse
}

const RESOURCE_PREFIX = `@${toUpperSnakeCase(RestuxResources.documentTemplate)}`

export const UiManagerDocumentTemplateActionTypes = {
  ADD_EXISTING_QUESTION_TO_DOCUMENT_IN_USE_TEMPLATE: `${RESOURCE_PREFIX}/ADD_EXISTING_QUESTION_TO_IN_USE_DOCUMENT_TEMPLATE`,
  ADD_QUESTIONNAIRE_TO_IN_USE_DOCUMENT_TEMPLATE: `${RESOURCE_PREFIX}/ADD_QUESTIONNAIRE_TO_IN_USE_DOCUMENT_TEMPLATE`,
  ADD_QUESTION_TO_IN_USE_DOCUMENT_TEMPLATE: `${RESOURCE_PREFIX}/ADD_QUESTION_TO_IN_USE_DOCUMENT_TEMPLATE`,
  CREATE: `${RESOURCE_PREFIX}/CREATE`,
  UPDATE: `${RESOURCE_PREFIX}/UPDATE`,
}

export type DocumentTemplateCreateResource =
  | {
      documentTemplateCategory: DocumentCategoryKeys
      documentType: 'farte'
    }
  | {
      documentTemplateCategory: DocumentCategoryKeys
      documentType: 'pdf'
      file: File
    }
