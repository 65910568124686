import getApi from '../../../../api/apisauceConfig'
import { DocumentInstance } from '../../../../model/DocumentInstance'
import { ApiDocumentInstanceResource } from '../../../cache/documentInstances/api'
import { deserializeDocumentInstanceResponse } from '../../../cache/documentInstances/api/DocumentInstances.mapper'

export const getDocumentInstance = (id: number) =>
  getApi()
    .get<ApiDocumentInstanceResource>(`documents/${id}`)
    .then(deserializeDocumentInstanceResponse)

export const lockDocumentInstance = (id: number, locked: boolean) =>
  getApi().post<DocumentInstance>(`documents/${id}/lock`, {
    locked,
  })

export const lockMultipleDocuments = (documentIds: Array<number>, locked: boolean) =>
  getApi().post<DocumentInstance[]>(`documents/lock/batch`, {
    locked,
    documentIds,
  })
export const renewPrescription = (id: number) =>
  getApi().post<DocumentInstance>(`documents/${id}/renew`, {})
