import { DocumentInstanceListItem } from '../../../model/DocumentInstance'
import { ApiDocumentInstanceListItemResource } from './documentInstances.model'

export function deserializeDocumentInstanceListItem(
  item: ApiDocumentInstanceListItemResource,
): DocumentInstanceListItem {
  return {
    ...item,
    category: item.documentCategory.id,
    contacts: item.contacts,
  }
}
