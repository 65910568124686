import { connect } from 'react-redux'
import { RootState } from '../../../store'
import { domainMailActions } from '../../../store/domain/mail'
import { loginInitateStatusSelector } from '../../../store/domain/mail/mail.selector'
import { currentUserSelector, enabledFeaturesPreferencesSelector } from '../../../store/domain/me'
import { MailLoginPage } from './MailLoginPage.component'
import { MailLoginPageDispatchProps, MailLoginPageStoreProps } from './MailLoginPage.model'

const mapStateToProps = (state: RootState): MailLoginPageStoreProps => ({
  loginInitiate: loginInitateStatusSelector(state),
  currentUser: currentUserSelector(state),
  enabledFeatures: enabledFeaturesPreferencesSelector(state),
})

const mapDispatchToProps: MailLoginPageDispatchProps = {
  initializeAuthentication: domainMailActions.initiateAuthMail,
  finalizeAuthentication: domainMailActions.finalizeAuthMail,
  setLoginInitiate: domainMailActions.setLoginInitiate,
  setOtpStatus: domainMailActions.setOtpStatus,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)(MailLoginPage)
export { withConnect as MailLoginPage }
