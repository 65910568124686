import React, { FunctionComponent } from 'react'
import { LegalDocumentListItemProps } from './LegalDocumentListItem.model'
import styles from './LegalDocumentListItem.module.scss'

export const LegalDocumentListItem: FunctionComponent<LegalDocumentListItemProps> = ({
  legalDocument,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.title}>{legalDocument.title}</div>
      </div>
    </div>
  )
}
