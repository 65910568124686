import { DoctolibPatient, DoctolibPatientOpen } from './doctolib.model'
import { openFollowPatient } from './doctolib.utils'

export const onInitPatient = (
  patient: DoctolibPatient,
  createOrUpdatePatientCallback: (patient: DoctolibPatient) => void,
) => {
  createOrUpdatePatientCallback(patient)
}

export const onUpdatePatient = (
  patient: DoctolibPatient,
  createPatientCallback: (patient: DoctolibPatient) => void,
) => {
  createPatientCallback(patient)
}

export const onOpenPatient = (patient: DoctolibPatientOpen) => {
  if (patient.pms_id) {
    const fwPatientId = parseInt(patient.pms_id, 10)
    if (Number.isInteger(fwPatientId)) {
      openFollowPatient(fwPatientId)
    } else {
      alert(
        `L'identifiant du patient indiqué par doctolib n'a pas le bon format: (${JSON.stringify(
          patient.pms_id,
        )})`,
      )
    }
  }
}
