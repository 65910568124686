import { RestuxResources } from '../../resources'
import { restuxCacheFactory } from '../../restux/cache'
import { MedicalEvent } from '../../../model/MedicalEvent'
import { restuxMedicalEventApiConfig } from './api'

const resourceName = RestuxResources.medicalEvent

const { actions, reducers, sagas } = restuxCacheFactory<MedicalEvent, MedicalEvent>({
  apiConfig: restuxMedicalEventApiConfig,
  refetchList: false,
  resourceName,
})

export const medicalEventsActions = actions
export const medicalEventsReducers = reducers
export const medicalEventsSagas = sagas
