import { FC } from 'react'
import { Modal, ActionModalLayout } from '../../../shared'
import { TaskInformationModalProps } from './TaskInformationModal.model'

export const TaskInformationModal: FC<TaskInformationModalProps> = ({
  isUnmounting,
  taskInformation,
  onClose,
}) => {
  return (
    <Modal isUnmounting={isUnmounting} onClickOutside={onClose}>
      <ActionModalLayout title="Informations" onClose={onClose}>
        <div className="bg-white py-4 px-6 w-103 flex flex-col">
          {taskInformation?.map(({ title, value }) => (
            <span>
              <span className="font-semibold">{title} : </span>
              {value}
            </span>
          ))}
        </div>
      </ActionModalLayout>
    </Modal>
  )
}
