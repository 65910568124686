import { connect } from 'react-redux'

import {
  AddMedicalEventContentDispatchProps,
  AddMedicalEventContentStateProps,
} from './AddMedicalEventContent.model'

import { AddMedicalEventContent } from './AddMedicalEventContent.component'
import {
  bottomPanelDocumentTemplatesActions,
  bottomPanelDocumentTemplatesPageSelector,
} from '../../../../store/ui/bottomPanelDocumentTemplates'
import { RootState } from '../../../../store'
import { domainDocumentTemplatesActions } from '../../../../store/domain/documentTemplates'
import { medicalEventDomainActions } from '../../../../store/domain/medicalEvents'
import { inUseMedicalEventDocumentTemplatesSuggestionSelector } from '../../../../store/ui/medicalEvents/medicalEventDocumentTemplatesSuggestion'
import { documentCategoriesDomainAction } from '../../../../store/domain/documentCategories'
import { usedDocumentCategoriesSelector } from '../../../../store/domain/documentCategories/documentCategories.selectors'

const mapStateToPorps = (state: RootState): AddMedicalEventContentStateProps => ({
  documentTemplatesPage: bottomPanelDocumentTemplatesPageSelector(state),
  suggestions: inUseMedicalEventDocumentTemplatesSuggestionSelector(state),
  usedDocumentCategories: usedDocumentCategoriesSelector(state),
})

const mapDispatchToProps: AddMedicalEventContentDispatchProps = {
  getPaginatedDocumentTemplate: bottomPanelDocumentTemplatesActions.requestPage,
  clearDocumentTemplateList: bottomPanelDocumentTemplatesActions.clearPagination,
  clearDocumentTemplatesSuggestion: domainDocumentTemplatesActions.clearDocumentTemplatesSuggestion,
  getDocumentTemplatesSuggestion: domainDocumentTemplatesActions.getDocumentTemplatesSuggestion,
  addDocumentInstances: medicalEventDomainActions.addDocumentInstances,
  getUsedDocumentCategories: documentCategoriesDomainAction.fetchUsedDocumentCategories,
}

const withConnect = connect(mapStateToPorps, mapDispatchToProps)(AddMedicalEventContent)
export { withConnect as AddMedicalEventContent }
