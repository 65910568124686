import React, { FunctionComponent } from 'react'

import { PatientInfosShowMoreProps } from './PatientInfosShowMore.model'
import styles from './PatientInfosShowMore.module.scss'
import { IconChip } from '../../..//shared'

export const PatientInfosShowMore: FunctionComponent<PatientInfosShowMoreProps> = ({
  showingMore,
  onClick,
}) => (
  <div className={styles.showMore}>
    <IconChip
      size="small"
      icon="chevron"
      iconRotate={showingMore ? 180 : 0}
      iconPosition="right"
      borderSize={1}
      label={showingMore ? 'Voir moins' : 'Voir plus'}
      onClick={() => onClick(!showingMore)}
    />
  </div>
)
