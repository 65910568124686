import { FunctionComponent } from 'react'
import { MailBodyProps } from './MailBody.model'

const parser = new DOMParser()

export const MailBody: FunctionComponent<MailBodyProps> = ({ inUseMail }) => (
  <div className="flex flex-col my-5 border-b-2 border-shades-7 ">
    <div
      className="text-shades-2 text-base font-medium my-2 break-words"
      dangerouslySetInnerHTML={{
        __html: parser.parseFromString(inUseMail.body, 'text/html').body.innerHTML,
      }}
    />
  </div>
)
