import classNames from 'classnames'
import { FC } from 'react'
import { AlertDetailBoxProps } from './AlertDetailBox.model'

export const AlertDetailBox: FC<AlertDetailBoxProps> = ({ title, children, large = false }) => (
  <div
    className={classNames('text-sm grid', {
      'col-span-2': large,
    })}
  >
    <span className="text-shades-4 font-medium">{title}</span>
    <span className="ml-2 mt-1 text-shades-2">{children}</span>
  </div>
)
