import { AdminUsersActionTypes } from './adminUsers.model'
import { LightUser, User } from '../../../model/User'

export const initializeUsurpedUser = () => ({
  type: AdminUsersActionTypes.INITIALIZE_USURPED_USER,
})

export const setUsurpedUser = (user?: LightUser) => ({
  type: AdminUsersActionTypes.SET_USURPED_USER,
  user,
})

export const storeUsurpedUser = (user?: User) => ({
  type: AdminUsersActionTypes.STORE_USURPED_USER,
  user,
})

export const usurpedUserInitializationDone = () => ({
  type: AdminUsersActionTypes.USURPED_USER_INITIALIZATION_DONE,
})
