export const library: { [key: string]: any } = {
  default: {
    view: '0 0 512 512',
    path: `<path d="M256 0C115.39 0 0 115.39 0 256s115.39 256 256 256 256-115.39 256-256S396.61 0 256 0zM97.108 368.954C73.48 335.805 61 296.737 61 256c0-107.52 87.48-195 195-195 40.737 0 79.805 12.48 112.954 36.108a15.132 15.132 0 0 1 1.886 1.625L98.733 370.841a15.215 15.215 0 0 1-1.625-1.887zM256 451c-40.737 0-79.805-12.48-112.954-36.108a15.132 15.132 0 0 1-1.886-1.625l272.105-272.105a14.92 14.92 0 0 1 1.626 1.885C438.52 176.195 451 215.263 451 256c0 107.52-87.48 195-195 195z"/>`,
  },
  bold: {
    view: '0 0 24 24',
    path: `<path d="M17.194 10.962A6.271 6.271 0 0 0 12.844.248H4.3a1.25 1.25 0 1 0 0 2.5h1.013a.25.25 0 0 1 .25.25V21a.25.25 0 0 1-.25.25H4.3a1.25 1.25 0 0 0 0 2.5h9.963a6.742 6.742 0 0 0 2.93-12.786l.001-.002zm-4.35-8.214a3.762 3.762 0 0 1 0 7.523H8.313a.25.25 0 0 1-.25-.25V3a.25.25 0 0 1 .25-.25l4.531-.002zm1.42 18.5H8.313a.25.25 0 0 1-.25-.25v-7.977a.25.25 0 0 1 .25-.25H14.264a4.239 4.239 0 0 1 0 8.477z"/>`,
  },
  center: {
    view: '0 0 24 24',
    path: `<path d="M22.667 24H1.333C.597 24 0 23.428 0 22.723s.597-1.276 1.333-1.276h21.334c.736 0 1.333.571 1.333 1.276 0 .705-.597 1.277-1.333 1.277zm0-21.447H1.333C.597 2.553 0 1.982 0 1.277 0 .572.597 0 1.333 0h21.334C23.403 0 24 .572 24 1.277s-.597 1.276-1.333 1.276zm-2.134 7.15H3.467c-.737 0-1.334-.572-1.334-1.277 0-.706.597-1.277 1.334-1.277h17.066c.737 0 1.334.571 1.334 1.277 0 .705-.597 1.276-1.334 1.276zm-2.666 7.148H6.133c-.736 0-1.333-.571-1.333-1.277 0-.705.597-1.276 1.333-1.276h11.734c.736 0 1.333.571 1.333 1.276 0 .706-.597 1.277-1.333 1.277z"/>`,
  },
  clipboard: {
    view: '0 0 24 24',
    path: ` <path d="M19,3H14.82C14.25,1.44 12.53,0.64 11,1.2C10.14,1.5 9.5,2.16 9.18,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3M12,3A1,1 0 0,1 13,4A1,1 0 0,1 12,5A1,1 0 0,1 11,4A1,1 0 0,1 12,3M7,7H17V5H19V19H5V5H7V7M17,11H7V9H17V11M15,15H7V13H15V15Z" />`,
  },
  italic: {
    view: '0 0 24 24',
    path: `<path d="M22.5.25h-7.637a1.25 1.25 0 0 0 0 2.5h1.086a.25.25 0 0 1 .211.384L4.78 21.019a.5.5 0 0 1-.422.231H1.5a1.25 1.25 0 0 0 0 2.5h7.637a1.25 1.25 0 0 0 0-2.5H8.051a.25.25 0 0 1-.211-.384L19.22 2.982a.5.5 0 0 1 .422-.232H22.5a1.25 1.25 0 0 0 0-2.5z"/>`,
  },
  image: {
    view: '0 0 18 18',
    path: `<path fill-rule="evenodd" d="M16,11.9219 C13.412,13.4209 12.307,13.3429 9.521,11.6459 C6.475,9.7919 4.667,9.5269 2,10.8179 L2,3.9999 L16,3.9999 L16,11.9219 Z M2,13.9999 L2,13.0779 C4.588,11.5789 5.693,11.6569 8.479,13.3539 C8.884,13.5999 9.26,13.8089 9.625,13.9999 L2,13.9999 Z M17,1.9999 C17.553,1.9999 18,2.4479 18,2.9999 L18,14.9999 C18,15.5519 17.553,15.9999 17,15.9999 L1,15.9999 C0.447,15.9999 0,15.5519 0,14.9999 L0,2.9999 C0,2.4479 0.447,1.9999 1,1.9999 L17,1.9999 Z M12.5,9 C13.328,9 14,8.329 14,7.5 C14,6.671 13.328,6 12.5,6 C11.672,6 11,6.671 11,7.5 C11,8.329 11.672,9 12.5,9 Z" />`,
  },
  justified: {
    view: '0 0 24 24',
    path: `<path d="M14.924 24H1.277C.572 24 0 23.404 0 22.67c0-.735.572-1.331 1.277-1.331h13.647c.705 0 1.277.596 1.277 1.33 0 .735-.572 1.331-1.277 1.331zm7.8-21.339H1.276C.572 2.661 0 2.065 0 1.331 0 .596.572 0 1.277 0h21.446C23.428 0 24 .596 24 1.33c0 .735-.572 1.331-1.277 1.331zm0 7.113H1.276C.572 9.774 0 9.178 0 8.444c0-.735.572-1.331 1.277-1.331h21.446c.705 0 1.277.596 1.277 1.33 0 .735-.572 1.331-1.277 1.331zm0 7.112H1.276C.572 16.886 0 16.29 0 15.556c0-.735.572-1.331 1.277-1.331h21.446c.705 0 1.277.596 1.277 1.33 0 .735-.572 1.331-1.277 1.331z"/>`,
  },
  left: {
    view: '0 0 24 24',
    path: `<path d="M22.723 24H1.277C.572 24 0 23.404 0 22.67c0-.735.572-1.331 1.277-1.331h21.446c.705 0 1.277.596 1.277 1.33 0 .735-.572 1.331-1.277 1.331zM19.8 2.661H1.277C.572 2.661 0 2.065 0 1.331 0 .596.572 0 1.277 0h18.522c.706 0 1.277.596 1.277 1.33 0 .735-.571 1.331-1.277 1.331zm-3.9 7.113H1.277C.572 9.774 0 9.178 0 8.444c0-.735.572-1.331 1.277-1.331h14.622c.705 0 1.277.596 1.277 1.33 0 .735-.572 1.331-1.277 1.331zm2.925 7.113H1.277c-.705 0-1.277-.596-1.277-1.33 0-.735.572-1.331 1.277-1.331h17.547c.705 0 1.277.596 1.277 1.33 0 .735-.572 1.331-1.277 1.331z"/>`,
  },
  orderedList: {
    view: '0 0 24 24',
    path: `<path d="M7.75 4.5a1 1 0 1 1 0-2h15a1 1 0 0 1 0 2h-15zm15 6.5a1 1 0 0 1 0 2h-15a1 1 0 0 1 0-2h15zm0 8.5a1 1 0 0 1 0 2h-15a1 1 0 0 1 0-2h15zM2.212 17.248v-.002a2 2 0 0 1 1.662 3.111.251.251 0 0 0 0 .278 2 2 0 1 1-3.589 1.648.75.75 0 1 1 1.445-.4.5.5 0 1 0 .482-.635.75.75 0 1 1 0-1.5.5.5 0 1 0-.483-.63.75.75 0 0 1-1.45-.386 2 2 0 0 1 1.933-1.484zm2.038-6.5c0 .574-.193 1.132-.55 1.582l-.814 1.012a.249.249 0 0 0 .195.406H3.5a.75.75 0 1 1 0 1.5H1a.75.75 0 0 1-.586-1.219l2.109-2.636c.147-.183.227-.41.227-.645a.5.5 0 1 0-1 0 .75.75 0 1 1-1.5 0 2 2 0 1 1 4 0zM4 5.248v.002a.75.75 0 0 1 0 1.5H1.5a.75.75 0 0 1 0-1.5h.25A.25.25 0 0 0 2 5V2a.25.25 0 0 0-.25-.252H1.5a.75.75 0 0 1 0-1.5h.625A1.377 1.377 0 0 1 3.5 1.623V5a.25.25 0 0 0 .25.248H4z"/>`,
  },
  pagebreak: {
    view: '0 0 24 24',
    path: `<path d="M20 21.46L13.63 16H5a1 1 0 0 0-1 1v6a1 1 0 0 1-2 0v-6a3 3 0 0 1 3-3h9a1 1 0 0 1 .65.24l7 6A1 1 0 0 1 22 21v2a1 1 0 0 1-2 0v-1.54zM2 1a1 1 0 1 1 2 0v4a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V1a1 1 0 0 1 2 0v4a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V1zm0 11a1 1 0 0 1 0-2h2a1 1 0 0 1 0 2H2zm6 0a1 1 0 0 1 0-2h2a1 1 0 0 1 0 2H8zm6 0a1 1 0 0 1 0-2h2a1 1 0 0 1 0 2h-2zm6 0a1 1 0 0 1 0-2h2a1 1 0 0 1 0 2h-2z"/>`,
  },
  right: {
    view: '0 0 24 24',
    path: `<path d="M22.717 24H1.279C.573 24 0 23.403 0 22.667c0-.737.573-1.334 1.279-1.334h21.438c.706 0 1.279.597 1.279 1.334 0 .736-.573 1.333-1.28 1.333zm0-21.333H4.2c-.706 0-1.279-.597-1.279-1.334C2.922.597 3.495 0 4.202 0H22.72C23.427 0 24 .597 24 1.333c0 .737-.573 1.334-1.279 1.334h-.004zm0 7.111H8.1c-.707 0-1.28-.597-1.28-1.333 0-.737.573-1.334 1.28-1.334h14.617c.706 0 1.279.597 1.279 1.334 0 .736-.573 1.333-1.28 1.333zm0 7.11H5.177c-.707 0-1.28-.596-1.28-1.333 0-.736.573-1.333 1.28-1.333h17.54c.706 0 1.279.597 1.279 1.333 0 .737-.573 1.334-1.28 1.334z"/>`,
  },
  underline: {
    view: '0 0 24 24',
    path: `<path d="M22.5 21.248a1.25 1.25 0 0 1 0 2.5h-21a1.25 1.25 0 0 1 0-2.5h21zM1.978 2.748v.002a1.25 1.25 0 1 1 0-2.5H7.7a1.25 1.25 0 1 1 0 2.5H6.345a.25.25 0 0 0-.25.25v8.523a5.909 5.909 0 0 0 11.818 0V3a.25.25 0 0 0-.25-.25H16.3a1.25 1.25 0 0 1 0-2.5h5.722a1.25 1.25 0 0 1 0 2.5h-1.363a.25.25 0 0 0-.25.25v8.521a8.409 8.409 0 1 1-16.818 0V2.998a.25.25 0 0 0-.25-.25H1.978z"/>`,
  },
  undo: {
    view: '0 0 512 512',
    path: `<path d="M447.9 368.2c0-16.8 3.6-83.1-48.7-135.7-35.2-35.4-80.3-53.4-143.3-56.2V96L64 224l192 128v-79.8c40 1.1 62.4 9.1 86.7 20 30.9 13.8 55.3 44 75.8 76.6l19.2 31.2H448c0-10.1-.1-22.9-.1-31.8z"/>`,
  },

  redo: {
    view: '0 0 512 512',
    path: `<path d="M64 400h10.3l19.2-31.2c20.5-32.7 44.9-62.8 75.8-76.6 24.4-10.9 46.7-18.9 86.7-20V352l192-128L256 96v80.3c-63 2.8-108.1 20.7-143.3 56.2C60.4 285.2 64 351.5 64 368.2c.1 8.9 0 21.7 0 31.8z"/>`,
  },
  unorderedList: {
    view: '0 0 24 24',
    path: `<path d="M2.5 6.498a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zM8.5 5a1 1 0 1 1 0-2H23a1 1 0 0 1 0 2H8.5zm-6 9.498a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zM23 11a1 1 0 0 1 0 2H8.5a1 1 0 0 1 0-2H23zM2.5 22.498a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zM23 19a1 1 0 0 1 0 2H8.5a1 1 0 0 1 0-2H23z"/>`,
  },
  // Svp, essayez de garder ce fichier trié par nom d'icone :) (ca évitera les doublons, comme "dots" et "dotsSquare" actuellement dans le fichier)
  // Et de ne mettre ici que les "path" et les "transform" associés eventuels
}

export type IconsType = keyof typeof library
