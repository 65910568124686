import React, { FunctionComponent, useState } from 'react'
import { PatientsListExtendedActionsProps } from './PatientsListExtendedActions.model'
import { DatePicker, SearchInput, TooltipWrapper, Icon } from '../../../shared'
import { Colors } from '../../../../colors'
import { AtcClassSelect, ActivePrinciplesSelect } from '../../../drug'
import { formatISO } from 'date-fns'
import { useDebounce } from 'react-use'

export const PatientsListExtendedActions: FunctionComponent<PatientsListExtendedActionsProps> = ({
  onSearchFiltersChange,
  filters: {
    drugSearch,
    prescriptionReason,
    drugStartDate,
    drugEndDate,
    atcCodeExternalId,
    activePrinciple,
  },
}) => {
  const [debouncedFilters, setDebouncedFilters] = useState({
    drugSearch: drugSearch,
    prescriptionReason: prescriptionReason,
  })

  useDebounce(
    () => {
      onSearchFiltersChange(debouncedFilters)
    },
    500,
    [debouncedFilters],
  )

  return (
    <div className="flex items-center">
      <div className="flex-[4]">
        <SearchInput
          value={debouncedFilters.prescriptionReason}
          placeholder="Rechercher par motif de prescription"
          onChange={({ target: { value } }) =>
            setDebouncedFilters((prev) => ({ ...prev, prescriptionReason: value }))
          }
        />
      </div>
      <div className="w-px mx-4 bg-shades-7" />
      <div className="flex-[3] mr-4">
        <ActivePrinciplesSelect
          setActivePrincipleSelected={(value) => {
            onSearchFiltersChange({
              activePrinciple: value ? value.name : '',
            })
          }}
          colorPreset="light"
          placeHolder="Rechercher par principe actif"
          initialSearch={activePrinciple}
        />
      </div>
      <div className="flex-[3] mr-4">
        <AtcClassSelect
          classId={atcCodeExternalId ? atcCodeExternalId : undefined}
          colorPreset="light"
          onClassSelected={(atcClass) => {
            onSearchFiltersChange({ atcCodeExternalId: atcClass ? atcClass.id : null })
          }}
        />
      </div>
      <div className="flex-[3] mr-4">
        <SearchInput
          icon="pills"
          value={debouncedFilters.drugSearch}
          placeholder="Rechercher par médicament prescrit"
          onChange={({ target: { value } }) =>
            setDebouncedFilters((prev) => ({ ...prev, drugSearch: value }))
          }
        />
      </div>
      <div className="flex-[1] mr-4">
        <DatePicker
          label="Début"
          icon="calendar"
          colorPreset="light"
          placeholder="Début"
          value={drugStartDate}
          onChange={(value) => onSearchFiltersChange({ drugStartDate: value && formatISO(value) })}
          inputType="search"
          showCalendarButton={false}
        />
      </div>
      <div className="flex-[1] mr-2">
        <DatePicker
          label="Fin"
          icon="calendar"
          colorPreset="light"
          placeholder="Fin"
          value={drugEndDate}
          onChange={(value) => onSearchFiltersChange({ drugEndDate: value && formatISO(value) })}
          inputType="search"
          showCalendarButton={false}
        />
      </div>
      <TooltipWrapper
        content="La recherche trouvera les patients qui ont été sous traitement de ce médicament au moins un jour pendant la période renseignée"
        size="small"
        pointerDirection="right"
      >
        <Icon size="micro" icon="infoCircle" color={Colors.backgroundPrimary} />
      </TooltipWrapper>
    </div>
  )
}
