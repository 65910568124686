import { combineReducers } from 'redux'
import { countriesConfig, CountriesState } from './countries.model'
import {
  inUseReducerFactory,
  paginationReducerFactory,
} from '../../restux/ui/restuxUiReducer.factory'

const pagination = paginationReducerFactory(countriesConfig)

const inUse = inUseReducerFactory(countriesConfig)

export const countriesReducer = combineReducers<CountriesState>({
  pagination,
  inUse,
})
