import { FunctionComponent, useCallback, useContext, useMemo, useState } from 'react'
import { MedicalEventHeaderProps } from './MedicalEventHeader.model'

import { IconButton, TooltipWrapper } from '../../../../shared'
import { Space } from './Space'

import styles from './MedicalEventHeader.module.scss'
import { DATE_FORMAT_FRONT_SHORT, formatFr } from '../../../../../misc/date.utilities'
import { getFullName, isDoctor } from '../../../../../misc/user.utilities'
import { ConnectedUserContext } from '../../../../../misc/auth.utilities'
import { hasDoctorRole } from '../../../../../misc/roles.utilities'
import { PatientPageHeader } from '../../PatientPageNavBar/PatientPageHeader'
import { useNavigate } from 'react-router-dom'
import { SearchMedicalEventDocumentsBottomPanel } from '../../../../shared/bottomPanel/SearchMedicalEventDocumentsBottomPanel'
import { useDownloadDocuments, usePrintDocuments } from '../../../../../hooks/queries/document'
import { DocumentInstanceListItem } from '../../../../../model/DocumentInstance'
import { getRenderedPdfFileName } from '../../../../../misc/files.utilities'
import { BasicRenderMode } from '../../../../../model/Document'

export const MedicalEventHeader: FunctionComponent<MedicalEventHeaderProps> = ({
  patient,
  medicalEvent,
  userEnabledFeatures,
  editMedicalEvent,
  sendMedicalEventDocuments,
  isOwner,
  onLock,
}) => {
  const [
    isOpenSearchMedicalEventDocumentsBottomPanel,
    setIsOpenSearchMedicalEventDocumentsBottomPanel,
  ] = useState<boolean | BasicRenderMode>(false)
  const { loggedUser, currentUser } = useContext(ConnectedUserContext)
  const allowedToLock = loggedUser && isOwner && hasDoctorRole(loggedUser.roles)
  const navigate = useNavigate()
  const print = usePrintDocuments()
  const download = useDownloadDocuments()

  const tooltipContent = useMemo(() => {
    if (userEnabledFeatures?.mail) {
      if (userEnabledFeatures.mssSmtp && isDoctor(currentUser) && !currentUser.mailizEmailAddress) {
        return "Vous devez saisir votre adresse MSS Mailiz dans vos informations de compte afin d'accéder à votre boîte de réception dans Follow. Cliquez sur le bouton pour saisir une adresse email"
      }

      return !patient?.inseeNumber
        ? 'Numéro de sécurité sociale du patient manquant'
        : 'Le patient ne souhaite pas être contacté ou que ses informations soient échangées via la messagerie de santé sécurisée'
    }
  }, [currentUser, patient?.inseeNumber, userEnabledFeatures])

  const handlePrint = useCallback(
    (documents: readonly DocumentInstanceListItem[]) => {
      print({
        documents: [...documents],
      })
    },
    [print],
  )

  const handleDownload = useCallback(
    (documents: readonly DocumentInstanceListItem[]) => {
      download({
        fileName: getRenderedPdfFileName(patient),
        documents: [...documents],
      })
    },
    [download, patient],
  )

  if (!patient) {
    return null
  }

  return (
    <div className={styles.contentWrapper}>
      <PatientPageHeader patient={patient} />
      {medicalEvent && (
        <div className={styles.medicalEventTitle}>
          {!isOwner && (
            <>
              {getFullName(medicalEvent.owner)} <Space>{`•`}</Space>
            </>
          )}
          {`${medicalEvent.title}, `}
          {formatFr(new Date(medicalEvent.date), DATE_FORMAT_FRONT_SHORT)}
          <Space />
          {medicalEvent.isEditable && (
            <div className={styles.action}>
              <IconButton onClick={() => editMedicalEvent()} icon="pencil" theme="dark" />
            </div>
          )}
          {allowedToLock && (
            <div className={styles.action}>
              <IconButton
                onClick={() => onLock(!medicalEvent.locked)}
                title={
                  medicalEvent.locked ? 'Cliquer pour déverrouiller' : 'Cliquer pour verrouiller'
                }
                icon={medicalEvent.locked ? 'lock' : 'unlock'}
                theme="dark"
              />
            </div>
          )}
          <div className={styles.action}>
            <IconButton
              onClick={() => setIsOpenSearchMedicalEventDocumentsBottomPanel('print')}
              icon="print"
              theme="dark"
            />
          </div>
          {userEnabledFeatures?.mail && (
            <div className={styles.action}>
              <TooltipWrapper
                content={tooltipContent}
                display={
                  !patient.inseeNumber ||
                  patient.disallowMSSMessaging ||
                  (userEnabledFeatures.mssSmtp &&
                    isDoctor(currentUser) &&
                    !currentUser.mailizEmailAddress)
                }
                pointerDirection="right"
              >
                {userEnabledFeatures.mssSmtp &&
                isDoctor(currentUser) &&
                !currentUser.mailizEmailAddress ? (
                  <div>
                    <IconButton
                      onClick={() => navigate('/account/settings?focus=mailizEmailAddress')}
                      icon="mail"
                      theme="dark"
                    />
                  </div>
                ) : (
                  <div>
                    <IconButton
                      onClick={() => sendMedicalEventDocuments()}
                      icon="mail"
                      theme="dark"
                      disabled={!patient.inseeNumber || patient.disallowMSSMessaging}
                    />
                  </div>
                )}
              </TooltipWrapper>
            </div>
          )}
          <div className={styles.action}>
            <IconButton
              onClick={() => setIsOpenSearchMedicalEventDocumentsBottomPanel('download')}
              icon="save"
              theme="primary"
            />
          </div>
          <SearchMedicalEventDocumentsBottomPanel
            display={!!isOpenSearchMedicalEventDocumentsBottomPanel}
            onRequestClose={() => setIsOpenSearchMedicalEventDocumentsBottomPanel(false)}
            medicalEvent={medicalEvent}
            title={
              isOpenSearchMedicalEventDocumentsBottomPanel === 'download'
                ? 'Sélectionner les documents à télécharger'
                : 'Sélectionner les documents à imprimer'
            }
            onSelect={
              isOpenSearchMedicalEventDocumentsBottomPanel === 'download'
                ? handleDownload
                : handlePrint
            }
          />
        </div>
      )}
    </div>
  )
}
