import { useState, Dispatch, SetStateAction } from 'react'

export function useLocalStorage<T>(key: string, initialValue: T): [T, Dispatch<SetStateAction<T>>] {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = window.localStorage.getItem(key)
      return item ? (JSON.parse(item).valueToStore as T) : (initialValue as T)
    } catch (error) {
      console.error(error)
      return initialValue
    }
  })

  function setValue(value: T | ((state: T) => T)) {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value
      setStoredValue(valueToStore)
      window.localStorage.setItem(key, JSON.stringify({ valueToStore }))
    } catch (error) {
      console.error(error)
    }
  }

  return [storedValue, setValue]
}
