import { createReducer } from 'redux-starter-kit'
import { documentCategoriesDomainAction } from './documentCategories.actions'
import {
  domainDocumentCategoriesActionTypes,
  DocumentCategoriesState,
} from './documentCategories.model'

const DOCUMENT_CATEGORIES_DEFAULT_STATE: DocumentCategoriesState = {
  usedDocumentCategories: [],
}

const ACTION_HANDLERS = {
  [domainDocumentCategoriesActionTypes.STORE_USED_DOCUMENT_CATEGORIES]: (
    state: DocumentCategoriesState = DOCUMENT_CATEGORIES_DEFAULT_STATE,
    {
      usedDocumentCategories,
    }: ReturnType<typeof documentCategoriesDomainAction.storeUsedDocumentCategories>,
  ) => ({
    ...state,
    usedDocumentCategories,
  }),
}

export const documentCategoriesReducer = createReducer<DocumentCategoriesState>(
  DOCUMENT_CATEGORIES_DEFAULT_STATE,
  ACTION_HANDLERS,
)
