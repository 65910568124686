import React, { FunctionComponent, useRef } from 'react'
import { getFileSizeInMegaBytes } from '../../../misc/files.utilities'
import { isDefined } from '../../../misc/functions.utilities'
import { RoundedButton, TooltipWrapper } from '../../shared'
import { FileAddButtonProps } from './FileAddButton.model'

const validFileType = (type: string, acceptType: string) =>
  acceptType === 'image/*' ? type.includes('image') : acceptType.includes(type)

export const FileAddButton: FunctionComponent<FileAddButtonProps> = ({
  multiple = true,
  type = '',
  buttonLabel = 'Ajouter un fichier',
  maxSizeInBytes,
  onAdd,
  onError,
  disabled = false,
}) => {
  const acceptType = type === 'image' ? 'image/*' : type === 'pdf' ? 'application/pdf' : type
  const fileInputRef = useRef<HTMLInputElement>(null)
  const onInputClick = (event: React.MouseEvent<HTMLInputElement>) => event.stopPropagation()
  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target && event.target.files) {
      const files = Array.from(event.target.files) as File[]
      const filteredFiles = files.filter(
        ({ size, type }) =>
          (!isDefined(maxSizeInBytes) || size <= maxSizeInBytes) && validFileType(type, acceptType),
      )

      if (onError) {
        // Contrôle des fichiers trop volumineux
        if (files.some(({ size }) => isDefined(maxSizeInBytes) && size > maxSizeInBytes)) {
          onError('OVERSIZED_FILE')
        }
        // Contrôle des types de fichier
        if (files.some(({ type }) => !validFileType(type, acceptType))) {
          onError('INCORRECT_FILE_TYPE')
        }
      }

      onAdd(filteredFiles)
    }
  }

  return (
    <>
      <TooltipWrapper
        content={`La taille des fichiers est limitée à ${getFileSizeInMegaBytes(
          maxSizeInBytes ?? 0,
        )} Mo.`}
        display={isDefined(maxSizeInBytes)}
      >
        <div
          onClick={() => {
            fileInputRef.current && fileInputRef.current.click()
          }}
        >
          <RoundedButton
            label={buttonLabel}
            icon="add"
            onClick={() => {}}
            disabled={disabled}
            type="button"
            theme="primary"
          />
        </div>
      </TooltipWrapper>
      <input
        ref={fileInputRef}
        accept={acceptType}
        autoComplete="off"
        className="hidden"
        disabled={disabled}
        onClick={onInputClick}
        onChange={onInputChange}
        multiple={multiple}
        tabIndex={-1}
        type="file"
      />
    </>
  )
}
