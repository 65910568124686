import { DocumentTemplateListItem, DocumentTemplate } from '../../../model/DocumentTemplate'
import { PageList } from '../../restux/ui/restuxUi.model'
import { restuxResolvePage } from '../../restux/ui/restuxUi.selectors'
import { RootState } from '../../reducers/index'

export const bottomPanelPaginationSelector = (state: RootState) =>
  state.ui.bottomPanelDocumentTemplates.pagination

export const bottomPanelDocumentTemplatesPageSelector = (
  state: RootState,
): PageList<DocumentTemplateListItem> => {
  const docTemplatesResource = state.cache.documentTemplates.list
  const pagination = bottomPanelPaginationSelector(state)

  return restuxResolvePage(docTemplatesResource, pagination)
}

export function inUseDocumentTemplateSelector(state: RootState): DocumentTemplate | null {
  const documentTemplateResources = state.cache.documentTemplates.details
  const inUseId = state.ui.managerDocumentTemplates.inUse.inUseId

  return inUseId ? documentTemplateResources[inUseId] : null
}
