import React, { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { PrescriptionTemplateListProps } from './PrescriptionTemplateList.model'
import { DropdownContainer, DropdownItem, IconButton } from '../../../shared'
import styles from './PrescriptionTemplateList.module.scss'
import { PrescriptionTemplate } from '../../../../model/PrescriptionTemplate'

export const PrescriptionTemplateList: FunctionComponent<PrescriptionTemplateListProps> = ({
  prescriptionTemplates,
  drugs,
  getPrescriptionTemplates,
  deletePrescriptionTemplate,
  onSelectPrescriptionTemplate,
}) => {
  const [opened, setOpened] = useState(false)

  useEffect(() => {
    getPrescriptionTemplates(drugs)
  }, [drugs, getPrescriptionTemplates])

  const handleDeletePrescriptionTemplate = useCallback(
    (prescriptionTemplate: PrescriptionTemplate) => {
      deletePrescriptionTemplate(prescriptionTemplate)
    },
    [deletePrescriptionTemplate],
  )

  return (
    <>
      {prescriptionTemplates && prescriptionTemplates?.length > 0 && (
        <div className={styles.buttonWrapper}>
          <IconButton
            icon="star"
            showOptionsCaret={true}
            onClick={(e) => {
              e.stopPropagation()
              setOpened(!opened)
            }}
          />
          <div className={styles.dropdownWrapper}>
            <DropdownContainer
              verticalScroll={false}
              opened={opened}
              closeDropdown={() => setOpened(false)}
            >
              {prescriptionTemplates?.map((prescriptionTemplate) => (
                <div className={styles.dropdownItemWrapper} key={prescriptionTemplate.id}>
                  <DropdownItem
                    layout="thin"
                    onClick={(e) => {
                      e.stopPropagation()
                      onSelectPrescriptionTemplate(prescriptionTemplate)
                    }}
                  >
                    <div className={styles.dropdownItemLabelWrapper}>
                      {prescriptionTemplate.name}
                    </div>
                    <div className={styles.dropdownItemActionsWrapper}>
                      <IconButton
                        icon="trash"
                        title="Supprimer"
                        onClick={(e) => {
                          e.stopPropagation()
                          handleDeletePrescriptionTemplate(prescriptionTemplate)
                        }}
                        theme="light"
                        noBorder
                      />
                    </div>
                  </DropdownItem>
                </div>
              ))}
            </DropdownContainer>
          </div>
        </div>
      )}
    </>
  )
}
