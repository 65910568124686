import { User } from '../../../model/User'

export interface UsurpationState {
  usurpedUser?: User
  usurpedUserInitializationWaitFlag: boolean
}

export interface AdminUsersState extends UsurpationState {}

export enum AdminUsersActionTypes {
  SET_USURPED_USER = '@ADMIN_USERS/SET_USURPED_USER',
  STORE_USURPED_USER = '@ADMIN_USER/STORE_USURPED_USER',
  INITIALIZE_USURPED_USER = '@ADMIN_USERS/INITIALIZE_USURPED_USER',
  USURPED_USER_INITIALIZATION = '@ADMIN_USERS/USURPED_USER_INITIALIZATION',
  USURPED_USER_INITIALIZATION_DONE = '@ADMIN_USERS/USURPED_USER_INITIALIZATION_DONE',
}
