import React, { FunctionComponent } from 'react'

import { renderToStaticMarkup } from 'react-dom/server'
import { JSONToValue, WithVariableValueToHtml } from '@follow/farte'

import { HeaderFooterProps } from './component-store.model'

export const PrintHeader: FunctionComponent<HeaderFooterProps> = ({
  template,
  variablesData,
  token,
}) => <WithVariableValueToHtml value={JSONToValue(template)} data={variablesData} token={token} />
PrintHeader.displayName = 'PrintHeader'

export function PrintHeaderToHtml({ template, variablesData }: HeaderFooterProps, token?: string) {
  return renderToStaticMarkup(
    <PrintHeader template={template} variablesData={variablesData} token={token} />,
  )
}
