import { CancelToken } from 'apisauce'
import { useState, useEffect } from 'react'

import getApi from '../../../api'
import { singleItemPageLimit } from '../../../constants'
import { isDefined } from '../../../misc/functions.utilities'
import {
  DrugTypes,
  ActivePrinciple,
  AtcClass,
  Drug,
  DrugSorting,
  Indications,
} from '../../../model/Drug'
import { PaginatedList } from '../../../model/Pagination'
import { IdentityIdentifier } from '../../../store/restux/restux.model'

export function useDrugList(
  page: number,
  title: string,
  drugSearchType: DrugTypes,
  sorting: DrugSorting,
  atcClass: AtcClass | null,
  activePrinciple: ActivePrinciple | undefined,
  indication: Indications | undefined,
  searchId: number | undefined,
  selectedDrugs: readonly Drug[],
  patientAldCompatible?: IdentityIdentifier,
) {
  const [drugs, setDrugs] = useState<Drug[]>([])
  const [itemCount, setItemCount] = useState(0)
  const [pageCount, setPageCount] = useState(0)
  const [loading, setLoading] = useState(false)

  const clearDrugs = () => {
    setDrugs([])
    setItemCount(0)
    setPageCount(0)
    setLoading(false)
  }

  useEffect(() => {
    const source = CancelToken.source()
    if (
      ([DrugTypes.SPECIALITY, DrugTypes.NON_PROPRIETARY].includes(drugSearchType) &&
        title.length > 2) ||
      (drugSearchType === DrugTypes.ACTIVE_PRINCIPLES && isDefined(activePrinciple)) ||
      (drugSearchType === DrugTypes.ATC && isDefined(atcClass)) ||
      (drugSearchType === DrugTypes.BIOSIMILAR_GROUP && isDefined(searchId)) ||
      (drugSearchType === DrugTypes.INDICATION && isDefined(indication))
    ) {
      const params = {
        type: drugSearchType,
        sort_by: sorting.sortBy,
        direction: sorting.direction,
        id:
          (drugSearchType === DrugTypes.ATC && atcClass?.id) ||
          (drugSearchType === DrugTypes.BIOSIMILAR_GROUP && searchId) ||
          (drugSearchType === DrugTypes.ACTIVE_PRINCIPLES && activePrinciple?.externalId) ||
          (drugSearchType === DrugTypes.INDICATION && indication?.externalId) ||
          undefined,
        search: title,
        page,
        limit: singleItemPageLimit,
        ...(selectedDrugs.length > 0 && {
          sameRoutesAsDrugs: selectedDrugs.map(({ type, id }) => `${id}:${type}`).join(','),
        }),
        ...(isDefined(patientAldCompatible) && { patientAldCompatible }),
      }
      const config = { cancelToken: source.token }
      // Construction de l'url
      setLoading(true)
      getApi()
        .get<PaginatedList<Drug>>('drugs', params, config)
        .then((response) => {
          if (response.ok && response.data) {
            setPageCount(response.data.pageCount)
            setItemCount(response.data.itemCount)
            setDrugs((currentDrugs) => {
              if (response.data) {
                if (page !== 1) return [...currentDrugs, ...response.data.items]
                else return response.data.items
              }
              return currentDrugs
            })
          } else {
            clearDrugs()
          }
          setLoading(false)
        })
        .catch(() => {
          clearDrugs()
        })
    }
    // Annulation de la requête lors du clean de l'effet
    return () => {
      source.cancel()
    }
  }, [
    page,
    title,
    drugSearchType,
    sorting,
    atcClass,
    activePrinciple,
    searchId,
    indication,
    patientAldCompatible,
    selectedDrugs,
  ])
  return { drugs, pageCount, itemCount, loading, clearDrugs }
}
