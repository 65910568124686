import { FC } from 'react'
import { Outlet } from 'react-router-dom'
import { useTitleBrowserTabs } from '../../../hooks/utils/useTitleBrowserTabs.hook'

export const PatientTitle: FC = () => {
  useTitleBrowserTabs()
  return (
    <>
      <Outlet />
    </>
  )
}
