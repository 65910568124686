import { RestuxUiSagaConf, RestuxInUse } from '../../../restux/ui'
import { RestuxResources } from '../../../resources'
import { RESTUX_IDENTIFIER } from '../../../restux.identifier'

export const medicalEventFilesConfig: RestuxUiSagaConf = {
  resourceName: RestuxResources.file,
  identifier: RESTUX_IDENTIFIER.medicalEventFiles,
}

export interface MedicalEventFilesState {
  inUse: RestuxInUse
}
