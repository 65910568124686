import { Filters } from '../../model/Filters'
import getApi from '../../api'
import { PaginatedList } from '../../model/Pagination'
import { TagType } from '../../model/Tag'

export const DEFAULT_TAGS_LIMIT_PER_PAGE = 100

export const getTags = (
  page: number,
  filters?: Filters,
  signal?: AbortSignal,
  limit = DEFAULT_TAGS_LIMIT_PER_PAGE,
) =>
  getApi().get<PaginatedList<TagType>>(
    '/tags',
    {
      page,
      limit,
      ...filters,
    },
    { signal },
  )
