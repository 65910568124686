import React, { FunctionComponent, useCallback, useState } from 'react'
import { ReactComponent as PandaError } from './Error-panda.svg'

import styles from './ErrorPandaMessage.module.scss'
import { Heading } from '../fonts'
import { assignLocation } from '../../../misc/url.utilities'
import { ErrorPandaMessageProps } from './ErrorPandaMessage.model'
import { RoundedButton } from '../buttons'
import classNames from 'classnames'

export const ErrorPandaMessage: FunctionComponent<ErrorPandaMessageProps> = ({
  title,
  subTitle,
  contactSupport = false,
  backToHomePage = true,
  errorDetails,
  className,
}) => {
  const [copied, setCopied] = useState(false)

  const cx = classNames.bind(styles)

  const handleCopyError = useCallback(async () => {
    if (errorDetails) {
      await navigator.clipboard.writeText(errorDetails)
      setCopied(true)
      setTimeout(() => {
        setCopied(false)
      }, 2000)
    }
  }, [errorDetails])

  return (
    <div className={cx(styles.container, className)}>
      <PandaError height="400px" width="400px" viewBox="0 0 240 240" />
      <div className={styles.content}>
        <div className={styles.headings}>
          <Heading size={2}>{title}</Heading>
          <Heading size={6}>{subTitle}</Heading>
        </div>
        <div className={styles.buttonContainer}>
          {backToHomePage && (
            <div>
              <RoundedButton
                icon="back"
                label="Retour à l'accueil"
                theme="light"
                onClick={() => {
                  assignLocation('/')
                }}
              />
            </div>
          )}
          {contactSupport && (
            <div>
              <RoundedButton
                label="Contacter le support"
                theme="primary"
                onClick={() => window.location.assign('mailto:support@follow.fr')}
              />
            </div>
          )}
          {errorDetails && (
            <div>
              <RoundedButton
                onClick={handleCopyError}
                icon={copied ? 'check' : 'clipboard'}
                label="Copier le détail de l'erreur"
                theme="dark"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
