import { useMemo } from 'react'
import { HealthDataCode, HealthDataType } from '../../../model/HealthData'

export const useIndexedModuleTypes = (moduleTypes: HealthDataType[]) => {
  const indexedTypes = useMemo<Partial<Record<HealthDataCode, HealthDataType>>>(
    () =>
      moduleTypes.reduce(
        (acc, type) => ({
          ...acc,
          [type.code]: type,
        }),
        {},
      ),
    [moduleTypes],
  )

  return indexedTypes
}
