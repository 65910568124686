// Headings
export type HeadingLevel =
  | {
      label: string
      key: 'heading'
      level: 1 | 2 | 3 | 4 | 5 | 6
    }
  | {
      label: string
      key: 'paragraph'
    }

export const HEADING_LEVELS: Array<HeadingLevel> = [
  { label: 'Normal', key: 'paragraph' },
  { label: 'Titre 1', key: 'heading', level: 1 },
  { label: 'Titre 2', key: 'heading', level: 2 },
  { label: 'Titre 3', key: 'heading', level: 3 },
]

// Font Family
export const FONT_FAMILIES = [
  'Amiri',
  'Crimson Pro',
  'Lato',
  'Lora',
  'Merriweather',
  'Montserrat',
  'Raleway',
  'Roboto',
  'Source Sans Pro',
] as const

export type FontFamily = typeof FONT_FAMILIES extends ReadonlyArray<infer E> ? E : never

export const DEFAULT_FONT_FAMILY: FontFamily = 'Montserrat'

// Font Size
export const fontSizeRegex = /(\d+)px/

export const DEFAULT_FONT_SIZE = 16
