import { getStandardizedPathname } from '../../analytics/segment'
import { preventAnalyticLoading } from '../../analytics/utils'

export enum FwTrackingEvent {
  DISPLAY_PATIENT = 'DISPLAY:PATIENT',
  SEARCH_PATIENT = 'SEARCH:PATIENT',
  DELETE_PATIENT = 'DELETE:PATIENT',
  OPEN_EDIT_PATIENT_MODAL = 'OPEN:EDIT_PATIENT_MODAL',
  OPEN_IMPORT_PATIENT_MODAL = 'OPEN:IMPORT_PATIENT_MODAL',

  DISPLAY_MEDICAL_EVENT = 'DISPLAY:MEDICAL_EVENT',
  SELECT_MEDICAL_EVENT_DOCUMENT = 'SELECT:MEDICAL_EVENT:DOCUMENT',
  SELECT_MEDICAL_EVENT_FILE = 'SELECT:MEDICAL_EVENT:FILE',
  SELECT_MEDICAL_EVENT_FILE_INSTANCE = 'SELECT:MEDICAL_EVENT:FILE_INSTANCE',
  SELECT_MEDICAL_EVENT_OBSERVATIONS = 'SELECT:MEDICAL_EVENT:OBSERVATIONS',
  SELECT_MEDICAL_EVENT_QUESTIONNAIRE = 'SELECT:MEDICAL_EVENT:QUESTIONNAIRE',

  INSERT_MEDICAL_EVENT_QUESTIONNAIRE = 'INSERT:MEDICAL_EVENT:SCORE',
  INSERT_MEDICAL_EVENT_DOCUMENT = 'INSERT:MEDICAL_EVENT:DOCUMENT',
  INSERT_MEDICAL_EVENT_FILE = 'INSERT:MEDICAL_EVENT:FILE',

  UPDATE_MEDICAL_EVENT_TITLE = 'UPDATE:MEDICAL_EVENT:TITLE',
  UPDATE_MEDICAL_EVENT_DATE = 'UPDATE:MEDICAL_EVENT:DATE',
  UPDATE_MEDICAL_EVENT_OBSERVATIONS = 'UPDATE:MEDICAL_EVENT:OBSERVATIONS',
  UPDATE_MEDICAL_EVENT_FILE = 'UPDATE:MEDICAL_EVENT:FILE',
  UPDATE_MEDICAL_EVENT_DOCUMENT_TITLE = 'UPDATE:MEDICAL_EVENT:DOCUMENT_TITLE',
  UPDATE_MEDICAL_EVENT_FILE_TITLE = 'UPDATE:MEDICAL_EVENT:FILE_TITLE',
  DELETE_MEDICAL_EVENT = 'DELETE:MEDICAL_EVENT',

  DELETE_MEDICAL_EVENT_DOCUMENT = 'DELETE:MEDICAL_EVENT:DOCUMENT',
  DELETE_MEDICAL_EVENT_FILE = 'DELETE:MEDICAL_EVENT:FILE',
  DELETE_MEDICAL_EVENT_FILE_INSTANCE = 'DELETE:MEDICAL_EVENT:FILE',
  DELETE_MEDICAL_EVENT_QUESTIONNAIRE = 'DELETE:MEDICAL_EVENT:QUESTIONNAIRE',

  TOGGLE_PATIENT_INFO_ACCORDION = 'TOGGLE:PATIENT_INFO_ACCORDION',

  OPEN_DOCUMENT_MODAL = 'OPEN:DOCUMENT_MODAL',
  OPEN_BILAN_MODAL = 'OPEN:BILAN_MODAL',
  OPEN_FILE_MODAL = 'OPEN:FILE_MODAL',

  MAXIMIZE_PANEL_BUTTON_CLICK = 'MAXIMIZE_PANEL_BUTTON:CLICK',

  ARTICLES_MODAL_CLICK_SEE_LATER = 'ARTICLES_MODAL:CLICK_SEE_LATER',
  ARTICLES_MODAL_CLICK_HIDE = 'ARTICLES_MODAL:CLICK_HIDE',

  USAGE_APP = 'USAGE:APP',

  STATISTICS_DISPLAY = 'STATISTICS:DISPLAY',

  MANAGER_VARIABLE_ADD = 'MANAGER:VARIABLE:ADD',
  MEDICAL_EVENT_VARIABLE_ADD = 'MEDICAL_EVENT:VARIABLE:ADD',

  RENDER_DOCUMENTS = 'RENDER:DOCUMENTS',
  MSS_MEDICAL_EVENT = 'MSS:MEDICAL_EVENT_SENDED',
}

export class TrackingService {
  private static enabled = false
  public static removeUser() {
    if (!preventAnalyticLoading(document)) {
      TrackingService.enabled = false
      window.analytics.reset()
    }
  }
  public static setUser(userId: string, name: string, email: string, userCreatedAt: Date) {
    if (!preventAnalyticLoading(document)) {
      TrackingService.enabled = true
      window.analytics.identify(userId, {
        name,
        email,
        appVersion: process.env.REACT_APP_VERSION,
      })
      window.satismeter({
        userId,
        traits: {
          name,
          email,
          createdAt: userCreatedAt,
          appVersion: process.env.REACT_APP_VERSION,
        },
      })
      TrackingService.page(window.location.pathname)
    }
  }
  public static sendEvent(event: FwTrackingEvent, options: object = {}) {
    if (TrackingService.enabled) {
      if (preventAnalyticLoading(document)) {
        return
      }
      if (!window.analytics) {
        throw new Error('Initialize Segment first')
      }
      try {
        window.analytics.track(event, options)
      } catch (error) {
        console.error(error)
      }
    }
  }
  public static page(pathname: string) {
    if (TrackingService.enabled) {
      if (preventAnalyticLoading(document)) {
        return
      }
      window.analytics.page(getStandardizedPathname(pathname))
    }
  }
}
