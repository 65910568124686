import { FC } from 'react'
import { Navigate } from 'react-router'
import { Outlet } from 'react-router-dom'
import { RouteGuardWithFallbackProps, RouteGuardWithRedirectionProps } from './RouteGuard.model'

export const RouteGuardWithRedirection: FC<RouteGuardWithRedirectionProps> = ({
  condition,
  redirect,
}) => {
  return condition ? <Outlet /> : <Navigate to={redirect} replace />
}

export const RouteGuardWithFallback: FC<RouteGuardWithFallbackProps> = ({
  condition,
  fallbackComponent,
}) => {
  return condition ? <Outlet /> : fallbackComponent
}
