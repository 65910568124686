import { FormikInput } from './Input.model'
import { useField } from 'formik'
import { FieldWrapper } from '../Fields'
import { MultiSelectInput } from '../../MultiSelectInput'
import { useCallback } from 'react'
import { MultiSelectOption } from '../../../../../model/SelectOption'

export function FormikMultiSelectInput<T>({
  label,
  fieldName,
  options,
  disabled,
  colorPreset,
  value,
}: FormikInput & {
  options: MultiSelectOption<T>[]
  value: MultiSelectOption<T>[]
}) {
  const [, meta, utils] = useField({ name: fieldName })

  const handleSelect = useCallback(
    (selected: MultiSelectOption<T>[]) => {
      utils.setValue(selected.map(({ value }) => value))
    },
    [utils],
  )

  return (
    <FieldWrapper errorMessage={!meta.touched ? '' : meta.error}>
      <MultiSelectInput
        title={label}
        options={options}
        value={value}
        onSelect={handleSelect}
        disabled={disabled}
        colorPreset={colorPreset}
      />
    </FieldWrapper>
  )
}
