import { EphemeralNotification } from './EphemeralNotification'
import { ServerSentNotification } from './Notification'
import { TaskNotification } from './TaskNotification'

export enum NotificationEventType {
  notification = 'notification',
  ephemeralNotification = 'ephemeral_notification',
  task = 'follow_task',
}

export type MercureMessageEventData =
  | TaskNotification
  | ServerSentNotification
  | EphemeralNotification

export interface MercureMessageEvent<T = string> extends MessageEvent<T> {
  type: NotificationEventType
}
