export interface InsiConnectInsResponse {
  CR?: InsiConnectInsCR
  error?: string
  exportId: string
  Ins: {
    nir: string
    key: string
    startDate: string
    endDate: string
    oid: string
  }
  name: string
  given: string
  birthGiven: string
  sex: 'M' | 'F'
  birthDate: string
  birthPlace: string
  request: InsiConnectInsRequest
}

export interface InsiConnectInsRequest {
  name: string
  given: string
  birthday: string
  sex: '1' | '2' | '3'
  birthplace: string
}

export interface InsiConnectInsCR {
  codeCR: '00' | '01' | '02'
  LibelleCR: string
}

export enum InsiConsultActions {
  MSS_CREATION = 'MSS_CREATION',
  MSS_UPDATE = 'MSS_UPDATE',
  MSS_CHECK = 'MSS_CHECK',
  PATIENT_FORM = 'PATIENT_FORM',
}
