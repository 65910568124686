import { EditorValueJSON, getEditorEmptyJsonValue, normalizeEditorValue } from '@follow/farte'

function parseJSON(text: string, empty: EditorValueJSON): EditorValueJSON {
  let json = empty
  try {
    json = JSON.parse(text) as EditorValueJSON
    json = normalizeEditorValue(json)
  } catch {
    // Les erreurs de parsing sont ignorées et la valeur de retour est EditorValueJSON
  }
  return json
}
export const parseToValueJSON = (json: string | null) => {
  const empty = getEditorEmptyJsonValue()
  return json === null ? empty : parseJSON(json, empty)
}

/**
 * Le style est soumis à un sanitize, voir @cdk/src/components/DangerousHtml. La classe "imageContainer" est nécessaire pour le rendu côté front https://fwhealth.atlassian.net/browse/DEV-701
 */
export const renderImage = (base64: string | undefined, textValue?: string) => {
  const image = `<img style="display: inline;" src="${base64}"/>`
  const text = `${textValue ? `<span>${textValue}</span>` : ''}`
  return `<div class="imageContainer" style="display: inline-grid; text-align: center">${image}${text}</div>`
}
