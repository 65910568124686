import { CurrentTreamentFormResource, Treatment } from '../../../model/Treatment'
import { uiActions, UiPatientCurrentTreatmentsActionTypes } from './patientCurrentTreatments.model'

export const uiPatientCurrentTreatmentsActions = {
  ...uiActions.actions,
  getCurrentTreatments: (patientId?: number) => ({
    type: UiPatientCurrentTreatmentsActionTypes.GET_CURRENTS_TREATMENTS,
    patientId,
  }),
  createCurrentTreatment: (treatmentForm: CurrentTreamentFormResource) => ({
    type: UiPatientCurrentTreatmentsActionTypes.CREATE_CURRENTS_TREATMENTS,
    treatmentForm,
  }),
  updateCurrentTreatment: (
    treatmentForm: CurrentTreamentFormResource,
    prescriptionUuid: string,
  ) => ({
    type: UiPatientCurrentTreatmentsActionTypes.UPDATE_CURRENTS_TREATMENTS,
    treatmentForm,
    prescriptionUuid,
  }),
  terminateTreatment: (treatment: Treatment, endAt: Date) => ({
    type: UiPatientCurrentTreatmentsActionTypes.TERMINATE_TREATMENT,
    treatment,
    endAt,
  }),
}
