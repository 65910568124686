import { connect } from 'react-redux'
import { RootState } from '../../../store'
import { documentCategoriesSelector } from '../../../store/domain/documentCategories/documentCategories.selectors'
import { insiDomainActions, insiIdentityCheckStatusSelector } from '../../../store/domain/insi'
import { domainMailActions } from '../../../store/domain/mail'
import { enabledFeaturesPreferencesSelector } from '../../../store/domain/me'
import { MailClassification } from './MailClassification.component'
import {
  MailClassificationDispatchProps,
  MailClassificationStoreProps,
} from './MailClassification.model'

const mapStateToProps = (state: RootState): MailClassificationStoreProps => ({
  documentCategories: documentCategoriesSelector(state),
  userEnabledFeatures: enabledFeaturesPreferencesSelector(state),
  insiStatus: insiIdentityCheckStatusSelector(state),
})

const mapDispatchToProps: MailClassificationDispatchProps = {
  createPatient: domainMailActions.createPatientFromMail,
  updatePatient: domainMailActions.updatePatientFromMail,
  classifyDocuments: domainMailActions.classifyDocuments,
  setInsiConsultRequest: insiDomainActions.setRequest,
  clearInsiPatientResponse: insiDomainActions.clearPatientResponse,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)(MailClassification)
export { withConnect as MailClassification }
