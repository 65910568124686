import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js'
import { phoneCountryCodeRegex } from '../../../../../../misc/regex'
import { PhoneCountryCode } from '../../../../../../model/Phone'
import { SelectOption } from '../../../../../../model/SelectOption'
import {
  CUSTOM_COUNTRY_CODE,
  countryCodeOptions,
  defaultCountryCodeOption,
} from './FormikInternationalPhoneInput.model'

export const getDefaultCountryOption = (code: string): SelectOption<PhoneCountryCode> => ({
  label: `+${code}`,
  value: {
    countryCode: CUSTOM_COUNTRY_CODE,
    label: 'Autre',
    phoneCode: code,
  },
})

export const filterOptions = (textInput: string, allOptions: SelectOption<PhoneCountryCode>[]) => {
  // Filtrage par nom ou phoneCode
  const filtered = allOptions.filter(({ label, value }) => {
    const isInLabel = value.label.toLowerCase().includes(textInput.toLowerCase())
    const isInCode = value.phoneCode
      .toLowerCase()
      .includes(textInput.toLowerCase().replaceAll('+', ''))
    return isInCode || isInLabel
  })

  // Si un indicatif a été rentré dans la recherche et qu'il ne correspond à aucun pays connu
  // On propose une saisie custom
  const codeMatch = textInput.match(phoneCountryCodeRegex)
  const code = codeMatch?.groups?.code
  if (code) {
    if (allOptions.every(({ value }) => value.phoneCode !== code)) {
      const customOption: SelectOption<PhoneCountryCode> = getDefaultCountryOption(code)
      filtered.push(customOption)
    }
  }

  return filtered
}

export const parseInitialValue = (internationalNumber: string) => {
  try {
    if (isValidPhoneNumber(internationalNumber)) {
      const parsed = parsePhoneNumber(internationalNumber)
      if (!parsed) throw new Error('Parse failed')

      const countryCode =
        countryCodeOptions.find(({ value }) => value.countryCode === parsed.country) ??
        getDefaultCountryOption(parsed.countryCallingCode)

      return { number: parsed.formatNational(), countryCode }
    }
  } catch {
    console.error('Could not parse existing phone value')
  }
  return { number: internationalNumber, countryCode: defaultCountryCodeOption }
}
