import React, { FunctionComponent, useCallback, useState } from 'react'
import styles from './TeamsBlock.module.scss'
import { FormBlock, Icon, ValidationModal } from '../../../shared'
import { TeamBlockProps } from './TeamBlock.model'
import { ADMIN_ROLES, USER_ROLES } from '../../../../model/Roles'
import RoleRestriction from '../../../../containers/RoleRestriction'
import { InformationAlert } from '../InformationAlert'
import { TeamInput, TeamCard } from '../../../team'
import { useDeleteTeam, usePutTeam } from '../../../../hooks/queries/admin'
import { Team } from '../../../../model/Team'
import { getFullName } from '../../../../misc/user.utilities'
import Waypoint from 'react-waypoint'

export const TeamsBlock: FunctionComponent<TeamBlockProps> = ({
  user,
  userTeams,
  readonly,
  fetchNextTeamPage,
  openTeamBottomPanel,
}) => {
  const [showAddToTeamModal, setShowAddToTeamModal] = useState(false)
  const [selectedTeam, setSelectedTeam] = useState<Team>()
  const [toBeDeletedTeam, setToBeDeletedTeam] = useState<Team>()

  const { mutate: updateTeam } = usePutTeam()
  const { mutate: deleteTeam } = useDeleteTeam()

  const addUserToTeam = () => {
    if (selectedTeam && user.id) {
      const { id, memberUsers } = selectedTeam
      const memberIds = memberUsers.map(({ id }) => id)
      updateTeam({ teamId: id, variables: { memberUserIds: [...memberIds, user.id] } })
    }
    setShowAddToTeamModal(false)
    setSelectedTeam(undefined)
  }

  const handleSelectTeam = useCallback((team: Team) => {
    setSelectedTeam(team)
    setShowAddToTeamModal(true)
  }, [])

  const handleDeleteTeam = useCallback(() => {
    if (toBeDeletedTeam) {
      deleteTeam(toBeDeletedTeam)
      setToBeDeletedTeam(undefined)
    }
  }, [deleteTeam, toBeDeletedTeam])

  return (
    <>
      <FormBlock label="Équipes" icon="team">
        <RoleRestriction roles={USER_ROLES} useUsurpedUser>
          <InformationAlert>
            Pour paramétrer vos équipes, nous vous invitons à vous rapprocher de notre équipe CSM
          </InformationAlert>
        </RoleRestriction>
        <RoleRestriction roles={ADMIN_ROLES}>
          {!readonly && (
            <TeamInput
              userId={user.id}
              onSelectItem={handleSelectTeam}
              onCreateTeam={openTeamBottomPanel}
            />
          )}
        </RoleRestriction>
        {userTeams.length === 0 && (
          <div className={styles.emptyTeam}>
            <Icon size="mega" icon="team" color="--fw-color-shades-shade4" />
            <div className={styles.text}>Vous n'avez aucune équipe pour le moment.</div>
          </div>
        )}
        <div className={styles.teamList}>
          {userTeams.map((team) => (
            <TeamCard
              key={team.id}
              team={team}
              readonly={readonly}
              onOpen={openTeamBottomPanel}
              onDelete={readonly ? undefined : setToBeDeletedTeam}
            />
          ))}
          {fetchNextTeamPage && <Waypoint bottomOffset={-800} onEnter={fetchNextTeamPage} />}
        </div>
      </FormBlock>
      <ValidationModal
        display={showAddToTeamModal}
        title={`Voulez-vous ajouter l'utilisateur ${getFullName(
          user,
        )} à l'équipe "${selectedTeam?.name}" ?`}
        onClose={() => setShowAddToTeamModal(false)}
        onSubmit={addUserToTeam}
      />
      <ValidationModal
        display={!!toBeDeletedTeam}
        title={`Êtes-vous sûr de vouloir supprimer la team "${toBeDeletedTeam?.name}" ?`}
        onClose={() => setToBeDeletedTeam(undefined)}
        onSubmit={handleDeleteTeam}
      />
    </>
  )
}
