import { FunctionComponent, useCallback, useState } from 'react'
import { v4 as uuid } from 'uuid'
import {
  drugSortOptions,
  DrugTypes,
  ActivePrinciple,
  AtcClass,
  Drug,
  Indications,
} from '../../../model/Drug'

import createSearchPanel from '../../SearchPanel'

import { SearchDrugProps } from './SearchDrug.model'
import { useDrugList } from './useDrugList.hook'

import DrugItem from './DrugItem'
import { Button, SearchInput, SelectSearch, Switch, Tag } from '../../../components/shared'
import { openMonograph, openSMR } from '../../../misc/drug.utilities'
import { ActivePrinciplesSelect, AtcClassSelect, IndicationsSelect } from '../../../components/drug'
import { SelectOption } from '../../../model/SelectOption'
import { isEqual } from 'lodash'
import { isDefined } from '../../../misc/functions.utilities'
import { IdentityIdentifier } from '../../../store/restux/restux.model'
import { getCurrentPatientId } from '../../../misc/currentPatient.utilities'
import { useGetPatientInformations } from '../../../hooks/queries/patientInformations'

const SearchPanel = createSearchPanel<Drug>()

const drugSearchTypesOptions: SelectOption<DrugTypes>[] = [
  { value: DrugTypes.SPECIALITY, label: 'Nom (spécialité)' },
  { value: DrugTypes.NON_PROPRIETARY, label: 'Dénomination commune' },
  { value: DrugTypes.ACTIVE_PRINCIPLES, label: 'Principe Actif' },
  { value: DrugTypes.INDICATION, label: 'Indication' },
  { value: DrugTypes.ATC, label: 'Classification ATC' },
]

const biosimilarGroupOption = {
  value: DrugTypes.BIOSIMILAR_GROUP,
  label: 'Groupe Bio-Similaire',
}

export const SearchDrug: FunctionComponent<SearchDrugProps> = ({
  actions,
  drugSortingPreference,
  disabled,
  initialSearch,
  isMultiSelect,
  selectDrug,
}) => {
  const patientId = getCurrentPatientId()
  const initialSearchType = [...drugSearchTypesOptions, biosimilarGroupOption].find(
    ({ value }) => value === initialSearch?.type,
  )

  const [activePrincipleSelected, setActivePrincipleSelected] = useState<ActivePrinciple>()
  const [indicationSelected, setIndicationSelected] = useState<Indications>()
  const [page, setPage] = useState(1)
  const [searchType, setSearchType] = useState(initialSearchType ?? drugSearchTypesOptions[0])
  const [title, setTitle] = useState('')
  const [selectedSort, setSelectedSort] = useState(
    drugSortOptions.find((option) => isEqual(option.value, drugSortingPreference)) ??
      drugSortOptions[0],
  )
  const [selectedAtcClass, setSelectedAtcClass] = useState<AtcClass | null>(null)
  const [patientAldCompatible, setPatientAldCompatible] = useState<IdentityIdentifier>()
  const [selectedDrugs, setSelectedDrugs] = useState<readonly Drug[]>([])
  const { drugs, pageCount, itemCount, loading, clearDrugs } = useDrugList(
    page,
    title,
    searchType.value,
    selectedSort.value,
    selectedAtcClass,
    activePrincipleSelected,
    indicationSelected,
    initialSearch?.searchId,
    selectedDrugs,
    patientAldCompatible,
  )

  const {
    query: { data: patientAllergies = [] },
  } = useGetPatientInformations(patientId!)

  const getItems = (newPage: number) => {
    setPage(newPage)
  }

  const onPrimaryAction = (selection: Drug) => {
    const prescriptionVariableUuid = uuid()
    selectDrug({
      drugs: [{ ...selection }],
      prescriptionVariableUuid,
      isAld: isDefined(patientAldCompatible),
    })
  }

  const resetDrugsList = useCallback(() => {
    clearDrugs()
    setPage(1)
  }, [clearDrugs, setPage])

  const handleMultiSelect = useCallback(
    (selection: readonly Drug[]) => {
      setSelectedDrugs(selection)
    },
    [setSelectedDrugs],
  )

  const submitMultiSelection = useCallback(
    () =>
      selectDrug({
        drugs: selectedDrugs.map(({ id, type }) => ({
          id,
          type,
        })),
        prescriptionVariableUuid: uuid(),
        isAld: isDefined(patientAldCompatible),
      }),
    [patientAldCompatible, selectDrug, selectedDrugs],
  )

  return (
    <SearchPanel
      testId={`search-drug`}
      items={drugs}
      loading={loading}
      pageCount={pageCount}
      itemCount={itemCount}
      onPrimaryAction={!isMultiSelect ? onPrimaryAction : undefined}
      getItems={getItems}
      actions={actions}
      filterCapabilities={[]}
      onMultiSelectionChange={isMultiSelect ? handleMultiSelect : undefined}
      renderActions={() =>
        isMultiSelect ? (
          <div>
            <Button
              disabled={selectedDrugs.length < 2}
              type="submit"
              label="Valider"
              theme="primary"
              onClick={submitMultiSelection}
            />
          </div>
        ) : null
      }
      renderCustomFilters={(resetPagination) => (
        <div className="space-y-6 flex flex-col">
          <SelectSearch
            disabled={disabled}
            value={searchType}
            options={drugSearchTypesOptions}
            onSelect={(option) => {
              if (option) {
                const { value } = option
                const drugResult = drugSearchTypesOptions.find(
                  (drugSearchType) => drugSearchType.value === value,
                )
                if (drugResult) {
                  setSearchType(drugResult)
                  setTitle('')
                  resetDrugsList()
                  resetPagination()
                }
              }
            }}
            colorPreset="dark"
            clearable={false}
          />
          {[DrugTypes.SPECIALITY, DrugTypes.NON_PROPRIETARY].includes(searchType.value) && (
            <SearchInput
              autofocus
              value={title}
              onChange={({ target: { value } }) => {
                setTitle(value)
                resetDrugsList()
                resetPagination()
              }}
              disabled={disabled}
              colorPreset="dark"
              placeholder="3 caractères min"
              testId={`search-drug`}
            />
          )}
          {searchType.value === DrugTypes.ATC && (
            <AtcClassSelect
              onClassSelected={(atcClass) => {
                if (atcClass) setSelectedAtcClass(atcClass)
                else resetDrugsList()
              }}
            />
          )}
          {searchType.value === DrugTypes.ACTIVE_PRINCIPLES && (
            <ActivePrinciplesSelect
              setActivePrincipleSelected={setActivePrincipleSelected}
              resetDrugsList={resetDrugsList}
            />
          )}
          {searchType.value === DrugTypes.INDICATION && (
            <IndicationsSelect
              setIndicationSelected={setIndicationSelected}
              resetDrugsList={resetDrugsList}
            />
          )}
          {patientAllergies.some((item) => item.isAld) && patientId && (
            <div className="font-semibold text-white">
              <Switch
                name="Pris en charge dans le cadre de l'ALD du patient"
                checked={isDefined(patientAldCompatible)}
                onChange={(checked) => setPatientAldCompatible(checked ? patientId : undefined)}
              />
            </div>
          )}

          {isMultiSelect && (
            <div className="flex flex-col border-t-2 border-white">
              <span className="mt-4 mb-1 text-white font-medium">
                Vous ne pouvez sélectionner que des médicaments ayant au moins une voie
                d'administration en commun
              </span>
              <div className="space-y-2">
                {selectedDrugs.map((drug) => (
                  <Tag key={drug.signature} title={drug.name} ellipsis={false} multiline>
                    {drug.name}
                  </Tag>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
      renderItem={(drug) => (
        <DrugItem
          drug={drug}
          onMonographClick={() => openMonograph(drug)}
          onSMRClick={() => openSMR(drug)}
        />
      )}
      renderHeaderActions={(resetPagination) => (
        <div className="my-4 mx-2 w-80">
          <SelectSearch
            options={drugSortOptions}
            value={selectedSort}
            onSelect={(selected) => {
              if (selected) {
                const { direction, sortBy } = selected.value
                const option = drugSortOptions.find(
                  ({ value }) => value.direction === direction && value.sortBy === sortBy,
                )
                if (option) {
                  resetPagination()
                  setSelectedSort(option)
                  setPage(1)
                }
              }
            }}
            clearable={false}
          />
        </div>
      )}
    />
  )
}
