import {
  DocumentHistoryResourceType,
  DomainDocumentHistoryActionTypes,
} from './documentHistory.model'

const createDocumentHistoryRevision = (
  documentId: number,
  documentType: DocumentHistoryResourceType,
) => ({
  type: DomainDocumentHistoryActionTypes.CREATE_REVISION,
  documentId,
  documentType,
})

export const domainDocumentHistoryActions = {
  createDocumentHistoryRevision,
}
