import {
  BaseVariableId,
  BaseVariableIdType,
  NormalTypeBaseVariableId,
  NormalTypeBaseVariableIdType,
} from './baseVariables.model'
import { SlateTypeBaseVariableId } from './farteType'
import { HTMLTypeBaseVariableId } from './htmlType'

export const isBaseVariable = (id: string): id is BaseVariableIdType =>
  Object.values<string>(BaseVariableId).includes(id)

export const isNormalTypeVariable = (
  variableId: string,
): variableId is NormalTypeBaseVariableIdType =>
  Object.values<string>(NormalTypeBaseVariableId).includes(variableId)

export const isHtmlTypeVariable = (variableId: string): variableId is HTMLTypeBaseVariableId =>
  Object.values<string>(HTMLTypeBaseVariableId).includes(variableId)

export const isSlateTypeVariable = (variableId: string): variableId is SlateTypeBaseVariableId =>
  Object.values<string>(SlateTypeBaseVariableId).includes(variableId)
