import { ColorNames, Colors } from './colors'

export const camelToSeparator = (text, separator) =>
  text
    .split(/(?=[A-Z])/)
    .join(separator)
    .toLowerCase()

export function getCssVariable<ColorName extends ColorNames>(
  name: ColorName,
  color: keyof Colors[ColorName],
) {
  return `--fw-color-${camelToSeparator(name, '-')}-${camelToSeparator(color, '-')}`
}
