import { FC, useCallback } from 'react'
import { ConsentTextBlockProps } from './ConsentTextBlock.model'
import { FormBlock } from '../../../shared'
import { EditorEvents, EditorTool, SimpleEditor } from '@follow/elfe'

const editorTools: Array<EditorTool> = ['marks', 'align', 'list', 'undo']

export const ConsentTextBlock: FC<ConsentTextBlockProps> = ({
  consentText,
  onChangeConsentText,
}) => {
  const handleChange = useCallback(
    (event: EditorEvents['update']) => {
      onChangeConsentText(event.editor.getHTML())
    },
    [onChangeConsentText],
  )

  return (
    <FormBlock label="Texte de consentement" icon="documentText">
      <div className="overflow-auto h-103">
        <SimpleEditor
          initialContent={consentText}
          onChange={handleChange}
          themes={['rounded', 'compact']}
          tools={editorTools}
        />
      </div>
    </FormBlock>
  )
}
