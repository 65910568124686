import React, { FunctionComponent, useState } from 'react'
import { GridLayout, SearchInput, SelectSearch } from '../../../../shared'
import { SearchSchema, deserializeFromUrlSearchParams } from '../../../../../misc/url.utilities'
import { useTimeoutEffect } from '../../../../../hooks/utils'
import {
  TagListPageFilters,
  TagListPageTypeFilterSelectValues,
} from '../../../../../pages/ManagerPage/TagListPage/TagListPage.model'
import { timeBeforeStartingResearch } from '../../../../../constants'

interface TagListActionsProps {
  onFiltersChange: (filters: TagListPageFilters) => void
}

function getFiltersSchema(): SearchSchema {
  return {
    title: {
      type: 'string',
      defaultValue: '',
    },
    private: {
      type: 'custom',
      customName: 'type',
      defaultValue: TagListPageTypeFilterSelectValues[0],
      customDeserializer: (filterParam) => {
        switch (filterParam) {
          case 'true':
            return TagListPageTypeFilterSelectValues[2]
          case 'false':
            return TagListPageTypeFilterSelectValues[1]
          default:
            return TagListPageTypeFilterSelectValues[0]
        }
      },
    },
  }
}

export const TagListActions: FunctionComponent<TagListActionsProps> = ({ onFiltersChange }) => {
  const urlFilters: TagListPageFilters = deserializeFromUrlSearchParams(
    document.location.search,
    getFiltersSchema(),
  )
  const [titleFilter, setTitleFilter] = useState(urlFilters.title)
  const [typeFilter, setTypeFilter] = useState(urlFilters.type)

  useTimeoutEffect(
    () => {
      onFiltersChange({
        title: titleFilter,
        type: typeFilter,
      })
    },
    timeBeforeStartingResearch,
    [titleFilter, typeFilter],
  )

  return (
    <GridLayout columnsTemplate="1fr 1fr" rowsOnMobile={2} gap="medium">
      <SearchInput
        placeholder="Rechercher un label"
        value={titleFilter}
        onChange={({ target: { value } }) => setTitleFilter(value)}
        autofocus
      />
      <SelectSearch
        placeholder="Type"
        icon="eye"
        value={typeFilter}
        options={TagListPageTypeFilterSelectValues}
        onSelect={(selected) => {
          if (selected !== undefined) {
            setTypeFilter(selected)
          }
        }}
        clearable={false}
      />
    </GridLayout>
  )
}
