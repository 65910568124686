import { hasAdminRole } from './roles.utilities'
import { Roles } from '../model/Roles'
import { DocumentCategoryKeys } from '../model/DocumentCategory'
import { DocumentContentType } from '../model/Document'
import {
  Prescription,
  PrescriptionDocumentUpdate,
  PRESCRIPTION_MANDATORY_VARIABLES,
} from '../model/Prescription'
import { FarteDocumentInstance } from '../model/DocumentInstance'
import { listVariables } from '@follow/farte'
import { DocumentLayoutLight } from '../model/DocumentLayout'

export const canAddContentToDocument = (contentType: DocumentContentType, roles?: Roles[]) => {
  return (
    (roles && hasAdminRole(roles)) ||
    contentType.PRESCRIPTION_ADD ||
    contentType.QUESTIONNAIRE_ADD ||
    contentType.QUESTION_ADD ||
    contentType.BASE_VARIABLE_ADD
  )
}

export const formatPrescriptionPrice = (priceInCents: number) => {
  const priceInEuros = (priceInCents / 100).toFixed(2).replace('.', ',')
  return `${priceInEuros}€`
}

export const formatPrescriptionForDocumentUpdate = ({
  drugs,
  prescriptionVariableUuid,
  note,
  prescriptionReason,
  uuid,
  isAld,
  isChronic,
  unsubstituableStatement,
}: Prescription): PrescriptionDocumentUpdate => ({
  drugs: drugs?.map((drug) => {
    return { id: drug.id, type: drug.type }
  }),
  prescriptionVariableUuid,
  note,
  prescriptionReason,
  uuid,
  isAld,
  isChronic,
  unsubstituableStatement,
})

export const isPrescription = (category: DocumentCategoryKeys) => {
  return [
    DocumentCategoryKeys.PrescriptionForMedication,
    DocumentCategoryKeys.BizonePrescriptionForMedication,
    DocumentCategoryKeys.PrescriptionForFootCare,
    DocumentCategoryKeys.PrescriptionForInfirmaryAct,
    DocumentCategoryKeys.PrescriptionForMedicalBiology,
    DocumentCategoryKeys.PrescriptionForOrthoptics,
    DocumentCategoryKeys.PrescriptionForPhysicalTherapy,
    DocumentCategoryKeys.PrescriptionForSpeechTherapy,
  ].includes(category)
}

export const computeMissingPrescriptionVariables = (
  document: FarteDocumentInstance,
  documentLayout?: DocumentLayoutLight,
) => {
  if (isPrescription(document.category.id) && !document.locked) {
    const documentVariable = listVariables(document.template)
    const documentVariableIds = documentVariable.map(({ id }) => id)
    const insertedVariableIds = [
      ...documentVariableIds,
      ...(documentLayout?.displayedVariables ?? []),
    ]

    const missingVariables = PRESCRIPTION_MANDATORY_VARIABLES.filter(({ variables }) =>
      variables.every((mandatory) => !insertedVariableIds.includes(mandatory)),
    )
    const missingVariablesLabels = missingVariables.map(({ label }) => label)

    return missingVariablesLabels
  } else {
    return []
  }
}
