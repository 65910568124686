import { connect } from 'react-redux'
import { RootState } from '../../../../store'
import { citiesPageSelector, uiCitiesActions } from '../../../../store/ui/cities'
import {
  countriesPageSelector,
  selectedCountrySelector,
  uiCountriesActions,
} from '../../../../store/ui/countries'
import { PatientBirthPlaceCodeBlock } from './PatientBirthPlaceCodeBlock.component'
import {
  PatientBirthPlaceCodeBlockStoreProps,
  PatientBirthPlaceCodeBlockDispatchProps,
} from './PatientBirthPlaceCodeBlock.model'

const mapStateToProps = (state: RootState): PatientBirthPlaceCodeBlockStoreProps => ({
  cities: citiesPageSelector(state),
  countries: countriesPageSelector(state),
  selectedCountry: selectedCountrySelector(state),
})

const mapDispatchToProps: PatientBirthPlaceCodeBlockDispatchProps = {
  searchCity: uiCitiesActions.actions.requestPage,
  searchCountry: uiCountriesActions.actions.requestPage,
  setSelectedCountry: uiCountriesActions.actions.useId,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)(PatientBirthPlaceCodeBlock)
export { withConnect as PatientBirthPlaceCodeBlock }
