import { PluginNames } from '@/model/Plugins'
import { Extension } from '@tiptap/react'
import './variableClass.scss'

export const VARIABLE_CLASS_PREFIX = 'variable-'

export const VariableClass = Extension.create({
  name: PluginNames.VariableClass,

  addGlobalAttributes() {
    return [
      {
        types: [PluginNames.ClassName],
        attributes: {
          variableClass: {
            parseHTML: (element) => element.className,
            renderHTML(attributes) {
              const className: string | undefined = attributes['variableClass']
              if (!className || !className.startsWith(VARIABLE_CLASS_PREFIX)) return {}

              return {
                class: className,
              }
            },
          },
        },
      },
    ]
  },
})
