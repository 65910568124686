import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import { AssistantBlockProps } from './AssistantBlock.model'
import { getFullName, isAssistant, userToSelectOptions } from '../../../../misc/user.utilities'
import { DropdownUserItem } from '../../DropdownUserItem'
import { SearchInput, FormBlock, IdentityCard } from '../../../shared'
import { SelectOption } from '../../../../model/SelectOption'
import { LightUserAssistant } from '../../../../model/User'
import { useGetInfiniteUsers } from '../../../../hooks/queries/admin'
import { Roles } from '../../../../model/Roles'
import { useAtom } from 'jotai'
import { assistantPermissionsForm } from '../../../../state/admin'
import { RESET } from 'jotai/utils'
import { searchLimit } from '../../../../constants'

export const AssistantBlock: FunctionComponent<AssistantBlockProps> = ({
  openAssistantPermissions,
  initialValue,
  disabled,
}) => {
  const [inputValue, setInputValue] = useState('')
  const [assistantsField, setAssistantsField] = useAtom(assistantPermissionsForm)

  useEffect(() => {
    setAssistantsField(initialValue)

    return () => {
      setAssistantsField(RESET)
    }
  }, [initialValue, setAssistantsField])

  const { userList, cancelPendingQuery } = useGetInfiniteUsers({
    filters: {
      search: inputValue,
      roles: Roles.ASSISTANT,
    },
    limit: searchLimit,
    enabled: inputValue.length > 0,
  })

  const options = useMemo(() => {
    if (userList) {
      return userList
        .filter(
          (item) =>
            !assistantsField.some((valueItem) => item.id === valueItem.id) && isAssistant(item),
        )
        .map(userToSelectOptions) as SelectOption<LightUserAssistant>[]
    }
    return []
  }, [assistantsField, userList])

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.currentTarget.value)
      cancelPendingQuery()
    },
    [cancelPendingQuery],
  )

  const handleSelect = useCallback(
    (selected: SelectOption<LightUserAssistant>) => {
      setAssistantsField([
        ...assistantsField,
        {
          ...selected.value,
          permissions: {
            insOidAccess: true,
            insiServiceAccess: true,
          },
        },
      ])

      setInputValue('')
    },
    [assistantsField, setAssistantsField],
  )

  const handleRemove = useCallback(
    (user: LightUserAssistant) => {
      const filtered = assistantsField.filter((item) => item.id !== user.id)
      setAssistantsField(filtered)
    },
    [assistantsField, setAssistantsField],
  )

  return (
    <FormBlock
      label="Assistant·e·s"
      icon="profile"
      linkLabel={assistantsField.length > 0 ? 'Configurer les accès' : undefined}
      onLinkClick={assistantsField.length > 0 ? openAssistantPermissions : undefined}
    >
      {!disabled && (
        <SearchInput
          placeholder="Rechercher un·e assistant·e"
          value={inputValue}
          onChange={handleInputChange}
          results={inputValue.length > 2 ? options : []}
          onSelect={handleSelect}
          renderResult={(item, isHovered) => (
            <DropdownUserItem
              selected={isHovered}
              user={item.value}
              selectedValues={assistantsField}
            />
          )}
        />
      )}
      <div className="space-y-2 pr-2 overflow-y-scroll max-h-88">
        {assistantsField.map((assistant) => (
          <IdentityCard
            key={assistant.id}
            title={getFullName(assistant)}
            icon="mail"
            subtitle={assistant.email}
            onRemove={() => handleRemove(assistant)}
            disabled={disabled}
          />
        ))}
      </div>
    </FormBlock>
  )
}
