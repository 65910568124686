import React, { FunctionComponent, useCallback, useContext, useState } from 'react'
import classNames from 'classnames/bind'
import { Link, useLocation } from 'react-router-dom'

import { NavigationMenuProps } from '../NavigationMenu.model'
import { MobileMenuLinkItemProps, MobileMenuItemProps } from './MobileNavigationMenu.model'
import { Icon, IconButton, ValidationModal } from '../../../../shared'

import styles from './MobileNavigationMenu.module.scss'
import { AuthService, ConnectedUserContext } from '../../../../../misc/auth.utilities'
import { getCssVariable } from '../../../../../design-system/utils'
import { UserAvatar } from '../../../../user'
import { getFullName, isAssistant, isDoctor } from '../../../../../misc/user.utilities'
import { AUTHENTICATION_ID_MAIL } from '../../../../../model/Mail'
import RoleRestriction from '../../../../../containers/RoleRestriction'
import { Roles } from '../../../../../model/Roles'
import { useAtom } from 'jotai'
import { mobileMenuOpenAtom } from '../../../MainLayout/NavBar/NavBar.component'

const classnamesCx = classNames.bind(styles)

const MobileMenuItem: FunctionComponent<MobileMenuItemProps> = ({
  label,
  selected,
  icon,
  onClick,
}) => (
  <div className={classnamesCx(styles.menuItem, { selected })} onClick={onClick}>
    <div className="h-6 w-6 text-shades-white">
      <Icon color={getCssVariable('shades', 'white')} icon={icon} size="micro" />
    </div>
    <span className={styles.label}>{label}</span>
  </div>
)

const MobileMenuLinkItem: FunctionComponent<MobileMenuLinkItemProps> = ({
  label,
  link,
  icon,
  selected,
  onClick,
}) =>
  link ? (
    <Link key={label} to={link} onClick={onClick}>
      <MobileMenuItem label={label} selected={selected} icon={icon} />
    </Link>
  ) : null

export const MobileNavigationMenu: FunctionComponent<NavigationMenuProps> = ({
  setUsurpedUser,
}) => {
  const { pathname } = useLocation()
  const [isLogoutModalOpen, setLogoutModalOpen] = useState(false)
  const { currentUser, loggedUser } = useContext(ConnectedUserContext)
  const [mobileMenuOpen, setMobileMenuOpen] = useAtom(mobileMenuOpenAtom)

  const closeMobileMenu = useCallback(() => setMobileMenuOpen(false), [setMobileMenuOpen])

  return (
    <div className={classnamesCx(styles.mobileNavigationMenu, { open: mobileMenuOpen })}>
      <div className={styles.openMenuHeader}>
        <div className={styles.logoContainer}>
          <Icon icon="follow" color={getCssVariable('shades', 'white')} size="macro" />
          <span className={styles.label}>follow</span>
        </div>
        <IconButton
          onClick={closeMobileMenu}
          icon="cross"
          size="micro"
          theme="primary"
          title="Fermer"
        />
      </div>
      {currentUser && (
        <div className={styles.currentUser}>
          <div className={styles.userContainer}>
            <UserAvatar
              familyName={currentUser.familyName}
              firstName={currentUser.firstName}
              theme="primary"
              size="small"
            />
            <span className={styles.label}>{getFullName(currentUser)}</span>
          </div>
          <div className={styles.logoutButton}>
            <IconButton
              onClick={() => {
                setLogoutModalOpen(!isLogoutModalOpen)
                closeMobileMenu()
              }}
              icon="power"
              size="micro"
              theme="primary"
              title="Déconnecter"
            />
          </div>
        </div>
      )}

      <RoleRestriction roles={[Roles.DOCTOR]} useUsurpedUser>
        <MobileMenuLinkItem
          label="Patients"
          link="/patients"
          icon="profile"
          selected={pathname.includes('/patients')}
        />
      </RoleRestriction>

      {loggedUser && currentUser && (
        <div className={styles.info}>
          {isDoctor(loggedUser) && loggedUser.profession && (
            <span className={styles.job}>{loggedUser.profession.description}</span>
          )}
          <span className={styles.name}>{getFullName(loggedUser)}</span>
          {isAssistant(loggedUser) && (
            <div className={styles.doctors}>
              {loggedUser.doctors.map((doctor) => (
                <UserAvatar
                  key={doctor.id}
                  familyName={doctor.familyName}
                  firstName={doctor.firstName}
                  theme="primary"
                  selected={currentUser.id === doctor.id}
                  onClick={() => {
                    setUsurpedUser(doctor)
                    closeMobileMenu()
                  }}
                />
              ))}
            </div>
          )}
        </div>
      )}
      <ValidationModal
        display={isLogoutModalOpen}
        icon="out"
        confirmLabel="Déconnexion"
        title="Êtes-vous sûr de vouloir vous déconnecter ?"
        onSubmit={() => {
          setUsurpedUser()
          localStorage.removeItem(AUTHENTICATION_ID_MAIL)
          AuthService.requestLogout()
        }}
        onClose={() => setLogoutModalOpen(false)}
      />
    </div>
  )
}
