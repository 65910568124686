import { City } from '../../../model/Contact'
import { RootState } from '../../reducers'
import { PageList } from '../../restux/ui'
import { restuxResolvePage } from '../../restux/ui/restuxUi.selectors'
export const citiesPaginationSelector = (state: RootState) => state.ui.cities.pagination

export const citiesPageSelector = (state: RootState): PageList<City> => {
  const pagination = citiesPaginationSelector(state)
  const citiesResource = state.cache.cities.list
  return restuxResolvePage(citiesResource, pagination)
}
