import { EditorValueJSON, RenderVariableDisplayMode, VariableTheme } from '@follow/farte'

export enum VariableKind {
  Base = 'Base',
  Drug = 'Drug',
  MultiDrugs = 'MultiDrugs',
  ManualPrescription = 'ManualPrescription',
  Question = 'Question',
  Questionnaire = 'Questionnaire',
  Acte = 'Acte',
}

export enum VariableKindPrefix {
  QUESTION = 'question',
  QUESTIONNAIRE = 'questionnaire',
  PRESCRIPTION = 'prescription',
  MANUAL_PRESCRIPTION = 'manual_prescription',
  QUOTE_LINE = 'quote_line',
}

export enum EditorVariableDisplayConfig {
  FULL = 'full',
  SCORE_VALUE = 'score_value',
  TITLE = 'title',
  TITLE_AND_SCORE_VALUE = 'title+score_value',
  HIDDEN = 'hidden',
  VARIABLE_DEFAULT = 'variable:default',
  VARIABLE_TITLE_ONLY = 'variable:title_only',
  CAPITALIZE = 'variable:capitalize',
}

export interface RenderValueJson {
  props: object | null
  renderer: string // component name
  displayMode?: RenderVariableDisplayMode
}

export interface BaseVariableDataJson {
  id: string
  variableTitle?: string
  type: 'normal' | 'render' | 'slate'
  value: string | RenderValueJson | EditorValueJSON | null
  fallbackValue: string | object | EditorValueJSON | null
  customTheme?: VariableTheme
  enableFixedValue?: boolean
}
export interface NormalVariableDataJson extends BaseVariableDataJson {
  type: 'normal'
  value: string | null
  fallbackValue: string | null
}
export interface RenderVariableDataJson extends BaseVariableDataJson {
  type: 'render'
  value: RenderValueJson
  fallbackValue: object | null
}
export interface SlateVariableDataJson extends BaseVariableDataJson {
  type: 'slate'
  value: EditorValueJSON | null
  fallbackValue: EditorValueJSON | null
}

export type VariableTypeJson =
  | NormalVariableDataJson
  | SlateVariableDataJson
  | RenderVariableDataJson

export interface VariablesDataJson {
  [variableName: string]: VariableTypeJson
}

export interface VariablesDataWithError {
  variables: VariablesDataJson
  errors: Record<string, string>
}
