import { ToolbarContainer, ToolbarButton, ToolbarSeparator } from '@/components/toolbar/atoms'
import { EditorConfigContext } from '@/context'
import { PluginNames } from '@/model/Plugins'
import { BubbleMenu, useCurrentEditor } from '@tiptap/react'
import React, { FC, useContext } from 'react'

export const TableBubbleMenu: FC = () => {
  const { editor } = useCurrentEditor()
  const { tools } = useContext(EditorConfigContext)

  if (!editor || !tools.includes('table')) return null

  const handleToggleHeaderRow = () => editor.chain().focus().toggleHeaderRow().run()
  const handleToggleHeaderColumn = () => editor.chain().focus().toggleHeaderColumn().run()
  const handleToggleHeaderCell = () => editor.chain().focus().toggleHeaderCell().run()

  const handleAddColumnBefore = () => editor.chain().focus().addColumnBefore().run()
  const handleAddColumnAfter = () => editor.chain().focus().addColumnAfter().run()
  const handleAddRowAfter = () => editor.chain().focus().addRowAfter().run()
  const handleAddRowBefore = () => editor.chain().focus().addRowBefore().run()

  const handleRemoveRow = () => editor.chain().focus().deleteRow().run()
  const handleRemoveColumn = () => editor.chain().focus().deleteColumn().run()
  const handleRemoveTable = () => editor.chain().focus().deleteTable().run()

  const handleMergeOrSplit = () => editor.chain().focus().mergeOrSplit().run()

  return (
    <BubbleMenu
      editor={editor}
      shouldShow={({ editor }) => editor.isActive(PluginNames.Table)}
      pluginKey={PluginNames.Table}
      tippyOptions={{
        maxWidth: '450px',
        offset() {
          if (editor.isActive(PluginNames.ImageResize)) return [0, 60]
          return [0, 20]
        },
      }}
    >
      <ToolbarContainer>
        <ToolbarButton
          title="Activer/Désactiver la ligne de titre"
          icon="HeaderRow"
          onClick={handleToggleHeaderRow}
        />
        <ToolbarButton
          title="Activer/Désactiver la colonne de titre"
          icon="HeaderColumn"
          onClick={handleToggleHeaderColumn}
        />
        <ToolbarButton
          title={
            editor.isActive('tableHeader')
              ? 'Retirer la cellule de titre'
              : 'Transformer en cellule de titre'
          }
          icon="ElevateCell"
          onClick={handleToggleHeaderCell}
        />
        <ToolbarSeparator />
        <ToolbarButton
          title="Ajouter une colonne à gauche"
          icon="AddColumnLeft"
          onClick={handleAddColumnBefore}
        />
        <ToolbarButton
          title="Ajouter une colonne à droite"
          icon="AddColumnRight"
          onClick={handleAddColumnAfter}
        />
        <ToolbarButton
          title="Ajouter une ligne au dessus"
          icon="AddRowTop"
          onClick={handleAddRowBefore}
        />
        <ToolbarButton
          title="Ajouter une ligne en dessous"
          icon="AddRowBottom"
          onClick={handleAddRowAfter}
        />
        <ToolbarSeparator />
        <ToolbarButton
          title="Fusionner ou séparer des cellules"
          icon="OverlapCell"
          disabled={!editor.can().mergeOrSplit()}
          onClick={handleMergeOrSplit}
        />
        <ToolbarSeparator />
        <ToolbarButton
          title="Supprimer la colonne"
          icon="DeleteColumn"
          onClick={handleRemoveColumn}
        />
        <ToolbarButton title="Supprimer la ligne" icon="DeleteRow" onClick={handleRemoveRow} />
        <ToolbarButton
          title="Supprimer le tableau"
          icon="RemoveTable"
          onClick={handleRemoveTable}
        />
      </ToolbarContainer>
    </BubbleMenu>
  )
}
