import { SelectOption } from '@/model/SelectOption'

type SimpleRgbColor = `rgb(${number},${number},${number})`
export type ColorOptions = Array<SelectOption<SimpleRgbColor | null>>

export const DEFAULT_COLORS_OPTIONS: ColorOptions = [
  { label: 'Par défaut', id: 'default', value: null },
  { label: 'Gris', id: 'grey', value: 'rgb(130, 130, 130)' },
  { label: 'Gris clair', id: 'lightgrey', value: 'rgb(224, 224, 224)' },
  { label: 'Rouge', id: 'red', value: 'rgb(235, 87, 87)' },
  { label: 'Orange', id: 'orange', value: 'rgb(242, 153, 74)' },
  { label: 'Jaune', id: 'yellow', value: 'rgb(242, 201, 76)' },
  { label: 'Vert', id: 'green', value: 'rgb(33, 150, 83)' },
  { label: 'Vert clair', id: 'lightgreen', value: 'rgb(111, 207, 151)' },
  { label: 'Bleu', id: 'blue', value: 'rgb(47, 128, 237)' },
  { label: 'Cyan', id: 'cyan', value: 'rgb(86, 204, 242)' },
  { label: 'Violet', id: 'purple', value: 'rgb(155, 81, 224)' },
  { label: 'Lavande', id: 'lavender', value: 'rgb(187, 107, 217)' },
]
