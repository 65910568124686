import { RootState } from '../../reducers'

export const insiIdentityCheckStatusSelector = (state: RootState) => state.domain.insi.status

export const insiIdentityCheckPatientSelector = (state: RootState) => state.domain.insi.patient

export const insiRequestSelector = (state: RootState) => {
  return state.domain.insi.request
}

export const insiMailPatientSelector = (state: RootState) => {
  return state.domain.insi.mailPatient
}
