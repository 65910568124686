import { Action } from 'redux'

export interface Message {
  id?: string
  type?: string
  title: string
  message?: string
  duration?: number
  disableDuration?: boolean
}
export interface SetMessageAction extends Action {
  message: Message
}

export interface ReduxMessages {
  messages: Message[]
}

export enum MessageActionTypes {
  ADD_MESSAGE = 'ADD_MESSAGE',
  CLEAR_MESSAGE = 'CLEAR_MESSAGE',
}
