import { SimpleEditor } from '@follow/farte'
import React, { FunctionComponent } from 'react'
import { NOTES_TOOLBAR_CONFIG } from '../../../../model/Notes'
import { PatientNotesProps } from './PatientNotes.model'

export const PatientNotes: FunctionComponent<PatientNotesProps> = ({
  patientNote,
  onChange,
  isEditable = true,
}) => {
  return (
    <SimpleEditor
      readOnly={!isEditable}
      value={patientNote}
      onValueChange={onChange}
      toolbarConfig={NOTES_TOOLBAR_CONFIG}
    />
  )
}
