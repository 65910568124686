import React, { Fragment } from 'react'
import { ToolbarSelectProps } from './ToolbarSelect.model'
import styles from './ToolbarSelect.module.scss'
import {
  Combobox,
  ComboboxButton,
  ComboboxOption,
  ComboboxOptions,
  Transition,
} from '@headlessui/react'
import { Icon } from '@/components/Icon'
import { isDefined } from '@/utils/functions.utils'

export function ToolbarSelect<T>({
  value,
  options,
  title,
  placeholder,
  icon,
  renderItem,
  onChange,
}: ToolbarSelectProps<T>) {
  const displayPlaceholder = !value?.label && isDefined(placeholder)

  return (
    <div className={styles['listbox']}>
      <Combobox value={value} by="id" onChange={onChange}>
        <ComboboxButton title={title} className={styles['button']}>
          {icon && (
            <div className={styles['icon']}>
              <Icon icon={icon} />
            </div>
          )}
          {!displayPlaceholder ? (
            <span>{value?.label}</span>
          ) : (
            <span className={styles['placeholder']}>{placeholder}</span>
          )}
          <span className={styles['caret']}>
            <Icon icon="VerticalCaret" />
          </span>
        </ComboboxButton>
        <Transition
          as={Fragment}
          enter={styles['transition']}
          enterFrom={styles['to']}
          enterTo={styles['from']}
          leave={styles['transition']}
          leaveFrom={styles['from']}
          leaveTo={styles['to']}
        >
          <ComboboxOptions className={styles['options']}>
            {options.map((option) => (
              <ComboboxOption key={option.id} value={option} className={styles['option']}>
                {renderItem ? renderItem(option) : option.label}
              </ComboboxOption>
            ))}
          </ComboboxOptions>
        </Transition>
      </Combobox>
    </div>
  )
}
