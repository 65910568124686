import React, { FunctionComponent, useMemo } from 'react'
import { Link } from 'react-router-dom'
import styles from './MemberUserList.module.scss'
import { MemberUserListItem } from './MemberUsersListItem'

type MemberUserListProps = {
  users: Array<{
    firstName: string
    familyName: string
    professionTitle: string | undefined
    isContact: boolean
    id: string
  }>
}

export const MemberUserList: FunctionComponent<MemberUserListProps> = ({ users }) => {
  const contactUsers = useMemo(() => users.filter((user) => user.isContact), [users])
  const members = useMemo(() => users.filter((user) => !user.isContact), [users])

  return (
    <div className={styles.memberUsers}>
      {contactUsers.map(({ firstName, familyName, professionTitle, id }, index) => (
        <Link key={index} to={`/admin/users/${id}`}>
          <MemberUserListItem
            key={index}
            firstName={firstName}
            familyName={familyName}
            professionTitle={professionTitle}
            showStar
          />
        </Link>
      ))}
      {members.map(({ firstName, familyName, professionTitle, id }, index) => (
        <Link to={`/admin/users/${id}`} key={id}>
          <MemberUserListItem
            key={index}
            firstName={firstName}
            familyName={familyName}
            professionTitle={professionTitle}
          />
        </Link>
      ))}
    </div>
  )
}
