import {
  SurveyActionTypes,
  Survey,
  SurveyBranchConfig,
  SurveyQuestionAnswer,
  SurveyQuestionConfig,
  SurveyBranchItem,
  SurveyScore,
  SurveyApi,
} from './survey.model'

export const getSurveys = (page: number) => ({ type: SurveyActionTypes.GET_SURVEYS, page })

export const getSurvey = (surveyId: number) => ({ type: SurveyActionTypes.GET_SURVEY, surveyId })

export const setSurveys = (surveys: Survey[], pageCount: number) => ({
  type: SurveyActionTypes.SET_SURVEYS,
  surveys,
  pageCount,
})

export const addSurveys = (surveys: Survey[], pageCount: number) => ({
  type: SurveyActionTypes.ADD_SURVEYS,
  surveys,
  pageCount,
})

export const addSurvey = (name: string) => ({
  type: SurveyActionTypes.ADD_SURVEY,
  name,
})

export const duplicateSurvey = (survey: Survey) => ({
  type: SurveyActionTypes.DUPLICATE_SURVEY,
  survey,
})

export const setSurvey = (survey: Survey) => ({ type: SurveyActionTypes.SET_SURVEY, survey })

export const clearSurveysList = () => ({ type: SurveyActionTypes.CLEAR_SURVEYS_LIST })

export const updateSurvey = (surveyId: number, survey: Partial<SurveyApi>) => ({
  type: SurveyActionTypes.UPDATE_SURVEY,
  surveyId,
  survey,
})

export const setUpdatedSurvey = (survey: Survey) => ({
  type: SurveyActionTypes.SET_UPDATED_SURVEY,
  survey,
})

export const setUpdatedSurveyBranch = (
  survey: Survey,
  tree: SurveyBranchConfig[],
  branchUuid: string,
) => ({
  type: SurveyActionTypes.SET_UPDATED_SURVEY_BRANCH,
  survey,
  tree,
  branchUuid,
})

export const deleteSurvey = (surveyId: number) => ({
  type: SurveyActionTypes.DELETE_SURVEY,
  surveyId,
})

export const removeSurvey = (surveyId: number) => ({
  type: SurveyActionTypes.REMOVE_SURVEY,
  surveyId,
})

export const setRoot = (surveyId: number) => ({ type: SurveyActionTypes.SET_ROOT, surveyId })

export const setBranch = (fromBranchUuid: string, toBranchUuid: string) => ({
  type: SurveyActionTypes.SET_BRANCH,
  fromBranchUuid,
  toBranchUuid,
})

export const resetTree = () => ({ type: SurveyActionTypes.RESET_TREE })

export const addQuestionnairesToSurvey = (questionnairesId: number[]) => ({
  type: SurveyActionTypes.ADD_QUESTIONNAIRES_TO_SELECTED_SURVEY,
  questionnairesId,
})
export const addScoresToSurveyBranch = (
  surveyId: number,
  scoresIds: number[],
  tree: SurveyBranchConfig[],
  branchUuid: string,
) => ({
  type: SurveyActionTypes.ADD_SCORES_TO_SURVEY_BRANCH,
  surveyId,
  tree,
  branchUuid,
  scoresIds,
})

export const deleteSurveyScore = (
  surveyId: number,
  tree: SurveyBranchConfig[],
  branchUuid: string,
  score: SurveyScore,
) => ({
  type: SurveyActionTypes.DELETE_SURVEY_SCORE,
  surveyId,
  tree,
  branchUuid,
  score,
})

export const addQuestionToSurveyBranch = (
  surveyId: number,
  tree: SurveyBranchConfig[],
  branchUuid: string,
  label: string,
  answers: SurveyQuestionAnswer[],
) => ({
  type: SurveyActionTypes.ADD_QUESTION_TO_SURVEY_BRANCH,
  surveyId,
  tree,
  branchUuid,
  label,
  answers,
})

export const deleteSurveyQuestion = (
  surveyId: number,
  tree: SurveyBranchConfig[],
  branchUuid: string,
  question: SurveyQuestionConfig,
) => ({
  type: SurveyActionTypes.DELETE_SURVEY_QUESTION,
  surveyId,
  tree,
  branchUuid,
  question,
})

export const editQuestionInSurveyBranch = (
  surveyId: number,
  tree: SurveyBranchConfig[],
  branchUuid: string,
  question: SurveyQuestionConfig,
  label: string,
  answers: SurveyQuestionAnswer[],
) => ({
  type: SurveyActionTypes.EDIT_QUESTION_IN_SURVEY_BRANCH,
  surveyId,
  tree,
  branchUuid,
  question,
  label,
  answers,
})

export const toggleSurveyScoreField = (
  surveyId: number,
  tree: SurveyBranchConfig[],
  branchUuid: string,
  scoreUuid: string,
  fieldId: number,
  checked: boolean,
) => ({
  type: SurveyActionTypes.TOGGLE_SURVEY_SCORE_FIELD,
  surveyId,
  tree,
  branchUuid,
  scoreUuid,
  fieldId,
  checked,
})

export const setScoreExcludedFields = (
  surveyId: number,
  tree: SurveyBranchConfig[],
  branchUuid: string,
  scoreUuid: string,
  fieldsIds: number[],
) => ({
  type: SurveyActionTypes.SET_SCORE_EXCLUDED_FIELDS,
  surveyId,
  tree,
  branchUuid,
  scoreUuid,
  fieldsIds,
})

export const updateScoreFieldLabel = (
  surveyId: number,
  tree: SurveyBranchConfig[],
  branchUuid: string,
  scoreUuid: string,
  fieldId: number,
  label: string,
) => ({
  type: SurveyActionTypes.UPDATE_SCORE_FIELD_LABEL,
  surveyId,
  tree,
  branchUuid,
  scoreUuid,
  fieldId,
  label,
})

export const updateSurveyConclusion = (surveyId: number, value: string) => ({
  type: SurveyActionTypes.UPDATE_SURVEY_CONCLUSION,
  surveyId,
  value,
})

export const setUpdatedSurveyConclusion = (surveyId: number, value: string) => ({
  type: SurveyActionTypes.SET_UPDATED_SURVEY_CONCLUSION,
  surveyId,
  value,
})

export const updateScoreIntroduction = (
  surveyId: number,
  tree: SurveyBranchConfig[],
  branchUuid: string,
  scoreUuid: string,
  introduction: string,
) => ({
  type: SurveyActionTypes.UPDATE_SURVEY_SCORE_INTRODUCTION,
  surveyId,
  tree,
  branchUuid,
  scoreUuid,
  introduction,
})

export const getBranchScores = (surveyId: number, branchScoresIds: number[]) => ({
  type: SurveyActionTypes.GET_BRANCH_SCORES,
  surveyId,
  branchScoresIds,
})

export const setSurveyItemLoading = (loading: boolean) => ({
  type: SurveyActionTypes.SET_SURVEY_ITEM_LOADING,
  loading,
})

export const setSurveyListLoading = (loading: boolean) => ({
  type: SurveyActionTypes.SET_SURVEY_LIST_LOADING,
  loading,
})

export const updateSurveyBranchItems = (
  surveyId: number,
  tree: SurveyBranchConfig[],
  branchUuid: string,
  items: SurveyBranchItem[],
) => ({
  type: SurveyActionTypes.UPDATE_SURVEY_BRANCH_ITEMS,
  surveyId,
  tree,
  branchUuid,
  items,
})

export const selectSurveyItem = (surveyId: number, withFetch: boolean = false) => ({
  type: SurveyActionTypes.SELECT_SURVEY_ITEM,
  surveyId,
  withFetch,
})

export const setSelectedSurvey = (surveyId: number, survey: Survey | null) => ({
  type: SurveyActionTypes.SET_SELECTED_SURVEY,
  surveyId,
  survey,
})

export const duplicateSurveyToAnotherUser = (surveyId: number, userId: string) => ({
  type: SurveyActionTypes.DUPLICATE_SURVEY_TO_ANOTHER_USER,
  surveyId,
  userId,
})
