import { City } from '../../../model/Contact'
import { RestuxResources } from '../../resources'
import { restuxCacheFactory } from '../../restux/cache/index'
import { restuxCitiesApiConfig } from './api'

const resourceName = RestuxResources.cities

const { actions, reducers, sagas } = restuxCacheFactory<City, City>({
  apiConfig: restuxCitiesApiConfig,
  refetchList: false,
  resourceName,
})

export const citiesActions = actions
export const citiesReducers = reducers
export const citiesSagas = sagas
