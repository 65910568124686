import { RootState } from '../../reducers'
import { restuxResolvePage } from '../../restux/ui/restuxUi.selectors'
import { createSelector } from 'reselect'

const paginationSelector = (state: RootState) => state.ui.mailContactSearch

export const mailContactSearchPaginationSelector = createSelector(
  [paginationSelector],
  (service) => service.pagination,
)

const pageSelector = (state: RootState) => state.cache.mailContactSearch.list

export const mailContactSearchPageSelector = createSelector(
  [mailContactSearchPaginationSelector, pageSelector],
  (pagination, resource) => restuxResolvePage(resource, pagination),
)
