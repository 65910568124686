import { resizeImage } from '../TextEditor.utilities'
import React from 'react'
import { Editor, Plugin, RenderBlockProps } from 'slate-react'
import { NodeType } from '../model/TextEditor.model'
import Image from './Image.component'
import { Size } from './Image.model'
import { Block } from 'slate'

export type ImagePluginMode = 'edit' | 'view'

interface ImagePluginOpts {
  mode: ImagePluginMode
  readOnly: boolean
}

const DEFAULT_IMAGE_PLUGIN_OPTS: ImagePluginOpts = {
  mode: 'edit',
  readOnly: false,
}

export const ImagePlugin = ({
  mode,
  readOnly,
}: ImagePluginOpts = DEFAULT_IMAGE_PLUGIN_OPTS): Plugin => {
  const editing = mode === 'edit'
  return {
    renderBlock(props: RenderBlockProps, editor: Editor, next: () => any) {
      const { attributes, node, isFocused } = props

      const setImageSize = (size: Size) => {
        editor.command('resizeImage', node, size)
      }

      switch (node.type) {
        case NodeType.IMAGE:
          const url = node.data.get('url')
          const align = node.data.get('align')
          const size = node.data.get('size')
          if (!url) {
            throw new Error('Creating an image without image url')
          }
          return (
            <Image
              url={url}
              size={size}
              isFocused={isFocused}
              readOnly={readOnly}
              editing={editing}
              align={align}
              renderAttributes={attributes}
              setSize={setImageSize}
            />
          )
        default:
          return next()
      }
    },
    commands: {
      resizeImage(editor: Editor, block: Block, size: Size) {
        resizeImage(editor, block, size)
        return editor
      },
    },
  }
}
