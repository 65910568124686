import { UsurpedUser } from 'core/AuthContext/auth.model'
import { DEFAULT_MAX_WIDTH, Size } from './Image.model'
import { Buffer } from 'buffer'

export const getBase64Image = async (
  url: string,
  token?: string,
  usurpedUser?: UsurpedUser,
): Promise<string> => {
  const OPTIMIAZED_URL_PATTERN = /data:.*;base64/
  if (!OPTIMIAZED_URL_PATTERN.test(url)) {
    const response = await fetch(
      `${
        usurpedUser
          ? `${url}?_switch_user=${encodeURIComponent(
              usurpedUser.username,
            )}&_switch_user_id=${encodeURIComponent(usurpedUser.id)}`
          : url
      }`,
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : '',
        },
        method: 'GET',
      },
    )

    const contentType = response.headers.get('content-type')
    const arrayBuffer = await response.arrayBuffer()
    const base64 = Buffer.from(arrayBuffer).toString('base64')
    return `data:${contentType};base64,${base64}`
  }

  return url
}

export const constraintSize = (value: Size, maxWidth = DEFAULT_MAX_WIDTH) => {
  const { width, height } = value
  if (width > maxWidth) {
    const resizeRatio = maxWidth / width
    return {
      width: maxWidth,
      height: height * resizeRatio,
    }
  }
  return value
}
