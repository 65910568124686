import { patientsKeys } from '../../queries/patients/patients.keys'
import { useSubscribeMutation } from '../mutation'
import { DeletePatientMutateVariable } from '../../queries/patients'
import { doctolibZipperDeletePatient } from '../../../misc/Doctolib/doctolib.actions'
import { useAtomValue } from 'jotai'
import { zipperStatusAtom } from '../../../state/doctolib'
import { DoctolibZipperStatus } from '../../../model/Doctolib'

export const useDeleteDoctolibPatient = () => {
  const zipperStatus = useAtomValue(zipperStatusAtom)

  useSubscribeMutation<null, DeletePatientMutateVariable>(
    patientsKeys.deleteItem,
    (_mutationData, variables) => {
      if (zipperStatus === DoctolibZipperStatus.LOADED) {
        console.log(`Doctolib: Delete patient with id ${variables.patientId}`)
        doctolibZipperDeletePatient(variables.patientId)
      }
    },
  )
}
