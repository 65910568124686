import type { PropsWithChildren } from 'react'
import { ColorPreset } from '../../../../../colors'

export enum SearchDocumentHistoryFilterType {
  ALL = 'all',
  HISTORY = 'history',
  RENDER_HISTORY = 'render_history',
}

export type SearchDocumentHistoryFiltersProps = PropsWithChildren<{
  colorPreset?: ColorPreset
  filter: SearchDocumentHistoryFilterType
  onChange: (filter: SearchDocumentHistoryFilterType) => void
}>
