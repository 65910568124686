import { Filters } from '../../model/Filters'
import getApi from '../../api'
import { PaginatedList } from '../../model/Pagination'
import { deserializeTasks } from './mapper/tasks.mapper'
import { TaskApi } from './mapper/Task.model'

export const DEFAULT_TASK_LIMIT_PER_PAGE = 999

export const getTasks = (
  page: number,
  filters?: Filters,
  signal?: AbortSignal,
  limit = DEFAULT_TASK_LIMIT_PER_PAGE,
) =>
  getApi()
    .get<PaginatedList<TaskApi>>(
      '/tasks',
      {
        page,
        limit,
        ...filters,
      },
      { signal },
    )
    .then(deserializeTasks)
