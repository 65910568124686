import { ManualPrescription } from '../../../model/ManualPrescription'
import { UiBottomPanelManualPrescriptionActionTypes } from './bottomPanelManualPrescription.model'

export const bottomPanelManualPrescriptionActions = {
  addManualPrescription: (
    onAddManualPrescription: (manualPrescription: ManualPrescription) => void,
  ) => ({
    type: UiBottomPanelManualPrescriptionActionTypes.ADD_MANUAL_PRESCRIPTION,
    onAddManualPrescription,
  }),
  submitManualPrescription: (manualPrescription: ManualPrescription) => ({
    type: UiBottomPanelManualPrescriptionActionTypes.SUBMIT_MANUAL_PRESCRIPTION,
    manualPrescription,
  }),
}
