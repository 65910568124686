import { Contact } from '../../../model/Contact'
import { IdentityIdentifier } from '../../restux/restux.model'
import { BottomPanelContactsContext } from '../../ui/bottomPanelContacts'
import { DomainContactsActionTypes } from './contacts.model'

const editContact = (
  contactId: IdentityIdentifier,
  updatedContact: Partial<Contact>,
  context?: BottomPanelContactsContext,
) => ({
  type: DomainContactsActionTypes.EDIT_CONTACT,
  contactId,
  updatedContact,
  context,
})

export const domainContactsActions = {
  editContact,
}
