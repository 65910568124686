import { FC } from 'react'
import { Icon } from '../../../../shared'
import { IdentityStatusConditionProps } from './IdentityStatusCondition.model'
import classNames from 'classnames'
import { getCssVariable } from '../../../../../design-system/utils'

export const IdentityStatusCondition: FC<IdentityStatusConditionProps> = ({ label, valid }) => (
  <div className="flex">
    <Icon
      size="nano"
      icon={valid ? 'done' : 'cross'}
      color={valid ? getCssVariable('shades', 'shade3') : getCssVariable('status', 'invalid')}
    />
    <span
      className={classNames('ml-2', {
        'text-status-invalid': !valid,
        'text-shades-3': valid,
      })}
    >
      {label}
    </span>
  </div>
)
