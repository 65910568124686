import React, { FC, useContext } from 'react'
import { ToolbarButton } from '@/components/toolbar/atoms'
import { ToolbarElementProps } from '@/model/Toolbar'
import { EditorConfigContext } from '@/context'

export const UndoGroup: FC<ToolbarElementProps> = ({ editor }) => {
  const { tools } = useContext(EditorConfigContext)

  const handleUndo = () => editor.chain().focus().undo().run()
  const handleRedo = () => editor.chain().focus().redo().run()

  const canUndo = editor.can().chain().focus().undo().run()
  const canRedo = editor.can().chain().focus().redo().run()

  const display = tools.includes('undo')

  return display ? (
    <>
      <ToolbarButton
        title="Annuler (Ctrl + Z)"
        icon="Undo"
        onClick={handleUndo}
        disabled={!canUndo}
      />
      <ToolbarButton
        title="Rétablir (Ctrl + Y)"
        icon="Redo"
        onClick={handleRedo}
        disabled={!canRedo}
      />
    </>
  ) : null
}
