import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { customHistory } from '../../../history'
import { useTrackingService } from '../../../misc/Tracking/useTrackingService'

export const NavigationUtils: FC = () => {
  customHistory.navigate = useNavigate()
  useTrackingService()

  return null
}
