import { Mail, MailListPage } from '../../../model/Mail'
import { RequestStatus } from '../../../model/RequestStatus'
import { MailFile } from './api/mail.model'

export const MAIL_CONNECTION_LOOP_DELAY = 1000 * 60 * 15

export interface MailState {
  loginInitate: boolean
  otpStatus: boolean
  emailAddress: Array<string>
  mailList: MailListPage
  inUseMailStatus: RequestStatus
  requestStatus: RequestStatus
  fetchingNextMailsStatus: RequestStatus
  filesToSend: File[]
  blackListedPatientMails: Array<string>
  unqualifiedPatientEmails: Array<string>
  inUseMail?: Mail
  inUseMailAddress?: string
  inUseAttachment?: MailFile
  isCreatePatientThroughMailModalOpen: boolean
  search?: string
}

export enum MailActionTypes {
  INITIATE_AUTH = '@MAIL/INITIATE_AUTH',
  FINALIZE_AUTH = '@MAIL/FINALIZE_AUTH',
  SET_LOGIN_INITIATE = '@MAIL/SET_LOGIN_INITIATE',
  SET_OTP_STATUS = '@MAIL/SET_OTP_STATUS',
  DISCONNECT = '@MAIL/DISCONNECT',
  DISCONNECT_WITH_ERROR = '@MAIL/DISCONNECT_WITH_ERROR',
  SET_MAIL_ADDRESS_LIST = '@MAIL/SET_MAIL_ADDRESS_LIST',
  GET_MAIL_ADDRESS_LIST = '@MAIL/GET_MAIL_ADDRESS_LIST',
  SET_IN_USE_MAIL_ADDRESS = '@MAIL/SET_IN_USE_MAIL_ADDRESS',
  GET_MAIL_LIST = '@MAIL/GET_MAIL_LIST',
  SET_FETCHING_NEXT_MAILS_STATUS = '@MAIL/SET_FETCHING_NEXT_MAILS_STATUS',
  SET_MAIL_LIST = '@MAIL/SET_MAIL_LIST',
  CLEAR_MAIL_LIST = '@MAIL/CLEAR_MAIL_LIST',
  RELOAD_MAIL_LIST = '@MAIL/RELOAD_MAIL_LIST',
  SET_IN_USE_MAIL = '@MAIL/SET_IN_USE_MAIL',
  GET_IN_USE_MAIL = '@MAIL/GET_IN_USE_MAIL',
  SET_REQUEST_MAIL_STATUS = '@MAIL/SET_REQUEST_MAIL_STATUS',
  SET_REQUEST_IN_USE_MAIL_STATUS = '@MAIL/SET_REQUEST_IN_USE_MAIL_STATUS',
  GET_ATTACHMENT_FILE = '@MAIL/GET_ATTACHMENT_FILE',
  CLASSIFY_DOCUMENTS = '@MAIL/CLASSIFY_DOCUMENTS',
  SEND_MAIL = '@MAIL/SEND_MAIL',
  SET_FILES_TO_SEND = `@MAIL/SET_FILES_TO_SEND`,
  CLEAR_FILES_TO_SEND = `@MAIL/CLEAR_FILES_TO_SEND`,
  SET_PATIENT_MAIL_BLACKLIST = `@MAIL/SET_PATIENT_MAIL_BLACKLIST`,
  SET_PATIENT_UNQUALIFIED = `@MAIL/SET_PATIENT_UNQUALIFIED`,
  CHECK_PATIENT_EMAIL_VALIDATION = '@MAIL/CHECK_PATIENT_EMAIL_VALIDATION',
  CREATE_PATIENT_FROM_MAIL = '@MAIL/CREATE_PATIENT_FROM_MAIL',
  UPDATE_PATIENT_FROM_MAIL = '@MAIL/UPDATE_PATIENT_FROM_MAIL',
  SET_ATTACHMENT_PATIENT = '@MAIL/SET_ATTACHMENT_PATIENT',
  SET_IN_USE_ATTACHMENT = '@MAIL/SET_IN_USE_ATTACHMENT',
  SET_CREATE_PATIENT_THROUGH_MAIL_MODAL_OPEN = '@MAIL/SET_CREATE_PATIENT_THROUGH_MAIL_MODAL_OPEN',
  SET_MAIL_SEARCH = '@MAIL/SET_MAIL_SEARCH',
  DELETE_MAIL = '@MAIL/DELETE_MAIL',
}

export interface ApiMailClassifyAttachmentBaseResource {
  id: string | number
  visibleName: string
}

export interface ApiMailClassifyAttachmentResource extends ApiMailClassifyAttachmentBaseResource {
  id: number
  cdaContent?: ApiMailClassifyCdaAttachmentResource[]
}

export interface ApiMailClassifyCdaAttachmentResource
  extends ApiMailClassifyAttachmentBaseResource {
  id: string
}
