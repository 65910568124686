import React, { FunctionComponent } from 'react'
import styles from './InfiniteLoader.module.scss'
import { ReactComponent as FollowCustomLoader } from '../../../../assets/images/loader-follow.svg'

interface InfiniteLoaderProps {
  loading: boolean
  label?: string
}

export const InfiniteLoader: FunctionComponent<InfiniteLoaderProps> = ({ loading, label }) => {
  return (
    <div style={{ display: loading ? 'inherit' : 'none' }}>
      {/* C'est pas ouf, mais ce composant devrait sauter rapidement, ca évite juste que le loader ne casse le flux dans un branch recompose */}
      <div
        className={styles.logo}
        style={{
          opacity: loading ? 1 : 0,
          display: loading ? 'block' : 'none',
        }}
      >
        <FollowCustomLoader />
      </div>
      {label && (
        <div style={{ opacity: loading ? 1 : 0 }} className={styles.label}>
          {label}
        </div>
      )}
    </div>
  )
}
