import { connect } from 'react-redux'

import { RootState } from '../../../store'
import {
  inUseLoadingContactSelector,
  inUseContactSelector,
  bottomPanelContactsActions,
  readOnlyContactSelector,
  contextContactSelector,
  isInCreatePrivateCopyModeSelector,
} from '../../../store/ui/bottomPanelContacts'
import { domainContactsActions } from '../../../store/domain/contacts/contacts.actions'

import { EditContactPanelDispatchProps, EditContactPanelStateProps } from './EditContactPanel.model'

import EditContactPanel from './EditContactPanel.component'
import { hideBottomPanel } from '../../../store/ui/bottomPanel'

const mapStateToProps = (state: RootState): EditContactPanelStateProps => ({
  inUseContact: inUseContactSelector(state),
  readOnly: readOnlyContactSelector(state),
  context: contextContactSelector(state),
  loadingState: inUseLoadingContactSelector(state),
  isInCreatePrivateCopyMode: isInCreatePrivateCopyModeSelector(state),
})

const mapDispatchToProps: EditContactPanelDispatchProps = {
  createContact: bottomPanelContactsActions.createPrivateCopy,
  updateContact: domainContactsActions.editContact,
  hideBottomPanel,
  setInUseContact: bottomPanelContactsActions.useId,
  switchToPrivateCopyMode: bottomPanelContactsActions.switchToPrivateCopyMode,
}

export default connect(mapStateToProps, mapDispatchToProps)(EditContactPanel)
