import {
  EditQuestionAction,
  UiBottomPanelQuestionsActionTypes,
  QuestionEditionOrigin,
} from './bottomPanelQuestions.model'

const createEditQuestionFromDocument = (origin: QuestionEditionOrigin) => (
  questionId: number,
  questionContext: {
    documentInstanceId?: number
    documentTemplateId?: number
  },
  onQuestionEdited?: () => void,
): EditQuestionAction => ({
  type: UiBottomPanelQuestionsActionTypes.EDIT_QUESTION,
  questionId,
  questionContext,
  onQuestionEdited,
  origin,
})

const editQuestionFromMedicalEvent = createEditQuestionFromDocument(
  QuestionEditionOrigin.MEDICAL_EVENT,
)
const editQuestionFromTemplate = createEditQuestionFromDocument(QuestionEditionOrigin.TEMPLATE)

export const bottomPanelQuestionsActions = {
  editQuestionFromMedicalEvent,
  editQuestionFromTemplate,
}
