import React from 'react'

import SearchDocumentInstanceHistory from './containers/SearchDocumentInstanceHistory'
import SearchDocumentTemplateHistory from './containers/SearchDocumentTemplateHistory'

import { SearchDocumentHistoryProps } from './SearchDocumentHistory.model'

export function SearchDocumentHistory(props: SearchDocumentHistoryProps) {
  switch (props.resource) {
    case 'document_templates':
      return <SearchDocumentTemplateHistory {...props} />
    case 'documents':
      return <SearchDocumentInstanceHistory {...props} />
  }
}
