import React, { FunctionComponent } from 'react'
import { ReactComponent as TaskEmptyIcon } from '../../../../assets/images/tasks/tasks.svg'
import { GridLayout } from '../../../shared'

export const TaskNotFound: FunctionComponent = () => {
  return (
    <div className="mt-4 mb-4 w-full h-16 bg-shades-7 rounded-xl text-xs">
      <GridLayout
        alignColumns="center"
        columnsTemplate="auto auto 1fr"
        shouldShrinkOnMobile={false}
        gap="small"
      >
        <div className="ml-4 mt-2 w-12 h-12">
          <TaskEmptyIcon />
        </div>
        <div className="text-base text-shades-4 mt-2">
          <p>Plus aucune tâche...</p>
        </div>
      </GridLayout>
    </div>
  )
}
