import styled, { CreateStyled } from '@emotion/styled'
import { mediaQueries } from './media-queries'
import { breakpoints } from './breakpoints'
import { spaces } from './spaces'

export const theme = {
  mediaQueries,
  breakpoints,
  spaces,
}

type CustomTheme = typeof theme

/** @deprecated Préférer l'utilisation de modules SCSS */
export default styled as CreateStyled

declare module '@emotion/react' {
  export interface Theme extends CustomTheme {}
}
