import { createReducer } from 'redux-starter-kit'
import { setVariableData, setVariableDataLoading } from './renderer.actions'
import { ReduxRenderer, RendererActionTypes } from './renderer.model'

export const RENDERER_INITIAL_STATE: ReduxRenderer = {
  blobToPrint: undefined,
  variableData: null,
  isVariableDataLoading: false,
}

const ACTION_HANDLERS = {
  [RendererActionTypes.SET_VARIABLE_DATA]: (
    state: ReduxRenderer = RENDERER_INITIAL_STATE,
    { variableData }: ReturnType<typeof setVariableData>,
  ) => ({
    ...state,
    variableData,
  }),
  [RendererActionTypes.SET_VARIABLE_DATA_LOADING]: (
    state = RENDERER_INITIAL_STATE,
    { loading }: ReturnType<typeof setVariableDataLoading>,
  ) => ({ ...state, isVariableDataLoading: loading }),
}

export const rendererReducer = createReducer<ReduxRenderer, any>(
  RENDERER_INITIAL_STATE,
  ACTION_HANDLERS,
)
