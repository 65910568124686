import { uiActionsFactory } from '../../../restux/ui/restuxUiActions.factory'
import {
  medicalEventQuestionnairesConfig,
  MedicalEventContentUiActionTypes,
  SelectedContent,
} from './medicalEventContent.model'

export const restuxUi = uiActionsFactory(medicalEventQuestionnairesConfig)

export const medicalEventContentUiActionTypes = restuxUi.types

export const medicalEventContentUiActions = {
  ...restuxUi.actions,

  selectMedicalEventContent: (filter: SelectedContent, withFetch = false) => ({
    type: MedicalEventContentUiActionTypes.SELECT_CONTENT,
    filter,
    withFetch,
  }),
}
