import React, { useEffect, useRef } from 'react'
import { List, ListItemType } from '../../../../../components/shared'
import { noop } from '../../../../../misc/functions.utilities'

import { SearchPanelContainer, SearchPanelFilters, SearchPanelList } from '../../../../SearchPanel'

import { SearchDocumentHistoryPanelProps } from './SearchDocumentHistoryPanel.model'

export function SearchDocumentHistoryPanel<HistoryItem extends ListItemType>({
  loading,
  items,
  renderFilters,
  renderItem,
}: SearchDocumentHistoryPanelProps<HistoryItem>) {
  const list = useRef<List<HistoryItem>>(null)
  useEffect(() => {
    if (list.current) {
      list.current.updateList()
    }
  }, [items])
  return (
    <SearchPanelContainer filterable={renderFilters !== undefined}>
      {renderFilters && <SearchPanelFilters>{renderFilters()}</SearchPanelFilters>}
      <SearchPanelList>
        <List<HistoryItem>
          ref={list}
          pageCount={items.length / 40}
          itemCount={items.length}
          loading={loading}
          items={items}
          onPrimaryAction={noop}
          renderItem={renderItem}
          onUpdateList={noop}
          fullPageMode={false}
        />
      </SearchPanelList>
    </SearchPanelContainer>
  )
}
