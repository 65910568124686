export const spaces = {
  none: '0px',
  extraSmall: '8px',
  small: '16px',
  medium: '24px',
  large: '32px',
  extraLarge: '40px',
  gigaLarge: '80px',
}

export type SpaceType = keyof typeof spaces | [SpaceType, SpaceType]
