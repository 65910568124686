import { useDispatch } from 'react-redux'
import { AnyAction } from 'redux'

/*
    Ce hook permet de binder une action avec une syntaxe plus sexy que dans l'implementation standard
    ex :  const myHookedAction = useActionDispatch(myAction)
          myHookedAction(params)
*/

export function useActionDispatch<Fn extends (...args: any[]) => AnyAction>(fn: Fn) {
  const dispatch = useDispatch()
  return (...args: Parameters<Fn>) => dispatch(fn(...args))
}
