import React, { FC, useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { openZenDesk } from '../../../../../../analytics'
import { DropdownContainer, DropdownItem, ValidationModal } from '../../../../../shared'
import { hasDoctorRole, hasExtDsmRole, hasStaffRole } from '../../../../../../misc/roles.utilities'
import { assignLocation } from '../../../../../../misc/url.utilities'
import { NavigationFloatingMenuTabProps } from './NavigationFloatingMenuTab.model'
import { ActionTab } from '../ActionTab'

export const NavigationFloatingMenuTab: FC<NavigationFloatingMenuTabProps> = ({
  currentUser,
  loggedUser,
  userEnabledFeatures,
  setUsurpedUser,
  onLogout,
}) => {
  const [open, setOpen] = useState(false)
  const [isLogoutModalOpen, setLogoutModalOpen] = useState(false)

  const displayStopUsurpation =
    currentUser && loggedUser && currentUser.id !== loggedUser.id && hasStaffRole(loggedUser.roles)

  const isExtDSM = loggedUser && hasExtDsmRole(loggedUser.roles)

  const closeMenu = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  return (
    <div className="relative">
      <ActionTab label="Menu" icon="dotSquare" onClick={() => setOpen(true)} />
      <div className="absolute bottom-8 left-20">
        <DropdownContainer
          opened={open}
          closeDropdown={closeMenu}
          direction="left"
          level="bottom"
          verticalScroll={false}
        >
          <Link to="/account/settings">
            <DropdownItem icon="key" testId="nav-link-account">
              Mon Compte
            </DropdownItem>
          </Link>
          {hasDoctorRole(loggedUser?.roles ?? []) && (
            <Link to="/account/preferences">
              <DropdownItem icon="preferences" testId="nav-link-preferences">
                Préférences
              </DropdownItem>
            </Link>
          )}
          {hasDoctorRole(currentUser?.roles ?? []) &&
          userEnabledFeatures?.dmp &&
          userEnabledFeatures.insiValidation &&
          userEnabledFeatures.mail &&
          !isExtDSM ? (
            <>
              <Link to="/account/dashboard">
                <DropdownItem icon="dashboard" testId="nav-link-dashboard">
                  Tableau de bord
                </DropdownItem>
              </Link>
            </>
          ) : null}
          <Link to="/legal">
            <DropdownItem icon="link" testId="nav-link-legal">
              Liens et informations
            </DropdownItem>
          </Link>
          <DropdownItem icon="school">
            <a
              href={process.env.REACT_APP_ACADEMY_APP_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              Follow Academy
            </a>
          </DropdownItem>
          <DropdownItem icon="question" onClick={() => openZenDesk()} testId="nav-link-help">
            Aide
          </DropdownItem>
          {displayStopUsurpation && (
            <DropdownItem
              icon="forbidden"
              onClick={() => {
                setUsurpedUser()
                assignLocation('/admin/users')
              }}
              testId="button-usurpation-drop"
            >
              Arrêter l'usurpation
            </DropdownItem>
          )}
          <DropdownItem
            icon="power"
            onClick={() => setLogoutModalOpen(true)}
            testId="button-logout"
          >
            Déconnexion
          </DropdownItem>
        </DropdownContainer>
      </div>
      <ValidationModal
        display={isLogoutModalOpen}
        icon="out"
        confirmLabel="Déconnexion"
        title="Êtes-vous sûr de vouloir vous déconnecter ?"
        onSubmit={onLogout}
        onClose={() => setLogoutModalOpen(false)}
        testId="modal-logout"
      />
    </div>
  )
}
