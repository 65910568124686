import React, { FC } from 'react'
import { Modal, ThinModalLayout, ModalContent, ModalActions } from '../Modal'
import { Heading } from '../../fonts'
import { Icon } from '../../Icon'
import { ValidationModalProps } from './ValidationModal.model'
import { RoundedButton } from '../../buttons'
import { getCssVariable } from '../../../../design-system/utils'
import { isDefined } from '../../../../misc/functions.utilities'

export const ValidationModal: FC<ValidationModalProps> = ({
  title,
  description,
  icon = 'alert',
  confirmLabel = 'Valider',
  cancelLabel = 'Retour',
  rootId,
  children,
  confirmTheme = 'invalid',
  size,
  testId,
  isUnmounting,
  onSubmit,
  onClose,
}) => (
  <Modal isUnmounting={isUnmounting} rootId={rootId} onClickOutside={onClose} testId={testId}>
    <ThinModalLayout size={size}>
      <ModalContent>
        <Icon size="mega" icon={icon} color={getCssVariable('shades', 'shade2')} />
        <Heading size={3}>{title}</Heading>
        {description && <span className="text-shades-2 font-medium text-base">{description}</span>}
        {children}
      </ModalContent>
      <ModalActions>
        <div className="mr-4">
          {onClose && (
            <RoundedButton
              onClick={onClose}
              icon="arrow"
              iconRotate={-90}
              theme="transparent-dark"
              label={cancelLabel}
              testId={`${testId}-button-cancel`}
            />
          )}
        </div>
        <RoundedButton
          theme={confirmTheme}
          label={confirmLabel}
          disabled={!isDefined(onSubmit)}
          onClick={onSubmit}
          testId={`${testId}-button-submit`}
        />
      </ModalActions>
    </ThinModalLayout>
  </Modal>
)
