import { ApiResponse } from 'apisauce'
import { DocumentRevision } from '../../../../model/DocumentHistory'
import { PaginatedList } from '../../../../model/Pagination'

export function deserializeDocumentRevisionResponse<WithRevision extends DocumentRevision>(
  response: ApiResponse<PaginatedList<WithRevision>>,
) {
  const revisions = (response.ok && response.data && response.data.items) || []
  return revisions.map((item) => ({ ...item, id: item.uuid || item.id }))
}
