import { MetricsSendingInfos } from '../../../model/Metrics'
import { RequestStatus } from '../../../model/RequestStatus'
import { RestuxResources } from '../../resources'
import { toUpperSnakeCase } from '../../restux/restux.utilities'

const RESOURCE_PREFIX = `@${toUpperSnakeCase(RestuxResources.metrics)}_DOMAIN`

export const metricsActionsType = {
  GET_LIST_OF_METRICS: `${RESOURCE_PREFIX}/GET_LIST_OF_METRICS`,
  GET_LIST_OF_METRICS_PENDING: `${RESOURCE_PREFIX}/GET_LIST_OF_METRICS_PENDING`,
  GET_LIST_OF_METRICS_FAILED: `${RESOURCE_PREFIX}/GET_LIST_OF_METRICS_FAILED`,
  GET_LIST_OF_METRICS_SUCCESS: `${RESOURCE_PREFIX}/GET_LIST_OF_METRICS_SUCCESS`,
  CLEAR_METRICS: `${RESOURCE_PREFIX}/CLEAR_METRICS`,
  DOWNLOAD_METRICS: `${RESOURCE_PREFIX}/DOWNLOAD_METRICS`,
  SEND_DMP_METRICS: `${RESOURCE_PREFIX}/SEND_DMP_METRICS`,
}

export interface MetricsState {
  messageSendingInfos: MetricsSendingInfos
  requestStatus: RequestStatus
}
