import React, { FunctionComponent, useContext } from 'react'
import { WithVariableEditorContext } from '../../../../WithVariableEditor/WithVariableEditorContext'
import { PageBreakContainerProps } from './PageBreak.model'
import { PageBreak } from './PageBreak.component'

const PageBreakContainer: FunctionComponent<PageBreakContainerProps> = props => {
  const { mode } = useContext(WithVariableEditorContext)

  return <PageBreak mode={mode} title="Saut de page" {...props} />
}

export default PageBreakContainer
