import { connect } from 'react-redux'

import { RootState } from '../../../../../store'
import { uiContactsActions, contactsPageSelector } from '../../../../../store/ui/contacts'
import { bottomPanelContactsActions } from '../../../../../store/ui/bottomPanelContacts'

import { DocumentContactsForm } from './DocumentContactsForm.component'
import {
  DocumentContactsFormDispatchProps,
  DocumentContactsFormStateProps,
} from './DocumentContactsForm.model'

const mapStateToProps = (state: RootState): DocumentContactsFormStateProps => ({
  predictions: contactsPageSelector(state),
})

const mapDispatchToProps: DocumentContactsFormDispatchProps = {
  searchContact: uiContactsActions.requestPage,
  clearPredictions: uiContactsActions.clearPagination,
  openCreateContactForm: bottomPanelContactsActions.openCreateContactForm,
  openEditContactForm: bottomPanelContactsActions.openEditContactForm,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)(DocumentContactsForm)
export { withConnect as DocumentContactsForm }
