export enum MedicalEventBaseVariableId {
  DOCTOR_NAME = 'doctorName',
  DOCTOR_TITLE = 'doctorTitle',
  DOCTOR_QUALITY = 'doctorQuality',
  DOCTOR_SPECIALITY = 'doctorSpeciality',
  DOCTOR_ACADEMIC_BACKGROUND_NO_LINE_BREAK = 'doctorAcademicBackgroundNoLineBreak',
  DOCTOR_ACADEMIC_BACKGROUND_LINE_BREAK = 'doctorAcademicBackgroundLineBreak',
  DOCTOR_ADDRESS = 'doctorAddress',
  DOCTOR_ADDRESS_LINEBREAKLESS = 'doctorAddressLinebreakLess',
  DOCTOR_EMAIL = 'doctorEmail',
  DOCTOR_EMAIL_MSS_MAILIZ = 'mailizEmailAddress',
  RPPS_NUMBER = 'rppsNumber',
  FINESS_NUMBER = 'finessNumber',
  MEDICAL_EVENT_DATE = 'interventionDate',
  MEDICAL_EVENT_DATE_NO_HASH = 'interventionDateNoHash',
  MEDICAL_EVENT_DATE_SHORT = 'interventionDateShort',
  MEDICAL_EVENT_DATE_DAY_BEFORE = 'medicalEventDateDayBefore',
  MEDICAL_EVENT_DATE_DAY_NEXT = 'medicalEventDateDayNext',
  MEDICAL_EVENT_DATE_DELAY = 'medicalEventDateDelay',
  MEDICAL_EVENT_TITLE = 'medicalEventTitle',
}
