import { FunctionComponent } from 'react'
import { DocumentFormCard } from '../Cards/DocumentFormCard.component'
import { QuestionnaireFormCard } from '../Cards/QuestionnaireFormCard.component'
import { ExportColumnsProps } from './ExportColumns.model'
import { LabeledCheckbox } from '../../legacy/LabeledCheckbox'
import {
  exportColumnsStateAtom,
  exportColumnsRemoveDocumentTemplate,
  exportColumnsRemoveQuestionnaire,
  exportColumnsSetOptions,
} from '../../../state/configurableExport'
import { useAtomValue, useSetAtom } from 'jotai'
import {
  EXPORT_PATIENT_COLUMNS_FULL,
  EXPORT_PATIENT_COLUMNS_PARTIAL,
} from '../../../model/ConfigurableExport'
import { useToggle } from 'react-use'
import { SearchExportColumnQuestionnaire } from '../BottomSearchPanel'

export const ExportColumns: FunctionComponent<ExportColumnsProps> = ({
  openSearchColumnDocument,
}) => {
  const [
    isOpenSearchExportColumnQuestionnaire,
    toggleOpenSearchExportColumnQuestionnaire,
  ] = useToggle(false)
  const columns = useAtomValue(exportColumnsStateAtom)
  const setOptions = useSetAtom(exportColumnsSetOptions)
  const removeQuestionnaire = useSetAtom(exportColumnsRemoveQuestionnaire)
  const removeTemplate = useSetAtom(exportColumnsRemoveDocumentTemplate)

  const handlePatientColumnsExportModes = (fullmode: boolean) => {
    if (fullmode) {
      setOptions({ patient: EXPORT_PATIENT_COLUMNS_FULL })
    } else {
      setOptions({ patient: EXPORT_PATIENT_COLUMNS_PARTIAL })
    }
  }

  return (
    <div className="flex flex-col gap-10">
      <h1>Sélection des données à exporter</h1>
      <p>Les ressources de données suivantes seront exportées pour les patients sélectionnés.</p>
      <DocumentFormCard
        description="Le titre et date des événements médicaux, les variables et les questionnaires des documents suivants seront exportés :"
        documentTemplates={columns.documentTemplates}
        addDocument={openSearchColumnDocument}
        removeDocument={removeTemplate}
      />
      <QuestionnaireFormCard
        description="Le titre et date des événements médicaux, les scores, les questions des questionnaires suivants seront exportés :"
        questionnaires={columns.questionnaires}
        addQuestionnaire={toggleOpenSearchExportColumnQuestionnaire}
        removeQuestionnaire={removeQuestionnaire}
      />
      <SearchExportColumnQuestionnaire
        open={isOpenSearchExportColumnQuestionnaire}
        onRequestClose={toggleOpenSearchExportColumnQuestionnaire}
      />
      <div className="flex flex-col gap-4">
        <LabeledCheckbox
          label="Exporter le nom, le prénom, l'email et la profession du médecin traitant des patients"
          checked={columns.assignatedDoctor}
          onChange={(e) => {
            setOptions({ assignatedDoctor: e.target.checked })
          }}
        />
        <LabeledCheckbox
          label="Exporter le nom, le prénom, l'email et la profession de l'adressant des patients"
          checked={columns.addressingDoctor}
          onChange={(e) => {
            setOptions({ addressingDoctor: e.target.checked })
          }}
        />
        <LabeledCheckbox
          label="Exporter toute la fiche patient (sinon le matricule, la date de naissance, le sexe et les labels des patients seront exportés)"
          checked={columns.patient !== EXPORT_PATIENT_COLUMNS_PARTIAL}
          onChange={(e) => {
            handlePatientColumnsExportModes(e.target.checked)
          }}
        />
      </div>
    </div>
  )
}
