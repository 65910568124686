import { useQuery } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { getPatientImportantInformations } from '../../../data/patientImportantInformations'
import { patientImportantInformationsKeys } from './patientImportantInformations.keys'
import { useMemo } from 'react'
import { PatientImportantInformation } from '../../../model/PatientImportantInformations'

interface GetAllPatientImportantInformationsParams {
  patientId: number
  enabled?: boolean
}

export const useGetAllPatientImportantInformations = ({
  patientId,
  enabled = true,
}: GetAllPatientImportantInformationsParams) => {
  const queryKey = useMemo(() => patientImportantInformationsKeys.listAll(patientId), [patientId])

  const fetchPatientImportantInformations = async (page: number, signal?: AbortSignal) => {
    const { data, ok } = await getPatientImportantInformations(page, patientId, signal)
    if (!data || !ok) {
      throw new UserFriendlyError(
        'error',
        'Impossible de récupérer la liste des informations importantes du patient',
        "Une erreur technique s'est produite",
      )
    }
    return data
  }

  const queryReturn = useQuery<PatientImportantInformation[]>(
    queryKey,
    async ({ signal }) => {
      const firstPaginatedList = await fetchPatientImportantInformations(1, signal)
      const paginatedLists = await Promise.all(
        Array.from({ length: firstPaginatedList.pageCount - 1 }).map((_, index) => {
          const page = index + 2
          return fetchPatientImportantInformations(page, signal)
        }),
      )

      return [...firstPaginatedList.items, ...paginatedLists.flatMap((p) => p.items)]
    },
    {
      enabled,
    },
  )

  return { query: queryReturn, patientImportantInformationList: queryReturn.data || [] }
}
