export enum Roles {
  DOCTOR = 'ROLE_DOCTOR',
  ASSISTANT = 'ROLE_ASSISTANT',
  ADMIN_TECH = 'ROLE_ADMIN_TECH',
  ADMIN_CSM = 'ROLE_ADMIN_CSM',
  ADMIN_SALE = 'ROLE_ADMIN_SALE',
  ADMIN_OFFICE_MANAGER = 'ROLE_ADMIN_OFFICE_MANAGER',
  EXT_DSM = 'ROLE_EXT_DSM',
  SUPER_ADMIN = 'ROLE_SUPER_ADMIN',
}

export const SUPER_ADMIN_ROLES = [Roles.SUPER_ADMIN]

export const ADMIN_ROLES = [
  Roles.SUPER_ADMIN,
  Roles.ADMIN_TECH,
  Roles.ADMIN_CSM,
  Roles.ADMIN_SALE,
  Roles.ADMIN_OFFICE_MANAGER,
] as const

export const CGU_ADMIN_ACCESS = [Roles.ADMIN_TECH, Roles.ADMIN_OFFICE_MANAGER] as const

export const USER_ROLES = [Roles.DOCTOR, Roles.ASSISTANT]

export const ROLES_TYPES = {
  [Roles.DOCTOR]: { id: Roles.DOCTOR, title: 'Médecin' },
  [Roles.ASSISTANT]: { id: Roles.ASSISTANT, title: 'Assistant' },
  [Roles.ADMIN_TECH]: { id: Roles.ADMIN_TECH, title: 'Admin Tech' },
  [Roles.ADMIN_CSM]: { id: Roles.ADMIN_CSM, title: 'Admin CSM' },
  [Roles.ADMIN_SALE]: { id: Roles.ADMIN_SALE, title: 'Admin Sale' },
  [Roles.ADMIN_OFFICE_MANAGER]: { id: Roles.ADMIN_OFFICE_MANAGER, title: 'Admin Office Manager' },
  [Roles.SUPER_ADMIN]: { id: Roles.SUPER_ADMIN, title: 'Super Admin' },
  [Roles.EXT_DSM]: { id: Roles.EXT_DSM, title: 'Externe DSM' },
}

export interface RoleOption {
  id: Roles
  title: string
}
