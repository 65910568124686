import React, { FunctionComponent } from 'react'

const ColoredIconLibrary = {
  checkupInactive: (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
      <path
        fill="#6B7A99"
        fillRule="evenodd"
        d="M30 2a4 4 0 0 1 4 4h2a6 6 0 0 1 6 6v28a6 6 0 0 1-6 6H12a6 6 0 0 1-6-6V12a6 6 0 0 1 6-6h2a4 4 0 0 1 4-4h12zm0 12H18a4 4 0 0 1-4-4h-2a2 2 0 0 0-2 2v28a2 2 0 0 0 2 2h24a2 2 0 0 0 2-2V12a2 2 0 0 0-2-2h-2a4 4 0 0 1-4 4zm0-8H18v4h12V6z"
      />
    </svg>
  ),
  documentInactive: (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
      <path
        fill="#6B7A99"
        fillRule="nonzero"
        d="M28 2c.055 0 .11.002.163.007L28 2a2 2 0 0 1 .329.027l.054.01c.037.007.073.015.109.024l.044.012c.045.012.088.026.131.041l.024.009-.024-.009a1.993 1.993 0 0 1 .645.377l.102.095 12 12c.038.037.074.076.108.116l-.108-.116a2 2 0 0 1 .513.877l.012.046c.01.035.017.07.024.107l.01.056c.006.037.012.076.016.114 0 .013.002.025.003.038l.003.027L42 16v24a6 6 0 0 1-6 6H12a6 6 0 0 1-6-6V8a6 6 0 0 1 6-6zm-2 4H12a2 2 0 0 0-2 2v32a2 2 0 0 0 2 2h24a2 2 0 0 0 2-2V18H28a2 2 0 0 1-1.995-1.85L26 16V6zm6 26a2 2 0 0 1 .15 3.995L32 36H16a2 2 0 0 1-.15-3.995L16 32h16zm0-8a2 2 0 0 1 .15 3.995L32 28H16a2 2 0 0 1-.15-3.995L16 24h16zm-12-8a2 2 0 0 1 .15 3.995L20 20h-4a2 2 0 0 1-.15-3.995L16 16h4zm10-7.171V14h5.172L30 8.829z"
      />
    </svg>
  ),
  fileInactive: (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
      <path
        fill="#6B7A99"
        fillRule="evenodd"
        d="M41.462 21.91l-18.38 18.38c-3.907 3.908-10.243 3.908-14.151 0-3.908-3.908-3.908-10.243 0-14.151l18.38-18.38a6.004 6.004 0 0 1 8.491 8.491L17.403 34.63a2.002 2.002 0 1 1-2.832-2.831l16.98-16.96a2 2 0 0 0-2.828-2.83l-16.98 16.96a6.002 6.002 0 1 0 8.488 8.49l18.4-18.38c3.907-3.907 3.907-10.242 0-14.149-3.907-3.907-10.242-3.907-14.149 0L6.102 23.31c-5.47 5.47-5.47 14.339 0 19.809 5.47 5.47 14.339 5.47 19.809 0l18.38-18.38a2 2 0 1 0-2.829-2.829z"
      />
    </svg>
  ),
  fileTemplateInactive: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="#6B7A99"
    >
      <path
        id="path84"
        d="m 42,39.9999 c 0,1.1045 -0.8954,2 -2,2 -1.1046,0 -2,-0.8955 -2,-2 0,-1.1046 0.8954,-2 2,-2 1.1046,0 2,0.8954 2,2 z m -32,0 c 0,1.1045 -0.89543,2 -2,2 -1.10457,0 -2,-0.8955 -2,-2 0,-1.1046 0.89543,-2 2,-2 1.10457,0 2,0.8954 2,2 z m 22,-12 c 0,-1.1046 0.8954,-2 2,-2 h 4 c 1.1046,0 2,0.8954 2,2 0,1.1045 -0.8954,2 -2,2 h -4 c -1.1046,0 -2,-0.8955 -2,-2 z m 0,-6 c 0,-1.1046 0.8954,-2 2,-2 h 4 c 1.1046,0 2,0.8954 2,2 0,1.1045 -0.8954,2 -2,2 h -2 v 10 c 0,1.1045 -0.8954,2 -2,2 -1.1046,0 -2,-0.8955 -2,-2 z m -24,0 c 0,-1.1046 0.89543,-2 2,-2 h 4 c 2.2086,0 4,1.7914 4,4 v 4 c 0,2.2085 -1.7914,4 -4,4 h -2 v 2 c 0,1.1045 -0.8954,2 -2,2 -1.10457,0 -2,-0.8955 -2,-2 z m 4,6 h 2 v -4 h -2 z m 8,-6 c 0,-1.1046 0.8954,-2 2,-2 h 4 c 2.2086,0 4,1.7914 4,4 v 8 c 0,2.2085 -1.7914,4 -4,4 h -4 c -1.1046,0 -2,-0.8955 -2,-2 z m 4,2 v 8 h 2 v -8 z M 6,7.99988 c 0,-3.31371 2.68629,-6 6,-6 h 16 c 0.5304,0 1.0391,0.21071 1.4142,0.58578 l 12,12.00004 c 0.7811,0.781 0.7811,2.0473 0,2.8284 -0.781,0.781 -2.0474,0.781 -2.8284,0 L 27.1716,5.99988 H 12 c -1.1046,0 -2,0.89543 -2,2 v 8.00002 c 0,1.1045 -0.89543,2 -2,2 -1.10457,0 -2,-0.8955 -2,-2 z m 4,32.00002 c 0,1.1045 0.8954,2 2,2 h 24 c 1.1046,0 2,-0.8955 2,-2 h 4 c 0,3.3137 -2.6863,6 -6,6 H 12 c -3.31371,0 -6,-2.6863 -6,-6 z M 28,1.99988 c 1.1046,0 2,0.89543 2,2 V 13.9999 h 10 c 1.1046,0 2,0.8954 2,2 0,1.1045 -0.8954,2 -2,2 H 28 c -1.1046,0 -2,-0.8955 -2,-2 V 3.99988 c 0,-1.10457 0.8954,-2 2,-2 z"
        fill="#6B7A99"
      />
    </svg>
  ),
  layoutInactive: (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
      <path
        fill="#6B7A99"
        fillRule="evenodd"
        d="M10 44a6 6 0 0 1-6-6V10a6 6 0 0 1 6-6h28a6 6 0 0 1 6 6v28a6 6 0 0 1-6 6H10zm6-24H8v18a2 2 0 0 0 2 2h6V20zm24 0H20v20h18a2 2 0 0 0 2-2V20zM38 8H10a2 2 0 0 0-2 2v6h32v-6a2 2 0 0 0-2-2z"
      />
    </svg>
  ),
  operativeReportInactive: (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
      <path
        fill="#6B7A99"
        fillRule="evenodd"
        d="M33.192 3.08a6 6 0 1 1 8.486 8.485L26.12 27.121a2 2 0 0 1-2.828 0l-1.414-1.414-1.535 1.535.122.167c3.128 4.554 2.76 10.786-1.103 14.945l-.313.324c-4.686 4.686-12.284 4.686-16.97 0a2 2 0 0 1-.156-2.654l.156-.175 16.97-16.97-1.414-1.415a2 2 0 0 1 0-2.828zM17.456 30.13L6.501 41.084l.28.159a8.005 8.005 0 0 0 9.152-1.12l.289-.275a8.004 8.004 0 0 0 1.394-9.44l-.16-.28zM36.021 5.907L21.879 20.05l2.828 2.829L38.85 8.737a2 2 0 1 0-2.828-2.829z"
      />
    </svg>
  ),
  preferencesInactive: (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
      <path
        fill="#6B7A99"
        fillRule="evenodd"
        d="M14 26a2 2 0 1 1 0 4h-4v12a2 2 0 0 1-1.85 1.995L8 44a2 2 0 0 1-2-2V30H2a2 2 0 0 1-1.995-1.85L0 28a2 2 0 0 1 2-2zm10-4a2 2 0 0 1 2 2v18a2 2 0 1 1-4 0V24a2 2 0 0 1 2-2zm22 8a2 2 0 1 1 0 4h-4v8a2 2 0 0 1-1.85 1.995L40 44a2 2 0 0 1-2-2v-8h-4a2 2 0 0 1-1.995-1.85L32 32a2 2 0 0 1 2-2zM40 4a2 2 0 0 1 2 2v18a2 2 0 1 1-4 0V6a2 2 0 0 1 2-2zM8 4a2 2 0 0 1 2 2v14a2 2 0 1 1-4 0V6a2 2 0 0 1 2-2zm16 0a2 2 0 0 1 2 2v8h4a2 2 0 0 1 1.995 1.85L32 16a2 2 0 0 1-2 2H18a2 2 0 1 1 0-4h4V6a2 2 0 0 1 1.85-1.995z"
      />
    </svg>
  ),
  exportInactive: (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 20 20">
      <path
        fill="#6B7A99"
        fillRule="evenodd"
        d="M19 12C19.5523 12 20 12.4477 20 13V17C20 18.6569 18.6569 20 17 20H3C1.34315 20 0 18.6569 0 17V13C0 12.4477 0.447715 12 1 12C1.55228 12 2 12.4477 2 13V17C2 17.5523 2.44772 18 3 18H17C17.5523 18 18 17.5523 18 17V13C18 12.4477 18.4477 12 19 12ZM10.0193 0.000183344C10.0427 0.000634934 10.066 0.0018949 10.0893 0.00396633C10.1097 0.00574788 10.1294 0.00810087 10.149 0.0110192C10.1659 0.013628 10.1834 0.0167022 10.2007 0.0202401C10.2227 0.0246398 10.2444 0.0298337 10.2658 0.0357211C10.2815 0.0400978 10.2969 0.0447288 10.3121 0.0497382C10.3318 0.0561226 10.3516 0.0633158 10.3711 0.071104L10.4232 0.0936735C10.4439 0.103377 10.4643 0.113794 10.4843 0.124876C10.4964 0.131499 10.5086 0.138607 10.5207 0.145995C10.5463 0.161719 10.5711 0.17849 10.5952 0.196314L10.629 0.224L10.6211 0.216226C10.6226 0.217376 10.624 0.21853 10.6254 0.219689L10.64 0.232L10.6525 0.242205L10.7071 0.292893L15.7071 5.29289C16.0976 5.68342 16.0976 6.31658 15.7071 6.70711C15.3466 7.06759 14.7794 7.09532 14.3871 6.7903L14.2929 6.70711L11 3.414V13C11 13.5128 10.614 13.9355 10.1166 13.9933L10 14C9.48716 14 9.06449 13.614 9.00673 13.1166L9 13V3.414L5.70711 6.70711C5.34662 7.06759 4.77939 7.09532 4.3871 6.7903L4.29289 6.70711C3.93241 6.34662 3.90468 5.77939 4.2097 5.3871L4.29289 5.29289L9.29289 0.292893L9.37455 0.219689L9.37889 0.216226C9.39016 0.207285 9.3974 0.201753 9.40469 0.196335C9.42887 0.17849 9.45372 0.161719 9.47934 0.146067L9.51594 0.124671C9.53571 0.113794 9.55611 0.103377 9.5769 0.0936537L9.62866 0.0712254C9.64842 0.0633157 9.6682 0.0561225 9.68826 0.0495467C9.70315 0.0447288 9.71854 0.0400976 9.73401 0.035845C9.75556 0.0298335 9.77725 0.0246399 9.79921 0.0201652C9.81662 0.0167022 9.83405 0.0136281 9.85153 0.0110178L9.918 0.00300002L10 0L10.0193 0.000183344Z"
      />
    </svg>
  ),
  qcmInactive: (
    <svg xmlns="http://www.w3.org/2000/svg" width="88" height="72">
      <g fill="none" fillRule="evenodd">
        <rect width="87" height="71" x="0.5" y="0.5" stroke="#CED8EB" rx="4" />
        <g fill="#CED8EB" transform="translate(8 48)">
          <rect width="48" height="8" x="24" y="4" rx="2" />
          <path d="M12 0a4 4 0 014 4v8a4 4 0 01-4 4H4a4 4 0 01-4-4V4a4 4 0 014-4h8zm0 2H4a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2V4a2 2 0 00-2-2z" />
        </g>
        <g transform="translate(8 28)">
          <rect width="48" height="8" x="24" y="4" fill="#6B7A99" rx="2" />
          <rect width="16" height="16" fill="#6B7A99" rx="4" />
          <rect width="6" height="6" x="5" y="5" fill="#FFF" rx="1" />
        </g>
        <g transform="translate(8 8)">
          <rect width="48" height="8" x="24" y="4" fill="#6B7A99" rx="2" />
          <rect width="16" height="16" fill="#6B7A99" rx="4" />
          <rect width="6" height="6" x="5" y="5" fill="#FFF" rx="1" />
        </g>
      </g>
    </svg>
  ),
  qcuInactive: (
    <svg xmlns="http://www.w3.org/2000/svg" width="88" height="72">
      <g fill="none" fillRule="evenodd">
        <rect width="87" height="71" x="0.5" y="0.5" stroke="#CED8EB" rx="4" />
        <g fill="#CED8EB" transform="translate(8 48)">
          <rect width="48" height="8" x="24" y="4" rx="2" />
          <path d="M8 0a8 8 0 110 16A8 8 0 018 0zm0 2a6 6 0 100 12A6 6 0 008 2z" />
        </g>
        <g fill="#CED8EB" transform="translate(8 28)">
          <rect width="48" height="8" x="24" y="4" rx="2" />
          <path d="M8 0a8 8 0 110 16A8 8 0 018 0zm0 2a6 6 0 100 12A6 6 0 008 2z" />
        </g>
        <g transform="translate(8 8)">
          <rect width="48" height="8" x="24" y="4" fill="#6B7A99" rx="2" />
          <rect width="16" height="16" fill="#6B7A99" rx="8" />
          <rect width="6" height="6" x="5" y="5" fill="#FFF" rx="3" />
        </g>
      </g>
    </svg>
  ),
  sliderInactive: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="88"
      height="72"
    >
      <defs>
        <rect id="sliderInactive-b" width="24" height="24" x="24" rx="12" />
        <filter
          id="sliderInactive-a"
          width="216.7%"
          height="216.7%"
          x="-58.3%"
          y="-41.7%"
          filterUnits="objectBoundingBox"
        >
          <feOffset dy="4" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="4" />
          <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd">
        <rect width="87" height="71" x="0.5" y="0.5" stroke="#CED8EB" rx="4" />
        <g transform="translate(8 24)">
          <rect width="36" height="8" x="36" y="8" fill="#CED8EB" rx="4" />
          <rect width="36" height="8" y="8" fill="#6B7A99" rx="4" />
          <use fill="#000" filter="url(#sliderInactive-a)" xlinkHref="#sliderInactive-b" />
          <use fill="#FFF" xlinkHref="#sliderInactive-b" />
        </g>
      </g>
    </svg>
  ),
  surveyInactive: (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
      <path
        fill="#6B7A99"
        fillRule="evenodd"
        d="M14.828 36l-7.414 7.414C6.154 44.674 4 43.782 4 42V10a6 6 0 0 1 6-6h28a6 6 0 0 1 6 6v20a6 6 0 0 1-6 6H14.828zm-2.242-3.414A2 2 0 0 1 14 32h24a2 2 0 0 0 2-2V10a2 2 0 0 0-2-2H10a2 2 0 0 0-2 2v27.172l4.586-4.586z"
      />
    </svg>
  ),
  tagInactive: (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
      <path
        fill="#6B7A99"
        fillRule="evenodd"
        d="M26 4a2 2 0 0 1 1.414.586L44.598 21.77a6.001 6.001 0 0 1-.004 8.464l-14.339 14.34a6 6 0 0 1-8.488.001l-17.18-17.16A2 2 0 0 1 4 26V6a2 2 0 0 1 2-2zm-.83 4H8v17.172l16.595 16.575a2 2 0 0 0 1.152.569l.263.017a2 2 0 0 0 1.416-.587L41.762 27.41c.775-.78.775-2.04.004-2.816L25.17 8zM16 14a2 2 0 1 1 0 4 2 2 0 0 1 0-4z"
      />
    </svg>
  ),
  textInactive: (
    <svg xmlns="http://www.w3.org/2000/svg" width="88" height="72">
      <g fill="none" fillRule="evenodd">
        <rect width="87" height="71" x="0.5" y="0.5" stroke="#CED8EB" rx="4" />
        <g transform="translate(8 8)">
          <path
            fill="#6B7A99"
            d="M46 48a2 2 0 012 2v4a2 2 0 01-2 2H2a2 2 0 01-2-2v-4a2 2 0 012-2h44zm24-16a2 2 0 012 2v4a2 2 0 01-2 2H2a2 2 0 01-2-2v-4a2 2 0 012-2h68zm-8-16a2 2 0 012 2v4a2 2 0 01-2 2H2a2 2 0 01-2-2v-4a2 2 0 012-2h60z"
          />
          <rect width="32" height="8" fill="#CED8EB" rx="2" />
        </g>
      </g>
    </svg>
  ),
}

export type ColoredIconType = keyof typeof ColoredIconLibrary

export const ColoredIcon: FunctionComponent<{ icon: ColoredIconType }> = ({ icon }) => {
  return ColoredIconLibrary[icon]
}
