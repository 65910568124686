import { RefObject, useCallback, useEffect } from 'react'

export const useClickOutside = (
  dropdownRef: RefObject<HTMLElement>,
  isDropOpen: boolean,
  closeOnSelect: boolean,
  onClickOutside?: () => void,
) => {
  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (!onClickOutside) return

      const element = event.target as HTMLElement
      if (closeOnSelect || !dropdownRef.current?.contains(element)) {
        onClickOutside()
      }
    },
    [closeOnSelect, dropdownRef, onClickOutside],
  )

  /* On utilise un setTimeout 0 pour tirer parti du fait que le timeout sera traité plus tard dans l'event loop
      Ainsi, on est sûr que l'événement qui a fait passer "isDropOpen" à true est terminé lorsqu'on ajoute notre event listener  */
  useEffect(() => {
    if (onClickOutside && isDropOpen) {
      setTimeout(() => {
        document.body.addEventListener('click', handleClickOutside)
      }, 0)
    }
    return () => {
      document.body.removeEventListener('click', handleClickOutside)
    }
  }, [isDropOpen, handleClickOutside, onClickOutside])
}
