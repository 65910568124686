import getApi from '../../../../api'
import { PrescriptionUpdate } from '../../../../model/Prescription'

export const switchToVMP = (prescriptionId: string) =>
  getApi().put(`/prescriptions/${prescriptionId}/convert_to_vmp`)

export const getPrescription = (prescriptionId: string) =>
  getApi().get(`/prescriptions/${prescriptionId}`)

export const updatePrescription = (
  prescriptionId: string,
  prescriptionUpdate: Partial<PrescriptionUpdate>,
) => getApi().put(`/prescriptions/${prescriptionId}`, prescriptionUpdate)
