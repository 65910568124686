import { connect } from 'react-redux'

import { RootState } from '../../../store'

import { setBottomPanelOptions } from '../../../store/ui/bottomPanel'

import { EditTagPanelStoreProps, EditTagPanelDispatchProps } from './EditTagPanel.model'

import EditTagPanel from './EditTagPanel.component'
import { currentUserSelector } from '../../../store/domain/me'

const mapStateToProps = (state: RootState): EditTagPanelStoreProps => ({
  currentUser: currentUserSelector(state),
})
const mapDispatchToProps: EditTagPanelDispatchProps = {
  setBottomPanelOptions,
}

export default connect(mapStateToProps, mapDispatchToProps)(EditTagPanel)
