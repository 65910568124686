export type ValuesOf<T extends ReadonlyArray<unknown>> = T[number]

export type NotNull<T> = T extends null ? never : T

export const isNumberGuard = (value: unknown): value is number => typeof value === 'number'

export const isDefinedGuard = <T>(value: T | undefined): value is T => typeof value !== 'undefined'

export interface TestProps {
  testId?: string
}
