import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { BlockAlignment } from 'core/model/TextEditor.model'

export const layoutColor = '#7ab7ff'

export const ImageContainer = styled.div<{ isFocused: boolean; align?: BlockAlignment }>`
  max-width: 100%;
  display: block;
  box-sizing: border-box;
  width: fit-content;
  position: relative;
  border: 2px dotted;
  border-color: ${({ isFocused }) => (isFocused ? '#7ab7ff' : 'transparent')};
  ${({ align }) => {
    if (align === 'left') {
      return css`
        margin: 0;
      `
    }
    if (align === 'right') {
      return css`
        margin: 0 0 0 auto;
      `
    }
    return css`
      margin: 0px auto;
    `
  }}
`
export const BackgroundImage = styled.div<{ url: string; selected: boolean }>`
  height: 100%;
  width: 100%;
  border: ${({ selected }) => (selected ? `2px dotted ${layoutColor}` : '2px dotted transparent')};
  background: url('${({ url }) => url}') center / contain no-repeat;
  cursor: pointer;
`
