import styled from '@emotion/styled'

const fontsize = '20px'
const colorPrimary = '#458FE6'
const colorPrimaryLight = '#61A4F2'
const colorPrimaryWashed = '#D9EBFF'
const colorBackground = 'rgba(18, 42, 89, 0.8)'

export const ResizeWindow = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const NumericResizeContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, auto);
  grid-template-rows: repeat(2, auto);
  align-items: center;
  gap: 3px;

  padding: 10px;
  border-radius: 5px;
  min-width: 120px;

  background: ${colorBackground};
  color: white;
  font-weight: 700;
`

export const InputStyled = styled.input`
  font-weight: 500;
  padding: 2px;
  border-radius: 5px;
  width: 70px;
  border: none;
  font-size: 20px;
  background-color: transparent;
  color: white;
`

export const InputContainer = styled.div`
  font-size: ${fontsize};
  color: white;
  border-bottom: solid 1px white;
`

export const ButtonStyled = styled.button`
  margin-top: 5px;
  border: none;
  font-style: Montserrat;
  border-radius: 20px;
  padding: 8px;
  height: 40px;
  font-weight: 600;
  color: #fff;
  font-size: 16px;
  background-color: ${colorPrimary};
  cursor: pointer;
  &:hover {
    background-color: ${colorPrimaryLight};
  }
  &:disabled {
    background-color: ${colorPrimaryWashed};
  }
`

export const SpanStyled = styled.span`
  margin-right: 5px;
`
