import { RestuxResources } from '../../resources'
import { RestuxPagination, RestuxUiSagaConf, uiActionsFactory } from '../../restux/ui'
import { mailContactsPaginationSelector } from './mailContacts.selectors'

export const mailContactsConfig: RestuxUiSagaConf = {
  resourceName: RestuxResources.mailContacts,
  identifier: 'mailContacts',
  paginationSelector: mailContactsPaginationSelector,
}

export interface MailContactsState {
  pagination: RestuxPagination
}

export const uiActions = uiActionsFactory(mailContactsConfig)

export const mailContactsUiActionTypes = {
  ...uiActions.types,
}
