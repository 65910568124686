import getApi from '../../../api/apisauceConfig'
import { UiQuoteline } from '../../../components/ccam/QuoteLineForm/QuoteLineForm.model'
import { QuoteLine } from '../../../model/Acte'
import { serializeQuoteLine } from './mapper/quoteLines.mapper'

export const getQuoteLinePreview = (
  quoteLineId: string,
  quoteLinePayload: UiQuoteline,
  signal?: AbortSignal,
) =>
  getApi().put<QuoteLine>(
    `/quote_lines/${quoteLineId}?preview=true`,
    serializeQuoteLine(quoteLinePayload),
    { signal },
  )
