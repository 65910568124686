import { useMutation, useQueryClient } from '@tanstack/react-query'
import { updatePatientInformations } from '../../../data/patientInformations/updatePatientInformations'
import { PatientInformationsPayload, PatientInformations } from '../../../model/PatientInformations'
import { UserFriendlyError } from '../../../model/Error'
import { patientInformationsKeys } from './patientInformations.keys'

interface UpdatePatientInformationsParams {
  patientId: number
  patientInformationId: string
  patientInformationsPayload: PatientInformationsPayload
}

export const useUpdatePatientInformations = (triggerSubscription: boolean = true) => {
  const queryClient = useQueryClient()

  const mutate = useMutation<PatientInformations, Error, UpdatePatientInformationsParams>(
    triggerSubscription ? patientInformationsKeys.mutation : [],
    async ({ patientId, patientInformationId, patientInformationsPayload }) => {
      const { data, ok } = await updatePatientInformations(
        patientId.toString(),
        patientInformationId,
        patientInformationsPayload,
      )

      if (!data || !ok) {
        throw new UserFriendlyError('error', "La mise à jour de l'allergie a échoué")
      }
      return data
    },
    {
      onSuccess: (_data, { patientId }) =>
        queryClient.invalidateQueries(patientInformationsKeys.list(patientId)),
    },
  )
  return mutate
}
