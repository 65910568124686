import { VariableType } from '@follow/farte'
import { base64StringToBlob } from 'blob-util'
import { createObjectURL } from 'pdfjs-dist'
import { FieldValue } from '../../../../model/Document'

export function pdfDataToUrl(pdfData) {
  return createObjectURL(pdfData, 'application/pdf')
}

export function base64ToBlobUrl(base64: string): string {
  const splitted = base64.split(',')

  return URL.createObjectURL(base64StringToBlob(splitted[1]))
}

export const blobToBase64 = async (blob: Blob) => {
  const reader = new FileReader()
  reader.readAsDataURL(blob)
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result)
    }
  })
}

export function mapFieldValues(
  fieldValues: FieldValue[],
  baseVariables: Record<string, VariableType>,
) {
  return fieldValues.map((fieldValue) => {
    if (fieldValue.type === 'variable' && typeof fieldValue.value === 'string') {
      const newValue = baseVariables[fieldValue.value]
      if (newValue && typeof newValue.value === 'string') {
        return { ...fieldValue, value: newValue.value }
      }
    }
    return fieldValue
  })
}
