import { connect } from 'react-redux'
import { RootState } from '../../../store'
import { enabledFeaturesPreferencesSelector } from '../../../store/domain/me'
import { filesDomainActions } from '../../../store/domain/files'
import { bottomPanelMailEditorActions } from '../../../store/ui/bottomPanelMailEditor'
import {
  displaySendFileModalSelector,
  inUseMedicalEventSelector,
  medicalEventUiActions,
} from '../../../store/ui/medicalEvents'
import { SendFileModal } from './SendFileModal.component'
import { SendFileModalDispatchProps, SendFileModalStoreProps } from './SendFileModal.model'
import { withUnmountTransition } from '../../../misc/withUnmountTransition.hoc'

const mapStateToProps = (state: RootState): SendFileModalStoreProps => ({
  display: displaySendFileModalSelector(state),
  currentMedicalEvent: inUseMedicalEventSelector(state),
  userEnabledFeatures: enabledFeaturesPreferencesSelector(state),
})

const mapDispatchToProps: SendFileModalDispatchProps = {
  displaySendFileModal: medicalEventUiActions.displaySendFileModal,
  openMailEditor: bottomPanelMailEditorActions.openEditor,
  sendFiles: filesDomainActions.send,
}

const withUnmountHoc = withUnmountTransition(SendFileModal)
const withConnect = connect(mapStateToProps, mapDispatchToProps)(withUnmountHoc)
export { withConnect as SendFileModal }
