import { put, takeEvery } from 'redux-saga/effects'

import { ClipboardActionTypes, ClipboardContentType } from './clipboard.model'
import { clipboardActions } from './clipboard.actions'

import { copyTextPlain, copyTextHtml } from '../../misc/clipboard.utilities'
import { addValid } from '../message'

function* copyContentToClipboardWorker({
  content,
  contentType,
}: ReturnType<typeof clipboardActions.copyContentToClipboard>) {
  switch (contentType) {
    case ClipboardContentType.TEXT_HTML:
      copyTextHtml(content)
      break
    case ClipboardContentType.TEXT_PLAIN:
      copyTextPlain(content)
      break
  }
  yield put(addValid('Le contenu a été copié dans le presse papier'))
}

function* copyContentToClipboardWatcher() {
  yield takeEvery([ClipboardActionTypes.COPY_CONTENT], copyContentToClipboardWorker)
}

export const clipboardSagas = {
  copyContentToClipboardWatcher,
}
