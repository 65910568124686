import { BottomPanelDrugsState, UiBottomPanelDrugsActionTypes } from './bottomPanelDrugs.model'
import { createReducer } from 'redux-starter-kit'
import { bottomPanelDrugsActions } from './bottomPanelDrugs.actions'

const INITIAL_STATE: BottomPanelDrugsState = {
  disabled: false,
  initialSearch: undefined,
}

const ACTION_HANDLERS = {
  [UiBottomPanelDrugsActionTypes.SEARCH_DRUG]: (
    state = INITIAL_STATE,
    action: ReturnType<typeof bottomPanelDrugsActions.searchDrug>,
  ) => ({
    ...state,
    ...action.state,
  }),
  [UiBottomPanelDrugsActionTypes.CLEAR_SEARCH_STATE]: (state = INITIAL_STATE) => INITIAL_STATE,
}

export const bottomPanelDrugReducer = createReducer(INITIAL_STATE, ACTION_HANDLERS)
