import { connect } from 'react-redux'
import { PatientPageDispatchProps } from './PatientPage.model'
import { PatientPage } from './PatientPage.component'
import { medicalEventUiActions } from '../../../store/ui/medicalEvents'

const mapDispatchToProps: PatientPageDispatchProps = {
  clearPatientTimeline: medicalEventUiActions.clearPagination,
}

const withConnect = connect(null, mapDispatchToProps)(PatientPage)
export { withConnect as PatientPage }
