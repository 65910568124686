import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useDocumentCategoryOptions } from '../../../hooks/utils'
import { DocumentCategoryKeys } from '../../../model/DocumentCategory'
import { SelectOption } from '../../../model/SelectOption'
import { CheckboxState, DatePicker, IconButton, RoundedButton, SelectInput } from '../../shared'
import { MailClassificationProps } from './MailClassification.model'
import { LightPatient, Sex } from '../../../model/Patient'
import { CustomFile } from '../../../model/File'
import { MailClassificationAttachmentItem } from './MailClassificationAttachmentItem'
import { PatientDropDownItemSelector } from '../../patient'
import { MailPatientInfo } from './MailPatientInfo'
import { MailPatientDetection } from './MailPatientDetection'
import { MailClassificationAttachment, MailPatient } from '../../../model/Mail'
import { formatFr } from '../../../misc/date.utilities'
import { InsiConsultActions } from '../../../model/Insi'
import { CreatePatientThroughMailModal } from '../../insi'
import { MailClassificationModal } from './MailClassificationModal'
import {
  mapMailAttachmentsToSelectedAttachments,
  mapMailAttachmentToSelectedAttachment,
} from './MailClassification.utilities'

export const MailClassification: FC<MailClassificationProps> = ({
  documentCategories,
  classifyDocuments,
  inUseMail,
  createPatient,
  updatePatient,
  userEnabledFeatures,
  setInsiConsultRequest,
  clearInsiPatientResponse,
  insiStatus,
}) => {
  const categoriesOptions = useDocumentCategoryOptions(documentCategories)
  const defaultCategory = useMemo(
    () =>
      categoriesOptions.find(
        (option: SelectOption<DocumentCategoryKeys>) =>
          option.value === DocumentCategoryKeys.ReceivedDocument,
      ),
    [categoriesOptions],
  )

  const [documentCategory, setDocumentCategory] = useState<
    SelectOption<DocumentCategoryKeys> | undefined
  >(defaultCategory) // Catégorie "Documents reçus" par défaut
  const [date, setDate] = useState(new Date())
  const [selectedPatient, setSelectedPatient] = useState<LightPatient | MailPatient | undefined>()
  const [selectedAttachments, setSelectedAttachments] = useState<MailClassificationAttachment[]>([])
  const [showClassificationModal, setShowClassificationModal] = useState<boolean>(false)

  const setDefaultFormValues = useCallback(() => {
    setDate(new Date(inUseMail.date.date))
    setSelectedAttachments(mapMailAttachmentsToSelectedAttachments(inUseMail.attachments))
    setDocumentCategory(defaultCategory)
    setSelectedPatient(undefined)
    setSelectedPatient(inUseMail.patient?.id ? inUseMail.patient : undefined)
  }, [inUseMail, defaultCategory])

  useEffect(() => {
    setDefaultFormValues()
  }, [inUseMail, setDefaultFormValues])

  useEffect(() => {
    return () => {
      clearInsiPatientResponse()
    }
  }, [clearInsiPatientResponse])

  const handleClassify = useCallback(
    (selectedAttachments: MailClassificationAttachment[]) => {
      if (
        documentCategory &&
        selectedPatient &&
        date &&
        selectedAttachments.length > 0 &&
        selectedPatient.id
      ) {
        classifyDocuments(selectedAttachments, selectedPatient.id, documentCategory.value, date)
        setShowClassificationModal(false)
        setDefaultFormValues()
      }
    },
    [classifyDocuments, date, documentCategory, selectedPatient, setDefaultFormValues],
  )

  const handleSelectFile = (selectedItem: CustomFile) => {
    const selection: MailClassificationAttachment[] = selectedAttachments.some(
      (item: MailClassificationAttachment) => item.id === selectedItem.id,
    )
      ? [
          ...selectedAttachments.filter(
            (item: MailClassificationAttachment) => item.id !== selectedItem.id,
          ),
        ]
      : [...selectedAttachments, mapMailAttachmentToSelectedAttachment(selectedItem)]

    setSelectedAttachments(selection)
  }

  const checkInsiValidity = useCallback(
    (patient: MailPatient, create = true) => {
      setInsiConsultRequest(
        {
          exportId: create
            ? patient.id
              ? InsiConsultActions.MSS_UPDATE
              : InsiConsultActions.MSS_CREATION
            : InsiConsultActions.MSS_CHECK,
          search: {
            name: patient.birthLastName,
            given: patient.birthFirstName,
            birthday: formatFr(new Date(patient.birthDate), 'dd/MM/yyyy'),
            sex: patient.sex === Sex.MALE ? '2' : '3',
            birthplace: patient.birthPlaceCode,
          },
        },
        patient,
      )
    },
    [setInsiConsultRequest],
  )

  const handlePatientCreation = useCallback(() => {
    if (!inUseMail.patient || !userEnabledFeatures?.mssExtractPatient) return

    if (userEnabledFeatures?.insiValidation === 'insiValidationRest') {
      createPatient(inUseMail.patient)
    } else if (insiStatus) {
      const patient = inUseMail.patient
      patient.identityStatus.insiStatus.checked = insiStatus === 'FOUND' ? 'VALIDATED' : 'FAILED'
      createPatient(patient)
    } else if (userEnabledFeatures?.insiValidation === 'insiValidationInApp') {
      checkInsiValidity(inUseMail.patient)
    } else {
      createPatient(inUseMail.patient)
    }
  }, [inUseMail.patient, createPatient, userEnabledFeatures, checkInsiValidity, insiStatus])

  const handlePatientUpdate = useCallback(() => {
    if (!inUseMail.patient?.id || !userEnabledFeatures?.mssExtractPatient) return

    if (userEnabledFeatures?.insiValidation === 'insiValidationRest') {
      updatePatient(inUseMail.patient.id, inUseMail.patient, true, true)
    } else if (insiStatus) {
      const patient = inUseMail.patient
      patient.identityStatus.insiStatus.checked = insiStatus === 'FOUND' ? 'VALIDATED' : 'FAILED'
      updatePatient(inUseMail.patient.id, patient)
    } else if (userEnabledFeatures?.insiValidation === 'insiValidationInApp') {
      checkInsiValidity(inUseMail.patient)
    } else {
      updatePatient(inUseMail.patient.id, inUseMail.patient)
    }
  }, [inUseMail.patient, updatePatient, checkInsiValidity, userEnabledFeatures, insiStatus])

  const handlePatientCheck = useCallback(() => {
    if (inUseMail.patient && userEnabledFeatures?.insiValidation === 'insiValidationInApp') {
      checkInsiValidity(inUseMail.patient, false)
    }
  }, [inUseMail.patient, checkInsiValidity, userEnabledFeatures])

  useEffect(() => {
    if (!insiStatus && userEnabledFeatures?.mssAutoInsiFetch) {
      handlePatientCheck()
    }
  }, [insiStatus, handlePatientCheck, userEnabledFeatures])

  return (
    <div className="p-5 bg-white w-full h-full space-y-4">
      {inUseMail.patient && userEnabledFeatures?.mssExtractPatient && (
        <div className="mb-5">
          <div className="mb-5">
            <MailPatientDetection
              patient={inUseMail.patient}
              onUpdate={handlePatientUpdate}
              onCreate={handlePatientCreation}
            />
          </div>
          <MailPatientInfo patient={inUseMail.patient} />
        </div>
      )}
      <span className="text-shades-3 text-base font-medium">CLASSER LE(S) DOCUMENT(S)</span>
      <div className="mt-2">
        {inUseMail?.attachments.map((item) => (
          <div className="mt-2" key={item.id}>
            <MailClassificationAttachmentItem
              item={item}
              checked={
                selectedAttachments?.some(({ id }) => item.id === id)
                  ? CheckboxState.CHECKED
                  : CheckboxState.UNCHECKED
              }
              selectItem={handleSelectFile}
            />
          </div>
        ))}
      </div>
      <PatientDropDownItemSelector
        selectedPatient={selectedPatient}
        setSelectedPatient={setSelectedPatient}
      />
      <SelectInput
        title="Catégorie du document"
        value={documentCategory}
        icon={documentCategory?.icon ?? 'documentText'}
        options={categoriesOptions}
        onSelect={setDocumentCategory}
      />
      <DatePicker
        value={date}
        colorPreset="light"
        icon="calendar"
        showCalendarButton={false}
        label="Date"
        onChange={(value) => {
          if (value) {
            setDate(value)
          }
        }}
        inputType="input"
      />
      <div className="py-4">
        <div className="flex">
          <RoundedButton
            disabled={
              !selectedPatient || !documentCategory || !date || selectedAttachments.length === 0
            }
            label="Classer ce(s) document(s)"
            onClick={() => handleClassify(selectedAttachments)}
            appearance={userEnabledFeatures?.mssSmtp ? 'left' : 'standalone'}
          />
          {userEnabledFeatures?.mssSmtp && (
            <IconButton
              disabled={
                !selectedPatient || !documentCategory || !date || selectedAttachments.length === 0
              }
              icon="caret"
              rotate={180}
              appearance="right"
              theme="primary"
              showOptionsCaret={false}
              options={[
                {
                  label: 'Renommer et classer',
                  onClick: () => setShowClassificationModal(true),
                },
              ]}
            />
          )}
        </div>
      </div>
      <MailClassificationModal
        display={showClassificationModal}
        attachments={selectedAttachments}
        onClose={() => setShowClassificationModal(false)}
        onSubmit={(values: MailClassificationAttachment[]) => {
          setSelectedAttachments([...values])
          handleClassify(values)
        }}
        testId={`mail-classification-modal`}
      />
      <CreatePatientThroughMailModal />
    </div>
  )
}
