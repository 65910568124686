import { FC, useMemo } from 'react'
import {
  SeverityNames,
  SeverityShortNames,
  DocumentAlertsCategoryNames,
} from '../../../../model/DocumentAlerts'
import { AccordionItem, Tag } from '../../../shared'
import { AlertDetailBox } from './AlertDetailBox'
import { DocumentAlertItemProps, mapSeverityToTagColor } from './DocumentAlertItem.model'
import { sanitizeToBasicHtml, removeAllHtmlTags } from '../../../../misc/sanitizer.utilities'
import styles from './DocumentAlertItem.module.scss'

export const DocumentAlertItem: FC<DocumentAlertItemProps> = ({ alert, open }) => {
  const alertTitle = useMemo(() => removeAllHtmlTags(alert.content || '-'), [alert.content]) // Suppression tag et injecté en html pour les caractères spéciaux
  const alertContent = useMemo(() => sanitizeToBasicHtml(alert.content || '-'), [alert.content])
  const alertDetail = useMemo(() => sanitizeToBasicHtml(alert.detail || '-'), [alert.detail])

  return (
    <AccordionItem
      open={open}
      theme="simple"
      displayToggleButton
      renderLabel={() => (
        <div className="max-w-full flex items-center text-sm mr-2">
          <div className="shrink-0 mr-2">
            <Tag color={mapSeverityToTagColor[alert.severityLevel]}>
              {SeverityShortNames[alert.severityLevel]}
            </Tag>
          </div>
          <span className="truncate mr-2">
            {alert.title}. <span dangerouslySetInnerHTML={{ __html: alertTitle }} />
          </span>
        </div>
      )}
    >
      {open && (
        <div className="grid grid-cols-2 gap-4 items-start mx-6">
          <AlertDetailBox title="Catégorie">
            {DocumentAlertsCategoryNames[alert.category] ?? alert.category}
          </AlertDetailBox>
          <AlertDetailBox title="Sévérité">
            <Tag color={mapSeverityToTagColor[alert.severityLevel]}>
              {SeverityNames[alert.severityLevel]}
            </Tag>
          </AlertDetailBox>
          <AlertDetailBox title="Type">{alert.typeDisplayName}</AlertDetailBox>
          <AlertDetailBox title="Sous-type">{alert.subTypeDisplayName || '-'}</AlertDetailBox>
          <AlertDetailBox title="Titre" large>
            {alert.title}
          </AlertDetailBox>
          <AlertDetailBox title="Contributeur">{alert.contributor || '-'}</AlertDetailBox>
          <AlertDetailBox title="Source">{alert.source || '-'}</AlertDetailBox>
          <AlertDetailBox title="Contenu" large htmlContent={alertContent}>
            <span
              className={styles.underlineAchildren}
              dangerouslySetInnerHTML={{ __html: alertContent }}
            />
          </AlertDetailBox>
          <AlertDetailBox title="Détail" large>
            <span dangerouslySetInnerHTML={{ __html: alertDetail }} />
          </AlertDetailBox>
        </div>
      )}
    </AccordionItem>
  )
}
