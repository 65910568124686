import React, { FunctionComponent } from 'react'
import classNames from 'classnames/bind'

import styles from './AddContentButton.module.scss'
import { IconButton } from '../IconButton'
import { RoundedButton } from '../RoundedButton'
import { AddContentButtonProps } from './AddContentButton.model'

const cx = classNames.bind(styles)

export const AddContentButton: FunctionComponent<AddContentButtonProps> = ({
  label,
  isSmallScreen,
  actLikeAChip = false,
  testId = 'button-add-content',
  onClick,
}) => {
  return (
    <div>
      {isSmallScreen ? (
        <IconButton testId={testId} icon="add" theme="primary" onClick={onClick} />
      ) : (
        <div className={cx({ actLikeAChip })}>
          <RoundedButton
            testId={testId}
            onClick={onClick}
            icon="add"
            theme="primary"
            label={label}
          />
        </div>
      )}
    </div>
  )
}
