import React, { FunctionComponent, useMemo } from 'react'
import { DoctorsBlockProps } from './DoctorsBlock.model'
import { MemberUserList } from '../../MemberUsersList'
import { EntityCard, EntityCardContent, FormBlock } from '../../../shared'

export const DoctorsBlock: FunctionComponent<DoctorsBlockProps> = ({ doctors }) => {
  const users = useMemo(
    () =>
      doctors
        ? doctors.map((item) => ({
            firstName: item.firstName,
            familyName: item.familyName,
            professionTitle: item.userType.professionTitle,
            isContact: false,
            id: item.id,
          }))
        : [],
    [doctors],
  )

  return (
    <FormBlock label="Médecins liés" icon="profile">
      <EntityCard loading={false} inactive={false}>
        <EntityCardContent>
          <MemberUserList users={users} />
        </EntityCardContent>
      </EntityCard>
    </FormBlock>
  )
}
