import React, { ChangeEvent, FunctionComponent, useCallback, useMemo, useState } from 'react'
import { DropdownItem, SearchInput } from '../../shared'
import { TeamInputProps } from './TeamInput.model'
import { Team } from '../../../model/Team'
import { SelectOption } from '../../../model/SelectOption'
import { useGetTeams } from '../../../hooks/queries/admin'
import { searchLimit } from '../../../constants'

const CREATE_TEAM_OPTION: SelectOption<null> = {
  value: null,
  label: 'Créer une équipe',
  icon: 'add',
}

export const TeamInput: FunctionComponent<TeamInputProps> = ({
  userId,
  onSelectItem,
  onCreateTeam,
}) => {
  const [inputValue, setInputValue] = useState<string>('')
  const { teamList, cancelPendingQuery } = useGetTeams({
    filters: { search: inputValue },
    limit: searchLimit,
    enabled: inputValue.length > 0,
  })

  const teamOptions: SelectOption<Team | null>[] = useMemo(() => {
    const options = teamList
      .filter((teamItem) => !teamItem.memberUsers.find((memberItem) => memberItem.id === userId))
      .map<SelectOption<Team>>((team) => ({
        value: team,
        label: team.name,
        icon: 'team',
      }))

    return [...options, CREATE_TEAM_OPTION]
  }, [teamList, userId])

  const handleSelect = useCallback(
    ({ value }: SelectOption<Team | null>, blurInput: () => void) => {
      if (onSelectItem) {
        if (value === CREATE_TEAM_OPTION.value) {
          onCreateTeam()
        } else {
          onSelectItem(value)
        }
        setInputValue('')
      }
      blurInput()
    },
    [onCreateTeam, onSelectItem],
  )

  const handleSearchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.currentTarget.value)
      cancelPendingQuery()
    },
    [cancelPendingQuery],
  )

  return (
    <SearchInput
      icon="search"
      placeholder="Rechercher une équipe"
      value={inputValue}
      onChange={handleSearchChange}
      onSelect={handleSelect}
      results={teamOptions}
      renderResult={({ label, value, icon }, isHovered) => (
        <DropdownItem icon={icon} selected={isHovered}>
          <div className="max-w-full whitespace-normal">{label}</div>
        </DropdownItem>
      )}
    />
  )
}
