import React, { FunctionComponent, useEffect, useMemo, useState } from 'react'

import { BottomPanelComponentType } from '../../../store/ui/bottomPanel'

import { TagBackgroundColors } from '../../../colors'
import {
  Button,
  GridLayout,
  Input,
  Radio,
  RadioGroup,
  TagColorList,
  TextArea,
} from '../../../components/shared'

import { EditTagPanelProps } from './EditTagPanel.model'
import styles from './EditTagPanel.module.scss'
import RoleRestriction from '../../RoleRestriction'
import { ADMIN_ROLES, Roles } from '../../../model/Roles'
import { hasAdminRole, hasExtDsmRole } from '../../../misc/roles.utilities'
import { useGetTags, useCreateTag, useUpdateTag } from '../../../hooks/queries/tag'
import { useAtom } from 'jotai'
import { editTagPanelAtom } from '../../../state/tag'
import { RESET } from 'jotai/utils'

const VISIBILITY_PUBLIC = 'public'
const VISIBILITY_PRIVATE = 'private'

const EditTagPanel: FunctionComponent<EditTagPanelProps> = ({
  actions: Actions,
  currentUser,
  setBottomPanelOptions,
}) => {
  const [isPrivate, setIsPrivate] = useState<boolean>(
    currentUser ? !(hasAdminRole(currentUser?.roles) || hasExtDsmRole(currentUser.roles)) : false,
  )
  const [label, setLabel] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [readOnly, setReadOnly] = useState(false)
  const [hasBeenEdited, setHasBeenEdited] = useState(false)
  const [color, setColor] = useState<TagBackgroundColors>(TagBackgroundColors.dsTag00)
  const { tagList: tags } = useGetTags()
  const { mutate: createTag } = useCreateTag()
  const { mutate: updateTag } = useUpdateTag()
  const [editTagPanel, setEditTagPanel] = useAtom(editTagPanelAtom)
  const inUseTag = editTagPanel.selectedTag
  const isValidLabel = useMemo(() => label.length > 0, [label])
  const isAvailableLabel = useMemo(
    () =>
      isValidLabel &&
      ((inUseTag && inUseTag.label === label) ||
        !tags.some((tag) => tag.label.toLowerCase() === label.toLowerCase())),
    [isValidLabel, label, tags, inUseTag],
  )
  useEffect(() => {
    if (inUseTag) {
      setLabel(inUseTag.label)
      setDescription(inUseTag.description || '')
      setColor(inUseTag.color)
      setIsPrivate(inUseTag.private)
      setReadOnly(!inUseTag.isEditable)
    }
  }, [inUseTag])

  useEffect(() => {
    return () => {
      setEditTagPanel(RESET)
    }
  }, [setEditTagPanel])

  function handleOk() {
    const updates = {
      label,
      description,
      color,
      private: isPrivate,
    }
    if (inUseTag) {
      updateTag({
        tagId: inUseTag.id,
        tagPayload: updates,
      })
    } else {
      createTag(updates)
    }
    setBottomPanelOptions({
      open: false,
      componentType: BottomPanelComponentType.EditTag,
    })
  }

  const onChangeLabel = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!hasBeenEdited) {
      setHasBeenEdited(true)
    }
    setLabel(e.currentTarget.value)
  }

  return (
    <div className={styles.container}>
      {!readOnly && (
        <Actions>
          <Button label="OK" onClick={handleOk} theme="primary" disabled={!isAvailableLabel} />
        </Actions>
      )}
      <div className={styles.form}>
        <GridLayout columns={2} rowsTemplate="auto 1fr" width="100%" gap="medium" padding="medium">
          <div>
            <Input
              name="label"
              label="Titre du label"
              value={label}
              onChange={onChangeLabel}
              colorPreset="light"
              valid={isAvailableLabel}
              disabled={readOnly}
            />
            {hasBeenEdited && !isValidLabel && (
              <label className={styles.errorMessage}>Le nom du label ne peut pas être vide</label>
            )}
            {isValidLabel && !isAvailableLabel && (
              <label className={styles.errorMessage}>
                Ce nom est déja utilisé pour un autre label
              </label>
            )}
          </div>
          <TagColorList
            color={color}
            onChange={(newColor) => setColor(newColor)}
            disabled={readOnly}
          />
          <TextArea
            name="description"
            label="Description du label"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            colorPreset="dark"
            disabled={readOnly}
          />
          <RoleRestriction roles={[...ADMIN_ROLES, Roles.EXT_DSM]}>
            <div>
              <RadioGroup
                name="visbility"
                value={isPrivate ? VISIBILITY_PRIVATE : VISIBILITY_PUBLIC}
                onChange={(e) => setIsPrivate(e.currentTarget.value === VISIBILITY_PRIVATE)}
                disabled={true}
              >
                <Radio label="Public" value={VISIBILITY_PUBLIC} />
                <Radio label="Privé" value={VISIBILITY_PRIVATE} />
              </RadioGroup>
              <label className={styles.errorMessage}>
                Les administrateurs ne peuvent pas créer de labels privés
              </label>
            </div>
          </RoleRestriction>
        </GridLayout>
      </div>
    </div>
  )
}

export default EditTagPanel
