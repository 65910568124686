import React, { useState } from 'react'

import { useLocalStorage } from '../../../../hooks/utils'
import { AlertBanner } from '../AlertBanner'
import { IconButton } from '../../buttons'

interface MaintenanceConfig {
  activated: boolean
  title: string
  message: string
}
const DEFAULT_CONFIG: MaintenanceConfig = { activated: false, title: '', message: '' }

export const MaintenanceBanner = () => {
  const [maintenanceConfig] = useState<MaintenanceConfig>(DEFAULT_CONFIG)

  const [storedValue, setValue] = useLocalStorage('maintenanceBanner.closed', false)

  return !storedValue && maintenanceConfig && maintenanceConfig.activated ? (
    <AlertBanner
      alerts={[
        {
          code: 'alert_maintenance',
          title: maintenanceConfig.title,
          text: maintenanceConfig.message,
          actionButton: (
            <IconButton
              onClick={() => {
                setValue(true)
              }}
              icon="cross"
              size="nano"
              theme="transparent"
            />
          ),
          color: 'blue',
          icon: 'infoCircle',
        },
      ]}
    />
  ) : null
}
