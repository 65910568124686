import { connect } from 'react-redux'
import { RootState } from '../../../store'
import {
  bottomPanelPosologyPrescriptionSelector,
  bottomPanelPosologyModeSelector,
  bottomPanelPosologySelectedDrugSelector,
} from '../../../store/ui/bottomPanelPosology/bottomPanelPosology.selector'
import {
  PosologyBottomPanelDispatchProps,
  PosologyBottomStoreProps,
} from './PosologyBottomPanel.model'
import { PosologyBottomPanel } from './PosologyBottomPanel.component'
import { domainDrugsActions } from '../../../store/domain/drugs/drugs.actions'
import { drugDetailsSelectors } from '../../../store/domain/drugs/drugs.selectors'
import { setBottomPanelOptions } from '../../../store/ui/bottomPanel'
import { uiPatientCurrentTreatmentsActions } from '../../../store/ui/patientCurrentTreatments'
import { domainPrescriptionActions } from '../../../store/domain/prescription'
import { uiContactsActions, contactsPageSelector } from '../../../store/ui/contacts'

const mapStateToProps = (state: RootState): PosologyBottomStoreProps => ({
  selectedDrug: bottomPanelPosologySelectedDrugSelector(state),
  mode: bottomPanelPosologyModeSelector(state),
  prescription: bottomPanelPosologyPrescriptionSelector(state),
  drugDetails: drugDetailsSelectors(state),
  predictions: contactsPageSelector(state),
})

const mapDispatchToProps: PosologyBottomPanelDispatchProps = {
  getDrugDetails: domainDrugsActions.getDetails,
  clearDrugDetails: domainDrugsActions.clearDetails,
  createTreatment: uiPatientCurrentTreatmentsActions.createCurrentTreatment,
  updatePrescription: domainPrescriptionActions.updatePrescription,
  setBottomPanelOptions,
  searchContact: uiContactsActions.requestPage,
  clearPredictions: uiContactsActions.clearPagination,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)(PosologyBottomPanel)
export { withConnect as PosologyBottomPanel }
