import { Data } from 'slate'
import Html from 'slate-html-serializer'
import { isDefined } from '../../utils/value.utils'
import { VariableFixedValue } from '../model/TextEditor.model'
import { JSONToValue, valueToJSON } from '../TextEditor.utilities'

export const CUSTOM_HTML_ATTRIBUTES = {
  variableId: 'data-variableid',
  displayConfig: 'data-displayconfig',
  isFallback: 'data-isfallback',
  legacyVariableType: 'data-legacy-variabletype',
  legacyRenderer: 'data-legacy-renderer',
}

export function toStylesObject(style: string) {
  const styleProperties = style.split(';')

  let styleObj: any = {}
  for (let styleProp of styleProperties) {
    const [propKey, propValue] = styleProp.trim().split(':')
    if (propKey && propValue) {
      styleObj[propKey.trim()] = propValue.trim()
    }
  }
  return styleObj
}

export function toObjectStyling(style: object) {
  const entries = Object.entries(style)

  return entries.reduce((acc, [key, value]) => {
    const formatedKey = key
      .split('-')
      .map((item, index) => {
        if (index === 0 || !item) {
          return item
        }
        const first = item[0]?.toUpperCase() ?? ''
        const rest = item.substring(1)
        return `${first}${rest}`
      })
      .join('')

    const castedNumber = Number.parseInt(value)
    const propValue = Number.isNaN(castedNumber) ? value : castedNumber

    return {
      ...acc,
      [formatedKey]: propValue,
    }
  }, {})
}

export function serializeFixedValue(value: VariableFixedValue | null, simpleSerializer: Html) {
  if (!value || typeof value !== 'object') return null
  if (!isDefined(value.isFallback) || !isDefined(value.content)) return null

  const valueType = inferFixedValueType(value)
  if (!valueType) return null

  const props = {
    [CUSTOM_HTML_ATTRIBUTES.isFallback]: value.isFallback,
    [CUSTOM_HTML_ATTRIBUTES.legacyVariableType]: valueType,
  }

  switch (valueType) {
    case 'normal': {
      return {
        props,
        value: value.content,
        valueType,
      }
    }
    case 'render': {
      const parsed = JSON.parse(value.content)
      if (!isDefined(parsed?.html) || typeof parsed.html !== 'string') return null

      return {
        props: {
          ...props,
          [CUSTOM_HTML_ATTRIBUTES.legacyRenderer]: value.renderer,
        },
        value: parsed.html,
        valueType,
      }
    }
    case 'slate': {
      const parsed = JSON.parse(value.content)
      const serialized = simpleSerializer.serialize(JSONToValue(parsed))

      return {
        props,
        valueType,
        value: serialized,
      }
    }
  }

  return null
}

function inferFixedValueType(value: VariableFixedValue) {
  if (typeof value.content !== 'string') {
    return null
  }
  if (value.renderer) {
    return 'render'
  }
  try {
    // Si c'est une valeur slate, alors on peut la parser en tant que json stringifié
    JSON.parse(value.content)
    return 'slate'
  } catch (e) {}
  return 'normal'
}

export function deserializeFixedValue(node: Element, simpleSerializer: Html) {
  if (!node || typeof node !== 'object') return null
  const legacyType = node.getAttribute(CUSTOM_HTML_ATTRIBUTES.legacyVariableType)
  const isFallback = node.getAttribute(CUSTOM_HTML_ATTRIBUTES.isFallback) === 'true' ? true : false

  switch (legacyType) {
    case 'normal': {
      return {
        content: node.textContent,
        isFallback,
      }
    }
    case 'render': {
      const renderer = node.getAttribute(CUSTOM_HTML_ATTRIBUTES.legacyRenderer)
      const html = node.innerHTML
      return {
        content: JSON.stringify({ html }),
        isFallback,
        renderer: renderer,
      }
    }
    case 'slate': {
      const deserialized = simpleSerializer.deserialize(node.innerHTML)
      const jsonValue = valueToJSON(deserialized)
      return {
        content: JSON.stringify(jsonValue),
        isFallback,
      }
    }
  }

  return null
}

export const serializeStyle = (data?: Data): React.CSSProperties | undefined => {
  if (!data) return undefined

  return Object.entries(data.toObject()).reduce((acc, [key, value]) => {
    if (!value) return acc

    switch (key) {
      case 'fontSize':
        return { ...acc, fontSize: `${value}px` }
      case 'align':
        return { ...acc, textAlign: value }
      case 'fontFamily':
        return { ...acc, fontFamily: value }
      default:
        return acc
    }
  }, {})
}
