import { FunctionComponent } from 'react'
import { PartnerLinkProps } from './PartnerLink.model'

export const PartnerLink: FunctionComponent<PartnerLinkProps> = ({ title, link, description }) => (
  <div className="flex flex-col items-start text-left">
    <span>{title}</span>
    <a
      className="text-shades-4 no-underline hover:underline text-sm"
      href={link}
      target="_blank"
      rel="noreferrer"
    >
      {description}
    </a>
  </div>
)
