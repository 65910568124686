import { Preferences } from '../../../model/Preference'
import { User, UserAdmin, UserAssistant, UserDoctor } from '../../../model/User'

export interface MeState {
  user: User | null
}

type GenericMeUpdate<T> = Omit<Partial<T>, 'preferences'> & {
  preferences?: Partial<Preferences>
}
export type MeUpdate =
  | GenericMeUpdate<UserDoctor & { signatureFileId?: number | null }>
  | GenericMeUpdate<UserAssistant>
  | GenericMeUpdate<UserAdmin>

export enum MeActionTypes {
  GET_ME = '@ME/GET_ME',
  SET_ME = '@ME/SET_ME',
  UPDATE_ME = '@ME/UPDATE_ME',
  UPDATE_PASSWORD = '@ME/UPDATE_PASSWORD',
  UPDATE_PREFERENCES = '@ME/UPDATE_PREFERENCES',
  UPDATE_TRUSTED_CONTACT = '@ME/UPDATE_TRUSTED_CONTACT',
  FETCH_ALL_TEAMS = '@ME/FETCH_ALL_TEAMS',
}
