import { FunctionComponent, useCallback } from 'react'

import { PatientInfosContainer } from '../PatientInfosContainer'

import { PatientTagsProps } from './PatientTags.model'
import { TagSearchAndDisplay } from '../../..//shared'
import { useCreateTag, useGetTags } from '../../../../hooks/queries/tag'
import { useUpdatePatient } from '../../../../hooks/queries/patients'
import { useCurrentPatient } from '../../../../hooks/utils'

export const PatientTags: FunctionComponent<PatientTagsProps> = ({ tagIds, disabled = false }) => {
  const { currentPatient } = useCurrentPatient()
  const { tagList: tags } = useGetTags()
  const { mutate: createTag } = useCreateTag()
  const { mutate: updatePatient } = useUpdatePatient({ optimistic: true })

  const handleUpdate = useCallback(
    (tagIds: string[]) => {
      if (currentPatient) {
        updatePatient({ id: currentPatient.id, payload: { ...currentPatient, tagIds } })
      }
    },
    [currentPatient, updatePatient],
  )

  const onCreate = useCallback(
    (label: string) => {
      createTag({ label }, { onSuccess: (tag) => handleUpdate([...tagIds, tag.id]) })
    },
    [createTag, handleUpdate, tagIds],
  )

  return (
    <PatientInfosContainer>
      <TagSearchAndDisplay
        colorPreset="light"
        tags={tags}
        valueIds={tagIds}
        onChange={handleUpdate}
        onCreate={onCreate}
        placeholder="Rechercher un label"
        disabled={disabled}
        dropdownPosition="top"
      />
    </PatientInfosContainer>
  )
}
