import { createReducer } from 'redux-starter-kit'

import { CodesDomainActionsTypes, CodesState } from './codes.model'
import { codesDomainActions } from './codes.actions'

const DEFAULT_STATE: CodesState = {}

const ACTION_HANDLERS = {
  [CodesDomainActionsTypes.FETCH_SUCCESS]: (
    _state: CodesState,
    { codeCategories }: ReturnType<typeof codesDomainActions.fetchSuccess>,
  ): CodesState => {
    return codeCategories.reduce((aggr, category) => {
      return { ...aggr, [category.code]: category }
    }, {})
  },
}
export const codesReducer = createReducer<CodesState>(DEFAULT_STATE, ACTION_HANDLERS)
