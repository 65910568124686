import React, { FunctionComponent, useCallback, useRef, useState } from 'react'
import classNames from 'classnames'
import { useDrop } from 'react-dnd'
import { NativeTypes } from 'react-dnd-html5-backend'
import { Colors } from '../../../colors'
import { Icon, IconButton } from '../../shared'
import { allFilesValid } from '../../../misc/files.utilities'
import styles from './FileDropzone.module.scss'

export interface DropzoneProps {
  type: 'file' | 'image' | 'pdf'
  multiple?: boolean
  onDrop: (files: File[]) => void
  onClose?: (event: React.MouseEvent<HTMLElement>) => void
  theme?: 'light' | 'dark'
}

const FILE_TYPES_LABELS = {
  file: 'fichier',
  image: 'image',
  pdf: 'PDF',
}

const typeMapping = {
  file: '',
  image: 'image/png, image/jpeg',
  pdf: 'application/pdf',
}

export const FileDropzone: FunctionComponent<DropzoneProps> = ({
  onClose,
  onDrop,
  multiple = true,
  type = 'file',
  theme = 'dark',
}) => {
  const [fileFormatError, setFileFormatError] = useState(false)

  const titleLabel = `Déposez ${multiple ? 'vos' : 'votre'} ${FILE_TYPES_LABELS[type]}${
    multiple ? 's' : ''
  }`
  const subtitleLabel = `Cliquez ou glissez ${multiple ? 'vos' : 'votre'} ${
    FILE_TYPES_LABELS[type]
  }${multiple ? 's' : ''} dans cette fenêtre`

  const acceptType = typeMapping[type]
  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleFilesToAdd = useCallback(
    (files: File[]) => {
      const isValid = allFilesValid(acceptType, files)
      setFileFormatError(!isValid)
      isValid && onDrop(files)
    },
    [acceptType, onDrop],
  )

  const [{ isOver }, drop] = useDrop(() => ({
    accept: [NativeTypes.FILE],
    drop: (item: { files: File[] }) => {
      handleFilesToAdd(item.files)
    },
    collect: (monitor) => ({ isOver: monitor.isOver() }),
  }))

  const onInputClick = (event: React.MouseEvent<HTMLInputElement>) => event.stopPropagation()
  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation()
    // Stop default browser behavior
    event.preventDefault()
    // Persist event for later usage
    event.persist()
    //
    if (event.target && event.target.files) {
      const files = Array.from(event.target.files) as File[]
      handleFilesToAdd(files)
    }
  }

  return (
    <div className={classNames(styles.fileDropZone, styles[theme])}>
      <div
        className={classNames(styles.dropZone, styles[theme], {
          [styles.fileHover]: isOver,
          [styles.error]: fileFormatError,
        })}
        ref={drop}
        onClick={() => {
          fileInputRef.current && fileInputRef.current.click()
        }}
        data-test-id="drop_zone"
      >
        <Icon
          icon="paperClip"
          color={theme === 'light' ? Colors.dsDimmerDark : Colors.dsWhite}
          size="macro"
        />
        <h1 className={styles[theme]}>{titleLabel}</h1>
        <h2 className={styles[theme]}>{subtitleLabel}</h2>
        {type === 'image' && <h2 className={styles[theme]}>Formats acceptés : .jpg, .png</h2>}
        {fileFormatError && <p className={styles.error}>Ce format de fichier n'est pas supporté</p>}
      </div>
      <input
        ref={fileInputRef}
        accept={acceptType}
        autoComplete="off"
        className={classNames(styles.browse, styles[theme])}
        onClick={onInputClick}
        onChange={onInputChange}
        multiple={multiple}
        tabIndex={-1}
        type="file"
      />
      {onClose && (
        <div className={styles.iconClose}>
          <IconButton onClick={onClose} icon="cross" theme="transparent" />
        </div>
      )}
    </div>
  )
}
