import { combineReducers } from 'redux'
import { inUseReducerFactory } from '../../../restux/ui/index'
import {
  medicalEventDocumentInstancesConfig,
  MedicalEventDocumentInstancesState,
} from './medicalEventDocumentInstances.model'

const inUse = inUseReducerFactory(medicalEventDocumentInstancesConfig)

export const medicalEventDocumentInstancesReducer = combineReducers<MedicalEventDocumentInstancesState>(
  {
    inUse,
  },
)
