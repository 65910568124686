import { connect } from 'react-redux'
import { setBottomPanelOptions } from '../../../store/ui/bottomPanel'
import { EditTeamPanelDispatchProps } from './EditTeamPanel.model'
import EditTeamPanel from './EditTeamPanel.component'

const mapDispatchToProps: EditTeamPanelDispatchProps = {
  setBottomPanelOptions,
}

export default connect(null, mapDispatchToProps)(EditTeamPanel)
