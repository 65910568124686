import React, { FunctionComponent } from 'react'
import { Icon } from '../../../Icon'
import { ActionModalLayoutProps } from './ActionModalLayout.model'

export const ActionModalLayout: FunctionComponent<ActionModalLayoutProps> = ({
  title,
  actions,
  onClose,
  children,
}) => (
  <div className={`max-h-screen-3/4 flex flex-col transition-width duration-500`}>
    <div className="bg-white p-4 flex items-center justify-between border-b border-gray-200">
      <span className="text-lg leading-6 font-semibold">{title}</span>
      {onClose && (
        <div className="cursor-pointer" onClick={onClose}>
          <Icon icon="cross" size="normal" />
        </div>
      )}
    </div>
    {children}
    {actions && (
      <div className="bg-white p-4 flex justify-center border-t border-gray-200">{actions}</div>
    )}
  </div>
)
