import React, { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { MINIMAL_SIZE, Size } from '../Image.model'
import {
  ButtonStyled,
  InputContainer,
  InputStyled,
  NumericResizeContainer,
  ResizeWindow,
  SpanStyled,
} from './NumericResize.styled'

interface NumericResizeProps {
  size: Size
  url: string
  setSize: (size: Size) => void
}

export const NumericResize: FunctionComponent<NumericResizeProps> = ({ size, url, setSize }) => {
  const ratio = useMemo(() => size.width / size.height, [url]) // On ne recalcule le ratio que si l'image change
  const [disabled, setDisabled] = useState(false)
  const [fields, setFields] = useState(size)

  useEffect(() => {
    setFields(size)
  }, [size])

  useEffect(() => {
    setDisabled(
      isNaN(fields.width) ||
        isNaN(fields.width) ||
        (fields.width < MINIMAL_SIZE.width && fields.height < MINIMAL_SIZE.height),
    )
  }, [fields])

  const onWidthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = parseInt(event.currentTarget.value)
    if (!isNaN(val)) {
      const width = val
      const height = Math.round(width / ratio)
      setFields({ width, height })
    } else {
      setFields({ width: NaN, height: NaN })
    }
  }

  const onHeightChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = parseInt(event.currentTarget.value)
    if (!isNaN(val)) {
      const height = val
      const width = Math.round(height * ratio)
      setFields({ width, height })
    } else {
      setFields({ width: NaN, height: NaN })
    }
  }

  const handleValid = () => {
    setSize(fields)
  }

  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key.toLowerCase() === 'enter') {
      handleValid()
    }
  }

  return (
    <ResizeWindow>
      <NumericResizeContainer>
        <InputContainer>
          <SpanStyled>⬍</SpanStyled>
          <InputStyled
            name="height"
            type="number"
            value={Math.trunc(fields.height)}
            onChange={onHeightChange}
            onKeyPress={onKeyPress}
          />
        </InputContainer>
        <InputContainer>
          <SpanStyled>⬌</SpanStyled>
          <InputStyled
            name="width"
            type="number"
            value={fields.width}
            onChange={onWidthChange}
            onKeyPress={onKeyPress}
          />
        </InputContainer>
        <ButtonStyled onClick={handleValid} disabled={disabled}>
          Valider
        </ButtonStyled>
      </NumericResizeContainer>
    </ResizeWindow>
  )
}
