import React, { FunctionComponent, useContext } from 'react'

import styles from './Radio.module.scss'
import { RadioGroupContext } from '../RadioGroup.context'
import { RadioProps } from './Radio.model'
import { Tick } from '../../Tick'

export const Radio: FunctionComponent<RadioProps> = React.forwardRef<HTMLDivElement, RadioProps>(
  ({ label, value: radioValue }, ref) => {
    const { name, value: radioGroupValue, onChange, disabled } = useContext(RadioGroupContext)
    const radioId = `radio-${label.replace(/ /g, '-')}`
    return (
      <div className={styles.radio} ref={ref}>
        <Tick
          id={radioId}
          type="radio"
          value={radioValue}
          checked={radioGroupValue === radioValue}
          name={name}
          onChange={onChange}
          rounded
          disabled={disabled}
        >
          <div className={styles.radioLabel}>{label}</div>
        </Tick>
      </div>
    )
  },
)
