import { PaginatedList } from '../../../model/Pagination'
import { TagType } from '../../../model/Tag'

export const fetchAllTags = async (
  fetchTagsFn: (page: number, signal?: AbortSignal) => Promise<PaginatedList<TagType>>,
  signal?: AbortSignal,
): Promise<TagType[]> => {
  const firstPaginatedList = await fetchTagsFn(1, signal)
  const paginatedLists = await Promise.all(
    Array.from({ length: firstPaginatedList.pageCount - 1 }).map((_, index) => {
      const page = index + 2
      return fetchTagsFn(page, signal)
    }),
  )

  return [...firstPaginatedList.items, ...paginatedLists.flatMap((p) => p.items)]
}
