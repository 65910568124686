import { useState, useEffect, EffectCallback, SetStateAction, Dispatch } from 'react'

/**
 * Hook permettant d'utiliser un effect ayant une dépendance vers une ref
 *
 * @param effectFunction la function d'effect à passer. Elle fonctionne comme une fonction classique de `useEffect`
 * @param deps Les dépendances de l'effect. Fonctionne comme les dépendances classique de `useEffect`
 *
 * @returns un tuple `[ref, setRef]` Attention le `setRef` est de type callbackRef
 */
export function useEffectWithRef<T>(
  effectFunction: EffectCallback,
  deps: ReadonlyArray<any> = [],
): [T | null, Dispatch<SetStateAction<T | null>>] {
  const [componentRef, setComponentRef] = useState<T | null>(null)
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(effectFunction, [componentRef, ...deps])

  return [componentRef, setComponentRef]
}
