import React, { FunctionComponent } from 'react'
import { ListExtendedActionsProps } from './ListExtendedActions.model'
import styles from './ListExtendedActions.module.scss'
import classNames from 'classnames'

export const ListExtendedActions: FunctionComponent<ListExtendedActionsProps> = ({
  open,
  children,
}) => {
  return (
    <div
      className={classNames(styles.container, {
        [styles.opened]: open === true,
        [styles.closed]: open === false,
      })}
    >
      <div>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  )
}
