import { takeEvery } from '@redux-saga/core/effects'
import { ApiResponse } from 'apisauce'
import { call, put } from 'redux-saga/effects'
import { Drug } from '../../../model/Drug'
import { getDrugDetails } from './api'
import { domainDrugsActions } from './drugs.actions'
import { domainDrugsActionTypes } from './drugs.model'

function* getDetailsWorker({ drug }: ReturnType<typeof domainDrugsActions.getDetails>) {
  const response: ApiResponse<Drug> = yield call(getDrugDetails, drug)
  if (response.ok && response.data) {
    yield put(domainDrugsActions.storeDetails(response.data))
  }
}

function* getDetailsWatcher() {
  yield takeEvery(domainDrugsActionTypes.GET_DETAILS, getDetailsWorker)
}

export const domainDrugsSagas = {
  getDetailsWatcher,
}
