import classNames from 'classnames/bind'
import React, { FC, FunctionComponent, MouseEvent, ReactNode, useEffect, useRef } from 'react'
import { useClickAway } from 'react-use'
import { useHotKeyEffect, HotKeys } from '../../../../hooks/utils'
import styles from './AutocompleteInput.module.scss'

const classNamesCx = classNames.bind(styles)

interface SuggestionItemProps {
  children?: ReactNode
  hover?: boolean
  onClick?: (event: MouseEvent) => void
}
export const SuggestionItem: FunctionComponent<SuggestionItemProps> = ({
  hover = false,
  onClick,
  children,
}) => {
  return (
    <div
      onMouseDown={onClick}
      className={classNamesCx(styles.suggestionItem, { hover })}
      role="button"
    >
      {children}
    </div>
  )
}

interface AutocompleteInputProps {
  renderInput: (
    suggestionContainer: FC<{
      children?: ReactNode
    }>,
  ) => ReactNode
  suggestionsShown: boolean
  selectedIndex?: number
  predictionsLength?: number
  handleClickAway?: (event: KeyboardEvent) => void
  setSelectedIndex: (index?: number) => void
  handlePressEnter?: () => void
}
export const AutocompleteInput: FunctionComponent<AutocompleteInputProps> = ({
  renderInput,
  suggestionsShown,
  selectedIndex,
  predictionsLength,
  handleClickAway,
  setSelectedIndex,
  handlePressEnter,
}) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setSelectedIndex(undefined)
  }, [suggestionsShown, setSelectedIndex])

  useClickAway(ref, (event) => {
    if (handleClickAway) {
      handleClickAway(event)
    }
  })

  useHotKeyEffect(
    HotKeys.Enter,
    (event) => {
      if (suggestionsShown) {
        event.preventDefault()
      }
      if (handlePressEnter && selectedIndex !== undefined) {
        handlePressEnter()
      }
    },
    [selectedIndex, handlePressEnter],
  )
  useHotKeyEffect(
    HotKeys.ArrowDown,
    (event) => {
      if (suggestionsShown) {
        event.preventDefault()
      }
      if (!suggestionsShown || !predictionsLength || selectedIndex === predictionsLength - 1) {
        return
      }
      if (selectedIndex === undefined) {
        setSelectedIndex(0)
      } else {
        setSelectedIndex(selectedIndex + 1)
      }
    },
    [suggestionsShown, predictionsLength, selectedIndex, setSelectedIndex],
  )

  useHotKeyEffect(
    HotKeys.ArrowUp,
    (event) => {
      if (suggestionsShown) {
        event.preventDefault()
      }
      if (!suggestionsShown) {
        return
      }
      if (selectedIndex !== undefined && selectedIndex !== 0) {
        setSelectedIndex(selectedIndex - 1)
      }
    },
    [suggestionsShown, selectedIndex, setSelectedIndex],
  )
  const suggestionContainer: FC<{
    children?: ReactNode
  }> = ({ children }) => (
    <div className={classNamesCx(styles.suggestions, { displayed: suggestionsShown })}>
      {children}
    </div>
  )
  return (
    <div ref={ref} className={styles.autocompleteInput}>
      {renderInput(suggestionContainer)}
    </div>
  )
}
