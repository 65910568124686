import { ApiResponse } from 'apisauce'
import { call, fork, put, select, take, takeEvery } from 'redux-saga/effects'
import { hasAdminRole, isDetachedAssistant } from '../../../misc/roles.utilities'
import { TrackingService } from '../../../misc/Tracking/index'
import { User } from '../../../model/User'
import * as Api from '../../cache/users/api/index'
import { userMeTeamsActions } from '../../cache/users/index'
import { addError, addResponseError, addValid } from '../../message/index'
import { codesDomainActions } from '../codes'
import { fetchAllTeams, setMe, updateMe, updatePassword, updatePreferences } from './me.actions'
import { MeActionTypes } from './me.model'
import { setUsurpedUser, usurpedSelector } from '../../ui/adminUsers'
import { getMeUsurpation, requestPasswordUpdate } from '../../cache/users/api/index'
import { documentCategoriesActions } from '../../cache/documentCategories'
import { isAssistant } from '../../../misc/user.utilities'
import { dmpDomainActions } from '../dmp'
import { singleItemPageLimit } from '../../../constants'

function* getMeWorker() {
  const response: ApiResponse<User> = yield call(Api.getMe)
  if (response.ok && response.data) {
    const me = response.data
    yield put(setMe(me))

    if (isAssistant(me) && isDetachedAssistant(me)) {
      return
    }

    if (!hasAdminRole(me.roles)) {
      const userCreatedAt = new Date(me.createdAt)
      TrackingService.setUser(
        me.trackingId,
        `${response.data.firstName} ${response.data.familyName}`,
        me.email,
        userCreatedAt,
      )
    }

    const usupedUser: ReturnType<typeof usurpedSelector> = yield select(usurpedSelector)
    if (isAssistant(me) && !usupedUser) {
      const firstDoctor = me.doctors[0]
      const newUsurpedUser = yield call(getMeUsurpation, firstDoctor.id, firstDoctor.email)
      yield put(setUsurpedUser(newUsurpedUser.data))
    } else {
      yield put(codesDomainActions.fetch())
      yield put(fetchAllTeams())
      yield put(documentCategoriesActions.actions.getPaginatedItems())
      yield put(dmpDomainActions.fetchHealthcareSettings())
    }
  }
}

function* getMeWatcher() {
  while (true) {
    yield take(MeActionTypes.GET_ME)
    yield fork(getMeWorker)
  }
}

function* updateMeWorker({ me }: ReturnType<typeof updateMe>) {
  const response = yield call(Api.updateMe, me)
  if (response.ok && response.data) {
    const newMe = response.data
    yield put(addValid('Compte mis à jour'))
    yield put(setMe(newMe))
  } else {
    yield put(addResponseError(response))
  }
}

function* updateMeWatcher() {
  yield takeEvery(MeActionTypes.UPDATE_ME, updateMeWorker)
}

function* updatePasswordWorker({ updatedPassword }: ReturnType<typeof updatePassword>) {
  const response = yield call(requestPasswordUpdate, updatedPassword)
  if (response.ok) {
    yield put(addValid('Mot de passe mis à jour'))
  } else if (response.status === 403) {
    yield put(addError('Mot de passe actuel invalid !'))
  } else {
    yield put(addResponseError(response))
  }
}

function* updatePasswordWatcher() {
  yield takeEvery(MeActionTypes.UPDATE_PASSWORD, updatePasswordWorker)
}

function* updatePreferencesWorker({ updatedPreferences }: ReturnType<typeof updatePreferences>) {
  const response: ApiResponse<User> = yield call(Api.updateMe, { preferences: updatedPreferences })
  if (response.ok && response.data) {
    yield put(addValid('Préférences mises à jour'))
    yield put(setMe(response.data))
  } else {
    yield put(
      addError(
        'La sauvegarde a échoué',
        "Une erreur s'est produite pendant la sauvegarde des préférences",
      ),
    )
  }
}
function* updatePreferencesWatcher() {
  yield takeEvery(MeActionTypes.UPDATE_PREFERENCES, updatePreferencesWorker)
}

function* updateTrustedContactWorker({
  updatedTrustedContact,
}: ReturnType<typeof updatedTrustedContact>) {
  const response: ApiResponse<User> = yield call(Api.updateTrustedContact, updatedTrustedContact)
  if (!response.ok) {
    yield put(
      addError(
        'La sauvegarde a échoué',
        "Une erreur s'est produite pendant la sauvegarde des contacts de confiance.",
      ),
    )
  }
}
function* updateTrustedContactWatcher() {
  yield takeEvery(MeActionTypes.UPDATE_TRUSTED_CONTACT, updateTrustedContactWorker)
}

function* fetchAllTeamsWorker() {
  yield put(userMeTeamsActions.actions.storeClearCache())
  yield put(
    userMeTeamsActions.actions.getPaginatedItems({
      page: { currentPage: 1, pageSize: singleItemPageLimit },
    }),
  )

  const {
    paginatedList: { currentPage, pageCount },
  }: ReturnType<typeof userMeTeamsActions.actions.storeSetListItems> = yield take(
    userMeTeamsActions.types.STORE_SET_LIST_ITEMS,
  )

  if (currentPage === 1 && pageCount > 1) {
    for (let pageSize = 2; pageSize <= pageCount; ++pageSize) {
      yield put(
        userMeTeamsActions.actions.getPaginatedItems({
          page: {
            currentPage: pageSize,
            pageSize: singleItemPageLimit,
          },
        }),
      )
    }
  }
}

function* fetchAllTeamsWatcher() {
  yield takeEvery(MeActionTypes.FETCH_ALL_TEAMS, fetchAllTeamsWorker)
}

export const meSagas = {
  getMeWatcher,
  updateMeWatcher,
  updatePasswordWatcher,
  updatePreferencesWatcher,
  updateTrustedContactWatcher,
  fetchAllTeamsWatcher,
}
