import { AssigningAuthority } from '../../../model/Patient'
import { DomainAssigningAuthoritiesTypes } from './assigningAuthorities.model'

const getAssigningAuthorithies = () => ({
  type: DomainAssigningAuthoritiesTypes.GET_ASSIGNING_AUTHORITIES,
})

const setAssigningAuthorithies = (assigningAuthorities: AssigningAuthority[]) => ({
  type: DomainAssigningAuthoritiesTypes.SET_ASSIGNING_AUTHORITIES,
  assigningAuthorities,
})

export const domainAssigningAuthorithiesActions = {
  getAssigningAuthorithies,
  setAssigningAuthorithies,
}
