import { SelectOption } from '@/model/SelectOption'

export type BlockSpacingValueOptions = Array<SelectOption<`${number}px` | null>>

export const DEFAULT_BLOCKSPACING_VALUE: BlockSpacingValueOptions = [
  { label: '0', id: '0', value: '0px' },
  { label: '0.25', id: '0.25', value: '4px' },
  { label: '0.5', id: '0.5', value: '8px' },
  { label: '1', id: 'default', value: null },
  { label: '2', id: '2', value: '32px' },
  { label: '3', id: '3', value: '48px' },
  { label: '4', id: '4', value: '64px' },
]
