import React, { FunctionComponent } from 'react'
import styles from './PatientTaskEmptyState.module.scss'

export const PatientTaskEmptyState: FunctionComponent = () => {
  return (
    <div className={styles.row}>
      <div>
        <h2 className={styles.title}>Aucune tâche</h2>
        <div className={styles.description}>
          <p>Vous n’avez actuellement aucune tâche à réaliser pour ce patient.</p>
        </div>
      </div>
    </div>
  )
}
