import getApi from '../../api'
import {
  ApiDocumentLayoutResource,
  DocumentLayoutMutationPayload,
} from '../../model/DocumentLayout'
import { deserializeDocumentLayoutResponse, serializeLayout } from './mapper/documentLayouts.mapper'

export const updateDocumentLayout = (id: number, payload: DocumentLayoutMutationPayload) =>
  getApi()
    .put<ApiDocumentLayoutResource>(`/document_layouts/${id}`, serializeLayout(payload))
    .then(deserializeDocumentLayoutResponse)
