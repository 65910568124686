import { RestuxResources } from '../../resources'
import { toUpperSnakeCase } from '../../restux/restux.utilities'
import { PrescriptionTemplate } from '../../../model/PrescriptionTemplate'

const RESOURCE_PREFIX = `@${toUpperSnakeCase(RestuxResources.prescriptionTemplate)}`

export const domainPrescriptionTemplateActionTypes = {
  GET_COLLECTION: `${RESOURCE_PREFIX}/GET_COLLECTION`,
  STORE_COLLECTION: `${RESOURCE_PREFIX}/STORE_COLLECTION`,
  DELETE_ITEM: `${RESOURCE_PREFIX}/DELETE_ITEM`,
  CREATE_PRESCRIPTION_TEMPLATE: `${RESOURCE_PREFIX}/CREATE_PRESCRIPTION_TEMPLATE`,
}

export interface PrescriptionTemplatesState {
  prescriptionTemplates: PrescriptionTemplate[] | null
}
