import { SelectOption } from '@/model/SelectOption'

export type LineHeightValueOptions = Array<SelectOption<string | null>>

export const DEFAULT_LINEHEIGHT_OPTIONS: LineHeightValueOptions = [
  { label: '1', id: 'default', value: null },
  { label: '1.25', id: '125', value: '125%' },
  { label: '1.5', id: '150', value: '150%' },
  { label: '2', id: '200', value: '200%' },
  { label: '2.5', id: '250', value: '250%' },
  { label: '3', id: '300', value: '300%' },
]
