import { takeEvery, call, put, select, debounce } from 'redux-saga/effects'

import { addError } from '../message'
import { setVariableData, setVariableDataLoading, sendDocuments } from './renderer.actions'
import { RendererActionTypes, RenderMode } from './renderer.model'
import { ApiResponse } from 'apisauce'
import {
  SelectedMedicalEventDocumentWrapper,
  selectedMedicalEventDocumentSelector,
} from '../ui/medicalEvents'
import { MedicalEventDocumentType } from '../../model/MedicalEvent'
import { patientCurrentTreatmentsActions } from '../cache/patientCurrentTreatments'
import { MailSubjectAction } from '../ui/mailSubject'
import { getMailSubject } from '../ui/mailSubject/api'
import { isDefined } from '../../misc/functions.utilities'
import { fetchDocumentVariableData } from './api'
import { VariableDataSerializer, VariablesDataWithError } from '@follow/cdk'
import { queryClient } from '../../App'
import { documentKeys } from '../../hooks/queries/documents/documents.keys'

function* sendDocumentWorker({ documents }: ReturnType<typeof sendDocuments>) {
  if (!documents || documents.length === 0) {
    return
  }
  const documentIds = documents
    .map((document) => (document.type === 'farte' ? document.documentId : null))
    .filter(isDefined)

  yield put(MailSubjectAction.setDocumentsIds(documentIds))

  const { data, ok }: ApiResponse<{ subject: string }> = yield call(getMailSubject, documentIds)
  if (data && ok) yield put(MailSubjectAction.setMailSubject(data.subject))
}

export function* sendDocumentWatcher() {
  yield takeEvery(RendererActionTypes.SEND_DOCUMENT, sendDocumentWorker)
}

export function* getVariableDataWorker() {
  const selectedDocument: SelectedMedicalEventDocumentWrapper | null = yield select(
    selectedMedicalEventDocumentSelector,
  )
  if (selectedDocument?.type !== MedicalEventDocumentType.FW_DOCUMENT) {
    return
  }

  const documentId = selectedDocument.item.id
  yield put(setVariableDataLoading(true))
  const variableData: ApiResponse<VariablesDataWithError> = yield call(
    fetchDocumentVariableData,
    documentId,
    RenderMode.PREVIEW,
  )
  yield put(setVariableDataLoading(false))

  if (variableData.ok && variableData.data) {
    const deserialized = VariableDataSerializer.deserialize(variableData.data.variables)
    yield put(setVariableData(deserialized))
    queryClient.invalidateQueries(documentKeys.variable_data(documentId))
  } else {
    yield put(
      addError(
        'Erreur pendant le chargement du document',
        "Le contenu des variables n'a pas pu être récupéré",
      ),
    )
    console.error('[Variable Data]', variableData)
  }
}

export function* getVariableDataWatcher() {
  yield takeEvery(RendererActionTypes.GET_VARIABLE_DATA, getVariableDataWorker)
}

export function* refreshVariableDataWatcher() {
  yield debounce(
    500,
    [patientCurrentTreatmentsActions.types.STORE_SET_LIST_ITEMS],
    getVariableDataWorker,
  )
}
