import React, { FC } from 'react'
import { HEADING_LEVELS, HeadingLevel } from '@/model/Font'
import { SelectOption } from '@/model/SelectOption'
import { ToolbarSelect } from '@/components/toolbar/atoms'
import { ToolbarElementProps } from '@/model/Toolbar'
import styles from './HeadingSelect.module.scss'

const headingOptions = HEADING_LEVELS.map<SelectOption<HeadingLevel>>((item) => {
  return {
    id: item.key === 'heading' ? `${item.key}_${item.level}` : item.key,
    label: item.label,
    value: item,
  }
})

const renderOption = (option: SelectOption<HeadingLevel>) => {
  if (option.value.key === 'heading') {
    switch (option.value.level) {
      case 1:
        return <h1 className={styles['headingOption']}>{option.label}</h1>
      case 2:
        return <h2 className={styles['headingOption']}>{option.label}</h2>
      case 3:
        return <h3 className={styles['headingOption']}>{option.label}</h3>
    }
  }
  return option.label
}

export const HeadingSelect: FC<ToolbarElementProps> = ({ editor }) => {
  const activeHeading = headingOptions.find(({ value }) =>
    editor.isActive(value.key, value.key === 'heading' ? { level: value.level } : undefined),
  )

  const handleHeadingChange = ({ value }: SelectOption<HeadingLevel>) => {
    if (value.key === 'heading') {
      editor.chain().focus().setHeading({ level: value.level }).run()
    } else if (value.key === 'paragraph') {
      editor.chain().focus().setParagraph().run()
    }
  }
  return (
    <ToolbarSelect
      title="Style"
      placeholder="Style"
      value={activeHeading}
      options={headingOptions}
      onChange={handleHeadingChange}
      renderItem={renderOption}
    />
  )
}
