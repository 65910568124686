import uuidv4 from 'uuid/v4'
import { createReducer } from 'redux-starter-kit'
import { dmpDomainActions } from './dmp.actions'
import { DmpDomainActionsTypes, DmpState } from './dmp.model'

const DEFAULT_STATE: DmpState = {
  healthcareSettings: [],
}

const ACTION_HANDLERS = {
  [DmpDomainActionsTypes.SET_REQUEST]: (
    state: DmpState,
    { documentId, request }: ReturnType<typeof dmpDomainActions.setRequest>,
  ): DmpState => {
    return {
      ...state,
      request: request
        ? {
            uuid: uuidv4(),
            documentId,
            payload: JSON.stringify({
              remoteCommand: window.btoa(
                window.unescape(encodeURIComponent(JSON.stringify(request))),
              ),
            }),
          }
        : undefined,
    }
  },
  [DmpDomainActionsTypes.STORE_HEALTHCARE_SETTINGS]: (
    state: DmpState,
    { healthcareSettings }: ReturnType<typeof dmpDomainActions.storeHealthcareSettings>,
  ) => ({
    ...state,
    healthcareSettings,
  }),
}

export const dmpReducer = createReducer<DmpState>(DEFAULT_STATE, ACTION_HANDLERS)
