import { toUpperSnakeCase } from '../../restux/restux.utilities'
import { RestuxResources } from '../../resources'

const RESOURCE_PREFIX = `@${toUpperSnakeCase(RestuxResources.file)}`

export const FilesDomainActionTypes = {
  DOWNLOAD: `${RESOURCE_PREFIX}/DOWNLOAD`,
  SELECT_AND_PRINT: `${RESOURCE_PREFIX}/SELECT_AND_PRINT`,
  PRINT: `${RESOURCE_PREFIX}/PRINT`,
  SEND: `${RESOURCE_PREFIX}/SEND`,
}
