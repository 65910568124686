import { PermissionFields } from '../model/Resource'
import { SharingConfigAccessLevel } from '../model/Team'

export const hasPermissionAction = (type: SharingConfigAccessLevel, item: PermissionFields) => {
  switch (type) {
    case SharingConfigAccessLevel.read:
      return item.isReadable ?? true

    case SharingConfigAccessLevel.write:
      return item.isEditable ?? true

    case SharingConfigAccessLevel.delete:
      return item.isDeletable ?? true

    default:
      return true
  }
}
