import React from 'react'

import { SideSelectContainer, SideSelectItem } from '../../../../../components/shared'

import {
  SearchDocumentHistoryFiltersProps,
  SearchDocumentHistoryFilterType,
} from './SearchDocumentHistoryFilters.model'

export function SearchDocumentHistoryFilters({
  colorPreset = 'dark',
  filter,
  onChange,
}: SearchDocumentHistoryFiltersProps) {
  return (
    <SideSelectContainer>
      <SideSelectItem
        icon="checkCircle"
        active={filter === SearchDocumentHistoryFilterType.ALL}
        colorPreset={colorPreset}
        onClick={() => onChange(SearchDocumentHistoryFilterType.ALL)}
      >
        Toutes les versions
      </SideSelectItem>
      <SideSelectItem
        icon="save_disk"
        active={filter === SearchDocumentHistoryFilterType.HISTORY}
        colorPreset={colorPreset}
        onClick={() => onChange(SearchDocumentHistoryFilterType.HISTORY)}
      >
        Révisions
      </SideSelectItem>
      <SideSelectItem
        icon="documentText"
        active={filter === SearchDocumentHistoryFilterType.RENDER_HISTORY}
        colorPreset={colorPreset}
        onClick={() => onChange(SearchDocumentHistoryFilterType.RENDER_HISTORY)}
      >
        Impressions & Téléchargements
      </SideSelectItem>
    </SideSelectContainer>
  )
}
