import { useMutation, useQueryClient } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { PaginatedList } from '../../../model/Pagination'
import { Task } from '../../../model/Task'
import { deleteTask } from '../../../data/task/deleteTask'
import { taskKeys } from './tasks.keys'

interface TaskMutateVariable {
  taskId: number
}

export const useDeleteTask = () => {
  const queryClient = useQueryClient()

  return useMutation<null, Error, TaskMutateVariable>(
    async ({ taskId }) => {
      const { ok } = await deleteTask(taskId)
      if (!ok) {
        throw new UserFriendlyError('error', 'La suppression de la tâche a échoué')
      }
      return null
    },
    {
      onSuccess: (_data, task) => {
        queryClient.refetchQueries<PaginatedList<Task>>({
          queryKey: taskKeys.all,
          refetchPage: (page) => page.items.some(({ id }) => id === task.taskId),
        })
      },
    },
  )
}
