import { ReactNode } from 'react'
import { FarteDocumentInstance } from '../../../model/DocumentInstance'
import { FarteDocumentTemplate } from '../../../model/DocumentTemplate'

import { EditorVariableDisplayConfig } from '@follow/cdk'
import { CommonVariableActionsProps } from '../CommonVariableActions/CommonVariableActions.model'
import { medicalEventContentUiActions } from '../../../store/ui/medicalEvents/medicalEventContent'
import { domainPrescriptionActions } from '../../../store/domain/prescription'
import { bottomPanelDrugsActions } from '../../../store/ui/bottomPanelDrugs/bottomPanelDrugs.actions'
import { VariableInsertionPosition } from '@follow/farte'
import { Block, Value } from 'slate'
import { AldStatus } from '../../../model/Drug'

export interface DrugVariableConfigurationOwnProps extends CommonVariableActionsProps {
  variableId: string
  variableTitle?: string
  displayConfig?: EditorVariableDisplayConfig
  renderTitle: ReactNode
  inUseEntity: FarteDocumentTemplate | FarteDocumentInstance | null // a tej ?
  isAdmin: boolean
  editorValue: Value
  onSelectVariableDisplayConfig: (
    config?: EditorVariableDisplayConfig,
    newVariableId?: string,
  ) => void
  onChangeVariablePosition: (
    variableId: string,
    position?: VariableInsertionPosition,
    relativeBlock?: Block,
    removeBlock?: boolean,
  ) => void
  hideContextualMenu: () => void
}

export interface IDrugVariableConfigurationDispatchProps {
  selectMedicalEventContent: typeof medicalEventContentUiActions.selectMedicalEventContent
  switchPrescriptionToVMP: typeof domainPrescriptionActions.switchToVMP
  searchDrug: typeof bottomPanelDrugsActions.searchDrug
  updatePrescription: typeof domainPrescriptionActions.updatePrescription
}

export const AldTitles: { [key in AldStatus]: string } = {
  [AldStatus.OK]: 'Le patient a une ALD déclarée exonérant ce médicament',
  [AldStatus.PATIENT_HAS_NO_ALD]: "Le patient n'a pas d'ALD déclarée",
  [AldStatus.NO_MATCHING_ALD_FOUND]: "Le patient n'a pas d'ALD déclarée exonérant ce médicament",
}

export type DrugVariableConfigurationProps = IDrugVariableConfigurationDispatchProps &
  DrugVariableConfigurationOwnProps
