import { customHistory } from '../../../history'

export function serializeMedicalEventFiltersToUrl(
  filters: Array<{ name: string; value: string }>,
  searchToKeep: string[] = [],
) {
  const previousSearch = new URLSearchParams(window.location.search)

  // Ajout des query params
  const newSearch = new URLSearchParams()
  filters.forEach(({ name, value }) => {
    newSearch.set(name, value)
  })

  // Conservation des anciens query params marqué comme à conserver
  searchToKeep.forEach((name) => {
    const value = previousSearch.get(name)
    if (value) {
      newSearch.set(name, value)
    }
  })

  // Update de l'url sans reload la page
  customHistory.navigate({ search: newSearch.toString() }, { replace: true })
}
