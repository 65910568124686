import './index.css'
import React, { ReactElement } from 'react'
import { createRoot } from 'react-dom/client'
import * as Bowser from 'bowser'
import App from './App'
import CompatibilityContainer from './containers/CompatibilityContainer'
import { defaultBrowsersConfig } from './misc/MatchDevice'
import { AuthService } from './misc/auth.utilities'
import { ErrorPandaMessage } from './components/shared'
import { registerServiceWorker } from './registerServiceWorker'
import { GlobalWorkerOptions } from 'pdfjs-dist'
import { basename } from './history'

const browser = Bowser.getParser(window.navigator.userAgent)
const isValidBrowser = browser.satisfies(defaultBrowsersConfig)

const container = document.getElementById('root')
/* /!\ BEWARE : browser.satisfies() returns 3 values [true, false, and UNDEFINED] (voir la doc)
    - true = sur liste blanche
    - false = sur liste noire
    - undefined = ni l'un ni l'autre
   Ici, on fonctionne par liste blanche seulement
*/
const renderApp = async (component: ReactElement) => {
  if (!container) return null
  const root = createRoot(container)

  // Attention en local cette URL est chargée via proxy
  GlobalWorkerOptions.workerSrc = `${basename}/pdf.worker.v2.6.347.min.js`
  try {
    await AuthService.initKeycloak()
    root.render(component)
  } catch (e) {
    root.render(
      <ErrorPandaMessage
        title="Une erreur est survenue"
        subTitle="L'authentification est actuellement indisponible"
      />,
    )
  }
}

if (!isValidBrowser && !(process.env.NODE_ENV === 'development')) {
  renderApp(<CompatibilityContainer />)
} else {
  renderApp(<App />)
}

if (process.env.NODE_ENV === 'production') {
  registerServiceWorker()
}

// eslint-disable-next-line no-extend-native
Array.prototype.at = function (n) {
  // ToInteger() abstract op
  n = Math.trunc(n) || 0
  // Allow negative indexing from the end
  if (n < 0) n += this.length
  // OOB access is guaranteed to return undefined
  if (n < 0 || n >= this.length) return undefined
  // Otherwise, this is just normal property access
  return this[n]
}
