import { takeEvery } from 'redux-saga/effects'
import { getzE } from '../../analytics/zendesk'
import { MeActionTypes, setMe } from '../domain/me/index'

function updateZendeskUser({ me }: ReturnType<typeof setMe>) {
  if (me) {
    const { id, firstName, familyName, email } = me
    // Charge dans le plugin zendesk (aide utilisateurs) les infos du user connecté
    const zE = getzE()
    if (!zE) {
      // ZenDesk deactivated
      return
    }
    zE(() => {
      getzE().identify({ name: `${firstName} ${familyName}`, email, external_id: id })
    })
  }
}

export function* getUserWatcher() {
  yield takeEvery(MeActionTypes.SET_ME, updateZendeskUser)
}
