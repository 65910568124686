import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import { TaskSection } from './TaskSection'
import { TaskEmptyState } from './TaskEmptyState'
import {
  TaskPageContentProps,
  TaskPageFiltersState,
  defaultTaskPageFilters,
} from './TaskPageContent.model'
import { TaskInformation, TaskStatus } from '../../../model/Task'
import { TaskListFilters } from '../TaskListFilters'
import { CdaContext } from '../../../model/CdaContext'
import { AnalysisResultModal } from '../../cda'
import { useLocalStorage } from 'react-use'
import {
  TaskPageContentFiltersContext,
  TaskPageContentFiltersContextType,
} from './TaskPageContent.context'
import { addTestIdSuffix } from '../../../misc/testId.utilities'
import { TaskInformationModal } from './TaskInformationModal'

export const TaskPageContent: FunctionComponent<TaskPageContentProps> = ({
  tasksTodo,
  tasksDone,
  loading,
  getTasks,
  onCreateOrUpdate,
  onDeleteTask,
  newTask,
  removeNewTask,
  onDeleteAllDoneTasks,
  testId,
}) => {
  const [filters, setFilters] = useState<TaskPageFiltersState>(defaultTaskPageFilters)
  const [hideAutoCreated, setHideAutoCreated] = useLocalStorage<boolean>(
    'task-hide-autocreated',
    false,
  )

  const contextValue: TaskPageContentFiltersContextType = useMemo(
    () => ({
      filters: { ...filters, hideAutoCreated },
      setFilters: ({ hideAutoCreated: hideAutoCreatedParam, ...params }) => {
        setFilters((oldFilters) => ({ ...oldFilters, ...params }))
        setHideAutoCreated(hideAutoCreatedParam ?? hideAutoCreated)
      },
    }),
    [filters, hideAutoCreated, setHideAutoCreated],
  )

  useEffect(() => {
    getTasks({ ...filters, hideAutoCreated })
  }, [filters, getTasks, hideAutoCreated])

  const totalTaskItem = useMemo(
    () => tasksTodo.filter((task) => task.id !== undefined).length,
    [tasksTodo],
  )
  const totalItemCount = useMemo(() => totalTaskItem + tasksDone.length, [totalTaskItem, tasksDone])

  const [taskInformation, setTaskInformation] = useState<TaskInformation[] | undefined>()
  const [cdaContext, setCdaContext] = useState<CdaContext>()

  const handleOpenTaskInformation = useCallback((taskInformation: TaskInformation[]) => {
    setTaskInformation(taskInformation)
  }, [])

  const handleOpenCdaInformation = useCallback((cdaContext: CdaContext) => {
    setCdaContext(cdaContext)
  }, [])

  return (
    <TaskPageContentFiltersContext.Provider value={contextValue}>
      <div className="p-6" data-test-id={addTestIdSuffix('root', testId)}>
        <div className="mb-5">
          <TaskListFilters testId={addTestIdSuffix('filters', testId)} />
        </div>
        <TaskSection
          tasksStatus={TaskStatus.TODO}
          itemCount={totalTaskItem}
          totalItemCount={totalItemCount}
          tasks={tasksTodo}
          onCreateOrUpdate={onCreateOrUpdate}
          onDeleteTask={onDeleteTask}
          removeNewTask={removeNewTask}
          openTaskInformation={handleOpenTaskInformation}
          openCdaInformation={handleOpenCdaInformation}
          testId="task-page-section-todo"
        />
        {tasksTodo.length === 0 && tasksDone.length === 0 && !loading && (
          <div className="mt-4 mb-4">
            <TaskEmptyState newTask={newTask} />
          </div>
        )}
        <TaskSection
          tasksStatus={TaskStatus.DONE}
          itemCount={tasksDone.length}
          totalItemCount={totalItemCount}
          tasks={tasksDone}
          onCreateOrUpdate={onCreateOrUpdate}
          onDeleteTask={onDeleteTask}
          onDeleteAllDoneTasks={onDeleteAllDoneTasks}
          testId="task-page-section-done"
        />
        <TaskInformationModal
          display={!!taskInformation}
          taskInformation={taskInformation}
          onClose={() => setTaskInformation(undefined)}
        />
        <AnalysisResultModal
          display={!!cdaContext}
          onClose={() => setCdaContext(undefined)}
          result={cdaContext?.analysisResult ?? []}
        />
      </div>
    </TaskPageContentFiltersContext.Provider>
  )
}
