import {
  AdminAnalyticsActionTypes,
  AdminAnalyticsState,
  AnalyticsState,
  OverallState,
} from './adminAnalytics.model'
import { combineReducers } from 'redux'
import { createReducer } from 'redux-starter-kit'
import { setOverall, eraseOverall, addAnalytics } from './adminAnalytics.actions'
import { sortByFromDate } from '../../../misc/analytics.utilities'

const DEFAULT_ANALYTICS_STATE: AnalyticsState = []

const ANALYTICS_ACTION_HANDLERS = {
  [AdminAnalyticsActionTypes.ANALYTICS_RESET]: (state: AnalyticsState) => [],
  [AdminAnalyticsActionTypes.ANALYTICS_ADD]: (
    state: AnalyticsState,
    { analytics }: ReturnType<typeof addAnalytics>,
  ) => [...state, analytics].sort(sortByFromDate),
}

export const analytics = createReducer(DEFAULT_ANALYTICS_STATE, ANALYTICS_ACTION_HANDLERS)

const DEFAULT_OVERALL_STATE: OverallState = [undefined]

const OVERALL_ACTION_HANDLERS = {
  [AdminAnalyticsActionTypes.OVERALL_ADD_FIELD]: (state: OverallState) => [...state, undefined],
  [AdminAnalyticsActionTypes.SET_OVERALL]: (
    state: OverallState,
    { analytics, index }: ReturnType<typeof setOverall>,
  ) => {
    const newState = [...state]
    newState[index] = analytics
    return newState
  },
  [AdminAnalyticsActionTypes.OVERALL_ERASE]: (
    state: OverallState,
    { index }: ReturnType<typeof eraseOverall>,
  ) => {
    if (state.length === 1) return state
    const newState = [...state]
    newState.splice(index, 1)
    return newState
  },
}

export const overall = createReducer(DEFAULT_OVERALL_STATE, OVERALL_ACTION_HANDLERS)

export const adminAnalyticsReducer = combineReducers<AdminAnalyticsState>({ analytics, overall })
