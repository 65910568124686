import { RestuxUiSagaConf, RestuxInUse } from '../../../restux/ui/index'
import { RestuxResources } from '../../../resources'
import { RESTUX_IDENTIFIER } from '../../../restux.identifier'
import { toUpperSnakeCase } from '../../../restux/restux.utilities'

export const medicalEventQuestionnairesConfig: RestuxUiSagaConf = {
  resourceName: RestuxResources.medicalEventContent,
  identifier: RESTUX_IDENTIFIER.medicalEventContent,
}
const RESOURCE_PREFIX = `@${toUpperSnakeCase(RESTUX_IDENTIFIER.medicalEventContent)}_UI`

export const MedicalEventContentUiActionTypes = {
  SELECT_CONTENT: `${RESOURCE_PREFIX}/SELECT_CONTENT`,
}

export enum SelectedContentType {
  ADD_VARIABLE_PANEL = 'add_variable_panel',
  EDITOR = 'editor',
  VARIABLES = 'variables',
  QUESTIONNAIRE = 'questionnaire',
  PRESCRIPTION = 'prescription',
  ACTE = 'acte',
}

interface BaseSelectedContent {
  type: SelectedContentType
}

interface DocumentAndVariableSelectedContent extends BaseSelectedContent {
  type:
    | SelectedContentType.ADD_VARIABLE_PANEL
    | SelectedContentType.EDITOR
    | SelectedContentType.VARIABLES
}

interface SelectedContentQuestionnaire extends BaseSelectedContent {
  type: SelectedContentType.QUESTIONNAIRE
  questionnaireId: number
}

interface SelectedContentPrescription extends BaseSelectedContent {
  type: SelectedContentType.PRESCRIPTION
  prescriptionId: string
  intervalId?: string
}
interface SelectedContentActe extends BaseSelectedContent {
  type: SelectedContentType.ACTE
  acteId: string
}

export type SelectedContent =
  | DocumentAndVariableSelectedContent
  | SelectedContentQuestionnaire
  | SelectedContentPrescription
  | SelectedContentActe

export interface SelectedContentState {
  filter: SelectedContent
}

export interface MedicalEventContentState {
  inUse: RestuxInUse
  selected: SelectedContentState
}
