import { VariableEditorConfig } from '@/model/Config'
import { createContext } from 'react'

export const defaultVariableEditorConfig: VariableEditorConfig = {
  variableMap: {},
  renderVariableMenu: () => null,
  sanitizeHtmlVariable: (html: string) => html,
}

export const VariableEditorContext = createContext<VariableEditorConfig>(
  defaultVariableEditorConfig,
)
