import { useCallback } from 'react'
import { EphemeralNotification } from '../../../model/EphemeralNotification'
import { addMessage } from '../../../store/message'
import { useActionDispatch } from '../useActionDispatch.hook'

export const useEphemeralNotificationEvent = () => {
  const dispatchMessage = useActionDispatch(addMessage)

  const handleEphemeralNotificationEvent = useCallback(
    (event: EphemeralNotification) => {
      const { title, shortMessage, level, type, disableDuration } = event
      if (type === 'snackbar') {
        dispatchMessage({
          title,
          type: level,
          disableDuration,
          message: shortMessage,
        })
      }
    },
    [dispatchMessage],
  )

  return { handleEphemeralNotificationEvent }
}
