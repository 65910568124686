import { PosologyFormResource, PosologyPreview } from '../../../../model/Posology'
import { Drug } from '../../../../model/Drug'
import getApi from '../../../../api/apisauceConfig'
import { PrescriptionPosologyUpdate } from '../../../../model/Prescription'

const batchPosology = (prescription: PrescriptionPosologyUpdate, prescriptionUuid: string) =>
  getApi().put(`prescriptions/${prescriptionUuid}`, prescription)

const getPreview = (posologyIntervals: PosologyFormResource[], drugs: Drug[]) =>
  getApi().post<PosologyPreview>(`prescriptions/preview`, { drugs, posologyIntervals })

export { batchPosology, getPreview }
