import { RoundedButton } from '../../../../shared'
import { PatientTransversalNavigationNextEventButtonProps } from './PatientTransversalNavigationNextEventButton.model'

export const PatientTransversalNavigationNextEventButton = ({
  onNextEventClick,
}: PatientTransversalNavigationNextEventButtonProps) => (
  <RoundedButton
    label="Voir l'évènement suivant"
    icon="arrowRight"
    iconPosition="right"
    onClick={onNextEventClick}
    testId="button-nav_trans-next_event"
  />
)
