import { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { TaskPatientSearchProps } from './TaskPatientSearch.model'
import { LightPatient } from '../../../../../../model/Patient'
import { Icon, SelectSearch, TooltipWrapper } from '../../../../../shared'
import { getDisplayedName } from '../../../../../../misc/patient.utilities'
import { Link } from 'react-router-dom'
import { isEqual } from 'lodash'
import { useGetInfinitePatient } from '../../../../../../hooks/queries/patients'
import { useDebounce } from 'react-use'
import { SelectOption } from '../../../../../../model/SelectOption'

const PATIENT_FILTERS_INITIAL_STATE = { search: '' }

export const TaskPatientSearch: FunctionComponent<TaskPatientSearchProps> = ({
  selectedPatient,
  setSelectedPatient,
  isEditModeEnabled,
}) => {
  const [patientSearchText, setPatientSearchText] = useState('')
  const [patientFilters, setPatientFilters] = useState(PATIENT_FILTERS_INITIAL_STATE)
  const [patients, setPatients] = useState<LightPatient[]>([])
  const {
    query: { isLoading },
    patientList,
  } = useGetInfinitePatient({
    enabled: !isEqual(patientFilters, PATIENT_FILTERS_INITIAL_STATE),
    filters: patientFilters,
  })
  const displayedName = selectedPatient ? getDisplayedName(selectedPatient) : ''
  const patientDefaultName = !displayedName
    ? 'Aucun patient'
    : `${displayedName.firstname} ${displayedName.lastname}`

  useDebounce(
    () => {
      setPatientFilters((prev) => {
        return patientSearchText.length >= 3
          ? { ...prev, search: patientSearchText }
          : { ...prev, search: PATIENT_FILTERS_INITIAL_STATE.search }
      })
    },
    500,
    [setPatientFilters, patientSearchText],
  )

  useEffect(() => {
    if (!isLoading) {
      setPatients(patientList)
    }
  }, [patientList, isLoading])

  const selectedPatientOption = selectedPatient
    ? { value: selectedPatient, label: selectedPatient.identity }
    : undefined

  const patientOptions = useMemo(
    () =>
      !isEqual(patientFilters, PATIENT_FILTERS_INITIAL_STATE)
        ? patients.map((patient) => ({
            value: patient,
            label: patient.identity,
          }))
        : [],
    [patientFilters, patients],
  )

  const onSelectPatientOption = (patientOption?: SelectOption<LightPatient>) => {
    if (patientOption) {
      const displayedName = patientOption.value ? getDisplayedName(patientOption.value) : null
      setSelectedPatient(patientOption.value)
      setPatientSearchText(`${displayedName?.firstname} ${displayedName?.lastname}`)
    } else {
      setSelectedPatient()
      setPatientSearchText(PATIENT_FILTERS_INITIAL_STATE.search)
    }
  }

  return (
    <div className="w-60">
      {isEditModeEnabled ? (
        <SelectSearch<LightPatient>
          placeholder="Rechercher un patient"
          textValue={patientSearchText}
          onTextChange={(value) => {
            setPatientSearchText(value)
          }}
          value={selectedPatientOption}
          options={patientOptions}
          onSelect={onSelectPatientOption}
          clearable
        />
      ) : (
        <div className="flex">
          {selectedPatient && selectedPatient.id ? (
            selectedPatient.deletedAt === null ? (
              <>
                <TooltipWrapper content="Naviguer vers le patient">
                  <Link to={`/patients/${selectedPatient?.id}`}>
                    <span className="inline hover:cursor-pointer">{patientDefaultName}</span>
                  </Link>
                </TooltipWrapper>
              </>
            ) : (
              <>
                <TooltipWrapper content="Patient·e supprimé·e">
                  <span className="inline">{patientDefaultName}</span>
                </TooltipWrapper>
              </>
            )
          ) : (
            <>
              <span className="inline">{patientDefaultName}</span>
            </>
          )}
          <div className="mt-2 ml-3">
            <Icon size="femto" icon="chevron" />
          </div>
        </div>
      )}
    </div>
  )
}
