import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { MultiSelectOption } from '../../model/SelectOption'

function defaultAreElementsEquals<Y>(a: Y, b: Y) {
  return a === b
}

export function useMultiSelect<T>(
  value: Array<MultiSelectOption<T>>,
  options: MultiSelectOption<T>[],
  mode: 'wrap' | 'multiline',
  onSelect: (option: Array<MultiSelectOption<T>>) => void,
  areElementsEquals: (a: T, b: T) => boolean = defaultAreElementsEquals,
  emitChange?: (value: string) => void,
) {
  const [open, setOpen] = useState(false)
  const [textValue, setTextValue] = useState('')
  const [computedOptions, setComputedOptions] = useState(options)
  const [showTags, setShowTags] = useState(true)

  const inputRef = useRef<HTMLInputElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  const sortOptions = useCallback(
    () =>
      options
        .reduce(
          ([selected, notSelected], option) => {
            const isSelected = !!value.find((val) => areElementsEquals(option.value, val.value))
            return isSelected
              ? [[...selected, option], notSelected]
              : [selected, [...notSelected, option]]
          },
          [[], []] as MultiSelectOption<T>[][],
        )
        .flat(),
    [areElementsEquals, options, value],
  )

  const handleTextInput = (textValue: string) => {
    if (textValue !== '') {
      const filtered = options.filter((option) =>
        option.label.toLowerCase().includes(textValue.toLowerCase()),
      )
      setComputedOptions(filtered)
    } else {
      setComputedOptions((currentOptions) =>
        options.length !== currentOptions.length ? options : currentOptions,
      )
    }
    setTextValue(textValue)
    if (emitChange) {
      emitChange(textValue)
    }
  }

  const handleOpen = (isOpen: boolean) => {
    if (isOpen) {
      const sorted = sortOptions()

      setComputedOptions(sorted)
    } else {
      handleTextInput('')
    }
    setShowTags(true)
    setOpen(isOpen)
  }

  const handleSelect = (option: MultiSelectOption<T>) => {
    const filtered = value.filter((val) => !areElementsEquals(option.value, val.value))
    handleTextInput('')
    if (filtered.length < value.length) {
      onSelect(filtered)
    } else {
      onSelect([...value, option])
    }
  }

  useEffect(() => {
    if (open) {
      inputRef.current?.focus()
    }
  }, [open])

  useEffect(() => {
    setComputedOptions(sortOptions())
  }, [options, sortOptions])

  useLayoutEffect(() => {
    if (mode === 'wrap') {
      if (containerRef.current && !open && value.length > 0) {
        const { scrollWidth, offsetWidth } = containerRef.current
        setShowTags(scrollWidth <= offsetWidth)
      } else {
        setShowTags(false)
      }
    } else {
      setShowTags(value.length > 0)
    }
  }, [mode, open, value])

  return {
    open,
    showTags,
    textValue,
    computedOptions,
    inputRef,
    containerRef,
    handleOpen,
    handleSelect,
    handleTextInput,
  }
}
