import React, { FunctionComponent } from 'react'
import { DatePicker, InputWrapper, Switch } from '../../../shared'
import { PatientInformationsFormProps } from './PatientInformationsForm.model'
import styles from './PatientInformationsForm.module.scss'
import { addTestIdSuffix } from '../../../../misc/testId.utilities'

export const PatientInformationsForm: FunctionComponent<PatientInformationsFormProps> = ({
  patientInformations,
  setPatientInformations,
  testId,
}) => {
  return (
    <div className="bg-gray-50 p-4 pb-6 w-full" data-test-id={addTestIdSuffix('root', testId)}>
      <div className="text-gray-500 mb-6">Merci de préciser les informations ci-dessous</div>
      <div className="space-y-6">
        <InputWrapper
          disabled={!!patientInformations.codedInformationId}
          title="Allergie, antécédent, pathologie ou commentaire"
        >
          <input
            className="text-shades-2 font-medium text-base w-full"
            disabled={!!patientInformations.codedInformationId}
            value={`${patientInformations.name}${
              patientInformations.codedInformation?.cim10
                ? ` (${patientInformations.codedInformation?.cim10})`
                : ''
            }`}
            onChange={(e) =>
              setPatientInformations({ ...patientInformations, name: e.currentTarget.value })
            }
            data-test-id={addTestIdSuffix('field-name', testId)}
          />
        </InputWrapper>
        <div className={styles.whiteBackground}>
          <DatePicker
            label="Date de début"
            value={patientInformations.startAt}
            onChange={(date) => setPatientInformations({ ...patientInformations, startAt: date })}
            placeholder="jj/mm/aaaa"
            showCalendarButton={false}
            clearable
            testId={addTestIdSuffix('field-start-at', testId)}
          />
        </div>
        <div className={styles.whiteBackground}>
          <DatePicker
            label="Date de fin"
            value={patientInformations.endAt}
            onChange={(date) => setPatientInformations({ ...patientInformations, endAt: date })}
            placeholder="jj/mm/aaaa"
            showCalendarButton={false}
            clearable
            testId={addTestIdSuffix('field-end-at', testId)}
          />
        </div>
        {patientInformations.codedInformation?.ald && (
          <div className="flex flex-col bg-white border border-shades-5 rounded-md">
            <div className="p-3">
              <span className="text-shades-3">{`ALD n°${patientInformations.codedInformation?.ald?.code} : ${patientInformations.codedInformation?.ald?.title}`}</span>
              <div className="font-semibold text-sm text-shades-2">
                <Switch
                  name="Marquer comme ALD pour le patient"
                  checked={patientInformations.isAld}
                  onChange={(checked) =>
                    setPatientInformations({ ...patientInformations, isAld: checked })
                  }
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
