import { AtcClass } from '../../../model/Drug'
import { RootState } from '../../reducers/reducers.model'
import { PageList } from '../../restux/ui'
import { restuxResolvePage } from '../../restux/ui/restuxUi.selectors'

export const atcClassificationPaginationSelector = (state: RootState) =>
  state.ui.atcClassification.pagination

export const atcClassificationPageSelector = (state: RootState): PageList<AtcClass> => {
  const pagination = atcClassificationPaginationSelector(state)
  const resource = state.cache.atcClassification.list
  return restuxResolvePage(resource, pagination)
}

export const inUseAtcClassIdSelector = (state: RootState) =>
  state.ui.atcClassification.inUse.inUseId

export const inUseAtcClassSelector = (state: RootState): AtcClass | null => {
  const inUseId = inUseAtcClassIdSelector(state)
  if (inUseId) {
    const cacheAtcClass = state.cache.atcClassification.details[inUseId]
    return cacheAtcClass || null
  }
  return null
}
