import { useQuery } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { useMemo } from 'react'
import { patientHealthDataKeys } from './patientHealthData.keys'
import { getPatientAlerts } from '../../../data/patientHealthData'
import { PatientAlert } from '../../../model/HealthData'

interface GetAllPatientAlertsParams {
  patientId: number
  enabled?: boolean
}

export const useGetPatientAlerts = ({ patientId, enabled = true }: GetAllPatientAlertsParams) => {
  const queryKey = useMemo(() => patientHealthDataKeys.listAlerts(patientId), [patientId])

  const queryReturn = useQuery<Array<PatientAlert>>(
    queryKey,
    async () => {
      const { data, ok } = await getPatientAlerts(patientId)
      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          'Impossible de récupérer les alertes du patient',
          "Une erreur technique s'est produite",
        )
      }
      return data
    },
    {
      enabled: enabled,
    },
  )

  return { query: queryReturn, patientAlertList: queryReturn.data || [] }
}
