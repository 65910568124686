import { Editor } from '@tiptap/react'

/**
 * Trouve la propriété spécifiée sur le noeud actif, si le noeud fait partie des types spécifiés
 * @param editor L'éditeur actif
 * @param types Les types de noeuds à inspecter, souvent options.types d'un plugin
 * @param property La propriété recherchée
 * @returns La première occurrence de la propriété trouvée, sinon null
 *
 * @example Récupérer l'interligne sur les paragraphes et titres
 * ```ts
 * findAttributesAmongTypes(editor, ['paragraph', 'heading'], 'lineheight')
 * ```
 */
export const findAttributesAmongTypes = (
  editor: Editor,
  types: Array<string>,
  property: string,
) => {
  for (let i in types) {
    const type = types[i] ?? ''
    const prop = editor.getAttributes(type)[property]
    if (!!prop) {
      return prop
    }
  }
  return null
}
