import { connect } from 'react-redux'
import { DocumentActions } from '../../store/ui/document/document.actions'
import DocumentEditor from './DocumentEditor.component'
import { DocumentEditorDispatchProps } from './DocumentEditor.model'

const mapDisptachToProps: DocumentEditorDispatchProps = {
  addImageToDocument: DocumentActions.addImageToDocument,
}

export default connect(null, mapDisptachToProps)(DocumentEditor)
