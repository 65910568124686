import { isValid, parse } from 'date-fns'
import * as Yup from 'yup'
import { DATE_FORMAT_FRONT_SHORT, FR_SHORT_DATE_REGEX } from '../../../misc/date.utilities'
import {
  BIRTH_PLACE_CODE_REGEX,
  FIRST_NAME_REGEX,
  IdentityStatus,
  NAME_REGEX,
  PHONE_REGEX,
} from '../../../model/Patient'
import { ObjectSchema } from 'yup'

export const getValidationSchema = (
  hasAccessToInsOid: boolean,
  isInsiFeatureEnabled: boolean,
): ObjectSchema => {
  return hasAccessToInsOid && isInsiFeatureEnabled
    ? PatientValidationSchema.concat(PatientOidValidationSchema)
    : PatientValidationSchema
}

export const PatientValidationSchema = Yup.object().shape({
  birthFirstName: Yup.string()
    .required('Obligatoire')
    .matches(
      FIRST_NAME_REGEX,
      'Seules les lettres sans accent, tirets, apostrophes et espaces sont autorisés',
    )
    .when(
      ['identityStatus', 'otherFirstNames'],
      (identityStatus: IdentityStatus, otherFirstNames: string, schema: Yup.StringSchema) => {
        if (identityStatus.insiStatus.checked === 'VALIDATED') {
          return schema.test(
            'insi check firstnames coherence',
            "Le prénom de naissance n'est pas conforme au retour INSi",
            (currentValue: string) => {
              const [first, ...nameList] = otherFirstNames.toLocaleLowerCase().split(' ')
              const sep = '[ -]'

              const nestNames = ([first, ...rest]: string[]) => {
                if (first) {
                  return `(?:${sep}${first}${nestNames(rest)})?`
                } else {
                  return ''
                }
              }

              const serialized = '^' + first + nestNames(nameList) + '$'
              const regex = RegExp(serialized)

              return regex.test(currentValue.toLocaleLowerCase())
            },
          )
        } else {
          return schema
        }
      },
    ),
  birthLastName: Yup.string()
    .required('Obligatoire')
    .matches(
      NAME_REGEX,
      'Seules les lettres majuscules sans accent, tirets, apostrophes et espaces sont autorisés',
    ),
  birthDate: Yup.string()
    .test('birthDate', 'La date ne respecte pas le format "jj/mm/aaaa"', (date) => {
      return (
        isValid(parse(date, DATE_FORMAT_FRONT_SHORT, new Date())) &&
        date.match(FR_SHORT_DATE_REGEX) !== null
      )
    })
    .required('Obligatoire'),
  sex: Yup.string().required('Obligatoire'),
  birthPlaceCode: Yup.string()
    .matches(
      BIRTH_PLACE_CODE_REGEX,
      'Saisir un code lieu de naissance valide (ex: 75110, 2A011, ...)',
    )
    .required('Obligatoire'),
  profession: Yup.string(),
  email: Yup.string().email('Format invalide'),
  telephone1: Yup.string().matches(PHONE_REGEX, 'Chiffres et "+" uniquement'),
  telephone2: Yup.string().matches(PHONE_REGEX, 'Chiffres et "+" uniquement'),
  doctor: Yup.string(),
  inseeNumber: Yup.string(),
  healthInsuranceNumber: Yup.string(),
  note: Yup.string(),
  address: Yup.object()
    .shape({
      street2: Yup.string().nullable(),
      street: Yup.string().nullable(),
      zipCode: Yup.string().nullable(),
      city: Yup.string().nullable(),
      country: Yup.string().nullable(),
    })
    .nullable(),
})

export const PatientOidValidationSchema = Yup.object().shape({
  assigningAuthority: Yup.object()
    .shape({
      oid: Yup.string().required('Obligatoire'),
    })
    .required('Obligatoire'),
})
