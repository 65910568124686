import React, { FunctionComponent, memo, useCallback, useMemo } from 'react'
import styles from './TeamCard.module.scss'
import {
  EntityCardHeader,
  EntityCard,
  EntityCardContent,
  HeaderTitle,
  HeaderActions,
  IconButton,
  Heading,
} from '../../shared'
import { TeamCardBadgeList } from './TeamCardBadgeList'
import { MemberUserList } from '../../user'
import { TeamCardProps } from './TeamCard.model'

export const TeamCard: FunctionComponent<TeamCardProps> = memo(function TeamCard({
  team,
  readonly,
  onOpen,
  onDelete,
}) {
  const users = useMemo(() => {
    return team.memberUsers.map((item) => ({
      firstName: item.firstName,
      familyName: item.familyName,
      professionTitle: item.userType.professionTitle,
      isContact: !!team.contactUsers.map((item) => item.id).includes(item.id),
      id: item.id,
    }))
  }, [team])

  const handleDelete = useCallback(() => {
    if (onDelete) {
      onDelete(team)
    }
  }, [onDelete, team])

  const handleOpen = useCallback(() => onOpen(team), [onOpen, team])

  return (
    <EntityCard loading={false} inactive={false}>
      <EntityCardHeader>
        <HeaderTitle>
          <Heading size={5}>
            <span className={styles.teamName}>{team.name}</span>
          </Heading>
        </HeaderTitle>
        <HeaderActions>
          <IconButton
            onClick={handleOpen}
            icon={readonly ? 'eye' : 'pencil'}
            size="micro"
            theme="light"
          />
          {onDelete && (
            <IconButton onClick={handleDelete} icon="trash" size="micro" theme="light" />
          )}
        </HeaderActions>
      </EntityCardHeader>
      <EntityCardContent>
        <MemberUserList users={users} />
        <div className={styles.divider} />
        <TeamCardBadgeList sharingConfig={team.sharingConfig} />
      </EntityCardContent>
    </EntityCard>
  )
})
