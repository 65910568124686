import { connect } from 'react-redux'

import { RootState } from '../../store/index'

import { MainLayoutReduxProps } from './MainLayout.model'
import MainLayout from './MainLayout.component'
import { currentUserSelector } from '../../store/domain/me'

const mapStateToProps = (state: RootState): MainLayoutReduxProps => ({
  bottomPanelIsOpen: state.ui.bottomPanel.open,
  currentUser: currentUserSelector(state),
})

export default connect(mapStateToProps)(MainLayout)
