import { VariablesData } from '@follow/farte'
import { RenderMode, AnswerPartsMode, VariablesDataJson } from '@follow/cdk'
import { DocumentCategory } from '../../model/DocumentCategory'

export { RenderMode, AnswerPartsMode }

export enum RendererActionTypes {
  RENDER_DOCUMENT_TEMPLATE = '@RENDER/RENDER_DOCUMENT_TEMPLATE',
  SEND_DOCUMENT = '@RENDER/SEND_DOCUMENT',
  GET_VARIABLE_DATA = '@RENDER/GET_VARIABLE_DATA',
  SET_VARIABLE_DATA = '@RENDER/SET_VARIABLE_DATA',
  SET_VARIABLE_DATA_LOADING = '@RENDER/SET_VARIABLE_DATA_LOADING',
}

export interface ReduxRenderer {
  blobToPrint?: Blob
  variableData: VariablesData | null
  isVariableDataLoading: boolean
}

export interface RenderableDocument {
  type: 'farte'
  documentId: number
}

export interface RenderDocumentsConfig {
  format?: FarteDocumentFormat
  fileName?: string
  lockAfterPrintDocumentIds?: Array<number> // À retirer avec le DEV-3232
}

export type BasicDownloadMode = 'print' | 'download'
export type DownloadMode = BasicDownloadMode | 'send' | 'dmp'
export type FarteDocumentFormat = 'pdf' | 'cda'

// À retirer avec https://fwhealth.atlassian.net/browse/DEV-3321
export interface RenderableDocumentTemplate {
  variablesData: VariablesDataJson
  type: 'farte'
  content: RenderableValue
  category: DocumentCategory
  layout?: {
    documentLayoutId: number | undefined
  }
}

interface RenderableValue {
  documentId: number
  renderer?: string
}

export interface RenderDocumentTemplateInfos {
  document: RenderableDocumentTemplate
  fileName: string
}
