import { FunctionComponent } from 'react'
import { MailHeader } from './MailHeader'
import { MailContentProps } from './MailContent.model'
import { MailBody } from './MailBody'
import { MailAttachments } from './MailAttachments'

export const MailContent: FunctionComponent<MailContentProps> = ({
  inUseMail,
  selectedAttachmentId,
  selectedFile,
  setSelectedAttachmentId,
  handleGetAttachmentFile,
  handleDownloadOrPrintFile,
  onClear,
}) => {
  return (
    <div className="w-full flex flex-col grow-2 py-2">
      <div className="mx-auto w-3/4">
        <div className="mt-4">
          <MailHeader inUseMail={inUseMail} />
        </div>
        <MailBody inUseMail={inUseMail} />
        {inUseMail.attachments.length > 0 && (
          <MailAttachments
            mailId={inUseMail.id}
            attachments={inUseMail.attachments}
            selectedAttachmentId={selectedAttachmentId}
            selectedFile={selectedFile}
            setSelectedAttachmentId={setSelectedAttachmentId}
            handleGetAttachmentFile={handleGetAttachmentFile}
            handleDownloadOrPrintFile={handleDownloadOrPrintFile}
            onClear={onClear}
          />
        )}
      </div>
    </div>
  )
}
