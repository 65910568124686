import { DocumentCategory } from '../../../model/DocumentCategory'
import { RestuxResources } from '../../resources'
import { toUpperSnakeCase } from '../../restux/restux.utilities'

const RESOURCE_PREFIX = `@${toUpperSnakeCase(RestuxResources.documentCategories)}`

export const domainDocumentCategoriesActionTypes = {
  FETCH_USED_DOCUMENT_CATEGORIES: `${RESOURCE_PREFIX}/FETCH_USED_DOCUMENT_CATEGORIES`,
  STORE_USED_DOCUMENT_CATEGORIES: `${RESOURCE_PREFIX}/STORE_USED_DOCUMENT_CATEGORIES`,
}

export interface DocumentCategoriesState {
  usedDocumentCategories: Array<DocumentCategory>
}
