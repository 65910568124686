import { createSelector } from 'reselect'
import { RootState } from '../../reducers'

const dmpRequestRawSelector = (state: RootState) => {
  return state.domain.dmp.request
}

export const dmpRequestSelector = createSelector([dmpRequestRawSelector], (request) => request)

const stateHealthcareSettingsSelector = (state: RootState) => state.domain.dmp.healthcareSettings

export const healthcareSettingsSelector = createSelector(
  [stateHealthcareSettingsSelector],
  (settings) => settings,
)
