import React, { FunctionComponent } from 'react'

import { ImageViewerProps } from './ImageViewer.model'

import styles from './ImageViewer.module.scss'

export const ImageViewer: FunctionComponent<ImageViewerProps> = ({ image }) => (
  <img
    className={styles.image}
    src={image.base64}
    title={image.visibleName}
    alt={image.visibleName}
  />
)
