import { formatISO, parseISO } from 'date-fns'
import { PatientInformations } from '../../../model/PatientInformations'
import { PatientInformationsForm, SerializedPatientInformations } from './patientInformations.model'
import { ApiResponse } from 'apisauce'
import { PaginatedList } from '../../../model/Pagination'

export const deserializePatientInformations = (
  item: SerializedPatientInformations,
): PatientInformations => ({
  ...item,
  startAt: item.startAt ? parseISO(item.startAt) : undefined,
  endAt: item.endAt ? parseISO(item.endAt) : undefined,
})

export const deserializePatientInformationsPaginatedResponse = (
  response: ApiResponse<PaginatedList<SerializedPatientInformations>>,
): ApiResponse<PaginatedList<PatientInformations>> => {
  const { data, ...responseRest } = response
  if (!response.ok || !data) {
    return { ...responseRest }
  }
  return {
    ...responseRest,
    data: { ...data, items: data.items.map(deserializePatientInformations) },
  }
}

export const deserializePatientResponse = (
  response: ApiResponse<SerializedPatientInformations>,
): ApiResponse<PatientInformations> => {
  const { data, ...responseRest } = response
  if (!response.ok || !data) {
    return { ...responseRest }
  }
  return {
    ...responseRest,
    data: deserializePatientInformations(data),
  }
}

export const serializePatientInformations = (
  patientInformations: Partial<PatientInformations>,
): PatientInformationsForm => {
  return {
    ...patientInformations,
    startAt: patientInformations.startAt ? formatISO(patientInformations.startAt) : null,
    endAt: patientInformations.endAt ? formatISO(patientInformations.endAt) : null,
  }
}
