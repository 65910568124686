import { isDoctor } from '../../../misc/user.utilities'
import { Mail, MailListPage } from '../../../model/Mail'
import { RequestStatus } from '../../../model/RequestStatus'
import { RootState } from '../../reducers'
import { currentUserSelector } from '../me'

export const loginInitateStatusSelector = (state: RootState): boolean => {
  return state.domain.mail.loginInitate
}

export const otpStatusSelector = (state: RootState): boolean => {
  return state.domain.mail.otpStatus
}

export const mailAddressListSelector = (state: RootState): Array<string> => {
  return state.domain.mail.emailAddress
}

export const inUseMailAddressSelector = (state: RootState): string | undefined => {
  return state.domain.mail.inUseMailAddress
}

export const mailListSelector = (state: RootState): MailListPage => {
  return state.domain.mail.mailList
}

export const inUseMailSelector = (state: RootState): Mail | undefined => {
  return state.domain.mail.inUseMail
}

export const inUseMailStatusSelector = (state: RootState): RequestStatus => {
  return state.domain.mail.inUseMailStatus
}

export const requestMailsStatus = (state: RootState) => {
  return state.domain.mail.requestStatus
}

export const fetchingNextMailsStatusSelector = (state: RootState) => {
  return state.domain.mail.fetchingNextMailsStatus
}

export const selectFilesToSend = (state: RootState): File[] => {
  return state.domain.mail.filesToSend
}

export const blackListedPatientMails = (state: RootState): Array<string> => {
  return state.domain.mail.blackListedPatientMails
}

export const unqualifiedPatientEmails = (state: RootState): Array<string> => {
  return state.domain.mail.unqualifiedPatientEmails
}

export const inUseAttachmentSelector = (state: RootState) => {
  return state.domain.mail.inUseAttachment
}

export const isCreatePatientThroughMailModalOpenSelector = (state: RootState) =>
  state.domain.mail.isCreatePatientThroughMailModalOpen

export const isCurrentUserLoggedToMss = (state: RootState) => {
  const otpStatus = otpStatusSelector(state)
  const currentUser = currentUserSelector(state)

  const isSmtpMssSetup =
    isDoctor(currentUser) &&
    currentUser.preferences.enabledFeatures.mssSmtp &&
    !!currentUser.mailizEmailAddress
  return otpStatus || !!isSmtpMssSetup
}

export const mailSearchSelector = (state: RootState): string | undefined => {
  return state.domain.mail.search
}
