import React, { FunctionComponent } from 'react'
import classnames from 'classnames'

import { PlaceholderProps } from './Placeholder.model'

import styles from './Placeholder.module.scss'
import { Icon } from '../../shared'

const Placeholder: FunctionComponent<PlaceholderProps> = ({ children, label, testId, onClick }) => {
  const onMouseDown = (event) => {
    event.preventDefault()
    if (onClick) {
      onClick()
    }
  }
  return (
    <div className={styles.container} onMouseDown={onMouseDown} data-test-id={testId}>
      <div className={styles.box}>{children}</div>
      <label className={styles.label}>{label}</label>
    </div>
  )
}

export const Qcu: FunctionComponent<PlaceholderProps> = ({ onClick, label = 'QCU' }) => (
  <Placeholder onClick={onClick} label={label} testId="question_placeholder-qcu">
    <div className={classnames(styles.row, styles.active)}>
      <div className={classnames(styles.input, styles.radio)}></div>
      <div className={styles.text}></div>
    </div>
    <div className={classnames(styles.row)}>
      <div className={classnames(styles.input, styles.radio)}></div>
      <div className={styles.text}></div>
    </div>
    <div className={classnames(styles.row)}>
      <div className={classnames(styles.input, styles.radio)}></div>
      <div className={styles.text}></div>
    </div>
  </Placeholder>
)

export const Qcm: FunctionComponent<PlaceholderProps> = ({ onClick, label = 'QCM' }) => (
  <Placeholder onClick={onClick} label={label} testId="question_placeholder-qcm">
    <div className={classnames(styles.row, styles.active)}>
      <div className={classnames(styles.input, styles.checkbox)}></div>
      <div className={styles.text}></div>
    </div>
    <div className={classnames(styles.row, styles.active)}>
      <div className={classnames(styles.input, styles.checkbox)}></div>
      <div className={styles.text}></div>
    </div>
    <div className={classnames(styles.row)}>
      <div className={classnames(styles.input, styles.checkbox)}></div>
      <div className={styles.text}></div>
    </div>
  </Placeholder>
)

export const Text: FunctionComponent<PlaceholderProps> = ({ onClick, label = 'Texte Libre' }) => (
  <Placeholder onClick={onClick} label={label} testId="question_placeholder-text">
    <div className={classnames(styles.row)}>
      <div className={classnames(styles.text, styles.short)}></div>
    </div>
    <div className={classnames(styles.row, styles.active)}>
      <div className={classnames(styles.text, styles.long1)}></div>
    </div>
    <div className={classnames(styles.row, styles.active)}>
      <div className={classnames(styles.text, styles.long2)}></div>
    </div>
    <div className={classnames(styles.row, styles.active)}>
      <div className={classnames(styles.text, styles.long3)}></div>
    </div>
  </Placeholder>
)

export const Slider: FunctionComponent<PlaceholderProps> = ({ onClick, label = 'Slider' }) => (
  <Placeholder onClick={onClick} label={label} testId="question_placeholder-slider">
    <div className={classnames(styles.row)}>
      <div className={classnames(styles.slider)}></div>
    </div>
  </Placeholder>
)

export const Date: FunctionComponent<PlaceholderProps> = ({ onClick, label = 'Date' }) => (
  <Placeholder onClick={onClick} label={label} testId="question_placeholder-date">
    <div className="flex h-full fill-current text-shades-3 justify-center items-center">
      <Icon icon="calendar" size="mega" />
    </div>
  </Placeholder>
)
