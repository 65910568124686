import { connect } from 'react-redux'
import { RootState } from '../../../store'

import { bottomPanelBaseVariablesActions } from '../../../store/ui/bottomPanelBaseVariables'
import { baseVariableListTypeSelector } from '../../../store/ui/bottomPanelBaseVariables/bottomPanelBaseVariables.selectors'

import SearchBaseVariable from './SearchBaseVariable.component'
import { SearchBaseVariableDispatchProps } from './SearchBaseVariable.model'

const mapStateToProps = (state: RootState) => ({
  baseVariableListType: baseVariableListTypeSelector(state),
})

const mapDisptachToProps: SearchBaseVariableDispatchProps = {
  selectBaseVariable: bottomPanelBaseVariablesActions.selectBaseVariable,
}

export default connect(mapStateToProps, mapDisptachToProps)(SearchBaseVariable)
