import { useCallback, useEffect, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Waypoint from 'react-waypoint'
import classNames from 'classnames/bind'
import { useAtomValue, useSetAtom } from 'jotai'
import { useGetFile } from '../../../../../hooks/queries/files'
import { getFileKind, isImageExtension, isPdfExtension } from '../../../../../misc/files.utilities'
import { MedicalEventContentTypeIcon } from '../../../../../model/MedicalEvent'
import { Loader } from '../../../../shared'
import { EventFileProps } from '../PatientTransversalNavigationEventDetails.model'
import {
  PatientTransversalNavigationEventDocHeader,
  EventDocumentType,
} from '../PatientTransversalNavigationEventDocHeader'
import { FileViewer } from '../../../../file'
import { filesDomainActions } from '../../../../../store/domain/files'
import { useActionDispatch } from '../../../../../hooks/utils'
import { useScrollToEventDocOrFile } from '../useScrollToEventDocOrFile.hook'
import { BackgroundColors } from '../../../../file/FileViewer/FileViewer.model'
import styles from './PatientTransversalNavigationEventFile.module.scss'
import {
  exitFullScreenAtom,
  scrolledItemIdAtom,
  ScrollHighlightStatus,
  scrollHighlightStatusAtom,
} from '../../../../../state/navTrans'

const cx = classNames.bind(styles)

export const PatientTransversalNavigationEventFile = ({
  id,
  isSelected,
  isFirstDocOrFile,
  isEventEditable,
  allowWaypointDetection,
}: EventFileProps) => {
  const dispatchPrintAction = useActionDispatch(filesDomainActions.print)
  const scrolledDocOrFileId = useAtomValue(scrolledItemIdAtom)
  const setScrolledDocOrFileId = useSetAtom(scrolledItemIdAtom)
  const setScrollHighlightStatus = useSetAtom(scrollHighlightStatusAtom)
  const setExitFullScreenState = useSetAtom(exitFullScreenAtom)

  const location = useLocation()
  const navigate = useNavigate()

  const {
    query: { data: file, isLoading },
  } = useGetFile({ fileId: id })

  useScrollToEventDocOrFile(id, EventDocumentType.File, isLoading, isSelected, isFirstDocOrFile)

  const handlePrint = useCallback(() => {
    file && dispatchPrintAction(file)
  }, [file, dispatchPrintAction])

  const handleVisibleWaypoint = useCallback(
    () => allowWaypointDetection && setScrolledDocOrFileId(id),
    [allowWaypointDetection, setScrolledDocOrFileId, id],
  )

  const focused = useMemo(
    () => (isSelected && scrolledDocOrFileId === id) || scrolledDocOrFileId === id,
    [id, isSelected, scrolledDocOrFileId],
  )

  const handleEnterFullScreen = useCallback(() => {
    setScrollHighlightStatus(ScrollHighlightStatus.DISABLED)
    if (scrolledDocOrFileId !== id) {
      navigate(`${location.search}#file=${id}`)
    }
  }, [id, location.search, navigate, scrolledDocOrFileId, setScrollHighlightStatus])

  const handleExitFullScreen = useCallback(() => {
    isSelected && setExitFullScreenState(true)
  }, [isSelected, setExitFullScreenState])

  useEffect(() => {
    return () => setScrolledDocOrFileId(null)
  }, [setScrolledDocOrFileId])

  if (isLoading) return <Loader />
  if (!file) return <p>Impossible de récupérer le fichier</p>

  return (
    <div
      id={`file-${id}`}
      className={cx({
        image: isImageExtension(file.extension),
        pdf: isPdfExtension(file.extension),
      })}
    >
      <PatientTransversalNavigationEventDocHeader
        id={file.id}
        title={file.visibleName}
        icon={MedicalEventContentTypeIcon[getFileKind(file.extension)]}
        type={EventDocumentType.File}
        isEventEditable={isEventEditable}
        onPrint={isPdfExtension(file.extension) ? handlePrint : undefined}
      />
      <Waypoint key={id} bottomOffset="65%" topOffset="20%" onEnter={handleVisibleWaypoint} />
      <div className={cx('fileContainer', { focused })}>
        <FileViewer
          file={file}
          backgroundColor={BackgroundColors.white}
          onEnterFullScreen={handleEnterFullScreen}
          onExitFullScreen={handleExitFullScreen}
        />
      </div>
    </div>
  )
}
