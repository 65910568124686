import { createContext, ReactNode } from 'react'

import { Question } from '../../../model/Questionnaire'

interface QuestionContextType {
  renderImportantAction?: (question: Question) => ReactNode
  singleColumnDisplay?: boolean
  scrollToAnswerId?: number
}

export const QuestionContext = createContext<QuestionContextType>({})
