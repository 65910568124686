import { connect } from 'react-redux'
import { compose, branch, renderComponent } from 'recompose'

import { RootState } from '../../../store/index'
import { filesDomainActions } from '../../../store/domain/files/index'
import {
  inUseMedicalEventSelector,
  medicalEventDocumentFilterSelector,
  medicalEventUiActions,
  selectedMedicalEventDocumentSelector,
} from '../../../store/ui/medicalEvents'
import { medicalEventDomainActions } from '../../../store/domain/medicalEvents/index'

import { MedicalEventFilters } from './MedicalEventFilters.component'
import {
  MedicalEventFiltersProps,
  MedicalEventFiltersStoreProps,
  MedicalEventFiltersDispatchProps,
} from './MedicalEventFilters.model'
import { MedicalEventFiltersPlaceholder } from './MedicalEventFilters.placeholder'
import { domainDocumentInstancesActions } from '../../../store/domain/documentInstances'
import { dmpDomainActions } from '../../../store/domain/dmp'
import { enabledFeaturesPreferencesSelector } from '../../../store/domain/me'

const mapStateToProps = (state: RootState): MedicalEventFiltersStoreProps => ({
  medicalEvent: inUseMedicalEventSelector(state),
  medicalEventDocumentFilter: medicalEventDocumentFilterSelector(state),
  medicalEventSelectedDocument: selectedMedicalEventDocumentSelector(state),
  files: state.cache.files.details,
  userEnabledFeatures: enabledFeaturesPreferencesSelector(state),
})

const mapDispatchToProps: MedicalEventFiltersDispatchProps = {
  initializeFromUrl: medicalEventDomainActions.initializeFromUrl,
  selectMedicalEventDocument: medicalEventUiActions.selectMedicalEventDocument,
  downloadFile: filesDomainActions.download,
  printFile: filesDomainActions.selectAndPrint,
  medicalEventDeleteDocument: medicalEventDomainActions.deleteDocument,
  updateDocument: medicalEventDomainActions.updateDocumentTitle,
  addFilesToMedicalEvent: medicalEventDomainActions.addFilesToMedicalEvent,
  lockDocument: domainDocumentInstancesActions.lockDocument,
  renewPrescription: domainDocumentInstancesActions.renewPrescription,
  deleteFromDMP: dmpDomainActions.deleteDocument,
  uploadToDMP: dmpDomainActions.uploadDocument,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)
const withBranch = branch<MedicalEventFiltersProps>(
  ({ medicalEvent }) => !medicalEvent,
  renderComponent(MedicalEventFiltersPlaceholder),
)

const withCompose = compose<MedicalEventFiltersProps, {}>(
  withConnect,
  withBranch,
)(MedicalEventFilters)

export { withCompose as MedicalEventFilters }
