import { FollowTypes } from '../model'

export function computeAddressSummary(
  address: FollowTypes.Address.Address | null,
  withLineBreak: boolean = true,
) {
  if (address !== null) {
    const { city, street, street2, zipCode } = address
    const requiredValues = [city, street, zipCode]

    if (
      requiredValues.includes(null) ||
      requiredValues.filter((value) => value !== '').length === 0
    ) {
      return null
    }

    const segmentSeparator = withLineBreak ? '\n' : ' '
    const baseAdress = `${street}${segmentSeparator}${zipCode} ${city}`

    if (street2 === null || street2.length === 0) {
      return baseAdress
    } else {
      return `${street2}${withLineBreak ? '' : ','}${segmentSeparator}${baseAdress}`
    }
  } else {
    return null
  }
}

export function computePraticeLocationSummary(
  location: FollowTypes.Address.PracticeLocation | null,
  withLineBreak: boolean = true,
) {
  if (location !== null) {
    const address = computeAddressSummary(location.address, withLineBreak)
    if (address === null) {
      return address
    }
    return `${
      location.name ? `${location.name}${withLineBreak ? '\n' : ', '}${address}` : `${address}`
    }`
  } else {
    return null
  }
}
