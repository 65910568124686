import { FunctionComponent, useMemo } from 'react'
import { ContactOrganization } from '../../../model/Contact'
import { SuggestionItem, Tag } from '../../shared'
import { ContactSuggestionProps } from './ContactSuggestion.model'
import styles from './ContactSuggestion.module.scss'

const isAddressDefined = (
  organization: ContactOrganization | null,
): organization is ContactOrganization => {
  return organization?.address !== null
}

export const ContactSuggestion: FunctionComponent<ContactSuggestionProps> = ({
  contact,
  hovered,
  onClick,
}) => {
  const organizations = useMemo(
    () =>
      'organizations' in contact
        ? contact.organizations
            .filter(isAddressDefined)
            .map(({ address }) => (address ? `${address.zipCode} - ${address.city}` : ''))
            .join(', ')
        : null,
    [contact],
  )

  return (
    <SuggestionItem hover={hovered} onClick={onClick}>
      <div className={styles.contactSuggestion}>
        <div className={styles.infos}>
          {'organizations' in contact && contact.title && (
            <span className={styles.title}>{contact.title}</span>
          )}
          <span className={styles.firstName}>{contact.firstName}</span>
          <span className={styles.familyName}>{contact.familyName}</span>
          <div className={styles.profession}>
            {contact.profession?.description}
            {contact.speciality && ` -  ${contact.speciality.description}`}
          </div>
          {'organizations' in contact && <div className={styles.organization}>{organizations}</div>}
        </div>
        {'organizations' in contact && contact.private && (
          <Tag>
            <span className={styles.tag}>Privé</span>
          </Tag>
        )}
      </div>
    </SuggestionItem>
  )
}
