import React, { Suspense } from 'react'

import { lazyWithRetry } from '../../../../misc/lazy.utilities'
import { PdfViewerProps } from './PdfViewer.model'
import { Loader } from '../../../shared'

const LazyPdfViewer = lazyWithRetry(() =>
  import(/* webpackPreload: true, webpackChunkName: "pdfviewer" */ './PdfViewer.component').then(
    ({ PdfViewer }) => PdfViewer,
  ),
)

export const PdfViewerLegacy = (props: PdfViewerProps) => (
  <Suspense fallback={<Loader />}>
    <LazyPdfViewer {...props} />
  </Suspense>
)
