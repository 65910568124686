import React, { FunctionComponent } from 'react'
import styles from './ListItem.module.scss'
import classNames from 'classnames/bind'
import { ListItemProps } from './ListItem.model'
import { IconButton } from '../../buttons'
import { Checkbox, CheckboxState } from '../../inputs'

const cx = classNames.bind(styles)

export const ListItem: FunctionComponent<ListItemProps> = ({
  onSelect,
  children,
  selected = false,
  focused = false,
  testId,
  itemId,
  onEdit,
  onDelete,
  onPrimaryAction,
  customActionsNode,
}) => (
  <>
    <div
      className={cx(styles.container, {
        primaryAction: onPrimaryAction,
        focused,
      })}
      data-test-id={testId ? `list_item-${testId}-${itemId}` : undefined}
    >
      <div className={styles.itemCheck}>
        {onSelect && (
          <Checkbox
            onChange={onSelect}
            checked={selected ? CheckboxState.CHECKED : CheckboxState.UNCHECKED}
          />
        )}
      </div>
      {/* onPrimaryAction est placé sur le content pour eviter les missclicks de selection en début de ligne */}
      <div className={styles.itemContent} onClick={onPrimaryAction}>
        {children}
      </div>
      {(onEdit || onDelete || customActionsNode) && (
        <div className={styles.itemActions}>
          {onEdit && (
            <IconButton
              onClick={onEdit}
              icon="pencil"
              theme="light"
              size="micro"
              title="Editer"
              testId={`button-edit-${testId}`}
            />
          )}
          {onDelete && (
            <IconButton
              onClick={onDelete}
              icon="trash"
              theme="light"
              size="micro"
              title="Supprimer"
              testId={`button-delete-${testId}`}
            />
          )}
          {customActionsNode && customActionsNode}
        </div>
      )}
    </div>
  </>
)
