import { toUpperSnakeCase } from '../../restux/restux.utilities'
import { RestuxResources } from '../../resources'

const RESOURCE_PREFIX = `@${toUpperSnakeCase(RestuxResources.documentHistory)}`

export type DocumentHistoryResourceType = 'documents' | 'document_templates'

export const DomainDocumentHistoryActionTypes = {
  CREATE_REVISION: `${RESOURCE_PREFIX}/CREATE_REVISION`,
}
