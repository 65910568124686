import React, { FunctionComponent, useCallback, useEffect } from 'react'
import { Contact } from '../../../model/Contact'

import { FullLoader } from '../../../components/shared'
import ContactForm from '../ContactForm'

import { EditContactPanelProps } from './EditContactPanel.model'

import { RestuxLoadingState } from '../../../store/restux/ui'

import styles from './EditContactPanel.module.scss'

const EditContactPanel: FunctionComponent<EditContactPanelProps> = ({
  actions,
  readOnly,
  context,
  inUseContact,
  loadingState,
  createContact,
  updateContact,
  hideBottomPanel,
  setInUseContact,
  switchToPrivateCopyMode,
  isInCreatePrivateCopyMode,
}) => {
  useEffect(() => {
    return () => {
      setInUseContact(null)
    }
  }, [setInUseContact])

  const onClose = useCallback(() => {
    hideBottomPanel()
  }, [hideBottomPanel])

  const onConfirm = useCallback(
    (updates: Partial<Contact>) => {
      if (inUseContact && !isInCreatePrivateCopyMode) {
        updateContact(inUseContact.id, { ...updates, id: inUseContact.id }, context)
      } else {
        const updatesOrganizations = updates.organizations ? [...updates.organizations] : []
        const organizations = updatesOrganizations.map((organization) => ({
          ...organization,
          phoneNumber: organization.phoneNumber?.replace(/[^0-9+]/g, '') || null,
        }))

        createContact(
          {
            ...updates,
            organizations,
            private: true,
          },
          context,
          isInCreatePrivateCopyMode,
        )
      }
    },
    [isInCreatePrivateCopyMode, inUseContact, updateContact, context, createContact],
  )

  const onCreatePrivateCopy = useCallback(() => {
    switchToPrivateCopyMode()
  }, [switchToPrivateCopyMode])

  return (
    <div className={styles.container}>
      {loadingState !== RestuxLoadingState.IDLE ? (
        <FullLoader />
      ) : (
        <ContactForm
          actions={actions}
          readOnly={readOnly}
          contact={inUseContact}
          onConfirm={onConfirm}
          onCancel={onClose}
          onCreatePrivateCopy={onCreatePrivateCopy}
          context={context}
        />
      )}
    </div>
  )
}

export default EditContactPanel
