import { ApiResponse } from 'apisauce'
import getApi from '../../api/apisauceConfig'
import { CustomFile, FileType } from '../../model/File'

const resourceIdNames = {
  [FileType.MEDICAL_EVENT]: 'medicalEventId',
}

export const uploadFile = (
  file: File,
  type: FileType,
  resourceId?: number,
): Promise<ApiResponse<CustomFile>> => {
  const data = new FormData()
  data.append('file', file)
  data.append('type', `${type}`)

  const resourceIdName = resourceIdNames[type]
  if (resourceIdName && resourceId) {
    data.append(resourceIdName, `${resourceId}`)
  } else {
    console.warn(`No ressource id specified for file upload type ${type}`)
  }

  return getApi().post<CustomFile>('/files', data)
}
