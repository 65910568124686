import { FunctionComponent, useState } from 'react'
import { Table } from '../../shared'
import { BiologyReportResultProps } from './BiologyReportResult.model'
import { Result } from '../../../model/CdaContext'

export const BiologyReportResult: FunctionComponent<BiologyReportResultProps> = ({
  analysisResults,
}) => {
  const [refMapping, setRefMapping] = useState<Record<number, number>>()

  return (
    <div className="flex max-h-150 w-3/4 rounded-md m-auto p-5 bg-gray-50">
      <div className="overflow-y-scroll overflow-x-hidden">
        <Table
          columns={[
            {
              title: 'Analyse',
              dataIndex: 'analysis',
            },
            {
              title: 'Résultat',
              dataIndex: 'value',
              render: ({ rowIndex, results }: { rowIndex: number; results: Result[] }) =>
                results.length > 1 ? (
                  <select
                    className="bg-transparent"
                    onChange={(event) =>
                      setRefMapping((currentValue) => ({
                        ...currentValue,
                        [rowIndex]: parseInt(event.target.value, 10),
                      }))
                    }
                  >
                    {results.map((result, index) => (
                      <option className="border-none rounded-sm text-gray-500" value={index}>
                        {result.value}
                      </option>
                    ))}
                  </select>
                ) : (
                  results[0].value
                ),
            },
            {
              title: 'Référence',
              dataIndex: 'ref',
              render: (reference) => <span className="text-xs whitespace-nowrap">{reference}</span>,
            },
            { title: 'Interprétation', dataIndex: 'criticity' },
            {
              title: 'Commentaire',
              dataIndex: 'comment',
              render: (comment) => <span className="text-xs">{comment}</span>,
            },
          ]}
          rows={analysisResults.map((row, index) => ({
            data: {
              ...row,
              value: { rowIndex: index, results: row.results },
              ref: row.results[refMapping ? refMapping[index] ?? 0 : 0].reference,
            },
            renderTd: (children) => (
              <td
                className={`w-full pr-6 pl-6 first:font-semibold ${
                  row.critical ? 'text-white bg-red-500' : 'font-normal text-gray-500'
                }`}
              >
                {children}
              </td>
            ),
          }))}
        ></Table>
      </div>
    </div>
  )
}
