import React, { useState, useEffect, FunctionComponent } from 'react'

import { BannerWrapper } from '../BannerWrapper'

import styles from './UpdateBanner.module.scss'
import { NewVersionLabel } from '../../../../constants'
import { IconButton } from '../../buttons'
import { ValidationModal } from '../../modal'

export const UpdateBanner: FunctionComponent = () => {
  const [outdated, setOutdated] = useState(false)
  const [openConfirm, setOpenConfirm] = useState(false)
  const [swRegistration, setSwRegistration] = useState<ServiceWorkerRegistration>()

  const onUpdate = (event) => {
    setOutdated(true)
    setSwRegistration(event.detail)
  }

  useEffect(() => {
    document.addEventListener(NewVersionLabel, onUpdate)
    navigator.serviceWorker.addEventListener('controllerchange', onServiceWorkerChange)

    return () => {
      document.removeEventListener(NewVersionLabel, onUpdate)
      navigator.serviceWorker.removeEventListener('controllerchange', onServiceWorkerChange)
    }
  }, [])

  const onServiceWorkerChange = () => {
    window.location.reload()
  }

  function enableNewServiceWorker() {
    setOpenConfirm(false)
    // "Skip waiting" dit à la nouvelle version du service worker de s'activer et désactive l'ancienne
    if (swRegistration) {
      swRegistration.waiting?.postMessage({ type: 'SKIP_WAITING' })
    }
  }

  return outdated ? (
    <div className={styles.banner}>
      <BannerWrapper criticity="error">
        <div className="font-semibold">
          Votre version de Follow n'est plus à jour, cliquez sur l'icone pour accéder à la dernière
          version et profiter des nouveautés.
        </div>
        <div className="ml-2 inline-flex">
          <IconButton
            onClick={() => setOpenConfirm(true)}
            icon="reload"
            size="nano"
            theme="transparent"
          />
        </div>
      </BannerWrapper>
      <ValidationModal
        display={openConfirm}
        title="En rechargeant la page vous perdrez les modifications non enregistrées"
        onSubmit={enableNewServiceWorker}
        onClose={() => setOpenConfirm(false)}
      />
    </div>
  ) : null
}
