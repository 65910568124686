import { VisibilityType } from '../../../../constants'
import { SelectOption } from '../../../../model/SelectOption'

export interface QuestionnaireListPageFilters {
  search: string
  type: SelectOption<VisibilityType>
  tagIds: ReadonlyArray<string>
}

export type QuestionnaireQueryFilters = {
  search?: string
  private?: string
  tagIds?: string
}
export const QuestionnaireListTypeFilterSelectValues: SelectOption<VisibilityType>[] = [
  { value: VisibilityType.ALL, label: 'Tous' },
  { value: VisibilityType.PUBLIC, label: 'Publics' },
  { value: VisibilityType.PRIVATE, label: 'Privés' },
]
