import React, { FC, useCallback } from 'react'
import { SliderInputProps } from './SliderInput.model'
import RcSlider from 'rc-slider'
import 'rc-slider/assets/index.css'

import styles from './SliderInput.module.scss'
import { isDefined } from '../../../../misc/functions.utilities'
import { Input } from '../Input'
import classNames from 'classnames/bind'
import { TooltipWrapper } from '../../tooltip'

const cx = classNames.bind(styles)

export const SliderInput: FC<SliderInputProps> = ({
  value,
  min,
  max,
  step,
  disabled = false,
  appearance = 'complete',
  testId,
  onChange,
}) => {
  const hasData = isDefined(value)

  const handleChange = useCallback(
    (value: number | number[]) => {
      let actualValue: number | undefined

      if (Array.isArray(value)) {
        actualValue = value.at(0)
      } else {
        actualValue = value
      }

      if (isDefined(actualValue)) {
        onChange(actualValue)
      }
    },
    [onChange],
  )

  return (
    <div className={styles.container} data-test-id={testId ? `slider-${testId}` : ''}>
      <span className={cx(styles.sliderValues, styles.min)}>{min}</span>
      {appearance === 'complete' && (
        <div className={styles.inputField}>
          <Input
            type="number"
            name="field"
            height="small"
            placeholder="?"
            value={`${value}`}
            disabled={disabled}
            onChange={(e) => onChange(parseInt(e.target.value, 10))}
            valid={hasData && value >= min && value <= max}
            colorPreset="light"
            centered
          />
        </div>
      )}
      <span className={cx(styles.sliderValues, styles.max)}>{max}</span>
      <div className={cx(styles.slider, styles[appearance])}>
        <RcSlider
          disabled={disabled}
          min={min}
          max={max}
          step={step}
          value={value}
          onChange={handleChange}
          className={!hasData || disabled ? styles.empty : ''}
          styles={{
            track: { height: '8px' },
            handle: { height: '20px', width: '20px', opacity: 1, top: '-1px' },
          }}
          handleRender={(node, handleProps) => (
            <TooltipWrapper content={handleProps.value} debounceHiding>
              {node}
            </TooltipWrapper>
          )}
        />
      </div>
    </div>
  )
}
