import { call, put, takeEvery } from 'redux-saga/effects'
import { documentCategoriesDomainAction } from './documentCategories.actions'
import { domainDocumentCategoriesActionTypes } from './documentCategories.model'
import { ApiResponse } from 'apisauce'
import { PaginatedList } from '../../../model/Pagination'
import { DocumentCategory } from '../../../model/DocumentCategory'
import { getUsedDocumentCategories } from '../../cache/documentCategories/api'
import { addResponseError } from '../../message/message.actions'

function* fetchUsedDocumentCategoriesWorker({
  usedInResource,
}: ReturnType<typeof documentCategoriesDomainAction.fetchUsedDocumentCategories>) {
  const response: ApiResponse<PaginatedList<DocumentCategory>> = yield call(
    getUsedDocumentCategories,
    usedInResource,
  )
  if (response.ok && response.data) {
    yield put(documentCategoriesDomainAction.storeUsedDocumentCategories(response.data.items))
  } else {
    yield put(addResponseError(response))
  }
}

function* fetchUsedDocumentCategoriesWatcher() {
  yield takeEvery(
    domainDocumentCategoriesActionTypes.FETCH_USED_DOCUMENT_CATEGORIES,
    fetchUsedDocumentCategoriesWorker,
  )
}

export const documentCategoriesSagas = { fetchUsedDocumentCategoriesWatcher }
