export enum QuestionType {
  Text = 'TEXT',
  Slider = 'SLIDER',
  Select = 'SELECT',
  YesNo = 'YES_NO', // Géré comme un Radio
  QCU = 'RADIO',
  QCM = 'BTN_GROUP_MULTIPLE',
  BtnGroup = 'BTN_GROUP',
  Date = 'DATE',
}
