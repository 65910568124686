import { FunctionComponent } from 'react'
import { DatePicker, TextArea } from '../../shared'
import { ManualPrescriptionFormProps } from './ManualPrescriptionForm.model'

export const ManualPrescriptionForm: FunctionComponent<ManualPrescriptionFormProps> = ({
  currentManualPrescription,
  onChange,
}) => {
  return (
    <div className="flex flex-col w-3/4">
      <TextArea
        name="prescriptionReason"
        placeholder="Motif de prescription"
        value={currentManualPrescription.content}
        onChange={({ target: { value } }) => {
          onChange({ content: value })
        }}
        initialRows={3}
        autoResize
      />
      <div className="flex flex-row mt-5">
        <div className="mr-4">
          <DatePicker
            label="Date de début"
            value={currentManualPrescription.startDate}
            onChange={(value) => {
              onChange({ startDate: value })
            }}
            colorPreset="light"
            inputType="input"
          />
        </div>
        <div className="ml-4">
          <DatePicker
            label="Date de fin"
            value={currentManualPrescription.endDate}
            onChange={(value) => {
              onChange({ endDate: value })
            }}
            colorPreset="light"
            inputType="input"
            validation={false}
          />
        </div>
      </div>
    </div>
  )
}
