import { FC } from 'react'
import { Icon } from '../../../../../components/shared'
import { getCssVariable } from '../../../../../design-system/utils'
import { ExternalResourceLinkProps } from './ExternalResourceLink.model'

export const ExternalResourceLink: FC<ExternalResourceLinkProps> = ({
  label,
  shrink = false,
  onClick,
}) => {
  return (
    <div
      className="flex-grow-0 mr-4 flex flex-row items-center font-medium text-base text-primary-default hover:underline cursor-pointer"
      onClick={onClick}
    >
      {!shrink && <span className="mr-2">{label}</span>}
      <Icon
        color={getCssVariable('primary', 'default')}
        icon="infoCircle"
        size={shrink ? 'normal' : 'nano'}
      />
    </div>
  )
}
