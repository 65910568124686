import { useMutation, useQueryClient } from '@tanstack/react-query'
import { deleteTeam } from '../../../../data/admin'
import { UserFriendlyError } from '../../../../model/Error'
import { Team } from '../../../../model/Team'
import { addValid } from '../../../../store/message'
import { useActionDispatch } from '../../../utils'
import { PaginatedList } from '../../../../model/Pagination'
import { teamKeys } from './teams.keys'

export const useDeleteTeam = () => {
  const valid = useActionDispatch(addValid)
  const queryClient = useQueryClient()

  const queryReturn = useMutation<null, Error, Team>(
    async ({ id }) => {
      const { ok } = await deleteTeam(id)
      if (!ok) {
        throw new UserFriendlyError('error', "La suppression de l'équipe a échoué")
      }
      return null
    },
    {
      onSuccess: (_data, team) => {
        valid(`L'équipe "${team.name}" a été supprimée`)
        queryClient.refetchQueries<PaginatedList<Team>>({
          queryKey: teamKeys.all,
          refetchPage: (page) => page.items.some(({ id }) => id === team.id),
        })
      },
    },
  )

  return queryReturn
}
