import { capitalize } from 'lodash'
import { Drug } from '../../../model/Drug'

export const computePriceLabel = (minUcdRangePrice?: number, maxUcdRangePrice?: number) => {
  const minLabel = minUcdRangePrice
    ? `${(minUcdRangePrice * 0.01).toFixed(2).replace('.', ',')}€`
    : ''
  const maxLabel = maxUcdRangePrice
    ? `${(maxUcdRangePrice * 0.01).toFixed(2).replace('.', ',')}€`
    : ''

  if (minUcdRangePrice && maxUcdRangePrice) {
    return minUcdRangePrice === maxUcdRangePrice ? minLabel : `${minLabel} - ${maxLabel}`
  }
  if (minUcdRangePrice || maxUcdRangePrice) {
    return minUcdRangePrice ? minLabel : maxLabel
  }
  return undefined
}

export const computePriceInformations = ({
  drug: { minUcdRangePrice, maxUcdRangePrice, refundRate },
  prescriptionPrice,
}: {
  drug: Drug
  prescriptionPrice?: number
}) => {
  const priceLabel = computePriceLabel(minUcdRangePrice, maxUcdRangePrice)
  const refundLabel = refundRate ? `remboursé à ${refundRate}%` : undefined
  if (priceLabel && refundLabel) {
    return `${
      prescriptionPrice
        ? `${(prescriptionPrice * 0.01).toFixed(2).replace('.', ',')}€/prescription `
        : ''
    }(${priceLabel}/unité), ${refundLabel}`
  }
  if (priceLabel || refundLabel) {
    return refundLabel ?? priceLabel
  }
  return undefined
}

export const computeActivePrinciplesLabel = (drug: Drug) => {
  const activePrinciples = drug.activePrinciples?.replaceAll(';', ',')
  return capitalize(activePrinciples)
}
