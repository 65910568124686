import { getEditorEmptyJsonValue, normalizeEditorValue } from '@follow/farte'
import { FollowTypes } from '../model'

export function deserializeDocumentLayout(
  dto: FollowTypes.DocumentLayout.DocumentLayoutDTO,
): FollowTypes.DocumentLayout.DocumentLayout {
  const { header, introduction, conclusion, footer, left, right } = dto

  return {
    ...dto,
    header: header ? normalizeEditorValue(JSON.parse(header)) : getEditorEmptyJsonValue(),
    introduction: introduction
      ? normalizeEditorValue(JSON.parse(introduction))
      : getEditorEmptyJsonValue(),
    conclusion: conclusion
      ? normalizeEditorValue(JSON.parse(conclusion))
      : getEditorEmptyJsonValue(),
    footer: footer ? normalizeEditorValue(JSON.parse(footer)) : getEditorEmptyJsonValue(),
    left: left ? normalizeEditorValue(JSON.parse(left)) : getEditorEmptyJsonValue(),
    right: right ? normalizeEditorValue(JSON.parse(right)) : getEditorEmptyJsonValue(),
  }
}
