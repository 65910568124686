import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Task, TaskMutationPayload } from '../../../model/Task'
import { UserFriendlyError } from '../../../model/Error'
import { PaginatedList } from '../../../model/Pagination'
import { updateTask } from '../../../data/task/updateTask'
import { taskKeys } from './tasks.keys'

interface TaskMutateVariable {
  taskId: number
  variables: TaskMutationPayload
}

export const useUpdateTask = () => {
  const queryClient = useQueryClient()

  return useMutation<Task, Error, TaskMutateVariable>(
    async ({ taskId, variables }) => {
      const { data, ok } = await updateTask(taskId, variables)
      if (!data || !ok) {
        throw new UserFriendlyError('error', 'La mise à jour de la tâche a échoué')
      }
      return data
    },
    {
      onSuccess: (data) => {
        queryClient.refetchQueries<PaginatedList<Task>>({
          queryKey: taskKeys.all,
          refetchPage: (page) => page.items.some(({ id }) => id === data.id),
        })
        queryClient.setQueryData(taskKeys.detail(data.id), data)
      },
    },
  )
}
