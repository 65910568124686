import React, { FunctionComponent } from 'react'
import { TooltipProps, TooltipSizes } from './Tooltip.model'
import classNames from 'classnames'

export const Tooltip: FunctionComponent<TooltipProps> = ({
  content,
  pointerDirection,
  respectParentWidth = false,
  size = 'medium',
  textAlign,
}) => {
  const rotation = {
    top: 'w-full left-0 right-0 bottom-full transform rotate-180',
    bottom: 'w-full left-0 right-0 top-full',
    left: 'top-0 bottom-0 right-full transform rotate-90 pb-1.5',
    right: 'top-0 bottom-0 left-full transform -rotate-90 pb-1.5',
  }

  return (
    <div
      className={classNames(
        `z-10 relative m-2 bg-gray-600 py-2 px-4 text-${textAlign} rounded text-white font-semibold text-xs`,
        respectParentWidth ? 'w-full' : 'w-max',
        TooltipSizes[size],
      )}
    >
      {content}
      <div className={`flex absolute h-fit w-fit m-auto ${rotation[pointerDirection]}`}>
        <svg className="text-gray-600 h-1.5" x="0px" y="0px" viewBox="0 0 510 255">
          <polygon className="fill-current" points="0,0 255,255 510,0" />
        </svg>
      </div>
    </div>
  )
}
