import { connect } from 'react-redux'
import { DocumentLayoutDispatchProps } from './DocumentLayoutForm.model'
import { DocumentLayoutForm } from './DocumentLayoutForm.component'
import { documentInstancesActions } from '../../../../../store/cache/documentInstances'

const mapDispatchToProps: DocumentLayoutDispatchProps = {
  updateDocumentInstance: documentInstancesActions.actions.apiUpdateItem,
}

const withConnect = connect(null, mapDispatchToProps)(DocumentLayoutForm)
export { withConnect as DocumentLayoutForm }
