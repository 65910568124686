import { useMutation, useQueryClient } from '@tanstack/react-query'
import { changePatientOwner } from '../../../data/patients'
import { UserFriendlyError } from '../../../model/Error'
import { patientsKeys } from './patients.keys'

interface PatientMutateVariable {
  patientId: number
  newOwnerId: string
  includeSubResources: boolean
}

export const useChangePatientOwner = () => {
  const queryClient = useQueryClient()

  return useMutation<null, Error, PatientMutateVariable>(
    async ({ patientId, newOwnerId, includeSubResources }) => {
      const { ok } = await changePatientOwner(patientId, newOwnerId, includeSubResources)
      if (!ok) {
        throw new UserFriendlyError('error', 'Le changement de référent du patient a échoué')
      }
      return null
    },
    {
      onSuccess: (_data, { patientId }) => {
        queryClient.invalidateQueries(patientsKeys.lists)
        queryClient.invalidateQueries(patientsKeys.detail(patientId))
      },
    },
  )
}
