import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'

import { User } from '../../model/User'
import {
  BOTTOM_PANEL_CONTENT_ROOT,
  Input,
  MultiSelectInput,
  RoundedButton,
  SelectInput,
  ValidationModal,
  TextArea,
} from '../../components/shared'
import { DropdownUserItem } from '../../components/user'
import { getFullName } from '../../misc/user.utilities'
import { MultiSelectOption } from '../../model/SelectOption'
import {
  EPHEMERAL_NOTIFICATION_LEVEL_OPTIONS,
  EphemeralNotification,
} from '../../model/EphemeralNotification'
import { PublishEphemeralNotificationPanelProps } from './PublishEphemeralNotificationPanel.model'
import { MessagesTypes } from '../../model/Notification'
import { searchLimit } from '../../constants'
import { useGetInfiniteUsers, usePostEphemeralNotification } from '../../hooks/queries/admin'

const userToOption = (user: User) => ({
  label: getFullName(user),
  value: user,
})

const optionsToIds = (options: ReadonlyArray<MultiSelectOption<User>>) =>
  options.map(({ value }) => value.id)

const areUsersEquals = (a: User, b: User) => a.id === b.id

export const PublishEphemeralNotificationPanel: FunctionComponent<
  PublishEphemeralNotificationPanelProps
> = ({ actions: Actions }) => {
  const [ephemeralNotificationToPublish, setEphemeralNotificationToPublish] = useState<
    EphemeralNotification | undefined
  >()

  const [openPublishModal, setOpenPublishModal] = useState(false)
  const [title, setTitle] = useState<string>('')
  const [level, setLevel] = useState<MessagesTypes>(MessagesTypes.Info)
  const [shortMessage, setShortMessage] = useState<string>('')
  const [userSearch, setUserSearch] = useState('')
  const [usersRecipient, setUsersRecipient] = useState<Array<MultiSelectOption<User>>>([])

  const { mutate: createEphemeralNotification } = usePostEphemeralNotification()

  const levelValue = useMemo(
    () => EPHEMERAL_NOTIFICATION_LEVEL_OPTIONS.find((localLevel) => level === localLevel.value),
    [level],
  )

  const { userList, cancelPendingQuery } = useGetInfiniteUsers({
    filters: {
      search: userSearch,
    },
    limit: searchLimit,
    enabled: userSearch.length > 0,
  })

  const userOptions = useMemo(() => userList.map(userToOption), [userList])

  const handleUserSearchChange = useCallback(
    (value: string) => {
      setUserSearch(value)
      cancelPendingQuery()
    },
    [cancelPendingQuery],
  )

  const handleSelectRecipient = useCallback(
    (selected: MultiSelectOption<User>[]) => {
      setUsersRecipient(selected)
      handleUserSearchChange('')
    },
    [handleUserSearchChange],
  )

  const closeModal = useCallback(() => {
    setOpenPublishModal(false)
  }, [])

  const openModal = useCallback(() => {
    const EphemeralNotification: EphemeralNotification = {
      title,
      shortMessage,
      level,
      type: 'snackbar',
      recipients: optionsToIds(usersRecipient),
    }

    setEphemeralNotificationToPublish(EphemeralNotification)
    setOpenPublishModal(true)
  }, [title, shortMessage, level, usersRecipient])

  const handleConfirmSubmit = useCallback(() => {
    if (ephemeralNotificationToPublish) {
      createEphemeralNotification(ephemeralNotificationToPublish)
      setOpenPublishModal(false)
    }
  }, [ephemeralNotificationToPublish, createEphemeralNotification])

  const editDisabled = title && shortMessage.length >= 10

  return (
    <div className="bg-white flex flex-col h-full">
      <Actions>
        <RoundedButton
          theme="primary"
          label="Publier"
          onClick={openModal}
          disabled={!editDisabled}
        />
      </Actions>
      <div className="px-4 pt-2 pb-4">
        <div className="flex items-center mb-2 flex-col w-full">
          <div className="flex flex-row w-full gap-4 items-end">
            <Input
              name="title"
              label="Titre"
              autocomplete="off"
              placeholder="Saisir un titre"
              value={title}
              onChange={(e) => setTitle(e.currentTarget.value)}
              colorPreset="light"
            />
            <SelectInput
              placeholder="Sélectionner un type"
              title="Level de la notification éphémère"
              icon={undefined}
              colorPreset="light"
              options={EPHEMERAL_NOTIFICATION_LEVEL_OPTIONS}
              value={levelValue}
              onSelect={(selected) => setLevel(selected.value)}
            />
          </div>

          <div className="mt-4 w-full">
            <MultiSelectInput
              title="Utilisateurs à notifier"
              placeholder="Laisser vide pour notifier tout le monde"
              colorPreset="light"
              mode="multiline"
              emitChange={handleUserSearchChange}
              value={usersRecipient}
              options={userOptions}
              onSelect={handleSelectRecipient}
              areElementsEquals={areUsersEquals}
              renderOption={({ value }) => (
                <DropdownUserItem
                  user={value}
                  selectedValues={usersRecipient.map(({ value }) => value)}
                />
              )}
            />
          </div>
          <div className="w-full mt-4">
            <TextArea
              name="content"
              placeholder="Message (10 caractères minimum)"
              onChange={(event) => setShortMessage(event.target.value)}
              value={shortMessage}
            />
          </div>
        </div>
      </div>
      <ValidationModal
        display={openPublishModal}
        title={
          usersRecipient.length > 0
            ? 'Voulez vous envoyer cette notification éphémère aux utilisateus sélectionnés ?'
            : 'Voulez vous envoyer cette notification éphémère ? Elle sera diffusée à tous les utilisateurs connectés à ce moment puis disparaîtra'
        }
        onSubmit={handleConfirmSubmit}
        rootId={BOTTOM_PANEL_CONTENT_ROOT}
        onClose={closeModal}
      />
    </div>
  )
}
