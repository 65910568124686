import { IconProps as IBaseIconProps } from './BaseIcon/BaseIcon.model'

export type IconSizes = 'femto' | 'pico' | 'nano' | 'micro' | 'normal' | 'macro' | 'mega' | 'large'

export const ICON_SIZES_MAPPING_TO_PX: { [size in IconSizes]: number } = {
  femto: 10,
  pico: 12,
  nano: 16,
  micro: 20,
  normal: 24,
  macro: 38,
  mega: 48,
  large: 52,
}

export interface IconProps extends IBaseIconProps {
  size: IconSizes
}
