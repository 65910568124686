import { RestuxCacheConf, Identity } from './restuxCache.model'
import { restuxCacheReducersFactory } from './restuxCacheReducer.factory'
import { restuxCacheSagasFactory } from './restuxCacheSagas.factory'
import { restuxCacheActionsFactory } from './restuxCacheActions.factory'

export function restuxCacheFactory<TL extends Identity, TD extends Identity>(
  config: RestuxCacheConf<TL, TD>,
) {
  return {
    actions: restuxCacheActionsFactory<TL, TD>(config.resourceName),
    reducers: restuxCacheReducersFactory<TL, TD>(config.resourceName),
    sagas: restuxCacheSagasFactory<TL, TD>(config),
  }
}
