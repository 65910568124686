import { Drug } from '../../../model/Drug'
import { domainPrescriptionTemplateActionTypes } from './prescriptionTemplate.model'
import { PrescriptionTemplate } from '../../../model/PrescriptionTemplate'

export const domainPrescriptionTemplateActions = {
  getCollection: (drugs: Drug[]) => ({
    type: domainPrescriptionTemplateActionTypes.GET_COLLECTION,
    drugs,
  }),
  storeCollection: (prescriptionTemplates: PrescriptionTemplate[] | null) => ({
    type: domainPrescriptionTemplateActionTypes.STORE_COLLECTION,
    prescriptionTemplates,
  }),
  deleteItem: (prescriptionTemplate: PrescriptionTemplate) => ({
    type: domainPrescriptionTemplateActionTypes.DELETE_ITEM,
    prescriptionTemplate,
  }),
  createPrescriptionTemplate: (prescriptionUuid: string, name: string) => ({
    type: domainPrescriptionTemplateActionTypes.CREATE_PRESCRIPTION_TEMPLATE,
    prescriptionUuid,
    name,
  }),
}
