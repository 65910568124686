import React, { FunctionComponent, useCallback, useLayoutEffect } from 'react'
import { useMeasure } from 'react-use'
import classNames from 'classnames/bind'
import { NavBarProps } from './NavBar.model'
import styles from './NavBar.module.scss'
import { IconButton, MaintenanceBanner } from '../../../shared'
import { useNavigate } from 'react-router-dom'
import { atom, useSetAtom } from 'jotai'

export const mobileMenuOpenAtom = atom(false)

const cx = classNames.bind(styles)

export const BackButton: FunctionComponent<{
  path: string | null
  keepSearchParams?: boolean
  title?: string
}> = ({ path, keepSearchParams, title = 'Retour' }) => {
  const navigate = useNavigate()
  return (
    <div className={styles.backButtonContainer}>
      <IconButton
        onClick={() => {
          if (path === null) {
            return navigate(-1)
          }
          navigate(keepSearchParams ? `${path}${document.location.search}` : path)
        }}
        icon="back"
        title={title}
        theme="dark"
        size="normal"
        testId="button-navigation-back"
      />
    </div>
  )
}

export const NavBar: FunctionComponent<NavBarProps> = ({
  backButtonPath,
  backButtonTitle,
  keepSearchParams,
  children,
  stickyHeaderContent,
  noDivider = false,
}) => {
  const [ref, measure] = useMeasure()
  const setMobileMenuOpen = useSetAtom(mobileMenuOpenAtom)

  useLayoutEffect(() => {
    document.documentElement.style.setProperty('--navbar-height', `${measure.height}px`)
    return () => {
      document.documentElement.style.removeProperty('--navbar-height')
    }
  }, [measure.height])

  const handleOpenMobileMenu = useCallback(() => {
    setMobileMenuOpen(true)
  }, [setMobileMenuOpen])

  return (
    <div className={styles.container} ref={ref}>
      <span className={styles.backButtonWithPageTitle}>
        {backButtonPath !== undefined && (
          <BackButton
            path={backButtonPath}
            title={backButtonTitle}
            keepSearchParams={keepSearchParams}
          />
        )}
        <div className={styles.title}>{children}</div>
        <div className={styles.menuMobileContainer}>
          <IconButton icon="menu" theme="transparent" size="micro" onClick={handleOpenMobileMenu} />
        </div>
      </span>
      <div className={cx(styles.divider, { noDivider })} />
      <div>
        <MaintenanceBanner />
        {stickyHeaderContent}
      </div>
    </div>
  )
}
