import { connect } from 'react-redux'
import { RootState } from '../../store'
import { isAdminSelector } from '../../store/ui/adminUsers'

import { VariableConfiguration } from './VariableConfiguration.component'
import { VariableConfigurationStateProps } from './VariableConfiguration.model'
import { inUseDocumentTemplateSelector } from '../../store/ui/bottomPanelDocumentTemplates/bottomPanelDocumentTemplates.selectors'
import { selectedMedicalEventDocumentSelector } from '../../store/ui/medicalEvents'

const mapStateToProps = (state: RootState): VariableConfigurationStateProps => ({
  isAdmin: isAdminSelector(state),
  inUseDocumentTemplate: inUseDocumentTemplateSelector(state),
  selectedMedicalEventDocument: selectedMedicalEventDocumentSelector(state),
})

export default connect(mapStateToProps)(VariableConfiguration)
