import React, { FunctionComponent, useCallback, useMemo } from 'react'
import { RadioGroup, Radio, FormikTextInput, FormBlock } from '../../../shared'
import { User, UserTypeId, USER_TYPES } from '../../../../model/User'
import { GeneralInformationsBlockProps } from './GeneralInformationsBlock.model'
import RoleRestriction from '../../../../containers/RoleRestriction'
import { ADMIN_ROLES, ROLES_TYPES, Roles } from '../../../../model/Roles'
import { RoleSelect } from './RoleSelect'
import { useFormikContext } from 'formik'
import { SelectOption } from '../../../../model/SelectOption'
import {
  DEFAULT_ADMIN_PREFERENCES,
  DEFAULT_ASSISTANT_PREFERENCES,
  DEFAULT_DOCTOR_PREFERENCES,
} from '../../../../model/Preference'
import { isAdminRole, isExtDsmRole } from '../../../../misc/roles.utilities'
import { retrieveBaseUser } from '../../../../misc/user.utilities'
import { DEFAULT_PRACTICE_LOCATION } from '../../../../model/Address'
import { ProfessionSelect } from './ProfessionSelect'
import { ProfessionSpecialitiesSelect } from './ProfessionSpecialitiesSelect'

const getRoleTypeOptions = () => Object.values(ROLES_TYPES)

export const GeneralInformationsBlock: FunctionComponent<GeneralInformationsBlockProps> = ({
  fields,
  onChange,
}) => {
  const roleTypeOptions = useMemo(getRoleTypeOptions, [])
  const { values, setValues } = useFormikContext<User>()

  const handleRoleChange = useCallback(
    ({ value }: SelectOption<Roles>) => {
      if (value !== values.mainRole) {
        const baseUser = retrieveBaseUser(values)
        const commonPreferences = {
          otpEnabled: values.preferences.otpEnabled,
        }

        if (value === Roles.DOCTOR) {
          setValues({
            ...baseUser,
            mainRole: Roles.DOCTOR,
            roles: [Roles.DOCTOR],
            rppsBarcode: null,
            rppsNumber: '',
            finessBarcode: null,
            finessNumber: '',
            assistants: [],
            preferences: {
              ...DEFAULT_DOCTOR_PREFERENCES,
              ...commonPreferences,
            },
            practiceLocation: DEFAULT_PRACTICE_LOCATION,
            profession: null,
            speciality: null,
            quality: '',
            externalAccount: [],
            signatureFile: null,
          })
        } else if (isAdminRole(value) || isExtDsmRole(value)) {
          setValues({
            ...baseUser,
            userType: USER_TYPES[UserTypeId.Other],
            mainRole: value,
            roles: [value],
            preferences: {
              ...DEFAULT_ADMIN_PREFERENCES,
              ...commonPreferences,
            },
          })
        } else if (value === Roles.ASSISTANT) {
          setValues({
            ...baseUser,
            userType: USER_TYPES[UserTypeId.Other],
            mainRole: Roles.ASSISTANT,
            roles: [Roles.ASSISTANT],
            preferences: {
              ...DEFAULT_ASSISTANT_PREFERENCES,
              ...commonPreferences,
            },
            permissions: { insOidAccess: false, insiServiceAccess: false },
            doctors: [],
            impersonatePermissions: [],
          })
        }
      }
    },
    [setValues, values],
  )

  return (
    <FormBlock label="Général" icon="profile">
      <FormikTextInput
        fieldName="familyName"
        label="Nom *"
        autocomplete={false}
        colorPreset="light"
      />
      <FormikTextInput
        fieldName="firstName"
        label="Prénom *"
        autocomplete={false}
        colorPreset="light"
      />
      {fields.mainRole === Roles.DOCTOR && (
        <>
          <FormikTextInput
            fieldName="quality"
            label="Qualité"
            autocomplete={false}
            colorPreset="light"
          />
          <ProfessionSelect />
          <ProfessionSpecialitiesSelect />
        </>
      )}
      <RoleRestriction roles={ADMIN_ROLES}>
        <RoleSelect
          options={roleTypeOptions}
          value={{
            value: fields.mainRole,
            label: ROLES_TYPES[fields.mainRole].title,
          }}
          onSelect={handleRoleChange}
        />
      </RoleRestriction>
      {fields.mainRole === Roles.DOCTOR && (
        <>
          <FormikTextInput fieldName="rppsNumber" colorPreset="light" label="Numéro RPPS" />
          <FormikTextInput fieldName="finessNumber" colorPreset="light" label="Numéro FINESS" />
        </>
      )}
      <RadioGroup name="sex" label="Sexe *" value={fields.sex} onChange={onChange}>
        <Radio label="Masculin" value="MALE" />
        <Radio label="Féminin" value="FEMALE" />
        <Radio label="Autre" value="UNKNOWN" />
      </RadioGroup>
    </FormBlock>
  )
}
