import { ApiResponse } from 'apisauce'
import { PaginatedList } from '../../../../model/Pagination'
import { Acte, ActeForList, Activity, DetailedActe, Phase, RateGrid } from '../../../../model/Acte'
import { omit } from 'lodash'

export function serializeActeForList(acte: Acte): ActeForList {
  return {
    id: acte.codeActe,
    ...acte,
  }
}

export function deserializeActeForList(acteForList: ActeForList): Acte {
  return omit(acteForList, ['id'])
}

export function deserializeActePageResponse(
  pageResponse: ApiResponse<PaginatedList<Acte>>,
): ApiResponse<PaginatedList<Acte>> {
  const { data, ...responseRest } = pageResponse
  // Error case => no deserialization
  if (!pageResponse.ok || !data) {
    return { ...responseRest }
  }
  return {
    ...responseRest,
    data: { ...data, items: data.items },
  }
}

type ApiRateGrid = SafeOmit<RateGrid, 'code'> & {
  code: number
}

type ApiPhase = SafeOmit<Phase, 'code' | 'grilles'> & {
  code: number
  grilles: ApiRateGrid[]
}

type ApiActivity = SafeOmit<Activity, 'phases'> & {
  phases: ApiPhase[]
}

export type ApiDetailedActe = Pick<Acte, 'codeActe' | 'libelle'> & {
  activites: ApiActivity[]
}

const deserialiseApiDetailedActe = (data: ApiDetailedActe): DetailedActe => ({
  ...data,
  activites: data.activites.map((activite) => ({
    ...activite,
    phases: activite.phases.map((phase) => ({
      ...phase,
      code: phase.code.toString(),
      grilles: phase.grilles.map((grille) => ({
        ...grille,
        code: grille.code.toString(),
      })),
    })),
  })),
})

export const deserializeDetailedActeResponse = (
  apiDetailedActeResponse: ApiResponse<ApiDetailedActe>,
): ApiResponse<DetailedActe> => {
  const { data, ...responseRest } = apiDetailedActeResponse

  if (!apiDetailedActeResponse.ok || !data) {
    return { ...responseRest }
  }
  return {
    ...responseRest,
    data: deserialiseApiDetailedActe(data),
  }
}
