import React, { FunctionComponent, useState } from 'react'
import { useTagSearch, useTimeoutEffect } from '../../../hooks/utils'
import {
  SelectSearch,
  RoundedButton,
  SearchInput,
  GridLayout,
  IconChip,
  ItemSelect,
  MultiSelectSearch,
  Tag,
} from '../../../components/shared'
import {
  DocumentCategoryKeys,
  DocumentCategoryColor,
  DocumentCategoryIcon,
} from '../../../model/DocumentCategory'

import { SearchPanelFilters, FiltersProps, FiltersType } from './Filters.model'

import styles from './Filters.module.scss'

const visibilities = [
  {
    label: 'Tous',
    value: undefined,
  },
  {
    label: 'Public',
    value: false,
  },
  {
    label: 'Privé',
    value: true,
  },
]
const defaultVisibility = visibilities[2]

const Filters: FunctionComponent<FiltersProps> = ({
  tags,
  usedTags,
  capabilities,
  documentCategories,
  testId,
  onFiltersChange,
  onClearFilters,
  renderActionItem,
  hideActionItems = false,
  renderCustomFilters,
  resetPagination,
}) => {
  const [filters, setFilters] = useState<SearchPanelFilters>({
    search: '',
    documentCategories: [],
    tagIds: [],
    private: defaultVisibility.value,
  })

  const [tagOptions, tagValues] = useTagSearch(filters.tagIds, tags)

  useTimeoutEffect(
    () =>
      onFiltersChange({
        search: filters.search,
        documentCategories: filters.documentCategories,
        private: filters.private,
        tagIds: filters.tagIds,
      }),
    500,
    [filters],
  )

  function onSearchChange(search) {
    if (search !== filters.search) {
      setFilters({
        ...filters,
        search,
      })
    }
  }

  const onDocumentCategoriesChange = (itemCategory: DocumentCategoryKeys) => {
    if (filters.documentCategories.includes(itemCategory)) {
      setFilters({
        ...filters,
        documentCategories: [],
      })
    } else {
      setFilters({ ...filters, documentCategories: [itemCategory] })
    }
  }

  const onTagsChange = (tagId: string) => {
    if (filters.tagIds.includes(tagId)) {
      setFilters({
        ...filters,
        tagIds: filters.tagIds.filter((currentTagId) => currentTagId !== tagId),
      })
    } else {
      setFilters({ ...filters, tagIds: [...filters.tagIds, tagId] })
    }
  }

  return (
    <div className={styles.container}>
      <GridLayout rowsTemplate="auto auto" gap="medium">
        <form onSubmit={(e) => e.preventDefault()}>
          <GridLayout rowsTemplate="auto" gap="medium" padding={['medium', 'none']}>
            {renderCustomFilters && renderCustomFilters(resetPagination)}
            {capabilities.includes(FiltersType.Title) && (
              <SearchInput
                value={filters.search}
                onChange={({ target: { value } }) => onSearchChange(value)}
                colorPreset="dark"
                placeholder="3 caractères min"
                autofocus
                testId={testId ? `search-${testId}-name` : undefined}
              />
            )}
            {capabilities.includes(FiltersType.Visibility) && (
              <SelectSearch
                value={visibilities.find(({ value }) => value === filters.private)}
                options={visibilities}
                colorPreset="dark"
                clearable={false}
                onSelect={(selected) => {
                  if (selected) {
                    setFilters({ ...filters, private: selected.value })
                  }
                }}
              />
            )}
            {capabilities.includes(FiltersType.Tags) && tags && (
              <>
                <MultiSelectSearch
                  placeholder="Rechercher par label"
                  icon="tag"
                  colorPreset="dark"
                  value={tagValues}
                  options={tagOptions}
                  onSelect={(selected) => {
                    const tagIds = selected.map(({ value }) => value)
                    setFilters({ ...filters, tagIds })
                  }}
                />
                {usedTags && (
                  <ItemSelect
                    title="Labels utilisés récemment"
                    items={usedTags}
                    renderItem={(item) => (
                      <Tag
                        color={item.color}
                        selected={filters.tagIds.includes(item.id)}
                        title={item.label}
                        onClick={() => onTagsChange(item.id)}
                      >
                        {item.label}
                      </Tag>
                    )}
                  />
                )}
              </>
            )}
            {capabilities.includes(FiltersType.DocumentCategory) && documentCategories && (
              <ItemSelect
                title="Type de document"
                items={documentCategories.map((category) => ({ ...category, id: category.id }))}
                renderItem={(item) => (
                  <IconChip
                    colorPreset="light"
                    borderColor={DocumentCategoryColor[item.id]}
                    icon={DocumentCategoryIcon[item.id]}
                    label={item.label}
                    selected={filters.documentCategories.includes(item.id)}
                    onClick={() => onDocumentCategoriesChange(item.id)}
                  />
                )}
              />
            )}
          </GridLayout>
        </form>
      </GridLayout>
      {!hideActionItems && (
        <div className={styles.clearFilters}>
          {!renderActionItem && onClearFilters && (
            <RoundedButton
              theme="transparent"
              label="Effacer les filtres"
              onClick={() => {
                setFilters({
                  search: '',
                  documentCategories: [],
                  tagIds: [],
                })
                onClearFilters()
              }}
            />
          )}
          {renderActionItem && (
            <div
              onClick={() => {
                setFilters({
                  search: '',
                  documentCategories: [],
                  tagIds: [],
                })
              }}
            >
              {renderActionItem()}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default Filters
