import React, { FC } from 'react'
import { ToolbarButton } from '@/components/toolbar/atoms'
import { PluginNames } from '@/model/Plugins'
import { ToolbarElementProps } from '@/model/Toolbar'

export const AlignmentGroup: FC<ToolbarElementProps> = ({ editor }) => {
  const disabled = editor.isActive(PluginNames.ImageResize)

  const isAlignmentActive = (textAlign: string) => editor.isActive({ textAlign })
  const toggleAlignment = (textAlign: string) =>
    editor.chain().focus().setTextAlign(textAlign).run()

  return (
    <>
      <ToolbarButton
        title={'Aligner à gauche (Ctrl + Shift + L)'}
        icon="AlignLeft"
        active={isAlignmentActive('left')}
        onClick={() => toggleAlignment('left')}
        disabled={disabled}
      />
      <ToolbarButton
        title={'Aligner au centre (Ctrl + Shift + E)'}
        icon="AlignCenter"
        active={isAlignmentActive('center')}
        onClick={() => toggleAlignment('center')}
        disabled={disabled}
      />
      <ToolbarButton
        title={'Aligner à droite (Ctrl + Shift + R)'}
        icon="AlignRight"
        active={isAlignmentActive('right')}
        onClick={() => toggleAlignment('right')}
        disabled={disabled}
      />
      <ToolbarButton
        title={'Justifier (Ctrl + Shift + J)'}
        icon="AlignJustify"
        active={isAlignmentActive('justify')}
        onClick={() => toggleAlignment('justify')}
        disabled={disabled}
      />
    </>
  )
}
