export enum MetricsTypeName {
  messageSent = 'messageSent',
  messageSentToDoctor = 'messageSentToDoctor',
  messageSentToPatient = 'messageSentToPatient',
  dmpDocumentSentToPatient = 'dmpDocumentSentToPatient',
}

export type Metric = {
  id: string
  success: number
  failure: number
  aboutPatientQualified?: number
  aboutPatientNotQualified?: number
}

export type MetricsSendingInfos = Record<MetricsTypeName, Metric | undefined>

export interface DmpMetricsPayload {
  success: boolean
  patientId: number | null
}
