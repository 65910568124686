import React, { FunctionComponent, useLayoutEffect, useState } from 'react'
import classNames from 'classnames'
import { DropdownOverlayProps } from './DropdownOverlay.model'
import styles from './DropdownOverlay.module.scss'

export const DropdownOverlay: FunctionComponent<DropdownOverlayProps> = ({
  display,
  children,
  scrollRef,
  position,
}) => {
  const [overlayClass, setOverlayClass] = useState<'bottom' | 'top'>(position ?? 'bottom')

  useLayoutEffect(() => {
    if (!position)
      setOverlayClass(() => {
        const rect = scrollRef.current?.getBoundingClientRect()
        if (rect) {
          const bottomBorder = rect.y + rect.height
          if (bottomBorder > window.innerHeight) {
            return 'top'
          }
        }
        return 'bottom'
      })
  }, [display, scrollRef, position])

  return display ? (
    <div ref={scrollRef} className={classNames(styles.overlay, { [styles[overlayClass]]: true })}>
      {children}
    </div>
  ) : null
}
