import React from 'react'

import styles from './Fields.module.scss'
import { FieldWrapperProps } from './FieldWrapper.model'

export const FieldWrapper = React.forwardRef<HTMLDivElement, FieldWrapperProps>(
  ({ errorMessage, children }, ref) => (
    <div className={styles.field} ref={ref}>
      <div>{children}</div>
      <div className={styles.errorMessage}>{errorMessage}</div>
    </div>
  ),
)
