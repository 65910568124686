import { PatientFormFields, EMPTY_DEFAULT_ADDRESS } from './PatientForm.model'
import { Patient, Title } from '../../../model/Patient'
import { formatShortFrStringToApiFormat } from '../../../misc/date.utilities'
import { Address } from '../../../model/Address'

function isFieldValueNotEmpty(fieldValue: string | undefined | null): fieldValue is string {
  return fieldValue !== undefined && fieldValue !== null && fieldValue.length > 0
}

function isFieldAddressNotEmpty({ street, zipCode, city, country }: Address) {
  return (
    isFieldValueNotEmpty(street) ||
    isFieldValueNotEmpty(zipCode) ||
    isFieldValueNotEmpty(city) ||
    isFieldValueNotEmpty(country)
  )
}

export function patientFormToPatientMapper(
  form: PatientFormFields,
  hasAccessToInsOid: boolean,
): Partial<Patient> {
  return {
    birthFirstName: form.birthFirstName,
    birthLastName: form.birthLastName,
    usedFirstName: form.usedFirstName,
    usedLastName: form.usedLastName,
    otherFirstNames: form.otherFirstNames,
    birthDate: formatShortFrStringToApiFormat(form.birthDate),
    sex: form.sex,
    title: form.title !== Title.NONE ? form.title : null,
    profession: isFieldValueNotEmpty(form.profession) ? form.profession : null,
    email: isFieldValueNotEmpty(form.email) ? form.email : null,
    telephone1: isFieldValueNotEmpty(form.telephone1) ? form.telephone1 : null,
    telephone2: isFieldValueNotEmpty(form.telephone2) ? form.telephone2 : null,
    doctor: isFieldValueNotEmpty(form.doctor) ? form.doctor : null,
    inseeNumber:
      hasAccessToInsOid === false
        ? undefined
        : (isFieldValueNotEmpty(form.inseeNumber) && form.inseeNumber.replace(/ /g, '')) || null,
    birthPlaceCode: form.birthPlaceCode,
    assigningAuthority:
      form.assigningAuthority && hasAccessToInsOid ? form.assigningAuthority : undefined,
    healthInsuranceNumber: isFieldValueNotEmpty(form.healthInsuranceNumber)
      ? form.healthInsuranceNumber
      : null,
    address: isFieldAddressNotEmpty(form.address)
      ? {
          street2: isFieldValueNotEmpty(form.address.street2) ? form.address.street2 : null,
          street: isFieldValueNotEmpty(form.address.street) ? form.address.street : null,
          zipCode: isFieldValueNotEmpty(form.address.zipCode) ? form.address.zipCode : null,
          city: isFieldValueNotEmpty(form.address.city) ? form.address.city : null,
          country: isFieldValueNotEmpty(form.address.country) ? form.address.country : null,
        }
      : EMPTY_DEFAULT_ADDRESS,
    identityStatus: form.identityStatus,
    contacts: form.contacts,
    customFields: form.customFields,
    disallowMSSMessaging: form.disallowMSSMessaging,
    isDeceased: form.isDeceased,
  }
}
