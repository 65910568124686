import getApi from './apisauceConfig'
import {
  FarteDocumentFormat,
  RenderableDocument,
  RenderableDocumentTemplate,
} from '../store/renderer'

export const getPdfFromDocuments = (
  documents: Array<RenderableDocument>,
  format: FarteDocumentFormat = 'pdf',
) => getApi().post('/documents/render', { documents }, { responseType: 'blob', params: { format } })

export const getPdfFromDocumentTemplate = (document: RenderableDocumentTemplate) =>
  getApi().post(
    '/document_templates/render',
    { documents: serializeDocumentTemplate(document) },
    { responseType: 'blob' },
  )

const serializeDocumentTemplate = ({
  layout,
  variablesData,
  content,
  ...doc
}: RenderableDocumentTemplate) => [
  {
    ...doc,
    content: {
      ...content,
      variablesData,
    },
    layout: layout
      ? {
          ...layout,
          variablesData,
        }
      : undefined,
  },
]
