import { PluginNames } from '@/model/Plugins'
import { SelectOption } from '@/model/SelectOption'
import { ToolbarElementProps } from '@/model/Toolbar'
import { CustomStorage } from '@tiptap/react'
import React, { FC, useMemo } from 'react'
import { ToolbarColorPalette } from '../../atoms'
import { DEFAULT_COLORS_OPTIONS } from '@/plugins/textColor/textColor.model'

const DEFAULT_COLOR_OPTION = DEFAULT_COLORS_OPTIONS[0]!

export const ColorGroup: FC<ToolbarElementProps> = ({ editor }) => {
  const colorOptions = (editor.storage as CustomStorage).textColor.getOptions().colorOptions

  const activeTextColor: string | null =
    editor.getAttributes(PluginNames.TextStyle)['color'] || null

  const activeTextColorOption = useMemo(
    () => colorOptions.find(({ value }) => value === activeTextColor),
    [activeTextColor, colorOptions],
  )

  const handleTextColorSelect = (option: SelectOption<string | null>) => {
    editor.chain().focus().setTextColor(option.value).run()
  }

  const activeHighlightColor: string | null =
    editor.getAttributes(PluginNames.TextStyle)['backgroundColor'] || null

  const activeHighlightColorOption = useMemo(
    () => colorOptions.find(({ value }) => value === activeHighlightColor),
    [activeHighlightColor, colorOptions],
  )

  const handleHighlightColorSelect = (option: SelectOption<string | null>) => {
    editor.chain().focus().setHighlightColor(option.value).run()
  }

  return (
    <>
      <ToolbarColorPalette
        title="Couleur du texte"
        value={activeTextColorOption ?? DEFAULT_COLOR_OPTION}
        options={colorOptions}
        icon="TextColor"
        onChange={handleTextColorSelect}
      />
      <ToolbarColorPalette
        title="Couleur de surlignage"
        icon="BackgroundColor"
        value={activeHighlightColorOption ?? DEFAULT_COLOR_OPTION}
        options={colorOptions}
        onChange={handleHighlightColorSelect}
      />
    </>
  )
}
