import { Indications } from '../../../model/Drug'

export interface IndicationsState {
  indications: Array<Indications>
  indicationsSelected?: Indications
}

export const IndicationsDomainActionsTypes = {
  SEARCH: '@INDICATIONS/SEARCH',
  SET_LIST: '@INDICATIONS/SET_LIST',
}
