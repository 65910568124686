import React, { FunctionComponent, useMemo } from 'react'
import { Country } from '../../../model/Contact'
import { SelectOption } from '../../../model/SelectOption'
import { DropdownItem, SelectSearch } from '../../shared'
import { CountriesSearchProps } from './CountriesSearch.model'

export const CountriesSearch: FunctionComponent<CountriesSearchProps> = ({
  search,
  colorPreset = 'light',
  countries,
  disabled = false,
  onSearch,
  onSelect,
}) => {
  const renderCountryOption = (
    { value }: SelectOption<Country>,
    currentValue: SelectOption<Country> | undefined,
  ) => {
    return (
      <DropdownItem selected={value.id === currentValue?.value.id}>
        <div key={value.id} className="flex flex-col">
          <span>{value.name}</span>
          <span className="font-normal text-sm">{value.inseeCode}</span>
        </div>
      </DropdownItem>
    )
  }

  const countriesSearchOptions = useMemo(
    () =>
      countries.map((country) => {
        return {
          label: country.name,
          value: country,
        }
      }),
    [countries],
  )

  return (
    <SelectSearch
      placeholder="Recherche par pays"
      colorPreset={colorPreset}
      icon="search"
      value={countriesSearchOptions.find((option) => option.label === search)}
      options={countriesSearchOptions}
      onSelect={(selected) => {
        onSelect(selected ? selected?.value : undefined)
      }}
      disabled={disabled}
      textValue={search}
      onTextChange={onSearch}
      renderOption={renderCountryOption}
    />
  )
}
