import { FollowTypes } from '@follow/cdk'

export enum MedicalEventFileType {
  IMAGE = 'image',
  FILE = 'file',
}

export type CustomFile = FollowTypes.File.CustomFile

export enum FileType {
  MEDICAL_EVENT = 1,
  EDITOR_DOCUMENT_FILE = 4,
  CONSENT_TEXT = 5,
  ANSWER_IMAGE = 6,
  SIGNATURE = 9,
}

export enum FileKind {
  IMAGE = 'image',
  FILE = 'file',
}
