import { DocumentTemplateSuggestionList } from '../../../../model/DocumentTemplate'
import { documentTemplatesSuggestionsSelector } from '../../../domain/documentTemplates'
import { RootState } from '../../../reducers'
import { inUseMedicalEventIdSelector } from '../medicalEvents.selectors'

export function inUseMedicalEventDocumentTemplatesSuggestionSelector(
  state: RootState,
): DocumentTemplateSuggestionList | undefined {
  const inUseId = inUseMedicalEventIdSelector(state)
  const suggestions = documentTemplatesSuggestionsSelector(state)
  return inUseId ? suggestions[inUseId] || undefined : undefined
}
