import { PluginNames } from '@/model/Plugins'
import { Mark } from '@tiptap/react'

export const ClassName = Mark.create({
  name: PluginNames.ClassName,

  group: 'inline',

  keepOnSplit: false,

  exitable: true,

  priority: 150, // Doit passer avant textStyle

  inclusive: false,

  parseHTML() {
    return [
      {
        tag: 'span',
        getAttrs(element) {
          const hasClass = !!element.className

          if (!hasClass) return false

          return {}
        },
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', HTMLAttributes, 0]
  },
})
