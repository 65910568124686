import getApi from '../../api'
import { PatientInformationsPayload } from '../../model/PatientInformations'
import {
  serializePatientInformations,
  deserializePatientResponse,
} from './mapper/patientInformations.mapper'
import { SerializedPatientInformations } from './mapper/patientInformations.model'

export const createPatientInformations = (
  patientId: string,
  patientInformationsPayload: PatientInformationsPayload,
) =>
  getApi()
    .post<SerializedPatientInformations>(
      `patients/${patientId}/patient_informations`,
      serializePatientInformations(patientInformationsPayload),
    )
    .then(deserializePatientResponse)
