import { toUpperSnakeCase } from '../../restux/restux.utilities'
import { RestuxResources } from '../../resources'

const RESOURCE_PREFIX = `@${toUpperSnakeCase(RestuxResources.documentInstance)}`

export const DomainDocumentInstancesActionTypes = {
  ADD_QUESTIONNAIRE: `${RESOURCE_PREFIX}/ADD_QUESTIONNAIRE`,
  REMOVE_VARIABLE: `${RESOURCE_PREFIX}/REMOVE_VARIABLE`,
  UPDATE_VALUE: `${RESOURCE_PREFIX}/UPDATE_VALUE`,
  LOCK_DOCUMENT: `${RESOURCE_PREFIX}/LOCK_DOCUMENT`,
  RENEW_PRESCRIPTION: `${RESOURCE_PREFIX}/RENEW_PRESCRIPTION`,
  LOCK_MULTIPLE_DOCUMENTS: `${RESOURCE_PREFIX}/LOCK_MULTIPLE_DOCUMENTS`,
  UPDATE_DOCUMENT_WITH_VARIABLEDATA_REFETCH: `${RESOURCE_PREFIX}/UPDATE_DOCUMENT_WITH_VARIABLEDATA_REFETCH`,
}
