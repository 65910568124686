import React, { FC } from 'react'
import LogoMss from '../../../../assets/images/logo_mss.png'
import { MailLoginCardProps } from './MailLoginCard.model'

export const MailLoginCard: FC<MailLoginCardProps> = ({ children }) => (
  <div className="flex flex-1 h-screen items-center justify-center bg-cover bg-no-repeat bg-top bg-shades-3 bg-[url('/src/assets/images/background/login-cover.svg')]">
    <div className="w-full max-w-sm bg-white rounded-2xl">
      <div
        className="bg-shades-1/2 overflow-hidden rounded-t-2xl"
        style={{ clipPath: 'ellipse(105% 100% at 50% 0%)' }}
      >
        <div className="flex justify-center items-center py-5">
          <img src={LogoMss} className="w-24" alt="logo-mss" />
        </div>
      </div>
      <div className="w-full px-10 mx-auto my-5">{children}</div>
    </div>
  </div>
)
