import { Reducer, AnyAction } from 'redux'
import { restuxCacheActionsFactory } from './restuxCacheActions.factory'
import { Identity, RestuxResourceState, RestuxEntityDict } from './restuxCache.model'

export const restuxCacheReducersFactory = <TL extends Identity, TD extends Identity>(
  resourceName: string,
): Reducer<RestuxResourceState<TL, TD>> => {
  const { types: ActionTypes, actions: ResourceActions } = restuxCacheActionsFactory<TL, TD>(
    resourceName,
  )
  const DEFAULT_STATE: RestuxResourceState<TL, TD> = {
    list: {},
    details: {},
  }
  return (
    state: RestuxResourceState<TL, TD> = DEFAULT_STATE,
    action: AnyAction,
  ): RestuxResourceState<TL, TD> => {
    switch (action.type) {
      case ActionTypes.STORE_SET_LIST_ITEMS:
        const { paginatedList } = action as ReturnType<typeof ResourceActions.storeSetListItems>

        const toAdd = paginatedList.items.reduce(
          (acc, item) => ({ ...acc, [item.id]: item }),
          {} as RestuxEntityDict<TL>,
        )
        return { ...state, list: { ...state.list, ...toAdd } }

      case ActionTypes.STORE_SET_ITEM_DETAILS:
        const { item } = action as ReturnType<typeof ResourceActions.storeSetItemDetails>
        return { ...state, details: { ...state.details, [item.id]: item } }

      case ActionTypes.STORE_DELETE_ITEM:
        const { id } = action as ReturnType<typeof ResourceActions.storeDeleteDetailsItem>
        // Suppression de l'item de details
        const { [id]: _itemToDeleteFromDetails, ...details } = state.details
        // Suppression de l'item de list
        const { [id]: _itemToDeleteFromList, ...list } = state.list
        return { ...state, details, list }
      case ActionTypes.STORE_CLEAR_CACHE:
        return DEFAULT_STATE
      default:
        return state
    }
  }
}
