import { patientStatisticsKeys } from './patientStatistics.keys'
import { useQuery } from '@tanstack/react-query'
import { getPatientStatistics } from '../../../data/patientStatistics'
import { UserFriendlyError } from '../../../model/Error'
import { Statistics } from '../../../model/PatientStatistics'

interface GetPatientStatisticsParams {
  enabled: boolean
  patientId: number
}
export const useGetPatientStatistics = ({
  enabled = true,
  patientId,
}: GetPatientStatisticsParams) => {
  const queryResult = useQuery<Statistics[]>(
    patientStatisticsKeys.detail(patientId),
    async () => {
      const { data, ok } = await getPatientStatistics(patientId)

      if (!data || !ok) {
        throw new UserFriendlyError('error', 'Impossible de récupérer les statistiques du patient')
      }
      return data
    },
    {
      enabled,
    },
  )

  return { query: queryResult }
}
