import uuidV4 from 'uuid/v4'
import { RestuxIdentifier } from './cache/restuxCache.model'
import { RestuxFilters } from './restux.model'

export function toUpperSnakeCase(str: string) {
  return str
    .replace(/(?:^|\.?)([A-Z])/g, (_x, y) => '_' + y.toLowerCase())
    .replace(/^_/, '')
    .replace(/-/g, '_')
    .toUpperCase()
}

export function notMatchingIdentifier(identifier, actionIdentifier) {
  return (identifier || actionIdentifier) && identifier !== actionIdentifier
}

export function generateIdentifier(scope: string) {
  return `${scope}_${uuidV4()}`
}

export function identifierMatchScope(identifier: RestuxIdentifier, scope: string) {
  return identifier && identifier.startsWith(scope)
}

export function isFiltersEmpty(filters: RestuxFilters) {
  return Object.values(filters).every((filter) => {
    if (filter instanceof Array) {
      return filter.length === 0
    } else {
      return filter === '' || filter === undefined
    }
  })
}
