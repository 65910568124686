import { take, takeEvery, put, race } from 'redux-saga/effects'
import {
  setBottomPanelOptions,
  BottomPanelComponentType,
  BottomPanelActionTypes,
} from '../bottomPanel'

import { bottomPanelBaseVariablesActions } from './bottomPanelBaseVariables.actions'
import { UiBottomPanelBaseVariablesActionTypes } from './bottomPanelBaseVariables.model'

function* searchBaseVariableSagaWorker({
  onBaseVariableSelected,
  baseVariableList,
}: ReturnType<typeof bottomPanelBaseVariablesActions.searchBaseVariable>) {
  if (baseVariableList) {
    yield put(bottomPanelBaseVariablesActions.setBaseVariableList(baseVariableList))
  }
  yield put(
    setBottomPanelOptions({
      title: `Ajouter une variable de base`,
      open: true,
      componentType: BottomPanelComponentType.SearchBaseVariable,
    }),
  )
  // Catch de la primary Action et de la fermeture du bottom panel
  const {
    selected,
  }: {
    selected?: ReturnType<typeof bottomPanelBaseVariablesActions.selectBaseVariable>
  } = yield race({
    canceled: take(BottomPanelActionTypes.SET_BOTTOM_PANEL_OPTIONS),
    selected: take(UiBottomPanelBaseVariablesActionTypes.SELECT_BASE_VARIABLE),
  })
  if (selected) {
    onBaseVariableSelected(selected.variableId)
    yield put(
      setBottomPanelOptions({
        open: false,
        componentType: BottomPanelComponentType.SearchBaseVariable,
      }),
    )
  }
}

function* searchBaseVariableSagaWatcher() {
  yield takeEvery(
    UiBottomPanelBaseVariablesActionTypes.SEARCH_BASE_VARIABLE,
    searchBaseVariableSagaWorker,
  )
}

export const bottomPanelBaseVariablesSagas = {
  searchBaseVariableSagaWatcher,
}
