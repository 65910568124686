import { useCallback, useState } from 'react'
import { useDebounce } from 'react-use'
import { EntityCard, FormHeading, Input } from '../../../shared'
import { ExtraFeesProps } from './ExtraFees.model'
import styles from './ExtraFees.module.scss'

export const ExtraFees = ({ amount, onExtraFeesChange }: ExtraFeesProps) => {
  const [fees, setFees] = useState<string>(amount.toString())

  const handleExtraFees = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setFees(e.target.value),
    [],
  )

  useDebounce(
    () => {
      onExtraFeesChange(fees)
    },
    400,
    [fees],
  )
  return (
    <EntityCard loading={false} inactive={false}>
      <div className={styles.cardContent}>
        <FormHeading headings={[{ label: 'Honoraires Complémentaires', size: 5 }]} />
        <div className={styles.extraFeesContainer}>
          <label className={styles.label}>Montant du dépassement (€)</label>
          <Input
            colorPreset="light"
            name="extraFees"
            onChange={handleExtraFees}
            type="number"
            value={fees}
            minValue={0}
          />
        </div>
      </div>
    </EntityCard>
  )
}
