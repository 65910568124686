import { call, takeEvery } from 'redux-saga/effects'
import {
  createDocumentInstanceHistoryRevision,
  createDocumentTemplateHistoryRevision,
} from '../../cache/documentHistory'
import { domainDocumentHistoryActions } from './documentHistory.actions'

import { DomainDocumentHistoryActionTypes } from './documentHistory.model'

function* createDocumentHistoryRevisionSagaWorker({
  documentId,
  documentType,
}: ReturnType<typeof domainDocumentHistoryActions.createDocumentHistoryRevision>) {
  if (documentType === 'documents') {
    yield call(createDocumentInstanceHistoryRevision, documentId)
  } else if (documentType === 'document_templates') {
    yield call(createDocumentTemplateHistoryRevision, documentId)
  }
}

function* createDocumentHistoryRevisionSagaWatcher() {
  yield takeEvery(
    DomainDocumentHistoryActionTypes.CREATE_REVISION,
    createDocumentHistoryRevisionSagaWorker,
  )
}

export const domainDocumentHistorySagas = {
  createDocumentHistoryRevisionSagaWatcher,
}
