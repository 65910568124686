import React, { FunctionComponent, useCallback, useMemo } from 'react'
import { TagSearchAndDisplay } from '../../shared'
import { TagType } from '../../../model/Tag'
import { FormCard } from './FormCard.component'
import { isDefined } from '../../../misc/functions.utilities'

interface LabelFormCardProps {
  tags: ReadonlyArray<TagType>
  tagValues: ReadonlyArray<TagType>
  setTagValues: (labels: Array<TagType>) => void
}

export const LabelFormCard: FunctionComponent<LabelFormCardProps> = ({
  tags,
  tagValues,
  setTagValues,
}) => {
  const valueIds = useMemo(() => tagValues.map(({ id }) => id), [tagValues])

  const handleUpdate = useCallback(
    (tagIds: string[]) => {
      const selectedTags = tagIds
        .map((tagId) => tags.find((tag) => tag.id === tagId))
        .filter(isDefined)
      setTagValues(selectedTags)
    },
    [setTagValues, tags],
  )

  return (
    <FormCard title="Labels" label="Les patients doivent posséder tous les labels suivants :">
      <TagSearchAndDisplay
        colorPreset="light"
        tags={tags}
        valueIds={valueIds}
        onChange={handleUpdate}
        placeholder="Rechercher un label"
      />
    </FormCard>
  )
}
