import React, { useState, useEffect } from 'react'

export function useOnScreen(ref: React.RefObject<HTMLElement> | null, rootMargin = '0px') {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    const reference = ref?.current
    if (reference) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          // Update our state when observer callback fires
          setIntersecting(entry.isIntersecting)
        },
        {
          rootMargin,
        },
      )
      observer.observe(reference)
      return () => {
        observer.unobserve(reference)
      }
    }
  }, [ref, rootMargin])

  return isIntersecting
}
