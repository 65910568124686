import { MailActionTypes, MailState } from './mail.model'
import { domainMailActions } from './mail.actions'
import { createReducer } from 'redux-starter-kit'
import { checkLocalStorageValueExist } from '../../../misc/localstorage.utilities'
import { TMP_AUTHENTICATION_ID_MAIL, AUTHENTICATION_ID_MAIL } from '../../../model/Mail'

export const MAIL_INITIAL_STATE: MailState = {
  loginInitate: checkLocalStorageValueExist(TMP_AUTHENTICATION_ID_MAIL),
  otpStatus: checkLocalStorageValueExist(AUTHENTICATION_ID_MAIL),
  emailAddress: [],
  requestStatus: 'idle',
  mailList: {
    items: [],
    itemsCount: 0,
  },
  inUseMailAddress: undefined,
  inUseMail: undefined,
  inUseMailStatus: 'idle',
  fetchingNextMailsStatus: 'idle',
  filesToSend: [],
  blackListedPatientMails: [],
  unqualifiedPatientEmails: [],
  inUseAttachment: undefined,
  isCreatePatientThroughMailModalOpen: false,
  search: undefined,
}

const ACTION_HANDLERS = {
  [MailActionTypes.SET_LOGIN_INITIATE]: (
    state: MailState = MAIL_INITIAL_STATE,
    { status }: ReturnType<typeof domainMailActions.setLoginInitiate>,
  ): MailState => ({
    ...state,
    loginInitate: status,
  }),
  [MailActionTypes.SET_OTP_STATUS]: (
    state: MailState = MAIL_INITIAL_STATE,
    { status }: ReturnType<typeof domainMailActions.setOtpStatus>,
  ): MailState => ({
    ...state,
    otpStatus: status,
  }),
  [MailActionTypes.GET_MAIL_ADDRESS_LIST]: (state: MailState = MAIL_INITIAL_STATE): MailState => ({
    ...state,
    requestStatus: 'pending',
  }),
  [MailActionTypes.SET_MAIL_ADDRESS_LIST]: (
    state: MailState = MAIL_INITIAL_STATE,
    { emailAddressList }: ReturnType<typeof domainMailActions.setMailAddressList>,
  ): MailState => ({
    ...state,
    emailAddress: emailAddressList,
    requestStatus: 'success',
  }),
  [MailActionTypes.SET_IN_USE_MAIL_ADDRESS]: (
    state: MailState = MAIL_INITIAL_STATE,
    { inUseMailAddress }: ReturnType<typeof domainMailActions.setInUseMailAddress>,
  ): MailState => ({
    ...state,
    inUseMailAddress: inUseMailAddress,
  }),
  [MailActionTypes.SET_MAIL_LIST]: (
    state: MailState = MAIL_INITIAL_STATE,
    { mailList }: ReturnType<typeof domainMailActions.setMailList>,
  ): MailState => ({
    ...state,
    mailList: {
      items: mailList.items,
      itemsCount: mailList.itemsCount,
    },
    requestStatus: 'success',
  }),
  [MailActionTypes.CLEAR_MAIL_LIST]: (state: MailState = MAIL_INITIAL_STATE): MailState => ({
    ...state,
    mailList: {
      items: [],
      itemsCount: 0,
    },
    requestStatus: 'idle',
    fetchingNextMailsStatus: 'idle',
  }),
  [MailActionTypes.SET_IN_USE_MAIL]: (
    state: MailState = MAIL_INITIAL_STATE,
    { inUseMail }: ReturnType<typeof domainMailActions.setInUseMail>,
  ): MailState => ({
    ...state,
    inUseMail,
    inUseMailStatus: 'success',
  }),
  [MailActionTypes.SET_REQUEST_MAIL_STATUS]: (
    state: MailState = MAIL_INITIAL_STATE,
    { requestStatus }: ReturnType<typeof domainMailActions.setRequestMailsStatus>,
  ): MailState => ({
    ...state,
    requestStatus,
  }),
  [MailActionTypes.SET_REQUEST_IN_USE_MAIL_STATUS]: (
    state: MailState = MAIL_INITIAL_STATE,
    { inUseMailStatus }: ReturnType<typeof domainMailActions.setRequestInUseMailsStatus>,
  ): MailState => ({
    ...state,
    inUseMailStatus,
  }),
  [MailActionTypes.SET_FILES_TO_SEND]: (
    state: MailState = MAIL_INITIAL_STATE,
    { files }: ReturnType<typeof domainMailActions.setFilesToSend>,
  ) => ({
    ...state,
    filesToSend: [...state.filesToSend, ...files],
  }),
  [MailActionTypes.CLEAR_FILES_TO_SEND]: (state: MailState = MAIL_INITIAL_STATE) => ({
    ...state,
    filesToSend: [],
  }),
  [MailActionTypes.SET_PATIENT_MAIL_BLACKLIST]: (
    state: MailState = MAIL_INITIAL_STATE,
    { blackListedPatientMails }: ReturnType<typeof domainMailActions.setPatientMailsBlackList>,
  ) => ({
    ...state,
    blackListedPatientMails: blackListedPatientMails,
  }),
  [MailActionTypes.SET_PATIENT_UNQUALIFIED]: (
    state: MailState = MAIL_INITIAL_STATE,
    { unqualifiedPatientEmailS }: ReturnType<typeof domainMailActions.setPatientUnqualified>,
  ) => ({
    ...state,
    unqualifiedPatientEmails: unqualifiedPatientEmailS,
  }),
  [MailActionTypes.SET_ATTACHMENT_PATIENT]: (
    state: MailState = MAIL_INITIAL_STATE,
    { patient }: ReturnType<typeof domainMailActions.setAttachementPatient>,
  ) => ({
    ...state,
    inUseMail: {
      ...state.inUseMail,
      patient,
    },
  }),
  [MailActionTypes.SET_IN_USE_ATTACHMENT]: (
    state: MailState = MAIL_INITIAL_STATE,
    { file }: ReturnType<typeof domainMailActions.setInUseAttachment>,
  ) => ({
    ...state,
    inUseAttachment: file,
  }),

  [MailActionTypes.SET_FETCHING_NEXT_MAILS_STATUS]: (
    state: MailState = MAIL_INITIAL_STATE,
    { status }: ReturnType<typeof domainMailActions.setFetchingNextMailsStatus>,
  ) => ({
    ...state,
    fetchingNextMailsStatus: status,
  }),

  [MailActionTypes.SET_CREATE_PATIENT_THROUGH_MAIL_MODAL_OPEN]: (
    state: MailState = MAIL_INITIAL_STATE,
    { open }: { open: boolean },
  ) => ({
    ...state,
    isCreatePatientThroughMailModalOpen: open,
  }),

  [MailActionTypes.SET_MAIL_SEARCH]: (
    state: MailState = MAIL_INITIAL_STATE,
    { search }: ReturnType<typeof domainMailActions.setMailSearch>,
  ) => ({
    ...state,
    search,
  }),
}

export const mailReducer = createReducer<MailState, any>(MAIL_INITIAL_STATE, ACTION_HANDLERS)
