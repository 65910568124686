import { ChangeEvent } from 'react'

type InputEvent = Event & {
  inputType: string
}

export function isEvent(
  input: ChangeEvent<HTMLInputElement> | string,
): input is ChangeEvent<HTMLInputElement> {
  return input.hasOwnProperty('nativeEvent')
}

export function isInputEvent(nativeEvent: Event): nativeEvent is InputEvent {
  return 'inputType' in nativeEvent
}
