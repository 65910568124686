import React, { FunctionComponent, useMemo } from 'react'
import { isDefined } from '../../../utils/value.utils'
import { NormalVariableComponentProps } from './Variable.model'
import { VariableWrapper } from './VariableWrapper.component'

const NormalVariable: FunctionComponent<NormalVariableComponentProps> = ({
  customTheme,
  fallbackTheme,
  handleClick,
  marks,
  mode,
  renderAttributes,
  value,
  fallbackValue,
  fixedValue,
  isUpToDate,
  variableTitle,
}) => {
  const [content, isFallbackActive] = useMemo<[string | null, boolean]>(() => {
    if (!!fixedValue && !!fixedValue.content) {
      // Fixed Value présente
      return [fixedValue.content, fixedValue.isFallback]
    } else {
      // Pas de fixed value
      const applyFallback = !isDefined(value)
      return [applyFallback ? fallbackValue : value, applyFallback]
    }
  }, [fixedValue, value, fallbackValue])

  return (
    <VariableWrapper
      onMouseUp={handleClick}
      mode={mode}
      title={variableTitle}
      customTheme={isFallbackActive ? fallbackTheme : customTheme}
      marks={marks}
      isFocused={renderAttributes.isFocused}
      isUpToDate={isUpToDate}
      displayMode={isFallbackActive && mode === 'print' ? 'none' : undefined}
    >
      {content}
    </VariableWrapper>
  )
}

export default NormalVariable
