import { ReactNode } from 'react'

export type TooltipSize = 'small' | 'medium'
export type TooltipTextAlign = 'left' | 'center' | 'right'

export type TooltipPointerDirection = 'top' | 'bottom' | 'left' | 'right'

export interface TooltipProps {
  content: ReactNode | string
  pointerDirection: TooltipPointerDirection
  respectParentWidth?: boolean
  size?: TooltipSize
  textAlign?: TooltipTextAlign
}

export const TooltipSizes: { [key in TooltipSize]: string } = {
  small: 'max-w-72',
  medium: 'max-w-103',
}
