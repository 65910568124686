import { capitalize } from 'lodash'
import { FunctionComponent, useCallback, useState } from 'react'
import { TerminateTreatmentModal } from '../../../drug'
import { IconButton } from '../../../shared'
import { Treatment } from '../../../../model/Treatment'
import { PatientInfosItemEditable } from '../PatientInfosItemEditable'
import { PatientCurrentTraetmentsProps } from './PatientCurrentTreatments.model'

export const PatientCurrentTreatments: FunctionComponent<PatientCurrentTraetmentsProps> = ({
  currentTreatments,
  openPosolosyBottomPanel,
  onDeleteRequest,
  navigateToDocument,
  terminateTreatment,
  isEditable,
}) => {
  const [terminatingTreatment, setTerminatingTreatment] = useState<Treatment>()

  const handleRedirectToTreatment = (item: Treatment) => {
    if (item.medicalEventId && item.documentId) {
      navigateToDocument(item.medicalEventId, item.documentId)
    }
  }

  const handleCloseModal = useCallback(() => {
    setTerminatingTreatment(undefined)
  }, [])

  const handleTerminateTreatment = useCallback(
    (endDate: Date) => {
      if (terminatingTreatment) {
        terminateTreatment(terminatingTreatment, endDate)
        handleCloseModal()
      }
    },
    [handleCloseModal, terminateTreatment, terminatingTreatment],
  )

  return (
    <div className="flex flex-col w-full px-4 py-2">
      {currentTreatments.map((item, index) => (
        <PatientInfosItemEditable
          testId={`patient-treatments-item-${index}`}
          key={index}
          name={capitalize(item.drugConfig.drug.activePrinciples ?? item.drugConfig.drug.name)}
          title={item.drugConfig.drug.name}
          startDate={item.startDate}
          endDate={item.endDate}
          prescriber={
            item.prescriber
              ? `${item.prescriber.firstName} ${capitalize(item.prescriber.familyName)}`
              : item.manualPrescriber
                ? item.manualPrescriber
                : undefined
          }
          actions={
            item.medicalEventId && item.documentId ? (
              <>
                {isEditable && (
                  <IconButton
                    icon="cross"
                    title="Arrêter le traitement"
                    theme="transparent-dark"
                    size="nano"
                    onClick={() => setTerminatingTreatment(item)}
                  />
                )}
                <IconButton
                  icon="logout"
                  title="Naviguer vers l'événement médical"
                  theme="transparent-dark"
                  size="nano"
                  onClick={() => handleRedirectToTreatment(item)}
                />
              </>
            ) : (
              <>
                {isEditable && (
                  <IconButton
                    icon="pencil"
                    title="Editer"
                    theme="transparent-dark"
                    size="nano"
                    onClick={() => openPosolosyBottomPanel(item.drugConfig.prescriptionUuid)}
                  />
                )}
                {isEditable && (
                  <IconButton
                    icon="trash"
                    title="Supprimer"
                    theme="transparent-dark"
                    size="nano"
                    onClick={() => onDeleteRequest(item)}
                  />
                )}
              </>
            )
          }
        />
      ))}
      <TerminateTreatmentModal
        display={!!terminatingTreatment}
        treatment={terminatingTreatment}
        onClose={handleCloseModal}
        onValid={handleTerminateTreatment}
      />
    </div>
  )
}
